import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { useNavigate, useParams } from "react-router-dom";
import { useGSTEdit, useUpdateGst } from "../../../hooks/GST";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import { useCreate, useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../../hooks/Api";
import Select from "react-select";
import axios from "axios";
import { Alert } from "../../../components/Model/Model";
import useFocusOnEnter from "../../../components/focus";
import CreatableSelect from "react-select/creatable";
import { useFetchAddressTypeDropDown, useFetchCityDropDown, useFetchCityPincodeDropDown, useFetchEmployeeDropDown, useFetchPersonTypeDropDown } from "../../../hooks/DropDown_Api";
import Stepper_Table from "../../../components/Dynamic_Table/Table/Stepper Table";
import Offcanvas from "react-bootstrap/esm/Offcanvas";
import { Columns_Address_Details, titleofheaders } from "./Columns_Address_Details";
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import Add_Address_Details from "./Add_Address_Details";
import Modal from "react-bootstrap/Modal";
import { Deletesinglewithdependancy } from "../../../components/modal";
import Add_Address_Modal from "./Address_Modal";
import Edit_Modal from "./Edit_Modal";
import Axiosinstance from "../../../hooks/Interceptor";
const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}
// import { Alert } from "../Model/Model";
// import Select from 'react-select/async';
interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOptions = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});

const Edit_Address_Details = ({ currentStep, setCurrentStep,setDataAdded }: any) => {
    let pageName = "Address Details";
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const [id,setId] = useState()
    // const { id } = useParams();
    const navigate = useNavigate();
    const apiname = process.env.REACT_APP_ADDRESS_API?.toString();
    const [getIdForEdit, setGetIdForEdit]: any = useState()


    let rawData = localStorage.getItem('Add Data');
    let addData = rawData ? JSON.parse(rawData) : null;
    // const ids = addData?.address?.map((e:any)=>{

    //     return e.id
    // })
    const tempData = addData?.address
    // const data = tempData


    const[isNew,setIsNew] = useState(false)

    const [data, setData]:any = useState(tempData);

    const[isEdited,setIsEdited]:any = useState(false)
    const[render,setRender] = useState(0)

useEffect(() => {
  if (isNew || isEdited) {
   

    const rawData = localStorage.getItem('Add Data');
    const addData = rawData ? JSON.parse(rawData) : null;
    setData(addData?.address);

    setIsNew(false);
    setIsEdited(false)
    
    // setRender(render+1)
  }
}, [isNew,isEdited]);

  



    const {
        mutate,
        isSuccess,
        isError,
        error,
        data: gstUpdated_response,
    }: any = useUpdateSingle();

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        reset,
        setValue,
    }: any = useForm();


    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()


    const [showOffcancasEdit, setShowOffcancasEdit] = useState(false);

    const handleCloseEdit = () => setShowOffcancasEdit(false);
    const handleShowEdit = (id:any) => {
        console.log('iddddd',id)
        setId(id)
        setShowOffcancasEdit(true)};



    // const [showOffcancasEdit, setShowOffcancasEdit] = useState(false);

    // const handleCloseEdit = () => setShowOffcancasEdit(false);
    // const handleShowEdit = () => setShowOffcancasEdit(true);


    const handleReset = () => {
        reset();
    };

    // useEffect(() => {
    //     if (data) {
    //         reset(data);
    //     }
    // }, [data]);

    const onSubmit = (materialtypeDetails: any) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(materialtypeDetails)) {
            const formvalue: any = value;
            if (typeof value !== "object") {
                formData.append(key, formvalue);
            }
        }
        let data = {
            apiname: apiname,
            id: getIdForEdit,
            data: formData,
        };
        mutate(data);
    };

    const [selEmployee, setSelEmployee]: any = useState("")
    const [selectedEmployee, setSelectedEmployee]: any = useState("")
    const [selCity, setSelCity]: any = useState("")
    const [selectedCity, setSelectedCity]: any = useState("")



    const [optionsCity, setOptionsCity]: any = useState()
    const [valueCityCreatable, setValueCityCreatable] = useState<Option | null>();

    const [valueCity, setValueCity]: any = useState()



    const [valueAddressType, setValueAddressType]: any = useState();
    const [selectedAddressType, setSelectedAddressType]: any = useState('');
    const [optionsAddressType, setOptionsAddressType]: any = useState([]);
    const [valueAddressTypeCreatable, setValueAddressTypeCreatable] = useState<Option | null>();

    const apinameForAddressType = process.env.REACT_APP_SALES_ADDRESS_TYPE_API?.toString()
    const { mutate: mutateAddressType, data: dataAddressType, isError: AddressTypeIsError, isLoading: AddressTypeLoading, isSuccess: AddressTypeSuccess, error: AddressTypeError }: any = useCreate(apinameForAddressType)

    const { data: AddressTypeData, refetch: AddressTypeRefetch } = useFetchAddressTypeDropDown()

    useEffect(() => {
        setOptionsAddressType(AddressTypeData)
    }, [AddressTypeData])

    const handleChangeAddressType = (e: any) => {
        setValueAddressType(e)
        setValue("address_type", `${e?.value}`)
        setValueAddressTypeCreatable(null)

    }


    const handleAddressTypeCreate = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOptions(inputValue);
            // setIsLoadingValue(false);


            mutateAddressType({ name: newOption.name, status: newOption.status })
            setOptionsAddressType((prev: any) => [...prev, newOption]);
            setValueAddressTypeCreatable(newOption);
        }, 0);


    };


    useEffect(() => {
        (async () => {
            if (AddressTypeSuccess && optionsAddressType) {
                await AddressTypeRefetch();
                setValue("address_type", AddressTypeData[0]?.value)
            }
        })();

        return () => {
        };
    }, [AddressTypeSuccess, AddressTypeData]);




    const apinameForCity = process.env.REACT_APP_CITY_API?.toString()

    const { mutate: mutateCity, data: dataCountry, isError: CountryIsError, isLoading: CountryLoading, isSuccess: CountrySuccess, error: CountryError }: any = useCreate(apinameForCity)


    const { data: CityData, refetch: CityRefetch } = useFetchCityDropDown()
    // const [optionsCavityName, setOptionsCavityName] = useCountry(cavityNameData)
    useEffect(() => {
        setOptionsCity(CityData)
    }, [CityData])

    const handleChangeCity = (e: any) => {

        setValue("city", `${e?.value}`)
        setValueCityCreatable(null)
        setValueDistrict(e?.district)
        setValueState(e?.state)
        setValueCountry(e?.country)
        setValueTaluka(e?.taluka)
        // setCountryValue(e?.value)

        setByCity(e?.value)
        setSelCity(e)
    }


    const handleCreateCity = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOptions(inputValue);
            // setIsLoadingValue(false);


            mutateCity({ name: newOption.name, status: newOption.status })
            setOptionsCity((prev: any) => [...prev, newOption]);
            setValueCityCreatable(newOption);
        }, 0);


    };

    // const [cityValue,setCityValue]=useState("")
    useEffect(() => {
        (async () => {
            if (CountrySuccess && optionsCity) {
                await CityRefetch();
            }
            setValue("city", CityData[0]?.value)
            // setCityValue(CityData[0]?.value)
        })();

        return () => {
        };
    }, [CountrySuccess, CityData]);






    const [valuePersonType, setValuePersonType]: any = useState();

    const [optionsPersonType, setOptionsPersonType]: any = useState([])

    const [isLoadingValue, setIsLoadingValue] = useState(false);
    const [selectedPersonType, setSelectedPersonType]: any = useState("")
    const [valuePersonTypeCreatable, setValuePersonTypeCreatable] = useState<Option | null>();

    const apinameForPersonType = process.env.REACT_APP_PERSON_TYPE_API?.toString()

    const { mutate: mutatePersonType, data: dataCavity, isError: cavityIsError, isLoading: cavityLoading, isSuccess: personTypeSuccess, error: cavityError }: any = useCreate(apinameForPersonType)


    const { data: personTypeData, refetch: personTypeRefetch } = useFetchPersonTypeDropDown()

    useEffect(() => {
        setOptionsPersonType(personTypeData)
    }, [personTypeData])





    const handleChangePersonType = (e: any) => {
        setValue("type", e?.value)
        setValuePersonTypeCreatable(null)
        setValuePersonType(e)
    }

    const handleCreatePersonType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOptions(inputValue);
            setIsLoadingValue(false);


            mutatePersonType({ name: newOption.name, status: newOption.status })
            setOptionsPersonType((prev: any) => [...prev, newOption]);
            setValuePersonTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (personTypeSuccess && optionsPersonType) {
                await personTypeRefetch();
                setValue("type", personTypeData[0].value)
            }
        })();

        return () => {
        };
    }, [personTypeSuccess, personTypeData]);


    const [selectedCityPincode, setSelectedCityPincode]: any = useState("")


    const [cityPincodeOptions, setCityPincodeOptions]: any = useState()
    const [forCityPincodeValue, setForCityPincodeValue]: any = useState()
    const [selCityPincode, setSelCityPincode]: any = useState("")
    const [byCity, setByCity] = useState()
    const { data: cityPincodeData, refetch: cityPincodeRefetch } = useFetchCityPincodeDropDown(byCity)


    useEffect(() => {

        setCityPincodeOptions(cityPincodeData)
    }, [cityPincodeData])

    const handleChangePincode = (e: any) => {
        setForCityPincodeValue(e)
        setValue("pincode", e?.value)
        setSelCityPincode(e)
    }




    const [forValue, setForValue]: any = useState()
    const [employeeOptions, setEmployeeOptions]: any = useState()
    const [forEmployeeValue, setForEmployeeValue] = useState(0)


    const { data: employeeData, refetch: employeeRefetch } = useFetchEmployeeDropDown()


    useEffect(() => {
        setEmployeeOptions(employeeData)
    }, [employeeData])



    const [valueDistrict, setValueDistrict] = useState()
    const [valueState, setValueState] = useState()
    const [valueCountry, setValueCountry] = useState()
    const [valuePincode, setValuePincode] = useState()
    const [valueTaluka, setValueTaluka] = useState()







    const handleChangeAreaManager = (e: any) => {

        setForEmployeeValue(e)
        setValue("employee_code", e.value)

    }
    const [buildingType, setBuildingType] = useState("")
    const handleBuildingType = (e: any) => {
        setBuildingType(e.target.value)
    }


    // const prevDataRef = useRef();

    // useEffect(() => {
    //     const isDataChanged = JSON.stringify(data) !== JSON.stringify(prevDataRef.current);
    //     if (data && isDataChanged) {
    //         reset(data);
    //         prevDataRef.current = data;
    //     }
    // }, [data, reset]);


    // useEffect(() => {
    //     if (data) {
    //         reset(data)
    //         setSelectedCity(data.city?.id)
    //         setValue("city", data.city?.id)
    //         setSelectedCityPincode(data.pincode?.id)
    //         setValue("pincode", data.pincode?.id)
    //         setValueState(data.city?.state?.name)
    //         setValueDistrict(data.city?.district?.name)
    //         setValueCountry(data.city?.country?.name)
    //         setValueTaluka(data.city?.taluka?.name)
    //         setByCity(data?.city?.id)
    //         setSelectedEmployee(data.employee_code?.id)
    //         setValue("employee_code", data.employee_code?.id)
    //         setValue("type", data.type?.id)
    //         setSelectedPersonType(data.type?.id)
    //         setSelectedAddressType(data?.address_type?.id)
    //         setValue('address_type', data?.address_type?.id)
    //     }
    // }, [])



  


    // const [show, setShow] = useState(false);
    // const [showmessage, setshowmessage] = useState("");

    // useEffect(() => {
    //     if (isSuccess) {
    //         setShow(true);
    //         setshowmessage("success");
    //         //  const currentPath = window.location.pathname;
            // if(currentPath === '/addCustomerDetails'){
            // setCurrentStep(currentStep + 1)
            // }
    //         //   navigate("/listAddress", { state: { showEditToast: true } });
    //     } else if (isError) {
    //         setShow(true);
    //         setshowmessage("danger");
    //     }
    // }, [gstUpdated_response, error, isSuccess]);

 

    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    };
    const maxLengthCheck = (object: any) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }


    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    const [openEditDateModel, setOpenEditDateModel] = useState(false)
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()
    const [expirationDate, setExpirationDate] = useState()


    const apinameForBasicDetails = process.env.REACT_APP_EMPLOYEE_API?.toString()
    const { mutate:BasicDetails, isSuccess:basicDetailsIsSuccess, isError:basicDetailsIsError, error:basicDetailsError, data: basicDetailsData }: any = useUpdateSingle()

    let rawDataTemp = localStorage.getItem('Add Data');
    let addDataTemp = rawDataTemp ? JSON.parse(rawDataTemp) : null;
    
    let basicIdTemp = addDataTemp ? addDataTemp.id : null;

    const [basicDetailsInitiated,setBasicDetailsInitiated] = useState(false)

    useEffect(() => {
        if ((isSuccess || yessuccess || nosuccess) && !basicDetailsInitiated) {
            setShow(true);
            setshowmessage("success");
            
        // let tempId = {
        //                 personal_detail:data?.data?.id
        //             }
        
        //             let tempData = {
        //                 id: basicIdTemp,
        //                 apiname: apinameForBasicDetails,
        //                 data: tempId
        //             }
        //             BasicDetails(tempData);
                    setBasicDetailsInitiated(true);
                    setDataAdded(true)
            //   navigate("/listCity", { state: { showEditToast: true } });
            //  const currentPath = window.location.pathname;
            // if(currentPath === '/addCustomerDetails'){
            // setCurrentStep(currentStep + 1)
            // }
        } else if (isError) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [data, isSuccess, yessuccess, nosuccess, gstUpdated_response, error,
        isSuccess,]);

        const[updateCompleted,setUpdateCompleted] = useState(false)

        useEffect(()=>{
            if(basicDetailsIsSuccess && basicDetailsData &&  !updateCompleted){
                
            localStorage.setItem('Add Data',JSON.stringify(basicDetailsData?.data))
            setUpdateCompleted(true);
             const currentPath = window.location.pathname;
            if(currentPath === '/addCustomerDetails'){
            setCurrentStep(currentStep + 1)
            };
            }
        },[basicDetailsIsSuccess,basicDetailsData])

    useEffect(() => {
        if (isError) {
            if (error.response?.status === 405) {
                setConfirmmodal(true);
            }
        } else if (isError || yeserror || noerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [isError, yeserror, noerror]);
    useEffect(() => {
        setTimeout(() => {
            setShow(false);
        }, 3000);
    }, [show]);
    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }
    const noupdatemodal = () => {
        setOpenEditDateModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {

        delete data.created_at
        delete data.created_by
        let ok = {
            id: basicIdTemp,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditDateModel(false)
    }




    const [ids, setIds] = useState([])

    const [ForEditIds,setForEditIds]:any = useState([])

    console.log('ForEditIds',ForEditIds)


    const [multipleIds, setMultipleIds]: any = useState([])

    const [added, setAdded] = useState(false)

    const prevDataRef = useRef();

    const [localIds,setLocalIds] = useState([])

    useEffect(() => {
        const isDataChanged = JSON.stringify(data) !== JSON.stringify(prevDataRef.current);
        if (data &&  ids.length>0 && isDataChanged) {
            debugger
            reset(data);
            setMultipleIds(data)
            setForEditIds(data)
          
            // setForEditIds((oldIds: any) => {
            //     const newIds = [...oldIds, ids];
            //     console.log('New IDs:', newIds);
            //     return newIds;
            // });
            prevDataRef.current = data;
        }
    }, [data, reset, ids]);



    // useEffect(()=>{
    //     if(isNew){
    //         
    //         setForEditIds(ids)
    //         setIsNew(false)
    //     }
    // },[isNew])


    useEffect(() => {
        if (isSuccess) {
            if (data) {
                setMultipleIds((oldIds: any) => {
                    const newIds = [...oldIds, data.data.id];
                    console.log('New IDs:', newIds);
                    return newIds;
                });

                setAdded(false)
            }
        }
    }, [isSuccess, data]);


    const filter = {

        name: ""

    };

    const addButtonName = "Document Detail";
    const addButtonPageName = "salesDocument"
    const permissionClass = {
        front: "document",
        back: "documentdetail"
    }
    const stickyStart = 1;
    const stickyEnd = 2;

    const handleNext = () => {
        let tempId = {
            address: multipleIds
        }

        let tempData = {
            id: basicIdTemp,
            apiname: apinameForBasicDetails,
            data: tempId
        }
        BasicDetails(tempData);
        // BasicDetails({})
         const currentPath = window.location.pathname;
            if(currentPath === '/addCustomerDetails'){
            setCurrentStep(currentStep + 1)
            };
    }


    const[addShowOffcancas,setAddShowOffcancas] = useState(false)


    // const[render]

    console.log('isEdited',isEdited)


  

    const[activePage,setActivePage] = useState('')
    
    useEffect(() => {
        setTimeout(() => {
            setActivePage('Edit Address')
        }, 500)

    }, [activePage]);

    const currentPath = window.location.pathname;

    

    const apinameAddress = process.env.REACT_APP_ADDRESS_API?.toString()    
    useEffect(()=>{
        if(data.length>0){
            const getCall = async()=>{
            try {
                const response = await Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apinameAddress}/fetch_multiple_ids/`, {
                  ids: data
                });
                console.log(response,"ResponseData:");
                setIds(response?.data?.data)
                
                reset(response?.data?.data)
                
              } catch (error) {
                console.error('Error:', error);
              }
            }
            getCall()
        }
    },[])


    return (
        <div>
            <div className="">
                <section className="content-header py-1 px-3">
                    {/* <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>Edit {pageName}</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#" className="text-decoration-none text-black">
                      Home
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" className="text-decoration-none text-black">
                      Sales And Marketing
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" className="text-decoration-none text-black">
                      {pageName}
                    </a>
                  </li>
                  <li className="breadcrumb-item active">Edit {pageName}</li>
                </ol>
              </div>
            </div>
          </div> */}
                </section>
                <section className="">
                    <div className="">
                        <div className="card card-default">
                        <div className="row">
                                        <p className="mb-0 " style={{fontSize:'14px'}}>{`${addData?.person_type?.name}-${addData?.name}-${addData?.extra_response?.city_name_and_distict_name?.village_name?addData?.extra_response?.city_name_and_distict_name?.village_name:addData?.extra_response?.city_name_and_distict_name?.city_name}`}</p>
                                    </div>
                            <div className="row d-flex  ">
                                <div className="row d-flex ">
                                    <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-end text-sm-end ">
                                        <button
                                            type="button" className="border-1 rounded text-white px-4 py-1"
                                            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                            onClick={()=>setAddShowOffcancas(true)}

                                        >Add Addresses</button>

                                    </div>
                                </div>
                            </div>


                            <Add_Address_Modal addShowOffcancas={addShowOffcancas} setAddShowOffcancas={setAddShowOffcancas} setAdded={setAdded} setForEditIds={setForEditIds}/>

                            <Edit_Modal  showOffcancasEdit={showOffcancasEdit} handleCloseEdit={handleCloseEdit} setShowOffcancasEdit={setShowOffcancasEdit} id={id} setIsEdited={setIsEdited}/>
                            


                           
{/* 
                            <Offcanvas show={showOffcancasEdit} onHide={handleCloseEdit} placement={'end'} style={{ width: '178vh', zIndex: '10000' }} >
                                <Offcanvas.Header closeButton>
                                </Offcanvas.Header>
                                <Offcanvas.Body >
                                    <div className="card card-default">
                                        <h3>Edit</h3>
                                        <form ref={focus}
                                            onKeyUp={event => onEnterKey(event)}
                                            onSubmit={handleSubmit(onSubmit)}>
                                            <div className="card-body" >
                                                <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                         







                                                            <div className="col-sm-3 mt-2">

                                                                <label >Address Type<span style={{ color: 'red' }}>*</span></label>

                                                             
                                                                <CreatableSelect
                                                                    isClearable={() => {
                                                                        setValueAddressType(null)
                                                                        setValue(null)
                                                                    }}
                                                                    {...register("address_type", { required: { value: true, message: "Address Type is required" } })}
                                                                    placeholder={'Select Address Type'}
                                                                    components={customCreatableIndicator}
                                                                    options={optionsAddressType}
                                                                    onCreateOption={handleAddressTypeCreate}
                                                                    onChange={handleChangeAddressType}
                                                                    value={valueAddressTypeCreatable == null ? valueAddressType == null ? optionsAddressType?.filter((obj: any) => obj.value === selectedAddressType) : valueAddressType : valueAddressTypeCreatable}

                                                                />
                                                                <span className='text-red w-100'>{errors?.address_type?.message}</span>

                                                            </div>
                                                            <div className="col-sm-3 mt-2">

                                                                <label >Building Type <span style={{ color: 'red' }}>*</span></label>

                                                                <select className="form-select" {...register("building_number_type", { onChange: handleBuildingType, required: { value: true, message: "Building Type is required" } })}>

                                                                    <option value={""} selected>Select Any</option>
                                                                    <option value="block no">Block No</option>
                                                                    <option value="shop no">Shop No</option>
                                                                    <option value="godown no">Godown No</option>
                                                                    <option value="flat no">Flat No</option>
                                                                    <option value="survey no">Survey No</option>
                                                                    <option value="factory no">Factory No</option>
                                                                </select>
                                                                <span className='text-red w-100'>{errors?.building_number_type?.message}</span>

                                                            </div>

                                                            <div className="col-sm-3 mt-2">

                                                                <label >Number <span style={{ color: 'red' }}>*</span></label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Number" {...register("number", { onChange: handleValue, required: { value: true, message: "Number is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.number?.message}</span>

                                                            </div>
                                                            <div className="col-sm-3 mt-2">

                                                                <label >Bunglow Name</label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Bunglow Name"
                                                                        disabled={buildingType != "block no"}
                                                                        {...register("bunglow_name", { onChange: handleValue, required: { value: false, message: "Bunglow Name is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.bunglow_name?.message}</span>

                                                            </div>


                                                            <div className="col-sm-3 mt-2">

                                                                <label >Building Name <span style={{ color: 'red' }}>*</span></label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Building Name" {...register("building_name", { onChange: handleValue, required: { value: true, message: "Building Name is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.building_name?.message}</span>

                                                            </div>


                                                            <div className="col-sm-3 mt-2">

                                                                <label >Society Name</label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control"
                                                                        disabled={buildingType != "block no"}
                                                                        placeholder="Society Name" {...register("society_name", { onChange: handleValue, required: { value: false, message: "Society Name is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.society_name?.message}</span>

                                                            </div>


                                                            <div className="col-sm-3 mt-2">

                                                                <label >Street Address <span style={{ color: 'red' }}>*</span></label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Street Address" {...register("street", { onChange: handleValue, required: { value: true, message: "Street Address is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.street?.message}</span>

                                                            </div>


                                                            <div className="col-sm-3 mt-2">

                                                                <label >Landmark <span style={{ color: 'red' }}>*</span></label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Landmark" {...register("landmark", { onChange: handleValue, required: { value: true, message: "Landmark is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.landmark?.message}</span>

                                                            </div>

                                                            <div className="col-sm-3 mt-2">

                                                                <label >Area <span style={{ color: 'red' }}>*</span></label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Area" {...register("area", { onChange: handleValue, required: { value: true, message: "Area is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.area?.message}</span>

                                                            </div>


                                                            <div className="col-sm-3 mt-2">

                                                                <label >City <span style={{ color: 'red' }}>*</span></label>
                                                                <Select
                                                                    isClearable={() => {
                                                                        setValueCity(null)
                                                                        setValue(null)
                                                                    }}
                                                                    {...register("city", { required: { value: true, message: "City Name is required" } })}
                                                                    placeholder={'Select City Name'}
                                                                    options={optionsCity}
                                                                    onChange={handleChangeCity}
                                                                    value={selCity === "" ? optionsCity?.filter((obj: any) => obj.value === selectedCity) : selCity}


                                                                />
                                                                <span className='text-red w-100'>{errors?.city?.message}</span>

                                                            </div>

                                                          
                                                            <div className="col-sm-3 mt-2">

                                                                <label >Mobile Number <span style={{ color: 'red' }}>*</span></label>
                                                                <div className="input-group">
                                                                    <input type="number" className="form-control" placeholder="Mobile Number"
                                                                        maxLength={10}
                                                                        minLength={15}
                                                                        onInput={maxLengthCheck}
                                                                        {...register('mobile_number', {
                                                                            required: "Mobile Number is required",

                                                                            pattern: {
                                                                                value: /^(\+\d{1,3}[- ]?)?\d{10}$/g,
                                                                                message: 'Please enter a valid number',
                                                                            },
                                                                        })}
                                                                    />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.mobile_number?.message}</span>

                                                            </div>





                                                            <div className="col-sm-3 mt-2">
                                                                <label >Remark </label>
                                                                <div className="input-group" >
                                                                    <input type="text" className="form-control"

                                                                        placeholder="Remark"
                                                                        {...register("remark", { onChange: handleValue, required: { value: false, message: "Remark is required" } })}
                                                                    />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.remark?.message}</span>
                                                            </div>













                                                            <div className="col-sm-3 mt-2">
                                                                <label >Status <span style={{ color: 'red' }}>*</span></label>
                                                                <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>

                                                                    <option value={""} selected>Select Any</option>
                                                                    <option value="active">Active</option>
                                                                    <option value="inactive">Inactive</option>
                                                                </select>
                                                                <span className='text-red w-100'>{errors?.status?.message}</span>

                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="row d-flex  ">
                                                    <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                                        <div className="row d-flex ">
                                                            <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                                <button
                                                                    type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                                    style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                                    onClick={handleSubmit(onSubmit)}
                                                                >Submit</button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </form>
                                    </div>


                                </Offcanvas.Body>
                            </Offcanvas> */}



                            <Stepper_Table
                            key={render}
                                apiname={apiname}
                                filterFields={filter}
                                addButtonName={addButtonName}
                                stickyStart={stickyStart}
                                stickyEnd={stickyEnd}
                                TableColumns={Columns_Address_Details}
                                addButtonPageName={addButtonPageName}
                                titleofheaders={titleofheaders}
                                permissionClass={permissionClass}
                                isMore={false}
                                isAddButton={false}
                                // handleMultipleData={multipleData}
                                multipleIds={ForEditIds}
                                added={added}
                                setAdded={setAdded}
                                setShowOffcancas={setAddShowOffcancas}
                                localIds={localIds}
                                // setShowOffcancasEdit={setShowOffcancasEdit}
                                // setMultipleIds={setMultipleIds}
                                setGetIdForEdit={setGetIdForEdit}
                                setIsNew={setIsNew}
                                handleCloseEdit={handleCloseEdit}
                                handleShowEdit={handleShowEdit}
                                activePage={activePage}
                            />






                            <div className="row d-flex  ">
                                <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                    <div className="row d-flex ">
                                        <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                            <button
                                                type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                onClick={handleNext}
                                            >Submit</button>
<button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetail")}>Cancel</button>
{currentPath === '/addCustomerDetails'?
                                         <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => setCurrentStep(currentStep-1)}>Back</button> 
                                        :null}
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </section>
            </div>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                    yesupdatemodal={yesupdatemodal}
                    cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />

                <Modal show={openEditDateModel} onHide={() => setOpenEditDateModel(false)} aria-labelledby="contained-modal-title-vcenter"
                    centered    >
                    <Modal.Header closeButton>
                        <Modal.Title>Select a Date</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-sm-12 mt-2">
                                    <label htmlFor="exampleInputEmail1">Choose Date</label>
                                    <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                                    {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditDateModel(false) }}>Close</button>
                        <button
                            type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                            onClick={handleSubmit(handleConfirm)}
                        >Confirm</button>
                    </Modal.Footer>
                </Modal>
        </div>
    );
};

export default Edit_Address_Details;
