import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate } from "../../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../../components/focus";
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import { DatePicker } from "antd";
import { AiOutlineCheck , AiOutlineClose } from "react-icons/ai";
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "../Sales Order/sales_order.css"


const Quotation_Details = () => {
    let pageTitle = "Sales Order"
    const focus:any = useRef();
    const {onEnterKey} = useFocusOnEnter(focus);
    const navigate = useNavigate()

    const [openEditModel, setOpenEditModel] = useState(false)

    const { register, handleSubmit, setError, formState, reset,setValue, getValues  }:any = useForm({
        mode:"onChange"
    });
    const { errors } = formState;

    
    
    const onSubmit = (data: any) => {
       
       
    }
    
    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
    .split(' ')
    .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');

    }
    
    
    const handleAddProduct = () => {
        setOpenEditModel(true)
    }

    const handleReset = () => {
        reset()
    }
    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        {/* <div className="row">
                            <div className="col-sm-6">
                                <h4>Add {pageTitle}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listState" className="text-decoration-none text-black">State</Link></li>
                                    <li className="breadcrumb-item active">Add {pageTitle}</li>
                                </ol>
                            </div>
                        </div> */}
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Source Name</span></h5>
                            </div> */}
                            <form ref={focus}
                             onKeyUp={event => onEnterKey(event)}
                             onSubmit={handleSubmit(onSubmit)}>
                         
                                            

                                            <div style={{display:"flex"}}>

                                                <div className="px-2">

<label >Name<span style={{ color: 'red' }}>*</span></label>
</div>

<div className="w-25 px-3">
<Select 
           
          

        />
        </div>
</div>

                                       
                                





<div className="py-2 ">
        <div className="row ">
        
        <div className="col-12 col-md-6 col-lg-3  grid-box d-flex align-items-center  vertical-line bottom-line alignItems paddingSet">
        <div className="cardShine" style={{display:"flex",justifyContent:"left",alignItems:"center",textAlign:"start",backgroundColor:"#003665"}}>
        <div className="shine"></div>
        <div className="content">
        <p  style={{color:"white"}}><strong className="font-weight-bold" style={{color:"white"}}>Name: </strong>M.M + 1234</p>
        <p style={{color:"white"}}><strong className="font-weight-bold" style={{color:"white"}}>Address: </strong>Ahmedabad</p>
        </div>
    </div>  
</div>
                
            <div className="col-12 col-md-6 col-lg-3  grid-box d-flex align-items-center vertical-line bottom-line paddingSet">
            <div className="cardShine" style={{display:"flex",justifyContent:"left",alignItems:"center",textAlign:"start",backgroundColor:"#003665"}}>
                      <div className="shine"></div>
                      <div className="content">
                      <p style={{color:"white"}}><strong  style={{color:"white"}} className="font-weight-bold">Party Mobile Number: </strong> 9898203562</p>
                                  <p style={{color:"white"}}><strong style={{color:"white"}} className="font-weight-bold">Sales Person: </strong> ABCD</p>
                      </div>
                  </div>  
            </div>
            
           
            <div className="col-12 col-md-6 col-lg-3 grid-box d-flex align-items-center vertical-line bottom-line paddingSet">
            <div className="cardShine" style={{display:"flex",justifyContent:"left",alignItems:"center",textAlign:"start",backgroundColor:"#003665"}}>
        <div className="shine"></div>
        <div className="content">
        <p style={{color:"white"}}><strong style={{color:"white"}} className="font-weight-bold">Delivered Cust. To: </strong> 1234</p>
                    <p style={{color:"white"}}><strong style={{color:"white"}} className="font-weight-bold">Delivery Address: </strong> - </p>
                    {/* <p style={{color:"white"}}> - </p> */}
                </div>
        </div>
            </div>
            
         
            {/* <div className="col-12 col-md-6 col-lg-3  grid-box d-flex align-items-center paddingSet"> */}
            <div className="col-12 col-md-6 col-lg-3  grid-box d-flex align-items-center  paddingSet py-1">
            <div className="cardShine" style={{display:"flex",justifyContent:"left",alignItems:"center",textAlign:"start",backgroundColor:"#003665"}}>
        <div className="shine"></div>
        <div className="content">
        <p style={{color:"white"}}><strong style={{color:"white"}} className="font-weight-bold">Date: </strong> 05 / 06 / 2023</p>
                    <p style={{color:"white"}}><strong style={{color:"white"}} className="font-weight-bold">Req. Delivery Date: </strong> 05 / 06 / 2023</p>
                    <p style={{color:"white"}}><strong style={{color:"white"}} className="font-weight-bold">Commitment Date: </strong> 05 / 06 / 2023</p>
                </div>
        </div>
            </div>
        </div>

        </div>

                              


{/* <div className="py-2">
        <Accordion >
      <Accordion.Item eventKey="0" >
        <Accordion.Header > Monthly Sales Details</Accordion.Header>
        <Accordion.Body>
        <Table responsive>
      <thead >
        <tr>
          <th>Month</th>
          <th>Aprl-23</th>
          <th>May-23</th>
          <th>Jun-23</th>
          <th>Jul-23</th>
          <th>Aug</th>
          <th>Sep</th>
          <th>Oct</th>
          <th>Nov</th>
          <th>Dec</th>
          <th>Jan</th>
          <th>Feb</th>
          <th>Mar</th>
          <th>Total</th>
          <th>Average</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>odear Qty.</td>
          <td>50</td>
          <td>50</td>
          <td>50</td>
          <td>50</td>
          <td>50</td>
          <td>50</td>
          <td>50</td>
          <td>50</td>
          <td>50</td>
          <td>50</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
        <td>Disp Qty.</td>
          <td>50</td>
          <td>50</td>
          <td>50</td>
          <td>50</td>
          <td>50</td>
          <td>50</td>
          <td>50</td>
          <td>50</td>
          <td>50</td>
          <td>50</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
        <td>Basic Amount</td>
          <td>70000.00</td>
          <td>70000.00</td>
          <td>70000.00</td>
          <td>70000.00</td>
          <td>70000.00</td>
          <td>70000.00</td>
          <td>70000.00</td>
          <td>70000.00</td>
          <td>70000.00</td>
          <td>70000.00</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </Table>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header> Other Details</Accordion.Header>
        <Accordion.Body>
   






<div className="p-2">
        <div className="row  p-3">
        
        <div className="col-12 col-md-12 col-lg-4  grid-box d-flex align-items-center justify-content-center  ">


       
        <table  >
      <thead>
        <tr>
            <th colSpan={4} style={{textAlign:"center"}}>
            Comm. Terms and Cond.
            </th>
        </tr>
        <tr>
          <th>Due Amount</th>
          <th>Total Amount</th>
          <th>Credit Limit Rs.</th>
          <th>Credit Days</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>500</td>
          <td>1000</td>
          <td>2000</td>
          <td>30</td>
        </tr>
        <tr>
        <td>500</td>
          <td>1000</td>
          <td>2000</td>
          <td>30</td>
        </tr>
        <tr>
        <td>500</td>
          <td>1000</td>
          <td>2000</td>
          <td>30</td>
        </tr>
      </tbody>
    </table>
 
   




</div>
                

            <div className="col-12 col-md-12 col-lg-4  grid-box d-flex align-items-center  ">
                
            <table  >
        
    
      
        <thead>
        <tr>
              <th colSpan={4} style={{textAlign:"center"}}>
              Transportation Details
              </th>
          </tr>
          <tr>
            <th>Mode Of Dispatch</th>
            <th>By Road</th>
           
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Insurance</td>
            <td>Nil</td>
            
          </tr>
          <tr>
          <td>Insurance</td>
            <td>ACPL</td>
            
          </tr>
          <tr>
          <td>Insurance</td>
            <td>Nil</td>
          </tr>
        </tbody>
        
      </table>



            </div>
            
           
            <div className="col-12 col-md-12 col-lg-4 grid-box d-flex align-items-center  ">
            <table  >
    <thead>
    <tr>
            <th colSpan={4} style={{textAlign:"center"}}>
            Pending Details
            </th>
        </tr>
        <tr>
          <th>Pending Order  NOS.</th>
          <th>DILEVRY ODER Pending BOX</th>
          
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>3</td>
          
        </tr>
        <tr>
          <td>34</td>
          <td>45</td>
          
        </tr>
        <tr>
          <td>56</td>
          <td > 45</td>
          
        </tr>
      </tbody>
    </table>
            </div>
            
         
           
        </div>

        </div>


        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div> */}

















<>

<div className="table-responsive" >
 

 <table className="table"  style={{marginTop:"20px"}}>
   <thead style={{backgroundColor:"#343a40",color:"white"}}>
     <tr >
       <th>ID</th>
       <th>Product Code</th>
       <th>Item Description</th> 
       <th>Godown Stock</th>
       <th>Balance Stock</th>
       <th>Lee10 Price</th>
       <th>UOM</th>
       <th>Order Qty</th>
       <th>Discount After Tax</th>
       <th>Discount %</th>
       <th>1 KG rate</th>
       <th>After Discount Box</th>
       <th>Brand</th>
       <th>Nos/Strip</th>
       <th>Box/Strip</th>
       <th>Cup Weight</th>
       <th>Box Weight</th>
       <th>Basic Discount</th>
       <th>Total Basic Discount</th>
       <th>Basic Rate per</th>
       <th>Total Basic</th>
       <th>GST Rate</th>
       <th>GST Amount</th>
       <th>Net Amount</th>
       <th > Delete</th>
       <th >Approve</th>
       <th >Special Requirement</th>
     </tr>
   </thead>
   <tbody>
   
       <tr >
         {/* <td>{field.id}</td> */}
         <td>
         <input
          style={{border:"none",textAlign:"center",background:"white"}}
             type="text"
             placeholder="ID"
             disabled
            value={"1"}
           />
          
         </td>
         <td>
         <input
         style={{border:"none",textAlign:"center",background:"white"}}
             type="text"
             value="123456788010"
             disabled
            
           />
           
         </td>
         <td>
         <input
         style={{border:"none",textAlign:"center",background:"white"}}
             type="text"
             value={"Glass"}
             disabled
            
           />
           
         </td>
         <td>
         <input
          style={{border:"none",textAlign:"center",background:"white"}}
             type="text"
             value={"100"}
             disabled
            
           />
           
         </td>
         <td>
           <input
            style={{border:"none",textAlign:"center",background:"white"}}
             type="text"
             disabled
             value={"50"}
           />
           
         </td>
         <td>
         <input
        value={"2000.00"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
           
           />
           
         </td>
         <td>
           <input
           value={"Box"}
             type="text"
             disabled
              style={{border:"none",textAlign:"center",background:"white"}}
           />
           
         </td>
         <td>
         <input
        value={"10"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
             />
         </td>
         <td>
         <input
        value={"100"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
             />
         </td>
         <td>
         <input
         value={"5"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
            
           />
         </td>
         <td>
         <input
         value={"126"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
             />
         </td>
         <td>
         <input
         value={"1900"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
            
           />
         </td>
         <td>
         <input
         value={"Lion"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
           
           />
         </td>
         <td>
         <input
         value={"50"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
           
           />
         </td>
         <td>
         <input
         value={"120"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
            
           />
         </td>
         <td>
         <input
         value={"2.50"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
            
           />
         </td>
         <td>
         <input
         value={"15.00"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
            
           />
         </td>
         <td>
         <input
         value={"84.75"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
            
           />
         </td>
         <td>
         <input
         value={"339.00"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
            
           />
         </td>
         <td>
         <input
         
             type="text"
             value={"1695.00"}
             disabled
              style={{border:"none",textAlign:"center",background:"white"}}
           />
         </td>
         <td>
         <input
          style={{border:"none",textAlign:"center",background:"white"}}
             type="text"
             value={"6441.00"}
             disabled
            
           />
         </td>
         <td>
         <input
          style={{border:"none",textAlign:"center",background:"white"}}
             type="text"
             value={"18"}
             disabled
            
           />
         </td>
         <td>
         <input
         value={"1159.38"}
             type="text"
             disabled
              style={{border:"none",textAlign:"center",background:"white"}}
           />
         </td>
         <td>
         <input
         value={"7600.38"}
             type="text"
             disabled
              style={{border:"none",textAlign:"center",background:"white"}}
           />
         </td>
         <td>
         <button
                  type="button"
                  className="btn btn-danger"
                >
                  <AiOutlineClose/>
                </button>
         </td>
         <td >
         <button
                  type="button"
                  className="btn btn-danger"
                >
                  <AiOutlineCheck/>
                </button>
         </td>
         <td >
         <select>
            <option value={""} selected>Select Any</option>
            <option value={"yes"}>Yes</option>
            <option value={"no"}>No</option>
         </select>
         </td>
        
       
         
       </tr>
       <tr  >
         {/* <td>{field.id}</td> */}
         <td>
         <input
         style={{border:"none",textAlign:"center",background:"white"}}
             type="text"
             placeholder="ID"
             disabled
            value={"2"}
           />
          
         </td>
         <td>
         <input
         style={{border:"none",textAlign:"center",background:"white"}}
             type="text"
             value="123456788010"
             disabled
            
           />
           
         </td>
         <td>
         <input
         style={{border:"none",textAlign:"center",background:"white"}}
             type="text"
             value={"Glass"}
             disabled
            
           />
           
         </td>
         <td>
         <input
          style={{border:"none",textAlign:"center",background:"white"}}
             type="text"
             value={"100"}
             disabled
            
           />
           
         </td>
         <td>
           <input
            style={{border:"none",textAlign:"center",background:"white"}}
             type="text"
             disabled
             value={"50"}
           />
           
         </td>
         <td>
         <input
        value={"2000.00"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
           
           />
           
         </td>
         <td>
           <input
           value={"Box"}
             type="text"
             disabled
              style={{border:"none",textAlign:"center",background:"white"}}
           />
           
         </td>
         <td>
         <input
        value={"10"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
             />
         </td>
         <td>
         <input
        value={"100"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
             />
         </td>
         <td>
         <input
         value={"5"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
            
           />
         </td>
         <td>
         <input
         value={"126"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
             />
         </td>
         <td>
         <input
         value={"1900"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
            
           />
         </td>
         <td>
         <input
         value={"Lion"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
           
           />
         </td>
         <td>
         <input
         value={"50"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
           
           />
         </td>
         <td>
         <input
         value={"120"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
            
           />
         </td>
         <td>
         <input
         value={"2.50"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
            
           />
         </td>
         <td>
         <input
         value={"15.00"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
            
           />
         </td>
         <td>
         <input
         value={"84.75"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
            
           />
         </td>
         <td>
         <input
         value={"339.00"}
             type="text"
              style={{border:"none",textAlign:"center",background:"white"}}
             disabled
            
           />
         </td>
         <td>
         <input
          style={{border:"none",textAlign:"center",background:"white"}}
             type="text"
             value={"1695.00"}
             disabled
           
           />
         </td>
         <td>
         <input
          style={{border:"none",textAlign:"center",background:"white"}}
             type="text"
             value={"6441.00"}
             disabled
            
           />
         </td>
         <td>
         <input
          style={{border:"none",textAlign:"center",background:"white"}}
             type="text"
             value={"18"}
             disabled
            
           />
         </td>
         <td>
         <input
         value={"1159.38"}
             type="text"
             disabled
              style={{border:"none",textAlign:"center",background:"white"}}
           />
         </td>
         <td>
         <input
         value={"7600.38"}
             type="text"
             disabled
             style={{border:"none",textAlign:"center",background:"white"}}
           />
         </td>
         <td>
         <button
                  type="button"
                  className="btn btn-danger"
                >
                  <AiOutlineClose/>
                </button>
         </td>
         <td >
         <button
                  type="button"
                  className="btn btn-danger"
                >
                  <AiOutlineCheck/>
                </button>
         </td>
         <td >
         <select>
            <option value={""} selected>Select Any</option>
            <option value={"yes"}>Yes</option>
            <option value={"no"}>No</option>
         </select>
         </td>
        
       
         
       </tr>


       
       <tr style={{textAlign:"center"}}>
        <td colSpan={3}  style={{backgroundColor:"#343a40",color:"white"}}>Total</td>
        <td >800</td>
        <td >400</td>
        <td >400</td>
        <td ></td>
        <td >26</td>
        <td >80.00</td>
        <td >3.43</td>
        <td >144.55</td>
        <td ></td>
        <td ></td>
        <td ></td>
        <td ></td>
        <td ></td>
        <td >17.36</td>
        <td >67.80</td>
        <td >1678.05</td>
        <td ></td>
        <td >58460.55</td>
        <td ></td>
        <td >10522.90</td>
        <td >68983.45</td>
        <td ></td>
        <td ></td>
        <td >   <button 
                                                type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3" 
                                                style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                
                                                // onClick={handleSubmit(onSubmit)}
                                                >Save</button></td>
       </tr>




















   
   </tbody>



 </table>



 </div>

 <div className="p-1" style={{textAlign:"end",justifyContent:"end"}}>

      <button
      type="button"
      className="btn btn-success"
      onClick={handleAddProduct}
      >
      Add Product
      </button>

</div>




 <div className="row">

<div className="col-12 col-md-12 col-lg-8 p-2" >


{/* <button
type="button"
className="btn btn-success"
onClick={handleAddProduct}
>
Add Product
</button> */}




      <Card>
      <Card.Header as="h4">Terms & Conditions</Card.Header>
      <Card.Body>
        
        <Card.Text>
        <ul className="ul">
    <li className="li">Acceptance of Terms: By using our services, you accept and agree to these terms and conditions in full.</li>
    <li className="li">User Eligibility: Our services are intended for users who are 18 years of age or older.</li>
    <li className="li">Privacy Policy: Your use of our services is also governed by our Privacy Policy.</li>
    <li className="li">Account Registration: You may be required to register for an account to access certain features.</li>
    <li className="li">User Conduct: You agree not to engage in any unlawful or prohibited activities while using our services.</li>
    <li className="li">Intellectual Property: All content and materials on our platform are protected by intellectual property rights.</li>
    <li className="li">Limitation of Liability: We do not guarantee the accuracy or completeness of information provided on our platform.</li>
    <li className="li">Indemnification: You agree to indemnify and hold us harmless from any claims or damages arising from your use of our services.</li>
    <li className="li">Modification of Terms: We reserve the right to modify these terms and conditions at any time. Please review them periodically.</li>
</ul>
        </Card.Text>
        
      </Card.Body>
    </Card>





</div>

{/* <div>

</div> */}
<div className="col-12 col-md-12 col-lg-4 p-2 " >

<Card>
      <Card.Header as="h4">Total</Card.Header>
      <Card.Body>
        {/* <Card.Title>Special title treatment</Card.Title> */}
        <Card.Text>
        <div className="row">
    <div className="col-6 col-md-6 col-lg-6 border-end">
      Total
    </div>
    <div className="col-6 col-md-6 col-lg-6">
      1000
    </div>
  </div>

  <div className="row">
    <div className="col-6 col-md-6 col-lg-6 border-end">
      Discount Amount
    </div>
    <div className="col-6 col-md-6 col-lg-6">
    1000
    </div>
  </div>

  <div className="row">
    <div className="col-6 col-md-6 col-lg-6 border-end">
      Transportation Charges
    </div>
    <div className="col-6 col-md-6 col-lg-6">
    1000
    </div>
  </div>

  <div className="row">
    <div className="col-6 col-md-6 col-lg-6 border-end">
     GST
    </div>
    <div className="col-6 col-md-6 col-lg-6">
    1000
    </div>
  </div>

  <div className="row">
    <div className="col-6 col-md-6 col-lg-6 border-end">
      Net Pay
    </div>
    <div className="col-6 col-md-6 col-lg-6">
    1000
    </div>
  </div>
        </Card.Text>
        
      </Card.Body>
    </Card>





<div style={{display:"flex",justifyContent:"center"}}>
    <button
type="button"
className="btn btn-success"
// onClick={handleAddProduct}
>
Submit
</button>
</div>


    </div>
    </div>
</>
























                            </form>


                        </div>





                        








                    </div>
                </section>
            </div>
            <Modal show={openEditModel} onHide={() => setOpenEditModel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Sales Order Product</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Add Product Here.</p>
        </Modal.Body>

        <Modal.Footer>
        <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>
                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        // onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>
        </Modal.Footer>
      </Modal>
        </div>
    )
}

export default Quotation_Details