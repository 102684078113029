import React, { useContext, useEffect, useRef, useState } from "react";
import SearchLogo from "../../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate, useUpdateSingle } from "../../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../../components/focus";
import CreatableSelect from "react-select/creatable";
import { useFetchNationalityDropDown, useFetchReligionDropDown, useFetchCasteDropDown, useFetchBloodGroupDropDown } from "../../../hooks/DropDown_Api";

import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import { DatePicker } from "antd";
import dayjs from 'dayjs';
import moment from "moment";

import { useFormContext } from 'react-hook-form';


import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/esm/Modal";
import { Deleteforce, Deletesingle } from "../../../components/Cell/Actions";
import { SimpleInput_Edit } from "../../../components/Dynamic_Form_Models/SimpleInput_Edit";
import { useDelete, useDeletemforce } from "../../../hooks/delete";
import Custom_Actions_Buttons from "../../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../../hooks/deletemultiple";

// import { GlobalStateContext } from "../GlobalStateContext";

interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOptions = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});

const Add_Personal_Details = ({ setSuccessPersonalDetails, setCurrentStep, currentStep }: any) => {
    let pageName = "Customer Grade"
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()
    // const { formData, setFormData } = useContext(GlobalStateContext);

    // const customIndicator = {
    //     IndicatorsContainer: IndicatorsContainer
    // }

    // const customCreatableIndicator = {
    //     IndicatorsContainer: IndicatorsContainerCreate
    // }



    const apiname = process.env.REACT_APP_SALES_PERSONAL_DETAILS_API?.toString()


    const { mutate: Grade, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)
    const { register, handleSubmit, setError, formState, reset, setValue, getValues }: any = useForm({
        mode: "onChange"
    });

    // const { register, handleSubmit, setError, formState, reset,setValue, getValues  }:any = useFormContext();
    const { errors, isDirty } = formState;

    // alert('in add')

    const onSubmit = (data: any) => {

        if (!aniversaryDate) {
            delete data.anniversary_date
        }
        if (!birthDate) {
            delete data.birth_date
        }
        if (!JoiningDate) {
            delete data.joining_date
        }
        if (!LeavingDate) {
            delete data.leaving_date
        }

        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            const formvalue: any = value



            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }

        }
        Grade(formData)


    }

    // useEffect(()=>{
    //     if(isSuccess){
    //         setSuccessPersonalDetails(true)
    //     }
    // },[isSuccess])


    const apinameForBasicDetails = process.env.REACT_APP_EMPLOYEE_API?.toString()
    const { mutate: BasicDetails, isSuccess: basicDetailsIsSuccess, isError: basicDetailsIsError, error: basicDetailsError, data: basicDetailsData }: any = useUpdateSingle()

    let rawData = localStorage.getItem('Add Data');
    let addData = rawData ? JSON.parse(rawData) : null;

    let basicId = addData ? addData.id : null;




    useEffect(() => {
        if (isSuccess && data) {


            let tempId = {
                personal_detail: data?.data?.id
            }

            let tempData = {
                id: basicId,
                apiname: apinameForBasicDetails,
                data: tempId
            }
            BasicDetails(tempData);
            // BasicDetails({})

        }
    }, [data, isSuccess])



    useEffect(() => {
        if (basicDetailsIsSuccess && basicDetailsData) {

            localStorage.setItem('Add Data', JSON.stringify(basicDetailsData?.data))
            const currentPath = window.location.pathname;
            if (currentPath === '/addCustomerDetails') {
                setCurrentStep(currentStep + 1)
            };
        }
    }, [basicDetailsIsSuccess, basicDetailsData])

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            // setSuccessPersonalDetails(true)
            // navigate('/listPersonDetailGrade', { state: { showToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    };

    const handleReset = () => {
        reset()
        setBirthDate(null)
        setJoiningDate(null)
        setLeavingDate(null)
        setAnniversaryDate(null)
        setAge(0)
    }


    const [valueNationality, setValueNationality]: any = useState();
    const [optionsNationality, setOptionsNationality]: any = useState([])
    const [isLoadingValue, setIsLoadingValue] = useState(false);
    const [valueNationalityCreatable, setValueNationalityCreatable] = useState<Option | null>();
    const apinameForNationality = process.env.REACT_APP_NATIONALITY_API?.toString()
    const { mutate: mutateNationality, data: dataNationality, isError: NationalityIsError, isLoading: NationalityLoading, isSuccess: NationalitySuccess, error: NationalityError }: any = useCreate(apinameForNationality)

    const { data: NationalityData, refetch: NationalityRefetch } = useFetchNationalityDropDown()

    useEffect(() => {
        setOptionsNationality(NationalityData)
    }, [NationalityData])

    const handleChangeNationality = (e: any) => {

        NationalityRefetch()

        setApiName(apinameForNationality)
        setDeleteApiName(apinameForNationality)
        setEditId(e?.value)
        setEditData(NationalityData)
        setRenderKey(prevKey => prevKey + 1);

        setValueNationality(e)

        setValue("nationality", e?.value)
        setValueNationalityCreatable(null)
    }

    const handleCreateNationality = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOptions(inputValue);
            setIsLoadingValue(false);


            mutateNationality({ name: newOption.name, status: newOption.status })
            setOptionsNationality((prev: any) => [...prev, newOption]);
            setValueNationalityCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (NationalitySuccess && optionsNationality) {
                await NationalityRefetch();
                setValue("nationality", NationalityData[0].value)
            }

        })();

        return () => {
        };
    }, [NationalitySuccess, NationalityData]);

    const [valueReligion, setValueReligion]: any = useState();
    const [optionsReligion, setOptionsReligion]: any = useState([])
    // const [isLoadingValue, setIsLoadingValue] = useState(false);
    const [valueReligionCreatable, setValueReligionCreatable] = useState<Option | null>();
    const apinameForReligion = process.env.REACT_APP_RELIGION_API?.toString()
    const { mutate: mutateReligion, data: dataReligion, isError: ReligionIsError, isLoading: ReligionLoading, isSuccess: ReligionSuccess, error: ReligionError }: any = useCreate(apinameForReligion)

    const { data: ReligionData, refetch: ReligionRefetch } = useFetchReligionDropDown()

    useEffect(() => {
        setOptionsReligion(ReligionData)
    }, [ReligionData])

    const handleChangeReligion = (e: any) => {

        ReligionRefetch()

        setApiName(apinameForReligion)
        setDeleteApiName(apinameForReligion)
        setEditId(e?.value)
        setEditData(ReligionData)
        setRenderKey(prevKey => prevKey + 1);

        setValueReligion(e)

        setValue("religion", e?.value)
        setValueReligionCreatable(null)
    }

    const handleCreateReligion = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOptions(inputValue);
            setIsLoadingValue(false);


            mutateReligion({ name: newOption.name, status: newOption.status })
            setOptionsReligion((prev: any) => [...prev, newOption]);
            setValueReligionCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ReligionSuccess && optionsReligion) {
                await ReligionRefetch();
                setValue("religion", ReligionData[0].value)
            }

        })();

        return () => {
        };
    }, [ReligionSuccess, ReligionData]);

    const [valueCaste, setValueCaste]: any = useState();
    const [optionsCaste, setOptionsCaste]: any = useState([])
    // const [isLoadingValue, setIsLoadingValue] = useState(false);
    const [valueCasteCreatable, setValueCasteCreatable] = useState<Option | null>();
    const apinameForCaste = process.env.REACT_APP_EMPLOYEE_CASTE_API?.toString()
    const { mutate: mutateCaste, data: dataCaste, isError: CasteIsError, isLoading: CasteLoading, isSuccess: CasteSuccess, error: CasteError }: any = useCreate(apinameForCaste)

    const { data: CasteData, refetch: CasteRefetch } = useFetchCasteDropDown()

    useEffect(() => {
        setOptionsCaste(CasteData)
    }, [CasteData])

    const handleChangeCaste = (e: any) => {

        CasteRefetch()

        setApiName(apinameForCaste)
        setDeleteApiName(apinameForCaste)
        setEditId(e?.value)
        setEditData(CasteData)
        setRenderKey(prevKey => prevKey + 1);

        setValueCaste(e)

        setValue("caste", e?.value)
        setValueCasteCreatable(null)
    }

    const handleCreateCaste = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOptions(inputValue);
            setIsLoadingValue(false);


            mutateCaste({ name: newOption.name, status: newOption.status })
            setOptionsCaste((prev: any) => [...prev, newOption]);
            setValueCasteCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (CasteSuccess && optionsCaste) {
                await CasteRefetch();
                setValue("caste", CasteData[0].value)
            }

        })();

        return () => {
        };
    }, [CasteSuccess, CasteData]);

    const [valueBloodGroup, setValueBloodGroup]: any = useState();
    const [optionsBloodGroup, setOptionsBloodGroup]: any = useState([])
    // const [isLoadingValue, setIsLoadingValue] = useState(false);
    const [valueBloodGroupCreatable, setValueBloodGroupCreatable] = useState<Option | null>();
    const apinameForBloodGroup = process.env.REACT_APP_EMPLOYEE_BLOODGROUP_API?.toString()
    const { mutate: mutateBloodGroup, data: dataBloodGroup, isError: BloodGroupIsError, isLoading: BloodGroupLoading, isSuccess: BloodGroupSuccess, error: BloodGroupError }: any = useCreate(apinameForBloodGroup)

    const { data: BloodGroupData, refetch: BloodGroupRefetch } = useFetchBloodGroupDropDown()

    useEffect(() => {
        setOptionsBloodGroup(BloodGroupData)
    }, [BloodGroupData])

    const handleChangeBloodGroup = (e: any) => {

        BloodGroupRefetch()

        setApiName(apinameForBloodGroup)
        setDeleteApiName(apinameForBloodGroup)
        setEditId(e?.value)
        setEditData(BloodGroupData)
        setRenderKey(prevKey => prevKey + 1);

        setValueBloodGroup(e)

        setValue("blood_group", e?.value)
        setValueBloodGroupCreatable(null)
    }

    const handleCreateBloodGroup = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOptions(inputValue);
            setIsLoadingValue(false);


            mutateBloodGroup({ name: newOption.name, status: newOption.status })
            setOptionsBloodGroup((prev: any) => [...prev, newOption]);
            setValueBloodGroupCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (BloodGroupSuccess && optionsBloodGroup) {
                await BloodGroupRefetch();
                setValue("blood_group", BloodGroupData[0].value)
            }

        })();

        return () => {
        };
    }, [BloodGroupSuccess, BloodGroupData]);

    const [birthDate, setBirthDate]: any = useState()

    const [age, setAge] = useState(0)

    const handleBirthDate = (e: any) => {


        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let birth_date = `${date}-${month}-${year}`
        setValue("birth_date", birth_date)

        setBirthDate(e)

        // setAge(0)
        // setValue("age", 0)
    }



    //       useEffect(() => {
    //     if (birthDate) {

    //         // 
    //         let todayDate = new Date().toISOString().split("T")[0];

    //           var bDate = moment([birthDate?.$y, birthDate?.$M + 1, birthDate?.$D]);


    //         var currentDate:any = moment([todayDate.split("-")[0], todayDate.split("-")[1], todayDate.split("-")[2]]);
    //         // var calculatedAge = currentDate.diff(bDate, 'years');


    //         setAge(currentDate.diff(bDate, 'days'));
    //         setValue("age", age);
    //     } else {
    //         setAge(0);
    //         setValue("age", 0);
    //     }
    // }, [birthDate,age]); 

    useEffect(() => {
        if (birthDate) {

            let todayDate = new Date().toISOString().split("T")[0]


            var DateOne = moment([birthDate.$y]);

            var currentDate = moment([todayDate.split("-")[0]]);


            setAge(currentDate.diff(DateOne, 'years'))
            setValue('age', age)

        }
    }, [birthDate, age])




    //   useEffect(() => {

    //       if (birthDate != null || birthDate) {


    //           let todayDate = new Date().toISOString().split("T")[0]

    //           if (birthDate?.$D === 31) {
    //               var bDate = moment([birthDate?.$y, birthDate?.$M, birthDate?.$D]);
    //           } else {
    //               var bDate = moment([birthDate?.$y, birthDate?.$M + 1, birthDate?.$D]);
    //           }



    //           var currentDate = moment([todayDate.split("-")[0], todayDate.split("-")[1], todayDate.split("-")[2]]);

    //           setAge(currentDate.diff(bDate, 'years'))
    //           setValue("age", age)





    //       } else {
    //           setAge(0)
    //           setValue("age", 0)
    //       }



    //   }, [birthDate,age])




    const [Married, setMarried] = useState("Single")

    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
    const [JoiningDate, setJoiningDate]: any = useState("")
    const [LeavingDate, setLeavingDate]: any = useState("")


    const handleJoiningDate = (e: any) => {

        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let joining_date = `${date}-${month}-${year}`
        setValue("joining_date", joining_date)
        setJoiningDate(e)

    }
    const handleLeavingDate = (e: any) => {

        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let leaving_date = `${date}-${month}-${year}`
        setValue("leaving_date", leaving_date)
        setLeavingDate(e)

    }

    const [aniversaryDate, setAnniversaryDate]: any = useState("")

    const handleAnniversaryDate = (e: any) => {

        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let anniversary_date = `${date}-${month}-${year}`
        setValue("anniversary_date", anniversary_date)
        setAnniversaryDate(e)

    }

    const onMarriedChange = (e: any) => {
        setMarried(e.target.value)
    }


    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
    } = useDelete();
    const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
    } = useDeletemforce();



    // const queryClient = useQueryClient();
    const handleRefetch = () => {
        if (apiName === apinameForNationality) {
            NationalityRefetch()
        }
        if (apiName === apinameForReligion) {
            ReligionRefetch()
        }
        if (apiName === apinameForCaste) {
            CasteRefetch()
        }
        if (apiName === apinameForBloodGroup) {
            BloodGroupRefetch()
        }


        // queryClient.invalidateQueries(['Panni Material Use']);




    }


    useEffect(() => {
        if (OpenEditDropDownModel && valueNationality && apiName === apinameForNationality) {
            setValueNationality(null)
            setValueNationalityCreatable(null)
        }
        if (OpenEditDropDownModel && valueReligion && apiName === apinameForReligion) {
            setValueReligion(null)
            setValueReligionCreatable(null)
        }
        if (OpenEditDropDownModel && valueCaste && apiName === apinameForCaste) {
            setValueCaste(null)
            setValueCasteCreatable(null)
        }
        if (OpenEditDropDownModel && valueBloodGroup && apiName === apinameForBloodGroup) {
            setValueBloodGroup(null)
            setValueBloodGroupCreatable(null)
        }








        if (deletesucess && apiName === apinameForNationality) {
            setValueNationality(null)
            setValueNationalityCreatable(null)
        }
        if (deletesucess && apiName === apinameForReligion) {
            setValueReligion(null)
            setValueReligionCreatable(null)
        }
        if (deletesucess && apiName === apinameForCaste) {
            setValueCaste(null)
            setValueCasteCreatable(null)
        }
        if (deletesucess && apiName === apinameForBloodGroup) {
            setValueBloodGroup(null)
            setValueBloodGroupCreatable(null)
        }




    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (


        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
            <span>{label}</span>
            <div style={{ float: "right", justifyContent: "end" }}>



                {isExist ?

                    <Custom_Actions_Buttons value={value}
                        apiname={deleteApiName}
                        mutate={singleDelete}
                        // masterName={addButtonName}
                        deleteid={deleteid}
                        handleDeletedforce={handleDeletedforce}
                        closereconfirmdelete={closereconfirmdelete}
                        deletemodalask={deletemodalask}
                        setEditOpen={setOpenEditDropDownModel}
                    // permissionClass={permissionClass}
                    />
                    : ""}

            </div>
        </div>


    )


    const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
    }



    useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
        return () => {
            setDeleteid(undefined);
        };
    }, []);
    const handleConfirmDelete = () => {
        setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
    };

    const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
    };
    useEffect(() => {
        if (forcefullysuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullyerror) {
            setShow(true);
            setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullydeleteerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
    ]);

    useEffect(() => {
        if (deleteMultipleSuccess) {
            const showdepenedancymsg = deleteMultiple?.data?.data;
            const dependacyfound = showdepenedancymsg?.filter((color: any) => {
                if (
                    color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
                ) {
                    return color;
                }
            });
            if (dependacyfound?.length === deleteMultipleid?.length) {
                deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
            } else {
                setConfirmforcemodal(true);
            }
        } else if (deleteMultipleIsError) {
            setShow(true);
            setshowmessage("danger");
        } else if (deletesucess) {
            if (deletedata.data?.data?.length > 0) {
                setConfirmforcemodal(true);
            } else if (deleteid) {
                setShow(true);
                setshowmessage("success");
                deleteforce({ deleteid, apiname: deleteApiName });
            }
        }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

    const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
    };








    const currentPath = window.location.pathname;




    return (
        <div>
            {/* <div className="" style={{ minHeight: '1345.31px' }}> */}
            <section className="content-header py-1 px-3">
                {/* <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Add {pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">{pageName}</Link></li>
                                    <li className="breadcrumb-item active">Add {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div> */}
            </section>
            {/* <section className="content"> */}
            {/* <div className="container-fluid"> */}
            <div className="card card-default">
                {/* <h3 className="px-3">Personal Details</h3> */}
                {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Source Name</span></h5>
                            </div> */}
                <form ref={focus}
                    onKeyUp={event => onEnterKey(event)}
                    onSubmit={handleSubmit(onSubmit)}>
                    <div className="card-body" >
                        <div className="row">
                            <p className="mb-0 " style={{ fontSize: '14px' }}>{`${addData?.person_type?.name}-${addData?.name}-${addData?.extra_response?.city_name_and_distict_name?.village_name ? addData?.extra_response?.city_name_and_distict_name?.village_name : addData?.extra_response?.city_name_and_distict_name?.city_name}`}</p>
                        </div>
                        <div className="row d-flex justify-content-md-center justify-content-lg-start">
                            <div className="col-md-12">
                                <div className="row">
                                    {/* <div className="col-sm-3 mt-2">
                                                <label >Person Type <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                    {...register("person_type", { required: { value: true, message: "Field is required" } })}
                                                    isClearable={()=>{
                                                        setValuePersonType(null)
                                                        setValue(null)}}
                                                        components={customCreatableIndicator}
                                                        placeholder={'Person Type'}
                                                        options={optionsPersonType}
                                                        onCreateOption={handleCreatePersonType}
                                                        onChange={handleChangePersonType}
                                                        value={valuePersonTypeCreatable==null?valuePersonType:valuePersonTypeCreatable}
                                                        

                                                    />
                                                    <span className='text-red w-100'>{errors?.person_type?.message}</span>

                                                </div>    */}







                                    <div className="col-sm-3 mt-2">
                                        <label htmlFor="manualcode_Disc_1">Joining Date <span style={{ color: 'red' }}>*</span></label>
                                        <div className="input-group" >
                                            <DatePicker
                                                className="form-control"
                                                format={dateFormatList}
                                                // value={forPointStartDateValue}
                                                // disabledDate={disabledDate}
                                                value={JoiningDate}
                                                {...register("joining_date", { required: { value: true, message: "Joining Date is required" } })}

                                                onChange={handleJoiningDate}
                                            />
                                        </div>
                                        <span className='text-red w-100'>{errors?.joining_date?.message}</span>
                                    </div>
                                    <div className="col-sm-3 mt-2">
                                        <label htmlFor="manualcode_Disc_1">Leaving Date </label>
                                        <div className="input-group" >
                                            <DatePicker
                                                className="form-control"
                                                format={dateFormatList}
                                                // value={forPointStartDateValue}
                                                // disabledDate={disabledDate}
                                                value={LeavingDate}
                                                {...register("leaving_date", { required: { value: false, message: "Leaving Date is required" } })}

                                                onChange={handleLeavingDate}
                                            />
                                        </div>
                                        <span className='text-red w-100'>{errors?.leaving_date?.message}</span>
                                    </div>
                                    <div className="col-sm-3 mt-2">
                                        <label htmlFor="manualcode_Disc_1">Birth Date <span style={{ color: 'red' }}>*</span></label>
                                        <div className="input-group" >
                                            <DatePicker
                                                className="form-control"
                                                format={dateFormatList}
                                                // value={forPointStartDateValue}
                                                // disabledDate={disabledDate}
                                                value={birthDate}
                                                {...register("birth_date", { required: { value: true, message: "Birth Date is required" } })}
                                                picker="date"
                                                onChange={handleBirthDate}
                                            />
                                        </div>
                                        <span className='text-red w-100'>{errors?.birth_date?.message}</span>
                                    </div>
                                    {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Age</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" disabled className="form-control"
                                                            value={age}
                                                        {...register("age", { required: { value: false, message: "Field is required" } })}
                                                            placeholder="Age"  />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.age?.message}</span>
                                                </div> */}
                                    <div className="col-sm-3 mt-2">
                                        <label htmlFor="exampleInputEmail1">Age</label>
                                        <div className="input-group" data-colorpicker-id={2}>
                                            <input type="number" disabled className="form-control"
                                                value={age}
                                                placeholder="Age" {...register("age", { required: { value: false, message: "Field is required" } })} />
                                        </div>
                                        <span className='text-red w-100'>{errors?.age?.message}</span>
                                    </div>
                                    <div className="col-sm-3 mt-2">
                                        <label htmlFor="exampleInputEmail1">Marital Status <span style={{ color: "red" }}>*</span></label>
                                        <select className="form-select"  {...register("marital_status", { onChange: onMarriedChange, required: { value: true, message: "Marital Status is required" } })}>
                                            <option value={""} selected >Select Any</option>
                                            <option value="Single" >Single</option>
                                            <option value="Married">Married</option>
                                            <option value="UnMarried">UnMarried</option>
                                            <option value="Divorced">Divorced</option>
                                        </select>

                                        <span className='text-red w-100'>{errors?.marital_status?.message}</span>
                                    </div>
                                    {/* {Married === "Married" && (<> */}
                                    <div className="col-sm-3 mt-2">
                                        <label htmlFor="manualcode_Disc_1">Anniversary Date </label>
                                        <div className="input-group" >
                                            <DatePicker
                                                className="form-control"
                                                format={dateFormatList}
                                                value={aniversaryDate}
                                                disabled={Married !== "Married"}
                                                // value={forPointStartDateValue}
                                                // disabledDate={disabledDate}
                                                {...register("anniversary_date", { required: { value: Married === "Married" ? true : false, message: "Point Start Date is required" } })}
                                                onChange={handleAnniversaryDate}
                                            />
                                        </div>
                                        <span className='text-red w-100'>{errors?.anniversary_date?.message}</span>
                                    </div>
                                    {/* </>)} */}
                                    <div className="col-sm-3 mt-2">
                                        <label htmlFor="exampleInputEmail1">Nationality <span style={{ color: "red" }}>*</span></label>
                                        {/* <select className="form-select"  {...register("nationality", { required: { value: true, message: "Nationality is required" } })}>
                                            <option value={""} selected >Select Any</option>
                                            <option value="Indian" >Indian</option>
                                            <option value="Other">Other</option>
                                        </select> */}
                                        <CreatableSelect
                                            {...register("nationality", { required: { value: true, message: "Field is required" } })}
                                            isClearable={() => {
                                                setValueNationality(null)
                                                setValue(null)
                                            }}
                                            components={customCreatableIndicator}
                                            placeholder={'Nationality'}
                                            onMenuOpen={() => NationalityRefetch()}
                                            options={optionsNationality}
                                            onCreateOption={handleCreateNationality}
                                            onChange={handleChangeNationality}
                                            value={valueNationalityCreatable == null ? valueNationality : valueNationalityCreatable}


                                        />
                                        <span className='text-red w-100'>{errors?.nationality?.message}</span>
                                    </div>
                                    <div className="col-sm-3 mt-2">
                                        <label htmlFor="exampleInputEmail1">Religion <span style={{ color: "red" }}>*</span></label>
                                        {/* <select className="form-select"  {...register("religion", { required: { value: true, message: "Religion is required" } })}>
                                            <option value={""} selected >Select Any</option>
                                            <option value="Hindu" >Hindu</option>
                                            <option value="Muslim">Muslim</option>
                                            <option value="Christian">Christian</option>
                                            <option value="Sikh">Sikh</option>
                                        </select> */}

                                        <CreatableSelect
                                            {...register("religion", { required: { value: true, message: "Field is required" } })}
                                            isClearable={() => {
                                                setValueReligion(null)
                                                setValue(null)
                                            }}
                                            components={customCreatableIndicator}
                                            placeholder={'Religion'}
                                            onMenuOpen={() => ReligionRefetch()}
                                            options={optionsReligion}
                                            onCreateOption={handleCreateReligion}
                                            onChange={handleChangeReligion}
                                            value={valueReligionCreatable == null ? valueReligion : valueReligionCreatable}


                                        />
                                        <span className='text-red w-100'>{errors?.religion?.message}</span>
                                    </div>
                                    <div className="col-sm-3 mt-2">
                                        <label htmlFor="exampleInputEmail1">Caste <span style={{ color: "red" }}>*</span></label>
                                        {/* <select className="form-select"  {...register("caste", { required: { value: true, message: "Caste is required" } })}>
                                            <option value={""} selected >Select Any</option>
                                            <option value="General" >General</option>
                                            <option value="ST">ST</option>
                                            <option value="SC">SC</option>
                                            <option value="OBC">OBC</option>
                                            <option value="Other">Other</option>
                                        </select> */}

                                        <CreatableSelect
                                            {...register("caste", { required: { value: true, message: "Field is required" } })}
                                            isClearable={() => {
                                                setValueCaste(null)
                                                setValue(null)
                                            }}
                                            components={customCreatableIndicator}
                                            placeholder={'Caste'}
                                            onMenuOpen={() => CasteRefetch()}
                                            options={optionsCaste}
                                            onCreateOption={handleCreateCaste}
                                            onChange={handleChangeCaste}
                                            value={valueCasteCreatable == null ? valueCaste : valueCasteCreatable}


                                        />

                                        <span className='text-red w-100'>{errors?.caste?.message}</span>
                                    </div>
                                    <div className="col-sm-3 mt-2">
                                        <label htmlFor="exampleInputEmail1">Blood Group <span style={{ color: "red" }}>*</span></label>
                                        {/* <select className="form-select"  {...register("blood_group", { required: { value: true, message: "Blood Group is required" } })}>
                                            <option value={""} selected >Select Any</option>
                                            <option value="O+" >O+</option>
                                            <option value="O-">O-</option>
                                            <option value="A+">A+</option>
                                            <option value="A-">A-</option>
                                            <option value="B+">B+</option>
                                            <option value="B-">B-</option>
                                            <option value="AB+">AB+</option>
                                            <option value="AB-">AB-</option>
                                        </select> */}

                                        <CreatableSelect
                                            {...register("blood_group", { required: { value: true, message: "Field is required" } })}
                                            isClearable={() => {
                                                setValueBloodGroup(null)
                                                setValue(null)
                                            }}
                                            components={customCreatableIndicator}
                                            placeholder={'Blood Group'}
                                            onMenuOpen={() => BloodGroupRefetch()}
                                            options={optionsBloodGroup}
                                            onCreateOption={handleCreateBloodGroup}
                                            onChange={handleChangeBloodGroup}
                                            value={valueBloodGroupCreatable == null ? valueBloodGroup : valueBloodGroupCreatable}


                                        />

                                        <span className='text-red w-100'>{errors?.blood_group?.message}</span>
                                    </div>

                                    <div className="col-sm-3 mt-2">
                                        <label >Re-Mark</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control"

                                                placeholder={'Re-Mark'}


                                                {...register('remark', { required: { value: false, message: "Field is required" } })}
                                            //  {...register("document_number", { required: { value: true, message: "Field is required" } })} 
                                            />
                                            <div className="input-group-append" >
                                                <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                /></span>

                                            </div>
                                        </div>
                                        <span className='text-red w-100'>{errors?.remark?.message}</span>
                                    </div>







                                </div>
                            </div>
                        </div>
                        {/* <div className="row d-flex  ">
                            <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                <div className="row d-flex ">
                                    
                                </div>
                            </div>
                        </div> */}

                        <div className="row d-flex  ">
                            <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                <div className="row d-flex ">
                                    {/* <div className="col-lg-12 col-md-12 col-sm-12 text-lg-start text-md-center text-sm-center ">
                                        
                                    </div> */}
                                    <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center " style={{display:'flex',justifyContent:'space-between'}}>
                                        <div>
                                            {currentPath === '/addCustomerDetails' ?
                                                <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "#fd7e14", background: "white", color: "#fd7e14" }} onClick={() => setCurrentStep(currentStep + 1)}>Skip</button>
                                                : null}

                                            {currentPath === '/addCustomerDetails' ?
                                                <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => setCurrentStep(currentStep - 1)}>Back</button>
                                                : null}
                                        </div>
                                        <div>

                                            <button
                                                type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                onClick={handleSubmit(onSubmit)}
                                            >Submit</button>
                                            <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>
                                            <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetail")}>Cancel</button>
                                        </div>

                                        {/* <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetailGrade")}>Back</button> */}

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
            {/* </div> */}
            {/* </section> */}

            {/* Edit Modal HTML */}
            <Modal
                show={deleteConfirm}
                onHide={() => setDeleteConfirm(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete {""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {" "}
                        <span className="text-center">Sure you want to delete it?</span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setDeleteConfirm(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={handleMultipleDeleted}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Deleteforce
                key={renderKey}
                handleDeletedforce={handleDeletedforce}
                reconfirmdelete={confirmforcemodal}
                masterName={""}
                closereconfirmdelete={closereconfirmdelete}
                referance={
                    deletesucess
                        ? deletedata
                        : ""
                }
                apiname={apiName}
            />
            <Deletesingle
                key={renderKey}
                confirmmodal={deleteConfirmsingle}
                confirmmodalclose={confirmmodalclose}
                masterName={""}
                confirmmodalsubmit={confirmmodalsubmit}
                apiname={apiName}
            />

            <SimpleInput_Edit key={renderKey}
                refetchForm={handleRefetch}
                open={OpenEditDropDownModel}
                setOpen={setOpenEditDropDownModel}
                id={editId}
                // superAccountId={superAccountId} 
                // optionsSuperAccount={optionsSuperAccount}
                // isSuperAccount={isSuperAccount}
                apiname={apiName} />
        </div>
        // </div>
    )
}

export default Add_Personal_Details