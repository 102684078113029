import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./dashboard.css"
import { Doughnut, Line,Pie } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
// import {  useDispatch, useSelector } from 'react-redux';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
  } from 'chart.js';
import Header from "../../components/Header/header";
// import { setStatus } from "../../redux/isDash";
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
  );



const Sales_Dashboard = () => {
 
      // const dispatch = useDispatch()
      // dispatch(setStatus(true))

     const options = {
        responsive: true,
        interaction: {
          mode: 'index' as const,
          intersect: false,
        },
        stacked: false,
        plugins: {
          title: {
            display: true,
            // text: 'Sales & Marketing',
          },
        },
        scales: {
          y: {
            type: 'linear' as const,
            display: true,
            position: 'left' as const,
          },
          y1: {
            type: 'linear' as const,
            display: true,
            position: 'right' as const,
            grid: {
              drawOnChartArea: false,
            },
          },
        },
      };

      const PieOptions:any = {
        
        // innerRadius: "10%",
        // responsive: true,
        cutout: 60,
        // cutoutPercentage: 60,
        // hoverOffset:40,
        elements: {
              
            center: {
              legend: { display: true, position: "right" },
              text: "Red is 2/3 the total numbers",
              color: "#FF6384", // Default is #000000
              fontStyle: "Arial", // Default is Arial
              sidePadding: 20, // Default is 20 (as a percentage)
              minFontSize: 20, // Default is 20 (in px), set to false and text will not wrap.
              lineHeight: 25, // Default is 25 (in px), used for when text wraps
            }
          },
        
      }
    //  const option = {
    //     responsive: true,
    //     interaction: {
    //       mode: 'index' as const,
    //       intersect: false,
    //     },
    //     stacked: false,
    //     plugins: {
    //       title: {
    //         display: true,
    //         text: 'Chart.js Line Chart - Multi Axis',
    //       },
    //     },
    //     scales: {
    //       y: {
    //         type: 'doughnut' as const,
    //         display: true,
    //         position: 'left' as const,
    //       },
    //       y1: {
    //         type: 'doughnut' as const,
    //         display: true,
    //         position: 'right' as const,
    //         grid: {
    //           drawOnChartArea: false,
    //         },
    //       },
    //     },
    //   };
      
      const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
      
       const data = {
        labels,
        datasets: [
          {
            label: 'Sales',
            data: labels.map(() => faker.datatype?.number({ min: -1000, max: 1000 })),
            // data:[1,2,3,4,5],
            borderColor: 'rgb(0, 54, 101)',
            backgroundColor: 'rgb(255,255,255)',
            yAxisID: 'y',
          },
          // {
          //   label: 'Marketing',
          //   data: labels.map(() => faker.datatype?.number({ min: -1000, max: 1000 })),
          //   // data:[1,2,3,4,5],
          //   borderColor: 'rgb(53, 162, 235)',
          //   backgroundColor: 'rgb(255,255,255)',
          //   yAxisID: 'y1',
            
          // },
        ],
      };


       const Piedata = {
        labels: ['India', 'US', 'Germany'],
        datasets: [
          {
            label: '# of Votes',
            data: [19, 12, 3],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
          },
        ],
      };


   

  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">




              {/* <div className="col-sm-6">
                <h4>Department</h4>
              </div> */}
              {/* <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to="/listColor"
                      className="text-decoration-none text-black"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      HR
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to="/listHrdepartment"
                      className="text-decoration-none text-black"
                    >
                      Department
                    </Link>
                  </li>
                  <li className="breadcrumb-item">Add Department</li>
                </ol>
              </div> */}
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">



            <body className="bg-default">
  <div className="main-content">
    <div className="header pt-3 pb-3 main">
      <div className="container-fluid">
        {/* <h2 className="mb-1 text-white">Stats Card</h2> */}
        <div className="header-body">
          <div className="row">
            <div className="col-xl-3 col-lg-6">
              <div className="card card-stats mb-4 mb-xl-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">Sales Revenue</h5>
                      <span className="h2 font-weight-bold mb-0 float-right">350,897</span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape main text-white rounded-circle shadow">
                        <i className="fas fa-chart-bar"></i>
                      </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    <span className="text-success mr-2"><i className="fa fa-arrow-up"></i> 3.48%</span>
                    <span className="text-nowrap">Since last month</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <div className="card card-stats mb-4 mb-xl-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">New Clients</h5>
                      <span className="h2 font-weight-bold mb-0 float-right">2,356</span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape main text-white rounded-circle shadow">
                        <i className="fas fa-chart-pie"></i>
                      </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    <span className="text-danger mr-2"><i className="fas fa-arrow-down"></i> 3.48%</span>
                    <span className="text-nowrap">Since last week</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <div className="card card-stats mb-4 mb-xl-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">Monthly Sales</h5>
                      <span className="h2 font-weight-bold mb-0 float-right">924</span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape main text-white rounded-circle shadow">
                        <i className="fas fa-users"></i>
                      </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    <span className="text-warning mr-2"><i className="fas fa-arrow-down"></i> 1.10%</span>
                    <span className="text-nowrap">Since yesterday</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <div className="card card-stats mb-4 mb-xl-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title text-uppercase text-muted mb-0">Performance</h5>
                      <span className="h2 font-weight-bold mb-0 float-right">49,65%</span>
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape main text-white rounded-circle shadow">
                        <i className="fas fa-percent"></i>
                      </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    <span className="text-success mr-2"><i className="fas fa-arrow-up"></i> 12%</span>
                    <span className="text-nowrap">Since last month</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>



              


      </div>
     
    </div>
   
  </div>
 

</body>

            

        
            </div>
            <div className="row">
  <div className="col-lg-8 col-md-auto col-sm-auto text-center">
  {/* <select className="custom-select" id="gender2">
          <option selected>Year</option>
          <option value="1">Month</option>
          <option value="2">Today</option>
        </select>    */}
    {/* <h2>Quaterly Sales</h2> */}
  <Line options={options} data={data} height={100} />
  </div>
  <div className="col-lg-4 col-md-auto col-sm-auto text-center">
    {/* <h2>Income And Expenses</h2> */}
  <Doughnut options={PieOptions} data={Piedata}  className="Doughnut"/>
  {/* <Pie data={Piedata}  height={10}/> */}
  </div>
</div>


                </div>
        </section>

       


      </div>
    </div>
  );
};

export default Sales_Dashboard;
