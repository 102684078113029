import React, { Ref, useEffect, useRef } from "react";
import ColumnVisibility from "../../../../Table Component/ColumnVisibility";
import More from "../../../../Table Component/More_Export/More";
import { TbFilterOff } from "react-icons/tb";
import { FiFilter } from "react-icons/fi";
import { BiReset } from "react-icons/bi";
import GlobalSearch from "../../../../Table Component/GlobalSearch";
import Restricted from "../../../../../Roles/Restricted";

export interface IHeaders {
    apiname?: any,
    titleofheaders?: any,
    columnVisibility?: any,
    NewColumnOrder?: any,
    setNewColumnOrder?: any
    tableInstance?: any,
    handleColumnVisibility?: () => void,
    handleRefresh?: (e?:any) => void,
    handleSearch?: (e?:any) => void,
    excelBody?: Object[],
    excelHeader?: [],
    setShow?: any
    setshowmessage?: any,
    headertitles?: any,
    handleConfirmDelete?: () => void
    handleFilter?: () => void
    filter?: any,
    setColumnVisibility?: any
    addButtonPageName:any
    addButtonName:any
    permissionClass:any
    refetch?:any
    isMore:any
    isAddButton:any
    setNewAdded?:any

  }


const Header = ({   apiname,
  permissionClass,
    titleofheaders,
    columnVisibility,
    NewColumnOrder,
    setNewColumnOrder,
    tableInstance,
    handleColumnVisibility,
    handleRefresh,
    handleSearch,
    excelBody,
    excelHeader,
    setShow,
    setshowmessage,
    headertitles,
    handleConfirmDelete,
    handleFilter,
    setColumnVisibility,
    filter,
    addButtonPageName,
    addButtonName,
    isMore=true,
    isAddButton,
    setNewAdded,
  refetch}:IHeaders) => {

        const menu: any = useRef();

        useEffect(() => {
          let handler = (e: any) => {
            if (!menu.current?.contains(e?.target)) {
              setColumnVisibility(false);
            }
          };
          document.addEventListener("mousedown", handler);
          return () => {
            document.removeEventListener("mousedown", handler);
          };
        });


        

  return (
    <>
      <div className="table-title">
        <div className="row ">
          <div className="col-sm-12 col-md-6 d-flex justify-content-md-end justify-content-lg-start justify-content-sm-center py-1">
          <Restricted
             to={`${permissionClass?.front}.view_${permissionClass?.back}`}
              fallback={""}
            >
            <div ref={menu}>
              <ColumnVisibility
                pagecolumn={apiname}
                columns={titleofheaders}
                columnVisibility={columnVisibility}
                NewColumnOrder={NewColumnOrder}
                setNewColumnOrder={setNewColumnOrder}
                instance={{ ...tableInstance }}
                handleColumnVisibility={handleColumnVisibility}
              />{" "}
            </div>
            </Restricted>
{isMore&&

<>
            <Restricted
             to={`${permissionClass?.front}.view_${permissionClass?.back}`}
              fallback={""}
            >
            <More
              setShow={setShow}
              excelBody={excelBody}
              setshowmessage={setshowmessage}
              Excelfilename={`${apiname}_list`}
              headertitles={headertitles}
              excelHeader={excelHeader}
              handleConfirmDelete={handleConfirmDelete}
            />
            </Restricted>
            </>
  }
            <Restricted
              to={`${permissionClass?.front}.view_${permissionClass?.back}`}
              fallback={""}
            >
            <button
            type="button"
              className="btn btn-primary rounded-3"
              onClick={handleFilter}
            >
              {filter ? (
                <TbFilterOff size={"18px"} />
              ) : (
                <FiFilter size={"18px"} />
              )}
            </button>
            </Restricted> 

            <Restricted
              to={`${permissionClass?.front}.view_${permissionClass?.back}`}
              fallback={""}
            >
            <button
             type="button"
              className="btn btn-primary rounded-3 text-white"
              onClick={handleRefresh}
            >
              <BiReset style={{ color: "white" }} size={25} />
            </button>
            </Restricted>
          </div>

          <GlobalSearch
            apiname={apiname}
            pageName={`add${addButtonPageName}`}
            handleSearch={handleSearch}
            buttonName={addButtonName}
            permissionClass={permissionClass}
            isAddButton={isAddButton}
            setNewAdded={setNewAdded}
          />
        </div>
      </div>
    </>
  );
};

export default Header;
