import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";

import { BiCheck } from "react-icons/bi";
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';

export const Status = ({ row, update, refetch_f, isSuccess, apiname }: any) => {
  const [ask, setAsk] = useState<any>(null);
  // const [openEditModel, setOpenEditModel] = useState(false)


  const handleSwitchStatus = async (e: any) => {
    var temp_data = {
      status: e.target.value == "active" ? "inactive" : "active" ,
    };

    const temp = await update(
      { id: e.target.id, data: temp_data, apiname: apiname },
      true
    );

    if (temp.status === 200) {
      refetch_f();
      setAsk(null);
    }
  };

  return (
   
    <div>
      {ask != null ? (
        <>
          {/* <BiCheck
            size={"24px"}
            onClick={() => {
              handleSwitchStatus(ask);
            }}
          />
          <RxCross2
            size={"24px"}
            onClick={() => {
              setAsk(null);
            }}
          /> */}


<Modal show={ask} onHide={() => setAsk(null)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Status Update Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                Are you sure you want to change the status?
                </Modal.Body>
                <Modal.Footer>



                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setAsk(null) }}>No</button>


                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={() => {
                          handleSwitchStatus(ask);
                        }}
                    >Confirm</button>

                </Modal.Footer>
            </Modal>
        </>
        
      ) : (
        <>
          <input
            type="checkbox"
            id={row.original.id}
            alt={row.original.status == "active" ? "on" : "off"}
            onChange={(e) => {
              setAsk(e);
            }}
            value={row.original.status}
          />

          <label
            htmlFor={row.original.id}
            data-on-label={
              row.original.status == "active" ? "Inactive" : "Active"
            }
            data-off-label={
              row.original.status == "active" ? "Active" : "Inactive"
            }
          ></label>
        </>
      )}






    </div>
   
  );
};
