
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import Actions from "../../components/Cell/Actions";
// import { updateBrand, useFetchBrands } from "../../hooks/brand";
import noImage from "../../images/noImage.png"
import { useFetchGst, useFetchProductType, useUpdateGst, useUpdateProductType } from "../../hooks/GST";
import { Update, useFetch } from "../../hooks/Api";
import Modal from "react-bootstrap/esm/Modal";


interface Data {
  selection: any
  name:any
  point_open_time:any
  point_close_time:any
  created_at:any
  created_by:any
  updated_by:any
  updated_at:any
  status: any;
  actions: any;
}

export const titleofheaders=[
  {
    id: `srno`,
    Header: "Sr No",
  },

  {
    id: 'name',
    Header: "Task Name",
  },
  
 
  {
    id: 'created_at',
    Header: 'Created at',
    
  },


  {
    id: 'created_by',
    Header: 'Created By',
    

 
  },
  {
    id: 'updated_at',
    Header: 'Updated At',
    
  },
  {
    id: 'updated_by',
    Header: 'Updated By',
    
  },

  {
    id: 'Actions',
    Header: 'Actions',

  
  },
]



export const Columns_Task_List: any = [



  
  {
    id: `srno`,
    Header: () => <div style={{display:"inline"}}>Sr<br/>No</div>,
    sticky:"left",
  },
  


  

      {
        id: 'name',
        Header: () => <div style={{ display: "inline" }}>Task<br />Name</div>,
        sticky:"left",
        accessor: 'name',
        Cell: ({ row }: any) => (row.original?.name)
      },
      {
        id: 'Actions_Yes_No',
        Header: 'Actions',
      
      },
    
      {
        id: 'point_open_time',
        Header: () => <div style={{ display: "inline" }}>Point<br />Open Time</div>,
        accessor: 'point_open_time',
        Cell: ({ row }: any) => (row.original?.point_open_time)
      },
      {
        id: 'point_start_date',
        Header: () => <div style={{ display: "inline" }}>Point<br />Start Date</div>,
        accessor: 'point_start_date',
        Cell: ({ row }: any) => (row.original?.point_start_date)
      },
      {
        id: 'point_close_time',
        Header: () => <div style={{ display: "inline" }}>Point<br />Close Time</div>,
        accessor: 'point_close_time',
        Cell: ({ row }: any) => (row.original?.point_close_time)
      },
      {
        id: 'point_end_date',
        Header: () => <div style={{ display: "inline" }}>Point<br />End Date</div>,
        accessor: 'point_end_date',
        Cell: ({ row }: any) => (row.original?.point_end_date)
      },
      {
        id: 'created_at',
        Header: () => <div style={{ display: "inline" }}>Created<br />At</div>,
        accessor: 'created_at',
        Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
      },
    
    
      {
        id: 'created_by',
        Header: () => <div style={{ display: "inline" }}>Created<br />By</div>,
        accessor: 'created_by',
        Cell: ({ row }: any) => row.original.created_by?.username
    
    
      },
      {
        id: 'updated_at',
        Header: () => <div style={{ display: "inline" }}>Updated<br />At</div>,
        accessor: 'updated_at',
        Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
      },
      {
        id: 'updated_by',
        Header: () => <div style={{ display: "inline" }}>Updated<br />By</div>,
        accessor: 'updated_by',
        Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
      },



  
]



