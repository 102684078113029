import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../../images/Export/Camera.png";
import pdfLogo from "../../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useBrandEdit, useUpdateBrand } from "../../../hooks/brand";
import { Controller, useForm } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import { RiPencilLine } from "react-icons/ri";
import { useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes, useCreate } from "../../../hooks/Api";
import useFocusOnEnter from "../../../components/focus";
import { Deletesinglewithdependancy } from "../../../components/modal";
import 'bootstrap/dist/css/bootstrap.min.css';
//    import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import Select from 'react-select'
import { Account_Model } from "../../../components/Dynamic_Form_Models/Account_Model";

import { DatePicker, Space, TimePicker } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { useFetchAccountDropDown, useFetchAdministrationTypeDropDown, 
    useFetchCityDropDown, useFetchEmployeeDropDown, useFetchGradeDropDown, useFetchOrganizationTypeDropDown, 
    useFetchPersonOccupationDropDown, useFetchPersonPurchaseTypeDropDown, 
    useFetchPersonSalesStatusDropDown, useFetchTitleDropDown, useFetchTypeOfBusinessDropDown, useFetchWeeklyOffDropDown } from "../../../hooks/DropDown_Api";
import { type } from "os";
import "react-datepicker/dist/react-datepicker.css";
import DatePickerReact from "react-datepicker";
import { FaCalendarAlt } from "react-icons/fa";
import CreatableSelect from 'react-select/creatable';
import Button from "react-bootstrap/esm/Button";
import { AiOutlinePlus } from "react-icons/ai";
import { Upload } from "antd";
import type { UploadFile } from 'antd/es/upload/interface';
import { Department_Model } from "../../../components/Dynamic_Form_Models/Department_Model";
import { City_Model } from "../../../components/Dynamic_Form_Models/City_Model";
import { Designation_Model } from "../../../components/Dynamic_Form_Models/Designation";

import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'

import Modal from "react-bootstrap/Modal";
import Actions, { Deleteforce, Deletesingle } from "../../../components/Cell/Actions";
import { useDelete, useDeletemforce } from "../../../hooks/delete";
import Custom_Actions_Buttons from "../../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../../hooks/deletemultiple";
import { SimpleInput_Edit } from "../../../components/Dynamic_Form_Models/SimpleInput_Edit";
import Axiosinstance from "../../../hooks/Interceptor";
import Mui_Toaster from "../../../components/Mui_Toaster/Mui_Toaster";

interface Option {
    readonly label: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    name: label,
    status: "active",
});

const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}


const Edit_Basic_Customer_Details = ({ setCurrentStep, currentStep,setDataForBasicDetails }: any) => {
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    // const { id } = useParams()

    let rawData = localStorage.getItem('Add Data');
    let addData = rawData ? JSON.parse(rawData) : null;

    const id = addData?.id
    const tempData = addData
    const data = tempData






    const navigate = useNavigate()

    const [logo, setlogo] = useState(null)
    const [logoPdf, setlogoPdf] = useState(null)
    const [agreement, setAgreement] = useState(null)
    const [openEditModel, setOpenEditModel] = useState(false)
    const [expirationDate, setExpirationDate] = useState()
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [Married, setMarried] = useState("Single")
    const [valuesofform, setValuesofform] = useState<any>()

    const [openCity, setOpenCity]: any = useState(false)
    const [open, setOpen] = useState(false)
    const [openDesignation, setOpenDesignation] = useState(false)

    const [submitted, setSubmitted] = useState(false)
    const [submittedDesignation, setSubmittedDesignation] = useState(false)

    const [openAccount, setOpenAccount]: any = useState(false)

    const apiname = process.env.REACT_APP_EMPLOYEE_API?.toString()

    // const { data } = useFetchSingle(id, apiname)




    const [EmployeeId, setEmployeeId] = useState("no")
    const onMarriedChange = (e: any) => {
        setMarried(e.target.value)
    }
    const onEmployeeChange = (e: any) => {
        setEmployeeId(e.target.value)
        setValue("employee_status", e.target.value)
    }
    const onLogoChange = (e: any) => {
        setlogo(e.target.files[0].name)
    }

    const onLogoPDFChange = (e: any) => {
        setlogoPdf(e.target.files[0].name)
    }

    const onAgreementChange = (e: any) => {
        setAgreement(e.target.files[0].name)
    }

    const { mutate, isSuccess, isError, error, data: brandUpdated_response }: any = useUpdateSingle()
    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()

    const { register, control, handleSubmit, setError, formState: { errors, isDirty }, reset, setValue, getValues }: any = useForm();


    const prevDataRef = useRef();

    useEffect(() => {
        const isDataChanged = JSON.stringify(data) !== JSON.stringify(prevDataRef.current);
        if (data && isDataChanged) {
           
            reset(data);
            prevDataRef.current = data;
        }
    }, [data, reset]);

    

    // useEffect(() => {
    //     const isDataChanged = JSON.stringify(data) !== JSON.stringify(prevDataRef.current);
    //     if (data && isDataChanged) {
    //         reset(data);
    //         prevDataRef.current = data;
    //     }
    // }, [data,reset]);



    const [optionsAccount, setOptionsAccount]: any = useState([])
    const [optionsDepartment, setOptionsDepartment]: any = useState([])
    const [selDepartment, setSelDepartment] = useState('')
    const [selectedDepartment, setSelectedDepartment]: any = useState('')
    const [selectedAccount, setSelectedAccount]: any = useState('')
    const [selAccount, setSelAccount]: any = useState('')

    const [selDesignation, setSelDesignation] = useState('')
    const [selectedDesignation, setSelectedDesignation]: any = useState('')
    const [age, setAge]: any = useState(0)


    
    const [optionsEmployee, setOptionsEmployee] = useState([])
    const [EmployeeValue, setEmployeeValue]: any = useState()
    const [selEmployee, setSelEmployee]: any = useState('')
    const [selectedEmployee, setSelectedEmployee]: any = useState('')
    const [showOtherRef, setShowOtherRef]: any = useState(false)

    const [selRefrence, setSelRefrence]: any = useState('')
    const [selectedRefrence, setSelectedRefrence]: any = useState('')
    const [RefrenceValue, setRefrenceValue]: any = useState()
    const [optionsRefrence, setOptionsRefrence] = useState([])

    const { data: EmployeeData, refetch: EmployeeRefetch } = useFetchEmployeeDropDown()


    useEffect(() => {
        setOptionsEmployee(EmployeeData)
    }, [EmployeeData])

    useEffect(() => {
        if (EmployeeData) {
            let temp_employee_data: any = EmployeeData?.map((e: any) => e)
            temp_employee_data?.unshift({ label: 'Other', value: 'other' })
            setOptionsRefrence(temp_employee_data)
        }
    }, [EmployeeData])


    const handleChangeRefrence = (e: any) => {
        if (e.value === 'other') {
            setSelRefrence(e)
            setRefrenceValue(e)
            setShowOtherRef(true)
            setValue("reference", '')
        }
        else {
            setSelRefrence(e)
            setRefrenceValue(e)
            setShowOtherRef(false)
            setValue('other_reference', '')
            setValue("reference", `${e?.value}`)
        }
    }


    const handleChangeEmployee = (e: any) => {
        setEmployeeValue(e)
        setSelEmployee(e)
        setValue("customer_from_party_name", `${e?.value}`)
    }

    const { data: accountData, refetch: accountRefetch } = useFetchAccountDropDown()

    useEffect(() => {
        setOptionsAccount(accountData)
    }, [accountData])



    const handleChangeAccount = (e: any) => {
        setValue("group", e?.value)
        setSelAccount(e)
        // setForDepartmentValue({ label: e?.label, value: e?.value })
    }



    const [forCityValue, setForCityValue]: any = useState([])
    const [cityOptions, setCityOptions]: any = useState([])
    const [selCity, setSelCity]: any = useState('')
    const [selectedCity, setSelectedCity]: any = useState('')

    const { data: cityData, refetch: cityRefetch } = useFetchCityDropDown()

    // useEffect(() => {
    //     setCityOptions(cityData)
    // }, [cityData])

    useEffect(() => {
        let temp = cityData?.map((e: any) => {
            return (
                {
                    value: e?.value,
                    label: `${e?.city ? e?.city : e?.village}::${e?.district}`,

                }
            )
        })
        setCityOptions(temp)
    }, [cityData])
    const handleChangeCity = (e: any) => {
        setForCityValue(e)
        setSelCity(e)
        setValue("city_name_and_distict_name", e?.value)

        // setValue("state",e?.stateID)
        // setValue("country",e?.countryID)
        // setValue("district",e?.districtID)


    }




    const optionsGrade = [
        {
            value: 1,
            label: "A"
        },
        {
            value: 2,
            label: "B"
        },
        {
            value: 3,
            label: "C"
        },
        {
            value: 4,
            label: "D"
        },
        {
            value: 5,
            label: "E"
        },

    ]
    const [forGradeValue, setForGradeValue]: any = useState([])
    const [selectedGrade, setselectedGrade]: any = useState('')

    const handleChangeGrade = (e: any) => {
        setForGradeValue(e)
        setValue("customer_grade", e?.label)
    }


    // const [forGradeValue, setForGradeValue]: any = useState([])

    // const [optionsGrade, setoptionsGrade]: any = useState([])
    // const [selectedGrade, setselectedGrade]: any = useState('')
    // const [selGrade, setselGrade]: any = useState('')

    // const { data: gradeData, refetch: gradeRefetch } = useFetchGradeDropDown()

    // useEffect(() => {
    //     setoptionsGrade(gradeData)
    // }, [gradeData])



    // const handleChangeGrade = (e: any) => {
    //     setValue("customer_grade", e?.value)
    //     setForGradeValue(e)
    //     setselGrade(e)
    // }



    




    
    const apinameOrganizationType = process.env.REACT_APP_CUSTOMER_ORGANIZATION_TYPE_API?.toString()
    const { mutate: mutateOrganizationType, data: dataOrganizationType, isError: isErrorOrganizationType, isLoading: isLoadingOrganizationType, isSuccess: isSuccessOrganizationType, error: errorOrganizationType }: any = useCreate(apinameOrganizationType)
    const [optionsOrganizationType, setOptionsOrganizationType]: any = useState([]);
    const [valueOrganizationType, setValueOrganizationType]: any = useState();
    const [selectedOrganizationType, setSelectedOrganizationType]: any = useState('');
    const [valueOrganizationTypeCreatable, setValueOrganizationTypeCreatable] = useState<Option | null>();
    const { data: OrganizationTypeData, refetch: OrganizationTypeRefetch } = useFetchOrganizationTypeDropDown()
    useEffect(() => {
        setOptionsOrganizationType(OrganizationTypeData)
    }, [OrganizationTypeData])

    const handleCreateOrganizationType = async (inputValue: string) => {
        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setValue("name", `${newOption?.value}`)
            mutateOrganizationType({ name: newOption.name, status: newOption.status })
            setOptionsOrganizationType((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValueOrganizationTypeCreatable(newOption);
        }, 0);

    };

    const handleChangeOrganizationType = (e: any) => {

        OrganizationTypeRefetch()
        setApiName(apinameOrganizationType)
        setDeleteApiName(apinameOrganizationType)
        setEditId(e?.value)
        setEditData(OrganizationTypeData)
        setRenderKey(prevKey => prevKey + 1);
        setValueOrganizationType(e)
        setValue("customer_organization_type", `${e?.value}`)
        setValueOrganizationTypeCreatable(null)
    }

    useEffect(() => {
        (async () => {
            if (isSuccessOrganizationType && optionsOrganizationType && valueOrganizationTypeCreatable != null) {
                await OrganizationTypeRefetch();
                setValue("customer_organization_type", OrganizationTypeData[0]?.value)
            }
        })();
        return () => {
        };
    }, [isSuccessOrganizationType, OrganizationTypeData]);


    const apinameTypeOfBusiness = process.env.REACT_APP_CUSTOMER_BUSINESS_TYPE_API?.toString()
    const { mutate: mutateTypeOfBusiness, data: dataTypeOfBusiness, isError: isErrorTypeOfBusiness, isLoading: isLoadingTypeOfBusiness, isSuccess: isSuccessTypeOfBusiness, error: errorTypeOfBusiness }: any = useCreate(apinameTypeOfBusiness)
    const [optionsTypeOfBusiness, setOptionsTypeOfBusiness]: any = useState([]);
    const [valueTypeOfBusiness, setValueTypeOfBusiness]: any = useState();
    const [selectedTypeOfBusiness, setSelectedTypeOfBusiness]: any = useState('');
    const [valueTypeOfBusinessCreatable, setValueTypeOfBusinessCreatable] = useState<Option | null>();
    const { data: TypeOfBusinessData, refetch: TypeOfBusinessRefetch } = useFetchTypeOfBusinessDropDown()
    useEffect(() => {
        setOptionsTypeOfBusiness(TypeOfBusinessData)
    }, [TypeOfBusinessData])

    const handleCreateTypeOfBusiness = async (inputValue: string) => {
        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setValue("name", `${newOption?.value}`)
            mutateTypeOfBusiness({ name: newOption.name, status: newOption.status })
            setOptionsTypeOfBusiness((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValueTypeOfBusinessCreatable(newOption);
        }, 0);

    };

    const handleChangeTypeOfBusiness = (e: any) => {

        TypeOfBusinessRefetch()
        setApiName(apinameTypeOfBusiness)
        setDeleteApiName(apinameTypeOfBusiness)
        setEditId(e?.value)
        setEditData(TypeOfBusinessData)
        setRenderKey(prevKey => prevKey + 1);
        setValueTypeOfBusiness(e)
        setValue("type_of_business", `${e?.value}`)
        setValueTypeOfBusinessCreatable(null)
    }

    useEffect(() => {
        (async () => {
            if (isSuccessTypeOfBusiness && optionsTypeOfBusiness && valueTypeOfBusinessCreatable != null) {
                await TypeOfBusinessRefetch();
                setValue("type_of_business", TypeOfBusinessData[0]?.value)
            }
        })();
        return () => {
        };
    }, [isSuccessTypeOfBusiness, TypeOfBusinessData]);


    const apinamePersonOccupation = process.env.REACT_APP_CUSTOMER_OCCUPATION_API?.toString()
    const { mutate: mutatePersonOccupation, data: dataPersonOccupation, isError: isErrorPersonOccupation, isLoading: isLoadingPersonOccupation, isSuccess: isSuccessPersonOccupation, error: errorPersonOccupation }: any = useCreate(apinamePersonOccupation)
    const [optionsPersonOccupation, setOptionsPersonOccupation]: any = useState([]);
    const [valuePersonOccupation, setValuePersonOccupation]: any = useState();
    const [selectedPersonOccupation, setSelectedPersonOccupation]: any = useState('');
    const [valuePersonOccupationCreatable, setValuePersonOccupationCreatable] = useState<Option | null>();
    const { data: PersonOccupationData, refetch: PersonOccupationRefetch } = useFetchPersonOccupationDropDown()
    useEffect(() => {
        setOptionsPersonOccupation(PersonOccupationData)
    }, [PersonOccupationData])

    const handleCreatePersonOccupation = async (inputValue: string) => {
        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setValue("name", `${newOption?.value}`)
            mutatePersonOccupation({ name: newOption.name, status: newOption.status })
            setOptionsPersonOccupation((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValuePersonOccupationCreatable(newOption);
        }, 0);

    };

    const handleChangePersonOccupation = (e: any) => {

        PersonOccupationRefetch()
        setApiName(apinamePersonOccupation)
        setDeleteApiName(apinamePersonOccupation)
        setEditId(e?.value)
        setEditData(PersonOccupationData)
        setRenderKey(prevKey => prevKey + 1);
        setValuePersonOccupation(e)
        setValue("occupation", `${e?.value}`)
        setValuePersonOccupationCreatable(null)
    }

    useEffect(() => {
        (async () => {
            if (isSuccessPersonOccupation && optionsPersonOccupation && valuePersonOccupationCreatable != null) {
                await PersonOccupationRefetch();
                setValue("occupation", PersonOccupationData[0]?.value)
            }
        })();
        return () => {
        };
    }, [isSuccessPersonOccupation, PersonOccupationData]);


    const apinamePersonSalesStatus = process.env.REACT_APP_CUSTOMER_SALES_STATUS_API?.toString()
    const { mutate: mutatePersonSalesStatus, data: dataPersonSalesStatus, isError: isErrorPersonSalesStatus, isLoading: isLoadingPersonSalesStatus, isSuccess: isSuccessPersonSalesStatus, error: errorPersonSalesStatus }: any = useCreate(apinamePersonSalesStatus)
    const [optionsPersonSalesStatus, setOptionsPersonSalesStatus]: any = useState([]);
    const [valuePersonSalesStatus, setValuePersonSalesStatus]: any = useState();
    const [selectedPersonSalesStatus, setSelectedPersonSalesStatus]: any = useState('');
    const [valuePersonSalesStatusCreatable, setValuePersonSalesStatusCreatable] = useState<Option | null>();
    const { data: PersonSalesStatusData, refetch: PersonSalesStatusRefetch } = useFetchPersonSalesStatusDropDown()
    useEffect(() => {
        setOptionsPersonSalesStatus(PersonSalesStatusData)
    }, [PersonSalesStatusData])

    const handleCreatePersonSalesStatus = async (inputValue: string) => {
        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setValue("name", `${newOption?.value}`)
            mutatePersonSalesStatus({ name: newOption.name, status: newOption.status })
            setOptionsPersonSalesStatus((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValuePersonSalesStatusCreatable(newOption);
        }, 0);

    };

    const handleChangePersonSalesStatus = (e: any) => {

        PersonSalesStatusRefetch()
        setApiName(apinamePersonSalesStatus)
        setDeleteApiName(apinamePersonSalesStatus)
        setEditId(e?.value)
        setEditData(PersonSalesStatusData)
        setRenderKey(prevKey => prevKey + 1);
        setValuePersonSalesStatus(e)
        setValue("customer_sales_status", `${e?.value}`)
        setValuePersonSalesStatusCreatable(null)
    }

    useEffect(() => {
        (async () => {
            if (isSuccessPersonSalesStatus && optionsPersonSalesStatus && valuePersonSalesStatusCreatable != null) {
                await PersonSalesStatusRefetch();
                setValue("customer_sales_status", PersonSalesStatusData[0]?.value)
            }
        })();
        return () => {
        };
    }, [isSuccessPersonSalesStatus, PersonSalesStatusData]);



    const apinamePersonPurchaseType = process.env.REACT_APP_CUSTOMER_BUYING_TYPE_API?.toString()
    const { mutate: mutatePersonPurchaseType, data: dataPersonPurchaseType, isError: isErrorPersonPurchaseType, isLoading: isLoadingPersonPurchaseType, isSuccess: isSuccessPersonPurchaseType, error: errorPersonPurchaseType }: any = useCreate(apinamePersonPurchaseType)
    const [optionsPersonPurchaseType, setOptionsPersonPurchaseType]: any = useState([]);
    const [valuePersonPurchaseType, setValuePersonPurchaseType]: any = useState();
    const [selectedPersonPurchaseType, setSelectedPersonPurchaseType]: any = useState();
    const [valuePersonPurchaseTypeCreatable, setValuePersonPurchaseTypeCreatable] = useState<Option | null>();
    const { data: PersonPurchaseTypeData, refetch: PersonPurchaseTypeRefetch } = useFetchPersonPurchaseTypeDropDown()
    useEffect(() => {
        setOptionsPersonPurchaseType(PersonPurchaseTypeData)
    }, [PersonPurchaseTypeData])

    const handleCreatePersonPurchaseType = async (inputValue: string) => {
        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setValue("name", `${newOption?.value}`)
            mutatePersonPurchaseType({ name: newOption.name, status: newOption.status })
            setOptionsPersonPurchaseType((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValuePersonPurchaseTypeCreatable(newOption);
        }, 0);

    };

    const handleChangePersonPurchaseType = (e: any) => {

        PersonPurchaseTypeRefetch()
        setApiName(apinamePersonPurchaseType)
        setDeleteApiName(apinamePersonPurchaseType)
        setEditId(e?.value)
        setEditData(PersonPurchaseTypeData)
        setRenderKey(prevKey => prevKey + 1);
        setValuePersonPurchaseType(e)
        setValue("customer_buying_type", `${e?.value}`)
        setValuePersonPurchaseTypeCreatable(null)
    }

    useEffect(() => {
        (async () => {
            if (isSuccessPersonPurchaseType && optionsPersonPurchaseType && valuePersonPurchaseTypeCreatable != null) {
                await PersonPurchaseTypeRefetch();
                setValue("customer_buying_type", PersonPurchaseTypeData[0]?.value)
            }
        })();
        return () => {
        };
    }, [isSuccessPersonPurchaseType, PersonPurchaseTypeData]);


    const apinameForWeeklyOff = process.env.REACT_APP_EMPLOYEE_WEEKLY_OFF_API?.toString()
    const { mutate: mutateWeeklyOff, data: dataWeeklyOff, isError: WeeklyOffIsError, isLoading: WeeklyOffLoading, isSuccess: WeeklyOffSuccess, error: WeeklyOffError }: any = useCreate(apinameForWeeklyOff)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueWeeklyOff, setValueWeeklyOff]: any = useState();
    const [selectedWeeklyOff, setSelectedWeeklyOff]: any = useState();
    const [optionsWeeklyOff, setOptionsWeeklyOff]: any = useState([]);
    const [valueWeeklyOffCreatable, setValueWeeklyOffCreatable] = useState<Option | null>();

    const { data: WeeklyOffData, refetch: WeeklyOffRefetch } = useFetchWeeklyOffDropDown()

    useEffect(() => {
        setOptionsWeeklyOff(WeeklyOffData)
    }, [WeeklyOffData])





    const handleChangeWeeklyOff = (e: any) => {

        WeeklyOffRefetch()

        setApiName(apinameForWeeklyOff)
        setDeleteApiName(apinameForWeeklyOff)
        setEditId(e?.value)
        setEditData(WeeklyOffData)
        setRenderKey(prevKey => prevKey + 1);

        setValueWeeklyOff(e)

        setValue("weekly_off", `${e?.value}`)
        setValueWeeklyOffCreatable(null)
    }





    const handleCreateWeeklyOff = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutateWeeklyOff({ name: newOption.name, status: newOption.status })
            setOptionsWeeklyOff((prev: any) => [...prev, newOption]);
            setValueWeeklyOffCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (WeeklyOffSuccess && optionsWeeklyOff) {
                await WeeklyOffRefetch();
                setValue("weekly_off", WeeklyOffData[0]?.value)
            }

        })();

        return () => {
        };
    }, [WeeklyOffSuccess, WeeklyOffData]);

    const apinameForAdministrationType = process.env.REACT_APP_EMPLOYEE_ADMINISTRATION_API?.toString()
    const { mutate: mutateAdministrationType, data: dataAdministrationType, isError: AdministrationTypeIsError, isLoading: AdministrationTypeLoading, isSuccess: AdministrationTypeSuccess, error: AdministrationTypeError }: any = useCreate(apinameForAdministrationType)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueAdministrationType, setValueAdministrationType]: any = useState();
    const [selectedAdministrationType, setSelectedAdministrationType]: any = useState('');
    const [optionsAdministrationType, setOptionsAdministrationType]: any = useState([]);
    const [valueAdministrationTypeCreatable, setValueAdministrationTypeCreatable] = useState<Option | null>();

    const { data: AdministrationTypeData, refetch: AdministrationTypeRefetch } = useFetchAdministrationTypeDropDown()

    useEffect(() => {
        setOptionsAdministrationType(AdministrationTypeData)
    }, [AdministrationTypeData])





    const handleChangeAdministrationType = (e: any) => {

        AdministrationTypeRefetch()

        setApiName(apinameForAdministrationType)
        setDeleteApiName(apinameForAdministrationType)
        setEditId(e?.value)
        setEditData(AdministrationTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueAdministrationType(e)

        setValue("administration_type", `${e?.value}`)
        setValueAdministrationTypeCreatable(null)
    }





    const handleCreateAdministrationType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutateAdministrationType({ name: newOption.name, status: newOption.status })
            setOptionsAdministrationType((prev: any) => [...prev, newOption]);
            setValueAdministrationTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (AdministrationTypeSuccess && optionsAdministrationType) {
                await AdministrationTypeRefetch();
                setValue("administration_type", AdministrationTypeData[0]?.value)
            }

        })();

        return () => {
        };
    }, [AdministrationTypeSuccess, AdministrationTypeData]);




    const [JoiningDate, setJoiningDate]: any = useState("")
    var Joining_Date = data?.machine_manufacturer_date;
    var final_Joining_Date = moment(Joining_Date, "DD-MM-YYYY").add(1, 'd').toISOString();
    let dateOne = data?.joining_date
    let fromJoiningValue = dateOne

    // const[birthDate,setBirthDate]:any = useState("")
    var Birth_Date = data?.machine_manufacturer_date;
    var final_Birth_Date = moment(Birth_Date, "DD-MM-YYYY").add(1, 'd').toISOString();
    let dateTwo = data?.joining_date
    let fromBirthValue = dateTwo


    var Anniversary_Date = data?.machine_manufacturer_date;
    var final_Anniversary_Date = moment(Anniversary_Date, "DD-MM-YYYY").add(1, 'd').toISOString();
    let dateThree = data?.anniversary_date
    let fromAnniversaryValue = dateThree

    console.log('data', data)

    console.log(data?.salary_type, "data?.salary_type");
    useEffect(() => {
        // const isDataChanged = JSON.stringify(data) !== JSON.stringify(prevDataRef.current);


        if (data) {

            

            reset(data)
            setMarried(data?.marital_status)

            if (data?.age) {
                setAge(data?.age)
                setValue("age", data?.age)
            }

            // setSelectedAccount(data?.group?.id)
            // setValue("group", data?.group?.id)
            // setSelectedCity(data?.city_name_and_distict_name?.id)
            // setValue("city_name_and_distict_name", data?.city_name_and_distict_name?.id)
            setSelectedWeeklyOff(data?.weekly_off?.id)
            setValue("weekly_off", data?.weekly_off?.id)
            setSelectedAdministrationType(data?.administration_type?.id)
            setValue("administration_type", data?.administration_type?.id)
      

            setSelectedOrganizationType(data?.customer_organization_type?.id)
            setValue('customer_organization_type',data?.customer_organization_type?.id)
            setSelectedTypeOfBusiness(data?.type_of_business?.id)
            setValue('type_of_business',data?.type_of_business?.id)
            setSelectedPersonOccupation(data?.occupation?.id)
            setValue('occupation',data?.occupation?.id)
            setSelectedPersonSalesStatus(data?.customer_sales_status?.id)
            setValue('customer_sales_status',data?.customer_sales_status?.id)
            setSelectedPersonPurchaseType(data?.customer_buying_type?.id)
            setValue('customer_buying_type',data?.customer_buying_type?.id)
            // setSelectedEmployee(data?.customer_from_party_name?.id)
            // setValue('customer_from_party_name',data?.customer_from_party_name?.id)
            // setselectedGrade(data?.customer_grade?.id)
            // setValue('customer_grade',data?.customer_grade?.id)
        
            setselectedGrade(data?.customer_grade)
            setValue('customer_grade',data?.customer_grade)
        
            // if (data?.reference === null || data?.reference === "") {

            //     setSelectedRefrence('other')
            //     setValue("reference", '')
            //     setShowOtherRef(true)
            // }
            // else {
            //     setSelectedRefrence(data?.reference?.id)
            //     setValue("reference", data?.reference?.id)
            // }

            if (data?.company_date_of_birth) {
                setJoiningDate(dayjs(data?.company_date_of_birth, 'DD/MM/YYYY'))
            }
            //   setValue("joining_date",new Date( moment(data.joining_date, "DD-MM-YYYY").toISOString()))

            //   setValue("point_open_time", dayjs("13:55", "HH:mm"))



            let tempVisiting = data?.visiting_card?.map((img: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${img?.id}`,
                    name: `${img.title}`,
                    status: 'done',
                    url: img?.doc,
                    thumbUrl: img?.doc,
                }

                return ctx
            })
            let tempConditionImg = data?.condition_image?.map((img: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${img?.id}`,
                    name: `${img.title}`,
                    status: 'done',
                    url: img?.doc,
                    thumbUrl: img?.doc,
                }

                return ctx
            })
            let tempSecurity = data?.security_cheque?.map((img: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${img?.id}`,
                    name: `${img.title}`,
                    status: 'done',
                    url: img?.doc,
                    thumbUrl: img?.doc,
                    
                }
console.log(ctx.name,"ctx");

                return ctx
            })

            let tempConditionPDF = data?.condition_pdf?.map((pdf: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${pdf?.id}`,
                    name: `${pdf.title}`,
                    status: 'done',
                    url: pdf?.doc,
                    thumbUrl: pdf?.doc,
                }

                return ctx
            })
           

            setUploadVisiting(tempVisiting)
            setUploadConditionImg(tempConditionImg)
            setUploadSecurity(tempSecurity)
            setUploadConditionPDF(tempConditionPDF)

        }
    }, []);



    const onSubmit = (branddetail: any) => {
        if (Visiting.length == 0) {
            delete branddetail.visiting_card
          }
          
          if (ConditionImg.length == 0) {
            delete branddetail.condition_image
          }
          if (Security.length == 0) {
            delete branddetail.security_cheque
          }
          if (ConditionPDF.length == 0) {
            delete branddetail.condition_pdf
          }

          if (EmployeeValue === undefined) {
            delete branddetail?.customer_from_party_name
          }
          if (forGradeValue === undefined || forGradeValue === '') {
            delete branddetail?.customer_grade
          }
          if (RefrenceValue === undefined) {
            delete branddetail?.reference
            delete branddetail?.other_reference
          }


        delete branddetail.employee_code
        delete branddetail.joining_date
        delete branddetail.birth_date
        delete branddetail.anniversary_date




        const formData = new FormData();





        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value

            if (key === "visiting_card") {
                VisitingId.map((old: any) => {
                    formData.append('visiting_cards', old?.id)
                })
                for (let i = 0; i < Visiting.length; i++) {

                    formData.append('visiting_card', Visiting[i])
                }
                continue
            }
            if (key === "condition_image") {
                ConditionImgId.map((old: any) => {
                    formData.append('condition_images', old?.id)
                })
                for (let i = 0; i < ConditionImg.length; i++) {

                    formData.append('condition_image', ConditionImg[i])
                }
                continue
            }
            if (key === "security_cheque") {
                SecurityId.map((old: any) => {
                    formData.append('security_cheques', old?.id)
                })
                for (let i = 0; i < Security.length; i++) {

                    formData.append('security_cheque', Security[i])
                }
                continue
            }
            if (key === "condition_pdf") {
                ConditionPDFId.map((old: any) => {
                    formData.append('condition_pdfs', old?.id)
                })
                for (let i = 0; i < ConditionPDF.length; i++) {

                    formData.append('condition_pdf', ConditionPDF[i])
                }
                continue
            }

            




            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            //     else if (Array.isArray(value) && key === "department_name") {
            //       value.forEach((val, index) => formData.append(`department_name`, val))
            //   }



        }

        let personType = localStorage.getItem('PersonType');
        let personTypeValue = personType ? JSON.parse(personType) : null;
        console.log(personTypeValue,"personTypeValue");
        formData.append('person_type', personTypeValue?.id||personTypeValue?.value)

        let data = {
            id: id,
            apiname: apiname,
            data: formData
        }
        mutate(data);
        setValuesofform(data)
    }





    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")




    useEffect(() => {
        if (isSuccess && brandUpdated_response) {
            localStorage.setItem('Add Data', JSON.stringify(brandUpdated_response?.data))
             const currentPath = window.location.pathname;
            if(currentPath === '/addCustomerDetails'){
            setCurrentStep(currentStep + 1)
            }
        }
    }, [isSuccess, brandUpdated_response])








    useEffect(() => {
        if (isSuccess || yessuccess || nosuccess) {
            setValuesofform(null)
            setShow(true)
            setshowmessage("success");

            // navigate('/listEmployee', { state: { showEditToast: true } })
        }

    }, [data, isSuccess, yessuccess,
        nosuccess, brandUpdated_response])


       



    useEffect(() => {
        if (isError) {
            if (error.response.status === 405) {
                setConfirmmodal(true)
            }
        }
        else if (isError || yeserror || noerror) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isError, yeserror, noerror])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, []);

    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }

    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }

    const noupdatemodal = () => {
        setOpenEditModel(true)
    }




    const handleConfirm = (data: any) => {


        let ok = {
            id: id,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditModel(false)
    }



    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {

        return current && current < dayjs().startOf('day');
    };


    const [birthDate, setBirthDate]: any = useState()

    const handleBirthDate = (e: any) => {


        if (e == null) {
            setBirthDate("")
            setValue("birth_date", ``)
            return
        }
        setBirthDate(dayjs(e, 'DD/MM/YYYY'))

        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let birth_date = `${date}-${month}-${year}`
        setValue("birth_date", birth_date)

        //   setBirthDate(e)
    }




    useEffect(() => {

        if (birthDate != null || birthDate) {

            // if(birthDate == null){

            // }

            let todayDate = new Date().toISOString().split("T")[0]


            if (birthDate?.$D === 31) {
                var bDate = moment([birthDate?.$y, birthDate?.$M, birthDate?.$D]);
            } else {
                var bDate = moment([birthDate?.$y, birthDate?.$M + 1, birthDate?.$D]);
            }

            //   var bDate = moment([birthDate?.$y, birthDate?.$M+1, birthDate?.$D]);    

            var currentDate = moment([todayDate.split("-")[0], todayDate.split("-")[1], todayDate.split("-")[2]]);

            setAge(currentDate.diff(bDate, 'years'))
            setValue("age", age)





        }



    }, [birthDate, age])


    const handleJoiningDate = (e: any) => {






        if (e == null) {
            setJoiningDate("")
            setValue("company_date_of_birth", ``)
            return
        }
        setJoiningDate(dayjs(e, 'DD/MM/YYYY'))
        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let company_date_of_birth = `${date}-${month}-${year}`
        setValue("company_date_of_birth", company_date_of_birth)



    }

    const [anniversary, setAnniversary]: any = useState("")


    const handleAnniversaryDate = (e: any) => {







        if (e == null) {
            setAnniversary("")
            setValue("anniversary_date", ``)
            return
        }
        setAnniversary(dayjs(e, 'DD/MM/YYYY'))
        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let anniversary_date = `${date}-${month}-${year}`
        setValue("anniversary_date", anniversary_date)

    }



    const handleUpper = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }

    const [VisitingId, setVisitingId] = useState([])
   

    const [Visiting, setVisiting] = useState([])
    const [uploadVisiting, setUploadVisiting] = useState<UploadFile[]>([])

   

   

    const VisitingOnChange = (e: any) => {
        e.file.status = "done"
        
        // e.file.status = "done"

        let tempOldFiles: any = []


        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }
                tempOldFiles.push(ctx)

            }
        })

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        }).filter((e: any) => e != undefined)

        setVisitingId(tempOldFiles)
        setVisiting(tempFiles)
        setUploadVisiting(e.fileList)


    }

    const [ConditionImgId, setConditionImgId] = useState([])
    const [ConditionImg, setConditionImg] = useState([])
    const [uploadConditionImg, setUploadConditionImg] = useState<UploadFile[]>([])

    const ConditionImgOnChange = (e: any) => {
        e.file.status = "done"
        
        // e.file.status = "done"

        let tempOldFiles: any = []


        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }
                tempOldFiles.push(ctx)

            }
        })

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        }).filter((e: any) => e != undefined)

        setConditionImgId(tempOldFiles)
        setConditionImg(tempFiles)
        setUploadConditionImg(e.fileList)


    }

    const [SecurityId, setSecurityId] = useState([])
    const [Security, setSecurity] = useState([])
    const [uploadSecurity, setUploadSecurity] = useState<UploadFile[]>([])

    const SecurityOnChange = (e: any) => {
        e.file.status = "done"
        
        // e.file.status = "done"

        let tempOldFiles: any = []


        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }
                tempOldFiles.push(ctx)

            }
        })

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        }).filter((e: any) => e != undefined)

        setSecurityId(tempOldFiles)
        setSecurity(tempFiles)
        setUploadSecurity(e.fileList)


    }

    const [ConditionPDFId, setConditionPDFId] = useState([])
    const [ConditionPDF, setConditionPDF] = useState([])
    const [uploadConditionPDF, setUploadConditionPDF] = useState<UploadFile[]>([])

    const ConditionPDFChange = (e: any) => {

        e.file.status = "done"

        let tempOldFiles: any = []


        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }
                tempOldFiles.push(ctx)

            }
        })

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        }).filter((e: any) => e != undefined)

        setConditionPDFId(tempOldFiles)

        setConditionPDF(tempFiles)
        setUploadConditionPDF(e.fileList)

    }
  

    const [SubmittedCity, setSubmittedCity]: any = useState(false)
    useEffect(() => {
        if (SubmittedCity) {

            setSelCity(cityOptions[0])


            setValue("city_name_and_distict_name", cityOptions[0]?.value)

        }

    }, [SubmittedCity, cityOptions])

    useEffect(() => {
        if (submitted) {
            setSelDepartment(optionsDepartment[0])

            setValue("department", optionsDepartment[0]?.value)

        }

    }, [submitted, optionsDepartment])


   
    const [SubmittedAccount, setSubmittedAccount]: any = useState(false)
    useEffect(() => {
        if (SubmittedAccount) {

            setSelAccount(optionsAccount[0])


            setValue("group", optionsAccount[0]?.value)

        }

    }, [SubmittedAccount, optionsAccount])



    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
    } = useDelete();
    const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
    } = useDeletemforce();




    const handleRefetch = () => {
        if (apiName === apinameOrganizationType) {
            OrganizationTypeRefetch()
        }
        if (apiName === apinameTypeOfBusiness) {
            TypeOfBusinessRefetch()
        }
        if (apiName === apinamePersonOccupation) {
            PersonOccupationRefetch()
        }
        if (apiName === apinamePersonSalesStatus) {
            PersonSalesStatusRefetch()
        }
        if (apiName === apinameForAdministrationType) {
            AdministrationTypeRefetch()
        }
        if (apiName === apinameForWeeklyOff) {
            WeeklyOffRefetch()
        }
       
        if (apiName === apinamePersonPurchaseType) {
            PersonPurchaseTypeRefetch()
        }
       



        // else if(apiName === apinameForTaluka){
        //   TalukaRefetch()
        // }
    }


    useEffect(() => {
        if (OpenEditDropDownModel && valueTypeOfBusiness && apiName === apinameTypeOfBusiness) {
            setValueTypeOfBusiness(null)
            setValueTypeOfBusinessCreatable(null)
        }
        if (OpenEditDropDownModel && valuePersonOccupation && apiName === apinamePersonOccupation) {
            setValuePersonOccupation(null)
            setValuePersonOccupationCreatable(null)
        }
        if (OpenEditDropDownModel && valueOrganizationType && apiName === apinameOrganizationType) {
            setValueOrganizationType(null)
            setValueOrganizationTypeCreatable(null)
        }
        if (OpenEditDropDownModel && valuePersonSalesStatus && apiName === apinamePersonSalesStatus) {
            setValuePersonSalesStatus(null)
            setValuePersonSalesStatusCreatable(null)
        }
        if (OpenEditDropDownModel && valueAdministrationType && apiName === apinameForAdministrationType) {
            setValueAdministrationType(null)
            setValueAdministrationTypeCreatable(null)
        }
        if (OpenEditDropDownModel && valueWeeklyOff && apiName === apinameForWeeklyOff) {
            setValueWeeklyOff(null)
            setValueWeeklyOffCreatable(null)
        }
        
        if (OpenEditDropDownModel && valuePersonPurchaseType && apiName === apinamePersonPurchaseType) {
            setValuePersonPurchaseType(null)
            setValuePersonPurchaseTypeCreatable(null)
        }
        






        if (deletesucess && apiName === apinameOrganizationType) {
            setValueOrganizationType(null)
            setValueOrganizationTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameTypeOfBusiness) {
            setValueTypeOfBusiness(null)
            setValueTypeOfBusinessCreatable(null)
        }
        if (deletesucess && apiName === apinamePersonOccupation) {
            setValuePersonOccupation(null)
            setValuePersonOccupationCreatable(null)
        }
        if (deletesucess && apiName === apinamePersonSalesStatus) {
            setValuePersonSalesStatus(null)
            setValuePersonSalesStatusCreatable(null)
        }

        if (deletesucess && apiName === apinameForAdministrationType) {
            setValueAdministrationType(null)
            setValueAdministrationTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameForWeeklyOff) {
            setValueWeeklyOff(null)
            setValueWeeklyOffCreatable(null)
        }
        
        if (deletesucess && apiName === apinamePersonPurchaseType) {
            setValuePersonPurchaseType(null)
            setValuePersonPurchaseTypeCreatable(null)
        }
        

    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (


        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
            <span>{label}</span>
            <div style={{ float: "right", justifyContent: "end" }}>



                {isExist ?

                    <Custom_Actions_Buttons value={value}
                        apiname={deleteApiName}
                        mutate={singleDelete}
                        // masterName={addButtonName}
                        deleteid={deleteid}
                        handleDeletedforce={handleDeletedforce}
                        closereconfirmdelete={closereconfirmdelete}
                        deletemodalask={deletemodalask}
                        setEditOpen={setOpenEditDropDownModel}
                    // permissionClass={permissionClass}
                    />
                    : ""}

            </div>
        </div>


    )


    const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
    }



    useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
        return () => {
            setDeleteid(undefined);
        };
    }, []);
    const handleConfirmDelete = () => {
        setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
    };

    const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
    };
    useEffect(() => {
        if (forcefullysuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullyerror) {
            setShow(true);
            setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullydeleteerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
    ]);

    useEffect(() => {
        if (deleteMultipleSuccess) {
            const showdepenedancymsg = deleteMultiple?.data?.data;
            const dependacyfound = showdepenedancymsg?.filter((color: any) => {
                if (
                    color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
                ) {
                    return color;
                }
            });
            if (dependacyfound?.length === deleteMultipleid?.length) {
                deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
            } else {
                setConfirmforcemodal(true);
            }
        } else if (deleteMultipleIsError) {
            setShow(true);
            setshowmessage("danger");
        } else if (deletesucess) {
            if (deletedata.data?.data?.length > 0) {
                setConfirmforcemodal(true);
            } else if (deleteid) {
                setShow(true);
                setshowmessage("success");
                deleteforce({ deleteid, apiname: deleteApiName });
            }
        }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

    const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
    };


    const apinameAccount = process.env.REACT_APP_ACCOUNT_TYPE_API?.toString()
    const apinameCity = process.env.REACT_APP_CITY_API?.toString()
    const apinameReference  = process.env.REACT_APP_PERSON_EMPLOYEE_API?.toString()
    const apinamePersonFromPartyName  = process.env.REACT_APP_PERSON_EMPLOYEE_API?.toString()
        useEffect(()=>{
           
        if(data?.group){
            const getCall = async()=>{
            try {
                const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameAccount}/${data?.group}`);

                setSelectedAccount(response?.data?.data?.id)
                setValue("group", response?.data?.data?.id)
              } catch (error) {
                console.error('Error:', error);
              }
            }
            getCall()
        }
        if(data?.city_name_and_distict_name){
            const getCall = async()=>{
            try {
                const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameCity}/${data?.city_name_and_distict_name}`);
                
                setSelectedCity(response?.data?.data?.id)
                setValue("city_name_and_distict_name", response?.data?.data?.id)
              } catch (error) {
                console.error('Error:', error);
              }
            }
            getCall()
        }
       
        if(data?.reference || data?.reference===null || data?.other_reference === "" ){
            const getCall = async()=>{
            try {
                 if (data?.other_reference === "" && data?.reference===null){
                        setSelectedRefrence('')
                        setValue("reference",'')
                        setShowOtherRef(false)
                    }
                else if (data?.other_reference){
                    setSelectedRefrence('other')
                    setValue("reference",'')
                    setShowOtherRef(true)    
                }
                //  if(data?.reference===null||data?.reference===""){
                //     // if (data?.other_reference === ""){
                //     //     setSelectedRefrence('')
                //     //     setValue("reference",'')
                //     //     setShowOtherRef(false)
                //     // }
                //     // else {

                //         setSelectedRefrence('other')
                //         setValue("reference",'')
                //         setShowOtherRef(true)
                //     // }
                // }
                else{
                    const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameReference}/${data?.reference}`);
                    setSelectedRefrence(response?.data?.data?.id)
                    setValue("reference",response?.data?.data?.id)
                }
                // setSelectedDesignation(response?.data?.data?.id)
                // setValue("designation", response?.data?.data?.id)
              } catch (error) {
                console.error('Error:', error);
              }
            }
            getCall()
        }
        if(data?.customer_from_party_name){
            const getCall = async()=>{
            try {
                const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinamePersonFromPartyName}/${data?.customer_from_party_name}`);
                
                setSelectedEmployee(response?.data?.data?.id)
                setValue("customer_from_party_name", response?.data?.data?.id)
              } catch (error) {
                console.error('Error:', error);
              }
            }
            getCall()
        }
        },[])



        




    return (
        <div >
            <section className="content-header py-1 px-3">
                {/* <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit Employee</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Users</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listEmployee" className="text-decoration-none text-black">Employee</Link></li>
                                    <li className="breadcrumb-item active">Edit Employee</li>
                                </ol>
                            </div>
                        </div>
                    </div> */}
            </section>
            <section className="">
                <div className="">
                    <div className="">

                        <form
                            ref={focus}
                            onKeyUp={event => onEnterKey(event)}
                            onSubmit={handleSubmit(onSubmit)}>
                            <div className="" >

                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                    <div className="col-md-12">
                                        <div className="row">

                                            

                                            <div className="col-sm-3 mt-2">
                                                <label htmlFor="exampleInputEmail1">Account Type <span style={{ color: "red" }}>*</span></label>
                                                <div className="d-flex row" >
                                                    <Select
                                                        {...register("group", { required: { value: true, message: "Account Type is required" } })}
                                                        placeholder={'Account Type'}
                                                        onChange={handleChangeAccount}
                                                        // value={forAccountValue}
                                                        options={optionsAccount}
                                                        components={customIndicator}
                                                        value={selAccount === "" ? optionsAccount?.find((obj: any) => obj.value === selectedAccount) : selAccount}

                                                        className="dropdown-select"

                                                    />
                                                    <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                        <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                            setOpenAccount(true)
                                                        }}><AiOutlinePlus /></Button>
                                                    </div>
                                                </div>
                                                <span className='text-red w-100'>{errors?.group?.message}</span>
                                            </div>


                                            <div className="col-sm-3 mt-2">

                                                <label >Customer Name <span style={{ color: 'red' }}>*</span></label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="Customer Name" {...register("customer_name", { onChange:handleValue, required: { value: true, message: "Customer Name is required" } })} />
                                                    <div className="input-group-append" >
                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                        /></span>

                                                    </div>
                                                </div>
                                                <span className='text-red w-100'>{errors?.customer_name?.message}</span>
                                            </div>


                                            

                                            <div className="col-sm-3 mt-2">
                                                <label htmlFor="exampleInputEmail1">City Name & District Name <span style={{ color: 'red' }}>*</span></label>
                                                <div className="d-flex row" >
                                                    <Select
                                                        {...register("city_name_and_distict_name", { required: { value: true, message: "City Name & District Name is required" } })}
                                                        onChange={handleChangeCity}
                                                        options={cityOptions}
                                                        components={customIndicator}
                                                        value={selCity === "" ? cityOptions?.find((obj: any) => obj.value === selectedCity) : selCity}

                                                        // maxMenuHeight={130}
                                                        // isOptionDisabled={(options:any)=>
                                                        //     {
                                                        //         if(options.status === "inactive" || options.deleted == true){
                                                        //         return options
                                                        //         }
                                                        //        }}

                                                        className="dropdown-select"
                                                    />
                                                    <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                        <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                            setOpenCity(true)
                                                        }}><AiOutlinePlus /></Button>
                                                    </div>
                                                </div>
                                                <span className='text-red w-100'>{errors?.city_name_and_distict_name?.message}</span>
                                            </div>

                                            <div className="col-sm-3 mt-2">
                                                    <label >Person Organization Type <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                        {...register("customer_organization_type", { required: { value: true, message: "Person Organization Type is required" } })}
                                                        isClearable={() => {
                                                            setValueOrganizationType(null)
                                                            setValue(null)
                                                        }}
                                                        components={customCreatableIndicator}
                                                        options={optionsOrganizationType}
                                                        onCreateOption={handleCreateOrganizationType}
                                                        onChange={handleChangeOrganizationType}
                                                        onMenuOpen={() => OrganizationTypeRefetch()}
                                                        // value={valueOrganizationTypeCreatable == null ? valueOrganizationType : valueOrganizationTypeCreatable}
                                                        value={valueOrganizationTypeCreatable == null ? valueOrganizationType == null ? optionsOrganizationType?.filter((obj: any) => obj.value === selectedOrganizationType) : valueOrganizationType : valueOrganizationTypeCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.customer_organization_type?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label >Type Of Business <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                        {...register("type_of_business", { required: { value: true, message: "Type Of Business is required" } })}
                                                        isClearable={() => {
                                                            setValueTypeOfBusiness(null)
                                                            setValue(null)
                                                        }}
                                                        components={customCreatableIndicator}
                                                        options={optionsTypeOfBusiness}
                                                        onCreateOption={handleCreateTypeOfBusiness}
                                                        onChange={handleChangeTypeOfBusiness}
                                                        onMenuOpen={() => TypeOfBusinessRefetch()}
                                                        // value={valueTypeOfBusinessCreatable == null ? valueTypeOfBusiness : valueTypeOfBusinessCreatable}
                                                        value={valueTypeOfBusinessCreatable == null ? valueTypeOfBusiness == null ? optionsTypeOfBusiness?.filter((obj: any) => obj.value === selectedTypeOfBusiness) : valueTypeOfBusiness : valueTypeOfBusinessCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.type_of_business?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >Person Occupation <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                        {...register("occupation", { required: { value: true, message: "Type Of Business is required" } })}
                                                        isClearable={() => {
                                                            setValuePersonOccupation(null)
                                                            setValue(null)
                                                        }}
                                                        components={customCreatableIndicator}
                                                        options={optionsPersonOccupation}
                                                        onCreateOption={handleCreatePersonOccupation}
                                                        onChange={handleChangePersonOccupation}
                                                        onMenuOpen={() => PersonOccupationRefetch()}
                                                        // value={valuePersonOccupationCreatable == null ? valuePersonOccupation : valuePersonOccupationCreatable}
                                                        value={valuePersonOccupationCreatable == null ? valuePersonOccupation == null ? optionsPersonOccupation?.filter((obj: any) => obj.value === selectedPersonOccupation) : valuePersonOccupation : valuePersonOccupationCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.occupation?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label >Person Sales Status <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                        {...register("customer_sales_status", { required: { value: true, message: "Type Of Business is required" } })}
                                                        isClearable={() => {
                                                            setValuePersonSalesStatus(null)
                                                            setValue(null)
                                                        }}
                                                        components={customCreatableIndicator}
                                                        options={optionsPersonSalesStatus}
                                                        onCreateOption={handleCreatePersonSalesStatus}
                                                        onChange={handleChangePersonSalesStatus}
                                                        onMenuOpen={() => PersonSalesStatusRefetch()}
                                                        // value={valuePersonSalesStatusCreatable == null ? valuePersonSalesStatus : valuePersonSalesStatusCreatable}
                                                        value={valuePersonSalesStatusCreatable == null ? valuePersonSalesStatus == null ? optionsPersonSalesStatus?.filter((obj: any) => obj.value === selectedPersonSalesStatus) : valuePersonSalesStatus : valuePersonSalesStatusCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.customer_sales_status?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label >Person Purchase Type <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                        {...register("customer_buying_type", { required: { value: true, message: "Type Of Business is required" } })}
                                                        isClearable={() => {
                                                            setValuePersonPurchaseType(null)
                                                            setValue(null)
                                                        }}
                                                        components={customCreatableIndicator}
                                                        options={optionsPersonPurchaseType}
                                                        onCreateOption={handleCreatePersonPurchaseType}
                                                        onChange={handleChangePersonPurchaseType}
                                                        onMenuOpen={() => PersonPurchaseTypeRefetch()}
                                                        // value={valuePersonPurchaseTypeCreatable == null ? valuePersonPurchaseType : valuePersonPurchaseTypeCreatable}
                                                        value={valuePersonPurchaseTypeCreatable == null ? valuePersonPurchaseType == null ? optionsPersonPurchaseType?.filter((obj: any) => obj.value === selectedPersonPurchaseType) : valuePersonPurchaseType : valuePersonPurchaseTypeCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.customer_buying_type?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="manualcode_Disc_1">Company Start Date <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <DatePicker
                                                            className="form-control"
                                                            format={dateFormatList}
                                                            // value={forPointStartDateValue}
                                                            // disabledDate={disabledDate}
                                                            value={JoiningDate}
                                                            {...register("company_date_of_birth", { required: { value: true, message: "Company Start Date is required" } })}

                                                            onChange={handleJoiningDate}
                                                        />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.company_date_of_birth?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name_and_grade">Person From Party Name </label>
                                                    <div className="d-flex row">
                                                        <Select
                                                            {...register("customer_from_party_name", { required: { value: false, message: "Person From Party Name is required" } })}
                                                            placeholder={'Person From Party Name'}
                                                            onChange={handleChangeEmployee}
                                                            // value={EmployeeValue}
                                                            options={optionsEmployee}
                                                            components={customIndicator}
                                                            maxMenuHeight={150}
                                                            value={selEmployee === "" ? optionsEmployee?.find((obj: any) => obj.value === selectedEmployee) : selEmployee}

                                                        />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.customer_from_party_name?.message}</span>

                                                </div>


                                            <div className="col-sm-3 mt-2">
                                                <label htmlFor="exampleInputEmail1">Weekly Off Day Name <span style={{ color: "red" }}>*</span></label>

                                                <CreatableSelect
                                                    isClearable={() => {
                                                        setValueWeeklyOff(null)
                                                        setValue(null)
                                                    }}
                                                    {...register("weekly_off", { required: { value: true, message: "Weekly Off Day Name is required" } })}
                                                    placeholder={'Weekly Off Day Name'}
                                                    // menuIsOpen={true}
                                                    components={customCreatableIndicator}
                                                    options={optionsWeeklyOff}
                                                    onMenuOpen={() => WeeklyOffRefetch()}
                                                    onCreateOption={handleCreateWeeklyOff}
                                                    onChange={handleChangeWeeklyOff}
                                                    // value={valueWeeklyOffCreatable == null ? valueWeeklyOff : valueWeeklyOffCreatable}
                                                    value={valueWeeklyOffCreatable == null ? valueWeeklyOff == null ? optionsWeeklyOff?.filter((obj: any) => obj.value === selectedWeeklyOff) : valueWeeklyOff : valueWeeklyOffCreatable}

                                                />
                                                <span className='text-red w-100'>{errors?.weekly_off?.message}</span>
                                            </div>

                                            <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name_and_grade">Customer Grade </label>
                                                    <div className="d-flex row">
                                                        <Select
                                                            {...register("customer_grade", { required: { value: false, message: "Customer Grade is required" } })}
                                                            placeholder={'Customer Grade'}
                                                            onChange={handleChangeGrade}
                                                            // value={forGradeValue}
                                                            options={optionsGrade}
                                                            components={customIndicator}
                                                            maxMenuHeight={150}
                                                            value={forGradeValue==""?optionsGrade?.filter((e:any)=>e?.label === selectedGrade):forGradeValue}


                                                        />
                                                    </div>
                                                    {/* <div className="d-flex row">
                                                        <Select
                                                            {...register("customer_grade", { required: { value: false, message: "Customer Grade is required" } })}
                                                            placeholder={'Customer Grade'}
                                                            onChange={handleChangeGrade}
                                                            // value={forGradeValue}
                                                            options={optionsGrade}
                                                            components={customIndicator}
                                                            maxMenuHeight={150}
                                                            value={selGrade === "" ? optionsGrade?.find((obj: any) => obj.value === selectedGrade) : selGrade}

                                                        />
                                                    </div> */}
                                                    <span className='text-red w-100'>{errors?.customer_grade?.message}</span>

                                                </div>


                                            <div className="col-sm-3 mt-2">
                                                <label htmlFor="exampleInputEmail1">Administration Type <span style={{ color: "red" }}>*</span></label>

                                                <CreatableSelect
                                                    isClearable={() => {
                                                        setValueAdministrationType(null)
                                                        setValue(null)
                                                    }}
                                                    {...register("administration_type", { required: { value: true, message: "Administration Type is required" } })}
                                                    placeholder={'Administration Type'}
                                                    // menuIsOpen={true}
                                                    components={customCreatableIndicator}
                                                    options={optionsAdministrationType}
                                                    onMenuOpen={() => AdministrationTypeRefetch()}
                                                    onCreateOption={handleCreateAdministrationType}
                                                    onChange={handleChangeAdministrationType}
                                                    // value={valueAdministrationTypeCreatable == null ? valueAdministrationType : valueAdministrationTypeCreatable}
                                                    value={valueAdministrationTypeCreatable == null ? valueAdministrationType == null ? optionsAdministrationType?.filter((obj: any) => obj.value === selectedAdministrationType) : valueAdministrationType : valueAdministrationTypeCreatable}

                                                />
                                                <span className='text-red w-100'>{errors?.administration_type?.message}</span>
                                            </div>

                                            <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name_and_grade">Reference By </label>
                                                    <div className="d-flex row">
                                                        <Select
                                                            {...register("reference", { required: { value: false, message: "Reference By is required" } })}
                                                            placeholder={'Reference By'}
                                                            onChange={handleChangeRefrence}
                                                            // value={RefrenceValue}
                                                            options={optionsRefrence}
                                                            components={customIndicator}
                                                            maxMenuHeight={150}
                                                            value={selRefrence === "" ? optionsRefrence?.find((obj: any) => obj.value === selectedRefrence) : selRefrence}
                                                        />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.reference?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Other Reference By </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Other Reference By"
                                                        disabled={!showOtherRef}
                                                        {...register("other_reference", { onChange: handleValue, required: { value: false, message: "Other Reference By is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.other_reference?.message}</span>
                                                </div>


                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Person First Name <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Person First Name" {...register("name", { onChange: handleValue, required: { value: true, message: "Person First Name is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.name?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Person Middle Name <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Person Middle Name" {...register("father_name", { onChange: handleValue, required: { value: true, message: "Person Middle Name is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.father_name?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Person Last Name <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Person Last Name" {...register("surname", { onChange: handleValue, required: { value: true, message: "Person Last Name is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.surname?.message}</span>
                                                </div> */}


                                                <div className="col-sm-3 mt-2">
                                                    <label >Re-Mark</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control"

                                                            placeholder={'Re-Mark'}


                                                            {...register('re_mark', { required: { value: false, message: "Field is required" } })}
                                                        //  {...register("document_number", { required: { value: true, message: "Field is required" } })} 
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.re_mark?.message}</span>
                                                </div>

                                            {/* <div className="col-sm-3 mt-2">
                                                <label htmlFor="name_and_grade">Reference By </label>
                                                <div className="d-flex row">
                                                    <Select
                                                        {...register("reference", { required: { value: false, message: "Reference By is required" } })}
                                                        placeholder={'Reference By'}
                                                        onChange={handleChangeEmployee}
                                                        // value={EmployeeValue}
                                                        options={optionsEmployee}
                                                        components={customIndicator}
                                                        value={selEmployee === "" ? optionsEmployee?.find((obj: any) => obj.value === selectedEmployee) : selEmployee}

                                                    />
                                                </div>
                                                <span className='text-red w-100'>{errors?.reference?.message}</span>

                                            </div> */}

                                            {/* <div className="col-sm-3 mt-2">
                                                <label htmlFor="exampleInputEmail1">Other Reference By </label>
                                                <div className="input-group" data-colorpicker-id={2}>
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder="Reference By"
                                                        disabled={!showOtherRef}
                                                        {...register("other_reference", { onChange: handleUpper, required: { value: false, message: "Reference By is required" } })} />
                                                    <div className="input-group-append" >
                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                    </div>
                                                </div>
                                                <span className='text-red w-100'>{errors?.other_reference?.message}</span>
                                            </div>
                                            <div className="col-sm-3 mt-2">
                                                <label htmlFor="exampleInputEmail1">Remark </label>
                                                <div className="input-group" data-colorpicker-id={2}>
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder="Remark" {...register("re_mark", { onChange: handleUpper, required: { value: false, message: "Remark is required" } })} />
                                                    <div className="input-group-append" >
                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                    </div>
                                                </div>
                                                <span className='text-red w-100'>{errors?.re_mark?.message}</span>
                                            </div> */}

                                            <div className="col-sm-3 mt-2">
                                                <label htmlFor="exampleInputEmail1">Status <span style={{ color: "red" }}>*</span></label>
                                                <select className="form-select" {...register("status", { required: { value: false, message: "Status is required" } })}>
                                                    <option value={""} disabled selected>Select Any</option>
                                                    <option value="active">Active</option>
                                                    <option value="inactive">Inactive</option>
                                                </select>
                                                <span className='text-red w-100'>{errors?.status?.message}</span>

                                            </div>
                                            <div className="col-sm-3 mt-2">
                                                <label htmlFor="exampleInputEmail1">Person Code </label>
                                                <div className="input-group" data-colorpicker-id={2}>
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder="Employee Code" disabled {...register("code", { required: { value: false, message: "Employee Code is required" } })} />

                                                </div>
                                                <span className='text-red w-100'>{errors?.code?.message}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Employee Image</label>
                                                    <label style={{ cursor: "pointer" }}>
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                        <input data-required="image" type="file" id="file_upload"  accept="image/*" className="image-input" data-traget-resolution="image_resolution"  {...register("image", { onChange: onLogoChange, required: false })} hidden />
                                                    </label>
                                                    <a href={data?.image} target="_blank" ><p>{logo == null ? data?.image?.split("/").pop() : logo}</p></a>
                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Document PDF</label>
                                                    <label style={{ cursor: "pointer" }}>
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                        <input data-required="image" type="file" id="file_upload"  accept="application/pdf" className="image-input" data-traget-resolution="image_resolution" {...register("document_pdf", { onChange: onLogoPDFChange, required: false })} hidden />
                                                    </label>
                                                    <a href={data?.document_pdf} target="_blank" ><p>{logoPdf == null ? data?.document_pdf?.split("/").pop() : logoPdf}</p></a>
                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Agreement PDF</label>
                                                    <label style={{ cursor: "pointer" }}>
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                        <input data-required="image" type="file" id="file_upload"  accept="application/pdf" className="image-input" data-traget-resolution="image_resolution" {...register("agreement_pdf", { onChange: onAgreementChange, required: false })} hidden />
                                                    </label>
                                                    <a href={data?.agreement_pdf} target="_blank" ><p>{agreement == null ? data?.agreement_pdf?.split("/").pop() : agreement}</p></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                <div className="row d-flex">
                                    <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                        <div className="row d-flex ">

                                            <div className="col-sm-3 mt-2 image-upload">
                                                <label htmlFor="exampleInputEmail1" className="col-12">Visiting Card Image All</label>

                                                {uploadVisiting?.length > 0 ?

                                                    <>
                                                         <Upload
                                                        {...register("visiting_card")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={VisitingOnChange}
                                                        multiple={true}
                                                        defaultFileList={uploadVisiting}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                    </> :
                                                    <>
                                                        <Upload
                                                            {...register("visiting_card")}
                                                            accept="image/png, image/jpeg"
                                                            listType="picture"
                                                            onChange={VisitingOnChange}
                                                            multiple={true}
                                                            // defaultFileList={uploadImage}
                                                            onPreview={(e: any) => { return "" }}
                                                        // key={"image"}
                                                        >
                                                            <img src={cameraLogo} alt='' className="uploaded-image" />
                                                        </Upload>
                                                    </>
                                                }


                                            </div>
                                            <div className="col-sm-3 mt-2 image-upload">
                                                <label htmlFor="exampleInputEmail1" className="col-12">Condition Image All</label>

                                                {uploadConditionImg?.length > 0 ?

                                                    <>
                                                         <Upload
                                                        {...register("condition_image")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={ConditionImgOnChange}
                                                        multiple={true}
                                                        defaultFileList={uploadConditionImg}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                    </> :
                                                    <>
                                                         <Upload
                                                        {...register("condition_image")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={ConditionImgOnChange}
                                                        multiple={true}
                                                        // defaultFileList={uploadConditionImg}
                                                        onPreview={(e: any) => { return "" }}
                                                        // key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                    </>
                                                }


                                            </div>
                                            <div className="col-sm-3 mt-2 image-upload">
                                                <label htmlFor="exampleInputEmail1" className="col-12">Security Cheque Image</label>

                                                {uploadSecurity?.length > 0 ?

                                                    <>
                                                        <Upload
                                                        {...register("security_cheque")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={SecurityOnChange}
                                                        multiple={true}
                                                        defaultFileList={uploadSecurity}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                    </> :
                                                    <>
                                                        <Upload
                                                        {...register("security_cheque")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={SecurityOnChange}
                                                        multiple={true}
                                                        // defaultFileList={uploadSecurity}
                                                        onPreview={(e: any) => { return "" }}
                                                        // key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                    </>
                                                }


                                            </div>

                                            <div className="col-sm-3 mt-2 image-upload">
                                                <label htmlFor="exampleInputEmail1" className="col-12">Condition PDF All</label>

                                                {uploadConditionPDF?.length > 0 ?

                                                    <>
                                                       <Upload
                                                        {...register("condition_pdf")}
                                                        accept=".doc,.docx,.xml,.pdf"
                                                        listType="text"
                                                        onChange={ConditionPDFChange}
                                                        multiple={true}
                                                        defaultFileList={uploadConditionPDF}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"pdf"}
                                                    >
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                    </> :
                                                    <>
                                                         <Upload
                                                        {...register("condition_pdf")}
                                                        accept=".doc,.docx,.xml,.pdf"
                                                        listType="text"
                                                        onChange={ConditionPDFChange}
                                                        multiple={true}
                                                        // defaultFileList={uploadConditionPDF}
                                                        onPreview={(e: any) => { return "" }}
                                                        // key={"pdf"}
                                                    >
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                    </>
                                                }

                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Visiting Card Image All</label>
                                                    <Upload
                                                        {...register("visiting_card")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={VisitingOnChange}
                                                        multiple={true}
                                                        defaultFileList={uploadVisiting}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload>

                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Condition Image All</label>
                                                    <Upload
                                                        {...register("condition_image")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={ConditionImgOnChange}
                                                        multiple={true}
                                                        defaultFileList={uploadConditionImg}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload>

                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Security Cheque Image</label>
                                                    <Upload
                                                        {...register("security_cheque")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={SecurityOnChange}
                                                        multiple={true}
                                                        defaultFileList={uploadSecurity}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload>

                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Condition PDF All</label>
                                                    <Upload
                                                        {...register("condition_pdf")}
                                                        accept=".doc,.docx,.xml,.pdf"
                                                        listType="text"
                                                        onChange={ConditionPDFChange}
                                                        multiple={true}
                                                        defaultFileList={uploadConditionPDF}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"pdf"}
                                                    >
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}


                                <div className="row d-flex  ">
                                    <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                        <div className="row d-flex ">



                                            <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                <button
                                                    type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                    style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                    onClick={handleSubmit(onSubmit)}


                                                >Submit</button>

                                                <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetail")}>Cancel</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>

                    </div>
                </div>
            </section>
            <Account_Model refetchForm={accountRefetch} open={openAccount} setOpen={setOpenAccount} setSubmitted={setSubmittedAccount} />
            <City_Model refetchForm={cityRefetch} open={openCity} setOpen={setOpenCity} setSubmitted={setSubmittedCity} />



            {/* Edit Modal HTML */}
            <Modal
                show={deleteConfirm}
                onHide={() => setDeleteConfirm(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton>
                    <Modal.Title>Delete {""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {" "}
                        <span className="text-center">Sure you want to delete it?</span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setDeleteConfirm(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={handleMultipleDeleted}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Deleteforce
                key={renderKey}
                handleDeletedforce={handleDeletedforce}
                reconfirmdelete={confirmforcemodal}
                masterName={""}
                closereconfirmdelete={closereconfirmdelete}
                referance={
                    deletesucess
                        ? deletedata
                        : ""
                }
                apiname={apiName}
            />
            <Deletesingle
                key={renderKey}
                confirmmodal={deleteConfirmsingle}
                confirmmodalclose={confirmmodalclose}
                masterName={""}
                confirmmodalsubmit={confirmmodalsubmit}
                apiname={apiName}
            />

            <SimpleInput_Edit key={renderKey}
                refetchForm={handleRefetch}
                open={OpenEditDropDownModel}
                setOpen={setOpenEditDropDownModel}
                id={editId}
                // superAccountId={superAccountId} 
                // optionsSuperAccount={optionsSuperAccount}
                // isSuperAccount={isSuperAccount}
                apiname={apiName} />




            <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>





                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                />
                            </div>
                        </div>
                    </div>



                </Modal.Body>
                <Modal.Footer>



                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>


                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>

                </Modal.Footer>
            </Modal>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />


{show && (
          <Mui_Toaster
            message={`Customer Updated Successfully`}
            severity={"success"}
            Isopen={true}
          />
        )}
        </div>
    )
}

export default Edit_Basic_Customer_Details