import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Columns_Customer_Detail_Other, titleofheaders } from "./Columns_Customer_Detail_Other"
import Table from "../../../components/Dynamic_Table/Table/Table";
// import { useFetchCheckListMapperDropDown } from "../../hooks/DropDown_Api";
const List_Customer_Detail_Other = () => {
  const Breadcrumb_Primary_Title = "Home";
  const Breadcrumb_Primary_Link = "";

  const Breadcrumb_Parent_Title = "Person";

  const Breadcrumb_Title_Name = "Person Detail";

  const apiname = process.env.REACT_APP_EMPLOYEE_API?.toString();

  const filter = {

    selection:"",
  code:"",
  name:"",
  city_and_village:"",
  zone:"",
  owner_name:"",
  owner_surname:"",
  contact_no:"",
  email:"",
  address:"",
  pincode:"",
  gst_type_registration:"",
  customer_gst_type:"",
  gst_no:"",
  gst_category:"",
  tds_details:"",
  tds_details_number:"",
  pan_number:"",
  customer_type:"",
  account_type:"",
  customer_organization_type:"",
  company_date_of_birth:"",
  customer_payment_method:"",
  customer_credit_day_limit:"",
  customer_credit_amount_limit:"",
  late_payment_interest:"",
  customer_running_not:"",
  targeted_monthly_box:"",
  targeted_monthly_amount:"",
  opening_balance:"",
  reference:"",
  customer_group:"",
  // koru_1:"",
  // koru_2:"",
  // koru_3:"",
  // koru_4:"",
  customer_title:"",
  customer_visiting_card_image:"",
  created_by:"",
  updated_by:"",
  status:"",

  };

  const addButtonName = "Person Detail";
  const addButtonPageName = "CustomerDetails"
  const permissionClass = {
    front: "customer",
    back: "customer"
  }
  const stickyStart = 1;
  const stickyEnd = 2;

localStorage.removeItem('PersonType')
localStorage.removeItem('Add Data')

const currentPath = window.location.pathname;


console.log('currentPath',currentPath)


  return (
    <>
      <div className="content-wrapper">
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{Breadcrumb_Title_Name} List</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to={`/list${Breadcrumb_Primary_Link}`}
                      className="text-decoration-none text-black"
                    >
                      {Breadcrumb_Primary_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      {Breadcrumb_Parent_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {Breadcrumb_Title_Name} List
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="container p-0" style={{ maxWidth: "100%" }}>
                {/* <BasicTable/> */}
                <Table
                  apiname={apiname}
                  filterFields={filter}
                  addButtonName={addButtonName}
                  stickyStart={stickyStart}
                  stickyEnd={stickyEnd}
                  TableColumns={Columns_Customer_Detail_Other}
                  addButtonPageName={addButtonPageName}
                  titleofheaders={titleofheaders}
                  permissionClass={permissionClass}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default List_Customer_Detail_Other;
