import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Columns_WareHouse, titleofheaders } from "./Columns_WareHouse"
import Table from "../../../components/Dynamic_Table/Table/Table";
// import { useFetchCheckListMapperDropDown } from "../../hooks/DropDown_Api";
const List_WareHouse = () => {
  const Breadcrumb_Primary_Title = "Home";
  const Breadcrumb_Primary_Link = "";

  const Breadcrumb_Parent_Title = "Sales & Marketing";

  const Breadcrumb_Title_Name = "Ware House";

  const apiname = process.env.REACT_APP_WAREHOUSE_API?.toString();

  const filter = {

    code:"",
    name: "",
    pincode:"" ,
    city: "",
    staff_code_and_name:"",
    staff_qty: "",
    district:"",
    state:"",
    country:"",
    responsible_person: "",
    sheth_responsible_person: "",
    created_by:"",
    updated_by:"", 
    status:''
  

  };

  const addButtonName = "Ware House";
  const addButtonPageName = "wareHouse"
  const permissionClass = {
    front: "warehouse",
    back: "warehouse"
  }
  const stickyStart = 1;
  const stickyEnd = 2;




  return (
    <>
      <div className="content-wrapper">
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{Breadcrumb_Title_Name} List</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to={`/list${Breadcrumb_Primary_Link}`}
                      className="text-decoration-none text-black"
                    >
                      {Breadcrumb_Primary_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      {Breadcrumb_Parent_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {Breadcrumb_Title_Name} List
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="container p-0" style={{ maxWidth: "100%" }}>
                {/* <BasicTable/> */}
                <Table
                  apiname={apiname}
                  filterFields={filter}
                  addButtonName={addButtonName}
                  stickyStart={stickyStart}
                  stickyEnd={stickyEnd}
                  TableColumns={Columns_WareHouse}
                  addButtonPageName={addButtonPageName}
                  titleofheaders={titleofheaders}
                  permissionClass={permissionClass}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default List_WareHouse;
