import React, { useEffect, useRef } from "react";
import Header from "../../components/Header/header";
import Sidebar from "../../components/Sidebar/sidebar";
import SearchLogo from "../../images/SearchLogo.png";
import ColorPickerLogo from "../../images/ColorPickerLogo.png";
import "../../components/Table/table.css";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useBrandPost } from "../../hooks/brand";
import { useState } from "react";
import { URL } from "node:url";
import Mui_Toaster from "../../components/Mui_Toaster/Mui_Toaster";
import { useCreate, useFetch, useFetchAllPermissions, useFetchSingle, useUpdateSingle } from "../../hooks/Api";
import { Alert } from "../../components/Model/Model";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../components/focus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons/faEyeSlash";
import CreatableSelect from "react-select/creatable";
import { PickList } from "primereact/picklist";

interface Option {
  readonly label: string;
  //   readonly value: string;
  readonly name: string;
}
const createOption = (label: string) => ({
  label,
  // value: label,
  name: label,
  status: "active",
});

const Edit_UserType = ({ checkToast }: any) => {
  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);
  const navigate = useNavigate();
  const [source, setSource] = useState([]);
  const [target, setTarget] = useState([]);
  const apiname = process.env.REACT_APP_LEOUSER_GROUP_API?.toString();
  const [loader,setLoader] = useState(false)

  const { id } = useParams()
  const { data } = useFetchSingle(id, apiname)
  const { mutate, isSuccess, isError, error, data: gstUpdated_response,isLoading }: any = useUpdateSingle()



  const { data: getSource, isFetching:fetchingForGetSource}: any = useFetchAllPermissions();




  useEffect(()=>{
    if(getSource){
      setSource(getSource)
    }
  },[getSource])


  // const {
  //   mutate: UserAdminPost,
  //   data,
  //   isError:,
  //   isLoading,
  //   isSuccess,
  //   error,
  // }: any = useCreate(apiname);

  useEffect(() => {
    if (fetchingForGetSource) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [fetchingForGetSource, getSource]);
  

  const {
    register,
    handleSubmit,
    setError,
    formState,
    reset,
    setValue,
    getValues,
  }: any = useForm({
    mode: "onChange",
  });
  const { errors } = formState;


  const onSubmit = (branddetail: any) => {
    const formData = new FormData();

    for (const [key, value] of Object.entries(branddetail)) {
     
      const formvalue: any = value;
   
       
    if (key === "permissions") {
       
        target.forEach((val:any) => 
          formData.append(`permissions`, val?.id))
          continue
    } 
    else if (typeof value !== "object") {
      formData.append(key, formvalue);
    }

    }
    let data = {
      apiname: apiname,
      id: id,
      data: formData
  }
    mutate(data);
  };





  useEffect(() => {
    if (data) {
        reset(data)
        setTarget(data?.permissions)
    }
}, [data]);



  const [show, setShow] = useState(false);
  const [showmessage, setshowmessage] = useState("");

  useEffect(() => {
    if (isSuccess) {
      setShow(true);
      setshowmessage("success");
      navigate("/listGroup", { state: { showEditToast: true } });
    } else if (isError) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [data, isSuccess,error]);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [show]);

  const handleUpper = (e: any) => {
    e.target.value = e.target.value
      .toLowerCase()
      .split(" ")
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  };

  const onChange = (event: any) => {
    setSource(event.source);
    setTarget(event.target);
  };


  

  const itemTemplate = (item: any) => {

    

    return (
      <div className="flex flex-wrap p-2 align-items-center gap-2 border-bottom">
        <div className="flex-1 flex flex-column">
          <span className="font-bold">{item.content_type.app_label}</span><span> - {item.name}</span>
        </div>
      </div>
    );
  };

  return (
    <div>
     
      <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
             
                <h4>Edit Permission</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to="/listColor"
                      className="text-decoration-none text-black"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      Product Details
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to="/listbrand"
                      className="text-decoration-none text-black"
                    >
                      Permission
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Edit Permission</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <form
                ref={focus}
                onKeyUp={(event) => onEnterKey(event)}
                onSubmit={handleSubmit(onSubmit)}
              >
                <div
                  className="card-body"
                  style={{ maxHeight: "80vh", overflow: "auto" }}
                >
                 {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message?.name} /> : ""}
                  <div className="row d-flex justify-content-md-center justify-content-lg-start">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            User Type<span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="input-group" data-colorpicker-id={2}>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputBrand"
                              placeholder="Add User Type"
                              {...register("name", {onChange:handleUpper,
                                required: {
                                  value: true,
                                  message: "User Type is required",
                                },
                                maxLength: 30,
                              })}
                              
                            />
                            <div className="input-group-append">
                              <span className="input-group-text border-start-0 bg-primary">
                                <RiPencilLine color="white" />
                              </span>
                            </div>
                          </div>
                          <span className="text-red w-100">
                            {errors?.name?.message}
                          </span>
                        </div>
                      </div>
                    </div>
                    {loader&& <div className="spinner-grow" 
                    // style={{top: "55%",position:"absolute",z-index: "99",left: "23%"}}
                    style={{top:"55%",position:"absolute",zIndex:99,left:"23%"}}
                     role="status">
  <span className="sr-only">Loading...</span>
</div>}
                    <div className="col-md-12 mt-3">
                      <div className="row">

                     
                        <PickList
                         {...register("permissions", {
                          required: {
                            value: false,
                            message: "Name is required",
                          },
                          maxLength: 30,
                        })}
                      
                          source={source}
                          target={target}
                          onChange={onChange}
                          itemTemplate={itemTemplate}
                          showSourceControls={false}
                          showTargetControls={false}
                          filter
                          filterBy="name"
                          breakpoint="1400px"
                          sourceHeader="Available Permissions"
                          targetHeader="Selected Permissions"
                          sourceStyle={{ height: "15rem", fontSize: "13px" }}
                          targetStyle={{ height: "15rem", fontSize: "13px" }}
                          sourceFilterPlaceholder="Search by Permission"
                          targetFilterPlaceholder="Search by Permission"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row d-flex  ">
                    <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                      <div className="row d-flex ">
                        <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                          <button
                            type="button"
                            className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                            style={{
                              borderColor: "rgb(0, 54, 101)",
                              background: "rgb(0, 54, 101)",
                              color: "white",
                            }}
                            onClick={handleSubmit(onSubmit)}
                            // disabled={isLoading}
                          >
                            Submit
                          </button>
                        

                          <button
                            type="button"
                            className=" border-1 rounded  px-4 py-1 mx-3 mt-2"
                            style={{
                              borderColor: "red",
                              background: "red",
                              color: "white",
                            }}
                            onClick={() => navigate("/listGroup")}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Edit_UserType;