import React, { useEffect } from 'react'
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import "./toast.css"
import MuiAlert, { AlertProps } from '@mui/material/Alert';


export interface State extends SnackbarOrigin {
    open: boolean;
}

const Mui_Toaster = ({message,severity,Isopen}:any) => {


    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
      ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });

    const [state, setState] = React.useState<State>({
        open: false,
        vertical: 'top',
        horizontal: 'right',
    });
    const { vertical, horizontal, open } = state;

    const handleClick = () => {
        setState({ ...state, open: true  });
    };

    const handleClose = () => {
        setState({ ...state, open: false });
    };
    
    useEffect(() => {
        if(Isopen){
            handleClick()
        }
    }, [Isopen])
    

    return (

        <>
            <div>
               
                <Snackbar autoHideDuration={5000}
                    anchorOrigin={{ vertical, horizontal }}
                    open={open}
                    onClose={handleClose}

                    key={vertical + horizontal}
                    >


                    <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                    </Alert>
                </Snackbar>
            </div>

        </>

    )
}

export default Mui_Toaster