import React from "react";
import TextBoxComponent from "./Input Component/TextBoxComponent";
import RadioButtonComponent from "./Input Component/RadioButtonComponent";
import DropDownListComponent from "./Input Component/DropDownListComponent";
import CheckBoxComponent from "./Input Component/CheckBoxComponent";
import React_Select_DropDownListComponent from "./Input Component/React_Select_DropDownListComponent";
import DatePickerComponent from "./Input Component/DatePickerComponent";
import TimePickerComponent from "./Input Component/TimePickerComponent";


const Input = ({ value, onChange, type, ...rest }: any) => {
 switch (type) {
   case "text":
     return (
       <TextBoxComponent
         placeholder={rest?.placeholder}
        //  onChange={(e:any) => {onChange(e.target.value)}}
        onChange={onChange}
         value={value}
       />
     );
   case "radio":
     return rest?.options.map((e:any) => (
       <RadioButtonComponent
         key={e}
         label={e}
         value={e}
         onChange={(e:any) => {onChange(e.target.value)}}
         checked={value === e}
       />
     ));
   case "dropdown":
     return (
       <DropDownListComponent
        options={rest?.options}
        onChange={(e:any) => {onChange(e.target.value)}}
         selectedOption={value}
       />
     );


    case "react_dropdown":
     return (
       <React_Select_DropDownListComponent
        options={rest?.options}
        onChange={(e:any) => {
          onChange(e)
        }}
        selectedOption={value}
       />
     );

   case "checkbox":
     return (
       <CheckBoxComponent
         label={rest?.checkboxLabel}
         onChange={(e: any) => onChange(e.target.checked)}
         checked={value}
         required={rest.rules.required}
       />
     );


     case "datepicker":
        return (
          <DatePickerComponent
          value={value}
            onChange={(date: any) => onChange(date)}
          />
        );

        case "timepicker":
          
        return (
          <TimePickerComponent
          value={value}
            onChange={(time: any) => onChange(time)}
          />
        );

   default:
     return null;
 }
};

export default Input;