import React from 'react'
import { RiPencilLine } from 'react-icons/ri'

interface RadioField {
    key?: any,
    id?: any,
    name?: any,
    onChange?: any,
    onClick?: any,
    type?: any,
    accept?: any,
    className?: any,
    placeholder?: any,
    htmlFor?: any,
    color?: any
    isPencil?:any
    value?:any,
    label?: any
    checked?: boolean
  }

const RadioButtonComponent = ({ key, id, name, onChange, type, className, placeholder, label, color ,isPencil=true, value, checked}: RadioField) => {
    return (
        <div className='mx-2' style={{marginTop:"10px"}}>
           
                <input type={"radio"} 
                    id={id} placeholder={placeholder} value={value}  onChange={onChange} checked={checked}/>
                    <label htmlFor={id} className='mx-1'>{label}</label>
                  {/* {isPencil && <div className={className ? className : "input-group-append"}>
                      <span className={className ? className : "input-group-text bg-white border-start-0"}><RiPencilLine color={color} /></span>
                  </div>} */}
          
        </div>
    )
}

export default RadioButtonComponent