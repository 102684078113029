import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { useNavigate, useParams } from "react-router-dom";
import { useGSTEdit, useUpdateGst } from "../../../hooks/GST";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import { useCreate, useFetchSingle, useUpdateSingle } from "../../../hooks/Api";
import Select from "react-select"
import axios from 'axios'
import { Alert } from "../../../components/Model/Model";
import useFocusOnEnter from "../../../components/focus";
import { useFetchEmployeeDropDown } from "../../../hooks/DropDown_Api";
// import { Alert } from "../Model/Model";
// import Select from 'react-select/async';

const Assign_To = () => {
    let pageName = "Assign To"
    const focus:any = useRef();
    const {onEnterKey} = useFocusOnEnter(focus);
    const { id } = useParams()
    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_SALES_ONLINE_INQUIRY_API?.toString()
    const apinameCustomerAllocation = process.env.REACT_APP_SALES_CUSTOMER_ALLOCATION_API?.toString()
    const { data } = useFetchSingle(id,apiname)
    // const { mutate, isSuccess, isError,error,data: Updated_response }: any = useCreate(apiname)
    const { mutate, isSuccess, isError,error,data: gstUpdated_response }: any = useUpdateSingle()

    const { register, handleSubmit, setError, formState: { errors }, reset, setValue }:any = useForm();


   const handleReset=()=>{
    reset()
   }

  
    useEffect(() => {
        if (data) {
            reset(data)
            setValue("assign_to",data?.assign_to?.id)
            setSelectedEmployee(data?.assign_to?.id)
        }
    }, [data]);


    const onSubmit = (Details: any) => {


  
        let data = {
          apiname: apiname,
          id:id,
          data: {assign_to:forEmployeeValue.value}
      }
        mutate(data);
        
       
    }


const[selectedEmployee,setSelectedEmployee]:any = useState("")
    const [employeeOptions, setEmployeeOptions]:any = useState()
    const [forEmployeeValue, setForEmployeeValue]:any = useState("")

    const { data: employeeData, refetch: employeeRefetch } = useFetchEmployeeDropDown()


    useEffect(() => {
        setEmployeeOptions(employeeData)
    }, [employeeData])
  
    const handleChangeEmployee = (e: any) => {

        setForEmployeeValue(e)
        setValue("assign_to", e.value)

    }



    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")


    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listOnlinesalesinquiry', { state: { showMouldShapeEditToast: true }})
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [gstUpdated_response, error,isSuccess])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    const handleValue=(e:any)=>{
        e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
    }

    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>{pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Sales And Marketing</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">{pageName}</a></li>
                                    <li className="breadcrumb-item active">{pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Source Name</span></h5>
                            </div> */}
                            <form ref={focus}
                             onKeyUp={event => onEnterKey(event)}
                             onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{maxHeight:"80vh"}}>
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-sm-4 mt-2">
                                            <label >Company Name</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" disabled  
                                                    value={data?.company_name}
                                                    />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    {/* <span className='text-red w-100'>{errors?.three?.message}</span> */}
                                                </div>

                                                <div className="col-sm-4 mt-2">
                                            <label >Product Type</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" disabled
                                                    value={data?.product_type?.type}  
                                                    />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    {/* <span className='text-red w-100'>{errors?.three?.message}</span> */}
                                                </div>


                                                <div className="col-sm-4 mt-2">
                                            <label >Product Qty</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" disabled  
                                                    value={data?.product_qty}
                                                    />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    {/* <span className='text-red w-100'>{errors?.three?.message}</span> */}
                                                </div>


                                                <div className="col-sm-4 mt-2">
                                            <label >UOM</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" disabled  
                                                    value={data?.uom?.name?.name}
                                                    />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    {/* <span className='text-red w-100'>{errors?.three?.message}</span> */}
                                                </div>


                                                <div className="col-sm-4 mt-2">
                                            <label >Requirement Date</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" disabled  
                                                    value={data?.requirement_date}
                                                    />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    {/* <span className='text-red w-100'>{errors?.three?.message}</span> */}
                                                </div>


                                                <div className="col-sm-4 mt-2">
                                            <label >Mobile Number</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" disabled 
                                                    value={data?.mobile_number} 
                                                    />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    {/* <span className='text-red w-100'>{errors?.three?.message}</span> */}
                                                </div>


                                                <div className="col-sm-4 mt-2">


<label >Employee Code,Surname & Name <span style={{ color: 'red' }}>*</span></label>

<Select
    {...register("assign_to", { required: { value: true, message: "Employee Code,Surname & Name is required" } })}
    onChange={handleChangeEmployee}
    options={employeeOptions}
    value={forEmployeeValue==""?employeeOptions?.filter((e:any)=>e.value === selectedEmployee):forEmployeeValue}
    // isOptionDisabled={(options: any) => {
    //     if (options.status === "inactive" || options.deleted == true) {
    //         return options
    //     }
    // }}

/>
<span className='text-red w-100'>{errors?.employee?.message}</span>
</div>
                                           
                                              
                                              
                                                
                                               
                                                {/* <div className="col-sm-4 mt-2">
                                                    <label >Status</label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Field is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>

                                                </div> */}

                                             
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                <button 
                                                type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3" 
                                                style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                
                                                onClick={handleSubmit(onSubmit)}
                                                >Submit</button>
{/* <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button> */}

<button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listOnlinesalesinquiry")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Assign_To


