
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../../components/Table/column";
import { updateColor, useFetchColors, useUpdateSingleColor } from "../../../hooks/color/color";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../../components/Cell/Status";
import Actions from "../../../components/Cell/Actions";
import { updateBrand, useFetchBrands } from "../../../hooks/brand";
import noImage from "../../images/noImage.png"
import { Update, useFetch } from "../../../hooks/Api";
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

interface Data {
  selection: any

  code:any
  number:any
  route_name:any
  city:any
  total_city:any
  salesman_employee:any
  area_manager:any
  state_manager:any
  sales_manager:any
  general_manager:any
  sheth_employee:any
  country:any
  state:any

  



  created_at:any
  created_by:any
  updated_by:any
  updated_at:any

  status: any;
  actions: any;
}



export const titleofheaders=[
  {
    id: `srno`,
    Header: `Sr No`,
  },


  {
    id: `code`,
    Header: `Code`,
  },

  {
    id: `number`,
    Header: `Number`,
  },

  {
    id: `route_name`,
    Header: `Route Name`,
  },




  {
    id: `city`,
    Header: `City`,
  },


  {
    id: `total_city`,
    Header: `Total City`,
  },
  {
    id: `salesman_employee`,
    Header: `Salesman`,
  },
  {
    id: `area_manager`,
    Header: `Area Manager`,
  },
  {
    id: `state_manager`,
    Header: `State Manager`,
  },

  {
    id: `sales_manager`,
    Header: `Sales Manager`,
  },
  {
    id: `general_manager`,
    Header: `General Manager`,
  },
  {
    id: `sheth_employee`,
    Header: `Sheth Employee`,
  },

  {
    id: `state`,
    Header: `State`,
  },

  {
    id: `country`,
    Header: `Country`,
  },
  




  
  
  
  
  
  
  
  
  
  
  
  
















 
      {
        id: 'status',
        Header: 'Status',
    
     
      },

   {
    id: 'created_at',
    Header: `Created At`,
  },


  {
    id: 'created_by',
    Header: `Created By`,

 
  },
  {
    id: 'updated_at',
    Header: `Updated At`,

  },
  {
    id: 'updated_by',
    Header: `Updated By`,

  },

  

 
]





export const Columns_Zone: any = [



 
  {
    id: `srno`,
    Header: () => <div style={{display:"inline"}}>Sr<br/>No</div>,
    sticky:"left",
  },
  {
    id: `code`,
    Header: () => <div style={{display:"inline"}}>Zone<br/>Code</div>,
    sticky:"left",
    accessor: `code`,
    
    
   
  
  },

  

  {
    id: `number`,
    Header: () => <div style={{display:"inline"}}>Zone<br/>Number</div>,
    accessor: `number`,
    // sticky:"left",
  },

  {
    id: `route_name`,
    Header: () => <div style={{display:"inline"}}>Route<br/>Name</div>,
    accessor: `route_name`,
  },




  {
    id: `city`,
    Header: () => <div style={{display:"inline"}}>Zone City<br/>Name</div>,
    accessor: `city`,
    Cell: ({ row }: any) => {
      let employee =  row.original.city?.map((e:any)=>{
       return e.city_name?e.city_name:e.village_name
      })
      return(
       <div>
         {employee?.join(",")}
       </div>
     )
   
   }
  },


  {
    id: `total_city`,
    Header: () => <div style={{display:"inline"}}>Zone<br/>Total City</div>,
    accessor: `total_city`,
  },
  {
    id: `state`,
    Header: () => <div style={{display:"inline"}}>State<br/>Name</div>,
    accessor: `state`,
    Cell: ({ row }: any) => row.original.city[0]?.state?.name
  },

  {
    id: `country`,
    Header: () => <div style={{display:"inline"}}>Country<br/>Name</div>,
    accessor: `country`,
    Cell: ({ row }: any) => row.original.city[0]?.country?.name
  },
  {
    id: `salesman_employee`,
    Header: () => <div style={{display:"inline"}}>City Sales Exicutive Code<br/>Code & Surname & Name </div>,
    accessor: `salesman_employee`,
    Cell: ({ row }: any) => `${row.original.salesman_employee?.name}`
  },
  {
    id: `area_manager`,
    Header: () => <div style={{display:"inline"}}>Area Sales Manager<br/>Code & Surname & Name </div>,
    accessor: `area_manager`,
    Cell: ({ row }: any) => row.original.area_manager?.name
  },
  {
    id: `state_manager`,
    Header: () => <div style={{display:"inline"}}>State Sales Manager<br/>Code & Surname & Name </div>,
    accessor: `state_manager`,
    Cell: ({ row }: any) => row.original.state_manager?.name
  },

  {
    id: `sales_manager`,
    Header: () => <div style={{display:"inline"}}>Country Sales Manager<br/>Code & Surname & Name </div>,
    accessor: `sales_manager`,
    Cell: ({ row }: any) => row.original.sales_manager?.name
  },
  {
    id: `general_manager`,
    Header: () => <div style={{display:"inline"}}>General Sales Manager<br/>Code & Surname & Name </div>,
    accessor: `general_manager`,
    Cell: ({ row }: any) => row.original.general_manager?.name
  },
  {
    id: `sheth_employee`,
    Header: () => <div style={{display:"inline"}}>Owner Sales Deparment<br/>Code & Surname & Name </div>,
    accessor: `sheth_employee`,
    Cell: ({ row }: any) => row.original.sheth_employee?.name
  },
  {
    id: `owner_all_management_code`,
    Header: () => <div style={{display:"inline"}}>Owner All Management<br/>Code & Surname & Name </div>,
    accessor: `owner_all_management_code`,
    Cell: ({ row }: any) => row.original.owner_all_management_code?.name
  },

  



  
  
  {
    id: 'created_at',
    Header: () => <div style={{display:"inline"}}>Created<br/>At</div>,
    accessor:'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{display:"inline"}}>Created<br/>By</div>,
    accessor:'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username

 
  },
  {
    id: 'updated_at',
    Header: () => <div style={{display:"inline"}}>Updated<br/>At</div>,
    accessor:'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{display:"inline"}}>Updated<br/>By</div>,
    accessor:'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },

  {
    id: 'status',
    Header: 'Status',
    accessor:'status',

 
  },

  {
    id: 'Actions',
    Header: 'Actions',

 
  },
  {
    id: 'Selection',
    // sticky:"left",
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_Zone, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows} = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ZONE_API}/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]
