import React, { useEffect, useRef } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import "../../components/Table/table.css"
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
// import { useCartonPost } from "../../hooks/Carton";
import { useState } from "react";
import Select from 'react-select'
import axios from 'axios'
import { useCreate } from "../../hooks/Api";
import { Alert } from "../../components/Model/Model";
import { RiPencilLine } from "react-icons/ri";
import { useFetchDepartmentDropDown } from "../../hooks/DropDown_Api";
import useFocusOnEnter from "../../components/focus";
import { AiOutlinePlus } from "react-icons/ai";
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/esm/Button";
import { Department_Model } from "../../components/Dynamic_Form_Models/Department_Model";
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'

const customIndicator = {
    IndicatorsContainer:IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer:IndicatorsContainerCreate
}

const Add_NumberSystem = () => {
    const focus:any = useRef();
    const {onEnterKey} = useFocusOnEnter(focus);
    const [open,setOpen] = useState(false)

    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_NUMBER_SYSTEM_API?.toString()

    // const {data:fetchAllCarton}=useCreate(apiname)
    const { mutate, data, isError, isLoading, isSuccess,error }:any = useCreate(apiname)
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue, getValues }: any = useForm({
        mode: "onChange"
    });

    const [optionsDepartment, setOptionsDepartment]:any = useState([])
    const [searchTerm, setSearchTerm] = useState('')


    const { data: colorData, refetch: colorRefetch } = useFetchDepartmentDropDown(searchTerm)

    useEffect(() => {
        setOptionsDepartment(colorData)
    }, [colorData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            colorRefetch()
        }
    }, [])


   



    const handleInputChange = (e: any) => {
      
        setSearchTerm(e)

    }
   
    const handleChange = (e: any) => {
        setSearchTerm(e)
        setValue("department", e.value)
        setForValue({label:e?.label,value:e?.value})
    }

    const [forValue,setForValue]:any = useState([])

    const handleReset = () => {
        reset()
        setForValue(null)
    }

    const onSubmit = (pannidetail: any) => {
        const formData = new FormData();
                for (const [key, value] of Object.entries(pannidetail)) {
            const formvalue: any = value

            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else if (formvalue.hasOwnProperty('size')) {
                formData.append(key, formvalue[0])
            }
            else if (key === "panni_image" || key === "panni_drawing") {
                if (typeof value === 'object' && value !== null) {
                    formData.append(key, formvalue[0])
                }
                else {
                    formData.append(key, "")
                }
            }

        }
        mutate(formData);
    }

 


   
    // useEffect(() => {
    //     if (data) {

    //         reset(data)
    //     }
    // }, [data]);
 

   

   




    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listAllCode', { state: { showToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error ])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);


    // const handleValue=(e:any)=>{
    //     e.target.value = e.target.value.toLowerCase()
    // .split(' ')
    // .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
    // .join(' ');
    // }

    const [submitted,setSubmitted] = useState(false)


    useEffect(()=>{
        if(submitted){
            setForValue(optionsDepartment[0])

            setValue("department", optionsDepartment[0].value)

        }

    },[submitted,optionsDepartment])


    return (
        <div>

            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Add All Table Code</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Product Details</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listAllCode" className="text-decoration-none text-black">All Table Code</Link></li>
                                    <li className="breadcrumb-item">Add All Table Code</li>

                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Number-System</span></h5>
                            </div> */}
                            <form 
                             ref={focus}
                             onKeyUp={event => onEnterKey(event)}
                            onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>
                                {show && showmessage === "danger"  ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}
                                    
                                    <div className="row d-flex justify-content-md-  center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-3 mt-2">
                                                <label htmlFor="name_and_grade">Department <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select 
                                                            {...register("department", { required: { value: true, message: "Department is required" } })}
                                                            placeholder={'Department'}
                                                            onChange={handleChange}
                                                            value={forValue}
                                                            components={customIndicator}
                                                            options={optionsDepartment}
                                                            // isOptionDisabled={(optionsDepartment:any)=>
                                                            //     {
                                                            //         if(optionsDepartment.status === "inactive" || optionsDepartment.deleted == true){
                                                            //         return optionsDepartment
                                                            //         }
                                                            //        }}
                                                            className="dropdown-select"
                                                        />
                                                      <div className="input-group-append" style={{width:0, padding:0}}>
                                                            <Button className="input-group-text border-start-0 bg-primary"  onClick={()=>{
                                                                setOpen(true)
                                                                }}><AiOutlinePlus/></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.department?.message}</span>

                                                </div>
                                            <div className="col-sm-3 mt-2">
                                                    <label htmlFor="table_sr_no">Table Sr.No. <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                    <input type="number" placeholder="Table Sr.No."  className="form-control" id="code_name"  {...register("table_sr_no", {  required: { value: true, message: "Table Sr.No is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            /></span>
                                                        </div>
                                                        </div>
                                                    <span className='text-red w-100'>{errors?.table_sr_no?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="code_name">Code Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                    <input type="text" placeholder="Code Name "  className="form-control" id="code_name"  {...register("code_name", {  required: { value: true, message: "Code Name is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.code_name?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="sub_name">Sub Name </label>
                                                    <div className="input-group" >
                                                    <input type="text" placeholder="Sub Name "  className="form-control" id="code_name"  {...register("sub_name")} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.sub_name?.message}</span>
                                                </div>
                                                
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="full_code">Full Code </label>
                                                    <div className="input-group" >
                                                    <input type="text" placeholder="Full Code"  className="form-control" id="full_code"  {...register("full_code")} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="default_no">Default No </label>
                                                    <div className="input-group" >
                                                    <input type="text" placeholder="Default No"  className="form-control" id="default_no"  {...register("default_no")} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="manualcode_1">Manual Code 1 </label>
                                                    <div className="input-group" >
                                                    <input type="text" placeholder="Manual Code 1"  className="form-control" id="manualcode_1"  {...register("manualcode_1")} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            /></span>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="manualcode_Disc_1">Manual Code Description-1 </label>
                                                    <div className="input-group" >
                                                    <input type="text" placeholder="Manualcode Description-1"  className="form-control" id="manualcode_Disc_1"  {...register("manualcode_Disc_1")} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            /></span>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="manualcode_2">Manual Code 2 </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" placeholder="Manual Code 2"   className="form-control" id="manualcode_2"  {...register("manualcode_2")} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="manualcode_Disc_2">Manual Code Description-2 </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" placeholder="Manual Code Description-2"   className="form-control" id="manualcode_Disc_2"  {...register("manualcode_Disc_2")} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="serial_no">Serial No. </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" placeholder="Serial No."   className="form-control" id="serial_no"  {...register("serial_no")} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="brand_type">Working-Running <span style={{ color: 'red' }}>*</span></label>
                                                    <select  className="form-select" {...register("code_type", { required: { value: true, message: "Working-Running is required" } })}>
                                                        <option value={""} selected>Select Any</option>
                                                        <option  value={"working"}>Working</option>
                                                        <option  value={"running"}>Running</option>
                                                        <option  value={"hold"}>Hold</option>
                                                        <option  value={"pending"}>Pending </option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.code_type?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="brand_type">Check List <span style={{ color: 'red' }}>*</span></label>
                                                    <select  className="form-select" {...register("check_list", { required: { value: true, message: "Check List is required" } })}>
                                                        <option value={""}  selected>Select Any</option>
                                                        <option  value={"ok"}>Ok</option>
                                                        <option  value={"not_ok"}>Not Ok</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.check_list?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="remarks">Remarks </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" placeholder="Remarks"  className="form-control" id="remarks"  {...register("remarks")} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                             
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                <button 
                                                type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3" 
                                                style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                onClick={handleSubmit(onSubmit)} disabled={isLoading}>Submit</button>

<button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

<button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listAllCode")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
            <Department_Model refetchForm={colorRefetch} open={open} setOpen={setOpen} setSubmitted={setSubmitted}/>

            {/* <Footer /> */}
        </div>
    )
}

export default Add_NumberSystem


