import React, { useEffect, useRef, useState } from 'react'
import { AiOutlineMail, AiOutlinePlus } from 'react-icons/ai';
import Select from "react-select";
import { RiPencilLine } from "react-icons/ri";
import { Link, useNavigate, useParams } from 'react-router-dom';
import useFocusOnEnter from '../../../components/focus';
import { useForm } from 'react-hook-form';
import { useFetchActionDropDown, useFetchCityDropDown, useFetchDepartmentDropDown, useFetchEmployeeDropDown, useFetchItemDropDown, useFetchMaintenanceIssueDropDown, useFetchMaterialTypeDropDown, useFetchStockDropDown, useFetchUomDropDown } from '../../../hooks/DropDown_Api';
import { useFetchSingle, useUpdateSingle, useCreate } from "../../../hooks/Api";
import { DatePicker, TimePicker, Upload } from 'antd';
import Button from "react-bootstrap/esm/Button";
import dayjs from 'dayjs';
import { RangePickerProps } from 'antd/es/date-picker';
import { Alert } from "../../../components/Model/Model";
import { nanoid } from 'nanoid';
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import Axiosinstance from '../../../hooks/Interceptor';
import Modal from "react-bootstrap/Modal";
import CreatableSelect from 'react-select/creatable';
import { Department_Model } from "../../../components/Dynamic_Form_Models/Department_Model";
import { Employee_Model } from '../../../components/Dynamic_Form_Models/Employee_Model';
import type { UploadFile } from 'antd/es/upload/interface';
import cameraLogo from "../../../images/Export/Camera.png";
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import noImage from "../../../images/noImage.png"
import axios from 'axios';
import { useQueryClient } from 'react-query';




interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});


const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}

const Add_Maintenance_Action = () => {
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const [inquiryModal, setInquiryModal] = useState(false)


    const apinameAction = process.env.REACT_APP_MAINTENANCE_ACTION_FETCH_API?.toString()

    const {
        mutate: addMaintenanceMultipleAction,
        data: addMaintenanceMultipleActionData,
        isError: addMaintenanceMultipleActionIsError,
        isLoading: addMaintenanceMultipleActionIsLoading,
        isSuccess: addMaintenanceMultipleActionIsSuccess,
        error: addMaintenanceMultipleActionError,
    }: any = useCreate(apinameAction);



    const apinameAddMaintenance = process.env.REACT_APP_MAINTENANCE_DETAIL_API?.toString()

    const {
        mutate: addMaintenance,
        data: addMaintenanceData,
        isError: addMaintenanceIsError,
        isLoading: addMaintenanceIsLoading,
        isSuccess: addMaintenanceIsSuccess,
        error: addMaintenanceError,
    }: any = useCreate(apinameAddMaintenance);
    const [activeRow, setActiveRow]: any = useState()

    // const apinameAddMaintenanceAction = `maintenance/maintenanceDetail/add_maintance_action/?id=${activeRow}`

    // useEffect(()=>{

    //     if(activeRow){


    //     const formData = new FormData();

    //     for (const [key, value] of Object.entries(data)) {
    //         const formvalue: any = value
    //         if (key === "resolve_complaint_image") {
    //             for (let i = 0; i < logo.length; i++) {
    //                 formData.append('resolve_complaint_image', logo[i]);
    //             }
    //         } else if (typeof value !== "object") {
    //             formData.append(key, formvalue);
    //         }
    //     }

    //     // Remove other_status from formData if OtherFault is not "Other"
    //     if (OtherFault !== "Other") {
    //         formData.delete('other_status');
    //     }

    //         Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}/maintenance/maintenanceDetail/add_maintance_action/?id=${activeRow}`,formData).then(
    //           (data:any)=>console.log('data',data)  
    //         )
    //     }
    // },[activeRow])

    // const {
    //     mutate: addMaintenanceAction,
    //     data: addMaintenanceActionData,
    //     isError: addMaintenanceActionIsError,
    //     isLoading: addMaintenanceActionIsLoading,
    //     isSuccess: addMaintenanceActionIsSuccess,
    //     error: addMaintenanceActionError,
    // }: any = useCreate(apinameAddMaintenanceAction);






    const apinameGetMultipleMaintenance = process.env.REACT_APP_MAINTENANCE_FETCH_API?.toString();




    const {
        mutate: getMaintenance,
        data: getMaintenanceData,
        isError: getMaintenanceIsError,
        isLoading: getMaintenanceIsLoading,
        isSuccess: getMaintenanceIsSuccess,
        error: getMaintenanceError,
    }: any = useCreate(apinameGetMultipleMaintenance);

    const { id } = useParams()
    const apiname = process.env.REACT_APP_MAINTENANCE_API?.toString()


    const [triggerFetch, setTriggerFetch] = useState(0);


    const { data } = useFetchSingle(id, apiname, triggerFetch)
    const { mutate, isSuccess, isError, error, data: BranchUpdated_response }: any = useUpdateSingle()

    const { register, handleSubmit, setError, formState, reset, setValue, getValues }: any = useForm({
        mode: "onChange"
    });
    const { errors } = formState;

    const { register: registerAddMaintenance, handleSubmit: handleSubmitAddMaintenace, setError: setErrorAddMaintenace, formState: formStateAddMaintenace, reset: resetAddMaintenace, setValue: setValueAddMaintenace, getValues: getValuesAddMaintenace }: any = useForm();
    const { errors: errorsAddMaintenace } = formStateAddMaintenace;

    const { data: productTypeData, refetch: productTypeRefetch } = useFetchMaterialTypeDropDown();



    const [department, setDepartment]: any = useState('')
    const [IssueRegistered, setIssueRegistered]: any = useState('')
    const [ReferencePerson, setReferencePerson]: any = useState('')
    const [Location, setLocation]: any = useState('')
    const [ResponsiblePerson, setResponsiblePerson]: any = useState('')
    const [maintenanceDetail, setMaintenanceDetail]: any = useState([])
    console.log(maintenanceDetail, "maintenanceDetail>>>>>");

    useEffect(() => {
        if (data) {
            reset(data)
            setDepartment(data?.department_name?.department)
            setIssueRegistered(`${data?.issue_registered_by_person_code_and_name?.code} ${data?.issue_registered_by_person_code_and_name?.surname} ${data?.issue_registered_by_person_code_and_name?.name} ${data?.issue_registered_by_person_code_and_name?.father_name}`)
            setReferencePerson(`${data?.refrence_person_code_and_name?.code} ${data?.refrence_person_code_and_name?.surname} ${data?.refrence_person_code_and_name?.name} ${data?.refrence_person_code_and_name?.father_name}`)
            setMaintenanceDetail(data?.maintenance_detail)
            setResponsiblePerson(`${data?.responsible_person?.code} ${data?.responsible_person?.surname} ${data?.responsible_person?.name} ${data?.responsible_person?.father_name}` ? `${data?.responsible_person?.code} ${data?.responsible_person?.surname} ${data?.responsible_person?.name} ${data?.responsible_person?.father_name}` : "")
            setLocation(data?.maintenance_location?.name)
      // setDataForView(data?.maintenance_action)
            console.log(data);

        }
    }, [data])



    const [openIssuePerson, setOpenIssuePerson]: any = useState(false)
    const [openResponsiblePerson, setOpenResponsiblePerson]: any = useState(false)
    const [openReference, setOpenReference]: any = useState(false)
    const [openProduct, setOpenProduct]: any = useState(false)


    const [viewData, setViewData]: any = useState([])





    // const handleOpenView = (field:any) => {
    //     const fieldId = field.id; // The ID to match in updatedTableData

    //     // Check if updatedTableData has a key that matches fieldId
    //     if (updatedTableData.hasOwnProperty(fieldId)) {
    //         // Retrieve the data associated with that key
    //         const matchedData = updatedTableData[fieldId];

    //         // Assuming matchedData is an array of objects, and you want to extract a specific property
    //         const ids = matchedData?.map((item:any) => item.maintenance_action);

    //         console.log(ids); // Log the ids or process them as needed
    //         setViewData(ids); // Set the data to viewData state
    //     } else {
    //         console.log("No matching key found for this ID in updatedTableData");
    //         setViewData([]); // Set viewData to an empty array or a default state
    //     }
    // };






    const [OtherFault, setOtherFault]: any = useState()

    const handleChangeOtherFault = (e: any) => {

        setOtherFault(e.target.value)

    }

    const [openUOM, setOpenUOM] = useState(false)
    const [showOtherUom,setShowOtherUom]:any =useState(false)

    const [forUOMValue, setForUOMValue]: any = useState([])

    const [optionsUom, setOptionsUom]: any = useState([])
    const { data: UomData, refetch: UomRefetch } = useFetchUomDropDown()
    useEffect(() => {
        // setOptionsUom(UomData)
        if (UomData){
            let temp_uom_data:any = UomData?.map((e: any) => e)
            temp_uom_data?.unshift({label:'Other',value:'other'})
            setOptionsUom(temp_uom_data)
        }
    }, [UomData])

    const handleChangeUom = (e: any) => {
        
        // setValueAddMaintenace("uom", `${e.value}`)

        // setForUOMValue({ label: e?.label, value: e?.value })
        if(e.value==='other'){
            setForUOMValue({ label: e?.label, value: e?.value })
            setShowOtherUom(true)
            setValueAddMaintenace("uom",'')
        }
        else{
            setForUOMValue({ label: e?.label, value: e?.value })
            setShowOtherUom(false)
            setValue('other_uom','')
            setValueAddMaintenace("uom", `${e?.value}`)
        }

    }






    const [requirementDate, setRequirementDate] = useState(null)

    const dateFormatList = ['DD-MM-YYYY'];
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {





        return current && current < dayjs().startOf('day');
    };





    const apinameForSubmit: any = process.env.REACT_APP_MAINTENANCE_DETAIL_UPDATE_ACTION_API?.toString()


    const onSubmit = async (details: any) => {
        details.maintenance_status = "Completed";

        const formData = new FormData();




        for (const [key, value] of Object.entries(details)) {
            const formvalue: any = value



            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }






        }

        let data = {
            apiname: apiname,
            id: id,
            data: formData
        }
        mutate(data);



        // let tempData = JSON.stringify(updatedTableData)



        // Axiosinstance.post(`${apinameForSubmit}/`, updatedTableData)
        //     .then(response => {
        //         console.log('Success:', response.data);
        //     })
        //     .catch(error => {
        //         console.error('Error:', error);
        //     });

        // let data = {
        //     apiname: apiname,
        //     id: id,
        //     data: JSON.stringify(tempData)
        // }
        // mutate(data);



        // if (Array.isArray(MaintenanceIds)) {
        //   MaintenanceIds.forEach((val: any, index: any) => formData.append(`maintenance_detail`, val))
        // }

        // if (displayData?.length === 0) {
        //   setNoProductError(true)
        // } else {
        //   await mutate(formData);
        // }
    }



    const handleReset = () => {
        reset()
        setRequirementDate(null)
        setRequirementDate(null)
    }



    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listMaintenanceResponsible', { state: { showToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error, isSuccess])



    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 5000)

    }, [show]);





















    const [additionalFields, setAdditionalFields]: any = useState(false);

    // const handleAddMaintenance = () => {

    //   if(isMaintenanceLocation === null){
    //     setErrorMaintenanceLocation(true)
    //   }else{

    //   setAdditionalFields(true)
    //   }

    // };


    const handleDeleteProduct = (id: any) => {

        resetAddMaintenace()
        // setValueMaintenanceIssue(null)
        // setValueMaintenanceIssueCreatable(null)
        setAdditionalFields(false)
    };





    const [added, setAdded] = useState(false)


    const [errorMaintenanceLocation, setErrorMaintenanceLocation] = useState(false)
    const onSubmitAddMaintenance = (data: any) => {
        const formData = new FormData();

        for (let i = 0; i < logo.length; i++) {
            formData.append('image', logo[i]);
        }

        for (const [key, value] of Object.entries(data)) {
            const formvalue: any = value
            if (typeof value !== "object" && value !== undefined) {
                formData.append(key, formvalue);
            }
        }

        // addMaintenance(formData);
        setAdded(true);
    };


    useEffect(() => {
        if (added && addMaintenanceIsSuccess) {
            if (addMaintenanceData) {
                setMaintenanceIds((oldIds: any) => [...oldIds, addMaintenanceData?.data?.id]);


                resetAddMaintenace()
                // setValueMaintenanceIssue(null)
                // setValueMaintenanceIssueCreatable(null)
                setAdditionalFields(false)
                // setDateLimitValue(null)
                // setForTimeLimit(null)
                setAdded(false)
                setLogo([])
                // setUploadImage([])
            }


        }

    }, [addMaintenanceData, addMaintenanceIsSuccess, added])




















    const handleChangeFaultName = (e: any, index: any) => {


        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');



        // setValueAddMaintenace('maintenance_fault', e.target.value)


    };






    const handleChangeReasonDetail = (e: any, index: any) => {

        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');



        // setValueAddMaintenace('reason_detail', e.target.value)



    };




    const [displayData, setDisplayData] = useState([])
    const [MaintenanceIds, setMaintenanceIds]: any = useState([])
    const [noProductError, setNoProductError] = useState(false)




    useEffect(() => {
        if (displayData?.length > 0) {
            setNoProductError(false)
        }
    }, [displayData])




    useEffect(() => {
        if (getMaintenanceData) {
            setDisplayData(getMaintenanceData?.data)
        }
    }, [getMaintenanceData])

    useEffect(() => {
        if (MaintenanceIds) {
            getMaintenance({ ids: MaintenanceIds })
        }
    }, [MaintenanceIds])






    const [renderKey, setRenderKey]: any = useState(false)



    const handleDeleteProductInTable = async (id: any) => {

        await Axiosinstance.delete(`${process.env.REACT_APP_BASE_URL}${apinameAddMaintenance}/${id}/`)

        setMaintenanceIds((currentIds: any) => currentIds.filter((currentId: any) => currentId !== id));

        getMaintenance({ ids: MaintenanceIds })
        setRenderKey((key: any) => key + 1)


    };



    useEffect(() => {
        if (isSuccess) {
            setDisplayData([])
            setMaintenanceIds([])
        }
    }, [isSuccess])




    const [valueItem, setValueItem]: any = useState();
    const [optionsItem, setOptionsItem]: any = useState([]);
    const [valueItemCreatable, setValueItemCreatable] = useState<Option | null>();

    const apinameForItem = process.env.REACT_APP_MAINTENANCE_ITEM_SOLUTION_API?.toString()
    const { mutate: mutateItem, data: dataItem, isError: ItemIsError, isLoading: ItemLoading, isSuccess: ItemSuccess, error: ItemError }: any = useCreate(apinameForItem)

    const { data: ItemData, refetch: ItemRefetch } = useFetchItemDropDown()

    useEffect(() => {
        setOptionsItem(ItemData)
    }, [ItemData])




    const handleItemCreate = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setTimeout(() => {
            const newOption = createOption(inputValue);


            mutateItem({ name: newOption.name, status: newOption.status })
            setOptionsItem((prev: any) => [...prev, newOption]);
            setValueItemCreatable(newOption);
        }, 0);


    };



    const handleChangeItem = (selectedOption: any, index: any) => {

        setValueAddMaintenace('item_solution_type', selectedOption?.value)
        setValueItem(selectedOption)
        setValueItemCreatable(null)

    };

    useEffect(() => {
        (async () => {
            if (ItemSuccess && optionsItem) {
                await ItemRefetch();
                setValueAddMaintenace("item_solution_type", ItemData[0]?.value)

            }
        })();

        return () => {
        };
    }, [ItemSuccess, ItemData]);



    const [valueStock, setValueStock]: any = useState();
    const [optionsStock, setOptionsStock]: any = useState([]);
    const [valueStockCreatable, setValueStockCreatable] = useState<Option | null>();

    const apinameForStock = process.env.REACT_APP_MAINTENANCE_ITEM_STOCK_API?.toString()
    const { mutate: mutateStock, data: dataStock, isError: StockIsError, isLoading: StockLoading, isSuccess: StockSuccess, error: StockError }: any = useCreate(apinameForStock)

    const { data: StockData, refetch: StockRefetch } = useFetchStockDropDown()

    useEffect(() => {
        setOptionsStock(StockData)
    }, [StockData])




    const handleStockCreate = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setTimeout(() => {
            const newOption = createOption(inputValue);


            mutateStock({ name: newOption.name, status: newOption.status })
            setOptionsStock((prev: any) => [...prev, newOption]);
            setValueStockCreatable(newOption);
        }, 0);


    };

    const handleChangeStock = (selectedOption: any, index: any) => {

        setValueAddMaintenace('item_stock_type', selectedOption?.value)
        setValueStock(selectedOption)
        setValueStockCreatable(null)

    };


    useEffect(() => {
        (async () => {
            if (StockSuccess && optionsStock) {
                await StockRefetch();
                setValueAddMaintenace("item_stock_type", StockData[0]?.value)

            }
        })();

        return () => {
        };
    }, [StockSuccess, StockData]);












    // const [uploadImage, setUploadImage] = useState<UploadFile[]>([])
    // const ImageOnChange = (e: any) => {
    //     e.file.status = "done"

    //     const tempFiles = e.fileList.map((file: any) => {
    //         return file.originFileObj
    //     })
    //     setLogo(tempFiles)
    //     setUploadImage(e.fileList)

    //     // setValueAddMaintenace('image',tempFiles)



    // }


    // const handleChangeMaintenanceLocation=(e:any)=>{
    //   setValue('maintenance_location',e.target.value)
    //   console.log(e.target.value)
    //   setValueAddMaintenace('maintenance_location',e.target.value)
    //   setIsMaintenanceLocation(e.target.value)
    //   // setErrorMaintenanceLocation(false)
    // }

    // console.log('isMaintenanceLocation',isMaintenanceLocation)

    // useEffect(()=>{
    //   if(isMaintenanceLocation === null || isMaintenanceLocation === undefined || isMaintenanceLocation === ''){
    //     setAdditionalFields(false)
    //     // setErrorMaintenanceLocation(true)
    //   }
    // },[isMaintenanceLocation])


    // console.log('additional', additionalFields)

    const [showImages, setShowImages] = useState(false)
    const [showImagesAction, setShowImagesAction] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const [fieldImages, setFieldImages] = useState([])
    const [fieldImagesAction, setFieldImagesAction] = useState([])
    // const [ResolveImages, setResolveImages]:any = useState([])

    const handleShowImages = (field: any) => {
        setShowImages(true)
        setShowImagesAction(true)
        setFieldImages(field.image)
        // setResolveImages(field.resolve_complaint_image)
        // setFieldImages(field)
    }
    const handleShowImagesAction = (field: any) => {
        // setShowImages(true)
        // console.log(field,"field===============>");
        
        setShowImagesAction(true)
        setFieldImagesAction(field?.resolve_complaint_image)
       
    }






    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentIndexAction, setCurrentIndexAction] = useState(0);

    const goToPrev = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? fieldImages?.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
        setCurrentIndexAction(newIndex);
    };

    const goToNext = () => {
        const isLastSlide = currentIndex === fieldImages?.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
        setCurrentIndexAction(newIndex);
    };


    const [updatedTableData, setUpdatedTableData]: any = useState([])
    const handleAddAction = (field: any) => {
        setShowModal(true)
        console.log(field, 'field')
        setActiveRow(field?.id)
    }

    const [dataForView, setDataForView] = useState([])


    const handleOpenView = (field: any) => {
        setDataForView(field?.maintenance_action)
        // console.log('viewDatttt=>>>>>>>>',field?.maintenance_action)
        setOpenProduct(true)
        const fieldId = field.id; // The key to match

        Object.keys(updatedTableData).forEach(key => {
            if (key === fieldId) {
                console.log(updatedTableData[key]); // Match found, do something with this data
                setViewData(updatedTableData[key]); // Set the data to state
            }
        });

        // Alternatively, using Object.entries for key-value pairs
        for (const [key, value] of Object.entries(updatedTableData)) {
            if (Number(key) == fieldId) {
                console.log(value); // Match found, do something with this data
                setViewData(value); // Set the data to state
                break; // Exit the loop after finding the match
            }
        }
    };


    console.log('view data', viewData)


    useEffect(() => {
        if (viewData) {
            let temp = viewData?.map((e: any) => e.maintenance_action)
            addMaintenanceMultipleAction({ ids: temp })
        }
    }, [viewData])



    // const [dataForView, setDataForView] = useState([])
    // useEffect(() => {
    //     if (addMaintenanceMultipleActionData) {
    //         setDataForView(addMaintenanceMultipleActionData?.data)
    //     }
    // }, [addMaintenanceMultipleActionData])


    // console.log('dataForView', dataForView, 'addMaintenanceMultipleActionData', addMaintenanceMultipleActionData)









    // const [innerIds, setInnerIds]: any = useState([])
    // useEffect(() => {
    //     if (addMaintenanceActionData && activeRow != null) {
    //         setShowModal(false);




    //     }
    // }, [addMaintenanceActionData]);



    // console.log('updatedTableData', updatedTableData, 'activeRow', activeRow, 'addMaintenanceActionData', addMaintenanceActionData)


    const [checked, setChecked] = useState(0)
    const handleAddModal = (data: any) => {
        if (OtherFault != "Other") {
            delete data?.other_status
        }
        const formData = new FormData();


        for (const [key, value] of Object.entries(data)) {
            const formvalue: any = value

            if (key === "resolve_complaint_image") {
                for (let i = 0; i < logo.length; i++) {

                    formData.append('resolve_complaint_image', logo[i])
                }
                continue
            }
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
        }

        Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}/maintenance/maintenanceDetail/add_maintance_action/?id=${activeRow}&main_id=${id}`, formData).then(

            (data: any) => {
                setShowModal(false)
                queryClient.invalidateQueries(['Single', id, apiname]);
                console.log('data', data)
                setChecked(checked + 1)
            }
        )

        // addMaintenanceAction(formData)

    }



    const [logo, setLogo]: any = useState([])

    const [uploadOkImage, setUploadOkImage] = useState<UploadFile[]>([])
    const OkImageOnChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setLogo(tempFiles)
        setUploadOkImage(e.fileList)

        // setValueAddMaintenace('image',tempFiles)



    }

    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }


    const [optionsFault, setOptionsFault]: any = useState();

    const { data: FaultData, refetch: FaultRefetch } = useFetchActionDropDown();

    useEffect(() => {
        setOptionsFault(FaultData);
    }, [FaultData]);

    const [suggestion, setSuggestions]: any = useState([]);
    const [input, setInput] = useState("");

    const [choosed, setChoosed] = useState(false)

    const handleChange = (e: any) => {
        setChoosed(false)
        let value = e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        let matches = [];
        if (value.length >= 1) {
            const regex = new RegExp(`${value}`, "gi");
            matches = optionsFault?.filter((item: any) => regex.test(item.label));
        }
        setSuggestions(matches);
        setInput(value);
    };




    return (
        <>
            <div>
                <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                    <section className="content-header py-1 px-3">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h4>Maintenance Action</h4>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                        <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                                        <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Maintenance Responsible</Link></li>
                                        <li className="breadcrumb-item active"> Maintenance Action</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="card card-default">

                                <form ref={focus}
                                    onKeyUp={event => onEnterKey(event)}>

                                    {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}
                                    <div className='row'>


                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Responsible Person Code & Name </label>
                                            <input type="text" placeholder="Department Name" className="form-control" disabled value={ResponsiblePerson}
                                            />
                                            <span className='text-red w-100'>{errors?.responsible_person?.message}</span>
                                        </div>

                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Department Name </label>
                                            <input type="text" placeholder="Department Name" className="form-control" disabled value={department}
                                            />
                                            <span className='text-red w-100'>{errors?.department_name?.message}</span>
                                        </div>

                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Issue Registered By Person Code & Name </label>
                                            <input type="text" placeholder="Issue Registered By Person Code & Name" className="form-control" disabled value={IssueRegistered}
                                            />
                                            <span className='text-red w-100'>{errors?.issue_registered_by_person_code_and_name?.message}</span>
                                        </div>

                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Fault Reference Person Code & Name </label>
                                            <input type="text" placeholder="Issue Registered By Person Code & Name" className="form-control" disabled value={ReferencePerson}
                                            />
                                            <span className='text-red w-100'>{errors?.refrence_person_code_and_name?.message}</span>
                                        </div>

                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Maintenance Location</label>
                                            <input type="text" placeholder="Maintenance Location" className="form-control" disabled value={Location}
                                                {...register("maintenance_location", { required: { value: true, message: "Maintenance Location is required" } })}
                                            />
                                            <span className='text-red'>{errors?.maintenance_location?.message}</span>
                                        </div>

                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Status </label>
                                            <input type="text" placeholder="Status" className="form-control" disabled
                                                {...register("status", { required: { value: true, message: "Status is required" } })}
                                            />
                                        </div>



                                        <div className="table-responsive"  >

                                            {noProductError ? <span style={{ color: 'red' }}>Add Atleast One Maintenance</span> : null}

                                            {errorMaintenanceLocation ? <span style={{ color: 'blue' }}>Maintenance Location Is Required To Add Maintenance</span> : null}


                                            <table key={renderKey} className="table table-striped table-bordered" style={{ marginTop: "15px", width: "-webkit-fill-available" }}>
                                                <thead style={{ textAlign: "center" }}>
                                                    <tr>
                                                        <th >Sr. <br /> No</th>
                                                        <th >Maintenance<br />Location</th>
                                                        <th>Fault<br />Name</th>
                                                        <th>Maintenance<br />The Reason</th>
                                                        <th>Reason <br />Detail</th>
                                                        <th>Date <br />Limit</th>
                                                        <th>Take Time<br />To Complete</th>
                                                        <th>Image<br />All</th>
                                                        <th>Count</th>
                                                        {/* <th>View</th> */}
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ textAlign: 'center' }}>
                                                    {maintenanceDetail?.map((field: any, index: any) => (

                                                        < tr key={index} >
                                                            <td>{index + 1}</td>


                                                            <td>

                                                                {field.maintenance_location?.name}

                                                            </td>



                                                            <td>


                                                                {field.maintenance_fault}

                                                            </td>

                                                            <td>


                                                                {field.maintenance_issue?.name}

                                                            </td>

                                                            <td>


                                                                {field?.reason_detail ? field.reason_detail : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}

                                                            </td>
                                                            <td>


                                                                {field.maintenance_date_limit}

                                                            </td>
                                                            <td>
                                                                {field.maintenance_time_limit}
                                                            </td>
                                                            <td>
                                                                {!field.image[0]?.doc ?
                                                                    <>
                                                                        <img src={noImage} height={50} width={50} />
                                                                    </>
                                                                    : <>
                                                                        <img src={field.image[0]?.doc} onClick={() => handleShowImages(field)} alt='no image' width={50} height={50} />
                                                                    </>}

                                                            </td>
                                                            <td>
                                                                {field?.maintenance_action?.length}
                                                            </td>
                                                            {/* <td>
                                                            <button type="button"
                                                                    className="btn btn-success"
                                                                    onClick={() => handleOpenView(field)}>View</button>

                                                            </td> */}
                                                            < td >
                                                                <button type="button"
                                                                    className="btn btn-success"
                                                                    onClick={() => handleAddAction(field)}>Add</button>

                                                                <button type="button"
                                                                    className="btn btn-success"
                                                                    onClick={() => handleOpenView(field)}>View</button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>


                                        </div>


                                        {/* <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Fault Name <span style={{ color: 'red' }}>*</span></label>
                                            <div className="d-flex row" >
                                                <Select
                                                    {...register("responsible_person", { required: { value: true, message: "Fault Name is required" } })}
                                                    placeholder={'Fault Name'}
                                                    onChange={handleChangeResponsiblePerson}
                                                    components={customIndicator}
                                                    // value={ForResponsiblePersonValue}
                                                    options={ResponsiblePersonOptions}
                                                    value={selResponsiblePerson === "" ? ResponsiblePersonOptions?.filter((obj: any) => obj.value === selectedResponsiblePerson) : selResponsiblePerson}
                                                    className="dropdown-select"
                                                />
                                                <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                    <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                        setOpenResponsiblePerson(true)
                                                    }}><AiOutlinePlus /></Button>
                                                </div>
                                            </div>
                                            <span className='text-red w-100'>{errors?.responsible_person?.message}</span>
                                        </div>

                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Item Repairing-New <span style={{ color: 'red' }}>*</span></label>
                                            <CreatableSelect
                                                // isClearable={() => {
                                                //   setValueCountry(null)

                                                //   setValue(null)
                                                // }}
                                                // {...register("country", { required: { value: true, message: "Country Name is required" } })}
                                                // placeholder={'Select Country Name'}
                                                // options={optionsCountry}
                                                // onCreateOption={handleCreateCountry}
                                                // onChange={handleChangeCountry}                            
                                                // onMenuOpen={() => {
                                                //   CountryRefetch()
                                                //   // setIsCountryOpen(true)
                                                // }}
                                                // // onMenuClose={() => setIsCountryOpen(false)}
                                                // value={valueCountryCreatable == null ? valueCountry : valueCountryCreatable}
                                                components={customCreatableIndicator}
                                            // defaultValue={{value:1,label:"India"}}  

                                            // isOptionSelected={(optionsCountry:any)=>
                                            //         {
                                            //             
                                            //             if(optionsCountry.label === "India"){
                                            //             return optionsCountry
                                            //             }
                                            //            }}

                                            />
                                            <span className='text-red w-100'>{errors?.country?.message}</span>
                                        </div>


                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Item Stock<span style={{ color: 'red' }}>*</span></label>
                                            <CreatableSelect
                                                // isClearable={() => {
                                                //     setValueCountry(null)

                                                //     setValue(null)
                                                // }}
                                                // {...register("country", { required: { value: true, message: "Country Name is required" } })}
                                                // placeholder={'Select Country Name'}
                                                // options={optionsCountry}
                                                // onCreateOption={handleCreateCountry}
                                                // onChange={handleChangeCountry}
                                                // onMenuOpen={() => {
                                                //     CountryRefetch()
                                                //     // setIsCountryOpen(true)
                                                // }}
                                                // // onMenuClose={() => setIsCountryOpen(false)}
                                                // value={valueCountryCreatable == null ? valueCountry : valueCountryCreatable}
                                                components={customCreatableIndicator}
                                            // defaultValue={{value:1,label:"India"}}  

                                            // isOptionSelected={(optionsCountry:any)=>
                                            //         {
                                            //             
                                            //             if(optionsCountry.label === "India"){
                                            //             return optionsCountry
                                            //             }
                                            //            }}

                                            />
                                            <span className='text-red w-100'>{errors?.country?.message}</span>
                                        </div>




                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Use Item Name <span style={{ color: 'red' }}>*</span></label>

                                            <input type="text" placeholder="Use Item Name" className="form-control"
                                            />
                                            <span className='text-red w-100'>{errors?.refrence_person_code_and_name?.message}</span>
                                        </div>
                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Use Item Quantity <span style={{ color: 'red' }}>*</span></label>

                                            <input type="text" placeholder="Use Item Quantity" className="form-control"
                                            />
                                            <span className='text-red w-100'>{errors?.refrence_person_code_and_name?.message}</span>
                                        </div>

                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">U.O.M <span style={{ color: 'red' }}>*</span></label>
                                            <div className="d-flex row" >
                                                <Select
                                                    // {...register("responsible_person", { required: { value: true, message: "Fault Name is required" } })}
                                                    // placeholder={'Fault Name'}
                                                    // onChange={handleChangeResponsiblePerson}
                                                    components={customIndicator}
                                                    // value={ForResponsiblePersonValue}
                                                    // options={ResponsiblePersonOptions}
                                                    // value={selResponsiblePerson === "" ? ResponsiblePersonOptions?.filter((obj: any) => obj.value === selectedResponsiblePerson) : selResponsiblePerson}
                                                    className="dropdown-select"
                                                />
                                                <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                    <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                        setOpenResponsiblePerson(true)
                                                    }}><AiOutlinePlus /></Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Fault Status <span style={{ color: 'red' }}>*</span></label>
                                            <select className="form-select" {...register("status",
                                                {
                                                    required: { value: true, message: " Status is required" },
                                                }
                                            )}>
                                                <option value="" selected >Select Any</option>
                                                <option value={"active"}>Active</option>
                                                <option value={"inactive"}>Inactive</option>
                                            </select>
                                            <span className='text-red w-100'>{errors?.status?.message}</span>

                                            
                                        </div>
                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Remark <span style={{ color: 'red' }}>*</span></label>

                                            <input type="text" placeholder="Remark" className="form-control"
                                            />
                                            <span className='text-red w-100'>{errors?.refrence_person_code_and_name?.message}</span>
                                        </div> */}

                                    </div>
                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                        onClick={handleSubmit(onSubmit)}
                                                    >Complete</button>
                                                    {/* <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button> */}

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listMaintenanceResponsible")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>


                        </div>
                    </section >
                </div >





                <Modal show={showImages} onHide={() => setShowImages(false)} aria-labelledby="contained-modal-title-vcenter"
                    centered    >

                    <Modal.Body>
                        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-indicators">

                            </div>



                            <div className="carousel-inner">
                                {fieldImages?.map((imgSrc: any, index) => (
                                    <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
                                        <img src={imgSrc?.doc} className="d-block w-100" alt={`Slide ${index}`} />
                                    </div>
                                ))}

                            </div>

                            <button className="carousel-control-prev" type="button" onClick={goToPrev} >
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" onClick={goToNext} >
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={() => setShowImages(false)}>OK</button>

                    </Modal.Footer>
                </Modal>



                <Modal show={showModal} onHide={() => setShowModal(false)} aria-labelledby="contained-modal-title-vcenter"
                    centered size='xl'  >

                    <Modal.Body>
                        <form >


                            <div className="card-body" style={{ maxHeight: "100vh" }}>
                                <div className="row d-flex justify-content-md- center justify-content-lg-start">
                                    <div className="col-md-12">
                                        <div className="row">
                                            {/* <div className="col-sm-3 mt-2">
                                                <label htmlFor="exampleInputEmail1">Fault Name <span style={{ color: 'red' }}>*</span></label>
                                                <input type="text" placeholder="Fault Name" className="form-control" disabled
                                                />
                                                <span className='text-red w-100'>{errors?.responsible_person?.message}</span>
                                            </div> */}

                                            <div className="col-sm-3 mt-2">
                                                <label htmlFor="exampleInputEmail1">Item Repairing-New <span style={{ color: 'red' }}>*</span></label>
                                                <CreatableSelect
                                                    isClearable={() => {
                                                        setValueItem(null)

                                                        setValueAddMaintenace(null)
                                                    }}
                                                    {...registerAddMaintenance("item_solution_type", { required: { value: true, message: "Item Repairing-New is required" } })}
                                                    placeholder={'Item Repairing-New'}
                                                    options={optionsItem}
                                                    onCreateOption={handleItemCreate}
                                                    onChange={handleChangeItem}
                                                    onMenuOpen={() => {
                                                        ItemRefetch()

                                                    }}

                                                    value={valueItemCreatable == null ? valueItem : valueItemCreatable}
                                                    components={customCreatableIndicator}




                                                />
                                                <span className='text-red w-100'>{errorsAddMaintenace?.item_solution_type?.message}</span>
                                            </div>


                                            <div className="col-sm-3 mt-2">
                                                <label htmlFor="exampleInputEmail1">Item Stock<span style={{ color: 'red' }}>*</span></label>
                                                <CreatableSelect
                                                    isClearable={() => {
                                                        setValueStock(null)

                                                        setValueAddMaintenace(null)
                                                    }}
                                                    {...registerAddMaintenance("item_stock_type", { required: { value: true, message: "Item Stock is required" } })}
                                                    placeholder={'Select Item Stock'}
                                                    options={optionsStock}
                                                    onCreateOption={handleStockCreate}
                                                    onChange={handleChangeStock}
                                                    onMenuOpen={() => {
                                                        StockRefetch()

                                                    }}

                                                    value={valueStockCreatable == null ? valueStock : valueStockCreatable}
                                                    components={customCreatableIndicator}



                                                />
                                                <span className='text-red w-100'>{errorsAddMaintenace?.item_stock_type?.message}</span>
                                            </div>




                                            <div className="col-sm-3 mt-2">
                                                <label htmlFor="exampleInputEmail1">Use Item Name <span style={{ color: 'red' }}>*</span></label>
                                                <div className="input-group" >
                                                    <input type="text" placeholder="Use Item Name" className="form-control"
                                                        {...registerAddMaintenance("use_item_name", { onChange: handleValue, required: { value: true, message: "Use Item Name is required" } })}
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                    </div>
                                                </div>
                                                <span className='text-red w-100'>{errorsAddMaintenace?.use_item_name?.message}</span>
                                            </div>
                                            <div className="col-sm-3 mt-2">
                                                <label htmlFor="exampleInputEmail1">Use Item Quantity <span style={{ color: 'red' }}>*</span></label>
                                                <div className="input-group" >
                                                    <input type="number" placeholder="Use Item Quantity" className="form-control"
                                                        {...registerAddMaintenance("use_item_quantity", { onChange: handleValue, required: { value: true, message: "Use Item Quantity is required" } })}
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                    </div>
                                                </div>
                                                <span className='text-red w-100'>{errorsAddMaintenace?.use_item_quantity?.message}</span>
                                            </div>

                                            <div className="col-sm-3 mt-2">
                                                <label htmlFor="exampleInputEmail1">U.O.M <span style={{ color: 'red' }}>*</span></label>
                                                <div className="d-flex row" >
                                                    <Select
                                                        {...registerAddMaintenance("uom", { required: { value: false, message: "U.O.M is required" } })}

                                                        components={customIndicator}
                                                        placeholder={'Select U.O.M'}
                                                        onChange={handleChangeUom}
                                                        options={optionsUom}
                                                        value={forUOMValue}
                                                    />

                                                </div>
                                            </div>

                                            <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Other U.O.M </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Other U.O.M"
                                                        disabled={!showOtherUom}
                                                        {...registerAddMaintenance("other_uom", { onChange: handleValue, required: { value: false, message: "Other U.O.M is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.other_uom?.message}</span>
                                                </div>
                                            <div className="col-sm-3 mt-2">
                                                <label htmlFor="exampleInputEmail1">Fault Status <span style={{ color: 'red' }}>*</span></label>
                                                <select className="form-select" {...registerAddMaintenance("fault",
                                                    {
                                                        onChange: handleChangeOtherFault, required: { value: true, message: "Fault Status is required" },
                                                    }
                                                )}>
                                                    <option value="" selected >Select Any</option>
                                                    <option value={"Ok"}>Ok</option>
                                                    <option value={"Not Ok"}>Not Ok</option>
                                                    <option value={"Other"}>Other</option>
                                                </select>
                                                <span className='text-red w-100'>{errorsAddMaintenace?.fault?.message}</span>

                                                {/* <input type="text" placeholder="Status" className="form-control" disabled
                                                {...register("status", { required: { value: true, message: "Status is required" } })}
                                            /> */}
                                            </div>


                                            <div className="col-sm-3 mt-2">
                                                <label htmlFor="exampleInputEmail1">Other Fault Status {OtherFault === "Other" ? <span style={{ color: 'red' }}>*</span> : ""}</label>
                                                <div className="input-group" >
                                                    <input type="text" placeholder="Other Fault Status" className="form-control"
                                                        value={input}
                                                        disabled={OtherFault != "Other"}
                                                        {...registerAddMaintenance("other_status", { onChange: handleChange, required: { value: OtherFault === "Other" ? true : false, message: "Other Fault Status is required" } })}
                                                    />
                                                    {suggestion?.length > 0 && !choosed ? (
                                                        <div className="suggestion-wrapper">
                                                            {suggestion.map((item: any) => {
                                                                return (
                                                                    <div
                                                                        className="suggestions"
                                                                        key={item.value}
                                                                        onClick={() => {
                                                                            setChoosed(true)
                                                                            setInput(item.label)
                                                                        }}
                                                                    >
                                                                        {item.label}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    ) : null}
                                                    <div className="input-group-append">
                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                    </div>
                                                </div>
                                                <span className='text-red w-100'>{errorsAddMaintenace?.other_status?.message}</span>
                                            </div>

                                            <div className="col-sm-3 mt-2">
                                                <label htmlFor="exampleInputEmail1">Remark</label>
                                                <div className="input-group" >
                                                    <input type="text" placeholder="Remark" className="form-control"
                                                        {...registerAddMaintenance("remark", { onChange: handleValue, required: { value: false, message: "Remark is required" } })}
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                    </div>
                                                </div>
                                                <span className='text-red w-100'>{errorsAddMaintenace?.remark?.message}</span>
                                            </div>
                                            <div className="col-md-2  mt-4 image-upload">
                                                <label htmlFor="exampleInputEmail1 " style={{ display: 'block' }}>Maintenance Ok Image All </label>
                                                <Upload
                                                    {...registerAddMaintenance("resolve_complaint_image")}
                                                    accept="image/png, image/jpeg"
                                                    listType="picture"
                                                    onChange={OkImageOnChange}
                                                    multiple={true}
                                                    defaultFileList={uploadOkImage}
                                                    onPreview={(e: any) => { return "" }}
                                                    key={"image"}
                                                >
                                                    <img src={cameraLogo} alt='' className="uploaded-image" />
                                                </Upload>

                                                {/* {logo.length === 0 ?<span className='text-red'>{'Image is Required'}</span>:null} */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* <button className="btn btn-primary" onClick={() => setShowModal(false)}>OK</button> */}
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setShowModal(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="info"
                            className="text-white"
                            onClick={handleSubmitAddMaintenace(handleAddModal)}
                        // onClick={handleCheck}
                        >
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>



                <Modal size="xl"
                    show={openProduct} onHide={() => setOpenProduct(false)}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered    >

                    <Modal.Body>

                        <div className="table-responsive"  >



                            <table key={checked} className="table table-striped table-bordered" style={{ marginTop: "3px", width: "-webkit-fill-available" }}>
                                <thead style={{ textAlign: "center" }}>
                                    <tr>
                                        <th >Sr. <br /> No</th>
                                        <th>Item <br />Repairing-New</th>
                                        <th>Item<br /> Stock Type</th>
                                        <th>Use <br />Item Name</th>
                                        <th>Use<br />Item Qty.</th>
                                        <th>U.O.M</th>
                                        <th>Other<br/>U.O.M</th>
                                        <th>Fault<br />Ok</th>
                                        <th>Other<br />Fault</th>
                                        <th>Remark</th>
                                        <th>Image<br />All</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: 'center' }}>
                                    {dataForView?.map((field: any, index: any) => (
                                        // {dataForView?.map((field: any, index: any) => (
                                        <tr key={index} >
                                            <td>{index + 1}</td>


                                            <td>

                                                {field.item_solution_type?.name}

                                            </td>



                                            <td>


                                                {field.item_stock_type?.name}

                                            </td>

                                            <td>

                                                {field.use_item_name}

                                            </td>
                                            <td>

                                                {field.use_item_quantity}

                                            </td>

                                            <td>

                                                {field.uom === null||field.uom=== ""?"Other":field.uom?.name?.abbreviation}

                                            </td>
                                            <td>


                                                {field.other_uom}

                                            </td>
                                            <td>


                                                {field?.fault}

                                            </td>
                                            <td>


                                                {field.other_status ? field.other_status : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}

                                            </td>
                                            <td>


                                                {field.remark ? field.remark : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}

                                            </td>
                                            <td>
                                                {!field.resolve_complaint_image[0]?.doc ?
                                                    <>
                                                        <img src={noImage} height={50} width={50} />
                                                    </>
                                                    : <>
                                                        <img src={field.resolve_complaint_image[0]?.doc}
                                                            // onClick={() => handleShowImagesAction(field)} 
                                                            alt='no image' width={50} height={50} />
                                                    </>}
                                                {/* <Modal show={showImagesAction} onHide={() => setShowImagesAction(false)} aria-labelledby="contained-modal-title-vcenter"
                                                    centered    >

                                                    <Modal.Body>
                                                        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                                                            <div className="carousel-indicators">

                                                            </div>



                                                            <div className="carousel-inner">
                                                                {field?.resolve_complaint_image?.map((imgSrc: any, index: any) => (
                                                                    <div key={index} className={`carousel-item ${index === currentIndexAction ? 'active' : ''}`}>
                                                                        <img src={imgSrc?.doc} className="d-block w-100" alt={`Slide ${index}`} />
                                                                    </div>
                                                                ))}
                                                            </div>

                                                            <button className="carousel-control-prev" type="button" onClick={goToPrev} >
                                                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span className="visually-hidden">Previous</span>
                                                            </button>
                                                            <button className="carousel-control-next" type="button" onClick={goToNext} >
                                                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span className="visually-hidden">Next</span>
                                                            </button>
                                                        </div>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <button className="btn btn-primary" onClick={() => setShowImagesAction(false)}>OK</button>

                                                    </Modal.Footer>
                                                </Modal> */}
                                            </td>



                                        </tr>
                                    ))}
                                </tbody>
                            </table>


                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={() => setOpenProduct(false)}>Close</button> 

                    </Modal.Footer>
                </Modal>




            </div >
        </>
    )
}

export default Add_Maintenance_Action
