
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import Actions from "../../components/Cell/Actions";
// import { updateBrand, useFetchBrands } from "../../hooks/brand";
import noImage from "../../images/noImage.png"
import { useFetchGst, useFetchProductType, useUpdateGst, useUpdateProductType } from "../../hooks/GST";
import { Update, useFetch } from "../../hooks/Api";
import Modal from "react-bootstrap/esm/Modal";


interface Data {
  selection: any
  
  product_type:any
  product_grade_name:any
  cavity_to_product:any
  HSN_code:any
  color:any


  price_list_1_pcs_weight: any
  product_1_pcs_weight: any
  use_mould_code_and_cavity: any
  cavity_qty:any

  product_ml_price_list:any
product_actual_ml:any
product_final_height_mm: any
   product_current_height_mm:any
   current_lip: any

  // panni:any

 





  product_image:any
  product_drawing:any
  created_at:any

  created_by:any
  updated_by:any
  updated_at:any
  status: any;
  Actions: any;
}




export const titleofheaders_Disposable=[
  {
    id: `srno`,
    Header: `Sr.No`,
  },



  {
    id: `product_type`,
    Header: `Product Type`,
  },


  {
    id: `product_grade_name`,
    Header: `Product Grade Name`,
  },


  {
    id: `cavity_to_product`,
    Header: `Cavity`,
  },


  {
    id: `HSN_code`,
    Header: `HSN Code`,
  },


  {
    id: `color`,
    Header: `Color`,
  },


  {
    id: `price_list_1_pcs_weight`,
    Header: `Price List 1 pcs Weight`,
  },
  
  
  {
    id: `product_1_pcs_weight`,
    Header: `Product 1 pcs Weight`,
  },  

{
  id: `use_mould_code_and_cavity`,
  Header: `Mould Code And Cavity`,
},

{
  id: `cavity_qty`,
  Header: `Cavity Qty`,
},
  {
    id: `product_ml_price_list`,
    Header: `Product ML Price List`,
  },



  {
    id: `product_actual_ml`,
    Header: `Product Actual ML`,
  },










  {
    id: `product_final_height_mm`,
    Header: `Product Final Height(MM)`,
  }, 
  
  
  {
    id: `product_current_height_mm`,
    Header: `Product Current Height(MM)`,
  }, 


  {
    id: `product_lip`,
    Header: `Product Lip`,
  },















// {
//   id: `panni`,
//   Header: `Panni`,
// },
  





  

  


 

 
//     {
//       id: `panni_balloon`,
//       Header: `Panni Balloon`,
//     },

  

    {
      id: 'status',
      Header: 'Status',
    
    }, 
  


{
  id: 'created_at',
  Header: 'Created at',
},


{
  id: 'created_by',
  Header: 'Created By',


},
{
  id: 'updated_at',
  Header: 'Updated At',
},
{
  id: 'updated_by',
  Header: 'Updated By',
},




{
  id: `product_image`,
  Header: 'Product Image',


  },


  {
    id: `product_drawing`,
    Header: 'Product Drawing',

    },


  



{
  id: 'Actions',
  Header: 'Actions',


},






 
]





export const Columns_SingleCode_Disposable: any = [



  
  {
    id: `srno`,
    Header: `Sr.No`,
    sticky:"left",
  },
  {
    id: `product_type`,
  Header: () => <div style={{display:"inline"}}>Product<br/>Type</div>,

    // Header: `Product Type`,
    accessor:`product_type`,
    sticky:"left",
    Cell: ({ row }: any) => row.original.extra_response?.product_type?row.original.extra_response?.product_type:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },

  {
    id: `single_piece_code`,
  Header: () => <div style={{display:"inline"}}>Product Single<br/>Piece Code</div>,

    // Header: `Single Piece Code`,
    accessor:`single_piece_code`,
    Cell: ({ row }: any) => row.original.single_piece_code
  },

  


  // {
  //   id: `product_grade_name`,
  // Header: () => <div style={{display:"inline"}}>Product<br/>Grade Name</div>,

  //   // Header: `Product Grade Name`,
  //   accessor:`product_grade_name`,
  //   sticky:"left",
  //   Cell: ({ row }: any) => row.original.product_grade_name?.material_type_name?row.original.product_grade_name?.material_type_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  // },


  // {
  //   id: `cavity_to_product`,
  //   Header: `Cavity`,
  //   accessor:`cavity_to_product`,
  //   Cell: ({ row }: any) => row.original.cavity_to_product?.name?row.original.cavity_to_product?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  // },

  {
    id: 'name_and_type',
    Header: () => <div style={{ display: "inline" }}>Product Name &<br />Type & Category</div>,
    // Header: 'Name And Grade',
    accessor: 'name_and_type',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.HSN_code?.material_type_name} {row.original.extra_response?.cavity_to_product?.product_name} {row.original.extra_response?.HSN_code?.product_categories_name}
        </div>
      )
    }

  },
  {
    id: `HSN_code`,
    Header: () => <div style={{ display: "inline" }}>Material Name & Type &<br />Category & HSN & GST%</div>,

    // Header: `HSN Code`,
    accessor:`HSN_code`,
    Cell: ({ row }: any) => {
return(
  <div>
      {row.original.extra_response?.HSN_code?.material_type_name}::{row.original.extra_response?.HSN_code?.particular_sector_name?row.original.extra_response?.HSN_code?.particular_sector_name:<span style={{ color: 'red' }}>****</span>}::
      {row.original.extra_response?.HSN_code?.product_categories_name?row.original.extra_response?.HSN_code?.product_categories_name:<span style={{ color: 'red' }}>****</span>}::
      {row.original.extra_response?.HSN_code?.hsn_code}::{row.original?.extra_response?.HSN_code?.india_GST}
      </div>
)
    }
    
  },
  {
    id: 'gst_type',
    Header: () => <div style={{ display: "inline" }}>GST<br />Type</div>,
    // Header: 'Hsn Code',
    accessor: 'gst_type',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.extra_response?.HSN_code?.gst_type_name}
        </div>
      )
    }
  },
  {
    id: `type`,
    Header: () => <div style={{ display: "inline" }}>Type Name &<br />Density & Sutra & GST%</div>,
    accessor: 'type',
    Cell: ({ row }: any) => {
        return(

        <div>
            {row.original.extra_response?.HSN_code?.material_type_name}::{row.original.extra_response?.HSN_code?.material_density === "0.000" ||row.original.extra_response?.HSN_code?.material_density=== null
      ? <span style={{ color: 'red'}}>0.000</span>
      : row.original.extra_response?.HSN_code?.material_density}::{row.original.extra_response?.HSN_code?.grade_sutra?row.original.extra_response?.HSN_code?.grade_sutra:<span style={{ color: 'red'}}>****</span>}::{row.original?.extra_response?.HSN_code?.grade_type_india_GST}
        </div>
         )}
},

{
  id: 'use_code_name',
  Header: () => <div style={{ display: "inline" }}>Use Product Code<br />& Name & Type</div>,
  // Header: 'Hsn Code',
  accessor: 'use_code_name',
  Cell: ({ row }: any) => {
    return (
      <div>
        {row.original.hsn_code?.use_code_name ? row.original.hsn_code?.use_code_name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
      </div>
    )
  }
},
{
  id: 'use_code_qty',
  Header: () => <div style={{ display: "inline" }}>Use Product<br />Code Qty</div>,
  // Header: 'Hsn Code',
  accessor: 'use_code_qty',
  Cell: ({ row }: any) => {
    return (
      <div>
        {row.original.hsn_code?.use_code_qty ? row.original.hsn_code?.use_code_qty : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
      </div>
    )
  }
},
{
  id: 'material_type',
  Header: () => <div style={{ display: "inline" }}>Material<br />Type</div>,
  // Header: 'Material Use',
  accessor: 'material_type',
  Cell: ({ row }: any) => {
    return (
      <div>
        {row.original?.material_type?.name}
      </div>
    )
    // if (!convertToUpper) return "";
    // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
    // return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
  }

},


  {
    id: `color`,
    Header: () => <div style={{display:"inline"}}>Product Color<br/>Name</div>,
  
    accessor:`color`,
    Cell: ({ row }: any) => row.original.extra_response?.company_color_name?row.original.extra_response?.company_color_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },
  // {
  //   id: `cavity_to_product`,
  //   Header: () => <div style={{display:"inline"}}>Product<br/>Name</div>,
  
  //   accessor:`cavity_to_product`,
  //   Cell: ({ row }: any) => row.original.extra_response?.cavity_to_product?.product_name?row.original.extra_response?.cavity_to_product?.product_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  // },



  {
    id: `price_list_1_pcs_weight`,
    Header: () => <div style={{display:"inline"}}>Product Price List<br/>1 pcs Weight Gram</div>,
  
    // Header: `Price List 1 pcs Weight`,
    accessor:`price_list_1_pcs_weight`,
    Cell: ({ row }: any) => row.original.price_list_1_pcs_weight?row.original.price_list_1_pcs_weight:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },

  {
    id: `product_1_pcs_weight`,
    Header: () => <div style={{display:"inline"}}>Product Production<br/>1 pcs Weight Gram</div>,
  
    // Header: `Product 1 pcs Weight`,
    accessor:`product_1_pcs_weight`,
    Cell: ({ row }: any) => row.original.product_1_pcs_weight?row.original.product_1_pcs_weight:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },  



  // {
  //   id: `use_mould_code_and_cavity`,
  //   Header: () => <div style={{display:"inline"}}>Mould Code<br/>And Cavity</div>,
  
  //   // Header: `Mould Code And Cavity`,
  //   accessor:`use_mould_code_and_cavity`,
  //   Cell: ({ row }: any) => {
      
     
    
  //     return <div>
  //       {row.original?.use_mould_code_and_cavity?
  //       <>
  //         {`${row.original?.use_mould_code_and_cavity?.mould_code}::${row.original.use_mould_code_and_cavity?.cavity_qty}`}
  //         </>
  //       :<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
  //     </div>;
  //   }
  // },
  
  // {
  //   id: `cavity_qty`,
  //   Header: () => <div style={{display:"inline"}}>Cavity<br/>Qty</div>,
  
  //   // Header: `Mould Code And Cavity`,
  //   accessor:`cavity_qty`,
  //   Cell: ({ row }: any) => row.original?.use_mould_code_and_cavity?.cavity_qty
  // },






  


  {
    id: `product_ml_price_list`,
  Header: () => <div style={{display:"inline"}}>Product ML<br/>Price List</div>,

    // Header: `Product ML Price List`,
    accessor:`product_ml_price_list`,
    Cell: ({ row }: any) => row.original?.product_ml_price_list?.name?row.original?.product_ml_price_list?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },



  {
    id: `product_actual_ml`,
  Header: () => <div style={{display:"inline"}}>Product<br/>Actual ML</div>,

    // Header: `Product Actual ML`,
    accessor:`product_actual_ml`,
    Cell: ({ row }: any) => row.original.product_actual_ml?.name?row.original.product_actual_ml?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },
  {
    id: `price_list_mould_diameter_mm`,
  Header: () => <div style={{display:"inline"}}>Price-List Mould<br/>Diameter mm</div>,

    // Header: `Product Actual ML`,
    accessor:`price_list_mould_diameter_mm`,
    Cell: ({ row }: any) => row.original.price_list_mould_diameter_mm?.name?row.original.price_list_mould_diameter_mm?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },
  {
    id: `mould_shape`,
  Header: () => <div style={{display:"inline"}}>Mould<br/>Shape</div>,

    // Header: `Product Actual ML`,
    accessor:`mould_shape`,
    Cell: ({ row }: any) => row.original.extra_response?.mould_shape?.mould_shape_name?row.original.extra_response?.mould_shape?.mould_shape_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },


  {
    id: `shape_image`,
    Header: () => <div style={{ display: "inline" }}>Mould Shape<br />Image</div>,
    accessor: 'shape_image',
    Cell: ({ row }: any) =>{
      const [deleteConfirm, setDeleteConfirm] = useState(false)
      

    return (
      <>


<div >

          {!`${process.env.REACT_APP_BASE_URL}media/${row.original.extra_response?.mould_shape?.shape_image}`?<>
          {/* <a 
          onClick={()=>setDeleteConfirm(true)}
          href={`#galleryModal${row.original.name}`}
          
          data-large-src={`${process.env.REACT_APP_BASE_URL}/media/${row.original.extra_response?.mould_shape?.shape_image}`} data-toggle="modal">
            <img src={`${process.env.REACT_APP_BASE_URL}/media/${row.original.extra_response?.mould_shape?.shape_image}`}  height={30} width={'auto'}/>
            </a> */}
          </>:<>
          {/* <a 
          onClick={()=>setDeleteConfirm(true)}
          href={`#galleryModal${row.original.name}`}
          
          data-large-src={noImage} data-toggle="modal">
            <img src={noImage}  height={30} width={'auto'}/>
            </a> */}
          
         
          <a 
          onClick={()=>setDeleteConfirm(true)}
          href={`#galleryModal${row.original.name}`}
          
          data-large-src={row.original.extra_response?.mould_shape?.shape_image?`${process.env.REACT_APP_BASE_URL}media/${row.original.extra_response?.mould_shape?.shape_image}`:noImage} data-toggle="modal">
            <img src={row.original.extra_response?.mould_shape?.shape_image?`${process.env.REACT_APP_BASE_URL}media/${row.original.extra_response?.mould_shape?.shape_image}`:noImage}  height={30} width={'auto'}/>
            </a>
          {/* <a 
          onClick={()=>setDeleteConfirm(true)}
          href={`#galleryModal${row.original.name}`}
          
          data-large-src={`${process.env.REACT_APP_BASE_URL}media/${row.original.extra_response?.mould_shape?.shape_image}`?`${process.env.REACT_APP_BASE_URL}/media/${row.original.extra_response?.mould_shape?.shape_image}`:''} data-toggle="modal">
            <img src={`${process.env.REACT_APP_BASE_URL}media/${row.original.extra_response?.mould_shape?.shape_image}`?`${process.env.REACT_APP_BASE_URL}/media/${row.original.extra_response?.mould_shape?.shape_image}`:''}  height={30} width={'auto'}/>
            </a> */}
          </>}
         
        </div>

        <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
        centered    >
        <Modal.Header >
          <Modal.Title>
        
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>  
        {/* <img src={`${process.env.REACT_APP_BASE_URL}/media/${row.original.extra_response?.mould_shape?.shape_image}`?`${process.env.REACT_APP_BASE_URL}/media/${row.original.extra_response?.mould_shape?.shape_image}`:noImage} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid"/> */}
        <img src={row.original.extra_response?.mould_shape?.shape_image?`${process.env.REACT_APP_BASE_URL}media/${row.original.extra_response?.mould_shape?.shape_image}`:noImage} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid"/>

        </Modal.Body>
        <Modal.Footer>
        <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

        </Modal.Footer>
      </Modal>
       
        </>
        )}
  },



  {
    id: `product_mould_diameter_mm`,
  Header: () => <div style={{display:"inline"}}>Production Mould<br/>Diameter mm</div>,

    // Header: `Product Actual ML`,
    accessor:`product_mould_diameter_mm`,
    Cell: ({ row }: any) => row.original.extra_response?.mould_shape?.product_mould_diameter_mm?row.original.extra_response?.mould_shape?.product_mould_diameter_mm:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },
  {
    id: `product_width_diameter`,
  Header: () => <div style={{display:"inline"}}>Production Mould<br/>Width mm</div>,

    // Header: `Product Actual ML`,
    accessor:`product_width_diameter`,
    Cell: ({ row }: any) => row.original.extra_response?.mould_shape?.product_width_diameter?row.original.extra_response?.mould_shape?.product_width_diameter:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },
  {
    id: `product_length_diameter`,
  Header: () => <div style={{display:"inline"}}>Production Mould<br/>Length mm</div>,

    // Header: `Product Actual ML`,
    accessor:`product_length_diameter`,
    Cell: ({ row }: any) => row.original.extra_response?.mould_shape?.product_length_diameter?row.original.extra_response?.mould_shape?.product_length_diameter:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },
  {
    id: `cavity_qty`,
  Header: () => <div style={{display:"inline"}}>Mould Cavity<br/>Qty</div>,

    // Header: `Product Actual ML`,
    accessor:`cavity_qty`,
    Cell: ({ row }: any) => row.original.extra_response?.mould_shape?.cavity_qty?row.original.extra_response?.mould_shape?.cavity_qty:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },







  {
    id: `product_final_height_mm`,
    Header: () => <div style={{display:"inline"}}>Product Final<br/>Height(mm)</div>,
  
    // Header: `Product Final Height(MM)`,
    accessor:`product_final_height_mm`,
    Cell: ({ row }: any) => row.original.product_final_height_mm?row.original.product_final_height_mm:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  }, 
  
  
  {
    id: `product_current_height_mm`,
    Header: () => <div style={{display:"inline"}}>Product Current<br/>Height(mm)</div>,
  
    // Header: `Product Current Height(MM)`,
    accessor:`product_current_height_mm`,
    Cell: ({ row }: any) => row.original.product_current_height_mm?row.original.product_current_height_mm:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  }, 





  {
    id: `current_lip`,
  Header: () => <div style={{display:"inline"}}>Current<br/>Lip</div>,

    // Header: `Product Lip`,
    accessor:`current_lip`,
    Cell: ({ row }: any) => row.original.current_lip?.name?row.original.current_lip?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },
  {
    id: `product_minimum_kg`,
  Header: () => <div style={{display:"inline"}}>Production <br/>Minimum Kg.</div>,

    // Header: `Product Lip`,
    accessor:`product_minimum_kg`,
    Cell: ({ row }: any) => row.original.product_minimum_kg?row.original.product_minimum_kg:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },
  {
    id: `producttion_1_kg_in_pcs`,
  Header: () => <div style={{display:"inline"}}>Production 1 <br/>Kg In Pcs</div>,

    // Header: `Product Lip`,
    accessor:`producttion_1_kg_in_pcs`,
    Cell: ({ row }: any) => row.original.producttion_1_kg_in_pcs?row.original.producttion_1_kg_in_pcs:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },

  {
    id: 'material_name',
    Header: () => <div style={{ display: "inline" }}>Material<br />Grade Name</div>,
    accessor:'material_name',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.extra_response?.HSN_code?.material_type_name}
        </div>
      )
    }

  },
  {
    id: `product_name`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Name</div>,
  
    accessor:`product_name`,
    Cell: ({ row }: any) => row.original.extra_response?.cavity_to_product?.product_name?row.original.extra_response?.cavity_to_product?.product_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },
 
  {
    id: 'material_type_name',
    Header: () => <div style={{ display: "inline" }}>Material<br />Type</div>,
    accessor:'material_type_name',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.extra_response?.HSN_code?.particular_sector_name}
        </div>
      )
    }

  },

  {
    id: 'material_category',
    Header: () => <div style={{ display: "inline" }}>Material<br />Category</div>,
    accessor:'material_category',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.extra_response?.HSN_code?.product_categories_name}
        </div>
      )
    }

  },
  
  
  {
    id: 'material_hsn',
    Header: () => <div style={{ display: "inline" }}>HSN<br />Code</div>,
    accessor:'material_hsn',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.extra_response?.HSN_code?.hsn_code}
        </div>
      )
    }

  },
 
  {
    id: 'material_gst',
    Header: () => <div style={{ display: "inline" }}>GST<br />%</div>,
    accessor:'material_gst',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.extra_response?.HSN_code?.india_GST}
        </div>
      )
    }

  },
  {
    id: 'product_density',
    Header: () => <div style={{ display: "inline" }}>Product<br />Density</div>,
    accessor:'product_density',
    Cell: ({ row }: any) => {
      return(
        <div>
         {row.original.hsn_code?.product_density?row.original.hsn_code?.product_density:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

        </div>
      )
    }

  },
  {
    id: 'use_cavity_code',
    Header: () => <div style={{ display: "inline" }}>Use Cavity<br />Code & Name</div>,
    accessor:'use_cavity_code',
    Cell: ({ row }: any) => {
      return(
        <div>
         {row.original.hsn_code?.use_cavity_code?row.original.hsn_code?.use_cavity_code:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

        </div>
      )
    }

  },
  {
    id: 'use_cavity_qty',
    Header: () => <div style={{ display: "inline" }}>Use Cavity<br />Qty</div>,
    accessor:'use_cavity_qty',
    Cell: ({ row }: any) => {
      return(
        <div>
         {row.original.hsn_code?.use_cavity_qty?row.original.hsn_code?.use_cavity_qty:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

        </div>
      )
    }

  },
  {
    id: 'use_mould_code',
    Header: () => <div style={{ display: "inline" }}>Use Mould<br />Code & Cavity Qty</div>,
    accessor:'use_mould_code',
    Cell: ({ row }: any) => {
      return(
        <div>
         {row.original.hsn_code?.use_mould_code?row.original.hsn_code?.use_mould_code:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

        </div>
      )
    }

  },
  {
    id: 'remark',
    Header: () => <div style={{ display: "inline" }}>Remark</div>,
    accessor:'remark',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.remark?row.original.remark:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
        </div>
      )
    }

  },


  
 

 
    

  

    {
      id: 'status',
      Header: 'Status',
      accessor:'status',
    
    }, 
  


    {
      id: 'created_at',
      Header: () => <div style={{display:"inline"}}>Created<br/>At</div>,
      accessor:'created_at',
      Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
    },
  
  
    {
      id: 'created_by',
      Header: () => <div style={{display:"inline"}}>Created<br/>By</div>,
      accessor:'created_by',
      Cell: ({ row }: any) => row.original.created_by?.username
  
   
    },
    {
      id: 'updated_at',
      Header: () => <div style={{display:"inline"}}>Updated<br/>At</div>,
      accessor:'updated_at',
      Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
    },
    {
      id: 'updated_by',
      Header: () => <div style={{display:"inline"}}>Updated<br/>By</div>,
      accessor:'updated_by',
      Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
    },


    {
      id: `product_image`,
      Header: () => <div style={{ display: "inline" }}>Product<br />Image All</div>,
      Cell: ({ row }: any) => {
        const [deleteConfirm, setDeleteConfirm] = useState(false)
        return (
          <>
            <div >
  
              {!row.original.product_image ? <>
                <a
  
                  href="#"
  
                >
                  <img src={noImage} height={60} width={100} />
                </a>
              </> : <>
                <a
                  onClick={() => setDeleteConfirm(true)}
                  href={`#galleryModal${row.original.name}`}
  
                  data-large-src={(row.original.product_image.length > 0 ? row.original.product_image.at(-1).doc : noImage)} data-toggle="modal">
                  <img src={(row.original.product_image.length > 0 ? row.original.product_image.at(-1).doc : noImage)} height={60} width={100} />
                </a>
              </>}
  
            </div>
            <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
              centered    >
  
              <Modal.Body>
                <img src={(row.original.product_image?.length > 0 ? row.original.product_image.at(-1).doc : noImage)} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid" />
  
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>
  
              </Modal.Footer>
            </Modal>
          </>)
      }
    },
    
    
      {
        id: `product_pdf_drawing`,
        Header: () => <div style={{ display: "inline" }}>Product<br />PDF Drawing All</div>,
    
        // accessor: `pdf`,
        Cell: ({ row }: any) =>
    
    
    
          <div>
            <a target="_blank" href={(row.original.product_pdf_drawing?.length > 0 ? row.original.product_pdf_drawing?.at(-1).doc : null)}>
              {(row.original.product_pdf_drawing?.length > 0 ? row.original.product_pdf_drawing?.at(-1).doc?.split('/').pop() : <span className="text-danger">No Document Uploaded</span>)}
            </a>
          </div>
    
      },
    



{
  id: 'Actions',
  Header: 'Actions',


},
  {
    id: 'selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_SingleCode_Disposable, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows} = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}single_code/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



