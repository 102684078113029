import { useEffect, useRef, useState } from "react";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../components/Header/header.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import { useCreate } from "../../hooks/Api";
import Button from "react-bootstrap/esm/Button";
import { AiOutlinePlus } from "react-icons/ai";
import Select from "react-select"
import {
    useFetchMouldMachineDropDown, useFetchMachineLocationDropDown, useFetchMaterialUseDropDown,
    useFetchMouldHSNDropDown, useFetchMouldBrandDropDown, useFetchMouldCompanyNameDropDown,
    useFetchMouldShapeDropDown
} from "../../hooks/DropDown_Api";
import { Alert } from "../../components/Model/Model";
import CreatableSelect from "react-select/creatable";
import { DatePicker } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from 'dayjs';
import useFocusOnEnter from "../../components/focus";
import { Upload } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'
import { HSN_Model } from "../../components/Dynamic_Form_Models/HSN_Model";
import { Machine_Model } from "../../components/Dynamic_Form_Models/Machine_Modle";
import Modal from "react-bootstrap/Modal";
import { Deleteforce, Deletesingle } from "../../components/Cell/Actions";
import { useDelete, useDeletemforce } from "../../hooks/delete";
import Custom_Actions_Buttons from "../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../hooks/deletemultiple";
import { SimpleInput_Edit } from "../../components/Dynamic_Form_Models/SimpleInput_Edit";


const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}

// import { Alert } from "../Model/Model";
// import Select from 'react-select/async';
interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});


const Add_Mould = () => {
    const navigate = useNavigate()
    const focus: any = useRef()
    const { onEnterKey } = useFocusOnEnter(focus)
    const apiname = process.env.REACT_APP_MOULD_API?.toString()
    const { mutate: gst, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm({
        mode: "onTouched"
    });
    const [openHSN, setOpenHSN] = useState(false)
    const [openMachine, setOpenMachine] = useState(false)


    const [optionsMouldShape, setOptionsMouldShape]: any = useState([])
    const [options, setOptions]: any = useState([])
    const [searchTerm, setSearchTerm] = useState('')

    const [logo, setlogo]: any = useState([])
    const [logoPdf, setlogoPdf]: any = useState([])

    const [Warranty, setWarranty]: any = useState('no')
    const onWarrantyChange = (e: any) => {
        setWarranty(e.target.value)
    }
    const [Guarantee, setGuarantee]: any = useState('no')
    const onGuaranteeChange = (e: any) => {
        setGuarantee(e.target.value)
    }

    const [valueCavity, setValueCavity]: any = useState();


    const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueCavityCreatable, setValueCavityCreatable] = useState<Option | null>();

    const apinameForCavity = process.env.REACT_APP_MOULD_SHAPE_API?.toString()

    const { mutate: mutateCavity, data: dataCavity, isError: cavityIsError, isLoading: cavityLoading, isSuccess: cavitySuccess, error: cavityError }: any = useCreate(apinameForCavity)


    const { data: mouldShapeData, refetch: mouldShapeRefetch } = useFetchMouldShapeDropDown(searchTerm)

    useEffect(() => {
        setOptionsMouldShape(mouldShapeData)
    }, [mouldShapeData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            mouldShapeRefetch()
        }
    }, [])



    const handleChangeMouldShape = (e: any) => {

        mouldShapeRefetch()

        setApiName(apinameForCavity)
        setDeleteApiName(apinameForCavity)
        setEditId(e?.value)
        setEditData(mouldShapeData)
        // setValueGumName(e)
        setRenderKey(prevKey => prevKey + 1);

        setValueCavity(e)
        setValue("mould_shape", e?.value)
        setValueCavityCreatable(null)
    }

    const handleCreate = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateCavity({ name: newOption.name, status: newOption.status })
            setOptionsMouldShape((prev: any) => [...prev, newOption]);
            setValueCavityCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (cavitySuccess && optionsMouldShape) {
                await mouldShapeRefetch();
                setValue("mould_shape", mouldShapeData[0].value)
            }

        })();

        return () => {
        };
    }, [cavitySuccess, mouldShapeData]);


    const apinameForMouldUse = process.env.REACT_APP_MATERIAL_USE_API?.toString()
    const { mutate: mutateMouldUse, data: dataMouldUse, isError: MouldUseIsError, isLoading: MouldUseLoading, isSuccess: MouldUseSuccess, error: MouldUseError }: any = useCreate(apinameForMouldUse)

    const [valueMouldUse, setValueMouldUse]: any = useState();
    const [optionsMouldUse, setOptionsMouldUse]: any = useState([]);
    const [valueMouldUseCreatable, setValueMouldUseCreatable] = useState<Option | null>();

    const { data: MouldUseData, refetch: MouldUseRefetch } = useFetchMaterialUseDropDown()

    useEffect(() => {
        setOptionsMouldUse(MouldUseData)
    }, [MouldUseData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            MouldUseRefetch()
        }
    }, [])


    const handleChangeMouldUse = (e: any) => {

        MouldUseRefetch()

        setApiName(apinameForMouldUse)
        setDeleteApiName(apinameForMouldUse)
        setEditId(e?.value)
        setEditData(MouldUseData)
        setValueMouldUse(e)
        setRenderKey(prevKey => prevKey + 1);

        setValue("mould_special_running", `${e?.value}`)
        setValueMouldUseCreatable(null)
    }





    const handleCreateMouldUse = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateMouldUse({ name: newOption.name, status: newOption.status })
            setOptionsMouldUse((prev: any) => [...prev, newOption]);
            setValueMouldUseCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (MouldUseSuccess && optionsMouldUse) {
                await MouldUseRefetch();
                setValue("mould_special_running", MouldUseData[0].value)
            }

        })();

        return () => {
        };
    }, [MouldUseSuccess, MouldUseData]);


    useEffect(() => {
        if (data) {

            reset(data)

        }
    }, [data]);


    const [tfmMachineValue, setTfmMachineValue]: any = useState()

    const handleReset = () => {
        reset()
        setValueCavityCreatable(null)
        setTfmMachineValue(null)
        setForToDate(null)
    }


    const { data: machineData, refetch: machineRefetch } = useFetchMouldMachineDropDown()
    useEffect(() => {

        setOptions(machineData)
    }, [machineData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            machineRefetch()
        }
    }, [])


    const handleChangeMachine = (e: any) => {



        const machine = e?.map((value: any) => {
            return value?.value
        })

        setValue("tfm_machine_usd_mould", machine)
        // setTfmMachineValue(e)
        setForMachineValue(e)
        // setForMachineValue({ label: e?.label, value: e?.value })
        // setSelMachine(e)
    }

    const [optionsHSN, setOptionsHSN]: any = useState([])

    const { data: HSNData, refetch: HSNRefetch } = useFetchMouldHSNDropDown(searchTerm)

    useEffect(() => {
        setOptionsHSN(HSNData)
    }, [HSNData])

    const handleChangeHSN = (e: any) => {
        setValue("mould_name_type_category_hsn_code_and_gst", `${e.value}`)
        setForHSNValue({ label: e?.label, value: e?.value })

        // setHSN(e.hsn)
        // setDensity(e.material)
        // setPolymer(e.polymer)
        // setValue("polymer_grade", e.polymer)
    }


    const [optionsMouldLocation, setOptionsMouldLocation]: any = useState()
    const [valueMouldLocationCreatable, setValueMouldLocationCreatable] = useState<Option | null>();
    const [valueMouldLocation, setValueMouldLocation]: any = useState();
    const apinameForMouldLocation = process.env.REACT_APP_MACHINE_LOCATION_API?.toString()
    const { mutate: mutateMouldLocation, data: dataMouldLocation, isError: MouldLocationIsError, isLoading: MouldLocationLoading, isSuccess: MouldLocationSuccess, error: MouldLocationError }: any = useCreate(apinameForMouldLocation)



    const { data: MouldLocationData, refetch: MouldLocationRefetch } = useFetchMachineLocationDropDown()
    // console.log(machineLocationData, "optionsLocation");
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsMouldLocation(MouldLocationData)
    }, [MouldLocationData])
    const handleChangeMouldLocation = (e: any) => {

        MouldLocationRefetch()

        setApiName(apinameForMouldLocation)
        setDeleteApiName(apinameForMouldLocation)
        setEditId(e?.value)
        setEditData(MouldLocationData)
        setValueMouldLocation(e)
        setRenderKey(prevKey => prevKey + 1);

        setValue("mould_location", `${e?.value}`)
        setValueMouldLocationCreatable(null)
    }

    const handleCreateMouldLocation = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateMouldLocation({ name: newOption.name, status: newOption.status })
            setOptionsMouldLocation((prev: any) => [...prev, newOption]);
            setValueMouldLocationCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (MouldLocationSuccess && optionsMouldLocation) {
                await MouldLocationRefetch();
                setValue("mould_location", MouldLocationData[0]?.value)
            }

        })();

        return () => {
        };
    }, [MouldLocationSuccess, MouldLocationData]);


    const [valueMouldBrandCreatable, setValueMouldBrandCreatable] = useState<Option | null>();
    const [valueMouldBrand, setValueMouldBrand]: any = useState();
    const [optionsMouldBrand, setOptionsMouldBrand]: any = useState();
    const apinameForMouldBrand = process.env.REACT_APP_MOULD_BRAND_NAME_API?.toString()
    const { mutate: mutateMouldBrand, data: dataMouldBrand, isError: MouldBrandIsError, isLoading: MouldBrandLoading, isSuccess: MouldBrandSuccess, error: MouldBrandError }: any = useCreate(apinameForMouldBrand)



    const { data: MouldBrandData, refetch: MouldBrandRefetch } = useFetchMouldBrandDropDown()
    // console.log(MouldBrandData, "optionsBrand");
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsMouldBrand(MouldBrandData)
    }, [MouldBrandData])
    const handleChangeMouldBrand = (e: any) => {

        MouldBrandRefetch()

        setApiName(apinameForMouldBrand)
        setDeleteApiName(apinameForMouldBrand)
        setEditId(e?.value)
        setEditData(MouldBrandData)
        setValueMouldBrand(e)
        setRenderKey(prevKey => prevKey + 1);

        setValue("mould_brand", `${e?.value}`)
        setValueMouldBrandCreatable(null)
    }

    const handleCreateMouldBrand = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateMouldBrand({ name: newOption.name, status: newOption.status })
            setOptionsMouldBrand((prev: any) => [...prev, newOption]);
            setValueMouldBrandCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (MouldBrandSuccess && optionsMouldBrand) {
                await MouldBrandRefetch();
                setValue("mould_brand", MouldBrandData[0]?.value)
            }

        })();

        return () => {
        };
    }, [MouldBrandSuccess, MouldBrandData]);


    const apinameForCompanyName = process.env.REACT_APP_MOULD_COMPANY_NAME_API?.toString()
    const { mutate: mutateCompanyName, data: dataCompanyName, isError: CompanyNameIsError, isLoading: CompanyNameLoading, isSuccess: CompanyNameSuccess, error: CompanyNameError }: any = useCreate(apinameForCompanyName)

    const [valueCompanyName, setValueCompanyName]: any = useState();
    const [optionsCompanyName, setOptionsCompanyName]: any = useState([]);
    const [valueCompanyNameCreatable, setValueCompanyNameCreatable] = useState<Option | null>();

    const { data: CompanyNameData, refetch: CompanyNameRefetch } = useFetchMouldCompanyNameDropDown()

    useEffect(() => {
        setOptionsCompanyName(CompanyNameData)
    }, [CompanyNameData])




    const handleChangeCompanyName = (e: any) => {

        CompanyNameRefetch()

        setApiName(apinameForCompanyName)
        setDeleteApiName(apinameForCompanyName)
        setEditId(e?.value)
        setEditData(CompanyNameData)
        setValueCompanyName(e)
        setRenderKey(prevKey => prevKey + 1);

        setValue("mould_company", `${e?.value}`)
        setValueCompanyNameCreatable(null)
    }





    const handleCreateCompanyName = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateCompanyName({ name: newOption.name, status: newOption.status })
            setOptionsCompanyName((prev: any) => [...prev, newOption]);
            setValueCompanyNameCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (CompanyNameSuccess && optionsCompanyName) {
                await CompanyNameRefetch();
                setValue("mould_company", CompanyNameData[0].value)
            }

        })();

        return () => {
        };
    }, [CompanyNameSuccess, CompanyNameData]);


    const onSubmit = (branddetail: any) => {
        if (!forToDate) {
            // const d = new Date();

            delete branddetail.purchase_date
        }
        if (Guarantee === "no") {
            branddetail.guarantee_months = ''
        }
        if (Warranty === "no") {
            branddetail.warranty_time = ''
        }


        const formData = new FormData();

        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value
            if (key === "image") {
                for (let i = 0; i < logo.length; i++) {

                    formData.append('image', logo[i])
                }
            }
            if (key === "pdf") {
                for (let i = 0; i < logoPdf.length; i++) {

                    formData.append('pdf', logoPdf[i])
                }
            }


            // if (typeof value !== "object") {
            //     formData.append(key, formvalue)
            // }
            if (typeof value !== "object") {
                if (value != undefined) {
                    formData.append(key, formvalue)
                }
            }
            if (Array.isArray(value) && key === "tfm_machine_usd_mould") {
                value.forEach((val, index) => formData.append(`tfm_machine_usd_mould`, val))
            }
            if (key === 'product_mould_diameter_mm') {
                let decimalNumber = Number(valueSize);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('product_mould_diameter_mm', formattedString)
            }
            if (key === 'product_length_diameter') {
                let decimalNumber = Number(valueLength);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('product_length_diameter', formattedString)
            }
            if (key === 'product_width_diameter') {
                let decimalNumber = Number(valueWidth);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('product_width_diameter', formattedString)
            }
            if (key === 'product_list_diameter_mm') {
                let decimalNumber = Number(valueProductionPriceList);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('product_list_diameter_mm', formattedString)
            }
            if (key === 'forming_cutting_area_sheet_length_mm') {
                let decimalNumber = Number(valueFormingCuttingLength);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('forming_cutting_area_sheet_length_mm', formattedString)
            }
            if (key === 'forming_cutting_area_sheet_width_mm') {
                let decimalNumber = Number(valueFormingCuttingWidth);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('forming_cutting_area_sheet_width_mm', formattedString)
            }
            if (key === 'cutter_gap') {
                let decimalNumber = Number(valueMouldCutterGap);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('cutter_gap', formattedString)
            }
            if (key === 'tfm_chain_infield_maximum_mm') {
                let decimalNumber = Number(valueTFMMaximum);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('tfm_chain_infield_maximum_mm', formattedString)
            }
            if (key === 'tfm_chain_infield_minimum_mm') {
                let decimalNumber = Number(valueTFMMinimum);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('tfm_chain_infield_minimum_mm', formattedString)
            }
            if (key === 'mould_cavity_hole_height_mm') {
                let decimalNumber = Number(valueCavityHoleHeight);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('mould_cavity_hole_height_mm', formattedString)
            }
            if (key === 'mould_cutter_thickness_mm') {
                let decimalNumber = Number(valueMouldCuttermm);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('mould_cutter_thickness_mm', formattedString)
            }
            // if (key === 'mould_cutter_id_mm') {
            //     let decimalNumber = Number(valueMouldCutterIdmm);
            //     const formattedString = decimalNumber.toFixed(2);
            //     formData.append('mould_cutter_id_mm', formattedString)
            //     continue
            // }
            if (valueMouldCutterIdmm && key === 'mould_cutter_id_mm') {
                let decimalNumber = Number(valueMouldCutterIdmm);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('mould_cutter_id_mm', formattedString)
                continue
            }
            if (valueMouldCutterOdmm && key === 'mould_cutter_od_mm') {
                let decimalNumber = Number(valueMouldCutterOdmm);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('mould_cutter_od_mm', formattedString)
                continue
            }
            // if (key === 'mould_cutter_od_mm') {
            //     let decimalNumber = Number(valueMouldCutterOdmm);
            //     const formattedString = decimalNumber.toFixed(2);
            //     formData.append('mould_cutter_od_mm', formattedString)
            //     continue
            // }
            if (key === 'sheet_width') {
                let decimalNumber = Number(ValueSheetSizeWidth);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('sheet_width', formattedString)
                continue
            }
            if (key === 'length') {
                let decimalNumber = Number(ValueMouldLengthmm);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('length', formattedString)
                continue
            }
            // if (key === 'mould_width_mm') {
            //     let decimalNumber = Number(ValueMouldWidthmm);
            //     const formattedString = decimalNumber.toFixed(2);
            //     formData.append('mould_width_mm', formattedString)
            //     continue
            // }
            if (ValueMouldWidthmm && key === 'mould_width_mm') {
                let decimalNumber = Number(ValueMouldWidthmm);
                const formattedString = decimalNumber.toFixed(2);
                formData.append('mould_width_mm', formattedString)
                continue
            }
            // if (key === 'mould_lip_mm') {
            //     let decimalNumber = Number(valueMouldLip);
            //     const formattedString = decimalNumber.toFixed(2);
            //     formData.append('mould_lip_mm', formattedString)
            // }

        }
        gst(formData);

    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listMould', { state: { showToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);


    const onLogoChange = (e: any) => {
        setlogo(e.target.files)


    }

    const onLogoPDFChange = (e: any) => {
        setlogoPdf(e.target.files)
    }

    const [forToDate, setForToDate]: any = useState()
    const [toDate, setToDate]: any = useState()

    const handleToDate = (e: any) => {
        setToDate(e)
        setValue("purchase_date", `${e?.$y}-${e?.$M + 1}-${e?.$D}`)
        setForToDate(e)
    }

    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {

        return current && current < dayjs().startOf('day');
    };

    const handleUpper = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }

    const [uploadFile, setUploadFile] = useState<UploadFile[]>([])
    const [uploadFileDoc, setUploadFileDoc] = useState<UploadFile[]>([])

    const FileOnChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setlogo(tempFiles)
        setUploadFile(e.fileList)

    }
    const DocOnChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setlogoPdf(tempFiles)
        setUploadFileDoc(e.fileList)

    }

    const [valueProductionDiameter, setValueProductionDiameter] = useState('')
    const [valueProductionPriceList, setValueProductionPriceList] = useState('')
    const [valueFormingCuttingLength, setValueFormingCuttingLength] = useState('')
    const [valueFormingCuttingWidth, setValueFormingCuttingWidth] = useState('')
    const [valueMouldCutterGap, setValueMouldCutterGap] = useState('')
    const [valueTFMMaximum, setValueTFMMaximum] = useState('')
    const [valueTFMMinimum, setValueTFMMinimum] = useState('')
    const [valueCavityHoleHeight, setValueCavityHoleHeight] = useState('')
    const [valueMouldCuttermm, setvalueMouldCuttermm] = useState('')
    const [valueMouldCutterIdmm, setvalueMouldCutterIdmm] = useState('')
    const [valueMouldCutterOdmm, setvalueMouldCutterOdmm] = useState('')
    const [ValueMouldLengthmm, setValueMouldLengthmm] = useState('')
    const [ValueSheetSizeWidth, setValueSheetSizeWidth] = useState('')
    const [ValueMouldWidthmm, setValueMouldWidthmm] = useState('')
    // const [valueMouldLip, setValueMouldLip] = useState('')

    const [valueSize, setValueSize] = useState('')
    const [valueLength, setValueLength] = useState('')
    const [valueWidth, setValueWidth] = useState('')

    const [forFromDate, setForFromDate]: any = useState()
    const [fromDate, setFromDate]: any = useState()

    const handleFromDate = (e: any) => {
        setFromDate(e)
        setValue("manufacture_date", `${e?.$y}-${e?.$M + 1}-${e?.$D}`)
        setForFromDate(e)
    }


    const handleNumbercode = (e: any) => {
        let temp = e.target.value.replace(/\D/g, '');
        if (temp.length > 10) temp = temp.substring(0, 10);
        if (temp.length > 6) {
            temp = temp.slice(0, 5) + ' ' + temp.slice(5);
        }
        e.target.value = temp;

        setValue('service_mobile_number', temp)
    }


    useEffect(() => {
        if (Guarantee === 'no') {
            setValue("guarantee_months", "")
        }
        if (Warranty === 'no') {
            setValue("warranty_time", "")
        }
    }, [Guarantee, Warranty])

    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }

    const [submittedHSN, setSubmittedHSN] = useState(false)
    const [forHSNValue, setForHSNValue]: any = useState([])


    useEffect(() => {
        if (submittedHSN) {
            setForHSNValue(optionsHSN[0])

            setValue("hsn_code", optionsHSN[0]?.value)

        }

    }, [submittedHSN, optionsHSN])

    const [submittedMachine, setSubmittedMachine] = useState(false)
    const [forMachineValue, setForMachineValue]: any = useState([])


    useEffect(() => {
        if (submittedMachine) {
            setForMachineValue(options[0])

            setValue("tfm_machine_usd_mould", options[0]?.value)

        }

    }, [submittedMachine, options])



    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
    } = useDelete();
    const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
    } = useDeletemforce();




    const handleRefetch = () => {
        if (apiName === apinameForCavity) {
            mouldShapeRefetch()
        }

        if (apiName === apinameForMouldUse) {
            MouldUseRefetch()
        }

        if (apiName === apinameForMouldLocation) {
            MouldLocationRefetch()
        }

        if (apiName === apinameForMouldBrand) {
            MouldBrandRefetch()
        }

        if (apiName === apinameForCompanyName) {
            CompanyNameRefetch()
        }


    }


    useEffect(() => {
        if (OpenEditDropDownModel && valueCavity && apiName === apinameForCavity) {
            setValueCavity(null)
            setValueCavityCreatable(null)
        }

        if (OpenEditDropDownModel && valueMouldUse && apiName === apinameForMouldUse) {
            setValueMouldUse(null)
            setValueMouldUseCreatable(null)
        }

        if (OpenEditDropDownModel && valueMouldLocation && apiName === apinameForMouldLocation) {
            setValueMouldLocation(null)
            setValueMouldLocationCreatable(null)
        }

        if (OpenEditDropDownModel && valueMouldBrand && apiName === apinameForMouldBrand) {
            setValueMouldBrand(null)
            setValueMouldBrandCreatable(null)
        }

        if (OpenEditDropDownModel && valueCompanyName && apiName === apinameForCompanyName) {
            setValueCompanyName(null)
            setValueCompanyNameCreatable(null)
        }









        if (deletesucess && apiName === apinameForCavity) {
            setValueCavity(null)
            setValueCavityCreatable(null)
        }

        if (deletesucess && apiName === apinameForMouldUse) {
            setValueMouldUse(null)
            setValueMouldUseCreatable(null)
        }

        if (deletesucess && apiName === apinameForMouldLocation) {
            setValueMouldLocation(null)
            setValueMouldLocationCreatable(null)
        }

        if (deletesucess && apiName === apinameForMouldBrand) {
            setValueMouldBrand(null)
            setValueMouldBrandCreatable(null)
        }

        if (deletesucess && apiName === apinameForCompanyName) {
            setValueCompanyName(null)
            setValueCompanyNameCreatable(null)
        }




    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (


        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
            <span>{label}</span>
            <div style={{ float: "right", justifyContent: "end" }}>



                {isExist ?

                    <Custom_Actions_Buttons value={value}
                        apiname={deleteApiName}
                        mutate={singleDelete}
                        // masterName={addButtonName}
                        deleteid={deleteid}
                        handleDeletedforce={handleDeletedforce}
                        closereconfirmdelete={closereconfirmdelete}
                        deletemodalask={deletemodalask}
                        setEditOpen={setOpenEditDropDownModel}
                    // permissionClass={permissionClass}
                    />
                    : ""}

            </div>
        </div>


    )


    const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
    }



    useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
        return () => {
            setDeleteid(undefined);
        };
    }, []);
    const handleConfirmDelete = () => {
        setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
    };

    const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
    };
    useEffect(() => {
        if (forcefullysuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullyerror) {
            setShow(true);
            setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullydeleteerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
    ]);

    useEffect(() => {
        if (deleteMultipleSuccess) {
            const showdepenedancymsg = deleteMultiple?.data?.data;
            const dependacyfound = showdepenedancymsg?.filter((color: any) => {
                if (
                    color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
                ) {
                    return color;
                }
            });
            if (dependacyfound?.length === deleteMultipleid?.length) {
                deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
            } else {
                setConfirmforcemodal(true);
            }
        } else if (deleteMultipleIsError) {
            setShow(true);
            setshowmessage("danger");
        } else if (deletesucess) {
            if (deletedata.data?.data?.length > 0) {
                setConfirmforcemodal(true);
            } else if (deleteid) {
                setShow(true);
                setshowmessage("success");
                deleteforce({ deleteid, apiname: deleteApiName });
            }
        }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

    const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
    };


    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header px-3 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Add Mould Size</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Masters</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listMould" className="text-decoration-none text-black">Mould Size</Link></li>
                                    <li className="breadcrumb-item active">Add Mould Size</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">

                            <form ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                                    {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}

                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Mould Name & Type & Category & HSN & GST% <span style={{ color: "red" }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            placeholder={'Select Mould Name & Type'}
                                                            {...register("mould_name_type_category_hsn_code_and_gst", { required: { value: true, message: "Mould Name & Type & Category & HSN & GST% is required" } })}
                                                            onChange={handleChangeHSN}
                                                            options={optionsHSN}
                                                            components={customIndicator}
                                                            value={forHSNValue}
                                                            // value={selHSN === "" ? optionsHSN?.find((obj: any) => obj.value === selectedHSN) : selHSN}

                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenHSN(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.mould_name_type_category_hsn_code_and_gst?.message}</span>


                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Plant Mould	Number <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >

                                                        <input type="text" placeholder="Plant Mould Number" className="form-control"
                                                            {...register("plant_mould_number", { required: { value: true, message: "Plant Mould	Number is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.plant_mould_number?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Model No.
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <div className="input-group" >

                                                        <input type="text" placeholder="Mould Model No." className="form-control"
                                                            {...register("model_number", { required: { value: true, message: "Mould Model No. is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.model_number?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1"> Price List Mould Size mm <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder=" Price List Mould Size mm" className="form-control"
                                                            {...register("product_list_diameter_mm", { onChange: ((e: any) => setValueProductionPriceList(e.target.value)), required: { value: true, message: " Price List Mould Size mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.product_list_diameter_mm?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Mould Shape <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueCavity(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("mould_shape", { required: { value: true, message: "Machine Shape is required" } })}
                                                        placeholder={'Select Mould Usage'}
                                                        options={optionsMouldShape}
                                                        onCreateOption={handleCreate}
                                                        onMenuOpen={() => mouldShapeRefetch()}
                                                        components={customCreatableIndicator}
                                                        onChange={handleChangeMouldShape}
                                                        value={valueCavityCreatable == null ? valueCavity : valueCavityCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.mould_shape?.message}</span>


                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1"> Production Mould Size mm
                                                        {/* <span style={{ color: 'red' }}>*</span> */}
                                                    </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder=" Production Mould Size mm" className="form-control"
                                                            disabled={(valueCavity?.mouldA ? false : true)}
                                                            {...register("product_mould_diameter_mm", { onChange: ((e: any) => setValueSize(e.target.value)), required: { value: valueCavity?.mouldA === "false" ? true : false, message: " Production Mould Size mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.product_mould_diameter_mm?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1"> Production Mould Width mm </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder=" Production Mould	Width mm" className="form-control"
                                                            disabled={(valueCavity?.mouldC ? false : true)}
                                                            {...register("product_width_diameter", { onChange: ((e: any) => setValueWidth(e.target.value)), required: { value: valueCavity?.mouldC === "false" ? true : false, message: " Production Mould Width mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.product_width_diameter?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1"> Production Mould Length mm </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder=" Production Mould	Length mm" className="form-control"
                                                            disabled={(valueCavity?.mouldB ? false : true)}
                                                            {...register("product_length_diameter", { onChange: ((e: any) => setValueLength(e.target.value)), required: { value: valueCavity?.mouldB === "false" ? true : false, message: " Production Mould	Length mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.product_length_diameter?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Cavity Hole Height mm
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Cavity Hole Height mm" className="form-control"
                                                            {...register("mould_cavity_hole_height_mm", { onChange: ((e: any) => setValueCavityHoleHeight(e.target.value)), required: { value: true, message: "Mould Cavity Hole Height mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.mould_cavity_hole_height_mm?.message}</span>

                                                </div>



                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Cavity Qty <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Cavity Qty" className="form-control"
                                                            {...register("cavity_qty", { required: { value: true, message: "Mould Cavity Qty is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.cavity_qty?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Lip <span style={{ color: 'red' }}>*</span>  </label>
                                                    {/* <div className="input-group" >

                                                        <input type="number" placeholder="Mould Lip mm" className="form-control"
                                                            {...register("mould_lip_mm", { onChange: ((e: any) => setValueMouldLip(e.target.value)), required: { value: false, message: "Mould Lip mm is required" } })}
                                                        /> */}

                                                    <select className="form-select" {...register("mould_lip_mm",
                                                        {

                                                            required: { value: true, message: "Mould Lip mm is required" }, maxLength: 30,

                                                        }
                                                    )}>
                                                        <option value="" selected >Select Any</option>
                                                        <option value={"Bend Lip"}>Bend Lip</option>
                                                        <option value={"Flat Lip"}>Flat Lip</option>
                                                    </select>

                                                    {/* <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div> */}
                                                    <span className='text-red'>{errors?.mould_lip_mm?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Cutter Gap mm <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Cutter Gap mm" className="form-control"
                                                            {...register("cutter_gap", { onChange: ((e: any) => setValueMouldCutterGap(e.target.value)), required: { value: true, message: "Mould Cutter Gap mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.cutter_gap?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Cutter Thikness mm <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Cutter Thikness mm" className="form-control"
                                                            {...register("mould_cutter_thickness_mm", { onChange: ((e: any) => setvalueMouldCuttermm(e.target.value)), required: { value: true, message: "Mould Cutter Thikness mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.mould_cutter_thickness_mm?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Cutter ID mm <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Cutter ID mm" className="form-control"
                                                            {...register("mould_cutter_id_mm", { onChange: ((e: any) => setvalueMouldCutterIdmm(e.target.value)), required: { value: true, message: "Mould Cutter ID mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.mould_cutter_id_mm?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Cutter OD mm <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Cutter OD mm" className="form-control"
                                                            {...register("mould_cutter_od_mm", { onChange: ((e: any) => setvalueMouldCutterOdmm(e.target.value)), required: { value: true, message: "Mould Cutter OD mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.mould_cutter_od_mm?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">T.F.M. Chain In field Minimum mm <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="T.F.M. Chain In field Minimum mm" className="form-control"
                                                            {...register("tfm_chain_infield_minimum_mm", { onChange: ((e: any) => setValueTFMMinimum(e.target.value)), required: { value: true, message: "T.F.M. Chain In field Minimum mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.tfm_chain_infield_minimum_mm?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">T.F.M. Chain In field Maximum mm <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="T.F.M. Chain In field Maximum mm" className="form-control"
                                                            {...register("tfm_chain_infield_maximum_mm", { onChange: ((e: any) => setValueTFMMaximum(e.target.value)), required: { value: true, message: "T.F.M. Chain In field Maximum mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.tfm_chain_infield_maximum_mm?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Forming Cutting Area Sheet Length mm <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Forming Cutting Area Sheet Length mm" className="form-control"
                                                            {...register("forming_cutting_area_sheet_length_mm", { onChange: ((e: any) => setValueFormingCuttingLength(e.target.value)), required: { value: true, message: "Forming Cutting Area Sheet Length mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.forming_cutting_area_sheet_length_mm?.message}</span>

                                                </div>



                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Forming Cutting Area Sheet Width mm <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Forming Cutting Area Sheet Width mm" className="form-control"
                                                            {...register("forming_cutting_area_sheet_width_mm", { onChange: ((e: any) => setValueFormingCuttingWidth(e.target.value)), required: { value: true, message: "Forming Cutting Area Sheet Width mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.forming_cutting_area_sheet_width_mm?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Sheet Role Length Meter <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Sheet Length mm" className="form-control"
                                                            {...register("sheet_length", { required: { value: true, message: "Mould Sheet Length mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.sheet_length?.message}</span>

                                                </div>



                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Sheet Size Width mm <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Sheet Width mm" className="form-control"
                                                            {...register("sheet_width", { onChange: ((e: any) => setValueSheetSizeWidth(e.target.value)), required: { value: true, message: "Mould Sheet Width mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.sheet_width?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Length mm <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Length mm" className="form-control"
                                                            {...register("length", { onChange: ((e: any) => setValueMouldLengthmm(e.target.value)), required: { value: true, message: "Mould Length mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.length?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Width mm <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Width mm" className="form-control"
                                                            {...register("mould_width_mm", { onChange: ((e: any) => setValueMouldWidthmm(e.target.value)), required: { value: true, message: "Mould Width mm is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.mould_width_mm?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Weight Kg <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type="number" placeholder="Mould Weight Kg" className="form-control"
                                                            {...register("mould_weight_kg", { required: { value: true, message: "Mould Weight Kg is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.mould_weight_kg?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Machine Usd Mould & Plant Number <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("tfm_machine_usd_mould", { required: { value: true, message: "Machine Usd Mould & Plant Number is required" } })}
                                                            placeholder={'Machine Usd Mould	& Plant Number'}
                                                            options={options}
                                                            onChange={handleChangeMachine}
                                                            // value={tfmMachineValue}
                                                            value={forMachineValue}
                                                            components={customIndicator}
                                                            // isClearable
                                                            isMulti
                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenMachine(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.tfm_machine_usd_mould?.message}</span>


                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Mould Sp. - Running<span style={{ color: 'red' }}>*</span></label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueMouldUse(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("mould_special_running", { required: { value: true, message: "Mould Sp. - Running is required" } })}
                                                        placeholder={'Select Mould Sp. - Running'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsMouldUse}
                                                        onMenuOpen={() => MouldUseRefetch()}
                                                        onCreateOption={handleCreateMouldUse}
                                                        onChange={handleChangeMouldUse}
                                                        value={valueMouldUseCreatable == null ? valueMouldUse : valueMouldUseCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.mould_special_running?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Purchase Date <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >

                                                        <DatePicker
                                                            className="form-control"
                                                            format={dateFormatList}
                                                            value={forToDate}
                                                            // disabledDate={disabledDate}

                                                            {...register("purchase_date", { required: { value: true, message: "Mould Purchase Date is required" } })}

                                                            onChange={handleToDate}
                                                        />


                                                    </div>
                                                    <span className='text-red'>{errors?.purchase_date?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Mould Manufacture Date <span style={{ color: 'red' }}>*</span></label>
                                                    <DatePicker
                                                        className="form-control"
                                                        format={dateFormatList}
                                                        value={forFromDate}
                                                        // disabledDate={disabledDate}
                                                        {...register("manufacture_date", { required: { value: true, message: "Field is required" } })}

                                                        onChange={handleFromDate}
                                                    />
                                                    <span className='text-red w-100'>{errors?.manufacture_date?.message}</span>


                                                </div>



                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Location <span style={{ color: 'red' }}>*</span>  </label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueMouldLocation(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("mould_location", { required: { value: true, message: "Mould Location is required" } })}
                                                        placeholder={'Select Mould Location'}
                                                        components={customCreatableIndicator}
                                                        options={optionsMouldLocation}
                                                        onMenuOpen={() => MouldLocationRefetch()}
                                                        onCreateOption={handleCreateMouldLocation}
                                                        onChange={handleChangeMouldLocation}
                                                        value={valueMouldLocationCreatable == null ? valueMouldLocation : valueMouldLocationCreatable}

                                                    />
                                                    <span className='text-red'>{errors?.mould_location?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Brand <span style={{ color: 'red' }}>*</span>  </label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueMouldBrand(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("mould_brand", { required: { value: true, message: "Mould Brand is required" } })}
                                                        placeholder={'Select Mould Brand'}
                                                        components={customCreatableIndicator}
                                                        options={optionsMouldBrand}
                                                        onMenuOpen={() => MouldBrandRefetch()}
                                                        onCreateOption={handleCreateMouldBrand}
                                                        onChange={handleChangeMouldBrand}
                                                        value={valueMouldBrandCreatable == null ? valueMouldBrand : valueMouldBrandCreatable}

                                                    />
                                                    <span className='text-red'>{errors?.mould_brand?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Mould Company Name & City <span style={{ color: 'red' }}>*</span>  </label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueCompanyName(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("mould_company", { required: { value: true, message: "Mould Company Name & City is required" } })}
                                                        placeholder={'Select Mould Company Name & City'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsCompanyName}
                                                        onMenuOpen={() => CompanyNameRefetch()}
                                                        onCreateOption={handleCreateCompanyName}
                                                        onChange={handleChangeCompanyName}
                                                        value={valueCompanyNameCreatable == null ? valueCompanyName : valueCompanyNameCreatable}

                                                    />
                                                    <span className='text-red'>{errors?.mould_company?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Mould New-Old <span style={{ color: 'red' }}>*</span>  </label>
                                                    <select className="form-select" {...register("mould_new_old",
                                                        {
                                                            required: { value: true, message: "Mould New-Old is required" }, maxLength: 30,

                                                        }
                                                    )}>
                                                        <option value="" selected >Select Any</option>
                                                        <option value={"new"}>New</option>
                                                        <option value={"old"}>Old</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.mould_new_old?.message}</span>


                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Country Mobile Code <span style={{ color: 'red' }}>*</span>  </label>
                                                    <div className="input-group" >

                                                        <input type={"text"} isPencil={true} placeholder={"Country Mobile Code"} className="form-control"   {...register("country_code", { required: { value: true, message: "Country Mobile Code is required" } })} color={"green"} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.country_code?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Service Mobile Number</label>
                                                    <div className="input-group" >

                                                        <input type={"text"} isPencil={true} placeholder={"Service Mobile Number"}
                                                            // maxLength={10}
                                                            // onInput={maxLengthCheck}
                                                            className="form-control"   {...register("service_mobile_number", {
                                                                required: false,

                                                                pattern: {
                                                                    // value: /^(\+\d{1,3}[- ]?)?\d{10}$/g,
                                                                    value: /^(\d{1,5})(?:\s(\d{1,5}))?(?:\s(\d{1,2}))?$/gm,
                                                                    message: 'Please enter a valid number',
                                                                },
                                                            })} color={"green"}
                                                            onChange={handleNumbercode}

                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.service_mobile_number?.message}</span>

                                                </div>




                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Guarantee Yes-No <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("guarantee_yes_no",
                                                        {
                                                            onChange: onGuaranteeChange,
                                                            required: { value: true, message: "Guarantee Yes-No is required" }, maxLength: 30,

                                                        }
                                                    )}>
                                                        <option value="" selected >Select Any</option>
                                                        <option value={"yes"}>Yes</option>
                                                        <option value={"no"}>No</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.guarantee_yes_no?.message}</span>


                                                </div>
                                                {/* {Guarantee ==="yes"? */}
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Guarantee Month {Guarantee === 'yes' ? <span style={{ color: 'red' }}>*</span> : ''}</label>
                                                    <div className="input-group" >

                                                        <input type={"number"} isPencil={true} placeholder={"Guarantee Month"} className="form-control" disabled={Guarantee === "no" || ""}  {...register("guarantee_months", { required: { value: Guarantee === 'no' ? false : true, message: "Guarantee Month is required" } })} color={"green"} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.guarantee_months?.message}</span>

                                                </div>
                                                {/* :''} */}
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Warranty Yes-No <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("warranty_yes_no",
                                                        {
                                                            onChange: onWarrantyChange,
                                                            required: { value: true, message: "Warranty is required" }, maxLength: 30,

                                                        }
                                                    )}>
                                                        <option value="" selected >Select Any</option>
                                                        <option value={"yes"}>Yes</option>
                                                        <option value={"no"}>No</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.warranty_yes_no?.message}</span>


                                                </div>
                                                {/* {Warranty === "yes" ? */}
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Warranty Month {Warranty === 'yes' ? <span style={{ color: 'red' }}>*</span> : ''}</label>
                                                    <div className="input-group" >

                                                        <input type={"number"} isPencil={true} placeholder={"Warranty Month"} className="form-control" disabled={Warranty === "no" || ""} {...register("warranty_time", { required: { value: Warranty === 'no' ? false : true, message: "Warranty Month is required" } })} color={"green"} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.warranty_time?.message}</span>

                                                </div>
                                                {/* : ""} */}

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Remark</label>
                                                    <div className="input-group" >

                                                        <input type={"text"} isPencil={true} placeholder={"Remark"} className="form-control"   {...register("remark", { onChange: handleValue, required: { value: false, message: "Remark is required" } })} color={"green"} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.remark?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status",
                                                        {
                                                            required: { value: true, message: " Status is required" }, maxLength: 30,

                                                        }
                                                    )}>
                                                        <option value="" selected >Select Any</option>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"inactive"}>Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>


                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Mould Image All</label>
                                                    <Upload
                                                        {...register("image")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={FileOnChange}
                                                        multiple={true}
                                                        defaultFileList={uploadFile}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload>

                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Mould PDF Drawing All</label>
                                                    <label style={{ cursor: "pointer" }}>
                                                    </label>
                                                    <Upload
                                                        {...register("pdf")}
                                                        accept=".doc,.docx,.xml,.pdf"
                                                        listType="text"
                                                        onChange={DocOnChange}
                                                        multiple={true}
                                                        defaultFileList={uploadFileDoc}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"pdf"}
                                                    >
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                    </Upload>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        onClick={handleSubmit(onSubmit)} disabled={isLoading}>Submit</button>
                                                    <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listMould")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
            <HSN_Model refetchForm={HSNRefetch} open={openHSN} setOpen={setOpenHSN} setSubmitted={setSubmittedHSN} />
            <Machine_Model refetchForm={machineRefetch} open={openMachine} setOpen={setOpenMachine} setSubmitted={setSubmittedMachine} />

            {/* Edit Modal HTML */}
            <Modal
                show={deleteConfirm}
                onHide={() => setDeleteConfirm(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete {""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {" "}
                        <span className="text-center">Sure you want to delete it?</span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setDeleteConfirm(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={handleMultipleDeleted}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Deleteforce
                key={renderKey}
                handleDeletedforce={handleDeletedforce}
                reconfirmdelete={confirmforcemodal}
                masterName={""}
                closereconfirmdelete={closereconfirmdelete}
                referance={
                    deletesucess
                        ? deletedata
                        : ""
                }
                apiname={apiName}
            />
            <Deletesingle
                key={renderKey}
                confirmmodal={deleteConfirmsingle}
                confirmmodalclose={confirmmodalclose}
                masterName={""}
                confirmmodalsubmit={confirmmodalsubmit}
                apiname={apiName}
            />

            <SimpleInput_Edit key={renderKey}
                refetchForm={handleRefetch}
                open={OpenEditDropDownModel}
                setOpen={setOpenEditDropDownModel}
                id={editId}
                // superAccountId={superAccountId} 
                // optionsSuperAccount={optionsSuperAccount}
                // isSuperAccount={isSuperAccount}
                apiname={apiName} />
        </div>
    )
}

export default Add_Mould