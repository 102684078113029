import { createSlice } from '@reduxjs/toolkit'

export const toaststate = createSlice({
  name: 'toast',
  initialState: {
    value: {
      visibility: false,
      message: "Successful",
      severity: "success"
    },
  },
  reducers: {
    setTrue: (state) => {
      state.value.visibility = true
    },
    setFalse: (state) => {
      state.value.visibility = false
    },
    setMessage: (state, action) => {
      state.value = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setTrue, setFalse, setMessage} = toaststate.actions

export default toaststate.reducer