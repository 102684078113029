import React, { useEffect, useRef, useState } from 'react'
import './ContactForm.css'
import { FaLocationArrow } from 'react-icons/fa';
import { GrLocation } from 'react-icons/gr';

import { BsTelephone } from 'react-icons/bs';

import { AiOutlineMail } from 'react-icons/ai';

import { CgWebsite } from 'react-icons/cg';
import Select from "react-select";

import { RiPencilLine } from "react-icons/ri";
import { useNavigate, useParams } from 'react-router-dom';
import useFocusOnEnter from '../../../components/focus';
import cameraLogo from "../../../images/Camera.png"
import logo from "../../../images/logo.png"
import { useForm } from 'react-hook-form';
import { useFetchCityDropDown, useFetchMaterialTypeDropDown, useFetchUOMDropDown } from '../../../hooks/DropDown_Api';
import { useCreate, useFetchSingle, useUpdateSingle } from '../../../hooks/Api';
import { DatePicker } from 'antd';
import moment from "moment";
import dayjs from 'dayjs';
import { RangePickerProps } from 'antd/es/date-picker';
import { Alert } from "../../../components/Model/Model";
import { RxCross2 } from 'react-icons/rx';

const Edit_Online_Inquiry = () => {



  let pageName = "Online Inquiry"
  const focus:any = useRef();
  const {onEnterKey} = useFocusOnEnter(focus);
  const navigate = useNavigate()
  const [visitingCard,setVisitingCard] = useState(null)
  const [optionsProductType, setOptionsProductType] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [productType, setProductType]:any = useState("");
  const [prductTypeValue, setProductTypeValue]: any = useState("");


  const onLogoChange = (e:any) => {
      setVisitingCard(e.target.files[0].name)
  }
  
  
  const apiname = process.env.REACT_APP_SALES_ONLINE_INQUIRY_API?.toString();




    const { id } = useParams()
    const { data } = useFetchSingle(id, apiname)
    const { mutate, isSuccess, isError, error, data: panniUpdated_response }: any = useUpdateSingle()

    const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();





    useEffect(() => {
        if (data) {

            reset(data)
            setValue("product_type",data?.product_type?.id)
            setSelectedProductType(data?.product_type?.id)

            setValue("uom",data?.uom?.id)
            setSelectedUOM(data?.uom?.id)

            setValue("village_code_and_name",data?.village_code_and_name?.id)
            setSelectedCity(data?.village_code_and_name?.id)


            setForState(data?.village_code_and_name?.state?.id)
            setForCountry(data?.village_code_and_name?.country?.id)
            setValue("state",data?.village_code_and_name?.state?.id)
            setValue("country",data?.village_code_and_name?.country?.id)


           
           
        }
    }, [data]);



    const onSubmit = (branddetail: any) => {
        if(!visitingCard){
            delete  branddetail.visiting_card_image
        }

        const formData = new FormData();
        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else if (Array.isArray(value) && key === "use_employee_code_and_surname_and_name") {
                value.forEach((val, index) => formData.append(`use_employee_code_and_surname_and_name`, val))
            }
            else if (key === "visiting_card_image"){
              if (typeof value === 'object' && value !== null && formvalue.length > 0) {
                  formData.append(key, formvalue[0])
              }
              else {
                  formData.append(key, "")
              }
          }
        }
        let data = {
            apiname: apiname,
            id: id,
            data: formData
        }

        mutate(data);
    }





const [selectedProductType,setSelectedProductType]:any = useState("")
  const { data: productTypeData, refetch: productTypeRefetch } = useFetchMaterialTypeDropDown();

useEffect(() => {
  setOptionsProductType(productTypeData);
}, [productTypeData]);


const handleChangeProductType = (e:any)=>{
  setProductTypeValue(e)
  setValue("product_type",e.value)
}


const [optionsUOM,setOptionsUOM]:any = useState()
const [forUOM,setForUOM]:any = useState("")

const { data: uomData, refetch: uomRefetch } = useFetchUOMDropDown();

useEffect(() => {
  setOptionsUOM(uomData);
}, [uomData]);

const [selectedUOM,setSelectedUOM]= useState("")
const handleChangeUOM = (e:any)=>{
  setForUOM(e)
  setValue("uom",e.value)
}


const[forCityValue,setForCityValue]:any = useState("")
const [cityOptions,setCityOptions]:any = useState()
const[totalCity,setTotalCity]:any = useState(0)

const {data:cityData, refetch:cityRefetch} = useFetchCityDropDown()


useEffect(()=>{
    setCityOptions(cityData)
},[cityData])

const[selectedCity,setSelectedCity] = useState("")
const [forState,setForState]:any = useState("")
const [forCountry,setForCountry]:any = useState("")


const handleChangeCity=(e:any)=>{

    
  //   let city = e?.map((e:any)=>{
  //       return e.value
  //   })

  //   let StateAndCountry = e?.map((e:any)=>{
  //       return {state:e.state,country:e.country,stateID:e.stateID,countryID:e.countryID}
  //   })


    setForCityValue(e)
    setValue("village_code_and_name",e.value)

  //   setTotalCity(city?.length)
  //   setValue("total_city",city?.length)

    setForState(e?.state)
    setForCountry(e?.country)
    setValue("state",e?.stateID)
    setValue("country",e?.countryID)
}






const [requirementDate,setRequirementDate]:any = useState(null)

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {

      return  current && current  < dayjs().startOf('day');
    };


    const handleRequirementDate=(e:any)=>{
setRequirementDate(dayjs(e, 'DD/MM/YYYY'))
setValue("requirement_date",`${e?.$D}-${e?.$M+1}-${e?.$y}`)
    }


  
    let dateOne = data?.requirement_date
    let fromDateValue = dateOne


  
  useEffect(()=>{
      if(isSuccess){
          reset()
          setProductTypeValue(null)
          setRequirementDate(null)
          setForUOM(null)
          setForCityValue(null)
          setForState(null)
          setForCountry(null)
          setVisitingCard(null)
      }
  },[isSuccess])
  
  
  const handleReset = () => {
      reset()
      setProductTypeValue(null)
      setRequirementDate(null)
      setForUOM(null)
      setForCityValue(null)
      setForState(null)
      setForCountry(null)
  }



  const [show, setShow] = useState(false)
  const [showmessage, setshowmessage] = useState("")

  useEffect(() => {
      if (isSuccess) {
          setShow(true)
          setshowmessage("success")
          navigate('/listOnlinesalesinquiry', { state: { showToast: true }})
      }
      else if (isError) {
          setShow(true)
          setshowmessage("danger")
      }
  }, [data, error,isSuccess])



  useEffect(() => {
      setTimeout(() => {
          setShow(false)
      }, 5000)

  }, [show]);

  const maxLengthCheck = (object:any) => {
      if (object.target.value.length > object.target.maxLength) {
       object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
      }





  return (
    <div>
    <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
        <section className="content-header px-3 py-1">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6">
                        <h4>{pageName}</h4>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Home</a></li>
                            <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Sales</a></li>
                            <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">{pageName}</a></li>
                            <li className="breadcrumb-item active">Add {pageName}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section className="content">
            <div className="container-fluid">
                <div className="card card-default">
                    {/* <div className="card-header">
                        <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Document</span></h5>
                    </div> */}
                     <form ref={focus}
                             onKeyUp={event => onEnterKey(event)}>

<div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>
                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message.name} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                <div className='row'>
                                    <div className='col-lg-3 mt-2' >
                                        <label htmlFor="exampleInputEmail1">Company Name <span style={{ color: 'red' }}>*</span></label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" id="exampleInputBrand" placeholder="Compony Name" 
                                            {...register("company_name",{ required: { value: true, message: "Field is required" }, maxLength: 30,})}
                                            />
                                            <div className="input-group-append" >
                                                <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                // style={{backgroundColor:"blue",color:"green"}} 
                                                /></span>
                                            </div>
                                           
                                        </div>
                                        <span className='text-red w-100'>{errors?.company_name?.message}</span>
                                    </div>
                                    <div className='col-lg-3 mt-2' >
                                        <label htmlFor="exampleInputEmail1">Product Type <span style={{ color: 'red' }}>*</span></label>
                                        
                                        <Select
                                            {...register("product_type", {
                                            required: {
                                                value: true,
                                                message: "Field is required",
                                            },
                                            })}
                                            onChange={handleChangeProductType}
                                            options={optionsProductType}
                                            value={prductTypeValue==""?optionsProductType?.filter((e:any)=> e.value === selectedProductType):prductTypeValue}
                                        //     isOptionDisabled={(optionsProductType: any) => {
                                        //     if (optionsProductType.status === "inactive" || optionsProductType.deleted == true) {
                                        //         return optionsProductType
                                        //     }
                                        // }}
                                        />
                                        <span className='text-red w-100'>{errors?.product_type?.message}</span>

                                    </div>
                                    <div className='col-lg-3 mt-2' >
                                        <label htmlFor="exampleInputEmail1">Product Description <span style={{ color: 'red' }}>*</span></label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product Description" 
                                             {...register("product_description",{ required: { value: true, message: "Field is required" }, maxLength: 30,})}
                                            />
                                            {/* <span className='text-red w-100'>{errors?.name?.message}</span> */}
                                            <div className="input-group-append" >
                                                <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                // style={{backgroundColor:"blue",color:"green"}} 
                                                /></span>
                                            </div>
                                         
                                        </div>
                                        <span className='text-red w-100'>{errors?.product_description?.message}</span>
                                    </div>
                                    <div className='col-lg-3 mt-2' >
                                        <label htmlFor="exampleInputEmail1">Product Quantity <span style={{ color: 'red' }}>*</span></label>
                                        <div className="input-group">
                                            <input type="number" className="form-control" id="exampleInputBrand" placeholder="Product Quantity" 
                                             {...register("product_qty",{ required: { value: true, message: "Field is required" }, maxLength: 30,})}
                                            />
                                            <div className="input-group-append" >
                                                <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                // style={{backgroundColor:"blue",color:"green"}} 
                                                /></span>

                                            </div>
                                        </div>
                                        <span className='text-red w-100'>{errors?.product_qty?.message}</span>
                                    </div>
                                    <div className='col-lg-3 mt-2' >
                                        <label htmlFor="exampleInputEmail1">U.O.M <span style={{ color: 'red' }}>*</span></label>
                                        <Select
                                            {...register("uom", {
                                            required: {
                                                value: true,
                                                message: "Field is required",
                                            },
                                            })}
                                            onChange={handleChangeUOM}
                                            options={optionsUOM}
                                            value={forUOM == ""?optionsUOM?.filter((e:any)=>e.value === selectedUOM):forUOM}
                                        //     isOptionDisabled={(optionsUom: any) => {
                                        //     if (optionsUom.status === "inactive" || optionsUom.deleted == true) {
                                        //         return optionsUom
                                        //     }
                                        // }}
                                        />
                                        <span className='text-red w-100'>{errors?.uom?.message}</span>

                                    </div>
                                    <div className='col-lg-3 mt-2' >
                                    <label > Requirement Date <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >

                                                    <DatePicker
                                                            className="form-control"
                                                            format={dateFormatList}
                                                            // value={requirementDate}
                                                            value={requirementDate == undefined ? dayjs(fromDateValue, "DD/MM/YYYY") : requirementDate}
                                                            disabledDate={disabledDate}
                                                        
                                                            {...register("requirement_date", { required: { value: true, message: "Field is required" } })}

                                                            onChange={handleRequirementDate}
                                                        />

                                                        
                                                    </div>
                                                    <span className='text-red'>{errors?.requirement_date?.message}</span>
                                    </div>
                                    <div className='col-lg-3 mt-2' >
                                    <label htmlFor="exampleInputEmail1">Mobile Number <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Mobile Number" 
                                                        maxLength={10}
                                                        minLength={15}
                                                        onInput={maxLengthCheck}
                                                          {...register('mobile_number', {
                                                            required: 'Mobile Number is required',
                                                            pattern: {
                                                                value: /^(\+\d{1,3}[- ]?)?\d{10}$/g,
                                                                message: 'Please enter a valid number',
                                                            },
                                                        })}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.mobile_number?.message}</span>
                                    </div>
                                    <div className='col-lg-3 mt-2' >
                                    <label htmlFor="exampleInputEmail1">Email Address <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Email Address" 
                                                         {...register('email_address', {
                                                            required: 'Email is required',
                                                            pattern: {
                                                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                                                                message: 'Please enter a valid email',
                                                            },
                                                        })}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.email_address?.message}</span>
                                    </div>
                                    <div className='col-lg-3 mt-2' >
                                    <label htmlFor="exampleInputEmail1">Village Code & Name <span style={{ color: 'red' }}>*</span></label>
                                    <Select
                                            {...register("village_code_and_name", {
                                            required: {
                                                value: true,
                                                message: "Field is required",
                                            },
                                            })}
                                            onChange={handleChangeCity}
                                            options={cityOptions}
                                            value={forCityValue==""?cityOptions?.filter((e:any)=> e.value === selectedCity):forCityValue}
                                        //     isOptionDisabled={(optionsUom: any) => {
                                        //     if (optionsUom.status === "inactive" || optionsUom.deleted == true) {
                                        //         return optionsUom
                                        //     }
                                        // }}
                                        />
                                        <span className='text-red w-100'>{errors?.village_code_and_name?.message}</span>
                                    </div>
                                    <div className='col-lg-3 mt-2' >
                                    <label >State Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                    <input disabled type="text" className="form-control" value={forState}  placeholder="State Name" 
                                                    // {...register("state", { required: { value: true, message: "Field is required" } })} 
                                                    />
                                                    
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.state?.message}</span>
                                    </div>
                                    <div className='col-lg-3 mt-2' >
                                    <label >Country Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                    <input disabled type="text" className="form-control" value={forCountry} placeholder="Country Name" 
                                                    // {...register("country", { required: { value: true, message: "Field is required" } })} 
                                                    />
                                                    
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.country?.message}</span>
                                    </div>

                                    {/* <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-12 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Visiting Card Image</label>
                                                    <label style={{cursor:"pointer"}}>
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                        <input data-required="image" type="file"  id="file_upload" accept="image/*" hidden className="image-input" data-traget-resolution="image_resolution" />
                                                    </label>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div> */}




                                    <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Visiting Card Image</label>
                                                    <label style={{cursor:"pointer"}}>
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                        <input data-required="image" type="file"  id="file_upload" accept="image/*" className="image-input" data-traget-resolution="image_resolution"  {...register("visiting_card_image",{onChange:onLogoChange, required:false})} hidden />
                                                    </label>
                                                    {/* {visitingCard&&<p style={{display:"inline-flex"}}>{visitingCard} 
                                                    <button style={{border:"none"}} onClick={()=>setVisitingCard(null)}><RxCross2 color='red' size={20}/></button>
                                                    </p>} */}
                                                    <a href={data?.visiting_card_image} target="_blank" ><p>{visitingCard == null ? data?.visiting_card_image?.split("/").pop() : visitingCard}</p></a>
                                                </div>
                                              
                                            </div>
                                        </div>
                                    </div>






                                    
                                </div>
                                </div>
                                
                                <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                <a type="button" className="btn border rounded text-white px-4 mt-2 mx-3" onClick={handleSubmit(onSubmit)}>Submit</a>

{/* <button type="button" className="btn border-1 rounded  border-1 px-4 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button> */}

<button type="button" className="btn border-1 rounded  px-4 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listOnlinesalesinquiry")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </form>

                </div>
            </div>
        </section>
    </div>
</div>
  )
}

export default Edit_Online_Inquiry