import { useEffect, useRef, useState } from "react";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import { useCreate, useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../../hooks/Api";
import Select from "react-select"
import {
    useFetchBrandDropDown, useFetchColorDropDown, 
    useFetchMaterialDensityDropDown,
    useFetchMaterialNameDropDown, 
    useFetchMaterialUseDropDown, useFetchMaterialandHSNDropDown, 
    useFetchParticularNameDropDown, useFetchMouldShapeDropDown, 
    useFetchDisposableDropDown, useFetchSheetCoreDropDown, 
    useFetchMachineLocationDropDown,useFetchMouldDropDown
} from "../../../hooks/DropDown_Api";
import { Alert } from "../../../components/Model/Model";
import useFocusOnEnter from "../../../components/focus";
import { AiOutlinePlus } from "react-icons/ai";
import Button from "react-bootstrap/esm/Button";
import { Material_Density_Model } from "../../../components/Dynamic_Form_Models/Material_Density_Model";
import { Color_Model } from "../../../components/Dynamic_Form_Models/Color_Model";
import { Brand_Model } from "../../../components/Dynamic_Form_Models/Brand_Model";
// import IndicatorsContainer from '../../components/Select Dropdown/Select'
import CreatableSelect from "react-select/creatable";
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import { HSN_Model } from "../../../components/Dynamic_Form_Models/HSN_Model";
import { Mould_Model } from "../../../components/Dynamic_Form_Models/Mould";
import Axiosinstance from "../../../hooks/Interceptor";

import Modal from "react-bootstrap/Modal";
import Actions, { Deleteforce, Deletesingle } from "../../../components/Cell/Actions";
import { useDelete, useDeletemforce } from "../../../hooks/delete";
import Custom_Actions_Buttons from "../../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../../hooks/deletemultiple";
import { SimpleInput_Edit } from "../../../components/Dynamic_Form_Models/SimpleInput_Edit";
import { Deletesinglewithdependancy } from "../../../components/modal";

const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}


interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});

const Edit_Sheet_Code = () => {
    const [open, setOpen] = useState(false)
    const [colorOpen, setColorOpen] = useState(false)
    const [brandOpen, setBrandOpen] = useState(false)


    const navigate = useNavigate()
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);

    const { id } = useParams()

    const apiname = process.env.REACT_APP_PRODUCTION_SHEET_CODE_API?.toString()
    const { data } = useFetchSingle(id, apiname)
    const { mutate, isSuccess, isError, error, data: gstUpdated_response, isLoading }: any = useUpdateSingle()


   
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue, getValues }: any = useForm();



    const [openEditModel, setOpenEditModel] = useState(false)

    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()
    const [expirationDate, setExpirationDate] = useState()
  
    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()



    const [openMouldName, setOpenMouldName] = useState(false)



    const [options, setOptions]: any = useState([])
    const [searchTerm, setSearchTerm] = useState('')



    const [optionsbrand, setOptionsBrand]: any = useState([])

    const [GST, setGST] = useState("")

    const [optionsPanniName, setOptionsPanniName]: any = useState([])
    const [polymer, setPolymer] = useState("")
    const [optionsMaterial, setOptionsMaterial]: any = useState([]);
    const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueMaterial, setValueMaterial]: any = useState();

    const apinameForMaterial = process.env.REACT_APP_MATERIAL_NAME_API?.toString()

    const { mutate: mutateMaterial, data: dataMaterial, isError: MaterialIsError, isLoading: MaterialLoading, isSuccess: MaterialSuccess, error: MaterialError }: any = useCreate(apinameForMaterial)

    const [valueMaterialCreatable, setValueMaterialCreatable] = useState<Option | null>();
    const { data: MaterialData, refetch: MaterialRefetch } = useFetchMaterialNameDropDown()
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsMaterial(MaterialData)
    }, [MaterialData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            MaterialRefetch()
        }
    }, [])


    const handleChangeMaterial = (e: any) => {

        setValue("name", `${e?.value}`)
        setValueMaterialCreatable(null)
    }





    const handleCreate = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateMaterial({ name: newOption.name, status: newOption.status })
            setOptionsMaterial((prev: any) => [...prev, newOption]);
            setValueMaterialCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (MaterialSuccess && optionsMaterial) {
                await MaterialRefetch();
            }
            setValue("name", MaterialData[0]?.value)
        })();

        return () => {
        };
    }, [MaterialSuccess, MaterialData]);

    




    const apinameForParticularName = process.env.REACT_APP_PRODUCTION_PARTICULAR_NAME_API?.toString()
    const { mutate: mutateParticularName, data: dataParticularName, isError: ParticularNameIsError, isLoading: ParticularNameLoading, isSuccess: ParticularNameSuccess, error: ParticularNameError }: any = useCreate(apinameForParticularName)

    const [valueParticularName, setValueParticularName]: any = useState();
    const [selectedParticularName, setSelectedParticularName]: any = useState('');
    const [optionsParticularName, setOptionsParticularName]: any = useState([]);
    const [valueParticularNameCreatable, setValueParticularNameCreatable] = useState<Option | null>();
    const { data: ParticularNameData, refetch: ParticularNameRefetch } = useFetchParticularNameDropDown()

    useEffect(() => {
        setOptionsParticularName(ParticularNameData)
    }, [ParticularNameData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            ParticularNameRefetch()
        }
    }, [])


    const handleChangeParticularName = (e: any) => {

        ParticularNameRefetch()

        setApiName(apinameForParticularName)
        setDeleteApiName(apinameForParticularName)
        setEditId(e?.value)
        setEditData(ParticularNameData)
        setRenderKey(prevKey => prevKey + 1);

        setValueParticularName(e)
        setValue("particular_name", `${e?.value}`)
        setValueParticularNameCreatable(null)
    }





    const handleCreateParticularName = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateParticularName({ name: newOption.name, status: newOption.status })
            setOptionsParticularName((prev: any) => [...prev, newOption]);
            setValueParticularNameCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ParticularNameSuccess && optionsParticularName) {
                await ParticularNameRefetch();
            }
            setValue("particular_name", ParticularNameData[0]?.value)
        })();

        return () => {
        };
    }, [ParticularNameSuccess, ParticularNameData]);


    const apinameForSheetCore = process.env.REACT_APP_PRODUCTION_SHEET_ROLL_CORE_ID_API?.toString()
    const { mutate: mutateSheetCore, data: dataSheetCore, isError: SheetCoreIsError, isLoading: SheetCoreLoading, isSuccess: SheetCoreSuccess, error: SheetCoreError }: any = useCreate(apinameForSheetCore)

    const [valueSheetCore, setValueSheetCore]: any = useState();
    const [selectedSheetCore, setSelectedSheetCore]: any = useState();
    const [optionsSheetCore, setOptionsSheetCore]: any = useState([]);
    const [valueSheetCoreCreatable, setValueSheetCoreCreatable] = useState<Option | null>();

    const { data: SheetCoreData, refetch: SheetCoreRefetch } = useFetchSheetCoreDropDown()

    useEffect(() => {
        setOptionsSheetCore(SheetCoreData)
    }, [SheetCoreData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            SheetCoreRefetch()
        }
    }, [])


    const handleChangeSheetCore = (e: any) => {
        SheetCoreRefetch()

        setApiName(apinameForSheetCore)
        setDeleteApiName(apinameForSheetCore)
        setEditId(e?.value)
        setEditData(SheetCoreData)
        setRenderKey(prevKey => prevKey + 1);

        setValueSheetCore(e)
        setValue("sheet_roll_core_id_mm", `${e?.value}`)
        setValueSheetCoreCreatable(null)
    }





    const handleCreateSheetCore = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateSheetCore({ name: newOption.name, status: newOption.status })
            setOptionsSheetCore((prev: any) => [...prev, newOption]);
            setValueSheetCoreCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (SheetCoreSuccess && optionsSheetCore) {
                await SheetCoreRefetch();
            }
            setValue("sheet_roll_core_id_mm", SheetCoreData[0]?.value)
        })();

        return () => {
        };
    }, [SheetCoreSuccess, SheetCoreData]);




   


    const apinameForProductLocation = process.env.REACT_APP_MACHINE_LOCATION_API?.toString()
    const { mutate: mutateProductLocation, data: dataProductLocation, isError: ProductLocationIsError, isLoading: ProductLocationLoading, isSuccess: ProductLocationSuccess, error: ProductLocationError }: any = useCreate(apinameForProductLocation)

    const [valueProductLocation, setValueProductLocation]: any = useState();
    const [selectedProductLocation, setSelectedProductLocation]: any = useState();
    const [optionsProductLocation, setOptionsProductLocation]: any = useState([]);
    const [valueProductLocationCreatable, setValueProductLocationCreatable] = useState<Option | null>();

    const { data: ProductLocationData, refetch: ProductLocationRefetch } = useFetchMachineLocationDropDown()

    useEffect(() => {
        setOptionsProductLocation(ProductLocationData)
    }, [ProductLocationData])


    // useEffect(() => {
    //     if (searchTerm?.length >= 1) {
    //         MaterialUseRefetch()
    //     }
    // }, [])


    const handleChangeProductLocation = (e: any) => {

        ProductLocationRefetch()

        setApiName(apinameForProductLocation)
        setDeleteApiName(apinameForProductLocation)
        setEditId(e?.value)
        setEditData(ProductLocationData)
        setRenderKey(prevKey => prevKey + 1);

        setValueProductLocation(e)
        setValue("product_location", `${e?.value}`)
        setValueProductLocationCreatable(null)
    }





    const handleCreateProductLocation = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateProductLocation({ name: newOption.name, status: newOption.status })
            setOptionsProductLocation((prev: any) => [...prev, newOption]);
            setValueProductLocationCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ProductLocationSuccess && optionsProductLocation) {
                await ProductLocationRefetch();
            }
            setValue("product_location", ProductLocationData[0]?.value)
        })();

        return () => {
        };
    }, [ProductLocationSuccess, ProductLocationData]);




    const { data: colorData, refetch: colorRefetch } = useFetchColorDropDown(searchTerm)

    useEffect(() => {
        setOptions(colorData)
    }, [colorData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            colorRefetch()
        }
    }, [])




    const { data: brandData, refetch: brandRefetch } = useFetchBrandDropDown(searchTerm)

    useEffect(() => {
        setOptionsBrand(brandData)
    }, [brandData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            brandRefetch()
        }
    }, [])


    const [valueSheetRollCreatable, setValueSheetRollCreatable] = useState<Option | null>();
    const [valueSheetRoll, setValueSheetRoll]: any = useState();
    const [selectedSheetRoll, setSelectedSheetRoll]: any = useState('');
    const apinameForSheetRoll = process.env.REACT_APP_MATERIAL_USE_API?.toString()
    const { mutate: mutateSheetRoll, data: dataSheetRoll, isError: SheetRollIsError, isLoading: SheetRollLoading, isSuccess: SheetRollSuccess, error: SheetRollError }: any = useCreate(apinameForSheetRoll)

    const [optionsSheetRoll, setOptionsSheetRoll]: any = useState([]);


    const { data: SheetRollData, refetch: SheetRollRefetch } = useFetchMaterialUseDropDown()
    // console.log(SheetRollData, "optionsBrand");
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsSheetRoll(SheetRollData)
    }, [SheetRollData])

    const handleChangeSheetRoll = (e: any) => {

        SheetRollRefetch()

        setApiName(apinameForSheetRoll)
        setDeleteApiName(apinameForSheetRoll)
        setEditId(e?.value)
        setEditData(SheetRollData)
        setRenderKey(prevKey => prevKey + 1);

        setValueSheetRoll(e)
        setValue("sheet_roll_special_running", `${e?.value}`)
        setValueSheetRollCreatable(null)
    }

    const handleCreateSheetRoll = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateSheetRoll({ name: newOption.name, status: newOption.status })
            setOptionsSheetRoll((prev: any) => [...prev, newOption]);
            setValueSheetRollCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (SheetRollSuccess && optionsSheetRoll) {
                await SheetRollRefetch();
            }
            setValue("sheet_roll_special_running", SheetRollData[0]?.value)
        })();

        return () => {
        };
    }, [SheetRollSuccess, SheetRollData]);


    const { data: materialdensityData, refetch: materialdensityRefetch } = useFetchMaterialDensityDropDown(searchTerm)

    useEffect(() => {
        setOptionsPanniName(materialdensityData)
    }, [materialdensityData])




    const [forColorValue, setForColorValue]: any = useState([])

    const [selectedColor, setSelectedColor]: any = useState("")
    const [selColor, setSelColor] = useState("")

    const handleChange = (e: any) => {

        // const color = e.map((value: any) => {
        //     return value.value
        // })
        setValue("sheet_color_name", e.value)
        setSelColor(e)
        setForColorValue({ label: e?.label, value: e?.value })
    }

    // const getRelatedHsn = process.env.REACT_APP_GET_RELATED_HSN_API?.toString()
    const [forHSNValue, setForHSNValue]: any = useState('')
    const [optionsHSN, setOptionsHSN]: any = useState([])
    const [selectedHSN, setSelectedHSN]: any = useState("")
    const [selHSN, setSelHSN] = useState("")
    // const [optionsHSN, setOptionsHSN]:any = useState([])



    const { data: HSNData, refetch: HSNRefetch } = useFetchMouldDropDown(searchTerm)

    useEffect(() => {
        let temp = HSNData?.map((e: any) => {
            return (
                {
                    value: e?.value,
                    label: e.mould_name,
                 
                }
            )
        })
        setOptionsHSN(temp)
    }, [HSNData])

    const handleChangeHSN = (e: any) => {
        setSelHSN(e)
        setValue("mould_name_and_type", `${e.value}`)
        // setHSN(e.hsn)
        setForHSNValue(e)
    }

    const [optionsMouldShape, setOptionsMouldShape]: any = useState([])
    const [selectedMouldShape, setSelectedMouldShape]: any = useState([])
    const [selMouldShape, setSelMouldShape] = useState([])
    const [forMouldShapeValue, setForMouldShapeValue]: any = useState('')
    const [mouldQty,setMouldQty]:any = useState('')

    const { data: MouldShapeData, refetch: MouldShapeRefetch } = useFetchMouldShapeDropDown(searchTerm)

    useEffect(() => {
        setOptionsMouldShape(MouldShapeData)
    }, [MouldShapeData])

    const handleChangeMouldShape = (e: any) => {
        // setSelHSN(e)
        const Mould = e.map((e:any)=>{
            return e.value
        }) 
        // setValue("mould_shape", `${e.value}`)
        setValue("mould_shape", Mould)
        setSelMouldShape(e)
        setForMouldShapeValue(e)
        setMouldQty(e?.length)
        setValue("use_mould_shape_qty",e?.length)
        setSelectedMouldShape([])
        // setHSN(e.hsn)

    }

    const [optionsProductCode, setOptionsProductCode]: any = useState([])
    const [forProductCodeValue, setForProductCodeValue]: any = useState('')

    const [selectedProductCode, setSelectedProductCode]: any = useState("")
    const [selProductCode, setSelProductCode] = useState("")
    const [ProductQty, setProductQty]: any = useState('')

    const { data: ProductCodeData, refetch: ProductCodeRefetch } = useFetchDisposableDropDown(searchTerm)

    useEffect(() => {
        setOptionsProductCode(ProductCodeData)
    }, [ProductCodeData])

    const handleChangeProductCode = (e: any) => {
        // setSelHSN(e)
        const ProductCode = e.map((e:any)=>{
            return e.value
        }) 
        setSelProductCode(e)
        setValue("use_product_code", ProductCode)
        setForProductCodeValue(e)
        setProductQty(e?.length)
        setValue("use_product_qty",e?.length)
        // setHSN(e.hsn)
        setSelectedProductCode('')
    }


    useEffect(() => {
        if (data) {
            reset(data)
            setMouldQty(data?.use_mould_shape_qty)
            setValue("use_mould_shape_qty",data?.use_mould_shape_qty)
            setProductQty(data?.use_product_qty)
            setValue("use_product_qty",data?.use_product_qty)
            setSelectedParticularName(data?.particular_name?.id)
            setValue("particular_name",data?.particular_name?.id)
            setSelectedSheetCore(data?.sheet_roll_core_id_mm?.id)
            setValue("sheet_roll_core_id_mm",data?.sheet_roll_core_id_mm?.id)
            setSelectedSheetRoll(data?.sheet_roll_special_running?.id)
            setValue('sheet_roll_special_running',data?.sheet_roll_special_running?.id)
            setSelectedProductLocation(data?.product_location?.id)
            setValue('product_location',data?.product_location?.id)
            setSelectedHSN(data?.mould_name_and_type?.id)
            setValue('mould_name_and_type',data?.mould_name_and_type?.id)
            setSelectedColor(data?.sheet_color_name?.id)
            setValue('sheet_color_name',data?.sheet_color_name?.id)
            // setSelectedMouldShape(data?.mould_shape?.map((e:any)=>e?.id))
            // setValue('mould_shape',data?.mould_shape?.map((e:any)=>e?.id))
            // setSelectedProductCode(data?.use_product_code?.map((e:any)=>e?.id))
            // setValue('use_product_code',data?.use_product_code?.map((e:any)=>e?.id))

            // setSheetRoll(data?.sheet_roll_weight)
            // setValue('sheet_roll_weight',data?.sheet_roll_weight)
        }
    }, [data]);

    const handleReset = () => {
        reset()
        // setForColorValue(null)
        // setGST("")
        // setPolymer("")

    }
const apinameMouldShape = process.env.REACT_APP_MOULD_SHAPE_API?.toString()
const apinameMould = process.env.REACT_APP_MOULD_API?.toString()
// const [dataMouldShape,setDataMouldShape]:any = useState([])
const apinameProductCode = process.env.REACT_APP_DISPOSABLE_PRODUCT_API?.toString()
// const [dataProductCode,setDataProductCode]:any = useState([])
    useEffect(()=>{
        if(data?.mould_shape.length>0){
        const getCall = async()=>{
        try {
            const response = await Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apinameMouldShape}/fetch_multiple_ids/`, {
              ids: data?.mould_shape
            });
            // console.log('Response:', response?.data?.data);
            // setDataMouldShape(response.data?.data)
            setSelectedMouldShape(response.data?.data.map((e:any)=>e?.id))
            setValue('mould_shape',response.data?.data.map((e:any)=>e?.id))
          } catch (error) {
            console.error('Error:', error);
          }
        }
        getCall()
    }
    if(data?.use_product_code.length>0){
        const getCall = async()=>{
        try {
            const response = await Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apinameProductCode}/fetch_multiple_ids/`, {
              ids: data?.use_product_code
            });
            // console.log('Response:', response?.data?.data);
            // setDataProductCode(response.data?.data)
            setSelectedProductCode(response.data?.data?.map((e:any)=>e?.id))
            setValue('use_product_code',response.data?.data?.map((e:any)=>e?.id))
          } catch (error) {
            console.error('Error:', error);
          }
        }
        getCall()
    }
    if(data?.mould_name_and_type){
        const getCall = async()=>{
        try {
            const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameMould}/${data?.mould_name_and_type}`);
            // console.log('Response:', response?.data?.data);
            // setDataProductCode(response.data?.data)
            setSelectedHSN(response.data?.data?.id)
            setValue('mould_name_and_type',response.data?.data?.id)
          } catch (error) {
            console.error('Error:', error);
          }
        }
        getCall()
    }
    },[data])

   

// console.log('dataMouldShape',dataMouldShape)


    const onSubmit = (detail: any) => {
        // 
        const formData = new FormData();

        for (const [key, value] of Object.entries(detail)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else if (Array.isArray(value) && key === "use_product_code") {
                value.forEach((val, index) => formData.append(`use_product_code`, val))
            }

            else if (Array.isArray(value) && key === "mould_shape") {
                value.forEach((val, index) => formData.append(`mould_shape`, val))
            }

            if (SheetRoll && key === 'sheet_roll_weight') {
                let decimalNumber = Number(SheetRoll);
                const formattedString = decimalNumber.toFixed(3);
                formData.append('sheet_roll_weight', formattedString)
                continue
              }
            if (CuttingSide && key === 'cutting_side_patti_kg') {
                let decimalNumber = Number(CuttingSide);
                const formattedString = decimalNumber.toFixed(3);
                formData.append('cutting_side_patti_kg', formattedString)
                continue
              }
            if (WasteSheet && key === 'waste_sheet_kg') {
                let decimalNumber = Number(WasteSheet);
                const formattedString = decimalNumber.toFixed(3);
                formData.append('waste_sheet_kg', formattedString)
                continue
              }
            // else {
            //     formData.append(key, formvalue[0])
            // }
            // if (typeof value === 'object' && value !== null) {
            //     formData.append(key, "")
            // }
            // if (key === 'mfa5') {
            //     let decimalNumber = Number(valueMFA5);
            //     const formattedString = decimalNumber.toFixed(2);
            //     formData.append('mfa5', formattedString)
            // }
        }
   
        let data = {
            apiname: apiname,
            id: id,
            data: formData
        }

        mutate(data);
        setValuesofform(data);
    }

    // const [show, setShow] = useState(false)
    // const [showmessage, setshowmessage] = useState("")

    // useEffect(() => {
    //     if (isSuccess) {
    //         setShow(true)
    //         setshowmessage("success")
    //         navigate('/listSheet', { state: { showToast: true } })

    //     }
    //     else if (isError) {
    //         setShow(true)
    //         setshowmessage("danger")
    //     }
    // }, [data, error])

    // useEffect(() => {
    //     setTimeout(() => {
    //         setShow(false)
    //     }, 3000)

    // }, [show]);

     const [show, setShow] = useState(false)
  const [showmessage, setshowmessage] = useState("")

  useEffect(() => {
    if (isSuccess || yessuccess || nosuccess) {
      setShow(true);
      setshowmessage("success");
      navigate("/listSheet", { state: { showEditToast: true } });
    } else if (isError) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [data, isSuccess, yessuccess, nosuccess, gstUpdated_response, error,
    isSuccess,]);
  useEffect(() => {
    if (isError) {
      if (error.response?.status === 405) {
        setConfirmmodal(true);
      }
    } else if (isError || yeserror || noerror) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [isError, yeserror, noerror]);
  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [show]);

  

  const yesupdatemodal = () => {
    setConfirmmodal(false);
    mutateyes(valuesofform)
  }
  const cancelupdatemodal = () => {
    setConfirmmodal(false);
  }
  const noupdatemodal = () => {
    setOpenEditModel(true)
  }


  const handleExpirationDate = (e: any) => {
    setExpirationDate(e.target.value)
    setValue("expiration_date", e.target.value)
  }

  const handleConfirm = (data: any) => {

    delete data.created_at
    delete data.created_by
    let ok = {
      id: id,
      apiname: apiname,
      data: data,
    }

    mutateno(ok)
    setConfirmmodal(false);
    setOpenEditModel(false)
  }



    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }



    const [submitted, setSubmitted] = useState(false)
    const [submittedColor, setSubmittedColor] = useState(false)
    const [submittedBrand, setSubmittedBrand] = useState(false)


    useEffect(() => {
        if (submitted) {



            // setForValue(optionsPanniName[0])
            setValue("type", optionsPanniName[0].value)
            setValue("density", optionsPanniName[0].density)
            setGST(optionsPanniName[0].gst.india_GST)
            setValue("gst", optionsPanniName[0].gst.id)
            setPolymer(optionsPanniName[0].polymer)
            setValue("polymer_grade", optionsPanniName[0].polymer)

        }



    }, [submitted, optionsPanniName])

    useEffect(() => {


        if (submittedColor) {

            setValue("sheet_color_name", options[0].value)
            setForColorValue(options[0])

        }


    }, [submittedColor, options])



    // const [valueMFA5, setValueMFA5] = useState('')
    // const handleMFA5 = (e: any) => {
    //     setValueMFA5(e.target.value)
    // };


    const [SubmittedMouldName, setSubmittedMouldName] = useState('')

    useEffect(() => {



        if (SubmittedMouldName) {

            setValue("mould_name_and_type", optionsHSN[0].value)
            setForHSNValue(optionsHSN[0])

        }

    }, [SubmittedMouldName, optionsHSN])


    const[SheetRoll,setSheetRoll] = useState('')
    const[CuttingSide,setCuttingSide] = useState('')
    const[WasteSheet,setWasteSheet] = useState('')


    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
      const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
      const [apiName, setApiName]: any = useState()
  
  
      const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
      const [confirmforcemodal, setConfirmforcemodal] = useState(false);
  
      const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
      } = useDelete();
      const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
      } = useDeletemultiple();
      const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
      } = useDeletemultipleforce();
      const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
      } = useDeletemforce();
    
  
  
  
    const handleRefetch = () => {
        if (apiName === apinameForParticularName) {
            ParticularNameRefetch()
        }
        if (apiName === apinameForSheetCore) {
            SheetCoreRefetch()
        }
       
        if (apiName === apinameForProductLocation) {
            ProductLocationRefetch()
        }
       
        if (apiName === apinameForSheetRoll) {
            SheetRollRefetch()
        }
       
     
  
       
         
         
      // else if(apiName === apinameForTaluka){
      //   TalukaRefetch()
      // }
      }
  
  
      useEffect(() => {
        if (OpenEditDropDownModel && valueParticularName && apiName === apinameForParticularName) {
          setValueParticularName(null)
          setValueParticularNameCreatable(null)
        }
        if (OpenEditDropDownModel && valueSheetCore && apiName === apinameForSheetCore) {
            setValueSheetCore(null)
            setValueSheetCoreCreatable(null)
          }
        if (OpenEditDropDownModel && valueProductLocation && apiName === apinameForProductLocation) {
            setValueProductLocation(null)
            setValueProductLocationCreatable(null)
          }
        
        if (OpenEditDropDownModel && valueSheetRoll && apiName === apinameForSheetRoll) {
            setValueSheetRoll(null)
            setValueSheetRollCreatable(null)
          }
        
  
       
       
    
      
    
      
        if(deletesucess && apiName === apinameForParticularName){
            setValueParticularName(null)
            setValueParticularNameCreatable(null)
        }
    
        if(deletesucess && apiName === apinameForSheetCore){
            setValueSheetCore(null)
            setValueSheetCoreCreatable(null)
        }
        if(deletesucess && apiName === apinameForProductLocation){
            setValueProductLocation(null)
            setValueProductLocationCreatable(null)
        }
    
        if(deletesucess && apiName === apinameForSheetRoll){
            setValueSheetRoll(null)
            setValueSheetRollCreatable(null)
        }
    
    
      
  
      }, [OpenEditDropDownModel, apiName, deletesucess])
  
  
      const [isExist, setIsExist] = useState(true)
  
  
      const CustomOption = ({ innerProps, label, value }: any) => (
    
    
        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
          <span>{label}</span>
          <div style={{ float: "right", justifyContent: "end" }}>
    
    
    
            {isExist ?
    
              <Custom_Actions_Buttons value={value}
                apiname={deleteApiName}
                mutate={singleDelete}
                // masterName={addButtonName}
                deleteid={deleteid}
                handleDeletedforce={handleDeletedforce}
                closereconfirmdelete={closereconfirmdelete}
                deletemodalask={deletemodalask}
              setEditOpen={setOpenEditDropDownModel}
              // permissionClass={permissionClass}
              />
              : ""}
    
          </div>
        </div>
    
    
      )
  
  
      const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
      }
  
  
   
      useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
      }, [deletesucess, editData?.results]);
      useEffect(() => {
        return () => {
          setDeleteid(undefined);
        };
      }, []);
      const handleConfirmDelete = () => {
        setDeleteConfirm(true);
      };
      const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
      };
    
      const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
      };
      const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
      };
      const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
      };
      const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
          deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
      };
      useEffect(() => {
        if (forcefullysuccess) {
          setShow(true);
          setshowmessage("success");
        } else if (forcefullyerror) {
          setShow(true);
          setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
          setShow(true);
          setshowmessage("success");
        } else if (forcefullydeleteerror) {
          setShow(true);
          setshowmessage("danger");
        }
      }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
      ]);
    
      useEffect(() => {
        if (deleteMultipleSuccess) {
          const showdepenedancymsg = deleteMultiple?.data?.data;
          const dependacyfound = showdepenedancymsg?.filter((color: any) => {
            if (
              color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
            ) {
              return color;
            }
          });
          if (dependacyfound?.length === deleteMultipleid?.length) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
          } else {
            setConfirmforcemodal(true);
          }
        } else if (deleteMultipleIsError) {
          setShow(true);
          setshowmessage("danger");
        } else if (deletesucess) {
          if (deletedata.data?.data?.length > 0) {
            setConfirmforcemodal(true);
          } else if (deleteid) {
            setShow(true);
            setshowmessage("success");
            deleteforce({ deleteid, apiname: deleteApiName });
          }
        }
      }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);
    
      const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
      };



    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header px-3 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Sheet Code</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Production</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listSheet" className="text-decoration-none text-black">Sheet Code</Link></li>
                                    <li className="breadcrumb-item">Add Sheet Code</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">

                            <form
                                ref={focus} onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}

                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">

                                            <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Sheet Code </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" disabled
                                                            // value={gst} 
                                                            className="form-control" id="exampleInputBrand" placeholder="Sheet Code"
                                                            {...register("code", {  required: { value: false, message: "Sheet Code is required" } })} />
                                                       
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.sheet_roll_weight?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Particular Name <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueParticularName(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("particular_name", { required: { value: true, message: "Particular Name is required" } })}
                                                        placeholder={'Select Particular Name'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsParticularName}
                                                        onCreateOption={handleCreateParticularName}
                                                        onChange={handleChangeParticularName}
                                                        onMenuOpen={()=>ParticularNameRefetch()}
                                                        // value={valueParticularNameCreatable == null ? valueParticularName : valueParticularNameCreatable}
                                                        value={valueParticularNameCreatable == null ? valueParticularName == null ? optionsParticularName?.filter((obj: any) => obj.value === selectedParticularName) : valueParticularName : valueParticularNameCreatable}


                                                    />
                                                    <span className='text-red w-100'>{errors?.particular_name?.message}</span>
                                                </div>




                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Mould Name & Type & Category & HSN & GST%<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            // placeholder={'Select Material Name & Type & HSN & GST%'}
                                                            {...register("mould_name_and_type", { required: { value: false, message: "Mould Name & Type & Category & HSN & GST% is required" } })}
                                                            onChange={handleChangeHSN}
                                                            options={optionsHSN}
                                                            components={customIndicator}
                                                            // value={forHSNValue}
                                                            value={selHSN === "" ? optionsHSN?.find((obj: any) => obj.value === selectedHSN) : selHSN}
                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenMouldName(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.mould_name_and_type?.message}</span>
                                                </div>

                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Type & Density & Sutra <span style={{ color: 'red' }}>*</span></label>

                                                    <div className="input-group" data-colorpicker-id={2}>

                                                        <input type="text"
                                                             disabled placeholder={'Material Type & Density & Sutra'}
                                                            className="form-control" id="exampleInputBrand" />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                        <span className='text-red w-100'>{errors?.type?.message}</span>
                                                    </div>
                                                </div> */}


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name_and_grade">Sheet Color Name<span style={{ color: 'red' }}>*</span></label>

                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("sheet_color_name", { required: { value: true, message: "Color is required" } })}
                                                            placeholder={'Select Color'}
                                                            onChange={handleChange}
                                                            // value={forColorValue}
                                                            components={customIndicator}
                                                            options={options}
                                                            value={selColor === "" ? options?.find((obj: any) => obj.value === selectedColor) : selColor}

                                                            // isOptionDisabled={(options:any)=>
                                                            //     {
                                                            //         if(options.status === "inactive" || options.deleted == true){
                                                            //         return options
                                                            //         }
                                                            //        }}
                                                            className="dropdown-select"

                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setColorOpen(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.sheet_color_name?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Use Mould Shape & Shape Image & Size mm<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            // placeholder={'Select Material Name & Type & HSN & GST%'}
                                                            {...register("mould_shape", { required: { value: false, message: "Use Mould Shape & Shape Image & Size mm is required" } })}
                                                            onChange={handleChangeMouldShape}
                                                            options={optionsMouldShape}
                                                            components={customIndicator}
                                                            // value={forMouldShapeValue}
                                                            value={selMouldShape.length === 0 ? optionsMouldShape?.find((obj: any) => selectedMouldShape?.includes(obj.value)) : selMouldShape}

                                                            isMulti
                                                        // className="dropdown-select"
                                                        />
                                                        {/* <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenMouldName(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div> */}
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.mould_shape?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Use Mould Shape Qty.</label>
                                                    <input type="number"
                                                        disabled placeholder={'Use Mould Shape Qty.'} value={mouldQty}
                                                        {...register("use_mould_shape_qty", { required: { value: false, message: "Use Mould Shape Qty. is required" } })}
                                                        className="form-control" id="exampleInputBrand" />

                                                    {/* <span className='text-red w-100'>{errors?.type?.message}</span> */}
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Use Product Code & Grade & Name & Type <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            // placeholder={'Select Material Name & Type & HSN & GST%'}
                                                            {...register("use_product_code", { required: { value: false, message: "Use Product Code & Grade & Name & Type  is required" } })}
                                                            onChange={handleChangeProductCode}
                                                            options={optionsProductCode}
                                                            components={customIndicator}
                                                            // value={forProductCodeValue}
                                                            value={selProductCode.length === 0 ? optionsProductCode?.find((obj: any) => selectedProductCode.includes(obj.value)) : selProductCode}

                                                            isMulti
                                                        // className="dropdown-select"
                                                        />
                                                        {/* <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenMouldName(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div> */}
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.use_product_code?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Use Product Shape Qty.</label>
                                                    <input type="number"
                                                        disabled placeholder={'Use Product Shape Qty.'} value={ProductQty}
                                                        {...register("use_product_qty", { required: { value: false, message: "Use Product Shape Qty. is required" } })}
                                                        className="form-control" id="exampleInputBrand" />

                                                    {/* <span className='text-red w-100'>{errors?.type?.message}</span> */}
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Thickness Sheet Micron <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number"
                                                            // value={gst}
                                                            className="form-control" id="exampleInputBrand" placeholder="Thickness Sheet Micron"
                                                            {...register("thickness_sheet_micron", { onChange: handleValue, required: { value: true, message: "Thickness Sheet Micron is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.thickness_sheet_micron?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Sheet Roll Weight(Kg.)</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number"
                                                            // value={gst}
                                                            className="form-control" id="exampleInputBrand" placeholder="Sheet Roll Weight"
                                                            {...register("sheet_roll_weight", { onChange: ((e: any) => setSheetRoll(e.target.value)), required: { value: false, message: "Sheet Roll Weight is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.sheet_roll_weight?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Roll Meter </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number"
                                                            // value={gst}
                                                            className="form-control" id="exampleInputBrand" placeholder="Roll Meter"
                                                            {...register("roll_meter", {  required: { value: false, message: "Roll Meter is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.sheet_roll_weight?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Cutting Side Patti Kg. </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number"
                                                            className="form-control" id="exampleInputBrand" placeholder="Cutting Side Patti Kg."
                                                            {...register("cutting_side_patti_kg", { onChange: ((e: any) => setCuttingSide(e.target.value)), required: { value: false, message: "Cutting Side Patti Kg. is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.cutting_side_patti_kg?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Waste Sheet Kg. </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number"
                                                            className="form-control" id="exampleInputBrand" placeholder="Waste Sheet Kg."
                                                            {...register("waste_sheet_kg", { onChange: ((e: any) => setWasteSheet(e.target.value)), required: { value: false, message: "Waste Sheet Kg. is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.waste_sheet_kg?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Sheet Roll Core Id MM <span style={{ color: 'red' }}>*</span></label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueSheetCore(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("sheet_roll_core_id_mm", { required: { value: true, message: "Sheet Roll Core Id MM is required" } })}
                                                        placeholder={'Select Sheet Roll Core Id MM'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsSheetCore}
                                                        onCreateOption={handleCreateSheetCore}
                                                        onChange={handleChangeSheetCore}
                                                        onMenuOpen={()=>SheetCoreRefetch()}
                                                        // value={valueSheetCoreCreatable == null ? valueSheetCore : valueSheetCoreCreatable}
                                                        value={valueSheetCoreCreatable == null ? valueSheetCore == null ? optionsSheetCore?.filter((obj: any) => obj.value === selectedSheetCore) : valueSheetCore : valueSheetCoreCreatable}


                                                    />
                                                    <span className='text-red w-100'>{errors?.sheet_roll_core_id_mm?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Roll Time Limit Maximum </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text"
                                                            className="form-control" id="exampleInputBrand" placeholder="Roll Time Limit Maximum"
                                                            {...register("roll_time_limit_maximum", { onChange: handleValue, required: { value: false, message: "Roll Time Limit Maximum is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.roll_time_limit_maximum?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Sheet Roll Special-Running <span style={{ color: 'red' }}>*</span></label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueSheetRoll(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("sheet_roll_special_running", { required: { value: true, message: "Sheet Roll Special-Running is required" } })}
                                                        placeholder={'Select Sheet Roll Special-Running Id MM'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsSheetRoll}
                                                        onCreateOption={handleCreateSheetRoll}
                                                        onChange={handleChangeSheetRoll}
                                                        onMenuOpen={()=>SheetRollRefetch()}
                                                        // value={valueSheetRollCreatable == null ? valueSheetRoll : valueSheetRollCreatable}
                                                        value={valueSheetRollCreatable == null ? valueSheetRoll == null ? optionsSheetRoll?.filter((obj: any) => obj.value === selectedSheetRoll) : valueSheetRoll : valueSheetRollCreatable}


                                                    />
                                                    <span className='text-red w-100'>{errors?.sheet_roll_special_running?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Roll Production Minimum </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number"
                                                            className="form-control" id="exampleInputBrand" placeholder="Roll Production Minimum"
                                                            {...register("roll_production_minimum", { onChange: handleValue, required: { value: false, message: "Roll Production Minimum is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.roll_production_minimum?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Product Location</label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueProductLocation(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("product_location", { required: { value: false, message: "Product Location is required" } })}
                                                        placeholder={'Select Product Location'}
                                                        components={customCreatableIndicator}
                                                        options={optionsProductLocation}
                                                        onCreateOption={handleCreateProductLocation}
                                                        onChange={handleChangeProductLocation}
                                                        onMenuOpen={()=>ProductLocationRefetch()}
                                                        // value={valueProductLocationCreatable == null ? valueProductLocation : valueProductLocationCreatable}
                                                        value={valueProductLocationCreatable == null ? valueProductLocation == null ? optionsProductLocation?.filter((obj: any) => obj.value === selectedProductLocation) : valueProductLocation : valueProductLocationCreatable}

                                                    />

                                                    <span className='text-red'>{errors?.product_location	?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Remark </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text"
                                                            className="form-control" id="exampleInputBrand" placeholder="Remark"
                                                            {...register("remark", { onChange: handleValue, required: { value: false, message: "Remark is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.remark?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span className='text-red'>*</span></label>
                                                    <select className="form-select"
                                                        {...register("status", {
                                                            required: { value: true, message: "Status is required" }, maxLength: 30,

                                                        })}
                                                    >
                                                        <option value={""} selected >Select Any</option>
                                                        <option value="active" >Active</option>
                                                        <option value="inactive" >Inactive</option>
                                                    </select>



                                                    <span className='text-red'>{errors?.status?.message}</span>




                                                </div>





                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        onClick={handleSubmit(onSubmit)} disabled={isLoading}>Submit</button>
                                                    {/* <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button> */}

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listSheet")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
            <Material_Density_Model refetchForm={materialdensityRefetch} open={open} setOpen={setOpen} setSubmitted={setSubmitted} />
            <Color_Model refetchForm={colorRefetch} open={colorOpen} setOpen={setColorOpen} setSubmitted={setSubmittedColor} />
            <Brand_Model refetchForm={brandRefetch} open={brandOpen} setOpen={setBrandOpen} setSubmitted={setSubmittedBrand} />
            <Mould_Model refetchForm={HSNRefetch} open={openMouldName} setOpen={setOpenMouldName} setSubmitted={setSubmittedMouldName} />

            <Modal
        show={deleteConfirm}
        onHide={() => setDeleteConfirm(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete {""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            {" "}
            <span className="text-center">Sure you want to delete it?</span>
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setDeleteConfirm(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="info"
            className="text-white"
            onClick={handleMultipleDeleted}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Deleteforce
        key={renderKey}
        handleDeletedforce={handleDeletedforce}
        reconfirmdelete={confirmforcemodal}
        masterName={""}
        closereconfirmdelete={closereconfirmdelete}
        referance={
          deletesucess
            ? deletedata
            : ""
        }
        apiname={apiName}
      />
      <Deletesingle
        key={renderKey}
        confirmmodal={deleteConfirmsingle}
        confirmmodalclose={confirmmodalclose}
        masterName={""}
        confirmmodalsubmit={confirmmodalsubmit}
        apiname={apiName}
      />

      <SimpleInput_Edit key={renderKey} 
      refetchForm={handleRefetch} 
      open={OpenEditDropDownModel} 
      setOpen={setOpenEditDropDownModel} 
      id={editId} 
      // superAccountId={superAccountId} 
      // optionsSuperAccount={optionsSuperAccount}
      // isSuperAccount={isSuperAccount}
      apiname={apiName} />


<Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
        centered    >
        <Modal.Header closeButton>
          <Modal.Title>Select a Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12">
            <div className="row">
              <div className="col-sm-12 mt-2">
                <label htmlFor="exampleInputEmail1">Choose Date</label>
                <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>
          <button
            type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
            onClick={handleSubmit(handleConfirm)}
          >Confirm</button>
        </Modal.Footer>
      </Modal>
      <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
        yesupdatemodal={yesupdatemodal}
        cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />


        </div>
    )
}

export default Edit_Sheet_Code