import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useBrandEdit, useUpdateBrand } from "../../hooks/brand";
import { Controller, useForm } from "react-hook-form";
import { Alert } from "../../components/Model/Model";
import { RiPencilLine } from "react-icons/ri";
import { useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes, useCreate } from "../../hooks/Api";
import useFocusOnEnter from "../../components/focus";
import { Deletesinglewithdependancy } from "../../components/modal";
import 'bootstrap/dist/css/bootstrap.min.css';
//    import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import Select from 'react-select'
import { Account_Model } from "../../components/Dynamic_Form_Models/Account_Model";

import { DatePicker, Space, TimePicker } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import {
    useFetchAccountDropDown, useFetchCavityNameDropDown,
    useFetchDesignationDropDown, useFetchEmployeeTypeDropDown,
    useFetchMouldDropDown, useFetchSalaryTypeDropDown, useFetchCityDropDown,
    useFetchTitleDropDown, useFetchCasteDropDown, useFetchBloodGroupDropDown,
    useFetchWeeklyOffDropDown, useFetchAdministrationTypeDropDown, useFetchEmployeeDropDown, useFetchDepartmentDropDown
} from "../../hooks/DropDown_Api";
import { type } from "os";
import "react-datepicker/dist/react-datepicker.css";
import DatePickerReact from "react-datepicker";
import { FaCalendarAlt } from "react-icons/fa";
import CreatableSelect from 'react-select/creatable';
import Button from "react-bootstrap/esm/Button";
import { AiOutlinePlus } from "react-icons/ai";
import { Upload } from "antd";
import type { UploadFile } from 'antd/es/upload/interface';
import { Department_Model } from "../../components/Dynamic_Form_Models/Department_Model";
import { City_Model } from "../../components/Dynamic_Form_Models/City_Model";
import { Designation_Model } from "../../components/Dynamic_Form_Models/Designation";

import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'

import Modal from "react-bootstrap/Modal";
import Actions, { Deleteforce, Deletesingle } from "../../components/Cell/Actions";
import { useDelete, useDeletemforce } from "../../hooks/delete";
import Custom_Actions_Buttons from "../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../hooks/deletemultiple";
import { SimpleInput_Edit } from "../../components/Dynamic_Form_Models/SimpleInput_Edit";
import Axiosinstance from "../../hooks/Interceptor";
import Mui_Toaster from "../../components/Mui_Toaster/Mui_Toaster";

interface Option {
    readonly label: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    name: label,
    status: "active",
});

const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}


const Edit_Employee = ({setCurrentStep,currentStep,setDataForBasicDetails}:any) => {
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    // const { id } = useParams()

    let rawData = localStorage.getItem('Add Data');
    let addData = rawData ? JSON.parse(rawData) : null;

    const id = addData?.id
    const tempData = addData
    const data = tempData


    



    const navigate = useNavigate()

    const [logo, setlogo] = useState(null)
    const [logoPdf, setlogoPdf] = useState(null)
    const [agreement, setAgreement] = useState(null)
    const [openEditModel, setOpenEditModel] = useState(false)
    const [expirationDate, setExpirationDate] = useState()
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [Married, setMarried] = useState("Single")
    const [valuesofform, setValuesofform] = useState<any>()

    const [openCity, setOpenCity]: any = useState(false)
    const [open, setOpen] = useState(false)
    const [openDesignation, setOpenDesignation] = useState(false)

    const [submitted, setSubmitted] = useState(false)
    const [submittedDesignation, setSubmittedDesignation] = useState(false)

    const [openAccount, setOpenAccount]: any = useState(false)

    const apiname = process.env.REACT_APP_EMPLOYEE_API?.toString()

    // const { data } = useFetchSingle(id, apiname)

    const [OpenSalaryType,setOpenSalaryType]:any = useState(false)




    const [EmployeeId, setEmployeeId] = useState("no")
    const onMarriedChange = (e: any) => {
        setMarried(e.target.value)
    }
    const onEmployeeChange = (e: any) => {
        setEmployeeId(e.target.value)
        setValue("employee_status", e.target.value)
    }
    const onLogoChange = (e: any) => {
        setlogo(e.target.files[0].name)
    }

    const onLogoPDFChange = (e: any) => {
        setlogoPdf(e.target.files[0].name)
    }

    const onAgreementChange = (e: any) => {
        setAgreement(e.target.files[0].name)
    }

    const { mutate, isSuccess, isError, error, data: brandUpdated_response }: any = useUpdateSingle()
    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()

    const { register, control, handleSubmit, setError, formState: { errors, isDirty }, reset, setValue, getValues }: any = useForm();


    const prevDataRef = useRef();

    useEffect(() => {
        const isDataChanged = JSON.stringify(data) !== JSON.stringify(prevDataRef.current);
        if (data && isDataChanged) {
            reset(data);
            prevDataRef.current = data;
        }
    }, [data, reset]);

    // useEffect(() => {
    //     const isDataChanged = JSON.stringify(data) !== JSON.stringify(prevDataRef.current);
    //     if (data && isDataChanged) {
    //         reset(data);
    //         prevDataRef.current = data;
    //     }
    // }, [data,reset]);



    const [optionsAccount, setOptionsAccount]: any = useState([])
    const [optionsDepartment, setOptionsDepartment]:any = useState([])
    const [selDepartment, setSelDepartment] = useState('')
    const [selectedDepartment, setSelectedDepartment]: any = useState('')
    const [selectedAccount, setSelectedAccount]: any = useState('')
    const [selAccount, setSelAccount]: any = useState('')

    const [selDesignation, setSelDesignation] = useState('')
    const [selectedDesignation, setSelectedDesignation]: any = useState('')
    const [age, setAge]: any = useState(0)


    const { data: departmentData, refetch: departmentRefetch } = useFetchDepartmentDropDown()

    useEffect(() => {
        setOptionsDepartment(departmentData)
    }, [departmentData])




    const [optionsDesignation, setOptionsDesignation]: any = useState([])

    const { data: designationData, refetch: designationRefetch } = useFetchDesignationDropDown()

    useEffect(() => {
        setOptionsDesignation(designationData)
    }, [designationData])




    const handleChangeDepartment = (e: any) => {


        // const department = e.map((value: any) => {
        //     return value.value
        // })
        setValue("department", e?.value)
        setSelDepartment(e)
        setSelectedDepartment([])
    }

    const handleChangeDesignation = (e: any) => {
        setValue("designation", e?.value)
        setSelDesignation(e)
        setSelectedDesignation([])
    }

    const [optionsEmployee, setOptionsEmployee] = useState([])
    const [EmployeeValue, setEmployeeValue]: any = useState()
    const [selEmployee, setSelEmployee]: any = useState('')
    const [selectedEmployee, setSelectedEmployee]: any = useState('')
    const [showOtherRef,setShowOtherRef]:any =useState(false)


    const { data: EmployeeData, refetch: EmployeeRefetch } = useFetchEmployeeDropDown()

    useEffect(() => {
        if (EmployeeData){
            let temp_employee_data:any = EmployeeData?.map((e: any) => e)
            temp_employee_data?.unshift({label:'Other',value:'other'})
            setOptionsEmployee(temp_employee_data)
        }
    }, [EmployeeData])


    const handleChangeEmployee = (e: any) => {
        if(e.value==='other'){
            setSelEmployee(e)
            setEmployeeValue(e)
            setShowOtherRef(true)
            setValue("reference",'')
        }
        else{
            setEmployeeValue(e)
            setSelEmployee(e)
            setShowOtherRef(false)
            setValue('other_reference','')
            setValue("reference", `${e?.value}`)
        }
    }

    const { data: accountData, refetch: accountRefetch } = useFetchAccountDropDown()

    useEffect(() => {
        setOptionsAccount(accountData)
    }, [accountData])



    const handleChangeAccount = (e: any) => {
        setValue("group", e?.value)
        setSelAccount(e)
        // setForDepartmentValue({ label: e?.label, value: e?.value })
    }



    const [forCityValue, setForCityValue]: any = useState([])
    const [cityOptions, setCityOptions]: any = useState([])
    const [selCity, setSelCity]: any = useState('')
    const [selectedCity, setSelectedCity]: any = useState('')

    const { data: cityData, refetch: cityRefetch } = useFetchCityDropDown()

    // useEffect(() => {
    //     setCityOptions(cityData)
    // }, [cityData])

    useEffect(() => {
        let temp = cityData?.map((e: any) => {
            return (
                {
                    value: e?.value,
                    label: `${e?.city?e?.city:e?.village}::${e?.district}`,

                }
            )
        })
        setCityOptions(temp)
    }, [cityData])
    const handleChangeCity = (e: any) => {
        setForCityValue(e)
        setSelCity(e)
        setValue("city_name_and_distict_name", e?.value)

        // setValue("state",e?.stateID)
        // setValue("country",e?.countryID)
        // setValue("district",e?.districtID)


    }


    const apinameForSalaryType = process.env.REACT_APP_EMPLOYEE_SALARY_TYPE_API?.toString()
    const { mutate: mutateSalaryType, data: dataSalaryType, isError: SalaryTypeIsError, isLoading: SalaryTypeLoading, isSuccess: SalaryTypeSuccess, error: SalaryTypeError }: any = useCreate(apinameForSalaryType)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueSalaryType, setValueSalaryType]: any = useState();
    const [selectedSalaryType, setSelectedSalaryType]: any = useState('');
    const [optionsSalaryType, setOptionsSalaryType]: any = useState([]);
    const [valueSalaryTypeCreatable, setValueSalaryTypeCreatable] = useState<Option | null>();

    const { data: SalaryTypeData, refetch: SalaryTypeRefetch } = useFetchSalaryTypeDropDown()

    useEffect(() => {
        setOptionsSalaryType(SalaryTypeData)
    }, [SalaryTypeData])





    const handleChangeSalaryType = (e: any) => {

        SalaryTypeRefetch()

        setApiName(apinameForSalaryType)
        setDeleteApiName(apinameForSalaryType)
        setEditId(e?.value)
        setEditData(SalaryTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueSalaryType(e)

        setValue("salary_type", `${e?.value}`)
        setValueSalaryTypeCreatable(null)

        setSalaryChanged(salaryChanged +1)
    }





    const handleCreateSalaryType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutateSalaryType({ name: newOption.name, status: newOption.status })
            setOptionsSalaryType((prev: any) => [...prev, newOption]);
            setValueSalaryTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (SalaryTypeSuccess && optionsSalaryType) {
                await SalaryTypeRefetch();
                setSalaryChanged(salaryChanged +1)
                setValue("salary_type", SalaryTypeData[0]?.value)
            }

        })();

        return () => {
        };
    }, [SalaryTypeSuccess, SalaryTypeData]);

 

    const apinameForTitle = process.env.REACT_APP_EMPLOYEE_TITLE_API?.toString()
    const { mutate: mutateTitle, data: dataTitle, isError: TitleIsError, isLoading: TitleLoading, isSuccess: TitleSuccess, error: TitleError }: any = useCreate(apinameForTitle)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueTitle, setValueTitle]: any = useState();
    const [selectedTitle, setSelectedTitle]: any = useState('');
    const [optionsTitle, setOptionsTitle]: any = useState([]);
    const [valueTitleCreatable, setValueTitleCreatable] = useState<Option | null>();

    const { data: TitleData, refetch: TitleRefetch } = useFetchTitleDropDown()

    useEffect(() => {
        setOptionsTitle(TitleData)
    }, [TitleData])





    const handleChangeTitle = (e: any) => {

        TitleRefetch()

        setApiName(apinameForTitle)
        setDeleteApiName(apinameForTitle)
        setEditId(e?.value)
        setEditData(TitleData)
        setRenderKey(prevKey => prevKey + 1);

        setValueTitle(e)

        setValue("title", `${e?.value}`)
        setValueTitleCreatable(null)
    }





    const handleCreateTitle = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutateTitle({ name: newOption.name, status: newOption.status })
            setOptionsTitle((prev: any) => [...prev, newOption]);
            setValueTitleCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (TitleSuccess && optionsTitle) {
                await TitleRefetch();
                setValue("title", TitleData[0]?.value)
            }

        })();

        return () => {
        };
    }, [TitleSuccess, TitleData]);


    const apinameForWeeklyOff = process.env.REACT_APP_EMPLOYEE_WEEKLY_OFF_API?.toString()
    const { mutate: mutateWeeklyOff, data: dataWeeklyOff, isError: WeeklyOffIsError, isLoading: WeeklyOffLoading, isSuccess: WeeklyOffSuccess, error: WeeklyOffError }: any = useCreate(apinameForWeeklyOff)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueWeeklyOff, setValueWeeklyOff]: any = useState();
    const [selectedWeeklyOff, setSelectedWeeklyOff]: any = useState();
    const [optionsWeeklyOff, setOptionsWeeklyOff]: any = useState([]);
    const [valueWeeklyOffCreatable, setValueWeeklyOffCreatable] = useState<Option | null>();

    const { data: WeeklyOffData, refetch: WeeklyOffRefetch } = useFetchWeeklyOffDropDown()

    useEffect(() => {
        setOptionsWeeklyOff(WeeklyOffData)
    }, [WeeklyOffData])





    const handleChangeWeeklyOff = (e: any) => {

        WeeklyOffRefetch()

        setApiName(apinameForWeeklyOff)
        setDeleteApiName(apinameForWeeklyOff)
        setEditId(e?.value)
        setEditData(WeeklyOffData)
        setRenderKey(prevKey => prevKey + 1);

        setValueWeeklyOff(e)

        setValue("weekly_off", `${e?.value}`)
        setValueWeeklyOffCreatable(null)
    }





    const handleCreateWeeklyOff = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutateWeeklyOff({ name: newOption.name, status: newOption.status })
            setOptionsWeeklyOff((prev: any) => [...prev, newOption]);
            setValueWeeklyOffCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (WeeklyOffSuccess && optionsWeeklyOff) {
                await WeeklyOffRefetch();
                setValue("weekly_off", WeeklyOffData[0]?.value)
            }

        })();

        return () => {
        };
    }, [WeeklyOffSuccess, WeeklyOffData]);

    const apinameForAdministrationType = process.env.REACT_APP_EMPLOYEE_ADMINISTRATION_API?.toString()
    const { mutate: mutateAdministrationType, data: dataAdministrationType, isError: AdministrationTypeIsError, isLoading: AdministrationTypeLoading, isSuccess: AdministrationTypeSuccess, error: AdministrationTypeError }: any = useCreate(apinameForAdministrationType)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueAdministrationType, setValueAdministrationType]: any = useState();
    const [selectedAdministrationType, setSelectedAdministrationType]: any = useState('');
    const [optionsAdministrationType, setOptionsAdministrationType]: any = useState([]);
    const [valueAdministrationTypeCreatable, setValueAdministrationTypeCreatable] = useState<Option | null>();

    const { data: AdministrationTypeData, refetch: AdministrationTypeRefetch } = useFetchAdministrationTypeDropDown()

    useEffect(() => {
        setOptionsAdministrationType(AdministrationTypeData)
    }, [AdministrationTypeData])





    const handleChangeAdministrationType = (e: any) => {

        AdministrationTypeRefetch()

        setApiName(apinameForAdministrationType)
        setDeleteApiName(apinameForAdministrationType)
        setEditId(e?.value)
        setEditData(AdministrationTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueAdministrationType(e)

        setValue("administration_type", `${e?.value}`)
        setValueAdministrationTypeCreatable(null)
    }





    const handleCreateAdministrationType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutateAdministrationType({ name: newOption.name, status: newOption.status })
            setOptionsAdministrationType((prev: any) => [...prev, newOption]);
            setValueAdministrationTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (AdministrationTypeSuccess && optionsAdministrationType) {
                await AdministrationTypeRefetch();
                setValue("administration_type", AdministrationTypeData[0]?.value)
            }

        })();

        return () => {
        };
    }, [AdministrationTypeSuccess, AdministrationTypeData]);




    const [JoiningDate, setJoiningDate]: any = useState("")
    var Joining_Date = data?.machine_manufacturer_date;
    var final_Joining_Date = moment(Joining_Date, "DD-MM-YYYY").add(1, 'd').toISOString();
    let dateOne = data?.joining_date
    let fromJoiningValue = dateOne

    // const[birthDate,setBirthDate]:any = useState("")
    var Birth_Date = data?.machine_manufacturer_date;
    var final_Birth_Date = moment(Birth_Date, "DD-MM-YYYY").add(1, 'd').toISOString();
    let dateTwo = data?.joining_date
    let fromBirthValue = dateTwo


    var Anniversary_Date = data?.machine_manufacturer_date;
    var final_Anniversary_Date = moment(Anniversary_Date, "DD-MM-YYYY").add(1, 'd').toISOString();
    let dateThree = data?.anniversary_date
    let fromAnniversaryValue = dateThree


    useEffect(() => {
        if (data) {

           

            reset(data)
            setMarried(data?.marital_status)

            if (data?.age) {
                setAge(data?.age)
                setValue("age", data?.age)
            }
            // setSelectedDepartment(data?.department?.id)
            // setValue("department", data?.department?.id)

            setSelectedDesignation(data?.designation?.id)
            setValue("designation", data?.designation?.id)
            // setSelectedAccount(data?.group?.id)
            // setValue("group", data?.group?.id)
            // setSelectedCity(data?.city_name_and_distict_name?.id)
            // setValue("city_name_and_distict_name", data?.city_name_and_distict_name?.id)
            setSelectedWeeklyOff(data?.weekly_off?.id)
            setValue("weekly_off", data?.weekly_off?.id)
            setSelectedAdministrationType(data?.administration_type?.id)
            setValue("administration_type", data?.administration_type?.id)
            setSelectedTitle(data?.title?.id)
            setValue("title", data?.title?.id)
            setSelectedSalaryType(data?.salary_type?.id)
            setValue("salary_type", data?.salary_type?.id)
            setselectedGrade(data?.customer_grade)
            setValue('customer_grade',data?.customer_grade)
            // if(data?.reference===null||data?.reference===""){

            //     setSelectedEmployee('other')
            //     setValue("reference","")
            //     setShowOtherRef(true)
            // }
            // else{
            //     setSelectedEmployee(data?.reference?.id)
            //     setValue("reference",data?.reference?.id)
            // }
            //   setValue("joining_date",new Date( moment(data.joining_date, "DD-MM-YYYY").toISOString()))
   
            //   setValue("point_open_time", dayjs("13:55", "HH:mm"))

           

            let tempImage = data?.image_all?.map((img: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${img?.id}`,
                    name: `${img.title}`,
                    status: 'done',
                    url: img?.doc,
                    thumbUrl: img?.doc,
                }

                return ctx
            })

            let tempDocumentPDF = data?.document_pdf?.map((pdf: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${pdf?.id}`,
                    name: `${pdf.title}`,
                    status: 'done',
                    url: pdf?.doc,
                    thumbUrl: pdf?.doc,
                }

                return ctx
            })
            let tempAgreementPDF = data?.agreement_pdf?.map((pdf: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${pdf?.id}`,
                    name: `${pdf.title}`,
                    status: 'done',
                    url: pdf?.doc,
                    thumbUrl: pdf?.doc,
                }

                return ctx
            })

            setUploadImage(tempImage)
            setUploadDocumentPDF(tempDocumentPDF)
            setUploadAgreementPDF(tempAgreementPDF)


        }
    }, []);



    const [salaryChanged,setSalaryChanged] = useState(0)
console.log('valueSalaryType.id != data?.salary_type.id',valueSalaryType?.value,data.salary_type.id )
    const onSubmit = (branddetail: any) => {
        setShow(false)

        if(valueSalaryType?.value != data.salary_type.id){
            setSalaryChanged(1)
        }

        if (Image.length == 0) {
            delete branddetail.image_all
        }

        if (DocumentPDF.length == 0) {
            delete branddetail.document_pdf
        }
        if (AgreementPDF.length == 0) {
            delete branddetail.agreement_pdf
        }

        if(EmployeeValue === undefined){
            delete branddetail.reference
            delete branddetail.other_reference
        }
       

        delete branddetail.employee_code
        delete branddetail.joining_date
        delete branddetail.birth_date
        delete branddetail.anniversary_date




        const formData = new FormData();





        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value

            if (key === "image_all") {
                logoId.map((old: any) => {
                  formData.append('images_all', old?.id)
                })
                for (let i = 0; i < Image.length; i++) {
        
                  formData.append('image_all', Image[i])
                }
                continue
              }
              if (key === "document_pdf") {
                DocumentId.map((old: any) => {
                  formData.append('document_pdfs', old?.id)
                })
                for (let i = 0; i < DocumentPDF.length; i++) {
        
                  formData.append('document_pdf', DocumentPDF[i])
                }
                continue
              }
        
              if (key === "agreement_pdf") {
                AgreementId.map((old: any) => {
                  formData.append('agreement_pdfs', old?.id)
                })
                for (let i = 0; i < AgreementPDF.length; i++) {
        
                  formData.append('agreement_pdf', AgreementPDF[i])
                }
                continue
              }

           


            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            //     else if (Array.isArray(value) && key === "department_name") {
            //       value.forEach((val, index) => formData.append(`department_name`, val))
            //   }



        }
        let personType = localStorage.getItem('PersonType');
        let personTypeValue = personType ? JSON.parse(personType) : null;
        formData.append('person_type', personTypeValue?.id||personTypeValue?.value)

        let dataTemp = {
            id: id,
            apiname: apiname,
            data: formData
        }
        if(salaryChanged==0){
            mutate(dataTemp);
            setValuesofform(dataTemp)

        }
        else{
            setOpenSalaryType(true)
        }
        
    }




    const onSubmitFromModel = (branddetail: any) => {
        setShow(false)

        if (Image.length == 0) {
            delete branddetail.image_all
        }

        if (DocumentPDF.length == 0) {
            delete branddetail.document_pdf
        }
        if (AgreementPDF.length == 0) {
            delete branddetail.agreement_pdf
        }

        if(EmployeeValue === undefined){
            delete branddetail.reference
            delete branddetail.other_reference
        }
       

        delete branddetail.employee_code
        delete branddetail.joining_date
        delete branddetail.birth_date
        delete branddetail.anniversary_date




        const formData = new FormData();





        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value

            if (key === "image_all") {
                logoId.map((old: any) => {
                  formData.append('images_all', old?.id)
                })
                for (let i = 0; i < Image.length; i++) {
        
                  formData.append('image_all', Image[i])
                }
                continue
              }
              if (key === "document_pdf") {
                DocumentId.map((old: any) => {
                  formData.append('document_pdfs', old?.id)
                })
                for (let i = 0; i < DocumentPDF.length; i++) {
        
                  formData.append('document_pdf', DocumentPDF[i])
                }
                continue
              }
        
              if (key === "agreement_pdf") {
                AgreementId.map((old: any) => {
                  formData.append('agreement_pdfs', old?.id)
                })
                for (let i = 0; i < AgreementPDF.length; i++) {
        
                  formData.append('agreement_pdf', AgreementPDF[i])
                }
                continue
              }

           


            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            //     else if (Array.isArray(value) && key === "department_name") {
            //       value.forEach((val, index) => formData.append(`department_name`, val))
            //   }



        }
        let personType = localStorage.getItem('PersonType');
        let personTypeValue = personType ? JSON.parse(personType) : null;
        formData.append('person_type', personTypeValue?.id||personTypeValue?.value)

        let dataTemp = {
            id: id,
            apiname: apiname,
            data: formData
        }
     
            mutate(dataTemp);
            setValuesofform(dataTemp)
            setOpenSalaryType(false)
            setSalaryChanged(0)
            
     
        
    }






    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")




    useEffect(()=>{
        if(isSuccess&& brandUpdated_response){
            localStorage.setItem('Add Data',JSON.stringify(brandUpdated_response?.data))
            // setCurrentStep(currentStep+1)
        }
    },[isSuccess,brandUpdated_response])








    useEffect(() => {
        if (isSuccess || yessuccess || nosuccess) {
            setValuesofform(null)
            // setSalaryChanged(0)
            setShow(true)
            // setOpenSalaryType(false)
            setshowmessage("success");
          if(salaryChanged == 0){
            setShow(true)

          }
            // navigate('/listEmployee', { state: { showEditToast: true } })
        }

    }, [data, isSuccess, yessuccess,salaryChanged,
        nosuccess, brandUpdated_response])


      



    useEffect(() => {
        if (isError) {
            if (error.response.status === 405) {
                setConfirmmodal(true)
            }
        }
        else if (isError || yeserror || noerror) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isError, yeserror, noerror])
    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, []);

    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }

    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }

    const noupdatemodal = () => {
        setOpenEditModel(true)
    }




    const handleConfirm = (data: any) => {


        let ok = {
            id: id,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditModel(false)
    }



    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {

        return current && current < dayjs().startOf('day');
    };


    const [birthDate, setBirthDate]: any = useState()

    const handleBirthDate = (e: any) => {


        if (e == null) {
            setBirthDate("")
            setValue("birth_date", ``)
            return
        }
        setBirthDate(dayjs(e, 'DD/MM/YYYY'))

        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let birth_date = `${date}-${month}-${year}`
        setValue("birth_date", birth_date)

        //   setBirthDate(e)
    }




    useEffect(() => {

        if (birthDate != null || birthDate) {

            // if(birthDate == null){

            // }

            let todayDate = new Date().toISOString().split("T")[0]


            if (birthDate?.$D === 31) {
                var bDate = moment([birthDate?.$y, birthDate?.$M, birthDate?.$D]);
            } else {
                var bDate = moment([birthDate?.$y, birthDate?.$M + 1, birthDate?.$D]);
            }

            //   var bDate = moment([birthDate?.$y, birthDate?.$M+1, birthDate?.$D]);    

            var currentDate = moment([todayDate.split("-")[0], todayDate.split("-")[1], todayDate.split("-")[2]]);

            setAge(currentDate.diff(bDate, 'years'))
            setValue("age", age)





        }



    }, [birthDate, age])


    const handleJoiningDate = (e: any) => {






        if (e == null) {
            setJoiningDate("")
            setValue("joining_date", ``)
            return
        }
        setJoiningDate(dayjs(e, 'DD/MM/YYYY'))
        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let joining_date = `${date}-${month}-${year}`
        setValue("joining_date", joining_date)



    }

    const [anniversary, setAnniversary]: any = useState("")


    const handleAnniversaryDate = (e: any) => {







        if (e == null) {
            setAnniversary("")
            setValue("anniversary_date", ``)
            return
        }
        setAnniversary(dayjs(e, 'DD/MM/YYYY'))
        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let anniversary_date = `${date}-${month}-${year}`
        setValue("anniversary_date", anniversary_date)

    }



    const handleUpper = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }

    const [logoId, setLogoId] = useState([])
    const [DocumentId, setDocumentId] = useState([])
    const [AgreementId, setAgreementId] = useState([])

    const [Image, setImage] = useState([])
    const [uploadImage, setUploadImage] = useState<UploadFile[]>([])

    const [DocumentPDF, setDocumentPDF] = useState([])
    const [uploadDocumentPDF, setUploadDocumentPDF] = useState<UploadFile[]>([])

    const [AgreementPDF, setAgreementPDF] = useState([])
    const [uploadAgreementPDF, setUploadAgreementPDF] = useState<UploadFile[]>([])

    const imageChange = (e: any) => {
        e.file.status = "done"

        // const tempFiles = e.fileList.map((file: any) => {
        //     return file.originFileObj
        // })
        // setlogo(tempFiles)
        // setUploadFile(e.fileList)





        e.file.status = "done"

        let tempOldFiles: any = []


        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }
                tempOldFiles.push(ctx)

            }
        })

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        }).filter((e: any) => e != undefined)

        setLogoId(tempOldFiles)
        setImage(tempFiles)
        setUploadImage(e.fileList)


    }



    const DocumentChange = (e: any) => {

        e.file.status = "done"

        let tempOldFiles: any = []


        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }
                tempOldFiles.push(ctx)

            }
        })

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        }).filter((e: any) => e != undefined)

        setDocumentId(tempOldFiles)

        setDocumentPDF(tempFiles)
        setUploadDocumentPDF(e.fileList)

    }
    const AgreementChange = (e: any) => {
        e.file.status = "done"

        let tempOldFiles: any = []


        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }
                tempOldFiles.push(ctx)

            }
        })

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        }).filter((e: any) => e != undefined)

        setAgreementId(tempOldFiles)
        setAgreementPDF(tempFiles)
        setUploadAgreementPDF(e.fileList)

    }


    const [SubmittedCity, setSubmittedCity]: any = useState(false)
    useEffect(() => {
        if (SubmittedCity) {

            setSelCity(cityOptions[0])


            setValue("city_name_and_distict_name", cityOptions[0]?.value)

        }

    }, [SubmittedCity, cityOptions])

    useEffect(() => {
        if (submitted) {
            setSelDepartment(optionsDepartment[0])

            setValue("department", optionsDepartment[0]?.value)

        }

    }, [submitted, optionsDepartment])


    useEffect(() => {
        if (submittedDesignation) {
            setSelDesignation(optionsDesignation[0])

            setValue("designation", optionsDesignation[0]?.value)

        }

    }, [submittedDesignation, optionsDesignation])

    const [SubmittedAccount, setSubmittedAccount]: any = useState(false)
    useEffect(() => {
        if (SubmittedAccount) {

            setSelAccount(optionsAccount[0])


            setValue("group", optionsAccount[0]?.value)

        }

    }, [SubmittedAccount, optionsAccount])



    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
    } = useDelete();
    const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
    } = useDeletemforce();




    const handleRefetch = () => {
        if (apiName === apinameForAdministrationType) {
            AdministrationTypeRefetch()
        }
        if (apiName === apinameForWeeklyOff) {
            WeeklyOffRefetch()
        }
        if (apiName === apinameForTitle) {
            TitleRefetch()
        }
        if (apiName === apinameForSalaryType) {
            SalaryTypeRefetch()
        }



        // else if(apiName === apinameForTaluka){
        //   TalukaRefetch()
        // }
    }


    useEffect(() => {
        if (OpenEditDropDownModel && valueAdministrationType && apiName === apinameForAdministrationType) {
            setValueAdministrationType(null)
            setValueAdministrationTypeCreatable(null)
        }
        if (OpenEditDropDownModel && valueWeeklyOff && apiName === apinameForWeeklyOff) {
            setValueWeeklyOff(null)
            setValueWeeklyOffCreatable(null)
        }
        if (OpenEditDropDownModel && valueTitle && apiName === apinameForTitle) {
            setValueTitle(null)
            setValueTitleCreatable(null)
        }
        if (OpenEditDropDownModel && valueSalaryType && apiName === apinameForSalaryType) {
            setValueSalaryType(null)
            setValueSalaryTypeCreatable(null)
        }






        if (deletesucess && apiName === apinameForAdministrationType) {
            setValueAdministrationType(null)
            setValueAdministrationTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameForWeeklyOff) {
            setValueWeeklyOff(null)
            setValueWeeklyOffCreatable(null)
        }
        if (deletesucess && apiName === apinameForTitle) {
            setValueTitle(null)
            setValueTitleCreatable(null)
        }
        if (deletesucess && apiName === apinameForSalaryType) {
            setValueSalaryType(null)
            setValueSalaryTypeCreatable(null)
        }

    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (


        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
            <span>{label}</span>
            <div style={{ float: "right", justifyContent: "end" }}>



                {isExist ?

                    <Custom_Actions_Buttons value={value}
                        apiname={deleteApiName}
                        mutate={singleDelete}
                        // masterName={addButtonName}
                        deleteid={deleteid}
                        handleDeletedforce={handleDeletedforce}
                        closereconfirmdelete={closereconfirmdelete}
                        deletemodalask={deletemodalask}
                        setEditOpen={setOpenEditDropDownModel}
                    // permissionClass={permissionClass}
                    />
                    : ""}

            </div>
        </div>


    )


    const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
    }



    useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
        return () => {
            setDeleteid(undefined);
        };
    }, []);
    const handleConfirmDelete = () => {
        setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
    };

    const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
    };
    useEffect(() => {
        if (forcefullysuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullyerror) {
            setShow(true);
            setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullydeleteerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
    ]);

    useEffect(() => {
        if (deleteMultipleSuccess) {
            const showdepenedancymsg = deleteMultiple?.data?.data;
            const dependacyfound = showdepenedancymsg?.filter((color: any) => {
                if (
                    color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
                ) {
                    return color;
                }
            });
            if (dependacyfound?.length === deleteMultipleid?.length) {
                deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
            } else {
                setConfirmforcemodal(true);
            }
        } else if (deleteMultipleIsError) {
            setShow(true);
            setshowmessage("danger");
        } else if (deletesucess) {
            if (deletedata.data?.data?.length > 0) {
                setConfirmforcemodal(true);
            } else if (deleteid) {
                setShow(true);
                setshowmessage("success");
                deleteforce({ deleteid, apiname: deleteApiName });
            }
        }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

    const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
    };


    const apinameAccount = process.env.REACT_APP_ACCOUNT_TYPE_API?.toString()
    const apinameCity = process.env.REACT_APP_CITY_API?.toString()
    const apinameDepartment = process.env.REACT_APP_HR_DEPARTMENT_API?.toString()
    const apinameDesignation  = process.env.REACT_APP_SALES_DESIGNATION_API?.toString()
    const apinameReference  = process.env.REACT_APP_PERSON_EMPLOYEE_API?.toString()
        useEffect(()=>{
           
        if(data?.group){
            const getCall = async()=>{
            try {
                const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameAccount}/${data?.group}`);

                setSelectedAccount(response?.data?.data?.id)
                setValue("group", response?.data?.data?.id)
              } catch (error) {
                console.error('Error:', error);
              }
            }
            getCall()
        }
        if(data?.city_name_and_distict_name){
            const getCall = async()=>{
            try {
                const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameCity}/${data?.city_name_and_distict_name}`);
                
                setSelectedCity(response?.data?.data?.id)
                setValue("city_name_and_distict_name", response?.data?.data?.id)
              } catch (error) {
                console.error('Error:', error);
              }
            }
            getCall()
        }
        if(data?.department){
            const getCall = async()=>{
            try {
                const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameDepartment}/${data?.department}`);
                
                setSelectedDepartment(response?.data?.data?.id)
                setValue("department", response?.data?.data?.id)
              } catch (error) {
                console.error('Error:', error);
              }
            }
            getCall()
        }
        if(data?.designation){
            const getCall = async()=>{
            try {
                const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameDesignation}/${data?.designation}`);
                
                setSelectedDesignation(response?.data?.data?.id)
                setValue("designation", response?.data?.data?.id)
              } catch (error) {
                console.error('Error:', error);
              }
            }
            getCall()
        }
        if(data?.reference || data?.reference===null ){
            const getCall = async()=>{
            try {
                // if(data?.reference===null||data?.reference===""){
                    
                //     setSelectedEmployee('other')
                //     setValue("reference",'')
                //     setShowOtherRef(true)
                // }
                // else{
                //     const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameReference}/${data?.reference}`);
                //     setSelectedEmployee(response?.data?.data?.id)
                //     setValue("reference",response?.data?.data?.id)
                // }

                if (data?.other_reference === "" && data?.reference===null){
                    setSelectedEmployee('')
                    setValue("reference",'')
                    setShowOtherRef(false)
                }
            else if (data?.other_reference){
                setSelectedEmployee('other')
                setValue("reference",'')
                setShowOtherRef(true)    
            }
                // setSelectedDesignation(response?.data?.data?.id)
                // setValue("designation", response?.data?.data?.id)
              } catch (error) {
                console.error('Error:', error);
              }
            }
            getCall()
        }
        },[])


        const optionsGrade = [
            {
                value: 1,
                label: "A"
            },
            {
                value: 2,
                label: "B"
            },
            {
                value: 3,
                label: "C"
            },
            {
                value: 4,
                label: "D"
            },
            {
                value: 5,
                label: "E"
            },
    
        ]
        const [forGradeValue, setForGradeValue]: any = useState([])
    const [selectedGrade, setselectedGrade]: any = useState('')

    
        const handleChangeGrade = (e: any) => {
            setForGradeValue(e)
            setValue("customer_grade", e?.label)
        }
    


    return (
            <div >
                <section className="content-header py-1 px-3">
                    {/* <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit Employee</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Users</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listEmployee" className="text-decoration-none text-black">Employee</Link></li>
                                    <li className="breadcrumb-item active">Edit Employee</li>
                                </ol>
                            </div>
                        </div>
                    </div> */}
                </section>
                <section className="">
                    <div className="">
                        <div className="">

                            <form
                                ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="" >

                                    {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">

                                               
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Account Type <span style={{ color: "red" }}>*</span></label>
                                                    <div className="d-flex row" >
                                                    <Select
                                                        {...register("group", { required: { value: true, message: "Account Type is required" } })}
                                                        placeholder={'Account Type'}
                                                        onChange={handleChangeAccount}
                                                        // value={forAccountValue}
                                                        options={optionsAccount}
                                                        components={customIndicator}
                                                        value={selAccount === "" ? optionsAccount?.find((obj: any) => obj.value === selectedAccount) : selAccount}

                                                        className="dropdown-select"
                                                        
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenAccount(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                        </div>
                                                    <span className='text-red w-100'>{errors?.group?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Salary Type <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueSalaryType(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("salary_type", { required: { value: true, message: "Salary Type is required" } })}
                                                        placeholder={'Salary Type'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsSalaryType}
                                                        onMenuOpen={() => SalaryTypeRefetch()}
                                                        onCreateOption={handleCreateSalaryType}
                                                        onChange={handleChangeSalaryType}
                                                        // value={valueSalaryTypeCreatable == null ? valueSalaryType : valueSalaryTypeCreatable}
                                                        value={valueSalaryTypeCreatable == null ? valueSalaryType == null ? optionsSalaryType?.filter((obj: any) => obj.value === selectedSalaryType) : valueSalaryType : valueSalaryTypeCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.salary_type?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Gender <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select"  {...register("gender", { required: { value: true, message: "Gender is required" } })}>
                                                        <option value={""} selected >Select Any</option>
                                                        <option value="Male" >Male</option>
                                                        <option value="Female">Female</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.gender?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Title <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueTitle(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("title", { required: { value: true, message: "Title is required" } })}
                                                        placeholder={'Title'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsTitle}
                                                        onMenuOpen={() => TitleRefetch()}
                                                        onCreateOption={handleCreateTitle}
                                                        onChange={handleChangeTitle}
                                                        // value={valueTitleCreatable == null ? valueTitle : valueTitleCreatable}
                                                        value={valueTitleCreatable == null ? valueTitle == null ? optionsTitle?.filter((obj: any) => obj.value === selectedTitle) : valueTitle : valueTitleCreatable}

                                                    />  
                                                    <span className='text-red w-100'>{errors?.title?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Employee First Name <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Employee First Name" {...register("name", { onChange: handleUpper, required: { value: true, message: "Employee First Name is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.name?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Employee Middle Name <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Employee Middle Name" {...register("father_name", { onChange: handleUpper, required: { value: true, message: "Employee Middle Name is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.father_name?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Employee Last Name <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Employee Last Name" {...register("surname", { onChange: handleUpper, required: { value: true, message: "Employee Last Name is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.surname?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">City Name & District Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("city_name_and_distict_name", { required: { value: true, message: "City Name & District Name is required" } })}
                                                            onChange={handleChangeCity}
                                                            options={cityOptions}
                                                            components={customIndicator}
                                                            value={selCity === "" ? cityOptions?.find((obj: any) => obj.value === selectedCity) : selCity}

                                                            // maxMenuHeight={130}
                                                            // isOptionDisabled={(options:any)=>
                                                            //     {
                                                            //         if(options.status === "inactive" || options.deleted == true){
                                                            //         return options
                                                            //         }
                                                            //        }}

                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenCity(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.city_name_and_distict_name?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name_and_grade">Department <span style={{ color: "red" }}>*</span></label>
                                                    <div className="d-flex row">
                                                        <Select
                                                            {...register("department", { required: { value: true, message: "Department is required" } })}
                                                            placeholder={'Department'}
                                                            onChange={handleChangeDepartment}
                                                            // value={forDepartmentValue}
                                                            options={optionsDepartment}
                                                            components={customIndicator}
                                                            value={selDepartment === "" ? optionsDepartment?.find((obj: any) => obj.value === selectedDepartment) : selDepartment}

                                                            className="dropdown-select"
                                                        // isMulti
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpen(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.department?.message}</span>

                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label >Designation <span style={{ color: "red" }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("designation", { required: { value: true, message: "Designation is required" } })}
                                                            placeholder={'Designation'}
                                                            onChange={handleChangeDesignation}
                                                            // value={forDesignationValue}
                                                            options={optionsDesignation}
                                                            components={customIndicator}
                                                            value={selDesignation === "" ? optionsDesignation?.find((obj: any) => obj.value === selectedDesignation) : selDesignation}

                                                            className="dropdown-select"
                                                        // isMulti
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenDesignation(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.designation?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name_and_grade">Grade </label>
                                                    <div className="d-flex row">
                                                        <Select
                                                            {...register("customer_grade", { required: { value: false, message: "Grade is required" } })}
                                                            placeholder={'Grade'}
                                                            onChange={handleChangeGrade}
                                                            // value={forGradeValue}
                                                            options={optionsGrade}
                                                            components={customIndicator}
                                                            maxMenuHeight={150}
                                                            value={forGradeValue==""?optionsGrade?.filter((e:any)=>e?.label === selectedGrade):forGradeValue}
                                                        />
                                                    </div>
                                                    {/*<div className="d-flex row">
                                                        <Select
                                                            {...register("customer_grade", { required: { value: false, message: "Customer Grade is required" } })}
                                                            placeholder={'Customer Grade'}
                                                            onChange={handleChangeGrade}
                                                            value={forGradeValue}
                                                            options={optionsGrade}
                                                            components={customIndicator}
                                                            maxMenuHeight={150}
                                                        />
                                                    </div>*/}
                                                    <span className='text-red w-100'>{errors?.customer_grade?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Qualification <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Qualification" {...register("qualification", { onChange: handleUpper, required: { value: true, message: "Qualification is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.qualification?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Weekly Off <span style={{ color: "red" }}>*</span></label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueWeeklyOff(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("weekly_off", { required: { value: true, message: "Weekly Off is required" } })}
                                                        placeholder={'Weekly Off'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsWeeklyOff}
                                                        onMenuOpen={() => WeeklyOffRefetch()}
                                                        onCreateOption={handleCreateWeeklyOff}
                                                        onChange={handleChangeWeeklyOff}
                                                        // value={valueWeeklyOffCreatable == null ? valueWeeklyOff : valueWeeklyOffCreatable}
                                                        value={valueWeeklyOffCreatable == null ? valueWeeklyOff == null ? optionsWeeklyOff?.filter((obj: any) => obj.value === selectedWeeklyOff) : valueWeeklyOff : valueWeeklyOffCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.weekly_off?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Administration Type <span style={{ color: "red" }}>*</span></label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueAdministrationType(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("administration_type", { required: { value: true, message: "Administration Type is required" } })}
                                                        placeholder={'Administration Type'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsAdministrationType}
                                                        onMenuOpen={() => AdministrationTypeRefetch()}
                                                        onCreateOption={handleCreateAdministrationType}
                                                        onChange={handleChangeAdministrationType}
                                                        // value={valueAdministrationTypeCreatable == null ? valueAdministrationType : valueAdministrationTypeCreatable}
                                                        value={valueAdministrationTypeCreatable == null ? valueAdministrationType == null ? optionsAdministrationType?.filter((obj: any) => obj.value === selectedAdministrationType) : valueAdministrationType : valueAdministrationTypeCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.administration_type?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name_and_grade">Reference By </label>
                                                    <div className="d-flex row">
                                                        <Select
                                                            {...register("reference", { required: { value: false, message: "Reference By is required" } })}
                                                            placeholder={'Reference By'}
                                                            onChange={handleChangeEmployee}
                                                            // value={EmployeeValue}
                                                            options={optionsEmployee}
                                                            components={customIndicator}
                                                            value={selEmployee === "" ? optionsEmployee?.find((obj: any) => obj.value === selectedEmployee) : selEmployee}

                                                        />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.reference?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Other Reference By </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Reference By"
                                                        disabled={!showOtherRef}
                                                        {...register("other_reference", { onChange: handleUpper, required: { value: false, message: "Reference By is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.other_reference?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Remark </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Remark" {...register("re_mark", { onChange: handleUpper, required: { value: false, message: "Remark is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.re_mark?.message}</span>
                                                </div>


                                                {/* 
                                                <div className="col-sm-3 mt-2">
                                                <label htmlFor="manualcode_Disc_1">Joining Date <span style={{ color: 'red' }}>*</span></label>
                                                <div className="input-group" >
                                                <DatePicker 
                                                        className="form-control"
                                                        format={dateFormatList}
                                                        // value={forPointStartDateValue}
                                                        value={JoiningDate == undefined ? dayjs(fromJoiningValue, "DD/MM/YYYY") : JoiningDate}
                                                        // disabledDate={disabledDate}
                                                        {...register("joining_date", { required: { value: false, message: "Joining Date is required" } })}

                                                        onChange={handleJoiningDate}
                                                        />


                                                  
                                                </div>  
                                                <span className='text-red w-100'>{errors?.joining_date?.message}</span>
                                            </div>
                                                <div className="col-sm-3 mt-2">
                                                <label htmlFor="manualcode_Disc_1">Birth Date <span style={{ color: 'red' }}>*</span></label>
                                                <div className="input-group" >


                                                <DatePicker 
                                                
                                                        className="form-control"
                                                        format={dateFormatList}
                                                        value={birthDate == undefined ? dayjs(fromBirthValue, "DD/MM/YYYY") : birthDate}
                                                        // value={forPointStartDateValue}
                                                        // disabledDate={disabledDate}
                                                        {...register("birth_date", { required: { value: false, message: "Birth Date is required" } })}
                                                        picker="date"
                                                        onChange={handleBirthDate}
                                                        />


                                                        
                                               


                                                  
                                                </div>  
                                                <span className='text-red w-100'>{errors?.birth_date?.message}</span>

                                            </div>
                                            <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Age </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                    <input type="number" disabled className="form-control" 
                                                     value={age}
                                                     placeholder="Age" {...register("age", { required: { value: false, message: "Field is required" } })} />
                                                    
                                                        </div>
                                                    <span className='text-red w-100'>{errors?.age?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Marital Status <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select"  {...register("marital_status", { onChange:onMarriedChange, required: { value: false, message: "Field is required" } })}>
                                                        <option value={""} disabled selected >Select Any</option>
                                                        <option value="Single" >Single</option>
                                                        <option value="Married">Married</option>
                                                        <option value="UnMarried">UnMarried</option>
                                                        <option value="Divorced">Divorced</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.marital_status?.message}</span>
                                                </div>
                                                {Married === "Married"&&(<>
                                                <div className="col-sm-3 mt-2">
                                                <label htmlFor="manualcode_Disc_1">Anniversary Date </label>
                                                <div className="input-group" >


                                                <DatePicker 
                                                        className="form-control"
                                                        format={dateFormatList}
                                                        value={anniversary == undefined ? dayjs(fromAnniversaryValue, "DD/MM/YYYY") : anniversary}
                                                        // value={forPointStartDateValue}
                                                        // disabledDate={disabledDate}
                                                        {...register("anniversary_date", { required: { value: false, message: "Point Start Date is required" } })}

                                                        onChange={handleAnniversaryDate}
                                                        />


                                                  
                                                </div>  
                                                <span className='text-red w-100'>{errors?.anniversary_date?.message}</span>

                                            </div>
                                            </>)}
                                            <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Nationality <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select"  {...register("nationality", {  required: { value: false, message: "Field is required" } })}>
                                                        <option value={""} disabled selected >Select Any</option>
                                                        <option value="Indian" >Indian</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.nationality?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Religion <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select"  {...register("religion", {  required: { value: false, message: "Field is required" } })}>
                                                        <option value={""} disabled selected >Select Any</option>
                                                        <option value="Hindu" >Hindu</option>
                                                        <option value="Muslim">Muslim</option>
                                                        <option value="Christian">Christian</option>
                                                        <option value="Sikh">Sikh</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.religion?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Caste <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select"  {...register("caste", {  required: { value: false, message: "Field is required" } })}>
                                                        <option value={""} disabled selected >Select Any</option>
                                                        <option value="General" >General</option>
                                                        <option value="ST">ST</option>
                                                        <option value="SC">SC</option>
                                                        <option value="OBC">OBC</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.caste?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Blood Group <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select"  {...register("blood_group", {  required: { value: false, message: "Field is required" } })}>
                                                        <option value={""} disabled selected >Select Any</option>
                                                        <option value="O+" >O+</option>
                                                        <option value="O-">O-</option>
                                                        <option value="A+">A+</option>
                                                        <option value="A-">A-</option>
                                                        <option value="B+">B+</option>
                                                        <option value="B-">B-</option>
                                                        <option value="AB+">AB+</option>
                                                        <option value="AB-">AB-</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.blood_group?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Reference </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                    <input type="text"  className="form-control" id="exampleInputBrand" placeholder="Reference" {...register("reference", { onChange:handleUpper,required: { value: false, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                        </div>
                                                    <span className='text-red w-100'>{errors?.reference?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Balance Sheet  <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select"  {...register("balance_sheet_group", {  required: { value: false, message: "Field is required" } })}>
                                                        <option value={""} disabled selected >Select Any</option>
                                                        <option value="Salary" >Salary</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.balance_sheet_group?.message}</span>
                                                </div> */}
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Employee  <span style={{ color: 'red' }}>*</span></label>


                                                    <select className="form-select" {...register("employee_status", { onChange: onEmployeeChange, required: { value: false, message: "Printing is required" } })}>
                                                        <option value={""} disabled selected>Select Any</option>
                                                        <option value={"yes"}>Yes</option>
                                                        <option value={"no"}>No</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.employee?.message}</span>
                                                </div> 
                                                 {EmployeeId === "yes" ?
                                                    <>
                                                    <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">User ID <span style={{color:'red'}}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="User ID"  {...register("username",{ required: { value: true, message: "User ID is required" }, maxLength: 30,})} 
                                                        onChange={handleUpper}
                                                        />
                                                         <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                        <span className='text-red w-100'>{errors?.username?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">User Password <span style={{color:'red'}}>*</span></label>
                                                    <div className="input-group-form" style={{width:"100%"}}>
                                                    <input
                                                        type={iconChange?iconChange:"password"}
                                                        className="form-control rounded-3"
                                                        placeholder="Password"
                                                        {...register("password", { required: {value: true, message: "Password is required"} })}
                                                    />
                                                    <div className="input-group-append" >
                                                    <span className="iconset" id="basic-addon1"><FontAwesomeIcon onClick={changeicon} icon={iconChange == "password" ? faEye : faEyeSlash} /></span>
                                                    </div>
                                                </div>
                                                        <span className='text-red w-100'>{errors?.password?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">User Confirm Password <span style={{color:'red'}}>*</span></label>
                                                    <div className="input-group-form" style={{width:"100%"}}>
                                                    <input
                                                        type={confirmiconChange?confirmiconChange:"password"}
                                                        className="form-control rounded-3"
                                                        placeholder="Password"
                                                        {...register("password", { required: {value: true, message: "Password is required"} })}
                                                    />
                                                    <div className="input-group-append" >
                                                    <span className="iconset" id="basic-addon1"><FontAwesomeIcon onClick={changeconfirmicon} icon={confirmiconChange == "confirmpassword" ? faEye : faEyeSlash} /></span>
                                                    </div>
                                                </div>
                                                        <span className='text-red w-100'>{errors?.password?.message}</span>
                                                </div>
                                                    </>:""} */}
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: false, message: "Status is required" } })}>
                                                        <option value={""} disabled selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Employee Code </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Employee Code" disabled {...register("code", { required: { value: false, message: "Employee Code is required" } })} />

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.code?.message}</span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Employee Image</label>
                                                    <label style={{ cursor: "pointer" }}>
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                        <input data-required="image" type="file" id="file_upload"  accept="image/*" className="image-input" data-traget-resolution="image_resolution"  {...register("image", { onChange: onLogoChange, required: false })} hidden />
                                                    </label>
                                                    <a href={data?.image} target="_blank" ><p>{logo == null ? data?.image?.split("/").pop() : logo}</p></a>
                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Document PDF</label>
                                                    <label style={{ cursor: "pointer" }}>
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                        <input data-required="image" type="file" id="file_upload"  accept="application/pdf" className="image-input" data-traget-resolution="image_resolution" {...register("document_pdf", { onChange: onLogoPDFChange, required: false })} hidden />
                                                    </label>
                                                    <a href={data?.document_pdf} target="_blank" ><p>{logoPdf == null ? data?.document_pdf?.split("/").pop() : logoPdf}</p></a>
                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Agreement PDF</label>
                                                    <label style={{ cursor: "pointer" }}>
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                        <input data-required="image" type="file" id="file_upload"  accept="application/pdf" className="image-input" data-traget-resolution="image_resolution" {...register("agreement_pdf", { onChange: onAgreementChange, required: false })} hidden />
                                                    </label>
                                                    <a href={data?.agreement_pdf} target="_blank" ><p>{agreement == null ? data?.agreement_pdf?.split("/").pop() : agreement}</p></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">

                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Image All</label>

                                                    {uploadImage?.length > 0 ?

                                                        <>
                                                            <Upload
                                                                {...register("image_all")}
                                                                accept="image/png, image/jpeg"
                                                                listType="picture"
                                                                onChange={imageChange}
                                                                multiple={true}
                                                                defaultFileList={uploadImage}
                                                                onPreview={(e: any) => { return "" }}
                                                                key={"image"}
                                                            >
                                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                            </Upload>
                                                        </> :
                                                        <>
                                                            <Upload
                                                                {...register("image_all")}
                                                                accept="image/png, image/jpeg"
                                                                listType="picture"
                                                                onChange={imageChange}
                                                                multiple={true}
                                                                // defaultFileList={uploadImage}
                                                                onPreview={(e: any) => { return "" }}
                                                            // key={"image"}
                                                            >
                                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                            </Upload>
                                                        </>
                                                    }


                                                </div>

                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Document PDF All</label>

                                                    {uploadDocumentPDF?.length > 0 ?

                                                        <>
                                                            <Upload
                                                                {...register("document_pdf")}
                                                                accept=".doc,.docx,.xml,.pdf"
                                                                listType="text"
                                                                onChange={DocumentChange}
                                                                multiple={true}
                                                                defaultFileList={uploadDocumentPDF}
                                                                onPreview={(e: any) => { return "" }}
                                                                key={"pdf"}
                                                            >
                                                                <img src={pdfLogo} alt='' className="uploaded-image" />
                                                            </Upload>
                                                        </> :
                                                        <>
                                                            <Upload
                                                                {...register("document_pdf")}
                                                                accept=".doc,.docx,.xml,.pdf"
                                                                listType="text"
                                                                onChange={DocumentChange}
                                                                multiple={true}
                                                                // defaultFileList={uploadDocumentPDF}
                                                                onPreview={(e: any) => { return "" }}
                                                            // key={"pdf"}
                                                            >
                                                                <img src={pdfLogo} alt='' className="uploaded-image" />
                                                            </Upload>
                                                        </>
                                                    }

                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Agreement PDF All</label>

                                                    {uploadAgreementPDF?.length > 0 ?
                                                        <>
                                                            <Upload
                                                                {...register("agreement_pdf")}
                                                                accept=".doc,.docx,.xml,.pdf"
                                                                listType="text"
                                                                onChange={AgreementChange}
                                                                multiple={true}
                                                                defaultFileList={uploadAgreementPDF}
                                                                onPreview={(e: any) => { return "" }}
                                                                key={"pdf"}
                                                            >
                                                                <img src={pdfLogo} alt='' className="uploaded-image" />


                                                            </Upload>
                                                        </> : <>
                                                            <Upload
                                                                {...register("agreement_pdf")}
                                                                accept=".doc,.docx,.xml,.pdf"
                                                                listType="text"
                                                                onChange={AgreementChange}
                                                                multiple={true}
                                                                // defaultFileList={uploadAgreementPDF}
                                                                onPreview={(e: any) => { return "" }}
                                                            // key={"pdf"}
                                                            >
                                                                <img src={pdfLogo} alt='' className="uploaded-image" />

                                                            </Upload>
                                                        </>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">



                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        onClick={handleSubmit(onSubmit)}


                                                    >Submit</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetail")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            <Account_Model refetchForm={accountRefetch} open={openAccount} setOpen={setOpenAccount} setSubmitted={setSubmittedAccount} />
            <City_Model refetchForm={cityRefetch} open={openCity} setOpen={setOpenCity} setSubmitted={setSubmittedCity} />
            <Department_Model refetchForm={departmentRefetch} open={open} setOpen={setOpen} setSubmitted={setSubmitted} />
            <Designation_Model refetchForm={designationRefetch} open={openDesignation} setOpen={setOpenDesignation} setSubmitted={setSubmittedDesignation} />

{ valueSalaryTypeCreatable || valueSalaryType ?
            <Modal
                show={OpenSalaryType}
                onHide={() => setOpenSalaryType(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Salary Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                       
                        <span className="text-center">Are You Sure You Want To Change <span style={{color:'red'}}>{data?.salary_type?.name}</span> To <span style={{color:'red'}}>{valueSalaryType?.label||valueSalaryTypeCreatable?.name}</span>?
                        
                        </span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setOpenSalaryType(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={handleSubmit(onSubmitFromModel)}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
:"" }
            {/* Edit Modal HTML */}
            <Modal
                show={deleteConfirm}
                onHide={() => setDeleteConfirm(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete {""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {" "}
                        <span className="text-center">Sure you want to delete it?</span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setDeleteConfirm(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={handleMultipleDeleted}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Deleteforce
                key={renderKey}
                handleDeletedforce={handleDeletedforce}
                reconfirmdelete={confirmforcemodal}
                masterName={""}
                closereconfirmdelete={closereconfirmdelete}
                referance={
                    deletesucess
                        ? deletedata
                        : ""
                }
                apiname={apiName}
            />
            <Deletesingle
                key={renderKey}
                confirmmodal={deleteConfirmsingle}
                confirmmodalclose={confirmmodalclose}
                masterName={""}
                confirmmodalsubmit={confirmmodalsubmit}
                apiname={apiName}
            />

            <SimpleInput_Edit key={renderKey}
                refetchForm={handleRefetch}
                open={OpenEditDropDownModel}
                setOpen={setOpenEditDropDownModel}
                id={editId}
                // superAccountId={superAccountId} 
                // optionsSuperAccount={optionsSuperAccount}
                // isSuperAccount={isSuperAccount}
                apiname={apiName} />




            <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>





                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                />
                            </div>
                        </div>
                    </div>



                </Modal.Body>
                <Modal.Footer>



                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>


                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>

                </Modal.Footer>
            </Modal>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />

                
{show && (
          <Mui_Toaster
            message={`Employee Updated Successfully`}
            severity={"success"}
            Isopen={true}
          />
        )}
        </div>
    )
}

export default Edit_Employee