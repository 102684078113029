import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { useNavigate, useParams } from "react-router-dom";
import { useGSTEdit, useUpdateGst } from "../../../hooks/GST";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import { useCreate, useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../../hooks/Api";
import Select from "react-select"
import axios from 'axios'
import { Alert } from "../../../components/Model/Model";
import useFocusOnEnter from "../../../components/focus";
import { useFetchNationalityDropDown, useFetchReligionDropDown, useFetchCasteDropDown, useFetchBloodGroupDropDown } from "../../../hooks/DropDown_Api";

// import { Alert } from "../Model/Model";
// import Select from 'react-select/async';
import moment from "moment";
import dayjs from 'dayjs';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import { DatePicker } from "antd";

import { Deletesinglewithdependancy } from "../../../components/modal";
import Axiosinstance from "../../../hooks/Interceptor";
import CreatableSelect from "react-select/creatable";

// import Button from "react-bootstrap/esm/Button";
// import Modal from "react-bootstrap/esm/Modal";
import { Deleteforce, Deletesingle } from "../../../components/Cell/Actions";
import { SimpleInput_Edit } from "../../../components/Dynamic_Form_Models/SimpleInput_Edit";
import { useDelete, useDeletemforce } from "../../../hooks/delete";
import Custom_Actions_Buttons from "../../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../../hooks/deletemultiple";
import Mui_Toaster from "../../../components/Mui_Toaster/Mui_Toaster";


interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOptions = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});
const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}

const Edit_Personal_Details = ({ currentStep, setCurrentStep }: any) => {
    let pageName = "Customer Grade"
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    // const  addData:any  = localStorage.getItem('Add Data')

    let rawData = localStorage.getItem('Add Data');
    let addData = rawData ? JSON.parse(rawData) : null;

    const id = addData?.personal_detail?.id
    const tempData = addData?.personal_detail
    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_SALES_PERSONAL_DETAILS_API?.toString()
    const data = tempData

    const { mutate, isSuccess, isError, error, data: gstUpdated_response }: any = useUpdateSingle()




    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()

    const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm({
        mode: "onChange"
    });

    const [age, setAge] = useState(0)






    const prevDataRef = useRef();

    useEffect(() => {
        const isDataChanged = JSON.stringify(data) !== JSON.stringify(prevDataRef.current);
        if (data && isDataChanged) {
            reset(data);
            prevDataRef.current = data;
            setAge(data?.age)
            console.log(data, "dataabc");

        }
    }, [data, reset]);



    const onSubmit = (materialtypeDetails: any) => {


        if (!aniversaryDate) {
            delete materialtypeDetails.anniversary_date
        }
        if (!birthDate) {
            delete materialtypeDetails.birth_date
        }
        if (!JoiningDate) {
            delete materialtypeDetails.joining_date
        }
        if (!LeavingDate) {
            delete materialtypeDetails?.leaving_date
        }

        const formData = new FormData();
        for (const [key, value] of Object.entries(materialtypeDetails)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }

        }
        let data = {
            apiname: apiname,
            id: id,
            data: formData
        }
        mutate(data);
        setValuesofform(data)


    }

    // const [show, setShow] = useState(false)
    // const [showmessage, setshowmessage] = useState("")


    // useEffect(() => {
    //     if (isSuccess) {
    //         setShow(true)
    //         setshowmessage("success")
    //         setCurrentStep(currentStep+1)
    //     }
    //     else if (isError) {
    //         setShow(true)
    //         setshowmessage("danger")
    //     }
    // }, [gstUpdated_response, error,isSuccess])

    // useEffect(() => {
    //     setTimeout(() => {
    //         setShow(false)
    //     }, 3000)

    // }, [show]);













    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    };

    const handleReset = () => {
        reset()
        setBirthDate(null)
        setJoiningDate(null)
        setLeavingDate(null)
        setAnniversaryDate(null)
        setAge(0)
    }




    const [birthDate, setBirthDate]: any = useState()

    const handleBirthDate = (e: any) => {


        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let birth_date = `${date}-${month}-${year}`
        setValue("birth_date", birth_date)

        setBirthDate(e)

        // setAge(0)
        // setValue("age", 0)
    }


    useEffect(() => {
        if (birthDate) {

            let todayDate = new Date().toISOString().split("T")[0]


            var DateOne = moment([birthDate.$y]);

            var currentDate = moment([todayDate.split("-")[0]]);


            setAge(currentDate.diff(DateOne, 'years'))
            setValue('age', age)

        }
    }, [birthDate, age])




    const [Married, setMarried] = useState("Single")

    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
    const [JoiningDate, setJoiningDate]: any = useState("")
    const [LeavingDate, setLeavingDate]: any = useState("")


    const handleJoiningDate = (e: any) => {

        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let joining_date = `${date}-${month}-${year}`
        setValue("joining_date", joining_date)
        setJoiningDate(e)

    }
    const handleLeavingDate = (e: any) => {

        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let leaving_date = `${date}-${month}-${year}`
        setValue("leaving_date", leaving_date)
        setLeavingDate(e)

    }

    const [aniversaryDate, setAnniversaryDate]: any = useState("")

    const handleAnniversaryDate = (e: any) => {

        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let anniversary_date = `${date}-${month}-${year}`
        setValue("anniversary_date", anniversary_date)
        setAnniversaryDate(e)

    }

    const onMarriedChange = (e: any) => {
        setMarried(e.target.value)
    }

    //     useEffect(() => {

    //         if (data) {


    // // console.log(data,"dataaaaaaaaaaaaa");

    //             reset(data)

    //             // setValue("joining_date",new Date( moment(data?.joining_date, "DD-MM-YYYY").toISOString()))

    //             if (data?.joining_date) {
    //                 setJoiningDate(dayjs(data?.joining_date, 'DD/MM/YYYY'))
    //             }
    //             if (data?.leaving_date) {
    //                 setLeavingDate(dayjs(data?.leaving_date, 'DD/MM/YYYY'))
    //             }
    //             if (data?.birth_date) {
    //                 setBirthDate(dayjs(data?.birth_date, 'DD/MM/YYYY'))
    //             }
    //         }

    //     }, [])









    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    const [openEditDateModel, setOpenEditDateModel] = useState(false)
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()
    const [expirationDate, setExpirationDate] = useState()

    const apinameForBasicDetails = process.env.REACT_APP_EMPLOYEE_API?.toString()
    const { mutate: BasicDetails, isSuccess: basicDetailsIsSuccess, isError: basicDetailsIsError, error: basicDetailsError, data: basicDetailsData }: any = useUpdateSingle()

    let rawDataTemp = localStorage.getItem('Add Data');
    let addDataTemp = rawDataTemp ? JSON.parse(rawDataTemp) : null;

    let basicIdTemp = addDataTemp ? addDataTemp.id : null;

    console.log(addDataTemp, "addDataTemp", basicIdTemp);


    const [basicDetailsInitiated, setBasicDetailsInitiated] = useState(false)

    useEffect(() => {
        if ((isSuccess || yessuccess || nosuccess) && !basicDetailsInitiated) {
            setShow(true);
            setshowmessage("success");


            let tempId = {
                personal_detail: data?.data?.id
            }

            let tempData = {
                id: basicIdTemp,
                apiname: apinameForBasicDetails,
                data: tempId
            }
            BasicDetails(tempData);
            setBasicDetailsInitiated(true);
            //   navigate("/listCity", { state: { showEditToast: true } });
            //  const currentPath = window.location.pathname;
            // if(currentPath === '/addCustomerDetails'){
            // setCurrentStep(currentStep + 1)
            // }
        } else if (isError) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [data, isSuccess, yessuccess, nosuccess, gstUpdated_response, error,
        isSuccess,]);

    const [updateCompleted, setUpdateCompleted] = useState(false)

    useEffect(() => {
        if (basicDetailsIsSuccess && basicDetailsData && !updateCompleted) {

            localStorage.setItem('Add Data', JSON.stringify(basicDetailsData?.data))
            setUpdateCompleted(true);
            const currentPath = window.location.pathname;
            if (currentPath === '/addCustomerDetails') {
                setCurrentStep(currentStep + 1)
            };
        }
    }, [basicDetailsIsSuccess, basicDetailsData])

    useEffect(() => {
        if (isError) {
            if (error.response?.status === 405) {
                setConfirmmodal(true);
            }
        } else if (isError || yeserror || noerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [isError, yeserror, noerror]);
    useEffect(() => {
        setTimeout(() => {
            setShow(false);
        }, 3000);
    }, []);
    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }
    const noupdatemodal = () => {
        setOpenEditDateModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {

        delete data.created_at
        delete data.created_by
        let ok = {
            id: id,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditDateModel(false)
    }



    const apinamePersonal = process.env.REACT_APP_SALES_PERSONAL_DETAILS_API?.toString()
    // console.log(data, "data-data");

    useEffect(() => {
        if (data.id) {
            const getCall = async () => {
                // debugger
                try {
                    const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinamePersonal}/${data.id}/`);
                    console.log('ResponseData:', response.data.data);
                    reset(response.data.data)
                    if (response.data.data?.joining_date) {
                        setJoiningDate(dayjs(response.data.data?.joining_date, 'DD/MM/YYYY'))
                    }
                    if (response.data.data?.leaving_date) {
                        setLeavingDate(dayjs(response.data.data?.leaving_date, 'DD/MM/YYYY'))
                    }
                    if (response.data.data?.birth_date) {
                        setBirthDate(dayjs(response.data.data?.birth_date, 'DD/MM/YYYY'))
                    }
                    setSelectedCaste(response.data.data?.caste?.id)
                    setValue('caste', response.data.data?.caste?.id)
                    setSelectedNationality(response.data.data?.nationality?.id)
                    setValue('nationality', response.data.data?.nationality?.id)
                    setSelectedReligion(response.data.data?.religion?.id)
                    setValue('religion', response.data.data?.religion?.id)
                    setSelectedBloodGroup(response.data.data?.blood_group?.id)
                    setValue('blood_group', response.data.data?.blood_group?.id)

                } catch (error) {
                    console.error('Error:', error);
                }
            }
            getCall()
        }

    }, [])


    // const handleNext =()=>{
    //     setCurrentStep(currentStep+1)
    // }

    const [valueNationality, setValueNationality]: any = useState();
    const [optionsNationality, setOptionsNationality]: any = useState([])
    const [isLoadingValue, setIsLoadingValue] = useState(false);
    const [selectedNationality, setSelectedNationality]: any = useState('');
    const [valueNationalityCreatable, setValueNationalityCreatable] = useState<Option | null>();
    const apinameForNationality = process.env.REACT_APP_NATIONALITY_API?.toString()
    const { mutate: mutateNationality, data: dataNationality, isError: NationalityIsError, isLoading: NationalityLoading, isSuccess: NationalitySuccess, error: NationalityError }: any = useCreate(apinameForNationality)

    const { data: NationalityData, refetch: NationalityRefetch } = useFetchNationalityDropDown()

    useEffect(() => {
        setOptionsNationality(NationalityData)
    }, [NationalityData])

    const handleChangeNationality = (e: any) => {

        NationalityRefetch()

        setApiName(apinameForNationality)
        setDeleteApiName(apinameForNationality)
        setEditId(e?.value)
        setEditData(NationalityData)
        setRenderKey(prevKey => prevKey + 1);

        setValueNationality(e)
        setValue("nationality", e?.value)
        setValueNationalityCreatable(null)
    }

    const handleCreateNationality = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOptions(inputValue);
            setIsLoadingValue(false);


            mutateNationality({ name: newOption.name, status: newOption.status })
            setOptionsNationality((prev: any) => [...prev, newOption]);
            setValueNationalityCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (NationalitySuccess && optionsNationality) {
                await NationalityRefetch();
                setValue("nationality", NationalityData[0].value)
            }

        })();

        return () => {
        };
    }, [NationalitySuccess, NationalityData]);

    const [valueReligion, setValueReligion]: any = useState();
    const [optionsReligion, setOptionsReligion]: any = useState([])
    const [selectedReligion, setSelectedReligion]: any = useState('');
    const [valueReligionCreatable, setValueReligionCreatable] = useState<Option | null>();
    const apinameForReligion = process.env.REACT_APP_RELIGION_API?.toString()
    const { mutate: mutateReligion, data: dataReligion, isError: ReligionIsError, isLoading: ReligionLoading, isSuccess: ReligionSuccess, error: ReligionError }: any = useCreate(apinameForReligion)

    const { data: ReligionData, refetch: ReligionRefetch } = useFetchReligionDropDown()

    useEffect(() => {
        setOptionsReligion(ReligionData)
    }, [ReligionData])

    const handleChangeReligion = (e: any) => {

        ReligionRefetch()

        setApiName(apinameForReligion)
        setDeleteApiName(apinameForReligion)
        setEditId(e?.value)
        setEditData(ReligionData)
        setRenderKey(prevKey => prevKey + 1);

        setValueReligion(e)
        setValue("religion", e?.value)
        setValueReligionCreatable(null)
    }

    const handleCreateReligion = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOptions(inputValue);
            setIsLoadingValue(false);


            mutateReligion({ name: newOption.name, status: newOption.status })
            setOptionsReligion((prev: any) => [...prev, newOption]);
            setValueReligionCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ReligionSuccess && optionsReligion) {
                await ReligionRefetch();
                setValue("religion", ReligionData[0].value)
            }

        })();

        return () => {
        };
    }, [ReligionSuccess, ReligionData]);

    const [valueCaste, setValueCaste]: any = useState();
    const [optionsCaste, setOptionsCaste]: any = useState([])
    const [selectedCaste, setSelectedCaste]: any = useState('');
    const [valueCasteCreatable, setValueCasteCreatable] = useState<Option | null>();
    const apinameForCaste = process.env.REACT_APP_EMPLOYEE_CASTE_API?.toString()
    const { mutate: mutateCaste, data: dataCaste, isError: CasteIsError, isLoading: CasteLoading, isSuccess: CasteSuccess, error: CasteError }: any = useCreate(apinameForCaste)

    const { data: CasteData, refetch: CasteRefetch } = useFetchCasteDropDown()

    useEffect(() => {
        setOptionsCaste(CasteData)
    }, [CasteData])

    const handleChangeCaste = (e: any) => {

        CasteRefetch()

        setApiName(apinameForCaste)
        setDeleteApiName(apinameForCaste)
        setEditId(e?.value)
        setEditData(CasteData)
        setRenderKey(prevKey => prevKey + 1);


        setValueCaste(e)
        setValue("caste", e?.value)
        setValueCasteCreatable(null)
    }

    const handleCreateCaste = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOptions(inputValue);
            setIsLoadingValue(false);


            mutateCaste({ name: newOption.name, status: newOption.status })
            setOptionsCaste((prev: any) => [...prev, newOption]);
            setValueCasteCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (CasteSuccess && optionsCaste) {
                await CasteRefetch();
                setValue("caste", CasteData[0].value)
            }

        })();

        return () => {
        };
    }, [CasteSuccess, CasteData]);

    const [valueBloodGroup, setValueBloodGroup]: any = useState();
    const [optionsBloodGroup, setOptionsBloodGroup]: any = useState([])
    const [selectedBloodGroup, setSelectedBloodGroup]: any = useState('');
    const [valueBloodGroupCreatable, setValueBloodGroupCreatable] = useState<Option | null>();
    const apinameForBloodGroup = process.env.REACT_APP_EMPLOYEE_BLOODGROUP_API?.toString()
    const { mutate: mutateBloodGroup, data: dataBloodGroup, isError: BloodGroupIsError, isLoading: BloodGroupLoading, isSuccess: BloodGroupSuccess, error: BloodGroupError }: any = useCreate(apinameForBloodGroup)

    const { data: BloodGroupData, refetch: BloodGroupRefetch } = useFetchBloodGroupDropDown()

    useEffect(() => {
        setOptionsBloodGroup(BloodGroupData)
    }, [BloodGroupData])

    const handleChangeBloodGroup = (e: any) => {


        BloodGroupRefetch()

        setApiName(apinameForBloodGroup)
        setDeleteApiName(apinameForBloodGroup)
        setEditId(e?.value)
        setEditData(BloodGroupData)
        setRenderKey(prevKey => prevKey + 1);

        setValueBloodGroup(e)
        setValue("blood_group", e?.value)
        setValueBloodGroupCreatable(null)
    }

    const handleCreateBloodGroup = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOptions(inputValue);
            setIsLoadingValue(false);


            mutateBloodGroup({ name: newOption.name, status: newOption.status })
            setOptionsBloodGroup((prev: any) => [...prev, newOption]);
            setValueBloodGroupCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (BloodGroupSuccess && optionsBloodGroup) {
                await BloodGroupRefetch();
                setValue("blood_group", BloodGroupData[0].value)
            }

        })();

        return () => {
        };
    }, [BloodGroupSuccess, BloodGroupData]);

    const currentPath = window.location.pathname;





    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
    } = useDelete();
    const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
    } = useDeletemforce();



    // const queryClient = useQueryClient();
    const handleRefetch = () => {
        if (apiName === apinameForNationality) {
            NationalityRefetch()
        }
        if (apiName === apinameForReligion) {
            ReligionRefetch()
        }
        if (apiName === apinameForCaste) {
            CasteRefetch()
        }
        if (apiName === apinameForBloodGroup) {
            BloodGroupRefetch()
        }


        // queryClient.invalidateQueries(['Panni Material Use']);




    }


    useEffect(() => {
        if (OpenEditDropDownModel && valueNationality && apiName === apinameForNationality) {
            setValueNationality(null)
            setValueNationalityCreatable(null)
        }
        if (OpenEditDropDownModel && valueReligion && apiName === apinameForReligion) {
            setValueReligion(null)
            setValueReligionCreatable(null)
        }
        if (OpenEditDropDownModel && valueCaste && apiName === apinameForCaste) {
            setValueCaste(null)
            setValueCasteCreatable(null)
        }
        if (OpenEditDropDownModel && valueBloodGroup && apiName === apinameForBloodGroup) {
            setValueBloodGroup(null)
            setValueBloodGroupCreatable(null)
        }








        if (deletesucess && apiName === apinameForNationality) {
            setValueNationality(null)
            setValueNationalityCreatable(null)
        }
        if (deletesucess && apiName === apinameForReligion) {
            setValueReligion(null)
            setValueReligionCreatable(null)
        }
        if (deletesucess && apiName === apinameForCaste) {
            setValueCaste(null)
            setValueCasteCreatable(null)
        }
        if (deletesucess && apiName === apinameForBloodGroup) {
            setValueBloodGroup(null)
            setValueBloodGroupCreatable(null)
        }




    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (


        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
            <span>{label}</span>
            <div style={{ float: "right", justifyContent: "end" }}>



                {isExist ?

                    <Custom_Actions_Buttons value={value}
                        apiname={deleteApiName}
                        mutate={singleDelete}
                        // masterName={addButtonName}
                        deleteid={deleteid}
                        handleDeletedforce={handleDeletedforce}
                        closereconfirmdelete={closereconfirmdelete}
                        deletemodalask={deletemodalask}
                        setEditOpen={setOpenEditDropDownModel}
                    // permissionClass={permissionClass}
                    />
                    : ""}

            </div>
        </div>


    )


    const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
    }



    useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
        return () => {
            setDeleteid(undefined);
        };
    }, []);
    const handleConfirmDelete = () => {
        setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
    };

    const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
    };
    useEffect(() => {
        if (forcefullysuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullyerror) {
            setShow(true);
            setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullydeleteerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
    ]);

    useEffect(() => {
        if (deleteMultipleSuccess) {
            const showdepenedancymsg = deleteMultiple?.data?.data;
            const dependacyfound = showdepenedancymsg?.filter((color: any) => {
                if (
                    color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
                ) {
                    return color;
                }
            });
            if (dependacyfound?.length === deleteMultipleid?.length) {
                deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
            } else {
                setConfirmforcemodal(true);
            }
        } else if (deleteMultipleIsError) {
            setShow(true);
            setshowmessage("danger");
        } else if (deletesucess) {
            if (deletedata.data?.data?.length > 0) {
                setConfirmforcemodal(true);
            } else if (deleteid) {
                setShow(true);
                setshowmessage("success");
                deleteforce({ deleteid, apiname: deleteApiName });
            }
        }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

    const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
    };





    return (
        // <>
        <div>
            {/* <div className="" style={{ minHeight: '1345.31px' }}> */}
            <section className="content-header py-1 px-3">
                {/* <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Add {pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">{pageName}</Link></li>
                                    <li className="breadcrumb-item active">Add {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div> */}
            </section>
            {/* <section className="content"> */}
            {/* <div className="container-fluid"> */}
            <div className="card card-default">
                {/* <h3 className="px-3">Personal Details</h3> */}
                {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Source Name</span></h5>
                            </div> */}
                <form ref={focus}
                    onKeyUp={event => onEnterKey(event)}
                // onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="card-body" >
                        {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message?.name} /> : ""}

                        <div className="row">
                            <p className="mb-0 " style={{ fontSize: '14px' }}>{`${addData?.person_type?.name}-${addData?.name}-${addData?.extra_response?.city_name_and_distict_name?.village_name ? addData?.extra_response?.city_name_and_distict_name?.village_name : addData?.extra_response?.city_name_and_distict_name?.city_name}`}</p>
                        </div>
                        <div className="row d-flex justify-content-md-center justify-content-lg-start">
                            <div className="col-md-12">
                                <div className="row">
                                    {/* <div className="col-sm-3 mt-2">
                                                <label >Person Type <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                    {...register("person_type", { required: { value: true, message: "Field is required" } })}
                                                    isClearable={()=>{
                                                        setValuePersonType(null)
                                                        setValue(null)}}
                                                        components={customCreatableIndicator}
                                                        placeholder={'Person Type'}
                                                        options={optionsPersonType}
                                                        onCreateOption={handleCreatePersonType}
                                                        onChange={handleChangePersonType}
                                                        value={valuePersonTypeCreatable==null?valuePersonType:valuePersonTypeCreatable}
                                                        

                                                    />
                                                    <span className='text-red w-100'>{errors?.person_type?.message}</span>

                                                </div>    */}







                                    <div className="col-sm-3 mt-2">
                                        <label htmlFor="manualcode_Disc_1">Joining Date <span style={{ color: 'red' }}>*</span></label>
                                        <div className="input-group" >
                                            <DatePicker
                                                className="form-control"
                                                {...register("joining_date", { required: { value: true, message: "Joining Date is required" } })}
                                                format={dateFormatList}
                                                value={JoiningDate}

                                                onChange={handleJoiningDate}
                                            />
                                        </div>
                                        <span className='text-red w-100'>{errors?.joining_date?.message}</span>
                                    </div>
                                    <div className="col-sm-3 mt-2">
                                        <label htmlFor="manualcode_Disc_1">Leaving Date </label>
                                        <div className="input-group" >
                                            <DatePicker
                                                {...register("leaving_date", { required: { value: false, message: "Leaving Date is required" } })}
                                                className="form-control"
                                                format={dateFormatList}
                                                value={LeavingDate}

                                                onChange={handleLeavingDate}
                                            />
                                        </div>
                                        <span className='text-red w-100'>{errors?.leaving_date?.message}</span>
                                    </div>
                                    <div className="col-sm-3 mt-2">
                                        <label htmlFor="manualcode_Disc_1">Birth Date <span style={{ color: 'red' }}>*</span></label>
                                        <div className="input-group" >
                                            <DatePicker
                                                className="form-control"
                                                {...register("birth_date", { required: { value: true, message: "Birth Date is required" } })}
                                                format={dateFormatList}
                                                value={birthDate}
                                                picker="date"
                                                onChange={handleBirthDate}
                                            />
                                        </div>
                                        <span className='text-red w-100'>{errors?.birth_date?.message}</span>
                                    </div>
                                    {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Age</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" disabled className="form-control"
                                                            value={age}
                                                        {...register("age", { required: { value: false, message: "Field is required" } })}
                                                            placeholder="Age"  />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.age?.message}</span>
                                                </div> */}
                                    <div className="col-sm-3 mt-2">
                                        <label htmlFor="exampleInputEmail1">Age</label>
                                        <div className="input-group" data-colorpicker-id={2}>
                                            <input type="number" disabled className="form-control"
                                                value={age}
                                                placeholder="Age"
                                                {...register("age", { required: { value: false, message: "Field is required" } })}
                                            />
                                        </div>
                                        <span className='text-red w-100'>{errors?.age?.message}</span>
                                    </div>
                                    <div className="col-sm-3 mt-2">
                                        <label htmlFor="exampleInputEmail1">Marital Status <span style={{ color: "red" }}>*</span></label>
                                        <select className="form-select"
                                            {...register("marital_status", { onChange: onMarriedChange, required: { value: true, message: "Marital Status is required" } })}
                                        >
                                            <option value={""} selected >Select Any</option>
                                            <option value="Single" >Single</option>
                                            <option value="Married">Married</option>
                                            <option value="UnMarried">UnMarried</option>
                                            <option value="Divorced">Divorced</option>
                                        </select>

                                        <span className='text-red w-100'>{errors?.marital_status?.message}</span>
                                    </div>
                                    {/* {Married === "Married" && (<> */}
                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="manualcode_Disc_1">Anniversary Date </label>
                                            <div className="input-group" >
                                                <DatePicker
                                                    className="form-control"
                                                    format={dateFormatList}
                                                    value={aniversaryDate}
                                                    disabled={Married !== "Married"}
                                                    // value={forPointStartDateValue}
                                                    // disabledDate={disabledDate}
                                                    {...register("anniversary_date", { required: { value: Married === "Married" ? true : false, message: "Point Start Date is required" } })}
                                                    onChange={handleAnniversaryDate}
                                                />
                                            </div>
                                            <span className='text-red w-100'>{errors?.anniversary_date?.message}</span>
                                        </div>
                                    {/* </>)} */}
                                    <div className="col-sm-3 mt-2">
                                        <label htmlFor="exampleInputEmail1">Nationality <span style={{ color: "red" }}>*</span></label>
                                        {/* <select className="form-select" 
                                             {...register("nationality", { required: { value: true, message: "Nationality is required" } })}
                                            >
                                                <option value={""} selected >Select Any</option>
                                                <option value="Indian" >Indian</option>
                                                <option value="Other">Other</option>
                                            </select> */}
                                        <CreatableSelect
                                            {...register("nationality", { required: { value: true, message: "Field is required" } })}
                                            isClearable={() => {
                                                setValueNationality(null)
                                                setValue(null)
                                            }}
                                            components={customCreatableIndicator}
                                            placeholder={'Nationality'}
                                            onMenuOpen={() => NationalityRefetch()}
                                            options={optionsNationality}
                                            onCreateOption={handleCreateNationality}
                                            onChange={handleChangeNationality}
                                            // value={valueNationalityCreatable == null ? valueNationality : valueNationalityCreatable}
                                            value={valueNationalityCreatable == null ? valueNationality == null ? optionsNationality?.filter((obj: any) => obj.value === selectedNationality) : valueNationality : valueNationalityCreatable}


                                        />
                                        <span className='text-red w-100'>{errors?.nationality?.message}</span>
                                    </div>
                                    <div className="col-sm-3 mt-2">
                                        <label htmlFor="exampleInputEmail1">Religion <span style={{ color: "red" }}>*</span></label>
                                        {/* <select className="form-select"  
                                            {...register("religion", { required: { value: true, message: "Religion is required" } })}
                                            >
                                                <option value={""} selected >Select Any</option>
                                                <option value="Hindu" >Hindu</option>
                                                <option value="Muslim">Muslim</option>
                                                <option value="Christian">Christian</option>
                                                <option value="Sikh">Sikh</option>
                                            </select> */}
                                        <CreatableSelect
                                            {...register("religion", { required: { value: true, message: "Field is required" } })}
                                            isClearable={() => {
                                                setValueReligion(null)
                                                setValue(null)
                                            }}
                                            components={customCreatableIndicator}
                                            placeholder={'Religion'}
                                            onMenuOpen={() => ReligionRefetch()}
                                            options={optionsReligion}
                                            onCreateOption={handleCreateReligion}
                                            onChange={handleChangeReligion}
                                            // value={valueReligionCreatable == null ? valueReligion : valueReligionCreatable}
                                            value={valueReligionCreatable == null ? valueReligion == null ? optionsReligion?.filter((obj: any) => obj.value === selectedReligion) : valueReligion : valueReligionCreatable}



                                        />
                                        <span className='text-red w-100'>{errors?.religion?.message}</span>
                                    </div>
                                    <div className="col-sm-3 mt-2">
                                        <label htmlFor="exampleInputEmail1">Caste <span style={{ color: "red" }}>*</span></label>
                                        {/* <select className="form-select" 
                                             {...register("caste", { required: { value: true, message: "Caste is required" } })}
                                             >
                                                <option value={""} selected >Select Any</option>
                                                <option value="General" >General</option>
                                                <option value="ST">ST</option>
                                                <option value="SC">SC</option>
                                                <option value="OBC">OBC</option>
                                                <option value="Other">Other</option>
                                            </select> */}
                                        <CreatableSelect
                                            {...register("caste", { required: { value: true, message: "Field is required" } })}
                                            isClearable={() => {
                                                setValueCaste(null)
                                                setValue(null)
                                            }}
                                            components={customCreatableIndicator}
                                            placeholder={'Caste'}
                                            onMenuOpen={() => CasteRefetch()}
                                            options={optionsCaste}
                                            onCreateOption={handleCreateCaste}
                                            onChange={handleChangeCaste}
                                            // value={valueCasteCreatable == null ? valueCaste : valueCasteCreatable}
                                            value={valueCasteCreatable == null ? valueCaste == null ? optionsCaste?.filter((obj: any) => obj.value === selectedCaste) : valueCaste : valueCasteCreatable}


                                        />

                                        <span className='text-red w-100'>{errors?.caste?.message}</span>
                                    </div>
                                    <div className="col-sm-3 mt-2">
                                        <label htmlFor="exampleInputEmail1">Blood Group <span style={{ color: "red" }}>*</span></label>
                                        {/* <select className="form-select"  
                                            {...register("blood_group", { required: { value: true, message: "Blood Group is required" } })}
                                            >
                                                <option value={""} selected >Select Any</option>
                                                <option value="O+" >O+</option>
                                                <option value="O-">O-</option>
                                                <option value="A+">A+</option>
                                                <option value="A-">A-</option>
                                                <option value="B+">B+</option>
                                                <option value="B-">B-</option>
                                                <option value="AB+">AB+</option>
                                                <option value="AB-">AB-</option>
                                            </select> */}

                                        <CreatableSelect
                                            {...register("blood_group", { required: { value: true, message: "Field is required" } })}
                                            isClearable={() => {
                                                setValueBloodGroup(null)
                                                setValue(null)
                                            }}
                                            components={customCreatableIndicator}
                                            placeholder={'Blood Group'}
                                            onMenuOpen={() => BloodGroupRefetch()}
                                            options={optionsBloodGroup}
                                            onCreateOption={handleCreateBloodGroup}
                                            onChange={handleChangeBloodGroup}
                                            // value={valueBloodGroupCreatable == null ? valueBloodGroup : valueBloodGroupCreatable}
                                            value={valueBloodGroupCreatable == null ? valueBloodGroup == null ? optionsBloodGroup?.filter((obj: any) => obj.value === selectedBloodGroup) : valueBloodGroup : valueBloodGroupCreatable}


                                        />

                                        <span className='text-red w-100'>{errors?.blood_group?.message}</span>
                                    </div>



                                    <div className="col-sm-3 mt-2">
                                        <label >Re-Mark</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control"

                                                placeholder={'Re-Mark'}


                                                {...register('remark', { required: { value: false, message: "Field is required" } })}
                                            //  {...register("document_number", { required: { value: true, message: "Field is required" } })} 
                                            />
                                            <div className="input-group-append" >
                                                <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                /></span>

                                            </div>
                                        </div>
                                        <span className='text-red w-100'>{errors?.remark?.message}</span>
                                    </div>





                                </div>
                            </div>
                        </div>

                        <div className="row d-flex  ">
                            <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                <div className="row d-flex ">
                                    <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                        <button
                                            type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                            onClick={handleSubmit(onSubmit)}
                                        >Submit</button>
                                        <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetail")}>Cancel</button>

                                        {currentPath === '/addCustomerDetails' ?
                                            <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => setCurrentStep(currentStep - 1)}>Back</button>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
            {/* </div> */}
            {/* </section> */}



            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />

            <Modal show={openEditDateModel} onHide={() => setOpenEditDateModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" style={{ fontSize: "13px" }}
                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                />
                                {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditDateModel(false) }}>Close</button>
                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>
                </Modal.Footer>
            </Modal>

            
{show && (
          <Mui_Toaster
            message={`Personal Updated Successfully`}
            severity={"success"}
            Isopen={true}
          />
        )}
        </div>
        // </>
    )
}

export default Edit_Personal_Details


