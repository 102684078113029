import { useEffect, useRef, useState } from "react";
import "../../components/Header/header.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import { useCreate } from "../../hooks/Api";
import { Alert } from "../../components/Model/Model";
import useFocusOnEnter from "../../components/focus";
import Select from 'react-select';
import { useFetchUomNameDropDown, useFetchUnit } from "../../hooks/DropDown_Api";
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'

import Modal from "react-bootstrap/Modal";
import Actions, { Deleteforce, Deletesingle } from "../../components/Cell/Actions";
import { useDelete, useDeletemforce } from "../../hooks/delete";
import Custom_Actions_Buttons from "../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../hooks/deletemultiple";
import { SimpleInput_Edit } from "../../components/Dynamic_Form_Models/SimpleInput_Edit";
import CreatableSelect from "react-select/creatable";
import Button from "react-bootstrap/esm/Button";


const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}

interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});


const Add_Capacity = () => {
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_CAPACITY_API?.toString()

    const { mutate: gst, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue, getValues }: any = useForm({ mode: 'onChange' });


    const [unitnameValue, setUnitNameValue]: any = useState()
    const [baseUnitValue, setBaseUnitValue]: any = useState()





    useEffect(() => {
        if (data) {

            reset(data)
        }
    }, [data]);

    const handleReset = () => {
        reset()
        setUnitNameValue(null)
        setBaseUnitValue(null)
        setAbbreviation("none")
    }

    const onSubmit = (branddetail: any) => {


        if (baseUnit === "yes") {
            delete branddetail.baseUnit

        }

        const formData = new FormData();
        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else {
                formData.append(key, formvalue[0])
            }
            if (typeof value === 'object' && value !== null) {
                formData.append(key, "")
            }
        }
        gst(formData);

    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listUom', { state: { showToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    const handleChangeCapital = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }


    // const[disable,setdisable]=useState(true)


    const isbaseunit = document?.getElementById("isbase")
    const baseunit = document?.getElementById("base")

    const keyup = () => {


    }

    const apinameForUnit = process.env.REACT_APP_UNIT_SEARCH_FILTER?.toString()


    const { mutate: unitMutate, data: unitSearchData, }: any = useCreate(apinameForUnit)

    const [type, setType] = useState()



    const handleType = (e: any) => {

        unitMutate({ "type": e.target.value })
        setType(e.target.value)



    }


    const [unitOptions, setUnitOptions]: any = useState([])

    //  const {data:unitData,refetch:unitRefetch,isSuccess:unitSuccess} = useFetchUnit()


    //  useEffect(()=>{
    //      setUnitOptions(unitData)
    //  },[unitData])

    useEffect(() => {
        const ok = unitSearchData?.results?.map((data: any) => {
            return (
                {
                    label: data?.name,
                    value: data?.id,
                    abbreviation: data?.abbreviation
                }
            )
        })
        setUnitOptions(ok)
    }, [unitSearchData])

    useEffect(() => {
    }, [unitOptions])


    const [abbreviation, setAbbreviation] = useState("none")


    const handleChangeUnitName = (e: any) => {

        setValue("name", e?.value)

        setAbbreviation(e?.abbreviation)
        setValue("abbreviation", e?.abbreviation)
        setUnitNameValue(e)

    }

    useEffect(() => {


    }, [abbreviation])

    const apinameForUom = process.env.REACT_APP_UOM_SEARCH_FILTER?.toString()

    const [baseUnit, setBaseUnit] = useState("yes")

    const { mutate: baseMutate, data: baseData, }: any = useCreate(apinameForUom)



    const handleBaseUnit = (e: any) => {

        setBaseUnit(e.target.value)

        if (e.target.value == "no") {
            baseMutate({ "type": type, "is_base_unit": "yes" })
        }


    }

    //  useEffect(()=>{

    //     if(baseUnit=="yes"){
    //         unitMutate({"is_base_unit":unitOptions?.is_base_unit})

    //     }

    //  },[baseUnit])

    const [baseUnitOptions, setBaseUnitOptions] = useState([])

    console.log(baseUnitOptions, "baseUnitOptions");



    useEffect(() => {
        const ok = baseData?.results?.map((data: any) => {
            if (data?.is_base_unit == "yes")
                console.log(data);
            return (
                {

                    label: data?.name?.name,
                    value: data?.name?.id,
                    // abbreviation:data?.abbreviation
                }
            )
        })
        setBaseUnitOptions(ok)
    }, [baseData])


    const handleChangeBaseUnit = (e: any) => {
        setValue("baseUnit", e?.value)
        setBaseUnitValue(e)

    }

    const apinameForUomName = process.env.REACT_APP_UOM_NAME_API?.toString()
    const { mutate: mutateUom, data: dataUom, isError: UomIsError, isLoading: UomLoading, isSuccess: UomSuccess, error: UomError }: any = useCreate(apinameForUomName)
    const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueUom, setValueUom]: any = useState();
    const [optionsUom, setOptionsUom]: any = useState([]);
    const [valueUomCreatable, setValueUomCreatable] = useState<Option | null>();

    const { data: UomData, refetch: UomRefetch } = useFetchUomNameDropDown()

    useEffect(() => {
        setOptionsUom(UomData)
    }, [UomData])




    const handleChangeUom = (e: any) => {

        UomRefetch()

        setApiName(apinameForUomName)
        setDeleteApiName(apinameForUomName)
        setEditId(e?.value)
        setEditData(UomData)
        setRenderKey(prevKey => prevKey + 1);

        setValueUom(e)

        setValue("uom", `${e?.value}`)
        setValueUomCreatable(null)
    }





    const handleCreateUom = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateUom({ name: newOption.name, status: newOption.status })
            setOptionsUom((prev: any) => [...prev, newOption]);
            setValueUomCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (UomSuccess && optionsUom) {
                await UomRefetch();
                setValue("uom", UomData[0]?.value)
            }
        })();

        return () => {
        };
    }, [UomSuccess, UomData]);



    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
    } = useDelete();
    const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
    } = useDeletemforce();




    const handleRefetch = () => {

        if (apiName === apinameForUomName) {
            UomRefetch()
        }



    }


    useEffect(() => {

        if (OpenEditDropDownModel && valueUom && apiName === apinameForUomName) {
            setValueUom(null)
            setValueUomCreatable(null)
        }








        if (deletesucess && apiName === apinameForUomName) {
            setValueUom(null)
            setValueUomCreatable(null)
        }






    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (


        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
            <span>{label}</span>
            <div style={{ float: "right", justifyContent: "end" }}>



                {isExist ?

                    <Custom_Actions_Buttons value={value}
                        apiname={deleteApiName}
                        mutate={singleDelete}
                        // masterName={addButtonName}
                        deleteid={deleteid}
                        handleDeletedforce={handleDeletedforce}
                        closereconfirmdelete={closereconfirmdelete}
                        deletemodalask={deletemodalask}
                        setEditOpen={setOpenEditDropDownModel}
                    // permissionClass={permissionClass}
                    />
                    : ""}

            </div>
        </div>


    )


    const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption

    }



    useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
        return () => {
            setDeleteid(undefined);
        };
    }, []);
    const handleConfirmDelete = () => {
        setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
    };

    const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
    };
    useEffect(() => {
        if (forcefullysuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullyerror) {
            setShow(true);
            setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullydeleteerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
    ]);

    useEffect(() => {
        if (deleteMultipleSuccess) {
            const showdepenedancymsg = deleteMultiple?.data?.data;
            const dependacyfound = showdepenedancymsg?.filter((color: any) => {
                if (
                    color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
                ) {
                    return color;
                }
            });
            if (dependacyfound?.length === deleteMultipleid?.length) {
                deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
            } else {
                setConfirmforcemodal(true);
            }
        } else if (deleteMultipleIsError) {
            setShow(true);
            setshowmessage("danger");
        } else if (deletesucess) {
            if (deletedata.data?.data?.length > 0) {
                setConfirmforcemodal(true);
            } else if (deleteid) {
                setShow(true);
                setshowmessage("success");
                deleteforce({ deleteid, apiname: deleteApiName });
            }
        }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

    const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
    };


    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>U.O.M</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Masters</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listUom" className="text-decoration-none text-black">U.O.M</Link></li>
                                    <li className="breadcrumb-item">Add U.O.M</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">

                            <form ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>

                                    {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">



                                                {/* <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">U.O.M Type<span style={{ color: 'red' }}>*</span></label>
                                                    <Select  {...register("type", { required: { value: true, message: "U.O.M Type is required" } })}
                                                        placeholder={'U.O.M Type'}
                                                        // options={optionsDepartment}  
                                                        // onChange={handleChange}
                                                        />

                                
                                                    <span className='text-red w-100'>{errors?.type?.message}</span>

                                                </div> */}




                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="status">U.O.M Type<span style={{ color: 'red' }}>*</span></label>
                                                    <select id="isbase" className="form-select"  {...register("type",
                                                        {
                                                            required: { value: true, message: "Type is required" }

                                                        })}
                                                        onChange={handleType}
                                                    >
                                                        <option value="" selected>Select Any </option>
                                                        <option value={"volume"}>Volume</option>
                                                        <option value={"weight"}>Weight</option>
                                                        <option value={"length"}>Length</option>
                                                        <option value={"packaging"}>Packaging</option>



                                                    </select>
                                                    <span className='text-red w-100'>{errors?.type?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Unit Name<span style={{ color: 'red' }}>*</span></label>
                                                    <Select  {...register("name", { required: { value: true, message: "Unit Name is required" } })}
                                                        placeholder={'Unit Name'}
                                                        options={unitOptions}
                                                        components={customIndicator}
                                                        onChange={handleChangeUnitName}
                                                        value={unitnameValue}
                                                        maxMenuHeight={120}
                                                    />


                                                    <span className='text-red w-100'>{errors?.name?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name">Abbreviation<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="text" className="form-control" disabled id="name"

                                                            placeholder="Abbreviation"
                                                            {...register("abbreviation", { required: { value: false, message: "Abbreviation is required" } })}

                                                            value={abbreviation} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.abbreviation?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name">U.O.M <span style={{ color: 'red' }}>*</span></label>
                                                    {/* <div className="input-group" >
                                                        <input type="text" className="form-control" 
                                                        placeholder="U.O.M" {...register("uom", { onChange: handleChangeCapital, required: { value: false, message: "U.O.M is required" } })}/>
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>

                                                        </div>
                                                    </div> */}

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueUom(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("uom", { required: { value: true, message: "U.O.M is required" } })}
                                                        placeholder={'Select U.O.M'}
                                                        // menuIsOpen={true}

                                                        components={customCreatableIndicator}
                                                        options={optionsUom}
                                                        onMenuOpen={() => UomRefetch()}
                                                        onCreateOption={handleCreateUom}
                                                        onChange={handleChangeUom}
                                                        value={valueUomCreatable == null ? valueUom : valueUomCreatable}


                                                    />
                                                    <span className='text-red w-100'>{errors?.uom?.message}</span>
                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="status">Is Base Unit <span style={{ color: 'red' }}>*</span></label>
                                                    <select id="isbase" className="form-select" {...register("is_base_unit",
                                                        {
                                                            required: { value: true, message: "Base Unit is required" }

                                                        })}

                                                        onChange={handleBaseUnit}
                                                    >
                                                        <option value={""} selected>Select Any </option>
                                                        <option value={"yes"}>Yes</option>
                                                        <option value={"no"}>No</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.is_base_unit?.message}</span>
                                                </div>


                                                {baseUnit == "no" &&


                                                    <div className="col-sm-3 mt-2">
                                                        <label htmlFor="exampleInputEmail1">Base Unit<span style={{ color: 'red' }}>*</span></label>
                                                        <Select id="base"  {...register("baseUnit", { required: { value: true, message: "Base Unit is required" } })}
                                                            placeholder={'Base Unit'}
                                                            options={baseUnitOptions}
                                                            components={customIndicator}
                                                            onChange={handleChangeBaseUnit}
                                                            value={baseUnitValue}
                                                            maxMenuHeight={120}

                                                        />


                                                        <span className='text-red w-100'>{errors?.baseUnit?.message}</span>

                                                    </div>}


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name">Conversation Ratio<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number" className="form-control" id="name" placeholder="Conversation Ratio"
                                                            {...register("conversationRatio", { onChange: handleChangeCapital, required: { value: true, message: "Conversation Ratio is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.conversationRatio?.message}</span>
                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="status">Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status",
                                                        {
                                                            required: { value: true, message: "Status is required" }

                                                        })}>
                                                        <option value="" selected>Select Any </option>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"inactive"}>Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>
                                                </div>



                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button"
                                                        disabled={isLoading}
                                                        className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{
                                                            borderColor: "rgb(0, 54, 101)",
                                                            background: "rgb(0, 54, 101)",
                                                            color: "white",
                                                        }}
                                                        onClick={handleSubmit(onSubmit)}
                                                    >
                                                        Submit
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3"
                                                        style={{
                                                            borderColor: "blue",
                                                            background: "blue",
                                                            color: "white",
                                                        }}
                                                        onClick={handleReset}
                                                    >
                                                        Reset
                                                    </button>

                                                    <button
                                                        type="button"
                                                        className=" border-1 rounded  px-4 py-1 mx-3 mt-2"
                                                        style={{
                                                            borderColor: "red",
                                                            background: "red",
                                                            color: "white",
                                                        }}
                                                        onClick={() => navigate("/listUom")}
                                                    >
                                                        Cancel
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
            <Modal
                show={deleteConfirm}
                onHide={() => setDeleteConfirm(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete {""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {" "}
                        <span className="text-center">Sure you want to delete it?</span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setDeleteConfirm(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={handleMultipleDeleted}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Deleteforce
                key={renderKey}
                handleDeletedforce={handleDeletedforce}
                reconfirmdelete={confirmforcemodal}
                masterName={""}
                closereconfirmdelete={closereconfirmdelete}
                referance={
                    deletesucess
                        ? deletedata
                        : ""
                }
                apiname={apiName}
            />
            <Deletesingle
                key={renderKey}
                confirmmodal={deleteConfirmsingle}
                confirmmodalclose={confirmmodalclose}
                masterName={""}
                confirmmodalsubmit={confirmmodalsubmit}
                apiname={apiName}
            />

            <SimpleInput_Edit key={renderKey}
                refetchForm={handleRefetch}
                open={OpenEditDropDownModel}
                setOpen={setOpenEditDropDownModel}
                id={editId}
                // superAccountId={superAccountId} 
                // optionsSuperAccount={optionsSuperAccount}
                // isSuperAccount={isSuperAccount}
                apiname={apiName} />
        </div>
    )
}

export default Add_Capacity