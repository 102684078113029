
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../../components/Table/column";
import { updateColor, useFetchColors, useUpdateSingleColor } from "../../../hooks/color/color";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../../components/Cell/Status";
import Actions from "../../../components/Cell/Actions";
import { updateBrand, useFetchBrands } from "../../../hooks/brand";
import noImage from "../../images/noImage.png"
import { Update, useFetch } from "../../../hooks/Api";
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Tooltip } from "@mui/material";

interface Data {
  selection: any
  country: any;
  district: any;
  state: any;
  city_name:any
  taluka:any
  village_name:any
  pincode:any
  code:any

  created_at:any
  created_by:any
  updated_by:any
  updated_at:any

  status: any;
  actions: any;
}



export const titleofheaders=[
  {
    id: `srno`,
    Header: `Sr No`,
  },
  {
    id: `code`,
    Header: `Code`,
  },
  {
    id: `pincode`,
    Header: `Pin Code`,
  },
 
  {
    id: `village_name`,
    Header: `Village Name`,


  },
  {
    id: `city_name`,
    Header: `City Name`,


  },
  {
    id: `taluka`,
    Header: `Taluka Name`,


  },

  {
    id: `district`,
    Header: `District Name`,
  },
  {
    id: `state`,
    Header: `State Name`,
  },
  {
    id: `country`,
    Header: `Country Name`,
  },

      {
        id: 'status',
        Header: 'Status',
    
     
      },

   {
    id: 'created_at',
    Header: `Created At`,
  },


  {
    id: 'created_by',
    Header: `Created By`,

 
  },
  {
    id: 'updated_at',
    Header: `Updated At`,

  },
  {
    id: 'updated_by',
    Header: `Updated By`,

  },

  

 
]





export const Columns_City: any = [



 
  {
    id: `srno`,
    Header: () => <div style={{ display: "inline" }}>Sr <br /> No.</div>,
    sticky:"left",
  },
  {
    id: `code`,
    Header: () => <div style={{display:"inline"}}>City<br/>Code</div>,
    accessor: `code`,
    sticky:"left",

  },
  {
    id: `pincode`,
    Header: () => <div style={{display:"inline"}}>Pin Code<br/>Number</div>,
    accessor: `pincode`,
    Cell: ({ row }: any) => {
      let pincode = row.original?.pincode?.map((e:any)=>{
        return e?.pincode
      })
      console.log(pincode.length,"pincode.length",pincode);
      
      return (
        <>
          <Tooltip title={<p style={{ fontSize: '15px', color: 'white' }}>{pincode?.join(',')}</p>} placement="top" arrow >
            <div>
              {pincode.length >=2 ? pincode?.join(',')?.slice(0, 14)?.concat('...'):pincode?.join(',')}
            </div>
          </Tooltip>

        </>
      )
}
  },



  {
    id: `village_name`,
    Header: () => <div style={{display:"inline"}}>Village<br/>Name</div>,
    accessor: `village_name`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.village_name?row.original.village_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
        </div>
      )
}
  },
  {
    id: `city_name`,
    Header: () => <div style={{display:"inline"}}>City<br/>Name</div>,
    accessor: `city_name`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.city_name?row.original.city_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
        </div>
      )
}
  },
  
 
  
  {
    id: `taluka`,
    Header: () => <div style={{display:"inline"}}>Taluka<br/>Name</div>,
    accessor: `taluka`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.taluka?.name?row.original.taluka?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
        </div>
      )
}
  },
  
  {
    id: `district`,
    Header: () => <div style={{display:"inline"}}>District <br/> Name</div>,
    accessor: `district`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.district?.name}
        </div>
      )
}
  },
  {
    id: `state`,
    Header: () => <div style={{display:"inline"}}>State <br/> Name</div>,
    accessor: `state`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.state?.name}
        </div>
      )
}
  },
  {
    id: `country`,
    Header: () => <div style={{display:"inline"}}>Country <br/>Name</div>,
    accessor: `country`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.country?.name}
        </div>
      )
}
  },

  {
    id: `country_code_mobile`,
    Header: () => <div style={{display:"inline"}}>Country <br/>Mobile Code</div>,
    accessor: `country_code_mobile`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.country?.country_code_mobile}
        </div>
      )
}
  },


  {
    id: `remark`,
    Header: () => <div style={{display:"inline"}}>Re-Mark</div>,
    accessor: `remark`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.remark?row.original.remark:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
        </div>
      )
}
  },
  {
    id: 'status',
    Header: 'Status',
    accessor:'status',

 
  },
  
 
  {
    id: 'created_at',
    Header: () => <div style={{display:"inline"}}>Created<br/>At</div>,
    accessor:'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{display:"inline"}}>Created<br/>By</div>,
    accessor:'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username

 
  },
  {
    id: 'updated_at',
    Header: () => <div style={{display:"inline"}}>Updated<br/>At</div>,
    accessor:'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{display:"inline"}}>Updated<br/>By</div>,
    accessor:'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },

 

  {
    id: 'Actions',
    Header: 'Actions',

 
  },
  {
    id: 'Selection',
    // sticky:"left",
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_City, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows} = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CITY_API}/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]
