import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CustomController from "./CustomController";
import Input from "./Input";
import Error from "../components/Form Component/error";

const Custom_Form = () => {


  // const [onChangeValue,setOnChangeValue] = useState()

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
    setValue,
    control,
  }: any = useForm();

  const dynamicForm: any = {
    firstName: {
      label: "First Name",
      type: "text",
      placeholder: "Enter your first name",
      defaultValue: "",
      rules: {
        required: true,
      },
      // onchange:((e:any)=> {
      //   setOnChangeValue(e)
      //   setValue("firstName",e)
      //   console.log("onChangeValue",onChangeValue)
      // })
    },
    lastName: {
      label: "Last Name",
      type: "text",
      placeholder: "Enter your last name",
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    gender: {
      label: "Gender",
      type: "radio",
      options: ["male", "female"],
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    profession: {
      label: "Profession",
      type: "dropdown",
      options: [{
          label: "Front ",
          value: "front"
        }, 
        {
          label: "Back ",
          value: "back"
        }, 
      ],
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    Hobbies: {
      label: "Hobbies",
      type: "react_dropdown",
      options: [{
          label: "Front End Dev",
          value: "front"
        }, 
        {
          label: "Back End Dev",
          value: "back"
        }, 
      ],
      defaultValue: "",
      rules: {
        required: true,
      },
    },
    FromDate: {
      label: "Date",
      type: "datepicker",
      defaultValue: "",
      rules: {
        required: true,
      },
    },

    Time: {
      label: "Time",
      type: "timepicker",
      defaultValue: "",
      rules: {
        required: false,
      },
    },
    agree: {
      type: "checkbox",
      label: "",
      checkboxLabel: "I hereby agree to the terms.",
      defaultValue: false,
      rules: {
        required: true,
      },
    },
  };

  const onSubmit = (data: any) => {
  }

  const formInputs = Object.keys(dynamicForm).map((e: any) => {
    const { rules, defaultValue, label,type } = dynamicForm[e];
    

    return (
      <div key={e} className="col-sm-3 mt-2">
        {label !== "" ? <label>{label}{(rules.required ? <span style={{ color: 'red' }}>*</span> : "")}</label> : ""}
        <Controller
          name={e}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div style={type=="radio"?{display:"flex"}:{}}>
              <Input
                value={field.value}
                onChange={field.onChange}
                {...dynamicForm[e]}
              />
            </div>
          )}
        />
        {errors[e] && <Error>{label} is required</Error>}
      </div>
    );
  });


    const handleReset=()=>{
      reset()
    }
  return (
    <>
      <div className="content-wrapper">
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>Form</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item active">Form</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">



            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>                                

                                    {/* {show && showmessage === "success" && <Alert color={showmessage} message="Brand successfully Updated" />} */}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                              
                                             
                                            {formInputs}

                                            
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                <a type="button" className="btn border rounded text-white px-4 mt-2 mx-3" onClick={handleSubmit(onSubmit)}>Submit</a>

<button type="button" className="btn border-1 rounded  border-1 px-4 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>







        
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Custom_Form;
