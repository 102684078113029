import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import logo from "../../images/logo.png"
import "./Quotation_Accept.css"
import { Link, useParams, useSearchParams } from 'react-router-dom';
import Axiosinstance from '../../hooks/Interceptor';
const Quotation_Accept = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const[message,setMessage] = useState('')

  useEffect(()=>{
    if(searchParams.get("uid")){
    Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}salesUtils/acceptQuotation/?code=${searchParams.get("uid")}`).then((data:any)=>setMessage(data?.data?.message))
    }
  },[])

  return (
    <>
    <div className='d-flex justify-content-center'>
        <a className="navbar-brand d-flex justify-content-center align-items-center" href="#">
            <img src={logo}
                 height={80}
                 width={80}
                 alt="MDB Logo"
                 loading="lazy" />
                  {/* <h2 className="brand-text font-weight-light">
              Quantum<b>Bot</b>
            </h2> */}
        </a>
    </div>

    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
<section className="mail-seccess section">
	<div className="container">
		<div className="row">
			<div className="col-lg-6 offset-lg-3 col-12">
				<div className="success-inner">
					<h1><i className="fa fa-envelope"></i><span>{message}</span></h1>
					{/* <p>Aenean eget sollicitudin lorem, et pretium felis. Nullam euismod diam libero, sed dapibus leo laoreet ut. Suspendisse potenti. Phasellus urna lacus</p> */}
					<Link to="/salesDashboard" className="btn btn-primary btn-lg">Go Home</Link>
				</div>
			
			</div>
		</div>
	</div>
</section>
</>
  )
}

export default Quotation_Accept