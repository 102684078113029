import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css'
import App from './App';
import "./index.css"
import { Provider } from 'react-redux';
import store from './redux/store';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
  
ReactDOM.render(

  

  <Provider store={store}>
  <I18nextProvider i18n={i18next} defaultNS={'translation'}>
    
            <App/>
        </I18nextProvider>
  </Provider>,
 
  document.getElementById('root')


  
);