
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../../components/Cell/Status";
import Actions from "../../../components/Cell/Actions";
// import { updateBrand, useFetchBrands } from "../../hooks/brand";
// import noImage from "../../images/noImage.png"
import { useFetchGst, useUpdateGst } from "../../../hooks/GST";


interface Data {
    selection: any
    designation_code:any
    name:any
    use_employee_code_and_surname_and_name:any
    employee_qty:any
    status:any

    created_at:any
    created_by: any
    updated_by: any
    updated_at: any
    //   status: any;
    actions: any;
}


export const titleofheaders=[
    {
        id: `srno`,
        Header: `Sr No`,
      },


      {
        id: `designation_code`,
        Header: `Code`,
      },
      {
        id: `designation`,
        Header: `Designation`,
      },
      {
        id: 'status',
        Header: 'Status',
      },
    
      {
        id: 'created_at',
        Header: `Created At`,
      },
    
    
      {
        id: 'created_by',
        Header: `Created By`,
    
     
      },
      {
        id: 'updated_at',
        Header: `Updated At`,
    
      },
      {
        id: 'updated_by',
        Header: `Updated By`,
    
      },
     
  ]
  



export const Columns_Sales_Designation: any = [



   
    {
        id: `srno`,
        Header: () => <div style={{display:"inline"}}>Sr.<br/>No.</div>,
        sticky:"left",
    },

    {
        id: `designation_code`,
        Header: () => <div style={{display:"inline"}}>Designation<br/>Code</div>,
        accessor: 'designation_code',
        sticky:"left",
        Cell: ({ row }: any) => (row.original.designation_code)
    },

    {
        id: 'designation',
        Header: () => <div style={{display:"inline"}}>Designation<br/>Name</div>,
        accessor: 'designation',
        // Cell: ({ row }: any) => (row.original.code? row.original.code : "-")


    },

    {
        id: 'use_employee_code_and_surname_and_name',
        Header: () => <div style={{display:"inline"}}>Use Employee Code <br/>Surname & Name</div>,
        accessor: 'use_employee_code_and_surname_and_name',
        Cell: ({ row }: any) => {
           let employee =  row.original.use_employee_code_and_surname_and_name?.map((e:any)=>{
            return e.name
           })
           // <div>
           if(!employee) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
           return(
             employee?.join(",")
            // </div>
          )
        
        }


    },

    {
        id: 'employee_qty',
        Header: () => <div style={{display:"inline"}}>Use Employee<br/>Qty</div>,
        accessor: 'employee_qty',
        Cell: ({ row }: any) => (row.original.name? row.original.name : <span style={{ color: 'red',fontSize:"20px" }}>****</span>)


    },
   
    



    {
        id: 'created_at',
        Header: () => <div style={{display:"inline"}}>Created<br/>At</div>,
        accessor:'created_at',
        Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
      },
    
    
      {
        id: 'created_by',
        Header: () => <div style={{display:"inline"}}>Created<br/>By</div>,
        accessor:'created_by',
        Cell: ({ row }: any) => row.original.created_by?.username
    
     
      },
      {
        id: 'updated_at',
        Header: () => <div style={{display:"inline"}}>Updated<br/>At</div>,
        accessor:'updated_at',
        Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
      },
      {
        id: 'updated_by',
        Header: () => <div style={{display:"inline"}}>Updated<br/>By</div>,
        accessor:'updated_by',
        Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
      },
    



    {
        id: 'status',
        Header: 'Status',
        accessor:'status',
    
     
      },


    {
        id: 'Actions',
        Header: 'Actions',


    },

    {
        id: 'Selection',
        Header: ({ getToggleAllRowsSelectedProps }: any) => {
            const [data, setData] = useState([])

            const columns: any = useMemo(() => Columns_Sales_Designation, [])
            const defaultColumn: any = useMemo(() => {
                return {
                    Filter: Columnlevel
                }
            }
                , [])

            const tableInstance: any = useTable({
                columns,
                data: data,
                defaultColumn,

            },


            )
            const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
            let { selectedFlatRows } = tableInstance
            const { } = useMutation((deleteid: any) => {
                const temp_data = {
                    id: deleteMultipleid
                }
                return axios.post(`${process.env.REACT_APP_BASE_URL}designation/deletelist/`, temp_data,
                    {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    });
            });
            return (
                <>
                    <div>
                        <Checkbox

                            onClick={() => {
                                setDeleteMultipleid(() => {
                                    const temp = selectedFlatRows?.map((e: any) => {
                                        return e.original.id;
                                    });
                                    return temp;
                                });
                            }}

                            {...getToggleAllRowsSelectedProps()} />
                    </div>
                </>
            );
        },

        Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
            // Add your onClick function here
        }} />
    },
]



