import { useEffect, useRef, useState } from "react";
import "../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./addColor.css"
import { Alert } from "../../components/Model/Model";
import { RiPencilLine } from "react-icons/ri";
import { useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../hooks/Api";
import useFocusOnEnter from "../../components/focus";
import { Deletesinglewithdependancy } from "../../components/modal";
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import Restricted from "../../Roles/Restricted";
const ntc = require('@yatiac/name-that-color');
const toHex = require('colornames')

const Edit_Color = () => {
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()
    const { id } = useParams()
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()
    const [openEditModel, setOpenEditModel] = useState(false)
    const [expirationDate, setExpirationDate] = useState()
    const apiname = process.env.REACT_APP_COLOR_API?.toString()
    const { data } = useFetchSingle(id, apiname)
    const { register, handleSubmit, setError, formState: { errors ,isDirty,dirtyFields}, reset, setValue } = useForm();



    const { mutate, isSuccess, isError, error, data: updated_response,isLoading }: any = useUpdateSingle()
    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()

    const onSubmit = (data: any) => {


        delete data.cpicker

        let ok = {
            id: id,
            apiname: apiname,
            data: data
        }
        mutate(ok)
        setValuesofform(ok)
    }

    useEffect(() => {
        if (data) {
            reset(data)
            setValue("hex", data?.hex)
            setValue("name", data?.name)
            setValue("status", data?.status)
            setValue("cpicker", data?.hex)

        }

    }, [data]);

    const [colorname, setColorname]: any = useState("");
    const [cn, setCn] = useState()



    const onColorPickerInfoChange = (color: any) => {

    
        // const ntc = require('@yatiac/name-that-color');
        // setColorname(ntc(color.target.value).colorName);
        // setCn(color.target.value)

        if (color.target.value.includes('#')) {
            // setValue("name", value, { shouldDirty: true })
            // setColorname(ntc(color.target.value).colorName);
            setValue("name", ntc(color.target.value).colorName,{ shouldDirty: true })
            setValue("hex", color.target.value,{ shouldDirty: true })
            setCn(ntc(color.target.value).closestColor)
            setValue("cpicker", ntc(color.target.value).closestColor,{ shouldDirty: true })
        }

        if (color.target.placeholder.includes("Name")) {
            // setColorname(color.target.value);
            if (toHex(color.target.value)) {
                // setColorname(toHex(color.target.value))
                setValue("hex", toHex(color.target.value),{ shouldDirty: true })
                setCn(toHex(color.target.value))
                setValue("cpicker", toHex(color.target.value),{ shouldDirty: true })

            }
            else {
                color.target.value = color.target.value.toLowerCase()
                    .split(' ')
                    .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(' ');
                setColorname("")
            }
        }
        if (color.target.placeholder.includes("Code")) {
            setCn(ntc(color.target.value).closestColor)
            setValue("cpicker", ntc(color.target.value).closestColor,{ shouldDirty: true })
            setValue("hex", color.target.value,{ shouldDirty: true })
        }






        // setValue("name", colorname)
        // setValue("hex", cn)

    };

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess || yessuccess || nosuccess) {
            setValuesofform(null)
            setShow(true)
            navigate('/listColor', { state: { showEditToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, isSuccess, yessuccess, isError,error,
        nosuccess])
    useEffect(() => {
        if (isError) {
            if (error.response.status === 405) {
                setConfirmmodal(true)
            }
        }
        else if (isError || yeserror || noerror) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isError, yeserror, noerror])

    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);


    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }
    const noupdatemodal = () => {
        setOpenEditModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {


        let ok = {
            id: id,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditModel(false)
    }



    return (
        <div>
            <Restricted
                to={`${apiname?.split("/").at(-1)}.change_${apiname?.split("/").at(-1)}`}
                fallback={`You are not authorized to Edit ${apiname
                ?.split("/")
                .at(-1)}`}
            >
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit Color</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Product Details</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Color</Link></li>
                                    <li className="breadcrumb-item active">Edit Color</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}

                                {/* {show && showmessage === "success" && <Alert color={showmessage} message="Color successfully Updated" />} */}
                                {/* {show && showmessage === "danger" && error?.response.data?.message.name == "color with this Color Name already exists."?<Alert color={showmessage} message="Color Already Exist"/>:""} */}
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <form ref={focus}
                                            onKeyUp={event => onEnterKey(event)}
                                            onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row d-flex justify-content-lg-start justify-content-sm-around justify-content-md-center">
                                            <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Company Color Name <span className='text-red'>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Company Color Name"
                                                            {...register("company_color_name", {onChange: handleValue, required: true })}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Color Name <span className='text-red'>*</span></label>
                                                   
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                       
                                                        <input type="text" className="form-control border-end-0" placeholder="Enter Color"
                                                            {...register("name", { onChange: handleValue, required: true, maxLength: 30 })}

                                                        />

                                                        <div className="input-group-append">
                                                            <input type='color' id="color1" className="input-group-text bg-white border-start-0"
                                                                {...register("cpicker")}

                                                                onChange={onColorPickerInfoChange} ></input>
                                                        </div>
                                                    </div>
                                                </div> */}

<div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Color Name <span className="text-red">*</span>
                          </label>
                          <div className="input-group" data-colorpicker-id={2}>
                            {/* <input type="text" className="form-control border-end-0" onClick={handleOpen} placeholder="Add Color Name"
                                                            {...register("colorname", { required: true, maxLength: 30 })}
                                                        /> */}
                            <input
                              type="text"
                              className="form-control border-end-0"
                              placeholder="Color Name"
                              // disabled
                              {...register("name", {
                                required: {
                                  value: true,
                                  message: "Color Name is required",
                                },
                                maxLength: 30,
                              })}
                              onChange={onColorPickerInfoChange}
                            />

                            <div className="input-group-append">
                              <input
                                type="color"
                                id="color1"
                                value={cn}
                                className="input-group-text bg-white border-start-0"
                                {...register("cpicker")}
                                onChange={onColorPickerInfoChange}
                              ></input>
                            </div>

                            {/* <span className="text-red w-100">
                              {errors?.name?.message}
                            </span> */}
                          </div>
                        </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Color Code <span className='text-red'>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Color Code"
                                                            {...register("hex", { required: true, maxLength: 30 })}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span className='text-red'>*</span></label>
                                                    <select className="form-select"
                                                        {...register("status", { required: true, maxLength: 30 })}
                                                    >
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="row d-flex  ">
                                    <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                        <div className="row d-flex ">
                                            {/* <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                <button type="button" className="btn border rounded bg-white px-4 mt-2 mx-4" onClick={()=>navigate("/listColor")}>Cancel</button>
                                                <button type="button" className="btn border rounded text-white px-4 mt-2 " onClick={handleSubmit(onSubmit)}>Submit</button>

                                            </div> */}

                                            <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                <button
                                                    type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                    style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                    onClick={handleSubmit(onSubmit)} 
                                                    // disabled={!isDirty}
                                                    >Submit</button>
                                                {/* <button type="button" className="btn border-1 rounded  border-1 px-4 mt-2 mx-3" style={{borderColor:"blue",background:"blue",color:"white"}} onClick={handleReset}>Reset</button> */}

                                                <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listColor")}>Cancel</button>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
            <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>





                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                />
                            </div>
                        </div>
                    </div>



                </Modal.Body>
                <Modal.Footer>



                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>


                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>

                </Modal.Footer>
            </Modal>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />

            </Restricted>
        </div>
    )
}

export default Edit_Color