import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/esm/Button";
import { useCreate } from '../../hooks/Api';
import { Alert } from "../../components/Model/Model";
import { useForm } from 'react-hook-form';
import { RiPencilLine } from "react-icons/ri";
import { useFetchCategoryDropDown, useFetchGstTypeDropDown, useFetchHSNProductTypeDropDown, useFetchMaterialDensityDropDown } from '../../hooks/DropDown_Api';
import useFocusOnEnter from '../focus';
import Select from 'react-select';
import { AiOutlinePlus } from 'react-icons/ai';
import CreatableSelect from 'react-select/creatable';
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'


const customIndicator = {
    IndicatorsContainer:IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer:IndicatorsContainerCreate
}
interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});


export const HSN_Model = ({refetchForm,open,setOpen,setSubmitted}:any) => {


    // const[open,setOpen] = useState(false)
    // const [submitted,setSubmitted] = useState(false)

    // const navigate = useNavigate()
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const [options, setOptions]:any = useState([])
    const [optionsType, setOptionsType] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [loading, setLoading] = useState(false)
    const [showGst, setShowGst]:any = useState({})
    const [showDen, setShowDen] = useState("")
    const apiname = process.env.REACT_APP_HSN_API?.toString()
// console.log(showGst,"showGst");


    const { mutate: hsn, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)


    const { register, handleSubmit, setError, formState: { errors }, reset, setValue, getValues,watch }: any = useForm();


    const value = getValues("gst")

    const { data: colorData, refetch: colorRefetch } = useFetchMaterialDensityDropDown(searchTerm)

    useEffect(() => {
        setOptions(colorData)
    }, [colorData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            colorRefetch()
        }
    }, [])



    const handleChange = (e: any) => {
        setShowGst(e)
        setShowDen(e)
        setValue("grade_type", e.value)
        setValue("gst", e.gst_id)
        setValue("density",e.density)
        setForValue({label:e?.label,value:e?.value})

      
        
    }


    const [valueCategory, setValueCategory]:any = useState();
    const [optionsCategory, setOptionsCategory]: any = useState([]);
    const [valueCategoryCreatable, setValueCategoryCreatable] = useState<Option | null>();
   
    const [isLoadingValue, setIsLoadingValue] = useState(false);
    const apinameForCategory = process.env.REACT_APP_PRODUCT_CATEGORY_API?.toString()
    const { mutate: mutateCategory, data: dataCategory, isError: CategoryIsError, isLoading: CategoryLoading, isSuccess: CategorySuccess, error: CategoryError }: any = useCreate(apinameForCategory)


    const { data: CategoryData, refetch: CategoryRefetch } = useFetchCategoryDropDown()
    
    useEffect(() => {
        setOptionsCategory(CategoryData)
    }, [CategoryData])

    const handleChangeCategory = (e: any) => {

        setValue("product_categories", `${e?.value}`)
        setValueCategoryCreatable(null)
    }

    const handleCreate = (inputValue: string) => {
        
        inputValue = inputValue.toLowerCase()
        .split(' ')
        .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
        const newOption = createOption(inputValue);
        setIsLoadingValue(false);
       
        
        mutateCategory({name:newOption.name,status:newOption.status})
        setOptionsCategory((prev:any) => [...prev, newOption]);
        setValueCategoryCreatable(newOption);
      }, 0);

   
    };


    useEffect(() => {
        (async () => {
            if(CategorySuccess && optionsCategory){
            await CategoryRefetch();
            setValue("product_categories",CategoryData[0]?.value)
            }
        })();
        
        return () => {
        };
      }, [CategorySuccess,CategoryData]);

      const [valueProductType, setValueProductType]:any = useState();
      const [optionsProductType, setOptionsProductType]: any = useState([]);
      const [valueProductTypeCreatable, setValueProductTypeCreatable] = useState<Option | null>();
     
      // const [isLoadingValue, setIsLoadingValue] = useState(false);
      const apinameForProductType = process.env.REACT_APP_PARTICULAR_SECTOR_NAME_API?.toString()
      const { mutate: mutateProductType, data: dataProductType, isError: ProductTypeIsError, isLoading: ProductTypeLoading, isSuccess: ProductTypeSuccess, error: ProductTypeError }: any = useCreate(apinameForProductType)
  
  
      const { data: ProductTypeData, refetch: ProductTypeRefetch } = useFetchHSNProductTypeDropDown()
      
      useEffect(() => {
          setOptionsProductType(ProductTypeData)
      }, [ProductTypeData])
  
      const handleChangeProductType = (e: any) => {
  
          setValue("particular_sector_name", `${e?.value}`)
          setValueProductTypeCreatable(null)
      }
  
      const handleCreateProductType = (inputValue: string) => {
          
          inputValue = inputValue.toLowerCase()
          .split(' ')
          .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');
          setIsLoadingValue(true);
          setTimeout(() => {
          const newOption = createOption(inputValue);
          setIsLoadingValue(false);
         
          
          mutateProductType({name:newOption.name,status:newOption.status})
          setOptionsProductType((prev:any) => [...prev, newOption]);
          setValueProductTypeCreatable(newOption);
        }, 0);
  
     
      };
  
  
      useEffect(() => {
          (async () => {
              if(ProductTypeSuccess && optionsProductType){
              await ProductTypeRefetch();
              }
              setValue("particular_sector_name",ProductTypeData[0]?.value)
          })();
          
          return () => {
          };
        }, [ProductTypeSuccess,ProductTypeData]);



      const [valueGst, setValueGst]:any = useState();
      const [optionsGst, setOptionsGst]: any = useState([]);
      const [valueGstCreatable, setValueGstCreatable] = useState<Option | null>();
     
      const apinameForGst = process.env.REACT_APP_GST_TYPE_API?.toString()
      const { mutate: mutateGst, data: dataGst, isError: GstIsError, isLoading: GstLoading, isSuccess: GstSuccess, error: GstError }: any = useCreate(apinameForGst)
  
      const { data: GstData, refetch: GstRefetch } = useFetchGstTypeDropDown()
      
      useEffect(() => {
          setOptionsGst(GstData)
      }, [GstData])
  
  
      const handleChangeGst = (e: any) => {
  
          setValue("gst_type", `${e?.value}`)
          setValueGstCreatable(null)
      }
  
      const handleCreateGst = (inputValue: string) => {
          
          inputValue = inputValue.toLowerCase()
          .split(' ')
          .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');
          setIsLoadingValue(true);
          setTimeout(() => {
          const newOption = createOption(inputValue);
          setIsLoadingValue(false);
         
          
          mutateGst({name:newOption.name,status:newOption.status})
          setOptionsGst((prev:any) => [...prev, newOption]);
          setValueGstCreatable(newOption);
        }, 0);
  
     
      };
  
  
  
      useEffect(() => {
          (async () => {
              if(GstSuccess && optionsGst){
              await GstRefetch();
              }
              setValue("gst_type",GstData[0]?.value)
          })();
          
          return () => {
          };
        }, [GstSuccess,GstData]);

    // useEffect(() => {
    //     if (data) {
    //         reset(data)
    //     }
    // }, [data]);

    const [forValue,setForValue]:any = useState([])

  

    const handleReset = () => {
        reset()
        setShowGst({})
        setShowDen("")
        setForValue(null)
        
    }

    const onSubmit = (hsndetail: any) => {
        const formData = new FormData();

        for (const [key, value] of Object.entries(hsndetail)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            // else {
            //     formData.append(key, formvalue[0])
            // }
        }
        hsn(formData);

    }





  


    useEffect(()=>{
        if(isSuccess){
            setSubmitted(true)
            setOpen(false)
            reset()
            // setForGSTValue(null)
        }
        refetchForm()
            },[isSuccess,data])


   
  


   



    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);


    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }


    const handleHSNcode = (e: any) => {
        let temp = e.target.value.replace(/\D/g, ''); 
        if (temp.length > 8) temp = temp.substring(0, 8); 
        if (temp.length > 4) {
            temp = temp.slice(0, 4) + ' ' + temp.slice(4);
        }
        e.target.value = temp;
    
        setValue('hsn_code',temp)
    }
  return (
    <>
    <Modal  
    
    size='xl'
    
    
    show={open} onHide={()=>{
        setShow(false)
        reset()
        setOpen(false)}} aria-labelledby="contained-modal-title-vcenter"
centered    >
  <Modal.Header closeButton>
    <Modal.Title style={{fontSize:'16px'}}>HSN Code</Modal.Title>
  </Modal.Header>
  <Modal.Body> 
     
  <form >

  {show && showmessage === "success" ? <Alert color={showmessage} message={"Successfully Added"} /> : ""}


{show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}

<div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">HSN Code <span style={{color:'red'}}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="HSN Code" 
                                                        
                                                        // onChange={handleHSNcode}
                                                        {...register('hsn_code', { 
                                                            required: 'Field is required',
                                                            pattern: {
                                                                value:  /^(\d{1,4})(?:\s(\d{1,4}))?(?:\s(\d{1,2}))?$/gm ,
                                                                message: 'No more than 6 digits',
                                                            },
                                                        })}
                                                        onChange={handleHSNcode}
                                                        
                                                        
                                                        // {...register("hsn_code")} 
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.hsn_code?.message}</span>

                                                </div>
                                                {/* <div className="col-sm-4 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Product Code & Name & Type</label>
                                                    <Select
                                                        // placeholder={'Select Gst'}
                                                        options={optionsType}
                                                        onChange={handleChangeType}
                                                        isMulti
                                                    // {...register("grade_type")}
                                                    // name="grade_type"
                                                    />
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">HSN Code Qty</label>
                                                    <input disabled type="text"
                                                        className="form-control" id="exampleInputBrand" placeholder="HSN Code Qty" {...register("hsn_qty")} />
                                                </div> */}
                                                {/* <div className="col-sm-4 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Grade Type <span style={{color:'red'}}>*</span></label>
                                                    <Select
                                                                                                        {...register("grade_type",{required: { value: true, message: "Field is required" }})}
                                                        value={forValue}
                                                        options={options}
                                                        onChange={handleChange}

                                                    />
                                                 <span className='text-red w-100'>{errors?.grade_type?.message}</span>

                                                </div> */}


                                                <div className="col-sm-4 mt-2">
                                                <label htmlFor="name_and_grade">Grade Type <span style={{ color: 'red' }}>*</span></label>
                                                    {/* <div className="input-group" ></div> */}
                                                    <div className="d-flex row" >
                                                        <Select 
                                                            {...register("grade_type",{required: { value: true, message: "Field is required" }})}
                                                            value={forValue}
                                                            options={options}
                                                            onChange={handleChange}
                                                            components={customIndicator}
                                                            // isOptionDisabled={(options:any)=>
                                                            //     {
                                                            //         if(options.status === "inactive" || options.deleted == true){
                                                            //         return options
                                                            //         }
                                                            //        }}
                                                            // className="dropdown-select"

                                                        />
                                                        {/* <div className="input-group-append" style={{width:0, padding:0}}>
                                                            <Button className="input-group-text border-start-0 bg-primary"  onClick={()=>{
                                                                setOpen(true)}}><AiOutlinePlus/></Button>
                                                        </div> */}
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.grade_type?.message}</span>

                                                </div>





                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Type</label>
                                                    <CreatableSelect
                                                    isClearable={()=>{setValueProductType(null)
                                                        setValue(null)}}
                                                        // classNames="creatable-select"
                                                        {...register("particular_sector_name", { required: { value: false, message: "Product Type is required" } })}
                                                        placeholder={'Select Product Type'}
                                                        components={ customCreatableIndicator }
                                                        // menuIsOpen={true}
                                                        options={optionsProductType}
                                                        onCreateOption={handleCreateProductType}
                                                        onChange={handleChangeProductType}
                                                        value={valueProductTypeCreatable==null?valueProductType:valueProductTypeCreatable}
                                                        maxMenuHeight={100}

                                                    />
                                                    {/* <div className="input-group"> */}
                                                        {/* <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product Type" {...register("particular_sector_name", { onChange: handleValue, required: { value: false, message: "Field is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div> */}
                                                    {/* </div> */}
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Category <span style={{color:'red'}}>*</span></label>
                                                    
                                                    
                                                     <CreatableSelect
                                                    isClearable={()=>{setValueCategory(null)
                                                        setValue(null)}}
                                                        // classNames="creatable-select"
                                                        {...register("product_categories", { required: { value: true, message: "Product Category is required" } })}
                                                        placeholder={'Select Product Category'}
                                                        components={ customCreatableIndicator }
                                                        // menuIsOpen={true}
                                                        options={optionsCategory}
                                                        onCreateOption={handleCreate}
                                                        onChange={handleChangeCategory}
                                                        value={valueCategoryCreatable==null?valueCategory:valueCategoryCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.product_categories?.message}</span>
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">GST%</label>
                                                    <input disabled type="text"
                                                        value={showGst?.gst}
                                                        className="form-control" id="exampleInputBrand" placeholder="GST%" {...register("gst")} />

                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">GST Type</label>
                                                    <CreatableSelect
                                                    isClearable={()=>{setValueGst(null)
                                                        setValue(null)}}
                                                        // classNames="creatable-select"
                                                        {...register("gst_type", { required: { value: false, message: "GST Type is required" } })}
                                                        placeholder={'Select GST Type'}
                                                        components={ customCreatableIndicator }
                                                        options={optionsGst}
                                                        onCreateOption={handleCreateGst}
                                                        onChange={handleChangeGst}
                                                        value={valueGstCreatable==null?valueGst:valueGstCreatable}

                                                    />
                                                    {/* <select className="form-select" {...register("gst_type")}>
                                                        <option value="" selected>Select Any</option>
                                                        <option value="service">Service</option>
                                                        <option value="goods">Goods</option>
                                                    </select> */}
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Density</label>
                                                    <div className="input-group">
                                                        <input disabled type="text" className="form-control" id="exampleInputBrand" 
                                                        placeholder="Material Density" {...register("density")} 
                                                        />

                                                        {/* <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="status">Status <span style={{color:'red'}}>*</span></label>
                                                    <select className="form-select" {...register("status",
                                                        {
                                                            required: { value: true, message: "Status is required" }, maxLength: 30,

                                                        })}>
                                                        <option value="" selected>Select Any </option>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"inactive"}>Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        </form>
      
</Modal.Body>
  <Modal.Footer>
  
    <Button variant="info" className="text-white" 
 onClick={handleSubmit(onSubmit)}
 style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
    >
      Submit
    </Button>
    <Button variant="info" className="text-white" style={{ borderColor: "blue", background: "blue", color: "white" }}
 onClick={handleReset}
 >
   Reset
 </Button>
    <Button variant="secondary" style={{ borderColor: "red", background: "red", color: "white" }} onClick={()=>{setOpen(false)}}>
      Cancel
    </Button>
  </Modal.Footer>
</Modal>
    </>
  )
}



