import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate } from "../../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../../components/focus";


const Add_Source_Inquiry = () => {
    const focus:any = useRef();
    const {onEnterKey} = useFocusOnEnter(focus);
    const navigate = useNavigate()

    const apiname = process.env.REACT_APP_SALES_SOURCE_INQUIRY_API?.toString();

    const {
        mutate: sales,
        data,
        isError,
        isLoading,
        isSuccess,
        error,
      }: any = useCreate(apiname);


    const { register, handleSubmit, setError, formState, reset,setValue, getValues  }:any = useForm({
        mode:"onChange"
    });
    const { errors } = formState;

    
    
    const onSubmit = (data: any) => {
       
       
    }
    
    
    
    
    const handleReset = () => {
        reset()
    }
    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Add Source Inquiry</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listSourceInquiry" className="text-decoration-none text-black">Source Inquiry</Link></li>
                                    <li className="breadcrumb-item active">Add Source Inquiry</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Source Name</span></h5>
                            </div> */}
                            <form ref={focus}
                             onKeyUp={event => onEnterKey(event)}
                             onSubmit={handleSubmit(onSubmit)}>
                            
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>
                                    {/* {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message.name} /> : ""} */}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Department Name</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder="Department Name" {...register("one", { required: { value: true, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.one?.message}</span>
                                                </div>
                                                
                                               
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status</label>
                                                    <select className="form-select" {...register("two", { required: { value: true, message: "Field is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.two?.message}</span>

                                                </div>

                                             
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                <button 
                                                type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3" 
                                                style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                
                                                onClick={handleSubmit(onSubmit)}
                                                >Submit</button>
<button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

<button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listSourceInquiry")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Add_Source_Inquiry