import { useEffect, useRef, useState } from 'react'
import background from '../images/logo-background.png'
import logo from "../images/logoBackup.png"
import "./login.css"
import { useForm } from "react-hook-form";
import axios from 'axios';
import { useMutation } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import { useNavigate } from "react-router-dom";
import useFocusOnEnter from '../components/focus';
import Axiosinstance from '../hooks/Interceptor';
import { setPermissions } from '../redux/permissions';

import { useDispatch } from 'react-redux';




const Login = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const focus:any = useRef();
    const {onEnterKey} = useFocusOnEnter(focus);

    

    const[err,setErr]=useState(false)
    const [iconChange, setIconChange] = useState("password")
    const { error, mutate, isError, isLoading, data,isSuccess }: any = useMutation(async (data: any) => {
        const postapicall = await axios.post(`${process.env.REACT_APP_BASE_URL}api/token/`, data)
        return postapicall.data
    }

    )
    const { register, handleSubmit,  formState: { errors } } = useForm();
    const onSubmit = (data: any) => {
        setErr(true)
        mutate(data)
    }
    useEffect(() => {
        if (data)
        
            localStorage.setItem('token', data?.access)
            localStorage.setItem('Username', data?.Username)
            localStorage.setItem('is_superuser', data?.is_superuser)

            if(data?.refresh) localStorage.setItem('refresh', data?.refresh)
           

        
        
    }, [data])

    const changeicon = () => {
        if (iconChange == "password") {
            setIconChange("text")
        }
        else {
            setIconChange("password")
        }
    }
// if(isSuccess){
//     navigate("/listColor")
// }
    
if(isSuccess){
    Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}leoUser/user/get_related_permissions`).then((data:any) => {
        dispatch(setPermissions(data?.data?.results))
    })
    navigate("/salesDashboard")
}

    return (
        <div>
            <div className='onScreenChange float-end'>
                <img src={background} alt="" className='test' />
            </div>
            <div style={{position:"absolute",top:"2%",right:"2%"}}>
            <button className='rounded-3 btn text-white px-4 py-2' type='button' onClick={()=>navigate("/addOnlinesalesinquiry")}>Inquiry</button>
            </div>
            <div className='container'>
                <div className='row justify-content-center vh-100'>
                    <div style={{ width: "400px", display: "block", margin: "auto" }}>
                        <img src={logo} className='pb-5 m-2 h-auto' alt="" width={350} height={160} />
                        <form className='text-center  border border-2 loginform rounded-4' 
                         ref={focus}
                         onKeyUp={event => onEnterKey(event)}
                        onSubmit={handleSubmit(onSubmit)}>
                            <h1 className='text-white p-3'>Login</h1>
                            {errors?.username && errors?.username?.type === "required" || errors?.password && errors?.password?.type  === "required" ? 
                                <div className="alert alert-danger  text-center py-0 mx-5" role="alert">
                                <span className=''><b>Username</b> and <b>Password</b> is required</span>
                              </div>
                                :""}
                            
                              
                            {isError ?
                            <div className="alert alert-danger  text-center py-0 mx-5" role="alert">
                            
                            <span >{error.response?.data?.message?.detail}</span>
                            </div>:""}

                            <div className="input-group-form mb-3 ">
                                <input
                                    type="text"
                                    className="form-control rounded-3"
                                    placeholder="Username"
                                    {...register("username", { required: true, maxLength: 30 })}
                                />
                                </div>

                            <div className="input-group-form mb-3">
                                <input
                                    type={iconChange?iconChange:"password"}
                                    className="form-control rounded-3"
                                    placeholder="Password"
                                    {...register("password", { required: true, maxLength: 30 })}
                                />
                                <span className="iconset" id="basic-addon1"><FontAwesomeIcon onClick={changeicon} icon={iconChange == "password" ? faEye : faEyeSlash} /></span>

                            </div>

                            <div className='d-flex justify-content-evenly mx-5 my-4'>

                                <button
                                                    type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                    style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                    onClick={handleSubmit(onSubmit)}>Login
                                                                                        {isLoading && <span className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>}

                                                    </button>
                            </div>
                        </form>
                    </div>
                </div>
              
            </div>

        </div>

    )
}

export default Login