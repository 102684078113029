import { createSlice } from '@reduxjs/toolkit'

export const table = createSlice({
  name: 'table',
  initialState: {
    value: {
      excelHeaders: [],
      excelBody: [],
      ColumnOrder: [],
    },
  },
  reducers: {
    setExcelHeader: (state, action) => {
      state.value.excelHeaders = action.payload
    },
    setExcelBody: (state, action) => {
        state.value.excelBody = action.payload
    },
    setColumnOrder: (state, action) => {
      state.value.ColumnOrder = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setExcelHeader, setExcelBody, setColumnOrder} = table.actions

export default table.reducer