export function fetchApiHelper(url:string, ) {
    return fetch(`${process.env.REACT_APP_BASE_URL}${url}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(response => response.json())
    .catch(error => console.error(error));
  }