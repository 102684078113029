
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import Actions from "../../components/Cell/Actions";
// import { updateBrand, useFetchBrands } from "../../hooks/brand";
// import noImage from "../../images/noImage.png"
import { useFetchGst, useUpdateGst } from "../../hooks/GST";


interface Data {
  selection: any
type:any
name:any
  is_base_unit:any
  baseUnit:any
  conversationRatio:any
  abbreviation:any

  created_at:any
  created_by:any
  updated_by:any
  updated_at:any
  status: any;
  actions: any;
}


export const titleofheaders=[
  {
    id: `srno`,
    Header: `Sr No`,
  },
 
  // {
  //   id: 'name',
  //   Header: 'U.O.M Name',
  // },
  {
    id: 'type',
    Header: 'U.O.M Type',
  },
  {
    id: 'name',
    Header: 'Unit Name',
  },
  {
    id: 'abbreviation',
    Header: 'Abbreviation',
  },
  {
    id: 'is_base_unit',
    Header: 'Is Base Unit',
  },
  {
    id: 'baseUnit',
    Header: 'Base Unit',
  },


  {
    id: 'conversationRatio',
    Header: 'Conversation Ratio',
  },




  {
    id: 'created_at',
    Header: 'Created At',

 
  },
  {
    id: 'created_by',
    Header: 'Created By',

 
  },
  {
    id: 'updated_at',
    Header: 'Updated At',
  },
  {
    id: 'updated_by',
    Header: 'Updated By',
  },

  {
    id: 'status',
    Header: 'Status',

 
  },
]


export const Columns_Capacity: any = [



  
  {
    id: `srno`,
    Header: () => <div>Sr<br/>No</div>,
    sticky: "left",
    
  },
  // {
  //   id: `name`,
  //   Header: () => <div>U.O.M<br/>Name</div>,
  //   sticky: "left",
  //   accessor:'name',
    
  //   Cell: ({ row }: any) =>{
  //       return(
  //       <div>
  //           {row.original.name}
  //       </div>
  //   )}

  // },
  {
    id: 'uom',
    Header: () => <div>U.O.M</div>,
    sticky: "left",
    accessor:'uom',

    Cell: ({ row }: any) => row.original.uom?.name?row.original.uom?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>

  },
  {
    id: 'type',
    Header: () => <div>U.O.M<br/>Type</div>,
    accessor:'type',
    Cell: ({row}:any) => {
      const convertToUpper = row.original.name.type
      if (!convertToUpper) return "";
      return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }


  },
  {
    id: 'name',
    Header: () => <div>Unit<br/>Name</div>,
    accessor:'name',

    Cell: ({ row }: any) => row.original.name?.name

  },

  {
    id: 'abbreviation',
    Header: 'Abbreviation',
    accessor:'abbreviation',
    // Cell: ({ row }: any) => row.original.name?.abbreviation
    Cell: ({row}:any) => {
      const convertToUpper = row.original.name.abbreviation
      if (!convertToUpper) return "";
      return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }

  },

  {
    id: 'is_base_unit',
    Header: () => <div>Is Base<br/>Unit?</div>,
    accessor:'is_base_unit',

    Cell: ({row}:any) => {
      const convertToUpper = row.original.is_base_unit
      if (!convertToUpper) return "";
      return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }

  },
  {
    id: 'baseUnit',
    Header: () => <div>Base<br/>Unit</div>,
    accessor:'baseUnit',

    Cell: ({ row }: any) => row.original.baseUnit?.name?row.original.baseUnit?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>

  },

  {
    id: 'conversationRatio',
    Header: () => <div>Conversation<br/>Ratio</div>,
    accessor:'conversationRatio',
    Cell: ({ row }: any) => row.original.conversationRatio

  },



  
  {
    id: 'status',
    Header: 'Status',
    accessor:'status',

 
  },

  {
    id: 'created_at',
    Header: () => <div>Created<br/>At</div>,
    accessor:'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div>Created<br/>By</div>,
    accessor:'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username

 
  },
  {
    id: 'updated_at',
    Header: () => <div>Updated<br/>At</div>,
    accessor:'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div>Updated<br/>By</div>,
    accessor:'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },


  

  


  {
    id: 'Actions',
    Header: 'Actions',

  
  },
  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_Capacity, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows} = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}capacity/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



