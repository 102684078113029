
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../../components/Cell/Status";
import Actions from "../../../components/Cell/Actions";
import Modal from "react-bootstrap/esm/Modal";
import noImage from "../../../images/noImage.png"

interface Data {
  selection: any
 
  code: any
  sales_inquiry_code: any
  company_new_old: any
  company: any
  difference_day: any
  // product_code: any
  carton_bundle: any
  carton_basic_rate: any
  carton_amount: any
  stick_rate: any
  company_delivery_date: any
  // address: any
  quotation:any


  created_at:any
  created_by:any
  updated_by:any
  updated_at:any
  status: any;
  actions: any;
}


export const titleofheaders=[
  {
    id: `srno`,
    Header: `Sr No`,
  },
  {
    id: `code`,
    Header: "Lead Generated Code",

},
{
    id: `sales_inquiry_code`,
    Header: "Sales Inquiry Code",

},

{
    id: `company_new_old`,
    Header: "Company Type",

},

{
  id: `difference_day`,
  Header: "Difference Day",

},
{
  id: `company`,
  Header: "Company Name & City",

},
{
  id: `employee`,
  Header: "Employee Code & Name",

},
 
// {
//   id: `product_code`,
//   Header: "Product Code, Name & Type",

// },
{
  id: `carton_bundle`,
  Header: "Carton Bundle",

},
{
  id: `company_delivery_date`,
  Header: "Company Delivery Date",

},
{
  id: `carton_basic_rate`,
  Header: "Carton Basic Rate",

},
{
  id: `gst_rate`,
  Header: "GST %",

},
{
  id: `gst_amount`,
  Header: "GST Amount",

},
{
  id: `carton_amount`,
  Header: "Carton Amount",

},
{
  id: `stick_rate`,
  Header: "Stick Rate",

},
 
{
  id: `ml_price_list`,
  Header: "ML Price List",

},
{
  id: `stick_nos`,
  Header: "Stick In Nos.",

},
{
  id: `carton_in_stick_qty`,
  Header:" Carton In Stick Qty.",

},
{
  id: `product_total_qty`,
  Header: "Product Total Pcs Qty.",

},
{
  id: `product_weight_price_list`,
  Header: "Product Single Pcs Weight",

},
{
  id: `carton_weight`,
  Header: "Carton Weight",

},
{
  id: `requirement_status`,
  Header: "Requirement Status",

},
{
  id: `lead_source_of_inquiry`,
  Header:" Lead Source Of Inquiry",

},
{
  id: `company_name_and_city`,
  Header: "Customer Name",

},
{
  id: `mobile_number`,
  Header: "Mobile Number",

},
{
  id: `email`,
  Header: "Email Id",

},
{
  id: `pincode`,
  Header: "Pin Code No.",


},
// {
//   id: `address`,
//   Header: "Address",


// },
{
  id: `city_name`,
  Header: "Village Code & Name",

},
{
  id: `taluka`,
  Header: "Taluka",

},
{
  id: `district`,
  Header: "District",

},
{
  id: `state`,
  Header: "State",

},
{
  id: `country`,
  Header: "Country",

},
{
  id: `reference_name`,
  Header: "Reference Name",

},
{
  id: `lead_contacted`,
  Header: "Lead Contacted Date & Time",

},
{
  id: `product_type`,
  Header: "Product Type",

},
{
  id: `product_special_running`,
  Header: "Special/Running",

},
{
  id: `product_code_name_type`,
  Header: "Code/Name/Type",

},
{
  id: `product_description`,
  Header: "Description",

},
{
  id: `product_qty`,
  Header: "Qty",

},
{
  id: `requirement_date`,
  Header:"Requirement Date",

},
{
  id: `expected_basic_value`,
  Header: "Expected Basic Value",

},
{
  id: `possible_yes_no`,
  Header:"Possible Yes/No",

},

{
  id: `quotation`,
  Header:"Quotation",

},
  
  {
    id: 'status',
    Header: 'Status',
  },
  {
    id: 'created_at',
    Header: `Created At`,
  },


  {
    id: 'created_by',
    Header: `Created By`,

 
  },
  {
    id: 'updated_at',
    Header: `Updated At`,

  },
  {
    id: 'updated_by',
    Header: `Updated By`,

  },
]


export const Columns_Lead_Generated: any = [



{
    id: `srno`,
    Header: () => <div style={{display:"inline"}}>Sr.<br/>No</div>,
    accessor:'srno',
    sticky:"left",
},
{
    id: `code`,
    Header: () => <div style={{display:"inline"}}>Lead Generated<br/>Code</div>,
    accessor:'code',
    sticky:"left",
},
{
    id: `sales_inquiry_code`,
    Header: () => <div style={{display:"inline"}}>Sales Inquiry <br/>Code</div>,
    accessor:'sales_inquiry_code',
    Cell: ({ row }: any) => {
      return(
      (row.original?.extra_response?.sales_inquiry_code)
      )
      }
},

{
    id: `company_new_old`,
    Header: () => <div style={{display:"inline"}}>Company<br/>Type</div>,
    accessor:'company_new_old',
    Cell: ({row}:any) => {
      const convertToUpper = row.original?.extra_response?.company_new_old
      if (!convertToUpper) return "";
      // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper?.split("_")?.map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase())?.join(" ")
      // return convertToUpper
    }
   
},

{
  id: `difference_day`,
  Header: () => <div style={{display:"inline"}}>Difference <br/>Day</div>,
  accessor:'difference_day',
  Cell: ({ row }: any) => {
    return(
    (row.original?.difference_day)
    )
    }
},
{
  id: `company`,
  Header: () => <div style={{display:"inline"}}>Company Name<br/>& City</div>,
  accessor:'company',
  Cell: ({ row }: any) => {
    return(`${row.original?.extra_response?.customer_name}-${row.original?.extra_response?.city[0]?.city_name?row.original?.extra_response?.city[0]?.city_name:row.original?.extra_response?.city[0]?.village_name}`)
    }
},
// {
//   id: `employee`,
//   Header: () => <div style={{display:"inline"}}>Employee Code<br/>& Name</div>,
//   accessor:'employee',
//   Cell: ({ row }: any) => {
//     return(`${row.original?.sales_inquiry_code?.lead_generated_by_sales_executive_code_and_name?.code} ${row.original?.sales_inquiry_code?.lead_generated_by_sales_executive_code_and_name?.name}`)
//     }
// },
 
// {
//   id: `product_code`,
//   Header: () => <div style={{display:"inline"}}>Product Code, <br/>Name & Type</div>,
//   accessor:'product_code',
//   Cell: ({ row }: any) => {
//     return(
//     (row.original?.product_code?.product_name_and_grade_and_type?.material_type_name)
//     )
//     }
// },
{
  id: `carton_bundle`,
  Header: () => <div style={{display:"inline"}}>Carton <br/>Bundle</div>,
  accessor:'carton_bundle',
  Cell: ({ row }: any) => {
    return(
    (row.original?.carton_bundle)
    )
    }
},
{
  id: `company_delivery_date`,
  Header: () => <div style={{display:"inline"}}>Company Delivery <br/>Date</div>,
  accessor:'company_delivery_date',
  Cell: ({ row }: any) => {
    return(
    (row.original?.company_delivery_date)
    )
    }
},
{
  id: `carton_basic_rate`,
  Header: () => <div style={{display:"inline"}}>Carton Basic <br/>Rate</div>,
  accessor:'carton_basic_rate',
  Cell: ({ row }: any) => {
    return(
    (row.original?.carton_basic_rate)
    )
    }
},
// {
//   id: `gst_rate`,
//   Header: () => <div style={{display:"inline"}}>GST %</div>,
//   accessor:'gst_rate',
//   Cell: ({ row }: any) => {
//     return(
//     (row.original?.product_code?.gst_rate?.india_GST)
//     )
//     }
// },
// {
//   id: `gst_amount`,
//   Header: () => <div style={{display:"inline"}}>GST<br/>Amount</div>,
//   accessor:'gst_amount',
//   Cell: ({ row }: any) => {
//     return(
//     (row.original?.product_code?.gst_amount)
//     )
//     }
// },
{
  id: `carton_amount`,
  Header: () => <div style={{display:"inline"}}>Carton <br/>Amount</div>,
  accessor:'carton_amount',
  Cell: ({ row }: any) => {
    return(
    (row.original?.carton_amount)
    )
    }
},
{
  id: `stick_rate`,
  Header: () => <div style={{display:"inline"}}>Stick <br/>Rate</div>,
  accessor:'stick_rate',
  Cell: ({ row }: any) => {
    return(
    (row.original?.stick_rate)
    )
    }
},
 

{
  id: `requirement_status`,
  Header: () => <div style={{display:"inline"}}>Requirement<br/>Status</div>,
  accessor:'requirement_status',
  Cell: ({ row }: any) => {
    return(
    (row.original?.extra_response?.requirement_status)
    )
    }
},
{
  id: `lead_source_of_inquiry`,
  Header: () => <div style={{display:"inline"}}>Lead Source<br/>Of Inquiry</div>,
  accessor:'lead_source_of_inquiry',
  Cell: ({ row }: any) => {
    return(
    (row.original?.extra_response?.lead_source_of_inquiry_name)
    )
    }
},
{
  id: `company_name_and_city`,
  Header: () => <div style={{display:"inline"}}>Customer <br/>Name</div>,
  accessor:'company_name_and_city',
  Cell: ({ row }: any) => {
    return(
    (row.original?.extra_response?.name)
    )
    }
},
{
  id: `mobile_number`,
  Header: () => <div style={{display:"inline"}}>Mobile  <br/>Number</div>,
  accessor:'mobile_number',
  Cell: ({ row }: any) => {
    return( (row.original?.extra_response?.contact_detail_number)
    // (row.original?.sales_inquiry_code?.company_new_old === "old"?row.original?.sales_inquiry_code?.company_name_and_city?.contact_detail?.number:row.original.sales_inquiry_code?.mobile_number)
    )
    }
},
{
  id: `email`,
  Header: () => <div style={{display:"inline"}}>Email<br/>Id</div>,
  accessor:'email',
  Cell: ({ row }: any) => {
    return((row.original?.extra_response?.email_address)
    // (row.original?.sales_inquiry_code?.company_new_old === "old"?row.original?.sales_inquiry_code?.company_name_and_city?.contact_detail?.email_address:row.original.sales_inquiry_code?.email)
    )
    }
},
{
  id: `pincode`,
  Header: () => <div style={{display:"inline"}}>Pin Code<br/>No.</div>,
  accessor:'pincode',
  Cell: ({ row }: any) => {
    return(
    (row.original?.extra_response?.city[0]?.pincode[0])
    )
    }
},
// {
//   id: `address`,
//   Header: () => <div style={{display:"inline"}}>Address</div>,
//   accessor:'address',
//   Cell: ({ row }: any) => {
//     return(
//     (row.original?.address)
//     )
//     }
// },


{
  id: `city_name`,
  Header: () => <div style={{display:"inline"}}>Village Code<br/>& Name</div>,
  accessor:'city_name',
  Cell: ({ row }: any) => {
    return (`${row.original?.extra_response?.city[0]?.city_name?row.original?.extra_response?.city[0]?.city_name:row.original?.extra_response?.city[0]?.village_name}`)
    
    }
},
{
  id: `taluka`,
  Header: () => <div style={{display:"inline"}}>Taluka</div>,
  accessor:'taluka',
  Cell: ({ row }: any) => {
    return(
    (row.original?.extra_response?.city[0]?.taluka?row.original?.extra_response?.city[0]?.taluka:<span style={{ color: 'red',fontSize:"20px" }}>****</span>)
    )
    }
},
{
  id: `district`,
  Header: () => <div style={{display:"inline"}}>District</div>,
  accessor:'district',
  Cell: ({ row }: any) => {
    return(
    (row.original?.extra_response?.city[0]?.district)
    )
    }
},
{
  id: `state`,
  Header: () => <div style={{display:"inline"}}>State</div>,
  accessor:'state',
  Cell: ({ row }: any) => {
    return(
    (row.original?.extra_response?.city[0]?.state)
    )
    }
},
{
  id: `country`,
  Header: () => <div style={{display:"inline"}}>Country</div>,
  accessor:'country',
  Cell: ({ row }: any) => {
    return(
    (row.original?.extra_response?.city[0]?.country)
    )
    }
},
{
  id: `reference_name`,
  Header: () => <div style={{display:"inline"}}>Reference<br/>Name</div>,
  accessor:'reference_name',
  Cell: ({ row }: any) => {
    return(
    (row.original?.extra_response?.reference_name?row.original?.extra_response?.reference_name:row.original?.extra_response?.other_reference)
    )
    }
},
{
  id: `lead_contacted`,
  Header: () => <div style={{display:"inline"}}>Lead Contacted<br/>Date & Time</div>,
  accessor:'lead_contacted',
  Cell: ({ row }: any) => {
    return(
    (row.original?.extra_response?.lead_contacted)
    )
    }
},
// {
//   id: `product_type`,
//   Header: () => <div style={{display:"inline"}}>Product<br/>Type</div>,
//   accessor:'product_type',
//   Cell: ({ row }: any) => {
//     let productType = row.original?.sales_inquiry_code?.product?.map((e:any)=>{
//       return e?.product_type?.type
//     })
//     if (productType?.length === 0) return "";
//     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
//     return productType?.join(",")
     
// }
// },
// {
//   id: `product_special_running`,
//   Header: () => <div style={{display:"inline"}}>Special<br/>Running</div>,
//   accessor:'product_special_running',
//   Cell: ({ row }: any) => {
//     let productType = row.original?.sales_inquiry_code?.product?.map((e:any)=>{
//       return e?.product_special_running
//     })
//     if (productType?.length === 0) return "";
//     return productType?.map((w:any) => w[0]?.toUpperCase() + w.substr(1).toLowerCase())?.join(",")
     
// }
// },
// {
//   id: `product_code_name_type`,
//   Header: () => <div style={{display:"inline"}}>Code/Name<br/>& Type</div>,
//   accessor:'product_code_name_type',
//   Cell: ({ row }: any) => {
//     let productType = row.original?.sales_inquiry_code?.product?.map((e:any)=>{
//       return e?.pricelist_product?.product_code?.product_code
//     })
//     if (productType?.length === 0) return "";
//     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
//     return productType?.join(",")
     
// }
// },
// {
//   id: `product_description`,
//   Header: () => <div style={{display:"inline"}}>Description</div>,
//   accessor:'product_description',
//   Cell: ({ row }: any) => {
//     let productType = row.original?.sales_inquiry_code?.product?.map((e:any)=>{
//       return e?.product_description
//     })
//     if (productType?.length === 0) return "";
//     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
//     return productType?.join(",")
     
// }
// },
// {
//   id: `product_qty`,
//   Header: () => <div style={{display:"inline"}}>Qty</div>,
//   accessor:'product_qty',
//   Cell: ({ row }: any) => {
//     let productType = row.original?.sales_inquiry_code?.product?.map((e:any)=>{
//       return e?.product_qty
//     })
//     if (productType?.length === 0) return "";
//     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
//     return productType?.join(",")
     
// }
// },
// {
//   id: `requirement_date`,
//   Header: () => <div style={{display:"inline"}}>	Requirement<br/>Date</div>,
//   accessor:'requirement_date',
//   Cell: ({ row }: any) => {
//     let productType = row.original?.sales_inquiry_code?.product?.map((e:any)=>{
//       return e?.requirement_date
//     })
//     if (productType?.length === 0) return "";
//     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
//     return productType?.join(",")
     
// }
// },
// {
//   id: `expected_basic_value`,
//   Header: () => <div style={{display:"inline"}}>Expected Basic<br/>Value</div>,
//   accessor:'expected_basic_value',
//   Cell: ({ row }: any) => {
//     let productType = row.original?.sales_inquiry_code?.product?.map((e:any)=>{
//       return e?.expected_basic_value
//     })
//     if (productType?.length === 0) return "";
//     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
//     return productType?.join(",")
     
// }
// },
// {
//   id: `possible_yes_no`,
//   Header: () => <div style={{display:"inline"}}>Possible<br/>Yes/No</div>,
//   accessor:'possible_yes_no',
//   Cell: ({ row }: any) => {
//     let productType = row.original?.sales_inquiry_code?.product?.map((e:any)=>{
//       return e?.possible_yes_no
//     })
//     if (productType?.length === 0) return "";
//     return productType
     
// }
// },
// {
//   id: `gst_rate`,
//   Header: () => <div style={{display:"inline"}}>GST %</div>,
//   accessor:'gst_rate',
//   Cell: ({ row }: any) => {
//     let productType = row.original?.sales_inquiry_code?.product?.map((e:any)=>{
//       return e?.pricelist_product?.product_code?.product_name_and_grade?.gst?.india_GST
//     })
//     if (productType?.length === 0) return "";
//     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
//     return productType?.join(",")
     
// },
  
// },
// {
//   id: `gst_amount`,
//   Header: () => <div style={{display:"inline"}}>GST<br/>Amount</div>,
//   accessor:'gst_amount',
//   Cell: ({ row }: any) => {
//     // let productType = row.original?.sales_inquiry_code?.product?.map((e:any)=>{
//       return <span style={{ color: 'red',fontSize:"20px" }}>****</span>
//     // })
//     // if (productType?.length === 0) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
//     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
//     // return productType?.join(",")
     
// },
// },
// {
//   id: `ml_price_list`,
//   Header: () => <div style={{display:"inline"}}>ML Price<br/>List</div>,
//   accessor:'ml_price_list',
//   Cell: ({ row }: any) => {
//     let productType = row.original?.sales_inquiry_code?.product?.map((e:any)=>{
//       return e?.pricelist_product?.product_code?.product_ml_price_list
//     })
//     if (productType?.length === 0) return "";
//     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
//     return productType?.join(",")
     
// },
// },
// {
//   id: `stick_nos`,
//   Header: () => <div style={{display:"inline"}}>Stick In<br/>Nos.</div>,
//   accessor:'stick_nos',
//   Cell: ({ row }: any) => {
//     let productType = row.original?.sales_inquiry_code?.product?.map((e:any)=>{
//       return e?.pricelist_product?.product_code?.stick_nos
//     })
//     if (productType?.length === 0) return "";
//     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
//     return productType?.join(",")
     
// },
// },
// {
//   id: `carton_in_stick_qty`,
//   Header: () => <div style={{display:"inline"}}>Carton In<br/>Stick Qty.</div>,
//   accessor:'carton_in_stick_qty',
//   Cell: ({ row }: any) => {
//     let productType = row.original?.sales_inquiry_code?.product?.map((e:any)=>{
//       return e?.pricelist_product?.product_code?.carton_total_nos
//     })
//     if (productType?.length === 0) return "";
//     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
//     return productType?.join(",")
     
// },
// },
// {
//   id: `product_total_qty`,
//   Header: () => <div style={{display:"inline"}}>Product Total<br/>Pcs Qty.</div>,
//   accessor:'product_total_qty',
//   Cell: ({ row }: any) => {
//     // let productType = row.original?.sales_inquiry_code?.product?.map((e:any)=>{
//       return <span style={{ color: 'red',fontSize:"20px" }}>****</span>
//     // })
//     // if (productType?.length === 0) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
//     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
//     // return productType?.join(",")
     
// },
// },
// {
//   id: `product_weight_price_list`,
//   Header: () => <div style={{display:"inline"}}>Product Single <br/>Pcs Weight</div>,
//   accessor:'product_weight_price_list',
//   Cell: ({ row }: any) => {
//     let productType = row.original?.sales_inquiry_code?.product?.map((e:any)=>{
//       return e?.pricelist_product?.product_code?.product_single_piece_code?.product_1_pcs_weight
//     })
//     if (productType?.length === 0) return "";
//     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
//     return productType?.join(",")
     
// },
// },
// {
//   id: `carton_weight`,
//   Header: () => <div style={{display:"inline"}}>Carton <br/>Weight</div>,
//   accessor:'carton_weight',
//   Cell: ({ row }: any) => {
//     let productType = row.original?.sales_inquiry_code?.product?.map((e:any)=>{
//       return e?.pricelist_product?.product_code?.carton_code_and_grade_and_name?.carton_empty_weight
//     })
//     if (productType?.length === 0) return "";
//     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
//     return productType?.join(",")
     
// },
// },

{
  id: `visiting_card_image`,
  Header: () => <div style={{ display: "inline" }}>Visiting Card<br />Image</div>,

  // accessor: `logo`,
  Cell: ({ row }: any) =>{
  
  const [deleteConfirm, setDeleteConfirm] = useState(false)
return(
  <>

      <div >

        {!row.original.sales_inquiry_code?.visiting_card_image?<>
          <a 
        
        href="#"
        
        >
          <img src={noImage}  height={60} width={100}/>
          </a>
        </>:<>
        <a 
        onClick={()=>setDeleteConfirm(true)}
        href={`#galleryModal${row.original.name}`}
        
        data-large-src={row.original.sales_inquiry_code?.visiting_card_image?row.original.sales_inquiry_code?.visiting_card_image:noImage} data-toggle="modal">
          <img src={row.original.sales_inquiry_code?.visiting_card_image?row.original.sales_inquiry_code?.visiting_card_image:noImage}  height={60} width={100}/>
          </a>
        </>}
       
      </div>



<Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
      centered    >
     
      <Modal.Body>  
      <img src={row.original.sales_inquiry_code?.visiting_card_image?row.original.sales_inquiry_code?.visiting_card_image:noImage} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid"/>

      </Modal.Body>
      <Modal.Footer>
      <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

      </Modal.Footer>
    </Modal>
      </>)
  }


  },


  

{
  id: 'status',
  Header: 'Status',
  accessor:'status',


},




{
    id: 'created_at',
    Header: () => <div style={{display:"inline"}}>Created<br/>At</div>,
    accessor:'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{display:"inline"}}>Created<br/>By</div>,
    accessor:'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username

 
  },
  {
    id: 'updated_at',
    Header: () => <div style={{display:"inline"}}>Updated<br/>At</div>,
    accessor:'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{display:"inline"}}>Updated<br/>By</div>,
    accessor:'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },

{
    id: 'Actions',
    Header: 'Actions',


},
  
  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_Lead_Generated, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows} = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LEAD_GENERATED_API}/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
  // {
  //   id: 'customer_details',
  //   Header:'Customer Details',
  //   sticky:"right",
  // },
  {
    id: 'quotation',
    Header:'Quotation',
    sticky:"right",
  },
]



