import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/esm/Button";
import { useCreate, useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from '../../hooks/Api';
import { Alert } from "../../components/Model/Model";
import { useForm } from 'react-hook-form';
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from '../focus';
import Select from "react-select"
import { useNavigate } from 'react-router-dom';
import { useFetchBrandDropDown, useFetchColorDropDown, useFetchCountryDropDown, useFetchDistrictDropDown, useFetchGSTDropDown, useFetchStateDropDown, useFetchSuperAccountDropDown } from '../../hooks/DropDown_Api';
import { Deletesinglewithdependancy } from '../modal';
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'
import { useQueryClient } from 'react-query';


const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}


export const SimpleInput_Edit = ({ refetchForm, open, setOpen, id, apiname, forState, forDistrict, countryId, stateId, districtId, forTaluka, isSuperAccount, superAccountId }: any) => {



    const queryClient = useQueryClient();
    // const [isEditing,setIsEditing] = useState(false)
    // const apiname = process.env.REACT_APP_COUNTRY_API?.toString()
    const { data } = useFetchSingle(id, apiname)
    const { mutate, isSuccess, isError, error, data: updateData, isLoading }: any = useUpdateSingle()


    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()

    const [openEditModel, setOpenEditModel] = useState(false)
    const [expirationDate, setExpirationDate] = useState()
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()

    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()


    const { register: register, handleSubmit: handleSubmit, formState: { errors }, reset, setValue }: any = useForm({
        mode: "onChange"
    });


    // const apiname = process.env.REACT_APP_SUPER_ACCOUNT_API?.toString()


    // const  { mutate, data,isSuccess,error,isError}:any = useCreate(apiname)



    useEffect(() => {
        if (isSuccess || yessuccess) {

            setOpen(false)
            reset()
            refetchForm()
            // queryClient.invalidateQueries(['Panni Material Use']);
        }
        refetchForm()
    }, [isSuccess, updateData, yessuccess])







    const handleReset = () => {
        reset()
    }

    const onSubmit = (detail: any) => {
        const formData = new FormData();

        for (const [key, value] of Object.entries(detail)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else if (key === "logo" || key === "logo_pdf") {
                if (typeof value === 'object' && value !== null && formvalue.length > 0) {
                    formData.append(key, formvalue[0])
                }
                else {
                    formData.append(key, "")
                }
            }




        }

        let data = {
            apiname: apiname,
            id: id,
            data: formData
        }
        mutate(data);

        setValuesofform(data)


    }


    useEffect(() => {
        if (yesupdated_response) {
            localStorage.setItem("superData", JSON.stringify(yesupdated_response.data))
        }
    }, [data, yesupdated_response])




    // useEffect(() => {
    //     if (isSuccess) {
    //         setShow(true)
    //         setshowmessage("success")
    //     }
    //     else if (isError) {
    //         setShow(true)
    //         setshowmessage("danger")
    //     }
    // }, [updateData, error])

    // useEffect(() => {
    //     setTimeout(() => {
    //         setShow(false)
    //     }, 3000)

    // }, [show]);



    const [isPincode, setIsPincode] = useState(false)

    useEffect(() => {

        if (data) {
            reset()
            if (apiname === 'geography/pincode') {
                setIsPincode(true)
                setValue("pincode", data?.pincode)

            } else {
                setIsPincode(false)
                setValue("name", data?.name)

            }
            // setValue("name",data?.name)
            setValue('country', countryId)
            setSelectedCountryId(countryId)
            setByState(countryId)

            setValue('state', stateId)
            setSelectedStateId(stateId)
            setByDistrict(stateId)

            setValue('district', districtId)
            setSelectedDistrictId(districtId)

            //Account Type Page

            setValue('superAccountGroup', superAccountId)
            setSelectedSuperAccount(superAccountId)



        }

    }, [data])







    const [valueStateId, setValueStateId] = useState("")
    const [optionsState, setOptionsState] = useState([])
    const [selectedStateId, setSelectedStateId]: any = useState()
    const [stateName, setStateName] = useState("")
    const [byState, setByState] = useState()
    const { data: StateData, refetch: StateRefetch } = useFetchStateDropDown(byState)
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsState(StateData)
    }, [StateData])

    const handleChangeState = (e: any) => {



        setValueStateId(e)
        setValue("state", `${e?.value}`)
        setStateName(e?.label)
        setByDistrict(e?.value)
    }


    const [valueDistrictId, setValueDistrictId] = useState("")
    const [optionsDistrict, setOptionsDistrict] = useState([])
    const [selectedDistrictId, setSelectedDistrictId]: any = useState()
    const [districtName, setDistrictName] = useState("")
    const [byDistrict, setByDistrict] = useState()
    const { data: DistrictData, refetch: machineNameRefetch } = useFetchDistrictDropDown(byDistrict)
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsDistrict(DistrictData)
    }, [DistrictData])


    const handleChangeDistrict = (e: any) => {



        setValueDistrictId(e)
        setValue("district", `${e?.value}`)
        setDistrictName(e?.label)
    }






















    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess || yessuccess || nosuccess) {
            setValuesofform(null)
            setShow(true)
            refetchForm()
            // queryClient.invalidateQueries(['Panni Material Use']);

            // navigate('/listAccountType', { state: { showEditToast: true }})
        }

    }, [data, isSuccess, yessuccess,
        nosuccess, updateData])

    useEffect(() => {
        if (isError) {
            if (error.response.status === 405) {
                setConfirmmodal(true)
            }
        }
        else if (isError || yeserror || noerror) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isError, yeserror, noerror])
    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);


    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
        setOpen(false)
        refetchForm()
        // queryClient.invalidateQueries(['Panni Material Use']);
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }
    const noupdatemodal = () => {
        setOpenEditModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {

        delete data.created_at
        delete data.created_by
        let ok = {
            id: id,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditModel(false)
    }


    const [optionsCountry, setOptionsCountry]: any = useState([]);
    const [selectedCountryId, setSelectedCountryId]: any = useState()
    const [valueCountryId, setValueCountryId] = useState('')
    const { data: CountryData, refetch: CountryRefetch } = useFetchCountryDropDown()
    useEffect(() => {
        setOptionsCountry(CountryData)
    }, [CountryData])


    useEffect(() => {
        if (countryId) {
            setSelectedCountryId(countryId)
        }
        if (stateId) {
            setSelectedStateId(stateId)
        }
        if (districtId) {
            setSelectedDistrictId(districtId)
        }

        if (superAccountId) {
            setSelectedSuperAccount(superAccountId)
        }


    }, [countryId, stateId, districtId, superAccountId])




    // In your select component




    const handleChangeCountry = (e: any) => {

        // setApiName(apinameForCountry)
        // setEditId(e?.value)
        // setEditName(countryName)
        // setOpenEditModel(false)
        setValueCountryId(e)
        setValue("country", `${e?.value}`)
        setByState(e?.value)
        // setValueCountryCreatable(null)
        // setByState(e?.value)
        // setCountryValue(e?.value)
        // setCountryName(e?.label)
        // setValueCountry(e)
    }


    //Account Type Page

    const [optionsSuperAccount, setOptionsSuperAccount]: any = useState([]);


    const [valueSuperAccount, setValueSuperAccount] = useState('')
    const [selectedSuperAccount, setSelectedSuperAccount]: any = useState()

    const { data: superAccountData, refetch: superAccountRefetch } = useFetchSuperAccountDropDown()
    useEffect(() => {
        setOptionsSuperAccount(superAccountData)
    }, [superAccountData])




    const handleChangeSuperAccount = (e: any) => {



        setValueSuperAccount(e)
        setValue("superAccountGroup", `${e?.value}`)
    }




    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    };





    return (
        <>
            <Modal

                size='sm'

                show={open} onHide={() => {
                    setShow(false)
                    reset()
                    setOpen(false)
                }} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    {/* <Modal.Title>Brand</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>

                    <form
                        ref={focus}
                        onKeyUp={event => onEnterKey(event)}
                        onSubmit={handleSubmit(onSubmit)}>
                        <div className="card-body" style={{ maxHeight: "80vh" }}>
                            {show && showmessage === "success" ? <Alert color={showmessage} message={"Successfully Added"} /> : ""}
                            {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}

                            <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-sm-12 mt-2">
                                            {/* <label htmlFor="exampleInputEmail1">Brand Name <span style={{color:'red'}}>*</span></label> */}

                                            {isPincode ?
                                                <div className="input-group" data-colorpicker-id={2}>
                                                    <input type="text" className="form-control"
                                                        {...register("pincode", { required: { value: false, message: "Pincode is required" } })}



                                                    //  onChange={handleValue}
                                                    />
                                                    <div className="input-group-append" >
                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                    </div>
                                                </div>
                                                :
                                                <div className="input-group" data-colorpicker-id={2}>
                                                    <input type="text" className="form-control"
                                                        {...register("name", { onChange: handleValue, required: { value: false, message: "Field is required" } })}



                                                    //  onChange={handleValue}
                                                    />
                                                    <div className="input-group-append" >
                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                    </div>
                                                </div>
                                            }
                                            {/* <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" 
                                                        {...register("name",{ required: { value: false, message: "Name is required" }})} 

                                                       
                                                        
                                                        onChange={handleValue}
                                                        />
                                                         <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div> */}
                                            <span className='text-red w-100'>{errors?.name?.message}</span>
                                        </div>
                                        {forState ?
                                            <>
                                                <div className="col-sm-12 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Country Name <span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        isClearable={() => {
                                                            // setValueCountry(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("country", { required: { value: true, message: "Country Name is required" } })}
                                                        placeholder={'Select Country Name'}
                                                        options={optionsCountry}
                                                        // onCreateOption={handleCreateCountry}
                                                        onChange={handleChangeCountry}
                                                        // menuIsOpen={isCountryOpen || OpenEditModel && !isStateOpen}
                                                        value={valueCountryId === '' ? optionsCountry?.find((e: any) => e.value === selectedCountryId) : valueCountryId}
                                                        components={customIndicator}
                                                    />
                                                    <span className='text-red w-100'>{errors?.country?.message}</span>
                                                </div>
                                            </>
                                            :
                                            null}

                                        {forDistrict ?
                                            <>
                                                <div className="col-sm-12 mt-2">
                                                    <label htmlFor="exampleInputEmail1">State Name<span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        isClearable={() => {
                                                            // setValueCountry(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("state", { required: { value: true, message: "State Name is required" } })}
                                                        placeholder={'Select State Name'}
                                                        options={optionsState}
                                                        isDisabled={!byState}
                                                        // onCreateOption={handleCreateCountry}
                                                        onChange={handleChangeState}
                                                        // menuIsOpen={isCountryOpen || OpenEditModel && !isStateOpen}
                                                        value={valueStateId === '' ? optionsState?.find((e: any) => e.value === selectedStateId) : valueStateId}
                                                        // components={customCreatableIndicator}
                                                        components={customIndicator}

                                                    />
                                                    <span className='text-red w-100'>{errors?.state?.message}</span>
                                                </div>
                                            </>
                                            :
                                            null}
                                        {forTaluka ?
                                            <>
                                                <div className="col-sm-12 mt-2">
                                                    <label htmlFor="exampleInputEmail1">District Name<span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        isClearable={() => {
                                                            // setValueCountry(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("district", { required: { value: true, message: "District Name is required" } })}
                                                        placeholder={'Select District Name'}
                                                        options={optionsDistrict}
                                                        isDisabled={!byDistrict}
                                                        // onCreateOption={handleCreateCountry}
                                                        onChange={handleChangeDistrict}
                                                        // menuIsOpen={isCountryOpen || OpenEditModel && !isStateOpen}
                                                        value={valueDistrictId === '' ? optionsDistrict?.find((e: any) => e.value === selectedDistrictId) : valueDistrictId}
                                                        // components={customCreatableIndicator}
                                                        components={customIndicator}

                                                    />
                                                    <span className='text-red w-100'>{errors?.district?.message}</span>
                                                </div>
                                            </>
                                            :
                                            null}


                                        {isSuperAccount ?
                                            <>
                                                <div className="col-sm-12 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Super Account Group<span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        isClearable={() => {
                                                            // setValueCountry(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("superAccountGroup", { required: { value: true, message: "Super Account Group is required" } })}
                                                        placeholder={'Select Super Account Group'}
                                                        options={optionsSuperAccount}
                                                        // onCreateOption={handleCreateCountry}
                                                        onChange={handleChangeSuperAccount}
                                                        // menuIsOpen={isCountryOpen || OpenEditModel && !isStateOpen}
                                                        value={valueSuperAccount === '' ? optionsSuperAccount?.find((e: any) => e.value === selectedSuperAccount) : valueSuperAccount}
                                                        // components={customCreatableIndicator}    
                                                        components={customIndicator}

                                                    />
                                                    <span className='text-red w-100'>{errors?.superAccountGroup?.message}</span>
                                                </div>
                                            </>
                                            :
                                            null}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="info" className="text-white"
                        onClick={handleSubmit(onSubmit)}
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white", opacity: '0.9' }}
                    >
                        Submit
                    </Button>
                    {/* <Button variant="info" className="text-white" style={{ borderColor: "blue", background: "blue", color: "white",opacity:'0.7' }}
 onClick={handleReset}
 >
   Reset
 </Button> */}
                    <Button variant="secondary" style={{ borderColor: "red", background: "red", color: "white", opacity: '0.7' }} onClick={() => { setOpen(false) }}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>



            <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                                {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>
                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>
                </Modal.Footer>
            </Modal>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />
        </>
    )
}



