import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Restricted from "../../Roles/Restricted";
// import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import axios from "axios";
import FileDownload from 'js-file-download';
import { BiDotsVerticalRounded } from "react-icons/bi";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useState } from "react";



const Dispatch_Order = ({ row, apiname, deletemodalask,permissionClass }: any) => {
  const [ask, setAsk]:any = useState(null);
//   document.addEventListener('mouseup', function(e:any) {
//     var container:any = document.getElementById('container');
//     if (!container.contains(e.target)) {
//         container.style.display = 'none';
//     }
// });

  const handleDispatchOrder = () => {
    // return axios.get(
    //   `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SALES_DISPATCH_ORDER_API}/?id`,
    //   {
    //     responseType: "blob",

    //     headers: {
    //       'Authorization': `Bearer ${localStorage.getItem('token')}`,
    //       "Content-Type": "application/json",
    //     },

    //   }
    // );
  };
 

  

  return (
    <>
      <div
      
        className="vertical-align-center"
        style={{
          display: "inline-block !important",
          justifyContent: "space-evenly !important",
        }}
      >

{/* <div className="m-2">
               <button onClick={handleDownloadQuotation} className="btn btn-primary text-white w-100">Dispatch Order</button>
                </div> */}


        {/* <Restricted
          to={`${permissionClass?.front}.change_${permissionClass?.back}`}
          fallback={""}
        > */}



          {/* <Link
            className="btn btn-primary text-white mx-2"
            to={`/quotation/${Number(row.original.id)}`}
          >
            <span className="text-xs">Get a Quotation</span>
          </Link> */}

<OverlayTrigger
// id="container"
          trigger={"focus"}
          key={"bottom"}
          placement={"bottom-end"}
          overlay={
            <Popover >
             
              <Popover.Body >
                <div className="m-2">
                  <Link to={`/addDispatchorder/${row.original.id}`} state={{ detail: row.original }}  className="btn btn-primary text-white mx-2">Dispatch Order</Link>
               {/* <button onClick={handleDispatchOrder} className="btn btn-primary text-white w-100">Download Quotation</button> */}
                </div>
                <div className="m-2">
               {/* <button onClick={(e) => {
              setAsk(e);
            }} className="btn btn-primary text-white w-100 ">E-Mail Quotation</button> */}
                </div>
              </Popover.Body>
            </Popover>
          }
        >



<button
          // onClick={handleSubmitQuotation}
          className="btn btn-primary text-white"
        >
        
          <BiDotsVerticalRounded/>
          {/* <RiDeleteBinLine />  */}
         </button>
        </OverlayTrigger> 

         
        
      </div>
    </>
  );
};

export default Dispatch_Order;

