
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import { useFetch, useUpdateSingle } from "../../hooks/Api";
// import Actions from "../components/Cell/Actions";
// import { updateBrand, useFetchBrands } from "../../hooks/brand";
// import noImage from "../../images/noImage.png"
// import { useFetchGst, useUpdateGst } from "../hooks/GST";
  

interface Data {
  selection: any

  gst_type: any
  gst: any
  grade_type:any
  hsn_qty:any
  hsn_code: any
  particular_sector_name: any
  product_categories:any
  density: any
  created_at: any

  created_by: any
  updated_by: any
  updated_at: any
  status: any;
  actions: any;
}

export const titleofheaders = [
  {
    id: `srno`,
    Header: `Sr No`,
  },
  {
    id:'material_name_type',
    Header: 'Name & Type & GST%',
  },
  
  {
    id: `hsn_qty`,
    Header: `HSN Code Qty`,
   
  },
  {
    id: 'hsn_code',
    Header: 'HSN Code',
  },
  {
    id:'material_type',
    Header: 'Material Type Name',
  },
  {
    id: `product_name_type`,
    Header: "Code & Name & Type",
    
  },
  
  {
    id: `grade_type`,
    Header: `Type & Density & Sutra`,
  },

  {
    id: 'particular_sector_name',
    Header: 'Product Type',


  },
  {
    id: `product_categories`,
    Header: `Product Category`,
  },

  {
    id: 'gst',
    Header: 'GST',


  },

  {
    id: 'gst_type',
    Header: 'GST Type',
  },




  
  
  {
    id: 'density',
    Header: 'Material Density',


  },
  {
    id: 'status',
    Header: 'Status',


  },

  {
    id: 'created_at',
    Header: `Created At`,
  },


  {
    id: 'created_by',
    Header: `Created By`,


  },
  {
    id: 'updated_at',
    Header: `Updated At`,

  },
  {
    id: 'updated_by',
    Header: `Updated By`,

  },

]



export const Columns_Hsn: any = [




  {
    id: `srno`,
    Header: () => <div>Sr<br />No</div>,
    sticky: "left",
  },
  {
    id: `material_name_type`,
    Header: () => <div>Name & Type &<br />Category & HSN & GST%</div>,
    accessor: 'material_name_type',
    sticky: "left",
    Cell: ({ row }: any) =>{
return(
      <div>
       {row.original.grade_type?.material_type_name}::{row.original.particular_sector_name?.name?row.original.particular_sector_name?.name:<span style={{ color: 'red' }}>****</span>}::
       {row.original.product_categories?.name?row.original.product_categories?.name:<span style={{ color: 'red' }}>****</span>}::
       {row.original.hsn_code}::{row.original?.gst?.india_GST}
      </div>
)
  }
  },
 
  {
    id: `product_name_type`,
    Header: () => <div>Use Product Code<br />& Name & Type</div>,
    accessor: 'product_name_type',
    Cell: ({ row }: any) =>

      <div>
        {row.original.product_name_type?row.original.product_name_type:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
      </div>
  },
  {
    id: `hsn_qty`,
    Header: () => <div>Use HSN <br />Code Qty</div>,
    accessor: 'hsn_qty',
    Cell: ({ row }: any) =>

      <div>
        {row.original.hsn_qty?row.original.hsn_qty:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
      </div>
  },
  {
    id: `hsn_code`,
    Header: () => <div>HSN<br />Code</div>,
    accessor: 'hsn_code',
    // sticky: "left",
    Cell: ({ row }: any) =>

      <div>
        {row.original.hsn_code}
      </div>
  },
  {
    id: `material_type`,
    Header: () => <div>Material Type <br /> Name</div>,
    accessor: 'material_type',
    
    Cell: ({ row }: any) =>

      <div>
        {row.original.grade_type?.material_type_name}
      </div>
  },


  {
    id: `particular_sector_name`,
    Header: () => <div>Product<br />Type</div>,
    accessor: 'particular_sector_name',
    Cell: ({ row }: any) =>

      <div>
        {row.original.particular_sector_name?.name ? row.original.particular_sector_name?.name : <span style={{ color: 'red',fontSize:"20px" }}>****</span>}
      </div>
  },

  {
    id: `product_categories`,
    Header: () => <div>Product<br/>Category</div>,
    accessor:'product_categories',
    // sticky:"left",
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.product_categories?.name}
        </div>
    )}

  },
 
  {
    id: `gst_type`,
    Header: () => <div>GST<br />Type</div>,
    accessor: 'gst_type',

    // Cell: ({row}:any) => {
      
    //   const convertToUpper =  row.original.gst_type ? row.original.gst_type : <span style={{ color: 'red',fontSize:"20px" }}>****</span>
    //   if (!convertToUpper) return "";
    //   return convertToUpper?.split("_")?.map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    // }
    Cell: ({ row }: any) =>

    <div>
      {row.original?.gst_type?.name}
    </div>

    // Cell: ({ row }: any) =>

    //   <div>
    //     {row.original.gst_type ? row.original.gst_type : <span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    //   </div>
  },
  {
    id: `gst`,
    Header: () => <div>GST<br/>%</div>,
    accessor: 'gst',
    Cell: ({ row }: any) =>

      <div>
        {row.original?.gst?.india_GST}
      </div>
  },
  {
    id: `density`,
    Header: () => <div>Material<br />Density</div>,
    accessor: 'density',
    Cell: ({ row }: any) =>

      <div>
        {row.original.density}
      </div>
  },


  {
    id: `grade_type`,
    Header: () => <div>Grade Type &<br /> Density & Sutra</div>,
    accessor: 'grade_type',
    
    Cell: ({ row }: any) =>{
return(
      <div>
        {row.original.grade_type?.material_type_name}::{row.original.grade_type?.material_density === "0.000" || row.original.grade_type?.material_density === null 
         ? <span style={{ color: 'red'}}>0.000</span>
         : row.original.grade_type?.material_density}::
        {row.original.grade_type?.grade_sutra?row.original.grade_type?.grade_sutra:<span style={{ color: 'red'}}>****</span>}::{row.original.grade_type?.gst?.india_GST}

       
      </div>
)
    }
  },

  


  

  


  
  





  {
    id: 'status',
    Header:'Status',
    accessor: 'status',
  },




  {
    id: 'created_at',
    Header: () => <div>Created<br />At</div>,
    accessor: 'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div>Created<br />By</div>,
    accessor: 'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username


  },
  {
    id: 'updated_at',
    Header: () => <div>Updated<br />At</div>,
    accessor: 'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div>Updated<br />By</div>,
    accessor: 'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },

  {
    id: 'Actions',
    Header: 'Actions',


  },
  {
    id: 'Selection',
    sticky: "left",
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_Hsn, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows } = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_HSN_API}/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



