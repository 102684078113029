import React from "react"


const IndicatorsContainer = (props: any) => {
  return (
    <div style={{ display: 'inline-flex', background: 'darkseagreen', /* Add other styles here */ }}>
      {props.children}
      
    </div>
  );
};

export default IndicatorsContainer