import React, { useEffect, useState } from 'react';
import "./header.css";
import user from "../../images/user.png"
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';

const Header = ({isDashboard}:any) => {

  const navigate= useNavigate()

  const[loggedIn,setLoggedIn] = useState(localStorage.token?true:false)
  // const token = localStorage.getItem('token')



  const handleLogout=()=>{
    localStorage.removeItem("refresh")
    localStorage.removeItem("token")


    navigate("/login")
  }



const [fontSize,setFontSize] = useState(100)
const [width,setWidth] = useState(16)
const [bodywidth,setBodywidth] = useState(13)


const [isRightState, setIsRightState]:any = useState(true);
const [activeButton, setActiveButton]:any = useState('right');

const handleButtonClick = (isRight:any) => {
  setIsRightState(isRight);
  setActiveButton(isRight ? 'right' : 'left');
  
};


const handleLanguage=()=>{

}



  
  return (
    <>
    <nav className="main-header navbar  navbar-light navbar-white">
    <div className="container-fluid">
     
      <div className="" id="navbarCollapse">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
          </li>


          
          
        </ul>
      </div>



{isDashboard && <>

  <div className="offset-lg-4 offset-md-0 offset-sm-0 ">
        <div className={`button-group ${isRightState ? 'left' : 'right'}`}>
      <div
        className={`button ${activeButton === 'right' ? 'active' : ''} right`}
        onClick={() => {
          navigate("/salesDashboard")
          handleButtonClick(true)}}
      >
        Sales
      </div>
      <div
        className={`button ${activeButton === 'left' ? 'active' : ''} left`}
        onClick={() => {
          navigate("/marketingDashboard")
          handleButtonClick(false)}}
      >
        Marketing
      </div>
    </div>
    </div>

</>}




		

      
      
      <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">






      <div className='d-flex rounded-3 h-0' style={{background:"#003665"}}>

<li className="nav-item mx-2 text-white " style={{paddingTop:"10px"}}>
    <label>{fontSize+"%"}</label>
  </li>
<li className=""style={{paddingTop:"8px"}}>
    <button className="btn text-light border-light px-2 py-0 m-0"  onClick={() => {
      const addingstyling: any = document.querySelector(".content-wrapper")
      const adding: any = document.querySelector(".os-content")
      setWidth(width+3);
      setBodywidth(bodywidth+3);
      if(addingstyling)addingstyling.style.zoom =`${fontSize+10}%`;
      if(adding)adding.style.zoom =`${fontSize+10}%`;
      setFontSize(fontSize+10)
    }}>+</button>
  </li>
 
  <li className=" mx-2 " style={{paddingTop:"8px"}}>
    <button className="btn text-light border-light px-2 py-0 m-0" onClick={() => {
      const addingstyling: any = document.querySelector(".content-wrapper")
      const adding: any = document.querySelector(".os-content")
     


      setWidth(width-3);
      setBodywidth(bodywidth-3);
      if(addingstyling)addingstyling.style.zoom =`${fontSize-10}%`;
      if(adding)adding.style.zoom =`${fontSize-10}%`;
      setFontSize(fontSize-10)
     
    }}>-</button>
  </li>

  <li className="nav-item mx-2 " style={{paddingTop:"8px"}}>
    <button className="btn text-light border-light px-2 py-0 m-0" onClick={() => {
            const addingstyling: any = document.querySelector(".content-wrapper")
            const adding: any = document.querySelector(".os-content")
            if(addingstyling)addingstyling.style.zoom =`${100}%`;
            if(adding)adding.style.zoom =`${100}%`;
      
       setFontSize(100)
     setWidth(16)
    }}>
      Reset
      </button>
  </li>
</div>


        
        <li className="nav-item mx-1">
          <div style={{display:'grid'}}>
          <img src={user} alt='' className="mx-3" height='30px'/>
          <p style={{paddingLeft:'10px'}}>{localStorage?.Username}</p>
          </div>
        </li>
        <li className="nav-item mx-2">
        {loggedIn?<a className="nav-link border border-2 rounded-2 border-danger btn-outline-danger" href="#" onClick={handleLogout}>
        <FontAwesomeIcon icon={faArrowRightFromBracket} color='black'/>  
          <span style={{marginLeft:"10px",color:"black"}}>

          Logout</span>
          
          </a>:<a className="nav-link" href="#" >Login</a>}
        </li>




      </ul>
    </div>
  </nav>




{/* <div className="">
  <div className="row">
  <nav className="main-header navbar  navbar-light navbar-white">
    <div className="container-fluid">
    <div className="col-sm col-lg-2">

    <div className="" id="navbarCollapse">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
          </li>


          
          
        </ul>
      </div>
      One of three columns
    </div>
    <div className="col-sm">


    {isDashboard && <>

<div className="offset-lg-1">
      <div className={`button-group ${isRightState ? 'left' : 'right'}`}>
    <div
      className={`button ${activeButton === 'right' ? 'active' : ''} right`}
      onClick={() => {
        navigate("/salesDashboard")
        handleButtonClick(true)}}
    >
      Sales
    </div>
    <div
      className={`button ${activeButton === 'left' ? 'active' : ''} left`}
      onClick={() => {
        navigate("/marketingDashboard")
        handleButtonClick(false)}}
    >
      Marketing
    </div>
  </div>
  </div>

</>}


      One of three columns
    </div>
    <div className="col-sm">



    <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">






<div className='d-flex rounded-3 h-0' style={{background:"#003665"}}>

<li className="nav-item mx-2 text-white " style={{paddingTop:"10px"}}>
<label>{fontSize+"%"}</label>
</li>
<li className=""style={{paddingTop:"8px"}}>
<button className="btn text-light border-light px-2 py-0 m-0"  onClick={() => {
const addingstyling: any = document.querySelector(".content-wrapper")
const adding: any = document.querySelector(".os-content")
setWidth(width+3);
setBodywidth(bodywidth+3);
if(addingstyling)addingstyling.style.zoom =`${fontSize+10}%`;
if(adding)adding.style.zoom =`${fontSize+10}%`;
setFontSize(fontSize+10)
}}>+</button>
</li>

<li className=" mx-2 " style={{paddingTop:"8px"}}>
<button className="btn text-light border-light px-2 py-0 m-0" onClick={() => {
const addingstyling: any = document.querySelector(".content-wrapper")
const adding: any = document.querySelector(".os-content")



setWidth(width-3);
setBodywidth(bodywidth-3);
if(addingstyling)addingstyling.style.zoom =`${fontSize-10}%`;
if(adding)adding.style.zoom =`${fontSize-10}%`;
setFontSize(fontSize-10)

}}>-</button>
</li>

<li className="nav-item mx-2 " style={{paddingTop:"8px"}}>
<button className="btn text-light border-light px-2 py-0 m-0" onClick={() => {
      const addingstyling: any = document.querySelector(".content-wrapper")
      const adding: any = document.querySelector(".os-content")
      if(addingstyling)addingstyling.style.zoom =`${100}%`;
      if(adding)adding.style.zoom =`${100}%`;

 setFontSize(100)
setWidth(16)
}}>
Reset
</button>
</li>
</div>


  
  <li className="nav-item mx-1">
    <img src={user} alt='' className="mx-3" />
  </li>
  <li className="nav-item mx-2">
  {loggedIn?<a className="nav-link border border-2 rounded-2 border-danger btn-outline-danger" href="#" onClick={handleLogout}>
  <FontAwesomeIcon icon={faArrowRightFromBracket} color='black'/>  
    <span style={{marginLeft:"10px",color:"black"}}>

    Logout</span>
    
    </a>:<a className="nav-link" href="#" >Login</a>}
  </li>




</ul>
</div>
      
      One of three columns
    </div>
    </nav>
  </div>
</div> */}
</>
  )
}

export default Header