
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../../components/Cell/Status";
import Actions from "../../../components/Cell/Actions";
import noImage from "../../../images/noImage.png"
import { Update, useFetch } from "../../../hooks/Api";
import Modal from "react-bootstrap/esm/Modal";

interface Data {
    selection: any

    person_type:any
    employee_code:any
    type:any
    document_number:any
    pdf:any



    created_at:any

    created_by: any
    updated_by: any
    updated_at: any
    status:any
    actions: any;
}



export const titleofheaders=[
    {
      id: `srno`,
      Header: `Sr No`,
    },


    {
        id: `person_type`,
        Header: `Product Type`,
      },


      {
        id: `employee_code`,
        Header: `Employee Code`,
      },

      {
        id: `type`,
        Header: `Document Type`,
      },

      {
        id: `document_number`,
        Header: `Document Number`,
      },

      {
        id: `pdf`,
        Header: `PDF`,
      },
      {
        id: 'created_at',
        Header: `Created At`,
      },
    
    
      {
        id: 'created_by',
        Header: `Created By`,
    
     
      },
      {
        id: 'updated_at',
        Header: `Updated At`,
    
      },
      {
        id: 'updated_by',
        Header: `Updated By`,
    
      },
   
    {
      id: 'status',
      Header: 'Status',
    },
  ]

export const Columns_Sales_Document: any = [



   
    {
        id: `srno`,
        Header: () => <div style={{display:"inline"}}>Sr.<br/>No</div>,
        sticky:"left",
    },


    // {
    //     id: `person_type`,
    //     Header: () => <div style={{display:"inline"}}>Person<br/>Type</div>,
    //     accessor:'person_type',  
    //     sticky:"left",
    //     Cell: ({ row }: any) => row.original.person_type?.name
    // },


    //   {
    //     id: `employee_code`,
    //     Header: () => <div style={{display:"inline"}}>Code & Full Name<br/>& City</div>,
    //     accessor:'employee_code',  
    //     Cell: ({ row }: any) => row.original.employee_code?.code
    // },


  //   {
  //     id: `account_type`,
  //     Header: () => <div style={{display:"inline"}}>Account<br/>Type</div>,
  //     accessor:'account_type',  
  //     Cell: ({ row }: any) => row.original.employee_code?.group?.accountStatementType?.name
  // },


//   {
//     id: `country`,
//     Header: () => <div style={{display:"inline"}}>Country<br/>Name</div>,
//     accessor:'country',  
//     Cell: ({ row }: any) => row.original.employee_code?.city_name_and_distict_name?.country?.name
// },

      {
        id: `document_type`,
       Header: () => <div style={{display:"inline"}}>Document<br/>Name</div>,
       accessor:'document_type',  
       sticky:"left",
       Cell: ({ row }: any) => row.original.document_type
    },

    {
      id: `in_print_name`,
     Header: () => <div style={{display:"inline"}}>Document In<br/>Print Name</div>,
     accessor:'in_print_name',  
     Cell: ({ row }: any) => row.original.in_print_name
  },

      {
        id: `document_number`,
        Header: () => <div style={{display:"inline"}}>Document<br/>Number</div>,
        accessor:'document_number',  
        Cell: ({ row }: any) => row.original.document_number
    },


    {
      id: `remark`,
      Header: () => <div style={{display:"inline"}}>Re-Mark</div>,
      accessor:'remark',  
      Cell: ({ row }: any) => row.original.remark?row.original.remark:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },




  {
    id: `image`,
    Header: () => <div style={{ display: "inline" }}>Document<br />Image All</div>,

    // accessor: `logo`,
    Cell: ({ row }: any) => {

      const [deleteConfirm, setDeleteConfirm] = useState(false)


      return (
        <>

          <div >

            {!row.original.image ? <>
              <a

                href="#"

              // data-large-src={row.original.image?row.original.image:noImage} data-toggle="modal"
              >
                <img src={noImage} height={60} width={100} />
              </a>
            </> : <>
              <a
                onClick={() => setDeleteConfirm(true)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.image.length > 0 ? row.original.image.at(-1).doc : noImage)} data-toggle="modal">
                <img src={(row.original.image.length > 0 ? row.original.image.at(-1).doc : noImage)} height={60} width={100} />
              </a>
            </>}

          </div>








          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            <Modal.Body>
              <img src={(row.original.image?.length > 0 ? row.original.image.at(-1).doc : noImage)} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid" />

            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>)
    }


  },



  {
    id: `pdf`,
    Header: () => <div style={{ display: "inline" }}>Document<br />PDF All</div>,

    // accessor: `pdf`,
    Cell: ({ row }: any) =>



      <div>
        <a target="_blank" href={(row.original.pdf?.length > 0 ? row.original.pdf?.at(-1).doc : null)}>
          {(row.original.pdf?.length > 0 ? row.original.pdf?.at(-1).doc?.split('/').pop() : <span className="text-danger">No Document Uploaded</span>)}
        </a>
      </div>

  },




  {
    id: 'status',
    Header: 'Status',
    accessor:'status',

 
  },

    //   {
    //     id: `pdf`,
    //     Header: `PDF`,
    //     accessor:'pdf',  
    // },


    


      {
        id: 'created_at',
        Header: () => <div style={{display:"inline"}}>Created<br/>At</div>,
        accessor:'created_at',
        Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
      },
    
    
      {
        id: 'created_by',
        Header: () => <div style={{display:"inline"}}>Created<br/>By</div>,
        accessor:'created_by',
        Cell: ({ row }: any) => row.original.created_by?.username
    
     
      },
      {
        id: 'updated_at',
        Header: () => <div style={{display:"inline"}}>Updated<br/>At</div>,
        accessor:'updated_at',
        Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
      },
      {
        id: 'updated_by',
        Header: () => <div style={{display:"inline"}}>Updated<br/>By</div>,
        accessor:'updated_by',
        Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
      },
    
   

    {
        id: 'Stepper_Actions',
        Header: 'Actions',


    },


    {
        id: 'Selection',
        Header: ({ getToggleAllRowsSelectedProps }: any) => {
            const [data, setData] = useState([])

            const columns: any = useMemo(() => Columns_Sales_Document, [])
            const defaultColumn: any = useMemo(() => {
                return {
                    Filter: Columnlevel
                }
            }
                , [])

            const tableInstance: any = useTable({
                columns,
                data: data,
                defaultColumn,

            },


            )
            const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
            let { selectedFlatRows } = tableInstance
            const { } = useMutation((deleteid: any) => {
                const temp_data = {
                    id: deleteMultipleid
                }
                return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DOCUMENT_DETAIL_API}/deletelist/`, temp_data,
                    {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    });
            });
            return (
                <>
                    <div>
                        <Checkbox

                            onClick={() => {
                                setDeleteMultipleid(() => {
                                    const temp = selectedFlatRows?.map((e: any) => {
                                        return e.original.id;
                                    });
                                    return temp;
                                });
                            }}

                            {...getToggleAllRowsSelectedProps()} />
                    </div>
                </>
            );
        },

        Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
            // Add your onClick function here
        }} />
    },
]
