
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import Actions from "../../components/Cell/Actions";
// import { updateBrand, useFetchBrands } from "../../hooks/brand";
import noImage from "../../images/noImage.png"
import { useFetchGst, useFetchProductType, useUpdateGst, useUpdateProductType } from "../../hooks/GST";
import { Update, useFetch } from "../../hooks/Api";
import Modal from "react-bootstrap/esm/Modal";


interface Data {
  selection: any
  
  name_and_grade:any
  color:any
  brand:any
  hsn_code:any
  gst:any
  printing_color:any

 
  tape_roll_code: any
  tape_roll_special_running: any,
  bursting_strength: any,
  printing: any
  printing_type: any
  printing_total_color: any
  width: any
  thickness_film_micron: any
  gum_name: any
  tape_roll_1_nos_meter: any
  gum_ler_micron: any
  tape_roll_1_meter_weight: any
  tape_roll_1_card_board_weight: any
  tape_roll_1_nos_weight: any
  tape_roll_1_bundle_weight: any
  tape_roll_bundle_in_carton: any
  tape_roll_carton_net_weight_kg: any
  tape_roll_1_Bundle_Nos: any
  box_image:any
  created_at:any

  created_by:any
  updated_by:any
  updated_at:any
  status: any;
  actions: any;
}

export const titleofheaders=[
  {
    id: `srno`,
    Header: "Sr No",
  },


  {
    id: `tape_roll_code`,
    Header: `Tape Roll Code`,
  },

  {
    id: `name_and_grade`,
    Header: `Name And Grade`,
  },

  {
    id: `color`,
    Header: `Color`,
},
  
{
  id: `printing`,
  Header: `Printing`,
},

  {
    id: `brand`,
    Header: `Brand`,
  },
  {
    id: `type`,
    Header: `Brand Type`,
  },


  {
    id: `printing_type`,
    Header: `Printitng Type`,
  },
  {
    id: `printing_color`,
    Header: `Printing Color`,
  },
  {
    id: `printing_total_color`,
    Header: `Printing Total Color`,
  },

  {
    id: `width`,
    Header: `Width`,
  },
  {
    id: `thickness_film_micron`,
    Header: `Thickness Film Micron`,
  },


  {
    id: `tape_roll_1_nos_meter`,
    Header: `Tape Roll 1nos Meter`,
  },

  {
    id: `gum_name`,
    Header: `Gum Name`,
  },
  
  {
    id: `gum_ler_micron`,
    Header: `Gum Micron`,
  },

  
  {
    id: `tape_roll_1_meter_weight`,
    Header: `Tape Roll 1 Meter Weight`,
  },
  {
    id: `tape_roll_1_card_board_weight`,
    Header: `Tape Roll 1 Puthu Weight`,
  },
  {
    id: `tape_roll_1_nos_weight`,
    Header: `Tape Roll 1 NOS Weight`,
  },

  {
    id: `tape_roll_1_Bundle_Nos`,
    Header: `Tape Roll 1 Bundle Nos`,
  },

  {
    id: `tape_roll_1_bundle_weight`,
    Header: `Tape Roll 1 Bundle Weight`,
  },
  {
    id: `tape_roll_bundle_in_carton`,
    Header: `Tape Roll Bundle In Carton`,
  },
  {
    id: `tape_roll_carton_net_weight_kg`,
    Header: `Tape Roll Carton Net Weight Kg`,

  },

  {
    id: `tape_roll_special_running`,
    Header: `Tape Roll`,
   
  },



  {
    id: `hsn_code`,
    Header: `Hsn Code`,
  },

  {
    id: `gst`,
    Header: `GST`,
  },

  {
    id: `bursting_strength`,
    Header: `Bursting Strenth`,
  },
  {
    id: 'status',
    Header: 'Status',

  },
  {
    id: `box_image`,
    Header: `Box Image`,
  },
  {
    id: `packet_image`,
    Header: `Packet Image`,
  },
  {
    id: `tape_roll_image`,
    Header: `Tape Roll Image`,
  },
 
  {
    id: `tape_roll_drawing`,
    Header: `Tape Roll PDF`,
  },
 
  {
    id: 'created_at',
    Header: 'Created at',
    
  },


  {
    id: 'created_by',
    Header: 'Created By',
    

 
  },
  {
    id: 'updated_at',
    Header: 'Updated At',
    
  },
  {
    id: 'updated_by',
    Header: 'Updated By',
    
  },

  {
    id: 'Actions',
    Header: 'Actions',

  
  },
]



export const Columns_BoxPatti: any = [



  
  {
    id: `srno`,
    Header: () => <div style={{display:"inline"}}>Sr<br/>No</div>,
    sticky:"left",
  },


  {
    id: `box_patti_code`,
    Header: () => <div style={{display:"inline"}}>Strap Roll<br/>Code</div>,
    // Header: `Tape Roll Code`,
    accessor:`box_patti_code`,
    sticky:"left",
    Cell: ({ row }: any) => row.original.box_patti_code
  },
  {
    id: 'name_and_type',
    Header: () => <div style={{ display: "inline" }}>Material Name &<br />Type & Category</div>,
    // Header: 'Name And Grade',
    accessor: 'name_and_type',
    // sticky: "left",
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.material_name_and_type_category_and_hsn_code?.grade_type?.material_type_name} {row.original.material_name_and_type_category_and_hsn_code?.particular_sector_name?.name} {row.original.material_name_and_type_category_and_hsn_code?.product_categories?.name}
        </div>
      )
    }

  },
  {
    id: 'hsn_code',
    Header: () => <div style={{ display: "inline" }}>Material Name & Type &<br />Category & HSN & GST%</div>,
    // Header: 'Hsn Code',
    accessor: 'hsn_code',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.material_name_and_type_category_and_hsn_code?.grade_type?.material_type_name}::
          {row.original.material_name_and_type_category_and_hsn_code?.particular_sector_name?.name === "0.000" || row.original.material_name_and_type_category_and_hsn_code?.particular_sector_name?.name === null ? <span style={{ color: 'red', fontSize: "20px" }}>0.000</span> : row.original.material_name_and_type_category_and_hsn_code?.particular_sector_name?.name}::
          {row.original.material_name_and_type_category_and_hsn_code?.product_categories?.name === "0.000" || row.original.material_name_and_type_category_and_hsn_code?.product_categories?.name === null ? <span style={{ color: 'red', fontSize: "20px" }}>****</span> : row.original.material_name_and_type_category_and_hsn_code?.product_categories?.name}::
          {row.original.material_name_and_type_category_and_hsn_code?.hsn_code}::
          {row.original.material_name_and_type_category_and_hsn_code?.gst?.india_GST}
        </div>
      )
    }

  },
  {
    id: 'gst_type',
    Header: () => <div style={{ display: "inline" }}>GST<br />Type</div>,
    // Header: 'Hsn Code',
    accessor: 'gst_type',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row?.original?.material_name_and_type_category_and_hsn_code?.gst_type?.name}
        </div>
      )
    }
  },
  {
    id: 'type_name',
    Header: () => <div style={{ display: "inline" }}>Type Name & Density<br />& Sutra & GST % </div>,
    // Header: 'Hsn Code',
    accessor: 'type_name',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.material_name_and_type_category_and_hsn_code?.grade_type?.material_type_name}::{row.original.material_name_and_type_category_and_hsn_code?.grade_type?.material_density ? row.original.material_name_and_type_category_and_hsn_code?.grade_type?.material_density : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}::
          {row.original.material_name_and_type_category_and_hsn_code?.grade_type?.grade_sutra ? row.original.material_name_and_type_category_and_hsn_code?.grade_type?.grade_sutra : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}::{row.original.material_name_and_type_category_and_hsn_code?.grade_type?.gst?.india_GST}
        </div>
      )
    }
  },
  {
    id: 'use_code_name',
    Header: () => <div style={{ display: "inline" }}>Use Product Code<br /> & Grade & Name & Type</div>,
    // Header: 'Hsn Code',
    accessor: 'use_code_name',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.hsn_code?.use_code_name ? row.original.hsn_code?.use_code_name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }
  },
  {
    id: 'use_code_qty',
    Header: () => <div style={{ display: "inline" }}>Use Product<br />Code Qty</div>,
    // Header: 'Hsn Code',
    accessor: 'use_code_qty',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.hsn_code?.use_code_qty ? row.original.hsn_code?.use_code_qty : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }
  },

  {
    id: `material_type`,
    Header: () => <div style={{display:"inline"}}>Material<br/>Type</div>,
    accessor:`material_type`,
    Cell: ({ row }: any) => row.original.material_type?.name
  },

  {
    id: `color`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Color Name</div>,
    accessor:`color`,
    Cell: ({ row }: any) => row.original.color?.company_color_name?row.original.color?.company_color_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },

  {
    id: `width`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Width mm</div>,
    accessor:`width`,
    Cell: ({ row }: any) => row.original.width?row.original.width:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },

  {
    id: `roll_net_kg`,
    Header: () => <div style={{display:"inline"}}>Roll<br/>Net kg.</div>,
    accessor:`roll_net_kg`,
    Cell: ({ row }: any) => row.original.roll_net_kg?row.original.roll_net_kg:<span style={{ color: 'red',fontSize:"20px" }}>****</span>	
  },

  {
    id: `thickness_micron`,
    Header: () => <div style={{display:"inline"}}>Thickness<br/>Micron</div>,
    accessor:`thickness_micron`,
    Cell: ({ row }: any) => row.original.thickness_micron	
  },


  {
    id: `thickness_gauge`,
    Header: () => <div style={{display:"inline"}}>Thickness<br/>Gauge</div>,
    accessor:`thickness_gauge`,
    Cell: ({ row }: any) => row.original.thickness_gauge	
  },

  
  {
    id: `one_meter_strap_patti_gram`,
    Header: () => <div style={{display:"inline"}}>1 Meter Strap<br/>Patti Gram</div>,
    accessor:`one_meter_strap_patti_gram`,
    Cell: ({ row }: any) => row.original.one_meter_strap_patti_gram?row.original.one_meter_strap_patti_gram:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },
  {
    id: `strap_patti_net_1_kg_in_meter`,
    Header: () => <div style={{display:"inline"}}>Strap Patti Net<br/>1 Kg. In Meter</div>,
    accessor:`strap_patti_net_1_kg_in_meter`,
    Cell: ({ row }: any) => row.original.strap_patti_net_1_kg_in_meter?row.original.strap_patti_net_1_kg_in_meter:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },
  {
    id: `strap_patti_roll_in_length_meter`,
    Header: () => <div style={{display:"inline"}}>Strap Patti Roll<br/>In Length Meter</div>,
    accessor:`strap_patti_roll_in_length_meter`,
    Cell: ({ row }: any) => row.original.strap_patti_roll_in_length_meter?row.original.strap_patti_roll_in_length_meter:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },
  {
    id: `paper_conr_weight_gram`,
    Header: () => <div style={{display:"inline"}}>Paper Conr<br/>Weight Gram</div>,
    accessor:`paper_conr_weight_gram`,
    Cell: ({ row }: any) => row.original.paper_conr_weight_gram?row.original.paper_conr_weight_gram:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },
  {
    id: `strap_patti_plus_corn_kg`,
    Header: () => <div style={{display:"inline"}}>Strap Patti<br/>+ Conr Kg.</div>,
    accessor:`strap_patti_plus_corn_kg`,
    Cell: ({ row }: any) => row.original.strap_patti_plus_corn_kg?row.original.strap_patti_plus_corn_kg:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },
  {
    id: `strap_patti_packing_roll_qty`,
    Header: () => <div style={{display:"inline"}}>Strap Patti Packing<br/>Roll Qty</div>,
    accessor:`strap_patti_packing_roll_qty`,
    Cell: ({ row }: any) => row.original.strap_patti_packing_roll_qty?.name?row.original.strap_patti_packing_roll_qty?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },
  {
    id: `strap_patti_packing_weight_kg`,
    Header: () => <div style={{display:"inline"}}>Strap Patti Packing<br/>Weight Kg.</div>,
    accessor:`strap_patti_packing_weight_kg`,
    Cell: ({ row }: any) => row.original.strap_patti_packing_weight_kg?row.original.strap_patti_packing_weight_kg:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },
  {
    id: `strap_patti_roll_in_packing_bori`,
    Header: () => <div style={{display:"inline"}}>Strap Patti Roll<br/>In Packing Bori</div>,
    accessor:`strap_patti_roll_in_packing_bori`,
    Cell: ({ row }: any) => row.original.strap_patti_roll_in_packing_bori?.name?row.original.strap_patti_roll_in_packing_bori?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },

 

  {
    id: `printing`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Printing</div>,
    accessor:`printing`,
    Cell: ({row}:any) => {
      const convertToUpper = row.original.printing
      if (!convertToUpper) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
     
    }
  },


  {
    id: `printing_type`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Printing Type</div>,
    accessor:`printing_type`,
    Cell: ({row}:any) => {
      const convertToUpper = row.original.printing_type?.name
      if (!convertToUpper) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
     
    }
  },


  {
    id: `printing_color`,
    Header: () => <div style={{display:"inline"}}>Printing<br/>Color Name</div>,
    accessor:`printing_color`,
    Cell: ({row}:any) => {
      let Printing_Color = row.original.printing_color?.map((e:any)=>{
         return e.name
                
         })
      if (Printing_Color.length === 0) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return Printing_Color.join(",")
     
    }
  },


  
  {
    id: `printing_total_color`,
    Header: () => <div style={{display:"inline"}}>Printing Total<br/>Color</div>,
    accessor:`printing_total_color`,
    Cell: ({ row }: any) => row.original.printing_total_color?row.original?.printing_total_color:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },

  {
    id: `brand`,
    Header: () => <div style={{display:"inline"}}>Printing<br/>Brand Name</div>,
    accessor:`brand`,
    Cell: ({row}:any) => {
      let Brand = row.original.brand?.map((e:any)=>{
         return e.name
                
         })
      if (Brand.length === 0) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return Brand.join(",")
     
    }
    // Cell: ({ row }: any) => row.original.brand?.name?row.original.brand?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },


  {
    id: `brand_our_other`,
    Header: () => <div style={{display:"inline"}}>Brand<br/>Our - Other</div>,
    accessor:`brand_our_other`,
    // Cell: ({ row }: any) => row.original.brand_our_other?row.original.brand_our_other:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
    Cell: ({row}:any) => {
      const convertToUpper = row.original.brand_our_other
      if (!convertToUpper) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
     
    }
  },


  {
    id: `product_stock_minimum_qty`,
    Header: () => <div style={{display:"inline"}}>Product Stock<br/>Minimum Qty</div>,
    accessor:`product_stock_minimum_qty`,
    Cell: ({ row }: any) => row.original.product_stock_minimum_qty	
  },


  
  {
    id: `product_stock_maximum_qty`,
    Header: () => <div style={{display:"inline"}}>Product Stock<br/>Maximum Qty</div>,
    accessor:`product_stock_maximum_qty`,
    Cell: ({ row }: any) => row.original.product_stock_maximum_qty	
  },


  {
    id: `bursting_strength`,
    Header: () => <div style={{display:"inline"}}>Bursting<br/>Strength</div>,
    accessor:`bursting_strength`,
    Cell: ({ row }: any) => row.original.bursting_strength	
  },


  {
    id: `product_by`,
    Header: () => <div style={{display:"inline"}}>Product<br/>By</div>,
    accessor:`product_by`,
    Cell: ({ row }: any) => row.original.product_by?.name
  },

  {
    id: `product_our_other`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Our - Other</div>,
    accessor:`product_our_other`,
    Cell: ({ row }: any) => row.original.product_our_other?.name
  },

  {
    id: `taperoll_type`,
    Header: () => <div style={{display:"inline"}}>Taperoll<br/>Type</div>,
    accessor:`taperoll_type`,
    Cell: ({ row }: any) => row.original.taperoll_type?.name
  },

  {
    id: `product_maximum_debit_day`,
    Header: () => <div style={{display:"inline"}}>Product Maximum<br/>Debit Day</div>,
    accessor:`product_maximum_debit_day`,
    Cell: ({ row }: any) => row.original.product_maximum_debit_day	
  },


  {
    id: `product_star`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Star</div>,
    accessor:`product_star`,
    Cell: ({ row }: any) => row.original.product_star?.name
  },


  {
    id: `product_location`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Location</div>,
    accessor:`product_location`,
    Cell: ({ row }: any) => row.original.product_location?.name
  },


  {
    id: 'material_name',
    Header: () => <div style={{ display: "inline" }}>Material<br />Name</div>,
    accessor:'material_name',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.material_name_and_type_category_and_hsn_code?.grade_type?.material_type_name}
        </div>
      )
    }

  },
 
  {
    id: 'material_type_name',
    Header: () => <div style={{ display: "inline" }}>Material<br />Type</div>,
    accessor:'material_type_name',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.material_name_and_type_category_and_hsn_code?.particular_sector_name?.name}
        </div>
      )
    }

  },

  {
    id: 'material_category',
    Header: () => <div style={{ display: "inline" }}>Material<br />Category</div>,
    accessor:'material_category',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.material_name_and_type_category_and_hsn_code?.product_categories?.name}
        </div>
      )
    }

  },
  
  
  {
    id: 'material_hsn',
    Header: () => <div style={{ display: "inline" }}>HSN<br />Code</div>,
    accessor:'material_hsn',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.material_name_and_type_category_and_hsn_code?.hsn_code}
        </div>
      )
    }

  },
 
  {
    id: 'material_gst',
    Header: () => <div style={{ display: "inline" }}>Product<br />GST %</div>,
    accessor:'material_gst',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.material_name_and_type_category_and_hsn_code?.gst?.india_GST}
        </div>
      )
    }

  },
  {
    id: 'product_density',
    Header: () => <div style={{ display: "inline" }}>Product<br />Density</div>,
    accessor:'product_density',
    Cell: ({ row }: any) => {
      return(
        <div>
         {row.original.material_name_and_type_category_and_hsn_code?.product_density?row.original.material_name_and_type_category_and_hsn_code?.product_density:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

        </div>
      )
    }

  },





  {
    id: `remark`,
    Header: () => <div style={{display:"inline"}}>Re-Mark<br/></div>,
    accessor:`remark`,
    Cell: ({ row }: any) => row.original.remark?row.original.remark:<span style={{ color: 'red',fontSize:"20px" }}>****</span>
  },


  {
    id: 'status',
    Header: 'Status',
    accessor:'status',
  
  },
  {
    id: 'created_at',
    Header: () => <div style={{ display: "inline" }}>Created<br />At</div>,
    accessor: 'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{ display: "inline" }}>Created<br />By</div>,
    accessor: 'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username


  },
  {
    id: 'updated_at',
    Header: () => <div style={{ display: "inline" }}>Updated<br />At</div>,
    accessor: 'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{ display: "inline" }}>Updated<br />By</div>,
    accessor: 'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },





  {
    id: `full_packing_images`,
    Header: () => <div style={{ display: "inline" }}>Full Packing<br />Image All</div>,
    Cell: ({ row }: any) => {
      const [deleteConfirm, setDeleteConfirm] = useState(false)
      return (
        <>
          <div >

            {!row.original.full_packing_images ? <>
              <a

                href="#"

              >
                <img src={noImage} height={60} width={100} />
              </a>
            </> : <>
              <a
                onClick={() => setDeleteConfirm(true)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.full_packing_images.length > 0 ? row.original.full_packing_images.at(-1).doc : noImage)} data-toggle="modal">
                <img src={(row.original.full_packing_images.length > 0 ? row.original.full_packing_images.at(-1).doc : noImage)} height={60} width={100} />
              </a>
            </>}

          </div>
          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            <Modal.Body>
              <img src={(row.original.full_packing_images?.length > 0 ? row.original.full_packing_images.at(-1).doc : noImage)} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid" />

            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>)
    }
  },



  {
    id: `product_packet_packing_images`,
    Header: () => <div style={{ display: "inline" }}>Product Packet<br />Packing Image All</div>,
    Cell: ({ row }: any) => {
      const [deleteConfirm, setDeleteConfirm] = useState(false)
      return (
        <>
          <div >

            {!row.original.product_packet_packing_images ? <>
              <a

                href="#"

              >
                <img src={noImage} height={60} width={100} />
              </a>
            </> : <>
              <a
                onClick={() => setDeleteConfirm(true)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.product_packet_packing_images.length > 0 ? row.original.product_packet_packing_images.at(-1).doc : noImage)} data-toggle="modal">
                <img src={(row.original.product_packet_packing_images.length > 0 ? row.original.product_packet_packing_images.at(-1).doc : noImage)} height={60} width={100} />
              </a>
            </>}

          </div>
          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            <Modal.Body>
              <img src={(row.original.product_packet_packing_images?.length > 0 ? row.original.product_packet_packing_images.at(-1).doc : noImage)} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid" />

            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>)
    }
  },




  {
    id: `product_images`,
    Header: () => <div style={{ display: "inline" }}>Product<br />Image All</div>,
    Cell: ({ row }: any) => {
      const [deleteConfirm, setDeleteConfirm] = useState(false)
      return (
        <>
          <div >

            {!row.original.product_images ? <>
              <a

                href="#"

              >
                <img src={noImage} height={60} width={100} />
              </a>
            </> : <>
              <a
                onClick={() => setDeleteConfirm(true)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.product_images.length > 0 ? row.original.product_images.at(-1).doc : noImage)} data-toggle="modal">
                <img src={(row.original.product_images.length > 0 ? row.original.product_images.at(-1).doc : noImage)} height={60} width={100} />
              </a>
            </>}

          </div>
          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            <Modal.Body>
              <img src={(row.original.product_images?.length > 0 ? row.original.product_images.at(-1).doc : noImage)} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid" />

            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>)
    }
  },
 




    


  {
    id: `product_pdfs_drawing`,
    Header: () => <div style={{ display: "inline" }}>Product PDF<br />Drawing All</div>,

    // accessor: `pdf`,
    Cell: ({ row }: any) =>



      <div>
        <a target="_blank" href={(row.original.product_pdfs_drawing?.length > 0 ? row.original.product_pdfs_drawing?.at(-1).doc : null)}>
          {(row.original.product_pdfs_drawing?.length > 0 ? row.original.product_pdfs_drawing?.at(-1).doc?.split('/').pop() : <span className="text-danger">No Document Uploaded</span>)}
        </a>
      </div>

  },


       
  


      

  {
    id: 'Actions',
    Header: 'Actions',

  
  },

  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_BoxPatti, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows} = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}boxPatti/boxPatti/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



