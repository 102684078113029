import react from "react"

export const Alert = (props:any) => {
    return (
        <>
            <div className={`alert alert-${props.color} alert-dismissible`} role="alert">
                <strong>{props.message}</strong>
                {/* <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button> */}
            </div>
        </>
    )
}