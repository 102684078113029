import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate } from "../../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../../components/focus";
import CreatableSelect from "react-select/creatable";
import { useFetchDistrictDropDown, useFetchStateDropDown, useFetchCountryDropDown, useFetchPincodeDropDown, useFetchTalukaDropDown } from "../../../hooks/DropDown_Api";
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import Custom_Actions_Buttons from "../../../components/Cell/Custom_Actions";
import { SimpleInput_Edit } from "../../../components/Dynamic_Form_Models/SimpleInput_Edit";
import { useDelete, useDeletemforce } from "../../../hooks/delete";
import { useDeletemultiple, useDeletemultipleforce } from "../../../hooks/deletemultiple";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Actions, { Deleteforce, Deletesingle } from "../../../components/Cell/Actions";



const customIndicator = {
  IndicatorsContainer: IndicatorsContainer
}







interface Option {
  readonly label: string;
  readonly pincode?: string;
  //   readonly value: string;
  readonly name?: string;
}
const createOptionsPincode = (label: string) => ({
  label,
  // value: label,
  pincode: label,
  status: "active"
});
const createOptions = (label: string) => ({
  label,
  // value: label,
  name: label,
  status: "active"
});
const createOptionDistrict = (label: string) => ({
  label,
  // value: label,
  name: label,
  status: "active"
});
const createOptionState = (label: string) => ({
  label,
  // value: label,
  name: label,
  status: "active"
});
const createOptionCountry = (label: string) => ({
  label,
  // value: label,
  name: label,
  status: "active"
});

const Add_City = () => {
  let pageName = "City";
  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);
  const navigate = useNavigate()
  const [valueDistrict, setValueDistrict]: any = useState();
  const [valueState, setValueState]: any = useState();
  const [valueCountry, setValueCountry]: any = useState();
  const [searchTerm, setSearchTerm] = useState('')
  const [optionsDistrict, setOptionsDistrict]: any = useState([]);
  const [optionsState, setOptionsState]: any = useState([]);
  const [optionsCountry, setOptionsCountry]: any = useState([]);
  const [isLoadingValue, setIsLoadingValue] = useState(false);
// console.log(valueCountry);

  const apiname = process.env.REACT_APP_CITY_API?.toString()


  const { mutate: city, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)

  const { register, handleSubmit, setError, formState, reset, setValue, getValues }: any = useForm({
    // mode:"onChange"
  });
  const { errors } = formState;
  const [valueDistrictCreatable, setValueDistrictCreatable] = useState<Option | null>();


  const apinameForDistrict = process.env.REACT_APP_DISTRICT_API?.toString()

  const { mutate: mutateDistrict, data: dataDistrict, isError: DistrictIsError, isLoading: DistrictLoading, isSuccess: DistrictSuccess, error: DistrictError }: any = useCreate(apinameForDistrict)

  const [byDistrict, setByDistrict] = useState()
  const { data: DistrictData, refetch: machineNameRefetch } = useFetchDistrictDropDown(byDistrict)
  // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
  useEffect(() => {
    setOptionsDistrict(DistrictData)
  }, [DistrictData])


  useEffect(() => {
    if (searchTerm?.length >= 1) {
      machineNameRefetch()
    }
  }, [])



  const [isExist, setIsExist] = useState(true)
  const [OpenEditModel, setOpenEditModel] = useState(false)

  const [countryName, setCountryName] = useState("")
  const [talukaName, settalukaName] = useState("")
  const [districtName, setDistrictName] = useState("")
  const [stateName, setStateName] = useState("")
  const [editName, setEditName] = useState("")

  const [submittedEdit, setSubmittedEdit] = useState(false)
  const [deleteApiName, setDeleteApiName]: any = useState("")
  const [deleteid, setDeleteid] = useState();
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
  const apinameForCountry = process.env.REACT_APP_COUNTRY_API?.toString()
  const [valueCountryCreatable, setValueCountryCreatable] = useState<Option | null>();

  const [isCountryOpen, setIsCountryOpen] = useState(false)
  const [isStateOpen, setIsStateOpen] = useState(false)
  const [forTaluka, setForTaluka] = useState(false)
  const [forDistrict, setForDistrict] = useState(false)
  const [forState, setForState] = useState(false)

  const [editData, setEditData]: any = useState()


  let countryId = getValues("country")
  let stateId = getValues("state")

  const [editId, setEditId]: any = useState()
  const [apiName, setApiName]: any = useState()

  // console.log('isCountryOpen',isCountryOpen,'isStateOpen',isStateOpen,'editId',editId,'editName',editName,'apiName',apiName)







  const { data: CountryData, refetch: CountryRefetch } = useFetchCountryDropDown()
  const [editRefetch, setEditRefetch]: any = useState()


  const handleRefetch = () => {
    if (apiName === apinameForCountry) {
      CountryRefetch()
    }  if (apiName === apinameForState) {
      StateRefetch()
    }  if(apiName === apinameForDistrict){
      machineNameRefetch()
    }  if(apiName === apinameForTaluka){
    TalukaRefetch()
  }
  if(apiName === apinameForPincode){
    PincodeRefetch()
  }
  }




  // useEffect(() => {
  //     if (deleteid) {
  //       setDeleteApiName(apinameForCountry)
  //     }

  //   }, [apinameForCountry])


  //   useEffect(()=>{
  //     let countryId = getValues("country")
  //     let stateId = getValues("state")
  //      if(isCountryOpen){
  //         setApiName(apinameForCountry)
  //         setEditId(countryId)
  //         setEditName(countryName)
  //      }else if(isStateOpen){
  //         setApiName(apinameForState)
  //         setEditId(stateId)
  //         setEditName(stateName)
  //      }
  //   },[apiName,editId,editName,isCountryOpen,isStateOpen])







  const {
    mutate: singleDelete,
    data: deletedata,
    isError: deletecoloriserror,
    isSuccess: deletesucess,
  } = useDelete();
  const {
    mutate: deleteMultipleMutet,
    data: deleteMultiple,
    isError: deleteMultipleIsError,
    isSuccess: deleteMultipleSuccess,
  } = useDeletemultiple();
  const {
    mutate: deleteMultipleMutetforce,
    isSuccess: forcefullysuccess,
    isError: forcefullyerror,
  } = useDeletemultipleforce();
  const {
    mutate: deleteforce,
    isSuccess: forcefullysingledeletesuccess,
    isError: forcefullydeleteerror,
  } = useDeletemforce();
  const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
  const [confirmforcemodal, setConfirmforcemodal] = useState(false);
  useEffect(() => {
    if (deletesucess === true) { }
    // CountryRefetch();
  }, [deletesucess, editData?.results]);
  useEffect(() => {
    return () => {
      setDeleteid(undefined);
    };
  }, []);
  const handleConfirmDelete = () => {
    setDeleteConfirm(true);
  };
  const closereconfirmdelete = () => {
    setConfirmforcemodal(false);
    setDeleteMultipleid([]);
  };

  const confirmmodalclose = () => {
    setDeleteConfirmsingle(false);
    setDeleteid(undefined);
  };
  const confirmmodalsubmit = () => {
    setDeleteConfirmsingle(false);
    singleDelete({ deleteid, apiname: deleteApiName });
  };
  const deletemodalask = (deleteidselect: any) => {
    setDeleteConfirmsingle(true);
    setDeleteid(deleteidselect);
  };
  const handleDeletedforce = () => {
    setConfirmforcemodal(false);
    if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
    else if (deleteMultipleSuccess) {
      deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
    }
  };
  useEffect(() => {
    if (forcefullysuccess) {
      setShow(true);
      setshowmessage("success");
      CountryRefetch();
    } else if (forcefullyerror) {
      setShow(true);
      setshowmessage("danger");
    } else if (forcefullysingledeletesuccess) {
      setShow(true);
      setshowmessage("success");
      CountryRefetch();
    } else if (forcefullydeleteerror) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [
    forcefullysuccess,
    forcefullyerror,
    forcefullysingledeletesuccess,
    forcefullydeleteerror,
  ]);

  useEffect(() => {
    if (deleteMultipleSuccess) {
      const showdepenedancymsg = deleteMultiple?.data?.data;
      const dependacyfound = showdepenedancymsg?.filter((color: any) => {
        if (
          color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
        ) {
          return color;
        }
      });
      if (dependacyfound?.length === deleteMultipleid?.length) {
        deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
      } else {
        setConfirmforcemodal(true);
      }
    } else if (deleteMultipleIsError) {
      setShow(true);
      setshowmessage("danger");
    } else if (deletesucess) {
      if (deletedata.data?.data?.length > 0) {
        setConfirmforcemodal(true);
      } else if (deleteid) {
        setShow(true);
        setshowmessage("success");
        deleteforce({ deleteid, apiname: deleteApiName });
        CountryRefetch();
      }
    }
  }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

  const handleMultipleDeleted = () => {
    deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
    setDeleteConfirm(false);
  };





  const CustomOption = ({ innerProps, label, value }: any) => (


    <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
      <span>{label}</span>
      <div style={{ float: "right", justifyContent: "end", zIndex: 5 }}>



        {isExist ?

          <Custom_Actions_Buttons value={value}
            apiname={deleteApiName}
            mutate={singleDelete}
            // masterName={addButtonName}

            deleteid={deleteid}
            handleDeletedforce={handleDeletedforce}
            closereconfirmdelete={closereconfirmdelete}
            deletemodalask={deletemodalask}
            setEditOpen={setOpenEditModel}
          // permissionClass={permissionClass}
          />
          : ""}

      </div>
    </div>


  )



  const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate,
    Option: CustomOption
  }

const[isDistrctOpen,setIsDistrictOpen] = useState(false)
  const handleChangeMachineName = (e: any) => {


    machineNameRefetch()

    setForDistrict(true)
    setForState(true)
    setForTaluka(false)

    setApiName(apinameForDistrict)
    setDeleteApiName(apinameForDistrict)
    setEditId(e?.value)
    setEditName(districtName)
    setEditData(DistrictData)
    setValueDistrict(e)
    setRenderKey(prevKey => prevKey + 1);


    setValueTaluka(null)
    setValueTalukaCreatable(null)

    setValuePincode(null)
    setValuePincodeCreatable(null)

    setValue("district", `${e?.value}`)
    setValueDistrictCreatable(null)
    setByTaluka(e?.value)
    setDistrictValue(e?.value)
    setDistrictName(e?.label)
  }


  const handleCreate = (inputValue: string) => {

    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setIsLoadingValue(true);
    setTimeout(() => {
      const newOption = createOptionDistrict(inputValue);
      setIsLoadingValue(false);


      mutateDistrict({ name: newOption.name, status: newOption.status, state: stateValue, country: countryValue })
      setOptionsDistrict((prev: any) => [...prev, newOption]);
      setValueDistrictCreatable(newOption);
    }, 0);


  };

  useEffect(() => {
    (async () => {
      if (DistrictSuccess && optionsDistrict) {
        await machineNameRefetch();
        setValue("district", DistrictData[0]?.value)
        setDistrictValue(DistrictData[0]?.value)
      }

    })();

    return () => {
    };
  }, [DistrictSuccess, DistrictData]);




  const [valueTaluka, setValueTaluka]: any = useState();
  const [optionsTaluka, setOptionsTaluka]: any = useState([]);
  const [valueTalukaCreatable, setValueTalukaCreatable] = useState<Option | null>();





  const apinameForTaluka = process.env.REACT_APP_TALUKA_API?.toString()

  const { mutate: mutateTaluka, data: dataTaluka, isError: TalukaIsError, isLoading: TalukaLoading, isSuccess: TalukaSuccess, error: TalukaError }: any = useCreate(apinameForTaluka)

  const [byTaluka, setByTaluka] = useState()
  const { data: TalukaData, refetch: TalukaRefetch } = useFetchTalukaDropDown(byTaluka)
  // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
  useEffect(() => {
    setOptionsTaluka(TalukaData)
  }, [TalukaData])


  //   useEffect(() => {
  //       if (searchTerm?.length >= 1) {
  //           machineNameRefetch()
  //       }
  //   }, [])

  const [districtValue, setDistrictValue]: any = useState()


  const [talukaValue, setTalukaValue]: any = useState()


  const handleChangeTaluka = (e: any) => {

    TalukaRefetch()

    setForTaluka(true)
    setForState(true)
    setForDistrict(true)

    setApiName(apinameForTaluka)
    setDeleteApiName(apinameForTaluka)
    setEditId(e?.value)
    setEditName(e?.label)
    setEditData(TalukaData)
    // setValueDistrict(e)
    setRenderKey(prevKey => prevKey + 1);

    setValue("taluka", `${e?.value}`)
    setValueTalukaCreatable(null)
    setTalukaValue(e?.value)
    setByPincode(e?.value)
    setValueTaluka(e)
  }

  const handleCreateTaluka = (inputValue: string) => {

    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setIsLoadingValue(true);
    setTimeout(() => {
      const newOption = createOptions(inputValue);
      setIsLoadingValue(false);


      mutateTaluka({ name: newOption.name, status: newOption.status, state: stateValue, country: countryValue, district: districtValue })
      setOptionsTaluka((prev: any) => [...prev, newOption]);
      setValueTalukaCreatable(newOption);
    }, 0);


  };

  useEffect(() => {
    (async () => {
      if (TalukaSuccess && optionsTaluka && dataTaluka) {
        await TalukaRefetch();
        setValue("taluka", TalukaData[0].value)
      }

    })();

    return () => {
    };
  }, [TalukaSuccess, TalukaData]);















  const [valuePincode, setValuePincode]: any = useState();
  const [optionsPincode, setOptionsPincode]: any = useState<Option[] | null>([]);
  const [valuePincodeCreatable, setValuePincodeCreatable]: any = useState<Option[] | null>([]);


  const apinameForPincode = process.env.REACT_APP_PINCODE_API?.toString()

  const { mutate: mutatePincode, data: dataPincode, isError: PincodeIsError, isLoading: PincodeLoading, isSuccess: PincodeSuccess, error: PincodeError }: any = useCreate(apinameForPincode)

  const [byPincode, setByPincode] = useState()
  const { data: PincodeData, refetch: PincodeRefetch } = useFetchPincodeDropDown(byPincode)
  // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
  useEffect(() => {
    setOptionsPincode(PincodeData)
  }, [PincodeData])


  //   useEffect(() => {
  //       if (searchTerm?.length >= 1) {
  //           machineNameRefetch()
  //       }
  //   }, [])

  const [pincodeValue, setPincodeValue]: any = useState([])


  const handleChangePincode = (e: any) => {


    const lastElement = e[e.length - 1];

    let pincodes = e?.map((e: any) => {
      return e.value
    })

    PincodeRefetch()

    setForTaluka(false)
    setForState(false)
    setForDistrict(false)
    

    setApiName(apinameForPincode)
    setDeleteApiName(apinameForPincode)
    setEditId(lastElement?.value)

    
    setEditName(e?.label)
    setEditData(PincodeData)
    // setValueDistrict(e)
    


    setValue("pincode", pincodes)
    setValuePincodeCreatable(e)
    setPincodeValue(pincodes)

    setRenderKey(prevKey => prevKey + 1);
  }


  const handleCreatePincode = async (inputValue: any) => {
    //   
    // console.log(inputValue)
    // let temp = inputValue.target.value;

    // // Remove non-digit characters and limit to 6 characters
    // temp = temp.replace(/\D/g, '').substring(0, 6);

    // // Insert space after the first 3 digits
    // temp = temp.replace(/^(\d{3})(\d{1,3})$/, '$1 $2');

    // // Set the formatted value back to the input
    // inputValue.target.value = temp;
    



    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setIsLoadingValue(true);

    const newOption = createOptionsPincode(inputValue);
    setIsLoadingValue(false);




    if (byTaluka) {
      await mutatePincode({ pincode: newOption.pincode, status: newOption.status, state: stateValue, country: countryValue, district: districtValue, taluka: talukaValue })

    } else {
      await mutatePincode({ pincode: newOption.pincode, status: newOption.status, state: stateValue, country: countryValue, district: districtValue })
    }

  };




  useEffect(() => {
    (async () => {
      if (PincodeSuccess && optionsPincode) {
        await dataPincode
        let ok = getValues('pincode'); // Get the existing values
        ok = Array.isArray(ok) ? ok : []; // Initialize as an array if not already

        let ctx = {
          deleted: dataPincode.data.is_deleted,
          label: dataPincode.data.pincode,
          status: dataPincode.data.status,
          value: dataPincode.data.id,
        }


        setOptionsPincode((prev: any) => [...prev, ctx]);
        // setValuePincodeCreatable((prevSelected: any) => [...prevSelected, ctx]);

        setValuePincodeCreatable((prevSelected: any) => [...(Array.isArray(prevSelected) ? prevSelected : []), ctx]);


        let check = [...ok, dataPincode.data.id];
        setValue("pincode", check); // Update the 'pincode' field value



      }

    })();

    return () => {


    };
  }, [PincodeSuccess, dataPincode]);

  useEffect(() => {

  }, [pincodeValue, valuePincodeCreatable])


























  const [stateValue, setStateValue] = useState("")
  const [valueStateCreatable, setValueStateCreatable] = useState<Option | null>();

  const apinameForState = process.env.REACT_APP_STATE_API?.toString()

  const { mutate: mutateState, data: dataState, isError: StateIsError, isLoading: StateLoading, isSuccess: StateSuccess, error: StateError }: any = useCreate(apinameForState)

  const [byState, setByState] = useState()
  const { data: StateData, refetch: StateRefetch } = useFetchStateDropDown(byState)
  // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
  useEffect(() => {
    setOptionsState(StateData)
  }, [StateData])

  const handleChangeState = (e: any) => {

    StateRefetch()

    setForState(true)
    setForDistrict(false)
    setForTaluka(false)

    setApiName(apinameForState)
    setDeleteApiName(apinameForState)
    setEditId(e?.value)
    setEditName(stateName)
    setEditData(StateData)
    setValueState(e)

    setValue("state", `${e?.value}`)
    setValueStateCreatable(null)
    setByDistrict(e?.value)
    setStateValue(e?.value)
    setStateName(e?.label)

    setValueDistrict(null)
    setValueDistrictCreatable(null)

    setValueTaluka(null)
    setValueTalukaCreatable(null)

    setValuePincode(null)
    setValuePincodeCreatable(null)

    setRenderKey(prevKey => prevKey + 1);

  }

  useEffect(() => {
    if (valueCountry || valueCountryCreatable || valueState || valueStateCreatable) {
      setEditId(editId)
    }
  }, [valueCountry,
    valueCountryCreatable,
    valueState,
    valueStateCreatable])


  const handleCreateState = (inputValue: string) => {

    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setIsLoadingValue(true);
    setTimeout(() => {
      const newOption = createOptionState(inputValue);
      setIsLoadingValue(false);


      mutateState({ name: newOption.name, status: newOption.status, country: countryValue })
      setOptionsState((prev: any) => [...prev, newOption]);
      setValueStateCreatable(newOption);
    }, 0);


  };

  useEffect(() => {
    (async () => {
      if (StateSuccess && optionsState) {
        await StateRefetch();
        setValue("state", StateData[0]?.value)
        setStateValue(StateData[0]?.value)
      }

    })();

    return () => {
    };
  }, [StateSuccess, StateData]);




  const { mutate: mutateCountry, data: dataCountry, isError: CountryIsError, isLoading: CountryLoading, isSuccess: CountrySuccess, error: CountryError }: any = useCreate(apinameForCountry)



  // const [optionsCavityName, setOptionsCavityName] = useCountry(cavityNameData)
  useEffect(() => {
    setOptionsCountry(CountryData)
  }, [CountryData])


  // Call this function after an option is edited with the updated option

  useEffect(() => {
    if (OpenEditModel && valueCountry && apiName === apinameForCountry) {
      setValueCountry(null)
      setValueCountryCreatable(null)
    }
    if (OpenEditModel && valueState && apiName === apinameForState) {
      setValueState(null)
      setValueStateCreatable(null)
    }

    if (OpenEditModel && valueDistrict && apiName === apinameForDistrict) {
      setValueDistrict(null)
      setValueDistrictCreatable(null)
    }

    if (OpenEditModel && valueTaluka && apiName === apinameForTaluka) {
      setValueTaluka(null)
      setValueTalukaCreatable(null)
    }

    if (OpenEditModel && valuePincode && apiName === apinameForPincode) {
      setValuePincode(null)
      setValuePincodeCreatable(null)
    }

    if(deletesucess && apiName === apinameForCountry){
      setValueCountry(null)
      setValueCountryCreatable(null)
    }

    if(deletesucess && apiName === apinameForState){
      setValueState(null)
      setValueStateCreatable(null)
    }

    if(deletesucess && apiName === apinameForDistrict){
      setValueDistrict(null)
      setValueDistrictCreatable(null)
    }

    if(deletesucess && apiName === apinameForTaluka){
      setValueTaluka(null)
      setValueTalukaCreatable(null)
    }

    if(deletesucess && apiName === apinameForPincode){
      setValuePincode(null)
      setValuePincodeCreatable(null)
    }
  }, [OpenEditModel, apiName, deletesucess])

  const [renderKey, setRenderKey] = useState(0);
  const [CountryCode, setCountryCode]:any = useState(0);
  console.log(CountryCode);
  
  const handleChangeCountry = (e: any) => {

    CountryRefetch()

    setValueState(null)
    setValueStateCreatable(null)

    setForState(false)
    setForDistrict(false)
    setForTaluka(false)

    setApiName(apinameForCountry)
    setDeleteApiName(apinameForCountry)
    setEditId(e?.value)
    setEditName(countryName)
    setEditData(CountryData)
    // setOpenEditModel(false)
    setCountryCode(e?.countryCode)
    setValue("country_code_mobile",e?.countryCode)
    setValue("country", `${e?.value}`)
    setValueCountryCreatable(null)
    setByState(e?.value)
    setValueCountry(e)
    setCountryValue(e?.value)
    setCountryName(e?.label)

    setValueDistrict(null)
    setValueDistrictCreatable(null)

    setValueTaluka(null)
    setValueTalukaCreatable(null)

    setValuePincode(null)
    setValuePincodeCreatable(null)

    setRenderKey(prevKey => prevKey + 1);

    // setValueCountry(e)
    
  }


  const handleCreateCountry = (inputValue: string) => {
    // setOpenEditModel(false)
    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setIsLoadingValue(true);
    setTimeout(() => {
      const newOption = createOptionCountry(inputValue);
      setIsLoadingValue(false);


      mutateCountry({ name: newOption.name, status: newOption.status })
      setOptionsCountry((prev: any) => [...prev, newOption]);
      setValueCountryCreatable(newOption);
    }, 0);


  };

  const [countryValue, setCountryValue] = useState("")

  // console.log('country id in add', countryValue)


  useEffect(() => {
    (async () => {
      if (CountrySuccess && optionsCountry) {
        await CountryRefetch();
        setValue("country", CountryData[0]?.value)
        setCountryValue(CountryData[0]?.value)
      }

    })();

    return () => {
    };
  }, [CountrySuccess, CountryData]);



  const onSubmit = (data: any) => {


    if (!valueTaluka) {
      delete data?.taluka
    }

    if (villageValue) {
      delete data?.city_name
    }

    if (cityValue) {
      delete data?.village_name
    }


    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      const formvalue: any = value



      if (typeof value !== "object") {
        formData.append(key, formvalue)
      }
      else if (Array.isArray(value) && key === "pincode") {
        value.forEach((val: any) => formData.append(`pincode`, val))
      }

    }
    city(formData);
  }


  const [show, setShow] = useState(false)
  const [showmessage, setshowmessage] = useState("")

  useEffect(() => {
    if (isSuccess) {
      setShow(true)
      setshowmessage("success")
      navigate('/listCity', { state: { showToast: true } })
    }
    else if (isError) {
      setShow(true)
      setshowmessage("danger")
    }
  }, [data, error])

  useEffect(() => {
    setTimeout(() => {
      setShow(false)
    }, 3000)

  }, [show]);

  const handleReset = () => {
    reset()
  }





  const handleValue = (e: any) => {
    e.target.value = e.target.value.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }


  const [villageValue, setVillageValue] = useState('')
  const [cityValue, setCityValue] = useState('')

  const handleVillageChange = (e: any) => {
    e.target.value = e.target.value.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');

    setVillageValue(e.target.value)
    setValue('village_name', e.target.value)
  }

  const handleCityChange = (e: any) => {
    e.target.value = e.target.value.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setCityValue(e.target.value)
    setValue('city_name', e.target.value)
  }

  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>Add {pageName}</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                  <li className="breadcrumb-item"><Link to="/listCity" className="text-decoration-none text-black">{pageName}</Link></li>
                  <li className="breadcrumb-item active">Add {pageName}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Source Name</span></h5>
                            </div> */}
              <form ref={focus}
                onKeyUp={event => onEnterKey(event)}
                onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body" style={{ maxHeight: "80vh" }}>
                  {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message} /> : ""}

                  <div className="row d-flex justify-content-md-center justify-content-lg-start">
                    <div className="col-md-12">
                      <div className="row">




                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Country Name<span style={{ color: 'red' }}>*</span></label>
                          <CreatableSelect
                            isClearable={() => {
                              setValueCountry(null)
                              
                              setValue(null)
                            }}
                            {...register("country", { required: { value: true, message: "Country Name is required" } })}
                            placeholder={'Select Country Name'}
                            options={optionsCountry}
                            onCreateOption={handleCreateCountry}
                            onChange={handleChangeCountry}                            
                            onMenuOpen={() => {
                              CountryRefetch()
                              // setIsCountryOpen(true)
                            }}
                            // onMenuClose={() => setIsCountryOpen(false)}
                            value={valueCountryCreatable == null ? valueCountry : valueCountryCreatable}
                            components={customCreatableIndicator}
                          // defaultValue={{value:1,label:"India"}}  

                          // isOptionSelected={(optionsCountry:any)=>
                          //         {
                          //             
                          //             if(optionsCountry.label === "India"){
                          //             return optionsCountry
                          //             }
                          //            }}

                          />
                          <span className='text-red w-100'>{errors?.country?.message}</span>
                        </div>

                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">State Name <span style={{ color: 'red' }}>*</span></label>
                          <CreatableSelect
                            isClearable={() => {
                              setValueState(null)
                              setValue(null)
                            }}
                            {...register("state", { required: { value: true, message: "State Name is required" } })}
                            placeholder={'Select State Name'}
                            isDisabled={!byState}
                            options={optionsState}
                            // menuIsOpen={true}
                            onMenuOpen={() => {
                              StateRefetch()
                              // setIsStateOpen(true)
                            }}
                            // onMenuClose={() => setIsStateOpen(false)}
                            onCreateOption={handleCreateState}
                            onChange={handleChangeState}
                            value={valueStateCreatable == null ? valueState : valueStateCreatable}
                            components={customCreatableIndicator}
                          // defaultValue={{value:7,label:"Gujarat"}}
                          />
                          <span className='text-red w-100'>{errors?.state?.message}</span>

                        </div>

                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">District Name <span style={{ color: 'red' }}>*</span></label>
                          <CreatableSelect
                            isClearable={() => {
                              setValueDistrict(null)
                              setValue(null)
                            }}
                            {...register("district", { required: { value: true, message: "District Name is required" } })}
                            placeholder={'Select District Name'}
                            isDisabled={!byDistrict}
                            options={optionsDistrict}
                            onMenuOpen={() => {
                              machineNameRefetch()
                              // setIsDistrictOpen(true)
                            }}
                            // onMenuClose={() => setIsDistrictOpen(false)}
                            // onMenuOpen={()=>setIsDistrictOpen(true)}
                            // onMenuClose={()=>setIsDistrictOpen(false)}
                            onCreateOption={handleCreate}
                            onChange={handleChangeMachineName}
                            components={customCreatableIndicator}
                            value={valueDistrictCreatable == null ? valueDistrict : valueDistrictCreatable}

                          />
                          <span className='text-red w-100'>{errors?.district?.message}</span>

                        </div>



                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Taluka Name</label>
                          {/* <div className="input-group">
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder="Taluka Name" {...register("taluka", {onChange:handleValue, required: { value: false, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.taluka?.message}</span> */}




                          <CreatableSelect
                            isClearable={() => {
                              setValueTaluka(null)
                              setValue(null)
                            }}
                            {...register("taluka", { required: { value: false, message: "Taluka Name is required" } })}
                            placeholder={'Select Taluka Name'}
                            isDisabled={!byTaluka}
                            options={optionsTaluka}
                            onMenuOpen={() => {
                              TalukaRefetch()
                            }}
                            onCreateOption={handleCreateTaluka}
                            onChange={handleChangeTaluka}
                            components={customCreatableIndicator}
                            value={valueTalukaCreatable == null ? valueTaluka : valueTalukaCreatable}

                          />
                          <span className='text-red w-100'>{errors?.taluka?.message}</span>
                        </div>




                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Village Name</label>
                          <div className="input-group">
                            <input type="text" className="form-control" id="exampleInputBrand"
                              value={villageValue}
                              disabled={cityValue}
                              placeholder="Village Name" {...register("village_name", { onChange: handleVillageChange, required: { value: false, message: "Field is required" } })} />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              // style={{backgroundColor:"blue",color:"green"}} 
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.village_name?.message}</span>

                        </div>
                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">City Name</label>
                          <div className="input-group">
                            <input type="text" className="form-control" id="exampleInputBrand"
                              value={cityValue}
                              placeholder="City Name"
                              disabled={villageValue}
                              {...register("city_name", { onChange: handleCityChange, required: { value: false, message: "Field is required" } })} />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              // style={{backgroundColor:"blue",color:"green"}} 
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.city_name?.message}</span>

                        </div>

                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Pin Code Number <span style={{ color: 'red' }}>*</span></label>
                          {/* <div className="input-group">
                                                    <input type="number" className="form-control" id="exampleInputBrand" placeholder="City Pin Code" {...register("pincode", { required: { value: true, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.pincode?.message}</span> */}


                          <CreatableSelect
                            isClearable={() => {
                              setValuePincode(null)
                              setValue(null)
                            }}
                            {...register("pincode", { required: { value: true, message: "Pin Code Number is required" } })}
                            placeholder={'Select Pin Code Number'}
                            isDisabled={!countryValue|| !stateValue||!districtValue}
                            options={optionsPincode}
                            onCreateOption={handleCreatePincode}
                            onChange={handleChangePincode}
                            onMenuOpen={() => {
                              PincodeRefetch()
                            }}
                            // value={valuePincodeCreatable==null?valuePincode:valuePincodeCreatable}
                            value={valuePincodeCreatable}
                            components={customCreatableIndicator}
                            getOptionLabel={(e: any) => e.label}
                            getOptionValue={(e: any) => e.value}
                            isMulti

                          />
                          <span className='text-red w-100'>{errors?.pincode?.message}</span>
                        </div>







                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Country Mobile Code</label>
                          <div className="input-group">
                            <input type="text" className="form-control" id="exampleInputBrand" disabled value={CountryCode} placeholder="Country Mobile Code" {...register("country_code_mobile", { onChange: handleValue, required: { value: false, message: "Field is required" } })} />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              // style={{backgroundColor:"blue",color:"green"}} 
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.country_code_mobile?.message}</span>

                        </div>


                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Re-Mark</label>
                          <div className="input-group">
                            <input type="text" className="form-control" id="exampleInputBrand" placeholder="Re-Mark" {...register("remark", { onChange: handleValue, required: { value: false, message: "Field is required" } })} />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              // style={{backgroundColor:"blue",color:"green"}} 
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.remark?.message}</span>

                        </div>




                        {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Responsible Person</label>
                                                    <select className="form-select" {...register("four", { required: { value: true, message: "Field is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Mr...</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.four?.message}</span>

                                                </div> */}


                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Status <span style={{ color: 'red' }}>*</span></label>
                          <select className="form-select" {...register("status", { required: { value: true, message: "Field is required" } })}>

                            <option value={""} selected>Select Any</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                          <span className='text-red w-100'>{errors?.status?.message}</span>

                        </div>


                      </div>
                    </div>
                  </div>

                  <div className="row d-flex  ">
                    <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                      <div className="row d-flex ">
                        <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                          <button
                            type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                            onClick={handleSubmit(onSubmit)}
                          >Submit</button>
                          <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                          <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listCity")}>Cancel</button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </section>
      </div>


      {/* Edit Modal HTML */}
      <Modal
        show={deleteConfirm}
        onHide={() => setDeleteConfirm(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete {""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            {" "}
            <span className="text-center">Sure you want to delete it?</span>
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setDeleteConfirm(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="info"
            className="text-white"
            onClick={handleMultipleDeleted}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Deleteforce
        key={renderKey}
        handleDeletedforce={handleDeletedforce}
        reconfirmdelete={confirmforcemodal}
        masterName={""}
        closereconfirmdelete={closereconfirmdelete}
        referance={
          deletesucess
            ? deletedata
            : ""
        }
        apiname={apiName}
      />
      <Deletesingle
        key={renderKey}
        confirmmodal={deleteConfirmsingle}
        confirmmodalclose={confirmmodalclose}
        masterName={""}
        confirmmodalsubmit={confirmmodalsubmit}
        apiname={apiName}
      />

      <SimpleInput_Edit key={renderKey} refetchForm={handleRefetch} open={OpenEditModel} setOpen={setOpenEditModel} value={editName} id={editId} countryId={countryValue} stateId={stateValue} districtId={districtValue} talukaId={talukaValue} setSubmitted={setSubmittedEdit} forState={forState} forDistrict={forDistrict} forTaluka={forTaluka} apiname={apiName} />

    </div>
  )
}

export default Add_City