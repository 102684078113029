import React, { useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import { CSVLink } from 'react-csv';
import ExcelLogo from "../../../images/ExcelLogo.png"
import CSVlogo from "../../../images/csv.png"
import * as FileSaver from 'file-saver';
import PrintLogo from "../../../images/Print.png"


import CopyLogo from "../../../images/copy-27 1.png"
import autoTable from "jspdf-autotable";
import DeleteLogo from "../../../images/Close.png"
import XLSX from 'sheetjs-style';
import jsPDF from 'jspdf';

interface moredownlaod {
  setShow?: any,
  excelBody?: any,
  setshowmessage?: any,
  handleConfirmDelete?: () => void,
  handlePrint?: () => void,
  handlePDFDownload?: any,
  handleCopy?: any,
  Excelfilename?: string
}

const More = ({ setShow, excelBody, setshowmessage, handleConfirmDelete, Excelfilename, headertitles, excelHeader }: any) => {

  const [forCsv,setForCsv] = useState([])

  useEffect(()=>{
    // Map through each data item in excelBody and transform it
    const updateexcelbody = excelBody.map((exceldata:any) => {
      let updateobj:any = {};

      for (const [key, value] of Object.entries(exceldata)) {
        const temp_object:any = value;
        const evenIndex = excelHeader.findIndex((number:any) => number === key);

        if (evenIndex >= 0) {
          if (typeof exceldata[key] === 'object') {

        const keysToTransform = [
          "staff_code_and_name",
          "responsible_person",
          "sheth_responsible_person",
        //   "state", 
        // "country", 
        // "district",
        // "salesman_employee", 
        // "general_manager",
        // "sales_manager",
        // "area_manager",
        // "state_manager",
        // "sheth_employee",
        "color",
        "material_color",
        "paper_color",
        "printing_color_name",
        "printing_color",
        "created_by",
      "updated_by",
    "gst",
    "printing_brand",
    "brand",
    "brand_name",
    "baseUnit",
    "cavity_to_product",
    "machine_name",
    "mould_shape",
    "groups",
    "superAccountGroup",
    "subAccountGroup",
    "accountStatementType",
    "type",
    "grade_type",
    "name_and_grade",
  "department",
"product_type",
"product_grade_name",
"HSN_code",
"hsn_code",
"hsn",
"designation",
"product_ml_price_list",

"use_mould_code_and_cavity",
"panni",
"mould_usage",
"machine_department",
"tfm_machine_usd_mould",
// "cavity_shape",
// "name",
// "product_name",
// "mould_usage",
// "name",
];










        if (keysToTransform.includes(key)) {


          // const innerKeyName = temp_object?.abbreviation || temp_object?.type;
          // const transformedValue = innerKeyName ? innerKeyName[key] : null;

          // updateobj["abbreviation"] = temp_object?.name?.abbreviation
          // updateobj["type"] = temp_object?.name?.type
          updateobj[headertitles[evenIndex]] =  temp_object?.material_type_name || 
          temp_object?.name||
          temp_object?.machine_name||
          temp_object?.department ||
          temp_object?.mould_shape || 
          temp_object?.panni_code || 
          temp_object?.mould_code ||
          temp_object?.ProductPriceListML ||
          temp_object?.designation || 
          temp_object?.hsn_code || 
          temp_object?.username || 
          temp_object?.india_GST || 
          temp_object?.department || 
          temp_object?.type 
          
        }

        if(key === "name" ){

            
          updateobj["abbreviation"] = temp_object?.abbreviation

            updateobj["type"] = temp_object?.type

            updateobj[key] = temp_object?.name

        }
        if(key === "salesman_employee" ){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key ===  "general_manager" ){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key === "sales_manager"){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key === "area_manager"){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key === "state_manager"){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key === "sheth_employee" ){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key ===  "employee_code"){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key === "state"){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key ===  "country" ){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key === "district"){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key === "cavity_shape"){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key === "name"){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key === "product_name"){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key === "mould_usage"){
          updateobj[headertitles[evenIndex]] =temp_object?.mould_code
        }
        // For Array
        if (key === "use_employee_code_and_surname_and_name" && Array.isArray(temp_object)) {
          const cityNames = temp_object.map((e:any) => e?.name);
          updateobj[key] = cityNames.join(', '); // Join country names with a comma             
        }

       


            if (key === "city" && Array.isArray(temp_object)) {
              const cityNames = temp_object.map((e:any) => e?.city_name);
              updateobj[key] = cityNames.join(', '); // Join country names with a comma             
            }

            if (key === "groups" && Array.isArray(temp_object)) {
              const cityNames = temp_object.map((e:any) => e?.name);
              updateobj[key] = cityNames.join(', '); // Join country names with a comma             
            }
            if (key === "color" && Array.isArray(temp_object)) {
              const cityNames = temp_object.map((e:any) => e?.name);
              updateobj[key] = cityNames.join(', '); // Join country names with a comma             
            }
            if (key === "material_color" && Array.isArray(temp_object)) {
              const cityNames = temp_object.map((e:any) => e?.name);
              updateobj[key] = cityNames.join(', '); // Join country names with a comma             
            }
            if (key === "paper_color" && Array.isArray(temp_object)) {
              const cityNames = temp_object.map((e:any) => e?.name);
              updateobj[key] = cityNames.join(', '); // Join country names with a comma             
            }
            if (key === "printing_color_name" && Array.isArray(temp_object)) {
              const cityNames = temp_object.map((e:any) => e?.name);
              updateobj[key] = cityNames.join(', '); // Join country names with a comma             
            }
            if (key === "printing_color" && Array.isArray(temp_object)) {
              const cityNames = temp_object.map((e:any) => e?.name);
              updateobj[key] = cityNames.join(', '); // Join country names with a comma             
            }

           
            if(key === "name" ){

            
              updateobj["abbreviation"] = temp_object?.abbreviation
  
                updateobj["type"] = temp_object?.type
  
                updateobj[key] = temp_object?.name

            }
           

          

          
          } else {
            updateobj[headertitles[evenIndex]] = value;
          }
        } else {
          updateobj["Sr No"] = value;
        }
      }
      return updateobj;
    });

  setForCsv(updateexcelbody)

  },[excelBody])

  useEffect(() => {
  }, [forCsv])


  // Import necessary libraries


// Define your function
const handleDownloadExcel = () => {
  // Assuming excelBody, excelHeader, headertitles, Excelfilename are defined

  // Check if excelBody is empty
  if (excelBody.length === 0) {
    // Set some state variables to show an error message
    setShow(true);
    setshowmessage("danger");
  } else {

    console.log('excelBody',excelBody)
    // Map through each data item in excelBody and transform it
    const updateexcelbody = excelBody.map((exceldata:any) => {
      let updateobj:any = {};
      for (const [key, value] of Object.entries(exceldata)) {

      

        const temp_object:any = value;
        const evenIndex = excelHeader.findIndex((number:any) => number === key);

        if (evenIndex >= 0) {
          if (typeof exceldata[key] === 'object') {

        const keysToTransform = [
          "staff_code_and_name",
          "responsible_person",
          "sheth_responsible_person",
        //   "state", 
        // "country", 
        // "district",
        // "salesman_employee", 
        // "general_manager",
        // "sales_manager",
        // "area_manager",
        // "state_manager",
        // "sheth_employee",
        "color",
        "material_color",
        "paper_color",
        "printing_color_name",
        "printing_color",
        "created_by",
      "updated_by",
    "gst",
    "printing_brand",
    "brand",
    "brand_name",
    "baseUnit",
    "cavity_to_product",
    "machine_name",
    "mould_shape",
    "groups",
    "superAccountGroup",
    "subAccountGroup",
    "accountStatementType",
    "type",
    "grade_type",
    "name_and_grade",
  "department",
"product_type",
"product_grade_name",
"HSN_code",
"hsn_code",
"hsn",
"designation",
"product_ml_price_list",

"use_mould_code_and_cavity",
"panni",
"mould_usage",
"machine_department",
"tfm_machine_usd_mould",
// "cavity_shape",
// "name",
// "product_name",
// "mould_usage",
// "name",
];


        if (keysToTransform.includes(key)) {


          // const innerKeyName = temp_object?.abbreviation || temp_object?.type;
          // const transformedValue = innerKeyName ? innerKeyName[key] : null;

          // updateobj["abbreviation"] = temp_object?.name?.abbreviation
          // updateobj["type"] = temp_object?.name?.type
          updateobj[headertitles[evenIndex]] =  temp_object?.material_type_name || 
          
          temp_object?.machine_name||
          temp_object?.department ||
          temp_object?.mould_shape || 
          temp_object?.panni_code || 
          temp_object?.mould_code ||
          temp_object?.ProductPriceListML ||
          temp_object?.designation || 
          temp_object?.hsn_code || 
          temp_object?.username || 
          temp_object?.india_GST || 
          temp_object?.department || 
          temp_object?.type ||
          temp_object?.name;
        }

        if(key === "salesman_employee" ){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key ===  "general_manager" ){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key === "sales_manager"){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key === "area_manager"){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key === "state_manager"){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key === "sheth_employee" ){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key ===  "employee_code"){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key === "state"){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key ===  "country" ){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key === "district"){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key === "cavity_shape"){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key === "name"){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key === "product_name"){
          updateobj[headertitles[evenIndex]] =temp_object?.name
        }
        if(key === "mould_usage"){
          updateobj[headertitles[evenIndex]] =temp_object?.mould_code
        }

        // if(key === "salesman_employee" || "general_manager" ||"sales_manager"||"area_manager"||"state_manager"||"sheth_employee" || "employee_code"){
        //   updateobj[headertitles[evenIndex]] =temp_object?.name
        // }
        // if(key === "state" || "country" || "district"){
        //   updateobj[headertitles[evenIndex]] =temp_object?.name
        // }

        // if(key === "city"){

        //   if(updateobj["state"]){

        //   updateobj["state"] = temp_object?.name
        //   }
        // }
        if(key === "name" ){
    
          updateobj["abbreviation"] =temp_object?.abbreviation
          // elt["type"] = elt[renameprop]?.type


            // elt[renameprop] = elt[renameprop]?.name

        }

        if(key === "name" ){

            
          // elt["abbreviation"] = elt[renameprop]?.abbreviation

          updateobj["type"] =temp_object?.type


          updateobj[headertitles[evenIndex]] =temp_object?.name

        }


        // if(key === "name" ){

            
        //   updateobj["abbreviation"] = temp_object?.abbreviation

        //     updateobj["type"] = temp_object?.type

        //     updateobj[key] = temp_object?.name

        // }
        
        // For Array

        if (key === "use_employee_code_and_surname_and_name" && Array.isArray(temp_object)) {
          const cityNames = temp_object.map((e:any) => e?.name);
          updateobj[key] = cityNames.join(', '); // Join country names with a comma             
        }

        // if (key === "use_employee_code_and_surname_and_name" && Array.isArray(temp_object)) {
        //   const cityNames = temp_object.map((e:any) => e?.name);
        //   updateobj[key] = cityNames.join(', '); // Join country names with a comma             
        // }

        if (key === "pincode" && Array.isArray(temp_object)) {
          const pincodes = temp_object.map((e:any) => e?.pincode);
          updateobj[key] = pincodes.join(', '); // Join country names with a comma             
        }else {
          updateobj[headertitles[evenIndex]] =temp_object?.pincode?.pincode
        }
      
            if (key === "city" && Array.isArray(temp_object)) {
              const cityNames = temp_object.map((e:any) => e?.city_name);
              updateobj[key] = cityNames.join(', '); // Join country names with a comma      
              
            }else {
              updateobj[headertitles[evenIndex]] =temp_object?.city_name
            }

            if (key === "groups" && Array.isArray(temp_object)) {
              const cityNames = temp_object.map((e:any) => e?.name);
              updateobj[key] = cityNames.join(', '); // Join country names with a comma             
            }
            if (key === "color" && Array.isArray(temp_object)) {
              const cityNames = temp_object.map((e:any) => e?.name);
              updateobj[key] = cityNames.join(', '); // Join country names with a comma             
            }
            if (key === "material_color" && Array.isArray(temp_object)) {
              const cityNames = temp_object.map((e:any) => e?.name);
              updateobj[key] = cityNames.join(', '); // Join country names with a comma             
            }
            if (key === "paper_color" && Array.isArray(temp_object)) {
              const cityNames = temp_object.map((e:any) => e?.name);
              updateobj[key] = cityNames.join(', '); // Join country names with a comma             
            }
            if (key === "printing_color_name" && Array.isArray(temp_object)) {
              const cityNames = temp_object.map((e:any) => e?.name);
              updateobj[key] = cityNames.join(', '); // Join country names with a comma             
            }
            if (key === "printing_color" && Array.isArray(temp_object)) {
              const cityNames = temp_object.map((e:any) => e?.name);
              updateobj[key] = cityNames.join(', '); // Join country names with a comma             
            }

           
            // if(key === "name" ){

            
            //   updateobj["abbreviation"] = temp_object?.abbreviation
  
            //     updateobj["type"] = temp_object?.type
  
            //     updateobj[key] = temp_object?.name

            // }
           

          

          
          } else {
            updateobj[headertitles[evenIndex]] = value;
          }
        } else {
          updateobj["Sr No"] = value;
        }
      }
      return updateobj;
    });

    // Prepare the file type and extension
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    // Convert the data to Excel format
    const ws = XLSX.utils.json_to_sheet(updateexcelbody);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob with the Excel data
    const data = new Blob([excelBuffer], { type: fileType });

    // Save the Blob as a file with the given filename and extension
    FileSaver.saveAs(data, Excelfilename + fileExtension);
  }
};


  const handlePrint = () => {
    if (excelBody.length === 0) {
      setShow(true)
      setshowmessage("danger")
    }
    else{
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    // const doc = new jsPDF(orientation, unit, size)
    const doc = new jsPDF('p', 'mm', [500, 580]);
    doc.setFontSize(20);
    const headers = [...headertitles]
    const data = excelBody.map((elt: any) => {
      let data: Array<any> = []
      excelHeader.map((content: any) => {
       
        const renameprop = content;
       

        if(typeof elt[renameprop] === 'object'){


          const keysToTransform = [
            "staff_code_and_name",
            "responsible_person",
            "sheth_responsible_person",
          //   "state", 
          // "country", 
          // "district",
          // "salesman_employee", 
          // "general_manager",
          // "sales_manager",
          // "area_manager",
          // "state_manager",
          // "sheth_employee",
          "color",
          "material_color",
          "paper_color",
          "printing_color_name",
          "printing_color",
          "created_by",
        "updated_by",
      "gst",
      "printing_brand",
      "brand",
      "brand_name",
      "baseUnit",
      "cavity_to_product",
      "machine_name",
      "mould_shape",
      "groups",
      "superAccountGroup",
      "subAccountGroup",
      "accountStatementType",
      "type",
      "grade_type",
      "name_and_grade",
    "department",
  "product_type",
  "product_grade_name",
  "HSN_code",
  "hsn_code",
  "hsn",
  "designation",
  "product_ml_price_list",
  
  "use_mould_code_and_cavity",
  "panni",
  "mould_usage",
  "machine_department",
  "tfm_machine_usd_mould",
  // "name",
  ];
 
          if (keysToTransform.includes(renameprop)) {
  
            
            // const innerKeyName = temp_object?.abbreviation || temp_object?.type;
            // const transformedValue = innerKeyName ? innerKeyName[key] : null;
  
            // updateobj["abbreviation"] = temp_object?.name?.abbreviation
            // updateobj["type"] = temp_object?.name?.type
            elt[renameprop] =  elt[renameprop]?.material_type_name || 
            elt[renameprop]?.name ||
            elt[renameprop]?.machine_name||
            elt[renameprop]?.department ||
            elt[renameprop]?.mould_shape || 
            elt[renameprop]?.panni_code || 
            elt[renameprop]?.mould_code ||
            elt[renameprop]?.ProductPriceListML ||
            elt[renameprop]?.designation || 
            elt[renameprop]?.hsn_code || 
            elt[renameprop]?.username || 
            elt[renameprop]?.india_GST || 
            elt[renameprop]?.department || 
            elt[renameprop]?.type 
           
          }

          if(renameprop === "salesman_employee" ){
            elt[renameprop] = elt[renameprop]?.name
          }
          if(renameprop ===  "general_manager" ){
            elt[renameprop] = elt[renameprop]?.name
          }
          if(renameprop === "sales_manager"){
            elt[renameprop] = elt[renameprop]?.name
          }
          if(renameprop === "area_manager"){
            elt[renameprop] = elt[renameprop]?.name
          }
          if(renameprop === "state_manager"){
            elt[renameprop] = elt[renameprop]?.name
          }
          if(renameprop === "sheth_employee" ){
            elt[renameprop] = elt[renameprop]?.name
          }
          if(renameprop ===  "employee_code"){
            elt[renameprop] = elt[renameprop]?.name
          }
          if(renameprop === "state"){
            elt[renameprop] = elt[renameprop]?.name
          }
          if(renameprop ===  "country" ){
            elt[renameprop] = elt[renameprop]?.name
          }
          if(renameprop === "district"){
            elt[renameprop] = elt[renameprop]?.name
          }

          // if(renameprop === "salesman_employee" || "general_manager" ||"sales_manager"||"area_manager"||"state_manager"||"sheth_employee" || "employee_code"){
          //   elt[renameprop] = elt[renameprop]?.name
          // }

          // if(renameprop === "state" || "country" || "district"){
          //   elt[renameprop] = elt[renameprop]?.name
          // }
  
          if(renameprop === "name" ){
  
              
            elt["abbreviation"] = elt[renameprop]?.abbreviation
            // elt["type"] = elt[renameprop]?.type


              // elt[renameprop] = elt[renameprop]?.name

          }
          if(renameprop === "name" ){
  
              
            // elt["abbreviation"] = elt[renameprop]?.abbreviation
            elt["type"] = elt[renameprop]?.type


              elt[renameprop] = elt[renameprop]?.name

          }
          
          // For Array


          if (renameprop === "use_employee_code_and_surname_and_name" && Array.isArray(elt[renameprop])) {
            const cityNames = elt[renameprop].map((e:any) => e?.city_name);
                elt[renameprop] = cityNames.join(', '); // Join country names with a comma          
          }
        
              if (renameprop === "city" && Array.isArray(elt[renameprop])) {
                const cityNames = elt[renameprop].map((e:any) => e?.city_name);
                elt[renameprop] = cityNames.join(', '); // Join country names with a comma     
                
              }
  
              if (renameprop === "groups" && Array.isArray(elt[renameprop])) {
                const cityNames = elt[renameprop].map((e:any) => e?.name);
                elt[renameprop] = cityNames.join(', '); // Join country names with a comma             
              }
              if (renameprop === "color" && Array.isArray(elt[renameprop])) {
                const cityNames = elt[renameprop].map((e:any) => e?.name);
                elt[renameprop] = cityNames.join(', '); // Join country names with a comma             
              }
              if (renameprop === "material_color" && Array.isArray(elt[renameprop])) {
                const cityNames = elt[renameprop].map((e:any) => e?.name);
                elt[renameprop] = cityNames.join(', '); // Join country names with a comma             
              }
              if (renameprop === "paper_color" && Array.isArray(elt[renameprop])) {
                const cityNames = elt[renameprop].map((e:any) => e?.name);
                elt[renameprop] = cityNames.join(', '); // Join country names with a comma             
              }
              if (renameprop === "printing_color_name" && Array.isArray(elt[renameprop])) {
                const cityNames = elt[renameprop].map((e:any) => e?.name);
                elt[renameprop] = cityNames.join(', '); // Join country names with a comma             
              }
              if (renameprop === "printing_color" && Array.isArray(elt[renameprop])) {
                const cityNames = elt[renameprop].map((e:any) => e?.name);
                elt[renameprop] = cityNames.join(', '); // Join country names with a comma             
              }
  

        }


        if (typeof (elt[renameprop]) === "undefined") {
          elt[renameprop] = "-"
        }

        data.push(elt[renameprop])
      })
      return data
    })
    autoTable(doc, {
      head: [[...headers]],
      body: data,
    })

   
    doc.autoPrint()
    doc.output("dataurlnewwindow");
  }
}






  const handlePDFDownload = () => {
    if (excelBody.length === 0) {
      setShow(true)
      setshowmessage("danger")
    }
    else {
      const unit = "pt";
      const size = "auto"; // Use A1, A2, A3 or A4
      const orientation = "portrait"; // portrait or landscape
      // const doc = new jsPDF(orientation, unit, size)
            const doc = new jsPDF('p', 'mm', [500, 580]);

      doc.setFontSize(20);
      const headers = [...headertitles]
      const data = excelBody.map((elt: any) => {


        let data: Array<any> = []
        excelHeader.map((content: any) => {
          const renameprop = content;

     

          if(typeof elt[renameprop] === 'object'){


            const keysToTransform = [
              "staff_code_and_name",
              "responsible_person",
              "sheth_responsible_person",
            //   "state", 
            // "country", 
            // "district",
            // "salesman_employee", 
            // "general_manager",
            // "sales_manager",
            // "area_manager",
            // "state_manager",
            // "sheth_employee",
            "color",
            "material_color",
            "paper_color",
            "printing_color_name",
            "printing_color",
            "created_by",
          "updated_by",
        "gst",
        "printing_brand",
        "brand",
        "brand_name",
        "baseUnit",
        "cavity_to_product",
        "machine_name",
        "mould_shape",
        "groups",
        "superAccountGroup",
        "subAccountGroup",
        "accountStatementType",
        "type",
        "grade_type",
        "name_and_grade",
      "department",
    "product_type",
    "product_grade_name",
    "HSN_code",
    "hsn_code",
    "hsn",
    "designation",
    "product_ml_price_list",
    
    "use_mould_code_and_cavity",
    "panni",
    "mould_usage",
    "machine_department",
    "tfm_machine_usd_mould",
    // "name",
    ];
    

            if (keysToTransform.includes(renameprop)) {
    
              
              // const innerKeyName = temp_object?.abbreviation || temp_object?.type;
              // const transformedValue = innerKeyName ? innerKeyName[key] : null;
    
              // updateobj["abbreviation"] = temp_object?.name?.abbreviation
              // updateobj["type"] = temp_object?.name?.type
              elt[renameprop] =  elt[renameprop]?.material_type_name || 
              
              elt[renameprop]?.machine_name||
              elt[renameprop]?.department ||
              elt[renameprop]?.mould_shape || 
              elt[renameprop]?.panni_code || 
              elt[renameprop]?.mould_code ||
              elt[renameprop]?.ProductPriceListML ||
              elt[renameprop]?.designation || 
              elt[renameprop]?.hsn_code || 
              elt[renameprop]?.username || 
              elt[renameprop]?.india_GST || 
              elt[renameprop]?.department || 
              elt[renameprop]?.type ||
              elt[renameprop]?.name 
            }

            if(renameprop === "salesman_employee" ){
              elt[renameprop] = elt[renameprop]?.name
            }
            if(renameprop ===  "general_manager" ){
              elt[renameprop] = elt[renameprop]?.name
            }
            if(renameprop === "sales_manager"){
              elt[renameprop] = elt[renameprop]?.name
            }
            if(renameprop === "area_manager"){
              elt[renameprop] = elt[renameprop]?.name
            }
            if(renameprop === "state_manager"){
              elt[renameprop] = elt[renameprop]?.name
            }
            if(renameprop === "sheth_employee" ){
              elt[renameprop] = elt[renameprop]?.name
            }
            if(renameprop ===  "employee_code"){
              elt[renameprop] = elt[renameprop]?.name
            }
            if(renameprop === "state"){
              elt[renameprop] = elt[renameprop]?.name
            }
            if(renameprop ===  "country" ){
              elt[renameprop] = elt[renameprop]?.name
            }
            if(renameprop === "district"){
              elt[renameprop] = elt[renameprop]?.name
            }
            if(renameprop === "city_and_village"){
              elt[renameprop] = elt[renameprop]?.city_name
            }
            if(renameprop === "zone"){
              elt[renameprop] = elt[renameprop]?.route_name
            }
            // if(renameprop === "pincode"){
            //   elt[renameprop] = elt[renameprop]?.pincode
            // }
            if(renameprop === "account_type"){
              elt[renameprop] = elt[renameprop]?.category
            }
            if(renameprop === "employee"){
              elt[renameprop] = elt[renameprop]?.name
            }
            if(renameprop === "customer"){
              elt[renameprop] = elt[renameprop]?.name
            }
            if(renameprop === "ifsc_code"){
              elt[renameprop] = elt[renameprop]?.IFSC 
            }

            // if(renameprop === "salesman_employee" || "general_manager" ||"sales_manager"||"area_manager"||"state_manager"||"sheth_employee" || "employee_code"){
            //   elt[renameprop] = elt[renameprop]?.name
            // }
            // if(renameprop === "state" || "country" || "district"){
            //   elt[renameprop] = elt[renameprop]?.name
            // }
    
            if(renameprop === "name" ){
    
                
              elt["abbreviation"] = elt[renameprop]?.abbreviation
              // elt["type"] = elt[renameprop]?.type
  
  
                // elt[renameprop] = elt[renameprop]?.name
  
            }

            if(renameprop === "name" ){
    
                
              // elt["abbreviation"] = elt[renameprop]?.abbreviation
              elt["type"] = elt[renameprop]?.type
  
  
                elt[renameprop] = elt[renameprop]?.name
  
            }
            
            // For Array

            if (renameprop === "use_employee_code_and_surname_and_name" && Array.isArray(elt[renameprop])) {
              const cityNames = elt[renameprop].map((e:any) => e?.city_name);
                  elt[renameprop] = cityNames.join(', '); // Join country names with a comma          
            }
          
                if (renameprop === "city" && Array.isArray(elt[renameprop])) {
                  const cityNames = elt[renameprop].map((e:any) => e?.city_name);
                  elt[renameprop] = cityNames.join(', '); // Join country names with a comma     
                  
                }
    
                if (renameprop === "groups" && Array.isArray(elt[renameprop])) {
                  const cityNames = elt[renameprop].map((e:any) => e?.name);
                  elt[renameprop] = cityNames.join(', '); // Join country names with a comma             
                }
                if (renameprop === "color" && Array.isArray(elt[renameprop])) {
                  const cityNames = elt[renameprop].map((e:any) => e?.name);
                  elt[renameprop] = cityNames.join(', '); // Join country names with a comma             
                }
                if (renameprop === "material_color" && Array.isArray(elt[renameprop])) {
                  const cityNames = elt[renameprop].map((e:any) => e?.name);
                  elt[renameprop] = cityNames.join(', '); // Join country names with a comma             
                }
                if (renameprop === "paper_color" && Array.isArray(elt[renameprop])) {
                  const cityNames = elt[renameprop].map((e:any) => e?.name);
                  elt[renameprop] = cityNames.join(', '); // Join country names with a comma             
                }
                if (renameprop === "printing_color_name" && Array.isArray(elt[renameprop])) {
                  const cityNames = elt[renameprop].map((e:any) => e?.name);
                  elt[renameprop] = cityNames.join(', '); // Join country names with a comma             
                }
                if (renameprop === "printing_color" && Array.isArray(elt[renameprop])) {
                  const cityNames = elt[renameprop].map((e:any) => e?.name);
                  elt[renameprop] = cityNames.join(', '); // Join country names with a comma             
                }
                if (renameprop === "pincode" && Array.isArray(elt[renameprop])) {
                  const pincodes = elt[renameprop].map((e:any) => e?.pincode);
                  elt[renameprop] = pincodes.join(', '); // Join country names with a comma             
                }else {
                  elt[headertitles[renameprop]] =elt[renameprop]?.pincode?.pincode
                }

          }
  

          if (typeof (elt[renameprop]) === "undefined") {
            elt[renameprop] = "-"
          }
          data.push(elt[renameprop])
        })
        return data
      })
      autoTable(doc, {
        head: [[...headers]],
        body: data,
      })
      doc.save(`${Excelfilename}Report.pdf`)
    }
  }



  
/**
 * Input:
 * Dependacies:headertitles,excelBody.
 * Output: Copied content to clipboard
 */
  const handleCopy = () => {
    if (excelBody.length === 0) {
      setShow(true)
      setshowmessage("danger")
    }
    else{
    const headers = [...headertitles]
    const data = excelBody.map((elt: any) => {
      let data: Array<any> = []
      excelHeader.map((content: any) => {

        const renameprop = content;
      

        if(typeof elt[renameprop] === 'object'){


          const keysToTransform = [
            "staff_code_and_name",
            "responsible_person",
            "sheth_responsible_person",
          //   "state", 
          // "country", 
          // "district",
          // "salesman_employee", 
          // "general_manager",
          // "sales_manager",
          // "area_manager",
          // "state_manager",
          // "sheth_employee",
          "color",
          "material_color",
          "paper_color",
          "printing_color_name",
          "printing_color",
          "created_by",
        "updated_by",
      "gst",
      "printing_brand",
      "brand",
      "brand_name",
      "baseUnit",
      "cavity_to_product",
      "machine_name",
      "mould_shape",
      "groups",
      "superAccountGroup",
      "subAccountGroup",
      "accountStatementType",
      "type",
      "grade_type",
      "name_and_grade",
    "department",
  "product_type",
  "product_grade_name",
  "HSN_code",
  "hsn_code",
  "hsn",
  "designation",
  "product_ml_price_list",
  
  "use_mould_code_and_cavity",
  "panni",
  "mould_usage",
  "machine_department",
  "tfm_machine_usd_mould",
  // "name",
  ];

  
          if (keysToTransform.includes(renameprop)) {
  
            
            // const innerKeyName = temp_object?.abbreviation || temp_object?.type;
            // const transformedValue = innerKeyName ? innerKeyName[key] : null;
  
            // updateobj["abbreviation"] = temp_object?.name?.abbreviation
            // updateobj["type"] = temp_object?.name?.type
            elt[renameprop] =  elt[renameprop]?.material_type_name || 
            
            elt[renameprop]?.machine_name||
            elt[renameprop]?.department ||
            elt[renameprop]?.mould_shape || 
            elt[renameprop]?.panni_code || 
            elt[renameprop]?.mould_code ||
            elt[renameprop]?.ProductPriceListML ||
            elt[renameprop]?.designation || 
            elt[renameprop]?.hsn_code || 
            elt[renameprop]?.username || 
            elt[renameprop]?.india_GST || 
            elt[renameprop]?.department || 
            elt[renameprop]?.type ||
            elt[renameprop]?.name 
          }

          if(renameprop === "salesman_employee" ){
            elt[renameprop] = elt[renameprop]?.name
          }
          if(renameprop ===  "general_manager" ){
            elt[renameprop] = elt[renameprop]?.name
          }
          if(renameprop === "sales_manager"){
            elt[renameprop] = elt[renameprop]?.name
          }
          if(renameprop === "area_manager"){
            elt[renameprop] = elt[renameprop]?.name
          }
          if(renameprop === "state_manager"){
            elt[renameprop] = elt[renameprop]?.name
          }
          if(renameprop === "sheth_employee" ){
            elt[renameprop] = elt[renameprop]?.name
          }
          if(renameprop ===  "employee_code"){
            elt[renameprop] = elt[renameprop]?.name
          }
          if(renameprop === "state"){
            elt[renameprop] = elt[renameprop]?.name
          }
          if(renameprop ===  "country" ){
            elt[renameprop] = elt[renameprop]?.name
          }
          if(renameprop === "district"){
            elt[renameprop] = elt[renameprop]?.name
          }

          // if(renameprop === "salesman_employee" || "general_manager" ||"sales_manager"||"area_manager"||"state_manager"||"sheth_employee" || "employee_code"){
          //   elt[renameprop] = elt[renameprop]?.name
          // }

          // if(renameprop === "state" || "country" || "district"){
          //   elt[renameprop] = elt[renameprop]?.name
          // }
  
          if(renameprop === "name" ){
  
              
            elt["abbreviation"] = elt[renameprop]?.abbreviation
            elt["type"] = elt[renameprop]?.type


              elt[renameprop] = elt[renameprop]?.name

          }
          
          // For Array

            if (renameprop === "use_employee_code_and_surname_and_name" && Array.isArray(elt[renameprop])) {
            const cityNames = elt[renameprop].map((e:any) => e?.city_name);
                elt[renameprop] = cityNames.join(', '); // Join country names with a comma          
          }
        
              if (renameprop === "city" && Array.isArray(elt[renameprop])) {
                const cityNames = elt[renameprop].map((e:any) => e?.city_name);
                elt[renameprop] = cityNames.join(', '); // Join country names with a comma     
                
              }
  
              if (renameprop === "groups" && Array.isArray(elt[renameprop])) {
                const cityNames = elt[renameprop].map((e:any) => e?.name);
                elt[renameprop] = cityNames.join(', '); // Join country names with a comma             
              }
              if (renameprop === "color" && Array.isArray(elt[renameprop])) {
                const cityNames = elt[renameprop].map((e:any) => e?.name);
                elt[renameprop] = cityNames.join(', '); // Join country names with a comma             
              }
              if (renameprop === "material_color" && Array.isArray(elt[renameprop])) {
                const cityNames = elt[renameprop].map((e:any) => e?.name);
                elt[renameprop] = cityNames.join(', '); // Join country names with a comma             
              }
              if (renameprop === "paper_color" && Array.isArray(elt[renameprop])) {
                const cityNames = elt[renameprop].map((e:any) => e?.name);
                elt[renameprop] = cityNames.join(', '); // Join country names with a comma             
              }
              if (renameprop === "printing_color_name" && Array.isArray(elt[renameprop])) {
                const cityNames = elt[renameprop].map((e:any) => e?.name);
                elt[renameprop] = cityNames.join(', '); // Join country names with a comma             
              }
              if (renameprop === "printing_color" && Array.isArray(elt[renameprop])) {
                const cityNames = elt[renameprop].map((e:any) => e?.name);
                elt[renameprop] = cityNames.join(', '); // Join country names with a comma             
              }
  

        }



        if (typeof (elt[renameprop]) === "undefined") {
          elt[renameprop] = "-"
        }
        data.push(elt[renameprop])
      })
      return data
    })
    const text = data.map((row: any) => row.join("\t")).join("\n");
    const hed = headers.join("\t") + "\n";
    navigator?.clipboard?.writeText(hed + text);
  }
}

  return (
    <>
      <Dropdown >
        <Dropdown.Toggle className='moreDropdown rounded-3' variant="primary" id="dropdown-basic" >
          More
        </Dropdown.Toggle>
        <Dropdown.Menu>


          <Dropdown.Item className='excelColor' onClick={handleDownloadExcel}>
            Excel <img src={ExcelLogo} className='float-end pr-2 pt-2' alt="" />
          </Dropdown.Item>

          {(excelBody.length > 0 ? <>
            <div className='csvColor mx-3'>
              <CSVLink data={forCsv} filename={"Report.csv"} separator={","} style={{ textDecoration: "none" }} >
                CSV
              </CSVLink>
              <img src={CSVlogo} className='float-end pr-2 pt-2' alt="" />
            </div>
          </>
            :
            <>
              <Dropdown.Item className='csvColor' onClick={() => {
                setShow(true)
                setshowmessage("danger")
              }}>
                CSV <img src={CSVlogo} className='float-end pr-2 pt-2' alt="" />
              </Dropdown.Item>
            </>
          )}

          <Dropdown.Item className='printColor' onClick={handlePDFDownload}>PDF <img src={PrintLogo} className='float-end pr-2 pt-2' alt="" /></Dropdown.Item>


          <Dropdown.Item className='printColor' onClick={handlePrint}

          >Print <img src={PrintLogo} className='float-end pr-2 pt-2' alt="" /></Dropdown.Item>

          <Dropdown.Item className='copyColor' onClick={handleCopy}>Copy <img src={CopyLogo} className='float-end pr-2 pt-2' alt="" /></Dropdown.Item>
          <Dropdown.Item href="#deleteMultiple" data-toggle="modal" className='deleteColor' onClick={handleConfirmDelete}>Delete <img src={DeleteLogo} className='float-end pr-2 pt-2' alt="" /></Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default More