import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/esm/Button";
import { useCreate } from '../../hooks/Api';
import { Alert } from "../../components/Model/Model";
import { useForm } from 'react-hook-form';
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from '../focus';
import Select from "react-select"
import { useNavigate } from 'react-router-dom';
import { useFetchColorDropDown, useFetchGSTDropDown } from '../../hooks/DropDown_Api';

const ntc = require('@yatiac/name-that-color');
const toHex = require('colornames')


export const Color_Model = ({refetchForm,open,setOpen,setSubmitted}:any) => {
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()

    const { register:register, handleSubmit:handleSubmit, formState: { errors },reset,setValue}: any = useForm({
        mode: "onChange"
    });


    const apiname = process.env.REACT_APP_COLOR_API?.toString()


    const  { mutate: department, data,isSuccess,error,isError}:any = useCreate(apiname)
  

    useEffect(()=>{
        if(isSuccess){
            setSubmitted(true)
            setOpen(false)
            reset()
            setForGSTValue(null)
        }
        refetchForm()
            },[isSuccess,data])


   
    const [forGST,setForGSTValue]:any = useState([])

 
const handleReset = () => {
    reset()
    setForGSTValue(null)
    }

    const onSubmit = (branddetail: any) => {
        
        const formData = new FormData();
        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else
            {
                formData.append(key, formvalue[0])
            }
            if(typeof value === 'object' && value !== null){
                formData.append(key, "")
            }
        }
        department(formData);

    }



    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);


    const [optionsGST, setOptionsGST] = useState([])

    const { data: colorData, refetch: colorRefetch } = useFetchColorDropDown()

    useEffect(() => {
        setOptionsGST(colorData)
    }, [colorData])

    const handleChange = (e: any) => {
        setValue("gst",`${e.value}`)
        setForGSTValue({label:e?.label,value:e?.value})

    }  






    const [color, setColor]: any = useState({ hex: "#ffffff" });
    const [colorname, setColorname]: any = useState("");
    const [colorName, setColorName] = useState("");
    const [cn, setCn] = useState("")
    const [displayColorPicker, setdisplayColorPicker] = useState(false);

    useEffect(() => {
        setColorName(ntc(color.hex).colorName);
    }, [color]);


    const handleClick = () => {
        setdisplayColorPicker(!displayColorPicker)
    };

    const handleClose = () => {
        setdisplayColorPicker(false)
    };

    const style = {
        popover: {
            position: 'absolute',
            zIndex: '2',
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
    }


    const onColorPickerInfoChange = (color: any) => {


        if (color.target.value.includes('#')) {
            // setColorname(ntc(color.target.value).colorName);
            setValue("name", ntc(color.target.value).colorName)
            setValue("hex", color.target.value)
            setCn(ntc(color.target.value).closestColor)
        }

        if (color.target.placeholder.includes("Name")) {
            // setColorname(color.target.value);
            if (toHex(color.target.value)) {
                // setColorname(toHex(color.target.value))
                setValue("hex", toHex(color.target.value))
                setCn(toHex(color.target.value))
            }
            else {
                color.target.value = color.target.value.toLowerCase()
        .split(' ')
        .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
                setColorname("")
            }
        }
        if (color.target.placeholder.includes("Code")) {
            setCn(ntc(color.target.value).closestColor)
            setValue("hex", color.target.value)
        }

        // setError(!errors)
    };


  


    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }


  return (
    <>
    <Modal  
    
    size='xl'
    
    show={open} onHide={()=>{
        setShow(false)
        reset()
        setOpen(false)}} aria-labelledby="contained-modal-title-vcenter"
centered    >
  <Modal.Header closeButton>
    <Modal.Title style={{fontSize:'16px'}}>Color</Modal.Title>
  </Modal.Header>
  <Modal.Body> 
     
  <form
                                            ref={focus}
                                         onKeyUp={event => onEnterKey(event)}
                                            onSubmit={handleSubmit(onSubmit)}
                                            
                                        >
                                             {show && showmessage === "success" ? <Alert color={showmessage} message={"Successfully Added"} /> : ""}

{show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}
                                            <div className="row d-flex justify-content-lg-start justify-content-sm-around justify-content-md-center">
                                            <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                          Company Color Name <span className="text-red">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Company Color Name"
                              {...register("company_color_name", {
                                onChange:handleValue, required: {
                                  value: true,
                                  message: "Company Color Name is required",
                                },
                                
                              })}
                              
                            />
                            <div className="input-group-append">
                              <span className="input-group-text border-start-0 bg-primary">
                                <RiPencilLine
                                  color="white"
                                  // style={{backgroundColor:"blue",color:"green"}}
                                />
                              </span>
                            </div>
                          </div>

                          <span className="text-red">
                            {errors?.company_color_name?.message}
                          </span>
                        </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Color Name <span className='text-red'>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control border-end-0" placeholder="Enter Color Name"
                                                            {...register("name", { required: { value: true, message: "Color Name is required" }, maxLength: 30 })} onChange={onColorPickerInfoChange}
                                                        />

                                                        <div className="input-group-append">
                                                            <input type='color' id="color1" value={cn} className="input-group-text bg-white border-start-0" onChange={onColorPickerInfoChange}></input>
                                                        </div>

                                                        <span className='text-red w-100'>{errors?.name?.message}</span>
                                                    </div>


                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Color Code<span className='text-red'>*</span></label> 
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Add Color Code"
                                                            {...register("hex", {
                                                                required: { value: true, message: "Color Code is required" },
                                                                pattern: { value: /^#(?:[0-9a-fA-F]{3}){1,2}$/, message: 'Color Code is not correct' }
                                                            })}
                                                            onChange={onColorPickerInfoChange}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            /></span>
                                                        </div>
                                                    </div>



                                                    <span className='text-red'>{errors?.hex?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span className='text-red'>*</span></label>

                                                    <select className={`form-select`} 
                                                        {...register("status", {
                                                            required: { value: true, message: "Status is required" }, maxLength: 30,

                                                        })}
                                                    >
                                                        <option value="" className='option' selected >-Status-</option>
                                                        <option value="active" >Active</option>
                                                        <option value="inactive" >Inactive</option>
                                                    </select>
                                                    <span className='text-red'>{errors?.status?.message}</span>
                                                </div>

                                                        <div>
      
                                                        </div>

                                            </div>
                                        </form>
      
</Modal.Body>
  <Modal.Footer>
  
    <Button variant="info" className="text-white" 
 onClick={handleSubmit(onSubmit)}
 style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
    >
      Submit
    </Button>
    <Button variant="info" className="text-white" style={{ borderColor: "blue", background: "blue", color: "white" }}
 onClick={handleReset}
 >
   Reset
 </Button>
    <Button variant="secondary" style={{ borderColor: "red", background: "red", color: "white" }} onClick={()=>{setOpen(false)}}>
      Cancel
    </Button>
  </Modal.Footer>
</Modal>
    </>
  )
}



