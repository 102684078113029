import { configureStore } from '@reduxjs/toolkit'
import toaststate from './toaststate'
import table from './table'
import permissions from './permissions'
import isDash from './isDash'
import priceListCalculation from './priceListCalculation'

export default configureStore({
  reducer: {
    toast: toaststate,
    table: table,
    permissions: permissions,
    isDash: isDash,
    priceListCalculation:priceListCalculation
    
  },
})