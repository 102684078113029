import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Link, redirect, useNavigate, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import moment from "moment";
import { useIdleTimer } from 'react-idle-timer'
import { useCreate, useFetch } from '../../hooks/Api';
import { useFetchCheckListMapperDropDown } from '../../hooks/DropDown_Api';

const convertToDateObject = (dateString:any) => {
  const [day, month, year] = dateString.split('-');
  return new Date(year, month - 1, day);
};

const isDateInRange = (startDate:any, endDate:any, currentDate:any) => {
  const start = convertToDateObject(startDate);
  const end = convertToDateObject(endDate);
  end.setHours(23, 59, 59, 999); 

  return start <= currentDate && currentDate <= end;
};


const newlyAdded = (startDate:any, currentDate:any) => {
  const start = convertToDateObject(startDate);
  // const end = convertToDateObject(endDate);
  // end.setHours(23, 59, 59, 999); 

  return start >= currentDate 
};

const outDated = (endDate:any, currentDate:any) => {
  // const start = convertToDateObject(startDate);
  const end = convertToDateObject(endDate);
  end.setHours(23, 59, 59, 999); 

  return  currentDate >= end;
};




const Actions_Yes_No = ({ row, refetch,choiceData
 
}: any) => {



  const location = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    // Return a cleanup function that sets a value in sessionStorage when the component unmounts
    return () => {
      sessionStorage.setItem('refreshMyComponent', 'true');
    };
  }, []);


  useEffect(() => {
    // Check if the sessionStorage value indicates a refresh is needed
    if (sessionStorage.getItem('refreshMyComponent') === 'true') {
      // Perform your refresh logic here
      console.log('Page refreshed');

      window.location.reload()

      // Clear the sessionStorage value
      sessionStorage.removeItem('refreshMyComponent');
    }
  }, []);




  // console.log('row',row.original)

  // useEffect(() => {
  //   const Point_Start_Date = moment(row.original.point_start_date).startOf('day');
  //   const Point_End_Date = moment(row.original.point_end_date).startOf('day');
  //   const currentDate = moment().startOf('day');
    
  //   // Check if current date is between start and end dates
  //   const isCurrentDateBetween = currentDate.isBetween(Point_Start_Date, Point_End_Date, 'day', '[]');
    
  //   console.log('Start Date:', Point_Start_Date.format('DD-MM-YYYY'));
  //   console.log('End Date:', Point_End_Date.format('DD-MM-YYYY'));
  //   console.log('Is current date between start and end date:', isCurrentDateBetween);

  // }, [row]);


  const [isInRange, setIsInRange] = useState(false);
  const [isNewly, setIsNewly] = useState(false);
  const [isOutDated, setIsOutDated] = useState(false);



  useEffect(() => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // reset hours, minutes, seconds, and milliseconds

    const startDate = row.original.point_start_date; // DD-MM-YYYY format
    const endDate = row.original.point_end_date;   // DD-MM-YYYY format

    const inRange = isDateInRange(startDate, endDate, currentDate);

    const newlyAddedTask = newlyAdded(startDate, currentDate)
    const outDatedTask = outDated(endDate, currentDate)

    setIsNewly(newlyAddedTask)

    setIsOutDated(outDatedTask)

    setIsInRange(inRange);

    
  }, []);

  const closeTime = row?.allCells?.map((e:any)=>{
   
      
    return e.row.values.point_close_time
  
  
})

const open = row?.allCells?.map((e:any)=>{
   
      
  return e.row.values.point_open_time


})

const[openTime,setOpenTime]:any = useState()
const [time,setTime]:any = useState()



  useEffect(()=>{

    const close = closeTime[0]
    const closeHour = close.split(":")[0]
    const closeMinute = close.split(":")[1]
    const closeSecond = close.split(":").pop()
    




    var d = new Date(); // for now
    let hour = d.getHours(); // => 9
    let minute = d.getMinutes(); // =>  30
    let second = d.getSeconds(); // => 51


  


   







    const TimeOne = moment()
    .set('hour',closeHour)
    .set('minute',closeMinute)
    .set('second',closeSecond)

    const TimeTwo = moment()
    .set('hour',hour)
    .set('minute',minute)
    .set('second',second)





    const final = TimeTwo.diff(TimeOne,'second')






    setTime(final)


   



   






  },[row,time])




  // useEffect(()=>{
  //   let interval:any;
  //   if (time || openTime) {
  //     interval = setInterval(() => {
  //       setTime()
  //       setOpenTime()
       
  //     }, 1000);
  //   } else {
  //      clearInterval(interval);
  //   }
  //   return () => clearInterval(interval);
  // },[time,openTime])


  const [TooLate,setTooLate] = useState(false)
  const [changeUpdate,setChangeUpdate] = useState(0)


  useEffect(()=>{
    let interval:any;
    if (changeUpdate) {
      interval = setInterval(() => {
        setTime()
        setOpenTime()
       
      }, 2000);
    } else {
       clearInterval(interval);
    }
    return () => clearInterval(interval);
  },[changeUpdate])



  
  

  useEffect(()=>{

    const openFinalTime = open[0]
    const openHour = openFinalTime.split(":")[0]
    const openMinute = openFinalTime.split(":")[1]
    const openSecond = openFinalTime.split(":").pop()
  




    var d = new Date(); // for now
    let hour = d.getHours(); // => 9
    let minute = d.getMinutes(); // =>  30
    let second = d.getSeconds(); // => 51




   







    const TimeOne = moment()
    .set('hour',openHour)
    .set('minute',openMinute)
    .set('second',openSecond)

    const TimeTwo = moment()
    .set('hour',hour)
    .set('minute',minute)
    .set('second',second)





    const final = TimeTwo.diff(TimeOne,'second')



    setOpenTime(final)




  },[row,openTime])





const apiname = process.env.REACT_APP_CHECKLIST_MAPPER_API
const {mutate,data,isSuccess,isError,error} = useCreate(apiname)




useEffect(()=>{
if(time === 0){
 
  mutate({checkList:row.original.id,choice_yes_no:"pending"})
}
// console.log("time",time)
},[time])

const handleYes = (e:any)=>{

  mutate({checkList:row.original.id,choice_yes_no:"yes"})
  setSubmitted(true)
}


const handleNo = (e:any)=>{

  mutate({checkList:row.original.id,choice_yes_no:"no"})
  setSubmitted(true)

}

const [submitted,setSubmitted] = useState(false)

const {data:checklistMapperData} = useFetchCheckListMapperDropDown()
const [fetchData,setFetchData]:any = useState()

useEffect(()=>{

  setFetchData(checklistMapperData?.filter((e:any)=> e.value == row.original.id))



},[checklistMapperData,row.original.id])






useEffect(()=>{






if(isSuccess){
  if(data?.data?.choice_yes_no == "yes"){
  setSubmitted(true)
  }
}




},[isSuccess,data,submitted])


useEffect(()=>{
  if(row){
    if(row?.original?.checklistMapper[0]?.choice_yes_no == "yes"){
    setSubmitted(true)
    }else{

    }
  }
  
},[row])

console.log('row?.original?.checklistMapper[0]?.choice_yes_no == "yes"',row?.original?.checklistMapper[0]?.choice_yes_no == "yes")


useEffect(()=>{
  if(time >= 0){
    setChangeUpdate(changeUpdate + 1)

  }

  if(openTime <=0){
    setChangeUpdate(changeUpdate + 1)
  }


  if(openTime >= 0 && time <= 0){
    setChangeUpdate(changeUpdate + 1)
  }


  if(openTime >= 0 && time <= 0){
    setChangeUpdate(changeUpdate + 1)
  }
 
},[time,openTime])

// const [tooLateTrigger,setTooLateTrigger] = useState(false)

// useEffect(()=>{
// if(time >= 0 && !submitted&&changeUpdate && isInRange &&isNewly&&!isOutDated){
//   mutate({checkList:row.original.id,choice_yes_no:"pending"})
//   setTooLateTrigger(true)
// }
// },[time,
//   submitted,
//   changeUpdate,
//   isInRange,
//   isNewly,
//   isOutDated])







console.log('time',time,'openTime',openTime)


  return (
    <>


{time >= 0 && !submitted&&changeUpdate && isInRange &&isNewly&&!isOutDated ?<>




     <div className='vertical-align-center' key={fetchData&&fetchData[0]?.value} style={{ display: "inline-block !important"}}> 
     
     <button className="btn btn-primary text-white mx-2" disabled>Too Late</button>
     </div>
      </>:
      null
      }

{/* {upcoming ?<>




<div className='vertical-align-center' style={{ display: "inline-block !important"}}> 

<button className="btn btn-primary text-white mx-2" disabled>Upcoming</button>
</div>
 </>:
 null
 } */}



{openTime <=0 &&changeUpdate && !submitted&& isInRange&& <>


  <div className='vertical-align-center' key={fetchData&&fetchData[0]?.value} style={{ display: "inline-block !important"}}> 
     
     <button className="btn btn-primary text-white mx-2" disabled 
     id={row.original.id}
     >Upcoming</button>
     </div>


</>}


{/* {fetchData&&fetchData[0]?.choice_yes_no == "no" &&  <>

<div className='vertical-align-center' key={fetchData&&fetchData[0]?.value} style={{ display: "inline-block !important"}}> 

<button className="btn btn-success text-white mx-2" id={row.original.id} disabled >Submitted !</button>

</div>

</>} */}

{submitted && isInRange&& !isOutDated&&<>

  <div className='vertical-align-center' key={fetchData&&fetchData[0]?.value} style={{ display: "inline-block !important"}}> 
   
   <button className="btn btn-success text-white mx-2" disabled >Submitted !</button>
   </div>

</>}


{isNewly && !isInRange&&<>

<div className='vertical-align-center' key={fetchData&&fetchData[0]?.value} style={{ display: "inline-block !important"}}> 
 
 <button className="btn btn-info text-white mx-2" disabled >Newly Added</button>
 </div>

</>}

{isOutDated && !isInRange&&<>

<div className='vertical-align-center' key={fetchData&&fetchData[0]?.value} style={{ display: "inline-block !important"}}> 
 
 <button className="btn btn-warning text-white mx-2" disabled >Out Dated</button>
 </div>

</>}





{openTime >= 0 && time <= 0  &&changeUpdate && !submitted && isInRange &&   !isOutDated&&


<>

  <div className='vertical-align-center' 
  key={fetchData&&fetchData[0]?.value} 
  onClick={()=>console.log("id",fetchData&&fetchData[0]?.value)}  
  style={{ display: "inline-block !important", justifyContent: "space-evenly !important" }}> 


  
  <button className="btn btn-primary text-white mx-2" 
   id={row.original.id}
   onClick={handleYes} >YES</button>
       <button className="btn btn-primary text-white" 
        id={row.original.id}
        onClick={handleNo}>NO</button>
  
 
 

       

     </div>


</>

}

    
    </>
  )
}

export default Actions_Yes_No

