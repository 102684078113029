import { createSlice } from '@reduxjs/toolkit'

export const IsDash = createSlice({
  name: 'isDash',
  initialState: {
    status: false,
  },
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload
    },
  },
})


// Action creators are generated for each case reducer function
export const { setStatus } = IsDash.actions

export default IsDash.reducer