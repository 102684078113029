import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
// import MyTable from "./MyTable";
import { Columns_Task_List, titleofheaders } from "./table-data";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Editable_Table from "../Dynamic_Table/Table/Editable_Table";
import { Columns_Color } from "../../pages/Color/Columns_Color";
import Axiosinstance from "../../hooks/Interceptor";
import { useForm } from "react-hook-form";
import { useCreate } from "../../hooks/Api";
import { Alert } from "../Model/Model";
import Mui_Toaster from "../Mui_Toaster/Mui_Toaster";
import Select from 'react-select'
import { useFetchColorDropDown } from "../../hooks/DropDown_Api";
import { RiDeleteBinLine } from 'react-icons/ri';
import { useMutation } from "react-query";
import axios from "axios";


// import "./styles.css";

// const TableStyles = styled.div`
//   padding: 1rem;

//   table {
//     border-spacing: 0;
//     border: 1px solid black;

//     tr {
//       :last-child {
//         td {
//           border-bottom: 0;
//         }
//       }
//     }

//     th,
//     td {
//       margin: 0;
//       padding: 0.5rem;
//       border-bottom: 1px solid black;
//       border-right: 1px solid black;

//       :last-child {
//         border-right: 0;
//       }
//       input {
//         font-size: 1rem;
//         padding: 0;
//         margin: 0;
//         border: 0;
//       }
//     }
//   }
// `;

const Viewable = ({
  allData,
  setMessage,
  setCartonPlyId,
  setOpen,
  checkToast,
  cartonSize,
  cartonLength = 0,
  cartonWidth = 0,
  cartonHeight = 0,
  kanHeight = 0,
  kanWidth = 0,
  panniKan = "no",
  // setSavedData
}: any) => {
  const [paperSizeLengthMM, setPaperSizeLengthMM] = useState(0);
  const [paperDecalSizeMM, setPaperDecalSizeMM] = useState(0);
  const [totalMM, settotalMM] = useState(0);

  const [paperLiner, setPaperLiner] = useState(0);
  const [usePaper, setUsePaper]: any = useState("");
  const [totalOfPaperGSM, setTotalOfPaperGSM] = useState(0);

  const [paperQty, setPaperQty] = useState(0);
  const [paperWeight, setPaperWeight] = useState(0);
  const [total_MM, setTotal_MM] = useState(0);

  const [tableData, setTableData]: any = React.useState([]);

  const memoizedColumns = React.useMemo(() => Columns_Task_List, []);
  const memoizedData = React.useMemo(() => tableData, [tableData]);
  const [selectedColor, setSelectedColor]: any = useState([]);
  const [selColor, setSelColor] = useState([]);
  const data: any = {
    paper_name: "",
    use_paper: "",
    paper_color: "",
    paper_design: "",
    paper_bursting_factor: "",
    paper_gsm: 0,
    paper_liner: "",
    paper_length_mm: cartonLength,
    paper_decal: cartonWidth,
    carton_height: cartonHeight,
    kan_width: kanWidth,
    kan_height: kanHeight,
    paper_size_length_mm: Number(cartonLength + cartonWidth + kanWidth),
    paper_decal_size_mm: paperDecalSizeMM,
    total_mm: paperSizeLengthMM * paperDecalSizeMM,
    paper_qty: "",
    paper_weight: "",
    paper_printing: "",
  };

  const handlePaperLiner = (e: any, index: any) => {
    // setPaperLiner(e.target.value);

    let temp_data = structuredClone(tableData);
    let temp_updated = temp_data.map((d: any, ind: any) => {
      let temp_d = { ...d };
      if (index === ind) {
        temp_d["paper_liner"] = e.target.value;
        temp_d["paper_length_mm"] =
          Number((cartonLength * Number(temp_d.paper_liner)) / 100) +
          cartonLength +
          cartonWidth +
          kanWidth;
        temp_d["total_mm"] =
          Number(temp_d["paper_length_mm"]) * paperDecalSizeMM;
      }
      return temp_d;
    });
    setTableData(temp_updated);
    // setUsePaper(e.target.value);
  };

  const handleUsePaper = (e: any, index: any) => {
    let temp_data = structuredClone(tableData);
    let temp_updated = temp_data.map((d: any, ind: any) => {
      let temp_d = { ...d };
      if (index === ind) {
        temp_d["use_paper"] = e.target.value;
      }
      return temp_d;
    });
    setTableData(temp_updated);
    // setUsePaper(e.target.value);
  };

  const handlePaperGSM = (e: any, index: any) => {
    let temp_data = structuredClone(tableData);
    let temp_updated = temp_data.map((d: any, ind: any) => {
      let temp_d = { ...d };
      if (index === ind) {
        temp_d["paper_gsm"] = e.target.value;
      }
      return temp_d;
    });
    setTableData(temp_updated);
    // setUsePaper(e.target.value);
  };

  const handlePaperQty = (e: any, index: any) => {
    let temp_data = structuredClone(tableData);
    let temp_updated = temp_data.map((d: any, ind: any) => {
      let temp_d = { ...d };
      if (index === ind) {
        temp_d["paper_qty"] = e.target.value;
      }
      return temp_d;
    });
    setTableData(temp_updated);
    // setUsePaper(e.target.value);
  };

  const handlePaperWeight = (e: any, index: any) => {
    let temp_data = structuredClone(tableData);
    let temp_updated = temp_data.map((d: any, ind: any) => {
      let temp_d = { ...d };
      if (index === ind) {
        temp_d["paper_weight"] = e.target.value;
      }
      return temp_d;
    });
    setTableData(temp_updated);
    // setUsePaper(e.target.value);
  };

  const handlePaperName = (e: any, index: any) => {
    let temp_data = structuredClone(tableData);
    let temp_updated = temp_data.map((d: any, ind: any) => {
      let temp_d = { ...d };
      if (index === ind) {
        temp_d["paper_name"] = e.target.value;
      }
      return temp_d;
    });
    setTableData(temp_updated);
    // setUsePaper(e.target.value);
  };

  const handlePaperColor = (e: any, index: any) => {
    let temp_data = structuredClone(tableData);
    let temp_updated = temp_data.map((d: any, ind: any) => {
      let temp_d = { ...d };
      if (index === ind) {
        temp_d["paper_color"] = e.value;
        setValue("paper_color", e.value)
      }
      return temp_d;
    });
    setTableData(temp_updated);
    // setUsePaper(e.target.value);
  };
  const handlePaperDesign = (e: any, index: any) => {
    let temp_data = structuredClone(tableData);
    let temp_updated = temp_data.map((d: any, ind: any) => {
      let temp_d = { ...d };
      if (index === ind) {
        temp_d["paper_design"] = e.target.value;
      }
      return temp_d;
    });
    setTableData(temp_updated);
    // setUsePaper(e.target.value);
  };
  const handlePaperBF = (e: any, index: any) => {
    let temp_data = structuredClone(tableData);
    let temp_updated = temp_data.map((d: any, ind: any) => {
      let temp_d = { ...d };
      if (index === ind) {
        temp_d["paper_bursting_factor"] = e.target.value;
      }
      return temp_d;
    });
    setTableData(temp_updated);
    // setUsePaper(e.target.value);
  };
  const handlePaperPrinting = (e: any, index: any) => {
    let temp_data = structuredClone(tableData);
    let temp_updated = temp_data.map((d: any, ind: any) => {
      let temp_d = { ...d };
      if (index === ind) {
        temp_d["paper_printing"] = e.target.value;
      }
      return temp_d;
    });
    setTableData(temp_updated);
    // setUsePaper(e.target.value);
  };

  useEffect(() => {
    let temp_data = structuredClone(tableData);
    let temp_gsm = temp_data
      .map((d: any, ind: any) => {
        return Number(d.paper_gsm);
      })
      .reduce((a: any, b: any) => a + b, 0);
    setTotalOfPaperGSM(temp_gsm);

    let temp_qty = temp_data
      .map((d: any, ind: any) => {
        return Number(d.paper_qty);
      })
      .reduce((a: any, b: any) => a + b, 0);
    setPaperQty(temp_qty);

    let temp_paper_weight = temp_data
      .map((d: any, ind: any) => {
        return parseFloat(d.paper_weight);
      })
      .reduce((a: any, b: any) => a + b, 0);
    setPaperWeight(temp_paper_weight.toFixed(3));

    let temp_total_mm = temp_data
      .map((d: any, ind: any) => {
        return Number(d.total_mm);
      })
      .reduce((a: any, b: any) => a + b, 0);
    setTotal_MM(temp_total_mm.toFixed(3));
  }, [tableData]);

  useEffect(() => {
    setPaperSizeLengthMM(Number(cartonLength + cartonWidth + kanWidth));
  }, [cartonWidth, kanWidth, cartonLength]);

  useEffect(() => {
    setPaperDecalSizeMM(Number(cartonWidth) + cartonHeight);
  }, [cartonWidth, cartonHeight, paperDecalSizeMM]);

  useEffect(() => {
    settotalMM(Number(paperSizeLengthMM) * paperDecalSizeMM);
  }, [paperSizeLengthMM, paperDecalSizeMM, totalMM]);



  React.useEffect(() => {
    let blankData: any = [];
    let temp = { ...data };

    setTableData([...allData?.product_ply]);
  }, [
    cartonSize,
    cartonLength,
    cartonWidth,
    cartonHeight,
    kanHeight,
    kanWidth,
    paperSizeLengthMM,
    paperDecalSizeMM,
  ]);

  const handleNewRowClick = () => {
    setTableData([{}, ...tableData]);
  };

  const updateMyData = (rowIndex: any, columnId: any, value: any) => {
    setTableData((prevTableData: any) =>
      prevTableData.map((row: any, index: any) => {
        if (index === rowIndex) {
          return {
            ...prevTableData[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };


  const Breadcrumb_Primary_Title = "Home";
  const Breadcrumb_Primary_Link = "Color";

  const Breadcrumb_Parent_Title = "Carton Ply";

  const Breadcrumb_Title_Name = "Carton Ply";

  const apiname = process.env.REACT_APP_TASK_LIST_API?.toString();

  const filter = {
    name: "",
    age: "",
  };

  const addButtonName = "Carton Ply List";
  const addButtonPageName = "taskList";

  const stickyStart = -1;
  const stickyEnd = -1;

  // const [savedData,setSavedData]:any = useState([])
  const apiCartonPly = process.env.REACT_APP_CARTON_PLY_API?.toString()
  const { mutate: cartonPly, data: CartonPly, isError, isLoading, isSuccess, error }: any = useCreate(apiCartonPly)

  const { register, handleSubmit, setError, formState: { errors, isDirty }, reset, setValue, getValues }: any = useForm({
    mode: "onChange"
  });

  const onSubmit = () => {
    // cartonPly({cartonPly:tableData})
    setCartonPlyId(oldIds)

    // Axiosinstance.post('/login', tableData).then((data:any)=>{});
  }

  // const [cartonPlyId,setCartonPlyId] = useState([])


  useEffect(() => {
    let cartonIds = CartonPly?.data?.map((e: any) => {
      return e?.id
    })
    setCartonPlyId(cartonIds)


  }, [CartonPly])



  const [show, setShow] = useState(false)
  const [showmessage, setshowmessage] = useState("")


  // const [message,set]

  useEffect(() => {
    if (isSuccess) {
      setShow(true)
      setshowmessage("success")
      setOpen(false)

      setMessage(true)
      // reset()
      // navigate('/listBrand', { state: { showToast: true }})
    }
    else if (isError) {
      setShow(true)
      setshowmessage("danger")
    }
  }, [data, isSuccess, error])



  useEffect(() => {
    setTimeout(() => {
      setShow(false)
    }, 3000)

  }, [show]);

  const [options, setOptions] = useState([])

  const { data: colorData, refetch: colorRefetch } = useFetchColorDropDown()

  useEffect(() => {
    setOptions(colorData)
  }, [colorData])




  const [color, setColor] = useState()
  const handleChange = (e: any) => {

    // const color = e.map((value: any) => {
    //     return value.value
    // })
    setColor(e.value)
    setValue("paper_color", e.value)
    setSelectedColor(e)

    // setForColorValue(e)

  }


  const [oldIds, setOldIds] = useState([])

  useEffect(() => {

    let oldCartonPlyIds = allData?.product_ply?.map((e: any) => {
      return e.id
    })
    setOldIds(oldCartonPlyIds)

  }, [allData])


  // {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}
  //                               {checkToast&&show && showmessage === "success" && 

  //                                   <Mui_Toaster message={"Brand Added Successfully"} severity={"success"} Isopen={true}/>
  //                               }



  //   const { data: bulkCartonPlyData, refetch: bulkCartonPlyRefetch } = useFetchBulkCartonPly()


  const deletePost = useMutation((id: any) => {
    return Axiosinstance.delete(
      `${process.env.REACT_APP_BASE_URL}cartonPly/${id}`
      // `http://localhost:3004/posts/${id}`

    );
  });

  return (
    <>
      <div className="">
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{Breadcrumb_Title_Name} List</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to={`/list${Breadcrumb_Primary_Link}`}
                      className="text-decoration-none text-black"
                    >
                      {Breadcrumb_Primary_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      {Breadcrumb_Parent_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {Breadcrumb_Title_Name} List
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="container p-0" style={{ maxWidth: "100%" }}>
                {/* <button onClick={handleNewRowClick}>Add New</button> */}
                {/* <BasicTable/> */}
                {/* <Editable_Table
                  apiname={apiname}
                  filterFields={filter}
                  addButtonName={addButtonName}
                  stickyStart={stickyStart}
                  stickyEnd={stickyEnd}
                  TableColumns={memoizedColumns}
                  data={memoizedData}
                  updateMyData={updateMyData}
                  addButtonPageName={addButtonPageName}
                  titleofheaders={titleofheaders}
                  handleNewRowClick={handleNewRowClick}
                /> */}

                <div className="table-responsive ">
                  {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}

                  {/* {checkToast&&show && showmessage === "success" && 
                                
                                                                   <Mui_Toaster message={"Added Successfully"} severity={"success"} Isopen={true}/>
                                                               } */}
                  <form action="">
                    <table
                      className="table table-bordered table-hover"
                      style={{ width: "max-content" }}
                    >

                      <thead className="text-center">
                        <tr>
                          {/* <th scope="col">#</th> */}
                          <th scope="col">
                            Paper <br /> Name
                          </th>
                          <th scope="col">
                            Use
                            <br /> Paper
                          </th>
                          <th scope="col">
                            Paper
                            <br /> Color
                          </th>
                          <th scope="col">
                            Paper <br />
                            Design
                          </th>
                          <th scope="col">
                            Paper
                            <br /> BF
                          </th>
                          <th scope="col">
                            Paper
                            <br /> GSM
                          </th>
                          <th scope="col">
                            Paper
                            <br /> Liner
                          </th>
                          <th scope="col">
                            Carton
                            <br /> Length MM
                          </th>
                          <th scope="col">
                            Carton
                            <br /> Width MM
                          </th>
                          <th scope="col">
                            Carton
                            <br /> Height MM
                          </th>
                          <th scope="col">
                            Carton
                            <br /> Kan Width MM
                          </th>
                          <th scope="col">
                            Carton
                            <br /> Kan Height MM
                          </th>
                          <th scope="col">
                            Paper <br />
                            Size Length MM
                          </th>
                          <th scope="col">
                            Paper <br />
                            Decal Size MM(Width)
                          </th>
                          <th scope="col">
                            Total
                            <br /> MM
                          </th>
                          <th scope="col">
                            Paper
                            <br /> Qty
                          </th>
                          <th scope="col">
                            Paper
                            <br /> Weight
                          </th>
                          <th scope="col">
                            Paper
                            <br /> Printing
                          </th>
                          {/* <th scope="col">
                          Action
                        </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {tableData?.map((data: any, index: any) => {
                          return (
                            <tr>
                              <td style={{ width: "180px" }}>
                                <div>
                                  {/* <select
                              disabled
                              //  {...register("name", { required: { value: true, message: "required" } })}
                                key={index}
                                // id={allData.product_ply?.id}

                                value={data?.paper_name}
                                
                                onChange={(e: any) => handlePaperName(e, index)}
                                className="text-center form-select"
                              >
                                <option value="" selected>
                                  select
                                </option>
                                <option value="GOLDEN_PAPER">Golden Paper</option>
                                <option value="NATURAL_PAPER">Natural Paper</option>
                              </select> */}

                                  <input
                                    key={index}
                                    type="text"
                                    disabled
                                    className="text-center form-select "
                                    value={data?.paper_name?.name}
                                  // style={{ width: "-webkit-fill-available" }}
                                  />
                                </div>
                                {/* <span className='text-red w-100'>{errors?.name?.message}</span> */}
                              </td>
                              <td style={{ width: "150px" }}>
                                <div>
                                  <select
                                    // {...register("use_paper", { required: { value: true, message: "required" } })}
                                    key={index}
                                    disabled
                                    value={data?.use_paper}
                                    onChange={(e: any) => handleUsePaper(e, index)}
                                    className="text-center form-select"
                                  >
                                    <option value="" selected>
                                      select
                                    </option>
                                    <option value="PLAIN">Plain</option>
                                    <option value="LINER">Liner</option>
                                  </select>
                                </div>
                                {/* <span className='text-red w-100'>{errors?.use_paper?.message}</span> */}

                              </td>

                              <td style={{ width: "150px" }}>
                                <div>
                                  {/* <select
                                                              {...register("paper_color", { required: { value: true, message: "required" } })}

                                key={index}
                                onChange={(e: any) =>
                                  handlePaperColor(e, index)
                                }
                                className="px-4"
                              >
                                <option value="" selected>
                                  select
                                </option>
                                <option value="brown">Brown</option>
                              </select> */}

                                  {/* <Select
                                                  
                                                        {...register("paper_color", { required: { value: true, message: "Color is required" } })}
                                                        // placeholder={'Select Color'}
                                                        onChange={(e: any) =>
                                                          handlePaperColor(e, index)
                                                        }
                                                        options={options}
                                                        // isMulti
                                                        // defaultValue={options}
                                                        // value={data?.paper_color}
                                                        value={selColor.length === 0 ? options?.map((obj: any) => obj.value === selectedColor) : selColor}
                                                        // value={options?.find((obj: any) => obj.value === selectedColor)}
                                                        // value={data?.paper_color?.name}
                                                        isOptionDisabled={(options:any)=>
                                                            {
                                                                if(options.status === "inactive" || options.deleted == true){
                                                                return options
                                                                }
                                                               }}
                                                        // className="dropdown-select"
                              
                                                        /> */}
                                  <input
                                    key={index}
                                    type="text"
                                    disabled
                                    className="text-center form-select "
                                    value={data?.paper_color.name}
                                  // style={{ width: "-webkit-fill-available" }}
                                  />



                                </div>
                                {/* <span className='text-red w-100'>{errors?.paper_color?.message}</span> */}
                              </td>

                              <td style={{ width: "140px" }}>
                                <div>
                                  {/* <select
                                    // {...register("paper_design", { required: { value: true, message: "required" } })}

                                    key={index}
                                    disabled
                                    value={data?.paper_design}
                                    onChange={(e: any) =>
                                      handlePaperDesign(e, index)
                                    }
                                    className="text-center form-select"
                                  >
                                    <option value="" selected>
                                      select
                                    </option>
                                    <option value="Triangle">Triangle</option>
                                    <option value="Verbal">Verbal</option>
                                  </select> */}

                                  <input
                                    key={index}
                                    type="text"
                                    disabled
                                    className="text-center form-select "
                                    value={data?.paper_design?.name}
                                  // style={{ width: "-webkit-fill-available" }}
                                  />
                                </div>
                                {/* <span className='text-red w-100'>{errors?.paper_design?.message}</span> */}
                              </td>
                              <td >
                                <div>
                                  {/* <select
                                    // {...register("paper_bf", { required: { value: true, message: "required" } })}

                                    key={index}
                                    disabled
                                    value={data?.paper_bursting_factor}
                                    onChange={(e: any) => handlePaperBF(e, index)}
                                    className="text-center form-select"
                                  >
                                    <option value="" selected>
                                      select
                                    </option>
                                    <option value={"12"}>12</option>
                                    <option value={"16"}>16</option>
                                  </select> */}

                                  <input
                                    key={index}
                                    type="text"
                                    disabled
                                    className="text-center form-select "
                                    value={data?.paper_bursting_factor?.name}
                                  // style={{ width: "-webkit-fill-available" }}
                                  />
                                </div>
                                {/* <span className='text-red w-100'>{errors?.paper_bf?.message}</span> */}
                              </td>
                              <td style={{ width: "180px" }}>
                                <div>
                                  {/* <select
                                    // {...register("paper_gsm", { required: { value: true, message: "required" } })}

                                    key={index}
                                    disabled
                                    value={data?.paper_gsm}
                                    onChange={(e: any) => handlePaperGSM(e, index)}
                                    className="text-center form-select"
                                  >
                                    <option value="" selected>
                                      select
                                    </option>
                                    <option value={"100"}>100</option>
                                    <option value={"150"}>150</option>
                                  </select> */}

                                  <input
                                    key={index}
                                    type="text"
                                    disabled
                                    className="text-center form-select "
                                    value={data?.paper_gsm?.name}
                                  // style={{ width: "-webkit-fill-available" }}
                                  />
                                </div>
                                {/* <span className='text-red w-100'>{errors?.paper_gsm?.message}</span> */}
                              </td>
                              <td style={{ width: "100px" }}>
                                <div>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={data?.paper_liner}
                                    disabled={data.use_paper !== "LINER"}
                                    key={index}
                                    onChange={(e: any) =>
                                      handlePaperLiner(e, index)
                                    }
                                    style={{ width: "-webkit-fill-available" }}
                                  />
                                </div>
                              </td>
                              <td style={{ width: "100px" }}>
                                <div>
                                  <input
                                    key={index}
                                    type="number"
                                    className="form-control"
                                    disabled
                                    value={allData?.product_length_mm}
                                    style={{ width: "-webkit-fill-available" }}
                                  />
                                </div>
                              </td>
                              <td style={{ width: "100px" }}>
                                <div>
                                  <input
                                    type="number"
                                    className="form-control"
                                    key={index}
                                    disabled
                                    value={allData?.product_width_mm}
                                    style={{ width: "-webkit-fill-available" }}
                                  />
                                </div>
                              </td>
                              <td style={{ width: "100px" }}>
                                <div>
                                  <input
                                    type="number"
                                    className="form-control"
                                    key={index}
                                    disabled
                                    value={allData.product_height_mm}
                                    style={{ width: "-webkit-fill-available" }}
                                  />
                                </div>
                              </td>
                              <td style={{ width: "180px" }}>
                                <div>
                                  <input
                                    type="number"
                                    className="form-control"
                                    key={index}
                                    value={allData?.product_kan_width}
                                    disabled
                                    style={{ width: "-webkit-fill-available" }}
                                  />
                                </div>
                              </td>
                              <td style={{ width: "180px" }}>
                                <div>
                                  <input
                                    type="number"
                                    className="form-control"
                                    key={index}
                                    value={allData.product_kan_length}
                                    disabled
                                    style={{ width: "-webkit-fill-available" }}
                                  />
                                </div>
                              </td>
                              <td style={{ width: "180px" }}>
                                <div>
                                  <input
                                    type="number"
                                    className="form-control"
                                    key={index}
                                    value={data?.paper_length_mm}
                                    disabled
                                    style={{ width: "-webkit-fill-available" }}
                                  />
                                </div>
                              </td>
                              <td style={{ width: "180px" }}>
                                <div>
                                  <input
                                    type="number"
                                    className="form-control"
                                    key={index}
                                    value={data?.paper_decal_size_mm}
                                    disabled
                                    style={{ width: "-webkit-fill-available" }}
                                  />
                                </div>
                              </td>
                              <td style={{ width: "180px" }}>
                                <div>
                                  <input
                                    type="number"
                                    className="form-control"
                                    key={index}
                                    value={data.total_mm}
                                    disabled
                                    style={{ width: "-webkit-fill-available" }}
                                  />
                                </div>
                              </td>
                              <td style={{ width: "180px" }}>
                                <div>
                                  <input
                                    // {...register("paper_qty", { required: { value: true, message: "required" } })}
                                    type="number"
                                    className="form-control"
                                    value={data.paper_qty}
                                    key={index}
                                    disabled
                                    onChange={(e: any) => handlePaperQty(e, index)}
                                    style={{ width: "-webkit-fill-available" }}
                                  />
                                </div>
                                {/* <span className='text-red w-100'>{errors?.paper_qty?.message}</span> */}
                              </td>
                              <td style={{ width: "180px" }}>
                                <div>
                                  <input
                                    // {...register("paper_weight", { required: { value: true, message: "required" } })}
                                    type="number"
                                    className="form-control"
                                    value={data.paper_weight}
                                    key={index}
                                    disabled
                                    onChange={(e: any) =>
                                      handlePaperWeight(e, index)
                                    }
                                    style={{ width: "-webkit-fill-available" }}
                                  />
                                </div>
                                {/* <span className='text-red w-100'>{errors?.paper_weight?.message}</span> */}
                              </td>
                              <td style={{ width: "180px" }}>
                                <div>
                                  <select
                                    // {...register("printing", { required: { value: true, message: "required" } })}
                                    key={index}
                                    disabled
                                    value={data?.paper_printing}
                                    onChange={(e: any) =>
                                      handlePaperPrinting(e, index)
                                    }
                                    className="text-center form-select"
                                  >
                                    <option value="" selected>
                                      select
                                    </option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                </div>
                                {/* <span className='text-red w-100'>{errors?.printing?.message}</span> */}
                              </td>



                              {/* <td >
                            <div className='vertical-align-center' style={{ display: "inline-block !important", justifyContent: "center",textAlign:"center" }}> 
                            <button
          onClick={(e:any) => {
            e.preventDefault()
            let temp = oldIds?.filter((item:any) => item !== data?.id)
            let temp_data = tableData?.filter((item:any) => item.id !== data?.id)
            setCartonPlyId(temp)
            setOldIds(temp)
            setTableData(temp_data)
            // deletePost.mutate(data.id)
        }}
          className="btn btn-primary text-white" ><RiDeleteBinLine /></button>
       
      </div>
                            </td> */}
                            </tr>
                          );
                        })}

                        <tr>
                          <td colSpan={5} className="table-active">
                            TOTAL
                          </td>
                          <td className="table-active">
                            Paper GSM = {totalOfPaperGSM}
                          </td>
                          <td colSpan={1} className="table-active"></td>
                          <td colSpan={1} className="table-active"></td>
                          <td colSpan={1} className="table-active"></td>
                          <td colSpan={1} className="table-active"></td>
                          <td colSpan={1} className="table-active"></td>
                          <td colSpan={1} className="table-active"></td>
                          <td colSpan={1} className="table-active"></td>
                          <td colSpan={1} className="table-active"></td>
                          <td colSpan={1} className="table-active">
                            Total MM = {total_MM}
                          </td>
                          <td colSpan={1} className="table-active">
                            Paper Qty = {paperQty}
                          </td>
                          <td colSpan={1} className="table-active">
                            Paper Weight = {paperWeight}
                          </td>
                          <td colSpan={1} className="table-active"></td>
                          {/* <td colSpan={1} className="table-active"></td> */}
                        </tr>
                      </tbody>
                    </table>
                  </form>
                </div>


                <div className="m-2" style={{ justifyContent: "end", float: "right" }}>
                  {/* <button
                    type="button"
                    className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                    style={{
                      borderColor: "rgb(0, 54, 101)",
                      background: "rgb(0, 54, 101)",
                      color: "white",
                    }}

                    onClick={handleSubmit(onSubmit)} 
                  >
                    Save
                  </button> */}

                </div>

              </div>
              {/* {show && showmessage === "success" ? <Alert color={showmessage} message={"Added Successfully"} /> : ""} */}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Viewable;
