import React from 'react'
import { Document, Page, Text, View, PDFViewer,Image } from '@react-pdf/renderer';
import logo from "../../../images/logo.png"
import Card from "react-bootstrap/Card"


const styles:any = {
    container: {
      flexDirection: "column",
      fontSize:10
    },
    headerRow: {
      flexDirection: "row",
      backgroundColor:"#DDF1FF",
      borderRadius: 15,
      padding:10,
      color:"black",
      alignItems: 'center', // Center vertically
    justifyContent: 'space-around',
    marginTop:-50,
    marginLeft:20,
    marginRight:20
    },
    headerRowDetails: {
      flexDirection: "row",
      // backgroundColor:"#DDF1FF",
      borderRadius: 15,
      padding:10,
      color:"black",
      // alignItems: 'center', // Center vertically
    justifyContent: 'space-between',
    // marginTop:-50,
    // marginLeft:20,
    // marginRight:20
    },
    row:{
      color:"black"
    },
    col: {
      // flexGrow: 1,
      width:120,
      display:"grid",
      justifyContent:"center",
     
    },
    colDetails: {
      // flexGrow: 1,
      // width:120,
      display:"grid",
      marginTop: 20,
      justifyContent:"center",
      marginRight:10
     
    },
    invoiceLine:{
      position: 'absolute',
      top: 10, 
      left: '33%',
      width: 1,
      height: 'calc(100% - 20px)',
      backgroundColor: 'gray',
  // marginLeft: -0.5, 
    },
    addressLine:{
      position: 'absolute',
      top: 10, 
      left: '66%', 
      width: 1,
      height: 'calc(100% - 20px)', 
      backgroundColor: 'gray', 
  // marginLeft: -0.5,
    },
    logoLine:{
      position: 'absolute',
      top: 20, 
      left: '15%', 
      width: 1,
      height: 'calc(20% - 6px)', 
      backgroundColor: 'white', 
  // marginLeft: -0.5,
    },
    card: {
      border: "1px solid #ccc",
      margin: 8,
      padding: 10,
    },
    blueBox: {
      // flexDirection: "row",
      backgroundColor:"rgb(0, 54, 101)",
      borderBottomLeftRadius: 15,
      borderBottomRightRadius: 15,
      padding:10,
      color:"white",
      height:150,
    },
    secondBlueBox: {
      border: "1px solid #ccc",
      margin: 8,
      padding: 8,
      flexDirection: "row",
    },
    bgLight: {
      backgroundColor: "white",
      borderRadius: 6,
      padding:20,
      verticleAlign:"center",
      width:180
      
    },
    centeredText: {
      textAlign: 'center',
    },
    // tableCell: {
    //   padding: 6,
    // },
    img: {
      height:60,
      width:70,
    },
    header:{
      fontSize:15,
      paddingBottom: 8
    },
    headerDetails:{
      fontSize:12,
      paddingBottom: 8,
      // marginTop:20,
      marginLeft:20
    },
 
    HeaderText:{
      fontSize:10,
      paddingLeft:20,
      
    },
    AddressText:{
      fontSize:10,
      // paddingLeft:20,
      // marginBottom:60,
      marginLeft:120,
      // marginTop:-200
    },
    label:{
      fontSize:10,
      color:"#6d6c6c",
      paddingBottom: 8
      
    },
    amount:{
      paddingBottom:8
    },


    table: {
      display: "table",
      width: "auto",
      // borderStyle: "solid",
      // borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      // paddingTop: 8,
      // borderRadius: 5
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
      // borderWidth: 1,
      // borderRadius: 5,
    },
    tableCol: {
      width: "11%",
      // borderStyle: "solid",
      // borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      // borderRadius: 5, 
    },
    tableCell: {
      margin: "auto",
      marginTop: 25,
      fontSize: 10,
      border: 0
    },
  
    tableRowHeader: {
      margin: "auto",
      flexDirection: "row",
      backgroundColor: "grey",
      // border: "1px solid grey",
      // borderRadius: 5,
    },
    tableColHeader: {
      width: "11%",
      // borderStyle: "solid",
      // borderWidth: 1,
     
      borderLeftWidth: 0,
      borderTopWidth: 0,
      // borderRadius: 5, 
    },
    tableCellHeader: {
      margin: 5,
      // margin: 5,

      justifyContent:'center',
      alignItems: "center",
      fontSize: 10,
    },
    TotalDetails:{
 
      display:"flex",
      textAlign:"end"
    }
  };


  const headers:any = [
    // Your header names here...
    // Example headers: Replace this with your actual headers
    'Sr.No.',
    'Description',
    'HSN Code',
    'Quantity',
    'Unit',
    'Rate',
    'Taxable Amount',
    'IGST Rate Amount',
    'Amount',
    // Add more headers as needed...
  ];



  const tableData = [
    // Your table data here...
    // Example data: Replace this with your actual table data
   
    // Example data with a product that has more than 10 fields
    {
      srno: '1',
      Description: '10 User',
      HSN_Code: '2019-02-20 - 2020-02-19',
      Quantity: '10',
      Unit: 'Field 1',
      Rate: 'Field 2',
      Taxable_Amount: 'Field 3',
      IGST_Rate_Amount: 'Field 4',
      Amount: 'Field 5',
      // field6: 'Field 6',
      // field7: 'Field 7',
      // field8: 'Field 8',
      // field9: 'Field 9',
      // field10: 'Field 10',
      // field11: 'Field 10',
      // field12: 'Field 10',
      // field13: 'Field 10',
      // field14: 'Field 10',
      // field15: 'Field 10',
      // field16: 'Field 10',
      // Add more fields for the product...
    },
    // Add more rows as needed...
  ];
  

const Check = () => {


    // Split table headers into chunks for each page
    const headersPerPage = 10; // Number of headers per page
    const headerPages = [];
  
    for (let i = 0; i < headers.length; i += headersPerPage) {
      const pageHeaders = headers.slice(i, i + headersPerPage);
      headerPages.push(pageHeaders);
    }


    const maxWidths:any = {};
    headers.forEach((header:any) => {
      const headerTextWidth = header.length * 1; // Adjust the factor for your font and size
      const maxCellWidth = tableData.reduce((max, rowData:any) => {
        const cellText = rowData[header.toLowerCase()] || '';
        const cellTextWidth = cellText.length * 1; // Adjust the factor for your font and size
        return Math.max(max, cellTextWidth);
      }, headerTextWidth);
      maxWidths[header] = maxCellWidth;
    });

  
  
  const pageSize = "A4"; // Adjust page size as needed
  const pageOrientation = "landscape"; // Adjust page orientation as needed
  return (
    <Document>
    {headerPages.map((pageHeaders, pageIndex) => (
      <Page key={pageIndex} wrap={false} size={pageSize} orientation={pageOrientation}>
        <View style={styles.container}>
          <View style={styles.card}>
            {/* Your existing content here... */}
            {/* <View> */}
              <View style={styles.blueBox}>
              <Image src={logo} style={styles.img} />
              <Text style={styles.HeaderText}>SQ08899</Text>
              </View>
            
              <View style={styles.logoLine}></View>
              {/* <View style={styles.AddressText}>
              <Text >2552 Avenue</Text>
              <Text >Boggstown, India</Text>
              <Text > India 360057</Text>
              </View> */}
            
            {/* </View> */}

          



          
          <View style={styles.headerRow}> 
            
            <View style={styles.col}>
              <Text  style={styles.label}>Invoice for</Text>
              <Text style={styles.header}>Thomas Lee</Text>
              <Text >2552 Avenue</Text>
              <Text >2552 Avenue</Text>
              <Text >2552 Avenue</Text>
              <Text >2552 Avenue</Text>
              
            </View>

            <View style={styles.invoiceLine}></View>
            
            <View style={styles.col}>
            <Text style={styles.header}>Delivery Location</Text>
              <Text >2552 Avenue</Text>
              <Text >Boggstown, India</Text>
              <Text >India 360057</Text>
            
            </View>

            <View style={styles.addressLine}></View>

            <View style={styles.col}>
              <View style={[styles.bgLight, styles.centeredText]}>
                <Text style={styles.header}>AMOUNT</Text>
                <Text style={styles.amount}>RS. 23,600</Text>
                <Text >APR 8, 2023</Text>
              </View>
            </View>
            </View>
          
            <View style={styles.headerRowDetails}>
            <View style={styles.colDetails}>
              <Text style={styles.headerDetails}>Quotation Number : SQ08899</Text>
              <Text style={styles.headerDetails}>Delivery Date: APR 8,2023</Text>
              <Text style={styles.headerDetails}>Number Of Item: 05</Text>
            
            </View>
            <View style={styles.colDetails}>
              <Text style={styles.headerDetails}>Quotation Date : APR 8,2023</Text>
              <Text style={styles.headerDetails}>Amendment: 0</Text>
              <Text style={styles.headerDetails}>Total Amount: 23,600</Text>
            
            </View>
            </View>
              <Card>
            <View style={styles.table}>
              <View style={styles.tableRowHeader}>
                {/* Table header cells */}
                {pageHeaders.map((header:any, headerIndex:any) => (
                  <View style={styles.tableColHeader} key={headerIndex}>
                    <Text style={styles.tableCellHeader}>{header}</Text>
                  </View>
                ))}
              </View>

              {/* Render table data */}
              {tableData.map((rowData:any, rowIndex) => (
                <View style={styles.tableRow} key={rowIndex}>
                  {pageHeaders.map((header:any, headerIndex:any) => (
                    <View style={styles.tableCol} key={headerIndex}>
                      <Text style={styles.tableCell}>{rowData[header.toLowerCase()]}</Text>
                    </View>
                  ))}
                </View>
              ))}
            </View>
            </Card>
            <View style={styles.TotalDetails}>
              <Text>Total (Before Tax): 22,000.00</Text>
            </View>
          </View>
        </View>
      </Page>
    ))}
  </Document>

  )
}

export default Check