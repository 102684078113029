import React, { useEffect, useRef, useState } from "react";
// import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../../images/Export/Camera.png";
import pdfLogo from "../../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "../../../components/Model/Model";
import { RiPencilLine } from "react-icons/ri";
import { useCreate, useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../../hooks/Api";
import InputField from "../../../components/Form Component/inputfield";
import Error from "../../../components/Form Component/error";
import { useForm, Controller } from "react-hook-form";
import useFocusOnEnter from "../../../components/focus";
import Select from 'react-select';
import { useFetchEmployeeDropDown, useFetchPersonTypeDropDown } from "../../../hooks/DropDown_Api";
import CreatableSelect from "react-select/creatable";
import { Upload, UploadFile } from "antd";
import Offcanvas from "react-bootstrap/esm/Offcanvas";
import { Deletesinglewithdependancy } from "../../../components/modal";
import Modal from "react-bootstrap/Modal";
import Add_Sales_Modal from "./Add_Sales_Modal";
import Stepper_Table from "../../../components/Dynamic_Table/Table/Stepper Table";
import { Columns_Sales_Document,titleofheaders } from "./Columns_Sales_Document";
import Axiosinstance from "../../../hooks/Interceptor";
interface Option {
    readonly label: string;
  //   readonly value: string;
    readonly name: string;
  }
  const createOption = (label: string) => ({
      label,
      // value: label,
      name: label,
      status: "active"
    });

const Edit_Sales_Modal = ({showOffcancasEdit,handleCloseEdit,id,setIsEdited,setShowOffcancasEdit}:any) => {
    let pageName = "Document Detail"
    // const focus:any = useRef();
    // const {onEnterKey} = useFocusOnEnter(focus);

    const apiname = process.env.REACT_APP_DOCUMENT_DETAIL_API?.toString()
    const { data } = useFetchSingle(id, apiname)


    let rawData = localStorage.getItem('Add Data');
    let addData = rawData ? JSON.parse(rawData) : null;
    const ids = addData?.document?.map((e:any)=>{

        return e.id
    })
    const tempData = addData?.document


  


const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()


 


    const navigate = useNavigate()
    const [File, setFile]: any = useState()


    
    // const { data } = useBrandEdit(id)


    // const {data} = useFetchSingle(id,apiname)

    const [documentPDF,setDocumentPDF] = useState(null)

    const onLogoChange = (e:any) => {
        setDocumentPDF(e.target.files[0].name)
    }

    const { mutate, isSuccess, isError,error,data: brandUpdated_response }: any = useUpdateSingle()

    const { register, handleSubmit, setError, formState: { errors }, reset, setValue,control }:any = useForm();

    const [uploadFile, setUploadFile]: any = useState<UploadFile[]>([])
    const [uploadFileDoc, setUploadFileDoc]: any = useState<UploadFile[]>([])

    const [logoId, setLogoId] = useState([])
    const [ProductDrawingId, setProductDrawingId] = useState([])




    useEffect(() => {
        if (data) {
            setValue("document_number",data?.document_number)
            setSelectedEmployee(data?.employee_code?.id)
            setValue("employee_code",data?.employee_code?.id)

            setValue("person_type",data?.person_type?.id)
            setSelectedPersonType(data?.person_type?.id)



         


            

            setDocumentType(data?.type)
            setValue("document_type",data?.type)




            let tempImages = data?.image?.map((img: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${index}`,
                    name: `image-${index}.jpg`,
                    status: 'done',
                    url: img?.doc,
                    thumbUrl: img?.doc,
                }

                return ctx
            })
            let tempPdf = data?.pdf?.map((pdf: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${pdf?.id}`,
                    name: `${pdf.title}`,
                    status: 'done',
                    url: pdf?.doc,
                    thumbUrl: pdf?.doc,
                }

                return ctx
            })

            setUploadFileDoc(tempPdf)

            setUploadFile(tempImages)
            // setValue("title", data.title)
            // setValue("type", data.type)
            // setValue("status", data.status)
            // setValue("doc", data.doc)
            // setFile(data.doc)
        }
    }, [data]);


    // const onSubmit = (branddetail: any) => {
    //     const formData = new FormData();
    //     if (branddetail.logo.length == 1) {
    //         delete branddetail.logo_pdf
            
    //     } else if (branddetail.logo_pdf.length == 1) {
    //         delete branddetail.logo
    //     }
    //     else {
    //         delete branddetail.logo
    //         delete branddetail.logo_pdf
    //     }

    //     for (const [key, value] of Object.entries(branddetail)) {
    //         const formvalue: any = value
    //         if (typeof value !== "object") {
    //             formData.append(key, formvalue)
    //         }
    //         else {
    //             formData.append(key, formvalue[0])
    //         }
    //     }
    //     mutate(formData);
    // }
    const [logo, setlogo]:any = useState(null)
    const [logoPdf, setlogoPdf]:any = useState(null)
 

    const onSubmit = (documentDetail: any) => {


        // if (documentDetail.doc?.length != 1) {
        //     delete documentDetail.doc
        // } 
        // else{
        //     delete documentDetail.doc
        // }

        if(logo==0){
            delete documentDetail?.images
        }
        if(logoPdf==0){
            delete documentDetail?.pdfs
        }

        const formData = new FormData();
      

        for (const [key, value] of Object.entries(documentDetail)) {
            const formvalue: any = value

            if (key === "images") {
                logoId.map((old: any) => {
                    formData.append('image', old?.id)
                })
                for (let i = 0; i < logo?.length; i++) {

                    formData.append('images', logo[i])
                }
                continue
            }
            if (key === "pdfs") {
                ProductDrawingId.map((old: any) => {
                    formData.append('pdf', old?.id)
                })
                for (let i = 0; i < logoPdf?.length; i++) {

                    formData.append('pdfs', logoPdf[i])
                }
                continue
            }

            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            // else if (key === "pdf"){
            //     if (typeof value === 'object' && value !== null && formvalue.length > 0) {
            //         formData.append(key, formvalue[0])
            //     }
            //     else {
            //         formData.append(key, "")
            //     }
            // }
        }
        let data = {
            id:id,
            apiname:apiname,
            data:formData
        }
        mutate(data);
        setValuesofform(data)
        
    }



    
    const [valuePersonType, setValuePersonType]:any = useState();

    const[optionsPersonType,setOptionsPersonType]:any = useState([])
    
    const [isLoadingValue, setIsLoadingValue] = useState(false);
    const [selectedPersonType,setSelectedPersonType]:any = useState("")
    const [valuePersonTypeCreatable, setValuePersonTypeCreatable] = useState<Option | null>();
    
    const apinameForPersonType = process.env.REACT_APP_PERSON_TYPE_API?.toString()

    const { mutate:mutatePersonType, data:dataCavity, isError:cavityIsError, isLoading:cavityLoading, isSuccess:personTypeSuccess, error:cavityError }: any = useCreate(apinameForPersonType)


    const { data: personTypeData, refetch: personTypeRefetch } = useFetchPersonTypeDropDown()

    useEffect(() => {
        setOptionsPersonType(personTypeData)
    }, [personTypeData])


   

    
    const handleChangePersonType = (e: any) => {
        setValue("person_type", e?.value)
        setValuePersonTypeCreatable(null)
        setValuePersonType(e)
    }

    const handleCreatePersonType = (inputValue: string) => {
        
        inputValue = inputValue.toLowerCase()
        .split(' ')
        .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
        const newOption = createOption(inputValue);
        setIsLoadingValue(false);
       
        
        mutatePersonType({name:newOption.name,status:newOption.status})
        setOptionsPersonType((prev:any) => [...prev, newOption]);
        setValuePersonTypeCreatable(newOption);
      }, 0);

   
    };

    useEffect(() => {
        (async () => {
            if(personTypeSuccess && optionsPersonType){
            await personTypeRefetch();
            }
            setValue("person_type",personTypeData[0].value)
        })();
        
        return () => {
        };
      }, [personTypeSuccess,personTypeData]);





const[selEmployee,setSelEmployee]:any = useState("")
const[selectedEmployee,setSelectedEmployee]:any = useState("")

      const[employeeOptions,setEmployeeOptions] = useState([])
      const[forEmployeeValue,setForEmployeeValue]:any = useState("")
      const {data:EmployeeData, refetch:EmployeeRefetch} = useFetchEmployeeDropDown()
          
          
              useEffect(()=>{
                setEmployeeOptions(EmployeeData)
              },[EmployeeData])
          
          
              const handleChangeEmployee=(e:any)=>{
                  setForEmployeeValue(e)
                  setSelEmployee(e)
                  setValue("employee_code",e.value)
              }





    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")


    const [openEditDateModel, setOpenEditDateModel] = useState(false)
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()
    const [expirationDate, setExpirationDate] = useState()


    const apinameForBasicDetails = process.env.REACT_APP_EMPLOYEE_API?.toString()
    const { mutate:BasicDetails, isSuccess:basicDetailsIsSuccess, isError:basicDetailsIsError, error:basicDetailsError, data: basicDetailsData }: any = useUpdateSingle()

    let rawDataTemp = localStorage.getItem('Add Data');
    let addDataTemp = rawDataTemp ? JSON.parse(rawDataTemp) : null;
    
    let basicIdTemp = addDataTemp ? addDataTemp.id : null;

    const [basicDetailsInitiated,setBasicDetailsInitiated] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            if (yessuccess) {
                try {
                    const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameForBasicDetails}/${basicIdTemp}`);
                    // console.log('response', response);
                    localStorage.setItem('Add Data',JSON.stringify(response?.data?.data))
                    // localStorage.setItem('Edit Data',JSON.stringify(response?.data?.data))
                    setShowOffcancasEdit(false)
                    setIsEdited(true)
                    // Do something with response.data
                } catch (error) {
                    console.error("Error fetching data:", error);
                    // Handle the error appropriately
                }
            }
        };

        fetchData();
    }, [yessuccess]);
   



    useEffect(() => {
        if ((isSuccess || yessuccess || nosuccess) && !basicDetailsInitiated) {
            setShow(true);
            setshowmessage("success");
            setShowOffcancasEdit(false)
            setBasicDetailsInitiated(true);
          
        // let tempId = {
        //                 personal_detail:data?.data?.id
        //             }
        
        //             let tempData = {
        //                 id: basicIdTemp,
        //                 apiname: apinameForBasicDetails,
        //                 data: tempId
        //             }
        //             BasicDetails(tempData);
                    // setBasicDetailsInitiated(true);
            //   navigate("/listCity", { state: { showEditToast: true } });
            // setCurrentStep(currentStep + 1)
        } else if (isError) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [data, isSuccess, yessuccess, nosuccess, brandUpdated_response, error,
        isSuccess,]);

        const[updateCompleted,setUpdateCompleted] = useState(false)

        useEffect(()=>{
            if(basicDetailsIsSuccess && basicDetailsData &&  !updateCompleted){
                
            localStorage.setItem('Add Data',JSON.stringify(basicDetailsData?.data))
            // localStorage.setItem('Edit Data',JSON.stringify(basicDetailsData?.data))
            setUpdateCompleted(true);
            }
        },[basicDetailsIsSuccess,basicDetailsData])

    useEffect(() => {
        if (isError) {
            if (error.response?.status === 405) {
                setConfirmmodal(true);
            }
        } else if (isError || yeserror || noerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [isError, yeserror, noerror]);
    useEffect(() => {
        setTimeout(() => {
            setShow(false);
        }, 3000);
    }, [show]);
    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }
    const noupdatemodal = () => {
        setOpenEditDateModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {

        delete data.created_at
        delete data.created_by
        let ok = {
            id: basicIdTemp,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditDateModel(false)
    }








    useEffect(() => {
        const isDataChanged = JSON.stringify(data) !== JSON.stringify(prevDataRef.current);
        if (data && isDataChanged) {
            reset(data);
            setMultipleIds(ids)
            setForEditIds(ids)
          
            // setForEditIds((oldIds: any) => {
            //     const newIds = [...oldIds, ids];
            //     console.log('New IDs:', newIds);
            //     return newIds;
            // });
            prevDataRef.current = data;
        }
    }, [data, reset]);



    // useEffect(()=>{
    //     if(isNew){
    //         
    //         setForEditIds(ids)
    //         setIsNew(false)
    //     }
    // },[isNew])


    useEffect(() => {
        if (isSuccess) {
            if (data) {
                setMultipleIds((oldIds: any) => {
                    const newIds = [...oldIds, data.data.id];
                    console.log('New IDs:', newIds);
                    return newIds;
                });

                setAdded(false)
            }
        }
    }, [isSuccess, data]);
 const[addShowOffcancas,setAddShowOffcancas] = useState(false)








    const[documentType,setDocumentType] = useState("")
const[regexForDocumentNumber,setRegexForDocumentNumber]:any = useState()


    const handleDocumentType =(e:any)=>{


    const type = e.target.value

   

if(type === "Aadhar"){
    setDocumentType("Aadhar Card")
    setRegexForDocumentNumber(/^\d{4}\s\d{4}\s\d{4}$/)
    
}else if(type === "Pan"){
    setDocumentType("Pan Card")
    setRegexForDocumentNumber(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/)
}else if(type === "Election"){
    setDocumentType("Election Card")
    setRegexForDocumentNumber(/^[A-Z]{3}[0-9]{7}$/)
}else if(type === "Passport"){
    setDocumentType("Passport")
    setRegexForDocumentNumber(/^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/)
}else if(type === "Driving"){
    setDocumentType("Driving License")
    setRegexForDocumentNumber(/^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/)
}else if(type === "GST"){
    setDocumentType("GST Certificate")
    setRegexForDocumentNumber(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/)
    
}

    }



    const FileOnChange = (e: any) => {
        e.file.status = "done"
        let tempOldFiles: any = []


        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }
                tempOldFiles.push(ctx)

            }
        })

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        }).filter((e: any) => e != undefined)
        setLogoId(tempFiles)
        setlogo(tempFiles)
        setUploadFile(e.fileList)

    }
    const DocOnChange = (e: any) => {
        e.file.status = "done"

        // const tempFiles = e.fileList.map((file: any) => {
        //     return file.originFileObj
        // }).filter((e: any) => e != undefined)

        let tempOldFiles: any = []
        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }

                tempOldFiles.push(ctx)
            }
        })



        const tempFiles = e.fileList.map((file: any) => {
            // console.log(file,"file");
            return file.originFileObj
        }).filter((e: any) => e != undefined)

        setProductDrawingId(tempFiles)
        setlogoPdf(tempFiles)
        setUploadFileDoc(e.fileList)

    }

    const [getIdForEdit, setGetIdForEdit]: any = useState()

    const Breadcrumb_Primary_Title = "Home";
    const Breadcrumb_Primary_Link = "";
  
    const Breadcrumb_Parent_Title = "Sales";
  
    const Breadcrumb_Title_Name = "Document Detail";
  
  
    const filter = {
  
      name:""
  
    };
  
    const addButtonName = "Document Detail";
    const addButtonPageName = "salesDocument"
    const permissionClass = {
      front: "document",
      back: "documentdetail"
    }
    const stickyStart = 1;
    const stickyEnd = 2;



    const [ForEditIds,setForEditIds]:any = useState([])

    console.log('ForEditIds',ForEditIds)


    const [multipleIds, setMultipleIds]: any = useState([])

    const [added, setAdded] = useState(false)

    const prevDataRef = useRef();

    const [localIds,setLocalIds] = useState([])

    useEffect(() => {
        const isDataChanged = JSON.stringify(data) !== JSON.stringify(prevDataRef.current);
        if (data && isDataChanged) {
            reset(data);
            setMultipleIds(ids)
            setForEditIds(ids)
          
            // setForEditIds((oldIds: any) => {
            //     const newIds = [...oldIds, ids];
            //     console.log('New IDs:', newIds);
            //     return newIds;
            // });
            prevDataRef.current = data;
        }
    }, [data, reset]);



    


    useEffect(() => {
        if (isSuccess) {
            if (data) {
                setMultipleIds((oldIds: any) => {
                    const newIds = [...oldIds, data.data.id];
                    console.log('New IDs:', newIds);
                    return newIds;
                });

                setAdded(false)
            }
        }
    }, [isSuccess, data]);


  



    return (
        <div>
            <div >
                <section className="content-header px-3 py-1">
                    {/* <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit {pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Sales</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">{pageName}</a></li>
                                    <li className="breadcrumb-item active">Edit {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div> */}
                </section>
                <section className="">
                    <div className="">
                        
                        <div className="">
                    



                         




                        <Offcanvas show={showOffcancasEdit} onHide={handleCloseEdit}  placement={'end'} style={{ width: '178vh',zIndex:'10000' }} >
                                                        <Offcanvas.Header closeButton>
                                                        </Offcanvas.Header>
                                                        <Offcanvas.Body >
                        <div className="card card-default">
                             <form 
                            
                            onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>
                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message.name} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                
                                               


                                                <div className="col-sm-3 mt-2">
                                                <label htmlFor="exampleInputEmail1">Document Name <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("document_type",
                                                     {
                                                        required: { value: true, message: "Type of File is required" },

                                                    })}
                                                    onChange={handleDocumentType}
                                                    >
                                                        <option value=""  selected>Select Any</option>
                                                        <option value="Aadhar">Aadhar Card</option>
                                                        <option value="Pan">Pan Card</option>
                                                        <option value="Election">Election Id Card</option>
                                                        <option value="Passport">Passport</option>
                                                        <option value="Driving">Driving License</option>
                                                        <option value="GST">GST Certificate</option>
                                                    </select>
                                                    {errors.document_type && <Error>{errors?.document_type?.message}</Error>}
                                                </div>



                                                
                                                <div className="col-sm-3 mt-2">


                                                <label >Document In Print Name<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control"
                                                    
                                                    placeholder={'Document In Print Name'}


                                                    {...register('in_print_name', {required:{ value: true, message: "Field is required" }})}
                                                     />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.in_print_name?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">


                                                <label >Document Number <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control"
                                                    
                                                    placeholder={documentType?documentType:"Document Number"}


                                                    {...register('document_number', {
                                                        required: 'Field is required',
                                                        pattern: {
                                                            value: regexForDocumentNumber,
                                                            message: 'Please enter a valid document',
                                                        },
                                                    })}
                                                    
                                                     />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.document_number?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">


<label >Re-Mark<span style={{ color: 'red' }}>*</span></label>
    <div className="input-group">
    <input type="text" className="form-control"
    
    placeholder={'Re-Mark'}


    {...register('remark', {required:{ value: false, message: "Field is required" }})}
    />
    <div className="input-group-append" >
    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
            /></span>

    </div>
    </div>
    <span className='text-red w-100'>{errors?.remark?.message}</span>
</div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Field is required" } })}>

                                                        <option value={""} disabled>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>

                                                </div>

                                                



                                                <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Mould Image All</label>


                                                    {uploadFile.length > 0 ?

                                                        <>
                                                            <Upload
                                                                {...register("images")}
                                                                accept="image/png, image/jpeg"
                                                                listType="picture"
                                                                onChange={FileOnChange}
                                                                multiple={true}
                                                                defaultFileList={[...uploadFile]}
                                                                onPreview={(e: any) => { return "" }}
                                                                key={"image"}

                                                            >
                                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                            </Upload>
                                                        </> :
                                                        <>
                                                            <Upload
                                                                {...register("images")}
                                                                accept="image/png, image/jpeg"
                                                                listType="picture"
                                                                onChange={FileOnChange}
                                                                multiple={true}
                                                                onPreview={(e: any) => { return "" }}

                                                            >
                                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                            </Upload>
                                                        </>
                                                    }





                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Mould PDF Drawing All</label>
                                                    <label style={{ cursor: "pointer" }}>
                                                    </label>
                                                    {uploadFileDoc.length > 0 ?
                                                        <>
                                                            <Upload
                                                                {...register("pdfs")}
                                                                accept=".doc,.docx,.xml,.pdf"
                                                                listType="text"
                                                                onChange={DocOnChange}
                                                                multiple={true}
                                                                defaultFileList={[...uploadFileDoc]}
                                                                onPreview={(e: any) => { return "" }}
                                                                key={"pdf"}
                                                            >
                                                                <img src={pdfLogo} alt='' className="uploaded-image" />

                                                            </Upload>
                                                        </> :
                                                        <>
                                                            <Upload
                                                                {...register("pdfs")}
                                                                accept=".doc,.docx,.xml,.pdf"
                                                                listType="text"
                                                                onChange={DocOnChange}
                                                                multiple={true}
                                                                onPreview={(e: any) => { return "" }}
                                                            >
                                                                <img src={pdfLogo} alt='' className="uploaded-image" />

                                                            </Upload>

                                                        </>
                                                    }


                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                            </div>
                                        </div>






                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                <a type="button" className="btn border rounded text-white px-4 mt-2 mx-3" onClick={handleSubmit(onSubmit)}>Submit</a>

                                                <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetail")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            </div>


                            </Offcanvas.Body>
                                                    </Offcanvas>

                        </div>
                    </div>
                </section>
            </div>
            {/* <Footer /> */}
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                    yesupdatemodal={yesupdatemodal}
                    cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />

                <Modal show={openEditDateModel} onHide={() => setOpenEditDateModel(false)} aria-labelledby="contained-modal-title-vcenter"
                    centered    >
                    <Modal.Header closeButton>
                        <Modal.Title>Select a Date</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-sm-12 mt-2">
                                    <label htmlFor="exampleInputEmail1">Choose Date</label>
                                    <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                                    {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditDateModel(false) }}>Close</button>
                        <button
                            type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                            onClick={handleSubmit(handleConfirm)}
                        >Confirm</button>
                    </Modal.Footer>
                </Modal>
        </div>
    )
}

export default Edit_Sales_Modal