// import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../../components/Cell/Status";
import { useFetch, useUpdateSingle } from "../../../hooks/Api";
import noImage from "../../../images/noImage.png"
import Modal from "react-bootstrap/esm/Modal";
import { Tooltip } from "@mui/material";



interface Data {
  selection: any



  transportation: any
  godown_stock: any
  balance_stock: any
  lee10_price: any
  order_qty: any
  uom: any
  discount_after_tax: any
  discount: any
  one_kg_rate: any
  after_discount_box: any
  brand: any
  nos_strip: any
  box_strip: any
  cup_weight: any
  box_weight: any
  basic_discount: any
  total_basic_discount: any
  basic_rate_per: any
  total_basic: any
  gst_rate: any
  gst_amount: any
  net_amount: any

  verify: any






  created_at: any

  created_by: any
  updated_by: any
  updated_at: any


  // status: any;
  dispatched: any;
}



export const titleofheaders = [
  {
    id: `srno`,
    Header: `Sr No`,
  },








  {
    id: 'do_number',
    Header: "Dispatch Number",

  },
  {
    id: 'name',
    Header: "Name",

  },
  {
    id: 'dispatch_date',
    Header: "Dispatch Date",

  },

  {
    id: 'verify',
    Header: "Verify",

  },




  {
    id: 'created_at',
    Header: "Created At",
  },
  {
    id: 'created_by',
    Header: "Created By",
  },
  {
    id: 'updated_at',
    Header: "Updated At",
  },
  {
    id: 'updated_by',
    Header: "Updated By",
  },

]




export const Columns_Dispatched: any = [




  {
    id: `srno`,
    Header: () => <div style={{ display: "inline" }}>Sr.<br />No</div>,
    sticky: "left",
  },

  {
    id: 'do_number',
    Header: () => <div style={{ display: "inline" }}>Dispatch<br />Number</div>,
    // Header: 'Sales Inquiry Code',
    sticky: "left",
    accessor: 'do_number',
    // Cell: ({ row }: any) => {

    //   return (
    //     (`${row.original.dispatch_order?.do_number}`)
    //   )

    // }
    Cell: ({ row }: any) => {
      let color = row.original?.extra_response?.do_number?.map((e: any) => {
        return e
      })
      return (color.join(","))
      // return (
      //   <div>
      //     {row.original?.dispatch_order?.do_number}
      //   </div>
      // )
    }
  },
  {
    id: 'name',
    Header: () => <div style={{ display: "inline" }}>Name</div>,
    // Header: 'Lead Generated by Sales Executive Code & Name',
    accessor: 'name',
    Cell: ({ row }: any) => {


      return (
        (`${row.original.extra_response?.customer?.name[0]}`)
      )
    }

  },
  {
    id: 'transportation',
    Header: () => <div style={{ display: "inline" }}>Transportation</div>,
    // Header: 'Lead Generated by Sales Executive Code & Name',
    accessor: 'transportation',
    Cell: ({ row }: any) => {


      return (
        (`${row.original.extra_response?.transportation?.transportation_name[0]}`)
      )
    }

  },
  {
    id: 'dispatched_product',
    Header: () => <div style={{ display: "inline" }}>Dispatched<br/>Product Qty</div>,
    // Header: 'Lead Generated by Sales Executive Code & Name',
    accessor: 'dispatched_product',
    Cell: ({ row }: any) => {


      return (
        (`${row.original.dispatched_product?.length}`)
      )
    }

  },
  {
    id: 'dispatch_date',
    Header: () => <div style={{ display: "inline" }}>Dispatch<br />Date</div>,
    // Header: 'Lead Source Of Enquiry',
    accessor: 'dispatch_date',
    // Cell: ({ row }: any) => {


    //   return (
    //     <div>
    //       {row.original.dispatch_order?.dispatch_date}
    //       {/* {"500"} */}
    //     </div>
    //   )
    // }
    Cell: ({ row }: any) => {
      let color = row.original?.extra_response?.dispatch_date?.map((e: any) => {
        return e
      })
      return (
        <div>
          {color?.join(",")}
        </div>
      )
      // return (
      //   <div>
      //     {row.original?.dispatch_order[0]?.dispatch_date}
      //   </div>
      // )
    }

  },







  {
    id: 'created_at',
    Header: () => <div style={{ display: "inline" }}>Created<br />At</div>,
    accessor: 'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{ display: "inline" }}>Created<br />By</div>,
    accessor: 'created_by',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_by?.username?.split(".")[0].split("T").join("  ") : "-")
    // Cell: ({ row }: any) => row.original.created_by?.username


  },
  {
    id: 'updated_at',
    Header: () => <div style={{ display: "inline" }}>Updated<br />At</div>,
    accessor: 'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{ display: "inline" }}>Updated<br />By</div>,
    accessor: 'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },



  // {
  //   id: 'Selection',
  //   Header: ({ getToggleAllRowsSelectedProps }: any) => {
  //     const [data, setData] = useState([])

  //     const columns: any = useMemo(() => Columns_Dispatched, [])
  //     const defaultColumn: any = useMemo(() => {
  //       return {
  //         Filter: Columnlevel
  //       }
  //     }
  //       , [])

  //     const tableInstance: any = useTable({
  //       columns,
  //       data: data,
  //       defaultColumn,

  //     },


  //     )
  //     const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
  //     let { selectedFlatRows } = tableInstance
  //     const { } = useMutation((deleteid: any) => {
  //       const temp_data = {
  //         id: deleteMultipleid
  //       }
  //       return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SALES_DISPATCH_ORDER_API}/deletelist/`, temp_data,
  //         {
  //           headers: {
  //             'Authorization': `Bearer ${localStorage.getItem('token')}`
  //           }
  //         });
  //     });
  //     return (
  //       <>
  //         <div>
  //           <Checkbox

  //             onClick={() => {
  //               setDeleteMultipleid(() => {
  //                 const temp = selectedFlatRows?.map((e: any) => {
  //                   return e.original.id;
  //                 });
  //                 return temp;
  //               });
  //             }}

  //             {...getToggleAllRowsSelectedProps()} />
  //         </div>
  //       </>
  //     );
  //   },

  //   Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
  //     // Add your onClick function here
  //   }} />
  // },
  {
    id: 'download_invoice',
    Header: 'Download Invoice'
  }
]




