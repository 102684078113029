
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";


interface Data {
  selection: any
  from_date: any
  to_date: any
  india_GST: any
  india_guj_GST: any
  ics_GST: any
  out_of_india_GST: any
  out_of_india_guj_GST: any
  out_of_india_ics_GST: any
  out_of_india_out_state_GST: any
  out_state_GST: any
  remarks: any

  created_at: any
  created_by: any
  updated_by: any
  updated_at: any
  status: any;
  actions: any;
}


export const titleofheaders = [
  {
    id: `srno`,
    Header: `Sr No`,
  },
  
    {
      id: 'india_GST',
      Header: 'GST',
    },
  
    {
      id: 'india_guj_GST',
      Header: 'State GST',
  
  
    },
  
    {
      id: 'out_state_GST',
      Header: 'Central GST',
    },
  
    {
      id: 'ics_GST',
      Header: 'Integrated GST',
  
  
    },
    {
      id: 'out_of_india_GST',
      Header: 'Out Of India GST',
    },
  
    {
      id: 'out_of_india_guj_GST',
      Header: 'Out Of India SGST',
  
  
    },
  
    {
      id: 'out_of_india_out_state_GST',
      Header: 'Out Of India CGST',
    },
  
    {
      id: 'out_of_india_ics_GST',
      Header: 'Out Of India IGST',
  
  
    },
  {
    id: `from_date`,
    Header: `From Date`,
  },

  {
    id: 'to_date',
    Header: 'To Date',
  },
  {
    id: 'remarks',
    Header: 'Remarks',


  },
  {
    id: 'status',
    Header: 'Status',


  },

  {
    id: 'created_at',
    Header: `Created At`,
  },


  {
    id: 'created_by',
    Header: `Created By`,


  },
  {
    id: 'updated_at',
    Header: `Updated At`,

  },
  {
    id: 'updated_by',
    Header: `Updated By`,

  },

]


export const Columns_Gst: any = [




  {
    id: `srno`,
    Header: () => <div>Sr<br />No</div>,
    sticky: "left",
  },

  {
    id: `india_GST`,
    Header: () => <div>GST<br />%</div>,
    accessor: 'india_GST',
    sticky: "left",
    Cell: ({ row }: any) =>
    
    {
      let sampleNumber = null
      if(row?.original?.india_GST[row?.original?.india_GST.length - 1] === "0"){
        sampleNumber = parseFloat(row?.original?.india_GST).toFixed(2)
      }
      else{
        sampleNumber = parseFloat(row?.original?.india_GST).toFixed(2)
      }
      
      return (sampleNumber)
      }
  },
  {
    id: `india_guj_GST`,
    Header: () => <div>State GST<br />(SGST)%</div>,
    accessor: 'india_guj_GST',
    Cell: ({ row }: any) =>

    {
      let sampleNumber = null
      if(row?.original?.india_guj_GST[row?.original?.india_guj_GST.length - 1] === "0"){
        sampleNumber = parseFloat(row?.original?.india_guj_GST).toFixed(2)
      }
      else{
        sampleNumber = parseFloat(row?.original?.india_guj_GST).toFixed(3)
      }
      
      return (sampleNumber)
      }

  
  },
  {
    id: `out_state_GST`,
    Header: () => <div>Central GST<br />(CGST)%</div>,
    accessor: 'out_state_GST',
    Cell: ({ row }: any) =>

    {
      let sampleNumber = null
      if(row?.original?.out_state_GST[row?.original?.out_state_GST.length - 1] === "0"){
        sampleNumber = parseFloat(row?.original?.out_state_GST).toFixed(2)
      }
      else{
        sampleNumber = parseFloat(row?.original?.out_state_GST).toFixed(3)
      }
      
      return (sampleNumber)
      }
  },
  {
    id: `ics_GST`,
    Header: () => <div>Integrated GST<br />(IGST)%</div>,
    accessor: 'ics_GST',

    Cell: ({ row }: any) =>

    {
      let sampleNumber = null
      if(row?.original?.ics_GST[row?.original?.ics_GST.length - 1] === "0"){
        sampleNumber = parseFloat(row?.original?.ics_GST).toFixed(2)
      }
      else{
        sampleNumber = parseFloat(row?.original?.ics_GST).toFixed(2)
      }
      
      return (sampleNumber)
      }
  },


  

  {
    id: `out_of_india_GST`,
    Header: () => <div>Out Of India<br />GST%</div>,
    accessor: 'out_of_india_GST',
    Cell: ({ row }: any) =>

    {
      let sampleNumber = null
      if(row?.original?.out_of_india_GST[row?.original?.out_of_india_GST.length - 1] === "0"){
        sampleNumber = parseFloat(row?.original?.out_of_india_GST).toFixed(2)
      }
      else{
        sampleNumber = parseFloat(row?.original?.out_of_india_GST).toFixed(2)
      }
      
      return (sampleNumber)
      }
  },
  {
    id: `out_of_india_guj_GST`,
    Header: () => <div>Out Of India State<br />GST(SGST)%</div>,
    accessor: 'out_of_india_guj_GST',
    Cell: ({ row }: any) =>

    {
      let sampleNumber = null
      if(row?.original?.out_of_india_guj_GST[row?.original?.out_of_india_guj_GST.length - 1] === "0"){
        sampleNumber = parseFloat(row?.original?.out_of_india_guj_GST).toFixed(2)
      }
      else{
        sampleNumber = parseFloat(row?.original?.out_of_india_guj_GST).toFixed(3)
      }
      
      return (sampleNumber)
      }
  },
  
  {
    id: `out_of_india_out_state_GST`,
    Header: () => <div>Out Of India Central<br/>GST(CGST)%</div>,
    accessor: 'out_of_india_out_state_GST',
    Cell: ({ row }: any) =>

    {
      let sampleNumber = null
      if(row?.original?.out_of_india_out_state_GST[row?.original?.out_of_india_out_state_GST.length - 1] === "0"){
        sampleNumber = parseFloat(row?.original?.out_of_india_out_state_GST).toFixed(2)
      }
      else{
        sampleNumber = parseFloat(row?.original?.out_of_india_out_state_GST).toFixed(3)
      }
      
      return (sampleNumber)
      }
  },
  {
    id: `out_of_india_ics_GST`,
    Header: () => <div>Out Of India Integrated<br />GST(IGST)%</div>,
    accessor: 'out_of_india_ics_GST',
    Cell: ({ row }: any) =>

    {
      let sampleNumber = null
      if(row?.original?.out_of_india_ics_GST[row?.original?.out_of_india_ics_GST.length - 1] === "0"){
        sampleNumber = parseFloat(row?.original?.out_of_india_ics_GST).toFixed(2)
      }
      else{
        sampleNumber = parseFloat(row?.original?.out_of_india_ics_GST).toFixed(2)
      }
      
      return (sampleNumber)
      }
  },
  {
    id: `from_date`,
    Header: () => <div>From<br />Date</div>,
    accessor: 'from_date',
    Cell: ({ row }: any) =>

      <div>
        {row.original.from_date?.split('T')[0]?row.original.from_date?.split('T')[0]:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
      </div>
  },

  {
    id: `to_date`,
    Header: () => <div>To<br />Date</div>,
    accessor: 'to_date',
    Cell: ({ row }: any) =>

      <div>
        {row.original.to_date?.split('T')[0]?row.original.to_date?.split('T')[0]:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
      </div>
  },
  {
    id: `remarks`,
    Header: () => <div>Remarks</div>,
    accessor: 'remarks',
    Cell: ({ row }: any) =>

      <div>
        {row.original.remarks?.split('T')[0]?row.original.remarks?.split('T')[0]:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
      </div>
  },

  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',


  },




  {
    id: 'created_at',
    Header: () => <div>Created<br />At</div>,
    accessor: 'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div>Created<br />By</div>,
    accessor: 'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username


  },
  {
    id: 'updated_at',
    Header: () => <div>Updated<br />At</div>,
    accessor: 'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div>Updated<br />By</div>,
    accessor: 'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },



  {
    id: 'Actions',
    Header: 'Actions',


  },
  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_Gst, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows } = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}gst/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



