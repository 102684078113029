import React from 'react'

interface DropDownFields {
    options? : any
    selectedOption? : any
    onChange? : any
    className?:any
}

const DropDownListComponent = ({ options, selectedOption, onChange,className }: DropDownFields) => {
  return (
    <div className={className ? className : "input-group"} >
    <select value={selectedOption} onChange={onChange} className={className ? className : "form-control"}>
        <option disabled selected value={""}>Select Any</option>
      {options.map((option: any) => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </select>
    </div>
  )
}

export default DropDownListComponent

