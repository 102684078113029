import React, { useEffect, useRef } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import "../../components/Table/table.css"
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import { Link, redirect, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";
import Select from 'react-select'
import axios from 'axios'
import { isConstructorDeclaration } from "typescript";
import { excel } from "react-export-table-to-excel/lib/lib";
import { useCreate } from "../../hooks/Api";
import { Alert } from "../../components/Model/Model";
import {
    useFetchCartonDropDown, useFetchColorDropDown, useFetchBrandDropDown, useFetchPanniProductPakingTypeDropDown,
    useFetchUomDropDown, useFetchPanniProductPrintingTypeDropDown, useFetchMaterialDensityDropDown,
    useFetchMaterialTypeDropDown, useFetchMouldShapeDropDown, useFetchPanniDropDown, useFetchPiceCodeDropDown,
    useFetchProductMLDropDown, useFetchStickDropDown, useFetchPacketPakingDropDown, useFetchCategoryDropDown, useFetchPanniProductByDropDown, useFetchMaterialUseDropDown, useFetchProductLocationDropDown, useFetchBoxPattiDropDown, useFetchTapeRollDropDown, useFetchPackingCoverDoriDropDown
} from "../../hooks/DropDown_Api";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../components/focus";
import CreatableSelect from "react-select/creatable";
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'
import Axiosinstance from "../../hooks/Interceptor";
import { Upload } from "antd";
import Button from "react-bootstrap/esm/Button";
import { AiOutlinePlus } from "react-icons/ai";
import { Brand_Model } from "../../components/Dynamic_Form_Models/Brand_Model";

import Modal from "react-bootstrap/Modal";
import Actions, { Deleteforce, Deletesingle } from "../../components/Cell/Actions";
import { useDelete, useDeletemforce } from "../../hooks/delete";
import Custom_Actions_Buttons from "../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../hooks/deletemultiple";
import { SimpleInput_Edit } from "../../components/Dynamic_Form_Models/SimpleInput_Edit";
import { UOM_Model } from "../../components/Dynamic_Form_Models/Uom_Model";

const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}

interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active",
});


const Add_DisposableProduct_Copy = () => {
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);

    const navigate = useNavigate()

    const apiname = process.env.REACT_APP_DISPOSABLE_PRODUCT_API?.toString()
    const getRelatedHsn = process.env.REACT_APP_GET_RELATED_HSN_API?.toString()

    const { mutate, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue, getValues }: any = useForm({
        mode: "onChange"
    });


    const [panniPrinting, setpanniPrinting] = useState("no")
    const [panniGadget, setpanniGadget] = useState("no")
    const [panniKan, setpanniKan] = useState("no")
    const [logo, setlogo]: any = useState(null)
    const [logoPdf, setlogoPdf]: any = useState(null)

    const [options, setOptions] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [searchTermpiceCode, setSearchTermpiceCode] = useState('')
    const [searchTermMould, setSearchTermMould] = useState('')
    const [ColorTotal, setColorTotal] = useState([])
    const [color, setColor] = useState([])
    const [brand, setBrand] = useState([])
    const [brandtype, setBrandType] = useState("")

    const [optionspanni, setOptionsPanni] = useState([])
    const [optionsstick, setOptionsStick] = useState([])
    const [optionscarton, setOptionsCarton] = useState([])
    const [optionsPiceCode, setOptionsPiceCode] = useState([])

    const [PanniValue, setPanniValue]: any = useState('')
    const [colorNameStick, setColorNameStick]: any = useState({})
    const [piceCode, setPiceCode]: any = useState({})
    const [Mould, setMould]: any = useState({})
    const [optionsProductType, setOptionsProductType] = useState([]);

    const [optionsMould, setOptionsMould] = useState([])
    const [searchHSN, setSearchHSN] = useState('')
    const [searchTermPanni, setSearchTermPanni] = useState('')
    const [searchTermStick, setSearchTermStick] = useState('')

    const [optionsPanniName, setOptionsPanniName] = useState([])
    const [panniName, setPanniName]: any = useState("")
    const [productType, setProductType] = useState("");


    // console.log(piceCode, "piceCode");


    const onLogoChange = (e: any) => {
        setlogo(e.target.files[0].name)
    }

    const onLogoPDFChange = (e: any) => {
        setlogoPdf(e.target.files[0].name)
    }

    const { data: colorData, refetch: colorRefetch } = useFetchColorDropDown(searchTerm)

    useEffect(() => {
        setOptions(colorData)
    }, [colorData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            colorRefetch()
        }
    }, [])





    const handleInputChange = (e: any) => {
        setSearchTerm(e)
    }
    const handleChange = (e: any) => {

        // const check = e?.map((value: any) => {
        //     return value.value
        // })
        setValue("product_color", e.value)

    }
    // const handleChangeColor = (e: any) => {

    //     const printing_color_name = e.map((value: any) => {
    //         return value.value
    //     })
    //     setValue("printing_color_name", printing_color_name)
    //     setValue("printing_total_color", e.length)
    // }



    const [prductCategoryValue, setPrductCategoryValue]: any = useState('')
    const [optionsCategory, setOptionsCategory]: any = useState([]);

    const { data: CategoryData, refetch: CategoryRefetch } = useFetchCategoryDropDown()

    useEffect(() => {
        setOptionsCategory(CategoryData)
    }, [CategoryData])

    const handleChangeCategory = (e: any) => {

        setPrductCategoryValue(e)
        setValue("product_categories", `${e?.value}`)
        setProductTypeValue(null)

    }



    const { data: panniData, refetch: panniRefetch } = useFetchPanniDropDown(searchTerm)

    useEffect(() => {
        setOptionsPanni(panniData)
    }, [panniData])


    useEffect(() => {
        if (searchTermPanni.length >= 1) {
            panniRefetch()
        }
    }, [])



    const handleInputChangePanni = (e: any) => {
        setSearchTermPanni(e)
    }

    const handleChangePanni = (e: any) => {

        setPanniValue(e)
        // let color = e?.color?.map((obj: any) => {
        //     return {
        //         value: obj.id,
        //         label: obj.name
        //     }
        // })
        // setColor(color)
        // setColorTotal(e?.printing_total_color)
        // setBrand(e?.brand?.name)
        // setBrandType(e?.brand?.type)

        setValue("panni_code_and_grade_name", e?.value)
        // setValue("panni_printing_color_name", color)
        // setValue("product_brand", e?.brand?.id)
        // setValue("panni_printing_total_color", e?.printing_total_color)
        // setValue("our_product_brand", e?.brand?.type)


    }



    // const { data: stickData, refetch: stickRefetch } = useFetchStickDropDown(searchTerm)

    // useEffect(() => {
    //     setOptionsStick(stickData)
    // }, [stickData])


    // useEffect(() => {
    //     if (searchTermStick.length >= 1) {
    //         stickRefetch()
    //     }
    // }, [])



    // const handleInputChangeStick = (e: any) => {
    //     setSearchTermStick(e)
    // }

    // const handleChangeStick = (e: any) => {
    //     setColorNameStick(e)
    //     setValue("stick_packing_type", `${e.value}`)


    // }
    const { data: picecodeData, refetch: picecodeRefetch } = useFetchPiceCodeDropDown(searchTerm)
    // console.log(picecodeData);

    useEffect(() => {
        setOptionsPiceCode(picecodeData)
    }, [picecodeData])

    useEffect(() => {
        if (searchTermpiceCode.length >= 1) {
            picecodeRefetch()
        }
    }, [])



    const handleInputChangePiceCode = (e: any) => {
        setSearchTermpiceCode(e)
    }

    const handleChangePiceCode = (e: any) => {
        // Disposable
        setValue("product_price_list_one_piece_weight", e.price_list_1_pcs_weight)
        setValue("product_current_height_mm", e.product_current_height_mm)
        setValue("product_ml_price_list", e.product_ml_price_list)
        setValue("product_actual_ml", e.product_actual_ml)
        setValue("product_final_height_mm", e.product_final_height_mm)
        // setValue("product_lip",e.product_lip)
        setValue("product_1_pcs_weight", e.product_1_pcs_weight)
        setValue("price_list_mould_diameter_mm", e.price_list_mould_diameter_mm)
        setValue("production_mould_diameter_mm", e.product_mould_diameter_mm)
        setValue("production_mould_length_mm", e.product_length_diameter)
        setValue("production_mould_width_mm", e.product_width_diameter)
        setValue("mould_shape", e?.mould_shape)
        setValue("product_lip", e?.current_lip)


        // LLDP LD Liner
        setValue('product_grade_name', e?.product_grade_name)
        setValue('material_type', e?.material_type)
        setValue('company_color_name', e?.company_color_name)
        setValue('product_width_mm', e?.product_width_mm)
        setValue('product_length_mm', e?.product_length_mm)
        setValue('thickness_micron', e?.thickness_micron)
        setValue('thickness_gauge', e?.thickness_gauge)
        setValue('gadget_yes_no', e?.gadget_yes_no)
        setValue('gadget_size_mm', e?.gadget_yes_no === 'no' ? '-' : e?.gadget_size_mm)
        setValue('product_kan_yes_no', e?.product_kan_yes_no)
        setValue('product_kan_width_mm', e?.product_kan_yes_no === 'no' ? "-" : e?.product_kan_width_mm)
        setValue('product_kan_length_mm', e?.product_kan_yes_no === 'no' ? "-" : e?.product_kan_length_mm)
        setValue('product_sealing_type', e?.product_sealing_type)
        setValue('product_ml', e?.product_ml)
        setValue('product_ml_actual', e?.product_ml_actual)
        setValue('product_bursting_strength', e?.product_bursting_strength)
        setValue('product_our_other', e?.product_our_other)
        setValue('product_special_running_type', e?.product_special_running_type)


        setPiceCode(e)
        setValue("product_single_piece_code", e.value)
    }

    const { data: mouldData, refetch: mouldRefetch } = useFetchMouldShapeDropDown(searchTerm)

    useEffect(() => {
        setOptionsMould(mouldData)
    }, [mouldData])


    useEffect(() => {
        if (searchTermMould.length >= 1) {
            mouldRefetch()
        }
    }, [])



    const handleInputChangeMould = (e: any) => {
        setSearchTermMould(e)
    }

    const handleChangeMould = (e: any) => {
        setMould(e)
        setValue("mouldshape", e.value)
    }

    const { data: cartonData, refetch: cartonRefetch } = useFetchCartonDropDown(searchTerm)

    useEffect(() => {
        setOptionsCarton(cartonData)
    }, [cartonData])


    useEffect(() => {
        if (searchTermStick.length >= 1) {
            cartonRefetch()
        }
    }, [])



    const handleInputChangeCarton = (e: any) => {
        setSearchTermStick(e)
    }

    const handleChangeCarton = (e: any) => {
        setColorNameStick(e)
        setValue("carton_code_and_grade_and_name", e.value)


    }


    const { data: materialdensityData, refetch: materialdensityRefetch } = useFetchMaterialDensityDropDown(searchTerm)

    useEffect(() => {
        setOptionsPanniName(materialdensityData)
    }, [materialdensityData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            materialdensityRefetch()
        }
    }, [])


    const handleInputChangePanniName = (e: any) => {
        setSearchHSN(e)
    }


    const handleChangePanniName = async (e: any) => {
        setPanniName(e)
        setValue("product_name_and_grade", `${e.value}`)
    }
    const { data: productTypeData, refetch: productTypeRefetch } = useFetchMaterialTypeDropDown(searchTerm);

    useEffect(() => {
        setOptionsProductType(productTypeData);
    }, [productTypeData]);

    // useEffect(() => {
    //     if (searchTerm.length >= 1) {
    //         productTypeRefetch();
    //     }
    // }, []);

    // const apinameProductType = `${process.env.REACT_APP_PRODUCT_TYPE_API}/search_filter/?page_size=1000`.toString()
    // useEffect(() => {

    //     if (prductCategoryValue?.label) {
    //         const getCall = async () => {
    //             try {
    //                 const response = await Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apinameProductType}`, {
    //                     product_categories: prductCategoryValue?.label
    //                 });
    //                 let data = response?.data?.results?.map((e: any) => {
    //                     return (
    //                         {
    //                             label: e.type,
    //                             value: e.id
    //                         }
    //                     )

    //                 })
    //                 setOptionsProductType(data)
    //             } catch (error) {
    //                 console.error('Error:', error);
    //             }
    //         }
    //         getCall()
    //     }

    // }, [prductCategoryValue])

    console.log(productType, "productType")

    const handleChangeProductType = (e: any) => {


        setProductTypeValue(e);
        setProductType(e?.label);
        setValue("product_type", e.value);
        // setValue("panni", "");
    };
    const [prductTypeValue, setProductTypeValue]: any = useState(null);
    const handleReset = () => {
        reset()
        setProductTypeValue(null)
        setProductType("")
        setPiceCode(null)
        setlogo("")
        setlogoPdf("")
    }

    const [optionsbrand, setOptionsBrand]: any = useState([])
    const [searchTermBrand, setSearchTermBrand] = useState('')
    const [loadingBrand, setLoadingBrand] = useState(false)
    const [brandOpen, setBrandOpen] = useState(false)
    const [forBrandValue, setForBrandValue]: any = useState()
    const [submittedBrand, setSubmittedBrand] = useState(false)

    const { data: brandData, refetch: brandRefetch } = useFetchBrandDropDown(searchTerm)

    useEffect(() => {
        setOptionsBrand(brandData)
    }, [brandData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            brandRefetch()
        }

    }, [])





    const handleChangeBrand = (e: any) => {
        // setPanniValue(e)
        setValue("product_brand", `${e.value}`)
        setValue("our_product_brand", `${e.type}`)
        setForBrandValue({ label: e?.label, value: e?.value, type: e?.type })
    }

    const [optionsBoxpatti, setOptionsboxpatti]: any = useState([])
    const [forBoxPattiValue, setForBoxPattiValue]: any = useState()


    const { data: boxpattiData, refetch: boxpattiRefetch } = useFetchBoxPattiDropDown(searchTerm)

    useEffect(() => {
        setOptionsboxpatti(boxpattiData)
    }, [boxpattiData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            boxpattiRefetch()
        }

    }, [])





    const handleChangeBoxPatti = (e: any) => {
        setForBoxPattiValue(e)
        setValue("box_patti", `${e.value}`)
        // setValue("boxpatti_type", `${e.type}`)
        setForBoxPattiValue({ label: e?.label, value: e?.value, type: e?.type })
    }

    const [optionsTapeRoll, setOptionsTapeRoll]: any = useState([])
    const [forTapeRollValue, setForTapeRollValue]: any = useState()


    const { data: TapeRollData, refetch: TapeRollRefetch } = useFetchTapeRollDropDown(searchTerm)

    useEffect(() => {
        setOptionsTapeRoll(TapeRollData)
    }, [TapeRollData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            TapeRollRefetch()
        }

    }, [])





    const handleChangeTapeRoll = (e: any) => {
        setForTapeRollValue(e)
        setValue("tape_roll_code_and_grade_name", `${e.value}`)
        // setValue("TapeRoll_type", `${e.type}`)
        setForTapeRollValue({ label: e?.label, value: e?.value, type: e?.type })
    }



    const apinameForProductPrintingType = process.env.REACT_APP_PANNI_PRINTING_TYPE_API?.toString()
    const { mutate: mutateProductPrintingType, data: dataProductPrintingType, isError: ProductPrintingTypeIsError, isLoading: ProductPrintingTypeLoading, isSuccess: ProductPrintingTypeSuccess, error: ProductPrintingTypeError }: any = useCreate(apinameForProductPrintingType)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueProductPrintingType, setValueProductPrintingType]: any = useState();
    const [optionsProductPrintingType, setOptionsProductPrintingType]: any = useState([]);
    const [valueProductPrintingTypeCreatable, setValueProductPrintingTypeCreatable] = useState<Option | null>();

    const { data: ProductPrintingTypeData, refetch: ProductPrintingTypeRefetch } = useFetchPanniProductPrintingTypeDropDown()

    useEffect(() => {
        setOptionsProductPrintingType(ProductPrintingTypeData)
    }, [ProductPrintingTypeData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            ProductPrintingTypeRefetch()
        }
    }, [])


    const handleChangeProductPrintingType = (e: any) => {

        ProductPrintingTypeRefetch()

        setApiName(apinameForProductPrintingType)
        setDeleteApiName(apinameForProductPrintingType)
        setEditId(e?.value)
        setEditData(ProductPrintingTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueProductPrintingType(e)

        setValue("panni_printing_type", `${e?.value}`)
        setValueProductPrintingTypeCreatable(null)
    }





    const handleCreateProductPrintingType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateProductPrintingType({ name: newOption.name, status: newOption.status })
            setOptionsProductPrintingType((prev: any) => [...prev, newOption]);
            setValueProductPrintingTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ProductPrintingTypeSuccess && optionsProductPrintingType) {
                await ProductPrintingTypeRefetch();
                setValue("panni_printing_type", ProductPrintingTypeData[0]?.value)
            }
        })();

        return () => {
        };
    }, [ProductPrintingTypeSuccess, ProductPrintingTypeData]);

    const apinameForStickPackingType = process.env.REACT_APP_STICK_PACKING_API?.toString()
    const { mutate: mutateStickPackingType, data: dataStickPackingType, isError: StickPackingTypeIsError, isLoading: StickPackingTypeLoading, isSuccess: StickPackingTypeSuccess, error: StickPackingTypeError }: any = useCreate(apinameForStickPackingType)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueStickPackingType, setValueStickPackingType]: any = useState();
    const [optionsStickPackingType, setOptionsStickPackingType]: any = useState([]);
    const [valueStickPackingTypeCreatable, setValueStickPackingTypeCreatable] = useState<Option | null>();

    const { data: StickPackingTypeData, refetch: StickPackingTypeRefetch } = useFetchStickDropDown()

    useEffect(() => {
        setOptionsStickPackingType(StickPackingTypeData)
    }, [StickPackingTypeData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            StickPackingTypeRefetch()
        }
    }, [])


    const handleChangeStickPackingType = (e: any) => {

        StickPackingTypeRefetch()

        setApiName(apinameForStickPackingType)
        setDeleteApiName(apinameForStickPackingType)
        setEditId(e?.value)
        setEditData(StickPackingTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueStickPackingType(e)

        setValue("stick_packing_type", `${e?.value}`)
        setValueStickPackingTypeCreatable(null)
    }





    const handleCreateStickPackingType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateStickPackingType({ name: newOption.name, status: newOption.status })
            setOptionsStickPackingType((prev: any) => [...prev, newOption]);
            setValueStickPackingTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (StickPackingTypeSuccess && optionsStickPackingType) {
                await StickPackingTypeRefetch();
                setValue("stick_packing_type", StickPackingTypeData[0]?.value)
            }
        })();

        return () => {
        };
    }, [StickPackingTypeSuccess, StickPackingTypeData]);





    const { data: UomData, refetch: UomRefetch } = useFetchUomDropDown()
    const [optionsUom, setOptionsUom]: any = useState()
    const [optionsProductUom, setOptionsProductUom]: any = useState()
    const [forUOMValue, setForUOMValue]: any = useState([])
    const [ForProductUOMValue, setForProductUOMValue]: any = useState([])
    const [openUOM, setOpenUOM] = useState(false)
    const [openProductUOM, setOpenProductUOM] = useState(false)

    useEffect(() => {
        setOptionsUom(UomData)
        setOptionsProductUom(UomData)
    }, [UomData])

    const handleChangeUom = (e: any) => {
        setValue("uom_1", `${e.value}`)
        setForUOMValue(e)
        setForUOMValue({ label: e?.label, value: e?.value })

    }

    const handleChangeProductUom = (e: any) => {
        setValue("uom_2", `${e.value}`)
        setForProductUOMValue(e)
        setForProductUOMValue({ label: e?.label, value: e?.value })

    }


    const [submittedUOM, setSubmittedUOM] = useState(false)


    useEffect(() => {
        if (submittedUOM) {
            setForUOMValue(optionsUom[0])

            setValue("uom_1", optionsUom[0]?.value)

        }

    }, [submittedUOM, optionsUom])
    const [submittedProductUOM, setSubmittedProductUOM] = useState(false)


    useEffect(() => {
        if (submittedProductUOM) {
            setForProductUOMValue(optionsProductUom[0])

            setValue("uom_2", optionsProductUom[0]?.value)

        }

    }, [submittedProductUOM, optionsProductUom])

    const apinameForProductBy = process.env.REACT_APP_PANNI_PRODUCT_BY_API?.toString()
    const { mutate: mutateProductBy, data: dataProductBy, isError: ProductByIsError, isLoading: ProductByLoading, isSuccess: ProductBySuccess, error: ProductByError }: any = useCreate(apinameForProductBy)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueProductBy, setValueProductBy]: any = useState();
    const [optionsProductBy, setOptionsProductBy]: any = useState([]);
    const [valueProductByCreatable, setValueProductByCreatable] = useState<Option | null>();

    const { data: ProductByData, refetch: ProductByRefetch } = useFetchPanniProductByDropDown()

    useEffect(() => {
        setOptionsProductBy(ProductByData)
    }, [ProductByData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            ProductByRefetch()
        }
    }, [])


    const handleChangeProductBy = (e: any) => {


        ProductByRefetch()

        setApiName(apinameForProductBy)
        setDeleteApiName(apinameForProductBy)
        setEditId(e?.value)
        setEditData(ProductByData)
        setRenderKey(prevKey => prevKey + 1);

        setValueProductBy(e)

        setValue("product_by", `${e?.value}`)
        setValueProductByCreatable(null)
    }





    const handleCreateProductBy = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateProductBy({ name: newOption.name, status: newOption.status })
            setOptionsProductBy((prev: any) => [...prev, newOption]);
            setValueProductByCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ProductBySuccess && optionsProductBy) {
                await ProductByRefetch();
                setValue("product_by", ProductByData[0]?.value)
            }
        })();

        return () => {
        };
    }, [ProductBySuccess, ProductByData]);


    const apinameForMaterialUse = process.env.REACT_APP_product_special_running_API?.toString()
    const { mutate: mutateMaterialUse, data: dataMaterialUse, isError: MaterialUseIsError, isLoading: MaterialUseLoading, isSuccess: MaterialUseSuccess, error: MaterialUseError }: any = useCreate(apinameForMaterialUse)

    const [valueMaterialUse, setValueMaterialUse]: any = useState();
    const [optionsMaterialUse, setOptionsMaterialUse]: any = useState([]);
    const [valueMaterialUseCreatable, setValueMaterialUseCreatable] = useState<Option | null>();

    const { data: MaterialUseData, refetch: MaterialUseRefetch } = useFetchMaterialUseDropDown()

    useEffect(() => {
        setOptionsMaterialUse(MaterialUseData)
    }, [MaterialUseData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            MaterialUseRefetch()
        }
    }, [])


    const handleChangeMaterialUse = (e: any) => {

        MaterialUseRefetch()

        setApiName(apinameForMaterialUse)
        setDeleteApiName(apinameForMaterialUse)
        setEditId(e?.value)
        setEditData(MaterialUseData)
        setRenderKey(prevKey => prevKey + 1);

        setValueMaterialUse(e)

        setValue("product_special_running", `${e?.value}`)
        setValueMaterialUseCreatable(null)
    }





    const handleCreateMaterialUse = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateMaterialUse({ name: newOption.name, status: newOption.status })
            setOptionsMaterialUse((prev: any) => [...prev, newOption]);
            setValueMaterialUseCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (MaterialUseSuccess && optionsMaterialUse) {
                await MaterialUseRefetch();
                setValue("product_special_running", MaterialUseData[0].value)
            }
        })();

        return () => {
        };
    }, [MaterialUseSuccess, MaterialUseData]);


    const apinameForProductLocation = process.env.REACT_APP_PRODUCT_LOCATION_API?.toString()
    const { mutate: mutateProductLocation, data: dataProductLocation, isError: ProductLocationIsError, isLoading: ProductLocationLoading, isSuccess: ProductLocationSuccess, error: ProductLocationError }: any = useCreate(apinameForProductLocation)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueProductLocation, setValueProductLocation]: any = useState();
    const [optionsProductLocation, setOptionsProductLocation]: any = useState([]);
    const [valueProductLocationCreatable, setValueProductLocationCreatable] = useState<Option | null>();

    const { data: ProductLocationData, refetch: ProductLocationRefetch } = useFetchProductLocationDropDown()

    useEffect(() => {
        setOptionsProductLocation(ProductLocationData)
    }, [ProductLocationData])





    const handleChangeProductLocation = (e: any) => {

        ProductLocationRefetch()

        setApiName(apinameForProductLocation)
        setDeleteApiName(apinameForProductLocation)
        setEditId(e?.value)
        setEditData(ProductLocationData)
        setValueProductLocation(e)
        setRenderKey(prevKey => prevKey + 1);

        setValue("product_location", `${e?.value}`)
        setValueProductLocationCreatable(null)
    }





    const handleCreateProductLocation = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutateProductLocation({ name: newOption.name, status: newOption.status })
            setOptionsProductLocation((prev: any) => [...prev, newOption]);
            setValueProductLocationCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ProductLocationSuccess && optionsProductLocation) {
                await ProductLocationRefetch();
                setValue("product_location", ProductLocationData[0]?.value)
            }

        })();

        return () => {
        };
    }, [ProductLocationSuccess, ProductLocationData]);

    const apinameForProductPackingType = process.env.REACT_APP_PANNI_PACKET_PAKING_TYPE_API?.toString()
    const { mutate: mutateProductPackingType, data: dataProductPackingType, isError: ProductPackingTypeIsError, isLoading: ProductPackingTypeLoading, isSuccess: ProductPackingTypeSuccess, error: ProductPackingTypeError }: any = useCreate(apinameForProductPackingType)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueProductPackingType, setValueProductPackingType]: any = useState();
    const [optionsProductPackingType, setOptionsProductPackingType]: any = useState([]);
    const [valueProductPackingTypeCreatable, setValueProductPackingTypeCreatable] = useState<Option | null>();

    const { data: ProductPackingTypeData, refetch: ProductPackingTypeRefetch } = useFetchPacketPakingDropDown()

    useEffect(() => {
        setOptionsProductPackingType(ProductPackingTypeData)
    }, [ProductPackingTypeData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            ProductPackingTypeRefetch()
        }
    }, [])


    const handleChangeProductPackingType = (e: any) => {

        ProductPackingTypeRefetch()

        setApiName(apinameForProductPackingType)
        setDeleteApiName(apinameForProductPackingType)
        setEditId(e?.value)
        setEditData(ProductPackingTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueProductPackingType(e)
        setValueProductPackingTypeCreatable(e)
        setValue("packet_packing_type", `${e?.value}`)
        setValueProductPackingTypeCreatable(null)
    }





    const handleCreateProductPackingType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateProductPackingType({ name: newOption.name, status: newOption.status })
            setOptionsProductPackingType((prev: any) => [...prev, newOption]);
            setValueProductPackingTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ProductPackingTypeSuccess && optionsProductPackingType) {
                await ProductPackingTypeRefetch();
                setValue("packet_packing_type", ProductPackingTypeData[0]?.value)
            }
        })();

        return () => {
        };
    }, [ProductPackingTypeSuccess, ProductPackingTypeData]);
    const apinameForPackingCoverDori = process.env.REACT_APP_DISPOSABLE_PRODUCT_PACKING_COVER_DORI_API?.toString()
    const { mutate: mutatePackingCoverDori, data: dataPackingCoverDori, isError: PackingCoverDoriIsError, isLoading: PackingCoverDoriLoading, isSuccess: PackingCoverDoriSuccess, error: PackingCoverDoriError }: any = useCreate(apinameForPackingCoverDori)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valuePackingCoverDori, setValuePackingCoverDori]: any = useState();
    const [optionsPackingCoverDori, setOptionsPackingCoverDori]: any = useState([]);
    const [valuePackingCoverDoriCreatable, setValuePackingCoverDoriCreatable] = useState<Option | null>();

    const { data: PackingCoverDoriData, refetch: PackingCoverDoriRefetch } = useFetchPackingCoverDoriDropDown()

    useEffect(() => {
        setOptionsPackingCoverDori(PackingCoverDoriData)
    }, [PackingCoverDoriData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            PackingCoverDoriRefetch()
        }
    }, [])


    const handleChangePackingCoverDori = (e: any) => {

        PackingCoverDoriRefetch()

        setApiName(apinameForPackingCoverDori)
        setDeleteApiName(apinameForPackingCoverDori)
        setEditId(e?.value)
        setEditData(PackingCoverDoriData)
        setRenderKey(prevKey => prevKey + 1);

        setValuePackingCoverDori(e)
        setValuePackingCoverDoriCreatable(e)
        setValue("packing_cover_dori", `${e?.value}`)
        setValuePackingCoverDoriCreatable(null)
    }





    const handleCreatePackingCoverDori = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutatePackingCoverDori({ name: newOption.name, status: newOption.status })
            setOptionsPackingCoverDori((prev: any) => [...prev, newOption]);
            setValuePackingCoverDoriCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (PackingCoverDoriSuccess && optionsPackingCoverDori) {
                await PackingCoverDoriRefetch();
                setValue("packing_cover_dori", PackingCoverDoriData[0]?.value)
            }
        })();

        return () => {
        };
    }, [PackingCoverDoriSuccess, PackingCoverDoriData]);

    const apinameForProductPakingType = process.env.REACT_APP_PANNI_PACKING_TYPE_API?.toString()
    const { mutate: mutateProductPakingType, data: dataProductPakingType, isError: ProductPakingTypeIsError, isLoading: ProductPakingTypeLoading, isSuccess: ProductPakingTypeSuccess, error: ProductPakingTypeError }: any = useCreate(apinameForProductPakingType)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueProductPakingType, setValueProductPakingType]: any = useState();
    const [optionsProductPakingType, setOptionsProductPakingType]: any = useState([]);
    const [valueProductPakingTypeCreatable, setValueProductPakingTypeCreatable] = useState<Option | null>();

    const { data: ProductPakingTypeData, refetch: ProductPakingTypeRefetch } = useFetchPanniProductPakingTypeDropDown()

    useEffect(() => {
        setOptionsProductPakingType(ProductPakingTypeData)
    }, [ProductPakingTypeData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            ProductPakingTypeRefetch()
        }
    }, [])


    const handleChangeProductPakingType = (e: any) => {

        ProductPakingTypeRefetch()

        setApiName(apinameForProductPakingType)
        setDeleteApiName(apinameForProductPakingType)
        setEditId(e?.value)
        setEditData(ProductPakingTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueProductPakingType(e)

        setValue("packing_type", `${e?.value}`)
        setValueProductPakingTypeCreatable(null)
    }





    const handleCreateProductPakingType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateProductPakingType({ name: newOption.name, status: newOption.status })
            setOptionsProductPakingType((prev: any) => [...prev, newOption]);
            setValueProductPakingTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ProductPakingTypeSuccess && optionsProductPakingType) {
                await ProductPakingTypeRefetch();
                setValue("packing_type", ProductPakingTypeData[0]?.value)
            }
        })();

        return () => {
        };
    }, [ProductPakingTypeSuccess, ProductPakingTypeData]);



    const [packet_packing_type, setPacket_packing_type]: any = useState()
    const [ProductQty, setProductQty]: any = useState()

    // console.log(packet_packing_type,"packet_packing_type");
    useEffect(() => {
        if (valueProductPackingTypeCreatable) {
            setPacket_packing_type(valueProductPackingTypeCreatable?.label)
        } else {
            setPacket_packing_type(valueProductPackingType?.label)
        }
    }, [valueProductPackingTypeCreatable, valueProductPackingType])

    const [ProductPackingtotal, setProductPackingtotal]: any = useState()

    useEffect(() => {
        setProductPackingtotal(Number(packet_packing_type) * Number(ProductQty))
        setValue("product_total_packing_kg", ProductPackingtotal)

    }, [ProductPackingtotal, packet_packing_type, ProductQty])

    const onProductQtyChange = (e: any) => {
        setProductQty(e.target.value)
    }

    const onSubmit = (pannidetail: any) => {
        if (!logo) {
            delete pannidetail.disposable_product_image
        }
        if (!logoPdf) {
            delete pannidetail.disposable_product_drawing
        }
        if (piceCode) {
            delete pannidetail.product_price_list_one_piece_weight
            delete pannidetail.product_1_pcs_weight
            delete pannidetail.mould_shape
            delete pannidetail.price_list_mould_diameter_mm
            delete pannidetail.production_mould_diameter_mm
            delete pannidetail.production_mould_width_mm
            delete pannidetail.production_mould_length_mm
            delete pannidetail.product_final_height_mm
            delete pannidetail.product_current_height_mm
            delete pannidetail.product_lip
            delete pannidetail.product_ml_price_list
            delete pannidetail.product_actual_ml
            delete pannidetail.mould

            delete pannidetail.product_grade_name
            delete pannidetail.material_type
            delete pannidetail.company_color_name
            delete pannidetail.product_width_mm
            delete pannidetail.product_length_mm
            delete pannidetail.thickness_micron
            delete pannidetail.thickness_gauge
            delete pannidetail.gadget_yes_no
            delete pannidetail.gadget_size_mm
            delete pannidetail.product_kan_yes_no
            delete pannidetail.product_kan_width_mm
            delete pannidetail.product_kan_length_mm
            delete pannidetail.product_sealing_type
            delete pannidetail.product_ml
            delete pannidetail.product_ml_actual
            delete pannidetail.product_bursting_strength
            delete pannidetail.product_our_other
            delete pannidetail.product_special_running_type
        }
        if (panniPrinting === "no" || panniPrinting === "") {
            delete pannidetail.panni_printing_type
            delete pannidetail.panni_printing_total_color
            delete pannidetail.panni_printing_color_name
        }
        if (productType !== "Disposable") {
            if (panniPrinting === "no" || panniPrinting === "") {
                delete pannidetail.panni_printing_type
                delete pannidetail.panni_printing_total_color
                delete pannidetail.panni_printing_color_name
                delete pannidetail.product_brand
                delete pannidetail.our_product_brand
            }
        }
        const formData = new FormData();

        for (const [key, value] of Object.entries(pannidetail)) {
            const formvalue: any = value

            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            // else if (formvalue.hasOwnProperty('size')) {
            //     formData.append(key, formvalue[0])
            // }
            else if (Array.isArray(value) && key === "panni_printing_color_name") {
                value.forEach((val, index) => formData.append(`panni_printing_color_name`, val))
            }
            // else if (Array.isArray(value) && key === "printing_color_name") {
            //     value.forEach((val, index) => formData.append(`printing_color_name`, val))
            // }
            else if (key === "disposable_product_image" || key === "disposable_product_drawing") {
                if (typeof value === 'object' && value !== null) {
                    formData.append(key, formvalue[0])
                }
                else {
                    formData.append(key, "")
                }
            }

        }

        mutate(formData);
    }

    // const [panniMicron, setPanniMicron] = useState(0)

    // const check = panniMicron



    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            // setshowmessage("success")
            navigate('/listDisposableproducts', { state: { showToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error, isSuccess])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);


    const [priceListValue, setPriceListValue]: any = useState();


    const { data: priceData, refetch: priceRefetch } =
        useFetchProductMLDropDown(searchTerm);

    useEffect(() => {
        setOptionsPriceList(priceData);
    }, [priceData]);

    const apinameForPrice = process.env.REACT_APP_PRICELIST_NAME_API?.toString();

    const {
        mutate: mutatePrice,
        data: dataPrice,
        isError: cpriceIsError,
        isLoading: priceLoading,
        isSuccess: priceSuccess,
        error: priceError,
    }: any = useCreate(apinameForPrice);

    const handleValue = (e: any) => {
        e.target.value = e.target.value
            .toLowerCase()
            .split(" ")
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(" ");
    };



    const [valuePriceList, setValuePriceList]: any = useState();

    const [isLoadingValue, setIsLoadingValue]: any = useState();

    const [optionsPriceList, setOptionsPriceList]: any = useState([]);

    const [valuePriceCreatable, setValuePriceCreatable] =
        useState<Option | null>();

    const handleCreatePrice = (inputValue: string) => {
        // setValueCavityCreatable(inputValue)

        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);

            // setValue("name", `${newOption?.value}`)

            mutatePrice({
                ProductPriceListML: newOption.name,
                status: newOption.status,
            });
            setOptionsPriceList((prev: any) =>
                prev ? [...prev, newOption] : [newOption]
            );
            setValuePriceCreatable(newOption);
        }, 0);
    };





    useEffect(() => {

        (async () => {
            if (priceSuccess && optionsPriceList) {
                await priceRefetch();
                setValue("product_ml_price_list", priceData[0]?.value)
            }
        })();

        return () => {
        };
    }, [priceSuccess, priceData]);

    let ok = getValues("product_ml_price_list")









    useEffect(() => {
        if (piceCode) {
            setpanniGadget(piceCode?.gadget_yes_no)
            setpanniKan(piceCode?.product_kan_yes_no)
        }
    }, [piceCode])

    const handleChangePriceList = (e: any) => {
        setValue("product_ml_price_list", `${e?.value}`);
        setValuePriceCreatable(null);
    };




    const onPrintingChange = (e: any) => {
        setpanniPrinting(e.target.value)
    }

    const [optionsPrinting, setOptionsPrinting]: any = useState([])
    const [forColorsValue, setForColorsValue]: any = useState([])


    const { data: colorPrintingData, refetch: colorPrintingRefetch } = useFetchColorDropDown(searchTerm)

    useEffect(() => {
        // setOptions(colorPrintingData);
        setOptionsPrinting(colorPrintingData)
    }, [colorPrintingData])

    const handleChangePrintingColor = (e: any) => {



        const color_name = e?.map((value: any) => {
            return value?.value
        })
        setValue("panni_printing_color_name", color_name)
        // setColorTotal(e.length)
        setValue("panni_printing_total_color", e.length)
        setForColorsValue(e)
    }




    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
    } = useDelete();
    const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
    } = useDeletemforce();




    const handleRefetch = () => {
        if (apiName === apinameForStickPackingType) {
            StickPackingTypeRefetch()
        }
        if (apiName === apinameForProductPrintingType) {
            ProductPrintingTypeRefetch()
        }
        if (apiName === apinameForProductBy) {
            ProductByRefetch()
        }
        if (apiName === apinameForProductPackingType) {
            ProductPackingTypeRefetch()
        }






        // else if(apiName === apinameForTaluka){
        //   TalukaRefetch()
        // }
    }


    useEffect(() => {
        if (OpenEditDropDownModel && valueStickPackingType && apiName === apinameForStickPackingType) {
            setValueStickPackingType(null)
            setValueStickPackingTypeCreatable(null)
        }
        if (OpenEditDropDownModel && valueStickPackingType && apiName === apinameForProductPrintingType) {
            setValueProductPrintingType(null)
            setValueProductPrintingTypeCreatable(null)
        }
        if (OpenEditDropDownModel && valueProductBy && apiName === apinameForProductBy) {
            setValueProductBy(null)
            setValueProductByCreatable(null)
        }
        if (OpenEditDropDownModel && valueProductPackingType && apiName === apinameForProductPackingType) {
            setValueProductPackingType(null)
            setValueProductPackingTypeCreatable(null)
        }









        if (deletesucess && apiName === apinameForStickPackingType) {
            setValueStickPackingType(null)
            setValueStickPackingTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameForProductPrintingType) {
            setValueProductPrintingType(null)
            setValueProductPrintingTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameForProductBy) {
            setValueProductBy(null)
            setValueProductByCreatable(null)
        }
        if (deletesucess && apiName === apinameForProductPackingType) {
            setValueProductPackingType(null)
            setValueProductPackingTypeCreatable(null)
        }




    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (


        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
            <span>{label}</span>
            <div style={{ float: "right", justifyContent: "end" }}>



                {isExist ?

                    <Custom_Actions_Buttons value={value}
                        apiname={deleteApiName}
                        mutate={singleDelete}
                        // masterName={addButtonName}
                        deleteid={deleteid}
                        handleDeletedforce={handleDeletedforce}
                        closereconfirmdelete={closereconfirmdelete}
                        deletemodalask={deletemodalask}
                        setEditOpen={setOpenEditDropDownModel}
                    // permissionClass={permissionClass}
                    />
                    : ""}

            </div>
        </div>


    )


    const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
    }



    useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
        return () => {
            setDeleteid(undefined);
        };
    }, []);
    const handleConfirmDelete = () => {
        setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
    };

    const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
    };
    useEffect(() => {
        if (forcefullysuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullyerror) {
            setShow(true);
            setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullydeleteerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
    ]);

    useEffect(() => {
        if (deleteMultipleSuccess) {
            const showdepenedancymsg = deleteMultiple?.data?.data;
            const dependacyfound = showdepenedancymsg?.filter((color: any) => {
                if (
                    color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
                ) {
                    return color;
                }
            });
            if (dependacyfound?.length === deleteMultipleid?.length) {
                deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
            } else {
                setConfirmforcemodal(true);
            }
        } else if (deleteMultipleIsError) {
            setShow(true);
            setshowmessage("danger");
        } else if (deletesucess) {
            if (deletedata.data?.data?.length > 0) {
                setConfirmforcemodal(true);
            } else if (deleteid) {
                setShow(true);
                setshowmessage("success");
                deleteforce({ deleteid, apiname: deleteApiName });
            }
        }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

    const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
    };


    const [packingStick,setPackingStick]:any = useState('')
    const [CartonStick,setCartonStick]:any = useState('')
    const [CartonTotalNos,setCartonTotalNos]:any = useState('')

    const handelChangePaking = (e:any) => {
        setPackingStick(e.target.value)
    }
    const handelChangeCartonStick = (e:any) => {
        setCartonStick(e.target.value)
    }
    useEffect(()=>{
        setCartonTotalNos(Number(packingStick)* Number(CartonStick))
        setValue('carton_total_nos',Number(packingStick) * Number(CartonStick))
    },[CartonTotalNos,packingStick,CartonStick])

    return (
        <div >

            <div
                className="content-wrapper "
                style={{ overflow: "auto" }}
            >
                <section className="content-header px-3 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>All Product </h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Product Details</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listDisposableProduct" className="text-decoration-none text-black">All Product List</Link></li>
                                    <li className="breadcrumb-item active">Add All Product</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Disposable Product</span></h5>
                            </div> */}
                            <form
                                ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" >                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message} /> : ""}

                                    {/* <div className="row">
                                        <h5 className="mb-0 mt-2">Disposable Product Detail</h5>
                                    </div> */}
                                    <div className="row d-flex justify-content-md-  center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">

                                                {/* <div className="col-sm-3 mt-2">
                                                    <label>Product Category<span style={{ color: "red" }}>*</span></label>
                                                    <Select
                                                        {...register("product_category", {
                                                            required: {
                                                                value: true,
                                                                message: "Field is required",
                                                            },
                                                        })}
                                                        onChange={handleChangeCategory}
                                                        options={optionsCategory}
                                                        components={customIndicator}
                                                        value={prductCategoryValue}
                                                    />
                                                    <span className='text-red w-100'>{errors?.product_single_piece_code?.message}</span>
                                                </div> */}

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">
                                                        Product Type <span style={{ color: "red" }}>*</span>
                                                    </label>

                                                    <Select
                                                        // id="Panni Name & Grade"
                                                        {...register("product_type", {
                                                            required: {
                                                                value: true,
                                                                message: "Field is required",
                                                            },
                                                        })}
                                                        onChange={handleChangeProductType}
                                                        options={optionsProductType}
                                                        components={customIndicator}
                                                        value={prductTypeValue}

                                                    />
                                                    <span className="text-red w-100">
                                                        {errors?.product_type?.message}
                                                    </span>
                                                </div>

                                                {/* {(prductTypeValue != null && prductCategoryValue.label == "Liner") ? */}
                                                {productType === "Ld" || productType === "Lldp" || productType === "Liner" ?
                                                    <>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Product Single Piece Code <span style={{ color: "red" }}>*</span></label>
                                                            <Select
                                                                {...register("product_single_piece_code", { required: { value: true, message: "Single Piece Code is required" } })}
                                                                placeholder={'Single Piece Code'}
                                                                options={optionsPiceCode}
                                                                onInputChange={handleInputChangePiceCode}
                                                                onChange={handleChangePiceCode}
                                                                components={customIndicator}
                                                                maxMenuHeight={130}

                                                            />
                                                            <span className='text-red w-100'>{errors?.product_single_piece_code?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Grade & Name & Type
                                                            </label>
                                                            <input
                                                                {...register('product_grade_name', { required: { value: false } })}
                                                                placeholder=" Product Grade & Name & Type"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Material Type
                                                            </label>
                                                            <input
                                                                {...register('material_type', { required: { value: false } })}
                                                                placeholder=" Material Type"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Color Name
                                                            </label>
                                                            <input
                                                                {...register('company_color_name', { required: { value: false } })}
                                                                placeholder=" Product Color Name"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Width MM
                                                            </label>
                                                            <input
                                                                {...register('product_width_mm', { required: { value: false } })}
                                                                placeholder=" Product Width MM"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product length MM
                                                            </label>
                                                            <input
                                                                {...register('product_length_mm', { required: { value: false } })}
                                                                placeholder=" Product length MM"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Thickness Micron
                                                            </label>
                                                            <input
                                                                {...register('thickness_micron', { required: { value: false } })}
                                                                placeholder=" Thickness Micron"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Thickness Gauge
                                                            </label>
                                                            <input
                                                                {...register('thickness_gauge', { required: { value: false } })}
                                                                placeholder=" Thickness Gauge"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Gadget Yes-No
                                                            </label>
                                                            <input
                                                                {...register('gadget_yes_no', { required: { value: false } })}
                                                                placeholder="Gadget Yes-No"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Gadget Size MM
                                                            </label>
                                                            <input
                                                                {...register('gadget_size_mm', { required: { value: false } })}
                                                                placeholder=" Gadget Size MM "
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product kan Yes-No
                                                            </label>
                                                            <input
                                                                {...register('product_kan_yes_no', { required: { value: false } })}
                                                                placeholder=" Product kan Yes-No "
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product kan Width MM
                                                            </label>
                                                            <input
                                                                {...register('product_kan_width_mm', { required: { value: false } })}
                                                                placeholder=" Product kan Width MM "
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product kan length MM
                                                            </label>
                                                            <input
                                                                {...register('product_kan_length_mm', { required: { value: false } })}
                                                                placeholder=" Product kan length MM "
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Silieing Type
                                                            </label>
                                                            <input
                                                                {...register('product_sealing_type', { required: { value: false } })}
                                                                placeholder=" Product Silieing Type "
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        {/* <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Silieing Type
                                                                </label>
                                                                <input
                                                                placeholder=" Product Silieing Type "
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                                // value={piceCode?.product_1_pcs_weight}
                                                                />
                                                                
                                                            </div> */}
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product ML Price List
                                                            </label>
                                                            <input
                                                                {...register('product_ml', { required: { value: false } })}
                                                                placeholder="Product ML Price List"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Actual ML
                                                            </label>
                                                            <input
                                                                {...register('product_ml_actual', { required: { value: false } })}
                                                                placeholder="Product Actual  ML "
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label>Product Printing <span style={{ color: "red" }}>*</span></label>
                                                            <select className="form-select" {...register("product_printing", { onChange: onPrintingChange, required: { value: false, message: "Product Printing is required" } })}>
                                                                <option value={""} disabled selected>Select Any</option>
                                                                <option value="yes">Yes</option>
                                                                <option value="no">No</option>
                                                            </select>
                                                            <span className='text-red w-100'>{errors?.product_printing?.message}</span>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label >Product Printing Printing Type</label>
                                                            <CreatableSelect
                                                                isClearable={() => {
                                                                    setValueProductPrintingType(null)
                                                                    setValue(null)
                                                                }}
                                                                {...register("product_printing_printing_type", { required: { value: panniPrinting === 'no' ? false : true, message: "Product Printing Printing Type is required" } })}
                                                                placeholder={'Product printing Printing Type'}
                                                                // menuIsOpen={true}
                                                                components={customCreatableIndicator}
                                                                isDisabled={panniPrinting === 'no'}
                                                                options={optionsProductPrintingType}
                                                                onMenuOpen={() => ProductPrintingTypeRefetch()}
                                                                onCreateOption={handleCreateProductPrintingType}
                                                                onChange={handleChangeProductPrintingType}
                                                                value={valueProductPrintingTypeCreatable == null ? valueProductPrintingType : valueProductPrintingTypeCreatable}

                                                            />
                                                            <span className='text-red w-100'>{errors?.product_printing_type?.message}</span>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label>Product Printing Color Name<span style={{ color: "red" }}>*</span></label>
                                                            <Select
                                                                {...register("panni_printing_color_name", { required: { value: panniPrinting === 'no' ? false : true, message: "Product Printing Color Name is required" } })}
                                                                placeholder={'Product Printing Color Name'}
                                                                isDisabled={panniPrinting === 'no'}
                                                                options={optionsPrinting}
                                                                value={forColorsValue}
                                                                onChange={handleChangePrintingColor}
                                                                components={customIndicator}
                                                                isMulti
                                                            // maxMenuHeight={130}

                                                            />
                                                            <span className='text-red w-100'>{errors?.Product_Printing_Color_Name?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Printing Total Color
                                                            </label>
                                                            <input
                                                                {...register('panni_printing_total_color', { required: { value: false } })}
                                                                placeholder=" Product Printing Total Color"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={ColorTotal}
                                                            // value={piceCode?.product_ml_actual}
                                                            />

                                                        </div>


                                                        <div className="col-sm-3 mt-2">
                                                            <label>Product Brand Name<span style={{ color: "red" }}>*</span></label>
                                                            <div className="d-flex row" >
                                                                <Select
                                                                    {...register("product_brand", { required: { value: panniPrinting === 'no' ? false : true, message: "Product Brand Name is required" } })}
                                                                    placeholder={'Select Brand Name'}
                                                                    onChange={handleChangeBrand}
                                                                    options={optionsbrand}
                                                                    value={forBrandValue}
                                                                    components={customIndicator}
                                                                    isDisabled={panniPrinting === 'no'}
                                                                    // isOptionDisabled={(optionsbrand:any)=>
                                                                    //     {
                                                                    //         if(optionsbrand.status === "inactive" || optionsbrand.deleted === true){
                                                                    //         return optionsbrand
                                                                    //         }
                                                                    //        }}
                                                                    className="dropdown-select"

                                                                />
                                                                <div className="input-group-append" style={{ width: 0, padding: 0 }} >
                                                                    <Button className="input-group-text border-start-0 bg-primary" disabled={panniPrinting === 'no'} onClick={() => {
                                                                        setBrandOpen(true)
                                                                    }} ><AiOutlinePlus /></Button>
                                                                </div>
                                                            </div>
                                                            <span className='text-red w-100'>{errors?.Product_Brand_Name?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Brand Our Other
                                                            </label>
                                                            <input
                                                                {...register('our_product_brand')}
                                                                placeholder="Brand Our Other"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Print Width MM
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    placeholder="Product Print Width MM "
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"
                                                                    disabled={panniPrinting === 'no'}
                                                                // value={piceCode?.product_1_pcs_weight}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Print length MM
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    placeholder="Product Print length MM "
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"
                                                                    disabled={panniPrinting === 'no'}
                                                                // value={piceCode?.product_1_pcs_weight}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Production 1 Piece Weight
                                                            </label>
                                                            <input
                                                                {...register('product_1_pcs_weight', { required: { value: false } })}
                                                                placeholder=" Product Production 1 Piece Weight"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Automatic 1 Piece Weight
                                                            </label>
                                                            <input
                                                                placeholder=" Product Automatic 1 Piece Weight"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>


                                                        <div className="col-sm-3 mt-2">
                                                            <label >Product Packet Packing Type</label>
                                                            <CreatableSelect
                                                                isClearable={() => {
                                                                    setValueProductPackingType(null)
                                                                    setValue(null)
                                                                }}
                                                                {...register("packet_packing_type", { required: { value: false, message: "Product Packet Packing Type is required" } })}
                                                                placeholder={'Select Product Packet Packing Type'}
                                                                // menuIsOpen={true}
                                                                components={customCreatableIndicator}
                                                                options={optionsProductPackingType}
                                                                onMenuOpen={() => ProductPackingTypeRefetch()}
                                                                onCreateOption={handleCreateProductPackingType}
                                                                onChange={handleChangeProductPackingType}
                                                                value={valueProductPackingTypeCreatable == null ? valueProductPackingType : valueProductPackingTypeCreatable}

                                                            />
                                                            <span className='text-red w-100'>{errors?.product_Packet_Packing_type?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label>U.O.M<span style={{ color: "red" }}>*</span></label>
                                                            <div className="d-flex row" >

                                                                <Select
                                                                    {...register("uom_1", { required: { value: true, message: "U.O.M is required" } })}
                                                                    placeholder={'Select U.O.M'}
                                                                    onChange={handleChangeUom}
                                                                    options={optionsUom}
                                                                    value={forUOMValue}
                                                                    components={customIndicator}
                                                                    className="dropdown-select"

                                                                />
                                                                <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                                    <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                        setOpenUOM(true)
                                                                    }}><AiOutlinePlus /></Button>
                                                                </div>
                                                            </div>
                                                            <span className='text-red w-100'>{errors?.UOM?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Packet Qty
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("packet_qty", { onChange: onProductQtyChange, required: { value: false } })}
                                                                    placeholder=" Packet Qty"
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_1_pcs_weight}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Total Packing Kg
                                                            </label>
                                                            <input
                                                                {...register("product_total_packing_kg", { required: { value: false } })}
                                                                placeholder=" Product Total Packing Kg"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label>U.O.M<span style={{ color: "red" }}>*</span></label>
                                                            <div className="d-flex row" >
                                                                <Select
                                                                    {...register("uom_2", { required: { value: true, message: "Product U.O.M is required" } })}
                                                                    placeholder={'Select U.O.M'}
                                                                    onChange={handleChangeProductUom}
                                                                    options={optionsProductUom}
                                                                    value={ForProductUOMValue}
                                                                    components={customIndicator}
                                                                    className="dropdown-select"

                                                                />
                                                                <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                                    <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                        setOpenProductUOM(true)
                                                                    }}><AiOutlinePlus /></Button>
                                                                </div>
                                                            </div>
                                                            <span className='text-red w-100'>{errors?.UOM?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label >Product Packing Type</label>
                                                            <CreatableSelect
                                                                isClearable={() => {
                                                                    setValueProductPakingType(null)
                                                                    setValue(null)
                                                                }}
                                                                {...register("packing_type", { required: { value: false, message: "Product Packing Type is required" } })}
                                                                placeholder={'Select Product Packing Type '}
                                                                // menuIsOpen={true}
                                                                components={customCreatableIndicator}
                                                                options={optionsProductPakingType}
                                                                onMenuOpen={() => ProductPakingTypeRefetch()}
                                                                onCreateOption={handleCreateProductPakingType}
                                                                onChange={handleChangeProductPakingType}
                                                                value={valueProductPakingTypeCreatable == null ? valueProductPakingType : valueProductPakingTypeCreatable}

                                                            />
                                                            <span className='text-red w-100'>{errors?.product_Packing_type?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Stock Minimum Kg
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("product_stock_minimum_kg", { required: { value: false } })}
                                                                    placeholder=" Product Stock Minimum Kg"
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"
                                                                    
                                                                    // value={piceCode?.product_1_pcs_weight}
                                                                    />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Stock maximum Kg
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("product_stock_maximum_kg", { required: { value: false } })}
                                                                    placeholder=" Product Stock maximum Kg"
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_1_pcs_weight}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Brusting Strenght
                                                            </label>
                                                            <input
                                                                {...register('product_bursting_strength', { required: { value: false } })}
                                                                placeholder=" Product Brusting Strenght "
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Our Other
                                                            </label>
                                                            <input
                                                                {...register('product_our_other', { required: { value: false } })}
                                                                placeholder=" Product Our Other "
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Type
                                                            </label>
                                                            <input
                                                                {...register('product_special_running_type', { required: { value: false } })}
                                                                placeholder=" Product Type "
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Maximum Debit Day
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("product_debit_day", { required: { value: false } })}
                                                                    placeholder=" Product Maximum Debit Day"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"
                                                                    
                                                                    // value={piceCode?.product_ml_actual}
                                                                    />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product 1
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("product_1", { required: { value: false } })}
                                                                    placeholder="Product 1"
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_1_pcs_weight}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label >Product Location</label>
                                                            <CreatableSelect
                                                                isClearable={() => {
                                                                    setValueProductLocation(null)
                                                                    setValue(null)
                                                                }}
                                                                {...register("product_location", { required: { value: false, message: "Product Location is required" } })}
                                                                placeholder={'Select Product Location'}
                                                                // menuIsOpen={true}
                                                                components={customCreatableIndicator}
                                                                options={optionsProductLocation}
                                                                onMenuOpen={() => ProductLocationRefetch()}
                                                                onCreateOption={handleCreateProductLocation}
                                                                onChange={handleChangeProductLocation}
                                                                value={valueProductLocationCreatable == null ? valueProductLocation : valueProductLocationCreatable}

                                                            />
                                                            <span className='text-red w-100'>{errors?.product_location?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Day Packet Packing Labour Rate
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("day_packet_packing_labour_rate", { required: { value: false } })}
                                                                    placeholder="Day Packet Packing Labour Rate"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_ml_actual}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Night Packet Packing Labour Rate
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("night_packet_packing_labour_rate", { required: { value: false } })}
                                                                    placeholder="Night Packet Packing Labour Rate"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_ml_actual}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Day Carton/Bori Packing Labour Rate
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("day_carton_packing_labour_rate", { required: { value: false } })}
                                                                    placeholder="Day Carton/Bori Packing Labour Rate"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_ml_actual}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Night Carton/Bori Packing Labour Rate
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("night_carton_packing_labour_rate", { required: { value: false } })}
                                                                    placeholder="Night Carton/Bori Packing Labour Rate"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_ml_actual}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label>Product Packing Cover Code & Name<span style={{ color: "red" }}>*</span></label>
                                                            <Select
                                                                {...register("product_packing_cover_code_name", { required: { value: false, message: "Product Packing Cover Code & Name is required" } })}
                                                                placeholder={'Product Packing Cover Code & Name'}
                                                                // options={optionsPiceCode}
                                                                // onInputChange={handleInputChangePiceCode}
                                                                // onChange={handleChangePiceCode}
                                                                components={customIndicator}
                                                            // maxMenuHeight={130}

                                                            />
                                                            <span className='text-red w-100'>{errors?.product_packing_cover_code_name?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label >Product Packing Cover Dori</label>
                                                            <CreatableSelect
                                                                // isClearable={() => {
                                                                //     setValuePackingCoverDori(null)
                                                                //     setValue(null)
                                                                // }}
                                                                {...register("packing_cover_dori")}
                                                                placeholder={'Product Packing Cover Dori'}
                                                                // menuIsOpen={true}
                                                                components={customCreatableIndicator}
                                                            // isDisabled={panniPrinting === 'no'}
                                                            options={optionsPackingCoverDori}
                                                            onMenuOpen={() => PackingCoverDoriRefetch()}
                                                            onCreateOption={handleCreatePackingCoverDori}
                                                            onChange={handleChangePackingCoverDori}
                                                            value={valuePackingCoverDoriCreatable == null ? valuePackingCoverDori : valuePackingCoverDoriCreatable}

                                                            />
                                                            <span className='text-red w-100'>{errors?.packing_cover_dori?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label>Carton Code & Grade & Name<span style={{ color: "red" }}>*</span></label>
                                                            <Select
                                                                {...register("carton_code_and_grade_and_name", { required: { value: false, message: "Carton Code & Grade & Name is required" } })}
                                                                placeholder={'Carton Code & Grade & Name'}
                                                                options={optionscarton}
                                                                // onInputChange={handleInputChangePiceCode}
                                                                onChange={handleChangeCarton}
                                                                components={customIndicator}
                                                            // maxMenuHeight={130}

                                                            />
                                                            <span className='text-red w-100'>{errors?.Carton_Code_Grade_Name?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label>Patti Code & Grade & Name<span style={{ color: "red" }}>*</span></label>
                                                            <Select
                                                                {...register("box_patti", { required: { value: false, message: "Patti Code & Grade & Name is required" } })}
                                                                placeholder={'Patti Code & Grade & Name'}
                                                                options={optionsBoxpatti}
                                                                value={forBoxPattiValue}
                                                                onChange={handleChangeBoxPatti}
                                                                components={customIndicator}
                                                            // maxMenuHeight={130}

                                                            />
                                                            <span className='text-red w-100'>{errors?.box_patti?.message}</span>
                                                        </div>


                                                    </>
                                                    : ""}

                                                {/* {(prductTypeValue != null && prductCategoryValue.label == "Disposable") ? */}
                                                {productType === "Disposable" && (
                                                    <>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Product Single Piece Code <span style={{ color: "red" }}>*</span></label>
                                                            <Select
                                                                {...register("product_single_piece_code", { required: { value: true, message: "Single Piece Code is required" } })}
                                                                placeholder={'Single Piece Code'}
                                                                options={optionsPiceCode}
                                                                onInputChange={handleInputChangePiceCode}
                                                                onChange={handleChangePiceCode}
                                                                components={customIndicator}
                                                                maxMenuHeight={130}

                                                            />
                                                            <span className='text-red w-100'>{errors?.product_single_piece_code?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Product Price-List 1 Piece Weight </label>
                                                            <input
                                                                {...register("product_price_list_one_piece_weight")}
                                                                placeholder="Product Price-List 1 Piece Weight "
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode.product_type}
                                                            />

                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Product Production 1 Piece Weight </label>
                                                            <input
                                                                {...register('product_1_pcs_weight')}
                                                                placeholder="Product Price-List 1 Piece Weight "
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode.product_type}
                                                            />

                                                        </div>


                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Mould Shape
                                                            </label>
                                                            <input
                                                                {...register("mould_shape")}
                                                                placeholder="Mould Shape"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Mould
                                                            </label>
                                                            <input
                                                                placeholder="Mould"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Price-List Mould Diameter mm
                                                            </label>
                                                            <input
                                                                {...register("price_list_mould_diameter_mm")}
                                                                placeholder="Price-List Mould Diameter mm"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Production Mould Diameter mm
                                                            </label>
                                                            <input
                                                                {...register("production_mould_diameter_mm")}
                                                                placeholder="Price-List Mould Diameter mm"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Production Mould Width mm
                                                            </label>
                                                            <input
                                                                {...register("production_mould_width_mm")}
                                                                placeholder="Production Mould Width mm"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Production Mould Length mm
                                                            </label>
                                                            <input
                                                                {...register("production_mould_length_mm")}
                                                                placeholder=" Production Mould Length mm"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Final Height MM
                                                            </label>
                                                            <input
                                                                {...register("product_final_height_mm")}
                                                                placeholder="Product Final Height MM"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Currunt Height MM
                                                            </label>
                                                            <input
                                                                {...register("product_current_height_mm")}
                                                                placeholder=" Product Currunt Height MM"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Lip
                                                            </label>
                                                            <input
                                                                {...register("product_lip")}
                                                                placeholder="Product Lip"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product  Ml Price-List
                                                            </label>
                                                            <input
                                                                {...register("product_ml_price_list")}
                                                                placeholder="Product  Ml Price-List"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Ml Actual
                                                            </label>
                                                            <input
                                                                {...register("product_actual_ml")}

                                                                placeholder="Product  Ml Actual"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label>Product Brand Name<span style={{ color: "red" }}>*</span></label>
                                                            <div className="d-flex row" >
                                                                <Select
                                                                    {...register("product_brand", { required: { value: panniPrinting === 'no' ? false : true, message: "Product Brand Name is required" } })}
                                                                    placeholder={'Select Brand Name'}
                                                                    onChange={handleChangeBrand}
                                                                    options={optionsbrand}
                                                                    value={forBrandValue}
                                                                    components={customIndicator}

                                                                    // isOptionDisabled={(optionsbrand:any)=>
                                                                    //     {
                                                                    //         if(optionsbrand.status === "inactive" || optionsbrand.deleted === true){
                                                                    //         return optionsbrand
                                                                    //         }
                                                                    //        }}
                                                                    className="dropdown-select"

                                                                />
                                                                <div className="input-group-append" style={{ width: 0, padding: 0 }} >
                                                                    <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                        setBrandOpen(true)
                                                                    }} ><AiOutlinePlus /></Button>
                                                                </div>
                                                            </div>
                                                            <span className='text-red w-100'>{errors?.product_brand_name?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Brand Our Other
                                                            </label>
                                                            <input
                                                                {...register('our_product_brand')}
                                                                placeholder="Brand Our Other"
                                                                type="text"
                                                                className="form-control"
                                                                id="exampleInputBrand"
                                                                disabled
                                                            // value={piceCode?.product_1_pcs_weight}
                                                            />

                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label>Packing Material Code & Grade & Name<span style={{ color: "red" }}>*</span></label>
                                                            <Select
                                                                {...register("panni_code_and_grade_name", { required: { value: true, message: "Packing Material Code & Grade & Name is required" } })}
                                                                placeholder={'Packing Material Code & Grade & Name'}
                                                                options={optionspanni}
                                                                onChange={handleChangePanni}
                                                                components={customIndicator}
                                                                value={PanniValue}
                                                            // maxMenuHeight={130}

                                                            />
                                                            <span className='text-red w-100'>{errors?.Packing_Material_Code_Grade_Name?.message}</span>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label>Carton Code & Grade & Name<span style={{ color: "red" }}>*</span></label>
                                                            <Select
                                                                {...register("carton_code_and_grade_and_name", { required: { value: true, message: "Carton Code & Grade & Name is required" } })}
                                                                placeholder={'Carton Code & Grade & Name'}
                                                                options={optionscarton}
                                                                // onInputChange={handleInputChangePiceCode}
                                                                onChange={handleChangeCarton}
                                                                components={customIndicator}
                                                            // maxMenuHeight={130}

                                                            />
                                                            <span className='text-red w-100'>{errors?.carton_code_and_grade_and_name?.message}</span>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label >Stick Packing Type</label>
                                                            <CreatableSelect
                                                                isClearable={() => {
                                                                    setValueStickPackingType(null)
                                                                    setValue(null)
                                                                }}
                                                                {...register("stick_packing_type", { required: { value: false, message: "Stick Packing Type is required" } })}
                                                                placeholder={'Stick Packing Type'}
                                                                // menuIsOpen={true}
                                                                components={customCreatableIndicator}
                                                                // isDisabled={panniPrinting === 'no'}
                                                                options={optionsStickPackingType}
                                                                onMenuOpen={() => StickPackingTypeRefetch()}
                                                                onCreateOption={handleCreateStickPackingType}
                                                                onChange={handleChangeStickPackingType}
                                                                value={valueStickPackingTypeCreatable == null ? valueStickPackingType : valueStickPackingTypeCreatable}

                                                            />
                                                            <span className='text-red w-100'>{errors?.stick_packing_type?.message}</span>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Packing Stick Nos
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register('stick_nos', { onChange:handelChangePaking, required: { value: false, message: "Packing Stick Nos is required" } })}
                                                                    placeholder=" Packing Stick Nos"
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_1_pcs_weight}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Bundle Stick Qty
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("stick_qty", { required: { value: false, message: "Bundle Stick Qty is required" } })}
                                                                    placeholder="Bundle Stick Qty"
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_1_pcs_weight}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Carton In Stick Qty
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("carton_in_stick_packing", { onChange:handelChangeCartonStick, required: { value: false, message: "Carton In Stick Qty is required" } })}
                                                                    placeholder="Carton In Stick Qty"
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_1_pcs_weight}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Carton In Total Nos
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("carton_total_nos", { required: { value: false, message: "Carton In Stick Qty is required" } })}
                                                                    placeholder="Carton In Stick Qty"
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"
                                                                    disabled
                                                                    value={CartonTotalNos}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Final Stick Hight MM
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("product_final_stick_height_mm")}
                                                                    placeholder="Product Final Stick Hight MM"
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_1_pcs_weight}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Current Stick Hight MM
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("product_current_stick_height_mm")}
                                                                    placeholder="Product Current Stick Hight MM"
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_1_pcs_weight}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-sm-3 mt-2">
                                                            <label>Product Printing <span style={{ color: "red" }}>*</span></label>
                                                            <select className="form-select" {...register("product_printing", { onChange: onPrintingChange, required: { value: false, message: "Product Printing is required" } })}>
                                                                <option value={""} disabled selected>Select Any</option>
                                                                <option value="yes">Yes</option>
                                                                <option value="no">No</option>
                                                            </select>
                                                            <span className='text-red w-100'>{errors?.Product_Printing?.message}</span>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label >Product Printing Printing Type</label>
                                                            <CreatableSelect
                                                                isClearable={() => {
                                                                    setValueProductPrintingType(null)
                                                                    setValue(null)
                                                                }}
                                                                {...register("panni_printing_type", { required: { value: panniPrinting === 'no' ? false : true, message: "Product Printing Printing Type is required" } })}
                                                                placeholder={'Product printing Printing Type'}
                                                                // menuIsOpen={true}
                                                                components={customCreatableIndicator}
                                                                isDisabled={panniPrinting === 'no'}
                                                                options={optionsProductPrintingType}
                                                                onMenuOpen={() => ProductPrintingTypeRefetch()}
                                                                onCreateOption={handleCreateProductPrintingType}
                                                                onChange={handleChangeProductPrintingType}
                                                                value={valueProductPrintingTypeCreatable == null ? valueProductPrintingType : valueProductPrintingTypeCreatable}

                                                            />
                                                            <span className='text-red w-100'>{errors?.panni_printing_type?.message}</span>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label>Product Printing Color Name<span style={{ color: "red" }}>*</span></label>
                                                            <Select
                                                                {...register("panni_printing_color_name", { required: { value: panniPrinting === 'no' ? false : true, message: "Product Printing Color Name is required" } })}
                                                                placeholder={'Product Printing Color Name'}
                                                                isDisabled={panniPrinting === 'no'}
                                                                options={optionsPrinting}
                                                                value={forColorsValue}
                                                                onChange={handleChangePrintingColor}
                                                                components={customIndicator}
                                                                isMulti
                                                            // maxMenuHeight={130}

                                                            />
                                                            <span className='text-red w-100'>{errors?.panni_printing_color_name?.message}</span>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Printing Total Color
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register('panni_printing_total_color', { required: { value: false } })}
                                                                    placeholder=" Product Printing Total Color"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"
                                                                    disabled
                                                                // value={ColorTotal}
                                                                // value={piceCode?.product_ml_actual}
                                                                />
                                                                {/* <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div> */}
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Carton Stock Minimum Qty
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("product_stock_minimum_qty")}
                                                                    placeholder="Carton Stock Minimum Qty"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_ml_actual}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Carton Stock Maximum Qty
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("product_stock_maximum_qty")}
                                                                    placeholder="Carton Stock Maximum Qty"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_ml_actual}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label >Product By</label>
                                                            <CreatableSelect
                                                                isClearable={() => {
                                                                    setValueProductBy(null)
                                                                    setValue(null)
                                                                }}
                                                                {...register("product_by", { required: { value: true, message: "Product By is required" } })}
                                                                placeholder={'Select Product By '}
                                                                // menuIsOpen={true}
                                                                components={customCreatableIndicator}
                                                                options={optionsProductBy}
                                                                onMenuOpen={() => ProductByRefetch()}
                                                                onCreateOption={handleCreateProductBy}
                                                                onChange={handleChangeProductBy}
                                                                value={valueProductByCreatable == null ? valueProductBy : valueProductByCreatable}

                                                            />
                                                            <span className='text-red w-100'>{errors?.product_by?.message}</span>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label >Product Type</label>
                                                            <CreatableSelect
                                                                isClearable={() => {
                                                                    setValueMaterialUse(null)
                                                                    setValue(null)
                                                                }}
                                                                {...register("product_special_running", { required: { value: true, message: "Material Use is required" } })}
                                                                placeholder={'Select Product Type'}
                                                                // menuIsOpen={true}
                                                                components={customCreatableIndicator}
                                                                options={optionsMaterialUse}
                                                                onCreateOption={handleCreateMaterialUse}
                                                                onChange={handleChangeMaterialUse}
                                                                value={valueMaterialUseCreatable == null ? valueMaterialUse : valueMaterialUseCreatable}
                                                                onMenuOpen={() => MaterialUseRefetch()}
                                                            />
                                                            <span className='text-red w-100'>{errors?.product_type?.message}</span>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Product Maximum Debit Day
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("product_debit_day")}
                                                                    placeholder=" Product Maximum Debit Day"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_ml_actual}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label >Product Location</label>
                                                            <CreatableSelect
                                                                isClearable={() => {
                                                                    setValueProductLocation(null)
                                                                    setValue(null)
                                                                }}
                                                                {...register("product_location", { required: { value: true, message: "Product Location is required" } })}
                                                                placeholder={'Select Product Location'}
                                                                // menuIsOpen={true}
                                                                components={customCreatableIndicator}
                                                                options={optionsProductLocation}
                                                                onMenuOpen={() => ProductLocationRefetch()}
                                                                onCreateOption={handleCreateProductLocation}
                                                                onChange={handleChangeProductLocation}
                                                                value={valueProductLocationCreatable == null ? valueProductLocation : valueProductLocationCreatable}

                                                            />
                                                            <span className='text-red w-100'>{errors?.product_location?.message}</span>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Day Packet Packing Labour Rate
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("day_packet_packing_labour_rate")}
                                                                    placeholder="Day Packet Packing Labour Rate"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_ml_actual}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Night Packet Packing Labour Rate
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("night_packet_packing_labour_rate")}
                                                                    placeholder="Night Packet Packing Labour Rate"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_ml_actual}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Day Carton Packing Labour Rate
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("day_carton_packing_labour_rate")}
                                                                    placeholder="Day Carton Packing Labour Rate"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_ml_actual}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">
                                                                Night Carton Packing Labour Rate
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("night_carton_packing_labour_rate")}
                                                                    placeholder="Night Carton Packing Labour Rate"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputBrand"

                                                                // value={piceCode?.product_ml_actual}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label>Patti Code & Grade & Name<span style={{ color: "red" }}>*</span></label>
                                                            <Select
                                                                {...register("box_patti", { required: { value: false, message: "Patti Code & Grade & Name is required" } })}
                                                                placeholder={'Patti Code & Grade & Name'}
                                                                options={optionsBoxpatti}
                                                                value={forBoxPattiValue}
                                                                onChange={handleChangeBoxPatti}
                                                                components={customIndicator}
                                                            // maxMenuHeight={130}

                                                            />
                                                            <span className='text-red w-100'>{errors?.box_patti?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label>Tap Roll Code & Grade & Name<span style={{ color: "red" }}>*</span></label>
                                                            <Select
                                                                {...register("tape_roll_code_and_grade_name", { required: { value: false, message: "Tap Roll Code & Grade & Name is required" } })}
                                                                placeholder={'Tap Roll Code & Grade & Name'}
                                                                options={optionsTapeRoll}
                                                                value={forTapeRollValue}
                                                                onChange={handleChangeTapeRoll}
                                                                components={customIndicator}
                                                            // maxMenuHeight={130}

                                                            />
                                                            <span className='text-red w-100'>{errors?.tape_roll_code_and_grade_name?.message}</span>
                                                        </div>
                                                    </>
                                                    //     :
                                                    //     ""
                                                    // }
                                                )}





                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">
                                                        Remark
                                                    </label>
                                                    <div className="input-group">
                                                        <input
                                                            {...register("remark", { required: { value: false, message: "Remark is required" } })}
                                                            placeholder="Remark"
                                                            type="text"
                                                            className="form-control"
                                                            id="exampleInputBrand"

                                                        // value={piceCode?.product_ml_actual}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label>Status <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>
                                                        <option value={""} disabled selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>
                                                </div>

                                                {(prductTypeValue != null && prductCategoryValue.label == "Liner") ?


                                                    <div className="row d-flex">
                                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                                            <div className="row d-flex ">
                                                                <div className="col-sm-3 mt-2 image-upload">
                                                                    <label htmlFor="exampleInputEmail1" className="col-12">Bori & Carton  All Image </label>
                                                                    <Upload
                                                                        {...register("bori_Carton_image")}
                                                                        accept="image/png, image/jpeg"
                                                                        listType="picture"
                                                                        //   onChange={BoriAndCartonChange}
                                                                        multiple={true}
                                                                        //   defaultFileList={uploadBoriAndCartonImage}
                                                                        onPreview={(e: any) => { return "" }}
                                                                        key={"image"}
                                                                    >
                                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                                    </Upload>
                                                                </div>
                                                                <div className="col-sm-3 mt-2 image-upload">
                                                                    <label htmlFor="exampleInputEmail1" className="col-12">Product Packet Packing  All Image </label>
                                                                    <Upload
                                                                        {...register("product_packet_packing_image")}
                                                                        accept="image/png, image/jpeg"
                                                                        listType="picture"
                                                                        //   onChange={BoriAndCartonChange}
                                                                        multiple={true}
                                                                        //   defaultFileList={uploadBoriAndCartonImage}
                                                                        onPreview={(e: any) => { return "" }}
                                                                        key={"image"}
                                                                    >
                                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                                    </Upload>
                                                                </div>
                                                                <div className="col-sm-3 mt-2 image-upload">
                                                                    <label htmlFor="exampleInputEmail1" className="col-12">Product  All Image </label>
                                                                    <Upload
                                                                        {...register("product_image")}
                                                                        accept="image/png, image/jpeg"
                                                                        listType="picture"
                                                                        //   onChange={BoriAndCartonChange}
                                                                        multiple={true}
                                                                        disabled
                                                                        //   defaultFileList={uploadBoriAndCartonImage}
                                                                        onPreview={(e: any) => { return "" }}
                                                                        key={"image"}
                                                                    >
                                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                                    </Upload>
                                                                </div>

                                                                <div className="col-sm-3 mt-2 image-upload">
                                                                    <label htmlFor="exampleInputEmail1" className="col-12">Product PDF All Drawing</label>
                                                                    <Upload
                                                                        {...register("product_pdf_drawing")}
                                                                        accept=".doc,.docx,.xml,.pdf"
                                                                        listType="text"
                                                                        //   onChange={ProductPDFOnChange}
                                                                        multiple={true}
                                                                        disabled
                                                                        //   defaultFileList={uploadProductPDF}
                                                                        onPreview={(e: any) => { return "" }}
                                                                        key={"pdf"}
                                                                    >
                                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                                    </Upload>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>



                                                    : " "}













                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button"
                                                        className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{
                                                            borderColor: "rgb(0, 54, 101)",
                                                            background: "rgb(0, 54, 101)",
                                                            color: "white",
                                                        }}
                                                        onClick={handleSubmit(onSubmit)}
                                                        disabled={isLoading}
                                                    >
                                                        Submit
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3"
                                                        style={{
                                                            borderColor: "blue",
                                                            background: "blue",
                                                            color: "white",
                                                        }}
                                                        onClick={handleReset}
                                                    >
                                                        Reset
                                                    </button>

                                                    <button
                                                        type="button"
                                                        className=" border-1 rounded  px-4 py-1 mx-3 mt-2"
                                                        style={{
                                                            borderColor: "red",
                                                            background: "red",
                                                            color: "white",
                                                        }}
                                                        onClick={() => navigate("/listSinglecode")}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </section >

            </div >
            <Brand_Model refetchForm={brandRefetch} open={brandOpen} setOpen={setBrandOpen} setSubmitted={setSubmittedBrand} />
            <UOM_Model refetchForm={UomRefetch} open={openUOM} setOpen={setOpenUOM} setSubmitted={setSubmittedUOM} />
            <UOM_Model refetchForm={UomRefetch} open={openProductUOM} setOpen={setOpenProductUOM} setSubmitted={setSubmittedProductUOM} />
            {/* <Footer /> */}

            {/* Edit Modal HTML */}
            <Modal
                show={deleteConfirm}
                onHide={() => setDeleteConfirm(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete {""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {" "}
                        <span className="text-center">Sure you want to delete it?</span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setDeleteConfirm(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={handleMultipleDeleted}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Deleteforce
                key={renderKey}
                handleDeletedforce={handleDeletedforce}
                reconfirmdelete={confirmforcemodal}
                masterName={""}
                closereconfirmdelete={closereconfirmdelete}
                referance={
                    deletesucess
                        ? deletedata
                        : ""
                }
                apiname={apiName}
            />
            <Deletesingle
                key={renderKey}
                confirmmodal={deleteConfirmsingle}
                confirmmodalclose={confirmmodalclose}
                masterName={""}
                confirmmodalsubmit={confirmmodalsubmit}
                apiname={apiName}
            />

            <SimpleInput_Edit key={renderKey}
                refetchForm={handleRefetch}
                open={OpenEditDropDownModel}
                setOpen={setOpenEditDropDownModel}
                id={editId}
                // superAccountId={superAccountId} 
                // optionsSuperAccount={optionsSuperAccount}
                // isSuperAccount={isSuperAccount}
                apiname={apiName} />
        </div >
    )
}

export default Add_DisposableProduct_Copy