import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Restricted from "../../Roles/Restricted";
// import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import axios from "axios";
import FileDownload from 'js-file-download';
import { GoKebabHorizontal } from "react-icons/go";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useState } from "react";



const Quotation_cell = ({ row, apiname, deletemodalask,permissionClass }: any) => {
  const [ask, setAsk]:any = useState(null);
//   document.addEventListener('mouseup', function(e:any) {
//     var container:any = document.getElementById('container');
//     if (!container.contains(e.target)) {
//         container.style.display = 'none';
//     }
// });

  const handleDownloadQuotation = () => {
    return axios.get(
      `${process.env.REACT_APP_BASE_URL}salesUtils/quotation/pdf/${row.original.extra_response?.company_name_and_city_id}?lead_id=${row.original.id}`,
      {
        responseType: "blob",

        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          "Content-Type": "application/json",
        },

      }
    ).then((response) => {
      FileDownload(response.data, 'Quotation.pdf');
    });
  };
  const handleEmailQuotation = () => {
    return axios.get(
      `${process.env.REACT_APP_BASE_URL}salesUtils/sendquotation/pdf/${row.original.extra_response?.company_name_and_city_id}?lead_id=${row.original.id}`,
      {
        responseType: "blob",

        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          "Content-Type": "application/json",
          // 'X-Frontend-Domain': window.location.origin
        },

      }
    )
    // .then((response) => {
    //   FileDownload(response.data, 'Quotation.pdf');
    // });
  
  };

  // console.log("rowwww",row)

  

  return (
    <>
      <div
      
        className="vertical-align-center"
        style={{
          display: "inline-block !important",
          justifyContent: "space-evenly !important",
        }}
      >
        {/* <Restricted
          to={`${permissionClass?.front}.change_${permissionClass?.back}`}
          fallback={""}
        > */}



          {/* <Link
            className="btn btn-primary text-white mx-2"
            to={`/quotation/${Number(row.original.id)}`}
          >
            <span className="text-xs">Get a Quotation</span>
          </Link> */}

<OverlayTrigger
// id="container"
          trigger={"focus"}
          key={"bottom"}
          placement={"bottom-end"}
          overlay={
            <Popover >
             
              <Popover.Body >
                <div className="m-2">
               <button onClick={handleDownloadQuotation} className="btn btn-primary text-white w-100">Download Quotation</button>
                </div>
                <div className="m-2">
               <button onClick={(e) => {
              setAsk(e);
            }} className="btn btn-primary text-white w-100 ">E-Mail Quotation</button>
                </div>
              </Popover.Body>
            </Popover>
          }
        >



<button
          // onClick={handleSubmitQuotation}
          className="btn btn-primary text-white"
        >
        
          <GoKebabHorizontal/>
          {/* <RiDeleteBinLine /> */}
        </button>
        </OverlayTrigger>

          {/* <Button onClick={()=>alert(row.original.id)}>Get a Quotation</Button> */}
        {/* </Restricted> */}

        {/* <Restricted
          to={`${permissionClass?.front}.delete_${permissionClass?.back}`}
          fallback={""}
        >
        <button
          onClick={() => {
            deletemodalask(row.original.id);
          }}
          className="btn btn-primary text-white"
        >
          <RiDeleteBinLine />
        </button>
        </Restricted> */}
        <Modal show={ask} onHide={() => setAsk(null)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Email Send Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                Are you sure you want to Send Email?
                </Modal.Body>
                <Modal.Footer>



                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setAsk(null) }}>No</button>


                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={() => {
                          handleEmailQuotation();
                          setAsk(null)
                        }}
                    >Confirm</button>

                </Modal.Footer>
            </Modal>
      </div>
    </>
  );
};

export default Quotation_cell;

