import { useEffect, useRef, useState } from "react";
import "../../../components/Header/header.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Select from 'react-select';
import { useCreate, useUpdateSingle } from "../../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../../components/focus";
import CreatableSelect from "react-select/creatable";
import { useFetchAddressTypeDropDown, useFetchCityDropDown, useFetchCityPincodeDropDown, useFetchEmployeeDropDown, useFetchPersonTypeDropDown } from "../../../hooks/DropDown_Api";
import "./Multiple.css"
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Columns_Address_Details, titleofheaders } from "./Columns_Address_Details"
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import Stepper_Table from "../../../components/Dynamic_Table/Table/Stepper Table";
import Axiosinstance from "../../../hooks/Interceptor";
const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}


interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});

interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOptions = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});

const Add_Address_Details = ({ setCurrentStep, currentStep, fromEdit, showFromEdit, setDataAdded }: any) => {

    const currentPage = window.location.pathname


    let pageName = "Address Details"
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()

    const apiname = process.env.REACT_APP_ADDRESS_API?.toString()


    const { mutate: Address, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)

    const { register, handleSubmit, setError, formState, reset, setValue, getValues }: any = useForm({
        mode: "onChange"
    });
    const { errors } = formState;



    const onSubmit = (data: any) => {

        if (buildingType != "block no") {
            delete data?.bunglow_name
            delete data?.society_name
        }

        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            const formvalue: any = value



            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }

        }
        Address(formData)
        setAdded(true)

    }



    const [optionsCity, setOptionsCity]: any = useState()
    const [valueCityCreatable, setValueCityCreatable] = useState<Option | null>();

    const [valueCity, setValueCity]: any = useState()

    const apinameForCity = process.env.REACT_APP_CITY_API?.toString()

    const { mutate: mutateCity, data: dataCountry, isError: CountryIsError, isLoading: CountryLoading, isSuccess: CountrySuccess, error: CountryError }: any = useCreate(apinameForCity)


    const { data: CityData, refetch: CityRefetch } = useFetchCityDropDown()
    // const [optionsCavityName, setOptionsCavityName] = useCountry(cavityNameData)
    useEffect(() => {
        setOptionsCity(CityData)
    }, [CityData])

    const handleChangeCity = (e: any) => {
        setValueCity(e)

        setValue("city", `${e?.value}`)
        setValueCityCreatable(null)
        setValueDistrict(e?.district)
        setValueState(e?.state)
        setValueCountry(e?.country)
        setValueTaluka(e?.taluka)
        // setCountryValue(e?.value)
        setForPincode(e?.label)
        setForPincodeId(e?.value)
        setByCity(e?.value)
    }


    const handleCreateCity = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOptions(inputValue);
            // setIsLoadingValue(false);


            mutateCity({ name: newOption.name, status: newOption.status })
            setOptionsCity((prev: any) => [...prev, newOption]);
            setValueCityCreatable(newOption);
        }, 0);


    };

    // const [cityValue,setCityValue]=useState("")
    useEffect(() => {
        (async () => {
            if (CountrySuccess && optionsCity) {
                await CityRefetch();
                setValue("city", CityData[0]?.value)
            }
            // setCityValue(CityData[0]?.value)
        })();

        return () => {
        };
    }, [CountrySuccess, CityData]);


    const [cityPincodeOptions, setCityPincodeOptions]: any = useState()
    const [forCityPincodeValue, setForCityPincodeValue]: any = useState()
    const [byCity, setByCity] = useState()
    const [forPincode, setForPincode]: any = useState("")
    const [forPincodeId, setForPincodeId] = useState("")



    const { data: cityPincodeData, refetch: cityPincodeRefetch } = useFetchCityPincodeDropDown(byCity)


    useEffect(() => {
        setCityPincodeOptions(cityPincodeData)
    }, [cityPincodeData])

    const handleChangePincode = (e: any) => {
        setForCityPincodeValue(e)
        setValue("pincode", e?.value)
    }



    const [valuePersonType, setValuePersonType]: any = useState();

    const [optionsPersonType, setOptionsPersonType]: any = useState([])

    const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valuePersonTypeCreatable, setValuePersonTypeCreatable] = useState<Option | null>();

    const apinameForPersonType = process.env.REACT_APP_PERSON_TYPE_API?.toString()

    const { mutate: mutatePersonType, data: dataCavity, isError: cavityIsError, isLoading: cavityLoading, isSuccess: personTypeSuccess, error: cavityError }: any = useCreate(apinameForPersonType)


    const { data: personTypeData, refetch: personTypeRefetch } = useFetchPersonTypeDropDown()

    useEffect(() => {
        setOptionsPersonType(personTypeData)
    }, [personTypeData])





    const handleChangePersonType = (e: any) => {
        setValue("type", e?.value)
        setValuePersonTypeCreatable(null)
    }

    const handleCreatePersonType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOptions(inputValue);
            setIsLoadingValue(false);


            mutatePersonType({ name: newOption.name, status: newOption.status })
            setOptionsPersonType((prev: any) => [...prev, newOption]);
            setValuePersonTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (personTypeSuccess && optionsPersonType) {
                await personTypeRefetch();
            }
            setValue("type", personTypeData[0].value)
        })();

        return () => {
        };
    }, [personTypeSuccess, personTypeData]);






    const [forValue, setForValue]: any = useState()
    const [employeeOptions, setEmployeeOptions] = useState()
    const [forEmployeeValue, setForEmployeeValue]: any = useState(0)


    const { data: employeeData, refetch: employeeRefetch } = useFetchEmployeeDropDown()


    useEffect(() => {
        setEmployeeOptions(employeeData)
    }, [employeeData])



    const [valueDistrict, setValueDistrict]: any = useState()
    const [valueState, setValueState]: any = useState()
    const [valueCountry, setValueCountry]: any = useState()
    // const [valuePincode,setValuePincode]= useState()
    const [valueTaluka, setValueTaluka]: any = useState()







    const handleChangeAreaManager = (e: any) => {

        setForEmployeeValue(e)
        setValue("employee_code", e.value)

    }
    const [buildingType, setBuildingType]: any = useState("")
    const handleBuildingType = (e: any) => {
        setBuildingType(e.target.value)
    }


    const handleReset = () => {
        reset()
        setForEmployeeValue(null)
        setBuildingType(null)
        setValueCity(null)
        setForCityPincodeValue(null)
        setForPincode(null)
        setValueCountry(null)
        setValueDistrict(null)
        setValueState(null)
        setValueTaluka(null)
    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")


    const [multipleIds, setMultipleIds]: any = useState([])

    const [added, setAdded] = useState(false)

    useEffect(() => {
        if (isSuccess) {
            if (data) {
                setMultipleIds((oldIds: any) => ([...oldIds, data?.results?.id]));

                setAdded(false)
                if (currentPage == '/addleadGenerated') {

                    setDataAdded(true)
                }
            }
        }
    }, [isSuccess, data]);

    console.log('multipleIds', multipleIds)


    const [ids, setIds]: any = useState([])
    useEffect(() => {
        if (isSuccess) {
            if (data) {
                setIds((oldIds: any) => ([...oldIds, data?.results?.id]));

                setAdded(false)
                if (currentPage == '/addleadGenerated') {

                    setDataAdded(true)
                }
            }
        }
    }, [isSuccess, data]);


    // useEffect(()=>{
    //     if(isSuccess&& data){
    //         
    //         localStorage.setItem('Address Details',data?.data?.id)


    //     }
    // },[data,isSuccess])






    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")


            reset()
            setForEmployeeValue(null)
            setBuildingType(null)
            setValueCity(null)
            setForCityPincodeValue(null)
            setForPincode(null)
            setValueCountry(null)
            setValueDistrict(null)
            setValueState(null)
            setValueTaluka(null)
            setValueAddressTypeCreatable(null)
            setValueAddressType(null)

            //  const currentPath = window.location.pathname;
            // if (currentPath === '/addCustomerDetails') {
            //     setCurrentStep(currentStep + 1)
            // };
            // navigate('/listAddress', { state: { showToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [isSuccess, data, show]);


    // useEffect(()=>{
    //     if(multipleIds.length>0 && !added){

    //         Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apiname}/fetch_multiple_ids/`,{
    //             ids:multipleIds
    //           })
    //       .then((response) => {
    //         console.log(response?.data);
    //         setMultipleData(response?.data?.data)
    //         setAdded(false)
    //         setShowOffcancas(false)
    //         reset()

    //       })
    //       .catch((error) => {
    //         console.error('There was an error!', error);  
    //       });
    //     }
    // },[multipleIds,added])



    const maxLengthCheck = (object: any) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }
    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }


    const [showOffcancas, setShowOffcancas] = useState(false);

    const handleClose = () => {
        // fromEdit(false)
        setShowOffcancas(false)
    };
    const handleShow = () => {
        // fromEdit(true)
        setShowOffcancas(true)
    };


    const apiMultipleData = process.env.REACT_APP_DOCUMENT_DETAIL_API?.toString()
    const [multipleData, setMultipleData] = useState([])




    const filter = {


        name: ''

    };

    const addButtonName = "Address Details";
    const addButtonPageName = "addressDetails"
    const permissionClass = {
        front: "geography",
        back: "address"
    }
    const stickyStart = 1;
    const stickyEnd = 2;




    const [valueAddressType, setValueAddressType]: any = useState();
    const [optionsAddressType, setOptionsAddressType]: any = useState([]);
    const [valueAddressTypeCreatable, setValueAddressTypeCreatable] = useState<Option | null>();

    const apinameForAddressType = process.env.REACT_APP_SALES_ADDRESS_TYPE_API?.toString()
    const { mutate: mutateAddressType, data: dataAddressType, isError: AddressTypeIsError, isLoading: AddressTypeLoading, isSuccess: AddressTypeSuccess, error: AddressTypeError }: any = useCreate(apinameForAddressType)

    const { data: AddressTypeData, refetch: AddressTypeRefetch } = useFetchAddressTypeDropDown()

    useEffect(() => {
        setOptionsAddressType(AddressTypeData)
    }, [AddressTypeData])

    const handleChangeAddressType = (e: any) => {
        setValueAddressType(e)
        setValue("address_type", `${e?.value}`)
        setValueAddressTypeCreatable(null)

    }


    const handleAddressTypeCreate = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutateAddressType({ name: newOption.name, status: newOption.status })
            setOptionsAddressType((prev: any) => [...prev, newOption]);
            setValueAddressTypeCreatable(newOption);
        }, 0);


    };


    useEffect(() => {
        (async () => {
            if (AddressTypeSuccess && optionsAddressType) {
                await AddressTypeRefetch();
                setValue("address_type", AddressTypeData[0]?.value)
            }
        })();

        return () => {
        };
    }, [AddressTypeSuccess, AddressTypeData]);




    const apinameForBasicDetails = process.env.REACT_APP_EMPLOYEE_API?.toString()
    const { mutate: BasicDetails, isSuccess: basicDetailsIsSuccess, isError: basicDetailsIsError, error: basicDetailsError, data: basicDetailsData }: any = useUpdateSingle()

    let rawData = localStorage.getItem('Add Data');
    let addData = rawData ? JSON.parse(rawData) : null;

    let basicId = addData ? addData.id : null;



    // const [ids, setIds]: any = useState([])
    // const [IsIds, setIsIds] = useState(false)

    // useEffect(() => {

    //     if (isSuccess && data) {
    //         setIds((prev: any) => ([...prev, data?.results?.id]))
    //         setIsIds(true)

    //         // let tempData = {
    //         //     id: basicId,
    //         //     apiname: apinameForBasicDetails,
    //         //     data: ids
    //         // }
    //         // BasicDetails(tempData);
    //     }


    //     // send()



    // }, [data, isSuccess])



    useEffect(() => {


        if (isSuccess && data) {

            let tempId = {
                address: ids
            }


            let tempData = {
                id: basicId,
                apiname: apinameForBasicDetails,
                data: tempId
            }
            BasicDetails(tempData);
        }

    }, [data, isSuccess, ids])







    const handleNext = () => {
        // let tempId = {
        //     address:multipleIds
        // }

        // let tempData = {
        //     id: basicId,
        //     apiname: apinameForBasicDetails,
        //     data: tempId
        // }
        // BasicDetails(tempData);

        const currentPath = window.location.pathname;
        if (currentPath === '/addCustomerDetails') {

            setCurrentStep(currentStep + 1)
        };
        // BasicDetails({})
    }


    useEffect(() => {
        if (basicDetailsIsSuccess && basicDetailsData) {
            localStorage.setItem('Add Data', JSON.stringify(basicDetailsData?.data))
            const currentPath = window.location.pathname;
            if (currentPath === '/addCustomerDetails') {
                setCurrentStep(currentStep + 1)
            };
        }
    }, [basicDetailsIsSuccess, basicDetailsData])

    const [isNew, setIsNew] = useState(false)


    const [activePage, setActivePage] = useState('')

    useEffect(() => {
        setTimeout(() => {
            setActivePage('Add Address')
        }, 500)

    }, [activePage]);


    const currentPath = window.location.pathname;






    return (
        <div>
            <div className="" >
                <section className="content-header py-1 px-3">
                    {/* <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Add {pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">{pageName}</Link></li>
                                    <li className="breadcrumb-item active">Add {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div> */}
                </section>
                <section className="">
                    <div className="">
                        <div className="card card-default">

                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Source Name</span></h5>
                            </div> */}

                            {/* <div className="col-sm-3 mt-2">
                                                <label >Person Type <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                    {...register("type", { required: { value: true, message: "Field is required" } })}
                                                    isClearable={()=>{
                                                        setValuePersonType(null)
                                                        setValue(null)}}
                               
                                                        placeholder={'Person Type'}
                                                        options={optionsPersonType}
                                                        onCreateOption={handleCreatePersonType}
                                                        onChange={handleChangePersonType}
                                                        value={valuePersonTypeCreatable==null?valuePersonType:valuePersonTypeCreatable}
                                                        

                                                    />
                                                    <span className='text-red w-100'>{errors?.type?.message}</span>

                                                </div> */}


                            <div className="row d-flex  ">
                                {/* <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 "> */}
                                <div className="row d-flex ">
                                    <div className="row">
                                        <p className="mb-0 " style={{ fontSize: '14px' }}>{`${addData?.person_type?.name}-${addData?.name}-${addData?.extra_response?.city_name_and_distict_name?.village_name ? addData?.extra_response?.city_name_and_distict_name?.village_name : addData?.extra_response?.city_name_and_distict_name?.city_name}`}</p>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-end text-sm-end ">
                                        <button
                                            type="button" className="border-1 rounded text-white px-4 py-1"
                                            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                            onClick={handleShow}

                                        >Add Addresses</button>

                                    </div>
                                </div>
                                {/* </div> */}
                            </div>




                            {/* <Offcanvas show={showOffcancas } onHide={handleClose}  placement={'end'} style={{ width: '88vw',zIndex:'10000' }} > */}
                            <Offcanvas show={showOffcancas || showFromEdit} onHide={handleClose} placement={'end'} style={{ width: '178vh', zIndex: '10000' }} >
                                <Offcanvas.Header closeButton>
                                    {/* <Offcanvas.Title>Add Address</Offcanvas.Title> */}
                                </Offcanvas.Header>
                                <Offcanvas.Body >
                                    <div className="card card-default">
                                        <h3>Add</h3>

                                        <form ref={focus}
                                            onKeyUp={event => onEnterKey(event)}
                                            onSubmit={handleSubmit(onSubmit)}>
                                            <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                                                <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            {/* <div className="col-sm-3 mt-2">
                                                    <label >Employee Code & Surname & Name<span style={{ color: 'red' }}>*</span></label>

                                                    <Select
                                                        {...register("employee_code", {
                                                            required: {
                                                                value: true,
                                                                message: "Employee Code,Surname & Name is required",
                                                            },
                                                        })}
                                                        onChange={handleChangeAreaManager}
                                                        options={employeeOptions}
                                                        value={forEmployeeValue}
                                                  
                                                    />
                                                    <span className='text-red w-100'>{errors?.employee_code?.message}</span>

                                                </div> */}







                                                            <div className="col-sm-3 mt-2">

                                                                {/* <label >Address Type<span style={{ color: 'red' }}>*</span></label>

                                                    <select className="form-select" {...register("address_type", {  required: { value: true, message: "Address Type is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="current">Current</option>
                                                        <option value="others">Others</option>
                                                        <option value="permanent">Permanent</option>
                                                    </select> */}


                                                                <label >Address Type<span style={{ color: "red" }}>*</span></label>
                                                                <CreatableSelect
                                                                    isClearable={() => {
                                                                        setValueAddressType(null)
                                                                        setValue(null)
                                                                    }}
                                                                    // classNames="creatable-select"
                                                                    {...register("address_type", { required: { value: true, message: "Address Type is required" } })}
                                                                    placeholder={'Select Address Type'}
                                                                    components={customCreatableIndicator}
                                                                    // menuIsOpen={true}
                                                                    options={optionsAddressType}
                                                                    onCreateOption={handleAddressTypeCreate}
                                                                    onChange={handleChangeAddressType}
                                                                    value={valueAddressTypeCreatable == null ? valueAddressType : valueAddressTypeCreatable}

                                                                />
                                                                <span className='text-red w-100'>{errors?.address_type?.message}</span>

                                                            </div>
                                                            <div className="col-sm-3 mt-2">

                                                                <label >Building Type <span style={{ color: 'red' }}>*</span></label>

                                                                <select className="form-select" {...register("premises_type", { onChange: handleBuildingType, required: { value: true, message: "Building Type is required" } })}>

                                                                    <option value={""} selected>Select Any</option>
                                                                    <option value="block no">Block No</option>
                                                                    <option value="shop no">Shop No</option>
                                                                    <option value="godown no">Godown No</option>
                                                                    <option value="flat no">Flat No</option>
                                                                    <option value="survey no">Survey No</option>
                                                                    <option value="factory no">Factory No</option>
                                                                </select>
                                                                <span className='text-red w-100'>{errors?.premises_type?.message}</span>

                                                            </div>

                                                            <div className="col-sm-3 mt-2">

                                                                <label >Number <span style={{ color: 'red' }}>*</span></label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Number" {...register("number", { onChange: handleValue, required: { value: true, message: "Number is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.number?.message}</span>

                                                            </div>
                                                            <div className="col-sm-3 mt-2">

                                                                <label >Bunglow Name</label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Bunglow Name"
                                                                        disabled={buildingType != "block no"}
                                                                        {...register("bunglow_name", { onChange: handleValue, required: { value: false, message: "Bunglow Name is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.bunglow_name?.message}</span>

                                                            </div>


                                                            <div className="col-sm-3 mt-2">

                                                                <label >Building Name </label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Building Name" {...register("building_name", { onChange: handleValue, required: { value: false, message: "Building Name is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.building_name?.message}</span>

                                                            </div>


                                                            <div className="col-sm-3 mt-2">

                                                                <label >Society Name</label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control"
                                                                        disabled={buildingType != "block no"}
                                                                        placeholder="Society Name" {...register("society_name", { onChange: handleValue, required: { value: false, message: "Society Name is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.society_name?.message}</span>

                                                            </div>


                                                            <div className="col-sm-3 mt-2">

                                                                <label >Street Address </label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Street Address" {...register("street", { onChange: handleValue, required: { value: false, message: "Street Address is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.street?.message}</span>

                                                            </div>


                                                            <div className="col-sm-3 mt-2">

                                                                <label >Landmark </label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Landmark" {...register("landmark", { onChange: handleValue, required: { value: false, message: "Landmark is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.landmark?.message}</span>

                                                            </div>

                                                            <div className="col-sm-3 mt-2">

                                                                <label >Area </label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Area" {...register("area", { onChange: handleValue, required: { value: false, message: "Area is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.area?.message}</span>

                                                            </div>


                                                            <div className="col-sm-3 mt-2">

                                                                <label >City <span style={{ color: 'red' }}>*</span></label>
                                                                <Select
                                                                    isClearable={() => {
                                                                        setValueCity(null)
                                                                        setValue(null)
                                                                    }}
                                                                    {...register("city", { required: { value: true, message: "City Name is required" } })}
                                                                    placeholder={'Select City Name'}
                                                                    options={optionsCity}
                                                                    // onCreateOption={handleCreateCity}s
                                                                    onChange={handleChangeCity}
                                                                    value={valueCityCreatable == null ? valueCity : valueCityCreatable}
                                                                    components={customIndicator}

                                                                />
                                                                <span className='text-red w-100'>{errors?.city?.message}</span>

                                                            </div>


                                                            {/* <div className="col-sm-3 mt-2">

<label htmlFor="exampleInputEmail1">Country Code <span style={{ color: 'red' }}>*</span>  </label>
<div className="input-group" >

    <input type={"text"} isPencil={true} placeholder={"Country Code"} className="form-control"   {...register("country_code", { required: { value: false, message: "Country Code is required" } })} color={"green"} />
    <div className="input-group-append">
        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
    </div>
</div>
<span className='text-red'>{errors?.country_code?.message}</span>

</div> */}


                                                            <div className="col-sm-3 mt-2">

                                                                <label >Mobile Number <span style={{ color: 'red' }}>*</span></label>
                                                                <div className="input-group">
                                                                    <input type="number" className="form-control" placeholder="Mobile Number"
                                                                        //  maxLength={15}
                                                                        //  minLength={10}
                                                                        //  onInput={ maxLengthCheck}
                                                                        {...register('mobile_number', {
                                                                            required: "Mobile Number is required",

                                                                            pattern: {
                                                                                value: /^(\+\d{1,3}[- ]?)?\d{10}$/g,
                                                                                message: 'Please enter a valid number',
                                                                            },
                                                                        })}
                                                                    />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.mobile_number?.message}</span>

                                                            </div>

                                                            {/* <div className="col-sm-3 mt-2">

                                                    <label >Taluka</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" 
                                                        value={valueTaluka}
                                                        placeholder="Taluka" 
                                                        // {...register("three", { required: { value: true, message: "Field is required" } })}
                                                         />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>

                                                </div> */}

                                                            {/* <div className="col-sm-3 mt-2">

                                                    <label >District</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control"
                                                        value={valueDistrict}
                                                        placeholder="District" 
                                                         />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>

                                                </div> */}

                                                            {/* <div className="col-sm-3 mt-2">

                                                    <label >State</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control"
                                                        value={valueState}
                                                        placeholder="State" 
                                                         />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>

                                                </div> */}

                                                            {/* <div className="col-sm-3 mt-2">

                                                    <label >Country</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" 
                                                        value={valueCountry}
                                                        placeholder="Country" 
                                                         />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>

                                                </div> */}





                                                            {/* <div className="col-sm-3 mt-2">

<label >Pincode <span style={{ color: 'red' }}>*</span></label>
<Select 
        {...register("pincode",{required: { value: false, message: "Pincode is required" }})}
        options={cityPincodeOptions}
        onChange={handleChangePincode}
        value={forCityPincodeValue}
        isDisabled={!forPincode}
        isOptionDisabled={(options:any)=>
            {
                if(options.status === "inactive" || options.deleted == true){
                return options
                }
               }}
       

    />
<span className='text-red w-100'>{errors?.pincode?.message}</span>

</div> */}



                                                            <div className="col-sm-3 mt-2">

                                                                <label >Re-Mark</label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control"
                                                                        placeholder="Re-Mark"
                                                                        {...register("remark", { required: { value: false, message: "Field is required" } })}
                                                                    />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.remark?.message}</span>

                                                            </div>







                                                            <div className="col-sm-3 mt-2">
                                                                <label >Status <span style={{ color: 'red' }}>*</span></label>
                                                                <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>

                                                                    <option value={""} selected>Select Any</option>
                                                                    <option value="active">Active</option>
                                                                    <option value="inactive">Inactive</option>
                                                                </select>
                                                                <span className='text-red w-100'>{errors?.status?.message}</span>

                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row d-flex  ">
                                                    <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                                        <div className="row d-flex ">
                                                            <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                                <button
                                                                    type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                                    style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                                    onClick={handleSubmit(onSubmit)}
                                                                >Submit</button>
                                                                <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>
                                                                {/* <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetail")}>Cancel</button> */}

                                                                <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => setCurrentStep(currentStep - 1)}>Back</button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>


                                </Offcanvas.Body>
                            </Offcanvas>





                            <Stepper_Table
                                apiname={apiname}
                                filterFields={filter}
                                addButtonName={addButtonName}
                                stickyStart={stickyStart}
                                stickyEnd={stickyEnd}
                                TableColumns={Columns_Address_Details}
                                addButtonPageName={addButtonPageName}
                                titleofheaders={titleofheaders}
                                permissionClass={permissionClass}
                                isMore={false}
                                isAddButton={false}
                                // handleMultipleData={multipleData}
                                multipleIds={multipleIds}
                                added={added}
                                setAdded={setAdded}
                                setShowOffcancas={setShowOffcancas}
                                setMultipleIds={setMultipleIds}
                                setIsNew={setIsNew}
                                activePage={activePage}
                            />










                            <div className="row d-flex  ">
                                <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                    <div className="row d-flex ">
                                        <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center " style={{display:'flex',justifyContent:'space-between'}}>
                                            <div>
                                                {currentPath === '/addCustomerDetails' ?
                                                    <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "#fd7e14", background: "white", color: "#fd7e14" }} onClick={() => setCurrentStep(currentStep + 1)}>Skip</button>
                                                    : null}
                                                {/* <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button> */}

                                                {currentPath === '/addCustomerDetails' ?
                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => setCurrentStep(currentStep - 1)}>Back</button>
                                                    : null}
                                            </div>
                                            <div>
                                                <button
                                                    type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                    style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                    onClick={handleNext}
                                                >Submit</button>
                                                <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetail")}>Cancel</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>






                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Add_Address_Details