
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../../components/Cell/Status";
import Actions from "../../../components/Cell/Actions";
// import { updateBrand, useFetchBrands } from "../../hooks/brand";
// import noImage from "../../images/noImage.png"
import { useFetchGst, useUpdateGst } from "../../../hooks/GST";
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import noImage from '../../../images/noImage.png'
import Axiosinstance from "../../../hooks/Interceptor";

interface Data {
  selection: any
  code:any
  company_name: any
  product_type: any
  product_description: any
  product_qty: any
  uom: any
  requirement_date: any
  mobile_number: any
  email_address: any
  village_code_and_name: any
  visiting_card_image: any

  employee: any



  status: any
  assignTo: any

  created_at: any
  created_by: any
  updated_by: any
  updated_at: any
  //   status: any;
  actions: any;
}


export const titleofheaders = [
  {
    id: `srno`,
    Header: "Sr. No"
  },


  {
    id: `code`,
    Header: "Code"
  },


  {
    id: `date`,
    Header: "Date"
  },




  {
    id: `company_name`,
    Header: "Company Name",
  },

  {
    id: `products`,
    Header: "Products",
  },

  {
    id: `requirement_date`,
    Header: "Requirement Date",
  },

  {
    id: `country_code`,
    Header: "Country Code",
  },

  {
    id: `mobile_number`,
    Header: "Mobile Number",
  },
  {
    id: `email_address`,
    Header: "Email Address",


  },


  {
    id: `city_name_and_distict_name`,
    Header: "City & District Name",
  },

  {
    id: `remark`,
    Header: "Re-Mark",

  },



  {
    id: 'status',
    Header: 'Status',


  },

  {
    id: `taluka_name`,
    Header: "Taluka Name",

  },


  {
    id: `country_name`,
    Header: "Country Name",

  },

  {
    id: `pincode`,
    Header: "Pin Code Number",

  },

  {
    id: `visiting_card_image`,
    Header: "Visiting Card Image",


  },

]




export const Columns_Online_Inquiry: any = [




  {
    id: `srno`,
    Header: () => <div style={{ display: "inline" }}>Sr.<br />No</div>,
    sticky: "left",
  },


  {
    id: `code`,
    Header: () => <div style={{ display: "inline" }}>Online Inquiry<br />Code</div>,
    accessor:"code",
    sticky: "left",
  },


  {
    id: `date`,
    Header: () => <div style={{ display: "inline" }}>Date</div>,
    Cell: ({ row }: any) => (row.original.created_at ? row.original.created_at?.split(" ")[0] : "-")
  },




  {
    id: `company_name`,
    Header: () => <div style={{ display: "inline" }}>Company<br />Name</div>,
    accessor: 'company_name',

  },

  {
    id: `products`,
    Header: () => <div style={{ display: "inline" }}>Products</div>,
    accessor: 'products',
    Cell: ({ row }: any) => {
      const [openProducts, setOpenProducts] = useState(false)
      // const ids = row.original.product?.map((e:any)=>{
      //   return e.id
      // })
      // const handleViewProducts=()=>{
      //   Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}//check_usages/${ids}/` )
      // }
      return (<>

        <div>
          <button
            type="button" className="border-1 rounded text-white px-4 py-1 mx-3"
            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

            onClick={() => setOpenProducts(true)}
          >Products</button>
        </div>
        <Modal size="xl" show={openProducts} onHide={() => setOpenProducts(false)} aria-labelledby="contained-modal-title-vcenter"
          centered    >
          <Modal.Header >
            <Modal.Title>
              Products
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="table-responsive"  >



              <table className="table table-striped table-bordered" style={{ marginTop: "3px", width: "-webkit-fill-available" }}>
                <thead style={{ textAlign: "center" }}>
                  <tr>
                    <th >Sr. <br /> No</th>
                    <th>Product <br />Type</th>


                    <th>Product<br /> Description</th>
                    <th>Product <br />Qty</th>
                    <th>UOM</th>


                    <th>Re-Mark</th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: 'center' }}>
                  {row.original.product?.map((field: any, index: any) => (
                    <tr key={index} >
                      <td>{index + 1}</td>


                      <td>

                        {field.product_type?.type}

                      </td>



                      <td>


                        {field.product_description}

                      </td>

                      <td>

                        {field.product_qty}

                      </td>

                      <td>


                        {field.uom?.name?.name ? field.uom?.name?.name : "-"}

                      </td>
                      <td>


                        {field.remark ? field.remark : '-'}

                      </td>



                    </tr>
                  ))}
                </tbody>
              </table>


            </div>


          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={() => setOpenProducts(false)}>Close</button>

          </Modal.Footer>
        </Modal>
      </>
      )
    }

  },

  {
    id: `requirement_date`,
    Header: () => <div style={{ display: "inline" }}>Requirement<br />Date</div>,
    accessor: 'requirement_date',
    Cell: ({ row }: any) => (row.original.requirement_date ? row.original.requirement_date?.split(".")[0].split("T").join("  ") : "-")
  },

  {
    id: `country_code`,
    Header: () => <div style={{ display: "inline" }}>Country<br />Code</div>,
    accessor: 'country_code',
    Cell: ({ row }: any) => (row.original.city_name_and_distict_name?.country?.country_code_mobile)
  },

  {
    id: `mobile_number`,
    Header: () => <div style={{ display: "inline" }}>Mobile<br />Number</div>,
    accessor: 'mobile_number',
  },
  {
    id: `email_address`,
    Header: () => <div style={{ display: "inline" }}>Email<br />Address</div>,
    accessor: 'email_address',

    Cell: ({ row }: any) => row.original.email_address ? row.original.email_address : <span style={{ color: 'red', fontSize: "20px" }}>****</span>,

  },


  {
    id: `city_name_and_distict_name`,
    Header: () => <div style={{ display: "inline" }}>City Name &<br />District Name</div>,
    accessor: 'city_name_and_distict_name',
    Cell: ({ row }: any) => {
      return (
        <div>
          {`${row.original.city_name_and_distict_name?.city_name ? row.original.city_name_and_distict_name?.city_name : row.original.city_name_and_distict_name?.village_name}::${row.original.city_name_and_distict_name?.district?.name}`}
        </div>
      )
    }
  },

  {
    id: `remark`,
    Header: () => <div style={{ display: "inline" }}>Re-Mark</div>,
    accessor: 'remark',
    Cell: ({ row }: any) => row.original.remark ? row.original.remark : <span style={{ color: 'red', fontSize: "20px" }}>****</span>,

  },

  // {
  //   id: `product_type`,
  //   Header: () => <div style={{display:"inline"}}>Product<br/>Type</div>,
  //   accessor:'product_type',
  //   Cell: ({ row }: any) => {
  //       return(
  //         <div>
  //           {row.original.product_type?.type}
  //         </div>
  //       )
  // }
  // },
  // {
  //   id: `product_description`,
  //   Header: () => <div style={{display:"inline"}}>Product<br/>Description</div>,
  //   accessor:'product_description',
  // },
  // {
  //   id: `product_qty`,
  //   Header: () => <div style={{display:"inline"}}>Product<br/>Qty</div>,
  //   accessor:'product_qty',
  // },
  // {
  //   id: `uom`,
  //   Header: `Uom`,
  //   accessor:'uom',
  //   Cell: ({ row }: any) => {
  //       return(
  //         <div>
  //           {row.original.uom?.name?.name}
  //         </div>
  //       )
  // }
  // },



  //   {
  //     id: `village_code_and_name`,
  //     Header: () => <div style={{display:"inline"}}>Village Code &<br/>Name</div>,
  //     accessor:'village_code_and_name',
  //     Cell: ({ row }: any) => {
  //         return(
  //           <div>
  //             {row.original.village_code_and_name?.city_name}
  //           </div>
  //         )
  //   }  
  // },

  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',


  },

  {
    id: `taluka_name`,
    Header: () => <div style={{ display: "inline" }}>Taluka<br />Name</div>,
    accessor: 'taluka_name',
    Cell: ({ row }: any) => row.original.city_name_and_distict_name?.taluka?.name ? row.original.city_name_and_distict_name?.taluka?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>,

  },


  {
    id: `country_name`,
    Header: () => <div style={{ display: "inline" }}>Country<br />Name</div>,
    accessor: 'country_name',
    Cell: ({ row }: any) => row.original.city_name_and_distict_name?.country?.name ? row.original.city_name_and_distict_name?.country?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>,

  },

  {
    id: `pincode`,
    Header: () => <div style={{ display: "inline" }}>Pin Code<br />Number</div>,
    accessor: 'pincode',
    Cell: ({ row }: any) => {
      let pincode = row.original?.city_name_and_distict_name?.pincode?.map((e: any) => {
        return e?.pincode
      })
      return (
        <div>
          {pincode?.join(",")}
        </div>
      )
    }
    // Cell: ({row}:any)=> row.original.city_name_and_distict_name?.country?.name?row.original.city_name_and_distict_name?.country?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>,

  },

  {
    id: `visiting_card_image`,
    Header: () => <div style={{ display: "inline" }}>Visiting<br />Card Image</div>,
    accessor: 'visiting_card_image',
    Cell: ({ row }: any) => {

      const [deleteConfirm, setDeleteConfirm] = useState(false)
      return (
        <>

          <div >

            {!row.original.visiting_card_image ? <>
              <a

                href="#"

              // data-large-src={row.original.logo?row.original.logo:noImage} data-toggle="modal"
              >
                <img src={noImage} height={60} width={100} />
              </a>
            </> : <>
              <a
                onClick={() => setDeleteConfirm(true)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={row.original.visiting_card_image ? row.original.visiting_card_image : noImage} data-toggle="modal">
                <img src={row.original.visiting_card_image ? row.original.visiting_card_image : noImage} height={60} width={100} />
              </a>
            </>}

          </div>



          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >
            <Modal.Header >
              <Modal.Title>

              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <img src={row.original.visiting_card_image ? row.original.visiting_card_image : noImage} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid" />

            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>)
    }


  },

  //   {
  //     id: 'assign_to',
  //     Header: 'Recently Assigned',
  //     accessor:'assign_to',
  //     Cell: ({ row }: any) => {
  //       return(
  //         <div>
  //           {row.original.assign_to?.name?row.original.assign_to?.name:<span style={{color:"red"}}>xxxx</span>}
  //         </div>
  //       )
  // }  

  //   },

  // {
  //   id: 'assignTo',
  //   Header: 'Assign To',
  //   accessor:'assignTo',


  // },





  //               {
  //     id: 'Actions',
  //     Header: 'Actions',


  // },




  // {
  //     id: 'created_at',
  //     Header: () => <div style={{display:"inline"}}>Created<br/>At</div>,
  //     accessor:'created_at',
  //     Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  //   },


  //   {
  //     id: 'created_by',
  //     Header: () => <div style={{display:"inline"}}>Created<br/>By</div>,
  //     accessor:'created_by',
  //     Cell: ({ row }: any) => row.original.created_by?.username


  //   },
  //   {
  //     id: 'updated_at',
  //     Header: () => <div style={{display:"inline"}}>Updated<br/>At</div>,
  //     accessor:'updated_at',
  //     Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  //   },
  //   {
  //     id: 'updated_by',
  //     Header: () => <div style={{display:"inline"}}>Updated<br/>By</div>,
  //     accessor:'updated_by',
  //     Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  //   },









  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_Online_Inquiry, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows } = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SALES_ONLINE_INQUIRY_API}/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



