import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { TiTickOutline } from "react-icons/ti";

import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Restricted from "../../Roles/Restricted";
const Discount_Actions = ({ row, apiname, deletemodalask,permissionClass }: any) => {
  return (
    <>
    <div
        className="vertical-align-center"
        style={{
          display: "flex !important",
          justifyContent: "space-evenly !important",
          
        }}
      >
      
        <Restricted
          to={`${permissionClass?.front}.change_${permissionClass?.back}`}
          fallback={""}
        >
        <button
          onClick={() => {
            deletemodalask(row.original.id);
          }}
          className="btn btn-primary text-white mx-2"
        >
          <RiDeleteBinLine />
        </button>
        </Restricted>

        <Restricted
          to={`${permissionClass?.front}.delete_${permissionClass?.back}`}
          fallback={""}
        >
        <button
          // onClick={() => {
          //   deletemodalask(row.original.id);
          // }}
          className="btn btn-primary text-white"
        >
          <TiTickOutline />
        </button>
        </Restricted>
      </div>
    </>
  );
};

export default Discount_Actions;

