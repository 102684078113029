import { useTable } from "react-table"
import { Checkbox } from "../../../components/inputcomponent/Checkbox"
import { useMemo, useState } from "react";
import Columnlevel from "../../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Tooltip } from "@mui/material";

interface Data {
  selection: any
  code:any
  sheet:any
  material_garde:any
  sheet_color:any
  opretar:any
  helper:any
  shift_time:any
  machine:any
  raw_material_betch_total_kg:any

  // task_name: any



  created_at: any
  created_by: any
  updated_by: any
  updated_at: any

  status: any;
  // actions: any;
}


// export const createDayColumns = () => {
//   const columns = [];

//   for (let day = 1; day <= 31; day++) {
//     columns.push({
//       id: `point_close_time_${day}`,
//       Header: `${day}`,
//       accessor: `point_close_time_${day}`,
//       Cell: ({ row }:any) => row.original[`point_close_time_${day}`]
//     });
//   }

//   return columns;
// };


export const titleofheaders = [
  {
    id: `srno`,
    Header: `Sr No`,
  },

  {
    id: `code`,
    Header: "Batch No.",
  },

  {
    id: `sheet`,
    Header: "Sheet Code & Name",
  },
  {
    id: `material_garde`,
    Header: "Material Grade",
  },
  {
    id: `sheet_color`,
    Header: "Sheet Color",
  },
  {
    id: `opretar`,
    Header: "Opretar Code & Name",
  },
  {
    id: `raw_material_betch_total_kg`,
    Header: "Raw Material Total Kg.",
  },
  // {
  //   id: 'status',
  //   Header: 'Status',


  // },

  {
    id: 'created_at',
    Header: `Created At`,
  },


  {
    id: 'created_by',
    Header: `Created By`,


  },
  // {
  //   id: 'updated_at',
  //   Header: `Updated At`,

  // },
  // {
  //   id: 'updated_by',
  //   Header: `Updated By`,

  // },





]


for (let date = 1; date <= 31; date++) {
  const formattedDate = String(date).padStart(2, '0');
  titleofheaders.push({
    id: `${formattedDate}`,
    Header: `${date}`,
  })
}



let a: any = [
  {
    id: `srno`,
    Header: () => <div style={{ display: "inline" }}>Sr<br />No</div>,
    sticky: "left",

  },

  {
    id: `code`,
    Header: () => <div style={{ display: "inline" }}>Batch<br />No.</div>,
    accessor: `code`,
    sticky: "left",

  },

  {
    id: `sheet`,
    Header: () => <div style={{ display: "inline" }}>Sheet Code & Grade &<br /> Name & Type & Color</div>,
    accessor: `sheet`,
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.sheet?.code} {row.original.extra_response?.sheet?.color}
        </div>
      )
    }

  },
  {
    id: `material_garde`,
    Header: () => <div style={{ display: "inline" }}>Material<br />Grade</div>,
    accessor: `material_garde`,
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.particular_name?.name ? row.original.particular_name?.name : <span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: `sheet_color`,
    Header: () => <div style={{ display: "inline" }}>Sheet<br />Color</div>,
    accessor: `sheet_color`,
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.sheet?.color}
        </div>
      )
    }

  },

  {
    id: `opretar`,
    Header: () => <div style={{ display: "inline" }}>Opretar<br />Code & Name </div>,
    accessor: 'opretar',
    Cell: ({ row }: any) => {
      let oprate = `${row.original?.extra_response?.opretar ?`${row.original?.extra_response?.opretar?.code}::${row.original?.extra_response?.opretar?.name}::
      ${row.original?.extra_response?.opretar?.father_name[0]}::
      ${row.original?.extra_response?.opretar?.surname}`: ''}`
      return (
        <Tooltip title={<p style={{ fontSize: '15px', color: 'white' }}>{oprate}</p>} placement="top" arrow >
          <div>
            {row.original?.extra_response?.opretar && oprate?.slice(0, 14).concat('...')}
          </div>
        </Tooltip>
      )
    }
  },

  {
    id: `helper`,
    Header: () => <div style={{ display: "inline" }}> Helper<br />Code & Name</div>,
    accessor: 'helper',
    Cell: ({ row }: any) => {
      let helper = row.original?.extra_response?.helper?.map((e: any) => {
        return `${e?.code}::${e?.name}::${e?.father_name[0]}::${e?.surname}`
      })
      return (
        <Tooltip title={<p style={{ fontSize: '15px', color: 'white' }}>{helper?.join(" ,")}</p>} placement="top" arrow >
          <div>
            {helper?.join(",")?.slice(0, 14).concat('...')}
          </div>
        </Tooltip>
      )
    }

  },
  {
    id: `shift_time`,
    Header: () => <div style={{ display: "inline" }}>Shift<br />Day </div>,
    accessor: 'shift_time',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original?.shift_time}


        </div>
      )
    }


  },
  {
    id: `machine`,
    Header: () => <div style={{ display: "inline" }}>Machine Code<br />& Name</div>,
    accessor: `machine`,
    Cell: ({ row }: any) => {
      let machine = `${row.original.extra_response?.machine ?`${row.original.extra_response?.machine?.machine_code}::${row.original.extra_response?.machine?.particular_sector_name}`:''}`
      return (
        <Tooltip title={<p style={{ fontSize: '15px', color: 'white' }}>{machine}</p>} placement="top" arrow >
          <div>
            {row.original.extra_response?.machine && machine?.slice(0, 14).concat('...')}
          </div>
        </Tooltip>
      )
    }

  },



]

let storedData = localStorage.getItem('dataItem');
let parsedData = storedData ? JSON?.parse(storedData) : [];
let b: any = []
let c: any = []

if (parsedData?.length > 0) {

  parsedData?.map((row: any) => {
    row?.sheet_raw_material?.map((e: any) => {
      var grade = `${e?.extra_response?.raw_material.material_grade_code} ${e?.extra_response.raw_material?.grade} ${e?.extra_response.raw_material?.material_type_name}`
      if (!b.includes(grade)) {
        c.push({
          name: grade,
          kg: e?.kg
        })
        b.push(grade)
      }
    })
  });

}

c?.map((e: any) => {
  let temp = {
    id: `${e.name}`,
    Header: `${e.name}`,
    accessor: `machine`,
    Cell: ({ row }: any) => {
      let dataExists = false;
      let kg = row?.original.sheet_raw_material?.map((data: any) => {

        if (`${data?.extra_response.raw_material?.material_grade_code} ${data?.extra_response.raw_material?.grade} ${data?.extra_response.raw_material?.material_type_name}` === `${e?.name}`) {
          dataExists = true;
          return data?.kg
        }

      }
      )
      

      if (!dataExists) {
        return <span style={{ color: 'red', fontSize: "20px" }}>****</span>;
      } else {

        return (
          <>
            {kg}
          </>
        )
      }
    }
  }

  a?.push(temp)
})








let maxLength = 0;
let rowWithMaxLength: any;

if (parsedData.length > 0) {

  parsedData.forEach((row: any) => {
    if (row.sheet_raw_material && row.sheet_raw_material.length > maxLength) {
      maxLength = row.sheet_raw_material.length;
      rowWithMaxLength = row;
    }
  });
}


export const Columns_TFM_Transaction: any = [...a,

{
  id: `raw_material_betch_total_kg`,
  Header: () => <div style={{ display: "inline" }}>Raw Material Batch<br />Total Kg.</div>,
  accessor: 'raw_material_betch_total_kg',

},




{
  id: 'created_at',
  Header: () => <div style={{ display: "inline" }}>Created<br />At</div>,
  accessor: 'created_at',
  Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
},


{
  id: 'created_by',
  Header: () => <div style={{ display: "inline" }}>Created<br />By</div>,
  accessor: 'created_by',
  Cell: ({ row }: any) => row.original.created_by?.username


},
// {
//   id: 'updated_at',
//   Header: () => <div style={{ display: "inline" }}>Updated<br />At</div>,
//   accessor: 'updated_at',
//   Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
// },
// {
//   id: 'updated_by',
//   Header: () => <div style={{ display: "inline" }}>Updated<br />By</div>,
//   accessor: 'updated_by',
//   Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
// },



{
  id: 'duplicate_granual',
  Header: 'Duplicate',


},
{
  id: 'Selection',
  Header: ({ getToggleAllRowsSelectedProps }: any) => {
    const [data, setData] = useState([])

    const columns: any = useMemo(() => Columns_TFM_Transaction, [])
    const defaultColumn: any = useMemo(() => {
      return {
        Filter: Columnlevel
      }
    }
      , [])

    const tableInstance: any = useTable({
      columns,
      data: data,
      defaultColumn,

    },


    )
    const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
    let { selectedFlatRows } = tableInstance
    const { } = useMutation((deleteid: any) => {
      const temp_data = {
        id: deleteMultipleid
      }
      return axios.post(`${process.env.REACT_APP_BASE_URL}checklist/deletelist/`, temp_data,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
    });
    return (
      <>
        <div>
          <Checkbox

            onClick={() => {
              setDeleteMultipleid(() => {
                const temp = selectedFlatRows?.map((e: any) => {
                  return e.original.id;
                });
                return temp;
              });
            }}

            {...getToggleAllRowsSelectedProps()} />
        </div>
      </>
    );
  },

  Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
  }} />
},
]

