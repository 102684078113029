import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
// import "../../components/Header/header.css";
import { useNavigate, useParams } from "react-router-dom";
import { useGSTEdit, useUpdateGst } from "../../../hooks/GST";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import { useFetchSingle, useUpdateSingle, useCreate, useUpdateSingleforcewithyes, useUpdateSingleforcewithno } from "../../../hooks/Api";
import Select from "react-select";
import axios from "axios";
import { Alert } from "../../../components/Model/Model";
import useFocusOnEnter from "../../../components/focus";
import { useFetchEmployeeDropDown, useFetchCustomerDropDown, useFetchIFSCDropDown, useFetchBankAccountTypeDropDown, useFetchPriorityDropDown } from "../../../hooks/DropDown_Api";
import Button from "react-bootstrap/esm/Button";
import { Upload, UploadFile } from "antd";
import CreatableSelect from "react-select/creatable";
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import Modal from "react-bootstrap/Modal";
import { Deletesinglewithdependancy } from "../../../components/modal";
import Axiosinstance from "../../../hooks/Interceptor";
import Mui_Toaster from "../../../components/Mui_Toaster/Mui_Toaster";
const customIndicator = {
  IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
  IndicatorsContainer: IndicatorsContainerCreate
}

interface Option {
  readonly label: string;
  //   readonly value: string;
  readonly name: string;
}
const createOption = (label: string) => ({
  label,
  // value: label,
  name: label,
  status: "active"
});
// import { Alert } from "../Model/Model";
// import Select from 'react-select/async';

const Edit_Bank_Format = ({ currentStep, setCurrentStep }: any) => {
  let pageName = "Bank Format";
  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);
  // const { id } = useParams();




  let rawData = localStorage.getItem('Add Data');
  let addData = rawData ? JSON.parse(rawData) : null;

  const id = addData?.bank?.id
  const tempData = addData?.bank
  const data = tempData
  const prevDataRef = useRef();







  const navigate = useNavigate();
  const apiname = process.env.REACT_APP_BANK_API?.toString();
  // const { data } = useFetchSingle(id, apiname);
  const {
    mutate,
    isSuccess,
    isError,
    error,
    data: gstUpdated_response,
  }: any = useUpdateSingle();

  const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
  const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()



  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
    setValue,
  }: any = useForm();






  useEffect(() => {
    const isDataChanged = JSON.stringify(data) !== JSON.stringify(prevDataRef.current);
    if (data && isDataChanged) {
      reset(data);
      prevDataRef.current = data;
    }
  }, [data, reset]);




  const [PersonType, setPersonType]: any = useState("");

  const onPersonTypeChange = (e: any) => {
    setPersonType(e.target.value)
  }
  const [employeeOptions, setEmployeeOptions]: any = useState()
  const [forEmployeeValue, setForEmployeeValue]: any = useState(0)
  const [selEmployee, setSelEmployee]: any = useState("")
  const [selectedEmployee, setSelectedEmployee]: any = useState("")


  const { data: employeeData, refetch: employeeRefetch } = useFetchEmployeeDropDown()


  useEffect(() => {
    setEmployeeOptions(employeeData)
  }, [employeeData])

  const handleChangeEmployee = (e: any) => {

    setForEmployeeValue(e)
    setSelEmployee(e)
    setValue("employee", e.value)

  }

  const [options, setOptions]: any = useState([])
  const [forCustomerValue, setCustomerValue]: any = useState(0)
  const [selCustomer, setSelCustomer]: any = useState("")
  const [selectedCustomer, setSelectedCustomer]: any = useState("")
  const { data: customerData, refetch: customerRefetch } = useFetchCustomerDropDown()


  useEffect(() => {
    setOptions(customerData)
  }, [customerData])

  const handleChange = (e: any) => {

    setCustomerValue(e)
    setSelCustomer(e)
    setValue("customer", e?.value)

  }

  const [IFSCid, setIFSCid]: any = useState()
  const [selectedIFSC, setSelectedIFSC]: any = useState()
  const [check, setCheck] = useState("")



  const apinamePriority = process.env.REACT_APP_BANK_PRIORITY_TYPE_API?.toString()
  const { mutate: mutatePriority, data: dataPriority, isError: isErrorPriority, isLoading: isLoadingPriority, isSuccess: isSuccessPriority, error: errorPriority }: any = useCreate(apinamePriority)
  const [optionsPriority, setOptionsPriority]: any = useState([]);
  const [valuePriority, setValuePriority]: any = useState();
  const [selectedPriority, setSelectedPriority]: any = useState('');
  const [valuePriorityCreatable, setValuePriorityCreatable] = useState<Option | null>();
  const { data: PriorityData, refetch: PriorityRefetch } = useFetchPriorityDropDown()
  useEffect(() => {
    setOptionsPriority(PriorityData)
  }, [PriorityData])

  const handleCreatePriority = async (inputValue: string) => {
    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setTimeout(() => {
      const newOption = createOption(inputValue);
      // setValue("name", `${newOption?.value}`)
      mutatePriority({ name: newOption.name, status: newOption.status })
      setOptionsPriority((prev: any) => prev ? [...prev, newOption] : [newOption]);
      setValuePriorityCreatable(newOption);
    }, 0);

  };

  const handleChangePriority = (e: any) => {

    PriorityRefetch()
    // setApiName(apinamePriority)
    // setDeleteApiName(apinamePriority)
    // setEditId(e?.value)
    // setEditData(PriorityData)
    // setRenderKey(prevKey => prevKey + 1);
    setValuePriority(e)
    setValue("priority", `${e?.value}`)
    setValuePriorityCreatable(null)
  }

  useEffect(() => {
    (async () => {
      if (isSuccessPriority && optionsPriority && valuePriorityCreatable != null) {
        await PriorityRefetch();
        setValue("priority", PriorityData[0]?.value)
      }
    })();
    return () => {
    };
  }, [isSuccessPriority, PriorityData]);


  const apinameAccountType = process.env.REACT_APP_BANK_ACCOUNT_TYPE_API?.toString()
  const { mutate: mutateAccountType, data: dataAccountType, isError: isErrorAccountType, isLoading: isLoadingAccountType, isSuccess: isSuccessAccountType, error: errorAccountType }: any = useCreate(apinameAccountType)
  const [optionsAccountType, setOptionsAccountType]: any = useState([]);
  const [valueAccountType, setValueAccountType]: any = useState();
  const [selectedAccountType, setSelectedAccountType]: any = useState('');
  const [valueAccountTypeCreatable, setValueAccountTypeCreatable] = useState<Option | null>();
  const { data: AccountTypeData, refetch: AccountTypeRefetch } = useFetchBankAccountTypeDropDown()
  useEffect(() => {
    setOptionsAccountType(AccountTypeData)
  }, [AccountTypeData])

  const handleCreateAccountType = async (inputValue: string) => {
    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setTimeout(() => {
      const newOption = createOption(inputValue);
      // setValue("name", `${newOption?.value}`)
      mutateAccountType({ name: newOption.name, status: newOption.status })
      setOptionsAccountType((prev: any) => prev ? [...prev, newOption] : [newOption]);
      setValueAccountTypeCreatable(newOption);
    }, 0);

  };

  const handleChangeAccountType = (e: any) => {

    AccountTypeRefetch()
    // setApiName(apinameAccountType)
    // setDeleteApiName(apinameAccountType)
    // setEditId(e?.value)
    // setEditData(AccountTypeData)
    // setRenderKey(prevKey => prevKey + 1);
    setValueAccountType(e)
    setValue("account_type", `${e?.value}`)
    setValueAccountTypeCreatable(null)
  }

  useEffect(() => {
    (async () => {
      if (isSuccessAccountType && optionsAccountType && valueAccountTypeCreatable != null) {
        await AccountTypeRefetch();
        setValue("account_type", AccountTypeData[0]?.value)
      }
    })();
    return () => {
    };
  }, [isSuccessAccountType, AccountTypeData]);




  // useEffect(() => {
  //   if (data) {

  //     reset(data);
  //     setPersonType(data?.type)
  //     setBankName(data.ifsc_code?.BANK)
  //     setCityName(data.ifsc_code?.CITY)
  //     setBranchName(data.ifsc_code?.BRANCH)
  //     setDistrict(data.ifsc_code?.DISTRICT)
  //     setState(data.ifsc_code?.STATE)
  //     setCountry(data.ifsc_code?.COUNTRY)
  //     setMicr(data.ifsc_code?.MICR)
  //     setPincode(data.ifsc_code?.PINCODE)
  //     setSelectedEmployee(data.employee?.id)
  //     setValue("employee", data.employee?.id)
  //     setSelectedCustomer(data.customer?.id)
  //     setValue("customer", data.customer?.id)
  //     setSelectedPriority(data?.priority?.id)
  //     setValue('priority', data?.priority?.id)
  //     setSelectedAccountType(data?.account_type?.id)
  //     setValue('account_type', data?.account_type?.id)
  //     if (check == "") {

  //       setSelectedIFSC(data?.ifsc_code?.IFSC)
  //       setIFSCid(data?.ifsc_code?.id)
  //     }
  //     let tempImages = data?.image?.map((img: any, index: number) => {
  //       let ctx: UploadFile = {
  //         uid: `${img?.id}`,
  //         name: `${img.title}`,
  //         status: 'done',
  //         url: img?.doc,
  //         thumbUrl: img?.doc,
  //       }

  //       return ctx
  //     })
  //     setUploadFile(tempImages)
  //     setValue("ifsc_code", data?.ifsc_code?.IFSC)

  //     if (data?.ifsc_code?.IFSC) {
  //       setVerify(true)
  //       setChangeIFSC(true)
  //     }

  //     // setSelectedIFSC(data?.ifsc_code?.IFSC)
  //     // setSelectedIFSC(data?.ifsc_code?.id)
  //   }






  // }, []);

  const onSubmit = (bankDetails: any) => {

    bankDetails.ifsc_code = IFSCid


    if (PersonType != "Customer") {
      delete bankDetails.customer
    }
    if (PersonType != "Employee") {
      delete bankDetails.employee
    }
    const formData = new FormData();
    for (const [key, value] of Object.entries(bankDetails)) {
      const formvalue: any = value;

      if (key === "image") {
        imageID.map((old: any) => {
          formData.append('cheque_image', old?.id)
        })
        for (let i = 0; i < logo?.length; i++) {
          formData.append('image', logo[i])
        }
      }
      if (typeof value !== "object") {
        if (value != undefined) {
          formData.append(key, formvalue)
        }
      }

    }
    let data = {
      apiname: apiname,
      id: id,
      data: formData,
    };
    mutate(data);
    setValuesofform(data)

  };

  const [show, setShow] = useState(false)
  const [showmessage, setshowmessage] = useState("")

  const [openEditDateModel, setOpenEditDateModel] = useState(false)
  const [confirmmodal, setConfirmmodal] = useState(false)
  const [valuesofform, setValuesofform] = useState<any>()
  const [expirationDate, setExpirationDate] = useState()

  const apinameForBasicDetails = process.env.REACT_APP_EMPLOYEE_API?.toString()
  const { mutate: BasicDetails, isSuccess: basicDetailsIsSuccess, isError: basicDetailsIsError, error: basicDetailsError, data: basicDetailsData }: any = useUpdateSingle()

  let rawDataTemp = localStorage.getItem('Add Data');
  let addDataTemp = rawDataTemp ? JSON.parse(rawDataTemp) : null;

  let basicIdTemp = addDataTemp ? addDataTemp.id : null;

  const [basicDetailsInitiated, setBasicDetailsInitiated] = useState(false)

  useEffect(() => {
    if ((isSuccess || yessuccess || nosuccess) && !basicDetailsInitiated) {
      setShow(true);
      setshowmessage("success");


      let tempId = {
        bank: data?.data?.id
      }

      let tempData = {
        id: basicIdTemp,
        apiname: apinameForBasicDetails,
        data: tempId
      }
      BasicDetails(tempData);
      setBasicDetailsInitiated(true);
      //   navigate("/listCity", { state: { showEditToast: true } });
      //  const currentPath = window.location.pathname;
      // if(currentPath === '/addCustomerDetails'){
      // setCurrentStep(currentStep + 1)
      // }
    } else if (isError) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [data, isSuccess, yessuccess, nosuccess, gstUpdated_response, error,
    isSuccess,]);

  const [updateCompleted, setUpdateCompleted] = useState(false)

  useEffect(() => {
    if (basicDetailsIsSuccess && basicDetailsData && !updateCompleted) {

      localStorage.setItem('Add Data', JSON.stringify(basicDetailsData?.data))
      setUpdateCompleted(true);
      const currentPath = window.location.pathname;
      if (currentPath === '/addCustomerDetails') {
        setCurrentStep(currentStep + 1)
      };
    }
  }, [basicDetailsIsSuccess, basicDetailsData])

  useEffect(() => {
    if (isError) {
      if (error.response?.status === 405) {
        setConfirmmodal(true);
      }
    } else if (isError || yeserror || noerror) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [isError, yeserror, noerror]);
  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, []);
  const yesupdatemodal = () => {
    setConfirmmodal(false);
    mutateyes(valuesofform)
  }
  const cancelupdatemodal = () => {
    setConfirmmodal(false);
  }
  const noupdatemodal = () => {
    setOpenEditDateModel(true)
  }


  const handleExpirationDate = (e: any) => {
    setExpirationDate(e.target.value)
    setValue("expiration_date", e.target.value)
  }

  const handleConfirm = (data: any) => {

    delete data.created_at
    delete data.created_by
    let ok = {
      id: id,
      apiname: apiname,
      data: data,
    }

    mutateno(ok)
    setConfirmmodal(false);
    setOpenEditDateModel(false)
  }
  const handleValue = (e: any) => {
    e.target.value = e.target.value.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }

  const handleValueUppercase = (e: any) => {
    e.target.value = e.target.value.toLowerCase()
      .split(' ')
      .map((s: any) => s.toUpperCase())
      .join(' ');
  }

  const apiIFSC = process.env.REACT_APP_IFSC_API?.toString()
  // const  { mutate: IFSC, data:dataIFSC, isError:isErrorIFSC, isLoading:isLoadingIFSC, isSuccess:isSuccessIFSC, error:errorIFSC }:any = useCreate(apiIFSC)
  const [ifscValue, setIfscValue] = useState("")
  const [changeIFSC, setChangeIFSC] = useState(false)
  const [verify, setVerify] = useState(false)
  const [ifscError, setIFSCError] = useState(false)
  const searchTerm = verify ? ifscValue : null;
  const { data: ifscData, refetch: ifscRefetch, isLoading: ifscIsLoading, isError: ifscIsError }: any = useFetchIFSCDropDown(searchTerm)

  const handleChangeIFSC = () => {
    // ifscIsSuccess(false)
    setChangeIFSC(false)
    setVerify(false)
  }

  const handleIFSC = (e: any) => {
    setVerify(false)
    setIfscValue(e.target.value)
    setIFSCError(false)
    // setSelIFSC(e.target.value)
    setCheck(e.target.value)

    // if(check != ""){
    setSelectedIFSC(e.target.value)
    // }

  }

  const onSubmitIFSC = (e: any) => {

    setVerify(true)
    setChangeIFSC(true)

  }

  useEffect(() => {
    if (ifscIsError) {
      setIFSCError(true)
    }
  }, [ifscIsError, ifscError])

  const [BankName, setBankName]: any = useState()
  const [CityName, setCityName]: any = useState()
  const [BranchName, setBranchName]: any = useState()
  const [District, setDistrict]: any = useState()
  const [State, setState]: any = useState()
  const [Country, setCountry]: any = useState()
  const [Micr, setMicr]: any = useState()
  const [Pincode, setPincode]: any = useState()








  useEffect(() => {
    if (ifscData) {
      if (check != "") {
        // setValue("ifsc_code",ifscData?.id)
        setIFSCid(ifscData?.id)
      }
      setBankName(ifscData?.BANK)
      setCityName(ifscData?.CITY)
      setBranchName(ifscData?.BRANCH)
      setDistrict(ifscData?.DISTRICT)
      setState(ifscData?.STATE)
      setCountry(ifscData?.COUNTRY)
      setMicr(ifscData?.MICR)
      setPincode(ifscData?.PINCODE)
    }
  }, [ifscData])


  const [uploadFile, setUploadFile]: any = useState<UploadFile[]>([])
  const [imageID, setImageID]: any = useState([])
  const [logo, setlogo]: any = useState([])

  const FileOnChange = (e: any) => {
    e.file.status = "done"

    let tempOldFiles: any = []
    e.fileList.map((e: any) => {
      if (!("originFileObj" in e)) {
        let ctx = {
          id: parseInt(e.uid),
          doc: e.url
        }
        tempOldFiles.push(ctx)
      }
    })

    const tempFiles = e.fileList.map((file: any) => {
      return file.originFileObj
    }).filter((e: any) => e != undefined)

    setImageID(tempOldFiles)
    setlogo(tempFiles)
    setUploadFile(e.fileList)

  }
  const onLogoChange = (e: any) => {
    setlogo(e.target.files)
  }


  useEffect(() => {
    if (ifscIsError) {
      setValue("ifsc_code", "")
      setChangeIFSC(false)
      setVerify(false)
    }
  }, [ifscIsError])



  useEffect(() => {
    if (ifscError) {
      setTimeout(() => {
        setIFSCError(false)
      }, 3000)
    }
  }, [ifscError]);



  const handleNumbercode = (e: any) => {
    let temp = e.target.value.replace(/\D/g, '');
    if (temp?.length > 10) temp = temp.substring(0, 10);
    if (temp?.length > 6) {
      temp = temp.slice(0, 5) + ' ' + temp.slice(5);
    }
    e.target.value = temp;

    setValue('service_mobile_no', temp)
  }

  const currentPath = window.location.pathname;

  const apinameBank = process.env.REACT_APP_BANK_API?.toString()

  useEffect(() => {
    if (data.id) {
      const getCall = async () => {
        // debugger
        try {
          const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameBank}/${data.id}/`);
          console.log('ResponseData:', response);
          reset(response?.data?.data)
          setPersonType(response?.data?.data?.type)
          setBankName(response?.data?.data.ifsc_code?.BANK)
          setCityName(response?.data?.data.ifsc_code?.CITY)
          setBranchName(response?.data?.data.ifsc_code?.BRANCH)
          setDistrict(response?.data?.data.ifsc_code?.DISTRICT)
          setState(response?.data?.data.ifsc_code?.STATE)
          setCountry(response?.data?.data.ifsc_code?.COUNTRY)
          setMicr(response?.data?.data.ifsc_code?.MICR)
          setPincode(response?.data?.data.ifsc_code?.PINCODE)
          setSelectedEmployee(response?.data?.data.employee?.id)
          setValue("employee", response?.data?.data.employee?.id)
          setSelectedCustomer(response?.data?.data.customer?.id)
          setValue("customer", response?.data?.data.customer?.id)
          setSelectedPriority(response?.data?.data?.priority?.id)
          setValue('priority', response?.data?.data?.priority?.id)
          setSelectedAccountType(response?.data?.data?.account_type?.id)
          setValue('account_type', response?.data?.data?.account_type?.id)
          if (check == "") {

            setSelectedIFSC(response?.data?.data?.ifsc_code?.IFSC)
            setIFSCid(response?.data?.data?.ifsc_code?.id)
          }
          let tempImages = response?.data?.data?.image?.map((img: any, index: number) => {
            let ctx: UploadFile = {
              uid: `${img?.id}`,
              name: `${img.title}`,
              status: 'done',
              url: img?.doc,
              thumbUrl: img?.doc,
            }

            return ctx
          })
          setUploadFile(tempImages)
          setValue("ifsc_code", response?.data?.data?.ifsc_code?.IFSC)

          if (response?.data?.data?.ifsc_code?.IFSC) {
            setVerify(true)
            setChangeIFSC(true)
          }

          // setSelectedIFSC(data?.ifsc_code?.IFSC)
          // setSelectedIFSC(data?.ifsc_code?.id)



        } catch (error) {
          console.error('Error:', error);
        }
      }
      getCall()
    }

  }, [])




  return (
    <div>
      <div >
        <section className="content-header">
          {/* <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>Edit {pageName}</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#" className="text-decoration-none text-black">
                      Home
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" className="text-decoration-none text-black">
                      Sales And Marketing
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" className="text-decoration-none text-black">
                      {pageName}
                    </a>
                  </li>
                  <li className="breadcrumb-item active">Edit {pageName}</li>
                </ol>
              </div>
            </div>
          </div> */}
        </section>
        <section className="">
          <div className="">
            <div className="card card-default">
              <div className="row">
                <p className="mb-0 " style={{ fontSize: '14px' }}>{`${addData?.person_type?.name}-${addData?.name}-${addData?.extra_response?.city_name_and_distict_name?.village_name ? addData?.extra_response?.city_name_and_distict_name?.village_name : addData?.extra_response?.city_name_and_distict_name?.city_name}`}</p>
              </div>
              <form
                ref={focus}
                onKeyUp={event => onEnterKey(event)}
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                  {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message?.name} /> : ""}
                  <div className="row d-flex justify-content-md-center justify-content-lg-start">
                    <div className="col-md-12">
                      <div className="row">
                        {/* <div className="col-sm-4 mt-2">
                                                <label >Person Type <span style={{ color: 'red' }}>*</span></label>
                                                <select className="form-select" {...register("type", { onChange:onPersonTypeChange, required: { value: true, message: "Priority is required" } })}>

                                                    <option value={""} selected>Select Any</option>
                                                    <option value="Customer">Customer</option>
                                                    <option value="Employee">Employee</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.type?.message}</span>

                                                </div> */}
                        <div className="col-sm-3 mt-2">
                          <label >Priority</label>
                          {/* <select className="form-select" {...register("priority", { required: { value: true, message: "Priority is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="First">First</option>
                                                        <option value="Second">Second</option>
                                                        <option value="Third">Third</option>
                                                    </select> */}
                          <CreatableSelect
                            {...register("priority", { required: { value: true, message: "Field is required" } })}
                            isClearable={() => {
                              setValuePriority(null)
                              setValue(null)
                            }}
                            components={customCreatableIndicator}
                            options={optionsPriority}
                            onCreateOption={handleCreatePriority}
                            onChange={handleChangePriority}
                            // onMenuOpen={() => PriorityRefetch()}
                            // value={valuePriorityCreatable == null ? valuePriority : valuePriorityCreatable}
                            value={valuePriorityCreatable == null ? valuePriority == null ? optionsPriority?.filter((obj: any) => obj.value === selectedPriority) : valuePriority : valuePriorityCreatable}

                          />
                          <span className='text-red w-100'>{errors?.priority?.message}</span>

                        </div>






                        {/* {PersonType ==="Customer"?<>
                                                <div className="col-sm-3 mt-2">

                                                    <label >Customer Code,Name & City<span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        {...register("customer", { required: { value: false, message: "Customer Code,Name & City is required" } })}
                                                        options={options}
                                                        onChange={handleChange}
                                                        value={selCustomer === "" ? options?.filter((obj: any) => obj.value === selectedCustomer) : selCustomer}
                                                        // isOptionDisabled={(options: any) => {
                                                        //     if (options.status === "inactive" || options.deleted == true) {
                                                        //         return options
                                                        //     }
                                                        // }}

                                                    />
                                                        <span className='text-red w-100'>{errors?.customer?.message}</span>
                                                </div>

                                                </>:""}
                                                {PersonType ==="Employee"?<>
                                                <div className="col-sm-3 mt-2">


                                                    <label >Employee Code,Surname & Name <span style={{ color: 'red' }}>*</span></label>
                                                 
                                                    <Select
                                                        {...register("employee", { required: { value: false, message: "Employee Code,Surname & Name is required" } })}
                                                        onChange={handleChangeEmployee}
                                                        options={employeeOptions}
                                                        value={selEmployee === "" ? employeeOptions?.filter((obj: any) => obj.value === selectedEmployee) : selEmployee}
                                                        // isOptionDisabled={(options: any) => {
                                                        //     if (options.status === "inactive" || options.deleted == true) {
                                                        //         return options
                                                        //     }
                                                        // }}

                                                    />
                                                    <span className='text-red w-100'>{errors?.employee?.message}</span>
                                                </div>
                                                </>:""} */}


                        <div className="col-sm-3 mt-2">

                          <label >Bank IFSC Code <span style={{ color: 'red' }}>*</span></label>
                          <div className="input-group">
                            <input type="text" className="form-control" disabled={changeIFSC}

                              // value={selIFSC == undefined?selectedIFSC:selIFSC}

                              placeholder="Bank IFSC Code" onChange={handleValueUppercase}
                              value={selectedIFSC}
                              {...register("ifsc_code", { onChange: handleIFSC, required: { value: true, message: "Bank IFSC Code is required" } })} />
                            {/* <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div> */}
                            <div className="input-group-append" style={{ width: "62px", padding: 0 }}>
                              {verify ?
                                <>
                                  <Button className="input-group-text border-start-0 bg-info" onClick={handleChangeIFSC}>
                                    Change
                                  </Button>
                                </>
                                :

                                <>
                                  <Button className="input-group-text border-start-0 bg-success" disabled={ifscValue == "" || ifscIsLoading} onClick={onSubmitIFSC}>
                                    {ifscIsLoading ? "Verifying..." : "Verify"}
                                  </Button>
                                </>}

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.ifsc_code?.message}</span>
                          {ifscError && <span className='text-red w-100'>{"IFSC Not Found"}</span>}
                        </div>

                        <div className="col-sm-3 mt-2">


                          <label >Bank Name </label>
                          <div className="input-group">
                            <input type="text" disabled className="form-control" value={BankName} placeholder="Branch Name" />

                          </div>
                          <span className='text-red w-100'>{errors?.name_and_city?.message}</span>
                        </div>


                        <div className="col-sm-3 mt-2">


                          <label >City Name</label>
                          <div className="input-group">
                            <input type="text" disabled className="form-control" value={CityName} placeholder="Branch Name" />

                          </div>

                          <span className='text-red w-100'>{errors?.city?.message}</span>
                        </div>


                        <div className="col-sm-3 mt-2">

                          <label >Branch Name</label>
                          <div className="input-group">
                            <input type="text" className="form-control" disabled value={BranchName} placeholder="Branch Name" />

                          </div>
                          <span className='text-red w-100'>{errors?.branch_name?.message}</span>
                        </div>


                        <div className="col-sm-3 mt-2">
                          <label >Bank Account Type <span style={{ color: 'red' }}>*</span></label>
                          {/* <select className="form-select" {...register("account_type", { required: { value: true, message: "Bank Account Type is required" } })}>

                                                    <option value={""} selected>Select Any</option>
                                                    <option value="Savings">Savings</option>
                                                    <option value="Current">Current</option>
                                                    <option value="CC">CC</option>
                                                    </select> */}

                          <CreatableSelect
                            {...register("account_type", { required: { value: true, message: "Field is required" } })}
                            isClearable={() => {
                              setValueAccountType(null)
                              setValue(null)
                            }}
                            components={customCreatableIndicator}
                            options={optionsAccountType}
                            onCreateOption={handleCreateAccountType}
                            onChange={handleChangeAccountType}
                            // onMenuOpen={() => AccountTypeRefetch()}
                            // value={valueAccountTypeCreatable == null ? valueAccountType : valueAccountTypeCreatable}
                            value={valueAccountTypeCreatable == null ? valueAccountType == null ? optionsAccountType?.filter((obj: any) => obj.value === selectedAccountType) : valueAccountType : valueAccountTypeCreatable}

                          />
                          <span className='text-red w-100'>{errors?.account_type?.message}</span>

                        </div>


                        <div className="col-sm-3 mt-2">

                          <label >Bank Account Number <span style={{ color: 'red' }}>*</span></label>
                          <div className="input-group">
                            <input type="number" className="form-control" placeholder="Bank Account Number" {...register("account_number", { required: { value: true, message: "Bank Account Number is required" } })} />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.account_number?.message}</span>
                        </div>

                        <div className="col-sm-3 mt-2">
                          <label >Bank Swift Code</label>
                          <div className="input-group">
                            <input type="text" className="form-control" placeholder="Bank Swift Code" {...register("swift_code", { onChange: handleValueUppercase, required: { value: false, message: "Bank Swift Code is required" } })} />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.swift_code?.message}</span>
                        </div>


                        <div className="col-sm-3 mt-2">
                          <label >Bank IEC Code</label>
                          <div className="input-group">
                            <input type="text" className="form-control" placeholder="Bank IEC Code" {...register("iec_code", { onChange: handleValueUppercase, required: { value: false, message: "Bank IEC Code is required" } })} />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.iec_code?.message}</span>
                        </div>
                        <div className="col-sm-3 mt-2">
                          <label >Bank Micr Code</label>
                          <div className="input-group">
                            <input type="text" className="form-control" disabled value={Micr} placeholder="Bank Micr Code" />

                          </div>
                          <span className='text-red w-100'>{errors?.micr_code?.message}</span>
                        </div>

                        <div className="col-sm-3 mt-2">
                          <label >Check Print Name</label>
                          <div className="input-group">
                            <input type="text" className="form-control" placeholder="Check Print Name" {...register("cheque_print_name", { onChange: handleValue, required: { value: false, message: "Check Print Name is required" } })} />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.cheque_print_name?.message}</span>
                        </div>

                        <div className="col-sm-3 mt-2">
                          <label >Amount Transfer Account Name</label>
                          <div className="input-group">
                            <input type="text" className="form-control" placeholder="Amount Transfer Account Name" {...register("amount_transfer_account_name", { onChange: handleValue, required: { value: true, message: "Amount Transfer Account Name is required" } })} />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.amount_transfer_account_name?.message}</span>
                        </div>




                        <div className="col-sm-3 mt-2">

                          <label >District</label>
                          <div className="input-group">
                            <input type="text" disabled className="form-control"
                              value={District}
                              placeholder="District"

                            />

                          </div>


                        </div>

                        <div className="col-sm-3 mt-2">

                          <label >State</label>
                          <div className="input-group">
                            <input type="text" disabled className="form-control"
                              value={State}
                              placeholder="State"

                            />

                          </div>


                        </div>

                        <div className="col-sm-3 mt-2">

                          <label >Country</label>
                          <div className="input-group">
                            <input type="text" disabled className="form-control"
                              value={Country}
                              placeholder="Country"
                            />

                          </div>


                        </div>
                        <div className="col-sm-3 mt-2">
                          <label >Pin Code </label>
                          <div className="input-group">
                            <input type="text" disabled className="form-control"
                              value={Pincode}
                              placeholder="Pin Code"

                            />
                          </div>
                          <span className='text-red w-100'>{errors?.account_type?.message}</span>
                        </div>

                        <div className="col-sm-3 mt-2">

                          <label >Pan No.</label>
                          <div className="input-group">
                            <input type="text" className="form-control" placeholder="Pan No." {...register("pan_number", { onChange: handleValueUppercase, required: { value: true, message: "Pan No. is required" } })} />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.pan_number?.message}</span>
                        </div>


                        <div className="col-sm-3 mt-2">

                          <label >Bank Contact Number-1 <span style={{ color: 'red' }}>*</span></label>
                          <div className="input-group">
                            <input type="text" className="form-control" placeholder="Bank Contact Number-1"
                              {...register('bank_contact_number_1', {
                                required: "Bank Contact Number-1 is required",

                                pattern: {
                                  value: /^(\+\d{1,3}[- ]?)?\d{10}$/g,
                                  message: 'Please enter a valid number',
                                },
                              })}
                            // onChange={handleNumbercode}
                            />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.bank_contact_number_1?.message}</span>
                        </div>
                        <div className="col-sm-3 mt-2">

                          <label >Bank Contact Number-2 </label>
                          <div className="input-group">
                            <input type="text" className="form-control" placeholder="Bank Contact Number-2"
                              {...register('bank_contact_number_2', {
                                required: false,

                                pattern: {
                                  value: /^(\+\d{1,3}[- ]?)?\d{10}$/g,
                                  message: 'Please enter a valid number',
                                },
                              })}
                            // onChange={handleNumbercode}
                            />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.bank_contact_number_2?.message}</span>
                        </div>



                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Bank Email Address-1 <span style={{ color: 'red' }}>*</span></label>
                          <div className="input-group">
                            <input type="email" className="form-control" id="exampleInputBrand" placeholder="Bank Email Address-1"
                              {...register('bank_email_id_1', {
                                required: 'Email is required',
                                pattern: {
                                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                                  message: 'Please enter a valid email',
                                },
                              })} />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              // style={{backgroundColor:"blue",color:"green"}} 
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.bank_email_id_1?.message}</span>
                        </div>


                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Bank Email Address-2 </label>
                          <div className="input-group">
                            <input type="email" className="form-control" id="exampleInputBrand" placeholder="Bank Email Address-2"
                              {...register('bank_email_id_2', {
                                required: false,
                                pattern: {
                                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                                  message: 'Please enter a valid email',
                                },
                              })} />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              // style={{backgroundColor:"blue",color:"green"}} 
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.bank_email_id_2?.message}</span>
                        </div>


                        <div className="col-sm-3 mt-2">


                          <label >Re-Mark</label>
                          <div className="input-group">
                            <input type="text" className="form-control"

                              placeholder={'Re-Mark'}


                              {...register('re_mark', { required: { value: false, message: "Field is required" } })}
                            //  {...register("document_number", { required: { value: true, message: "Field is required" } })} 
                            />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.re_mark?.message}</span>
                        </div>


                        <div className="col-sm-3 mt-2">
                          <label >Status</label>
                          <select className="form-select" {...register("status", { required: { value: true, message: "Field is required" } })}>

                            <option value={""} selected>Select Any</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                          <span className='text-red w-100'>{errors?.status?.message}</span>

                        </div>


                      </div>
                    </div>
                  </div>
                  <div className="row d-flex">
                    <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                      <div className="row d-flex ">
                        <div className="col-sm-3 mt-2 image-upload">
                          <label htmlFor="exampleInputEmail1" className="col-12">Machine Image</label>


                          {uploadFile?.length > 0 ?

                            <>
                              <Upload
                                {...register("image")}
                                accept="image/png, image/jpeg"
                                listType="picture"
                                onChange={FileOnChange}
                                multiple={true}
                                defaultFileList={[...uploadFile]}
                                onPreview={(e: any) => { return "" }}
                                key={"image"}

                              >
                                <img src={cameraLogo} alt='' className="uploaded-image" />
                              </Upload>
                            </> :
                            <>
                              <Upload
                                {...register("image")}
                                accept="image/png, image/jpeg"
                                listType="picture"
                                onChange={FileOnChange}
                                multiple={true}
                                onPreview={(e: any) => { return "" }}

                              >
                                <img src={cameraLogo} alt='' className="uploaded-image" />
                              </Upload>
                            </>
                          }





                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row d-flex  ">
                    <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                      <div className="row d-flex ">
                        <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                          {!verify && <span style={{ color: "#4287f5" }}>Don't Forget To Verify Your IFSC Before Submit</span>}
                          <button
                            type="button"
                            className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                            style={{
                              borderColor: "rgb(0, 54, 101)",
                              background: "rgb(0, 54, 101)",
                              color: "white",
                            }}
                            onClick={handleSubmit(onSubmit)}
                          >
                            Submit
                          </button>
                          <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetail")}>Cancel</button>
                          {currentPath === '/addCustomerDetails' ?
                            <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => setCurrentStep(currentStep - 1)}>Back</button>
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>

      <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
        yesupdatemodal={yesupdatemodal}
        cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />

      <Modal show={openEditDateModel} onHide={() => setOpenEditDateModel(false)} aria-labelledby="contained-modal-title-vcenter"
        centered    >
        <Modal.Header closeButton>
          <Modal.Title>Select a Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12">
            <div className="row">
              <div className="col-sm-12 mt-2">
                <label htmlFor="exampleInputEmail1">Choose Date</label>
                <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditDateModel(false) }}>Close</button>
          <button
            type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
            onClick={handleSubmit(handleConfirm)}
          >Confirm</button>
        </Modal.Footer>
      </Modal>

      {show && (
        <Mui_Toaster
          message={`Bank Details Updated Successfully`}
          severity={"success"}
          Isopen={true}
        />
      )}
    </div>
  );
};

export default Edit_Bank_Format;
