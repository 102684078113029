import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineEye } from "react-icons/ai";

import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Restricted from "../../Roles/Restricted";
import { useState } from "react";


const Sheet_Roll_Code = ({ row, apiname, deletemodalask,permissionClass }: any) => {

    const [show,setShow]:any = useState(false)
  return (
    <>
    <div
        className="vertical-align-center"
        style={{
          display: "flex !important",
          justifyContent: "space-evenly !important",
          
        }}
      >
      
        
      {/* <Restricted
          to={`${permissionClass?.front}.change_${permissionClass?.back}`}
          fallback={""}
        > */}
          <Link
            className="btn btn-primary text-white mx-2"
            to={`/sheetRollCode/${Number(row.original.id)}`}
          >
            Sheet Roll
          </Link>
        {/* </Restricted> */}
       
      </div>
    </>
  );
};

export default Sheet_Roll_Code;

