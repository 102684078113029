import axios from 'axios';
import { useNavigate } from 'react-router-dom';


// const Interceptor = () => {
function getLocalAccessToken() {
    const accessToken = localStorage.getItem("token");
    return accessToken;
}

function getLocalRefreshToken() {
    const refreshToken = localStorage.getItem("refresh");
    return refreshToken;
}

const Axiosinstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
    // headers: {
    //     "Content-Type": "application/json",
    // },
});

// instance.interceptors.response.use(
//     function (response) {
//       return response;
//     },
//     function (error) {
//       return Promise.reject(error);
//     }
//   );


Axiosinstance.interceptors.request.use(
    (config) => {
        const token = getLocalAccessToken();
        if (token) {
            config.headers.Authorization = token ? `Bearer ${token}` : '';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

Axiosinstance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {

        const originalConfig = err.config;

        if (err.response) {

            if (err.response.status === 401 && !originalConfig._retry) {

                const isInquiryPage = window.location.pathname === '/addOnlinesalesinquiry';

                originalConfig._retry = true;
                if (!isInquiryPage) {
                    try {
                        const rs = await refreshToken();
                        const { access } = rs.data;
                        localStorage.setItem("token", access);
                        Axiosinstance.defaults.headers.common = { 'Authorization': `Bearer ${access}` }

                        return Axiosinstance(originalConfig);
                    } catch (_error: any) {
                        if (_error.response && _error.response.data) {
                            // alert("Please Login again")
                            localStorage.clear()
                            // let navigate = useNavigate()
                            // navigate("/login")
                            window.location.href = "/login"
                            return Promise.reject(_error.response.data);
                        }
                        return Promise.reject(_error);
                    }
                }
            }

            if (err.response.status === 403 && err.response.data) {

                return Promise.reject(err.response.data);
            }
        }

        return Promise.reject(err);
    }
);

function refreshToken() {
    return axios.post(`${process.env.REACT_APP_BASE_URL}api/token/refresh/`, {
        refresh: getLocalRefreshToken(),
    });
}
// }

export default Axiosinstance