import React from "react";
import { TimePicker } from "antd";

interface TimePickerFields {
  className?: any;
  value?: any;
  onChange?: any;
}

const TimePickerComponent = ({
  className,
  value,
  onChange,
}: TimePickerFields) => {
  const format = "HH:mm";

  return (
    <div>
      <TimePicker
        className={className ? className : "form-control"}
        format={format}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default TimePickerComponent;
