import React, { useEffect, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../../images/Export/Camera.png";
import pdfLogo from "../../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate, useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import { useFetchAccountDropDown, useFetchDeliveryAddressDropDown, useFetchMaterialTypeDropDown, useFetchPriceListDropDown, useFetchSalesInquiryDropDown } from "../../../hooks/DropDown_Api";
import { DatePicker } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from 'dayjs';
import Button from "react-bootstrap/esm/Button";
import { AiOutlinePlus } from "react-icons/ai";
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import { Customer_Details_Model } from "../../../components/Dynamic_Form_Models/Customer_Details_Model";
import Axiosinstance from "../../../hooks/Interceptor";
import { Deletesinglewithdependancy } from "../../../components/modal";
import Modal from "react-bootstrap/esm/Modal";
const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}


const Edit_LeadGeneraed = () => {
    let pageName = "Lead Generate"
    const navigate = useNavigate()
    const { id } = useParams()

    const [forSalesValue, setForSalesValue]: any = useState("")
    const [optionsSales, setOptionsSales]: any = useState([])
    const [selectedSales, setSelectedSales]: any = useState("");
    const [selectedPrice, setSelectedPrice]: any = useState("");
    const apiname = process.env.REACT_APP_LEAD_GENERATED_API?.toString()
    const { data } = useFetchSingle(id, apiname)
    const { mutate, isSuccess, isError, error, data: gstUpdated_response }: any = useUpdateSingle()

    const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();



    const [openEditModel, setOpenEditModel] = useState(false)
    const [expirationDate, setExpirationDate] = useState()
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()

    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()



    const [forPriceValue, setForPriceValue]: any = useState("")
    const [product, setProduct]: any = useState([]);
    const [visitingCard, setVisitingCard] = useState(null)











    const [companyValue, setCompanyValue]: any = useState("");
    const [employeeValue, setEmployeeValue]: any = useState("");
    const [CompanyDateValue, setCompanyDateValue]: any = useState("");
    const [CustomerId, setCustomerId] = useState('')
    const { data: salesData, refetch: salesRefetch } = useFetchSalesInquiryDropDown()

    useEffect(() => {
        setOptionsSales(salesData)
    }, [salesData])

    const handleChangeSales = (e: any) => {
        setValue("sales_inquiry_code", e?.value)
        setForSalesValue(e)
        setProduct(e.product)
        // setCompanyValue(e.company)
        // setEmployeeValue(e.employee)
        // setProductQtyValue(e.productQty)
        setCompanyDateValue(e.companyDate)
        // setProductTypeValue(e.productCode)
        // setProductYesnoValue(e.possible)
        setValue("employee_code", e.employee)
        setValue("company", e.company)
        setValue("product_type", e.productCode)
        setValue("product_qty", e.productQty)
        setValue("possible_yes_no", e.possible)
        setValue("requirement_date", e.requireDate)
        setValue("company_new_old", e.companyNewOld)
        setValue("lead_source_of_inquiry", e.leadInquiry)
        setValue("requirement_status", e.requirmentStatus)
        setValue("company_name_and_city", e.company_name_and_city)
        setValue("mobile_number", e.mobile_number)
        setValue("email", e.email)
        setValue("pincode", e.pincode)
        setValue("city_and_village", e.city_and_village)
        setValue("district", e.district)
        setValue("taluka", e.taluka)
        setValue("country", e.country)
        setValue("state", e.state)
        setValue("reference_name", e.reference)
        setValue("lead_contacted", e.lead_contacted)
        setValue("visiting_card_image", e.visiting_card_image)
        setValue("gst", e.gst)
        setVisitingCard(e.visiting_card_image)
        setCustomerId(e?.customerId)
        // console.log(e,"gst")
        // setForDepartmentValue({ label: e?.label, value: e?.value })

        // console.log(e.requirement_status,"requirement_status",e.lead_source_of_inquiry,"lead_source_of_inquiry",e);
    }



    const [optionsPriceList, setOptionsPriceList] = useState([]);

    const { data: priceListData, refetch: priceListRefetch } =
        useFetchPriceListDropDown();

    useEffect(() => {
        setOptionsPriceList(priceListData);
    }, [priceListData]);





    const handleChangePriceList = (e: any) => {
        setForPriceValue(e)
        setValue("product_code", e?.value)
        // setValue("product_code",e?.producat_value)
        setValue("product_ml_price_list", e.value);
        setValue("gst", e.gst);
        setValue("gst_amount", e.gst_amount);
        setValue("ml_price_list", e.ml_price_list);
        setValue("stick_nos", e.stick_nos);
        setValue("carton_stick", e.carton_stick);
        setValue("product_total_qty", e.product_total_qty);
        setValue("product_weight_price_list", e.product_weight_price_list);
        setValue("carton_weight", e.carton_weight);
    };

    const onSubmit = (leaddetail: any) => {

        delete leaddetail.company_new_old
        delete leaddetail.gst
        delete leaddetail.gst_amount
        delete leaddetail.ml_price_list
        delete leaddetail.stick_nos
        delete leaddetail.carton_stick
        delete leaddetail.product_total_qty
        delete leaddetail.product_weight_price_list
        delete leaddetail.carton_weight
        delete leaddetail.requirement_status
        delete leaddetail.lead_source_of_inquiry
        delete leaddetail.company_name_and_city
        delete leaddetail.mobile_number
        delete leaddetail.email
        delete leaddetail.pincode
        delete leaddetail.city_and_village
        delete leaddetail.taluka
        delete leaddetail.district
        delete leaddetail.state
        delete leaddetail.country
        delete leaddetail.lead_contacted
        delete leaddetail.visiting_card_image
        delete leaddetail.product_ml_price_list
        delete leaddetail.product_type
        delete leaddetail.product_qty
        delete leaddetail.possible_yes_no
        delete leaddetail.requirement_date

        const formData = new FormData();
        for (const [key, value] of Object.entries(leaddetail)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            // else {
            //     formData.append(key, formvalue[0])
            // }
        }
        let data = {
            apiname: apiname,
            id: id,
            data: formData
        }
        mutate(data);
        setValuesofform(data)

    }



    // const [show, setShow] = useState(false)
    // const [showmessage, setshowmessage] = useState("")

    // useEffect(() => {
    //     if (isSuccess) {
    //         setShow(true)
    //         setshowmessage("success")
    //         navigate('/listLeadGenerated', { state: { showEditToast: true } })
    //     }
    //     else if (isError) {
    //         setShow(true)
    //         setshowmessage("danger")
    //     }
    // }, [gstUpdated_response, error, isSuccess])

    // useEffect(() => {
    //     setTimeout(() => {
    //         setShow(false)
    //     }, 3000)

    // }, [show]);


    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")


    useEffect(() => {
        if (isSuccess || yessuccess || nosuccess) {
            setShow(true)
            setValuesofform(null)
            setshowmessage("success")
            navigate('/listLeadGenerated', { state: { showEditToast: true } })


        }

    }, [gstUpdated_response, data, isSuccess, yessuccess,
        nosuccess])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);
    useEffect(() => {
        if (isError) {
            if (error.response.status === 405) {
                setConfirmmodal(true)
            }
        }
        else if (isError || yeserror || noerror) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isError, yeserror, noerror])

    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }


    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }
    const noupdatemodal = () => {
        setOpenEditModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {


        let ok = {
            id: id,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditModel(false)
    }


    useEffect(() => {
        if (data) {
            reset(data)
            console.log('data', data)
            // setSelectedPrice(data?.product_code?.product_name_and_grade_and_type?.id)
            // setValue("product_code",data?.product_code?.product_name_and_grade_and_type?.id)
            // setValue("product_code",data?.product_code?.producat_value)
            //   setValue("product_ml_price_list", data.sales_inquiry_code?.product[0]?.value);
            //   setValue("gst", data.sales_inquiry_code?.product?.pricelist_product?.product_code?.product_name_and_grade?.gst?.india_GST);
            //   setValue("gst_amount", data.sales_inquiry_code?.gst_amount);
            //   setValue("ml_price_list", data.sales_inquiry_code?.ml_price_list);
            //   setValue("stick_nos", data.sales_inquiry_code?.stick_nos);
            //   setValue("carton_stick", data.sales_inquiry_code?.carton_stick);
            //   setValue("product_total_qty", data.sales_inquiry_code?.product_total_qty);
            //   setValue("product_weight_price_list", data.sales_inquiry_code?.product_weight_price_list);
            //   setValue("carton_weight", data.sales_inquiry_code?.carton_weight);

            // console.log(data,"data");

            //   setValue("product_type",data.sales_inquiry_code?.productCode)
            // setSelectedSales(data?.sales_inquiry_code?.id)
            // setValue("sales_inquiry_code",data.sales_inquiry_code?.id)
            // setCustomerId(data?.sales_inquiry_code?.company_name_and_city?.id)
            // setValue("possible_yes_no",data.sales_inquiry_code?.possible)
            // setValue("product_qty",data.sales_inquiry_code?.productQty)
            //   setValue("requirement_date",data.sales_inquiry_code?.requireDate)
            //   setValue("company_new_old",data.sales_inquiry_code?.company_new_old)
            //   setValue("lead_source_of_inquiry",data.sales_inquiry_code?.lead_source_of_inquiry?.name)
            //   setValue("requirement_status",data.sales_inquiry_code?.requirement_status)
            //   setCompanyValue(`${data?.sales_inquiry_code?.extra_response?.company_name_and_city?.name}-${data.sales_inquiry_code?.extra_response?.company_name_and_city?.city[0]?.city_name?data.sales_inquiry_code?.extra_response?.company_name_and_city?.city[0]?.city_name:data.sales_inquiry_code?.extra_response?.company_name_and_city?.city[0]?.village_name}`)
            //   setValue("company_name_and_city",data.sales_inquiry_code?.extra_response?.company_name_and_city?.name)
            setEmployeeValue(`${data.sales_inquiry_code?.lead_generated_by_sales_executive_code_and_name?.code} ${data.sales_inquiry_code?.lead_generated_by_sales_executive_code_and_name?.name}`)
            //   setValue("email",data.sales_inquiry_code?.extra_response?.company_name_and_city?.email_address)
            //   setValue("mobile_number",data.sales_inquiry_code?.extra_response?.company_name_and_city?.contact_detail_number)
            //   setValue("pincode",data.sales_inquiry_code?.extra_response?.company_name_and_city?.city[0]?.pincode[0])
            //   setValue("city_and_village",`${data.sales_inquiry_code?.extra_response?.company_name_and_city?.city[0]?.city_name?data.sales_inquiry_code?.extra_response?.company_name_and_city?.city[0]?.city_name:data.sales_inquiry_code?.extra_response?.company_name_and_city?.city[0]?.village_name}`)
            //   setValue("district",data.sales_inquiry_code?.extra_response?.company_name_and_city?.city[0]?.district)
            //   setValue("taluka",data.sales_inquiry_code?.extra_response?.company_name_and_city?.city[0]?.taluka)
            //   setValue("country",data.sales_inquiry_code?.extra_response?.company_name_and_city?.city[0]?.country)
            //   setValue("state",data.sales_inquiry_code?.extra_response?.company_name_and_city?.city[0]?.state)
            //   setValue("reference_name",`${data.sales_inquiry_code?.extra_response?.company_name_and_city?.reference_name?data.sales_inquiry_code?.extra_response?.company_name_and_city?.reference_name:data.sales_inquiry_code?.extra_response?.company_name_and_city?.other_reference}`)
            //   setValue("lead_contacted",data.sales_inquiry_code?.lead_contacted)
            // setValue("visiting_card_image", data.sales_inquiry_code?.visiting_card_image)
            // setVisitingCard(data.sales_inquiry_code?.visiting_card_image?.split("/").pop())
            //   setProduct(data?.sales_inquiry_code?.product)
            //   setSelectedDelivery(data?.delivery_address?.id)
            //   setValue("delivery_address",data?.delivery_address?.id)

        }

    }, [data]);


    const apinameSales = process.env.REACT_APP_SALES_INQUIRY_API?.toString()
    // const [dataMouldShape,setDataMouldShape]:any = useState([])
    const apinameDeliveryAddress = process.env.REACT_APP_ADDRESS_API?.toString()
    const apinameProduct = process.env.REACT_APP_SALES_ADD_PRODUCT_API?.toString()
    // const [dataProductCode,setDataProductCode]:any = useState([])
    useEffect(() => {
        if (data?.sales_inquiry_code) {
            const getCall = async () => {
                try {
                    const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameSales}/${data?.sales_inquiry_code}`, {
                        //   ids: data?.sales_inquiry_code
                    });
                    console.log(response, "response");
                    setCustomerId(response?.data?.data?.company_name_and_city)
                    setSelectedSales(response?.data?.data?.id)
                    setValue("sales_inquiry_code", response?.data?.data.id)
                    setValue("possible_yes_no", response?.data?.data.possible)
                    setValue("product_qty", response?.data?.data.productQty)
                    setValue("requirement_date", response?.data?.data.requireDate)
                    setValue("company_new_old", response?.data?.data.company_new_old)
                    setValue("lead_source_of_inquiry", response?.data?.data.lead_source_of_inquiry?.name)
                    setValue("requirement_status", response?.data?.data.requirement_status)
                    setCompanyValue(`${response?.data?.data?.extra_response?.company_name_and_city?.name}-${response?.data?.data.extra_response?.company_name_and_city?.city[0]?.city_name ? response?.data?.data.extra_response?.company_name_and_city?.city[0]?.city_name : response?.data?.data.extra_response?.company_name_and_city?.city[0]?.village_name}`)
                    setValue("company_name_and_city", response?.data?.data.extra_response?.company_name_and_city?.name)
                    setValue("email", response?.data?.data.extra_response?.company_name_and_city?.email_address)
                    setValue("mobile_number", response?.data?.data.extra_response?.company_name_and_city?.contact_detail_number)
                    setValue("pincode", response?.data?.data.extra_response?.company_name_and_city?.city[0]?.pincode[0])
                    setValue("city_and_village", `${response?.data?.data.extra_response?.company_name_and_city?.city[0]?.city_name ? response?.data?.data.extra_response?.company_name_and_city?.city[0]?.city_name : response?.data?.data.extra_response?.company_name_and_city?.city[0]?.village_name}`)
                    setValue("district", response?.data?.data.extra_response?.company_name_and_city?.city[0]?.district)
                    setValue("taluka", response?.data?.data.extra_response?.company_name_and_city?.city[0]?.taluka)
                    setValue("country", response?.data?.data.extra_response?.company_name_and_city?.city[0]?.country)
                    setValue("state", response?.data?.data.extra_response?.company_name_and_city?.city[0]?.state)
                    setValue("reference_name", `${response?.data?.data.extra_response?.company_name_and_city?.reference_name ? response?.data?.data.extra_response?.company_name_and_city?.reference_name : response?.data?.data.extra_response?.company_name_and_city?.other_reference}`)
                    setValue("gst", response?.data?.data.product?.pricelist_product?.product_code?.product_name_and_grade?.gst?.india_GST);
                    setValue("gst_amount", response?.data?.data.gst_amount);
                    setValue("ml_price_list", response?.data?.data.ml_price_list);
                    setValue("stick_nos", response?.data?.data.stick_nos);
                    setValue("carton_stick", response?.data?.data.carton_stick);
                    setValue("product_total_qty", response?.data?.data.product_total_qty);
                    setValue("product_weight_price_list", response?.data?.data.product_weight_price_list);
                    setValue("carton_weight", response?.data?.data.carton_weight);
                    // setProduct(response?.data?.data?.product)
                    setValue("lead_contacted", response?.data?.data.lead_contacted)
                    setValue("visiting_card_image", response?.data?.data?.visiting_card_image)
                    setVisitingCard(response?.data?.data?.visiting_card_image?.split("/").pop())
                } catch (error) {
                    console.error('Error:', error);
                }
            }
            getCall()
        }
        if (data?.delivery_address) {
            const getCall = async () => {
                try {
                    const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameDeliveryAddress}/${data?.delivery_address}`);

                    setSelectedDelivery(response?.data?.data?.id)
                    setValue("delivery_address", response?.data?.data?.id)
                } catch (error) {
                    console.error('Error:', error);
                }
            }
            getCall()
        }
        if (data?.extra_response?.sales_inquiry_products?.length > 0) {
            const getCall = async () => {
                try {
                    const response = await Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apinameProduct}/fetch_multiple_ids/`, {
                        ids: data.extra_response?.sales_inquiry_products
                    });
                    console.log(response, "response=======>");
                    setProduct(response?.data?.data)
                    // setSelectedDelivery(response?.data?.data?.id)
                    // setValue("delivery_address", response?.data?.data?.id)
                } catch (error) {
                    console.error('Error:', error);
                }
            }
            getCall()
        }
    }, [data])

    const [forFromDate, setForFromDate]: any = useState()
    const [fromDate, setFromDate]: any = useState()

    const handleFromDate = (e: any) => {
        setFromDate(e)
        setValue("company_delivery_date", `${e?.$D}-${e?.$M + 1}-${e?.$y}`)
        setForFromDate(e)
    }


    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {

        return current && current < dayjs().startOf('day');
    };
    let dateOne = data?.company_delivery_date
    let fromDateValue = dateOne


    const handleCavityName = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');


    }

    const [OpenCustomerDetails, setOpenCustomerDetails] = useState(false)

    const [optionsDelivery, setOptionsDelivery] = useState([])

    const [forTab, setForTab] = useState('Addresses')


    const [dataAdded, setDataAdded]:any = useState(false)

    const apinameAddress = 'person/employee/customer/get_only_address'
    useEffect(() => {
        const fetchData = async () => {
            if (forSalesValue || dataAdded || selectedSales) {
                try {
                    // 
                    const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameAddress}/?id=${CustomerId}`);
                    console.log('response', response);

                    let address = response?.data?.data?.address?.map((e: any) => {
                        return (
                            {
                                value: e?.id,
                                label: `${e?.number}, ${e?.building_name ? e?.building_name : e?.bunglow_name}`

                            }
                        )



                    })

                    await setOptionsDelivery(address)
                    if (dataAdded) {
                        //   setDeliveryValue([])

                    }
                    await setDataAdded(false)



                    // Do something with response.data
                } catch (error) {
                    console.error("Error fetching data:", error);
                    // Handle the error appropriately
                }
            }
        };

        fetchData();

    }, [forSalesValue, dataAdded, selectedSales]);





    const [DeliveryValue, setDeliveryValue]: any = useState(null)



    const handleDelivery = (e: any) => {
        setValue('delivery_address', e?.value)
        // setDeliveryValue(e)
        setSelDelivery(e)
    }

    // const [DeliveryValue, setDeliveryValue]: any = useState("")
    const [selDelivery, setSelDelivery]: any = useState("")
    const [selectedDelivery, setSelectedDelivery]: any = useState("")
    const { data: deliveryData, refetch: deliveryRefetch } = useFetchDeliveryAddressDropDown()

    // useEffect(()=>{
    //   setOptionsDelivery(deliveryData)
    // },[deliveryData])


    // const handleDelivery = (e: any) => {
    //     setValue('delivery_address', e?.value)
    //     // setDeliveryValue(e)
    //     setSelDelivery(e)
    // }

 

    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit {pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">{pageName}</Link></li>
                                    <li className="breadcrumb-item active">Edit {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Cavity</span></h5>
                            </div> */}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                                    {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message.name} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Sales Inquiry Code </label>
                                                    {/* <div className="input-group"> */}
                                                    <Select
                                                        placeholder={'Select Sales Inquiry Code'}
                                                        {...register("sales_inquiry_code", { required: { value: true, message: "Sales Inquiry Code is required" } })}
                                                        onChange={handleChangeSales}
                                                        options={optionsSales}
                                                        components={customIndicator}
                                                        value={forSalesValue === "" ? optionsSales?.find((e: any) => e.value === selectedSales) : forSalesValue}
                                                    // isOptionDisabled={(optionsSales: any) => {
                                                    //     if (optionsSales.status === "inactive" || optionsSales.deleted == true) {
                                                    //         return optionsSales
                                                    //     }
                                                    // }}
                                                    />
                                                    {/* </div> */}
                                                    <span className='text-red w-100'>{errors?.sales_inquiry_code?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Company Type</label>
                                                    <select disabled className="form-select" {...register("company_new_old", { required: { value: false, message: "Company Type is required" } })}>
                                                        <option value={""} selected>Select Any</option>
                                                        <option value="old">Old</option>
                                                        <option value="new">New</option>

                                                    </select>
                                                    <span className='text-red w-100'>{errors?.company_new_old?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Difference Day</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Difference Day" {...register("difference_day", { required: { value: true, message: "Difference Day is required" } })} />
                                                        {/* <span className='text-red w-100'>{errors?.name?.message}</span> */}
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.difference_day?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Company Name & City</label>

                                                    <input type="text" disabled className="form-control" value={companyValue} id="exampleInputBrand" placeholder="Company Name & City" />

                                                    <span className='text-red w-100'>{errors?.city?.message}</span>

                                                </div>
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Employee Code & Name</label>
                                                    <input type="text" disabled className="form-control" value={employeeValue} id="exampleInputBrand" placeholder="Employee Code & Name"  />

                                                    <span className='text-red w-100'>{errors?.zone?.message}</span>

                                                </div> */}
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1"> Product Type</label>
                                                    
                                                        <div className="input-group">
                                                        <input type="text" disabled className="form-control"  id="exampleInputBrand" placeholder="Product Type" {...register("product_type")} />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_type?.message}</span>

                                                </div> */}
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Total Qty</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control"  id="exampleInputBrand" placeholder="Product Total Qty"{...register("product_qty")}  />
                                                    </div>
                                                </div> */}
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Possible Yes-No</label>
                                                    <select className="form-select" {...register("possible_yes_no", { required: { value: false, message: "Cavity Type is required" } })}>
                                                        <option value={""} selected>Select Any</option>
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                       
                                                    </select>
                                                    
                                                    <span className='text-red w-100'>{errors?.possible_yes_no?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Special-Running</label>
                                                    <select className="form-select" {...register("product_special_running", { required: { value: false, message: "Product Special-Running is required" } })}>
                                                        <option value={""} selected>Select Any</option>
                                                        <option value="special">Special</option>
                                                        <option value="running">Running</option>    
                                                        <option value="full_running ">Full Running</option>
                                                       
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.product_special_running?.message}</span>
                                                </div> */}
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Code, Name & Type</label>
                                                    <Select
                                                            placeholder={'Select Product Code, Name & Type'}
                                                            {...register("product_code", { required: { value: true, message: "Product Code, Name & Type is required" } })}
                                                            onChange={handleChangePriceList}
                                                            options={optionsPriceList}
                                                            // value={forPriceValue}
                                                            value={forPriceValue === "" ? optionsPriceList?.find((obj: any) => obj.value === selectedPrice) : forPriceValue}

                                                            // isOptionDisabled={(optionsPriceList: any) => {
                                                            //     if (optionsPriceList.status === "inactive" || optionsPriceList.deleted == true) {
                                                            //         return optionsPriceList
                                                            //     }
                                                            // }}
                                                        />
                                                    <span className='text-red w-100'>{errors?.product_code?.message}</span>
                                                </div> */}
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Carton Bundle</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Carton Bundle" {...register("carton_bundle", { onChange: handleCavityName, required: { value: true, message: "Cavity Name is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.carton_bundle?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Company Delivery Date</label>
                                                    <DatePicker
                                                        className="form-control"
                                                        format={dateFormatList}
                                                        // value={forFromDate}
                                                        value={forFromDate == undefined ? dayjs(fromDateValue, "DD/MM/YYYY") : forFromDate}

                                                        {...register("company_delivery_date", { required: { value: false, message: "Field is required" } })}

                                                        onChange={handleFromDate}
                                                    />
                                                    {/* <div className="input-group">
                                                        <input type="text" disabled className="form-control"  id="exampleInputBrand" placeholder="Product Type" {...register("")} />
                                                    </div> */}
                                                    <span className='text-red w-100'>{errors?.company_delivery_date?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Carton Basic Rate</label>
                                                    <div className="input-group">
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Carton Basic Rate" {...register("carton_basic_rate", { required: { value: true, message: "District Name is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    {/* <span className='text-red w-100'>{errors?.name?.message}</span> */}
                                                </div>
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">GST %</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" id="exampleInputBrand" placeholder="GST %" {...register("gst")} />
                                                       
                                                    </div>
                                                   
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">GST Amount</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" id="exampleInputBrand" placeholder="GST Amount" {...register("gst_amount")} />
                                                       
                                                    </div>
                                                    
                                                </div> */}

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Carton Amount</label>
                                                    <div className="input-group">
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Carton Amount" {...register("carton_amount", { onChange: handleCavityName, required: { value: true, message: "District Name is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.carton_amount?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Stick Rate</label>
                                                    <div className="input-group">
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Stick Rate" {...register("stick_rate", { onChange: handleCavityName, required: { value: true, message: "District Name is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    {/* <span className='text-red w-100'>{errors?.name?.message}</span> */}
                                                </div>
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">ML Price List</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" id="exampleInputBrand" placeholder="ML Price List" {...register("ml_price_list")} />
                                                        
                                                    </div>
                                                   
                                                   
                                                </div> */}
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Stick In Nos.</label>
                                                   
                                                     <div className="input-group">
                                                        <input type="text" disabled className="form-control" id="exampleInputBrand" placeholder="Stick In Nos." {...register("stick_nos", { onChange: handleCavityName, required: { value: false, message: "GST No. is required" } })} />
                                                        
                                                    </div>
                                                   
                                                </div> */}


                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Carton In Stick Qty.</label>
                                                    <div className="input-group">
                                                    <input type="text" disabled className="form-control" id="exampleInputBrand" placeholder="Carton In Stick Qty." {...register("carton_stick")} />

                                                    </div>
                                                    
                                                </div> */}

                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Total Pcs Qty</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" id="exampleInputBrand" placeholder="Product Total Pcs Qty" {...register("product_total_qty")} />
                                                        
                                                    </div>
                                                    
                                                </div> */}

                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Single Pcs Weight</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" id="exampleInputBrand" placeholder="Product Single Pcs Weight" {...register("product_weight_price_list")} />
                                                       
                                                    </div>
                                                    
                                                </div> */}
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Expected Basic Rate</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" id="exampleInputBrand" placeholder="Expected Basic Rate" {...register("pan_no", { onChange: handleCavityName, required: { value: false, message: "TDS Details Number is required" } })} />
                                                        
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.name?.message}</span>
                                                </div> */}
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Carton Weight</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" id="exampleInputBrand" placeholder="Carton Weight" {...register("carton_weight")} />
                                                        
                                                    </div>
                                                    
                                                </div> */}
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Requirment Date</label>
                                                    <DatePicker
                                                        className="form-control"
                                                        format={dateFormatList}
                                                        value={forFromDate}
                                                        {...register("from_date", { required: { value: false, message: "Field is required" } })}

                                                        // onChange={handleFromDate}
                                                    />
                                                    <span className='text-red w-100'>{errors?.name?.message}</span>
                                                </div> */}
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Requirment Status </label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" id="exampleInputBrand" placeholder="Requirment Status" {...register("requirement_status")} />

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.type?.message}</span>
                                                </div>
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1"> Product Description</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product Description" {...register("product_description", { onChange: handleCavityName, required: { value: false, message: "TDS Details Number is required" } })} />

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_description?.message}</span>
                                                </div> */}

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1"> Lead Source Of Inquiry</label>
                                                    <div className="input-group">

                                                        <input type="text" disabled className="form-control" id="exampleInputBrand" placeholder="Lead Source Of Inquiry" {...register("lead_source_of_inquiry")} />

                                                    </div>
                                                    {/* <span className='text-red w-100'>{errors?.name?.message}</span> */}
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Customer Name</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" id="exampleInputBrand" placeholder="Customer Name" {...register("company_name_and_city")} />

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.customer_name?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Mobile Number</label>
                                                    <div className="input-group">
                                                        <input type="number" disabled className="form-control" id="exampleInputBrand" placeholder="Mobile Number" {...register("mobile_number")} />
                                                        {/* <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div> */}
                                                    </div>
                                                    {/* <span className='text-red w-100'>{errors?.name?.message}</span> */}
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">E-Mail Address</label>
                                                    <div className="input-group">
                                                        <input type="email" disabled className="form-control" id="exampleInputBrand" placeholder="E-Mail Address" {...register("email")} />

                                                    </div>
                                                    {/* <span className='text-red w-100'>{errors?.name?.message}</span> */}
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Pin Code No.</label>
                                                    <div className="input-group">
                                                        <input type="number" disabled className="form-control" id="exampleInputBrand" placeholder="Pin Code No." {...register("pincode")} />

                                                    </div>
                                                    {/* <span className='text-red w-100'>{errors?.name?.message}</span> */}
                                                </div>
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Allow</label>
                                                    <div className="input-group">
                                                    <select className="form-select" {...register("gst_category", { required: { value: false, message: "Cavity Type is required" } })}>
                                                        <option value={""} selected>Select Any</option>
                                                        
                                                    </select>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.name?.message}</span>
                                                </div> */}
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Address</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder="Address" {...register("address", {  required: { value: true, message: "Late Payment Interest % is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.address?.message}</span>
                                                </div> */}

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Village Code & Name</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" id="exampleInputBrand" placeholder="Village Code & Name" {...register("city_and_village")} />

                                                    </div>
                                                    {/* <span className='text-red w-100'>{errors?.name?.message}</span> */}
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Taluka</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" id="exampleInputBrand" placeholder="Taluka"
                                                            {...register("taluka")} />

                                                    </div>
                                                    {/* <span className='text-red w-100'>{errors?.name?.message}</span> */}
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">District</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" id="exampleInputBrand" placeholder="District"
                                                            {...register("district")} />

                                                    </div>
                                                    {/* <span className='text-red w-100'>{errors?.name?.message}</span> */}
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">State</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" id="exampleInputBrand" placeholder="State"
                                                            {...register("state", { required: { value: false, message: "State is required" } })} />

                                                    </div>
                                                    {/* <span className='text-red w-100'>{errors?.name?.message}</span> */}
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Country</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" id="exampleInputBrand" placeholder="Country"
                                                            {...register("country", { required: { value: false, message: "Country is required" } })} />

                                                    </div>
                                                    {/* <span className='text-red w-100'>{errors?.name?.message}</span> */}
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Reference Name</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" id="exampleInputBrand" placeholder="Reference Name"
                                                            {...register("reference_name")} />

                                                    </div>
                                                    {/* <span className='text-red w-100'>{errors?.name?.message}</span> */}
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Lead Contacted Date & Time</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" id="exampleInputBrand" placeholder="Lead Contacted Date & Time"
                                                            {...register("lead_contacted")} />

                                                    </div>
                                                    {/* <span className='text-red w-100'>{errors?.name?.message}</span> */}
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="textareaField" className="form-label">Delivery Address</label>
                                                    <div className="d-flex row" >

                                                        <Select
                                                            {...register("delivery_address", { required: { value: false, message: "Delivery Address is required" }, maxLength: 30, })}
                                                            // value={DeliveryValue}
                                                            options={optionsDelivery}
                                                            onChange={handleDelivery}
                                                            components={customIndicator}
                                                            className="dropdown-select"
                                                            value={selDelivery === "" ? optionsDelivery?.find((e: any) => e.value === selectedDelivery) : selDelivery}

                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenCustomerDetails(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.delivery_address?.message}</span>
                                                </div>


                                            </div>
                                            <div className="row d-flex">
                                                <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                                    <div className="row d-flex ">
                                                        <div className="col-sm-3 mt-2 image-upload">
                                                            <label htmlFor="exampleInputEmail1" className="col-12">Visiting Card Image</label>
                                                            <label style={{ cursor: "pointer" }}>
                                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                                <input data-required="image" disabled type="file" id="file_upload" accept="image/*" className="image-input" data-traget-resolution="image_resolution" hidden {...register("visiting_card_image")} />
                                                            </label>
                                                            <a href={data?.visiting_card_image} target="_blank" ><p>{visitingCard == null ? data?.visiting_card_image : visitingCard}</p></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="table-responsive" >


                                            <table className="table" style={{ marginTop: "20px", height: "auto" }}>
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Product<br />Type</th>
                                                        <th>Special/Running</th>
                                                        <th>Product Code &<br />Name & Type</th>
                                                        <th>U.O.M</th>
                                                        <th>Product <br />Description</th>
                                                        <th>Product<br />Qty</th>
                                                        <th>Requirement<br />Date</th>
                                                        <th>Expected<br />Basic Value</th>
                                                        <th>Possible<br />Yes/No</th>
                                                        <th>Remark</th>
                                                        {/* <th>Approved Discount</th>
       <th>Gst %</th>
       <th>Gst Amount</th>
       <th>ML Price List</th> 
       <th>Stick In Nos.</th>
       <th>Carton In Stick Qty.</th>
       <th>Product Total Pcs Qty</th>
       <th>Product Single Pcs Weight</th>
       <th>Carton Weight</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>



                                                    {product?.map((field: any, index: any) => {

                                                        return (

                                                            <tr key={index} >
                                                                <td>{field?.id}</td>
                                                                <td>
                                                                    {/* <Select
     options={optionsProductType}
   value={optionsProductType?.find((option:any) => option.value === field?.product_type?.id)}
   isDisabled
      /> */}
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        disabled
                                                                        placeholder="Product Type"
                                                                        value={field?.product_type?.type}

                                                                    />

                                                                </td>
                                                                <td>
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        disabled
                                                                        placeholder="Special/Running"
                                                                        value={field.product_special_running}

                                                                    />


                                                                </td>
                                                                <td>


                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        disabled
                                                                        //  value={`${field?.pricelist_product?.product_code?.product_code}::${field?.pricelist_product?.product_code?.product_type?.type}::${field?.pricelist_product?.product_code?.mouldshape?.name}`}
                                                                        value={`${field?.pricelist_product != null ? `${field?.extra_response?.product_code}::${field?.extra_response?.product_name}::${field?.extra_response?.mouldshape_name}` : '-'}`}

                                                                    />

                                                                </td>

                                                                <td>
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        disabled
                                                                        //  value={field?.uom?.name?.name}
                                                                        value={field.extra_response?.uom}

                                                                    />

                                                                </td>
                                                                <td>
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        disabled
                                                                        placeholder="Product Description"
                                                                        value={field.product_description}
                                                                        onChange={handleCavityName}
                                                                    />

                                                                </td>
                                                                <td>
                                                                    <input
                                                                        className="form-control"
                                                                        type="number"
                                                                        disabled
                                                                        placeholder="Product Total Qty."
                                                                        value={field.product_qty}

                                                                    />

                                                                </td>
                                                                <td>
                                                                    {/* <DatePicker
     format={dateFormatList}
     disabled
   //   value={field.requirement_date}
   value={dayjs(field.requirement_date, "DD/MM/YYYY")}
    
     
   /> */}
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        disabled
                                                                        placeholder="Date"
                                                                        value={field.requirement_date}

                                                                    />

                                                                </td>
                                                                <td>
                                                                    <input
                                                                        className="form-control"
                                                                        type="number"
                                                                        disabled
                                                                        placeholder="Expected Basic Value"
                                                                        value={field.expected_basic_value}

                                                                    />

                                                                </td>
                                                                <td>
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        disabled
                                                                        placeholder="Possible Yes/No"
                                                                        value={field.possible_yes_no}

                                                                    />

                                                                </td>

                                                                <td>

                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        disabled
                                                                        placeholder="Remark"
                                                                        value={field?.remark}

                                                                    />

                                                                </td>
                                                                {/* <td>
 
 <input
   className="form-control"
     type="text"
     disabled
     placeholder="Approved Discount"
     value={field?.approved_discount}
     
   />

 </td>
 <td>

 <input
   className="form-control"
     type="text"
     disabled
     placeholder="Gst %"
     value={field?.pricelist_product?.product_code?.product_name_and_grade?.gst?.india_GST}
     
   />

 </td>
 <td>
 <input
   className="form-control"
     type="text"
     disabled
     placeholder="Gst Amount"
    //  value={field?.pricelist_product?.product_code?.product_ml_price_list}
     
   />

   

 </td>
 <td>
 

 <input
   className="form-control"
     type="text"
     disabled
     placeholder="ML Price List"
     value={field?.pricelist_product?.product_code?.product_ml_price_list}
     
   />
 
 </td>

 <td>
 <input
   className="form-control"
     type="text"
     disabled
     placeholder="Stick In Nos."
     value={field?.pricelist_product?.product_code?.stick_nos}
     
   />

 </td>
 <td>
 <input
   className="form-control"
     type="text"
     disabled
     placeholder="Carton In Stick Qty."
     value={field?.pricelist_product?.product_code?.carton_total_nos}
     
   />
 
 </td>
 <td>
   <input
   className="form-control"
     type="number"
     disabled
     placeholder="Product Total Pcs Qty"
    //  value={field.product_qty}
     
   />
   
 </td> 
 <td>

 <input
   className="form-control"
     type="text"
     disabled
     placeholder="Product Single Pcs Weight"
     value={field?.pricelist_product?.product_code?.product_single_piece_code?.product_1_pcs_weight}
     
   />
   
 </td>
 <td>
 <input
   className="form-control"
     type="text"
     disabled
     placeholder="Carton Weight"
     value={field?.pricelist_product?.product_code?.carton_code_and_grade_and_name?.carton_empty_weight}
     
   />
   
 </td>*/}

                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="row d-flex  ">
                                            <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                                <div className="row d-flex ">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                        <button
                                                            type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                            onClick={handleSubmit(onSubmit)}
                                                        >Submit</button>

                                                        <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listLeadGenerated")}>Cancel</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
            <Customer_Details_Model open={OpenCustomerDetails} setOpen={setOpenCustomerDetails} setDataAdded={setDataAdded}
                customerId={CustomerId} dataAdded={dataAdded} forTab={forTab} setOptionsDelivery={setOptionsDelivery} />

            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />

            <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                                {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>
                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Edit_LeadGeneraed