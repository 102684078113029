import React, { useEffect,useRef } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import "../../components/Table/table.css"
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";
import Select from 'react-select'
import axios from 'axios'
import { isConstructorDeclaration } from "typescript";
import { excel } from "react-export-table-to-excel/lib/lib";
import { useCreate } from "../../hooks/Api";
import { Alert } from "../../components/Model/Model";
import { useFetchCartonDropDown, useFetchColorDropDown, useFetchMaterialDensityDropDown, useFetchMaterialTypeDropDown, useFetchMouldShapeDropDown, useFetchPanniDropDown, useFetchPiceCodeDropDown, useFetchProductMLDropDown, useFetchStickDropDown } from "../../hooks/DropDown_Api";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../components/focus";
import CreatableSelect from "react-select/creatable";
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'
const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
  }
  
  const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
  }

interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
  }
  const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active",
  });


const Add_DisposableProduct = () => {
    const focus:any = useRef();
    const {onEnterKey} = useFocusOnEnter(focus);

    const navigate = useNavigate()

    const apiname = process.env.REACT_APP_DISPOSABLE_PRODUCT_API?.toString()
    const getRelatedHsn = process.env.REACT_APP_GET_RELATED_HSN_API?.toString()

    const { mutate, data, isError, isLoading,isSuccess, error }:any = useCreate(apiname)
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue, getValues }: any = useForm({
        mode: "onChange"
    });


    const [panniPrinting, setpanniPrinting] = useState("no")
    const [panniGadget, setpanniGadget] = useState("no")
    const [panniKan, setpanniKan] = useState("no")
    const [logo, setlogo]:any = useState(null)
    const [logoPdf, setlogoPdf]:any = useState(null)

    const [options, setOptions] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [searchTermpiceCode, setSearchTermpiceCode] = useState('')
    const [searchTermMould, setSearchTermMould] = useState('')
    const [ColorTotal, setColorTotal] = useState([])
    const [color, setColor] = useState([])
    const [brand, setBrand] = useState([])
    const [brandtype, setBrandType] = useState("")

    const [optionspanni, setOptionsPanni] = useState([])
    const [optionsstick, setOptionsStick] = useState([])
    const [optionscarton, setOptionsCarton] = useState([])
    const [optionsPiceCode, setOptionsPiceCode] = useState([])

    const [colorNamePanni, setColorNamePanni]: any = useState({})
    const [colorNameStick, setColorNameStick]: any = useState({})
    const [piceCode, setPiceCode]: any = useState({})
    const [Mould, setMould]: any = useState({})
    const [optionsProductType, setOptionsProductType] = useState([]);

    const [optionsMould, setOptionsMould] = useState([])
    const [searchHSN, setSearchHSN] = useState('')
    const [searchTermPanni, setSearchTermPanni] = useState('')
    const [searchTermStick, setSearchTermStick] = useState('')

    const [optionsPanniName, setOptionsPanniName] = useState([])
    const [panniName, setPanniName]: any = useState("")
    const [productType, setProductType] = useState("");


    const   onLogoChange = (e: any) => {
        setlogo(e.target.files[0].name)
    }

    const onLogoPDFChange = (e: any) => {
        setlogoPdf(e.target.files[0].name)
    }

    const { data: colorData, refetch: colorRefetch } = useFetchColorDropDown(searchTerm)

    useEffect(() => {
        setOptions(colorData)
    }, [colorData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            colorRefetch()
        }
    }, [])





    const handleInputChange = (e: any) => {
        setSearchTerm(e)
    }
    const handleChange = (e: any) => {

        // const check = e?.map((value: any) => {
        //     return value.value
        // })
        setValue("product_color", e.value)

    }
    // const handleChangeColor = (e: any) => {

    //     const printing_color_name = e.map((value: any) => {
    //         return value.value
    //     })
    //     setValue("printing_color_name", printing_color_name)
    //     setValue("printing_total_color", e.length)
    // }





    const { data: panniData, refetch: panniRefetch } = useFetchPanniDropDown(searchTerm)

    useEffect(() => {
        setOptionsPanni(panniData)
    }, [panniData])


    useEffect(() => {
        if (searchTermPanni.length >= 1) {
            panniRefetch()
        }
    }, [])



    const handleInputChangePanni = (e: any) => {
        setSearchTermPanni(e)
    }

    const handleChangePanni = (e: any) => {

        setColorNamePanni(e)
        let color = e?.color?.map((obj:any)=>{
            return {
            value:obj.id,
            label:obj.name    
            }
        })
        setColor(color)
        setColorTotal(e?.printing_total_color)
        setBrand(e?.brand?.name)
        setBrandType(e?.brand?.type)

        setValue("panni_code_and_grade_name", e?.value)
        setValue("panni_printing_color_name", color)
        setValue("product_brand", e?.brand?.id)
        setValue("panni_printing_total_color", e?.printing_total_color)
        setValue("our_product_brand", e?.brand?.type)
        

    }



    const { data: stickData, refetch: stickRefetch } = useFetchStickDropDown(searchTerm)

    useEffect(() => {
        setOptionsStick(stickData)
    }, [stickData])


    useEffect(() => {
        if (searchTermStick.length >= 1) {
            stickRefetch()
        }
    }, [])



    const handleInputChangeStick = (e: any) => {
        setSearchTermStick(e)
    }

    const handleChangeStick = (e: any) => {
        setColorNameStick(e)
        setValue("stick_packing_type", `${e.value}`)


    }
    const { data: picecodeData, refetch: picecodeRefetch } = useFetchPiceCodeDropDown(searchTerm)

    useEffect(() => {
        setOptionsPiceCode(picecodeData)
    }, [picecodeData])

    useEffect(() => {
        if (searchTermpiceCode.length >= 1) {
            picecodeRefetch()
        }
    }, [])



    const handleInputChangePiceCode = (e: any) => {
        setSearchTermpiceCode(e)
    }

    const handleChangePiceCode = (e: any) => {


        setPiceCode(e)
        setValue("product_single_piece_code", e.value)
    }

    const { data:mouldData, refetch:mouldRefetch } = useFetchMouldShapeDropDown(searchTerm)

    useEffect(() => {
        setOptionsMould(mouldData)
    }, [mouldData])


    useEffect(() => {
        if (searchTermMould.length >= 1) {
            mouldRefetch()
        }
    }, [])



    const handleInputChangeMould = (e: any) => {
        setSearchTermMould(e)
    }

    const handleChangeMould = (e: any) => {
        setMould(e)
        setValue("mouldshape", e.value)
    }

    const { data: cartonData, refetch: cartonRefetch } = useFetchCartonDropDown(searchTerm)

    useEffect(() => {
        setOptionsCarton(cartonData)
    }, [cartonData])


    useEffect(() => {
        if (searchTermStick.length >= 1) {
            cartonRefetch()
        }
    }, [])



    const handleInputChangeCarton = (e: any) => {
        setSearchTermStick(e)
    }

    const handleChangeCarton = (e: any) => {
        setColorNameStick(e)
        setValue("carton_code_and_grade_and_name", e.value)


    }


    const { data: materialdensityData, refetch: materialdensityRefetch } = useFetchMaterialDensityDropDown(searchTerm)

    useEffect(() => {
        setOptionsPanniName(materialdensityData)
    }, [materialdensityData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            materialdensityRefetch()
        }
    }, [])
  

    const handleInputChangePanniName = (e: any) => {
        setSearchHSN(e)
    }


    const handleChangePanniName = async (e: any) => {
        setPanniName(e)
        setValue("product_name_and_grade", `${e.value}`)
    }   
    const { data: productTypeData, refetch: productTypeRefetch } =
    useFetchMaterialTypeDropDown(searchTerm);

  useEffect(() => {
    setOptionsProductType(productTypeData);
  }, [productTypeData]);

  useEffect(() => {
    if (searchTerm.length >= 1) {
      productTypeRefetch();
    }
  }, []);

  const handleChangeProductType = (e: any) => {
    setProductTypeValue(e);
    setProductType(e.label);
    setValue("product_type", e.value);
    setValue("panni", "");
  };
  const [prductTypeValue, setProductTypeValue]: any = useState();
    const handleReset = () => {
        reset()
        setProductTypeValue(null)
        setProductType("")
        setPiceCode("")
        setlogo("")
        setlogoPdf("")
    }

    const onSubmit = (pannidetail: any) => {
        if(!logo){
            delete pannidetail.disposable_product_image
        }
        if(!logoPdf){
            delete pannidetail.disposable_product_drawing
        }
        const formData = new FormData();

        for (const [key, value] of Object.entries(pannidetail)) {
            const formvalue: any = value

            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            // else if (formvalue.hasOwnProperty('size')) {
            //     formData.append(key, formvalue[0])
            // }
            else if (Array.isArray(value) && key === "product_color") {
                value.forEach((val, index) => formData.append(`product_color`, val))
            }
            // else if (Array.isArray(value) && key === "printing_color_name") {
            //     value.forEach((val, index) => formData.append(`printing_color_name`, val))
            // }
            else if (key === "disposable_product_image" || key === "disposable_product_drawing") {
                if (typeof value === 'object' && value !== null) {
                    formData.append(key, formvalue[0])
                }
                else {
                    formData.append(key, "")
                }
            }

        }

        mutate(formData);
    }

    // const [panniMicron, setPanniMicron] = useState(0)

    // const check = panniMicron


     
    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            // setshowmessage("success")
            navigate('/listDisposableproducts', { state: { showToast: true }})
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error,isSuccess])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    const handleMlActual=(e:any)=>{
        let Capital = e.target.value
        setValue("product_ml_actual", Capital.charAt(0).toUpperCase() + Capital.slice(1))

    }

    const handleCartonInStickPacking=(e:any)=>{
        let Capital = e.target.value
        setValue("carton_in_stick_packing", Capital.charAt(0).toUpperCase() + Capital.slice(1))

    }

    const [priceListValue, setPriceListValue]: any = useState();


const { data: priceData, refetch: priceRefetch } =
useFetchProductMLDropDown(searchTerm);

useEffect(() => {
setOptionsPriceList(priceData);
}, [priceData]);

const apinameForPrice = process.env.REACT_APP_PRICELIST_NAME_API?.toString();

const {
mutate: mutatePrice,
data: dataPrice,
isError: cpriceIsError,
isLoading: priceLoading,
isSuccess: priceSuccess,
error: priceError,
}: any = useCreate(apinameForPrice);

const handleValue = (e: any) => {
    e.target.value = e.target.value
      .toLowerCase()
      .split(" ")
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  };



const [valuePriceList, setValuePriceList]: any = useState();

const [isLoadingValue, setIsLoadingValue]: any = useState();

const [optionsPriceList, setOptionsPriceList]: any = useState([]);

const [valuePriceCreatable, setValuePriceCreatable] =
  useState<Option | null>();

const handleCreatePrice = (inputValue: string) => {
  // setValueCavityCreatable(inputValue)

  setIsLoadingValue(true);
  setTimeout(() => {
    const newOption = createOption(inputValue);
    setIsLoadingValue(false);

    // setValue("name", `${newOption?.value}`)

    mutatePrice({
      ProductPriceListML: newOption.name,
      status: newOption.status,
    });
    setOptionsPriceList((prev: any) =>
      prev ? [...prev, newOption] : [newOption]
    );
    setValuePriceCreatable(newOption);
  }, 0);
};





useEffect(() => {
    
    (async () => {
        if(priceSuccess && optionsPriceList){
        await priceRefetch();
        }
        setValue("product_ml_price_list",priceData[0]?.value)
    })();
    
    return () => {
    };
  }, [priceSuccess,priceData]);

  let ok = getValues("product_ml_price_list")









useEffect(()=>{
    if(piceCode){
    setpanniGadget(piceCode?.panni_gadget)
    setpanniKan(piceCode?.panni_kan)
    }
},[piceCode])

const handleChangePriceList = (e: any) => {
    setValue("product_ml_price_list", `${e?.value}`);
    setValuePriceCreatable(null);
  };


   

  const onPrintingChange = (e: any) => {
    setpanniPrinting(e.target.value)
}

const [optionsPrinting, setOptionsPrinting]:any = useState([])
const[forColorsValue,setForColorsValue]:any = useState([])


const { data: colorPrintingData , refetch: colorPrintingRefetch } = useFetchColorDropDown(searchTerm)

useEffect(() => {
    setOptions(colorPrintingData);
setOptionsPrinting(colorPrintingData)
}, [colorPrintingData])

const handleChangePrintingColor = (e: any) => {

        
    const printing_color_name = e.map((value: any) => {
        return value.value
    })
    setValue("printing_color_name",printing_color_name)
    setColorTotal(e.length)
    setValue("printing_total_color",e.length)
    setForColorsValue(e)
}

    return (
        <div className="scroll">

            <div 
            className="content-wrapper " 
            style={{ overflow:"auto" }}
            >
                <section className="content-header px-3 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>All Product </h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Product Details</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listDisposableProduct" className="text-decoration-none text-black">All Product List</Link></li>
                                    <li className="breadcrumb-item active">Add All Product</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Disposable Product</span></h5>
                            </div> */}
                            <form
                             ref={focus}
                             onKeyUp={event => onEnterKey(event)} 
                              onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{maxHeight:"80vh"}}>                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message} /> : ""}

                                    {/* <div className="row">
                                        <h5 className="mb-0 mt-2">Disposable Product Detail</h5>
                                    </div> */}
                                    <div className="row d-flex justify-content-md-  center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-sm-3 mt-2">
                                                <label htmlFor="exampleInputEmail1">Product Type <span style={{ color: "red" }}>*</span></label>
                                                    <Select
                                                        // id="Panni Name & Grade"
                                                        {...register("product_type", {
                                                        required: {
                                                            value: true,
                                                            message: "Field is required",
                                                        },
                                                        })}
                                                        placeholder="Product Type"
                                                        onChange={handleChangeProductType}
                                                        options={optionsProductType}
                                                        value={prductTypeValue}
                                                        components={customIndicator}
                                                        isOptionDisabled={(optionsProductType: any) => {
                                                        if (optionsProductType.status === "inactive" || optionsProductType.deleted == true) {
                                                            return optionsProductType
                                                        }
                                                    }}
                                                    />
                                                    <span className="text-red w-100">
                                                        {errors?.product_type?.message}
                                                    </span>
                                                    </div>




                                                    {productType === "Disposable"&& <>


                                                    {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Code</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number"   className="form-control" id="exampleInputBrand" placeholder="Product Code" {...register("product_code", { required: false })} />
                                                    </div>
                                                </div> */}


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Name & Grade <span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        // id="Panni Name & Grade"
                                                        {...register("product_name_and_grade", { required: { value: true, message: "Panni Name and Grade is required" } })}
                                                        placeholder={'Panni Name & Grade'}
                                                        onInputChange={handleInputChangePanniName}
                                                        onChange={handleChangePanniName}
                                                        options={optionsPanniName}
                                                        components={customIndicator}
                                                        isOptionDisabled={(optionsProductType: any) => {
                                                            if (optionsProductType.status === "inactive" || optionsProductType.deleted == true) {
                                                                return optionsProductType
                                                            }
                                                        }}
                                                    />
                                                    <span className='text-red w-100'>{errors?.product_name_and_grade?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Color Name <span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        {...register("product_color", { required: { value: true, message: "Color is required" } })}
                                                        placeholder={'Select Color'}
                                                        onInputChange={handleInputChange}
                                                        onChange={handleChange}
                                                        options={options}
                                                        components={customIndicator}
                                                        isOptionDisabled={(optionsProductType: any) => {
                                                            if (optionsProductType.status === "inactive" || optionsProductType.deleted == true) {
                                                                return optionsProductType
                                                            }
                                                        }}
                                                    />
                                                    <span className='text-red w-100'>{errors?.product_color?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Panni Code & Grad & Name <span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        // id="Panni Name & Grade"
                                                        {...register("panni_code_and_grade_name", { required: { value: true, message: "Panni Code & Grad & Name is required" } })}
                                                        placeholder={'Panni Name & Grade'}
                                                        onInputChange={handleInputChangePanni}
                                                        onChange={handleChangePanni}
                                                        options={optionspanni}
                                                        components={customIndicator}
                                                        isOptionDisabled={(optionsProductType: any) => {
                                                            if (optionsProductType.status === "inactive" || optionsProductType.deleted == true) {
                                                                return optionsProductType
                                                            }
                                                        }}
                                                        
                                                    />
                                                    <span className='text-red w-100'>{errors?.panni_code_and_grade_name?.message}</span>
                                                </div>




                                               
                                              
                                              
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Panni Printing Color Name</label>
                                                    {/* <input type="text" value={color} disabled className="form-control" id="exampleInputBrand" placeholder=" 0" {...register("panni_printing_total_color", { required: false })} /> */}
                                                    <Select
                                                        isDisabled
                                                        placeholder="Panni Printing Color Name"
                                                        {...register("panni_printing_color_name", { required: { value: false, message: "Panni Printing Color Name is required" } })}
                                                        value={color}
                                                        isMulti
                                                        components={customIndicator}
                                                    />
                                                    {/* <span className='text-red w-100'>{errors?.printing_color_name?.message}</span> */}
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Panni Printing Total Color</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" value={ColorTotal} disabled className="form-control" id="exampleInputBrand" placeholder="Panni Printing Total Color" {...register("panni_printing_total_color", { required: false })} />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.panni_printing_total_color?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Brand Name </label>
                                                   
                                                    <input type="text" value={brand} disabled className="form-control" id="exampleInputBrand" placeholder="Product Brand Name" {...register("product_brand", { required: false })} />
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Our Product Brand</label>
                                                        <input type="text" value={brandtype} disabled className="form-control" id="exampleInputBrand" placeholder="Our Product Brand" {...register("our_product_brand", { required: false })} />
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Stick Packing Type <span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        {...register("stick_packing_type", { required: { value: true, message: "Stick Packing Type is required" } })}
                                                        placeholder={'Stick Packing Type'}
                                                        options={optionsstick}
                                                        onInputChange={handleInputChangeStick}
                                                        onChange={handleChangeStick}
                                                        components={customIndicator}
                                                        isOptionDisabled={(optionsProductType: any) => {
                                                            if (optionsProductType.status === "inactive" || optionsProductType.deleted == true) {
                                                                return optionsProductType
                                                            }
                                                        }}
                                                    />
                                                    <span className='text-red w-100'>{errors?.stick_packing_type?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Carton Code & Grade & Name <span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        {...register("carton_code_and_grade_and_name", { required: { value: true, message: "Carton Code & Grade & Name is required" } })}
                                                        placeholder={'Carton Code & Grade & Name'}
                                                        options={optionscarton}
                                                        onInputChange={handleInputChangeCarton}
                                                        onChange={handleChangeCarton}
                                                        components={customIndicator}
                                                        isOptionDisabled={(optionsProductType: any) => {
                                                            if (optionsProductType.status === "inactive" || optionsProductType.deleted == true) {
                                                                return optionsProductType
                                                            }
                                                        }}
                                                    />
                                                    <span className='text-red w-100'>{errors?.carton_code_and_grade_and_name?.message}</span>
                                                </div>


                                                    
                                                    </>}



                                                    {productType === "Lldp" || productType === "Ld" ?(<>

                                                        {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Code</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number"   className="form-control" id="exampleInputBrand" placeholder="Product Code" {...register("product_code", { required: false })} />
                                                    </div>
                                                </div> */}



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Name & Grade <span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        // id="Panni Name & Grade"
                                                        {...register("product_name_and_grade", { required: { value: true, message: "Panni Name and Grade is required" } })}
                                                        placeholder={'Panni Name & Grade'}
                                                        onInputChange={handleInputChangePanniName}
                                                        onChange={handleChangePanniName}
                                                        options={optionsPanniName}
                                                        components={customIndicator}
                                                        isOptionDisabled={(optionsProductType: any) => {
                                                            if (optionsProductType.status === "inactive" || optionsProductType.deleted == true) {
                                                                return optionsProductType
                                                            }
                                                        }}
                                                    />
                                                    <span className='text-red w-100'>{errors?.product_name_and_grade?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Stick Packing Type</label>
                                                    <Select
                                                        {...register("stick_packing_type", { required: { value: false, message: "Stick Packing Type is required" } })}
                                                        placeholder={'Stick Packing Type'}
                                                        options={optionsstick}
                                                        onInputChange={handleInputChangeStick}
                                                        onChange={handleChangeStick}
                                                        components={customIndicator}
                                                        isOptionDisabled={(optionsProductType: any) => {
                                                            if (optionsProductType.status === "inactive" || optionsProductType.deleted == true) {
                                                                return optionsProductType
                                                            }
                                                        }}
                                                    />
                                                    {/* <span className='text-red w-100'>{errors?.printing_color_name?.message}</span> */}
                                                </div>











                                                    <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Panni Code & Grad & Name <span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        // id="Panni Name & Grade"
                                                        {...register("panni_code_and_grade_name", { required: { value: true, message: "Panni Code & Grad & Name is required" } })}
                                                        placeholder={'Panni Name & Grade'}
                                                        onInputChange={handleInputChangePanni}
                                                        onChange={handleChangePanni}
                                                        options={optionspanni}
                                                        components={customIndicator}
                                                        isOptionDisabled={(optionsProductType: any) => {
                                                            if (optionsProductType.status === "inactive" || optionsProductType.deleted == true) {
                                                                return optionsProductType
                                                            }
                                                        }}
                                                        
                                                    />
                                                    <span className='text-red w-100'>{errors?.panni_code_and_grade_name?.message}</span>
                                                </div>




                                               
                                              
                                              
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Panni Printing Color Name</label>
                                                    {/* <input type="text" value={color} disabled className="form-control" id="exampleInputBrand" placeholder=" 0" {...register("panni_printing_total_color", { required: false })} /> */}
                                                    <Select
                                                    placeholder="Panni Printing Color Name"
                                                        isDisabled
                                                        {...register("panni_printing_color_name", { required: { value: false, message: "Panni Printing Color Name is required" } })}
                                                        value={color}
                                                        isMulti
                                                        components={customIndicator}
                                                    />
                                                    {/* <span className='text-red w-100'>{errors?.printing_color_name?.message}</span> */}
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Panni Printing Total Color</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" value={ColorTotal} disabled className="form-control" id="exampleInputBrand" placeholder="Panni Printing Total Color" {...register("panni_printing_total_color", { required: false })} />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.panni_printing_total_color?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Brand Name </label>
                                                   
                                                    <input type="text" value={brand} disabled className="form-control" id="exampleInputBrand" placeholder="Product Brand Name" {...register("product_brand", { required: false })} />
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Our Product Brand</label>
                                                        <input type="text" value={brandtype} disabled className="form-control" id="exampleInputBrand" placeholder="Our Product Brand" {...register("our_product_brand", { required: false })} />
                                                </div>


                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Panni Packing Type</label>

                                                    <select className="form-select" {...register("panni_packing_type", { required: false })}>
                                                    <option value="" selected>Select any</option>
                                                        <option value="Bori">Bori</option>
                                                        <option value="Carton">Carton</option>


                                                    </select>
                                                </div> */}

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Carton Code & Grade & Name</label>
                                                    <Select
                                                        {...register("carton_code_and_grade_and_name", { required: { value: false, message: "Carton Code & Grade & Name is required" } })}
                                                        placeholder={'Carton Code & Grade & Name'}
                                                        options={optionscarton}
                                                        onInputChange={handleInputChangeCarton}
                                                        onChange={handleChangeCarton}
                                                        components={customIndicator}
                                                        isOptionDisabled={(optionsProductType: any) => {
                                                            if (optionsProductType.status === "inactive" || optionsProductType.deleted == true) {
                                                                return optionsProductType
                                                            }
                                                        }}
                                                    />
                                                    {/* <span className='text-red w-100'>{errors?.printing_color_name?.message}</span> */}
                                                </div>


                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Panni Packet Packing Type</label>

                                                    <select className="form-select" {...register("panni_packet_packing_type", { required: false })}>
                                                    <option value="" selected>Select any</option>
                                                        <option value="looz">Looz</option>
                                                        <option value="kilogram">Kilogram</option>


                                                    </select>
                                                </div> */}


 {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Ml Price List <span style={{ color: 'red' }}>*</span></label>



                                                    <CreatableSelect
                                isClearable={() => {
                                  setValuePriceList(null);
                                  setValue(null);
                                }}
                                {...register("product_ml_price_list", {
                                  onChange: handleValue,
                                  required: {
                                    value: true,
                                    message: "Field is required",
                                  },
                                })}
                                placeholder={"price"}
                                options={optionsPriceList}
                                onCreateOption={handleCreatePrice}
                                onChange={handleChangePriceList}
                                value={
                                  valuePriceCreatable == null
                                    ? valuePriceList
                                    : valuePriceCreatable
                                }
                              /> */}
                                                    {/* <div className="input-group" data-colorpicker-id={2}>
                                                        <select className="form-select"  {...register("product_ml_price_list", { required: { value: true, message: "Product Ml Price List is required" } })}>
                                                        <option value={""}  selected>Select Any</option>
                                                        <option value={"275"}>275</option>
                                                        <option value={"200"}>200</option>
                                                        <option value={"150"}>150</option>
                                                        <option value={"100"}>100</option>
                                                        <option value={"125"}>125</option>
                                                        <option value={"500"}>500</option>
                                                        <option value={"250"}>250</option>
                                                        <option value={"225"}>225</option>
                                                        <option value={"120"}>120</option>
                                                        <option value={"400"}>400</option>
                                                        <option value={"90"}>90</option>
                                                        <option value={"300"}>300</option>
                                                        <option value={"180"}>180</option>
                                                        <option value={"350"}>350</option>
                                                    </select>
                                                         
                                                        
                                                    </div> */}
                                                    {/* <span className='text-red w-100'>{errors?.product_ml_price_list?.message}</span>
                                                </div> */}
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Ml Actual <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product Ml Actual" {...register("product_ml_actual", { 
                                                            onChange:handleMlActual,
                                                            required: { value: true, message: "Product Ml Actual is required" } })} />
                                                    
                                                            <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                        </div>
                                                    <span className='text-red w-100'>{errors?.product_ml_actual?.message}</span>
                                                </div>

                                               
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Stick Nos <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Stick Nos" {...register("stick_nos", { required: { value: true, message: "Stick Nos is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.stick_nos?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Stick Qty <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Stick Qty" {...register("stick_qty", { required: { value: true, message: "Stick Qty is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.stick_qty?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Carton Total Nos</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Carton Total Nos" {...register("carton_total_nos", { required: true })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.carton_total_nos?.message}</span>
                                                </div>
                                             
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Carton In Stick Packing <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Carton In Stick Packing"
                                                         {...register("carton_in_stick_packing", { required: { value: true, message: "Carton In Stick Packing is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.carton_in_stick_packing?.message}</span>
                                                </div>
                                            
                                               
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Price List 1 Piece Weight <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Product Price List 1 Piece Weight" {...register("product_price_list_1_piece_weight", { required: { value: true, message: "Product Price List 1 Piece Weight is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_price_list_1_piece_weight?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Production List 1 Piece Weight <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Product Production List 1 Piece Weight" {...register("product_production_list_1_piece_weight", { required: { value: true, message: "Product Production List 1 Piece Weight is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_production_list_1_piece_weight?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Mould Shape</label>
                                                    <Select
                                                        {...register("mouldshape", { required: { value: true, message: "Mould Shape is required" } })}
                                                        placeholder={'Mould Shape'}
                                                        options={optionsMould}
                                                        onInputChange={handleInputChangeMould}
                                                        onChange={handleChangeMould}
                                                        components={customIndicator}
                                                        isOptionDisabled={(optionsProductType: any) => {
                                                            if (optionsProductType.status === "inactive" || optionsProductType.deleted == true) {
                                                                return optionsProductType
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product List Mould Diameter <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Product List Mould Diameter" {...register("product_list_mould_diameter", { required: { value: true, message: "Product List Mould Diameter is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_list_mould_diameter?.message}</span>
                                                </div>


                                                    
                                                    
                                                    
                                                    </>):""}

                                                  







                                              
                                                
                                            </div>
                                        </div>
                                    </div>

                                   
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
           
















{productType === "Disposable"&& <>


<div 
            // className="content-wrapper" 
            // style={{ minHeight: '1345.31px' }}
            >
                <section className="content-header px-3 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Single Code</h4>
                            </div>
                            
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Disposable Product</span></h5>
                            </div> */}
                            <form
                              >
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message} /> : ""}

                                    {/* <div className="row">
                                        <h5 className="mb-0 mt-2">Disposable Product Detail</h5>
                                    </div> */}
                                    <div className="row d-flex justify-content-md-  center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">

                                            <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Single Piece Code <span style={{ color: "red" }}>*</span></label>
                                                    <Select
                                                        {...register("product_single_piece_code", { required: { value: true, message: "Single Piece Code is required" } })}
                                                        placeholder={'Single Piece Code'}
                                                        options={optionsPiceCode}
                                                        onInputChange={handleInputChangePiceCode}
                                                        onChange={handleChangePiceCode}
                                                        components={customIndicator}
                                                        isOptionDisabled={(optionsPiceCode: any) => {
                                                            // if (
                                                            //     optionsPiceCode.status === "inactive" || optionsPiceCode.deleted == true ) {
                                                               
                                                            //     return optionsPiceCode
                                                            // }
                                                            if (productType === "Disposable"){
                                                                if(optionsPiceCode.product_type  != "Disposable"){
                                                                return optionsPiceCode
                                                            }
                                                            }
                                                          
                                                            // if (productType === "LLDP"){
                                                            //     if(optionsProductType.label === "LLDP")
                                                            //     return optionsProductType
                                                            // }
                                                            // if(productType === "Disposable" && optionsProductType.label === "Disposable"){
                                                            //     return optionsProductType
                                                            // }
                                                            // if ()
                                                        }}
                                                    />
                                                    <span className='text-red w-100'>{errors?.product_single_piece_code?.message}</span>
                                                </div>
                                        
                                      





                                    
                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Product Name & Grade
                                
                              </label>
                              <input
                              placeholder="Product Name & Grade"
                              {...register("product_name_and_grade")}
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={piceCode?.product_grade_name}
                                />
                              <span className="text-red w-100">
                                {errors?.product_grade_name?.message}
                              </span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Cavity 
                              </label>
                              <input
                              placeholder="Cavity"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={piceCode?.cavity_to_product?.name}
                                />
                              <span className="text-red w-100">
                                {errors?.cavity_to_product?.message}
                              </span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                HSN 
                              </label>
                              <input
                              placeholder="HSN"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={piceCode?.hsn_code}
                                />
                              <span className="text-red w-100">
                                {errors?.HSN_code?.message}
                              </span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Color 
                              </label>
                              <input
                              placeholder="Color"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={piceCode?.color}
                                />
                              <span className="text-red w-100">
                                {errors?.color?.message}
                              </span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Price List 1-PCS Weight
                              </label>
                              <div className="input-group">
                              <input
                              placeholder="Price List 1-PCS Weight"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={piceCode?.price_list_1_pcs_weight}
                                />
                                <div className="input-group-append">
                                  {/* <span className="input-group-text border-start-0 bg-primary">
                                    <RiPencilLine color="white" />
                                  </span> */}
                                </div>
                              </div>
                              <span className="text-red w-100">
                                {errors?.price_list_1_pcs_weight?.message}
                              </span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Production 1-PCS Weight
                              </label>
                              <div className="input-group">
                              <input
                              placeholder="Production 1-PCS Weight"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={piceCode?.product_1_pcs_weight}
                                />
                                <div className="input-group-append">
                                  {/* <span className="input-group-text border-start-0 bg-primary">
                                    <RiPencilLine color="white" />
                                  </span> */}
                                </div>
                              </div>
                              <span className="text-red w-100">
                                {errors?.product_1_pcs_weight?.message}
                              </span>
                            </div>

                          

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Mould Code And Cavity
                                
                              </label>
                              <input
                              placeholder="Mould Code And Cavity"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={piceCode?.mould_code_and_cavity}
                                />
                              <span className="text-red w-100">
                                {errors?.use_mould_code_and_cavity?.message}
                              </span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Cavity Qty
                              </label>
                              <div className="input-group">
                              <input
                              placeholder="Cavity Qty"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={piceCode?.cavity_qty}
                                />
                                <div className="input-group-append">
                                  {/* <span className="input-group-text border-start-0 bg-primary">
                                    <RiPencilLine color="white" />
                                  </span> */}
                                </div>
                              </div>
                              <span className="text-red w-100">
                                {errors?.cavity_qty?.message}
                              </span>
                            </div>

                         

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Product ML Price List
                              </label>


                              <input
                              placeholder="Product ML Price List"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={piceCode?.product_ml_price_list}
                                />
                           

                            

                              <span className="text-red">
                                {errors?.product_ml_price_list?.message}
                              </span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Product Actual ML
                              </label>
                              <div className="input-group">
                              <input
                              placeholder="Product Actual ML"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={piceCode?.product_actual_ml}
                                />
                                <div className="input-group-append">
                                  {/* <span className="input-group-text border-start-0 bg-primary">
                                    <RiPencilLine color="white" />
                                  </span> */}
                                </div>
                              </div>
                              <span className="text-red w-100">
                                {errors?.product_actual_ml?.message}
                              </span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Product Final Height(MM)
                              </label>
                              <div className="input-group">
                              <input
                              placeholder="Product Final Height(MM)"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={piceCode?.product_final_height_mm}
                                />
                                <div className="input-group-append">
                                  {/* <span className="input-group-text border-start-0 bg-primary">
                                    <RiPencilLine color="white" />
                                  </span> */}
                                </div>
                              </div>
                              <span className="text-red w-100">
                                {errors?.product_final_height_mm?.message}
                              </span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Product Current Height(MM)
                              </label>
                              <div className="input-group">
                              <input
                              placeholder="Product Current Height(MM)"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={piceCode?.product_current_height_mm}
                                />
                                <div className="input-group-append">
                                  {/* <span className="input-group-text border-start-0 bg-primary">
                                    <RiPencilLine color="white" />
                                  </span> */}
                                </div>
                              </div>
                              <span className="text-red w-100">
                                {errors?.product_current_height_mm?.message}
                              </span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Product Lip
                              </label>
                              <div className="input-group">
                              <input
                              placeholder=" Product Lip"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={piceCode?.product_lip}
                                />
                                <div className="input-group-append">
                                  {/* <span className="input-group-text border-start-0 bg-primary">
                                    <RiPencilLine color="white" />
                                  </span> */}
                                </div>
                              </div>
                              <span className="text-red w-100">
                                {errors?.product_lip?.message}
                              </span>
                            </div>
                          



                            </div>
                                        </div>
                                    </div>

                                 
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>



</>}








{productType === "Lldp" || productType === "Ld" ?(<>


<div 
            // className="content-wrapper" 
            // style={{ minHeight: '1345.31px' }}
            >
                <section className="content-header px-3 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Single Code</h4>
                            </div>
                            
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Disposable Product</span></h5>
                            </div> */}
                            <form
                              >
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}

                                    {/* <div className="row">
                                        <h5 className="mb-0 mt-2">Disposable Product Detail</h5>
                                    </div> */}
                                    <div className="row d-flex justify-content-md-  center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">




                                            <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Single Piece Code <span style={{ color: "red" }}>*</span></label>
                                                    <Select
                                                        {...register("product_single_piece_code", { required: { value: true, message: "Single Piece Code is required" } })}
                                                        placeholder={'Single Piece Code'}
                                                        options={optionsPiceCode}
                                                        onInputChange={handleInputChangePiceCode}
                                                        onChange={handleChangePiceCode}
                                                        components={customIndicator}
                                                        maxMenuHeight={130}
                                                        isOptionDisabled={(optionsProductType: any) => {
                                                            // if (optionsProductType.status === "inactive" || optionsProductType.deleted == true) {
                                                            //     return optionsProductType
                                                            // }
                                                            if (productType === "Lldp" || productType === "Ld"){
                                                                
                                                                if(optionsProductType.product_type  != "Lldp" && optionsProductType.product_type  != "Ld"){
                                                                return optionsProductType
                                                            }
                                                            }
                                                        }}
                                                    />
                                                    <span className='text-red w-100'>{errors?.product_single_piece_code?.message}</span>
                                                </div>

                                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Name & Grade
                                
                              </label>
                              <input
                              placeholder=" Name & Grade"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={piceCode.product_grade_name}
                                />
                              <span className="text-red w-100">
                                {errors?.product_grade_name?.message}
                              </span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                HSN 
                              </label>
                              <input
                              placeholder="HSN"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={piceCode.hsn_code}
                                />
                              <span className="text-red w-100">
                                {errors?.HSN_code?.message}
                              </span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Color 
                              </label>
                              <input
                              placeholder="Color"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={piceCode.color}
                                />
                              <span className="text-red w-100">
                                {errors?.color?.message}
                              </span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Panni
                              </label>
                              <input
                              placeholder="Panni"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={piceCode.panni_code}
                                />
                              <span className="text-red w-100">
                                {errors?.panni?.message}
                              </span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Panni Type
                              </label>
                              <div
                                className="input-group"
                                data-colorpicker-id={2}
                              >
                              <input
                              placeholder="Panni Type"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={piceCode.panni_type}
                                />
                              </div>
                              <span className="text-red w-100">
                                {errors?.type?.message}
                              </span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Panni Material
                              </label>
                              <div
                                className="input-group"
                                data-colorpicker-id={2}
                              >
                               <input
                               placeholder="Panni Material"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={piceCode.panni_material}
                                />
                              </div>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Panni Sealing Type
                              </label>
                              <div
                                className="input-group"
                                data-colorpicker-id={2}
                              >
                                <input
                                placeholder="Panni Sealing Type"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={piceCode.panni_sealing_type}
                                />
                              </div>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Panni Length MM{" "}
                              </label>
                              <div
                                className="input-group"
                                data-colorpicker-id={2}
                              >
                                <input
                                placeholder="Panni Length MM"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={piceCode.panni_length}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Panni Width MM{" "}
                              </label>
                              <div
                                className="input-group"
                                data-colorpicker-id={2}
                              >
                                <input
                                placeholder="Panni Width MM"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={piceCode.panni_width}
                                />
                              </div>
                            </div>

                            {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Panni Baloon Width MM <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control"
                                                                disabled
                                                                id="exampleInputBrand" 
                                                            {...register("width", {
                                                                onChange: handleSetPanniWidth, required: { value: true, message: "Width is required" },
                                                                pattern: {
                                                                    value: /^[0-9]+$/,
                                                                    message: "input numbers only"
                                                                }
                                                            })}
                                                        //  onChange={(e:any)=>{
                                                        //     setPanniWidth(e.target.value)
                                                        //  }}
                                                        />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.width?.message}</span>
                                                </div> */}

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Gadget</label>
                              <div
                                className="input-group"
                                data-colorpicker-id={2}
                              >
                               <input
                               placeholder="Gadget"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={piceCode.panni_gadget}
                                />
                              </div>
                            </div>
                            {panniGadget === "yes" ? (
                              <>
                                <div className="col-sm-3 mt-2">
                                  <label htmlFor="exampleInputEmail1">
                                    Gadget Size MM
                                  </label>
                                  <div
                                    className="input-group"
                                    data-colorpicker-id={2}
                                  >
                                    <input
                                    placeholder="Gadget Size MM"
                                      type="text"
                                      className="form-control"
                                      id="exampleInputBrand"
                                      disabled
                                      value={piceCode.gadget_size}
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Panni Kan
                              </label>

                              <div
                                className="input-group"
                                data-colorpicker-id={2}
                              >
                                <input
                                placeholder=" Panni Kan"
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={piceCode.panni_kan}
                                />
                              </div>
                            </div>
                            {panniKan === "yes" ? (
                              <>
                                <div className="col-sm-3 mt-2">
                                  <label htmlFor="exampleInputEmail1">
                                    Panni Kan Length MM
                                  </label>
                                  <div
                                    className="input-group"
                                    data-colorpicker-id={2}
                                  >
                                    <input
                                    placeholder="Panni Kan Length MM"
                                      type="text"
                                      className="form-control"
                                      id="exampleInputBrand"
                                      disabled
                                      value={piceCode.kan_length}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-3 mt-2">
                                  <label htmlFor="exampleInputEmail1">
                                    Panni Kan Width MM
                                  </label>
                                  <div
                                    className="input-group"
                                    data-colorpicker-id={2}
                                  >
                                    <input
                                    placeholder="Panni Kan Width MM"
                                      type="text"
                                      className="form-control"
                                      id="exampleInputBrand"
                                      disabled
                                      value={piceCode.kan_width}
                                    />
                                  </div>
                                </div>
                                {/* <div className="col-sm-3 mt-2">
                                  <label htmlFor="exampleInputEmail1">
                                    Panni Kan Width MM
                                  </label>
                                  <div
                                    className="input-group"
                                    data-colorpicker-id={2}
                                  >
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="exampleInputBrand"
                                      disabled
                                    //   value={panniKanWidth}
                                    />
                                  </div>
                                </div> */}
                              </>
                            ) : (
                              ""
                            )}



                            </div>
                                        </div>
                                    </div>

                                 
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>



</>):""}























































{productType === "Disposable"?(<>


<div 
            // className="content-wrapper" 
            // style={{ minHeight: '1345.31px' }}
            >
                {/* <section className="content-header px-3 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Single Code</h4>
                            </div>
                            
                        </div>
                    </div>
                </section> */}

                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Disposable Product</span></h5>
                            </div> */}
                            <form
                              >
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message} /> : ""}

                                    {/* <div className="row">
                                        <h5 className="mb-0 mt-2">Disposable Product Detail</h5>
                                    </div> */}
                                    <div className="row d-flex justify-content-md-  center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">





  <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Stick Nos <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Stick Nos" {...register("stick_nos", { required: { value: true, message: "Stick Noes is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.stick_nos?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Stick Qty <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Stick Qty" {...register("stick_qty", { required: { value: true, message: "Stick Qty is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.stick_qty?.message}</span>
                                                </div>
                                             
                                             
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Carton In Stick Packing <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Carton In Stick Packing"
                                                         {...register("carton_in_stick_packing", { required: { value: true, message: "Carton In Stick Packing is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.carton_in_stick_packing?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Carton Total Nos</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Carton Total Nos" {...register("carton_total_nos", { required: { value: true, message: "Carton Total Nos is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.carton_total_nos?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Final Stick Height MM <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product Final Stick Height MM"
                                                         {...register("product_final_stick_height_mm", { required: { value: true, message: "Product Final Stick Height MM is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_final_stick_height_mm?.message}</span>
                                                </div>




                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Current Stick Height MM <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product Current Stick Height MM"
                                                         {...register("product_current_stick_height_mm", { required: { value: true, message: "Product Current Stick Height MM is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_current_stick_height_mm?.message}</span>
                                                </div>



                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Panni Printing <span style={{ color: 'red' }}>*</span></label>


                                                    <select className="form-select" {...register("panni_printing", { onChange: onPrintingChange, required: { value: true, message: "Printing is required" } })}>
                                                        <option value={""}  selected>Select Any</option>
                                                        <option value={"yes"}>Yes</option>
                                                        <option value={"no"}>No</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.panni_printing?.message}</span>
                                                </div> */}
                                              
                                                
                                                        {/* <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Panni Printing Type</label>
                                                            <select className="form-select"
                                                                {...register("printing_type", { required: { value: true, message: "Printing Type is required" } })}>
                                                                <option value={""} selected >Select Any</option>
                                                                <option value={"half"}>Half</option>
                                                                <option value={"full"}>Full</option>
                                                                <option value={"continue"}>Continue</option>
                                                            </select>
                                                            <span className='text-red w-100'>{errors?.printing_type?.message}</span>
                                                        </div> */}

                                                        {/* <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Panni Printing Color Name</label>
                                                            <div className="d-flex row" >
                                                            <Select
                                                                {...register("panni_printing_color_name", { required: { value: false, message: "Color is required" } })}
                                                                placeholder={'Select Color'}
                                                                value={forColorsValue}
                                                                options={optionsPrinting}
                                                                // onInputChange={handleChangePrintingColor}
                                                                onChange={handleChangePrintingColor}
                                                                isMulti={true}
                                                                isOptionDisabled={(optionsPanniName:any)=>
                                                                    {
                                                                        if(optionsPanniName.status === "inactive" || optionsPanniName.deleted == true){
                                                                        return optionsPanniName
                                                                        }
                                                                       }}
                                      
                                                                />
                                                            </div>
                                                        </div> */}

                                                        {/* <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Panni Printing Total Color</label>
                                                            <div className="input-group" data-colorpicker-id={2}>
                                                                <input type="text" value={ColorTotal} disabled className="form-control" id="exampleInputBrand" placeholder=" 0" {...register("panni_printing_total_color", { required: false })} />
                                                            </div>
                                                            <span className='text-red w-100'>{errors?.panni_printing_total_color?.message}</span>
                                                        </div> */}




                                                        <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Day Packet Packing Labour Rate  <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Day Packet Packing Labour Rate"
                                                         {...register("day_packet_packing_labour_rate", { required: { value: true, message: "Day Packet Packing Labour Rate is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.day_packet_packing_labour_rate?.message}</span>
                                                </div>


                                                
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Night Packet Packing Labour Rate  <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Night Packet Packing Labour Rate"
                                                         {...register("night_packet_packing_labour_rate", { required: { value: true, message: "Night Packet Packing Labour Rate is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.night_packet_packing_labour_rate?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Day Cartoon Packing Labour Rate  <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Day Cartoon Packing Labour Rate"
                                                         {...register("day_carton_packing_labour_rate", { required: { value: true, message: "Day Cartoon Packing Labour Rate is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.day_carton_packing_labour_rate?.message}</span>
                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Night Cartoon Packing Labour Rate  <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Night Cartoon Packing Labour Rate"
                                                         {...register("night_carton_packing_labour_rate", { required: { value: true, message: "Night Cartoon Packing Labour Rate is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.night_carton_packing_labour_rate?.message}</span>
                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Stock Minimum Qty  <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product Stock Minimum Qty"
                                                         {...register("product_stock_minimum_qty", { required: { value: true, message: "Product Stock Minimum Qty is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_stock_minimum_qty?.message}</span>
                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Stock Maximum Qty  <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product Stock Maximum Qty"
                                                         {...register("product_stock_maximum_qty", { required: { value: true, message: "Product Stock Maximum Qty is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_stock_maximum_qty?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Opening Stock Qty  <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product Opening Stock Qty"
                                                         {...register("product_opening_stock_qty", { required: { value: true, message: "Product Opening Stock Qty is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_opening_stock_qty?.message}</span>
                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Our-Other</label>

                                                    <select className="form-select" {...register("product_our_other", { required: false })}>
                                                    <option value="" selected>Select any</option>
                                                        <option value="our">Our</option>
                                                        <option value="other">Other</option>



                                                    </select>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Debit Day   <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product Debit Day"
                                                         {...register("product_debit_day", { required: { value: true, message: "Product Debit Day is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_debit_day?.message}</span>
                                                </div>










                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Special-Running</label>

                                                    <select className="form-select" {...register("product_special_running", { required: false })}>
                                                    <option value="" selected>Select any</option>
                                                        <option value="special">Special</option>
                                                        <option value="running">Running</option>
                                                        <option value="full_running ">Full Running</option>

                                                    </select>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product By</label>

                                                    <select className="form-select" {...register("product_by", { required: false })}>
                                                    <option value="" selected>Select any</option>
                                                        <option value="manufacturing">Manufacturing</option>
                                                        <option value="job work">Job Work</option>
                                                        <option value="trading">Trading</option>



                                                    </select>
                                                </div>
















                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Location   <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product Location"
                                                         {...register("product_location", { required: { value: true, message: "Product Location is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_location?.message}</span>
                                                </div>






                                                <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Product Image</label>
                                                    <label style={{ cursor: "pointer" }}>
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                        <input data-required="image" type="file" id="file_upload" accept="image/*" className="image-input" data-traget-resolution="image_resolution" defaultValue="" hidden {...register("disposable_product_image",
                                                            { onChange: onLogoChange }
                                                        )} />
                                                    </label>
                                                    <p>{logo == null ? data?.disposable_product_image?.split("/").pop() : logo}</p>
                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Product PDF</label>
                                                    <label style={{ cursor: "pointer" }}>
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                        <input type="file"
                                                            data-required="pdf"
                                                            id="file_upload" accept="application/pdf" className="image-input" data-traget-resolution="image_resolution" defaultValue="" hidden {...register("disposable_product_drawing",
                                                                { onChange: onLogoPDFChange }
                                                            )} />
                                                    </label>
                                                    <p>{logoPdf == null ? data?.disposable_product_drawing?.split("/").pop() : logoPdf}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">

                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        onClick={handleSubmit(onSubmit)} disabled={isLoading}>Submit</button>
                                                    <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listDisposableproducts")}>Cancel</button>


                                                </div>
                                            </div>
                                        </div>
                                    </div>









                            </div>
                                        </div>
                                    </div>

                                 
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>



</>):""}


























{productType === "Lldp" || productType === "Ld" ?(<>


<div 
            // className="content-wrapper" 
            // style={{ minHeight: '1345.31px' }}
            >
                {/* <section className="content-header px-3 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Single Code</h4>
                            </div>
                            
                        </div>
                    </div>
                </section> */}

                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Disposable Product</span></h5>
                            </div> */}
                            <form
                              >
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message} /> : ""}

                                    {/* <div className="row">
                                        <h5 className="mb-0 mt-2">Disposable Product Detail</h5>
                                    </div> */}
                                    <div className="row d-flex justify-content-md-  center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">





  {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Stick Nos <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Add Panni Bursting Strength" {...register("stick_noes", { required: { value: true, message: "Stick Noes is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.stick_noes?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Stick Qty <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Add Panni Bursting Strength" {...register("stick_qty", { required: { value: true, message: "Stick Qty is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.stick_qty?.message}</span>
                                                </div>
                                             
                                             
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Carton In Stick Packing <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Carton In Stick Packing"
                                                         {...register("carton_in_stick_packing", { required: { value: true, message: "Carton In Stick Packing is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.carton_in_stick_packing?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Carton Total Nos</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Carton Total Nos" {...register("carton_total_nos", { required: true })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.carton_total_nos?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Final Stick Height MM <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Carton In Stick Packing"
                                                         {...register("carton_in_stick_packing", { required: { value: true, message: "Carton In Stick Packing is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.carton_in_stick_packing?.message}</span>
                                                </div>




                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Current Stick Height MM <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Carton In Stick Packing"
                                                         {...register("carton_in_stick_packing", { required: { value: true, message: "Carton In Stick Packing is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.carton_in_stick_packing?.message}</span>
                                                </div> */}



                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Panni Printing <span style={{ color: 'red' }}>*</span></label>


                                                    <select className="form-select" {...register("panni_printing", { onChange: onPrintingChange, required: { value: true, message: "Printing is required" } })}>
                                                        <option value={""}  selected>Select Any</option>
                                                        <option value={"yes"}>Yes</option>
                                                        <option value={"no"}>No</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.panni_printing?.message}</span>
                                                </div> */}


                                                {/* <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Panni Printing Type</label>
                                                            <select className="form-select"
                                                                {...register("panni_printing_type", { required: { value: true, message: "Printing Type is required" } })}>
                                                                <option value={""} selected >Select Any</option>
                                                                <option value={"half"}>Half</option>
                                                                <option value={"full"}>Full</option>
                                                                <option value={"continue"}>Continue</option>
                                                            </select>
                                                            <span className='text-red w-100'>{errors?.panni_printing_type?.message}</span>
                                                        </div> */}
                                              
                                                
                                                        {/* <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Panni Printing Type</label>
                                                            <select className="form-select"
                                                                {...register("printing_type", { required: { value: true, message: "Printing Type is required" } })}>
                                                                <option value={""} selected >Select Any</option>
                                                                <option value={"half"}>Half</option>
                                                                <option value={"full"}>Full</option>
                                                                <option value={"continue"}>Continue</option>
                                                            </select>
                                                            <span className='text-red w-100'>{errors?.printing_type?.message}</span>
                                                        </div> */}

                                                        {/* <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Panni Printing Color Name</label>
                                                            <div className="d-flex row" >
                                                            <Select
                                                                {...register("panni_printing_color_name", { required: { value: false, message: "Color is required" } })}
                                                                placeholder={'Select Color'}
                                                                value={forColorsValue}
                                                                options={optionsPrinting}
                                                                onInputChange={handleChangePrintingColor}
                                                                onChange={handleChangePrintingColor}
                                                                isMulti={true}
                                                                isOptionDisabled={(optionsPanniName:any)=>
                                                                    {
                                                                        if(optionsPanniName.status === "inactive" || optionsPanniName.deleted == true){
                                                                        return optionsPanniName
                                                                        }
                                                                       }}
                                      
                                                                />
                                                               
                                                            </div>
                                                        </div> */}

                                                        {/* <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Panni Printing Total Color</label>
                                                            <div className="input-group" data-colorpicker-id={2}>
                                                                <input type="text" value={ColorTotal} disabled className="form-control" id="exampleInputBrand" placeholder=" 0" {...register("panni_printing_total_color", { required: false })} />
                                                            </div>
                                                            <span className='text-red w-100'>{errors?.panni_printing_total_color?.message}</span>
                                                        </div> */}




                                                        <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Day Packet Packing Labour Rate  <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Day Packet Packing Labour Rate"
                                                         {...register("day_packet_packing_labour_rate", { required: { value: true, message: "Day Packet Packing Labour Rate is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.day_packet_packing_labour_rate?.message}</span>
                                                </div>


                                                
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Night Packet Packing Labour Rate  <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Night Packet Packing Labour Rate"
                                                         {...register("night_packet_packing_labour_rate", { required: { value: true, message: "Night Packet Packing Labour Rate is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.night_packet_packing_labour_rate?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Day Cartoon Packing Labour Rate  <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Day Cartoon Packing Labour Rate"
                                                         {...register("day_carton_packing_labour_rate", { required: { value: true, message: "Day Cartoon Packing Labour Rate is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.day_carton_packing_labour_rate?.message}</span>
                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Night Cartoon Packing Labour Rate  <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Night Cartoon Packing Labour Rate"
                                                         {...register("night_carton_packing_labour_rate", { required: { value: true, message: "Night Cartoon Packing Labour Rate is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.night_carton_packing_labour_rate?.message}</span>
                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Stock Minimum Qty  <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product Stock Minimum Qty"
                                                         {...register("product_stock_minimum_qty", { required: { value: true, message: "Product Stock Minimum Qty is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_stock_minimum_qty?.message}</span>
                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Stock Maximum Qty  <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product Stock Maximum Qty"
                                                         {...register("product_stock_maximum_qty", { required: { value: true, message: "Product Stock Maximum Qty is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_stock_maximum_qty?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Opening Stock Qty  <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product Opening Stock Qty"
                                                         {...register("product_opening_stock_qty", { required: { value: true, message: "Product Opening Stock Qty is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_opening_stock_qty?.message}</span>
                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Our-Other</label>

                                                    <select className="form-select" {...register("product_our_other", { required: false })}>
                                                    <option value="" selected>Select any</option>
                                                        <option value="our">Our</option>
                                                        <option value="other">Other</option>



                                                    </select>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Debit Day   <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product Debit Day"
                                                         {...register("product_debit_day", { required: { value: true, message: "Product Debit Day is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_debit_day?.message}</span>
                                                </div>










                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Special-Running</label>

                                                    <select className="form-select" {...register("product_special_running", { required: false })}>
                                                    <option value="" selected>Select any</option>
                                                        <option value="special">Special</option>
                                                        <option value="running">Running</option>
                                                        <option value="full_running ">Full Running</option>

                                                    </select>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product By</label>

                                                    <select className="form-select" {...register("product_by", { required: false })}>
                                                    <option value="" selected>Select any</option>
                                                        <option value="manufacturing">Manufacturing</option>
                                                        <option value="job work">Job Work</option>
                                                        <option value="trading">Trading</option>



                                                    </select>
                                                </div>
















                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Location   <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product Location"
                                                         {...register("product_location", { required: { value: true, message: "Product Location is required" } })} />
                                                    <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_location?.message}</span>
                                                </div>






                                                <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Product Image</label>
                                                    <label style={{ cursor: "pointer" }}>
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                        <input data-required="image" type="file" id="file_upload" accept="image/*" className="image-input" data-traget-resolution="image_resolution" defaultValue="" hidden {...register("disposable_product_image",
                                                            { onChange: onLogoChange }
                                                        )} />
                                                    </label>
                                                    <p>{logo == null ? data?.disposable_product_image?.split("/").pop() : logo}</p>
                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Product PDF</label>
                                                    <label style={{ cursor: "pointer" }}>
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                        <input type="file"
                                                            data-required="pdf"
                                                            id="file_upload" accept="application/pdf" className="image-input" data-traget-resolution="image_resolution" defaultValue="" hidden {...register("disposable_product_drawing",
                                                                { onChange: onLogoPDFChange }
                                                            )} />
                                                    </label>
                                                    <p>{logoPdf == null ? data?.disposable_product_drawing?.split("/").pop() : logoPdf}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">

                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        onClick={handleSubmit(onSubmit)} disabled={isLoading}>Submit</button>
                                                    <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listDisposableproducts")}>Cancel</button>


                                                </div>
                                            </div>
                                        </div>
                                    </div>









                            </div>
                                        </div>
                                    </div>

                                 
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>



</>):""}
















         
</div>

            {/* <Footer /> */}
        </div>
    )
}

export default Add_DisposableProduct