import React, { useEffect, useRef } from "react";
import Header from "../../components/Header/header";
import Sidebar from "../../components/Sidebar/sidebar";
import SearchLogo from "../../images/SearchLogo.png";
import ColorPickerLogo from "../../images/ColorPickerLogo.png";
import "../../components/Table/table.css";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useBrandPost } from "../../hooks/brand";
import { useState } from "react";
import { URL } from "node:url";
import Mui_Toaster from "../../components/Mui_Toaster/Mui_Toaster";
import { useCreate, useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../hooks/Api";
import { useFetchDepartmentDropDown, useFetchEmployeeDropDown, useFetchUserAdminDropDown } from "../../hooks/DropDown_Api";
import { Alert } from "../../components/Model/Model";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../components/focus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import Select from 'react-select'
import { Deletesinglewithdependancy } from "../../components/modal";
import Modal from "react-bootstrap/esm/Modal";
import Axiosinstance from "../../hooks/Interceptor";
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'
import Button from "react-bootstrap/esm/Button";
import { AiOutlinePlus } from "react-icons/ai";
import { Employee_Model } from "../../components/Dynamic_Form_Models/Employee_Model";


const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
  }
  
  const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
  }

const Edit_UserAdmin = ({ checkToast }: any) => {
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const { id } = useParams()
    const navigate = useNavigate()
    const [iconChange, setIconChange] = useState("password")
    const apiname = process.env.REACT_APP_LEOUSER_API?.toString()
    const { data } = useFetchSingle(id, apiname)
    const [optionsUserType, setOptionsUserType]: any = useState([])
    const [valueCavity, setValueCavity]: any = useState();
    const [searchTerm, setSearchTerm] = useState('')
    const [selUser, setSelUser] = useState([])
    const [selectedUser, setSelectedUser]: any = useState([])

    const [openEditModel, setOpenEditModel] = useState(false)
    const [open, setOpen]: any = useState(false)

    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()
    const [expirationDate, setExpirationDate] = useState()

    const { mutate, isSuccess, isError, error, data: UserAdmin, isLoading }: any = useUpdateSingle()
    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue, getValues }: any = useForm({
        mode: "onChange"
    });
    // const {  } = formState;
    // useEffect(()=>{
    //     if(data){
    //         reset()
    //     }
    // },[data])


    const changeicon = () => {
        if (iconChange == "password") {
            setIconChange("text")
        }
        else {
            setIconChange("password")
        }
    }
    const { data: machineNameData, refetch: machineNameRefetch } = useFetchUserAdminDropDown()
    useEffect(() => {
        setOptionsUserType(machineNameData)
    }, [machineNameData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            machineNameRefetch()
        }
    }, [])


    const handleChangeUserType = (e: any) => {
        const groups = e?.map((value: any) => {
            return value.value
        })
        setValue("groups", groups)
        // setValue("groups", groups)
        setSelUser(e)
        setSelectedUser([])
    }





    const onSubmit = (branddetail: any) => {

        if (!password) {
            delete branddetail.password
        }
        // delete branddetail.password
        const formData = new FormData();

        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value

            if (Array.isArray(value) && key === "groups") {
                value.forEach((val, index) => formData.append(`groups`, val))
            }
            else if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            // else if (key === "logo" || key === "logo_pdf"){
            //     if (typeof value === 'object' && value !== null && formvalue.length > 0) {
            //         formData.append(key, formvalue[0])
            //     }
            //     else {
            //         formData.append(key, "")
            //     }
            // }




        }
        let data = {
            apiname: apiname,
            id: id,
            data: formData
        }
        mutate(data);
        setValuesofform(data);

    }

    const [selEmployee, setSelEmployee]: any = useState("")
    const [selectedEmployee, setSelectedEmployee]: any = useState("")


    useEffect(() => {
        if (data) {
            reset(data)
            // setSelectedUser(data?.groups?.map((e: any) => e?.id))
            // setValue("groups", data?.groups?.map((e: any) => e?.id))
            // setSelectedDepartment(data?.department?.map((e:any) => e?.id ))
            // setSelectedDepartment(data?.department?.id)
            // setValue("department",data?.department?.map((e:any) => e?.id ))
            // setValue("department", data?.department?.id)

            // setValue("code_and_employees_name_and_city",data?.code_and_employees_name_and_city?.id )
            // setSelectedEmployee(data?.code_and_employees_name_and_city?.id )


            if (data?.code_and_employees_name_and_city != null || []) {
                setExtendEmployee(true)
            }

            // setEmployeeName(data?.code_and_employees_name_and_city?.name)
            // setEmployeeDepartment(data?.code_and_employees_name_and_city?.department?.department)
            // setEmployeeJoiningDate(data?.code_and_employees_name_and_city?.joining_date)

        }
    }, [data])


    const apinameEmployee = process.env.REACT_APP_PERSON_EMPLOYEE_API?.toString()
    const apinameGroup = process.env.REACT_APP_LEOUSER_GROUP_API?.toString()
    // const [dataProductCode,setDataProductCode]:any = useState([])
    useEffect(() => {
        if (data?.code_and_employees_name_and_city) {
            const getCall = async () => {
                try {
                    const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameEmployee}/${data?.code_and_employees_name_and_city}`);
                    // console.log('Response:', response?.data?.data);
                    setSelectedEmployee(response?.data?.data?.id)
                    setValue("code_and_employees_name_and_city", response?.data?.data?.id)
                    setEmployeeDepartment(response?.data?.data?.extra_response?.department?.department_name)
                    setEmployeeDesignation(response?.data?.data?.extra_response?.designation?.designation_name)
                    setEmployeeMobile(response?.data?.data?.extra_response?.contact_detail?.contact_number)
                    setEmployeeEmail(response?.data?.data?.extra_response?.contact_detail?.email_address)

                } catch (error) {
                    console.error('Error:', error);
                }
            }
            getCall()
        }
        if (data?.groups>0) {
            const getCall = async () => {
                try {
                    const response = await Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apinameGroup}/fetch_multiple_ids/`,{
                        ids:data?.groups
                    });
                    setSelectedUser(response.data?.data?.map((e:any)=>e?.id))
                    setValue("groups", response.data?.data?.map((e:any)=>e?.id))

                } catch (error) {
                    console.error('Error:', error);
                }
            }
            getCall()
        }
    }, [data])



    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess || yessuccess || nosuccess) {
            setShow(true);
            setshowmessage("success");
            navigate("/listLeouser", { state: { showEditToast: true } });
        } else if (isError) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [data, isSuccess, yessuccess, nosuccess, UserAdmin, error,
        isSuccess,]);
    useEffect(() => {
        if (isError) {
            if (error.response?.status === 405) {
                setConfirmmodal(true);
            }
        } else if (isError || yeserror || noerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [isError, yeserror, noerror]);
    useEffect(() => {
        setTimeout(() => {
            setShow(false);
        }, 3000);
    }, [show]);


    const handleUpper = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }
    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }
    const noupdatemodal = () => {
        setOpenEditModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {

        delete data.created_at
        delete data.created_by
        let ok = {
            id: id,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditModel(false)
    }



    // const [optionsDepartment, setOptionsDepartment] = useState([])
    // const [selDepartment, setSelDepartment] = useState('')
    // const [selectedDepartment, setSelectedDepartment]: any = useState("")


    // const { data: departmentData, refetch: departmentRefetch } = useFetchDepartmentDropDown()

    // useEffect(() => {
    //     setOptionsDepartment(departmentData)
    // }, [departmentData])



    // const handleChange = (e: any) => {
    //     // setValue("department", e.value)
    //     setSelDepartment(e)
    // }






    const [employeeDesignation, setEmployeeDesignation] = useState("")
    const [employeeMobile, setEmployeeMobile] = useState("")
    const [employeeEmail, setEmployeeEmail] = useState("")
    const [employeeDepartment, setEmployeeDepartment] = useState("")

    const [optionsEmployee, setOptionsEmployee]: any = useState([])



    const { data: employeeData, refetch: employeeRefetch } = useFetchEmployeeDropDown()
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsEmployee(employeeData)
    }, [employeeData])



    const [extendEmployee, setExtendEmployee] = useState(false)


    const handleEmployeeChange = (e: any) => {


        setSelEmployee(e)
        setEmployeeDesignation(e.designation)
        setEmployeeMobile(e.mobile)
        setEmployeeEmail(e.email)
        setEmployeeDepartment(e.department)
        setValue("department", e.department_id)
        setValue("code_and_employees_name_and_city", e.value)
        // setForEmployeeValue(e)
        setExtendEmployee(true)

    }


    const [password, setPassword] = useState("")

    const handlePassword = (e: any) => {
        setPassword(e.target.value)
        setValue("password", e.target.value)
    }

    const [SubmittedEmployee, setSubmittedEmployee]: any = useState(false)
    useEffect(() => {
        if (SubmittedEmployee) {

            setSelEmployee(optionsEmployee[0])
            setEmployeeDesignation(optionsEmployee[0].designation)
            setEmployeeMobile(optionsEmployee[0].mobile)
            setEmployeeEmail(optionsEmployee[0].email)
            setEmployeeDepartment(optionsEmployee[0].department)

            setValue("code_and_employees_name_and_city", optionsEmployee[0]?.value)

        }

    }, [SubmittedEmployee, optionsEmployee])

    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit User Admin</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Product Details</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listUserAdmin" className="text-decoration-none text-black">User Admin</Link></li>
                                    <li className="breadcrumb-item active">Edit User Admin</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">

                            <form
                                ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh" }}>
                                    {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}
                                    {checkToast && show && showmessage === "success" &&

                                        <Mui_Toaster message={"Brand Added Successfully"} severity={"success"} Isopen={true} />
                                    }




                                    {/* <div className="row mt-2">
                                        <h5 className="mb-0 mt-2">Employee</h5>
                                    </div> */}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">

                                            <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">User ID <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="User ID"  {...register("username", { onChange: handleUpper, required: { value: true, message: "Name is required" }, maxLength: 30, })}

                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.username?.message}</span>
                                                </div>




                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">User Password </label>
                                                    <div className="input-group-form" style={{ width: "100%" }}>
                                                        <input
                                                            type={iconChange ? iconChange : "password"}
                                                            className="form-control rounded-3"
                                                            placeholder="Password"
                                                            {...register("password", { required: false, message: "Password is required" })}
                                                            value={password}
                                                            onChange={handlePassword}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="iconset" id="basic-addon1"><FontAwesomeIcon onClick={changeicon} icon={iconChange == "password" ? faEye : faEyeSlash} /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.password?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name_and_grade">Code & Employees Name & City </label>
                                                    {/* <div className="input-group" ></div> */}
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("code_and_employees_name_and_city", { required: { value: false, message: "Field is required" } })}
                                                            // placeholder={'Department'}
                                                            onChange={handleEmployeeChange}
                                                            // value={forEmployeeValue}
                                                            components={customIndicator}
                                                            options={optionsEmployee}
                                                            maxMenuHeight={120}
                                                            value={selEmployee === "" ? optionsEmployee?.find((obj: any) => obj.value === selectedEmployee) : selEmployee}
                                                        className="dropdown-select"
                                                        // isMulti
                                                        />
                                                        <div className="input-group-append" style={{width:0, padding:0}}>
                                                            <Button className="input-group-text border-start-0 bg-primary"  onClick={()=>{
                                                                setOpen(true)
                                                                }}><AiOutlinePlus/></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.code_and_employees_name_and_city?.message}</span>

                                                </div>


                                                {/* {extendEmployee && <> */}
                                                    <div className="col-sm-3 mt-2">
                                                        <label htmlFor="exampleInputEmail1">Employee Department</label>
                                                        <div className="input-group" data-colorpicker-id={2}>
                                                            <input type="text" className="form-control" id="exampleInputBrand" placeholder="Employee Department"
                                                                disabled
                                                                value={employeeDepartment}
                                                            />
                                                            {/* <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 mt-2">
                                                        <label htmlFor="exampleInputEmail1">Employee Designation</label>
                                                        <div className="input-group" data-colorpicker-id={2}>
                                                            <input type="text" className="form-control" id="exampleInputBrand" placeholder="Employee Designation"
                                                                disabled
                                                                value={employeeDesignation}
                                                            />
                                                            {/* <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 mt-2">
                                                        <label htmlFor="exampleInputEmail1">Employee Mobile No.</label>
                                                        <div className="input-group" data-colorpicker-id={2}>
                                                            <input type="text" className="form-control" id="exampleInputBrand" placeholder="Employee Mobile No."
                                                                disabled
                                                                value={employeeMobile}
                                                            />
                                                            {/* <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 mt-2">
                                                        <label htmlFor="exampleInputEmail1">Employee Email ID</label>
                                                        <div className="input-group" data-colorpicker-id={2}>
                                                            <input type="text" className="form-control" id="exampleInputBrand" placeholder="Employee Email ID"
                                                                disabled
                                                                value={employeeEmail}
                                                            />
                                                            {/* <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">User Type <span style={{ color: 'red' }}>*</span></label>



                                                    <Select
                                                        {...register("groups", { required: { value: true, message: "User Type is required" } })}
                                                        placeholder={'User Type'}
                                                        onChange={handleChangeUserType}
                                                        components={customIndicator}
                                                        options={optionsUserType}
                                                        isMulti
                                                        value={selUser?.length === 0 ? optionsUserType?.filter((obj: any) => selectedUser?.includes(obj?.value)) : selUser}
                                                        maxMenuHeight={120}
                                                    />
                                                    <span className='text-red w-100'>{errors?.groups?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Finger Print ID </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Finger Print ID"  {...register("finger_print_id", { required: { value: false, message: "Finger Print ID is required" }, maxLength: 30, })}
                                                            onChange={handleUpper}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.finger_print_id?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Face ID </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Face ID"  {...register("face_id", { required: { value: false, message: "Face ID is required" }, maxLength: 30, })}
                                                            onChange={handleUpper}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.face_id?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status",
                                                        {
                                                            required: { value: true, message: "Status is required" }, maxLength: 30,

                                                        })}>
                                                        <option value="" selected>Select Any </option>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"inactive"}>Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>
                                                </div>

                                                {/* </>} */}
                                            </div>
                                        </div>
                                    </div>















                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                

                                                {/* <div className="col-sm-3 mt-2">
                                                <label htmlFor="name_and_grade">Department <span style={{ color: "red" }}>*</span></label>
                                                <div className="d-flex row" >
                                                    
                                                    <Select
                                                        {...register("department", { required: { value: true, message: "Department is required" } })}
                                                        placeholder={'Department'}
                                                        onChange={handleChange}
                                                        options={optionsDepartment}
                                                        // isMulti
                                                        value={selDepartment === "" ? optionsDepartment?.find((obj: any) => obj.value === selectedDepartment) : selDepartment}
                                                        // value={selDepartment === "" ? optionsDepartment?.filter((obj: any) => obj.value === selectedDepartment) : selDepartment}
                                                        // value={selDepartment.length === 0 ? optionsDepartment?.filter((obj: any) => selectedDepartment?.includes(obj.value)) : selDepartment}

                                                        />
                                                </div>
                                                <span className='text-red w-100'>{errors?.department_name?.message}</span>

                                            </div> */}









                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Code & Employees Name & City <span style={{color:'red'}}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Code & Employees Name & City"  {...register("code_and_employees_name_and_city",{onChange:handleUpper, required: { value: true, message: "Employees Name & City is required" }, maxLength: 30,})} 
                                                        
                                                        />
                                                         <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                        <span className='text-red w-100'>{errors?.code_and_employees_name_and_city?.message}</span>
                                                </div> */}
                                                
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        onClick={handleSubmit(onSubmit)}
                                                    // disabled={isLoading}
                                                    >Submit</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listLeouser")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>

            <Employee_Model refetchForm={employeeRefetch} open={open} setOpen={setOpen} setSubmitted={setSubmittedEmployee} />

            <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                                {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>
                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>
                </Modal.Footer>
            </Modal>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />
        </div>
    )
}

export default Edit_UserAdmin