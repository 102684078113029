import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Columns_Material_Density, titleofheaders } from "./Columns_MaterialDensity"
import Table from "../../components/Dynamic_Table/Table/Table";
const List_Material_Density = () => {
  const Breadcrumb_Primary_Title = "Home";
  const Breadcrumb_Primary_Link = "Color";

  const Breadcrumb_Parent_Title = "Purchase";

  const Breadcrumb_Title_Name = "Material Density";

  const apiname = process.env.REACT_APP_MATERIAL_DENSITY_API?.toString();

  const filter = {

    material_type_name:"",
    material_density:"",
    gst:"",
    grade_sutra:"",
    grade_polymer:"",
    status:"",

    created_by:"",
  updated_by:"" 

  };

  const addButtonName = "Material Density";
  const addButtonPageName = "MaterialDensity"
  const permissionClass = {
    front: "material_density",
    back: "materialdensity"
  }
  const stickyStart = 1;
  const stickyEnd = 2;

  return (
    <>
      <div className="content-wrapper">
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{Breadcrumb_Title_Name} </h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to={`/list${Breadcrumb_Primary_Link}`}
                      className="text-decoration-none text-black"
                    >
                      {Breadcrumb_Primary_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      {Breadcrumb_Parent_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {Breadcrumb_Title_Name} 
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="container p-0" style={{ maxWidth: "100%" }}>
                {/* <BasicTable/> */}
                <Table
                  apiname={apiname}
                  filterFields={filter}
                  addButtonName={addButtonName}
                  stickyStart={stickyStart}
                  stickyEnd={stickyEnd}
                  TableColumns={Columns_Material_Density}
                  addButtonPageName={addButtonPageName}
                  titleofheaders={titleofheaders}
                  permissionClass={permissionClass}
                />
                
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default List_Material_Density;
