import React, { useState } from "react";
import { BsFillPlusSquareFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Restricted from "../../Roles/Restricted";
import Granual from "../../pages/Production/Granual/Granual";


const GlobalSearch = ({ pageName, handleSearch, buttonName, apiname,permissionClass,isAddButton=true,setNewAdded,setPage }: any) => {
  const navigate = useNavigate();
  let currentPage = window.location.pathname

  
    
  const [onOpen,setOnOpen]:any = useState(false)

  const handleAddColor = () => {
 
  
      navigate(`/${pageName}`);
    
  };

  console.log('onOpen',onOpen)


  return (
    <>
      <div className="col-sm-12 col-md-6 d-flex justify-content-md-end justify-content-sm-center py-1">
        <Restricted
          to={`${permissionClass?.front}.view_${permissionClass?.back}`}
          fallback={""}
        >
          <input
            type="text"
            className="form-control col-6"
            onChange={handleSearch}
            placeholder="Search"
          />
        </Restricted>

        {isAddButton &&
        <>
          <Restricted
          to={`${permissionClass?.front}.add_${permissionClass?.back}`}
          fallback={""}
        >

          {currentPage === '/listProductionline' || currentPage === '/listproductionline' ? 
          <button
          className="btn btn-primary rounded-3 py-2"
          onClick={()=>setOnOpen(true)}
        >
          <BsFillPlusSquareFill className="mx-1 my-0" />
          {buttonName}
        </button>
          :
        

<button
className="btn btn-primary rounded-3 py-2"
onClick={handleAddColor}
>
<BsFillPlusSquareFill className="mx-1 my-0" />
{buttonName}
</button>
        }



        </Restricted>
        </>}

     
     {onOpen  && <Granual setOnOpen={setOnOpen} setNewAdded={setNewAdded}/>}
      </div>
    </>
  );
};

export default GlobalSearch;
