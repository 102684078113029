import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { useNavigate, useParams } from "react-router-dom";
import { useGSTEdit, useUpdateGst } from "../../../hooks/GST";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import { useFetchSingle, useUpdateSingle, useCreate, useUpdateSingleforcewithyes, useUpdateSingleforcewithno } from "../../../hooks/Api";
import Select from "react-select"
import axios from 'axios'
import { Alert } from "../../../components/Model/Model";
import useFocusOnEnter from "../../../components/focus";
import { useFetchCustomerRunningNotDropDown, useFetchLatePaymentDropDown, useFetchMaterialTypeDropDown, useFetchPaymentMethodDropDown, useFetchProductAllowDropDown } from "../../../hooks/DropDown_Api";
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import CreatableSelect from "react-select/creatable";
import Modal from "react-bootstrap/Modal";
import { Deletesinglewithdependancy } from "../../../components/modal";
import Axiosinstance from "../../../hooks/Interceptor";
import Mui_Toaster from "../../../components/Mui_Toaster/Mui_Toaster";

const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}

interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});

// import { Alert } from "../Model/Model";
// import Select from 'react-select/async';

const Edit_Customer_Condition = ({ currentStep, setCurrentStep }: any) => {
    let pageName = "Customer Condition"
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    // const  id  = localStorage.getItem('Customer Conditions')



    let rawData = localStorage.getItem('Add Data');
    let addData = rawData ? JSON.parse(rawData) : null;

    const id = addData?.customer_condition?.id
    const tempData = addData?.customer_condition
    const data = tempData
    const prevDataRef = useRef();


    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_SALES_CUSTOMER_CONDITION_API?.toString()
    // const { data } = useFetchSingle(id,apiname)
    const { mutate, isSuccess, isError, error, data: gstUpdated_response }: any = useUpdateSingle()

    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()

    const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();


    useEffect(() => {
        const isDataChanged = JSON.stringify(data) !== JSON.stringify(prevDataRef.current);
        if (data && isDataChanged) {
            reset(data);
            prevDataRef.current = data;
        }
    }, [data, reset]);




    const handleReset = () => {
        reset()
    }

    const [isTargettedMonthly, setIsTargettedMonthly] = useState("no")

    const handleTragettedMonthlyBox = (e: any) => {
        setIsTargettedMonthly(e.target.value)
    }


    const [valuePaymentMethod, setValuePaymentMethod]: any = useState();
    const [selectedPaymentMethod, setSelectedPaymentMethod]: any = useState('');
    const [optionsPaymentMethod, setOptionsPaymentMethod]: any = useState([]);
    const [valuePaymentMethodCreatable, setValuePaymentMethodCreatable] = useState<Option | null>();

    const apinameForPaymentMethod = process.env.REACT_APP_SALES_CUSTOMER_PAYMENT_METHOD_API?.toString()
    const { mutate: mutatePaymentMethod, data: dataPaymentMethod, isError: PaymentMethodIsError, isLoading: PaymentMethodLoading, isSuccess: PaymentMethodSuccess, error: PaymentMethodError }: any = useCreate(apinameForPaymentMethod)

    const { data: PaymentMethodData, refetch: PaymentMethodRefetch } = useFetchPaymentMethodDropDown()

    useEffect(() => {
        setOptionsPaymentMethod(PaymentMethodData)
    }, [PaymentMethodData])

    const handleChangePaymentMethod = (e: any) => {
        setValuePaymentMethod(e)
        setValue("customer_payment_method", `${e?.value}`)
        setValuePaymentMethodCreatable(null)

    }


    const handlePaymentMethodCreate = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutatePaymentMethod({ name: newOption.name, status: newOption.status })
            setOptionsPaymentMethod((prev: any) => [...prev, newOption]);
            setValuePaymentMethodCreatable(newOption);
        }, 0);


    };


    useEffect(() => {
        (async () => {
            if (PaymentMethodSuccess && optionsPaymentMethod) {
                await PaymentMethodRefetch();
                setValue("customer_payment_method", PaymentMethodData[0]?.value)
            }
        })();

        return () => {
        };
    }, [PaymentMethodSuccess, PaymentMethodData]);




    const [valueLatePaymentInterest, setValueLatePaymentInterest]: any = useState();
    const [selectedLatePaymentInterest, setSelectedLatePaymentInterest]: any = useState('');
    const [optionsLatePaymentInterest, setOptionsLatePaymentInterest]: any = useState([]);
    const [valueLatePaymentInterestCreatable, setValueLatePaymentInterestCreatable] = useState<Option | null>();

    const apinameForLatePaymentInterest = process.env.REACT_APP_SALES_LATE_PAYMENT_INTEREST_API?.toString()
    const { mutate: mutateLatePaymentInterest, data: dataLatePaymentInterest, isError: LatePaymentInterestIsError, isLoading: LatePaymentInterestLoading, isSuccess: LatePaymentInterestSuccess, error: LatePaymentInterestError }: any = useCreate(apinameForLatePaymentInterest)

    const { data: LatePaymentInterestData, refetch: LatePaymentInterestRefetch } = useFetchLatePaymentDropDown()

    useEffect(() => {
        setOptionsLatePaymentInterest(LatePaymentInterestData)
    }, [LatePaymentInterestData])

    const handleChangeLatePaymentInterest = (e: any) => {
        setValueLatePaymentInterest(e)
        setValue("late_payment_interest", `${e?.value}`)
        setValueLatePaymentInterestCreatable(null)

    }


    const handleLatePaymentInterestCreate = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutateLatePaymentInterest({ name: newOption.name, status: newOption.status })
            setOptionsLatePaymentInterest((prev: any) => [...prev, newOption]);
            setValueLatePaymentInterestCreatable(newOption);
        }, 0);


    };


    useEffect(() => {
        (async () => {
            if (LatePaymentInterestSuccess && optionsLatePaymentInterest) {
                await LatePaymentInterestRefetch();
                setValue("late_payment_interest", LatePaymentInterestData[0]?.value)
            }
        })();

        return () => {
        };
    }, [LatePaymentInterestSuccess, LatePaymentInterestData]);




    const [valueCustomerRunningNot, setValueCustomerRunningNot]: any = useState();
    const [selectedCustomerRunningNot, setselectedCustomerRunningNot]: any = useState('');
    const [optionsCustomerRunningNot, setOptionsCustomerRunningNot]: any = useState([]);
    const [valueCustomerRunningNotCreatable, setValueCustomerRunningNotCreatable] = useState<Option | null>();

    const apinameForCustomerRunningNot = process.env.REACT_APP_SALES_CUSTOMER_RUNNING_NOT_API?.toString()
    const { mutate: mutateCustomerRunningNot, data: dataCustomerRunningNot, isError: CustomerRunningNotIsError, isLoading: CustomerRunningNotLoading, isSuccess: CustomerRunningNotSuccess, error: CustomerRunningNotError }: any = useCreate(apinameForCustomerRunningNot)

    const { data: CustomerRunningNotData, refetch: CustomerRunningNotRefetch } = useFetchCustomerRunningNotDropDown()

    useEffect(() => {
        setOptionsCustomerRunningNot(CustomerRunningNotData)
    }, [CustomerRunningNotData])

    const handleChangeCustomerRunningNot = (e: any) => {
        setValueCustomerRunningNot(e)
        setValue("customer_running_not", `${e?.value}`)
        setValueCustomerRunningNotCreatable(null)

    }


    const handleCustomerRunningNotCreate = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutateCustomerRunningNot({ name: newOption.name, status: newOption.status })
            setOptionsCustomerRunningNot((prev: any) => [...prev, newOption]);
            setValueCustomerRunningNotCreatable(newOption);
        }, 0);


    };


    useEffect(() => {
        (async () => {
            if (CustomerRunningNotSuccess && optionsCustomerRunningNot) {
                await CustomerRunningNotRefetch();
                setValue("customer_running_not", CustomerRunningNotData[0]?.value)
            }
        })();

        return () => {
        };
    }, [CustomerRunningNotSuccess, CustomerRunningNotData]);



    const [valueProductAllow, setValueProductAllow]: any = useState();
    const [selectedProductAllow, setSelectedProductAllow]: any = useState('');
    const [optionsProductAllow, setOptionsProductAllow]: any = useState([]);
    const [valueProductAllowCreatable, setValueProductAllowCreatable] = useState<Option | null>();

    const apinameForProductAllow = process.env.REACT_APP_SALES_PRODUCT_ALLOW_API?.toString()
    const { mutate: mutateProductAllow, data: dataProductAllow, isError: ProductAllowIsError, isLoading: ProductAllowLoading, isSuccess: ProductAllowSuccess, error: ProductAllowError }: any = useCreate(apinameForProductAllow)

    const { data: ProductAllowData, refetch: ProductAllowRefetch } = useFetchProductAllowDropDown()

    useEffect(() => {
        setOptionsProductAllow(ProductAllowData)
    }, [ProductAllowData])

    const handleChangeProductAllow = (e: any) => {
        setValueProductAllow(e)
        setValue("product_allow", `${e?.value}`)
        setValueProductAllowCreatable(null)

    }


    const handleProductAllowCreate = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutateProductAllow({ name: newOption.name, status: newOption.status })
            setOptionsProductAllow((prev: any) => [...prev, newOption]);
            setValueProductAllowCreatable(newOption);
        }, 0);


    };


    useEffect(() => {
        (async () => {
            if (ProductAllowSuccess && optionsProductAllow) {
                await ProductAllowRefetch();
                setValue("product_allow", ProductAllowData[0]?.value)
            }
        })();

        return () => {
        };
    }, [ProductAllowSuccess, ProductAllowData]);


    const [options, setOptions]: any = useState([])
    // const[typeValue,setTypeValue]:any = useState()
    const [selProductType, setSelProductType]: any = useState([])
    const [selectedProductType, setSelectedProductType]: any = useState([])

    const { data: typeData, refetch: typeRefetch } = useFetchMaterialTypeDropDown()
    useEffect(() => {

        setOptions(typeData)
    }, [typeData])
    const handleChangeMachine = (e: any) => {



        const type = e.map((value: any) => {
            return value.value
        })
        setSelProductType(e)
        setValue("product_type", type)
        setSelectedProductType([])
        // setTypeValue(e)
        // setSelMachine(e)
    }




    // useEffect(() => {
    //     if (data) {
    //         reset(data)
    //         setSelectedPaymentMethod(data?.customer_payment_method?.id)
    //         setValue('customer_payment_method',data?.customer_payment_method?.id)
    //         setSelectedLatePaymentInterest(data?.late_payment_interest?.id)
    //         setValue('late_payment_interest',data?.late_payment_interest?.id)
    //         setselectedCustomerRunningNot(data?.customer_running_not?.id)
    //         setValue('customer_running_not',data?.customer_running_not?.id)
    //         setSelectedProductAllow(data?.product_allow?.id)
    //         setValue('product_allow',data?.product_allow?.id)
    //         setSelectedProductType(data?.product_type?.map((e:any)=>e?.id))
    //         setValue('product_type',data?.product_type?.map((e:any)=>e?.id))
    //         setIsTargettedMonthly(data?.targeted_monthly_box)
    //     }
    // }, []);


    const onSubmit = (materialtypeDetails: any) => {


        const formData = new FormData();
        for (const [key, value] of Object.entries(materialtypeDetails)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }

            else if (Array.isArray(value) && key === "product_type") {
                value.forEach((val, index) => formData.append(`product_type`, val))
            }

        }
        let data = {
            apiname: apiname,
            id: id,
            data: formData
        }
        mutate(data);
        setValuesofform(data)

    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")


    const [openEditDateModel, setOpenEditDateModel] = useState(false)
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()
    const [expirationDate, setExpirationDate] = useState()

    const apinameForBasicDetails = process.env.REACT_APP_EMPLOYEE_API?.toString()
    const { mutate: BasicDetails, isSuccess: basicDetailsIsSuccess, isError: basicDetailsIsError, error: basicDetailsError, data: basicDetailsData }: any = useUpdateSingle()

    let rawDataTemp = localStorage.getItem('Add Data');
    let addDataTemp = rawDataTemp ? JSON.parse(rawDataTemp) : null;

    let basicIdTemp = addDataTemp ? addDataTemp.id : null;

    const [basicDetailsInitiated, setBasicDetailsInitiated] = useState(false)

    useEffect(() => {
        if ((isSuccess || yessuccess || nosuccess) && !basicDetailsInitiated) {
            setShow(true);
            setshowmessage("success");


            let tempId = {
                customer_condition: data?.data?.id
            }

            let tempData = {
                id: basicIdTemp,
                apiname: apinameForBasicDetails,
                data: tempId
            }
            BasicDetails(tempData);
            setBasicDetailsInitiated(true);
            //   navigate("/listCity", { state: { showEditToast: true } });
            //  const currentPath = window.location.pathname;
            // if(currentPath === '/addCustomerDetails'){
            // setCurrentStep(currentStep + 1)
            // }
        } else if (isError) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [data, isSuccess, yessuccess, nosuccess, gstUpdated_response, error,
        isSuccess,]);

    const [updateCompleted, setUpdateCompleted] = useState(false)

    let rawDataPersonType = localStorage.getItem('PersonType');
    let addDataPersonType = rawDataPersonType ? JSON.parse(rawDataPersonType) : null;
    let personTypeLabel = addDataPersonType?.label === 'Employee'



    useEffect(() => {
        if (basicDetailsIsSuccess && basicDetailsData && !updateCompleted) {

            localStorage.setItem('Add Data', JSON.stringify(basicDetailsData?.data))
            setUpdateCompleted(true);
            if (!personTypeLabel) {

                const currentPath = window.location.pathname;
                if (currentPath === '/addCustomerDetails') {
                    setCurrentStep(currentStep + 1)
                };
            } else {
                navigate('/listPersonDetail')
                localStorage.removeItem('PersonType')
                localStorage.removeItem('Add Data')

            }
            //    const currentPath = window.location.pathname;
            // if(currentPath === '/addCustomerDetails'){
            // setCurrentStep(currentStep + 1)
            // };
        }
    }, [basicDetailsIsSuccess, basicDetailsData])

    useEffect(() => {
        if (isError) {
            if (error.response?.status === 405) {
                setConfirmmodal(true);
            }
        } else if (isError || yeserror || noerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [isError, yeserror, noerror]);
    useEffect(() => {
        setTimeout(() => {
            setShow(false);
        }, 3000);
    }, []);
    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }
    const noupdatemodal = () => {
        setOpenEditDateModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {

        delete data.created_at
        delete data.created_by
        let ok = {
            id: id,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditDateModel(false)
    }

    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }

    const currentPath = window.location.pathname;


    const apinameCondition = process.env.REACT_APP_SALES_CUSTOMER_CONDITION_API?.toString()

    useEffect(() => {
        if (data.id) {
            const getCall = async () => {
                // debugger
                try {
                    const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameCondition}/${data.id}/`);
                    console.log('ResponseData:', response);
                    reset(response?.data?.data)

                    setSelectedPaymentMethod(response?.data?.data?.customer_payment_method?.id)
                    setValue('customer_payment_method', response?.data?.data?.customer_payment_method?.id)
                    setSelectedLatePaymentInterest(response?.data?.data?.late_payment_interest?.id)
                    setValue('late_payment_interest', response?.data?.data?.late_payment_interest?.id)
                    setselectedCustomerRunningNot(response?.data?.data?.customer_running_not?.id)
                    setValue('customer_running_not', response?.data?.data?.customer_running_not?.id)
                    setSelectedProductAllow(response?.data?.data?.product_allow?.id)
                    setValue('product_allow', response?.data?.data?.product_allow?.id)
                    setSelectedProductType(response?.data?.data?.product_type?.map((e: any) => e?.id))
                    setValue('product_type', response?.data?.data?.product_type?.map((e: any) => e?.id))
                    setIsTargettedMonthly(response?.data?.data?.targeted_monthly_box)




                } catch (error) {
                    console.error('Error:', error);
                }
            }
            getCall()
        }

    }, [])


    return (
        <div>
            <div >
                <section className="content-header">
                    {/* <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit {pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Sales And Marketing</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">{pageName}</a></li>
                                    <li className="breadcrumb-item active">Edit {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div> */}
                </section>
                <section className="">
                    <div className="">
                        <div className="card card-default">
                            <div className="row">
                                <p className="mb-0 " style={{ fontSize: '14px' }}>{`${addData?.person_type?.name}-${addData?.name}-${addData?.extra_response?.city_name_and_distict_name?.village_name ? addData?.extra_response?.city_name_and_distict_name?.village_name : addData?.extra_response?.city_name_and_distict_name?.city_name}`}</p>
                            </div>
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Source Name</span></h5>
                            </div> */}
                            <form ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                                    {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message?.name} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-3 mt-2">
                                                    <label >Customer Payment Method <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValuePaymentMethod(null)
                                                            setValue(null)
                                                        }}
                                                        // classNames="creatable-select"
                                                        {...register("customer_payment_method", { required: { value: true, message: "Customer Payment Method is required" } })}
                                                        placeholder={'Select Customer Payment Method'}
                                                        components={customCreatableIndicator}
                                                        // menuIsOpen={true}
                                                        options={optionsPaymentMethod}
                                                        onCreateOption={handlePaymentMethodCreate}
                                                        onChange={handleChangePaymentMethod}
                                                        // value={valuePaymentMethodCreatable == null ? valuePaymentMethod : valuePaymentMethodCreatable}
                                                        value={valuePaymentMethodCreatable == null ? valuePaymentMethod == null ? optionsPaymentMethod?.filter((obj: any) => obj.value === selectedPaymentMethod) : valuePaymentMethod : valuePaymentMethodCreatable}


                                                    />
                                                    <span className='text-red w-100'>{errors?.customer_payment_method?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label >Customer Credit Day Limit <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="number" className="form-control" placeholder="Customer Credit Day Limit" {...register("customer_credit_day_limit", { onChange: handleValue, required: { value: false, message: "Customer Credit Day Limit is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.customer_credit_day_limit?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >Customer Credit Amount Limit <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="number" className="form-control" placeholder="Customer Credit Amount Limit" {...register("customer_credit_amount_limit", { onChange: handleValue, required: { value: false, message: "Customer Credit Amount Limit is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.customer_credit_amount_limit?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label >Late Payment Interest % <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueLatePaymentInterest(null)
                                                            setValue(null)
                                                        }}
                                                        // classNames="creatable-select"
                                                        {...register("late_payment_interest", { required: { value: true, message: "Late Payment Interest % is required" } })}
                                                        placeholder={'Select Late Payment Interest %'}
                                                        components={customCreatableIndicator}
                                                        // menuIsOpen={true}
                                                        options={optionsLatePaymentInterest}
                                                        onCreateOption={handleLatePaymentInterestCreate}
                                                        onChange={handleChangeLatePaymentInterest}
                                                        // value={valueLatePaymentInterestCreatable == null ? valueLatePaymentInterest : valueLatePaymentInterestCreatable}
                                                        value={valueLatePaymentInterestCreatable == null ? valueLatePaymentInterest == null ? optionsLatePaymentInterest?.filter((obj: any) => obj.value === selectedLatePaymentInterest) : valueLatePaymentInterest : valueLatePaymentInterestCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.late_payment_interest?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label >Customer Runnig-Not <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueCustomerRunningNot(null)
                                                            setValue(null)
                                                        }}
                                                        // classNames="creatable-select"
                                                        {...register("customer_running_not", { required: { value: true, message: "Customer Runnig-Not is required" } })}
                                                        placeholder={'Select Customer Runnig-Not'}
                                                        components={customCreatableIndicator}
                                                        // menuIsOpen={true}
                                                        options={optionsCustomerRunningNot}
                                                        onCreateOption={handleCustomerRunningNotCreate}
                                                        onChange={handleChangeCustomerRunningNot}
                                                        // value={valueCustomerRunningNotCreatable == null ? valueCustomerRunningNot : valueCustomerRunningNotCreatable}
                                                        value={valueCustomerRunningNotCreatable == null ? valueCustomerRunningNot == null ? optionsCustomerRunningNot?.filter((obj: any) => obj.value === selectedCustomerRunningNot) : valueCustomerRunningNot : valueCustomerRunningNotCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.customer_running_not?.message}</span>
                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label >Product Type <span style={{ color: "red" }}>*</span></label>
                                                    <Select

                                                        {...register("product_type", { required: { value: true, message: "Product Type is required" } })}
                                                        placeholder={'Product Type'}
                                                        options={options}
                                                        onChange={handleChangeMachine}
                                                        // value={typeValue}
                                                        components={customIndicator}
                                                        isMulti
                                                        isClearable
                                                        value={selProductType?.length === 0 ? options?.filter((obj: any) => selectedProductType?.includes(obj?.value)) : selProductType}
                                                    // className="dropdown-select"
                                                    />
                                                    {/* <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenHSN(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div> */}
                                                    <span className='text-red w-100'>{errors?.product_type?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label >Product Allow <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueProductAllow(null)
                                                            setValue(null)
                                                        }}
                                                        // classNames="creatable-select"
                                                        {...register("late_payment_interest", { required: { value: true, message: "Product Allow is required" } })}
                                                        placeholder={'Select Product Allow'}
                                                        components={customCreatableIndicator}
                                                        // menuIsOpen={true}
                                                        options={optionsProductAllow}
                                                        onCreateOption={handleProductAllowCreate}
                                                        onChange={handleChangeProductAllow}
                                                        // value={valueProductAllowCreatable == null ? valueProductAllow : valueProductAllowCreatable}
                                                        value={valueProductAllowCreatable == null ? valueProductAllow == null ? optionsProductAllow?.filter((obj: any) => obj.value === selectedProductAllow) : valueProductAllow : valueProductAllowCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.product_allow?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label >Targetted Monthly Box <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group">
                                                        <select className="form-select" {...register("targeted_monthly_box", { onChange: handleTragettedMonthlyBox, required: { value: false, message: "Targetted Monthly Box is required" } })}>
                                                            <option value={""} selected>Select Any</option>
                                                            <option value={"yes"} >Yes</option>
                                                            <option value={"no"} >No</option>

                                                        </select>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.targeted_monthly_box?.message}</span>
                                                </div>


                                                {isTargettedMonthly === "yes" ?
                                                    <>
                                                        <div className="col-sm-3 mt-2">
                                                            <label >Targetted Monthly Amount <span style={{ color: "red" }}>*</span></label>
                                                            <div className="input-group">
                                                                <input type="number" className="form-control" placeholder="Targetted Monthly Amount" {...register("targeted_monthly_amount", { onChange: handleValue, required: { value: isTargettedMonthly === "yes" ? true : false, message: "Targetted Monthly Amount is required" } })} />
                                                                <div className="input-group-append" >
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                    // style={{backgroundColor:"blue",color:"green"}} 
                                                                    /></span>

                                                                </div>
                                                            </div>
                                                            <span className='text-red w-100'>{errors?.targeted_monthly_amount?.message}</span>
                                                        </div>
                                                    </>
                                                    : ""
                                                }



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Field is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>

                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        onClick={handleSubmit(onSubmit)}
                                                    >Submit</button>
<button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetail")}>Cancel</button>
                                                    {currentPath === '/addCustomerDetails' ?
                                                        <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => setCurrentStep(currentStep - 1)}>Back</button>
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>


            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />

            <Modal show={openEditDateModel} onHide={() => setOpenEditDateModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                                {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditDateModel(false) }}>Close</button>
                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>
                </Modal.Footer>
            </Modal>


            {show && (
                <Mui_Toaster
                    message={`Condition Updated Successfully`}
                    severity={"success"}
                    Isopen={true}
                />
            )}
        </div>
    )
}

export default Edit_Customer_Condition


