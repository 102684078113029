import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Restricted from "../../Roles/Restricted";
// import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import axios from "axios";
import FileDownload from 'js-file-download';
import { BiDotsVerticalRounded } from "react-icons/bi";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useState } from "react";
import { useNavigate } from "react-router-dom";



const Dispatched = ({ row, apiname, deletemodalask, permissionClass, refetch, dos }: any) => {
  const [isDispatched, setIsDispatched] = useState(false)
  const [ask, setAsk]: any = useState(null);
  const navigate = useNavigate()
  //   document.addEventListener('mouseup', function(e:any) {
  //     var container:any = document.getElementById('container');
  //     if (!container.contains(e.target)) {
  //         container.style.display = 'none';
  //     }
  // });

  //   const handleDispatchOrder = () => {
  //     axios.patch(
  //       `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SALES_DISPATCH_ORDER_API}/${row?.original?.id}/?edit=yes`,
  //       {
  //         "is_dispatched":true,
  //         // responseType: "blob",



  //       },
  //       {
  //       headers: {
  //         'Authorization': `Bearer ${localStorage.getItem('token')}`,
  //         "Content-Type": "application/json",
  //       },
  //     }
  //     )
  //     navigate('/listDispatch')
  //     // setIsDispatched(true)
  // //     .then((e:any)=>
  // //     {
  // //     if(e?.data?.message === "success"){
  // // window.location.reload()
  // //     }
  // //   })




  //   };




  return (
    <>
      <div

        className="vertical-align-center"
        style={{
          display: "inline-block !important",
          justifyContent: "space-evenly !important",
        }}
      >

        {/* <div className="m-2">
               <button onClick={handleDownloadQuotation} className="btn btn-primary text-white w-100">Dispatch Order</button>
                </div> */}


        {/* <Restricted
          to={`${permissionClass?.front}.change_${permissionClass?.back}`}
          fallback={""}
        > */}



        {/* <Link
            className="btn btn-primary text-white mx-2"
            to={`/quotation/${Number(row.original.id)}`}
          >
            <span className="text-xs">Get a Quotation</span>
          </Link> */}

        <OverlayTrigger

          trigger={"focus"}
          key={"bottom"}
          placement={"bottom-end"}
          overlay={
            <Popover >

              <Popover.Body >
                <div className="m-2">
                  <Link
                  //  to={`/verifydispatch_order_list?dos=${encodeURIComponent(JSON.stringify(dos))}`}
                   to={`/verifydispatch_order_list_id/${Number(row.original.id)}`}
                    className="btn btn-primary text-white mx-2"
                  >
                    Verify
                  </Link>
                  {/*<button onClick={handleDispatchOrder} disabled={isDispatched} className="btn btn-primary text-white w-100">{isDispatched?"Dispatched":"Dispatch"}</button>*/}
                </div>
                <div className="m-2">
                </div>
              </Popover.Body>
            </Popover>
          }
        >



          <button
            // onClick={handleSubmitQuotation}
            className="btn btn-primary text-white"
          >

            <BiDotsVerticalRounded />
            {/* <RiDeleteBinLine />  */}
          </button>
        </OverlayTrigger>



      </div>
    </>
  );
};

export default Dispatched;

