import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Columns_Carton, titleofheaders } from "./Columns_Carton"
import Table from "../../components/Dynamic_Table/Table/Table";
const List_Brand = () => {
  const Breadcrumb_Primary_Title = "Home";
  const Breadcrumb_Primary_Link = "Color";

  const Breadcrumb_Parent_Title = "Product Details";

  const Breadcrumb_Title_Name = "Carton";

  const apiname = process.env.REACT_APP_CARTON_API?.toString();

  const filter = {
//     carton_code: "",
//     name_and_grade: "",
//     paper_color: "",
//     carton_special_running: "",
//     brand: "",
//     brand_type: "",
//     status: "",
//     hsn: "",
//     gst: "",
//     carton_length: "",
//     carton_width: "",
//     carton_height: "",
//     carton_empty_weight: "",
//     // carton_ply: "",
//     carton_empty_bunch_packing_nos: "",
//     carton_moisture: "",
//     bursting_strength: "",
//     kan_length: "",
//     kan_width: "",
//     printing_length: "",
//     printing_width: "",
//     printing_color_name: "",
//     printing_total_color: "",
//     created_by:"",
//   updated_by:"" ,
//   kan: '',
// printing: ''
carton_code: "",
material_name_and_type_category_and_hsn_code_and_gst: "",
material_use: "",
product_color_name: "",
product_width_mm: "",
product_length_mm: "",
product_height_mm: "",
product_kan:"",
product_kan_length:"",
product_kan_width:"",
// product_ply:"",
product_sealing_type:"",
product_printing: "",
product_printing_type:"",
product_printing_color_name:"",
product_printing_total_color:"",
product_brand:"",
product_brand_type:"",
product_printing_length:"",
product_printing_width:"",
product_empty_weight:"",
product_empty_bunch_packing_nos:"",
uom:"",
product_packing_type:"",
stock_minimum_bunch_qty:"",
stock_maximum_bunch_qty:"",
bursting_strength: "",
carton_moisture:"",
product_by:"",
product_type:"",
product_maximum_debit_day:"",
product_location:"",
remark:"",

  };

  const addButtonName = "Carton";
  const addButtonPageName = "Carton"
  const permissionClass = {
    front: "carton",
    back: "carton"
  }
  const stickyStart = 1;
  const stickyEnd = 2;

  return (
    <>
      <div className="content-wrapper">
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{Breadcrumb_Title_Name} List</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to={`/list${Breadcrumb_Primary_Link}`}
                      className="text-decoration-none text-black"
                    >
                      {Breadcrumb_Primary_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      {Breadcrumb_Parent_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {Breadcrumb_Title_Name} List
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="container p-0" style={{ maxWidth: "100%" }}>
                {/* <BasicTable/> */}
                <Table
                  apiname={apiname}
                  filterFields={filter}
                  addButtonName={addButtonName}
                  stickyStart={stickyStart}
                  stickyEnd={stickyEnd}
                  TableColumns={Columns_Carton}
                  addButtonPageName={addButtonPageName}
                  titleofheaders={titleofheaders}
                  permissionClass={permissionClass}
                />
                
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default List_Brand;
