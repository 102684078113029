import { useRowSelect, useTable, useFilters, useSortBy, useGlobalFilter, useColumnOrder, usePagination, Column } from "react-table"
// import { Columns } from "../Columnorderhiding/Columns"
import { useEffect, useMemo, useState, useRef, SetStateAction, useCallback } from "react"
import { FiFilter } from 'react-icons/fi';
import { TbFilterOff } from 'react-icons/tb';
import axios from "axios"
import { useMutation } from "react-query"
import { useLocation, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark ,faSortUp, faSortDown, faSort} from '@fortawesome/free-solid-svg-icons';
import "../../pages/Color/colorList.css"
import { Link } from "react-router-dom"
import GlobalSearch from "../../components/Table Component/GlobalSearch"

import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { BiReset } from 'react-icons/bi';


// import { Alert } from '../../Model/Model';
import { ItemRenderProps, SortableList } from '@thaddeusjiang/react-sortable-list';
// import  {Columns1}  from "../Columnorderhiding/column1"
import { ReactSortable } from "react-sortablejs";
import { isVariableStatement } from "typescript"
import { CSVLink } from 'react-csv';
import ReactPaginate from 'react-paginate';

import { PaginationControl } from 'react-bootstrap-pagination-control';
import Pagination from '@mui/material/Pagination';

//Color
import { useCopyToClipboard } from 'react-use';
import copy from 'copy-to-clipboard';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import Table_footer from "../../components/Table Component/Table_footer"
import ColumnVisibility from "../../components/Table Component/ColumnVisibility"
import FilterInput from "../../components/Table Component/FilterInput"
import More from "../../components/Table Component/More_Export/More"
import Mui_Toaster from "../../components/Mui_Toaster/Mui_Toaster"
import { Alert } from "../../components/Model/Model";
// import { useFetchGst, useUpdateGst } from "../../hooks/GST";
import Actions from "../../components/Cell/Actions";
import { useFetch, useUpdateSingle } from "../../hooks/Api";
import Axiosinstance from "../../hooks/Interceptor";
import { Columns_StickPacking, titleofheaders } from "./Columns_StickPacking";
import autoTable from "jspdf-autotable";
// PDF
import jsPDF from 'jspdf'


const FileDownload = require('js-file-download');


const List_StickPacking = () => {
  const componentRef:any = useRef();

  

  const printRef = useRef(null);
  const tableRef = useRef("");
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState(false)
  const [FilterBy, setFilterBy] = useState({
    materialtype_code:'',
    type:'',
    gst:'',
    created_by:"",
  updated_by:"" 
  
  })
  const [data, setData] = useState([])
  const [pageSize, setPageSize] = useState("10")
  const [search, setSearch] = useState("")
  const [deleted, setDeleted] = useState(false)
  const [deleteid, setDeleteid] = useState()
  var [deleteMultipleid, setDeleteMultipleid]: any = useState([])
  const [excelBody, setExcelBody]: any = useState([])
  const [excelHeader, setExcelHeader]: any = useState<any[]>([])

  const [columnVisibility, setColumnVisibility] = useState(false)
  const [pageNumber, setPageNumber] = useState<Number[]>([])
  const [totalPages, setTotalPages] = useState<Number>(1)
  const [columnSort,setColumnSort]= useState([])
  const[orderBy,setOrderBy] = useState("")
  const [pageLoader, setPageLoader] = useState(false)
  const [multipleDelete, setMultipleDelete] = useState(false)

  const { state } = useLocation();
  const [tempShow, setTempShow] = useState(false)
  const [tempUpdateShow, setTempUpdateShow] = useState(false)

  const [show, setShow] = useState(false)
  const [showmessage, setshowmessage] = useState("")
  const [deleteConfirm, setDeleteConfirm] = useState(false)



  //Color Copy
  const [copyState, copyToClipboard] = useCopyToClipboard();


  //---------------------------------------- API Section ---------------------------------------//

  const apiname = process.env.REACT_APP_STICK_PACKING_API?.toString()
  const addButtonName = "Stick Packing"

  const { isLoading: fetchLoading, isError, data: fetchAllData, error, isFetching, refetch, isSuccess, isPreviousData, isRefetching } = useFetch(FilterBy, search, page, pageSize, orderBy, apiname)
  const { mutate, data: deletedata, isLoading: deletecolorloading, isError: deletecoloriserror, isSuccess: deletesucess } = useMutation(() => {
    return Axiosinstance.delete(`${process.env.REACT_APP_BASE_URL}${apiname}/${deleteid}?force=True`);
  });


  const { mutate: deleteMultipleMutet, data: deleteMultiple, isLoading: deleteMultipleLoading, isError: deleteMultipleIsError, isSuccess: deleteMultipleSuccess } = useMutation((deleteid: any) => {
    const temp_data = {
      id: deleteMultipleid
    }
    if (deleteMultipleid.length === 0) {
      throw new Error("No Rows are selected!")
    }
    else {
      return Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apiname}/deletelist/`, temp_data);
    }
  });

  //---------------------------------------- API Section End ---------------------------------------//


  //------------------------------------React Table---------------------------------------//


  const columns: any = useMemo(() => Columns_StickPacking, [])
    
  const tableInstance: any = useTable({
    columns,
    data: data,
    initialState : { sortBy: columnSort },
    
    
  }, useColumnOrder, useGlobalFilter, useFilters, useSortBy, usePagination, useRowSelect)

  let { visibleColumns, getTableProps,disableSortBy, selectedFlatRows, getTableBodyProps, headerGroups, rows, prepareRow, allColumns,  setSortBy,
     setColumnOrder,  toggleHideAllColumns,  toggleHideColumn, state: {  sortBy }} = tableInstance

     const [NewColumnOrder, setNewColumnOrder] = useState(allColumns)

  //------------------------------------End---------------------------------------//

  const handleFilter = () => {
    setFilter(!filter)
  }

  useEffect(() => {
    setFilterBy({  
        materialtype_code:'',
        type:'',
        gst:'',
        created_by:"",
      updated_by:"" 
    })
  }, [filter])

  useEffect(() => {
    refetch()
  }, [page,pageSize, search, FilterBy,orderBy])

    useEffect(() => {
    if (fetchAllData) {
      setData(fetchAllData.results)
      let col:any = localStorage.getItem(`colu${apiname}`)
      let existingEntries:any = JSON.parse(col);

      existingEntries?.map((e:any) => {
        tableInstance.toggleHideColumn(e) 
      })

    }

  }, [fetchAllData])




  // const setHandleFilter = (e: any) => {

  //   if (e.target.name === "hex" && e.target.value.trim()) {
  //     setFilterBy({ ...FilterBy, hex: e.target.value });
  //   }
  //   else if (!e.target.value.trim() && e.target.name === "hex") {
  //     setFilterBy({ ...FilterBy, hex: '' });
  //   }

  //   if (e.target.name === "name" && e.target.value.trim()) {
  //     setFilterBy({ ...FilterBy, name: e.target.value });
  //   }
  //   else if (e.target.name === "name" && !e.target.value.trim()) {
  //     setFilterBy({ ...FilterBy, name: '' });
  //   }
  // }


  const handlePageSize = (e: any) => {
    setPageSize(e.target.value)
    setPage(1)
  };

  


 

  useEffect(() => {
    if (deleteMultipleSuccess === true) refetch()
  }, [deleteMultipleSuccess,fetchAllData?.results])
  useEffect(() => {
    return () => {
      setDeleteMultipleid([])
    }
  }, [])
  const handleMultipleDeleted = () => {

    deleteMultipleMutet(deleteMultipleid)
    setMultipleDelete(true)
  }

  const handleMultipleClose = () => {
    setMultipleDelete(false)

  }

  const handleMultipleDeleteModel = () => {
    setMultipleDelete(true)

  }



  useEffect(() => {
    setDeleteMultipleid(() => {
      const temp = selectedFlatRows.map((e: any) => {
        return e.original.id
      })
      return temp
    })

  }, [selectedFlatRows])





  useEffect(() => {
    if (deletesucess === true) refetch()
  }, [deletesucess])
  useEffect(() => {
    return () => {
      setDeleteid(undefined)
    }
  }, [])
  const handleDeleted = () => {

    mutate(deleteid)
    setDeleted(true)
  }

  const handleClose = () => {
    setDeleted(false)

  }

  const handleColumnVisibility = () => {
    if (!columnVisibility) {
      setColumnVisibility(true)
    }
    else {
      setColumnVisibility(false)
    }
  }


  

  useEffect(() => {
    refetch(fetchAllData?.results)
  }, [useUpdateSingle, fetchAllData?.results])

  const handleAddColor = () => {
    navigate("/addcolor")
  }


  const downloadSampleExcelFile = () => {
    return axios.get(
      `${process.env.REACT_APP_BASE_URL}color/export_excel`,
      {
        responseType: "blob",

        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          "Content-Type": "application/json",
        },

      }
    ).then((response) => {
      FileDownload(response.data, 'materialType_report.xls');
    });
  };

  const downloadSampleCsvFile = () => {
    return axios.get(
      `${process.env.REACT_APP_BASE_URL}color/export_csv`,
      {
        responseType: "blob",

        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          "Content-Type": "application/json",
        },

      }
    ).then((response) => {
      FileDownload(response.data, 'materialType_report.csv');
    });
  };


  if (isError) {
    const errorpassesfromcall: any = error
    if (errorpassesfromcall?.response.data.error === "Unauthorized") { navigate("/login") }
  }

  useEffect(() => {
    if (isSuccess) {
      try {
        setPageNumber(Array.from(Array(Math.ceil(fetchAllData?.count / Number(pageSize))), (_, x) => x + 1)) 
      } catch (error) {
        setPageNumber([1])
      }
      const temp_ = pageNumber[pageNumber.length - 1]
      setTotalPages(temp_)
    }
  }, [fetchAllData?.results])

  

  

  const handleSearch = (e: any) => {
    setSearch(e.target.value)
  }

  useEffect(() => {
    setExcelBody(() => {
      const temp = selectedFlatRows.map((e: any, index:any) => {
        const take_columns: any = {
          Sr: index + 1
        }
        for (const [key, value] of Object.entries(e.original)) {
          if (excelHeader.includes(key)) {
            take_columns[key.charAt(0).toUpperCase() + key.slice(1)] = value;
          }
        }
        return take_columns
      })
      return temp
    })

  }, [selectedFlatRows, excelHeader])

  useEffect(() => {
    const temp_col: any = allColumns
    setExcelHeader(() => {
      const temp_header = temp_col.filter((item: any) => item.isVisible && item.accessor != undefined).map((e: any) => {
        return e.id
      })
      return temp_header
    })

  }, [headerGroups])

  const handleDownloadExcel = () => {

    if(excelBody.length === 0){
      setShow(true)
      setshowmessage("danger")  
    }
    else{
      const fileType = "application/vnd.openxmlformats-officedocument. spreadsheetml. sheet ;charset=UTF-8"
      const fileExtension = ".xlsx"

      const ws = XLSX.utils.json_to_sheet(excelBody);
      const wb: any = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, 'materialType_report_list' + fileExtension);
    }
  }





 

  useEffect(() => {
    setColumnOrder(() => {
      const temp_order = NewColumnOrder.map((e: any) => {
        return (e.id)
      })
      return temp_order
    })

  }, [NewColumnOrder])




  useEffect(() => {
    if (deleteMultipleSuccess) {
      setShow(true)
      setshowmessage("success")
    }
    else if (deleteMultipleIsError) {
      setShow(true)
      setshowmessage("danger")
    }


  }, [deleteMultipleSuccess, deleteMultipleIsError])


  useEffect(() => {
    if (deletesucess) {
      setShow(true)
      setshowmessage("success")
    }
    else if (deletecoloriserror) {
      setShow(true)
      setshowmessage("danger")
    }


  }, [deletesucess, deletecoloriserror,fetchAllData?.results])




  useEffect(() => {
    setTimeout(() => {
      setShow(false)
    }, 3000)

  }, [show]);

  const handleConfirmDelete = () => {
    setDeleteConfirm(true)
  }









   // Copy Component
   const handleCopy = () => {
    const headers = [...excelHeader]
const data = excelBody.map((elt: any) => {
  let data:Array<any>=[]
  excelHeader.map((content:any)=>{
    const renameprop=content.substr(0, 1).toUpperCase() + content.substr(1)
    if (typeof(elt[renameprop]) !== "string"&&typeof(elt[renameprop]) !== "number"){
      elt[renameprop] = elt[renameprop]?.username
    }
    if (typeof(elt[renameprop]) === "undefined"){
      elt[renameprop] = "-"
    }
    data.push(elt[renameprop])
  })
return data
})
const text=   data.map((row:any) => row.join("\t")).join("\n");
const hed=headers.join("\t")+"\n";
navigator.clipboard.writeText(hed+text);
}

// PDF Generate
const handlePDFDownload = () => {
const unit = "pt";
const size = "A4"; // Use A1, A2, A3 or A4
const orientation = "portrait"; // portrait or landscape
const marginLeft = 40;
const doc = new jsPDF(orientation, unit, size)
const headers = [...excelHeader]
const data = excelBody.map((elt: any) => {
  let data:Array<any>=[]
  excelHeader.map((content:any)=>{
    const renameprop=content.substr(0, 1).toUpperCase() + content.substr(1)
    if (typeof(elt[renameprop]) !== "string"&&typeof(elt[renameprop]) !== "number"){
      elt[renameprop] = elt[renameprop]?.username
    }
    if (typeof(elt[renameprop]) === "undefined"){
      elt[renameprop] = "-"
    }
    data.push(elt[renameprop])
  })
return data
})
autoTable(doc, {
  head: [[...headers]],
  body: data,
})
doc.save('Report.pdf')
}

// Column TH Drag & Drop

const handleDragStart = (e: any) => {
  const { id } = e.target;
  const dummy = NewColumnOrder
  const idx = dummy.map((e:any, index:any) => {
    if(e.id === id){
      return index
    }
  }).filter((e:any) => e != undefined)[0]

  e.dataTransfer.setData("colIdx", idx);
};

const handleDragOver = (e: any) => e.preventDefault();
const handleDragEnter = (e: any) => {
  const { id } = e.target;
};

const handleOnDrop = (e: any) => {
  const { id } = e.target;
  const dummy = NewColumnOrder
  const droppedColIdx = dummy.map((e:any, index:any) => {
    if(e.id === id){
      return index
    }
  }).filter((e:any) => e != undefined)[0]

  const draggedColIdx = Number(e.dataTransfer.getData("colIdx"))
  const tempCols = NewColumnOrder 
    const dummydatacol= tempCols[draggedColIdx]
    tempCols.splice(draggedColIdx,1)
    tempCols.splice(droppedColIdx,0,dummydatacol)
    setNewColumnOrder(tempCols);

  setColumnOrder(() => {
    const temp_order = tempCols.map((e: any) => {
      return (e.id)
    })
    return temp_order
  })
};



const handleRefresh=()=>{
  navigate(0);
}
 

useEffect(() => {
  setColumnSort(sortBy)

}, [useSortBy, sortBy])





useEffect(() => {
  if(state){
    if(state.showStickPackingToast){
      setTempShow(true)
      window.history.replaceState({}, document.title)
    }
    if(state.showStickPackingEditToast){
      setTempUpdateShow(true)
      window.history.replaceState({}, document.title)
    }
      
    setTimeout(() => {
      refetch() 
  }, 2000);
  }

}, [state,fetchAllData])



useEffect(() => {
  setColumnSort(sortBy)
  
  if(sortBy.length > 0){
    const temp_orderBy = sortBy[0]
    const order_by = temp_orderBy.desc ? `-${temp_orderBy.id}` : temp_orderBy.id
    setOrderBy(order_by)  
  }
  else{
    setOrderBy("")
  }

}, [useSortBy, sortBy])



useEffect(()=>{
  if( isFetching ){
    setPageLoader(true)

  }else{
    setPageLoader(false)
  }
},[isFetching])



// useEffect(()=>{
//   let paging = (page != 1 ? page - 1 : page)
 
//   if(deleteMultipleSuccess){
//     setPage(paging-1)
//     refetch()

//   }



     
// },[deleteMultipleSuccess])


useEffect(() => {
  let paging = (page != 1 ? page - 1 : page)
  if (deleteMultipleSuccess) {
    setPage(paging)
    refetch()

  }

}, [deleteMultipleSuccess])


const handlePrint = useReactToPrint({
  content: () => printRef.current,
  onBeforeGetContent :async()=> {
    await toggleHideColumn("selection",true)
  await toggleHideColumn("actions",true)
  },
  onAfterPrint:()=>{
    toggleHideColumn("selection",false)
    toggleHideColumn("actions",false)

  }
});

const menu:any=useRef()
    
useEffect(()=>{
   let handler=(e:any)=>{
       if(!menu.current?.contains(e?.target)){
           setColumnVisibility(false);
       }

   };
   document.addEventListener("mousedown",handler);
   return()=>{
       document.removeEventListener("mousedown",handler)
   }
})
return (

    <>
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h4>Stick Packing</h4>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><a href="/listColor" className="text-decoration-none text-black">Home</a></li>
                <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Masters</a></li>
                <li className="breadcrumb-item active">Stick Packing list</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="card card-default">


      <div className="container p-0" style={{ maxWidth: "100%" }}>

      <div className="table-title"  style={{paddingTop:"40px",width:"100%",marginLeft:"0px"}}>
            <div className="row ">
              <div className="col-sm-12 col-md-6 d-flex justify-content-md-end justify-content-lg-start justify-content-sm-center py-1">

              <div ref={menu}>


              <ColumnVisibility pagecolumn={apiname} columns={titleofheaders} columnVisibility={columnVisibility}  NewColumnOrder={NewColumnOrder} setNewColumnOrder={setNewColumnOrder} instance={{...tableInstance}} handleColumnVisibility={handleColumnVisibility}/>
</div>

                 


            <More 
            handlePDFDownload={handlePDFDownload}
            handleCopy={handleCopy}
            handleDownloadExcel={handleDownloadExcel}setShow={setShow}excelBody={excelBody}setshowmessage={setshowmessage}handleConfirmDelete={handleConfirmDelete}
            handlePrint={handlePrint}
            />

                <button className='btn btn-primary rounded-3' onClick={handleFilter}>
                  {filter ? <TbFilterOff size={"18px"} /> : <FiFilter size={"18px"} />}
                </button>

                <button className='btn btn-primary rounded-3 text-white' onClick={handleRefresh}>
                       <BiReset style={{color:"white"}} size={25}/>
                      </button>

              </div>
            
              <GlobalSearch pageName={"addStickPacking"} handleSearch={handleSearch} buttonName={"Add Stick Packing"}/>
            </div>
          </div>
        {/* <BasicTable/> */}

        <div className="table-wrapper" style={{overflow:"auto"}}>
      

          {/* Error Messages */}  
          {tempShow &&

<Mui_Toaster message={"Stick Packing Added Successfully"} severity={"success"} Isopen={true} />
}
{tempUpdateShow &&

<Mui_Toaster message={"Stick Packing Type Updated Successfully"} severity={"success"} Isopen={true} />
}

          {show && showmessage === "success" && <Alert color={showmessage} message="Stick Packing's Deleted successfully" />}
          {show && showmessage === "danger" && <Alert color={showmessage} message="No Rows are Selected" />}

          <div className='table-responsive' 
          // style={{width:"180%"}}
          >

            <table className="table table-bordered table-hover table-striped" {...getTableProps()} ref={printRef} >
              <thead className='text-center'>
              

                {
                  headerGroups.map((group: any) => {



                    return (
                      <>
                      <tr {...group.getHeaderGroupProps()}>
                        
                        {group.headers.map((column: any) => {
                          
                          return (
                          
                          <th draggable
                            {...column.getHeaderProps(!filter && column.getSortByToggleProps())}
                            id={column.id}
                            key={column.id}
                            onDragStart={handleDragStart}
                            onDragOver={handleDragOver}
                            onDrop={handleOnDrop}
                            onDragEnter={handleDragEnter}
                            
                            >{column.render("Header")}
                            <span className="mx-2">

                            {!filter && column.canSort ? 
                              
                              (column.isSorted? column.isSortedDesc? <>
                              <FontAwesomeIcon icon={faSortUp} color="#787878"/>
                                <FontAwesomeIcon icon={faSortDown} style={{color: "black", position:"relative",left:"-10px", top:"0.6px"}}/>
                                </>:
                                  <>
                                  <FontAwesomeIcon icon={faSortUp} color="black"/>
                                  <FontAwesomeIcon icon={faSortDown} style={{color: "#787878", position:"relative",left:"-10px", top:"0.6px"}}/>
                                  </>
                                
                                : 
                                <>
                                <FontAwesomeIcon icon={faSort} style={{color: "#787878"}}/>
                                </>

                                ) :  ""
                              
                              
                              }



                            




                            </span>
                           

                           
                              <FilterInput filter={filter} column={column}FilterBy={FilterBy} setFilterBy={setFilterBy} />
                          </th>
)

                        })
                        }
                                             
                                             

                      </tr>
                      </>

                    )
                  })
                }
                
               
              </thead>


              <tbody className='text-center' {...getTableBodyProps()}>
              

                {rows?.map((row: any, index: any) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps([{size: "10"}])}>


                      {row.cells.map((cell: any) => {
                        if(cell.column.id === "srno"){
                          return (
                            <td {...cell.getCellProps([{size: "10"}])}>{(pageSize != "all"? (Number(pageSize) * page - Number(pageSize)) + index + 1 : index + 1 )}</td>
                          )  
                        }else if (cell.column.id === "Actions") {
                          return (
                            <td {...cell.getCellProps()}>
                              {
                                <Actions row={row} apiname={apiname} mutate={mutate} masterName={addButtonName} deleteid={deleteid} setDeleteid={setDeleteid}/>
                              }

                            </td>
                          )

                        }
                        else{
                          return (
                            <td {...cell.getCellProps([{size: "10"}])}>{cell.render("Cell")}</td>
                          )
                        }
                      })
                      }




                    </tr>
                  
                  )
                 
                })}
              
              </tbody>

            </table>
            {/* 
                  <pre>
                <code>
                    {
                        JSON.stringify({
                            selectedFlatRows: selectedFlatRows.map((row: any) => row.original)
                        }, null, 2)
                    }
                </code>
            </pre> */}
          </div>

        </div>

   
        <Table_footer page={page} setPageSize={setPageSize} setPage={setPage} pageSize={pageSize} pageNumber={pageNumber} visibleColumns={visibleColumns} data={fetchAllData} PageLoading={pageLoader}/>




      </div>

      {/* Edit Modal HTML */}
      <div id="deleteMultiple" className="modal fade">
        <div className="modal-dialog">
          <div className="modal-content">
            <form>
              <div className="modal-header">
                <h4 className="modal-title">Delete Stick Packing</h4>
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
              </div>
              <div className="modal-body">
                <span> <span className='text-center'>Sure you want to delete it?</span></span>

              </div>


              <>
                <div className="modal-footer">
                  <input type="button" className="btn btn-default text-white" data-dismiss="modal" defaultValue="Cancel" />
                  <button type="button" className="btn btn-info text-white" data-dismiss="modal"
                    onClick={handleMultipleDeleted}>Yes</button>
                </div>
              </>




            </form>
          </div>
        </div>
      </div>


      </div>
        </div>
      </section>
      </div>


    </>

  )
}

export default List_StickPacking





