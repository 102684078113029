
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import { updateColor, useFetchColors, useUpdateSingleColor } from "../../hooks/color/color";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import Actions from "../../components/Cell/Actions";
import { Update, useFetch } from "../../hooks/Api";



interface Data {
  selection: any
  
  name: string;
  age: string;


use_paper: string;
paper_color: string;
paper_design: string;
paper_bf: string;
paper_gsm: string;
paper_liner: string;
paper_length_mm: string;
paper_decal: string;
kan_width: string;
kan_height: string;
total: string;
paper_qty: string;
paper_weight: string;
paper_printing: string;


  // created_at:any

  // created_by:any
  // updated_by:any
  // updated_at:any
  status: any;
  actions: any;
}
export const titleofheaders=[
  {
    id: `srno`,
    Header: `Sr No`,
  },
  {
    id: `name`,
    Header: "Name",
  },



 
  // {
  //   id: 'created_at',
  //   Header: "Created At",
  // },
  // {
  //   id: 'created_by',
  //   Header: "Created By",
  // },
  // {
  //   id: 'updated_at',
  //   Header: "Updated At",
  // },
  // {
  //   id: 'updated_by',
  //   Header: "Updated By",
  // },

  {
    id: 'status',
    Header: 'Status',
  },
]




export const Columns_Task_List: any = [



  
  // {
  //   id: `srno`,
  //   Header: () => <div>Sr<br/>No</div>,
  //   // sticky:"left",
    
  // },

  {
    id: `name`,
    Header: () => <div style={{display:"inline"}}>Paper<br/>Name</div>,
    // sticky:"left",
    accessor: `name`,




    Cell: ({ row }: any) => 

    {
      return(
        <div>
        <select className="px-4" style={{width:"110px"}}>
         <option value="" selected>select</option>
         <option value="">Golden Paper</option>
         <option value="">Natural Paper</option>

 
        </select>
 
         </div>
      )
}
  },


  {
    id: `use_paper`,
    Header: () => <div style={{display:"inline"}}>Use<br/>Paper</div>,
    accessor: `use_paper`,
    Cell: ({ row }: any) => 

    {
      return(
        <div>
        <select 
        
        // value={row?.original[0]?.use_paper} 
        onChange={(e:any)=>e.target.value}
        className="px-4">
         <option value="" selected>select</option>
         <option value="plain">Plain</option>
         <option value="liner">Liner</option>

 
        </select>
 
         </div>
      )
}

  },

  
  {
    id: `paper_color`,
    Header: () => <div style={{display:"inline"}}>Paper<br/>Color</div>,
    accessor: `paper_color`,
        Cell: ({ row }: any) => 

    {
      return(
        <div>
       <select className="px-4">
        <option value="" selected>select</option>
        <option value="">Brown</option>

       </select>

        </div>
      )
}


  },

  
  {
    id: `paper_design`,
    Header: () => <div style={{display:"inline"}}>Paper<br/>Design</div>,
    accessor: `paper_design`,
    Cell: ({ row }: any) => 

    {
      return(
        <div>
       <select className="px-4">
        <option value="" selected>select</option>
        <option value="">Triangle</option>
        <option value="">Verbal</option>


       </select>

        </div>
      )
}


  },


  {
    id: `paper_bf`,
    Header: () => <div style={{display:"inline"}}>Paper<br/>Bf</div>,
    accessor: `paper_bf`,
    Cell: ({ row }: any) => 

    {
      return(
        <div>
        <select className="px-4">
         <option value="" selected>select</option>
         <option value="">12</option>
         <option value="">16</option>
 
 
        </select>
 
         </div>
      )
}

  },

  {
    id: `paper_gsm`,
    Header: () => <div style={{display:"inline"}}>Paper<br/>Gsm</div>,
    accessor: `paper_gsm`,
    Cell: ({ row }: any) => 

    {
      return(
        <div>
        <select className="px-4">
         <option value="" selected>select</option>
         <option value="">100</option>
         <option value="">150</option>
 
 
        </select>
 
         </div>
      )
}

  },



  {
    id: `paper_liner`,
    Header: () => <div style={{display:"inline"}}>Paper<br/>Liner</div>,
    accessor: `paper_liner`,
    Cell: ({ row }: any) => 

    {
      return(
        <>
     <input style={{width:"-webkit-fill-available"}}
     disabled
      //  style={{textAlign:"center",background:"transparent",border:"none"}}
       
  />

        </>
      )
}

  },


  {
    id: `paper_length_mm`,
    Header: () => <div style={{display:"inline"}}>Paper<br/>Length MM</div>,
    accessor: `paper_length_mm`,
    Cell: ({ row }: any) => 
      
    {
      return(
        <>
     <input 
     type="number"
     disabled
     value={row?.original[0]?.paper_length_mm} 
     style={{width:"-webkit-fill-available"}}
      //  style={{textAlign:"center",background:"transparent",border:"none"}}
       
  />

        </>
      )
}

  },


  {
    id: `paper_decal`,
    Header: () => <div style={{display:"inline"}}>Paper Decal<br/>Size MM</div>,
    accessor: `paper_decal`,
    Cell: ({ row }: any) => 

    {
      return(
        <>
     <input 
      type="number"
      disabled
      value={row?.original[0]?.paper_decal} 
    //  value={cartonWidth}
      style={{width:"-webkit-fill-available"}}
      //  style={{textAlign:"center",background:"transparent",border:"none"}}
       
  />

        </>
      )
}

  },

  {
    id: `carton_height`,
    Header: () => <div style={{display:"inline"}}>Carton Height<br/>MM</div>,
    accessor: `carton_height`,
    Cell: ({ row }: any) => 

    {
      return(
        <>
     <input 
      type="number"
      disabled
      value={row?.original[0]?.carton_height} 
    //  value={cartonWidth}
      style={{width:"-webkit-fill-available"}}
      //  style={{textAlign:"center",background:"transparent",border:"none"}}
       
  />

        </>
      )
}

  },




  {
    id: `kan_width`,
    Header: () => <div style={{display:"inline"}}>Kan<br/>Width MM</div>,
    accessor: `kan_width`,
    Cell: ({ row }: any) => 

    {
      return(
        <>
     <input 
     
     type="number"
     value={row?.original[0]?.kan_width} 
     
     disabled 
     
     style={{width:"-webkit-fill-available"}}
      //  style={{textAlign:"center",background:"transparent",border:"none"}}
       
  />

        </>
      )
}

  },

  {
    id: `kan_height`,
    Header: () => <div style={{display:"inline"}}>Kan<br/>Height MM</div>,
    accessor: `kan_height`,
    Cell: ({ row }: any) => 

    {
      return(
        <>
     <input 
     type="number"
     value={row?.original[0]?.kan_height} 
     disabled style={{width:"-webkit-fill-available"}}
      //  style={{textAlign:"center",background:"transparent",border:"none"}}
       
  />

        </>
      )
}

  },



  {
    id: `paper_size_length_mm`,
    Header: () => <div style={{display:"inline"}}>Paper Size<br/>Length MM</div>,
    accessor: `paper_size_length_mm`,
    Cell: ({ row }: any) => 

    {
      return(
        <>
     <input 
     type="number"
     value={row?.original[0]?.paper_size_length_mm} 
     disabled 
     style={{width:"-webkit-fill-available"}}
      //  style={{textAlign:"center",background:"transparent",border:"none"}}
       
  />

        </>
      )
}

  },


  {
    id: `paper_decal_size_mm`,
    Header: () => <div style={{display:"inline"}}>Paper Decal<br/>Size MM</div>,
    accessor: `paper_decal_size_mm`,
    Cell: ({ row }: any) => 

    {
      return(
        <>
     <input 
     type="number"
     value={row?.original[0]?.paper_decal_size_mm} 
     disabled 
     style={{width:"-webkit-fill-available"}}
      //  style={{textAlign:"center",background:"transparent",border:"none"}}
       
  />

        </>
      )
}

  },


  {
    id: `total`,
    Header: () => <div style={{display:"inline"}}>Total<br/>MM</div>,
    accessor: `total`,
    Cell: ({ row }: any) => 

    {
      return(
        <>
     <input  type="number"
     value={row?.original[0]?.total} 
     disabled  style={{width:"-webkit-fill-available"}}
      //  style={{textAlign:"center",background:"transparent",border:"none"}}
       
  />

        </>
      )
}

  },


  {
    id: `paper_qty`,
    Header: () => <div style={{display:"inline"}}>Paper<br/>Qty</div>,
    accessor: `paper_qty`,
    Cell: ({ row }: any) => 

    {
      return(
        <>
     <input style={{width:"-webkit-fill-available"}}
      //  style={{textAlign:"center",background:"transparent",border:"none"}}
       
  />

        </>
      )
}

  },


  {
    id: `paper_weight`,
    Header: () => <div style={{display:"inline"}}>Paper<br/>Weight</div>,
    accessor: `paper_weight`,
    Cell: ({ row }: any) => 

    {
      return(
        <>
     <input style={{width:"-webkit-fill-available"}}
      //  style={{textAlign:"center",background:"transparent",border:"none"}}
       
  />

        </>
      )
}

  },


  {
    id: `paper_printing`,
    Header: () => <div style={{display:"inline"}}>Paper<br/>Printing</div>,
    accessor: `paper_printing`,
    Cell: ({ row }: any) => 

    {
      return(
        <div>
       <select className="px-4">
        <option value="" selected>select</option>
        <option value="">Yes</option>
        <option value="">No</option>

       </select>

        </div>
      )
}
  },
 


 



  // {
  //   id: 'created_at',
  //   Header: () => <div style={{display:"inline"}}>Created<br/>At</div>,
  //   accessor:'created_at',
  //   Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split(" ").join("  ") : "-")
  // },


  // {
  //   id: 'created_by',
  //   Header: () => <div style={{display:"inline"}}>Created<br/>By</div>,
  //   accessor:'created_by',
  //   Cell: ({ row }: any) => row.original.created_by?.username

 
  // },
  // {
  //   id: 'updated_at',
  //   Header: () => <div style={{display:"inline"}}>Updated<br/>At</div>,
  //   accessor:'updated_at',
  //   Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split(" ").join("  ") : "-")
  // },
  // {
  //   id: 'updated_by',
  //   Header: () => <div style={{display:"inline"}}>Updated<br/>By</div>,
  //   accessor:'updated_by',
  //   Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  // },





  // {
  //   id: 'Actions',
  //   Header: 'Actions',
  // },

  // {
  //   id: 'Selection',
  //   // sticky:"left",

  //   Header: ({ getToggleAllRowsSelectedProps }: any) => {



  //     const [data, setData] = useState([])

  //     const columns: any = useMemo(() => Columns_Task_List, [])
  //     const defaultColumn: any = useMemo(() => {
  //       return {
  //         Filter: Columnlevel
  //       }
  //     }
  //       , [])

  //     const tableInstance: any = useTable({
  //       columns,
  //       data: data,
  //       defaultColumn,
      

  //     },

     


  //     )
  //     const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
  //     let { selectedFlatRows} = tableInstance
  //     const { } = useMutation((deleteid: any) => {
  //       const temp_data = {
  //         id: deleteMultipleid
  //       }
  //       return axios.post(`${process.env.REACT_APP_BASE_URL}color/deletelist/`, temp_data,
  //         {
  //           headers: {
  //             'Authorization': `Bearer ${localStorage.getItem('token')}`
  //           }
  //         });
  //     });
     
  //     return (
  //       <>
  //         <div>
  //           <Checkbox

  //             onClick={() => {
  //               setDeleteMultipleid(() => {
  //                 const temp = selectedFlatRows?.map((e: any) => {
  //                   return e.original.id;
  //                 });
  //                 return temp;
  //               });
                
  //             }}

  //             {...getToggleAllRowsSelectedProps()} />
  //         </div>
  //       </>
  //     );
  //   },

  //   Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
  //     // Add your onClick function here
  //   }} />
  // },
]

  //What would come from an API
  // export const data = [
  //   {
  //     name:"",
  //     use_paper: "",
  //     paper_color: "",
  //     paper_design: "",
  //     paper_bf: "",
  //     paper_gsm: "",
  //     paper_liner: "",
  //     paper_length_mm: "",
  //     paper_decal: "",
  //     kan_width: "",
  //     kan_height: "",
  //     total: "",
  //     paper_qty: "",
  //     paper_weight: "",
  //     paper_printing: "",
  //   },
  
  // ];
  