import { useEffect, useState } from "react";
import "../../components/Header/header.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import { useCreate } from "../../hooks/Api";
import Select from "react-select"
import { useFetchBrandDropDown, useFetchColorDropDown, useFetchDisposableDropDown, useFetchGSTDropDown, useFetchMaterialTypeDropDown, useFetchUOMDropDown } from "../../hooks/DropDown_Api";
import { Alert } from "../../components/Model/Model";
import { DatePicker } from "antd";
import "./Product_Tabel.css"
import Price_List_Table from "./table";
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'
// import { Alert } from "../Model/Model";
// import Select from 'react-select/async';
const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
  }
  
  const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
  }
  
const Add_PriceList = () => {



    const [number, setNumber] = useState('');
    const [rateValue, setRateValue]: any = useState('')

    const [oldValueCartonBasicRate, setOldValueCartonBasicRate] = useState(10)
    const [oldValueCartonRate, setOldValueCartonRate] = useState(20)
    const [oldValueKGRate, setOldValueKGRate] = useState(10)
    const [oldValueStickRate, setOldValueStickRate] = useState(20)





    const [OperationCartonBasicRate, setOperationCartonBasicRate] = useState('?');
    const [OperationCartonRate, setOperationCartonRate] = useState('?');
    const [OperationStickRate, setOperationStickRate] = useState('?');
    const [OperationKGRate, setOperationKGRate] = useState('?');
    const [OperationModuloRate, setOperationModuloRate] = useState('no');

    const handleOperationCartonBasicRate = (event: any) => {
        setOperationCartonBasicRate(event.target.value);
    };


    const handleOperationCartonRate = (event: any) => {
        setOperationCartonRate(event.target.value);
    };

    const handleOperationStickRate = (event: any) => {
        setOperationStickRate(event.target.value);
    };

    const handleOperationKGRate = (event: any) => {
        setOperationKGRate(event.target.value);
    };

    const handleOperationModuloRate = (event: any) => {
        setOperationModuloRate(event.target.value)
    };


    const [changeValueCartonBasicRate, setChangeValueCartonBasicRate]: any = useState(0)
    const [changeValueCartonRate, setChangeValueCartonRate] = useState(0)
    const [changeValueStickRate, setChangeValueStickRate] = useState(0)
    const [changeValueKGRate, setChangeValueKGRate] = useState(0)
    const [changeValueModuloRate, setChangeValueModuloRate]: any = useState('')

    const handleValueCartonBasicRate = (event: any) => {
        setChangeValueCartonBasicRate(event.target.value);
    };


    const handleValueCartonRate = (event: any) => {
        setChangeValueCartonRate(event.target.value);
    };

    const handleValueStickRate = (event: any) => {
        setChangeValueStickRate(event.target.value);
    };

    const handleValueKGRate = (event: any) => {
        setChangeValueKGRate(event.target.value);
    };

    const handleValueModuloRate = (event: any) => {
        setChangeValueModuloRate(event.target.value);
    };


    const [newValueCartonBasicRate, setNewValueCartonBasicRate] = useState(0)
    const [newValueCartonRate, setNewValueCartonRate] = useState(0)
    const [newValueStickRate, setNewValueStickRate] = useState(0)
    const [newValueKGRate, setNewValueKGRate] = useState(0)
    const [newValuePercentage, setNewValuePercentage] = useState(0)





    // useEffect(()=>{


    //     switch (OperationCartonBasicRate) {
    //         case '?':
    //             setNewValueCartonBasicRate(oldValueCartonBasicRate)
    //             setValue("change_carton_basic_rate",0)                
    //             break;
    //         case '+':
    //             setNewValueCartonBasicRate(Number(oldValueCartonBasicRate) + Number(changeValueCartonBasicRate))
    //             break;
    //         case '-':
    //             setNewValueCartonBasicRate(Number(oldValueCartonBasicRate) - Number(changeValueCartonBasicRate))
    //             break;
    //         case '*':
    //             setNewValueCartonBasicRate(Number(oldValueCartonBasicRate) * Number(changeValueCartonBasicRate))
    //             break;
    //         case '/':
    //             setNewValueCartonBasicRate(Number(oldValueCartonBasicRate) / Number(changeValueCartonBasicRate))
    //             break;

    //         default:

    //     }



    //     setValue("new_carton_basic_rate",newValueCartonBasicRate)


    // },[OperationCartonBasicRate,oldValueCartonBasicRate,changeValueCartonBasicRate,newValueCartonBasicRate,rateValue])


    // useEffect(()=>{





    //     switch (OperationCartonRate) {
    //         case '?':
    //             setNewValueCartonRate(oldValueCartonRate)
    //             setValue("change_carton_rate",0)    
    //             break;
    //         case '+':
    //             setNewValueCartonRate(Number(oldValueCartonRate) + Number(changeValueCartonRate))
    //             break;
    //         case '-':
    //             setNewValueCartonRate(Number(oldValueCartonRate) - Number(changeValueCartonRate))
    //             break;
    //         case '*':
    //             setNewValueCartonRate(Number(oldValueCartonRate) * Number(changeValueCartonRate))
    //             break;
    //         case '/':
    //             setNewValueCartonRate(Number(oldValueCartonRate) / Number(changeValueCartonRate))
    //             break;

    //         default:
    //             // calculatedResult = 'Invalid operation';
    //     }

    //     setValue("new_carton_rate",newValueCartonRate)



    // },[OperationCartonRate,oldValueCartonRate,changeValueCartonRate,newValueCartonRate])



    // useEffect(()=>{


    //     switch (OperationStickRate) {
    //         case '?':
    //             setNewValueStickRate(oldValueStickRate)
    //             setValue("change_stick_rate",0)    
    //             break;
    //         case '+':
    //             setNewValueStickRate(Number(oldValueStickRate) + Number(changeValueStickRate))
    //             break;
    //         case '-':
    //             setNewValueStickRate(Number(oldValueStickRate) - Number(changeValueStickRate))
    //             break;
    //         case '*':
    //             setNewValueStickRate(Number(oldValueStickRate) * Number(changeValueStickRate))
    //             break;
    //         case '/':
    //             setNewValueStickRate(Number(oldValueStickRate) / Number(changeValueStickRate))
    //             break;

    //         default:
    //             // calculatedResult = 'Invalid operation';
    //     }

    //     setValue("new_stick_rate",newValueStickRate)

    // },[OperationStickRate,oldValueStickRate,changeValueStickRate,newValueStickRate])



    // useEffect(()=>{


    //     switch (OperationKGRate) {
    //         case '?':
    //             setNewValueKGRate(oldValueKGRate)
    //             setValue("change_one_kg_rate",0)    
    //             break;
    //         case '+':
    //             setNewValueKGRate(Number(oldValueKGRate) + Number(changeValueKGRate))
    //             break;
    //         case '-':
    //             setNewValueKGRate(Number(oldValueKGRate) - Number(changeValueKGRate))
    //             break;
    //         case '*':
    //             setNewValueKGRate(Number(oldValueKGRate) * Number(changeValueKGRate))
    //             break;
    //         case '/':
    //             setNewValueKGRate(Number(oldValueKGRate) / Number(changeValueKGRate))
    //             break;

    //         default:
    //             // calculatedResult = 'Invalid operation';
    //     }

    //     setValue("new_one_kg_rate",newValueKGRate)

    // },[OperationKGRate,oldValueKGRate,changeValueKGRate,newValueKGRate])




    // useEffect(()=>{





    //     switch (OperationModuloRate) {

    //         case 'yes':
    //             setNewValueCartonBasicRate(oldValueCartonBasicRate + (oldValueCartonBasicRate * changeValueModuloRate / 100))
    //             break;
    //         case 'no':
    //             setChangeValueModuloRate('')
    //             if(OperationCartonBasicRate === "?"){
    //             setNewValueCartonBasicRate(oldValueCartonBasicRate)
    //             }
    //             break;

    //         default:
    //             // calculatedResult = 'Invalid operation';
    //     }

    //     setValue("new_carton_basic_rate",newValueCartonBasicRate)




    // },[OperationModuloRate,oldValueCartonBasicRate,changeValueModuloRate,newValueCartonBasicRate])



    const [stickNOS, setStickNOS] = useState(0)
    const [cartonStick, setCartonStick] = useState(0)
    const [productTotalQty, setProductTotalQty] = useState(0)

    // useEffect(()=>{

    //         setProductTotalQty(Number(stickNOS) * Number(cartonStick))
    //         setValue("product_total_qty",productTotalQty)

    // },[stickNOS,cartonStick,productTotalQty])


    const handleStickNos = (e: any) => {
        setStickNOS(e.target.value)
    }

    const handleCartonStick = (e: any) => {
        setCartonStick(e.target.value)
    }











    let pageName = "Price List"
    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_PRICELIST_API?.toString()

    const { mutate: PriceListForm, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue, getValues }: any = useForm();





    const handleReset = () => {
        reset()
    }

    // const onSubmit = (detail: any) => {

    //     const formData = new FormData();

    //     for (const [key, value] of Object.entries(detail)) {
    //         const formvalue: any = value
    //         if (typeof value !== "object") {
    //             formData.append(key, formvalue)
    //         } else if (Array.isArray(value) && key === "panni_color") {
    //             value.forEach((val, index) => formData.append(`panni_color`, val))
    //         } 

    //     }
    //     PriceListForm(formData);

    // }


    const [priceData, setPriceData]: any = useState()


    const onSubmit = (data: any) => {

        // data.old_carton_basic_rate = oldValueCartonBasicRate
        // data.old_carton_rate = oldValueCartonRate
        // data.old_stick_rate = oldValueStickRate
        // data.old_one_kg_rate = oldValueKGRate


        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            const formvalue: any = value

            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }




        }
        if (priceData) {

            delete priceData.isBasicRateEditable
            delete priceData.inputBasicRateValue
            delete priceData.isRateEditable
            delete priceData.inputRateValue
            delete priceData.isStickRateEditable
            delete priceData.inputStickRateValue
            delete priceData.isOneKgEditable
            delete priceData.inputOneKgValue





            const filteredPriceData = priceData.map((item: any) => {

                console.log('item',item)
                // Filter out unwanted properties from each object
                const filteredItem = {
                    old_carton_basic_rate: item.old_carton_basic_rate,
                    old_carton_rate: item.old_carton_rate,
                    old_stick_rate: item.old_stick_rate,
                    old_one_kg_rate: item.old_one_kg_rate,
                    old_percentage_rate:item.old_percentage_rate,

                    product_rate_change_date:item.product_rate_change_date,

                    product_code: item?.id,
                    change_carton_basic_rate: item.change_carton_basic_rate,
                    change_carton_rate: item.change_carton_rate,
                    change_stick_rate: item.change_stick_rate,
                    change_one_kg_rate: item.change_one_kg_rate,
                    change_percentage_rate:item.change_percentage_rate,

                    new_carton_basic_rate: item.new_carton_basic_rate,
                    new_carton_rate: item.new_carton_rate,
                    new_stick_rate: item.new_stick_rate,
                    new_one_kg_rate: item.new_one_kg_rate,
                    new_percentage_rate:item.new_percentage_rate,
                    // Add more properties as needed
                };
                return filteredItem;
            });

            formData.append("pricelist_products", JSON.stringify(filteredPriceData));
        }

        PriceListForm(formData);
    }




console.log('priceData',priceData)




    const [productType, setProductType]: any = useState("");
    const [prductTypeValue, setProductTypeValue]: any = useState();

    // const { data: productTypeData, refetch: productTypeRefetch } = useFetchMaterialDensityDropDown();

    // useEffect(() => {
    //   setOptionsProductType(productTypeData);
    // }, [productTypeData]);


    // const handleChangeProductType = (e:any)=>{
    //   setProductTypeValue(e)
    //   setValue("product_name_and_grade_and_type",e.value)
    // }

    const [valueProductType, setValueProductType] = useState("")
    const [optionsProductType, setOptionsProductType]: any = useState([]);

    const { data: productTypeData, refetch: productTypeRefetch } = useFetchMaterialTypeDropDown();

    const [product_type, setProduct_type]: any = useState()

    useEffect(() => {
        setOptionsProductType(productTypeData);
    }, [productTypeData]);

    const handleChangeProductType = async (e: any) => {
        setValueProductType(e)
        setValue("product_type", e.value)
        // await setProduct_type(e?.label)
        setProduct_type(e?.label)

    }

    useEffect(() => {
        if (product_type) {
            product_typeMutate({ product_type: product_type })
        }
    }, [product_type])

    const [optionsUOM, setOptionsUOM] = useState()
    const [forUOM, setForUOM]: any = useState()

    const { data: uomData, refetch: uomRefetch } = useFetchUOMDropDown();

    useEffect(() => {
        setOptionsUOM(uomData);
    }, [uomData]);


    const handleChangeUOM = (e: any) => {
        setForUOM(e)
        setValue("unit_of_measure", e.value)
    }





    const [optionsGST, setOptionsGST] = useState()
    const [valueGST, setValueGST]: any = useState()

    const { data: gstData, refetch: gstRefetch } = useFetchGSTDropDown();

    useEffect(() => {
        setOptionsGST(gstData);
    }, [gstData]);


    const handleChangeGST = (e: any) => {
        setValueGST(e)
        setValue("gst_rate", e.value)
    }



    const [optionsDisposable, setOptionsDisposable] = useState()
    const [valueDisposable, setValueDisposable]: any = useState()

    let apinameForProduct_Type = `${process.env.REACT_APP_DISPOSABLE_PRODUCT_API}/search_filter`
    const { mutate: product_typeMutate, data: product_typeData } = useCreate(apinameForProduct_Type)



    const { data: disposableData, refetch: disposableRefetch } = useFetchDisposableDropDown();

    useEffect(() => {
        setOptionsDisposable(disposableData);
    }, [disposableData]);


    const handleChangeDisposable = (e: any) => {
        setValueDisposable(e)
        setValue("product_code", e.value)

    }





    const [optionsColor, setOptionsColor] = useState()
    const [valueColor, setValueColor]: any = useState()

    const { data: colorData, refetch: colorRefetch } = useFetchColorDropDown();

    useEffect(() => {
        setOptionsColor(colorData);
    }, [colorData]);


    const handleChangeColor = (e: any) => {
        setValueColor(e)
        setValue("product_color", e.value)
    }

    const [valuePanniColor, setValuePanniColor]: any = useState()

    const handleChangePanniColor = (e: any) => {
        let colors = e?.map((e: any) => {
            return e.value
        })
        setValuePanniColor(e)
        setValue("panni_color", colors)
    }





    const [optionsBrand, setOptionsBrand] = useState()
    const [valueBrand, setValueBrand]: any = useState()

    const { data: brandData, refetch: brandRefetch } = useFetchBrandDropDown();

    useEffect(() => {
        setOptionsBrand(brandData);
    }, [brandData]);


    const handleChangeBrand = (e: any) => {
        setValueBrand(e)
        setValue("product_brand", e.value)
    }



    const [requirementDate, setRequirementDate] = useState(null)
    const [changeDate, setChangeDate] = useState(null)

    const dateFormatList = ['DD-MM-YYYY'];
    // const disabledDate: RangePickerProps['disabledDate'] = (current) => {





    //     return  current && current  < dayjs().startOf('day');
    //   };


    const handleRequirementDate = (e: any) => {
        setRequirementDate(e)
        setValue("date", `${e?.$D}-${e?.$M + 1}-${e?.$y}`)
    }
    const [currentDate,setCurrentDate] = useState('')
    const handleChangeDate = (e: any,date:any) => {
        console.log('data',date)
        setChangeDate(e)
        setValue("rate_change_date", date)
        setCurrentDate(date)
    }





    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listPriceList', { state: { showToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isSuccess, data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 5000)

    }, [show]);


    const optionsPriceListType = [
        {
            value: "All",
            label: "All"
        },
        {
            value: "Grade",
            label: "Grade"
        },
        {
            value: "Glass Name",
            label: "Glass Name"
        },
        {
            value: "Type",
            label: "Type"
        },
        {
            value: "Brand",
            label: "Brand"
        },
        {
            value: "Diameter",
            label: "Diameter"
        },
        {
            value: "Color",
            label: "Color"
        },
        {
            value: "ML.",
            label: "ML."
        }
    ]

    const [selectedPriceListType, setSelectedPriceListType]: any = useState(null)

    const handleChangePriceListType = (e: any) => {
        setSelectedPriceListType(e)
        setValue("price_list_type", e?.label)
    }



    const [filterOptions, setFilterOptions]: any = useState([])
    const [selectedFilter, setSelectedFilter]: any = useState("")



    const [productBrand, setProductBrand]: any = useState()
    const [productColor, setProductColor]: any = useState()

    const handleChangeFilter = async (e: any) => {
        setSelectedFilter(e)
        if (selectedPriceListType?.label === "Brand") {
            let brandLabel = e?.label
            await setProductBrand(brandLabel)

        }
        if (selectedPriceListType?.label === "Color") {
            let colorLabel = e?.label
            await setProductColor(colorLabel)

        }
    }

    useEffect(() => {

        if (productBrand) {
            product_typeMutate({ product_type: product_type, product_brand: productBrand })
        }
        if (productColor) {
            product_typeMutate({ product_type: product_type, product_color: productColor })
        }

    }, [productBrand, productColor, selectedFilter])

    useEffect(() => {
    }, [product_typeData, selectedPriceListType])

    const [tableData, setTableData]: any = useState([])

    const [priceListAction, setPriceListAction] = useState()

    useEffect(() => {
        if (selectedPriceListType?.label === "Brand" && selectedPriceListType?.label != "All") {
            setFilterOptions(optionsBrand)
        }
        if (selectedPriceListType?.label === "Color" && selectedPriceListType?.label != "All") {
            setFilterOptions(optionsColor)
        }
        if (selectedPriceListType?.label === "All") {
            setSelectedFilter(null)
            product_typeMutate({ product_type: product_type })
        }

    }, [selectedPriceListType, selectedFilter, optionsBrand, optionsColor])

    // console.log("selectedFilter",selectedFilter,"table data",tableData,"product_typeData?.results",product_typeData?.results,"productTypeData",productTypeData)



    useEffect(() => {
        setTableData(product_typeData?.results)
        //  if(selectedPriceListType?.label === "All"){
        //     setTableData(product_typeData?.results)


        // }
    }, [product_typeData, tableData, selectedPriceListType])






    useEffect(() => {
        let temp = tableData?.map((e: any) => {
            return { old_carton_basic_rate: e?.changeCartonBasicRate }

        })
    }, [tableData])



    const optionsPriceListGrade = [
        {
            value: 1,
            label: "A"
        },
        {
            value: 2,
            label: "B"
        },
        {
            value: 3,
            label: "C"
        },
        {
            value: 4,
            label: "D"
        },
        {
            value: 5,
            label: "E"
        },

    ]
    const [valuePriceListGrade, setValuePriceListGrade]: any = useState()

    const handlePriceListGrade = (e: any) => {
        setValuePriceListGrade(e)
        setValue("grade", e?.label)
    }











    const [renderTable,setRenderTable] = useState(0)
    // const [priceListAction,setPriceListAction] = useState()

    const handlePriceAction = (e: any) => {
        setPriceListAction(e.target.value)
        setRenderTable(renderTable+1)
    }


       
          
        



const [rateType,setRateType] = useState('')

const handleChangeRateType=(e:any)=>{
    setValue('rate',e.target.value)
    setRateType(e.target.value)
    setRenderTable(renderTable+1)
}   





    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>{pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Masters</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Add {pageName}</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body  py-0"
                                    style={{ maxHeight: "80vh", overflow: "auto" }}
                                >                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message[0]} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">

                                                <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                                    <div className="col-md-12">
                                                        <div className="row">


                                                            <div className="col-sm-3 mt-4">
                                                                <label >Product Type<span style={{ color: 'red' }}>*</span></label>

                                                                <Select
                                                                    {...register("product_type", {
                                                                        required: {
                                                                            value: true,
                                                                            message: "Field is required",
                                                                        },
                                                                    })}
                                                                    onChange={handleChangeProductType}
                                                                    options={optionsProductType}
                                                                    value={valueProductType}
                                                                    components={customIndicator}
                                                             
                                                                />
                                                                <span className='text-red w-100'>{errors?.product_type?.message}</span>

                                                            </div>


                                                            <div className="col-sm-3 mt-4">
                                                                <label >Price-List Type<span style={{ color: 'red' }}>*</span></label>

                                                                <Select
                                                                    {...register("price_list_type", {
                                                                        required: {
                                                                            value: true,
                                                                            message: "Field is required",
                                                                        },
                                                                    })}
                                                                    // menuIsOpen
                                                                    onChange={handleChangePriceListType}
                                                                    options={optionsPriceListType}
                                                                    value={selectedPriceListType}
                                                                    components={customIndicator}

                                                                />
                                                                <span className='text-red w-100'>{errors?.price_list_type?.message}</span>

                                                            </div>


                                                            {filterOptions && selectedPriceListType?.label != null ?
                                                                <>
                                                                    <div className="col-sm-3 mt-4">
                                                                        <label >{selectedPriceListType?.label}<span style={{ color: 'red' }}>*</span></label>

                                                                        <Select
                                                                            isDisabled={selectedPriceListType?.label === "All"}
                                                                            placeholder={selectedPriceListType?.label === "All" && "Choose another option for filter"}
                                                                            onChange={handleChangeFilter}
                                                                            options={filterOptions}
                                                                            value={selectedFilter}
                                                                            components={customIndicator}
                                                                        />
                                                                    </div>
                                                                </>
                                                                : ""}



                                                            <div className="col-sm-3 mt-4">
                                                                <label >Price Action<span className='text-red'>*</span></label>
                                                                <select className="form-select"
                                                                    {...register("price", {onChange:handlePriceAction,
                                                                        required: { value: true, message: "Action is required" }, maxLength: 30,

                                                                    })}
                                                                    // onChange={handlePriceAction}
                                                                >
                                                                    <option value="" selected >Select Any</option>
                                                                    <option value="Up Price" >Up</option>
                                                                    <option value="Down Price" >Down</option>
                                                                </select>

                                                                <span className='text-red'>{errors?.price?.message}</span>

                                                            </div>



                                                            <div className="col-sm-3 mt-4">
                                                                <label >Rate Type With GST<span className='text-red'>*</span></label>
                                                                <select className="form-select"
                                                                    {...register("rate_type", {
                                                                        required: { value: true, message: "Rate is required" }, maxLength: 30,

                                                                    })}
                                                                >
                                                                    <option value="" selected >Select Any</option>
                                                                    <option value="With GST Rate" >With GST Rate</option>
                                                                    <option value="Basic Rate" >Basic Rate</option>
                                                                </select>

                                                                <span className='text-red'>{errors?.rate_type?.message}</span>

                                                            </div>


                                                            <div className="col-sm-3 mt-4">
                                                                <label >Rate Type<span className='text-red'>*</span></label>
                                                                <select className="form-select"
                                                                    {...register("rate", {onChange:handleChangeRateType,
                                                                        required: { value: true, message: "Rate is required" }, maxLength: 30,

                                                                    })}
                                                                >
                                                                    <option value="" selected >Select Any</option>
                                                                    <option value="Packet Rate" >Packet Rate</option>
                                                                    <option value="1 Kg. Rate" >1 Kg. Rate</option>
                                                                    <option value="% Rate" >% Rate</option>
                                                                    <option value="Carton Rate" >Carton Rate</option>
                                                                </select>

                                                                <span className='text-red'>{errors?.rate?.message}</span>

                                                            </div>

                                                            <div className="col-sm-3 mt-4">
                                                                <label >Price-List Name<span style={{ color: 'red' }}>*</span></label>
                                                                <div className="input-group" >
                                                                    <input type="text"
                                                                        className="form-control"
                                                                        placeholder="Price-List Name"
                                                                        {...register("name", { required: { value: true, message: "Field is required" } })}
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.name?.message}</span>
                                                            </div>

                                                            <div className="col-sm-3 mt-4">
                                                                <label >Price-List Grade<span style={{ color: 'red' }}>*</span></label>

                                                                <Select
                                                                    {...register("grade", {
                                                                        required: {
                                                                            value: true,
                                                                            message: "Field is required",
                                                                        },
                                                                    })}
                                                                    onChange={handlePriceListGrade}
                                                                    options={optionsPriceListGrade}
                                                                    value={valuePriceListGrade}
                                                                    components={customIndicator}

                                                                />
                                                                <span className='text-red w-100'>{errors?.grade?.message}</span>

                                                            </div>

                                                            <div className="col-sm-3 mt-4">
                                                                <label >Price-List Rate Change Date</label>
                                                                <div className="input-group" >

                                                                    <DatePicker
                                                                        className="form-control"
                                                                        format={dateFormatList}
                                                                        value={changeDate}
                                                                        // disabledDate={disabledDate}

                                                                        {...register("rate_change_date", { required: { value: true, message: "Date is required" } })}

                                                                        onChange={handleChangeDate}
                                                                    />


                                                                </div>
                                                                <span className='text-red'>{errors?.rate_change_date?.message}</span>
                                                            </div>

                                                            {(tableData?.length > 0 ?
                                                                <Price_List_Table key={renderTable} data={tableData} currentDate={currentDate} rateType={rateType} priceListAction={priceListAction} setPriceData={setPriceData} />
                                                                : ""
                                                            )}

                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="col-sm-3 mt-4">
                                                    <label >Status <span className='text-red'>*</span></label>
                                                    <select className="form-select"
                                                        {...register("status", {
                                                            required: { value: true, message: "Status is required" }, maxLength: 30,

                                                        })}
                                                    >
                                                        <option value="" selected >Select Any</option>
                                                        <option value="active" >Active</option>
                                                        <option value="inactive" >Inactive</option>
                                                    </select>

                                                    <span className='text-red'>{errors?.status?.message}</span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                        onClick={handleSubmit(onSubmit)}
                                                    >Submit</button>
                                                    <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPriceList")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Add_PriceList