import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { useNavigate, useParams } from "react-router-dom";
import { useGSTEdit, useUpdateGst } from "../../../hooks/GST";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import { useCreate, useFetchSingle, useUpdateSingle, useUpdateSingleforcewithyes, useUpdateSingleforcewithno } from "../../../hooks/Api";
import Select from "react-select"
import axios from 'axios'
import { Alert } from "../../../components/Model/Model";
import useFocusOnEnter from "../../../components/focus";
import CreatableSelect from "react-select/creatable";
import { useFetchEmployeeDropDown, useFetchPersonTypeDropDown, useFetchCountryDropDown } from "../../../hooks/DropDown_Api";
import Modal from "react-bootstrap/esm/Modal";
import { Deletesinglewithdependancy } from "../../../components/modal";
import Axiosinstance from "../../../hooks/Interceptor";
import Mui_Toaster from "../../../components/Mui_Toaster/Mui_Toaster";

interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOptionType = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});


const Edit_Contact_Format = ({ currentStep, setCurrentStep }: any) => {
    let pageName = "Contact Detail"


    let rawData = localStorage.getItem('Add Data');
    let addData = rawData ? JSON.parse(rawData) : null;

    const id = addData?.contact_detail?.id
    const tempData = addData?.contact_detail

    const data = tempData

    // const  id  = localStorage.getItem('Contact Details')
    const navigate = useNavigate();
    const [optionsType, setOptionsType]: any = useState([]);
    const [valueTypeCreatable, setValueTypeCreatable] = useState<Option | null>();
    const [valueType, setValueType]: any = useState();
    const [selectedDistrict, setSelectedDistrict]: any = useState();
    const [isLoadingValue, setIsLoadingValue] = useState(false);
    const [searchTerm, setSearchTerm] = useState('')

    const [selectedEmployee, setSelectedEmployee]: any = useState("")
    const [selEmployee, setSelEmployee]: any = useState("")

    const [selectedCountry, setSelectedCountry]: any = useState("")
    const [selCountry, setSelCountry]: any = useState("")


    const [openEditModel, setOpenEditModel] = useState(false)
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()
    const [expirationDate, setExpirationDate] = useState()

    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()


    const apiname = process.env.REACT_APP_CONTACT_DETAIL_API?.toString()
    // const {data} = useFetchSingle(id,apiname)

    // const  { mutate: Contact, data, isError, isLoading, isSuccess, error }:any = useCreate(apiname)
    const { mutate, isSuccess, isError, error, data: ContactUpdated_response }: any = useUpdateSingle()

    const { register, handleSubmit, setError, formState, reset, setValue, getValues }: any = useForm({
        mode: "onChange"
    });
    const { errors } = formState;


    const apinameForType = process.env.REACT_APP_PERSON_TYPE_API?.toString()

    const { mutate: mutateType, data: dataType, isError: TypeIsError, isLoading: TypeLoading, isSuccess: TypeSuccess, error: TypeError }: any = useCreate(apinameForType)



    const prevDataRef = useRef();

    useEffect(() => {
        const isDataChanged = JSON.stringify(data) !== JSON.stringify(prevDataRef.current);
        if (data && isDataChanged) {
            reset(data);
            prevDataRef.current = data;
        }
    }, [data, reset]);



    const { data: TypeData, refetch: TypeRefetch } = useFetchPersonTypeDropDown()
    useEffect(() => {
        setOptionsType(TypeData)
    }, [TypeData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            TypeRefetch()
        }
    }, [])


    const handleChangeType = (e: any) => {

        setValue("type", `${e?.value}`)
        setValueTypeCreatable(null)
        setValueType(e)
    }


    const handleCreate = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOptionType(inputValue);
            setIsLoadingValue(false);


            mutateType({ name: newOption.name, status: newOption.status })
            setOptionsType((prev: any) => [...prev, newOption]);
            setValueTypeCreatable(newOption);
        }, 0);


    };



    useEffect(() => {
        (async () => {
            if (TypeSuccess && optionsType) {
                await TypeRefetch();
            }
            setValue("type", TypeData[0]?.value)
        })

        return () => {
        };
    }, [TypeSuccess, TypeData]);


    const [employeeOptions, setEmployeeOptions] = useState([])
    const [forEmployeeValue, setForEmployeeValue]: any = useState("")
    const { data: EmployeeData, refetch: EmployeeRefetch } = useFetchEmployeeDropDown()


    useEffect(() => {
        setEmployeeOptions(EmployeeData)
    }, [EmployeeData])


    const handleChangeEmployee = (e: any) => {
        setForEmployeeValue(e)
        setValue("employee_code", e.value)
        setSelEmployee(e)
    }


    const [CountryOptions, setCountryOptions]: any = useState([])
    const [forCountryValue, setForCountryValue]: any = useState("")

    const { data: CountryData, refetch: CountryRefetch } = useFetchCountryDropDown()


    useEffect(() => {
        setCountryOptions(CountryData)
    }, [CountryData])


    const handleChangeCountry = (e: any) => {
        setForCountryValue(e)
        //   setValue("country",e.value)
        setValue('country_code', e.countryCode)
        setSelCountry(e)
    }



    const onSubmit = (dataContact: any) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(dataContact)) {
            const formvalue: any = value



            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }

        }
        let data = {
            id: id,
            apiname: apiname,
            data: formData
        }
        mutate(data)
        setValuesofform(data)
    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    const apinameForBasicDetails = process.env.REACT_APP_EMPLOYEE_API?.toString()
    const { mutate: BasicDetails, isSuccess: basicDetailsIsSuccess, isError: basicDetailsIsError, error: basicDetailsError, data: basicDetailsData }: any = useUpdateSingle()

    let rawDataTemp = localStorage.getItem('Add Data');
    let addDataTemp = rawDataTemp ? JSON.parse(rawDataTemp) : null;

    let basicIdTemp = addDataTemp ? addDataTemp.id : null;

    const [basicDetailsInitiated, setBasicDetailsInitiated] = useState(false)

    useEffect(() => {
        if ((isSuccess || yessuccess || nosuccess) && !basicDetailsInitiated) {
            setShow(true);
            setshowmessage("success");
            //   setCurrentStep(currentStep+1)
            let tempId = {
                contact_detail: data?.data?.id
            }

            let tempData = {
                id: basicIdTemp,
                apiname: apinameForBasicDetails,
                data: tempId
            }
            BasicDetails(tempData);
            setBasicDetailsInitiated(true);
            //   navigate("/listContactDetail", { state: { showEditToast: true } });
        } else if (isError) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [data, isSuccess, yessuccess, nosuccess, ContactUpdated_response, error,
        isSuccess,]);


    const [updateCompleted, setUpdateCompleted] = useState(false)

    useEffect(() => {
        if (basicDetailsIsSuccess && basicDetailsData && !updateCompleted) {
            
            localStorage.setItem('Add Data', JSON.stringify(basicDetailsData?.data))
            setUpdateCompleted(true);
             const currentPath = window.location.pathname;
            if(currentPath === '/addCustomerDetails'){
            setCurrentStep(currentStep + 1)
            };
        }
    }, [basicDetailsIsSuccess, basicDetailsData])


    useEffect(() => {
        if (isError) {
            if (error.response?.status === 405) {
                setConfirmmodal(true);
            }
        } else if (isError || yeserror || noerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [isError, yeserror, noerror]);
    useEffect(() => {
        setTimeout(() => {
            setShow(false);
        }, 3000);
    }, []);

    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }

    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }
    const noupdatemodal = () => {
        setOpenEditModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {

        delete data.created_at
        delete data.created_by
        let ok = {
            id: id,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditModel(false)
    }

    const handleReset = () => {
        reset()
    }


    // useEffect(() => {
    //     if (data) {
    //         reset(data)
    //         setSelectedDistrict(data.type?.id)
    //         setValue("type", data.type?.id)
    //         setSelectedEmployee(data.employee_code?.id)
    //         setValue("employee_code", data.employee_code?.id)
    //         setSelectedCountry(data.country?.id)
    //         // setValue("country",data.country?.id)
    //     }
    // }, [])



    const [documentType, setDocumentType] = useState("")
    const [regexForDocumentNumber, setRegexForDocumentNumber]: any = useState()

    const handleDocumentType = (e: any) => {


        const type = e.target.value
        if (type === "Lan") {
            setDocumentType("Lan Number")
            setRegexForDocumentNumber(/^[0-9]\d{2,4}\d{6,8}$/)
        } else if (type === "Mobile") {
            setDocumentType("Mobile Number")
            setRegexForDocumentNumber(/^(\+\d{1,3}[- ]?)?\d{10}$/g)
        } else if (type === "Fax") {
            setDocumentType("Fax Number")
            setRegexForDocumentNumber(/[\+? *[1-9]+]?[0-9 ]+/gm)
        }
    }

    const maxLengthCheck = (object: any) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    // console.log(data,"datadata");


    const handleNumbercode = (e: any) => {
        let temp = e.target.value.replace(/\D/g, '');
        if (temp.length > 10) temp = temp.substring(0, 10);
        if (temp.length > 6) {
            temp = temp.slice(0, 5) + ' ' + temp.slice(5);
        }
        e.target.value = temp;

        setValue('service_mobile_no', temp)
    }

    const currentPath = window.location.pathname;

    
    const apinameContect= process.env.REACT_APP_CONTACT_DETAIL_API?.toString()
    console.log(data,"data-data");
    
        useEffect(()=>{
            if(data.id){ 
                const getCall = async()=>{
                    // debugger
                    try {
                    const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameContect}/${data.id}/`);
                    console.log('ResponseData:', response.data.data);
                        reset(response.data.data)
                        // setSelectedDistrict(response.data.data?.type?.id)
                        // setValue("type", response.data.data?.type?.id)
                        // setSelectedEmployee(response.data.data?.employee_code?.id)
                        // setValue("employee_code", response.data.data?.employee_code?.id)
                        // setSelectedCountry(response.data.data?.country?.id)
                  
                  } catch (error) {
                    console.error('Error:', error);
                  }
                }
                getCall()
            }
          
    },[])


    return (
        <div>
            <div >
                <section className="content-header">
                    {/* <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit {pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Sales And Marketing</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">{pageName}</a></li>
                                    <li className="breadcrumb-item active">Edit {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div> */}
                </section>
                <section className="">
                    <div className="">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Source Name</span></h5>
                            </div> */}
                            <form
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" >
                                <div className="row">
                                        <p className="mb-0 " style={{fontSize:'14px'}}>{`${addData?.person_type?.name}-${addData?.name}-${addData?.extra_response?.city_name_and_distict_name?.village_name?addData?.extra_response?.city_name_and_distict_name?.village_name:addData?.extra_response?.city_name_and_distict_name?.city_name}`}</p>
                                    </div>
                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message?.name} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                {/*<div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Type <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                    isClearable={()=>{setValueType(null)
                                                        setValue(null)}}
                                                        {...register("type", { required: { value: true, message: "Type is required" } })}
                                                        placeholder={'Select Type'}
                                                        options={optionsType}
                                                        onCreateOption={handleCreate}
                                                        onChange={handleChangeType}
                                                        // value={valueTypeCreatable==null?valueType:valueTypeCreatable}
                                                        value={valueTypeCreatable==null?valueType==null?optionsType?.filter((obj:any) => obj.value === selectedDistrict):valueType:valueTypeCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.type?.message}</span>

                                                </div> */}
                                                {/*<div className="col-sm-3 mt-2">
                                            <label htmlFor="name_and_grade">Employee Code,Surname,Name & City <span style={{ color: 'red' }}>*</span></label>
                                                    
                                                        <Select 
                                                            {...register("employee_code",{required: { value: true, message: "Field is required" }})}
                                                            // value={forEmployeeValue}
                                                            value={selEmployee === "" ? employeeOptions?.find((obj: any) => obj.value === selectedEmployee) : selEmployee}
                                                            options={employeeOptions}
                                                            onChange={handleChangeEmployee}
                                                          

                                                        />
                                                       

                                                </div> */}
                                                {/* <div className="col-sm-3 mt-2">
                                                <label htmlFor="name_and_grade">Country <span style={{ color: 'red' }}>*</span></label>
                                                    
                                                        <Select 
                                                            // {...register("country",{required: { value: true, message: "Field is required" }})}
                                                            options={CountryOptions}
                                                            value={selCountry === "" ? CountryOptions?.find((obj: any) => obj.value === selectedCountry) : selCountry}
                                                            onChange={handleChangeCountry}
                                                            // isOptionDisabled={(options:any)=>
                                                            //     {
                                                            //         if(options.status === "inactive" || options.deleted == true){
                                                            //         return options
                                                            //         }
                                                            //        }}

                                                        />
                                                       

                                                </div> */}

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Country </label>
                                                    <input type="text" className="form-control" id="exampleInputBrand" disabled value={addData?.extra_response?.city_name_and_distict_name?.country_name} placeholder="Country"
                                                    // {...register("country", { required: { value: false, message: "Country is required" } })} 
                                                    />
                                                    <span className='text-red w-100'>{errors?.country?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Email Type <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("email_type", { required: { value: true, message: "Field is required" } })}>

                                                    <option value={""} selected>Select Any</option>
                                                        <option value="Home">Home</option>
                                                        <option value="Shop">Shop</option>
                                                        <option value="Office">Office</option>
                                                        <option value="Personal">Personal</option>
                                                        <option value="Company Email Id">Company Email Id</option>
                                                        <option value="Personal Email Id">Personal Email Id</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.email_type?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Number Type <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("number_type", { required: { value: true, message: "Field is required" } })}>

                                                    <option value={""} selected>Select Any</option>
                                                        <option value="Mobile-1">Mobile-1</option>
                                                        <option value="Mobile-2">Mobile-2</option>
                                                        <option value="Home Mobile">Home Mobile</option>
                                                        <option value="Shop">Shop</option>
                                                        <option value="Office">Office</option>
                                                        <option value="Company Mobile No">Company Mobile No</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.number_type?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Country Code </label>
                                                    {/* <div className="input-group"> */}
                                                    <input type="text" className="form-control" id="exampleInputBrand" disabled value={addData?.extra_response?.city_name_and_distict_name?.country_code} placeholder="City Name" {...register("country_code", { required: { value: false, message: "Field is required" } })} />

                                                    {/* </div> */}
                                                    <span className='text-red w-100'>{errors?.country_code?.message}</span>
                                                </div>

                                                {/*<div className="col-sm-3 mt-2">
                                                <label htmlFor="exampleInputEmail1">Device Type <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("device_type", {onChange:handleDocumentType, required: { value: true, message: "Field is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="Lan">LAN</option>
                                                        <option value="Mobile">Mobile</option>
                                                        <option value="Fax">FAX</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.device_type?.message}</span>
                                                </div> */}

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Mobile Number <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" id="exampleInputBrand"
                                                            placeholder={documentType ? documentType : "Mobile Number"}
                                                            
                                                            // onInput={documentType ==="Mobile Number" && maxLengthCheck}
                                                            {...register('number', {
                                                                // onChange:maxLengthCheck,
                                                                required: 'Mobile Number is required',
                                                                pattern: {
                                                                    value: regexForDocumentNumber,
                                                                    message: 'Please enter a valid Mobile Number',
                                                                },
                                                            })} 
                                                            onChange={handleNumbercode}
                                                            />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.number?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Contact Name & Surname <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Contact Name & Surname"
                                                            {...register('contact_name_and_surname', { required: true, message: 'Contact Name & Surname is required' })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.contact_name_and_surname?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Email Address <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="email" className="form-control" id="exampleInputBrand" placeholder="Email Address"
                                                            {...register('email_address', {
                                                                required: 'Email is required',
                                                                pattern: {
                                                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                                                                    message: 'Please enter a valid email',
                                                                },
                                                            })}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.email_address?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Website </label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Website"
                                                            {...register('website', { required: false, message: 'Website is required' })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.website?.message}</span>
                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Re-Mark </label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Re-Mark"
                                                            {...register('re_mark', { required: false, message: 'Re-Mark is required' })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.re_mark?.message}</span>
                                                </div>




                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>

                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                        onClick={handleSubmit(onSubmit)}
                                                    >Submit</button>
<button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetail")}>Cancel</button>
{currentPath === '/addCustomerDetails'?
                                         <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => setCurrentStep(currentStep-1)}>Back</button> 
                                        :null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
            <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                                {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>
                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>
                </Modal.Footer>
            </Modal>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />

                
{show && (
          <Mui_Toaster
            message={`Contact Details Updated Successfully`}
            severity={"success"}
            Isopen={true}
          />
        )}
        </div>
    )
}

export default Edit_Contact_Format


