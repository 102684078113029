import { useMutation } from "react-query";
import Axiosinstance from "./Interceptor";

interface viewUsage {
    viewId: Array<any>
    apiname: string | undefined

}

const viewData = (apiname: string | undefined, id: any) => {

    console.log('id',id)
    
    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/check_usages/?id=${id?.id}`, id);
}

export const useViewData = () => {
    return useMutation((input: viewUsage) => {
        return viewData(input.apiname, { id: input.viewId })
    }
    )
}
// export const useDeletemultipleforce = () => {
//     return useMutation((input: datamultidelete) => {
//         return deletemultipledataforce(input.apiname, { id: input.deleteMultipleid })
//     }

//     )
// }

// const deletemultipledataforce = (apiname: string | undefined, temp_data: any) => {
//     return Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apiname}/deletelist/?force=true`, temp_data);
// }