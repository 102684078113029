import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Steps, Button, Form, Select, DatePicker } from 'antd';

const { Step } = Steps;
const { Option } = Select;

const optionsAccount = [
  { label: 'Option 1', value: 'option1' },
  { label: 'Option 2', value: 'option2' },
  { label: 'Option 3', value: 'option3' },
];

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

const Stepper = () => {
  const { register,handleSubmit, control,  formState, setValue }:any = useForm();

  const {errors} = formState
  const [currentStep, setCurrentStep] = useState(0);

  const onSubmit = (data:any) => {
    // console.log('Form data:', data);

    if (currentStep < 3) {
       const currentPath = window.location.pathname;
            if(currentPath === '/addCustomerDetails'){
            setCurrentStep(currentStep + 1)
            };
    }
  };

  return (
    <div>
      <Steps current={currentStep}>
        <Step title="Step 1" />
        <Step title="Step 2" />
        <Step title="Step 3" />
        <Step title="Step 4" />
      </Steps>
      <Form
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
        style={{ marginTop: '20px',marginLeft:"100px" }}
      >
        {currentStep === 0 && (
          <div className="col-sm-4 mt-2">
            <label>Account Type <span style={{ color: 'red' }}>*</span></label>
            <Controller
              name="account_type"
              control={control}
              rules={{ required: 'Account Type is required' }}
              render={({ field }) => (
                <Select
                  placeholder="Account Type"
                  {...field}
                  options={optionsAccount}
                />
              )}
            />
            <span className='text-red w-100'>{errors?.account_type?.message}</span>
          </div>
        )}

        {currentStep === 1 && (
          <div className="col-sm-4 mt-2">
            <label>Customer Organization Type <span style={{ color: 'red' }}>*</span></label>
            <div className="input-group">
              <select
                className="form-select"
                {...register("customer_organization_type", { required: { value: true, message: "Cavity Type is required" } })}
              >
                <option value={""} selected>Select Any</option>
                <option value="Pvt.Ltd">Pvt.Ltd.</option>
                <option value="Limited">Limited</option>
                <option value="Proprietor">Proprietor</option>
                <option value="Partnership">Partnership</option>
                <option value="LLP">LLP</option>
              </select>
            </div>
            <span className='text-red w-100'>{errors?.customer_organization_type?.message}</span>
          </div>
        )}

        {currentStep === 2 && (
          <div className="col-sm-4 mt-2">
            <label>Company Starting Date <span style={{ color: 'red' }}>*</span></label>
            <div className="input-group">
              <DatePicker
                className="form-control"
                format={dateFormatList}
                // disabledDate={disabledDate}
                {...register("company_date_of_birth", { required: { value: true, message: "Date is required" } })}
              />
            </div>
            <span className='text-red'>{errors?.company_date_of_birth?.message}</span>
          </div>
        )}

        {currentStep === 3 && (
          <div className="col-sm-4 mt-2">
            <label>Customer Running-Not <span style={{ color: 'red' }}>*</span></label>
            <div className="input-group">
              <select
                className="form-select"
                {...register("customer_running_not", { required: { value: true, message: "Field is required" } })}
              >
                <option value={""} selected>Select Any</option>
                <option value="running">Running</option>
                <option value="not">Not</option>
              </select>
            </div>
            <span className='text-red w-100'>{errors?.customer_running_not?.message}</span>
          </div>
        )}

        <Form.Item >
          {currentStep > 0 && (
            <Button
              style={{ marginRight: '8px' }}
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              Previous
            </Button>
          )}
          {currentStep < 3 && (
            <Button
            style={{ marginLeft: '8px' }}
              type="primary"
              onClick={() => setCurrentStep(currentStep + 1)}>
              Next
            </Button>
          )}
          {currentStep === 3 && (
            <Button
            style={{ marginLeft: '8px' }}
              type="primary"
              htmlType="submit"
              loading={formState.isSubmitting}
            >
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default Stepper;
