import { createSlice } from '@reduxjs/toolkit'

export const permissions = createSlice({
  name: 'permissions',
  initialState: {
    permissions: [],
    error: null,
  },
  reducers: {
    setPermissions: (state, action) => {
      state.permissions = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
})


// Action creators are generated for each case reducer function
export const { setPermissions, setError} = permissions.actions

// export const FetchPermissions = () => {
//   const dispatch = useDispatch()
//   Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}leouser/user/get_related_permissions`).then((data:any) => {
//     dispatch(setPermissions(data?.data?.results))
//     return data?.data?.results
//   })
// }


// export const fetchDataAsync = () => () => {
//   const dispatch = useDispatch()
//   try {
//     // const data = FetchPermissions()
//     dispatch(setPermissions(data))
//   } catch (error: any) {
//     dispatch(setError(error.message))
//   }
// };




export default permissions.reducer