import React, { useEffect, useState } from "react";
import "../../../components/Header/header.css"
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import { useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../../hooks/Api";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import { useFetchEmployeeDropDown } from "../../../hooks/DropDown_Api";
import Modal from "react-bootstrap/esm/Modal";
import { Deletesinglewithdependancy } from "../../../components/modal";



const Edit_Sales_Designation = () => {
    let pageName = "Designation"
    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_SALES_DESIGNATION_API?.toString()
    const { id } = useParams()
    const { data } = useFetchSingle(id, apiname)
    const { mutate, isSuccess, isError, error, data: panniUpdated_response }: any = useUpdateSingle()

    const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();

    const [openEditModel, setOpenEditModel] = useState(false)
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()
    const [expirationDate, setExpirationDate] = useState()

    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()


    useEffect(() => {
        if (data) {

            reset(data)
            setSelectedDesignation(data?.use_employee_code_and_surname_and_name?.map((e: any) => e.id))
            setValue("use_employee_code_and_surname_and_name", data?.use_employee_code_and_surname_and_name?.map((e: any) => e.id))
            setEmployeeQty(data?.employee_qty)
            setValue("employee_qty", data?.employee_qty)
        }
    }, [data]);



    const onSubmit = (branddetail: any) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else if (Array.isArray(value) && key === "use_employee_code_and_surname_and_name") {
                value.forEach((val, index) => formData.append(`use_employee_code_and_surname_and_name`, val))
            }
        }
        let data = {
            apiname: apiname,
            id: id,
            data: formData
        }

        mutate(data);
        setValuesofform(data)

    }




    const [forDesignationValue, setForDesignationValue]: any = useState()
    const [designationOptions, setDesignationOptions]: any = useState()
    const [employeeQty, setEmployeeQty] = useState(0)
    const [selDesignation, setSelDesignation]: any = useState("")
    const [selectedDesignation, setSelectedDesignation]: any = useState("")


    const { data: designationData, refetch: designationRefetch } = useFetchEmployeeDropDown()


    useEffect(() => {
        setDesignationOptions(designationData)
    }, [designationData])


    const handleChangeDesignation = (e: any) => {
        let employees = e?.map((e: any) => {
            return e.value
        })
        setSelDesignation(e)
        setValue("use_employee_code_and_surname_and_name", employees)

        setEmployeeQty(employees?.length)
        setValue("employee_qty", employees?.length)
    }










    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")


    useEffect(() => {
        if (isSuccess || yessuccess || nosuccess) {
            setValuesofform(null)
            setShow(true)
            navigate('/listDesignation', { state: { showEditToast: true } })
        }

    }, [data, isSuccess, yessuccess,
        nosuccess, panniUpdated_response])

    useEffect(() => {
        if (isError) {
            if (error.response.status === 405) {
                setConfirmmodal(true)
            }
        }
        else if (isError || yeserror || noerror) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isError, yeserror, noerror])
    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    // useEffect(() => {
    //     if (isSuccess) {
    //         setShow(true)
    //         // setshowmessage("success")
    //         navigate('/listDesignation', { state: { showEditToast: true } })

    //     }
    //     else if (isError) {
    //         setShow(true)
    //         setshowmessage("danger")
    //     }
    // }, [data, error,isSuccess])

    // useEffect(() => {
    //     setTimeout(() => {
    //         setShow(false)
    //     }, 3000)

    // }, [show]);

    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }
    const noupdatemodal = () => {
        setOpenEditModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {

        delete data.created_at
        delete data.created_by
        let ok = {
            id: id,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditModel(false)
    }


    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }
    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header px-3 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit {pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">HR</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">{pageName}</Link></li>
                                    <li className="breadcrumb-item">Edit {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                                    {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message} /> : ""}

                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">

                                                <div className="col-sm-3 mt-2">
                                                    <label >Designation Name  <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="text" className="form-control" placeholder="Designation Name" {...register("designation", { onChange: handleValue, required: { value: true, message: "Designation Name is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.designation?.message}</span>
                                                </div>
                                                {/* <div className="col-sm-4 mt-2">
                                                    <label >Use Employee Code,Surname & Name  <span style={{ color: 'red' }}>*</span></label>
                                                        <Select 
                                                            {...register("use_employee_code_and_surname_and_name",{required: { value: true, message: "Field is required" }})}
                                                            value={selDesignation === "" ? designationOptions?.filter((obj: any) => selectedDesignation.includes(obj.value)) : selDesignation}
                                                            options={designationOptions}
                                                            onChange={handleChangeDesignation}
                                                            isMulti
                                                            
                                                            className="dropdown-select"

                                                        />

                                                <span className='text-red w-100'>{errors?.use_employee_code_and_surname_and_name?.message}</span>
                                                      
                                                </div> */}
                                                {/* <div className="col-sm-4 mt-2">
                                                    <label >Employee Qty</label>
                                                    <div className="input-group" >
                                                    <input type="text" className="form-control" disabled value={employeeQty}  placeholder="" {...register("employee_qty",{ required: { value: false, message: "Employee Qty is required" }})} />
                                                        </div>
                                                    <span className='text-red w-100'>{errors?.employee_qty?.message}</span>
                                                </div> */}
                                                <div className="col-sm-3 mt-2">
                                                    <label >Status  <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>
                                                        <option value={""} selected>Select Any</option>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"inactive"}>Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >Designation Code</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" placeholder="Designation Code" {...register("designation_code", { required: { value: false, message: "Field is required" } })} />
                                                        {/* <div className="input-group-append" >
                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                    /></span>

                                            </div> */}
                                                    </div>
                                                </div>




                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <a type="button" className="btn border rounded text-white px-4 mt-2 mx-3" onClick={handleSubmit(onSubmit)}>Submit</a>


                                                    <button type="button" className="btn border-1 rounded  px-4 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listDesignation")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
            <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                                {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>
                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>
                </Modal.Footer>
            </Modal>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />
        </div>
    )
}

export default Edit_Sales_Designation