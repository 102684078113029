
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import Actions from "../../components/Cell/Actions";
// import { updateBrand, useFetchBrands } from "../../hooks/brand";
// import noImage from "../../images/noImage.png"
import { useFetchGst, useUpdateGst } from "../../hooks/GST";


interface Data {
  selection: any
  mould_shape_code	:any,
  mould_shape:any,
  created_at:any

  created_by:any
  updated_by:any
  updated_at:any
//   status: any;
  actions: any;
}


export const titleofheaders=[
  {
    id: `srno`,
    Header: `Sr No`,
  },
 
  {
    id: 'status',
    Header: 'Status',
  },
]


export const Columns_MouldShape: Column<Data>[] = [



  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_MouldShape, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows} = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}mouldshape/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
  {
    id: `srno`,
    Header: `Sr.No`,
  },
  {
    id: `mould_shape_code`,
    Header: `Mould Shape Code`,
    accessor:'mould_shape_code',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.mould_shape_code}
        </div>
    )}

  }, {
    id: `mould_shape`,
    Header: `Mould Shape`,
    accessor:'mould_shape',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.mould_shape}
        </div>
    )}
  },

  
 

 


  

  
  {
    id: 'created_at',
    Header: 'Created at',
    accessor:'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: 'Created By',
    accessor:'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username

 
  },
  {
    id: 'updated_at',
    Header: 'Updated At',
    accessor:'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: 'Updated By',
    accessor:'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },


  {
    id: 'Actions',
    Header: 'Actions',

  
  },
]



