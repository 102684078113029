
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import Actions from "../../components/Cell/Actions";
// import { updateBrand, useFetchBrands } from "../../hooks/brand";
// import noImage from "../../images/noImage.png"
import { useFetchGst, useUpdateGst } from "../../hooks/GST";
import { Update, useFetch, useUpdateSingle } from "../../hooks/Api";
import Modal from "react-bootstrap/esm/Modal";
import noImage from "../../images/noImage.png"


interface Data {
  selection: any
  cavity_code: any
  product_name: any
  cavity_shape: any
  cavity_size_mm: any
  cavity_length_mm: any
  cavity_width_mm: any
  name: any,
  height: any
  type: any
  mould_usage: any
  image: any
  pdf: any
  cavity_stock_pcs:any
  created_at: any

  created_by: any
  updated_by: any
  updated_at: any
  status: any;
  actions: any;
}

export const titleofheaders = [
  {
    id: `srno`,
    Header: `Sr.No`,

  },


  {
    id: `cavity_code`,
    Header: `Cavity Code`,

  },
  {
    id: `name`,
    Header: `Cavity Name`,

  },
  {
    id: `product_name`,
    Header: `Product Name`,

  },



  {
    id: `mould_usage`,
    Header: `Mould Usage`,

  },




  {
    id: `cavity_stock_pcs`,
    Header: `Cavity Stock Pcs`,

  },

  {
    id: `height`,
    Header: `Cavity Height`,

  },
  {
    id: `cavity_shape`,
    Header: `Cavity Shape`,

  },
  {
    id: `cavity_size_mm`,
    Header: `Cavity Size MM`,

  },
  {
    id: `cavity_length_mm`,
    Header: `Cavity Length MM`,

  },
  {
    id: `cavity_width_mm`,
    Header: `Cavity Width MM`,

  },



  {
    id: `type`,
    Header: `Cavity Type`,

  },
  {
    id: `image`,
    Header: `Cavity Image`,

  },
  {
    id: `pdf`,
    Header: `Cavity PDF`,

  },




  {
    id: 'created_at',
    Header: 'Created at',

  },


  {
    id: 'created_by',
    Header: 'Created By',


  },
  {
    id: 'updated_at',
    Header: 'Updated At',

  },
  {
    id: 'updated_by',
    Header: 'Updated By',

  },

  {
    id: 'status',
    Header: 'Status',



  },

  {
    id: 'Actions',
    Header: 'Actions',


  },
  {
    id: 'Selection',
    Header: 'Selection'
  }
]



export const Columns_Cavity: any = [




  {
    id: `srno`,
    Header: () => <div style={{ display: "inline" }}>Sr<br />No</div>,
    sticky: "left",
  },


  {
    id: `cavity_code`,
    Header: () => <div style={{ display: "inline" }}>Cavity<br />Code</div>,
    accessor: 'cavity_code',
    sticky: "left",
    Cell: ({ row }: any) =>

      <div>
        {row.original.cavity_code}
      </div>
  },
  {
    id: `name`,
    Header: () => <div style={{ display: "inline" }}>Cavity<br />Name</div>,
    accessor: 'name',
    // sticky:"left",
    Cell: ({ row }: any) =>

      <div>
        {row.original.name?.name}
      </div>
  },
  {
    id: `product_name`,
    Header: () => <div style={{ display: "inline" }}>Product<br />Name</div>,
    accessor: 'product_name',
    Cell: ({ row }: any) =>

      <div>
        {row.original.product_name?.name}
      </div>
  },

  {
    id: `materialname_type_category_hsn_gst`,
    Header: () => <div style={{display:"inline"}}>Material Name & Type &<br/>Category & HSN & GST%</div>,

    accessor:'materialname_type_category_hsn_gst',
    Cell: ({ row }: any) => {
    return(
    <div>
      {row.original?.extra_response?.materialname_type_category_hsn_gst?.grade_type?.material_type_name}::{row.original?.extra_response?.materialname_type_category_hsn_gst?.particular_sector_name?row.original?.extra_response?.materialname_type_category_hsn_gst?.particular_sector_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}::
      {row.original?.extra_response?.materialname_type_category_hsn_gst?.product_categories_name?row.original?.extra_response?.materialname_type_category_hsn_gst?.product_categories_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}::
      {row.original?.extra_response?.materialname_type_category_hsn_gst?.hsn_code}::{row.original?.extra_response?.materialname_type_category_hsn_gst?.india_GST}


    </div>
    )}
  },

  {
    id: `gst_type`,
    Header: () => <div style={{display:"inline"}}>GST<br/>Type</div>,

    accessor:'gst_type',
    Cell: ({ row }: any) => 
    
    <div>
      {row.original?.extra_response?.materialname_type_category_hsn_gst?.gst_type?row.original?.extra_response?.materialname_type_category_hsn_gst?.gst_type:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

    </div>

  },
  {
    id: `type_name_gst`,
    Header: () => <div style={{display:"inline"}}>Type Name & Density<br/>& Sutra & GST%</div>,

    accessor:'type_name_gst',
    Cell: ({ row }: any) => {
    return(
    <div>
      {row.original?.extra_response?.materialname_type_category_hsn_gst?.grade_type?.material_type_name}::
      {row.original?.extra_response?.materialname_type_category_hsn_gst?.grade_type?.material_density === "0.000" || row.original?.extra_response?.materialname_type_category_hsn_gst?.grade_type?.material_density=== null 
      ? <span style={{ color: 'red'}}>0.000</span>:row.original?.extra_response?.materialname_type_category_hsn_gst?.grade_type?.material_density}::
      {row.original?.extra_response?.materialname_type_category_hsn_gst?.grade_type?.grade_sutra=== null?<span style={{ color: 'red',fontSize:"20px" }}>****</span>:row.original?.extra_response?.materialname_type_category_hsn_gst?.grade_type?.grade_sutra}
      ::{row.original?.extra_response?.materialname_type_category_hsn_gst?.grade_type?.grade_type_india_GST}


    </div>
    )}
  },
  {
    id: `product_code_name_type`,
    Header: () => <div style={{ display: "inline" }}>Use Product Code<br />& Name & Type</div>,
    accessor: 'product_code_name_type',
    Cell: ({ row }: any) =>

      <div>
        {row.original.product_code_name_type?row.original.product_code_name_type:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
      </div>
  },
  {
    id: `product_qty`,
    Header: () => <div style={{ display: "inline" }}>Use Product<br />Qty</div>,
    accessor: 'product_qty',
    Cell: ({ row }: any) =>

      <div>
        {row.original.product_qty?row.original.product_qty:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
      </div>
  },
  {
    id: `product_single`,
    Header: () => <div style={{ display: "inline" }}>Product Single Pcs<br />Code & Name & Color</div>,
    accessor: 'product_single',
    Cell: ({ row }: any) =>

      <div>
        {row.original.product_single?row.original.product_single:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
      </div>
  },
  {
    id: `product_single_qty`,
    Header: () => <div style={{ display: "inline" }}>Product Single Pcs<br />Code Qty</div>,
    accessor: 'product_single_qty',
    Cell: ({ row }: any) =>

      <div>
        {row.original.product_single_qty?row.original.product_single_qty:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
      </div>
  },

  

  {
    id: `mould_usage`,
    Header: () => <div style={{ display: "inline" }}>Mould Code & <br />Diameter & Cavity Qty</div>,
    accessor: 'mould_usage',
    Cell: ({ row }: any) => {
      return <div>
        {row.original?.mould_usage ?
          <>
            {`${row.original?.extra_response?.mould_usage?.mould_code}::${row.original?.extra_response?.mould_usage?.product_mould_diameter_mm}::${row.original.extra_response?.mould_usage?.cavity_qty}`}
          </>
          : <span style={{ color: 'red',fontSize:"20px" }}>****</span>}
      </div>;
      // return row.original?.use_mould_code_and_cavity.split("_").map((w: any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")

    }

  },





  {
    id: `cavity_stock_pcs`,
    Header: () => <div style={{ display: "inline" }}>Cavity<br />Stock Pcs</div>,
    accessor: 'cavity_stock_pcs',
    Cell: ({ row }: any) =>

      <div>
        {row.original.cavity_stock_pcs}
      </div>
  },
 

  {
    id: `cavity_shape`,
    Header: () => <div style={{ display: "inline" }}>Cavity<br />Shape</div>,
    accessor: 'cavity_shape',
    Cell: ({ row }: any) =>

      <div>
        {row.original.cavity_shape?.name}
      </div>
  },
  {
    id: `shape_image`,
    Header: () => <div style={{ display: "inline" }}>Cavity Shape<br />Image</div>,
    accessor: 'shape_image',
    Cell: ({ row }: any) =>{
      const [deleteConfirm, setDeleteConfirm] = useState(false)
      

    return (
      <>


<div >

          {!row.original.cavity_shape?.shape_image?<>
            <a 
          
          href="#"
          
          // data-large-src={row.original.cavity_shape?.shape_image?row.original.cavity_shape?.shape_image:noImage} data-toggle="modal"
          >
            {/* <img src={''}  height={60} width={100}/> */}
            </a>
          </>:<>
          <a 
          onClick={()=>setDeleteConfirm(true)}
          href={`#galleryModal${row.original.name}`}
          
          data-large-src={row.original.cavity_shape?.shape_image?row.original.cavity_shape?.shape_image:''} data-toggle="modal">
            <img src={row.original.cavity_shape?.shape_image?row.original.cavity_shape?.shape_image:''}  height={30} width={'auto'}/>
            </a>
          </>}
         
        </div>

        <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
        centered    >
        <Modal.Header >
          <Modal.Title>
        
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>  
        <img src={row.original.cavity_shape?.shape_image?row.original.cavity_shape?.shape_image:noImage} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid"/>

        </Modal.Body>
        <Modal.Footer>
        <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

        </Modal.Footer>
      </Modal>
       
        </>
        )}
  },
  {
    id: `cavity_size_mm`,
    Header: () => <div style={{ display: "inline" }}>Cavity<br />Size MM</div>,
    accessor: 'cavity_size_mm',
    Cell: ({ row }: any) =>

      <div>
        {row.original.cavity_size_mm?row.original.cavity_size_mm:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
      </div>
  },
  
  {
    id: `cavity_width_mm`,
    Header: () => <div style={{ display: "inline" }}>Cavity<br />Width MM</div>,
    accessor: 'cavity_width_mm',
    Cell: ({ row }: any) =>

      <div>
        {row.original.cavity_width_mm === '0.00'|| row.original.cavity_width_mm === null ? <span style={{ color: 'red',fontSize:"20px" }}>****</span>: row.original.cavity_width_mm}
      </div>
  },
  {
    id: `cavity_length_mm`,
    Header: () => <div style={{ display: "inline" }}>Cavity<br />Length MM</div>,
    accessor: 'cavity_length_mm',
    Cell: ({ row }: any) =>

      <div>
        {row.original.cavity_length_mm === '0.00'||row.original.cavity_length_mm === null?<span style={{ color: 'red',fontSize:"20px" }}>****</span>:row.original.cavity_length_mm}
      </div>
  },
  {
    id: `height`,
    Header: () => <div style={{ display: "inline" }}>Cavity<br />Height MM</div>,
    accessor: 'height',
    Cell: ({ row }: any) =>

      <div>
        {row.original.height}
      </div>
  },
  {
    id: `type`,
    Header: () => <div style={{ display: "inline" }}>Cavity<br />Type</div>,
    accessor: 'type',
    Cell: ({ row }: any) =>

    <div>
      {row.original.type?.name}
    </div>
  },




 
  {
    id: `image`,
    Header: () => <div style={{ display: "inline" }}>Cavity<br />Image All</div>,

    // accessor: `logo`,
    Cell: ({ row }: any) => {

      const [deleteConfirm, setDeleteConfirm] = useState(false)


      return (
        <>

          <div >

            {!row.original.image ? <>
              <a

                href="#"

              // data-large-src={row.original.image?row.original.image:noImage} data-toggle="modal"
              >
                <img src={noImage} height={60} width={100} />
              </a>
            </> : <>
              <a
                onClick={() => setDeleteConfirm(true)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.image.length > 0 ? row.original.image.at(-1).doc : noImage)} data-toggle="modal">
                <img src={(row.original.image.length > 0 ? row.original.image.at(-1).doc : noImage)} height={60} width={100} />
              </a>
            </>}

          </div>








          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            <Modal.Body>
              <img src={(row.original.image?.length > 0 ? row.original.image.at(-1).doc : noImage)} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid" />

            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>)
    }


  },



  {
    id: `pdf?`,
    Header: () => <div style={{ display: "inline" }}>Cavity PDF<br />Drawing All</div>,

    // accessor: `pdf`,
    Cell: ({ row }: any) =>



      <div>
        <a target="_blank" href={(row.original.pdf?.length > 0 ? row.original.pdf?.at(-1).doc : null)}>
          {(row.original.pdf?.length > 0 ? row.original.pdf?.at(-1).doc?.split('/').pop() : <span className="text-danger">No Document Uploaded</span>)}
        </a>
      </div>

  },



  {
    id: `product_image`,
    Header: () => <div style={{ display: "inline" }}>Product<br />Image All</div>,

    // accessor: `logo`,
    Cell: ({ row }: any) => {

      const [deleteConfirm, setDeleteConfirm] = useState(false)


      return (
        <>

          <div >

            {!row.original.product_image ? <>
              <a

                href="#"

              // data-large-src={row.original.image?row.original.image:noImage} data-toggle="modal"
              >
                <img src={noImage} height={60} width={100} />
              </a>
            </> : <>
              <a
                onClick={() => setDeleteConfirm(true)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.product_image.length > 0 ? row.original.product_image.at(-1).doc : noImage)} data-toggle="modal">
                <img src={(row.original.product_image.length > 0 ? row.original.product_image.at(-1).doc : noImage)} height={60} width={100} />
              </a>
            </>}

          </div>








          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            <Modal.Body>
              <img src={(row.original.product_image?.length > 0 ? row.original.product_image.at(-1).doc : noImage)} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid" />

            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>)
    }


  },



  {
    id: `product_pdf`,
    Header: () => <div style={{ display: "inline" }}>Product PDF<br />Drawing All</div>,

    // accessor: `pdf`,
    Cell: ({ row }: any) =>



      <div>
        <a target="_blank" href={(row.original.product_pdf?.length > 0 ? row.original.product_pdf?.at(-1).doc : null)}>
          {(row.original.product_pdf?.length > 0 ? row.original.product_pdf?.at(-1).doc?.split('/').pop() : <span className="text-danger">No Document Uploaded</span>)}
        </a>
      </div>

  },
  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',


  },







  {
    id: 'created_at',
    Header: () => <div style={{ display: "inline" }}>Created<br />At</div>,
    accessor: 'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{ display: "inline" }}>Created<br />By</div>,
    accessor: 'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username


  },
  {
    id: 'updated_at',
    Header: () => <div style={{ display: "inline" }}>Updated<br />At</div>,
    accessor: 'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{ display: "inline" }}>Updated<br />By</div>,
    accessor: 'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },

  {
    id: 'Actions',
    Header: 'Actions',


  },
  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_Cavity, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows } = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CAVITY_API}/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



