
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import { updateColor, useFetchColors, useUpdateSingleColor } from "../../hooks/color/color";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import Actions from "../../components/Cell/Actions";
import { updateBrand, useFetchBrands } from "../../hooks/brand";
import noImage from "../../images/noImage.png"
import { Update, useFetch } from "../../hooks/Api";
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
// import { Tooltip } from "react-tooltip";
import Tooltip from '@mui/material/Tooltip';



interface Data {
  selection: any



  check_list_code:any,
  name:any,
  choice_yes_no:any,
  department_name:any,
  name_and_surname:any,
  point_open_time:any,
  point_close_time:any,
  total_min:any,
  point_start_date:any,
  point_end_date:any,
  total_running_days:any,
  pending_running_days:any,
  emergency_on_date:any,
  emergency_on_reason:any,
  emergency_close_date:any,
  emergency_off_reason:any,
  emergency_on_off_person_name:any,
  point_on_off:any,
  suggested_by:any,



  created_at:any
  created_by:any
  updated_by:any
  updated_at:any

  status: any;
  actions: any;
}



export const titleofheaders=[
  {
    id: `srno`,
    Header: `Sr No`,
  },



  {
    id: `check_list_code`,
    Header: `Checklist Code`,
  },
  {
    id: `name`,
    Header: `Name`,
  },
  {
    id: `choice_yes_no`,
    Header: `Choice`,
  },
  {
    id: `department_name`,
    Header: `Department Name`,
  },
  {
    id: `name_and_surname`,
    Header: `Name And SurName`,
  },
  {
    id: `point_open_time`,
    Header: `Open Time`,
  },
  {
    id: `point_close_time`,
    Header: `Close Time`,
  },
  {
    id: `total_min`,
    Header: `Total Min`,
  },
  {
    id: `point_start_date`,
    Header: `Start Date`,
  },
  {
    id: `point_end_date`,
    Header: `End Date`,
  },
  {
    id: `total_running_days`,
    Header: `Total Running Days`,
  },
  {
    id: `pending_running_days`,
    Header: `Remaining Days`,
  },
  {
    id: `emergency_on_date`,
    Header: `Emergency On Date`,
  },
  {
    id: `emergency_on_reason`,
    Header: `Emergency On Reason`,
  },
  {
    id: `emergency_close_date`,
    Header: `Emergency Off Date`,
  },
  {
    id: `emergency_off_reason`,
    Header: `Emergency Off Reason`,
  },
  {
    id: `emergency_on_off_person_name`,
    Header: `Emergency Decision By`,
  },
  {
    id: `point_on_off`,
    Header: `On-Off`,
  },
  {
    id: `suggested_by`,
    Header: `Suggested By`,
  },

















 
  


      {
        id: 'status',
        Header: 'Status',
    
     
      },
 
  {
    id: 'created_at',
    Header: `Created At`,
  },


  {
    id: 'created_by',
    Header: `Created By`,

 
  },
  {
    id: 'updated_at',
    Header: `Updated At`,

  },
  {
    id: 'updated_by',
    Header: `Updated By`,

  },



 
]





export const Columns_CheckList: any = [



 
  {
    id: `srno`,
    Header: () => <div style={{display:"inline"}}>Sr<br/>No</div>,
    sticky:"left",
    
  },
 

  {
    id: `check_list_code`,
    Header: () => <div style={{display:"inline"}}>Checklist<br/>Code</div>,
    accessor: `check_list_code`,

    // Cell: ({ row }: any) => {row.original.check_list_code},
    sticky:"left",

  },
  {
    id: `name`,
    Header: `Name`,
    accessor: `name`,


    // <td data-tooltip-id="fault-name" data-tooltip-content={field.maintenance_fault}>

    //                     <Tooltip id="fault-name" />
    //                     {field.maintenance_fault.slice(0, 15).concat('...')}

    //                   </td>

    Cell: ({ row }: any) => {

      
      
      return(
        <>
       <Tooltip title={row.original.name} placement="top" arrow >
          <div> 
         {row.original?.name?.slice(0,15).concat('...')}
         </div>
        </Tooltip>

        </>
      )
      }
  },
  {
    id: `choice_yes_no`,
    Header: `Choice`,
    accessor: `choice_yes_no`,
    Cell: ({row}:any) => {
      const convertToUpper = row.original.choice_yes_no
      if (!convertToUpper) return "";
      return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }
    // Cell: ({ row }: any) => {row.original.choice_yes_no}

  },
  {
    id: `department_name`,
    Header: () => <div style={{display:"inline"}}>Department<br/>Name</div>,
    Cell: ({ row }: any) => {
        let department = row.original.department_name?.map((e:any)=>{
          return e.department
        })
        return(
          <div>
            {department?.join(",")}
          </div>
        )
  }
  },
  {
    id: `name_and_surname`,
    Header: () => <div style={{display:"inline"}}>Name And<br/>Surname</div>,
    accessor: `name_and_surname`,

    Cell: ({ row }: any) => {
      let department = row.original.name_and_surname?.map((e:any)=>{
        return e.username
      })
      return(
        <div>
          {department?.join(",")}
        </div>
      )
}

  },
  {
    id: `point_open_time`,
    accessor: `point_open_time`,

    Header: () => <div style={{display:"inline"}}>Open<br/>Time</div>,
    Cell: ({ row }: any) => 
    {
        let ok = row.original.point_open_time
        let hour = ok?.split(":")[0]
        let minute = ok?.split(":")[1]
        return(
           <>
               {`${hour}:${minute}`}

           </>
        )
    }

  },
  {
    id: `point_close_time`,
    accessor: `point_close_time`,

    Header: () => <div style={{display:"inline"}}>Close<br/>Time</div>,
    Cell: ({ row }: any) => 
    {
        let ok = row.original.point_close_time
        let hour = ok?.split(":")[0]
        let minute = ok?.split(":")[1]
        return(
           <>
               {`${hour}:${minute}`}

           </>
        )
    }

},
  {
    id: `total_min`,
    accessor: `total_min`,

    Header: () => <div style={{display:"inline"}}>Total<br/>Min</div>,
    Cell: ({ row }: any) => row.original.total_min

},
  {
    id: `point_start_date`,
    accessor: `point_start_date`,

    Header: () => <div style={{display:"inline"}}>Start<br/>Date</div>,
    Cell: ({ row }: any) => row.original.point_start_date

},
  {
    id: `point_end_date`,
    Header: () => <div style={{display:"inline"}}>End<br/>Date</div>,
        accessor: `point_end_date`,

    Cell: ({ row }: any) => row.original.point_end_date

},
  {
    id: `total_running_days`,
    Header: () => <div style={{display:"inline"}}>Total Running<br/>Days</div>,
        accessor: `total_running_days`,

    Cell: ({ row }: any) => row.original.total_running_days

},
  {
    id: `pending_running_days`,
    Header: () => <div style={{display:"inline"}}>Remaining<br/>Days</div>,
        accessor: `pending_running_days`,

    Cell: ({ row }: any) => row.original.pending_running_days

},
  {
    id: `emergency_on_date`,
    Header: () => <div style={{display:"inline"}}>Emergency On<br/>Date</div>,
    accessor: `emergency_on_date`,

    Cell: ({ row }: any) => row.original.emergency_on_date?row.original.emergency_on_date:<span style={{ color: 'red',fontSize:"20px" }}>****</span>

},
  {
    id: `emergency_on_reason`,
    Header: `Emergency On Reason`,
        accessor: `emergency_on_reason`,

    Cell: ({ row }: any) => row.original.emergency_on_reason?row.original.emergency_on_reason:<span style={{ color: 'red',fontSize:"20px" }}>****</span>

},
  {
    id: `emergency_close_date`,
    Header: `Emergency Off Date`,
        accessor: `emergency_close_date`,

    Cell: ({ row }: any) => row.original.emergency_close_date?row.original.emergency_close_date:<span style={{ color: 'red',fontSize:"20px" }}>****</span>

},
  {
    id: `emergency_off_reason`,
    Header: `Emergency Off Reason`,
        accessor: `emergency_off_reason`,

    Cell: ({ row }: any) => row.original.emergency_off_reason?row.original.emergency_off_reason:<span style={{ color: 'red',fontSize:"20px" }}>****</span>

},
  {
    id: `emergency_on_off_person_name`,
    Header: `Emergency Decision By`,
        accessor: `emergency_on_off_person_name`,

    Cell: ({ row }: any) => row.original.emergency_on_off_person_name?row.original.emergency_on_off_person_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>

},
  {
    id: `point_on_off`,
    Header: `On-Off`,
        accessor: `point_on_off`,
        Cell: ({row}:any) => {
          const convertToUpper = row.original.point_on_off
          if (!convertToUpper) return "";
          return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
        }
    // Cell: ({ row }: any) => {row.original.point_on_off}

},
  {
    id: `suggested_by`,
        accessor: `suggested_by`,

    Header: () => <div style={{display:"inline"}}>Suggested<br/>By</div>,
    Cell: ({ row }: any) => row.original.suggested_by

},




  {
    id: 'status',
    Header: 'Status',
    accessor:'status',

 
  },

  {
    id: 'created_at',
    Header: () => <div style={{display:"inline"}}>Created<br/>At</div>,
    accessor:'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{display:"inline"}}>Created<br/>By</div>,
    accessor:'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username

 
  },
  {
    id: 'updated_at',
    Header: () => <div style={{display:"inline"}}>Updated<br/>At</div>,
    accessor:'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{display:"inline"}}>Updated<br/>By</div>,
    accessor:'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },

  

  {
    id: 'Actions',
    Header: 'Actions',

 
  },
  {
    id: 'Selection',
    // sticky:"left",
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_CheckList, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows} = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}checklist/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]
