
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../../components/Cell/Status";
import Actions from "../../../components/Cell/Actions";
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import noImage from "../../../images/noImage.png"
import { Columns_Address_Details } from "../Address_Details/Columns_Address_Details";
import Edit_Customer_Details from "./Edit_Customer_Detail";
// import Columns_Address_Details from "../Address_Details/Columns_Address_Details"


interface Data {
  selection: any
  code:any
  name:any
  city_and_village:any
  zone:any
  owner_name:any
  owner_surname:any
  contact_no:any
  email:any
  address:any
  pincode:any
  gst_type_registration:any
  customer_gst_type:any
  gst_no:any
  gst_category:any
  tds_details:any
  tds_details_number:any
  pan_number:any
  customer_type:any
  account_type:any
  customer_organization_type:any
  company_date_of_birth:any
  customer_payment_method:any
  customer_credit_day_limit:any
  customer_credit_amount_limit:any
  late_payment_interest:any
  customer_running_not:any
  targeted_monthly_box:any
  targeted_monthly_amount:any
  opening_balance:any
  reference:any
  customer_group:any
  // koru_1:any
  // koru_2:any
  // koru_3:any
  // koru_4:any
  customer_title:any
  customer_visiting_card_image:any


  customer_details_edit:any
  created_at:any
  created_by:any
  updated_by:any
  updated_at:any

  status: any;
  // actions: any;
}


export const titleofheaders=[
  {
    id: `srno`,
    Header: `Sr No`,
  },


  {
    id: `code`,
    Header: `Code`,
  },

  {
    id: `name`,
    Header: `Name`,
  },

  {
    id: `city_and_village`,
    Header: `City Code & Name`,
  },


  {
    id: `zone`,
    Header: `Zone`,
  },


  {
    id: `owner_name`,
    Header: `Owner Name`,
  },


  {
    id: `owner_surname`,
    Header: `Owner Surname`,
  },


  {
    id: `contact_no`,
    Header: `Contact Number`,
  },
  {
    id: `email`,
    Header: `Email ID`,
  },
  {
    id: `address`,
    Header: `Address`,
  },

  {
    id: `pincode`,
    Header: `Pincode`,
  },

  {
    id: `gst_type_registration`,
    Header: `GST Type Registration`,
  },

  {
    id: `customer_gst_type`,
    Header: `Customer GST Type`,
  },


  {
    id: `gst_no`,
    Header: `GST No.`,
  },

  {
    id: `gst_category`,
    Header: `GST Category`,
  },

  {
    id: `tds_details`,
    Header: `TDS Details`,
  },

  {
    id: `tds_details_number`,
    Header: `TDS Details Number`,
  },


  {
    id: `pan_number`,
    Header: `Pan Number`,
  },
  {
    id: `customer_type`,
    Header: `Customer Type`,
  },
  {
    id: `account_type`,
    Header: `Account Type`,
  },





  {
    id: `customer_organization_type`,
    Header: `Organization Type`,
  },
  {
    id: `company_date_of_birth`,
    Header: `Starting Date`,
  },
  {
    id: `customer_payment_method`,
    Header: `Payment Method`,
  },






  {
    id: `customer_credit_day_limit`,
    Header: `Credit Day Limit`,
  },
  {
    id: `customer_credit_amount_limit`,
    Header: `Credit Amount Limit`,
  },
  {
    id: `late_payment_interest`,
    Header: `Late Payment Interest`,
  },





  {
    id: `customer_running_not`,
    Header: `Customer Runnig-Not`,
  },
  {
    id: `targeted_monthly_box`,
    Header: `Targetted Monthly Box`,
  },
  {
    id: `targeted_monthly_amount`,
    Header: `Monthly Amount`,
  },




  {
    id: `opening_balance`,
    Header: `Opening Balance`,
  },
  {
    id: `reference`,
    Header: `Reference By`,
  },
  {
    id: `customer_group`,
    Header: `Customer Group`,
  },



  // {
  //   id: `koru_1`,
  //   Header: `Blank-1`,
  // },
  // {
  //   id: `koru_2`,
  //   Header: `Blank-2`,
  // },
  // {
  //   id: `koru_3`,
  //   Header: `Blank-3`,
  // },
  // {
  //   id: `koru_4`,
  //   Header: `Blank-4`,
  // },
  {
    id: `customer_title`,
    Header: `Customer Title`,
  },
  {
    id: `customer_visiting_card_image`,
    Header: `Visiting Card Image`,
  },








 
  {
    id: 'status',
    Header: 'Status',
  },
  {
    id: 'created_at',
    Header: `Created At`,
  },


  {
    id: 'created_by',
    Header: `Created By`,

 
  },
  {
    id: 'updated_at',
    Header: `Updated At`,

  },
  {
    id: 'updated_by',
    Header: `Updated By`,

  },
]


export const Columns_Customer_Detail: any = [



  
  {
    id: `srno`,
    Header: () => <div style={{display:"inline"}}>Sr.<br/>No</div>,
    sticky:"left",
  },
 
  
 
 
  {
    id: `code`,
    Header: `Code`,
    accessor: `code`,
    sticky:"left",
    
  },

  {
    id: `person_type`,
    Header: () => <div style={{display:"inline"}}>Person<br/>Type</div>,
    accessor: `person_type`,
    Cell: ({ row }: any) => {
            return(
              <div>
                {row.original.person_type?.name}
              </div>
            )
      }
  },

  {
    id: `group`,
    Header: () => <div style={{display:"inline"}}>Account<br/>Group</div>,
    accessor: `group`,
    Cell: ({ row }: any) => {
            return(
              <div>
                {row.original.extra_response?.group?.category_name?row.original.extra_response?.group?.category_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
              </div>
            )
      }
  },
  {
    id: `account_type`,
    Header: () => <div style={{display:"inline"}}>Account<br/>Type</div>,
    accessor: `account_type`,
    Cell: ({ row }: any) => {
            return(
              <div>
                {row.original.extra_response?.group?.accountStatementType_name?row.original.extra_response?.group?.accountStatementType_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
              </div>
            )
      }
  },

  {
    id: `customer_name`,
    Header: () => <div style={{display:"inline"}}>Customer<br/>Name</div>,
    accessor: `customer_name`,
    Cell: ({ row }: any) => {
            return(
              <div>
                {row.original.customer_name?row.original.customer_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
              </div>
            )
      }
  },

  {
    id: `name`,
    Header: () => <div style={{display:"inline"}}>First<br/>Name</div>,
    accessor: `name`,
    Cell: ({ row }: any) => {
            return(
              <div>
                {row.original.name?row.original.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
              </div>
            )
      }
  },
  {
    id: `father_name`,
    Header: () => <div style={{display:"inline"}}>Middle<br/>Name</div>,
    accessor: `father_name`,
    Cell: ({ row }: any) => {
            return(
              <div>
                {row.original.father_name?row.original.father_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
              </div>
            )
      }
  },
  {
    id: `surname`,
    Header: () => <div style={{display:"inline"}}>Last<br/>Name</div>,
    accessor: `surname`,
    Cell: ({ row }: any) => {
            return(
              <div>
                {row.original.surname?row.original.surname:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
              </div>
            )
      }
  },
 

//   {
//     id: `city_and_village`,
//     Header: () => <div style={{display:"inline"}}>City &<br/>Village</div>,
//     accessor: `city_and_village`,
//     Cell: ({ row }: any) => {
//       return(
//         <div>
//           {row.original.address[0]?.city_and_village?.city_name}
//         </div>
//       )
// }
//   },


//   {
//     id: `zone`,
//     Header: `Zone`,
//     accessor: `zone`,
//     Cell: ({ row }: any) => {
//       return(
//         <div>
//           {row.original.zone?.route_name}
//         </div>
//       )
// }
//   },


  // {
  //   id: `owner_name`,
  //   Header: () => <div style={{display:"inline"}}>Owner<br/>Name</div>,
  //   accessor: `owner_name`,
  // },


  // {
  //   id: `owner_surname`,
  //   Header: () => <div style={{display:"inline"}}>Owner<br/>Surname</div>,
  //   accessor: `owner_surname`,
  // },


  // {
  //   id: `contact_no`,
  //   Header: () => <div style={{display:"inline"}}>Contact<br/>No</div>,
  //   accessor: `contact_no`,
  // },
  // {
  //   id: `email`,
  //   Header: () => <div style={{display:"inline"}}>Email<br/>ID</div>,
  //   accessor: `email`,
  // },
  // {
  //   id: `basic_details`,
  //   Header: () => <div style={{display:"inline"}}>Basic<br/>Details</div>,
  //   accessor: `basic_details`,
  //   Cell: ({ row }: any) => {
  //     return(
  //    <div>

  //      <button
  //      type="button" className="border-1 rounded text-white px-2 py-1 mx-3"
  //      style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
  //     //  onClick={handleSubmit(onSubmit)} 
  //     //  disabled={isLoading}
  //      >Basic Details</button>
  //     </div>
  //   )
  //   }
  // },

  // {
  //   id: `personal_details`,
  //   Header: () => <div style={{display:"inline"}}>Personal<br/>Details</div>,
  //   accessor: `personal_details`,
  //   Cell: ({ row }: any) => {
  //     return(
  //    <div>

  //      <button
  //      type="button" className="border-1 rounded text-white  py-1 mx-3"
  //      style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
  //     //  onClick={handleSubmit(onSubmit)} 
  //     //  disabled={isLoading}
  //      >Personal Details</button>
  //     </div>
  //   )
  //   }
  // },

  // {
  //   id: `contact_details`,
  //   Header: () => <div style={{display:"inline"}}>Contact<br/>Details</div>,
  //   accessor: `contact_details`,
  //   Cell: ({ row }: any) => {
  //     return(
  //    <div>

  //      <button
  //      type="button" className="border-1 rounded text-white  py-1 mx-3"
  //      style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
  //     //  onClick={handleSubmit(onSubmit)} 
  //     //  disabled={isLoading}
  //      >Contact Details</button>
  //     </div>
  //   )
  //   }
  // },


  // {
  //   id: `Addresses`,
  //   Header: () => <div style={{display:"inline"}}>Addresses</div>,
  //   accessor: `Addresses`,
  //   Cell: ({ row }: any) => {
  //     return(
  //    <div>

  //      <button
  //      type="button" className="border-1 rounded text-white px-2 py-1 mx-3"
  //      style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
  //     //  onClick={handleSubmit(onSubmit)} 
  //     //  disabled={isLoading}
  //      >Addresses</button>
  //     </div>
  //   )
  //   }
  // },


  // {
  //   id: `documents`,
  //   Header: () => <div style={{display:"inline"}}>Documents</div>,
  //   accessor: `documents`,
  //   Cell: ({ row }: any) => {
  //     return(
  //    <div>

  //      <button
  //      type="button" className="border-1 rounded text-white px-2 py-1 mx-3"
  //      style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
  //     //  onClick={handleSubmit(onSubmit)} 
  //     //  disabled={isLoading}
  //      >Documents</button>
  //     </div>
  //   )
  //   }
  // },


  // {
  //   id: `bank_details`,
  //   Header: () => <div style={{display:"inline"}}>Bank<br/>Details</div>,
  //   accessor: `bank_details`,
  //   Cell: ({ row }: any) => {
  //     return(
  //    <div>

  //      <button
  //      type="button" className="border-1 rounded text-white px-2 py-1 mx-3"
  //      style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
  //     //  onClick={handleSubmit(onSubmit)} 
  //     //  disabled={isLoading}
  //      >Bank Details</button>
  //     </div>
  //   )
  //   }
  // },


  // {
  //   id: `customer_conditions`,
  //   Header: () => <div style={{display:"inline"}}>Customer<br/>Conditions</div>,
  //   accessor: `customer_conditions`,
  //   Cell: ({ row }: any) => {

  //     let navigate = useNavigate()

      
  //     return(
  //    <div>

  //      <button
  //      type="button" className="border-1 rounded text-white  py-1 mx-1"
  //      style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
  //      onClick={()=>navigate(`/editemployee/${row.original.id}`)} 
  //     //  disabled={isLoading}
  //      >Customer Conditions</button>
  //     </div>
  //   )
  //   }
  // },


  // {
  //   id: `transport_details`,
  //   Header: () => <div style={{display:"inline"}}>Transport<br/>Details</div>,
  //   accessor: `transport_details`,
  //   Cell: ({ row }: any) => {
  //     return(
  //    <div>

  //      <button
  //      type="button" className="border-1 rounded text-white    py-1 mx-3"
  //      style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
  //     //  onClick={handleSubmit(onSubmit)} 
  //     //  disabled={isLoading}
  //      >Transport Details</button>
  //     </div>
  //   )
  //   }
  // },


//   {
//     id: `pincode`,
//     Header: `Pincode`,
//     accessor: `pincode`,
//     Cell: ({ row }: any) => {
//       return(
//         <div>
//           {row.original.address[0]?.pincode?.pincode}
//         </div>
//       )
// }
//   },

  // {
  //   id: `gst_type_registration`,
  //   Header: () => <div style={{display:"inline"}}>GST Type<br/>Registration</div>,
  //   accessor: `gst_type_registration`,
  //   Cell: ({row}:any) => {
  //     const convertToUpper = row.original?.gst_type_registration
  //     if (!convertToUpper) return "";
  //     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
  //     return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
  //   }
  // },

//   {
//     id: `customer_gst_type`,
//     Header: () => <div style={{display:"inline"}}>Customer GST<br/>Type</div>,
//     accessor: `customer_gst_type`,
//     Cell: ({ row }: any) => {
//       return(
//         <div>
//           {row.original.customer_gst_type?row.original.customer_gst_type:<span style={{color:"red"}}>xxxx</span>}
//         </div>
//       )
// }
//   },


//   {
//     id: `gst_no`,
//     Header: () => <div style={{display:"inline"}}>GST<br/>No</div>,
//     accessor: `gst_no`,
//     Cell: ({ row }: any) => {
//       return(
//         <div>
//           {row.original.gst_no?row.original.gst_no:<span style={{color:"red"}}>xxxx</span>}
//         </div>
//       )
// }
//   },

//   {
//     id: `gst_category`,
//     Header: () => <div style={{display:"inline"}}>GST<br/>Category</div>,
//     accessor: `gst_category`,
//     Cell: ({ row }: any) => {
//       return(
//         <div>
//           {row.original.gst_category?row.original.gst_category:<span style={{color:"red"}}>xxxx</span>}
//         </div>
//       )
// }
//   },



//   {
//     id: `tds_details`,
//     Header: () => <div style={{display:"inline"}}>TDS<br/>Details</div>,
//     accessor: `tds_details`,
//     Cell: ({row}:any) => {
//       const convertToUpper = row.original?.tds_details
//       if (!convertToUpper) return "";
//       // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
//       return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
//     }
//   },

//   {
//     id: `tds_details_number`,
//     Header: () => <div style={{display:"inline"}}>TDS Detail<br/>Number</div>,
//     accessor: `tds_details_number`,
//     Cell: ({ row }: any) => {
//       return(
//         <div>
//           {row.original?.tds_details_number?row.original?.tds_details_number:<span style={{color:"red"}}>xxxx</span>}
//         </div>
//       )
// }
//   },


//   {
//     id: `pan_number`,
//     Header: () => <div style={{display:"inline"}}>PAN<br/>No</div>,
//     accessor: `pan_number`,
//     Cell: ({ row }: any) => {
//       return(
//         <div>
//           {row.original?.pan_number?row.original?.pan_number:<span style={{color:"red"}}>xxxx</span>}
//         </div>
//       )
// }
//   },
//   {
//     id: `customer_type`,
//     Header: () => <div style={{display:"inline"}}>Customer<br/>Type</div>,
//     accessor: `customer_type`,
//   },
//   {
//     id: `account_type`,
//     Header: () => <div style={{display:"inline"}}>Account<br/>Type</div>,
//     accessor: `account_type`,
//     Cell: ({ row }: any) => {
//       return(
//         <div>
//           {row.original.account_type?.category?.name}
//         </div>
//       )
// }
//   },





//   {
//     id: `customer_organization_type`,
//     Header: () => <div style={{display:"inline"}}>Customer Organization<br/>Type</div>,
//     accessor: `customer_organization_type`,
   
//   },
//   {
//     id: `company_date_of_birth`,
//     Header: () => <div style={{display:"inline"}}>Company<br/>DOB</div>,
//     accessor: `company_date_of_birth`,
    
//   },
//   {
//     id: `customer_payment_method`,
//     Header: () => <div style={{display:"inline"}}>Customer<br/>Payment Method</div>,
//     accessor: `customer_payment_method`,
//     Cell: ({ row }: any) => {
//       return(
//         <div>
//           {row.original.customer_payment_method?row.original.customer_payment_method:<span style={{color:"red"}}>xxxx</span>}
//         </div>
//       )
// }
//   },






//   {
//     id: `customer_credit_day_limit`,
//     Header: () => <div style={{display:"inline"}}>Customer Credit<br/>Day Limit</div>,
//     accessor: `customer_credit_day_limit`,
//     Cell: ({ row }: any) => {
//       return(
//         <div>
//           {row.original.customer_credit_day_limit?row.original.customer_credit_day_limit:<span style={{color:"red"}}>xxxx</span>}
//         </div>
//       )
// }
//   },
//   {
//     id: `customer_credit_amount_limit`,
//     Header: () => <div style={{display:"inline"}}>Customer Credit<br/>Amount Limit</div>,
//     accessor: `customer_credit_amount_limit`,
//     Cell: ({ row }: any) => {
//       return(
//         <div>
//           {row.original.customer_credit_amount_limit?row.original.customer_credit_amount_limit:<span style={{color:"red"}}>xxxx</span>}
//         </div>
//       )
// }
//   },
//   {
//     id: `late_payment_interest`,
//     Header: () => <div style={{display:"inline"}}>Late Payment<br/>Interest</div>,
//     accessor: `late_payment_interest`,
//     Cell: ({ row }: any) => {
//       return(
//         <div>
//           {row.original.late_payment_interest?row.original.late_payment_interest:<span style={{color:"red"}}>xxxx</span>}
//         </div>
//       )
// }
//   },





//   {
//     id: `customer_running_not`,
//     Header: `Customer`,
//     accessor: `customer_running_not`,
//     Cell: ({row}:any) => {
//       const convertToUpper = row.original?.customer_running_not
//       if (!convertToUpper) return "";
//       // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
//       return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
//     }
//   },
//   {
//     id: `targeted_monthly_box`,
//     Header: () => <div style={{display:"inline"}}>Targeted Monthly<br/>Box</div>,
//     accessor: `targeted_monthly_box`,
//     Cell: ({row}:any) => {
//       const convertToUpper = row.original?.targeted_monthly_box
//       if (!convertToUpper) return "";
//       // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
//       return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
//     }
//   },
//   {
//     id: `targeted_monthly_amount`,
//     Header: () => <div style={{display:"inline"}}>Targeted Monthly<br/>Amount</div>,
//     accessor: `targeted_monthly_amount`,
//     Cell: ({ row }: any) => {
//       return(
//         <div>
//           {row.original.targeted_monthly_amount?row.original.targeted_monthly_amount:<span style={{color:"red"}}>xxxx</span>}
//         </div>
//       )
// }
//   },




//   {
//     id: `opening_balance`,
//     Header: () => <div style={{display:"inline"}}>Opening<br/>Balance</div>,
//     accessor: `opening_balance`,
//   },
//   {
//     id: `reference`,
//     Header: `Reference`,
//     accessor: `reference`,
//     Cell: ({ row }: any) => {
//       return(
//         <div>
//           {row.original.reference?row.original.reference:<span style={{color:"red"}}>xxxx</span>}
//         </div>
//       )
// }
//   },
//   {
//     id: `customer_group`,
//     Header: () => <div style={{display:"inline"}}>Customer<br/>Group</div>,
//     accessor: `customer_group`,
//   },



//   {
//     id: `koru_1`,
//     Header: () => <div style={{display:"inline"}}>Blank<br/>1</div>,
//     accessor: `koru_1`,
//     Cell: ({ row }: any) => {
//       return(
//         <div>
//           {row.original.koru_1?row.original.koru_1:<span style={{color:"red"}}>xxxx</span>}
//         </div>
//       )
// }
//   },
//   {
//     id: `koru_2`,
//     Header: () => <div style={{display:"inline"}}>Blank<br/>2</div>,
//     accessor: `koru_2`,
//     Cell: ({ row }: any) => {
//       return(
//         <div>
//           {row.original.koru_2?row.original.koru_2:<span style={{color:"red"}}>xxxx</span>}
//         </div>
//       )
// }
//   },
//   {
//     id: `koru_3`,
//     Header: () => <div style={{display:"inline"}}>Blank<br/>3</div>,
//     accessor: `koru_3`,
//     Cell: ({ row }: any) => {
//       return(
//         <div>
//           {row.original.koru_3?row.original.koru_3:<span style={{color:"red"}}>xxxx</span>}
//         </div>
//       )
// }
//   },




//   {
//     id: `koru_4`,
//     Header: () => <div style={{display:"inline"}}>Blank<br/>4</div>,
//     accessor: `koru_4`,
//     Cell: ({ row }: any) => {
//       return(
//         <div>
//           {row.original.koru_4?row.original.koru_4:<span style={{color:"red"}}>xxxx</span>}
//         </div>
//       )
// }
//   },
  // {
  //   id: `customer_title`,
  //   Header: () => <div style={{display:"inline"}}>Customer<br/>Title</div>,
  //   accessor: `customer_title`,
  // },
  // // {
  // //   id: `customer_visiting_card_image`,
  // //   Header: `Sr No`,
  // // },





//   {
//     id: `customer_visiting_card_image`,
//     Header: () => <div style={{display:"inline"}}>Visiting Card<br/>Image</div>,

//     // accessor: `logo`,
//     Cell: ({ row }: any) =>{
    
//     const [deleteConfirm, setDeleteConfirm] = useState(false)
// return(
//     <>

//         <div >

//           {!row.original.customer_visiting_card_image?<>
//             <a 
          
//           href="#"
          
//           // data-large-src={row.original.logo?row.original.logo:noImage} data-toggle="modal"
//           >
//             <img src={noImage}  height={60} width={100}/>
//             </a>
//           </>:<>
//           <a 
//           onClick={()=>setDeleteConfirm(true)}
//           href={`#galleryModal${row.original.name}`}
          
//           data-large-src={row.original.customer_visiting_card_image?row.original.customer_visiting_card_image:noImage} data-toggle="modal">
//             <img src={row.original.customer_visiting_card_image?row.original.customer_visiting_card_image:noImage}  height={60} width={100}/>
//             </a>
//           </>}
         
//         </div>



// <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
//         centered    >
//         <Modal.Header >
//           <Modal.Title>
        
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>  
//         <img src={row.original.customer_visiting_card_image?row.original.customer_visiting_card_image:noImage} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid"/>

//         </Modal.Body>
//         <Modal.Footer>
//         <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

//         </Modal.Footer>
//       </Modal>
//         </>)
//     }
  
  
//     },





  
  {
    id: 'status',
    Header: 'Status',
    accessor:'status',

 
  },
  
  {
    id: 'created_at',
    Header: () => <div style={{display:"inline"}}>Created<br/>At</div>,
    accessor:'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{display:"inline"}}>Created<br/>By</div>,
    accessor:'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username

 
  },
  {
    id: 'updated_at',
    Header: () => <div style={{display:"inline"}}>Updated<br/>At</div>,
    accessor:'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{display:"inline"}}>Updated<br/>By</div>,
    accessor:'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },



  {
    id: 'Actions',
    Header: 'Actions',

  
  },
  // {
  //   id: 'customer_details_edit',
  //   Header: () => <div style={{display:"inline"}}>Direct<br/>Edit</div>,

  
  // },
  
  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_Customer_Detail, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows} = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CUSTOMER_DETAILS_API}/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



