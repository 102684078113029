import React from "react"


const IndicatorsContainer = (props: any) => {
  return (
    <div style={{ display: 'inline-flex', background: 'mediumpurple' }}>
      {props.children}
    </div>
  );
};
export default IndicatorsContainer