import React, { useEffect, useState } from "react";
import logo from "../../images/logo.png";
import vector from "../../images/Vector (1).png";
import MaintenanceLogo from "../../images/Dashboard (8).png";
import { Link, useLocation } from "react-router-dom";
import { useFetch } from "../../hooks/Api";
import { useFetchSidebar } from "../../hooks/DropDown_Api";
import { AiOutlineDown, AiOutlineLine } from "react-icons/ai";
import "./sidebar.css";
import Restricted from "../../Roles/Restricted";

const Sidebar = () => {
  const apiname = process.env.REACT_APP_SIDEBAR?.toString();
  const [sidebarOptions, setSidebarOptions]: any = useState([]);

  const { data: sidebarData, refetch: colorRefetch } = useFetchSidebar();

  useEffect(() => {
    setSidebarOptions(sidebarData);
  }, [sidebarData]);

  const location = useLocation()

  const [activeLink, setActiveLink]: any = useState(null);
  const [subactiveLink, setSubActiveLink]: any = useState(null);




  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  useEffect(() => {
    // Function to check if all required classes are present on the body
    const checkBodyClasses = () => {
      return document.body.classList.contains('sidebar-mini') &&
        document.body.classList.contains('layout-fixed') &&
        document.body.classList.contains('sidebar-collapse');
    };

    // Update state based on the current body classes
    setIsSidebarCollapsed(checkBodyClasses());

    // Optional: Setup a MutationObserver to listen for changes to the body classList
    // This is useful if the classes can change after initial render
    const observer = new MutationObserver(() => {
      setIsSidebarCollapsed(checkBodyClasses());
    });

    observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });

    // Cleanup observer on component unmount
    return () => observer.disconnect();
  }, [isSidebarCollapsed]);


  return (
    <div>
      <>
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
          <div className="bg-light p-0 brand-link ">
            <img src={logo} alt="" className="p-1 mx-2 mt-1" width={56} />
            <span className="brand-text font-weight-light">
              Quantum<b>Bot</b>
            </span>
          </div>
          <div className="sidebar">
            <nav className="navbar navbar-expand-lg" style={{ top: "-56px" }}>
              <ul
                className="
                   nav
                    nav-pills
                    nav-sidebar
                     flex-column
                   
                   navbar-nav mr-auto sidenav"
                data-widget="treeview"
                id="navAccordion"
                role="menu"
                data-accordion="false"
              >
                {/* <li className="nav-item ">
                  <Link
                    className="nav-link nav-link-collapse"
                    to="/listColor"
                    id="hasSubItems"
                    data-toggle="collapse"
                    data-target="#collapseSubItems2"
                    aria-controls="collapseSubItems2"
                    aria-expanded="false"
                  >
                    <img src={vector} alt="" />
                    <p className="mx-2">
                      Dashboard
                 
                    </p>
                  </Link>
             
                </li> */}

                {sidebarOptions?.map((option: any, index: any) => {


                  let dataTarget = `#collapseSubItems4${index}`;

                  // Function to set the active link
                  const handleLinkClick = () => {
                    setActiveLink(dataTarget);
                  };

                  // Check if the link is active
                  const isActive = activeLink === dataTarget;



                  return (

                    <li className={"nav-item"} key={index}>
                      <Link

                        // className="nav-link nav-link-collapse "
                        className={`nav-link nav-link-collapse ${isActive ? 'activeLink' : ''}`}

                        to="#"
                        id="hasSubItems"
                        data-toggle="collapse"
                        // data-target={`#collapseSubItems4${index}`}
                        data-target={dataTarget}
                        aria-controls={`collapseSubItems4${index}`}
                        aria-expanded="false"
                        onClick={handleLinkClick}

                      >
                        <img src={option.icon} alt="" />
                        <p className="mx-2" style={{ color: 'white' }}>
                          {option.title}
                          <i
                            className="right fas fa-caret-right angle"
                            style={{ float: "right" }}
                          />
                        </p>
                      </Link>

                      <ul
                        className="nav-second-level collapse"
                        id={`collapseSubItems4${index}`}
                        data-parent="#navAccordion"
                      >
                        {option.modules?.map((suboption: any, subindex: any) => {

                          let check = `/${option?.link_path}${suboption?.url
                              ?.charAt(0)
                              ?.toUpperCase() +
                              suboption?.url?.slice(1) ?
                              suboption?.url
                                ?.charAt(0)
                                ?.toUpperCase() +
                              suboption?.url.slice(1) :
                              suboption?.model?.model
                                ?.charAt(0)
                                ?.toUpperCase() +
                              suboption?.model?.model.slice(1)
                            }`

                          if (Object.keys(suboption).includes("title")) {

                            let classSubNames = ''

                            let dataTargetSub = `#collapseSubItems${index}${subindex}`;

                            const isSubActive = subactiveLink === dataTargetSub;

                            const handleSubLinkClick = () => {
                              setSubActiveLink(dataTargetSub);
                            };

                            return (
                              <li
                                className="nav-item"
                                key={suboption?.title}
                              >
                                <Link
                                  className={`nav-link nav-link-collapse ${isSubActive ? 'subActiveLink' : ''}`}

                                  to="#"
                                  id="hasSubItems"
                                  data-toggle="collapse"
                                  data-target={dataTargetSub}
                                  aria-controls={`collapseSubItems${index}${subindex}`}
                                  aria-expanded="false"
                                  onClick={handleSubLinkClick}

                                >
                                  <img src={suboption.icon} alt="" />
                                  <p className="mx-2" style={{ color: 'white' }}>
                                    {suboption.title}
                                    <i
                                      className="right fas fa-caret-right angle"
                                      style={{ float: "right" }}
                                    />
                                  </p>
                                </Link>

                                <ul
                                  className={`nav-third-level collapse ${isSubActive ? 'subShow' : ''}`}
                                  id={`collapseSubItems${index}${subindex}`}
                                  style={{ listStyleType: 'none' }}
                                >
                                  {
                                    suboption?.modules.map((submodules: any, submoduleIndex: any) => {
                                      const check = `/${suboption?.link_path}${submodules?.url
                                          ?.charAt(0)
                                          ?.toUpperCase() +
                                          submodules?.url?.slice(1) ?
                                          submodules?.url
                                            ?.charAt(0)
                                            ?.toUpperCase() +
                                          submodules?.url?.slice(1) :
                                          submodules?.model?.model
                                            ?.charAt(0)
                                            ?.toUpperCase() +
                                          submodules?.model?.model.slice(1)
                                        }`
                                      const isActive = location.pathname === check

                                      const linkClass = isActive ? "nav-link nav-link-collapse activeLink" : "nav-link nav-link-collapse";

                                      return (<>
                                        <li
                                          className="nav-item"
                                          key={submodules?.label ? submodules?.label : submodules?.model?.app_label}
                                        >
                                          <Link
                                            to={`/${suboption?.link_path}${submodules?.url
                                                ?.charAt(0)
                                                ?.toUpperCase() +
                                                submodules?.url?.slice(1) ?
                                                submodules?.url
                                                  ?.charAt(0)
                                                  ?.toUpperCase() +
                                                submodules?.url?.slice(1) :
                                                submodules?.model?.model
                                                  ?.charAt(0)
                                                  ?.toUpperCase() +
                                                submodules?.model?.model?.slice(1)
                                              }`}
                                            // className="nav-link"
                                            className={linkClass}
                                            style={{ color: "white" }}
                                          >
                                            <i className="fas fa-solid fa-circle iconClass mx-2"></i>
                                            {/* <p className="pl-3">{suboption?.app_label}</p> */}
                                            <span className="nav-link-text mx-4">
                                              {submodules?.label ? submodules?.label : submodules?.model?.app_label}
                                            </span>
                                          </Link>
                                        </li>
                                      </>)
                                    })
                                  }

                                </ul>
                              </li>
                            );
                          }
                          else {

                            const isActive = location.pathname === check

                            const linkClass = isActive ? "nav-link nav-link-collapse activeLink" : "nav-link nav-link-collapse";

                            return (
                              <li
                                className="nav-item"
                                key={suboption?.label ? suboption?.label : suboption?.model?.app_label}
                              >
                                <Link
                                  to={`/${option?.link_path}${suboption?.url
                                      ?.charAt(0)
                                      ?.toUpperCase() +
                                      suboption?.url?.slice(1) ?
                                      suboption?.url
                                        ?.charAt(0)
                                        ?.toUpperCase() +
                                      suboption?.url.slice(1) :
                                      suboption?.model?.model
                                        ?.charAt(0)
                                        ?.toUpperCase() +
                                      suboption?.model?.model.slice(1)
                                    }`}
                                  onClick={() => { setSubActiveLink(null) }}
                                  // className="nav-link"
                                  className={linkClass}
                                  style={{ color: "white" }}
                                >
                                  <i className="fas fa-solid fa-circle iconClass mx-2"></i>
                                  {/* <p className="pl-3">{suboption?.app_label}</p> */}
                                  <span className="nav-link-text mx-4">
                                    {suboption?.label ? suboption?.label : suboption?.model?.app_label}
                                  </span>
                                </Link>
                              </li>
                            );
                          }


                        }
                        )}
                      </ul>
                    </li>

                  );
                })}
                <Restricted to={"configuration.view_columns"} fallback={""}>
                  <li className="nav-item ">
                    <Link
                      className="nav-link nav-link-collapse"
                      to="#"
                      id="hasSubItems"
                      data-toggle="collapse"
                      data-target="#collapseSubItems3"
                      aria-controls="collapseSubItems3"
                      aria-expanded="false"
                    >
                      <img src={MaintenanceLogo} alt="" />
                      <p className="mx-2">
                        Settings
                        <i
                          className="right fas fa-caret-right angle"
                          style={{ float: "right" }}
                        />
                      </p>
                    </Link>

                    <ul
                      className="nav-second-level collapse"
                      id="collapseSubItems3"
                      data-parent="#navAccordion"
                    >
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={"/settings/ColumnVisibility"}
                          style={{ color: "white" }}
                        >
                          <i className="fas fa-solid fa-circle iconClass mx-2"></i>

                          <span className="nav-link-text mx-4">Column Visibility</span>
                        </Link>
                      </li>
                    </ul>


                  </li>
                </Restricted>


                <Restricted to={"configuration.view_columns"} fallback={""}>
                  <li className="nav-item ">
                    <Link
                      className="nav-link nav-link-collapse"
                      to="#"
                      id="hasSubItems"
                      data-toggle="collapse"
                      data-target="#collapseSubItems4"
                      aria-controls="collapseSubItems4"
                      aria-expanded="false"
                    >
                      <img src={MaintenanceLogo} alt="" />
                      <p className="mx-2">
                        Maintenance
                        <i
                          className="right fas fa-caret-right angle"
                          style={{ float: "right" }}
                        />
                      </p>
                    </Link>
                    {localStorage?.is_superuser === "true" ?
                      <ul
                        className="nav-second-level collapse"
                        id="collapseSubItems4"
                        data-parent="#navAccordion"
                      >
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={"/listMaintenance"}
                            style={{ color: "white" }}
                          >
                            <i className="fas fa-solid fa-circle iconClass mx-2"></i>

                            <span className="nav-link-text mx-4">Maintenance </span>
                          </Link>
                        </li>
                      </ul>
                      : ""}
                    <ul
                      className="nav-second-level collapse"
                      id="collapseSubItems4"
                      data-parent="#navAccordion"
                    >
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={"/listMaintenanceResponsible"}
                          style={{ color: "white" }}
                        >
                          <i className="fas fa-solid fa-circle iconClass mx-2"></i>

                          <span className="nav-link-text mx-4">Maintenance Responsible</span>
                        </Link>
                      </li>
                    </ul>

                    <ul
                      className="nav-second-level collapse"
                      id="collapseSubItems4"
                      data-parent="#navAccordion"
                    >
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={"/listComplaintMaintenance"}
                          style={{ color: "white" }}
                        >
                          <i className="fas fa-solid fa-circle iconClass mx-2"></i>

                          <span className="nav-link-text mx-4">Complaint Maintenance </span>
                        </Link>
                      </li>
                    </ul>
                    {localStorage?.is_superuser === "true" ?
                      <ul
                        className="nav-second-level collapse"
                        id="collapseSubItems4"
                        data-parent="#navAccordion"
                      >
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={"/listStatusReport"}
                            style={{ color: "white" }}
                          >
                            <i className="fas fa-solid fa-circle iconClass mx-2"></i>

                            <span className="nav-link-text mx-4">Status Report</span>
                          </Link>
                        </li>
                      </ul>
                      : ""}

                  </li>
                </Restricted>








                {/* <li className="nav-item ">
                  <Link
                    className="nav-link nav-link-collapse"
                    to="/salesOrder"
                    id="hasSubItems"
                    data-toggle="collapse"
                    data-target="#collapseSubItems2"
                    aria-controls="collapseSubItems2"
                    aria-expanded="false"
                  >
                    <p className="mx-2">
                      Sales Order
                 
                    </p>
                  </Link>
             
                </li> */}




                {/* <Restricted to={"configuration.view_columns"} fallback={""}> */}
                {/* <li className="nav-item ">
                  <Link
                    className="nav-link nav-link-collapse"
                    to="#"
                    id="hasSubItems"
                    data-toggle="collapse"
                    data-target="#collapseSubItems5"
                    aria-controls="collapseSubItems5"
                    aria-expanded="false"
                  >
                    <img src={""} alt="" />
                    <p className="mx-2">
                      For Approval
                      <i
                        className="right fas fa-caret-right angle"
                        style={{ float: "right" }}
                      />
                    </p>
                  </Link>
                  
                  <ul
                    className="nav-second-level collapse"
                    id="collapseSubItems5"
                    data-parent="#navAccordion"
                  >
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to={"/addSales"}
                        style={{ color: "white" }}
                      >
                       <i className="fas fa-solid fa-circle iconClass mx-2"></i>

                        <span className="nav-link-text mx-4">Add Sales</span>
                      </Link>
                    </li>
                  </ul>
                  

                </li> */}
                {/* </Restricted> */}









              </ul>
            </nav>
          </div>
        </aside>
      </>
    </div>
  );
};

export default Sidebar;
