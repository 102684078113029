import React, { useContext, useEffect, useRef, useState } from "react";
import SearchLogo from "../../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate } from "../../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../../components/focus";
import CreatableSelect from "react-select/creatable";
import { useFetchPersonTypeDropDown } from "../../../hooks/DropDown_Api";

import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import { DatePicker } from "antd";
import dayjs from 'dayjs';
import moment from "moment";

import { useFormContext } from 'react-hook-form';
import { Add_Employee } from "../../Employee/Add_Employee";
import Add_Customer_Details from "../Customer Detail/Add_Customer_Detail";
import Edit_Employee from "../../Employee/Edit_Employee";
import Add_Basic_Customer_Details from "../Basic Customer Detail/Add_Basic_Customer_Details";
// import { GlobalStateContext } from "../GlobalStateContext";

import Modal from "react-bootstrap/Modal";
import Actions, { Deleteforce, Deletesingle } from "../../../components/Cell/Actions";
import { useDelete, useDeletemforce } from "../../../hooks/delete";
import Custom_Actions_Buttons from "../../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../../hooks/deletemultiple";
import { SimpleInput_Edit } from "../../../components/Dynamic_Form_Models/SimpleInput_Edit";
import Button from "react-bootstrap/esm/Button";




interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOptions = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});

const Add_Basic_Details = ({ setSuccessPersonalDetails, setCurrentStep, currentStep }: any) => {
    let pageName = "Customer Grade"
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()
    // const { formData, setFormData } = useContext(GlobalStateContext);

    const customIndicator = {
        IndicatorsContainer: IndicatorsContainer
    }

    // const customCreatableIndicator = {
    //     IndicatorsContainer: IndicatorsContainerCreate
    // }



    const apiname = process.env.REACT_APP_SALES_PERSONAL_DETAILS_API?.toString()


    const { mutate: Grade, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)
    const { register, handleSubmit, setError, formState, reset, setValue, getValues }: any = useForm({
        mode: "onChange"
    });

    // const { register, handleSubmit, setError, formState, reset,setValue, getValues  }:any = useFormContext();
    const { errors, isDirty } = formState;



    const onSubmit = (data: any) => {

        if (!aniversaryDate) {
            delete data.anniversary_date
        }
        if (!birthDate) {
            delete data.birth_date
        }
        if (!JoiningDate) {
            delete data.joining_date
        }

        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            const formvalue: any = value



            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }

        }
        Grade(formData)


    }

    // useEffect(()=>{
    //     if(isSuccess){
    //         setSuccessPersonalDetails(true)
    //     }
    // },[isSuccess])

    // useEffect(()=>{
    //     if(isSuccess&& data){
    //         
    //         localStorage.setItem('Personal Details',data?.data?.id)
    //          const currentPath = window.location.pathname;
            // if(currentPath === '/addCustomerDetails'){
            // setCurrentStep(currentStep + 1)
            // };
    //     }
    // },[data,isSuccess])

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            // setSuccessPersonalDetails(true)
            // navigate('/listPersonDetailGrade', { state: { showToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    };

    const handleReset = () => {
        reset()
        setBirthDate(null)
        setJoiningDate(null)
        setLeavingDate(null)
        setAnniversaryDate(null)
        setAge(0)
    }


    const [valuePersonType, setValuePersonType]: any = useState();

    const [optionsPersonType, setOptionsPersonType]: any = useState([])

    const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valuePersonTypeCreatable, setValuePersonTypeCreatable] = useState<Option | null>();

    const apinameForPersonType = process.env.REACT_APP_PERSON_TYPE_API?.toString()

    const { mutate: mutatePersonType, data: dataCavity, isError: cavityIsError, isLoading: cavityLoading, isSuccess: personTypeSuccess, error: cavityError }: any = useCreate(apinameForPersonType)


    const { data: personTypeData, refetch: personTypeRefetch } = useFetchPersonTypeDropDown()

    useEffect(() => {
        setOptionsPersonType(personTypeData)
    }, [personTypeData])



    const [personTypeLabel, setPersonTypeLabel] = useState('')

    const handleChangePersonType = (e: any) => {

        personTypeRefetch()

        setApiName(apinameForPersonType)
        setDeleteApiName(apinameForPersonType)
        setEditId(e?.value)
        setEditData(personTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValuePersonType(e)


        console.log('e', e)
        setPersonTypeLabel(e?.label)
        setValue("person_type", e?.value)
        setValuePersonTypeCreatable(null)
        // setValuePersonType(e)
        localStorage.removeItem('Add Data')

        if (e === null) { // Check if the event is a clear event
            handleOnPersonTypeClear();
        } else {
            // Existing handleChangePersonType logic
        }

    }

    let tempData:any = localStorage.getItem('PersonType')
    let tempDataString = tempData ? JSON.parse(tempData) : null;
    const [selectedId,setSelectedID]:any = useState('')


    

    useEffect(()=>{
        
        if(tempDataString){
            
            setSelectedID(tempDataString?.value)
            setValue('person_type',tempDataString?.value)
            setPersonTypeLabel(tempDataString?.label)
        }
        
    },[tempDataString,personTypeLabel,valuePersonType])

    useEffect(() => {
        if (valuePersonType) {
            localStorage.setItem('PersonType', JSON.stringify(valuePersonType))
        }
    }, [valuePersonType,tempDataString,personTypeLabel])

    const handleCreatePersonType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOptions(inputValue);
            setIsLoadingValue(false);


            mutatePersonType({ name: newOption.name, status: newOption.status })
            setOptionsPersonType((prev: any) => [...prev, newOption]);
            setValuePersonTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (personTypeSuccess && optionsPersonType) {
                await personTypeRefetch();
                // localStorage.setItem('Person Type In Add', personTypeData[0].value)
                setValue("person_type", personTypeData[0].value)
                localStorage.setItem('PersonType', JSON.stringify(personTypeData[0]))
            }

        })();

        return () => {
        };
    }, [personTypeSuccess, personTypeData]);

    const [birthDate, setBirthDate]: any = useState(null)

    const [age, setAge]: any = useState()

    const handleBirthDate = (e: any) => {


        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let birth_date = `${date}-${month}-${year}`
        setValue("birth_date", birth_date)

        setBirthDate(e)

        setAge(0)
        setValue('age', 0)
    }


    //   useEffect(() => {

    //     if (birthDate != null || birthDate) {

    //         // if(birthDate == null){

    //         // }

    //         let todayDate = new Date().toISOString().split("T")[0]

    //         if (birthDate?.$D === 31) {
    //             var bDate = moment([birthDate?.$y, birthDate?.$M, birthDate?.$D]);
    //         } else {
    //             var bDate = moment([birthDate?.$y, birthDate?.$M + 1, birthDate?.$D]);
    //         }



    //         var currentDate = moment([todayDate.split("-")[0], todayDate.split("-")[1], todayDate.split("-")[2]]);

    //         setAge(currentDate.diff(bDate, 'years'))
    //         setValue("age", age)





    //     } else {
    //         setAge(0)
    //         setValue("age", 0)
    //     }



    // }, [birthDate, age])    


    //   useEffect(() => {

    //       if (birthDate != null || birthDate) {


    //           let todayDate = new Date().toISOString().split("T")[0]
    //           console.log(todayDate,"todayDate");

    //           if (birthDate?.$D === 31) {
    //               var bDate = moment([birthDate?.$y, birthDate?.$M, birthDate?.$D]);
    //           } else {
    //               var bDate = moment([birthDate?.$y, birthDate?.$M + 1, birthDate?.$D]);
    //           }



    //           var currentDate = moment([todayDate.split("-")[0], todayDate.split("-")[1], todayDate.split("-")[2]]);

    //           setAge(currentDate.diff(bDate, 'years'))
    //           setValue("age", age)





    //       } else {
    //           setAge(0)
    //           setValue("age", 0)
    //       }



    //   }, [birthDate,age])

    // console.log(age,"age");



    const [Married, setMarried] = useState("Single")

    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
    const [JoiningDate, setJoiningDate]: any = useState("")
    const [LeavingDate, setLeavingDate]: any = useState("")


    const handleJoiningDate = (e: any) => {

        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let joining_date = `${date}-${month}-${year}`
        setValue("joining_date", joining_date)
        setJoiningDate(e)

    }
    const handleLeavingDate = (e: any) => {

        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let leaving_date = `${date}-${month}-${year}`
        setValue("leaving_date", leaving_date)
        setLeavingDate(e)

    }

    const [aniversaryDate, setAnniversaryDate]: any = useState("")

    const handleAnniversaryDate = (e: any) => {

        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let anniversary_date = `${date}-${month}-${year}`
        setValue("anniversary_date", anniversary_date)
        setAnniversaryDate(e)

    }

    const onMarriedChange = (e: any) => {
        setMarried(e.target.value)
    }


    const [onNext, setOnNext] = useState(false)


    const handleNext = () => {
        if (onNext) {
             const currentPath = window.location.pathname;
            if(currentPath === '/addCustomerDetails'){
            setCurrentStep(currentStep + 1)
            };

        }
    }


    const handleOnPersonTypeClear = ()=>{
        setValuePersonType(null)
        setValue(null)
        localStorage.removeItem('PersonType')
        setSelectedID('')
        setValue('person_type','')
    }

    const currentPath = window.location.pathname;



    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
    } = useDelete();
    const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
    } = useDeletemforce();




    const handleRefetch = () => {
        if (apiName === apinameForPersonType) {
            personTypeRefetch()
        }
     



        // else if(apiName === apinameForTaluka){
        //   TalukaRefetch()
        // }
    }


    useEffect(() => {
        if (OpenEditDropDownModel && valuePersonType && apiName === apinameForPersonType) {
            setValuePersonType(null)
            setValuePersonTypeCreatable(null)
        }
      






        if (deletesucess && apiName === apinameForPersonType) {
            setValuePersonType(null)
            setValuePersonTypeCreatable(null)
        }
     

    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (


        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
            <span>{label}</span>
            <div style={{ float: "right", justifyContent: "end" }}>



                {isExist ?

                    <Custom_Actions_Buttons value={value}
                        apiname={deleteApiName}
                        mutate={singleDelete}
                        // masterName={addButtonName}
                        deleteid={deleteid}
                        handleDeletedforce={handleDeletedforce}
                        closereconfirmdelete={closereconfirmdelete}
                        deletemodalask={deletemodalask}
                        setEditOpen={setOpenEditDropDownModel}
                    // permissionClass={permissionClass}
                    />
                    : ""}

            </div>
        </div>


    )


    const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
    }



    useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
        return () => {
            setDeleteid(undefined);
        };
    }, []);
    const handleConfirmDelete = () => {
        setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
    };

    const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
    };
    useEffect(() => {
        if (forcefullysuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullyerror) {
            setShow(true);
            setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullydeleteerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
    ]);

    useEffect(() => {
        if (deleteMultipleSuccess) {
            const showdepenedancymsg = deleteMultiple?.data?.data;
            const dependacyfound = showdepenedancymsg?.filter((color: any) => {
                if (
                    color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
                ) {
                    return color;
                }
            });
            if (dependacyfound?.length === deleteMultipleid?.length) {
                deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
            } else {
                setConfirmforcemodal(true);
            }
        } else if (deleteMultipleIsError) {
            setShow(true);
            setshowmessage("danger");
        } else if (deletesucess) {
            if (deletedata.data?.data?.length > 0) {
                setConfirmforcemodal(true);
            } else if (deleteid) {
                setShow(true);
                setshowmessage("success");
                deleteforce({ deleteid, apiname: deleteApiName });
            }
        }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

    const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
    };

    return (
        <div>
            {/* <div className="" style={{ minHeight: '1345.31px' }}> */}
            <section className="content-header py-1 px-3">
                {/* <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Add {pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">{pageName}</Link></li>
                                    <li className="breadcrumb-item active">Add {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div> */}
            </section>
            {/* <section className="content"> */}
            {/* <div className="container-fluid"> */}
            <div className="card card-default">
                {/* <h3 className="px-3">Personal Details</h3> */}
                {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Source Name</span></h5>
                            </div> */}
                <form ref={focus}
                    onKeyUp={event => onEnterKey(event)}
                    onSubmit={handleSubmit(onSubmit)}>
                    <div className="card-body" >
                        <div className="row d-flex justify-content-md-center justify-content-lg-start">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-sm-3 mt-2">
                                        <label >Person Type <span style={{ color: 'red' }}>*</span></label>
                                        <CreatableSelect
                                            {...register("person_type", { required: { value: true, message: "Field is required" } })}
                                            isClearable={true}
                                            
                                            components={customCreatableIndicator}
                                            placeholder={'Person Type'}
                                            options={optionsPersonType}
                                            onCreateOption={handleCreatePersonType}
                                            onChange={handleChangePersonType}
                                            onMenuOpen={()=>personTypeRefetch()}
                                            // value={valuePersonTypeCreatable==null?valuePersonType:valuePersonTypeCreatable}
                                            // value={valuePersonTypeCreatable == null ? valuePersonType == null ? optionsPersonType?.filter((obj: any) => obj.value === localStorage.getItem('Person Type In Add')) : valuePersonType : valuePersonTypeCreatable}
                                            value={valuePersonTypeCreatable == null ? valuePersonType == null ? optionsPersonType?.filter((obj: any) => obj.value === selectedId) : valuePersonType : valuePersonTypeCreatable}



                                        />
                                        <span className='text-red w-100'>{errors?.person_type?.message}</span>

                                    </div>






                                    <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                {personTypeLabel === 'Employee' ?
                                                    <>


                                                        <Add_Employee setOnNext={setOnNext} setCurrentStep={setCurrentStep} currentStep={currentStep} />


                                                    </>
                                                    :
                                                    null}
                                                {personTypeLabel != 'Employee' && valuePersonType || personTypeLabel != 'Employee' && valuePersonTypeCreatable?
                                                    <>
                                                        <Add_Basic_Customer_Details setCurrentStep={setCurrentStep} currentStep={currentStep}/>


                                                    </>
                                                    :
                                                    null}
                                            </div>
                                        </div>
                                    </div>






                                </div>
                            </div>
                        </div>

                        {/* <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                <button 
                                                type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3" 
                                                style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                
                                                onClick={handleNext}
                                                >Next</button>
<button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>


                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                    </div>
                </form>

            </div>
            {/* </div> */}
            {/* </section> */}

            {/* Edit Modal HTML */}
            <Modal
                show={deleteConfirm}
                onHide={() => setDeleteConfirm(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete {""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {" "}
                        <span className="text-center">Sure you want to delete it?</span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setDeleteConfirm(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={handleMultipleDeleted}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Deleteforce
                key={renderKey}
                handleDeletedforce={handleDeletedforce}
                reconfirmdelete={confirmforcemodal}
                masterName={""}
                closereconfirmdelete={closereconfirmdelete}
                referance={
                    deletesucess
                        ? deletedata
                        : ""
                }
                apiname={apiName}
            />
            <Deletesingle
                key={renderKey}
                confirmmodal={deleteConfirmsingle}
                confirmmodalclose={confirmmodalclose}
                masterName={""}
                confirmmodalsubmit={confirmmodalsubmit}
                apiname={apiName}
            />

            <SimpleInput_Edit key={renderKey}
                refetchForm={handleRefetch}
                open={OpenEditDropDownModel}
                setOpen={setOpenEditDropDownModel}
                id={editId}
                // superAccountId={superAccountId} 
                // optionsSuperAccount={optionsSuperAccount}
                // isSuperAccount={isSuperAccount}
                apiname={apiName} />
        </div>
        // </div>
    )
}

export default Add_Basic_Details

