import axios from "axios";
import "./example.css"
import { fetchApiHelper } from "../../hooks/helpers/fetch";
import { useEffect, useState } from "react";


const GridItem = ({ colu, instance, headerTitle,pagecolumn }: any) => {
    const [disablecolumns,setDisablecolumns]=useState()
    var coluCheck = [colu.id];

    //...
  
    // useEffect(()=>{
    //     localStorage.getItem(colu)
    // },[colu])
    return (
        <>

            {(colu.isVisible == true &&
                <>
                <div className='col-4 dragHandle' >
                <button className='w-100 btn bg-success' style={{marginTop:"5px",marginLeft:0}} onClick={() => {
                    
                    instance.toggleHideColumn(colu.id)
                    let col:any = localStorage.getItem(`colu${pagecolumn}`)
                    
                    let existingEntries:any = col!==null ? JSON.parse(col) :[]
                    if(!existingEntries?.includes(coluCheck[0])){
                        existingEntries?.push(coluCheck[0])
                    }
                    localStorage.setItem(`colu${pagecolumn}`, JSON.stringify(existingEntries));
                
                   
                }
                } >
                    {
                    headerTitle?headerTitle:
                    (typeof (colu.Header) === "string" ? colu.Header : colu.id.charAt(0).toUpperCase() + colu.id.slice(1))}
                </button>
                {/* {!('sticky' in colu)?
                    <button className='w-100 btn bg-success' style={{marginTop:"5px",marginLeft:0}} onClick={() => {
                    
                    
                        instance.toggleHideColumn(colu.id)
                        let col:any = localStorage.getItem(`colu${pagecolumn}`)
                        
                        let existingEntries:any =col!==null ?JSON.parse(col):[]
                        if(!existingEntries?.includes(coluCheck[0])){
                        existingEntries?.push(coluCheck[0])
                        }
                        localStorage.setItem(`colu${pagecolumn}`, JSON.stringify(existingEntries));
                    
                       
                    }
                    } >
                        {
                        headerTitle?headerTitle:
                        (typeof (colu.Header) === "string" ? colu.Header : colu.id.charAt(0).toUpperCase() + colu.id.slice(1))}
                    </button>
:

<>


<button className='w-100 btn bg-secondary' style={{marginTop:"5px",marginLeft:0}} onClick={() => {
                    
                    
                  
                
                   
                }
                } >
                    {
                    headerTitle?headerTitle:
                    (typeof (colu.Header) === "string" ? colu.Header : colu.id.charAt(0).toUpperCase() + colu.id.slice(1))}
                </button>
</>





} */}
                </div>
                </>)}
            {(colu.isVisible == false &&
                <>
                 
  <div className='col-4 dragHandle' style={{ marginLeft:0}}>
  <button className='w-100 bg-danger btn'style={{marginTop:"5px",marginLeft:0}} onClick={() => {
                        
                   
                        instance.toggleHideColumn(colu.id)
                        
                        let col:any = localStorage.getItem(`colu${pagecolumn}`)
                        let existingEntries:any = JSON.parse(col);

                        var index = existingEntries.indexOf(colu.id);
                        if (index !== -1) {
                            existingEntries.splice(index, 1);
                        }
                        
                        // existingEntries?.pop(coluCheck[0])

                        localStorage.setItem(`colu${pagecolumn}`, JSON.stringify(existingEntries));
                    
                    }}>
                    {
                    headerTitle?headerTitle:
                    (typeof (colu.Header) === "string" ? colu.Header : colu.id.charAt(0).toUpperCase() + colu.id.slice(1))}
                    </button>
  {/* {!('sticky' in colu)?
                    <button className='w-100 bg-danger btn'style={{marginTop:"5px",marginLeft:0}} onClick={() => {
                        
                   
                        instance.toggleHideColumn(colu.id)
                        
                        
                        let col:any = localStorage.getItem(`colu${pagecolumn}`)
                        let existingEntries:any = JSON.parse(col);
                        
                        existingEntries?.pop(coluCheck[0])

                        localStorage.setItem(`colu${pagecolumn}`, JSON.stringify(existingEntries));
                    
                    }}>
                    {
                    headerTitle?headerTitle:
                    (typeof (colu.Header) === "string" ? colu.Header : colu.id.charAt(0).toUpperCase() + colu.id.slice(1))}
                    </button>
:
<>
<button className='w-100 bg-secondary btn'style={{marginTop:"5px",marginLeft:0}} onClick={() => {
                        
                   
                     
                    
                    }}>
                    {
                    headerTitle?headerTitle:
                    (typeof (colu.Header) === "string" ? colu.Header : colu.id.charAt(0).toUpperCase() + colu.id.slice(1))}
                    </button>
</>

} */}
                </div>

                </>)}
        </>

    );
};

export default GridItem;