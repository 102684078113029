import { useMutation } from "react-query"
import { patchApiHelper } from "./helpers/patch"

export const useModeloptionsvisibleupt = () => {
    return useMutation(modeloptions)
}
const modeloptions = async(data:any) => {
    try {
    const {value}=data
  //   const cleanObj = Object.entries(data)
  // .reduce((acc:any, [key, value]) => {
  //   if (value !== undefined) {
  //     acc[key] = value?"SHOW":"HIDE";
  //   }
  //   return acc;
  // }, {});
    const uptcall=await patchApiHelper(`configuration/columns/${value}/?edit=yes`,{visibility:data.visibility ? "SHOW" : "HIDE"})
    
       
        return uptcall
    }
    catch (error) {
        return error
    }
}