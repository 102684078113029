
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../../components/Cell/Status";
import Actions from "../../../components/Cell/Actions";


interface Data {
  selection: any
 
  priority: any,
  code: any,
  transport_id: any,
  name: any,
  city_1: any,
  city_2: any,
  total_city_qty: any,
  mobile_no_city_1: any,
  mobile_no_city_2: any,
  email_id_city_1: any,
  email_id_city_2: any,
  state: any,
  country: any,

  created_at:any
  created_by:any
  updated_by:any
  updated_at:any

  status: any;
  actions: any;
}


export const titleofheaders=[
  {
    id: `srno`,
    Header: `Sr No`,
  },
  {
    id: `code`,
    Header: `Code`,
   
  },
  {
    id: `priority`,
    Header: `Priority`,
   
  },
  {
    id: `transport_id`,
    Header: `Transport id`,
   
  },
  {
    id: `name`,
    Header: `Name`,
   
  },
  {
    id: `city_1`,
    Header: `City - 1`,
   
  },
  {
    id: `city_2`,
    Header: `City - 2`,
   
  },
  {
    id: `total_city_qty`,
    Header: `Total City Qty`,
    
  },
  {
    id: `mobile_no_city_1`,
    Header: `Mobile No. City-1`,
  },
  {
    id: `mobile_no_city_2`,
    Header: `Mobile No. City-2`,
   
  },
  {
    id: `email_id_city_1`,
    Header: `Email City-1`,
    
  },
  {
    id: `email_id_city_2`,
    Header: `Email City-2`,
   
  },
 
  {
    id: `state`,
    Header: `State`,
   
  },
  {
    id: `country`,
    Header: `Country`,
    
  },
 
  {
    id: 'status',
    Header: 'Status',
  },

  {
    id: 'created_at',
    Header: `Created At`,
  },


  {
    id: 'created_by',
    Header: `Created By`,

 
  },
  {
    id: 'updated_at',
    Header: `Updated At`,

  },
  {
    id: 'updated_by',
    Header: `Updated By`,

  },
]


export const Columns_Transport_Format: any[] = [



  
  {
    id: `srno`,
    Header: `Sr.No`,
    sticky:"left",
  },
 
  
  {
    id: `code`,
    Header: () => <div style={{display:"inline"}}>Transport<br/>Code</div>,
    accessor: `code`,
    sticky:"left",
  },
  {
    id: `priority`,
    Header: () => <div style={{display:"inline"}}>Priority</div>,
    accessor: `priority`,
    sticky:"left",
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.priority?row.original.priority:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
        </div>
      )
}
  },
  {
    id: `transport_id`,
    Header: () => <div style={{display:"inline"}}>Transport<br/>Id</div>,
    accessor: `transport_id`,
  },
  {
    id: `name`,
    Header: () => <div style={{display:"inline"}}>Transport<br/>Name</div>,
    accessor: `name`,
  },
  {
    id: `city_1`,
    Header: () => <div style={{display:"inline"}}>Transport<br/>City-1</div>,
    accessor: `city_1`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.city_1?.city_name}
        </div>
      )
}
  },
  {
    id: `city_2`,
    Header: () => <div style={{display:"inline"}}>Transport<br/>City-2</div>,
    accessor: `city_2`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.city_2?.city_name?row.original.city_2?.city_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
        </div>
      )
}
  },
  {
    id: `total_city_qty`,
    Header: () => <div style={{display:"inline"}}>Zone,Village<br/>& City Qty</div>,
    accessor: `total_city_qty`,
  },
  {
    id: `mobile_no_city_1`,
    Header: () => <div style={{display:"inline"}}>Mobile No.<br/>City-1</div>,
    accessor: `mobile_no_city_1`,
  },
  {
    id: `mobile_no_city_2`,
    Header: () => <div style={{display:"inline"}}>Mobile No.<br/>City-2</div>,
    accessor: `mobile_no_city_2`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.mobile_no_city_2?row.original.mobile_no_city_2:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
        </div>
      )
}
  },
  {
    id: `email_id_city_1`,
    Header: () => <div style={{display:"inline"}}>Email Id<br/>City-1</div>,
    accessor: `email_id_city_1`,
  },
  {
    id: `email_id_city_2`,
    Header: () => <div style={{display:"inline"}}>Email Id<br/>City-2</div>,
    accessor: `email_id_city_2`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.email_id_city_2?row.original.email_id_city_2:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
        </div>
      )
}
  },
 
  {
    id: `state`,
    Header: () => <div style={{display:"inline"}}>State</div>,
    accessor: `state`,
  },
  {
    id: `country`,
    Header: () => <div style={{display:"inline"}}>Country</div>,
    accessor: `country`,
  },

  {
    id: 'status',
    Header: 'Status',
    accessor:'status',

 
  },

  
  {
    id: 'created_at',
    Header: () => <div style={{display:"inline"}}>Created<br/>At</div>,
    accessor:'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{display:"inline"}}>Created<br/>By</div>,
    accessor:'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username

 
  },
  {
    id: 'updated_at',
    Header: () => <div style={{display:"inline"}}>Updated<br/>At</div>,
    accessor:'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{display:"inline"}}>Updated<br/>By</div>,
    accessor:'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },


  {
    id: 'Actions',
    Header: 'Actions',

  
  },
  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_Transport_Format, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows} = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_TRANSPORT_FORMAT_API}/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



