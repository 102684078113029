import { useEffect, useState, useRef } from "react";
import "../../components/Header/header.css";
import "../../components/Table/table.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import { useCreate } from "../../hooks/Api";
import { Alert } from "../../components/Model/Model";
import useFocusOnEnter from "../../components/focus";
import { DatePicker } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from 'dayjs';
import moment from "moment";


const Add_Gst = () => {
    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_GST_API?.toString()
    const [stateGST, setStateGST]: any = useState("")
    const [centralGST, setCentralGST]: any = useState("")
    const [integratedGST, setIntegratedGST]: any = useState("")
    const [indiastateGST, setIndiaStateGST]: any = useState("")
    const [indiacentralGST, setIndiaCentralGST]: any = useState("")
    const [indiaintegratedGST, setIndiaIntegratedGST]: any = useState("")
    const [length, setLength]: any = useState("")

    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);

    const { mutate: gst, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue, watch }: any = useForm({

    }
    );

    useEffect(() => {
        if (data) {

            reset(data)
        }
    }, [data]);



    const onSubmit = (branddetail: any) => {




        if (!fromDate && !toDate) {
            delete branddetail.from_date
            delete branddetail.to_date

        } else if (fromDate && !toDate) {
            delete branddetail.to_date

        } else if (!fromDate && toDate) {
            delete branddetail.from_date
        }
        const formData = new FormData();
        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value

            if (key === "from_date") {
                const renewdate = moment(branddetail.from_date, 'YYYY-MM-DD').format("DD-MM-YYYY")
                formData.append(key, renewdate)
            }
            else if (key === "to_date") {
                const renewdate = moment(branddetail.to_date, 'YYYY-MM-DD').format("DD-MM-YYYY")
                formData.append(key, renewdate)
            }
            else if (key === "india_GST" || key === "out_of_india_GST") {
                formData.append(key, parseFloat(branddetail[key]).toFixed(2))
            }

            else if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else {
                formData.append(key, formvalue[0])
            }
            if (typeof value === 'object' && value !== null) {
                formData.append(key, "")
            }
        }
        gst(formData);

    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listGST', { state: { showToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    const [indiaGst, setIndiaGst]: any = useState()

    const handleIndiaGST = (e: any) => {
        setLength(e.target.length < 3)
        setStateGST(((e.target.value) / 2))
        setCentralGST((e.target.value) / 2)
        setIntegratedGST(e.target.value)
        setValue("india_guj_GST", (e.target.value) / 2)
        setValue("out_state_GST", (e.target.value) / 2)
        setValue("ics_GST", e.target.value)

    }
    const handleOutofIndiaGST = (e: any) => {
        setIndiaStateGST((e.target.value) / 2)
        setIndiaIntegratedGST(e.target.value)
        setIndiaCentralGST((e.target.value) / 2)
        setValue("out_of_india_guj_GST", (e.target.value) / 2)
        setValue("out_of_india_out_state_GST", (e.target.value) / 2)
        setValue("out_of_india_ics_GST", e.target.value)
    }

    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }


    const [fromDate, setFromDate]: any = useState()
    const [toDate, setToDate]: any = useState()

    const [forFromDate, setForFromDate]: any = useState()
    const [forToDate, setForToDate]: any = useState()



    const handleFromDate = (e: any) => {
        setFromDate(e)
        setValue("from_date", `${e?.$y}-${e?.$M + 1}-${e?.$D}`)
        setForFromDate(e)
    }

    const handleToDate = (e: any) => {
        setToDate(e)
        setValue("to_date", `${e?.$y}-${e?.$M + 1}-${e?.$D}`)
        setForToDate(e)

    }


    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {

        return current && current < dayjs().startOf('day');
    };


    const handleReset = () => {

        reset()
        setStateGST("")
        setCentralGST("")
        setIntegratedGST("")
        setIndiaStateGST("")
        setIndiaCentralGST("")
        setIndiaIntegratedGST("")
        setForFromDate(null)
        setForToDate(null)


    }





    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header px-3 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>GST</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Masters</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listGST" className="text-decoration-none text-black">Gst</Link></li>
                                    <li className="breadcrumb-item">Add Gst</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">

                            <form
                                ref={focus} onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                                    {show && showmessage === "danger" && error?.response?.data?.message?.name == "color with this Color Name already exists." ? <Alert color={showmessage} message="Color Already Exist" /> : ""}

                                    {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">


                                                <h5 className="mb-0 mt-2 b">India GST%</h5>

                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="exampleInputEmail1">GST% <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number" className="form-control" placeholder="GST%"
                                                            // {...register("india_GST", { onChange: handleIndiaGST, required: { message: "GST is required" ,
                                                            // pattern: {
                                                            //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            //     message: "No more than 99"
                                                            //   }
                                                            // } })} 

                                                            {...register('india_GST', {
                                                                onChange: handleIndiaGST,
                                                                required: 'Field is required',
                                                                pattern: {
                                                                    // value: /^(0(?![.0-9])|[1-9]\d?(?:\.\d{0,3})?)$/gm,
                                                                    value: /^((?:0(?![.0-9])|0\.\d{1,3}|\.0[1-9]|\.[1-9]|[1-9]\d?(?:\.\d{0,3})?))$/gm,
                                                                    message: 'Enter valid GST',
                                                                },
                                                            })}




                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.india_GST?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="exampleInputEmail1">State GST (SGST)% <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number" className="form-control"
                                                            value={stateGST}
                                                            disabled
                                                            id="exampleInputBrand" placeholder="State GST (SGST)%" {...register("india_guj_GST", { required: { value: false, message: "State GST is required" } })} />
                                                        {/* <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div> */}
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.india_guj_GST?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="exampleInputEmail1">Central GST (CGST)% <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number" className="form-control" id="exampleInputBrand"
                                                            value={centralGST}
                                                            disabled
                                                            placeholder="Central GST (CGST)%" {...register("out_state_GST", { required: { value: false, message: "Central GST is required" } })} />
                                                        {/* <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div> */}
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.out_state_GST?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="exampleInputEmail1">Integrated GST (IGST)% <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number" className="form-control" id="exampleInputBrand"
                                                            value={integratedGST}
                                                            disabled
                                                            placeholder="Integrated GST (IGST)%" {...register("ics_GST", { required: { value: false, message: "Integrated GST is required" } })} />
                                                        {/* <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div> */}
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.ics_GST?.message}</span>
                                                </div>

                                                <div className="row mt-4">
                                                    <h5 className="mb-0 mt-2 b">Out of India GST%</h5>
                                                </div>

                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="exampleInputEmail1">GST% <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Out of India GST%"
                                                            {...register('out_of_india_GST', {
                                                                onChange: handleOutofIndiaGST,
                                                                required: 'Field is required',
                                                                pattern: {
                                                                    // value: /^(0(?![.0-9])|[1-9]\d?(?:\.\d{0,3})?)$/gm,
                                                                    value: /^((?:0(?![.0-9])|0\.\d{1,3}|\.0[1-9]|\.[1-9]|[1-9]\d?(?:\.\d{0,3})?))$/gm,
                                                                    message: 'Enter valid GST',
                                                                },
                                                            })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.out_of_india_GST?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="exampleInputEmail1">State GST (SGST)% <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number" className="form-control" id="exampleInputBrand"
                                                            value={indiastateGST}
                                                            disabled
                                                            placeholder="State GST (SGST)%" {...register("out_of_india_guj_GST", { required: { value: false, message: "State GST is required" } })} />
                                                        {/* <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div> */}
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.out_of_india_guj_GST?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="exampleInputEmail1">Central GST (CGST)% <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number" className="form-control" id="exampleInputBrand"
                                                            value={indiacentralGST}
                                                            disabled
                                                            placeholder="Central GST (CGST)%" {...register("out_of_india_out_state_GST", { required: { value: false, message: "Central GST is required" } })} />
                                                        {/* <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div> */}
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.out_of_india_out_state_GST?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="exampleInputEmail1">Integrated GST (IGST)% <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number" className="form-control" id="exampleInputBrand"
                                                            value={indiaintegratedGST}
                                                            disabled
                                                            placeholder="Integrated GST (IGST)%" {...register("out_of_india_ics_GST ", { required: { value: false, message: "Integrated GST is required" } })} />
                                                        {/* <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div> */}
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.out_of_india_out_state_GST?.message}</span>
                                                </div>
                                                <div className="row mt-4"></div>
                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="exampleInputEmail1">From Date</label>
                                                    <DatePicker
                                                        className="form-control"
                                                        format={dateFormatList}
                                                        value={forFromDate}
                                                        disabledDate={disabledDate}
                                                        {...register("from_date", { required: { value: false, message: "Field is required" } })}

                                                        onChange={handleFromDate}
                                                    />
                                                    {/* <input type="date" className="form-control"  style={{ fontSize: "13px" }}
                                                    
                                                    id="exampleInputBrand" {...register("from_date")} onChange={handleFromDate}/> */}
                                                    <span className='text-red w-100'>{errors?.from_date?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="exampleInputEmail1">To Date {/*<span style={{ color: 'red' }}>*</span>*/}</label>
                                                    {/* <input type="date" className="form-control" 
                                                    onChange={handleToDate}
                                                    style={{ fontSize: "13px" }} id="exampleInputBrand"  {...register("to_date")} /> */}

                                                    <DatePicker
                                                        className="form-control"
                                                        format={dateFormatList}
                                                        value={forToDate}
                                                        disabledDate={disabledDate}
                                                        {...register("to_date", { required: { value: false, message: "Field is required" } })}

                                                        onChange={handleToDate}
                                                    />
                                                    <span className='text-red w-100'>{errors?.to_date?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="remarks">Remarks </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" placeholder="Remarks" className="form-control" id="remarks"  {...register("remarks", { onChange: handleValue })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="status">Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status",
                                                        {
                                                            required: { value: true, message: "Status is required" }, maxLength: 30,

                                                        })}>
                                                        <option value="" selected>Select Any </option>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"inactive"}>Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        onClick={handleSubmit(onSubmit)} disabled={isLoading}>Submit</button>
                                                    <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listGST")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Add_Gst