import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Columns_Discount, titleofheaders } from "./Columns_Discount"
import Table from "../../../components/Dynamic_Table/Table/Table";

const Discount_Approval = () => {


    const Breadcrumb_Primary_Title = "Home";
    const Breadcrumb_Primary_Link = "";
  
    const Breadcrumb_Parent_Title = "Sales And Marketing";
  
    const Breadcrumb_Title_Name = "Discount Approval";
  
    const apiname = process.env.REACT_APP_SALES_DISCOUNT_PENDING_APPROVAL_API?.toString();
  
    const filter = {
      from_discount:'',
      to_discount:'',
      approver:'',
      optional_approver:''
  
    };
  
    const addButtonName = "Discount Approval";
    const addButtonPageName = "DiscountApprovalView"
    const permissionClass = {
      front: "sales",
      back: "discountapproval"
    }
    const stickyStart = 1;
    const stickyEnd = 2;
  


  return (
<div className="content-wrapper">
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{Breadcrumb_Title_Name} List</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to={`/list${Breadcrumb_Primary_Link}`}
                      className="text-decoration-none text-black"
                    >
                      {Breadcrumb_Primary_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      {Breadcrumb_Parent_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {Breadcrumb_Title_Name} List
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="container p-0" style={{ maxWidth: "100%" }}>
                <Table
                  apiname={apiname}
                  filterFields={filter}
                  addButtonName={addButtonName}
                  stickyStart={stickyStart}
                  stickyEnd={stickyEnd}
                  TableColumns={Columns_Discount}
                  addButtonPageName={addButtonPageName}
                  titleofheaders={titleofheaders}
                  permissionClass={permissionClass}
                  isAddButton={false}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
  )
}

export default Discount_Approval