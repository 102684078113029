import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/esm/Button";
import { useCreate } from '../../hooks/Api';
import { Alert } from "../../components/Model/Model";
import { useForm } from 'react-hook-form';
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from '../focus';
import Select from "react-select"
import { useNavigate } from 'react-router-dom';
import { useFetchBrandDropDown, useFetchColorDropDown, useFetchGSTDropDown } from '../../hooks/DropDown_Api';
import { DatePicker } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from 'dayjs';
import moment from "moment";


export const Gst_Model = ({refetchForm,open,setOpen,setSubmitted}:any) => {
    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_GST_API?.toString()
    const [stateGST, setStateGST]: any = useState("")
    const [centralGST, setCentralGST]: any = useState("")
    const [integratedGST, setIntegratedGST]: any = useState("")
    const [indiastateGST, setIndiaStateGST]: any = useState("")
    const [indiacentralGST, setIndiaCentralGST]: any = useState("")
    const [indiaintegratedGST, setIndiaIntegratedGST]: any = useState("")
    const [length, setLength]: any = useState("")

    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);

    const { mutate: gst, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue ,watch}: any = useForm({
        
    }
    );

    useEffect(() => {
        if (data) {

            reset(data)
        }
    }, [data]);

    

    const onSubmit = (branddetail: any) => {
        if(!fromDate && !toDate){
            delete branddetail.from_date
            delete branddetail.to_date

        }else if(fromDate && !toDate){
             delete branddetail.to_date

        }else if(!fromDate && toDate){
            delete branddetail.from_date
        }
        const formData = new FormData();
        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value

            if (key === "from_date") {
                const renewdate = moment(branddetail.from_date, 'YYYY-MM-DD').format("DD-MM-YYYY")
                formData.append(key, renewdate)
            }
            else if (key === "to_date") {
                const renewdate = moment(branddetail.to_date, 'YYYY-MM-DD').format("DD-MM-YYYY")
                formData.append(key, renewdate)
            }

            else if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else {
                formData.append(key, formvalue[0])
            }
            if (typeof value === 'object' && value !== null) {
                formData.append(key, "")
            }
        }
        gst(formData);

    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('#', { state: { showGstToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    const handleIndiaGST = (e: any) => {
        setLength(e.target.length < 3)
        setStateGST((e.target.value) / 2)
        setCentralGST((e.target.value) / 2)
        setIntegratedGST(e.target.value)
        setValue("india_guj_GST", (e.target.value) / 2)
        setValue("out_state_GST", (e.target.value) / 2)
        setValue("ics_GST", e.target.value)
    }
    const handleOutofIndiaGST = (e: any) => {
        setIndiaStateGST((e.target.value) / 2)
        setIndiaIntegratedGST(e.target.value)
        setIndiaCentralGST((e.target.value) / 2)
        setValue("out_of_india_guj_GST", (e.target.value) / 2)
        setValue("out_of_india_out_state_GST", (e.target.value) / 2)
        setValue("out_of_india_ics_GST", e.target.value)
    }

   


    const [fromDate, setFromDate]:any = useState()
    const [toDate, setToDate]:any = useState()

    const [forFromDate, setForFromDate]:any = useState()
    const [forToDate, setForToDate]:any = useState()



    const handleFromDate = (e: any) => {
        setFromDate(e)
        setValue("from_date", `${e?.$y}-${e?.$M + 1}-${e?.$D}`)
        setForFromDate(e)
    }

    const handleToDate = (e: any) => {
        setToDate(e)
        setValue("to_date", `${e?.$y}-${e?.$M + 1}-${e?.$D}`)
        setForToDate(e)

    }


    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {

        return current && current < dayjs().startOf('day');
    };


    const handleReset = () => {

        reset()
        setStateGST("")
        setCentralGST("")
        setIntegratedGST("")
        setIndiaStateGST("")
        setIndiaCentralGST("")
        setIndiaIntegratedGST("")
        setForFromDate(null)
        setForToDate(null)

       
    }



    useEffect(()=>{
        if(isSuccess){
            setSubmitted(true)
            setOpen(false)
            reset()
            // setForGSTValue(null)
        }
        refetchForm()
            },[isSuccess,data])
    

  


    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }


  return (
    <>
    <Modal  
    
    size='xl'
    
    show={open} onHide={()=>{
        setShow(false)
        reset()
        setOpen(false)}} aria-labelledby="contained-modal-title-vcenter"
centered    >
  <Modal.Header closeButton>
    <Modal.Title style={{fontSize:'16px'}}>GST</Modal.Title>
  </Modal.Header>
  <Modal.Body> 
     
  <form 
                            ref={focus} onKeyUp={event => onEnterKey(event)} 
                            onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto",overflowX:"hidden"}}>                                {show && showmessage === "success" ? <Alert color={showmessage} message={"Successfully Added"} /> : ""}

                                    {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">


                                                <h5 className="mb-0 mt-2 b">India GST%</h5>

                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="exampleInputEmail1">GST% <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number" className="form-control" placeholder="GST%"
                                                          
                                                            {...register('india_GST', {
                                                                onChange: handleIndiaGST,
                                                                required: 'Field is required',
                                                                pattern: {
                                                                    value: /^(?:(?:\d{1,2}.\d{2})|(?:\d{1,2}))$/gm,
                                                                message: 'enter valid number',
                                                                },
                                                            })}


                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.india_GST?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="exampleInputEmail1">State GST (SGST)% <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number" className="form-control"
                                                            value={stateGST}
                                                            disabled
                                                            id="exampleInputBrand" placeholder="State GST (SGST)%" {...register("india_guj_GST", { required: { value: false, message: "State GST is required" } })} />
                                                     
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.india_guj_GST?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="exampleInputEmail1">Central GST (CGST)% <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number" className="form-control" id="exampleInputBrand"
                                                            value={centralGST}
                                                            disabled
                                                            placeholder="Central GST (CGST)%" {...register("out_state_GST", { required: { value: false, message: "Central GST is required" } })} />
                                                        
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.out_state_GST?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="exampleInputEmail1">Integrated GST (IGST)% <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number" className="form-control" id="exampleInputBrand"
                                                            value={integratedGST}
                                                            disabled
                                                            placeholder="Integrated GST (IGST)%" {...register("ics_GST", { required: { value: false, message: "Integrated GST is required" } })} />
                                                       
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.ics_GST?.message}</span>
                                                </div>

                                                <div className="row mt-4">
                                                    <h5 className="mb-0 mt-2 b">Out of India GST%</h5>
                                                </div>

                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="exampleInputEmail1">GST% <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Out of India GST%"
                                                            {...register('out_of_india_GST', {
                                                                onChange: handleOutofIndiaGST,
                                                                required: 'Field is required',
                                                                pattern: {
                                                                    value: /^(?:(?:\d{1,2}.\d{2})|(?:\d{1,2}))$/gm,
                                                                message: 'enter valid number',
                                                                },
                                                            })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.out_of_india_GST?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="exampleInputEmail1">State GST (SGST)% <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number" className="form-control" id="exampleInputBrand"
                                                            value={indiastateGST}
                                                            disabled
                                                            placeholder="State GST (SGST)%" {...register("out_of_india_guj_GST", { required: { value: false, message: "State GST is required" } })} />
                                                        
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.out_of_india_guj_GST?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="exampleInputEmail1">Central GST (CGST)% <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number" className="form-control" id="exampleInputBrand"
                                                            value={indiacentralGST}
                                                            disabled
                                                            placeholder="Central GST (CGST)%" {...register("out_of_india_out_state_GST", { required: { value: false, message: "Central GST is required" } })} />
                                                        
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.out_of_india_out_state_GST?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="exampleInputEmail1">Integrated GST (IGST)% <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number" className="form-control" id="exampleInputBrand"
                                                            value={indiaintegratedGST}
                                                            disabled
                                                            placeholder="Integrated GST (IGST)%" {...register("out_of_india_ics_GST ", { required: { value: false, message: "Integrated GST is required" } })} />
                                                        
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.out_of_india_out_state_GST?.message}</span>
                                                </div>
                                                <div className="row mt-4"></div>
                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="exampleInputEmail1">From Date</label>
                                                    <DatePicker
                                                        className="form-control"
                                                        format={dateFormatList}
                                                        value={forFromDate}
                                                        disabledDate={disabledDate}
                                                        {...register("from_date", { required: { value: false, message: "Field is required" } })}

                                                        onChange={handleFromDate}
                                                    />
                                                   

                                                </div>
                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="exampleInputEmail1">To Date</label>
                                                    

                                                    <DatePicker
                                                        className="form-control"
                                                        format={dateFormatList}
                                                        value={forToDate}
                                                        disabledDate={disabledDate}
                                                        {...register("to_date", { required: { value: false, message: "Field is required" } })}

                                                        onChange={handleToDate}
                                                    />

                                                </div>
                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="remarks">Remarks </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" placeholder="Remarks" className="form-control" id="remarks"  {...register("remarks", { onChange: handleValue })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="status">Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status",
                                                        {
                                                            required: { value: true, message: "Status is required" }, maxLength: 30,

                                                        })}>
                                                        <option value="" selected>Select Any </option>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"inactive"}>Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                  

                                </div>
                            </form>

      
</Modal.Body>
  <Modal.Footer>
  
    <Button variant="info" className="text-white" 
 onClick={handleSubmit(onSubmit)}
 style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
    >
      Submit
    </Button>
    <Button variant="info" className="text-white" style={{ borderColor: "blue", background: "blue", color: "white" }}
 onClick={handleReset}
 >
   Reset
 </Button>
    <Button variant="secondary" style={{ borderColor: "red", background: "red", color: "white" }} onClick={()=>{setOpen(false)}}>
      Cancel
    </Button>
  </Modal.Footer>
</Modal>
    </>
  )
}



