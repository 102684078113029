import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate } from "../../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../../components/focus";
import "../Sales Order/sales_order.css"
import Accordion from 'react-bootstrap/Accordion';
import { DatePicker } from "antd";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useFetchCustomerDropDown, useFetchSalesOrderDropDown, useFetchDeliveryAddressDropDown, useFetchTransportationDropDown, useFetchMaterialTypeDropDown, useFetchPriceListProductDropDown, useFetchUOMDropDown, useFetchDispatchOrderDropDown } from "../../../hooks/DropDown_Api";
import Table from "./../../../components/Dynamic_Table/Table/Table"
// import { Columns_DispatchsOrder, titleofheaders } from "./columns_DispatchsOrder.tsx";
import { customAlphabet } from "nanoid";
import Axiosinstance from '../../../hooks/Interceptor'
import jsPDF from 'jspdf'
import FileDownload from 'js-file-download';
import { useLocation } from 'react-router-dom';
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'


const customIndicator = {
  IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
  IndicatorsContainer: IndicatorsContainerCreate
}


const Dispatch_Order = () => {

  const location = useLocation();

  // Get the passed data
  const rowData = location.state?.detail;

  // console.log("rowData", rowData)

  const nanoid = customAlphabet('1234567890', 5)
  const apiname = process.env.REACT_APP_SALES_DISPATCH_ORDER_API?.toString();
  const { mutate, data, isError, isLoading, isSuccess: isSuccessSalesOrder, error }: any = useCreate(apiname)
  // console.log(data,"data");

  let pageTitle = "Dispatch Order"
  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);
  const navigate = useNavigate()

  const [openEditModel, setOpenEditModel] = useState(false)

  const { register, handleSubmit, setError, formState, reset, setValue, getValues }: any = useForm({
    mode: "onChange"
  });
  const { errors } = formState;


  const [salesOrder, setSalesorder]: any = useState()
console.log(salesOrder,"salesOrder");

  useEffect(() => {
    setSalesorder(rowData)

  }, [])

  const [optionsTransportation, setOptionsTransportation] = useState([])
  const [TransportationValue, setTransportationValue]: any = useState("")
  const { data: TransportationData, refetch: TransportationRefetch } = useFetchTransportationDropDown()

  useEffect(() => {
    setOptionsTransportation(TransportationData)
  }, [TransportationData])


  const handleTransportation = (e: any) => {
    setValue('transportation', e?.value)
    setTransportationValue(e)
  }
  const [optionsProductType, setOptionsProductType] = useState([])
  const [ProductTypeValue, setProductTypeValue] = useState("")




  const handleValue = (e: any) => {
    e.target.value = e.target.value.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');

  }

  const [selectedTrnsportation, setSelectedTrnsportation] = useState('')

  useEffect(() => {
    if (salesOrder) {
      // console.log(salesOrder?.extra_response?.transport_detail?.transport_detail_id,"salesOrder?.transportation?.id");
      
      setSelectedTrnsportation(salesOrder?.extra_response?.transport_detail?.transport_detail_id)
      setValue('transportation', salesOrder?.extra_response?.transport_detail?.transport_detail_id)

    }
  }, [salesOrder])
  useEffect(() => {
    if (data) {
      reset(data)

    }
  }, [data])


  const handleReset = () => {
    reset()
  }




  







  const onSubmit = async (data: any) => {

    // data.transportation.id
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      const formvalue: any = value



      if (typeof value !== "object") {
        formData.append(key, formvalue)
      }

    }
    formData.append('sales_order', salesOrder?.id);
    formData.append('name', salesOrder?.extra_response?.customer?.customer_id);
    formData.append('dispatch_date', currentDate);


    if (finalData) {
      let temp = finalData?.map((e: any) => {
        return (
          {
            sales_product: e?.id,
            dispatch_qty: e?.dispatch_qty
          }
        )
      })
      formData.append('dispatch_product_qtys', JSON.stringify(temp))
    }

    const newFieldErrors = finalData.map((field: any) => {
      const errors: any = {};

      if (!field.dispatch_qty) {
        errors.dispatch_qty = 'Field is required';
      }
      return errors;
    });

    // setFieldErrors(newFieldErrors);

    mutate(formData);


  }



  //   const onSubmit = async (data: any) => {
  //     const rawData: any = {};

  //     for (const [key, value] of Object.entries(data)) {
  //         if (typeof value !== "object") {
  //             rawData[key] = value;
  //         }
  //     }

  //     rawData.sales_order = salesOrder?.id;
  //     rawData.name = salesOrder?.name?.id;
  //     rawData.dispatch_date = currentDate;

  //     if (finalData) {
  //         let temp = finalData.map((e: any) => ({
  //             sales_product: e?.id,
  //             dispatch_qty: e?.dispatch_qty,
  //         }));
  //         rawData.dispatch_product_qty = temp;
  //     }

  //     mutate(rawData);
  // }






  // const apinameForAddSalesOrder = process.env.REACT_APP_ADD_SALES_ORDER_API?.toString();

  // const { mutate: addSalesOrder, data: dataOfAddSalesOrder, isSuccess } = useCreate(apinameForAddSalesOrder)


  // const [rerenderKey, setRerenderKey] = useState(0);

  // useEffect(() => {
  //   if (dataOfAddSalesOrder) {
  //     setOrderData(dataOfAddSalesOrder?.data?.sales_inquiry_code?.product.reverse())
  //     setRerenderKey(prevKey => prevKey + 1);
  //   }
  // }, [dataOfAddSalesOrder])










  // useEffect(() => {
  //   if (rerenderKey) {

  //   }
  // }, [rerenderKey])










  function getDate() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${date}-${month}-${year}`;
  }
  const [currentDate, setCurrentDate] = useState(getDate());










  const [show, setShow] = useState(false)
  const [showmessage, setshowmessage] = useState("")

  useEffect(() => {
    if (isSuccessSalesOrder) {
      setShow(true)
      setshowmessage("success")
      navigate('/listDispatchOrder', { state: { showToast: true } })

    }
    else if (isError) {
      setShow(true)
      setshowmessage("danger")
    }
  }, [isSuccessSalesOrder, data, error])

  useEffect(() => {
    setTimeout(() => {
      setShow(false)
    }, 3000)

  }, [show]);


  // console.log(salesOrder,"sales");

  const [finalData, setFinalData]: any = useState(rowData?.salesorder_product?.map((item: any) => ({
    ...item,
    checked: false,
    dispatch_qty: "",
  })));
  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedData = [...finalData];

    if (e.target.checked) {
      updatedData[index].dispatch_qty = updatedData[index].remaining_dispatch_qty;
      updatedData[index].checked = true;
    } else {
      updatedData[index].dispatch_qty = "";  // reset the value
      updatedData[index].checked = false;
    }

    setFinalData(updatedData);
  };


  const apinameTransport = process.env.REACT_APP_TRANSPORT_FORMAT_API?.toString();
  const apinameSalesOrderProduct = process.env.REACT_APP_SALES_SALES_ORDER_PRODUCT_ID_API?.toString();
  useEffect(() => {
    if (rowData?.transportation) {

      const getCall = async () => {
        // 
        try {
          const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameTransport}/${rowData?.transportation}`);

          // console.log(response,":response");

          setSelectedTrnsportation(response?.data?.data?.id)

        }
        catch (error) {
          console.error('Error:', error);
        }
      }
      getCall()
    }
    if (rowData?.salesorder_product.length>0) {

      const getCall = async () => {
        // 
        try {
          const responseSalesOrder = await Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apinameSalesOrderProduct}/fetch_multiple_ids/`, {
            ids: rowData?.salesorder_product
          });
          setFinalData(responseSalesOrder?.data?.data)

        }
        catch (error) {
          console.error('Error:', error);
        }
      }
      getCall()
    }
  }, [rowData])

  // console.log("final", finalData)



  return (
    <div>


      <div className="content-wrapper" >
        <section className="content-header px-3 py-1">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{pageTitle}</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  {/* <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li> */}
                  {/* <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li> */}
                  <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales</Link></li>
                  <li className="breadcrumb-item active">{pageTitle}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <form ref={focus}
                onKeyUp={event => onEnterKey(event)}
                onSubmit={handleSubmit(onSubmit)}>

                {show && showmessage === "success" ? <Alert color={showmessage} message={"Updated Successfully"} /> : ""}







                <div className="row">
                  {/* <div className="col-md-3">
                    <div className="">
                      <label htmlFor="inputField" className="form-label">Name</label>
                      <Select
                        {...register("name", { required: { value: true, message: "Name is required" } })}
                        value={customerValue}
                        options={optionsCustomer}
                        // onChange={handleCustomer}

                      />

                    </div>
                    <span className='text-red w-100'>{errors?.name?.message}</span>
                  </div> */}


                  <div className="col-md-3">

                    <label htmlFor="selectField1" className="form-label">Transportation</label>

                    <Select
                      {...register("transportation", { required: { value: false, message: "Transportation is required" }, maxLength: 30, })}
                      value={TransportationValue === "" ? optionsTransportation?.filter((e: any) => e.value === selectedTrnsportation) : TransportationValue}
                      options={optionsTransportation}
                      onChange={handleTransportation}
                      components={customIndicator}
                      isDisabled
                    />

                    <span className='text-red w-100'>{errors?.transportation?.message}</span>
                  </div>


                </div>


                <div className="card" style={{ backgroundColor: "#013564", color: "white" }}>
                  <div className="card-body" style={{ padding: "0px" }}>
                    <div className="row">

                      <div className="col-sm-4" style={{ fontSize: "13px" }}>
                        <p className="card-text"><strong>Name:</strong> {salesOrder?.extra_response?.customer?.name}</p>
                        <p className="card-text"><strong>Transportation:</strong> {salesOrder?.extra_response?.transport_detail?.transport_detail_name}</p>
                        {/* <p className="card-text"><strong>Address:</strong> {DeliveryValue?.label} </p> */}
                      </div>
                      {/* <div className="col-sm-4">
                        <p className="card-text"><strong>Address:</strong> {DeliveryValue?.label} </p>
                        <p className="card-text"><strong>Sales Person Name:</strong> {customerValue?.zone?.salesman_employee?.name}</p>
                        <p className="card-text"><strong>Delivered Cust. To:</strong> {customerValue?.name}</p>
                        <p className="card-text"><strong>Zone:</strong> {customerValue?.zone?.route_name}</p>
                        <p className="card-text"><strong>Delivered Address:</strong> {DeliveryValue?.label}</p>
                      </div> */}
                      <div className="col-sm-4">
                        <p className="card-text"><strong>Sales Order Number:</strong> {salesOrder?.code}</p>
                        <p className="card-text"><strong>Date:</strong> {currentDate} </p>

                      </div>
                    </div>
                  </div>
                </div>




                <div className="table-responsive" >
                  <table className="table" style={{ marginTop: "20px" }}>
                    <thead>
                      <tr>
                        <th>Sr.<br />No. </th>
                        <th>Product Code<br />& Name & Type</th>
                        <th>Order <br /> Qty</th>
                        <th>Remaining <br /> Qty</th>
                        <th>Check <br />Box</th>
                        <th>Dispacth <br /> Qty</th>

                      </tr>
                    </thead>

                    <tbody>
                      {finalData?.map((e: any, index: any) => {

                        // console.log("final data", e)

                        return (

                          <tr key={e?.id}>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Sr. No."
                                disabled
                                value={index + 1}
                              />

                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Product Code & Name & Type"
                                disabled
                                value={e?.product_code}
                              />
                              {/* <Select
                            placeholder="Product Code & Name & Type"
                          // options={optionsProductType}
                          //   value={field.product_type}
                          // onChange={(selectedOption) =>
                          //   handleChangeProductType(selectedOption, index)
                          // }

                          /> */}
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Order Qty"
                                disabled
                                value={e?.order_qty}
                              />

                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Remaining Qty"
                                disabled
                                value={e?.remaining_dispatch_qty}
                              />

                            </td>
                            <td style={{ textAlign: 'center' }}>
                              <input
                                type="checkbox"
                                checked={e.checked}
                                onChange={(event) => handleCheck(event, index)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Dispatch Qty"
                                value={e.dispatch_qty}
                                disabled={e.checked}
                                onChange={(event) => {
                                  const newData = [...finalData];
                                  newData[index].dispatch_qty = event.target.value;
                                  setFinalData(newData);
                                }}
                              />
                              {/* {fieldErrors[index] && (
                                    <div className="error-message text-red">{fieldErrors[index].dispatch_qty}</div>
                                  )} */}
                            </td>

                          </tr>


                        )
                      })}

                      {/* <td colSpan={2} style={{backgroundColor:'#AAAAAA',textAlign:'center'}}>
                        <b>
                        Total
                        </b>
                        </td>
                      <td  style={{backgroundColor:'#AAAAAA'}}>
                        
                        </td> */}

                    </tbody>
                  </table>
                </div>

                <div className="p-1" style={{ display: "flex", justifyContent: "end" }}>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Submit
                  </button>


                </div>



              </form>


            </div>

          </div>
        </section>
      </div>
      {/* <Modal show={openEditModel} onHide={() => setOpenEditModel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Sales Order Product</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Add Product Here.</p>
        </Modal.Body>

        <Modal.Footer>
          <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>
          <button
            type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
          // onClick={handleSubmit(handleConfirm)}
          >Confirm</button>
        </Modal.Footer>
      </Modal> */}
    </div>
  )
}

export default Dispatch_Order