import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/esm/Button";
import { useCreate } from '../../hooks/Api';
import { Alert } from "../../components/Model/Model";
import { useForm } from 'react-hook-form';
import { RiPencilLine } from "react-icons/ri";


export const Department_Model = ({refetchForm,open,setOpen,setSubmitted}:any) => {


    const { register:registerDepartment, handleSubmit:handleSubmitDepartment, formState: { errors:errorsDepartment },reset}: any = useForm({
        mode: "onChange"
    });


    const apinameforDepartment = process.env.REACT_APP_HR_DEPARTMENT_API?.toString()


    const  { mutate: department, data,isSuccess,error,isError,success}:any = useCreate(apinameforDepartment)
  


    useEffect(()=>{
        if(isSuccess){
            setSubmitted(true)
            setOpen(false)
            reset()
            // setForGSTValue(null)
        }
        refetchForm()
            },[isSuccess,data])


   
  
 
const handleResetDepartment = () => {
    reset()
    }

    const onSubmitDepartment = (branddetail: any) => {
        
        const formData = new FormData();
        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else
            {
                formData.append(key, formvalue[0])
            }
            if(typeof value === 'object' && value !== null){
                formData.append(key, "")
            }
        }
        department(formData);

    }



    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);


    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }


  return (
    <>
    <Modal  
    
    size='xl'
    
    
    show={open} onHide={()=>{
        setShow(false)
        reset()
        setOpen(false)}} aria-labelledby="contained-modal-title-vcenter"
centered    >
  <Modal.Header closeButton>
    <Modal.Title style={{fontSize:'16px'}}>Department</Modal.Title>
  </Modal.Header>
  <Modal.Body> 
     
  <form >

  {show && showmessage === "success" ? <Alert color={showmessage} message={"Successfully Added"} /> : ""}


{show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}

<div className="row d-flex justify-content-md-center justify-content-lg-start">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-sm-6 mt-2">
                                        <label htmlFor="exampleInputEmail1">Department Name <span style={{ color: 'red' }}>*</span></label>
                                        <div className="input-group" >
                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Department Name" 
                                        {...registerDepartment("department",{ 
                                            onChange:handleValue,
                                            required: { value: true, message: "Department Name is required" }})} 
                                            />
                                                <div className="input-group-append" >
                                                <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                // style={{backgroundColor:"blue",color:"green"}} 
                                                /></span>
                                            </div>
                                            </div>
                                        <span className='text-red w-100'>{errorsDepartment?.department?.message}</span>
                                    </div>
                                    <div className="col-sm-6 mt-2">
                                        <label htmlFor="exampleInputEmail1">Status <span style={{color:'red'}}>*</span></label>
                                        <select className="form-select" {...registerDepartment("status", { required: { value: true, message: "Status is required" } })}>
                                        <option value={""}  selected>Select Any</option>
                                            <option value={"active"}>Active</option>
                                            <option value={"inactive"}>Inactive</option>
                                        </select>
                                        <span className='text-red w-100'>{errorsDepartment?.status?.message}</span>
                                    </div>


                                   
                                
                                </div>
                            </div>
                        </div>
                        </form>
      
</Modal.Body>
  <Modal.Footer>
  
    <Button variant="info" className="text-white" 
 onClick={handleSubmitDepartment(onSubmitDepartment)}
 style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
    >
      Submit
    </Button>
    <Button variant="info" className="text-white" style={{ borderColor: "blue", background: "blue", color: "white" }}
 onClick={handleResetDepartment}
 >
   Reset
 </Button>
    <Button variant="secondary" style={{ borderColor: "red", background: "red", color: "white" }} onClick={()=>{setOpen(false)}}>
      Cancel
    </Button>
  </Modal.Footer>
</Modal>
    </>
  )
}



