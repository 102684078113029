import React, { useEffect, useState } from "react";
import SearchLogo from "../../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate, useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import { useFetchCityDropDown, useFetchEmployeeDropDown } from "../../../hooks/DropDown_Api";
import Modal from "react-bootstrap/esm/Modal";
import { Deletesinglewithdependancy } from "../../../components/modal";
import { City_Model } from "../../../components/Dynamic_Form_Models/City_Model";
import { Employee_Model } from "../../../components/Dynamic_Form_Models/Employee_Model";
import Button from "react-bootstrap/esm/Button";
import { AiOutlinePlus } from "react-icons/ai";
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'


const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}

const Edit_Zone = () => {
    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_ZONE_API?.toString()
    const { id } = useParams()
    const { data } = useFetchSingle(id, apiname)

    const [options, setOptions] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [loading, setLoading] = useState(false)
    const [openCity, setOpenCity]: any = useState(false)

    const [openEditModel, setOpenEditModel] = useState(false)
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()
    const [expirationDate, setExpirationDate] = useState()

    // const { mutate, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)
    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()

    const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();
    const { mutate, isSuccess, isError, error, data: Updated_response }: any = useUpdateSingle()


    const [openCityEmployee, setOpenCityEmployee]: any = useState(false)
    const [openAreaEmployee, setOpenAreaEmployee]: any = useState(false)
    const [openStateEmployee, setOpenStateEmployee]: any = useState(false)
    const [openCountryEmployee, setOpenCountryEmployee]: any = useState(false)
    const [openGeneralEmployee, setOpenGeneralEmployee]: any = useState(false)
    const [openOwnerEmployee, setOpenOwnerEmployee]: any = useState(false)
    const [openOwnerAllEmployee, setOpenOwnerAllEmployee]: any = useState(false)








    const onSubmit = (details: any) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(details)) {
            const formvalue: any = value

            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else if (Array.isArray(value) && key === "city") {
                value.forEach((val, index) => formData.append(`city`, val))
            }
            else if (Array.isArray(value) && key === "salesman_employee") {
                value.forEach((val, index) => formData.append(`salesman_employee`, val))
            }
            else if (Array.isArray(value) && key === "area_manager") {
                value.forEach((val, index) => formData.append(`area_manager`, val))
            }
            else if (Array.isArray(value) && key === "state_manager") {
                value.forEach((val, index) => formData.append(`state_manager`, val))
            }
            else if (Array.isArray(value) && key === "sales_manager") {
                value.forEach((val, index) => formData.append(`sales_manager`, val))
            }
            else if (Array.isArray(value) && key === "general_manager") {
                value.forEach((val, index) => formData.append(`general_manager`, val))
            }
            else if (Array.isArray(value) && key === "sheth_employee") {
                value.forEach((val, index) => formData.append(`sheth_employee`, val))
            }
            // else {
            //     formData.append(key, formvalue[0])
            // }
        }
        let data = {
            apiname: apiname,
            id: id,
            data: formData
        }
        mutate(data);
        setValuesofform(data);


    }


    const [forCityValue, setForCityValue]: any = useState()
    const [selCity, setSelCity]: any = useState("")
    const [selectedCity, setSelectedCity]: any = useState("")
    const [cityOptions, setCityOptions]: any = useState()
    const [totalCity, setTotalCity]: any = useState(0)

    const { data: cityData, refetch: cityRefetch } = useFetchCityDropDown()


    useEffect(() => {
        setCityOptions(cityData)
    }, [cityData])


    const [forState, setForState] = useState("")
    const [forCountry, setForCountry] = useState("")


    const handleChangeCity = (e: any) => {

        let city = e?.map((e: any) => {
            return e.value
        })

        let StateAndCountry = e?.map((e: any) => {
            return { state: e.state, country: e.country, stateID: e.stateID, countryID: e.countryID }
        })

        setSelCity(e)
        setForCityValue(e)
        setValue("city", city)

        setTotalCity(city?.length)
        setValue("total_city", city?.length)

        setForState(StateAndCountry[0]?.state)
        setForCountry(StateAndCountry[0]?.country)
        setValue("state", StateAndCountry[0]?.stateID)
        setValue("country", StateAndCountry[0]?.countryID)

    }


    const [forSalesmanValue, setForSalesmanValue]: any = useState("")
    const [selSalesmanValue, setSelSalesmanValue] = useState("")
    const [selectedSalesmanValue, setSelectedSalesmanValue]: any = useState("")

    const [forStateManagerValue, setForStateManagerValue]: any = useState("")
    const [selStateManagerValue, setSelStateManagerValue]: any = useState("")
    const [selectedStateManagerValue, setSelectedStateManagerValue]: any = useState("")

    const [forAreaManagerValue, setForAreaManagerValue]: any = useState("")
    const [selAreaManagerValue, setSelAreaManagerValue]: any = useState("")
    const [selectedAreaManagerValue, setSelectedAreaManagerValue]: any = useState("")

    const [forSalesManagerValue, setForSalesManagerValue]: any = useState("")
    const [selSalesManagerValue, setSelSalesManagerValue]: any = useState("")
    const [selectedSalesManagerValue, setSelectedSalesManagerValue]: any = useState("")

    const [forGeneralManagerValue, setForGeneralManagerValue]: any = useState("")
    const [selGeneralManagerValue, setSelGeneralManagerValue]: any = useState("")
    const [selectedGeneralManagerValue, setSelectedGeneralManagerValue]: any = useState("")

    const [forOwnerValue, setForOwnerValue]: any = useState("")
    const [selOwnerValue, setSelOwnerValue]: any = useState("")
    const [selectedOwnerValue, setSelectedOwnerValue]: any = useState("")

    const [forOwnerAllValue, setForOwnerAllValue]: any = useState()
    const [selOwnerAllValue, setSelOwnerAllValue]: any = useState("")
    const [selectedOwnerAllValue, setSelectedOwnerAllValue]: any = useState("")

    // const[forValue,setForValue]:any = useState()
    const [employeeOptions, setEmployeeOptions]: any = useState([])
    // const[employeeQty,setEmployeeQty] = useState(0)


    const { data: employeeData, refetch: EmployeeRefetch } = useFetchEmployeeDropDown()


    useEffect(() => {
        setEmployeeOptions(employeeData)
    }, [employeeData])







    const handleChangeAreaManager = (e: any) => {
        //  let employees = e?.map((e:any)=>{
        //     return e.value
        // })
        setForAreaManagerValue(e.value)
        setValue("area_manager", e.value)
        setSelAreaManagerValue(e)

    }
    const handleChangeStateManager = (e: any) => {
        //  let employees = e?.map((e:any)=>{
        //     return e.value
        // })
        setForStateManagerValue(e.value)
        setValue("state_manager", e.value)
        setSelStateManagerValue(e)

    }
    const handleChangeSalesManager = (e: any) => {
        //  let employees = e?.map((e:any)=>{
        //     return e.value
        // })
        setForSalesManagerValue(e.value)
        setValue("sales_manager", e.value)
        setSelSalesManagerValue(e)

    }
    const handleChangeGeneralManager = (e: any) => {
        //  let e.value = e?.map((e:any)=>{
        //     return e.value
        // })
        setForGeneralManagerValue(e.value)
        setValue("general_manager", e.value)
        setSelGeneralManagerValue(e)

    }
    const handleChangeOwner = (e: any) => {
        //  let e.value = e?.map((e:any)=>{
        //     return e.value
        // })
        setForOwnerValue(e.value)
        setValue("sheth_employee", e.value)
        setSelOwnerValue(e)

    }
    const handleChangeSalesman = (e: any) => {
        //  let e.value = e?.map((e:any)=>{
        //     return e.value
        // })
        setForSalesmanValue(e.value)
        setValue("salesman_employee", e.value)
        setSelSalesmanValue(e)

    }

    const handleChangeOwnerAll = (e: any) => {

        setForOwnerAllValue(e)
        setValue("owner_all_management_code", e.value)
        setSelOwnerAllValue(e)
    }


    useEffect(() => {
        if (data) {

            reset(data)

            setValue("city", data?.city?.map((e: any) => e.id))
            setSelectedCity(data?.city?.map((e: any) => e.id))

            setValue("area_manager", data?.area_manager?.id)
            setSelectedAreaManagerValue(data?.area_manager?.id)

            setValue("state_manager", data?.state_manager?.id)
            setSelectedStateManagerValue(data?.state_manager?.id)

            setValue("sales_manager", data?.sales_manager?.id)
            setSelectedSalesManagerValue(data?.sales_manager?.id)

            setValue("general_manager", data?.general_manager?.id)
            setSelectedGeneralManagerValue(data?.general_manager?.id)

            setValue("sheth_employee", data?.sheth_employee?.id)
            setSelectedOwnerValue(data?.sheth_employee?.id)

            setValue("salesman_employee", data?.salesman_employee?.id)
            setSelectedSalesmanValue(data?.salesman_employee?.id)

            setForState(data?.city[0]?.state?.name)
            setValue("state", data?.city[0]?.state?.name)

            setForCountry(data?.city[0]?.country?.name)
            setValue("country", data?.city[0]?.country?.name)

            setTotalCity(data?.city?.length)

            setSelectedOwnerAllValue(data?.owner_all_management_code?.id)
            setValue('owner_all_management_code', data?.owner_all_management_code?.id)


        }
    }, [data])





    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess || yessuccess || nosuccess) {
            setValuesofform(null)
            setShow(true)
            navigate('/listZone', { state: { showEditToast: true } })
        }

    }, [data, isSuccess, yessuccess,
        nosuccess, Updated_response])

    useEffect(() => {
        if (isError) {
            if (error.response.status === 405) {
                setConfirmmodal(true)
            }
        }
        else if (isError || yeserror || noerror) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isError, yeserror, noerror])
    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);




    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }
    const noupdatemodal = () => {
        setOpenEditModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {

        delete data.created_at
        delete data.created_by
        let ok = {
            id: id,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditModel(false)
    }

    const [SubmittedCity, setSubmittedCity]: any = useState(false)
    useEffect(() => {
        if (SubmittedCity) {

            setSelCity(cityOptions[0])


            setValue("city", cityOptions[0]?.value)

        }

    }, [SubmittedCity, cityOptions])


    const [SubmittedCityEmployee, setSubmittedCityEmployee]: any = useState(false)
    useEffect(() => {
        if (SubmittedCityEmployee) {

            setSelSalesmanValue(employeeOptions[0])


            setValue("salesman_employee", employeeOptions[0]?.value)

        }

    }, [SubmittedCityEmployee, employeeOptions])
    const [SubmittedAreaEmployee, setSubmittedAreaEmployee]: any = useState(false)
    useEffect(() => {
        if (SubmittedAreaEmployee) {

            setSelAreaManagerValue(employeeOptions[0])


            setValue("area_manager", employeeOptions[0]?.value)

        }

    }, [SubmittedAreaEmployee, employeeOptions])
    const [SubmittedStateEmployee, setSubmittedStateEmployee]: any = useState(false)
    useEffect(() => {
        if (SubmittedStateEmployee) {

            setSelStateManagerValue(employeeOptions[0])


            setValue("state_manager", employeeOptions[0]?.value)

        }

    }, [SubmittedStateEmployee, employeeOptions])
    const [SubmittedCountryEmployee, setSubmittedCountryEmployee]: any = useState(false)
    useEffect(() => {
        if (SubmittedCountryEmployee) {

            setSelSalesManagerValue(employeeOptions[0])


            setValue("sales_manager", employeeOptions[0]?.value)

        }

    }, [SubmittedCountryEmployee, employeeOptions])
    const [SubmittedGeneralEmployee, setSubmittedGeneralEmployee]: any = useState(false)
    useEffect(() => {
        if (SubmittedGeneralEmployee) {

            setSelGeneralManagerValue(employeeOptions[0])


            setValue("general_manager", employeeOptions[0]?.value)

        }

    }, [SubmittedGeneralEmployee, employeeOptions])
    const [SubmittedOwnerEmployee, setSubmittedOwnerEmployee]: any = useState(false)
    useEffect(() => {
        if (SubmittedOwnerEmployee) {

            setSelOwnerValue(employeeOptions[0])


            setValue("sheth_employee", employeeOptions[0]?.value)

        }

    }, [SubmittedOwnerEmployee, employeeOptions])
    const [SubmittedOwnerAllEmployee, setSubmittedOwnerAllEmployee]: any = useState(false)
    useEffect(() => {
        if (SubmittedOwnerEmployee) {

            setSelOwnerAllValue(employeeOptions[0])


            setValue("owner_all_management_code", employeeOptions[0]?.value)

        }

    }, [SubmittedOwnerEmployee, employeeOptions])


    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit Zone</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listSourceInquiry" className="text-decoration-none text-black">Source Inquiry</Link></li>
                                    <li className="breadcrumb-item active">Edit Zone</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Source Name</span></h5>
                            </div> */}
                            <form
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                
                                                <div className="col-sm-3 mt-2">
                                                    <label >Zone Number <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Zone Number" {...register("number", { required: { value: true, message: "Field is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.number?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >Route Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Route Name" {...register("route_name", { required: { value: true, message: "Field is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.route_name?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >Zone City Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row">
                                                        <Select
                                                            {...register("city", {
                                                                required: {
                                                                    value: false,
                                                                    message: "Field is required",
                                                                },
                                                            })}
                                                            isMulti
                                                            onChange={handleChangeCity}
                                                            options={cityOptions}
                                                            components={customIndicator}
                                                            value={selCity === "" ? cityOptions?.filter((obj: any) => selectedCity.includes(obj.value)) : selCity}
                                                            //     isOptionDisabled={(optionsUom: any) => {
                                                            //     if (optionsUom.status === "inactive" || optionsUom.deleted == true) {
                                                            //         return optionsUom
                                                            //     }
                                                            // }}
                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenCity(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.city?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >Zone Total City</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" value={totalCity} placeholder="Zone Total City" {...register("total_city", { required: { value: false, message: "Field is required" } })} />

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.total_city?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label >State Name</label>
                                                    <div className="input-group">
                                                        <input disabled type="text" className="form-control" value={forState} placeholder="State Name" {...register("state", { required: { value: false, message: "Field is required" } })} />

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.state?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label >Country Name</label>
                                                    <div className="input-group">
                                                        <input disabled type="text" className="form-control" value={forCountry} placeholder="Country Name" {...register("country", { required: { value: false, message: "Field is required" } })} />

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.country?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >City Sales Exicutive Code & Surname & Name  <span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        {...register("salesman_employee", {
                                                            required: {
                                                                value: false,
                                                                message: "Field is required",
                                                            },
                                                        })}
                                                        components={customIndicator}
                                                        onChange={handleChangeSalesman}
                                                        options={employeeOptions}
                                                        value={selSalesmanValue === "" ? employeeOptions?.find((obj: any) => obj.value === selectedSalesmanValue) : selSalesmanValue}
                                                    //     isOptionDisabled={(employeeOptions: any) => {
                                                    //     if (employeeOptions.status === "inactive" || employeeOptions.deleted == true) {
                                                    //         return employeeOptions
                                                    //     }
                                                    // }}
                                                    />
                                                    <span className='text-red w-100'>{errors?.salesman_employee?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >Area Sales Manager Employee Code & Surname & Name  <span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        {...register("area_manager", {
                                                            required: {
                                                                value: false,
                                                                message: "Field is required",
                                                            },
                                                        })}
                                                        components={customIndicator}
                                                        onChange={handleChangeAreaManager}
                                                        options={employeeOptions}
                                                        value={selAreaManagerValue === "" ? employeeOptions?.find((obj: any) => obj.value === selectedAreaManagerValue) : selAreaManagerValue}
                                                    //     isOptionDisabled={(employeeOptions: any) => {
                                                    //     if (employeeOptions.status === "inactive" || employeeOptions.deleted == true) {
                                                    //         return employeeOptions
                                                    //     }
                                                    // }}
                                                    />
                                                    <span className='text-red w-100'>{errors?.area_manager?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >State Sales Manager Code & Surname & Name  <span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        {...register("state_manager", {
                                                            required: {
                                                                value: false,
                                                                message: "Field is required",
                                                            },
                                                        })}
                                                        components={customIndicator}
                                                        onChange={handleChangeStateManager}
                                                        options={employeeOptions}
                                                        value={selStateManagerValue === "" ? employeeOptions?.find((obj: any) => obj.value === selectedStateManagerValue) : selStateManagerValue}
                                                    //     isOptionDisabled={(employeeOptions: any) => {
                                                    //     if (employeeOptions.status === "inactive" || employeeOptions.deleted == true) {
                                                    //         return employeeOptions
                                                    //     }
                                                    // }}
                                                    />
                                                    <span className='text-red w-100'>{errors?.state_manager?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >Country Sales Manager Code & Surname & Name  <span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        {...register("sales_manager", {
                                                            required: {
                                                                value: false,
                                                                message: "Field is required",
                                                            },
                                                        })}
                                                        components={customIndicator}
                                                        onChange={handleChangeSalesManager}
                                                        options={employeeOptions}
                                                        value={selSalesManagerValue === "" ? employeeOptions?.find((obj: any) => obj.value === selectedSalesManagerValue) : selSalesManagerValue}
                                                    //     isOptionDisabled={(employeeOptions: any) => {
                                                    //     if (employeeOptions.status === "inactive" || employeeOptions.deleted == true) {
                                                    //         return employeeOptions
                                                    //     }
                                                    // }}
                                                    />
                                                    <span className='text-red w-100'>{errors?.sales_manager?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >General Sales Manager Code & Surname & Name  <span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        {...register("general_manager", {
                                                            required: {
                                                                value: false,
                                                                message: "Field is required",
                                                            },
                                                        })}
                                                        components={customIndicator}
                                                        onChange={handleChangeGeneralManager}
                                                        options={employeeOptions}
                                                        value={selGeneralManagerValue === "" ? employeeOptions?.find((obj: any) => obj.value === selectedGeneralManagerValue) : selGeneralManagerValue}
                                                    //     isOptionDisabled={(employeeOptions: any) => {
                                                    //     if (employeeOptions.status === "inactive" || employeeOptions.deleted == true) {
                                                    //         return employeeOptions
                                                    //     }
                                                    // }}
                                                    />
                                                    <span className='text-red w-100'>{errors?.general_manager?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >Owner Sales Deparment Code & Surname & Name  <span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        {...register("sheth_employee", {
                                                            required: {
                                                                value: false,
                                                                message: "Field is required",
                                                            },
                                                        })}
                                                        components={customIndicator}
                                                        onChange={handleChangeOwner}
                                                        options={employeeOptions}
                                                        value={selOwnerValue === "" ? employeeOptions?.find((obj: any) => obj.value === selectedOwnerValue) : selOwnerValue}
                                                    //     isOptionDisabled={(employeeOptions: any) => {
                                                    //     if (employeeOptions.status === "inactive" || employeeOptions.deleted == true) {
                                                    //         return employeeOptions
                                                    //     }
                                                    // }}
                                                    />
                                                    <span className='text-red w-100'>{errors?.sheth_employee?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >Owner All Management Code & Surname & Name  <span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        {...register("owner_all_management_code", {
                                                            required: {
                                                                value: true,
                                                                message: "Field is required",
                                                            },
                                                        })}
                                                        onChange={handleChangeOwnerAll}
                                                        options={employeeOptions}
                                                        value={selOwnerAllValue === "" ? employeeOptions?.find((obj: any) => obj.value === selectedOwnerAllValue) : selOwnerAllValue}
                                                        components={customIndicator}
                                                    //     isOptionDisabled={(employeeOptions: any) => {
                                                    //     if (employeeOptions.status === "inactive" || employeeOptions.deleted == true) {
                                                    //         return employeeOptions
                                                    //     }
                                                    // }}
                                                    />
                                                    <span className='text-red w-100'>{errors?.owner_all_management_code?.message}</span>

                                                </div>
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label >Responsible Person</label>
                                                    <select className="form-select" {...register("seven", { required: { value: true, message: "Field is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Hemal</option>
                                                        <option value="inactive">Mann</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.seven?.message}</span>

                                                </div>
                                             */}



                                                <div className="col-sm-3 mt-2">
                                                    <label >Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Field is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >Zone Code</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" placeholder="Zone Code" {...register("code", { required: { value: false, message: "Field is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                        onClick={handleSubmit(onSubmit)}
                                                    >Submit</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listZone")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
            <City_Model refetchForm={cityRefetch} open={openCity} setOpen={setOpenCity} setSubmitted={setSubmittedCity} />
            <Employee_Model refetchForm={EmployeeRefetch} open={openCityEmployee} setOpen={setOpenCityEmployee} setSubmitted={setSubmittedCityEmployee} />
            <Employee_Model refetchForm={EmployeeRefetch} open={openAreaEmployee} setOpen={setOpenAreaEmployee} setSubmitted={setSubmittedAreaEmployee} />
            <Employee_Model refetchForm={EmployeeRefetch} open={openStateEmployee} setOpen={setOpenStateEmployee} setSubmitted={setSubmittedStateEmployee} />
            <Employee_Model refetchForm={EmployeeRefetch} open={openCountryEmployee} setOpen={setOpenCountryEmployee} setSubmitted={setSubmittedCountryEmployee} />
            <Employee_Model refetchForm={EmployeeRefetch} open={openGeneralEmployee} setOpen={setOpenGeneralEmployee} setSubmitted={setSubmittedGeneralEmployee} />
            <Employee_Model refetchForm={EmployeeRefetch} open={openOwnerEmployee} setOpen={setOpenOwnerEmployee} setSubmitted={setSubmittedOwnerEmployee} />
            <Employee_Model refetchForm={EmployeeRefetch} open={openOwnerAllEmployee} setOpen={setOpenOwnerAllEmployee} setSubmitted={setSubmittedOwnerAllEmployee} />

            <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                                {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>
                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>
                </Modal.Footer>
            </Modal>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />
        </div>
    )
}

export default Edit_Zone