import React, { useEffect, useRef, useState } from 'react'
import Button from "react-bootstrap/esm/Button";
import { useCreate } from '../../hooks/Api';
import { Alert } from "../../components/Model/Model";
import { useForm } from 'react-hook-form';
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from '../focus';
import Select from "react-select"
import { useNavigate } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';

import {
    useFetchAccountDropDown, useFetchCavityNameDropDown,
    useFetchDesignationDropDown, useFetchEmployeeTypeDropDown,
    useFetchMouldDropDown, useFetchSalaryTypeDropDown, useFetchCityDropDown,
    useFetchTitleDropDown, useFetchCasteDropDown, useFetchBloodGroupDropDown,
    useFetchWeeklyOffDropDown, useFetchAdministrationTypeDropDown, useFetchEmployeeDropDown, useFetchDepartmentDropDown, useFetchPersonTypeDropDown
} from "../../hooks/DropDown_Api";
import moment from "moment";
import { AiOutlinePlus } from "react-icons/ai";
import DatePicker from "antd/es/date-picker";

import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";

import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'


import Modal from "react-bootstrap/Modal";
import Actions, { Deleteforce, Deletesingle } from "../../components/Cell/Actions";
import { useDelete, useDeletemforce } from "../../hooks/delete";
import Custom_Actions_Buttons from "../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../hooks/deletemultiple";
import { SimpleInput_Edit } from "../../components/Dynamic_Form_Models/SimpleInput_Edit";

const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}

interface Option {
    readonly label: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    name: label,
    status: "active",
});


const ntc = require('@yatiac/name-that-color');
const toHex = require('colornames')


export const Employee_Model = ({ refetchForm, open, setOpen, setSubmitted }: any) => {
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()
    const [logo, setlogo] = useState(null)
    const [logoPdf, setlogoPdf] = useState(null)


    // const [logo, setlogo] = useState(null)
    // const [logoPdf, setlogoPdf] = useState(null)
    const [agreement, setAgreement] = useState(null)
    const [EmployeeId, setEmployeeId] = useState("no")
    const [Married, setMarried] = useState("Single")
    const [isLoadingValue, setIsLoadingValue] = useState(false);
    const [clear, setClear] = useState(null)
    const [submittedValue, setSubmittedValue] = useState(false)
    const [submittedDesignation, setSubmittedDesignation] = useState(false)
    const [optionsDepartment, setOptionsDepartment]: any = useState([])
    const [optionsAccount, setOptionsAccount]: any = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [forDepartmentValue, setForDepartmentValue]: any = useState([])
    const [forAccountValue, setForAccountValue]: any = useState([])
    const [forDesignationValue, setForDesignationValue]: any = useState([])
    const [options, setOptions] = useState([])

    // const onLogoChange = (e:any) => {
    //     setlogo(e.target.files[0].name)
    // }

    // const onLogoPDFChange = (e:any) => {
    //     setlogoPdf(e.target.files[0].name)
    // }

    const { register: register, handleSubmit: handleSubmit, formState: { errors }, reset, setValue }: any = useForm({
        mode: "onChange"
    });


    const apiname = process.env.REACT_APP_PERSON_EMPLOYEE_API?.toString()


    const { mutate: brandPost, data, isSuccess, error, isError }: any = useCreate(apiname)

    const onEmployeeChange = (e: any) => {
        setEmployeeId(e.target.value)
        setValue("employee_status", e.target.value)
    }
    const onLogoChange = (e: any) => {
        setlogo(e.target.files[0].name)
    }

    const onLogoPDFChange = (e: any) => {
        setlogoPdf(e.target.files[0].name)
    }

    const onAgreementChange = (e: any) => {
        setAgreement(e.target.files[0].name)
    }





    const { data: departmentData, refetch: departmentRefetch } = useFetchDepartmentDropDown()

    useEffect(() => {
        setOptionsDepartment(departmentData)
    }, [departmentData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            departmentRefetch()
        }
    }, [])


    const [optionsDesignation, setOptionsDesignation]: any = useState([])


    const { data: designationData, refetch: designationRefetch } = useFetchDesignationDropDown()

    useEffect(() => {
        setOptionsDesignation(designationData)
    }, [designationData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            designationRefetch()
        }
    }, [])










    const handleChangeDepartment = (e: any) => {
        setValue("department", e?.value)
        setForDepartmentValue(e)
        // setForDepartmentValue({ label: e?.label, value: e?.value })
    }
    const { data: accountData, refetch: accountRefetch } = useFetchAccountDropDown()

    useEffect(() => {
        setOptionsAccount(accountData)
    }, [accountData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            accountRefetch()
        }
    }, [])










    const handleChangeAccount = (e: any) => {
        setValue("group", e?.value)
        setForAccountValue(e)
        // setForDepartmentValue({ label: e?.label, value: e?.value })
    }

    const handleChangeDesignation = (e: any) => {
        setValue("designation", e.value)
        setForDesignationValue(e)
    }

    const [forCityValue, setForCityValue]: any = useState([])
    const [cityOptions, setCityOptions]: any = useState([])

    const { data: cityData, refetch: cityRefetch } = useFetchCityDropDown()

    // useEffect(() => {
    //     setCityOptions(cityData)
    // }, [cityData])

    useEffect(() => {
        let temp = cityData?.map((e: any) => {
            return (
                {
                    value: e?.value,
                    label: `${e?.city?e?.city:e?.village}::${e?.district}`,

                }
            )
        })
        setCityOptions(temp)
    }, [cityData])
    const handleChangeCity = (e: any) => {
        setForCityValue(e)
        setValue("city_name_and_distict_name", e?.value)

        // setValue("state",e?.stateID)
        // setValue("country",e?.countryID)
        // setValue("district",e?.districtID)


    }



    const [EmployeeValue, setEmployeeValue]: any = useState()
    const [optionsEmployee, setOptionsEmployee] = useState([])
    const [showOtherRef,setShowOtherRef]:any =useState(false)

    const { data: EmployeeData, refetch: EmployeeRefetch } = useFetchEmployeeDropDown()
console.log(EmployeeData);

    useEffect(() => {
        if (EmployeeData){
            let temp_employee_data:any = EmployeeData?.map((e: any) => e)
            temp_employee_data?.unshift({label:'Other',value:'other'})
            setOptionsEmployee(temp_employee_data)
        }
    }, [EmployeeData])


    const handleChangeEmployee = (e: any) => {
        if(e.value==='other'){
            setEmployeeValue(e)
            setShowOtherRef(true)
            setValue("reference",'')
        }
        else{
            setEmployeeValue(e)
            setShowOtherRef(false)
            // setShowOtherRef(null)
            setValue('other_reference','')
            setValue("reference", `${e?.value}`)
        }
    }
   
    const apinameForSalaryType = process.env.REACT_APP_EMPLOYEE_SALARY_TYPE_API?.toString()
    const { mutate: mutateSalaryType, data: dataSalaryType, isError: SalaryTypeIsError, isLoading: SalaryTypeLoading, isSuccess: SalaryTypeSuccess, error: SalaryTypeError }: any = useCreate(apinameForSalaryType)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueSalaryType, setValueSalaryType]: any = useState();
    const [optionsSalaryType, setOptionsSalaryType]: any = useState([]);
    const [valueSalaryTypeCreatable, setValueSalaryTypeCreatable] = useState<Option | null>();

    const { data: SalaryTypeData, refetch: SalaryTypeRefetch } = useFetchSalaryTypeDropDown()

    useEffect(() => {
        setOptionsSalaryType(SalaryTypeData)
    }, [SalaryTypeData])





    const handleChangeSalaryType = (e: any) => {

        SalaryTypeRefetch()

        setApiName(apinameForSalaryType)
        setDeleteApiName(apinameForSalaryType)
        setEditId(e?.value)
        setEditData(SalaryTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueSalaryType(e)

        setValue("salary_type", `${e?.value}`)
        setValueSalaryTypeCreatable(null)
    }





    const handleCreateSalaryType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutateSalaryType({ name: newOption.name, status: newOption.status })
            setOptionsSalaryType((prev: any) => [...prev, newOption]);
            setValueSalaryTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (SalaryTypeSuccess && optionsSalaryType) {
                await SalaryTypeRefetch();
                setValue("salary_type", SalaryTypeData[0]?.value)
            }

        })();

        return () => {
        };
    }, [SalaryTypeSuccess, SalaryTypeData]);



    const apinameForTitle = process.env.REACT_APP_EMPLOYEE_TITLE_API?.toString()
    const { mutate: mutateTitle, data: dataTitle, isError: TitleIsError, isLoading: TitleLoading, isSuccess: TitleSuccess, error: TitleError }: any = useCreate(apinameForTitle)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueTitle, setValueTitle]: any = useState();
    const [optionsTitle, setOptionsTitle]: any = useState([]);
    const [valueTitleCreatable, setValueTitleCreatable] = useState<Option | null>();

    const { data: TitleData, refetch: TitleRefetch } = useFetchTitleDropDown()

    useEffect(() => {
        setOptionsTitle(TitleData)
    }, [TitleData])





    const handleChangeTitle = (e: any) => {

        TitleRefetch()

        setApiName(apinameForTitle)
        setDeleteApiName(apinameForTitle)
        setEditId(e?.value)
        setEditData(TitleData)
        setRenderKey(prevKey => prevKey + 1);

        setValueTitle(e)

        setValue("title", `${e?.value}`)
        setValueTitleCreatable(null)
    }





    const handleCreateTitle = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutateTitle({ name: newOption.name, status: newOption.status })
            setOptionsTitle((prev: any) => [...prev, newOption]);
            setValueTitleCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (TitleSuccess && optionsTitle) {
                await TitleRefetch();
                setValue("title", TitleData[0]?.value)
            }

        })();

        return () => {
        };
    }, [TitleSuccess, TitleData]);


    const apinameForWeeklyOff = process.env.REACT_APP_EMPLOYEE_WEEKLY_OFF_API?.toString()
    const { mutate: mutateWeeklyOff, data: dataWeeklyOff, isError: WeeklyOffIsError, isLoading: WeeklyOffLoading, isSuccess: WeeklyOffSuccess, error: WeeklyOffError }: any = useCreate(apinameForWeeklyOff)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueWeeklyOff, setValueWeeklyOff]: any = useState();
    const [optionsWeeklyOff, setOptionsWeeklyOff]: any = useState([]);
    const [valueWeeklyOffCreatable, setValueWeeklyOffCreatable] = useState<Option | null>();

    const { data: WeeklyOffData, refetch: WeeklyOffRefetch } = useFetchWeeklyOffDropDown()

    useEffect(() => {
        setOptionsWeeklyOff(WeeklyOffData)
    }, [WeeklyOffData])





    const handleChangeWeeklyOff = (e: any) => {

        WeeklyOffRefetch()

        setApiName(apinameForWeeklyOff)
        setDeleteApiName(apinameForWeeklyOff)
        setEditId(e?.value)
        setEditData(WeeklyOffData)
        setRenderKey(prevKey => prevKey + 1);

        setValueWeeklyOff(e)

        setValue("weekly_off", `${e?.value}`)
        setValueWeeklyOffCreatable(null)
    }





    const handleCreateWeeklyOff = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutateWeeklyOff({ name: newOption.name, status: newOption.status })
            setOptionsWeeklyOff((prev: any) => [...prev, newOption]);
            setValueWeeklyOffCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (WeeklyOffSuccess && optionsWeeklyOff) {
                await WeeklyOffRefetch();
                setValue("weekly_off", WeeklyOffData[0]?.value)
            }

        })();

        return () => {
        };
    }, [WeeklyOffSuccess, WeeklyOffData]);

    const apinameForAdministrationType = process.env.REACT_APP_EMPLOYEE_ADMINISTRATION_API?.toString()
    const { mutate: mutateAdministrationType, data: dataAdministrationType, isError: AdministrationTypeIsError, isLoading: AdministrationTypeLoading, isSuccess: AdministrationTypeSuccess, error: AdministrationTypeError }: any = useCreate(apinameForAdministrationType)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueAdministrationType, setValueAdministrationType]: any = useState();
    const [optionsAdministrationType, setOptionsAdministrationType]: any = useState([]);
    const [valueAdministrationTypeCreatable, setValueAdministrationTypeCreatable] = useState<Option | null>();

    const { data: AdministrationTypeData, refetch: AdministrationTypeRefetch } = useFetchAdministrationTypeDropDown()

    useEffect(() => {
        setOptionsAdministrationType(AdministrationTypeData)
    }, [AdministrationTypeData])





    const handleChangeAdministrationType = (e: any) => {

        AdministrationTypeRefetch()

        setApiName(apinameForAdministrationType)
        setDeleteApiName(apinameForAdministrationType)
        setEditId(e?.value)
        setEditData(AdministrationTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueAdministrationType(e)

        setValue("administration_type", `${e?.value}`)
        setValueAdministrationTypeCreatable(null)
    }





    const handleCreateAdministrationType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutateAdministrationType({ name: newOption.name, status: newOption.status })
            setOptionsAdministrationType((prev: any) => [...prev, newOption]);
            setValueAdministrationTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (AdministrationTypeSuccess && optionsAdministrationType) {
                await AdministrationTypeRefetch();
                setValue("administration_type", AdministrationTypeData[0]?.value)
            }

        })();

        return () => {
        };
    }, [AdministrationTypeSuccess, AdministrationTypeData]);

    const apinameForPersonType = process.env.REACT_APP_EMPLOYEE_ADMINISTRATION_API?.toString()
    const { mutate: mutatePersonType, data: dataCavity, isError: cavityIsError, isLoading: cavityLoading, isSuccess: personTypeSuccess, error: cavityError }: any = useCreate(apinameForPersonType)

   const [valuePersonType, setValuePersonType]: any = useState();
    const [optionsPersonType, setOptionsPersonType]: any = useState([])
    const [valuePersonTypeCreatable, setValuePersonTypeCreatable] = useState<Option | null>();

    const { data: personTypeData, refetch: personTypeRefetch } = useFetchPersonTypeDropDown()

    useEffect(() => {
        setOptionsPersonType(personTypeData)
    }, [personTypeData])





    const handleChangePersonType = (e: any) => {

        personTypeRefetch()

        setApiName(apinameForPersonType)
        setDeleteApiName(apinameForPersonType)
        setEditId(e?.value)
        setEditData(personTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValuePersonType(e)

        setValue("person_type", `${e?.value}`)
        setValuePersonTypeCreatable(null)
    }





    const handleCreatePersonType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutatePersonType({ name: newOption.name, status: newOption.status })
            setOptionsPersonType((prev: any) => [...prev, newOption]);
            setValuePersonTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (personTypeSuccess && optionsPersonType) {
                await personTypeRefetch();
                setValue("person_type", personTypeData[0]?.value)
            }

        })();

        return () => {
        };
    }, [personTypeSuccess, personTypeData]);
   


    // const [cavityNameValue,setCavityNameValue]:any = useState()
    // const [valuePersonType, setValuePersonType]: any = useState();

    // const [optionsPersonType, setOptionsPersonType]: any = useState([])

    // // const [isLoadingValue, setIsLoadingValue] = useState(false);

    // const [valuePersonTypeCreatable, setValuePersonTypeCreatable] = useState<Option | null>();

    // const apinameForPersonType = process.env.REACT_APP_PERSON_TYPE_API?.toString()

    // const { mutate: mutatePersonType, data: dataCavity, isError: cavityIsError, isLoading: cavityLoading, isSuccess: personTypeSuccess, error: cavityError }: any = useCreate(apinameForPersonType)


    // const { data: personTypeData, refetch: personTypeRefetch } = useFetchPersonTypeDropDown()

    // useEffect(() => {
    //     setOptionsPersonType(personTypeData)
    // }, [personTypeData])



    // const [personTypeLabel, setPersonTypeLabel] = useState('')

    // const handleChangePersonType = (e: any) => {

    //     console.log('e', e)
    //     setPersonTypeLabel(e?.label)
    //     setValue("person_type", e?.value)
    //     setValuePersonTypeCreatable(null)
    //     setValuePersonType(e)
    //     localStorage.removeItem('Add Data')

    //     if (e === null) { // Check if the event is a clear event
    //         handleOnPersonTypeClear();
    //     } else {
    //         // Existing handleChangePersonType logic
    //     }

    // }

    
    // const handleCreatePersonType = (inputValue: string) => {

    //     inputValue = inputValue.toLowerCase()
    //         .split(' ')
    //         .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
    //         .join(' ');
    //     setIsLoadingValue(true);
    //     setTimeout(() => {
    //         const newOption = createOptions(inputValue);
    //         setIsLoadingValue(false);


    //         mutatePersonType({ name: newOption.name, status: newOption.status })
    //         setOptionsPersonType((prev: any) => [...prev, newOption]);
    //         setValuePersonTypeCreatable(newOption);
    //     }, 0);


    // };

    



    const handleUpper = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }

    // const changeicon = () => {
    //     if (iconChange == "password") {
    //         setIconChange("text")
    //     }
    //     else {
    //         setIconChange("password")
    //     }
    // }
    // const changeconfirmicon = () => {
    //     if (confirmiconChange == "password") {
    //         setconfirmIconChange("text")
    //     }
    //     else {
    //         setconfirmIconChange("password")
    //     }
    // }
    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
    // const disabledDate: RangePickerProps['disabledDate'] = (current) => {

    //     return current && current < dayjs().startOf('year');
    // };


    const [birthDate, setBirthDate]: any = useState()

    const handleBirthDate = (e: any) => {


        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let birth_date = `${date}-${month}-${year}`
        setValue("birth_date", birth_date)

        setBirthDate(e)
    }


    const [age, setAge]: any = useState(0)


    useEffect(() => {

        if (birthDate != null || birthDate) {

            // if(birthDate == null){

            // }

            let todayDate = new Date().toISOString().split("T")[0]

            if (birthDate?.$D === 31) {
                var bDate = moment([birthDate?.$y, birthDate?.$M, birthDate?.$D]);
            } else {
                var bDate = moment([birthDate?.$y, birthDate?.$M + 1, birthDate?.$D]);
            }



            var currentDate = moment([todayDate.split("-")[0], todayDate.split("-")[1], todayDate.split("-")[2]]);

            setAge(currentDate.diff(bDate, 'years'))
            setValue("age", age)





        } else {
            setAge(0)
            setValue("age", 0)
        }



    }, [birthDate, age])



    const onMarriedChange = (e: any) => {
        setMarried(e.target.value)
    }

    const [JoiningDate, setJoiningDate]: any = useState("")


    const handleJoiningDate = (e: any) => {

        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let joining_date = `${date}-${month}-${year}`
        setValue("joining_date", joining_date)
        setJoiningDate(e)

    }

    const [aniversaryDate, setAnniversaryDate]: any = useState("")

    const handleAnniversaryDate = (e: any) => {

        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let anniversary_date = `${date}-${month}-${year}`
        setValue("anniversary_date", anniversary_date)
        setAnniversaryDate(e)

    }



    useEffect(() => {
        if (isSuccess) {
            setSubmitted(true)
            setOpen(false)
            reset()
            setForGSTValue(null)
        }
        refetchForm()
    }, [isSuccess, data])



    const [forGST, setForGSTValue]: any = useState([])


    const handleReset = () => {
        reset()
        setForGSTValue(null)
    }

    const onSubmit = (branddetail: any) => {
        const formData = new FormData();

        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else if (key === "logo" || key === "logo_pdf") {
                if (typeof value === 'object' && value !== null && formvalue.length > 0) {
                    formData.append(key, formvalue[0])
                }
                else {
                    formData.append(key, "")
                }
            }




        }
        brandPost(formData);

    }




    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);



    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
    } = useDelete();
    const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
    } = useDeletemforce();




    const handleRefetch = () => {
        if (apiName === apinameForAdministrationType) {
            AdministrationTypeRefetch()
        }
        if (apiName === apinameForWeeklyOff) {
            WeeklyOffRefetch()
        }
        if (apiName === apinameForTitle) {
            TitleRefetch()
        }
        if (apiName === apinameForSalaryType) {
            SalaryTypeRefetch()
        }



        // else if(apiName === apinameForTaluka){
        //   TalukaRefetch()
        // }
    }


    useEffect(() => {
        if (OpenEditDropDownModel && valueAdministrationType && apiName === apinameForAdministrationType) {
            setValueAdministrationType(null)
            setValueAdministrationTypeCreatable(null)
        }
        if (OpenEditDropDownModel && valueWeeklyOff && apiName === apinameForWeeklyOff) {
            setValueWeeklyOff(null)
            setValueWeeklyOffCreatable(null)
        }
        if (OpenEditDropDownModel && valueTitle && apiName === apinameForTitle) {
            setValueTitle(null)
            setValueTitleCreatable(null)
        }
        if (OpenEditDropDownModel && valueSalaryType && apiName === apinameForSalaryType) {
            setValueSalaryType(null)
            setValueSalaryTypeCreatable(null)
        }






        if (deletesucess && apiName === apinameForAdministrationType) {
            setValueAdministrationType(null)
            setValueAdministrationTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameForWeeklyOff) {
            setValueWeeklyOff(null)
            setValueWeeklyOffCreatable(null)
        }
        if (deletesucess && apiName === apinameForTitle) {
            setValueTitle(null)
            setValueTitleCreatable(null)
        }
        if (deletesucess && apiName === apinameForSalaryType) {
            setValueSalaryType(null)
            setValueSalaryTypeCreatable(null)
        }

    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (


        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
            <span>{label}</span>
            <div style={{ float: "right", justifyContent: "end" }}>



                {isExist ?

                    <Custom_Actions_Buttons value={value}
                        apiname={deleteApiName}
                        mutate={singleDelete}
                        // masterName={addButtonName}
                        deleteid={deleteid}
                        handleDeletedforce={handleDeletedforce}
                        closereconfirmdelete={closereconfirmdelete}
                        deletemodalask={deletemodalask}
                        setEditOpen={setOpenEditDropDownModel}
                    // permissionClass={permissionClass}
                    />
                    : ""}

            </div>
        </div>


    )


    const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
    }



    useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
        return () => {
            setDeleteid(undefined);
        };
    }, []);
    const handleConfirmDelete = () => {
        setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
    };

    const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
    };
    useEffect(() => {
        if (forcefullysuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullyerror) {
            setShow(true);
            setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullydeleteerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
    ]);

    useEffect(() => {
        if (deleteMultipleSuccess) {
            const showdepenedancymsg = deleteMultiple?.data?.data;
            const dependacyfound = showdepenedancymsg?.filter((color: any) => {
                if (
                    color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
                ) {
                    return color;
                }
            });
            if (dependacyfound?.length === deleteMultipleid?.length) {
                deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
            } else {
                setConfirmforcemodal(true);
            }
        } else if (deleteMultipleIsError) {
            setShow(true);
            setshowmessage("danger");
        } else if (deletesucess) {
            if (deletedata.data?.data?.length > 0) {
                setConfirmforcemodal(true);
            } else if (deleteid) {
                setShow(true);
                setshowmessage("success");
                deleteforce({ deleteid, apiname: deleteApiName });
            }
        }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

    const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
    };





    return (
        <>
            <Modal

                size='xl'

                show={open} onHide={() => {
                    setShow(false)
                    reset()
                    setOpen(false)
                }} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title style={{fontSize:'16px'}}>Employee</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>

                    <form
                        ref={focus}
                        onKeyUp={event => onEnterKey(event)}
                        onSubmit={handleSubmit(onSubmit)}>
                        <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                            {show && showmessage === "success" ? <Alert color={showmessage} message={"Successfully Added"} /> : ""}
                            {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}

                            <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                <div className="col-md-12">
                                    <div className="row">
                                    <div className="col-sm-3 mt-2">
                                        <label >Person Type <span style={{ color: 'red' }}>*</span></label>
                                        <CreatableSelect
                                            {...register("person_type", { required: { value: true, message: "Field is required" } })}
                                            isClearable={true}
                                            
                                            components={customCreatableIndicator}
                                            placeholder={'Person Type'}
                                            options={optionsPersonType}
                                            onCreateOption={handleCreatePersonType}
                                            onChange={handleChangePersonType}
                                           
                                            value={valuePersonTypeCreatable==null?valuePersonType:valuePersonTypeCreatable}
                                            // value={valuePersonTypeCreatable == null ? valuePersonType == null ? optionsPersonType?.filter((obj: any) => obj.value === localStorage.getItem('Person Type In Add')) : valuePersonType : valuePersonTypeCreatable}
                                            // value={valuePersonTypeCreatable == null ? valuePersonType == null ? optionsPersonType?.filter((obj: any) => obj.value === selectedId) : valuePersonType : valuePersonTypeCreatable}
                                                isOptionDisabled={(optionsPersonType: any) => {
                                                                    if (optionsPersonType?.label != "Employee") {
                                                                        return optionsPersonType
                                                                    }
                                                                }}
                                        />
                                        <span className='text-red w-100'>{errors?.person_type?.message}</span>

                                    </div>
                                    <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Account Group <span style={{ color: "red" }}>*</span></label>
                                                    <div className="d-flex row" >
                                                    <Select
                                                        {...register("group", { required: { value: true, message: "Account Group is required" } })}
                                                        placeholder={'Account Group'}
                                                        onChange={handleChangeAccount}
                                                        value={forAccountValue}
                                                        options={optionsAccount}
                                                        maxMenuHeight={150}
                                                        components={customIndicator}
                                                        
                                                        />
                                                      
                                                        </div>
                                                    <span className='text-red w-100'>{errors?.group?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Salary Type <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueSalaryType(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("salary_type", { required: { value: true, message: "Salary Type is required" } })}
                                                        placeholder={'Salary Type'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsSalaryType}
                                                        onMenuOpen={() => SalaryTypeRefetch()}
                                                        onCreateOption={handleCreateSalaryType}
                                                        onChange={handleChangeSalaryType}
                                                        value={valueSalaryTypeCreatable == null ? valueSalaryType : valueSalaryTypeCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.salary_type?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Gender <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select"  {...register("gender", { required: { value: true, message: "Gender is required" } })}>
                                                        <option value={""} selected >Select Any</option>
                                                        <option value="Male" >Male</option>
                                                        <option value="Female">Female</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.gender?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Title <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueTitle(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("title", { required: { value: true, message: "Title is required" } })}
                                                        placeholder={'Title'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsTitle}
                                                        onMenuOpen={() => TitleRefetch()}
                                                        onCreateOption={handleCreateTitle}
                                                        onChange={handleChangeTitle}
                                                        value={valueTitleCreatable == null ? valueTitle : valueTitleCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.title?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Employee First Name <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Employee First Name" {...register("name", { onChange: handleUpper, required: { value: true, message: "Employee First Name is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.name?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Employee Middle Name <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Employee Middle Name" {...register("father_name", { onChange: handleUpper, required: { value: true, message: "Employee Middle Name is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.father_name?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Employee Last Name <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Employee Last Name" {...register("surname", { onChange: handleUpper, required: { value: true, message: "Employee Last Name is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.surname?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">City Name & District Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("city_name_and_distict_name", { required: { value: true, message: "City Name & District Name is required" } })}
                                                            onChange={handleChangeCity}
                                                            options={cityOptions}
                                                            components={customIndicator}
                                                            value={forCityValue}
                                                            maxMenuHeight={150}
                                                            // isOptionDisabled={(options:any)=>
                                                            //     {
                                                            //         if(options.status === "inactive" || options.deleted == true){
                                                            //         return options
                                                            //         }
                                                            //        }}

                                                        />
                                                        
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.city_name_and_distict_name?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name_and_grade">Department <span style={{ color: "red" }}>*</span></label>
                                                    <div className="d-flex row">
                                                        <Select
                                                            {...register("department", { required: { value: true, message: "Department is required" } })}
                                                            placeholder={'Department'}
                                                            onChange={handleChangeDepartment}
                                                            value={forDepartmentValue}
                                                            options={optionsDepartment}
                                                            components={customIndicator}
                                                            maxMenuHeight={150}
                                                        // isMulti
                                                        />
                                                        
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.department?.message}</span>

                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label >Designation <span style={{ color: "red" }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("designation", { required: { value: true, message: "Designation is required" } })}
                                                            placeholder={'Designation'}
                                                            onChange={handleChangeDesignation}
                                                            value={forDesignationValue}
                                                            options={optionsDesignation}
                                                            components={customIndicator}
                                                            maxMenuHeight={150}
                                                            
                                                        />
                                                        
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.designation?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Qualification <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Qualification" {...register("qualification", { onChange: handleUpper, required: { value: true, message: "Qualification is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.qualification?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Weekly Off Day Name <span style={{ color: "red" }}>*</span></label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueWeeklyOff(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("weekly_off", { required: { value: true, message: "Weekly Off Day Name is required" } })}
                                                        placeholder={'Weekly Off Day Name'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsWeeklyOff}
                                                        onMenuOpen={() => WeeklyOffRefetch()}
                                                        onCreateOption={handleCreateWeeklyOff}
                                                        onChange={handleChangeWeeklyOff}
                                                        value={valueWeeklyOffCreatable == null ? valueWeeklyOff : valueWeeklyOffCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.weekly_off?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Administration Type <span style={{ color: "red" }}>*</span></label>
                                                    
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueAdministrationType(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("administration_type", { required: { value: true, message: "Administration Type is required" } })}
                                                        placeholder={'Administration Type'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsAdministrationType}
                                                        onMenuOpen={() => AdministrationTypeRefetch()}
                                                        onCreateOption={handleCreateAdministrationType}
                                                        onChange={handleChangeAdministrationType}
                                                        value={valueAdministrationTypeCreatable == null ? valueAdministrationType : valueAdministrationTypeCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.administration_type?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name_and_grade">Reference By </label>
                                                    <div className="d-flex row">
                                                        <Select
                                                            {...register("reference", { required: { value: false, message: "Reference By is required" } })}
                                                            placeholder={'Reference By'}
                                                            onChange={handleChangeEmployee}
                                                            value={EmployeeValue}
                                                            options={optionsEmployee}
                                                            components={customIndicator}
                                                            maxMenuHeight={150}
                                                        />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.employee?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Other Reference By </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Other Reference By"
                                                        disabled={!showOtherRef}
                                                        {...register("other_reference", { onChange: handleUpper, required: { value: false, message: "Other Reference By is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.other_reference?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Remark </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Remark" {...register("re_mark", { onChange: handleUpper, required: { value: false, message: "Remark is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.re_mark?.message}</span>
                                                </div>

                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Status <span style={{ color: "red" }}>*</span></label>
                                            <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>
                                                <option value={""} selected>Select Any</option>
                                                <option value="active">Active</option>
                                                <option value="inactive">Inactive</option>
                                            </select>
                                            <span className='text-red w-100'>{errors?.status?.message}</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex">
                                <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                    <div className="row d-flex ">
                                        <div className="col-sm-3 mt-2 image-upload">
                                            <label htmlFor="exampleInputEmail1" className="col-12">Employee Image</label>
                                            <label style={{ cursor: "pointer" }}>
                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                <input data-required="image" type="file" id="file_upload" accept="image/*" className="image-input" data-traget-resolution="image_resolution"  {...register("image", { onChange: onLogoChange, required: false })} hidden />
                                            </label>
                                            <p>{logo}</p>
                                        </div>
                                        <div className="col-sm-3 mt-2 image-upload">
                                            <label htmlFor="exampleInputEmail1" className="col-12">Document PDF</label>
                                            <label style={{ cursor: "pointer" }}>
                                                <img src={pdfLogo} alt='' className="uploaded-image" />
                                                <input data-required="image" type="file" id="file_upload" accept="application/pdf" className="image-input" data-traget-resolution="image_resolution" {...register("document_pdf", { onChange: onLogoPDFChange, required: false })} hidden />
                                            </label>
                                            <p>{logoPdf}</p>
                                        </div>
                                        <div className="col-sm-3 mt-2 image-upload">
                                            <label htmlFor="exampleInputEmail1" className="col-12">Agreement PDF</label>
                                            <label style={{ cursor: "pointer" }}>
                                                <img src={pdfLogo} alt='' className="uploaded-image" />
                                                <input data-required="image" type="file" id="file_upload" accept="application/pdf" className="image-input" data-traget-resolution="image_resolution" {...register("agreement_pdf", { onChange: onAgreementChange, required: false })} hidden />
                                            </label>
                                            <p>{agreement}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                     {/* Edit Modal HTML */}
            <Modal
                show={deleteConfirm}
                onHide={() => setDeleteConfirm(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete {""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {" "}
                        <span className="text-center">Sure you want to delete it?</span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setDeleteConfirm(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={handleMultipleDeleted}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Deleteforce
                key={renderKey}
                handleDeletedforce={handleDeletedforce}
                reconfirmdelete={confirmforcemodal}
                masterName={""}
                closereconfirmdelete={closereconfirmdelete}
                referance={
                    deletesucess
                        ? deletedata
                        : ""
                }
                apiname={apiName}
            />
            <Deletesingle
                key={renderKey}
                confirmmodal={deleteConfirmsingle}
                confirmmodalclose={confirmmodalclose}
                masterName={""}
                confirmmodalsubmit={confirmmodalsubmit}
                apiname={apiName}
            />

            <SimpleInput_Edit key={renderKey}
                refetchForm={handleRefetch}
                open={OpenEditDropDownModel}
                setOpen={setOpenEditDropDownModel}
                id={editId}
                // superAccountId={superAccountId} 
                // optionsSuperAccount={optionsSuperAccount}
                // isSuperAccount={isSuperAccount}
                apiname={apiName} />
                    </div>

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="info" className="text-white"
                        onClick={handleSubmit(onSubmit)}
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                    >
                        Submit
                    </Button>
                    <Button variant="info" className="text-white" style={{ borderColor: "blue", background: "blue", color: "white" }}
                        onClick={handleReset}
                    >
                        Reset
                    </Button>
                    <Button variant="secondary" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpen(false) }}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}



