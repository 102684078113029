
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../../components/Cell/Status";
import Actions from "../../../components/Cell/Actions";


interface Data {
  selection: any
 
  code:any,
  name: any,
  pincode:any ,
  city: any,
  staff_code_and_name:any,
  staff_qty: any,
  responsible_person: any,
  sheth_responsible_person: any,

  created_at:any
  created_by:any
  updated_by:any
  updated_at:any
  status: any;
  actions: any;
}


export const titleofheaders=[
  {
    id: `srno`,
    Header: `Sr No`,
  },
  {
    id: `code`,
    Header: "Warehouse Code",
    

  },
  {
    id: `name`,
    Header: "Warehouse Name",
    
  },
  {
    id: `city`,
    Header: "City Code & Name",
   
  },
  {
    id: `pincode`,
    Header: " City Pincode",
    
  },
  {
    id: `district`,
    Header: " City District",
  
  },
  {
    id: `state`,
    Header: " City State",
   
  },
  {
    id: `country`,
    Header: " City Country",
  
  },
  {
    id: `staff_code_and_name`,
    Header: "Staff Code & Name",
 
  },
  {
    id: `staff_qty`,
    Header: "Staff Qty",
 
  },
  {
    id: `responsible_person`,
    Header: "Responsible Person ",

  },
  {
    id: `sheth_responsible_person`,
    Header: "Responsible Sheth ",
  
  },
  {
    id: 'status',
    Header: 'Status',
  },
  {
    id: 'created_at',
    Header: `Created At`,
  },


  {
    id: 'created_by',
    Header: `Created By`,

 
  },
  {
    id: 'updated_at',
    Header: `Updated At`,

  },
  {
    id: 'updated_by',
    Header: `Updated By`,

  },
]


export const Columns_WareHouse: any[] = [



 
  
 
  
  {
    id: `srno`,
    Header: () => <div style={{display:"inline"}}>Sr.<br/>No</div>,
    sticky:"left",

  },
 
  {
    id: `code`,
    Header: () => <div style={{display:"inline"}}>Warehouse<br/>Code</div>,
    accessor: `code`,
    sticky:"left",

  },
  {
    id: `name`,
    Header: () => <div style={{display:"inline"}}>Warehouse<br/>Name</div>,
    accessor: `name`,

  },
  {
    id: `pincode`,
    Header: () => <div style={{display:"inline"}}>Pin Code<br/>Number</div>,
    accessor: `pincode`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.pincode?.pincode}
        </div>
      )
}
  },
  {
    id: `city`,
    Header: () => <div style={{display:"inline"}}>Warehouse City<br/>Code & Name</div>,
    accessor: `city`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {`${row.original.city?.code}::${row.original.city?.city_name?row.original.city?.city_name:row.original.city?.village_name}`}

        </div>
      )
}
  },
 
  {
    id: `district`,
    Header: () => <div style={{display:"inline"}}>Warehouse<br/>District</div>,
    accessor: `district`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.city?.district?.name}
        </div>
      )
}
  },
  {
    id: `state`,
    Header: () => <div style={{display:"inline"}}>Warehouse<br/>State</div>,
    accessor: `state`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.city?.state?.name}
        </div>
      )
}
  },
  {
    id: `country`,
    Header: () => <div style={{display:"inline"}}>Warehouse<br/>Country</div>,
    accessor: `country`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.city?.country?.name}
        </div>
      )
}
  },
  {
    id: `staff_code_and_name`,
    Header: () => <div style={{display:"inline"}}>Use Branch Staff<br/>Code & Name</div>,
    accessor: `staff_code_and_name`,
    Cell: ({ row }: any) => {
      let printingColor = row.original?.staff_code_and_name?.map((e: any) => {
        return e?.name
      })
      if (printingColor.length === 0) return <span style={{ color: 'red', fontSize: "20px" }}>****</span>;
      return printingColor?.join(",")

    }
//     Cell: ({ row }: any) => {
//       let color = row.original?.staff_code_and_name?.map((e:any)=>{
//         return e?.name
//       })
//       return(
//         <div>
//           {color?.join(",")}
//         </div>
//       )
// }
   
  },
  {
    id: `staff_qty`,
    Header: () => <div style={{display:"inline"}}>Use Branch <br/>Staff Qty</div>,
    accessor: `staff_qty`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original?.staff_qty?row.original?.staff_qty:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
        </div>
      )
}
  },
  {
    id: `responsible_person`,
    Header: () => <div style={{display:"inline"}}>Warehouse Responsible<br/>Person Code & Name</div>,
    accessor: `responsible_person`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.responsible_person?.name}
        </div>
      )
}
  },
  {
    id: `sheth_responsible_person`,
    Header: () => <div style={{display:"inline"}}>Warehouse Responsible<br/>Owner Code & Name</div>,
    accessor: `sheth_responsible_person`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.sheth_responsible_person?.name}
        </div>
      )
}
  },
 
  {
    id: 'remark',
    Header: 'Re-Mark',
    accessor:'remark',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.remark?row.original.remark:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
        </div>
      )
}
 
  },
 
 

  {
    id: 'status',
    Header: 'Status',
    accessor:'status',

 
  },


  
  {
    id: 'created_at',
    Header: () => <div style={{display:"inline"}}>Created<br/>At</div>,
    accessor:'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{display:"inline"}}>Created<br/>By</div>,
    accessor:'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username

 
  },
  {
    id: 'updated_at',
    Header: () => <div style={{display:"inline"}}>Updated<br/>At</div>,
    accessor:'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{display:"inline"}}>Updated<br/>By</div>,
    accessor:'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },



  {
    id: 'Actions',
    Header: 'Actions',

  
  },
  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_WareHouse, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows} = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_WAREHOUSE_API}/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



