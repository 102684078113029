import { createSlice } from '@reduxjs/toolkit'

export const priceListCalculation = createSlice({
  name: 'priceListCalculation',
  initialState: {
    products : [
        {
            id: 0,
            change_carton_basic_rate:0,
            change_carton_rate:0,
            change_stick_rate:0,
            change_one_kg_rate:0,
            new_carton_basic_rate:0,
            new_carton_rate:0,
            new_stick_rate:0,
            new_one_kg_rate:0
        }
    ],
    price_action: "Up Price"
  },
  reducers: {
    setChange_carton_basic_rate: (state:any, action) => {
      let x = state.products?.map((e: any) => {
        if(e.id === action.payload.id){
            e.change_carton_basic_rate = action.payload.value
        }
        return e
      })
      state.products = x
    },
    setChange_carton_rate: (state, action) => {

        let x = state.products?.map((e: any) => {
            if(e.id === action.payload.id){
                e.change_carton_rate = action.payload.value
            }
            return e
          })
          state.products = x
      },
      setChange_stick_rate: (state, action) => {

        let x = state.products?.map((e: any) => {
            if(e.id === action.payload.id){
                e.change_stick_rate = action.payload.value
            }
            return e
          })
          state.products = x
      },
      setChange_one_kg_rate: (state, action) => {

        let x = state.products?.map((e: any) => {
            if(e.id === action.payload.id){
                e.change_one_kg_rate = action.payload.value
            }
            return e
          })
          state.products = x
      },
      setNew_carton_basic_rate: (state, action) => {

        let x = state.products?.map((e: any) => {
            if(e.id === action.payload.id){
                e.new_carton_basic_rate = action.payload.value
            }
            return e
          })
          state.products = x
      },
      setNew_carton_rate: (state, action) => {

        let x = state.products?.map((e: any) => {
            if(e.id === action.payload.id){
                e.new_carton_rate = action.payload.value
            }
            return e
          })
          state.products = x
      },
      setNew_stick_rate: (state, action) => {

        let x = state.products?.map((e: any) => {
            if(e.id === action.payload.id){
                e.new_stick_rate = action.payload.value
            }
            return e
          })
          state.products = x
      },
      setNew_one_kg_rate: (state, action) => {

        let x = state.products?.map((e: any) => {
            if(e.id === action.payload.id){
                e.new_one_kg_rate = action.payload.value
            }
            return e
          })
          state.products = x
      },
      setProductsGetorCreate: (state, action) => {
        let checkExist = state.products.filter((e:any) => e.id === action.payload)

        if(checkExist.length === 0){
            state.products.push(
                {
                    id: action.payload,
                    change_carton_basic_rate:0,
                    change_carton_rate:0,
                    change_stick_rate:0,
                    change_one_kg_rate:0,
                    new_carton_basic_rate:0,
                    new_carton_rate:0,
                    new_stick_rate:0,
                    new_one_kg_rate:0
                }
            )
        }

      },
      setPriceAction: (state, action) => {
        state.price_action = action.payload
      }
  },
})


// Action creators are generated for each case reducer function
export const { setChange_carton_basic_rate,
    setChange_carton_rate,
    setChange_stick_rate,
    setChange_one_kg_rate,
    setNew_carton_basic_rate,
    setNew_carton_rate,
    setNew_stick_rate,
    setNew_one_kg_rate,
    setProductsGetorCreate,
    setPriceAction
} = priceListCalculation.actions

export default priceListCalculation.reducer