import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import SearchLogo from "../../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate } from "../../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../../components/focus";
import "./sales_order.css"
import Accordion from 'react-bootstrap/Accordion';
import { DatePicker } from "antd";
import { AiOutlineCheck, AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useFetchCustomerDropDown, useFetchSalesOrderDropDown, useFetchDeliveryAddressDropDown, useFetchTransportationDropDown, useFetchMaterialTypeDropDown, useFetchPriceListProductDropDown, useFetchUOMDropDown, useFetchDisposableDropDown } from "../../../hooks/DropDown_Api";
import Table from "./../../../components/Dynamic_Table/Table/Table"
import { Columns_SalesOrderProduct, titleofheaders } from "./columns_SalesOrderProduct";
import { customAlphabet } from "nanoid";
import Axiosinstance from '../../../hooks/Interceptor'
import jsPDF from 'jspdf'
import FileDownload from 'js-file-download';
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import { Customer_Details_Model } from "../../../components/Dynamic_Form_Models/Customer_Details_Model";
const customIndicator = {
  IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
  IndicatorsContainer: IndicatorsContainerCreate
}

const Sales_Order = () => {
  const nanoid = customAlphabet('1234567890', 5)
  const apinameSalesOrder = process.env.REACT_APP_SALES_SALES_ORDER_API?.toString();
  const { mutate, data, isError, isLoading, isSuccess: isSuccessSalesOrder, error }: any = useCreate(apinameSalesOrder)


  let pageTitle = "Sales Order"
  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);
  const navigate = useNavigate()

  const [openEditModel, setOpenEditModel] = useState(false)

  const { register, handleSubmit, setError, formState, reset, setValue, getValues }: any = useForm({
    mode: "onChange"
  });
  const { errors } = formState;





  const [customerValue, setCustomerValue]: any = useState("")
  const [customerID, setCustomerID]: any = useState("")
  const [optionsCustomer, setOptionsCustomer] = useState([])
  const { data: customerData, refetch: customerRefetch } = useFetchCustomerDropDown()

  useEffect(() => {
    setOptionsCustomer(customerData)
  }, [customerData])

  const [CustomerGrade, setCustomerGrade] = useState('')
  // const handleCustomer = (e: any) => useMemo(()=>{


  //   console.log(e)
  //   setCustomerValue(e)
  //   setCustomerID(e?.value)
  //   setValue('name', e?.value)
  //   setLeadGenerateValue('')
  //   setOrderData([])

  //   setCustomerGrade(e?.grade)
  // },[e])
  const [dataAdded, setDataAdded] = useState(false)
  const [inquiryId, setInquiryId]: any = useState()

  const handleCustomer = useCallback((e: any) => {
    console.log(e);
    setCustomerValue(e);
    setCustomerID(e?.value);
    setValue('name', e?.value);
    setLeadGenerateValue('');
    setOrderData([]);
    setLeadProducts([])
    setDeliveryValue('')
    setTransportationValue('')


    // setOptionsDelivery([])
    // setOptionsTransportation([])
    // setDataAdded(true)

    setCustomerGrade(e?.grade);
  }, [dataAdded]);

  // const handleCustomer =(e:any)=>  {
  //   console.log(e);
  //   setCustomerValue(e);
  //   setCustomerID(e?.value);
  //   setValue('name', e?.value);
  //   setLeadGenerateValue('');
  //   setOrderData([]);
  //   // setOptionsDelivery([])
  //   // setOptionsTransportation([])
  //   setDataAdded(true)

  //   setCustomerGrade(e?.grade);
  // }
  // setRerenderKey(prevKey => prevKey + 1);
  // setOptionsDelivery(e?.address)



  let apinameForProductPricelist = process.env.REACT_APP_PRICELIST_API?.toString();


  const [pricelistMainId, setPricelistMainId] = useState()
  useEffect(() => {
    if (CustomerGrade) {


      Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apinameForProductPricelist}/search_filter/`, {
        grade: CustomerGrade,
      })
        .then((response) => {
          // setOrderData(response?.data?.results)
          setPricelistMainId(response?.data?.results[0]?.id)
          let Options = response?.data?.results[0]?.price_list_products?.map((e: any) => {
            console.log(e, ":eeeeeeeeeeeeee");

            return {
              value: e?.id,
              label: `${e?.product_code?.product_code}`
            }
          })


          setOptionsProductType(Options)

        })
        .catch((error) => {
          console.error('There was an error!', error);
        });


    }
  }, [CustomerGrade])

  const apinameLead = process.env.REACT_APP_LEAD_GENERATED_API?.toString()


  const { data: orderDataforTable, refetch: orderRefetch } = useFetchSalesOrderDropDown(customerID)
  const [leadGenerateValue, setLeadGenerateValue]: any = useState('')
  const [leadProducts, setLeadProducts] = useState([])

  useEffect(() => {
    if (orderDataforTable?.lead_generate.length > 0) {

      const getCall = async () => {
        // 
        try {
          const response = await Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apinameLead}/fetch_multiple_ids/`, {
            ids: orderDataforTable?.lead_generate
          });

          let temp = response?.data?.data?.map((e: any) => {

            return {
              value: e?.id,
              label: e?.code,
              product: e?.extra_response?.sales_inquiry_products,
              sales_inquiry_code: e?.sales_inquiry_code

            }
          })

          setOptionsLeadGenerate(temp)

          if (leadProducts) {

            try {
              const responseForLeadsProducts = await Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apinameSalesProduct}/fetch_multiple_ids/`, {
                ids: leadProducts
              });

              // let temp =  response?.data?.data?.map((e: any) => {
              //   return {
              //     value: e?.id,
              //     label: e?.code
              //   }
              // })
              console.log('responseForLeadsProducts', responseForLeadsProducts)
              setOrderData(responseForLeadsProducts?.data?.data)
              setRerenderKey(prevKey => prevKey + 1);
              // setOrderData(temp)

            } catch (error) {
              console.error('Error:', error);
            }
          }
          // setSelectedDelivery(response?.data?.data?.id)
          // setValue("delivery_address", response?.data?.data?.id)
        } catch (error) {
          console.error('Error:', error);
        }
      }
      getCall()
    }
  }, [orderDataforTable, leadProducts])




  const [salesID, setSalesID] = useState()
  const [leadID, setLeadID] = useState()

  console.log(leadGenerateValue, "leadGenerateValue");



  const handleLeadGenerate = (e: any) => {
    // 
    console.log("generated leads", e)
    setValue('lead_generate', e?.value)
    setLeadGenerateValue(e)
    setSalesID(e?.salesID)

    setLeadID(e?.value)
    setLeadProducts(e?.product)
    setInquiryId(e?.sales_inquiry_code)



  }


  // const [optionsDelivery, setOptionsDelivery] = useState([])
  // const [DeliveryValue, setDeliveryValue]: any = useState("")
  // const { data: deliveryData, refetch: deliveryRefetch } = useFetchDeliveryAddressDropDown()

  // useEffect(()=>{
  //   setOptionsDelivery(deliveryData)
  // },[deliveryData])



  const [optionsTransportation, setOptionsTransportation]: any = useState([])
  const [TransportationValue, setTransportationValue]: any = useState("")
  console.log(TransportationValue, "TransportationValue");

  const { data: TransportationData, refetch: TransportationRefetch } = useFetchTransportationDropDown()

  // useEffect(() => {
  //   setOptionsTransportation(TransportationData)
  // }, [TransportationData])


  const handleTransportation = (e: any) => {
    setValue('transportation', e?.value)
    setTransportationValue(e)
  }
  const [optionsProductType, setOptionsProductType] = useState([])
  const [ProductTypeValue, setProductTypeValue] = useState("")
  const { data: ProductTypeData, refetch: ProductTypeRefetch } = useFetchPriceListProductDropDown()

  // useEffect(() => {
  //   setOptionsProductType(ProductTypeData)
  // }, [ProductTypeData])


  const handleProductType = (e: any) => {
    setProductTypeValue(e)
  }

  const [orderData, setOrderData]: any = useState([])

  const [optionsLeadGenerate, setOptionsLeadGenerate] = useState([])



  // console.log("orderData",orderData)
  // console.log('orderData',orderData)


  useEffect(() => {
    if (orderDataforTable) {
      let temp = orderDataforTable?.lead_generate?.map((e: any) => {
        return {
          value: e?.id,
          label: e?.code,
          salesID: e?.sales_inquiry_code?.id,
          orderData: e?.sales_inquiry_code?.product,

        }
      })

      let tempAddress = orderDataforTable?.address?.map((e: any) => {
        return {
          value: e?.id,
          label: e?.address,


        }
      })
      // setOptionsDelivery(tempAddress)
      // setOptionsLeadGenerate(temp)
    }
  }, [orderDataforTable])

  // console.log('orderData',orderData)
  // useEffect(()=>{
  //   setOrderData(orderDataforTable?.lead_generate)
  // },[orderDataforTable,orderData])


  // useEffect(()=>{
  //   if(orderDataforTable){
  //     setRerenderKey(prevKey => prevKey + 1);
  //   }
  //       },[orderDataforTable])

  const handleValue = (e: any) => {
    e.target.value = e.target.value.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');

  }




  const handleReset = () => {
    reset()
  }



  const apiname = process.env.REACT_APP_SALES_ORDER_API?.toString();

  const filter = {
    code: "",
    godown_stock: "",
    balance_stock: "",
    lee10_price: "",
    order_qty: "",
    uom: "",
    discount_after_tax: "",
    approved_discount: "",
    one_kg_rate: "",
    after_discount_box: "",
    brand: "",
    nos_strip: "",
    box_strip: "",
    cup_weight: "",
    box_weight: "",
    basic_discount: "",
    total_basic_discount: "",
    basic_rate_per: "",
    total_basic: "",
    gst_rate: "",
    gst_amount: "",
    net_amount: "",
    created_by: "",
    updated_by: "",
    // status:""


  };

  const addButtonName = "Sales";
  const addButtonPageName = "sales"
  const permissionClass = {
    front: "sales",
    back: "salesorderproduct"
  }
  const stickyStart = 1;
  const stickyEnd = 2;





  const [additionalFields, setAdditionalFields]: any = useState([]);
  const [fieldCounter, setFieldCounter] = useState(2);
  const [fieldErrors, setFieldErrors]: any = useState(Array(additionalFields?.length).fill({}));


  const [total_godown_stock, setTotal_godown_stock]: any = useState()
  const [total_balance_stock, setTotal_balance_stock]: any = useState()
  const [total_lee_price, setTotal_lee_price]: any = useState()
  const [total_order_qty, setTotal_order_qty]: any = useState()
  const [total_discount, setTotal_Discount]: any = useState()
  const [total_discount_after_tax, setTotal_discount_after_tax]: any = useState()
  const [total_one_kg_rate, setTotal_one_kg_rate]: any = useState()
  const [total_box_weight, setTotal_box_weight]: any = useState()
  const [basic_discount, setBasic_discount]: any = useState()
  const [total_basic, setTotal_basic]: any = useState()
  const [total_basic_discount, setTotal_basic_discount]: any = useState()
  const [total_gst_amount, setTotal_gst_amount]: any = useState()
  const [total_net_amount, setTotal_net_amount]: any = useState()

  const onDownload = () => {
    return axios.get(
      `${process.env.REACT_APP_BASE_URL}salesUtils/generatedispatchorder/?salesOrder_id=77`,

      {
        responseType: "blob",

        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          "Content-Type": "application/json",
        },

      }
    ).then((response) => {
      FileDownload(response.data, 'Dispatch Order.pdf');
    });
  }


  const onSubmit = async (data: any) => {


    //  





    const formData = new FormData();

    if (!leadGenerateValue) {
      delete data.lead_generate
    }

    if (!DeliveryValue) {
      delete data.delivery_address
    }


    for (const [key, value] of Object.entries(data)) {
      const formvalue: any = value



      if (typeof value !== "object") {
        formData.append(key, formvalue)
      }

    }

    if (orderData) {






      // formData.append('total_godown_stock', total_godown_stock)
      // formData.append('balance_stock', total_balance_stock)
      // formData.append('lee10_price', total_lee_price)
      // formData.append('total_order_qty', total_order_qty)
      // formData.append('total_discount', total_discount)
      // formData.append('total_discount_after_tax', total_discount_after_tax)
      // formData.append('total_1kg_rate', total_one_kg_rate)
      // formData.append('total_box_weight', total_box_weight)
      // formData.append('basic_discount	', basic_discount)
      // formData.append('total_basic', total_basic)
      // formData.append('total_basic_discount', total_basic_discount)
      // formData.append('gst_amount', total_gst_amount)
      // formData.append('net_amount', total_net_amount)


      let tempOrderData = orderData?.map((e: any) => {
        console.log("eeeeeeeee", e)
        return {
          id: e?.id,
          // product_code:`${e.pricelist_product?.product_code?.product_code}::${e.pricelist_product?.product_code?.mouldshape?.name}::${e.pricelist_product?.product_code?.product_name_and_grade?.use_grade_name}::${e.pricelist_product?.product_code?.product_type?.type}`,
          product_code: `${e.extra_response?.product_code}`,

          godown_stock: e?.godown_stock,
          balance_stock: e?.balance_stock,
          lee10_price: e?.lee10_price,
          uom: e?.uom?.name?.name,
          order_qty: e?.product_qty,
          discount_after_tax: e?.discount_after_tax,
          discount: e?.approved_discount,
          one_kg_rate: e?.pricelist_product?.new_one_kg_rate,
          after_discount_box: e?.after_discount_box,
          brand: e?.pricelist_product?.product_code?.product_brand?.name,
          nos_strip: e?.nos_strip,
          box_strip: e?.box_strip,
          cup_weight: e?.cup_weight,
          box_weight: e?.box_weight,
          basic_discount: e?.basic_discount,
          total_basic_discount: e?.total_basic_discount,
          basic_rate_per: e?.basic_rate_per,
          total_basic: e?.total_basic,
          gst_rate: e?.pricelist_product?.product_code?.product_name_and_grade?.gst?.india_GST,
          gst_amount: e?.gst_amount,
          net_amount: e?.net_amount,
          pricelist: e?.pricelist,
          pricelist_product: e?.pricelist_product,
          product: e?.id,

          remaining_dispatch_qty: e?.product_qty,

        }
      })



      formData.append('salesorder_product', JSON.stringify(tempOrderData))
    }

    mutate(formData);


  }

  // console.log('orderData',orderData)


  const [totalOfAll, setTotalOfAll]: any = useState([])

  useEffect(() => {

    if (orderData) {

      const totalGodownStock = orderData?.reduce((total: any, item: any) => {
        return total + item.godown_stock;
      }, 0);

      setTotal_godown_stock(Number(totalGodownStock))

      const totalBalanceStock = orderData?.reduce((total: any, item: any) => {
        return total + item.balance_stock;
      }, 0);

      setTotal_balance_stock(Number(totalBalanceStock))

      const totalLeePrice = orderData?.reduce((total: any, item: any) => {
        return total + item.lee10_price;
      }, 0);

      setTotal_lee_price(Number(totalLeePrice))

      const totalOrderQty = orderData?.reduce((total: any, item: any) => {
        return total + item.product_qty;
      }, 0);

      setTotal_order_qty(Number(totalOrderQty))

      const totalDiscount = orderData?.reduce((total: number, item: any) => {
        return total + Number(item.approved_discount);
      }, 0);

      setTotal_Discount(Number(totalDiscount))

      const totalDiscountAfterTax = orderData?.reduce((total: any, item: any) => {
        return total + item.discount_after_tax;
      }, 0);

      setTotal_discount_after_tax(Number(totalDiscountAfterTax))

      const totalOneKgRate = orderData?.reduce((total: any, item: any) => {
        return total + item?.pricelist_product?.new_one_kg_rate;
      }, 0);

      setTotal_one_kg_rate(Number(totalOneKgRate))


      const totalBoxWeight = orderData?.reduce((total: any, item: any) => {
        return total + item.box_weight;
      }, 0);

      setTotal_box_weight(Number(totalBoxWeight))

      const basicDiscount = orderData?.reduce((total: any, item: any) => {
        return total + item.basic_discount;
      }, 0);

      setBasic_discount(Number(basicDiscount))

      const totalBasic = orderData?.reduce((total: any, item: any) => {
        return total + item.total_basic;
      }, 0);

      setTotal_basic(Number(totalBasic))

      const totalBasicDiscount = orderData?.reduce((total: any, item: any) => {
        return total + item.total_basic_discount;
      }, 0);

      setTotal_basic_discount(Number(totalBasicDiscount))

      const totalGstAmount = orderData?.reduce((total: any, item: any) => {
        return total + item.gst_amount;
      }, 0);

      setTotal_gst_amount(Number(totalGstAmount))

      const totalNetAmount = orderData?.reduce((total: any, item: any) => {
        return total + item.net_amount;
      }, 0);

      setTotal_net_amount(Number(totalNetAmount))





    }

  }, [orderData, total_godown_stock, total_balance_stock,
    total_lee_price,
    total_order_qty,
    total_discount,
    total_discount_after_tax,
    total_one_kg_rate,
    total_box_weight,
    basic_discount,
    total_basic,
    total_basic_discount,
    total_gst_amount,
    total_net_amount])

  useEffect(() => {


    if (total_godown_stock &&
      total_balance_stock &&
      total_lee_price &&
      total_order_qty &&
      total_discount &&
      total_discount_after_tax &&
      total_one_kg_rate &&
      total_box_weight &&
      basic_discount &&
      total_basic &&
      total_basic_discount &&
      total_gst_amount &&
      total_net_amount) {
      const totalAllData = {
        total_godown_stock: Number(total_godown_stock),
        total_balance_stock: Number(total_balance_stock),
        total_lee_price: Number(total_lee_price),
        total_order_qty: Number(total_order_qty),
        total_discount: Number(total_discount),
        total_discount_after_tax: Number(total_discount_after_tax),
        total_one_kg_rate: Number(total_one_kg_rate),
        total_box_weight: Number(total_box_weight),
        basic_discount: Number(basic_discount),
        total_basic: Number(total_basic),
        total_basic_discount: Number(total_basic_discount),
        total_gst_amount: Number(total_gst_amount),
        total_net_amount: Number(total_net_amount),
      };

      setTotalOfAll([totalAllData]);
    }
  }, [orderData, total_godown_stock, total_balance_stock,
    total_lee_price,
    total_order_qty,
    total_discount,
    total_discount_after_tax,
    total_one_kg_rate,
    total_box_weight,
    basic_discount,
    total_basic,
    total_basic_discount,
    total_gst_amount,
    total_net_amount])


  useEffect(() => {
    if (totalOfAll && orderData) {
      setRerenderKey(prevKey => prevKey + 1);
    }
  }, [totalOfAll, total_godown_stock, total_balance_stock,
    total_lee_price,
    total_order_qty,
    total_discount,
    total_discount_after_tax,
    total_one_kg_rate,
    total_box_weight,
    basic_discount,
    total_basic,
    total_basic_discount,
    total_gst_amount,
    total_net_amount])





  useEffect(() => {
    if (additionalFields.length === 0) {
      setFieldErrors([]);
    }
  }, [additionalFields]);

  // console.log("orderData",orderData)




  console.log('inquiry id', inquiryId)
  const handleAddProduct = () => {
    const newProduct = {
      id: nanoid(),
      pricelist_product: null,
      uom: null,
      product_qty: '',
      requirement_date: null,
      product_discount: '',
      pricelist: pricelistMainId,
      sales_id: null
    };

    setAdditionalFields([newProduct]);
    // setAdditionalFields([...additionalFields, newProduct]);

  };


  const handleDeleteProduct = (id: any) => {
    const updatedFields = additionalFields.filter((field: any) => field.id !== id);
    setAdditionalFields(updatedFields);
    // setRerenderKey(prevKey => prevKey + 1);
  };




  let apinameForNewProductPricelist = `${process.env.REACT_APP_PRICELIST_API}/search_filter`
  const { mutate: newProductPricelist, data: dataOfNewProductPricelist } = useCreate(apinameForNewProductPricelist)


  let apinameForSalesOrderProduct = `${process.env.REACT_APP_SALES_ORDER_PRODUCT_API}/search_filter`
  const { mutate: salesOrderProduct, data: dataOfSalesOrderProduct } = useCreate(apinameForSalesOrderProduct)

  const [newProductID, setNewProductID] = useState("")

  const handleChangeProductType = (selectedOption: any, index: any) => {
    const updatedFields = [...additionalFields];


    // setProductMultipleIds((oldIds: any) => [...oldIds, selectedOption?.value]);



    updatedFields[index].pricelist_product = selectedOption?.value;
    updatedFields[index].pricelist = pricelistMainId;
    updatedFields[index].sales_id = inquiryId

    setAdditionalFields(updatedFields);

    setNewProductID(selectedOption?.value)

    setNewProductCode(selectedOption?.productCode)



  };

  const [newProductPricelistId, setNewProductPricelistId] = useState()
  const [newProductCode, setNewProductCode] = useState("")
  const [newProductQty, setNewProductQty] = useState("")
  const [newProductDiscount, setNewProductDiscount] = useState("")



  const [oldProductsId, setOldProductId] = useState([])


  useEffect(() => {



  }, [])



  // useEffect(() => {
  //   if (newProductID) {
  //     newProductPricelist({ price_list_products: newProductID })

  //   }
  // }, [newProductID])

  // useEffect(() => {
  //   if (dataOfNewProductPricelist) {
  //     setNewProductPricelistId(dataOfNewProductPricelist?.results[0]?.id)
  //   }
  // }, [dataOfNewProductPricelist])


  const [newProductData, setNewProductData]: any = useState()

  const [check, setCheck]: any = useState([])

  const apinameSalesProduct = process.env.REACT_APP_SALES_ADD_PRODUCT_API?.toString();
  const apinameForAddSalesOrder = process.env.REACT_APP_ADD_SALES_ORDER_API?.toString();

  const { mutate: addSalesOrderLead, data: dataOfAddSalesOrderLead, isSuccess: isSuccessSalesOrderLead } = useCreate(apinameForAddSalesOrder)

  // let apinameForAddSalesOrder = `${process.env.REACT_APP_ADD_SALES_ORDER_API}/search_filter`
  const { mutate: addSalesOrder, data: dataOfAddSalesOrder, isSuccess } = useCreate(apinameSalesProduct)

  //     useEffect(()=>{
  // if(dataOfAddSalesOrder){
  //   setRerenderKey(prevKey => prevKey + 1);
  // }
  //     },[dataOfAddSalesOrder])
  const [rerenderKey, setRerenderKey] = useState(0);

  const [added, setAdded] = useState(false)

  const [productMultipleIds, setProductMultipleIds]: any = useState([])

  useEffect(() => {
    if (dataOfAddSalesOrder) {



      setProductMultipleIds((oldIds: any) => [...oldIds, dataOfAddSalesOrder?.data?.id]);
      setAdded(true)
      setRerenderKey(prevKey => prevKey + 1);


      // getProduct({ ids: productMultipleIds })

    }
  }, [dataOfAddSalesOrder])



  useEffect(() => {
    if (dataOfAddSalesOrderLead) {
      // 


      // setOrderData(dataOfAddSalesOrderLead?.data?.extra_response?.product)
      setLeadProducts(dataOfAddSalesOrderLead?.data?.extra_response?.sales_inquiry_products)
      setRerenderKey(prevKey => prevKey + 1);

    }
  }, [dataOfAddSalesOrderLead])

  console.log('dataOfAddSalesOrderLead', dataOfAddSalesOrderLead)


  // useEffect(()=>{
  //   if(dataOfAddSalesOrder){
  //   setOrderData(dataOfAddSalesOrder?.data?.sales_inquiry_code?.product.reverse())
  //   setRerenderKey(prevKey => prevKey + 1);
  //   }
  //   },[dataOfAddSalesOrder])





  const apinameGetMultipleProductSalesOrder = process.env.REACT_APP_SALES_PRODUCTS_FETCH_API?.toString();




  const {
    mutate: getProduct,
    data: getProductData,
    isError: getProductIsError,
    isLoading: getProductIsLoading,
    isSuccess: getProductIsSuccess,
    error: getProductError,
  }: any = useCreate(apinameGetMultipleProductSalesOrder);


  useEffect(() => {
    if (getProductData) {
      setOrderData(getProductData?.data)
    }
  }, [getProductData])

  useEffect(() => {
    if (productMultipleIds) {
      getProduct({ ids: productMultipleIds })
    }
  }, [productMultipleIds])

  // console.log('getProductData',getProductData)

  // console.log('dataOfAddSalesOrder',dataOfAddSalesOrder)
  // console.log('orderData',orderData)
  // console.log('added',added)






  const handleAddItems = () => {

    const newFieldErrors = additionalFields?.map((field: any) => {
      const errors: any = {};

      if (!field.pricelist_product) {
        errors.pricelist_product = 'Product is required';
      }

      // if (!field.uom) {
      //   errors.uom = 'UOM is required';
      // }


      if (!field.product_qty) {
        errors.product_qty = 'Product Qty is required';
      }

      // if (!field.requirement_date) {
      //   errors.requirement_date = 'Date is required';
      // }

      // if (!field.product_discount) {
      //   errors.product_discount = 'Product Discount is required';
      // }



      return errors;
    });

    setFieldErrors(newFieldErrors);

    // Check if there are any errors
    const hasErrors = newFieldErrors.some((errors: any) => Object.keys(errors).length > 0);

    if (!hasErrors && additionalFields) {
      delete additionalFields[0]?.id
      if (leadGenerateValue) {
        addSalesOrderLead(additionalFields[0])
      } else {

        addSalesOrder(additionalFields[0])
      }
      setAdditionalFields([]);

    } else {
    }






    // setRerenderKey(prevKey => prevKey + 1);
    // delete additionalFields[0]?.id
    //       addSalesOrder(additionalFields)







  }




  useEffect(() => {
    if (rerenderKey) {

    }
  }, [rerenderKey])






  // useEffect(()=>{

  //   if(newProductData){
  //     
  //     const updatedOrderData = [...orderData, newProductData];
  //     setOrderData(updatedOrderData);
  //   }
  // },[newProductData,orderData])

  const [optionsUOM, setOptionsUOM] = useState([])

  const { data: uomData, refetch: refetchUOM } = useFetchUOMDropDown()

  useEffect(() => {
    setOptionsUOM(uomData)
  }, [uomData])


  const handleChangeUOM = (selectedOption: any, index: any) => {
    const updatedFields = [...additionalFields];


    updatedFields[index].uom = selectedOption?.value;
    setAdditionalFields(updatedFields);


  };



  const handleChangeProductQty = (e: any, index: any) => {
    const updatedFields = [...additionalFields];
    updatedFields[index].product_qty = e.target.value;
    // updatedFields[index].pricelist = newProductPricelistId;
    // updatedFields[index].sales_id = salesID
    updatedFields[index].lead_generate = leadID
    setAdditionalFields(updatedFields);

    setNewProductQty(e.target.value)


  };

  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];

  const handleRequirementDate = (date: any, index: any) => {
    const updatedFields = [...additionalFields];

    let finalDate = date?.$D
    let finalMonth = date?.$M + 1
    let finalYear = date?.$y

    let final = `${finalDate}-${finalMonth}-${finalYear}`


    updatedFields[index].requirement_date = final;
    setAdditionalFields(updatedFields);
  };
  // console.log('additional',additionalFields)


  const handleChangeProductDiscount = (e: any, index: any) => {
    const updatedFields = [...additionalFields];
    updatedFields[index].product_discount = e.target.value;
    setAdditionalFields(updatedFields);

    setNewProductDiscount(e.target.value)

  };



  function getDate() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${date}/${month}/${year}`;
  }
  const [currentDate, setCurrentDate] = useState(getDate());






  // Whenever you want to trigger a re-render, call setRerenderKey with a new value
  // For example, to trigger a re-render when something changes, call setRerenderKey with a different value
  // For demonstration purposes, I'm using a button click to trigger the re-render here.
  // const handleButtonClick = () => {
  //   setRerenderKey(prevKey => prevKey + 1);
  // };               

  // const handleSubmitQuotation = () => {
  //   return axios.get(
  //     `${process.env.REACT_APP_BASE_URL}pdf/quotation/${customerID}?lead_id=${leadID}`,
  //     {
  //       responseType: "blob",

  //       headers: {
  //         'Authorization': `Bearer ${localStorage.getItem('token')}`,
  //         "Content-Type": "application/json",
  //       },

  //     }
  //   ).then((response) => {
  //     FileDownload(response.data, 'Quotation.pdf');
  //   });
  // };



  const [show, setShow] = useState(false)
  const [showmessage, setshowmessage] = useState("")

  useEffect(() => {
    if (isSuccessSalesOrder) {
      setShow(true)
      setshowmessage("success")

      reset()
      setOrderData([])
      setCustomerValue(null)
      setLeadGenerateValue(null)
      setDeliveryValue(null)
      setTransportationValue(null)
      navigate('/listSalesOrder', { state: { showToast: true } })

    }
    else if (isError) {
      setShow(true)
      setshowmessage("danger")
    }
  }, [isSuccessSalesOrder, data, error])

  useEffect(() => {
    setTimeout(() => {
      setShow(false)
    }, 5000)

  }, [show]);

  // const [show, setShow] = useState(false)
  // const [showmessage, setshowmessage] = useState("")




  const [isDelete, setIsDelete] = useState(false)

  useEffect(() => {
    if (isDelete) {
      setRerenderKey(prevKey => prevKey + 1);
    }
  }, [isDelete])

  //   console.log('customerValue',customerValue)


  // console.log('ids=>>>>>',productMultipleIds)


  const [OpenCustomerDetails, setOpenCustomerDetails] = useState(false)

  const [optionsDelivery, setOptionsDelivery] = useState([])

  const [addressOn, setAddressOn] = useState(false)


  const apinameAddress = 'person/employee/customer/get_only_address'
  const apinameTransport = 'person/employee/customer/get_only_transport'
  useEffect(() => {
    const fetchData = async () => {
      if (customerValue || dataAdded) {
        try {
          // 
          const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameAddress}/?id=${customerID}`);
          // console.log('response', response);

          let address = response?.data?.data?.address?.map((e: any) => {
            return (
              {
                value: e?.id,
                label: `${e?.number}, ${e?.building_name ? e?.building_name : e?.bunglow_name}`

              }
            )



          })
          // let address:any = []
          await setOptionsDelivery(address)

          let transport: any = [{
            value: response?.data?.data?.transport_detail.id,
            label: response?.data?.data?.transport_detail.name
          }]
          // console.log(transport,"transport");

          await setOptionsTransportation(transport)
          if (dataAdded && addressOn) {
            await setDeliveryValue(address[0])
            await setValue('delivery_address', address[0])
          }
          if (dataAdded) {

            await setTransportationValue(transport)
            await setValue('transportation', transport[0].value)
          }
          await setDataAdded(false)
          await setAddressOn(false)


          // Do something with response.data
        } catch (error) {
          console.error("Error fetching data:", error);
          // Handle the error appropriately
        }


      }
    };

    fetchData();
  }, [customerValue, dataAdded]);




  const [DeliveryValue, setDeliveryValue]: any = useState("")
  const { data: deliveryData, refetch: deliveryRefetch } = useFetchDeliveryAddressDropDown()

  // useEffect(()=>{
  //   setOptionsDelivery(deliveryData)
  // },[deliveryData])


  const handleDelivery = (e: any) => {
    setValue('delivery_address', e?.value)
    setDeliveryValue(e)
  }


  const [forTab, setForTab] = useState('')

  return (
    <div>


      <div className="content-wrapper" >
        <section className="content-header px-3 py-1">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>Sales Order</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  {/* <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li> */}
                  {/* <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li> */}
                  <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales</Link></li>
                  <li className="breadcrumb-item active">Sales Order</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <form ref={focus}
                onKeyUp={event => onEnterKey(event)}
                onSubmit={handleSubmit(onSubmit)}>

                {show && showmessage === "success" ? <Alert color={showmessage} message={"Updated Successfully"} /> : ""}







                <div className="row">
                  <div className="col-md-3">
                    <div className="">
                      <label htmlFor="inputField" className="form-label">Name</label>
                      <Select
                        {...register("name", { required: { value: true, message: "Name is required" } })}
                        value={customerValue}
                        options={optionsCustomer}
                        onChange={handleCustomer}
                        components={customIndicator}
                      />




                    </div>
                    <span className='text-red w-100'>{errors?.name?.message}</span>
                  </div>

                  <div className="col-md-3">
                    <div className="">
                      <label htmlFor="inputField" className="form-label">Generated Leads</label>
                      <Select
                        {...register("lead_generate", { required: { value: false, message: "Generated Leads is required" } })}
                        value={leadGenerateValue}
                        options={optionsLeadGenerate}
                        onChange={handleLeadGenerate}
                        components={customIndicator}
                      />




                    </div>
                    <span className='text-red w-100'>{errors?.lead_generate?.message}</span>
                  </div>

                  {/* <div className="col-md-2">
                    <div className="">
                      <label htmlFor="textareaField" className="form-label">Delivery Address</label>

                      <Select
                        {...register("delivery_address", { required: { value: false, message: "Delivery Address is required" }, maxLength: 30, })}
                        value={DeliveryValue}
                        options={optionsDelivery}
                        onChange={handleDelivery}
                        components={customIndicator}
                      />
                    </div>
                    <span className='text-red w-100'>{errors?.delivery_address?.message}</span>
                  </div> */}


                  <div className="col-md-2">
                    <label htmlFor="textareaField" className="form-label">Delivery Address</label>
                    <div className="d-flex row" >

                      <Select
                        {...register("delivery_address", { required: { value: false, message: "Delivery Address is required" }, maxLength: 30, })}
                        value={DeliveryValue}
                        options={optionsDelivery}
                        onChange={handleDelivery}
                        components={customIndicator}
                        className="dropdown-select"

                      />
                      <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                        <Button className="input-group-text border-start-0 bg-primary" onClick={async () => {
                          await setForTab('Addresses')
                          setOpenCustomerDetails(true)
                          setAddressOn(true)
                        }}><AiOutlinePlus /></Button>
                      </div>
                    </div>
                    <span className='text-red w-100'>{errors?.delivery_address?.message}</span>
                  </div>

                  {/* <div className="col-md-2">
                    <div className="">
                      <label htmlFor="selectField1" className="form-label">Transportation</label>

                      <Select
                        {...register("transportation", { required: { value: true, message: "Transportation is required" }, maxLength: 30, })}
                        value={TransportationValue}
                        options={optionsTransportation}
                        onChange={handleTransportation}
                        components={customIndicator}
                      />
                    </div>
                    <span className='text-red w-100'>{errors?.transportation?.message}</span>
                  </div> */}


                  <div className="col-md-2">
                    <label htmlFor="textareaField" className="form-label">Transportation</label>
                    <div className="d-flex row" >

                      <Select
                        {...register("transportation", { required: { value: true, message: "Transportation is required" }, maxLength: 30, })}
                        value={TransportationValue}
                        options={optionsTransportation}
                        onChange={handleTransportation}
                        components={customIndicator}
                        className="dropdown-select"

                      />
                      <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                        <Button className="input-group-text border-start-0 bg-primary" onClick={async () => {
                          await setForTab('Transport')
                          setOpenCustomerDetails(true)
                        }}><AiOutlinePlus /></Button>
                      </div>
                    </div>
                    <span className='text-red w-100'>{errors?.transportation?.message}</span>
                  </div>

                  <div className="col-md-2">
                    <div className="">
                      <label htmlFor="selectField2" className="form-label">Bill Type</label>

                      <select className="form-select"{...register("bill_type", { required: { value: true, message: "Bill type is required" }, maxLength: 30, })} >
                        <option value="" selected >Select Any</option>
                        <option value="Service" >Service</option>
                        <option value="Goods" >Goods</option>
                      </select>
                    </div>
                    <span className='text-red w-100'>{errors?.bill_type?.message}</span>
                  </div>
                </div>


                <div className="card" style={{ backgroundColor: "#013564", color: "white" }}>
                  <div className="card-body" style={{ padding: "0px" }}>
                    <div className="row">

                      <div className="col-sm-4" style={{ fontSize: "13px" }}>
                        <p className="card-text"><strong>Name:</strong> {customerValue?.name}</p>
                        <p className="card-text"><strong>Phone:</strong> {customerValue?.mobile}</p>
                        <p className="card-text"><strong>Address:</strong> {DeliveryValue?.label} </p>
                      </div>
                      <div className="col-sm-4">
                        <p className="card-text"><strong>Sales Person Name:</strong> {customerValue?.zone?.salesman_employee?.name}</p>
                        <p className="card-text"><strong>Delivered Cust. To:</strong> {customerValue?.name}</p>
                        <p className="card-text"><strong>Zone:</strong> {customerValue?.zone?.route_name}</p>
                        <p className="card-text"><strong>Delivered Address:</strong> {DeliveryValue?.label}</p>
                      </div>
                      <div className="col-sm-4">
                        <p className="card-text"><strong>Order Number:</strong> 123456</p>
                        <p className="card-text"><strong>Delivery Date:</strong> {currentDate} </p>
                        <p className="card-text"><strong>Req. Delivery Date:</strong> 14-09-2023</p>
                        <p className="card-text"><strong>Commitment Date:</strong> 14-09-2023</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div  >



                  {additionalFields?.map((field: any, index: any) => (


                    <>

                      <div className="row" key={index}>
                        <div className="col-md-2  mt-4">
                          <div className="mb-3">
                            <label htmlFor="inputField" className="form-label">Product</label>
                            <Select
                              options={optionsProductType}
                              components={customIndicator}
                              value={field.product_type}
                              onChange={(selectedOption) =>
                                handleChangeProductType(selectedOption, index)
                              }

                            />
                            {fieldErrors[index] && (
                              <div className="error-message text-red">{fieldErrors[index].pricelist_product}</div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-2  mt-4">
                          <div className="mb-3">
                            <label htmlFor="inputField" className="form-label">Unit Of Measurment</label>
                            <Select
                              options={optionsUOM}
                              //  value={field.uom}
                              components={customIndicator}
                              onChange={(selectedOption) =>
                                handleChangeUOM(selectedOption, index)
                              }

                            />
                            {/* {fieldErrors[index] && (
         <div className="error-message text-red">{fieldErrors[index].uom}</div>
       )} */}
                          </div>
                        </div>

                        <div className="col-md-2  mt-4">
                          <div className="mb-3">
                            <label htmlFor="inputField" className="form-label">Product Quantity</label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Product Total Qty."
                              value={field.product_qty}
                              onChange={(e) => handleChangeProductQty(e, index)}
                            />
                            {fieldErrors[index] && (
                              <div className="error-message text-red">{fieldErrors[index].product_qty}</div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-2  mt-4">
                          <div className="mb-3">
                            <label htmlFor="inputField" className="form-label">Product Discount</label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Product Discount."
                              value={field.product_discount}
                              onChange={(e) => handleChangeProductDiscount(e, index)}
                            />
                            {/* {fieldErrors[index] && (
         <div className="error-message text-red">{fieldErrors[index].product_discount}</div>
       )} */}
                          </div>
                        </div>

                        <div className="col-md-2  mt-4">
                          <div className="mb-3">
                            <label htmlFor="inputField" className="form-label">Requirement Date</label>
                            <DatePicker
                              className="form-control"
                              format={dateFormatList}
                              //   value={field.requirement_date}
                              onChange={(date: any) => handleRequirementDate(date, index)}
                            />
                            {/* {fieldErrors[index] && (
              <div className="error-message text-red">{fieldErrors[index].requirement_date}</div>
            )} */}
                          </div>
                        </div>

                        <div className="col-md-2  mt-4" style={{ display: "flex", justifyContent: "space-around", alignItems: "center", paddingTop: "25px" }}>
                          <div className="mb-3">
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => handleDeleteProduct(field.id)}
                            >
                              Delete
                            </button>


                          </div>
                          <div className="mb-3">
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={handleAddItems}
                            >
                              Add
                            </button>


                          </div>
                        </div>
                      </div>
                    </>
                  ))}



                  {/* <button
   type="button"
   className="btn btn-success"
   onClick={handleAddProduct}
 >
   Add Product
 </button> */}

                </div>
                <div className="p-1" style={{ display: "flex", justifyContent: "space-between" }}>

                  <button
                    type="button"
                    disabled={additionalFields.length > 0 || customerValue === ''}
                    className="btn btn-success"
                    onClick={handleAddProduct}
                  >
                    Add Product
                  </button>




                </div>

                <div key={rerenderKey}>

                  {
                    leadGenerateValue ?

                      <>
                        {orderData && <>
                          <Table
                            key={rerenderKey}
                            // apiname={apiname}
                            filterFields={filter}
                            addButtonName={addButtonName}
                            stickyStart={stickyStart}
                            stickyEnd={stickyEnd}
                            TableColumns={Columns_SalesOrderProduct}
                            addButtonPageName={addButtonPageName}
                            titleofheaders={titleofheaders}
                            permissionClass={permissionClass}
                            isMore={false}
                            isFooter={false}
                            isAddButton={false}
                            idTableFooter={true}
                            orderData={orderData}
                            totalOfAll={totalOfAll}
                            added={added}
                            setAdded={setAdded}
                          />
                        </>}
                      </> :

                      <>
                        <Table
                          key={rerenderKey}
                          // apiname={apiname}
                          filterFields={filter}
                          addButtonName={addButtonName}
                          stickyStart={stickyStart}
                          stickyEnd={stickyEnd}
                          TableColumns={Columns_SalesOrderProduct}
                          addButtonPageName={addButtonPageName}
                          titleofheaders={titleofheaders}
                          permissionClass={permissionClass}
                          isMore={false}
                          isFooter={false}
                          isAddButton={false}
                          idTableFooter={true}
                          orderData={orderData}
                          totalOfAll={totalOfAll}
                          added={added}
                          setAdded={setAdded}
                        />
                      </>
                  }


                  {/* {orderData?.length > 0 && <> */}
                  {/* <Table
                      key={rerenderKey}
                      // apiname={apiname}
                      filterFields={filter}
                      addButtonName={addButtonName}
                      stickyStart={stickyStart}
                      stickyEnd={stickyEnd}
                      TableColumns={Columns_SalesOrderProduct}
                      addButtonPageName={addButtonPageName}
                      titleofheaders={titleofheaders}
                      permissionClass={permissionClass}
                      isMore={false}
                      isFooter={false}
                      isAddButton={false}
                      idTableFooter={true}
                      orderData={orderData}
                      totalOfAll={totalOfAll}
                      added={added}
                      setAdded={setAdded}
                    /> */}
                  {/* </>
                  } */}
                  {/* <button onClick={handleB  uttonClick}>Trigger Re-render</button> */}
                </div>













































                <div className="p-1" style={{ display: "flex", justifyContent: "end" }}>

                  {/* <button
type="button"
disabled={additionalFields.length > 0}
className="btn btn-success"
onClick={handleAddProduct}
>
Add Product
</button> */}




                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Submit
                  </button>
                  {/* <button
type="button"
className="btn btn-success"
onClick={onDownload}
>
Dispatch Order
</button> */}
                  {/* <button
type="button"
className="btn btn-success"
onClick={handleSubmitQuotation}
>
Download Quotation
</button> */}

                </div>

                {/* {show && showmessage === "success" ? <Alert color={showmessage} message={"Updated Successfully"} /> : ""} */}




                <div className="py-2" style={{ overflow: "auto" }}>
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Monthly Sales Details</Accordion.Header>
                      <Accordion.Body>
                        <div className="row col-12 d-flex">
                          <div className="col-12 col-md-8 col-lg-8 grid-box d-flex align-items-center">
                            <div className="table-responsive" >
                              <table className="table" >
                                {/* ... Table content ... */}
                                <thead style={{ backgroundColor: "#343a40", color: "white" }}>
                                  <tr>
                                    <th colSpan={15} style={{ textAlign: "center" }}>
                                      Monthly Sales Details
                                    </th>
                                  </tr>
                                  <tr>
                                    <th>Month</th>
                                    <th>Aprl-23</th>
                                    <th>May-23</th>
                                    <th>Jun-23</th>
                                    <th>Jul-23</th>
                                    <th>Aug</th>
                                    <th>Sep</th>
                                    <th>Oct</th>
                                    <th>Nov</th>
                                    <th>Dec</th>
                                    <th>Jan</th>
                                    <th>Feb</th>
                                    <th>Mar</th>
                                    <th>Total</th>
                                    <th>Average</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Order</td>
                                    <td>50</td>
                                    <td>50</td>
                                    <td>50</td>
                                    <td>50</td>
                                    <td>50</td>
                                    <td>50</td>
                                    <td>50</td>
                                    <td>50</td>
                                    <td>50</td>
                                    <td>50</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td>Disp</td>
                                    <td>50</td>
                                    <td>50</td>
                                    <td>50</td>
                                    <td>50</td>
                                    <td>50</td>
                                    <td>50</td>
                                    <td>50</td>
                                    <td>50</td>
                                    <td>50</td>
                                    <td>50</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td>Amount</td>
                                    <td>70000.00</td>
                                    <td>70000.00</td>
                                    <td>70000.00</td>
                                    <td>70000.00</td>
                                    <td>70000.00</td>
                                    <td>70000.00</td>
                                    <td>70000.00</td>
                                    <td>70000.00</td>
                                    <td>70000.00</td>
                                    <td>70000.00</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="col-12 col-md-4 col-lg-4 grid-box d-flex align-items-center justify-content-center">
                            <div className="table-responsive" >
                              <table className="table" >
                                {/* ... Table content ... */}
                                <thead style={{ backgroundColor: "#343a40", color: "white" }}>
                                  <tr>
                                    <th colSpan={4} style={{ textAlign: "center" }}>
                                      Comm. Terms and Cond.
                                    </th>
                                  </tr>
                                  <tr>
                                    <th>Due Amount</th>
                                    <th>Total Amount</th>
                                    <th>Credit Limit Rs.</th>
                                    <th>Credit Days</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>500</td>
                                    <td>1000</td>
                                    <td>2000</td>
                                    <td>30</td>
                                  </tr>
                                  <tr>
                                    <td>500</td>
                                    <td>1000</td>
                                    <td>2000</td>
                                    <td>30</td>
                                  </tr>
                                  <tr>
                                    <td>500</td>
                                    <td>1000</td>
                                    <td>2000</td>
                                    <td>30</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>


                        </div>






                        <div className="row col-12 d-flex">



                          <div className="col-12 col-md-6 col-lg-6 grid-box d-flex align-items-center">
                            <div className="table-responsive" >
                              <table className="table" >
                                {/* ... Table content ... */}
                                <thead style={{ backgroundColor: "#343a40", color: "white" }}>
                                  <tr>
                                    <th colSpan={4} style={{ textAlign: "center" }}>
                                      Transportation Details
                                    </th>
                                  </tr>
                                  <tr>
                                    <th>Mode Of Dispatch</th>
                                    <th>By Road</th>

                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Insurance</td>
                                    <td>Nil</td>

                                  </tr>
                                  <tr>
                                    <td>Insurance</td>
                                    <td>ACPL</td>

                                  </tr>
                                  <tr>
                                    <td>Insurance</td>
                                    <td>Nil</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>




                          <div className="col-12 col-md-6 col-lg-6 grid-box d-flex align-items-center">
                            <div className="table-responsive" >
                              <table className="table" >
                                {/* ... Table content ... */}
                                <thead style={{ backgroundColor: "#343a40", color: "white" }}>
                                  <tr>
                                    <th colSpan={4} style={{ textAlign: "center" }}>
                                      Pending Details
                                    </th>
                                  </tr>
                                  <tr>
                                    <th>Pending Order  NOS.</th>
                                    <th>DILEVRY ODER Pending BOX</th>

                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>3</td>

                                  </tr>
                                  <tr>
                                    <td>34</td>
                                    <td>45</td>

                                  </tr>
                                  <tr>
                                    <td>56</td>
                                    <td > 45</td>

                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>

                        </div>






                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                {/* </div> */}

              </form>


            </div>

          </div>
        </section>
      </div>
      <Modal show={openEditModel} onHide={() => setOpenEditModel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Sales Order Product</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Add Product Here.</p>
        </Modal.Body>

        <Modal.Footer>
          <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>
          <button
            type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
          // onClick={handleSubmit(handleConfirm)}
          >Confirm</button>
        </Modal.Footer>
      </Modal>


      <Customer_Details_Model open={OpenCustomerDetails} setOpen={setOpenCustomerDetails} setDataAdded={setDataAdded} customerId={customerID} dataAdded={dataAdded} forTab={forTab} />
    </div>
  )
}

export default Sales_Order