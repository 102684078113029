import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Columns_Employee_Daily, titleofheaders } from "./Columns_Employee_Daily"
import Table from "../../components/Dynamic_Table/Table/Table";
const List_Employee_Daily = () => {
  const Breadcrumb_Primary_Title = "Home";
  const Breadcrumb_Primary_Link = "Color";

  const Breadcrumb_Parent_Title = "Users";

  const Breadcrumb_Title_Name = "Employee";

  const apiname = process.env.REACT_APP_EMPLOYEE_API?.toString();

  const filter = {

    type:'',
    code:'',
    salary_type:'',
    surname:'',
    name:'',
    father_name:'',
    department:'',
    designation:'',
    gender:'',
    title:'',
    weekly_off:'',
    qualification:'',
    joining_date:'',
    birth_date:'',
    age:'',
    marital_status:'',
    anniversary_date:'',
    nationality:'',
    religion:'',
    caste:'',
    blood_group:'',
    reference:'',
    balance_sheet_group:'',
    employee_status:'',
    status:'',
    // created_at:'',
    created_by: '',
    updated_by: '',
    // updated_at: '',
  };

  const addButtonName = "Employee";
  const addButtonPageName = "Employee"
  const permissionClass = {
    front: "hrms",
    back: "department"
  }
  const stickyStart = 1;
  const stickyEnd = 2;

  return (
    <>
      <div className="content-wrapper">
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{Breadcrumb_Title_Name} List</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to={`/list${Breadcrumb_Primary_Link}`}
                      className="text-decoration-none text-black"
                    >
                      {Breadcrumb_Primary_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      {Breadcrumb_Parent_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {Breadcrumb_Title_Name} List
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="container p-0" style={{ maxWidth: "100%" }}>
                {/* <BasicTable/> */}
                <Table
                  apiname={apiname}
                  filterFields={filter}
                  addButtonName={addButtonName}
                  stickyStart={stickyStart}
                  stickyEnd={stickyEnd}
                  TableColumns={Columns_Employee_Daily}
                  addButtonPageName={addButtonPageName}
                  titleofheaders={titleofheaders}
                  permissionClass={permissionClass}
                />
                
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default List_Employee_Daily;
