



import styled from "styled-components";
export const Styles = styled.div`
padding: 1rem;
.table {
  border: 1px solid black;
  

  .tr {
    :last-child {
      .td {
        border-bottom: 0;
      }
    }
  }
  .tr:nth-of-type(even) {
      background-color: #99999977;
      opacity: 1;
      
  }
  .tr:nth-of-type(odd) {
      background-color: #ffffff;
      opacity: 1;
  }

  .th,
  .td {
    padding: 5px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    // background-color: #fff;
    overflow: hidden;
    // margin: auto;
    display: flex !important;
    vertical-align: middle !important;
    justify-content: center !important;
    align-items: center !important;
    // font-size: 13px;
  
    :last-child {
      border-right: 0;
    }

    .resizer {
      display: inline-block;
      width: 5px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(50%);
      z-index: 1;
     

      &.isResizing {
        background: black;
      }
    }
  }

  &.sticky {
    overflow: scroll;
    .header,
    .footer {
      position: sticky;
      z-index: 1;
      width: fit-content;
    }

    .header {
      top: 0;
      box-shadow: 0px 3px 3px #ccc;
      // color: blue;
      font-size: 14px !important; 
    }

    .footer {
      bottom: 0;
      box-shadow: 0px -3px 3px #ccc;
    }

    .body {
      position: relative;
      z-index: 0;
      font-size: 13px !important;    
    }

    [data-sticky-td] {
      position: sticky;
      background-color: #ccc;
    }

    // [data-sticky-th] {
    //   position: sticky;
    //   background-color: #ccc;
    // }

    [data-sticky-last-left-td] {
      box-shadow: 2px 0px 3px #ccc;
    }

    [data-sticky-first-right-td] {
      box-shadow: -2px 0px 3px #ccc;
    }
  }
}
table.table-custom {
  width: 100% !important;
  tr {
    width: 100% !important;
    min-width: 2000px !important;

    th.customth {
      width: 6% !important;
      padding: 15px !important;
    }

     td.customtd {
      width: 6% !important;
      // left: 100px !important
      padding: 15px !important;
    }
  }
}
`;

