import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate, useUpdateSingle } from "../../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../../components/focus";
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import { useFetchCustomerRunningNotDropDown, useFetchLatePaymentDropDown, useFetchMaterialTypeDropDown, useFetchPaymentMethodDropDown, useFetchProductAllowDropDown } from "../../../hooks/DropDown_Api";
import CreatableSelect from "react-select/creatable";
const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}


interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});

const Add_Customer_Condition = ({ setCurrentStep, currentStep }: any) => {
    let pageName = "Customer Condition"
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()



    const apiname = process.env.REACT_APP_SALES_CUSTOMER_CONDITION_API?.toString()


    const { mutate: Condition, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)

    const { register, handleSubmit, setError, formState, reset, setValue, getValues }: any = useForm({
        mode: "onChange"
    });
    const { errors } = formState;



    const onSubmit = (data: any) => {

        if (isTargettedMonthly === 'no') {
            delete data?.targeted_monthly_amount
        }

        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            const formvalue: any = value



            if (typeof value !== "object") {
                if (value != undefined) {
                    formData.append(key, formvalue)
                }
            }
            if (Array.isArray(value) && key === "product_type") {
                value.forEach((val, index) => formData.append(`product_type`, val))
            }

        }
        Condition(formData)
    }



    const apinameForBasicDetails = process.env.REACT_APP_EMPLOYEE_API?.toString()
    const { mutate: BasicDetails, isSuccess: basicDetailsIsSuccess, isError: basicDetailsIsError, error: basicDetailsError, data: basicDetailsData }: any = useUpdateSingle()

    let rawData = localStorage.getItem('Add Data');
    let addData = rawData ? JSON.parse(rawData) : null;

    let basicId = addData ? addData.id : null;




    useEffect(() => {
        if (isSuccess && data) {


            let tempId = {
                customer_condition: data?.data?.id
            }

            let tempData = {
                id: basicId,
                apiname: apinameForBasicDetails,
                data: tempId
            }
            BasicDetails(tempData);
            // BasicDetails({})

        }
    }, [data, isSuccess])

    let rawDataPersonType = localStorage.getItem('PersonType');
    let addDataPersonType = rawDataPersonType ? JSON.parse(rawDataPersonType) : null;
    let personTypeLabel = addDataPersonType?.label === 'Employee'


    useEffect(() => {
        if (basicDetailsIsSuccess && basicDetailsData) {

            localStorage.setItem('Add Data', JSON.stringify(basicDetailsData?.data))
            if (!personTypeLabel) {

                const currentPath = window.location.pathname;
                if (currentPath === '/addCustomerDetails') {
                    setCurrentStep(currentStep + 1)
                };
            } else {
                navigate('/listPersonDetail')
                localStorage.removeItem('PersonType')
                localStorage.removeItem('Add Data')

            }
        }
    }, [basicDetailsIsSuccess, basicDetailsData])


    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            // navigate('/listPersonDetailGrade', { state: { showToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    };

    const handleReset = () => {
        reset()
    }

    const [isTargettedMonthly, setIsTargettedMonthly] = useState("no")

    const handleTragettedMonthlyBox = (e: any) => {
        setIsTargettedMonthly(e.target.value)
    }





    const [valuePaymentMethod, setValuePaymentMethod]: any = useState();
    const [optionsPaymentMethod, setOptionsPaymentMethod]: any = useState([]);
    const [valuePaymentMethodCreatable, setValuePaymentMethodCreatable] = useState<Option | null>();

    const apinameForPaymentMethod = process.env.REACT_APP_SALES_CUSTOMER_PAYMENT_METHOD_API?.toString()
    const { mutate: mutatePaymentMethod, data: dataPaymentMethod, isError: PaymentMethodIsError, isLoading: PaymentMethodLoading, isSuccess: PaymentMethodSuccess, error: PaymentMethodError }: any = useCreate(apinameForPaymentMethod)

    const { data: PaymentMethodData, refetch: PaymentMethodRefetch } = useFetchPaymentMethodDropDown()

    useEffect(() => {
        setOptionsPaymentMethod(PaymentMethodData)
    }, [PaymentMethodData])

    const handleChangePaymentMethod = (e: any) => {

        setValue("customer_payment_method", `${e?.value}`)
        setValuePaymentMethodCreatable(null)

    }


    const handlePaymentMethodCreate = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutatePaymentMethod({ name: newOption.name, status: newOption.status })
            setOptionsPaymentMethod((prev: any) => [...prev, newOption]);
            setValuePaymentMethodCreatable(newOption);
        }, 0);


    };


    useEffect(() => {
        (async () => {
            if (PaymentMethodSuccess && optionsPaymentMethod) {
                await PaymentMethodRefetch();
                setValue("customer_payment_method", PaymentMethodData[0]?.value)
            }
        })();

        return () => {
        };
    }, [PaymentMethodSuccess, PaymentMethodData]);




    const [valueLatePaymentInterest, setValueLatePaymentInterest]: any = useState();
    const [optionsLatePaymentInterest, setOptionsLatePaymentInterest]: any = useState([]);
    const [valueLatePaymentInterestCreatable, setValueLatePaymentInterestCreatable] = useState<Option | null>();

    const apinameForLatePaymentInterest = process.env.REACT_APP_SALES_LATE_PAYMENT_INTEREST_API?.toString()
    const { mutate: mutateLatePaymentInterest, data: dataLatePaymentInterest, isError: LatePaymentInterestIsError, isLoading: LatePaymentInterestLoading, isSuccess: LatePaymentInterestSuccess, error: LatePaymentInterestError }: any = useCreate(apinameForLatePaymentInterest)

    const { data: LatePaymentInterestData, refetch: LatePaymentInterestRefetch } = useFetchLatePaymentDropDown()

    useEffect(() => {
        setOptionsLatePaymentInterest(LatePaymentInterestData)
    }, [LatePaymentInterestData])

    const handleChangeLatePaymentInterest = (e: any) => {

        setValue("late_payment_interest", `${e?.value}`)
        setValueLatePaymentInterestCreatable(null)

    }


    const handleLatePaymentInterestCreate = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutateLatePaymentInterest({ name: newOption.name, status: newOption.status })
            setOptionsLatePaymentInterest((prev: any) => [...prev, newOption]);
            setValueLatePaymentInterestCreatable(newOption);
        }, 0);


    };


    useEffect(() => {
        (async () => {
            if (LatePaymentInterestSuccess && optionsLatePaymentInterest) {
                await LatePaymentInterestRefetch();
                setValue("late_payment_interest", LatePaymentInterestData[0]?.value)
            }
        })();

        return () => {
        };
    }, [LatePaymentInterestSuccess, LatePaymentInterestData]);




    const [valueCustomerRunningNot, setValueCustomerRunningNot]: any = useState();
    const [optionsCustomerRunningNot, setOptionsCustomerRunningNot]: any = useState([]);
    const [valueCustomerRunningNotCreatable, setValueCustomerRunningNotCreatable] = useState<Option | null>();

    const apinameForCustomerRunningNot = process.env.REACT_APP_SALES_CUSTOMER_RUNNING_NOT_API?.toString()
    const { mutate: mutateCustomerRunningNot, data: dataCustomerRunningNot, isError: CustomerRunningNotIsError, isLoading: CustomerRunningNotLoading, isSuccess: CustomerRunningNotSuccess, error: CustomerRunningNotError }: any = useCreate(apinameForCustomerRunningNot)

    const { data: CustomerRunningNotData, refetch: CustomerRunningNotRefetch } = useFetchCustomerRunningNotDropDown()

    useEffect(() => {
        setOptionsCustomerRunningNot(CustomerRunningNotData)
    }, [CustomerRunningNotData])

    const handleChangeCustomerRunningNot = (e: any) => {

        setValue("customer_running_not", `${e?.value}`)
        setValueCustomerRunningNotCreatable(null)

    }


    const handleCustomerRunningNotCreate = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutateCustomerRunningNot({ name: newOption.name, status: newOption.status })
            setOptionsCustomerRunningNot((prev: any) => [...prev, newOption]);
            setValueCustomerRunningNotCreatable(newOption);
        }, 0);


    };


    useEffect(() => {
        (async () => {
            if (CustomerRunningNotSuccess && optionsCustomerRunningNot) {
                await CustomerRunningNotRefetch();
                setValue("customer_running_not", CustomerRunningNotData[0]?.value)
            }
        })();

        return () => {
        };
    }, [CustomerRunningNotSuccess, CustomerRunningNotData]);



    const [valueProductAllow, setValueProductAllow]: any = useState();
    const [optionsProductAllow, setOptionsProductAllow]: any = useState([]);
    const [valueProductAllowCreatable, setValueProductAllowCreatable] = useState<Option | null>();

    const apinameForProductAllow = process.env.REACT_APP_SALES_PRODUCT_ALLOW_API?.toString()
    const { mutate: mutateProductAllow, data: dataProductAllow, isError: ProductAllowIsError, isLoading: ProductAllowLoading, isSuccess: ProductAllowSuccess, error: ProductAllowError }: any = useCreate(apinameForProductAllow)

    const { data: ProductAllowData, refetch: ProductAllowRefetch } = useFetchProductAllowDropDown()

    useEffect(() => {
        setOptionsProductAllow(ProductAllowData)
    }, [ProductAllowData])

    const handleChangeProductAllow = (e: any) => {

        setValue("product_allow", `${e?.value}`)
        setValueProductAllowCreatable(null)

    }


    const handleProductAllowCreate = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutateProductAllow({ name: newOption.name, status: newOption.status })
            setOptionsProductAllow((prev: any) => [...prev, newOption]);
            setValueProductAllowCreatable(newOption);
        }, 0);


    };


    useEffect(() => {
        (async () => {
            if (ProductAllowSuccess && optionsProductAllow) {
                await ProductAllowRefetch();
                setValue("product_allow", ProductAllowData[0]?.value)
            }
        })();

        return () => {
        };
    }, [ProductAllowSuccess, ProductAllowData]);


    const [options, setOptions]: any = useState([])
    const [typeValue, setTypeValue]: any = useState()

    const { data: typeData, refetch: typeRefetch } = useFetchMaterialTypeDropDown()
    useEffect(() => {

        setOptions(typeData)
    }, [typeData])
    const handleChangeMachine = (e: any) => {



        const type = e.map((value: any) => {
            return value.value
        })

        setValue("product_type", type)
        setTypeValue(e)
        // setSelMachine(e)
    }



    const currentPath = window.location.pathname;





    return (
        <div>
            <div className="" >
                <section className="content-header py-1 px-3">
                    {/* <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Add {pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">{pageName}</Link></li>
                                    <li className="breadcrumb-item active">Add {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div> */}
                </section>
                <section className="">
                    <div className="">
                        <div className="card card-default">
                            <div className="row">
                                <p className="mb-0 " style={{ fontSize: '14px' }}>{`${addData?.person_type?.name}-${addData?.name}-${addData?.extra_response?.city_name_and_distict_name?.village_name ? addData?.extra_response?.city_name_and_distict_name?.village_name : addData?.extra_response?.city_name_and_distict_name?.city_name}`}</p>
                            </div>
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Source Name</span></h5>
                            </div> */}
                            <form ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-3 mt-2">
                                                    <label >Customer Payment Method <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValuePaymentMethod(null)
                                                            setValue(null)
                                                        }}
                                                        // classNames="creatable-select"
                                                        {...register("customer_payment_method", { required: { value: true, message: "Customer Payment Method is required" } })}
                                                        placeholder={'Select Customer Payment Method'}
                                                        components={customCreatableIndicator}
                                                        // menuIsOpen={true}
                                                        options={optionsPaymentMethod}
                                                        onCreateOption={handlePaymentMethodCreate}
                                                        onChange={handleChangePaymentMethod}
                                                        value={valuePaymentMethodCreatable == null ? valuePaymentMethod : valuePaymentMethodCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.customer_payment_method?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label >Customer Credit Day Limit <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="number" className="form-control" placeholder="Customer Credit Day Limit" {...register("customer_credit_day_limit", { onChange: handleValue, required: { value: false, message: "Customer Credit Day Limit is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.customer_credit_day_limit?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >Customer Credit Amount Limit <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="number" className="form-control" placeholder="Customer Credit Amount Limit" {...register("customer_credit_amount_limit", { onChange: handleValue, required: { value: false, message: "Customer Credit Amount Limit is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.customer_credit_amount_limit?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label >Late Payment Interest % <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueLatePaymentInterest(null)
                                                            setValue(null)
                                                        }}
                                                        // classNames="creatable-select"
                                                        {...register("late_payment_interest", { required: { value: true, message: "Late Payment Interest % is required" } })}
                                                        placeholder={'Select Late Payment Interest %'}
                                                        components={customCreatableIndicator}
                                                        // menuIsOpen={true}
                                                        options={optionsLatePaymentInterest}
                                                        onCreateOption={handleLatePaymentInterestCreate}
                                                        onChange={handleChangeLatePaymentInterest}
                                                        value={valueLatePaymentInterestCreatable == null ? valueLatePaymentInterest : valueLatePaymentInterestCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.late_payment_interest?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label >Customer Runnig-Not <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueCustomerRunningNot(null)
                                                            setValue(null)
                                                        }}
                                                        // classNames="creatable-select"
                                                        {...register("customer_running_not", { required: { value: true, message: "Customer Runnig-Not is required" } })}
                                                        placeholder={'Select Customer Runnig-Not'}
                                                        components={customCreatableIndicator}
                                                        // menuIsOpen={true}
                                                        options={optionsCustomerRunningNot}
                                                        onCreateOption={handleCustomerRunningNotCreate}
                                                        onChange={handleChangeCustomerRunningNot}
                                                        value={valueCustomerRunningNotCreatable == null ? valueCustomerRunningNot : valueCustomerRunningNotCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.customer_running_not?.message}</span>
                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label >Product Type <span style={{ color: "red" }}>*</span></label>
                                                    <Select
                                                        {...register("product_type", { required: { value: true, message: "Product Type is required" } })}
                                                        placeholder={'Machine Usd Mould	& Plant Number'}
                                                        options={options}
                                                        onChange={handleChangeMachine}
                                                        value={typeValue}
                                                        components={customIndicator}
                                                        isMulti
                                                    // className="dropdown-select"
                                                    />
                                                    {/* <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenHSN(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div> */}
                                                    <span className='text-red w-100'>{errors?.product_type?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label >Product Allow <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueProductAllow(null)
                                                            setValue(null)
                                                        }}
                                                        // classNames="creatable-select"
                                                        {...register("late_payment_interest", { required: { value: true, message: "Product Allow is required" } })}
                                                        placeholder={'Select Product Allow'}
                                                        components={customCreatableIndicator}
                                                        // menuIsOpen={true}
                                                        options={optionsProductAllow}
                                                        onCreateOption={handleProductAllowCreate}
                                                        onChange={handleChangeProductAllow}
                                                        value={valueProductAllowCreatable == null ? valueProductAllow : valueProductAllowCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.product_allow?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label >Targetted Monthly Box <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group">
                                                        <select className="form-select" {...register("targeted_monthly_box", { onChange: handleTragettedMonthlyBox, required: { value: false, message: "Targetted Monthly Box is required" } })}>
                                                            <option value={""} selected>Select Any</option>
                                                            <option value={"yes"} >Yes</option>
                                                            <option value={"no"} >No</option>

                                                        </select>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.targeted_monthly_box?.message}</span>
                                                </div>


                                                {isTargettedMonthly === "yes" ?
                                                    <>
                                                        <div className="col-sm-3 mt-2">
                                                            <label >Targetted Monthly Amount <span style={{ color: "red" }}>*</span></label>
                                                            <div className="input-group">
                                                                <input type="number" className="form-control" placeholder="Targetted Monthly Amount" {...register("targeted_monthly_amount", { onChange: handleValue, required: { value: isTargettedMonthly === "yes" ? true : false, message: "Targetted Monthly Amount is required" } })} />
                                                                <div className="input-group-append" >
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                    // style={{backgroundColor:"blue",color:"green"}} 
                                                                    /></span>

                                                                </div>
                                                            </div>
                                                            <span className='text-red w-100'>{errors?.targeted_monthly_amount?.message}</span>
                                                        </div>
                                                    </>
                                                    : ""
                                                }



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Field is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>

                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center " style={{display:'flex',justifyContent:'space-between'}}>
                                                    <div>
                                                    {currentPath === '/addCustomerDetails' && personTypeLabel ?
                                                        <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "#fd7e14", background: "white", color: "#fd7e14" }} onClick={() => navigate('/listPersonDetail')}>Skip</button>
                                                        :
                                                        null
                                                    }

                                                    {currentPath === '/addCustomerDetails' ?
                                                        <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "#fd7e14", background: "white", color: "#fd7e14" }} onClick={() => setCurrentStep(currentStep + 1)}>Skip</button>
                                                        : null}

                                                    {currentPath === '/addCustomerDetails' ?
                                                        <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => setCurrentStep(currentStep - 1)}>Back</button>
                                                        : null}
                                                    </div>
                                                    <div>

                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        
                                                        onClick={handleSubmit(onSubmit)}
                                                        >Submit</button>
                                                    <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>
                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetail")}>Cancel</button>
                                                        </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Add_Customer_Condition