
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../../components/Cell/Status";
import Actions from "../../../components/Cell/Actions";


interface Data {
  selection: any

  type:any
  employee_code:any
  address_type:any
  premises_type:any
  number:any
  bunglow_name:any
  building_name:any
  society_name:any
  street:any
  landmark:any
  area:any
  city:any
  mobile_number:any
  pincode:any
  district:any
  state:any
  country:any
  taluka:any


  created_at:any

  created_by:any
  updated_by:any
  updated_at:any
  status: any;
  actions: any;
}


export const titleofheaders=[
  {
    id: `srno`,
    Header: `Sr No`,
  },
  {
    id: `type`,
    Header: `Type`,
  },
  {
    id: `employee_code`,
    Header: `Employee Code & Name`,
  },
  {
    id: `address_type`,
    Header: `Address Type`,
  },
  {
    id: `premises_type`,
    Header: `Building Number Type`,
  },
  {
    id: `number`,
    Header: `Number`,
  },

  {
    id: `bunglow_name`,
    Header: `Bunglow Name`,
  },
  {
    id: `building_name`,
    Header: `Building Name`,
  },
  {
    id: `society_name`,
    Header: `Society Name`,
  },
  {
    id: `street`,
    Header: `Street`,
  },
  {
    id: `landmark`,
    Header: `Landmark`,
  },


  {
    id: `area`,
    Header: `Area`,
  },
  {
    id: `city`,
    Header: `City`,
  },
  {
    id: `mobile_number`,
    Header: `Mobile Number`,
  },
  {
    id: `pincode`,
    Header: `Pincode`,
  },
  {
    id: `district`,
    Header: `District`,
  },
  {
    id: `taluka`,
    Header: `Taluka`,
  },
  {
    id: `state`,
    Header: `State`,
  },
  {
    id: `country`,
    Header: `Country`,
  },
  {
    id: 'status',
    Header: 'Status',
  },


  {
    id: 'created_at',
    Header: `Created At`,
  },


  {
    id: 'created_by',
    Header: `Created By`,

 
  },
  {
    id: 'updated_at',
    Header: `Updated At`,

  },
  {
    id: 'updated_by',
    Header: `Updated By`,

  },





]


export const Columns_Address_Details: any = [



  {
    id: `srno`,
    Header: () => <div style={{display:"inline"}}>Sr.<br/>No</div>,
    accessor:'srno',
    sticky:"left",
},



// {
//   id: `employee_code`,
//   Header: () => <div style={{display:"inline"}}>Employee Code<br/>& Name</div>,
//   accessor:'employee_code',
//   sticky:"left",
//   Cell: ({ row }: any) => {
//     return(
// `${row.original?.employee_code?.code}::${row.original?.employee_code?.name}`
//     )
//     }

// },

// {
//   id: `type`,
//   Header: `Type`,
//   accessor:'type',
 
//   Cell: ({ row }: any) => row.original.type?.name
// },

{
  id: `address_type`,
  Header: () => <div style={{display:"inline"}}>Address<br/>Type</div>,
  accessor:'address_type',
  sticky:"left",
  Cell: ({row}:any) => {
    const convertToUpper = row.original.address_type?.name
    if (!convertToUpper) return "";
    // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
    return convertToUpper?.split(" ").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
  }
},
{
  id: `premises_type`,
  Header: () => <div style={{display:"inline"}}>Building <br/>Type</div>,
  accessor:'premises_type',
  Cell: ({row}:any) => {
    const convertToUpper = row.original.premises_type
    if (!convertToUpper) return "";
    // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
    return convertToUpper.split(" ").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
  }
},
{
  id: `number`,
  Header: `Number`,
  accessor:'number',
},

{
  id: `bunglow_name`,
  Header: () => <div style={{display:"inline"}}>Bunglow<br/>Name</div>,
  accessor:'bunglow_name',
  Cell: ({ row }: any) => (row.original.bunglow_name?row.original.bunglow_name:<span className="text-danger">xxxx</span>)
},
{
  id: `building_name`,
  Header: () => <div style={{display:"inline"}}>Building<br/>Name</div>,
  accessor:'building_name',
},
{
  id: `society_name`,
  Header: () => <div style={{display:"inline"}}>Society<br/>Name</div>,
  accessor:'society_name',
  Cell: ({ row }: any) => (row.original.society_name?row.original.society_name:<span className="text-danger">xxxx</span>)
},
{
  id: `street`,
  Header: `Street`,
  accessor:'street',
},
{
  id: `landmark`,
  Header: `Landmark`,
  accessor:'landmark',
},


{
  id: `area`,
  Header: `Area`,
  accessor:'area',
},
{
  id: `city`,
  Header: `City`,
  accessor:'city',
  Cell: ({ row }: any) => row.original?.city?.city_name?row.original?.city?.city_name:row.original?.city?.village_name
},
{
  id: `district`,
  Header: `District`,
  accessor:'district',
  Cell: ({ row }: any) => row.original?.city?.district?.name
},
{
  id: `state`,
  Header: `State`,
  accessor:'state',
  Cell: ({ row }: any) => row.original?.city?.state?.name
},
{
  id: `country`,
  Header: `Country`,
  accessor:'country',
  Cell: ({ row }: any) => row.original?.city?.country?.name
},
{
  id: `taluka`,
  Header: `Taluka`,
  accessor:'taluka',
  Cell: ({ row }: any) => (row.original.city.taluka?.name?row.original.city.taluka?.name:<span className="text-danger">xxxx</span>)
},
{
  id: `mobile_number`,
  Header: () => <div style={{display:"inline"}}>Mobile<br/>Number</div>,
  accessor:'mobile_number',
},
// {
//   id: `pincode`,
//   Header: `Pincode`,
//   accessor:'pincode',
//   Cell: ({ row }: any) => row.original.pincode?.pincode
// },


{
  id: `pincode`,
  Header: () => <div style={{display:"inline"}}>Pin<br/>Code</div>,
  accessor: `pincode`,
  Cell: ({ row }: any) => {
    let color = row.original?.city?.pincode?.map((e:any)=>{
      return e?.pincode
    })
    return(
      <div>
        {color?.join(",")}
      </div>
    )
}
},










{
  id: 'remark',
  Header: 'Re-Mark',
  accessor:'remark',


},


{
  id: 'status',
  Header: 'Status',
  accessor:'status',


},


{
    id: 'created_at',
    Header: () => <div style={{display:"inline"}}>Created<br/>At</div>,
    accessor:'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{display:"inline"}}>Created<br/>By</div>,
    accessor:'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username

 
  },
  {
    id: 'updated_at',
    Header: () => <div style={{display:"inline"}}>Updated<br/>At</div>,
    accessor:'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{display:"inline"}}>Updated<br/>By</div>,
    accessor:'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },

  {
    id: 'Stepper_Actions',
    Header: 'Actions',


},

{
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
        const [data, setData] = useState([])

        const columns: any = useMemo(() => Columns_Address_Details, [])
        const defaultColumn: any = useMemo(() => {
            return {
                Filter: Columnlevel
            }
        }
            , [])

        const tableInstance: any = useTable({
            columns,
            data: data,
            defaultColumn,

        },


        )
        const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
        let { selectedFlatRows } = tableInstance
        const { } = useMutation((deleteid: any) => {
            const temp_data = {
                id: deleteMultipleid
            }
            return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADDRESS_API}/deletelist/`, temp_data,
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
        });
        return (
            <>
                <div>
                    <Checkbox

                        onClick={() => {
                            setDeleteMultipleid(() => {
                                const temp = selectedFlatRows?.map((e: any) => {
                                    return e.original.id;
                                });
                                return temp;
                            });
                        }}

                        {...getToggleAllRowsSelectedProps()} />
                </div>
            </>
        );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
        // Add your onClick function here
    }} />
},
]



