import axios from "axios";

export function patchApiHelper(url:string,data:Object ) {
    return axios.patch(`${process.env.REACT_APP_BASE_URL}${url}`, 
      
      data,{
        headers:{
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
    .then(response => response.data)
    .catch(error => console.error(error));
  }