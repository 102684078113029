
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import Actions from "../../components/Cell/Actions";
// import { updateBrand, useFetchBrands } from "../../hooks/brand";
// import noImage from "../../images/noImage.png"
import { useFetchGst, useUpdateGst } from "../../hooks/GST";
import noImage from "../../images/noImage.png"
import Modal from 'react-bootstrap/Modal';


interface Data {
    selection: any
    type:any
    code:any
    salary_type:any
    surname:any
    name:any
    father_name:any
    department:any
    designation:any
    gender:any
    title:any
    weekly_off:any
    qualification:any
    joining_date:any
    bith_date:any
    age:any
    martial_status:any
    anniversary_date:any
    nationality:any
    religion:any
    caste:any
    blood_group:any
    reference:any
    balance_sheet_group:any
    employee_status:any




    image:any
    document_pdf:any
    agreement_pdf:any
    status: any;
    created_at:any
    created_by: any
    updated_by: any
    updated_at: any
      
    actions: any;
}


export const titleofheaders=[
    {
      id: `srno`,
      Header: `Sr No`,
    },
    {
      id: `type`,
      Header: "Type",
    },
    {
      id: `code`,
      Header: "Employee Code",
    },


    {
        id: `salary_type`,
        Header: "Salary Type",
      },

      {
        id: `surname`,
        Header: "Employee Surname",
      },

      {
        id: `name`,
        Header: "Employee Name",
      },

      {
        id: `father_name`,
        Header: "Employee Father Name",
      },

      {
        id: `department`,
        Header: "Department",
      },


      {
        id: `designation`,
        Header: "Designation",
      },
      {
        id: `gender`,
        Header: "Gender",
      },
      {
        id: `title`,
        Header: "Title",
      },
      {
        id: `weekly_off`,
        Header: "Weekly Off",
      },
      {
        id: `qualification`,
        Header: "Qualification",
      },
      {
        id: `joining_date`,
        Header: "Joining Date",
      },
      {
        id: `bith_date`,
        Header: "Birth Date",
      },
      {
        id: `age`,
        Header: "Age",
      },



      {
        id: `marital_status`,
        Header: "Marital Status",
      },   
      
      {
        id: `anniversary_date`,
        Header: "Anniversary Date",
      },   
      
      {
        id: `nationality`,
        Header: "Nationality",
      },   
      
      {
        id: `religion`,
        Header: "Religion",
      },   
      
      {
        id: `caste`,
        Header: "Caste",
      },   
      
      {
        id: `blood_group`,
        Header: "Blood Group",
      },   
      
      {
        id: `reference`,
        Header: "Reference",
      },   
      
      {
        id: `balance_sheet_group`,
        Header: "Balance Sheet Group",
      },

      {
        id: `employee_status`,
        Header: "Department",
      },














    {
        id: 'status',
        Header: "Status",
      },
  
    {
      id: 'created_at',
      Header: "Created At",
    },
    {
      id: 'created_by',
      Header: "Created By",
    },
    {
      id: 'updated_at',
      Header: "Updated At",
    },
    {
      id: 'updated_by',
      Header: "Updated By",
    },

    
  ]





export const Columns_Employee_Daily: any = [



    {
        id: `srno`,
        Header: () => <div>Sr<br/>No</div>,
        sticky:"left",

    },

    {
        id: `code`,
        Header: () => <div style={{display:"inline"}}>Employee<br/>Code</div>,
        accessor: 'code',
        sticky:"left",


        // Cell: ({ row }: any) => {
        //     return (
        //         <div>
        //             {row?.original?.code}
        //         </div>
        //     )
        // }

    },
    
    {
        id: `group`,
        Header: () => <div style={{display:"inline"}}>Account<br/>Group</div>,
        // Header: `Type`,
        accessor: 'group',
        
        Cell: ({ row }: any) => {
          return (
              <div>
                  {row.original.group?.category?.name}
              </div>
          )
      }
    },
    {
        id: `type`,
        Header: () => <div style={{display:"inline"}}>Account<br/>Type</div>,
        // Header: `Type`,
        accessor: 'type',
        
        Cell: ({ row }: any) => {
          return (
              <div>
                  {row.original.group?.accountStatementType?.name}
              </div>
          )
      }
    },
    {
        id: `salary_type`,
        Header: () => <div>Salary<br/>Type</div>,
        accessor: 'salary_type',
        // Cell: ({row}:any) => {
        //   const convertToUpper = row.original.salary_type?.name
        //   if (!convertToUpper) return "";
        //   // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
        //   return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
         
        // }

        Cell: ({ row }: any) => {
            return (
                <div>
                    {row.original.salary_type?.name}
                </div>
            )
        }

    },

    {
      id: 'gender',
      Header: 'Gender',
      accessor:'gender',
  
      Cell: ({ row }: any) => {
        return (
            <div>
                {row.original.gender}
            </div>
        )
    }
    },


    {
      id: 'title',
      Header: 'Title',
      accessor:'title',
  
      Cell: ({ row }: any) => {
        return (
            <div>
                {row.original.title?.name}
            </div>
        )
    }
    },

   
    {
      id: 'name',
      Header: () => <div style={{display:"inline"}}>Employee First<br/>Name</div>,
      accessor:'name',
  
   
    },

    {
      id: 'father_name',
      Header: () => <div style={{display:"inline"}}>Employee Middle<br/>Name</div>,
      accessor:'father_name',
  
   
    },
    {
      id: 'surname',
      Header: () => <div style={{display:"inline"}}>Employee Last<br/>Name</div>,
      accessor:'surname',
  
   
    },

    {
      id: 'city_name_and_distict_name',
      Header: () => <div style={{display:"inline"}}>City Name &<br/>District Name</div>,
      accessor:'city_name_and_distict_name',
      Cell: ({ row }: any) => {
        return (
            <div>
                {`${row.original.city_name_and_distict_name?.city_name?row.original.city_name_and_distict_name?.city_name:row.original.city_name_and_distict_name?.village_name}::${row.original.city_name_and_distict_name?.district?.name}`}
            </div>
        )
    }
  
   
    },
    {
      id: 'department',
      Header: 'Department',
      accessor:'department',
      Cell: ({ row }: any) => {
        return (
            <div>
                {row.original.department?.department}
            </div>
        )
    }
  
   
    },


    {
      id: 'designation',
      Header: 'Designation',
      accessor:'designation',
      Cell: ({ row }: any) => {
        return (
            <div>
                {row.original.designation?.designation}
            </div>
        )
    }
  
   
    },


  

    


    {
      id: 'qualification',
      Header: 'Qualification',
      accessor:'qualification',
  
   
    },

    {
      id: 'weekly_off',
      Header: () => <div style={{display:"inline"}}>Weekly Off<br/>Day Name</div>,
      accessor:'weekly_off',
      Cell: ({ row }: any) => {
        return (
            <div>
                {row.original.weekly_off?.name}
            </div>
        )
    }
   
    },

    {
      id: 'administration_type',
      Header: () => <div style={{display:"inline"}}>Administration<br/>Type</div>,
      accessor:'administration_type',
      Cell: ({ row }: any) => {
        return (
            <div>
                {row.original.administration_type?.name}
            </div>
        )
    }
   
    },
    // {
    //   id: 'joining_date',
    //   Header: () => <div style={{display:"inline"}}>Joining<br/>Date</div>,
    //   accessor:'joining_date',
  
   
    // },


    // {
    //   id: 'birth_date',
    //   Header: () => <div style={{display:"inline"}}>Birth<br/>Date</div>,
    //   accessor:'birth_date',
  
   
    // },


    // {
    //   id: 'age',
    //   Header: 'Age',
    //   accessor:'age',
  
   
    // },


    // {
    //   id: 'marital_status',
    //   Header: () => <div style={{display:"inline"}}>Marital<br/>Status</div>,
    //   accessor:'marital_status',
  
   
    // },


    // {
    //   id: 'anniversary_date',
    //   Header: () => <div style={{display:"inline"}}>Anniversary<br/>Date</div>,
    //   accessor:'anniversary_date',
    //   Cell: ({row}:any)=> row.original.anniversary_date?row.original.anniversary_date:<span style={{ color: 'red',fontSize:"20px" }}>****</span>,
   
    // },

    // {
    //   id: 'nationality',
    //   Header: 'Nationality',
    //   accessor:'nationality',
  
   
    // },

    // {
    //   id: 'religion',
    //   Header: 'Religion',
    //   accessor:'religion',
  
   
    // },

    // {
    //   id: 'caste',
    //   Header: 'Caste',
    //   accessor:'caste',
  
   
    // },

    // {
    //   id: 'blood_group',
    //   Header: () => <div style={{display:"inline"}}>Blood<br/>Group</div>,
    //   accessor:'blood_group',
  
   
    // },


    {
      id: 'reference',
      Header: () => <div style={{display:"inline"}}>Reference<br/>By</div>,

      // Header: 'Reference By',
      accessor:'reference',
      Cell: ({row}:any)=> {
      return(
        `${row.original.reference === null||row.original.reference === ""?"Other":row.original.reference?.name}`
      )
      }
   
    },
    {
      id: 'other_reference',
      Header: () => <div style={{display:"inline"}}>Other Reference<br/>By</div>,

      accessor:'other_reference',
      Cell: ({row}:any)=> row.original.other_reference?row.original.other_reference:<span style={{ color: 'red',fontSize:"20px" }}>****</span>,
   
    },

    {
      id: 're_mark',
      Header: 'Remark',
      accessor:'re_mark',
      Cell: ({row}:any)=> row.original.re_mark?row.original.re_mark:<span style={{ color: 'red',fontSize:"20px" }}>****</span>,
   
    },

    // {
    //   id: 'balance_sheet_group',
    //   Header: () => <div style={{display:"inline"}}>Balance Sheet<br/>Group</div>,
    //   accessor:'balance_sheet_group',
  
   
    // },

    {
      id: 'status',
      Header: 'Status',
      accessor:'status',
  
   
    },
    {
      id: 'created_at',
      Header: () => <div style={{display:"inline"}}>Created<br/>At</div>,
      accessor:'created_at',
      Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split(" ").join("  ") : "-")
    },

  {
      id: 'created_by',
      Header: () => <div>Created<br/>By</div>,
      accessor: 'created_by',
      Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_by?.username : "-")


  },
  {
      id: 'updated_at',
      Header: () => <div>Updated<br/>At</div>,
      accessor: 'updated_at',
      Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split(" ").join("  ") : "-")
  },
  {
      id: 'updated_by',
      Header: () => <div>Updated<br/>By</div>,
      accessor: 'updated_by',
      Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },


    {
      id: `image_all`,
      Header: () => <div style={{ display: "inline" }}>Image<br />All</div>,
      Cell: ({ row }: any) => {
        const [deleteConfirm, setDeleteConfirm] = useState(false)
        return (
          <>
            <div >
  
              {!row.original.image_all ? <>
                <a
  
                  href="#"
  
                >
                  <img src={noImage} height={60} width={100} />
                </a>
              </> : <>
                <a
                  onClick={() => setDeleteConfirm(true)}
                  href={`#galleryModal${row.original.name}`}
  
                  data-large-src={(row.original.image_all.length > 0 ? row.original.image_all.at(-1).doc : noImage)} data-toggle="modal">
                  <img src={(row.original.image_all.length > 0 ? row.original.image_all.at(-1).doc : noImage)} height={60} width={100} />
                </a>
              </>}
  
            </div>
            <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
              centered    >
  
              <Modal.Body>
                <img src={(row.original.image_all?.length > 0 ? row.original.image_all.at(-1).doc : noImage)} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid" />
  
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>
  
              </Modal.Footer>
            </Modal>
          </>)
      }
    },
  
  
  
   
  
  
    {
      id: `document_pdf`,
      Header: () => <div style={{ display: "inline" }}>Document<br />PDF All</div>,
  
      // accessor: `pdf`,
      Cell: ({ row }: any) =>
  
  
  
        <div>
          <a target="_blank" href={(row.original.document_pdf?.length > 0 ? row.original.document_pdf?.at(-1).doc : null)}>
            {(row.original.document_pdf?.length > 0 ? row.original.document_pdf?.at(-1).doc?.split('/').pop() : <span className="text-danger">No Document Uploaded</span>)}
          </a>
        </div>
  
    },
    {
      id: `agreement_pdf`,
      Header: () => <div style={{ display: "inline" }}>Agreement<br />PDF All</div>,
  
      // accessor: `pdf`,
      Cell: ({ row }: any) =>
  
  
  
        <div>
          <a target="_blank" href={(row.original.agreement_pdf?.length > 0 ? row.original.agreement_pdf?.at(-1).doc : null)}>
            {(row.original.agreement_pdf?.length > 0 ? row.original.agreement_pdf?.at(-1).doc?.split('/').pop() : <span className="text-danger">No Document Uploaded</span>)}
          </a>
        </div>
  
    },


    // {
    //   id: 'employee',
    //   Header: 'Employee',
    //   accessor:'employee',
  
   
    // },

    
    

   






    {
        id: 'Actions',
        Header: 'Actions',


    },
   

    {
        id: 'Selection',
        Header: ({ getToggleAllRowsSelectedProps }: any) => {
            const [data, setData] = useState([])

            const columns: any = useMemo(() => Columns_Employee_Daily, [])
            const defaultColumn: any = useMemo(() => {
                return {
                    Filter: Columnlevel
                }
            }
                , [])

            const tableInstance: any = useTable({
                columns,
                data: data,
                defaultColumn,

            },


            )
            const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
            let { selectedFlatRows } = tableInstance
            const { } = useMutation((deleteid: any) => {
                const temp_data = {
                    id: deleteMultipleid
                }
                return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_EMPLOYEE_API}/deletelist/`, temp_data,
                    {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    });
            });
            return (
                <>
                    <div>
                        <Checkbox

                            onClick={() => {
                                setDeleteMultipleid(() => {
                                    const temp = selectedFlatRows?.map((e: any) => {
                                        return e.original.id;
                                    });
                                    return temp;
                                });
                            }}

                            {...getToggleAllRowsSelectedProps()} />
                    </div>
                </>
            );
        },

        Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
            // Add your onClick function here
        }} />
    },
]



