
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import { updateColor, useFetchColors, useUpdateSingleColor } from "../../hooks/color/color";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import Actions from "../../components/Cell/Actions";
import { updateBrand, useFetchBrands } from "../../hooks/brand";
import noImage from "../../images/noImage.png"
import { Update, useFetch } from "../../hooks/Api";


interface Data {
    selection: any
    title: any;
    type: string;
    doc: any;
    created_at:any

    created_by: any
    updated_by: any
    updated_at: any
    actions: any;
}



export const titleofheaders=[
    {
      id: `srno`,
      Header: `Sr No`,
    },
   
    {
      id: 'status',
      Header: 'Status',
    },
  ]

export const Columns_Document: Column<Data>[] = [



    {
        id: 'Selection',
        Header: ({ getToggleAllRowsSelectedProps }: any) => {
            const [data, setData] = useState([])

            const columns: any = useMemo(() => Columns_Document, [])
            const defaultColumn: any = useMemo(() => {
                return {
                    Filter: Columnlevel
                }
            }
                , [])

            const tableInstance: any = useTable({
                columns,
                data: data,
                defaultColumn,

            },


            )
            const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
            let { selectedFlatRows } = tableInstance
            const { } = useMutation((deleteid: any) => {
                const temp_data = {
                    id: deleteMultipleid
                }
                return axios.post(`${process.env.REACT_APP_BASE_URL}document/deletelist/`, temp_data,
                    {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    });
            });
            return (
                <>
                    <div>
                        <Checkbox

                            onClick={() => {
                                setDeleteMultipleid(() => {
                                    const temp = selectedFlatRows?.map((e: any) => {
                                        return e.original.id;
                                    });
                                    return temp;
                                });
                            }}

                            {...getToggleAllRowsSelectedProps()} />
                    </div>
                </>
            );
        },

        Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
            // Add your onClick function here
        }} />
    },
    {
        id: `srno`,
        Header: `Sr.No`,
    },
    {
        id: `title`,
        Header: `Document Title`,
        accessor: `title`,



    },
    {
        id: `type`,
        Header: `Document Type`,
        accessor: `type`,



    },




    {
        id: `doc`,
        Header: `File`,
        accessor: `doc`,
        Cell: ({ row }: any) =>



            <div>
                <a target="_blank" href={row.original.doc ? row.original.doc : null}>{row.original.doc?.split('/').pop() ? row.original.doc?.split('/').pop() : <span className="text-danger">No File Uploaded</span>}</a>
            </div>

    },


    {
        id: 'created_at',
        Header: 'Created at',
        accessor:'created_at',
        Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
      },
    
    
      {
        id: 'created_by',
        Header: 'Created By',
        accessor:'created_by',
        Cell: ({ row }: any) => row.original.created_by?.username
    
     
      },
      {
        id: 'updated_at',
        Header: 'Updated At',
        accessor:'updated_at',
        Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
      },
      {
        id: 'updated_by',
        Header: 'Updated By',
        accessor:'updated_by',
        Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
      },

    {
        id: 'Actions',
        Header: 'Actions',


    },
]
