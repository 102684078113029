import React from 'react'
import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';



interface DatePickerFields {
    className?:any
    value?:any
    onChange?:any
   
}

const DatePickerComponent = ({className,value,onChange}:DatePickerFields) => {

    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {

        return  current && current  < dayjs().startOf('day');
      };
  return (
    <div>
        <DatePicker
        className={className ? className : "form-control"}
        format={dateFormatList}
        value={value}
        
        disabledDate={disabledDate}
        onChange={onChange}
        />
    </div>
  )
}

export default DatePickerComponent