import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Restricted from "../../Roles/Restricted";
// import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import axios from "axios";
import FileDownload from 'js-file-download';
import { BiDotsVerticalRounded } from "react-icons/bi";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useState } from "react";



const Customer_Details = ({ row, apiname, deletemodalask, permissionClass }: any) => {
  const [ask, setAsk]: any = useState(null);


const navigate = useNavigate()


// const [currentPage,setCurrentPage] = useState('')

let currentPage = window.location.pathname

console.log('currentPage',currentPage)


  return (
    <>
      {/* <div

        className="vertical-align-center"
        style={{
          display: "flex",
          justifyContent: "space-evenly !important",
        }}
      > */}


 {currentPage === '/listLeadgenerated'?
 
<> 
<OverlayTrigger
          // id="container"
          // trigger={"focus"}
          trigger={"click"}
          key={"bottom"}
          placement={"bottom-end"}
          overlay={
            <Popover >

              <Popover.Body >
                <div>
                <div className="m-1">
                  
                  <Link 
                  to={`/edit${apiname?.split("/").at(-1)}/${Number(row?.original.sales_inquiry_code?.company_name_and_city?.id)}`}
                    state={{ detail: 'Basic Details' }}
                    className="btn btn-primary text-white mx-2 w-100">Basic Detail</Link>
               
                 
                </div>
                <div className="m-1">

                  <Link to={`/edit${apiname?.split("/").at(-1)}/${Number(row?.original.sales_inquiry_code?.company_name_and_city?.id)}`}
                   state={{ detail: 'Personal Details' }}
                    className="btn btn-primary text-white mx-2 w-100">Personal Detail</Link>
                </div>
                <div className="m-1">
                  <Link to={`/edit${apiname?.split("/").at(-1)}/${Number(row?.original.sales_inquiry_code?.company_name_and_city?.id)}`}
                    state={{ detail: 'Contact Details' }}
                    className="btn btn-primary text-white mx-2 w-100">Contact Detail</Link>
                </div>
                <div className="m-1">
                  <Link to={`/edit${apiname?.split("/").at(-1)}/${Number(row?.original.sales_inquiry_code?.company_name_and_city?.id)}`}
                    state={{ detail: 'Addresses' }}
                    className="btn btn-primary text-white mx-2 w-100">Addresses</Link>
                </div>
                <div className="m-1">
                  <Link to={`/edit${apiname?.split("/").at(-1)}/${Number(row?.original.sales_inquiry_code?.company_name_and_city?.id)}`}
                    state={{ detail: 'Document Details' }}
                    className="btn btn-primary text-white mx-2 w-100">Document Detail</Link>
                </div>
                <div className="m-1">
                  <Link to={`/edit${apiname?.split("/").at(-1)}/${Number(row?.original.sales_inquiry_code?.company_name_and_city?.id)}`}
                    state={{ detail: 'Bank' }}
                    className="btn btn-primary text-white mx-2 w-100">Bank</Link>
                </div>
                <div className="m-1">
                  <Link to={`/edit${apiname?.split("/").at(-1)}/${Number(row?.original.sales_inquiry_code?.company_name_and_city?.id)}`}
                    state={{ detail: 'Condition' }}
                    className="btn btn-primary text-white mx-2 w-100">Condition</Link>
                </div>
                <div className="m-1">
                  <Link to={`/edit${apiname?.split("/").at(-1)}/${Number(row?.original.sales_inquiry_code?.company_name_and_city?.id)}`}
                    state={{ detail: 'Transport' }}
                    className="btn btn-primary text-white mx-2 w-100">Transport</Link>
                </div>

                {/* <button onClick={handleDispatchOrder} className="btn btn-primary text-white w-100">Download Quotation</button> */}
              </div>
              {/* <div className="m-1">
                <button onClick={(e) => {
              setAsk(e);
            }} className="btn btn-primary text-white w-100 ">E-Mail Quotation</button>
              </div> */}
            </Popover.Body>
            </Popover>
          }
        >



      <button
        // onClick={handleSubmitQuotation}
        className="btn btn-primary text-white mx-2 my-1"
        style={{fontSize:'12px'}}
      >

       Customer Details
        {/* <RiDeleteBinLine />  */}
      </button>
    </OverlayTrigger > 
</>

:

<>
<OverlayTrigger
          // id="container"
          // trigger={"focus"}
          trigger={"click"}
          key={"bottom"}
          placement={"bottom-end"}
          overlay={
            <Popover >

              <Popover.Body >
                <div>
                <div className="m-1">
                
                <Link 
                to={`/edit${apiname?.split("/").at(-1)}/${Number(row?.original.id)}`}
                  state={{ detail: 'Basic Details' }}
                  className="btn btn-primary text-white mx-2 w-100">Basic Detail</Link>
                
                 
                </div>
                <div className="m-1">

                  <Link to={`/edit${apiname?.split("/").at(-1)}/${Number(row?.original.id)}`}
                   state={{ detail: 'Personal Details' }}
                    className="btn btn-primary text-white mx-2 w-100">Personal Detail</Link>
                </div>
                <div className="m-1">
                  <Link to={`/edit${apiname?.split("/").at(-1)}/${Number(row?.original.id)}`}
                    state={{ detail: 'Contact Details' }}
                    className="btn btn-primary text-white mx-2 w-100">Contact Detail</Link>
                </div>
                <div className="m-1">
                  <Link to={`/edit${apiname?.split("/").at(-1)}/${Number(row?.original.id)}`}
                    state={{ detail: 'Addresses' }}
                    className="btn btn-primary text-white mx-2 w-100">Addresses</Link>
                </div>
                <div className="m-1">
                  <Link to={`/edit${apiname?.split("/").at(-1)}/${Number(row?.original.id)}`}
                    state={{ detail: 'Document Details' }}
                    className="btn btn-primary text-white mx-2 w-100">Document Detail</Link>
                </div>
                <div className="m-1">
                  <Link to={`/edit${apiname?.split("/").at(-1)}/${Number(row?.original.id)}`}
                    state={{ detail: 'Bank' }}
                    className="btn btn-primary text-white mx-2 w-100">Bank</Link>
                </div>
                <div className="m-1">
                  <Link to={`/edit${apiname?.split("/").at(-1)}/${Number(row?.original.id)}`}
                    state={{ detail: 'Condition' }}
                    className="btn btn-primary text-white mx-2 w-100">Condition</Link>
                </div>
                <div className="m-1">
                  <Link to={`/edit${apiname?.split("/").at(-1)}/${Number(row?.original.id)}`}
                    state={{ detail: 'Transport' }}
                    className="btn btn-primary text-white mx-2 w-100">Transport</Link>
                </div>

                {/* <button onClick={handleDispatchOrder} className="btn btn-primary text-white w-100">Download Quotation</button> */}
              </div>
              {/* <div className="m-1">
                <button onClick={(e) => {
              setAsk(e);
            }} className="btn btn-primary text-white w-100 ">E-Mail Quotation</button>
              </div> */}
            </Popover.Body>
            </Popover>
          }
        >



      <button
        // onClick={handleSubmitQuotation}
        className="btn btn-primary text-white"
      >

        <FiEdit />
        {/* <RiDeleteBinLine />  */}
      </button>
    </OverlayTrigger > 
</>}
    

         
        
      {/* </div > */}
    </>
  );
};

export default Customer_Details;

