import React, { useEffect, useState } from 'react'
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import "./Product_Tabel.css"
import ColumnVisibilityControls from './column_visibility'
import Pricelist_Calculation from './Pricelist_Calculation'



const Price_List_Table = ({ data, priceListAction, editData, rateType, mainData,currentDate,
  setPriceData,
 }: any) => {



  const [PercentageRate, setPercentageRate]: any = useState(0)
  const [HeaderCartonRate, setHeaderCartonRate]: any = useState(0)
  const [HeaderKGRate, setHeaderKGRate]: any = useState(0)
  const [HeaderStickRate, setHeaderStickRate]: any = useState(0)






  const [editableStates, setEditableStates]: any = useState([]);
  const [checkData, setCheckData]: any = useState([]);



  useEffect(() => {
    if (editableStates.length == 0) {
      let temp = data?.map((e: any, index: any) => {

        return {
          isPercentageEditable: false,
          inputPercentageValue: 0,

          isBasicRateEditable: false,
          inputBasicRateValue: 0,
          isRateEditable: false,
          inputRateValue: 0,
          isStickRateEditable: false,
          inputStickRateValue: 0,
          isOneKgEditable: false,
          inputOneKgValue: 0,


          product_code: e?.product_code,



          carton_total_nos: e?.carton_total_nos ? Number(e?.carton_total_nos) : Number(e?.product_code?.carton_total_nos),
          stick_nos: e?.stick_nos ? Number(e?.stick_nos) : Number(e?.product_code?.stick_nos),
          stick_qty: e?.stick_qty ? Number(e?.stick_qty) : Number(e?.product_code?.stick_qty),
          product_price_list_1_piece_weight: e?.product_single_piece_code?.price_list_1_pcs_weight ? Number(e?.product_single_piece_code?.price_list_1_pcs_weight) : Number(e?.product_code?.product_single_piece_code?.price_list_1_pcs_weight),






          id: e?.id,

          // old_carton_basic_rate: e?.new_carton_basic_rate ? e?.new_carton_basic_rate : 0,
          // old_carton_rate: e?.new_carton_rate ? e?.new_carton_rate : 0,
          // old_stick_rate: e?.new_stick_rate ? e?.new_stick_rate : 0,
          // old_one_kg_rate: e?.new_one_kg_rate ? e?.new_one_kg_rate : 0,
          // old_percentage_rate: editData?100:0,

          old_carton_basic_rate: e?.old_carton_basic_rate ? e?.old_carton_basic_rate : 0,
          old_carton_rate: e?.old_carton_rate ? e?.old_carton_rate : 0,
          old_stick_rate: e?.old_stick_rate ? e?.old_stick_rate : 0,
          old_one_kg_rate: e?.old_one_kg_rate ? e?.old_one_kg_rate : 0,
          old_percentage_rate: editData?100:0,


          old_new_carton_basic_rate: e.old_new_carton_basic_rate,
          old_new_carton_rate: e.old_new_carton_rate,
          old_new_stick_rate: e.old_new_stick_rate,
          old_new_one_kg_rate: e.old_new_one_kg_rate,
          old_new_percentage_rate: editData?100:0,


          show_on_off: false,
          show_on_off_duplicate: false,

          change_carton_basic_rate: 0,
          change_percentage_rate: 0,

          change_carton_rate: 0,
          change_stick_rate: 0,
          change_one_kg_rate: 0,

          new_carton_basic_rate: e?.new_carton_basic_rate ? e?.new_carton_basic_rate : 0,
          new_carton_rate: e?.new_carton_rate ? e?.new_carton_rate : 0,
          new_stick_rate: e?.new_stick_rate ? e?.new_stick_rate : 0,
          new_one_kg_rate: e?.new_one_kg_rate ? e?.new_one_kg_rate : 0,
          new_percentage_rate: e?.new_percentage_rate ? e?.new_percentage_rate : 0,

          product_rate_change_date:e.product_rate_change_date
        }
      })

      setEditableStates(temp)
      setCheckData(temp)
    }


  }, [data])

  console.log('editable',editableStates,'checkData',checkData)

  const [duplicate,setDuplicate]:any = useState([])

  useEffect(() => {
    if (editableStates.length>0 || duplicate.length>0) {
      let FinalData = [...editableStates,...duplicate]
      setPriceData(FinalData)
    }

  }, [editableStates,duplicate])


















  const enableEdit = (index: any, field: any) => {
    const updatedStates = [...editableStates];
    if (!editableStates[index]?.show_on_off) {
      updatedStates[index][`is${field}Editable`] = true;
    }
    setEditableStates(updatedStates);
  };

  const [forUp, setForUp]: any = useState()
  const [forDown, setForDown]: any = useState()


  const handleInputChange = (index: any, field: any, value: any) => {

    const updatedStates = [...editableStates];

    let ChangeFieldName = `change_${field}`
    let OldFieldName = `old_${field}`
    let NewFieldName = `new_${field}`

    const parsedValue = parseInt(value) || 0;



    updatedStates[index][ChangeFieldName] = parseInt(value);


    if (updatedStates[index].show_on_off && HeaderCartonRate && HeaderCartonRate !== 0) {
      

      if (priceListAction === "Up Price") {
        updatedStates[index][NewFieldName] = parseInt(updatedStates[index][OldFieldName]) + parseInt(HeaderCartonRate) || 0;
      } else if (priceListAction === "Down Price") {
        updatedStates[index][NewFieldName] = parseInt(updatedStates[index][OldFieldName]) - parseInt(HeaderCartonRate) || 0;
      }

    } else if (updatedStates[index].show_on_off && HeaderStickRate && HeaderStickRate !== 0) {
      if (priceListAction === "Up Price") {
        updatedStates[index][NewFieldName] = parseInt(updatedStates[index][OldFieldName]) + parseInt(HeaderStickRate) || 0;
      } else if (priceListAction === "Down Price") {
        updatedStates[index][NewFieldName] = parseInt(updatedStates[index][OldFieldName]) - parseInt(HeaderStickRate) || 0;
      }

    } else if (updatedStates[index].show_on_off && HeaderKGRate && HeaderKGRate !== 0) {

      if (priceListAction === "Up Price") {
        updatedStates[index][NewFieldName] = parseInt(updatedStates[index][OldFieldName]) + parseInt(HeaderKGRate) || 0;
      } else if (priceListAction === "Down Price") {
        updatedStates[index][NewFieldName] = parseInt(updatedStates[index][OldFieldName]) - parseInt(HeaderKGRate) || 0;
      }

    }

    else if (updatedStates[index].show_on_off && PercentageRate && PercentageRate !== 0) {

      if (priceListAction === "Up Price") {
        updatedStates[index][NewFieldName] = parseInt(updatedStates[index][OldFieldName]) + parseInt(PercentageRate) || 0;
      } else if (priceListAction === "Down Price") {
        updatedStates[index][NewFieldName] = parseInt(updatedStates[index][OldFieldName]) - parseInt(PercentageRate) || 0;
      }

    }


    setEditableStates(updatedStates);
  };





  const disableEdit = (index: any, field: any) => {
    const updatedStates = [...editableStates];

    if (!editableStates[index]?.show_on_off) {
      updatedStates[index][`is${field}Editable`] = false;
    }
    setEditableStates(updatedStates);
  };


  const [showOnOff, setShowOnOff]: any = useState()




  const handleMainShowOff = () => {
    setShowOnOff(!showOnOff);



    if (HeaderCartonRate && HeaderCartonRate !== 0) {

      var headerRate = showOnOff ? 0 : Number(HeaderCartonRate);
    } else if (HeaderStickRate && HeaderStickRate !== 0) {
      var headerRate = showOnOff ? 0 : Number(HeaderStickRate);

    }

    else if (HeaderKGRate && HeaderKGRate !== 0) {
      var headerRate = showOnOff ? 0 : Number(HeaderKGRate);

    }

    else if (PercentageRate && PercentageRate !== 0) {
      var headerRate = showOnOff ? 0 : Number(PercentageRate);

    }



    if (rateType === 'Carton Rate') {
      const updatedStates = editableStates.map((state: any) => {
        
        var difference = (Number(state?.old_carton_rate) - Number(headerRate))
        return {

          ...state,
          show_on_off: !showOnOff,
          product_rate_change_date:currentDate,
          show_on_off_duplicate: false,
          change_carton_rate: !showOnOff ? (Number(headerRate)) : 0,
          change_stick_rate: !showOnOff ? ((

            (Number(headerRate) / Number((state?.carton_total_nos) || 1)) *
            (Number(state?.stick_nos))

          )).toFixed(1) : 0,
          change_one_kg_rate: !showOnOff ? (


            Number(headerRate) /
            (((Number((state?.product_price_list_1_piece_weight) || 1) * Number((state?.carton_total_nos) || 1)) / 1000))
          ).toFixed(1) : 0,

          change_percentage_rate: priceListAction === "Up Price" ?

            !showOnOff ? (
              Number(((Number(HeaderCartonRate)) /

                Number((state?.old_carton_rate) || 1)) * 100)

            )
              .toFixed(1) : 0 :

            !showOnOff ? (
              Number(((Number(difference) - Number(state?.old_carton_rate)) /

                Number((state?.old_carton_rate) || 1)) * 100)

            )
              .toFixed(1) : 0,

          new_carton_rate: priceListAction === "Up Price" ? !showOnOff ? (Number(state.old_carton_rate) + Number(headerRate)).toFixed(1) : 0 : !showOnOff ? (Number(state.old_carton_rate) - Number(headerRate)).toFixed(1) : 0,
          new_stick_rate: priceListAction === "Up Price" ? !showOnOff ? (((

            (Number(headerRate) / Number((state?.carton_total_nos) || 1)) *
            (Number(state?.stick_nos))

          )) + Number(state.old_stick_rate)).toFixed(1) : 0 : !showOnOff ? (
            Number(state.old_stick_rate) -
            ((

              (Number(headerRate) / Number((state?.carton_total_nos) || 1)) *
              (Number(state?.stick_nos))

            ))).toFixed(1) : 0,



          new_one_kg_rate: priceListAction === "Up Price" ? !showOnOff ? ((


            Number(headerRate) /
            (((Number((state?.product_price_list_1_piece_weight) || 1) * Number((state?.carton_total_nos) || 1)) / 1000))
          ) + Number(state.old_one_kg_rate)).toFixed(1) : 0 :

            !showOnOff ? (Number(state.old_one_kg_rate) - (


              Number(headerRate) /
              (((Number((state?.product_price_list_1_piece_weight) || 1) * Number((state?.carton_total_nos) || 1)) / 1000))
            )).toFixed(1) : 0

          ,

          new_percentage_rate: priceListAction === "Up Price" ?


            !showOnOff ? (
              Number(((Number(HeaderCartonRate)) /

                Number((state?.old_carton_rate) || 1)) * 100)

            )
              .toFixed(1) : 0 :


            !showOnOff ? (
              Number(((Number(difference) - Number(state?.old_carton_rate)) /

                Number((state?.old_carton_rate) || 1)) * 100)

            )
              .toFixed(1) : 0,


        }
      });

      setEditableStates(updatedStates);
    }


    if (rateType === 'Packet Rate') {
      const updatedStates = editableStates.map((state: any) => {
        
        var difference = (Number(state?.old_stick_rate) - Number(headerRate))
        return {

          ...state,
          show_on_off: !showOnOff,
          product_rate_change_date:currentDate,
          show_on_off_duplicate: false,
          change_carton_rate: !showOnOff ? ((Number(headerRate) / Number(state?.stick_nos)) * Number((state?.carton_total_nos))).toFixed(1) : 0,
          change_stick_rate: !showOnOff ? Number(headerRate) : 0,

          change_one_kg_rate: !showOnOff ? (



            ((Number(headerRate) / Number(state?.stick_nos)) * Number((state?.carton_total_nos))) /
            (((Number((state?.product_price_list_1_piece_weight) || 1) * Number((state?.carton_total_nos) || 1)) / 1000))
          ).toFixed(1) : 0,

          


          change_percentage_rate: priceListAction === "Up Price" ?

            !showOnOff ? (
              Number(((Number(HeaderStickRate)) /

                Number((state?.old_stick_rate) || 1)) * 100)

            )
              .toFixed(1) : 0 :

            !showOnOff ? (
              Number(((Number(difference) - Number(state?.old_stick_rate)) /

                Number((state?.old_stick_rate) || 1)) * 100)

            )
              .toFixed(1) : 0,

          new_carton_rate: priceListAction === "Up Price" ? !showOnOff ? (Number(state.old_carton_rate) + ((Number(headerRate) / Number(state?.stick_nos)) * Number((state?.carton_total_nos)))).toFixed(1) : 0 : !showOnOff ? (Number(state.old_carton_rate) - ((Number(headerRate) / Number(state?.stick_nos)) * Number((state?.carton_total_nos)))).toFixed(1) : 0,
          new_stick_rate: priceListAction === "Up Price" ? !showOnOff ?
            (Number(headerRate) + Number(state.old_stick_rate)).toFixed(1) :

            0 :
            !showOnOff ?
              (Number(state.old_stick_rate) - Number(headerRate)).toFixed(1) :

              0,


          new_one_kg_rate: priceListAction === "Up Price" ? !showOnOff ? ((



            ((Number(headerRate) / Number(state?.stick_nos)) * Number((state?.carton_total_nos))) /
            (((Number((state?.product_price_list_1_piece_weight) || 1) * Number((state?.carton_total_nos) || 1)) / 1000))
          ) + Number(state.old_one_kg_rate)).toFixed(1) : 0
            :



            !showOnOff ? (Number(state.old_one_kg_rate) - (
              ((Number(headerRate) / Number(state?.stick_nos)) * Number((state?.carton_total_nos))) /
              (((Number((state?.product_price_list_1_piece_weight) || 1) * Number((state?.carton_total_nos) || 1)) / 1000))
            )).toFixed(1) : 0,

          new_percentage_rate: priceListAction === "Up Price" ?


            !showOnOff ? (
              Number(((Number(HeaderStickRate)) /

                Number((state?.old_stick_rate) || 1)) * 100)

            )
              .toFixed(1) : 0 :

            !showOnOff ? (
              Number(((Number(difference) - Number(state?.old_stick_rate)) /

                Number((state?.old_stick_rate) || 1)) * 100)

            )
              .toFixed(1) : 0,


        }
      });

      setEditableStates(updatedStates);
    }




    if (rateType === '1 Kg. Rate') {
      const updatedStates = editableStates.map((state: any) => {
        
        var difference = (Number(state?.old_one_kg_rate) - Number(headerRate))
        return {

          ...state,
          show_on_off: !showOnOff,
          product_rate_change_date:currentDate,
          show_on_off_duplicate: false,

          change_carton_rate: !showOnOff ? (
            Number(headerRate) * 
            (((Number((state?.product_price_list_1_piece_weight) || 1) * 
               Number((state?.carton_total_nos) || 1)) / 1000)
        )).toFixed(1) : 0,
        
          change_stick_rate: !showOnOff ? 

          (  (  (
              Number(headerRate) * 
              (((Number((state?.product_price_list_1_piece_weight) || 1) * 
                 Number((state?.carton_total_nos) || 1)) / 1000)
          )) /
          Number((state?.carton_total_nos))
          ) *  Number(state?.stick_nos)  ).toFixed(1): 0,


          change_one_kg_rate: !showOnOff ? (Number(headerRate)) : 0,

          change_percentage_rate: priceListAction === "Up Price" ?

            !showOnOff ? (
              Number(((Number(HeaderKGRate)) /

                Number((state?.old_one_kg_rate) || 1)) * 100)

            )
              .toFixed(1) : 0 :

            !showOnOff ? (
              Number(((Number(difference) - Number(state?.old_one_kg_rate)) /

                Number((state?.old_one_kg_rate) || 1)) * 100)

            )
              .toFixed(1) : 0,

          new_carton_rate: priceListAction === "Up Price" ? !showOnOff ? (Number(state.old_carton_rate) +  Number(headerRate) * 
          (((Number((state?.product_price_list_1_piece_weight) || 1) * 
             Number((state?.carton_total_nos) || 1)) / 1000)
      )).toFixed(1) : 0 : !showOnOff ? (Number(state.old_carton_rate) -  Number(headerRate) * 
      (((Number((state?.product_price_list_1_piece_weight) || 1) * 
         Number((state?.carton_total_nos) || 1)) / 1000)
  )).toFixed(1) : 0,

  
          new_stick_rate: priceListAction === "Up Price" ? !showOnOff ?
            (Number(state.old_stick_rate) + (  (  (
              Number(headerRate) * 
              (((Number((state?.product_price_list_1_piece_weight) || 1) * 
                 Number((state?.carton_total_nos) || 1)) / 1000)
          )) /
          Number((state?.carton_total_nos))
          ) *  Number(state?.stick_nos)  )).toFixed(1) :

            0 :
            !showOnOff ?
              (Number(state.old_stick_rate) - (  (  (
                Number(headerRate) * 
                (((Number((state?.product_price_list_1_piece_weight) || 1) * 
                   Number((state?.carton_total_nos) || 1)) / 1000)
            )) /
            Number((state?.carton_total_nos))
            ) *  Number(state?.stick_nos)  )).toFixed(1) :

              0,


          new_one_kg_rate: priceListAction === "Up Price" ? !showOnOff ?
          (Number(headerRate) + Number(state.old_one_kg_rate)).toFixed(1) :

          0 :
          !showOnOff ?
            (Number(state.old_one_kg_rate) - Number(headerRate)).toFixed(1) :

            0,

          new_percentage_rate: priceListAction === "Up Price" ?


            !showOnOff ? (
              Number(((Number(HeaderKGRate)) /

                Number((state?.old_one_kg_rate) || 1)) * 100)

            )
              .toFixed(1) : 0 :

            !showOnOff ? (
              Number(((Number(difference) - Number(state?.old_one_kg_rate)) /

                Number((state?.old_one_kg_rate) || 1)) * 100)

            )
              .toFixed(1) : 0,


        }
      });

      setEditableStates(updatedStates);
    }







    if (rateType === '% Rate') {
      const updatedStates = editableStates.map((state: any) => {

        
        var difference = (Number(state?.old_percentage_rate) - Number(PercentageRate))
        return {

          ...state,
          show_on_off: !showOnOff,
          product_rate_change_date:currentDate,
          show_on_off_duplicate: false,
          change_carton_rate: !showOnOff ? ((Number(state.old_carton_rate) * Number(headerRate)) / 100).toFixed(1) : 0,
         
          change_stick_rate: !showOnOff ? (((Number(state.old_stick_rate) * Number(headerRate)) / 100)).toFixed(1) : 0,

          change_one_kg_rate: !showOnOff ?  (((Number(state.old_one_kg_rate) * Number(headerRate)) / 100)).toFixed(1) : 0,

          


          change_percentage_rate: priceListAction === "Up Price" ?

          !showOnOff ?  Number(headerRate).toFixed(1) : 0 :

            !showOnOff ?  Number(headerRate).toFixed(1) : 0,

          new_carton_rate: priceListAction === "Up Price" ? !showOnOff ? (Number(state.old_carton_rate) + ((Number(state.old_carton_rate) * Number(headerRate)) / 100)).toFixed(1) : 0 : !showOnOff ? (Number(state.old_carton_rate) - ((Number(state.old_carton_rate) * Number(headerRate)) / 100)).toFixed(1) : 0,
          new_stick_rate: priceListAction === "Up Price" ? !showOnOff ?
            (Number(state.old_stick_rate) + 
            
            (((Number(state.old_stick_rate) * Number(headerRate)) / 100))
            
            ).toFixed(1) :

            0 :
            !showOnOff ?
              (Number(state.old_stick_rate) - (((Number(state.old_stick_rate) * Number(headerRate)) / 100))).toFixed(1) :

              0,


          new_one_kg_rate: priceListAction === "Up Price" ? !showOnOff ? ((Number(state.old_one_kg_rate) + ((Number(state.old_one_kg_rate) * Number(headerRate)) / 100))).toFixed(1) : 0
            :



            !showOnOff ? (Number(state.old_one_kg_rate) - (((Number(state.old_one_kg_rate) * Number(headerRate)) / 100))).toFixed(1) : 0,

        


          new_percentage_rate: priceListAction === "Up Price" ? !showOnOff ?
          (Number(headerRate) + Number(state.old_percentage_rate)).toFixed(1) :

          0 :
          !showOnOff ?
            (Number(state.old_percentage_rate) - Number(headerRate)).toFixed(1) :

            0,


        }
      });

      setEditableStates(updatedStates);
    }




  };








const [showOnOffDuplicate,setShowOnOffDuplicate] = useState(false)

  const handleMainShowOffDuplicate = () => {
    setShowOnOffDuplicate(!showOnOffDuplicate);

    if (rateType === 'Carton Rate') {
      const updatedStates = duplicate.map((state: any) => {
        
        return {

          ...state,
          show_on_off: false,
          show_on_off_duplicate: !showOnOffDuplicate,
         

        }
      });

      setDuplicate(updatedStates);
    }


    if (rateType === 'Packet Rate') {
      const updatedStates = duplicate.map((state: any) => {
        
        return {

          ...state,
          show_on_off: false,
          show_on_off_duplicate: !showOnOffDuplicate,
         

        }
      });

      setDuplicate(updatedStates);
    }


    if (rateType === '1 Kg. Rate') {
      const updatedStates = duplicate.map((state: any) => {
        
        return {

          ...state,
          show_on_off: false,
          show_on_off_duplicate: !showOnOffDuplicate,
         

        }
      });

      setDuplicate(updatedStates);
    }


    if (rateType === '% Rate') {
      const updatedStates = duplicate.map((state: any) => {
        
        return {

          ...state,
          show_on_off: false,
          show_on_off_duplicate: !showOnOffDuplicate,
         

        }
      });

      setDuplicate(updatedStates);
    }

  };








  const handleInnerShowOnOff = (e: any, index: any) => {




    const updatedStates = [...editableStates]
    const isChecked = e.target.checked

    updatedStates[index].show_on_off = isChecked
    updatedStates[index].show_on_off_duplicate = false

    updatedStates[index].product_rate_change_date = currentDate

    if (HeaderCartonRate && HeaderCartonRate !== 0) {








   
        
    
        updatedStates[index].change_carton_rate= isChecked ? (Number(HeaderCartonRate)) : 0
        updatedStates[index].change_stick_rate= isChecked ? ((

            (Number(HeaderCartonRate) / Number((updatedStates[index]?.carton_total_nos) || 1)) *
            (Number(updatedStates[index]?.stick_nos))

          )).toFixed(1) : 0
          updatedStates[index].change_one_kg_rate= isChecked ? (


            Number(HeaderCartonRate) /
            (((Number((updatedStates[index]?.product_price_list_1_piece_weight) || 1) * Number((updatedStates[index]?.carton_total_nos) || 1)) / 1000))
          ).toFixed(1) : 0

           if(priceListAction === "Up Price") {
            updatedStates[index].change_percentage_rate= isChecked ? (
              Number(((Number(HeaderCartonRate)) /

                Number((updatedStates[index]?.old_carton_rate) || 1)) * 100)

            )
              .toFixed(1) : 0
          }

            else{

              var difference = (Number(updatedStates[index]?.old_carton_rate) - Number(HeaderCartonRate))

              updatedStates[index].change_percentage_rate=isChecked ? (
              Number(((Number(difference) - Number(updatedStates[index]?.old_carton_rate)) /

                Number((updatedStates[index]?.old_carton_rate) || 1)) * 100)

            )
              .toFixed(1) : 0
            }

               if(priceListAction === "Up Price") {
                updatedStates[index].new_carton_rate=isChecked ? (Number(updatedStates[index].old_carton_rate) + Number(HeaderCartonRate)).toFixed(1) : 0
              }  else{
                updatedStates[index].new_carton_rate=isChecked ? (Number(updatedStates[index].old_carton_rate) - Number(HeaderCartonRate)).toFixed(1) : 0

              } 
              if(priceListAction === "Up Price") {
                updatedStates[index].new_stick_rate=  isChecked ? (((

                  (Number(HeaderCartonRate) / Number((updatedStates[index]?.carton_total_nos) || 1)) *
                  (Number(updatedStates[index]?.stick_nos))
      
                )) + Number(updatedStates[index].old_stick_rate)).toFixed(1) : 0
              }  else{

                updatedStates[index].new_stick_rate=  isChecked ? (
                  Number(updatedStates[index].old_stick_rate) -
                  ((
      
                    (Number(HeaderCartonRate) / Number((updatedStates[index]?.carton_total_nos) || 1)) *
                    (Number(updatedStates[index]?.stick_nos))
      
                  ))).toFixed(1) : 0
              } 



             if(priceListAction === "Up Price") {

              updatedStates[index].new_one_kg_rate=isChecked ? ((


                Number(HeaderCartonRate) /
                (((Number((updatedStates[index]?.product_price_list_1_piece_weight) || 1) * Number((updatedStates[index]?.carton_total_nos) || 1)) / 1000))
              ) + Number(updatedStates[index].old_one_kg_rate)).toFixed(1) : 0

            }  else{

              updatedStates[index].new_one_kg_rate= isChecked ? (Number(updatedStates[index].old_one_kg_rate) - (


                Number(HeaderCartonRate) /
                (((Number((updatedStates[index]?.product_price_list_1_piece_weight) || 1) * Number((updatedStates[index]?.carton_total_nos) || 1)) / 1000))
              )).toFixed(1) : 0

            }

            

          

           if(priceListAction === "Up Price") {

            updatedStates[index].new_percentage_rate=isChecked ? (
              Number(((Number(HeaderCartonRate)) /

                Number((updatedStates[index]?.old_carton_rate) || 1)) * 100)

            )
              .toFixed(1) : 0

          }else{
            var difference = (Number(updatedStates[index]?.old_carton_rate) - Number(HeaderCartonRate))
            updatedStates[index].new_percentage_rate=isChecked ? (
              Number(((Number(difference) - Number(updatedStates[index]?.old_carton_rate)) /

                Number((updatedStates[index]?.old_carton_rate) || 1)) * 100)

            )
              .toFixed(1) : 0

          }


           

          
            


        
   















     



    } else if (HeaderStickRate && HeaderStickRate !== 0) {







       
        updatedStates[index].change_carton_rate= isChecked ? ((Number(HeaderStickRate) / Number(updatedStates[index]?.stick_nos)) * Number((updatedStates[index]?.carton_total_nos))).toFixed(1) : 0
          updatedStates[index].change_stick_rate= isChecked ? Number(HeaderStickRate) : 0

          updatedStates[index].change_one_kg_rate= isChecked ? (



            ((Number(HeaderStickRate) / Number(updatedStates[index]?.stick_nos)) * Number((updatedStates[index]?.carton_total_nos))) /
            (((Number((updatedStates[index]?.product_price_list_1_piece_weight) || 1) * Number((updatedStates[index]?.carton_total_nos) || 1)) / 1000))
          ).toFixed(1) : 0

          


           if(priceListAction === "Up Price") {
            updatedStates[index].change_percentage_rate=isChecked ? (
              Number(((Number(HeaderStickRate)) /

                Number((updatedStates[index]?.old_stick_rate) || 1)) * 100)

            )
              .toFixed(1) : 0
          }
          else{
            var difference = (Number(updatedStates[index]?.old_stick_rate) - Number(HeaderStickRate))
            updatedStates[index].change_percentage_rate=isChecked ? (
              Number(((Number(difference) - Number(updatedStates[index]?.old_stick_rate)) /

                Number((updatedStates[index]?.old_stick_rate) || 1)) * 100)

            )
              .toFixed(1) : 0
          }

             

           

               if(priceListAction === "Up Price") {
                updatedStates[index].new_carton_rate=isChecked ? (Number(updatedStates[index].old_carton_rate) + ((Number(HeaderStickRate) / Number(updatedStates[index]?.stick_nos)) * Number((updatedStates[index]?.carton_total_nos)))).toFixed(1) : 0
              } 
              else{
                updatedStates[index].new_carton_rate=isChecked ? (Number(updatedStates[index].old_carton_rate) - ((Number(HeaderStickRate) / Number(updatedStates[index]?.stick_nos)) * Number((updatedStates[index]?.carton_total_nos)))).toFixed(1) : 0

              } 
               if(priceListAction === "Up Price") {
                updatedStates[index].new_stick_rate= isChecked ?
                (Number(HeaderStickRate) + Number(updatedStates[index].old_stick_rate)).toFixed(1) :
    
                0
              } 
              else{
                updatedStates[index].new_stick_rate=isChecked ?
                (Number(updatedStates[index].old_stick_rate) - Number(HeaderStickRate)).toFixed(1) :
  
                0
              } 
            


               if(priceListAction === "Up Price") {
                updatedStates[index].new_one_kg_rate=isChecked ? ((



                  ((Number(HeaderStickRate) / Number(updatedStates[index]?.stick_nos)) * Number((updatedStates[index]?.carton_total_nos))) /
                  (((Number((updatedStates[index]?.product_price_list_1_piece_weight) || 1) * Number((updatedStates[index]?.carton_total_nos) || 1)) / 1000))
                ) + Number(updatedStates[index].old_one_kg_rate)).toFixed(1) : 0
              } 
              else{
                updatedStates[index].new_one_kg_rate=isChecked ? (Number(updatedStates[index].old_one_kg_rate) - (
                  ((Number(HeaderStickRate) / Number(updatedStates[index]?.stick_nos)) * Number((updatedStates[index]?.carton_total_nos))) /
                  (((Number((updatedStates[index]?.product_price_list_1_piece_weight) || 1) * Number((updatedStates[index]?.carton_total_nos) || 1)) / 1000))
                )).toFixed(1) : 0

              }
            



           

             if(priceListAction === "Up Price") {
              updatedStates[index].new_percentage_rate=isChecked ? (
                Number(((Number(HeaderStickRate)) /
  
                  Number((updatedStates[index]?.old_stick_rate) || 1)) * 100)
  
              )
                .toFixed(1) : 0
            }
            else{
              var difference = (Number(updatedStates[index]?.old_stick_rate) - Number(HeaderStickRate))
              updatedStates[index].new_percentage_rate=isChecked ? (
                Number(((Number(difference) - Number(updatedStates[index]?.old_stick_rate)) /
  
                  Number((updatedStates[index]?.old_stick_rate) || 1)) * 100)
  
              )
                .toFixed(1) : 0

            }


            

           


            updatedStates[index].product_rate_change_date = currentDate


    

    }


    else if (HeaderKGRate && HeaderKGRate !== 0) {




    
     


        updatedStates[index].change_carton_rate= isChecked ? (
            Number(HeaderKGRate) * 
            (((Number((updatedStates[index]?.product_price_list_1_piece_weight) || 1) * 
               Number((updatedStates[index]?.carton_total_nos) || 1)) / 1000)
        )).toFixed(1) : 0
        
        updatedStates[index].change_stick_rate= isChecked ? 

          (  (  (
              Number(HeaderKGRate) * 
              (((Number((updatedStates[index]?.product_price_list_1_piece_weight) || 1) * 
                 Number((updatedStates[index]?.carton_total_nos) || 1)) / 1000)
          )) /
          Number((updatedStates[index]?.carton_total_nos))
          ) *  Number(updatedStates[index]?.stick_nos)  ).toFixed(1): 0


          updatedStates[index].change_one_kg_rate= isChecked ? (Number(HeaderKGRate)) : 0

           if(priceListAction === "Up Price") {
            updatedStates[index].change_percentage_rate=isChecked ? (
              Number(((Number(HeaderKGRate)) /

                Number((updatedStates[index]?.old_one_kg_rate) || 1)) * 100)

            )
              .toFixed(1) : 0
          }
          else{
            var difference = (Number(updatedStates[index]?.old_one_kg_rate) - Number(HeaderKGRate))
            updatedStates[index].change_percentage_rate=isChecked ? (
              Number(((Number(difference) - Number(updatedStates[index]?.old_one_kg_rate)) /

                Number((updatedStates[index]?.old_one_kg_rate) || 1)) * 100)

            )
              .toFixed(1) : 0
          }

            

           

               if(priceListAction === "Up Price") {
                updatedStates[index].new_carton_rate=isChecked ? (Number(updatedStates[index].old_carton_rate) +  Number(HeaderKGRate) * 
          (((Number((updatedStates[index]?.product_price_list_1_piece_weight) || 1) * 
             Number((updatedStates[index]?.carton_total_nos) || 1)) / 1000)
      )).toFixed(1) : 0

              } 
              else{
                updatedStates[index].new_carton_rate=isChecked ? (Number(updatedStates[index].old_carton_rate) -  Number(HeaderKGRate) * 
      (((Number((updatedStates[index]?.product_price_list_1_piece_weight) || 1) * 
         Number((updatedStates[index]?.carton_total_nos) || 1)) / 1000)
  )).toFixed(1) : 0
              
              } 

  
   if(priceListAction === "Up Price") {

    updatedStates[index].new_stick_rate= isChecked ?
            (Number(updatedStates[index].old_stick_rate) + (  (  (
              Number(HeaderKGRate) * 
              (((Number((updatedStates[index]?.product_price_list_1_piece_weight) || 1) * 
                 Number((updatedStates[index]?.carton_total_nos) || 1)) / 1000)
          )) /
          Number((updatedStates[index]?.carton_total_nos))
          ) *  Number(updatedStates[index]?.stick_nos)  )).toFixed(1) :

            0

  } 
  else{

    updatedStates[index].new_stick_rate= isChecked ?
    (Number(updatedStates[index].old_stick_rate) - (  (  (
      Number(HeaderKGRate) * 
      (((Number((updatedStates[index]?.product_price_list_1_piece_weight) || 1) * 
         Number((updatedStates[index]?.carton_total_nos) || 1)) / 1000)
  )) /
  Number((updatedStates[index]?.carton_total_nos))
  ) *  Number(updatedStates[index]?.stick_nos)  )).toFixed(1) :

    0
  
  } 
           


              if(priceListAction === "Up Price") {

                updatedStates[index].new_one_kg_rate= isChecked ?
          (Number(HeaderKGRate) + Number(updatedStates[index].old_one_kg_rate)).toFixed(1) :

          0

              } 
              else{

                updatedStates[index].new_one_kg_rate= isChecked ?
                (Number(updatedStates[index].old_one_kg_rate) - Number(HeaderKGRate)).toFixed(1) :
    
                0
              
              } 
         

             if(priceListAction === "Up Price") {

              updatedStates[index].new_percentage_rate= isChecked ? (
                Number(((Number(HeaderKGRate)) /
  
                  Number((updatedStates[index]?.old_one_kg_rate) || 1)) * 100)
  
              )
                .toFixed(1) : 0

            }
            else{
              var difference = (Number(updatedStates[index]?.old_one_kg_rate) - Number(HeaderKGRate))
              updatedStates[index].new_percentage_rate= isChecked ? (
                Number(((Number(difference) - Number(updatedStates[index]?.old_one_kg_rate)) /
  
                  Number((updatedStates[index]?.old_one_kg_rate) || 1)) * 100)
  
              )
                .toFixed(1) : 0
            
            }


            
            updatedStates[index].product_rate_change_date = currentDate
           




     

    }

    else if (PercentageRate && PercentageRate !== 0) {



        var difference = (Number(updatedStates[index]?.old_percentage_rate) - Number(PercentageRate))
        updatedStates[index].change_carton_rate= isChecked ? ((Number(updatedStates[index].old_carton_rate) * Number(PercentageRate)) / 100).toFixed(1) : 0
        updatedStates[index].change_stick_rate= isChecked ? (((Number(updatedStates[index].old_stick_rate) * Number(PercentageRate)) / 100)).toFixed(1) : 0

        updatedStates[index].change_one_kg_rate= isChecked ?  (((Number(updatedStates[index].old_one_kg_rate) * Number(PercentageRate)) / 100)).toFixed(1) : 0

          


        if(priceListAction === "Up Price") {
          updatedStates[index].change_percentage_rate= isChecked ?  Number(PercentageRate).toFixed(1) : 0
        }else{
          updatedStates[index].change_percentage_rate= isChecked ?  Number(PercentageRate).toFixed(1) : 0
        }

          

           

             if(priceListAction === "Up Price") {

              updatedStates[index].new_carton_rate= isChecked ? (Number(updatedStates[index].old_carton_rate) + ((Number(updatedStates[index].old_carton_rate) * Number(PercentageRate)) / 100)).toFixed(1) : 0

            } else{

              updatedStates[index].new_carton_rate= isChecked ? (Number(updatedStates[index].old_carton_rate) - ((Number(updatedStates[index].old_carton_rate) * Number(PercentageRate)) / 100)).toFixed(1) : 0
            
            } 
             if(priceListAction === "Up Price") {

              updatedStates[index].new_stick_rate= isChecked ?
            (Number(updatedStates[index].old_stick_rate) + 
            
            (((Number(updatedStates[index].old_stick_rate) * Number(PercentageRate)) / 100))
            
            ).toFixed(1) :

            0

            } else{

              updatedStates[index].new_stick_rate= isChecked ?
              (Number(updatedStates[index].old_stick_rate) - (((Number(updatedStates[index].old_stick_rate) * Number(PercentageRate)) / 100))).toFixed(1) :

              0
            
            } 
            


               if(priceListAction === "Up Price") {

                updatedStates[index].new_one_kg_rate= isChecked ? ((Number(updatedStates[index].old_one_kg_rate) + ((Number(updatedStates[index].old_one_kg_rate) * Number(PercentageRate)) / 100))).toFixed(1) : 0

              } else{

                updatedStates[index].new_one_kg_rate= isChecked ? (Number(updatedStates[index].old_one_kg_rate) - (((Number(updatedStates[index].old_one_kg_rate) * Number(PercentageRate)) / 100))).toFixed(1) : 0
              
              }
            



            

       

             if(priceListAction === "Up Price") {

              updatedStates[index].new_percentage_rate= isChecked ?
          (Number(PercentageRate) + Number(updatedStates[index].old_percentage_rate)).toFixed(1) :

          0

            } else{

              updatedStates[index].new_percentage_rate= isChecked ?
            (Number(updatedStates[index].old_percentage_rate) - Number(PercentageRate)).toFixed(1) :

            0
            
            } 
          


            updatedStates[index].product_rate_change_date = currentDate
    

    }




    // const sortedArray = [...editableStates].sort((a, b) => {
    //   const indexA = editableStates.indexOf(a);
    //   const indexB = editableStates.indexOf(b);
    //   const aChecked = updatedStates[indexA].show_on_off;
    //   const bChecked = updatedStates[indexB].show_on_off;
    //   return aChecked === bChecked ? 0 : aChecked ? 1 : -1;
    // });



    setEditableStates(updatedStates);

    const allChecked = updatedStates.every(state => state.show_on_off);
    setShowOnOff(allChecked);
  };




  const handleInnerShowOnOffDuplicate = (e: any, index: any) => {




    const updatedStates = [...duplicate]
    const isChecked = e.target.checked

    updatedStates[index].show_on_off_duplicate = isChecked



    updatedStates[index].show_on_off = false

    // const sortedArray = [...duplicate].sort((a, b) => {
    //   const indexA = duplicate.indexOf(a);
    //   const indexB = duplicate.indexOf(b);
    //   const aChecked = updatedStates[indexA].show_on_off_duplicate;
    //   const bChecked = updatedStates[indexB].show_on_off_duplicate;
    //   return aChecked === bChecked ? 0 : aChecked ? 1 : -1;
    // });



    setDuplicate(updatedStates);

    const allChecked = updatedStates.every(state => state.show_on_off_duplicate);
    setShowOnOffDuplicate(allChecked);
  };

const [inputError,setInputError] = useState(false)
const [inputErrorMessage,setInputErrorMessage] = useState('Allow only numbers, decimal point.')

  const handleHeaderRateChange = (e: any) => {


    const  newRate  = e.target.value;
    setHeaderCartonRate(newRate)
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(newRate) || newRate === '') {
        setInputError(false)
    }else{
      setInputError(true)
    }


    const updatedStates = editableStates.map((state: any) => {
      var difference = (Number(state?.old_carton_rate) - Number(newRate))

      if (state.show_on_off) {
        return {
          ...state,




          change_carton_rate: newRate ? (Number(newRate)) : 0,
          change_stick_rate: newRate ? ((

            (Number(newRate) / Number((state?.carton_total_nos) || 1)) *
            (Number(state?.stick_nos))

          )).toFixed(1) : 0,
          change_one_kg_rate: newRate ? (


            Number(newRate) /
            (((Number((state?.product_price_list_1_piece_weight) || 1) * Number((state?.carton_total_nos) || 1)) / 1000))
          ).toFixed(1) : 0,

          change_percentage_rate: priceListAction === "Up Price" ?

            newRate ? (
              Number(((Number(HeaderCartonRate)) /

                Number((state?.old_carton_rate) || 1)) * 100)

            )
              .toFixed(1) : 0 :

            newRate ? (
              Number(((Number(difference) - Number(state?.old_carton_rate)) /

                Number((state?.old_carton_rate) || 1)) * 100)

            )
              .toFixed(1) : 0,

          new_carton_rate: priceListAction === "Up Price" ? newRate ? (Number(state.old_carton_rate) + Number(newRate)).toFixed(1) : 0 : newRate ? (Number(state.old_carton_rate) - Number(newRate)).toFixed(1) : 0,
          new_stick_rate: priceListAction === "Up Price" ? newRate ? (((

            (Number(newRate) / Number((state?.carton_total_nos) || 1)) *
            (Number(state?.stick_nos))

          )) + Number(state.old_stick_rate)).toFixed(1) : 0 : newRate ? (
            Number(state.old_stick_rate) -
            ((

              (Number(newRate) / Number((state?.carton_total_nos) || 1)) *
              (Number(state?.stick_nos))

            ))).toFixed(1) : 0,



          new_one_kg_rate: priceListAction === "Up Price" ? newRate ? ((


            Number(newRate) /
            (((Number((state?.product_price_list_1_piece_weight) || 1) * Number((state?.carton_total_nos) || 1)) / 1000))
          ) + Number(state.old_one_kg_rate)).toFixed(1) : 0 :

            newRate ? (Number(state.old_one_kg_rate) - (


              Number(newRate) /
              (((Number((state?.product_price_list_1_piece_weight) || 1) * Number((state?.carton_total_nos) || 1)) / 1000))
            )).toFixed(1) : 0

          ,

          new_percentage_rate: priceListAction === "Up Price" ?


            newRate ? (
              Number(((Number(HeaderCartonRate)) /

                Number((state?.old_carton_rate) || 1)) * 100)

            )
              .toFixed(1) : 0 :


            newRate ? (
              Number(((Number(difference) - Number(state?.old_carton_rate)) /

                Number((state?.old_carton_rate) || 1)) * 100)

            )
              .toFixed(1) : 0,








        };
      }
      return state;
    });

    setEditableStates(updatedStates);
  };



  const handleStickRateChange = (e: any) => {
    const  newRate  = e.target.value;
    setHeaderStickRate(newRate)
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(newRate) || newRate === '') {
        setInputError(false)
    }else{
      setInputError(true)
    }

    const updatedStates = editableStates.map((state: any) => {
      var difference = (Number(state?.old_carton_rate) - Number(newRate))

      if (state.show_on_off) {
        return {
          ...state,




          change_carton_rate: newRate ? ((Number(newRate) / Number(state?.stick_nos)) * Number((state?.carton_total_nos))).toFixed(1) : 0,
          // change_stick_rate: newRate ? ((

          //   (Number(newRate) / Number((state?.carton_total_nos) || 1)) *
          //   (Number(state?.stick_nos))

          // )).toFixed(1) : 0,
          change_stick_rate: newRate ? Number(newRate) : 0,

          change_one_kg_rate: newRate ? (



            ((Number(newRate) / Number(state?.stick_nos)) * Number((state?.carton_total_nos))) /
            (((Number((state?.product_price_list_1_piece_weight) || 1) * Number((state?.carton_total_nos) || 1)) / 1000))
          ).toFixed(1) : 0,

          


          change_percentage_rate: priceListAction === "Up Price" ?

            newRate ? (
              Number(((Number(HeaderStickRate)) /

                Number((state?.old_stick_rate) || 1)) * 100)

            )
              .toFixed(1) : 0 :

            newRate ? (
              Number(((Number(difference) - Number(state?.old_stick_rate)) /

                Number((state?.old_stick_rate) || 1)) * 100)

            )
              .toFixed(1) : 0,

          new_carton_rate: priceListAction === "Up Price" ? newRate ? (Number(state.old_carton_rate) + ((Number(newRate) / Number(state?.stick_nos)) * Number((state?.carton_total_nos)))).toFixed(1) : 0 : newRate ? (Number(state.old_carton_rate) - ((Number(newRate) / Number(state?.stick_nos)) * Number((state?.carton_total_nos)))).toFixed(1) : 0,
          new_stick_rate: priceListAction === "Up Price" ? newRate ?
            (Number(newRate) + Number(state.old_stick_rate)).toFixed(1) :

            0 :
            newRate ?
              (Number(state.old_stick_rate) - Number(newRate)).toFixed(1) :

              0,


          new_one_kg_rate: priceListAction === "Up Price" ? newRate ? ((



            ((Number(newRate) / Number(state?.stick_nos)) * Number((state?.carton_total_nos))) /
            (((Number((state?.product_price_list_1_piece_weight) || 1) * Number((state?.carton_total_nos) || 1)) / 1000))
          ) + Number(state.old_one_kg_rate)).toFixed(1) : 0
            :



            newRate ? (Number(state.old_one_kg_rate) - (
              ((Number(newRate) / Number(state?.stick_nos)) * Number((state?.carton_total_nos))) /
              (((Number((state?.product_price_list_1_piece_weight) || 1) * Number((state?.carton_total_nos) || 1)) / 1000))
            )).toFixed(1) : 0,

          new_percentage_rate: priceListAction === "Up Price" ?


            newRate ? (
              Number(((Number(HeaderStickRate)) /

                Number((state?.old_stick_rate) || 1)) * 100)

            )
              .toFixed(1) : 0 :

            newRate ? (
              Number(((Number(difference) - Number(state?.old_stick_rate)) /

                Number((state?.old_stick_rate) || 1)) * 100)

            )
              .toFixed(1) : 0,










        };
      }
      return state;
    });

    setEditableStates(updatedStates);
  }



  const handle1KGRateChange = (e: any) => {
    const  newRate  = e.target.value;
    setHeaderKGRate(newRate)
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(newRate) || newRate === '') {
        setInputError(false)
    }else{
      setInputError(true)
    }

    const updatedStates = editableStates.map((state: any) => {
      var difference = (Number(state?.old_carton_rate) - Number(newRate))

      if (state.show_on_off) {
        return {
          ...state,








          change_carton_rate: newRate ? (
            Number(newRate) * 
            (((Number((state?.product_price_list_1_piece_weight) || 1) * 
               Number((state?.carton_total_nos) || 1)) / 1000)
        )).toFixed(1) : 0,
        
          change_stick_rate: newRate ? 

          (  (  (
              Number(newRate) * 
              (((Number((state?.product_price_list_1_piece_weight) || 1) * 
                 Number((state?.carton_total_nos) || 1)) / 1000)
          )) /
          Number((state?.carton_total_nos))
          ) *  Number(state?.stick_nos)  ).toFixed(1): 0,
          // change_stick_rate: newRate ? (newRate * (((Number(state?.product_price_list_1_piece_weight) || 1) * Number(state?.carton_total_nos) || 1) / 1000)) /
          // Number(state?.stick_nos) * Number(state?.carton_total_nos): 0,


          change_one_kg_rate: newRate ? (Number(newRate)) : 0,

          change_percentage_rate: priceListAction === "Up Price" ?

            newRate ? (
              Number(((Number(HeaderKGRate)) /

                Number((state?.old_one_kg_rate) || 1)) * 100)

            )
              .toFixed(1) : 0 :

            newRate ? (
              Number(((Number(difference) - Number(state?.old_one_kg_rate)) /

                Number((state?.old_one_kg_rate) || 1)) * 100)

            )
              .toFixed(1) : 0,

          new_carton_rate: priceListAction === "Up Price" ? newRate ? (Number(state.old_carton_rate) +  Number(newRate) * 
          (((Number((state?.product_price_list_1_piece_weight) || 1) * 
             Number((state?.carton_total_nos) || 1)) / 1000)
      )).toFixed(1) : 0 : newRate ? (Number(state.old_carton_rate) -  Number(newRate) * 
      (((Number((state?.product_price_list_1_piece_weight) || 1) * 
         Number((state?.carton_total_nos) || 1)) / 1000)
  )).toFixed(1) : 0,

  
          new_stick_rate: priceListAction === "Up Price" ? newRate ?
            (Number(state.old_stick_rate) + (  (  (
              Number(newRate) * 
              (((Number((state?.product_price_list_1_piece_weight) || 1) * 
                 Number((state?.carton_total_nos) || 1)) / 1000)
          )) /
          Number((state?.carton_total_nos))
          ) *  Number(state?.stick_nos)  )).toFixed(1) :

            0 :
            newRate ?
              (Number(state.old_stick_rate) - (  (  (
                Number(newRate) * 
                (((Number((state?.product_price_list_1_piece_weight) || 1) * 
                   Number((state?.carton_total_nos) || 1)) / 1000)
            )) /
            Number((state?.carton_total_nos))
            ) *  Number(state?.stick_nos)  )).toFixed(1) :

              0,


          new_one_kg_rate: priceListAction === "Up Price" ? newRate ?
          (Number(newRate) + Number(state.old_one_kg_rate)).toFixed(1) :

          0 :
          newRate ?
            (Number(state.old_one_kg_rate) - Number(newRate)).toFixed(1) :

            0,

          new_percentage_rate: priceListAction === "Up Price" ?


            newRate ? (
              Number(((Number(HeaderKGRate)) /

                Number((state?.old_one_kg_rate) || 1)) * 100)

            )
              .toFixed(1) : 0 :

            newRate ? (
              Number(((Number(difference) - Number(state?.old_one_kg_rate)) /

                Number((state?.old_one_kg_rate) || 1)) * 100)

            )
              .toFixed(1) : 0,





        };
      }
      return state;
    });

    setEditableStates(updatedStates);
  }



  const handlePercentageRateChange = (e: any) => {
    const  newRate  = e.target.value;
    setPercentageRate(newRate)
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(newRate) || newRate === '') {
        setInputError(false)
    }else{
      setInputError(true)
    }

    const updatedStates = editableStates.map((state: any) => {
      var difference = (Number(state?.old_carton_rate) - Number(newRate))

      if (state.show_on_off) {
        return {
          ...state,





          change_carton_rate: newRate ? ((Number(state.old_carton_rate) * Number(newRate)) / 100).toFixed(1) : 0,
        
          change_stick_rate: newRate ? (((Number(state.old_stick_rate) * Number(newRate)) / 100)).toFixed(1) : 0,

          change_one_kg_rate: newRate ?  (((Number(state.old_one_kg_rate) * Number(newRate)) / 100)).toFixed(1) : 0,

          


          change_percentage_rate: priceListAction === "Up Price" ?

          newRate ?  Number(newRate).toFixed(1) : 0 :

            newRate ?  Number(newRate).toFixed(1) : 0,

          new_carton_rate: priceListAction === "Up Price" ? newRate ? (Number(state.old_carton_rate) + ((Number(state.old_carton_rate) * Number(newRate)) / 100)).toFixed(1) : 0 : newRate ? (Number(state.old_carton_rate) - ((Number(state.old_carton_rate) * Number(newRate)) / 100)).toFixed(1) : 0,
          new_stick_rate: priceListAction === "Up Price" ? newRate ?
            (Number(state.old_stick_rate) + 
            
            (((Number(state.old_stick_rate) * Number(newRate)) / 100))
            
            ).toFixed(1) :

            0 :
            newRate ?
              (Number(state.old_stick_rate) - (((Number(state.old_stick_rate) * Number(newRate)) / 100))).toFixed(1) :

              0,


          new_one_kg_rate: priceListAction === "Up Price" ? newRate ? ((Number(state.old_one_kg_rate) + ((Number(state.old_one_kg_rate) * Number(newRate)) / 100))).toFixed(1) : 0
            :



            newRate ? (Number(state.old_one_kg_rate) - (((Number(state.old_one_kg_rate) * Number(newRate)) / 100))).toFixed(1) : 0,

        


          new_percentage_rate: priceListAction === "Up Price" ? newRate ?
          (Number(newRate) + Number(state.old_percentage_rate)).toFixed(1) :

          0 :
          newRate ?
            (Number(state.old_percentage_rate) - Number(newRate)).toFixed(1) :

            0,






        };
      }
      return state;
    });

    setEditableStates(updatedStates);
  }





  // const sortedData = () => {
  //   const sortedArray = [...data];

  //   sortedArray.sort((a, b) => {
  //     const aChecked = editableStates[data.indexOf(a)]?.show_on_off;
  //     const bChecked = editableStates[data.indexOf(b)]?.show_on_off;
  //     return aChecked === bChecked ? 0 : aChecked ? 1 : -1;
  //   });

  //   return sortedArray;
  // };


  

  const handleSave=()=>{
    let temp = editableStates.filter((e:any,index:any)=> {
      const rowState = editableStates[index] || {};
      return(
        rowState.show_on_off
      )
    })
    
    setDuplicate((prev:any)=> ([...prev,...temp]))

    let tempEditable = editableStates.filter((e:any,index:any)=> {
      const rowState = editableStates[index] || {};
      return(
        !rowState.show_on_off
      )
    })
    setEditableStates([...tempEditable])
  }


  const handleRemove=()=>{
    let temp = duplicate.filter((e:any,index:any)=> {
      const rowState = editableStates[index] || {};
      return(
        !e.show_on_off_duplicate
      )
    })
    
    setDuplicate([...temp])
    let tempEditable =  duplicate.filter((e:any,index:any)=> {

      return(
        
        e.show_on_off_duplicate

      )
    }).map((e:any)=>e.id)
    
    
    let checkedData = checkData.filter((e:any) => tempEditable.includes(e.id)).map((e:any)=>{
      debugger
      return{
        isPercentageEditable: false,
        inputPercentageValue: 0,

        isBasicRateEditable: false,
        inputBasicRateValue: 0,
        isRateEditable: false,
        inputRateValue: 0,
        isStickRateEditable: false,
        inputStickRateValue: 0,
        isOneKgEditable: false,
        inputOneKgValue: 0,


        product_code: e?.product_code,



        carton_total_nos: e?.carton_total_nos ? Number(e?.carton_total_nos) : Number(e?.product_code?.carton_total_nos),
        stick_nos: e?.stick_nos ? Number(e?.stick_nos) : Number(e?.product_code?.stick_nos),
        stick_qty: e?.stick_qty ? Number(e?.stick_qty) : Number(e?.product_code?.stick_qty),
        product_price_list_1_piece_weight: e?.product_single_piece_code?.price_list_1_pcs_weight ? Number(e?.product_single_piece_code?.price_list_1_pcs_weight) : Number(e?.product_code?.product_single_piece_code?.price_list_1_pcs_weight),






        id: e?.id,

        // old_carton_basic_rate: e?.new_carton_basic_rate ? e?.new_carton_basic_rate : 0,
        // old_carton_rate: e?.new_carton_rate ? e?.new_carton_rate : 0,
        // old_stick_rate: e?.new_stick_rate ? e?.new_stick_rate : 0,
        // old_one_kg_rate: e?.new_one_kg_rate ? e?.new_one_kg_rate : 0,
        // old_percentage_rate: editData?100:0,

        old_carton_basic_rate: e?.old_carton_basic_rate ? e?.old_carton_basic_rate : 0,
        old_carton_rate: e?.old_carton_rate ? e?.old_carton_rate : 0,
        old_stick_rate: e?.old_stick_rate ? e?.old_stick_rate : 0,
        old_one_kg_rate: e?.old_one_kg_rate ? e?.old_one_kg_rate : 0,
        old_percentage_rate: editData?100:0,


        old_new_carton_basic_rate: e.old_new_carton_basic_rate,
        old_new_carton_rate: e.old_new_carton_rate,
        old_new_stick_rate: e.old_new_stick_rate,
        old_new_one_kg_rate: e.old_new_one_kg_rate,
        old_new_percentage_rate: editData?100:0,


        show_on_off: false,
        show_on_off_duplicate: false,

        change_carton_basic_rate: 0,
        change_percentage_rate: 0,

        change_carton_rate: 0,
        change_stick_rate: 0,
        change_one_kg_rate: 0,

        new_carton_basic_rate: editData !== undefined && editData.length>0 ?e?.old_new_carton_basic_rate : 0,
        new_carton_rate: editData !== undefined && editData.length>0?e?.old_new_carton_rate  : 0,
        new_stick_rate: editData !== undefined && editData.length>0?e?.old_new_stick_rate  : 0,
        new_one_kg_rate: editData !== undefined && editData.length>0?e?.old_new_one_kg_rate : 0,
        new_percentage_rate: editData !== undefined && editData.length>0?e?.old_new_percentage_rate  : 0,

        product_rate_change_date:currentDate
      }
    })


  
    setEditableStates((prev:any)=>([...prev,...checkedData]))
  }


  console.log(checkData,'checkData',editData !== undefined)

  








  return (
    <>
      { priceListAction &&
        <>
          <div className="zui-wrapper" >
            <div className="zui-scroller">
              <table className="zui-table">
                <thead>
                  <tr>

                    <th className="zui-sticky-col">Sr.<br />No</th>
                    <th className="zui-sticky-col2">Old Carton<br />Basic Rate</th>
                    <th className="zui-sticky-col3">Old Carton<br />Rate</th>
                    <th className="zui-sticky-col4">Old Stick<br />Rate</th>
                    <th className="zui-sticky-col5">Old 1 Kg<br />Rate</th>
                    <th>Product<br />Code</th>
                    <th>Product Grade,<br />Name & Type</th>
                    <th>
                      <div>
                        <input type="checkbox"
                          id="mainCheck"
                          onClick={handleMainShowOff}
                          checked={showOnOff} />
                      </div>
                      Show<br />On-Off

                    </th>
                    <th>
                      Date
                    </th>
                    <th>

                      Change Carton<br />Basic Rate</th>
                    <th>
                      {inputError && HeaderCartonRate !=0? <span style={{color:'red'}}>Allow only numbers, decimal point.</span>:null}
                      <div>
                        <input type='text' name='Carton Rate' disabled={rateType != 'Carton Rate'} style={{ textAlign: 'center' }} value={HeaderCartonRate} onChange={handleHeaderRateChange} />

                      </div>
                      Change Carton<br />Rate</th>
                    <th>
                    {inputError && HeaderStickRate !=0? <span style={{color:'red'}}>Allow only numbers, decimal point.</span>:null}
                      <div>
                        <input type='text' name='Stick Rate' disabled={rateType != 'Packet Rate'} style={{ textAlign: 'center' }} value={HeaderStickRate} onChange={handleStickRateChange} />
                      </div>
                      Change Stick<br />Rate</th>
                    <th>
                    {inputError && HeaderKGRate !=0? <span style={{color:'red'}}>Allow only numbers, decimal point.</span>:null}
                      <div>
                        <input type='text' name='1 Kg. Rate' disabled={rateType != '1 Kg. Rate'} style={{ textAlign: 'center' }} value={HeaderKGRate}
                          onChange={handle1KGRateChange}
                        />
                      </div>

                      Change 1 KG<br />Rate</th>
                    <th>
                    {inputError  && PercentageRate !=0? <span style={{color:'red'}}>Allow only numbers, decimal point.</span>:null}
                      <div>
                        <input type='text' name='' disabled={rateType != '% Rate'} style={{ textAlign: 'center' }} value={PercentageRate}
                          onChange={handlePercentageRateChange}
                        />
                      </div>
                      % <br />Rate</th>
                    <th>GST Change<br />Amount</th>
                    <th>GST<br />Rate %</th>
                    <th>Carton<br />Basic Rate</th>
                    <th>GST<br />Amount</th>
                    <th>Carton<br />Rate</th>
                    <th>Difference<br />Rate</th>
                    <th>New Carton<br />Basic Rate</th>
                    <th>New Carton<br />Rate</th>
                    <th>New Stick<br />Rate</th>
                    <th>New 1 KG<br />Rate</th>
                    <th>New Percentage<br />Rate</th>
                    <th>Product Weight<br />Price List</th>
                    <th>Stick<br />Nos</th>
                    <th>Carton<br />Stick</th>
                    <th > Product Total <br />Qty</th>
                    <th >UOM</th>
                    <th >Carton<br />Weight</th>
                    <th >Price List <br />Mould Size(MM)</th>
                    <th >Production<br />Mould Size(MM)</th>
                    <th >ML Price<br />List</th>
                    <th >Product <br />Color</th>
                    <th >Product <br />Brand</th>
                    <th >Panni <br />Color</th>
                  </tr>
                </thead>
                <tbody>
                  {(priceListAction ? editableStates?.map((e: any, index: any) => {
                    const rowState = editableStates[index] || {};

                    return (<>
                      <tr key={index}>

                        <td className="zui-sticky-col" style={rowState?.show_on_off ? { background: 'rgb(204, 204, 204)' } : {}}>
                          <input
                            style={rowState?.show_on_off ? { border: "none", width: "50px", backgroundColor: "rgb(204, 204, 204)" } : { border: "none", width: "50px", backgroundColor: "rgb(204, 204, 204)" }}

                            type="text"
                            placeholder="ID"
                            disabled
                            value={index + 1}
                          />



                        </td>
                        <td className="zui-sticky-col2" style={rowState?.show_on_off ? { background: 'rgb(204, 204, 204)' } : {}}>
                          <input
                            type="number"
                            style={{
                              textAlign: 'center',
                              border: 'none',
                              backgroundColor: 'transparent',
                              width: '100%',
                            }}
                            disabled
                            value={rowState?.old_carton_basic_rate}


                            placeholder='Old basic rate'

                          />

                        </td>
                        <td className="zui-sticky-col3" style={rowState?.show_on_off ? { background: 'rgb(204, 204, 204)' } : {}}>
                          <input
                            type="number"
                            style={{
                              textAlign: 'center',
                              border: 'none',
                              backgroundColor: 'transparent',
                              width: '100%',
                            }}
                            disabled
                            value={rowState?.old_carton_rate}


                            placeholder='Old rate'

                          />

                        </td>
                        <td className="zui-sticky-col4" style={rowState?.show_on_off ? { background: 'rgb(204, 204, 204)' } : {}}>
                          <input
                            type="number"
                            style={{
                              textAlign: 'center',
                              border: 'none',
                              backgroundColor: 'transparent',
                              width: '100%',
                            }}
                            disabled
                            value={rowState?.old_stick_rate}


                            placeholder='Old stick rate'

                          />

                        </td>
                        <td className="zui-sticky-col5" style={rowState?.show_on_off ? { background: 'rgb(204, 204, 204)' } : {}}>
                          <input
                            type="number"
                            style={{
                              textAlign: 'center',
                              border: 'none',
                              backgroundColor: 'transparent',
                              width: '100%',
                            }}
                            disabled
                            value={rowState?.old_one_kg_rate}


                            placeholder='Old One Kg Rate'

                          />

                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={editData ? rowState?.product_code?.product_code : rowState?.product_code}
                            // value={rowState?.product_code?.product_code}

                            type="text"

                            disabled
                            placeholder='Product Code'

                          />

                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={editData ? rowState?.product_code?.product_name_and_grade?.material_type_name : rowState?.product_name_and_grade?.material_type_name}
                            type="text"
                            disabled
                            placeholder='Product Grade & Name & Type'

                          />

                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          <input type="checkbox" style={{ border: "none" }}
                            onChange={(e: any) => handleInnerShowOnOff(e, index)}
                            value={rowState?.show_on_off}
                            checked={rowState?.show_on_off}
                            //  disabled
                            placeholder='Show on-off' />
                        </td>

                        <td >
                          <input type="text" style={{ border: "none" }}
                            value={rowState?.product_rate_change_date}
                            disabled
                            placeholder='Date' />
                        </td>

                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>






                          <input
                            style={{ border: "none" }}
                            value={rowState?.carton_basic_rate}
                            type="text"

                            disabled
                            placeholder='Basic Rate'

                          />





                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>



                          {rowState?.isRateEditable ? (
                            <input
                              type="number"
                              disabled={rateType != 'Carton Rate' || rowState?.show_on_off}


                              style={rateType != 'Carton Rate' || rowState?.show_on_off ? {
                                textAlign: 'center',
                                border: '2px solid black',
                                backgroundColor: 'grey',
                                width: '100%',
                              } :
                                {
                                  textAlign: 'center',
                                  border: '2px solid black',
                                  backgroundColor: 'transparent',
                                  width: '100%',
                                }
                              }
                              value={Math.ceil(rowState.change_carton_rate)}


                              onChange={(e) =>
                                handleInputChange(index, 'carton_rate', e.target.value)
                              }
                              onBlur={() => disableEdit(index, 'Rate')}
                              autoFocus
                              placeholder="Change Rate"
                            />
                          ) : (
                            <div
                              // onClick={() => enableEdit(index, 'Rate')}

                              style={rateType != 'Carton Rate' || rowState?.show_on_off ? {
                                textAlign: 'center',
                                border: '2px solid black',
                                backgroundColor: 'grey',
                                cursor: 'pointer',
                                width: '100%',
                              } :
                                {
                                  textAlign: 'center',
                                  border: '2px solid black',
                                  backgroundColor: 'transparent',
                                  cursor: 'pointer',
                                  width: '100%',
                                }
                              }
                            >
                              {rowState?.change_carton_rate}
                            </div>
                          )}



                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>






                          {rowState?.isStickRateEditable ? (
                            <input
                              type="number"
                              disabled={rateType != 'Packet Rate' || rowState?.show_on_off}


                              style={rateType != 'Packet Rate' || rowState?.show_on_off ? {
                                textAlign: 'center',
                                border: '2px solid black',
                                backgroundColor: 'grey',
                                width: '100%',
                              } :
                                {
                                  textAlign: 'center',
                                  border: '2px solid black',
                                  backgroundColor: 'transparent',
                                  width: '100%',
                                }
                              }
                              value={Math.ceil(rowState?.change_stick_rate)}
                              onChange={(e) =>
                                handleInputChange(index, 'stick_rate', e.target.value)
                              }
                              onBlur={() => disableEdit(index, 'StickRate')}
                              autoFocus
                              placeholder="Change Stick Rate"
                            />
                          ) : (
                            <div
                              style={rateType != 'Packet Rate' || rowState?.show_on_off ? {
                                textAlign: 'center',
                                border: '2px solid black',
                                backgroundColor: 'grey',
                                cursor: 'pointer',
                                width: '100%',
                              } :
                                {
                                  textAlign: 'center',
                                  border: '2px solid black',
                                  backgroundColor: 'transparent',
                                  cursor: 'pointer',
                                  width: '100%',
                                }
                              }
                            >
                              {rowState?.change_stick_rate}
                            </div>
                          )}
                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>



                          {rowState?.isOneKgEditable ? (
                            <input
                              type="number"
                              disabled={rateType != '1 Kg. Rate' || rowState?.show_on_off}


                              style={rateType != '1 Kg. Rate' || rowState?.show_on_off ? {
                                textAlign: 'center',
                                border: '2px solid black',
                                backgroundColor: 'grey',
                                width: '100%',
                              } :
                                {
                                  textAlign: 'center',
                                  border: '2px solid black',
                                  backgroundColor: 'transparent',
                                  width: '100%',
                                }
                              }
                              value={Math.ceil(rowState?.change_one_kg_rate)}
                              onChange={(e) =>
                                handleInputChange(index, 'one_kg_rate', e.target.value)
                              }
                              onBlur={() => disableEdit(index, 'OneKg')}
                              autoFocus
                              placeholder="Change One Kg Rate"
                            />
                          ) : (
                            <div
                              style={rateType != '1 Kg. Rate' || rowState?.show_on_off ? {
                                textAlign: 'center',
                                border: '2px solid black',
                                backgroundColor: 'grey',
                                cursor: 'pointer',
                                width: '100%',
                              } :
                                {
                                  textAlign: 'center',
                                  border: '2px solid black',
                                  backgroundColor: 'transparent',
                                  cursor: 'pointer',
                                  width: '100%',
                                }
                              }
                            >
                              {rowState?.change_one_kg_rate}
                            </div>
                          )}
                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>




                          {rowState?.isPercentageEditable ? (

                            <input
                              type="number"


                              disabled={rateType != '% Rate' || rowState?.show_on_off}


                              style={rateType != '% Rate' || rowState?.show_on_off ? {
                                textAlign: 'center',
                                border: '2px solid black',
                                backgroundColor: 'grey',
                                width: '100%',
                              } :
                                {
                                  textAlign: 'center',
                                  border: '2px solid black',
                                  backgroundColor: 'transparent',
                                  width: '100%',
                                }
                              }

                              value={Math.ceil(rowState?.change_percentage_rate)}
                              onChange={(e) =>
                                handleInputChange(index, 'carton_percentage_rate', e.target.value)
                              }
                              onBlur={() => disableEdit(index, 'PercentageRate')}
                              placeholder="Change Percentage Rate"
                            />
                          ) : (
                            <div
                              // onClick={() => enableEdit(index, 'PercentageRate')}
                              style={rateType != '% Rate' || rowState?.show_on_off ? {
                                textAlign: 'center',
                                border: '2px solid black',
                                backgroundColor: 'grey',
                                cursor: 'pointer',
                                width: '100%',
                              } :
                                {
                                  textAlign: 'center',
                                  border: '2px solid black',
                                  backgroundColor: 'transparent',
                                  cursor: 'pointer',
                                  width: '100%',
                                }
                              }
                            >
                              {rowState?.change_percentage_rate}
                            </div>
                          )}




                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={"Lion"}
                            type="text"

                            disabled
                            placeholder='Gst Change Amount'

                          />
                        </td>

                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={editData ? rowState?.product_code?.panni_code_and_grade_name?.name_and_grade?.gst?.india_GST : rowState?.panni_code_and_grade_name?.name_and_grade?.gst?.india_GST}
                            type="text"

                            disabled
                            placeholder='Gst %'

                          />
                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={"2.50"}
                            type="text"

                            disabled
                            placeholder='Carton Basic Rate'

                          />
                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={"15.00"}
                            type="text"

                            disabled
                            placeholder='GST Amount'

                          />
                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={"84.75"}
                            type="text"

                            disabled
                            placeholder='Carton Rate'

                          />
                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={"339.00"}
                            type="text"

                            disabled
                            placeholder='Difference Rate'

                          />
                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          {/* <input
   style={{border:"none"}}
   
       type="text"
       value={new_carton_basic_rate}
       disabled
       placeholder='New basic rate'
     
     /> */}

                          <input
                            type="number"
                            style={{
                              textAlign: 'center',
                              border: 'none',
                              backgroundColor: 'transparent',
                              width: '100%',
                            }}
                            disabled
                            value={rowState?.new_carton_basic_rate}


                            placeholder='New basic rate'

                          />
                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          {/* <input
   style={{border:"none"}}
   
       type="text"
       value={new_carton_rate}
       disabled
       placeholder='New rate'
      
     /> */}
                          <input
                            type="number"
                            style={{
                              textAlign: 'center',
                              border: 'none',
                              backgroundColor: 'transparent',
                              width: '100%',
                            }}
                            disabled
                            value={rowState?.new_carton_rate}


                            placeholder='New basic rate'

                          />
                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          {/* <input
   style={{border:"none"}}
   
       type="text"
       value={new_stick_rate}
       disabled
       placeholder='New stick rate'
      
     /> */}
                          <input
                            type="number"
                            style={{
                              textAlign: 'center',
                              border: 'none',
                              backgroundColor: 'transparent',
                              width: '100%',
                            }}
                            disabled
                            value={rowState?.new_stick_rate}


                            placeholder='New basic rate'

                          />
                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          {/* <input
   style={{border:"none"}}
   value={new_one_kg_rate}
       type="text"
       disabled
       placeholder='New 1 kg rate'
      
     /> */}
                          <input
                            type="number"
                            style={{
                              textAlign: 'center',
                              border: 'none',
                              backgroundColor: 'transparent',
                              width: '100%',
                            }}
                            disabled
                            value={rowState?.new_one_kg_rate}


                            placeholder='New basic rate'

                          />
                        </td>

                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          {/* <input
   style={{border:"none"}}
   value={new_one_kg_rate}
       type="text"
       disabled
       placeholder='New 1 kg rate'
      
     /> */}
                          <input
                            type="number"
                            style={{
                              textAlign: 'center',
                              border: 'none',
                              backgroundColor: 'transparent',
                              width: '100%',
                            }}
                            disabled
                            value={rowState?.new_percentage_rate}


                            placeholder='New Percentage rate'

                          />
                        </td>
                        {/* <td>
   <input
   style={{border:"none"}}
   value={"7600.38"}
       type="text"
       disabled
       placeholder='Date'
     
     />
   </td> */}
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={editData ? rowState?.product_code?.product_price_list_1_piece_weight : rowState?.product_price_list_1_piece_weight}
                            type="text"
                            disabled
                            placeholder='Product Weight Price List'

                          />
                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={editData ? rowState?.product_code?.stick_nos : rowState?.stick_nos}
                            type="text"
                            disabled
                            placeholder='Stick Nos'

                          />
                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={editData ? rowState?.product_code?.carton_in_stick_packing : rowState?.carton_in_stick_packing}
                            type="text"
                            disabled
                            placeholder='Carton Stick'

                          />
                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={"7600.38"}
                            type="text"
                            disabled
                            placeholder='Product Total Qty'

                          />
                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={"Gram"}
                            type="text"
                            disabled
                            placeholder='Unit of Measure'

                          />
                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={editData ? rowState?.product_code?.carton_code_and_grade_and_name?.carton_empty_weight : rowState?.carton_code_and_grade_and_name?.carton_empty_weight}
                            type="text"
                            disabled
                            placeholder='Carton Weight'

                          />
                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={"10.05"}
                            type="text"
                            disabled
                            placeholder='Price List Mould Size MM'

                          />
                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={"76.38"}
                            type="text"
                            disabled
                            placeholder='Production Mould Size MM'

                          />
                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={editData ? rowState?.product_code?.product_single_piece_code?.product_ml_price_list?.ProductPriceListML : rowState?.product_single_piece_code?.product_ml_price_list?.ProductPriceListML}
                            type="text"
                            disabled
                            placeholder='ML Pricelist'

                          />
                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={editData ? rowState?.product_code?.product_color?.map((e: any) => e?.name) : rowState?.product_color?.map((e: any) => e?.name)}
                            type="text"
                            disabled
                            placeholder='Product Color'

                          />
                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={editData ? rowState?.product_code?.product_brand?.name : rowState?.product_brand?.name}
                            type="text"
                            disabled
                            placeholder='Product Brand'

                          />
                        </td>
                        <td style={rowState?.show_on_off ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={editData ? rowState?.product_code?.panni_code_and_grade_name?.color?.map((e: any) => e?.name) : rowState?.panni_code_and_grade_name?.color?.map((e: any) => e?.name)}
                            type="text"
                            disabled
                            placeholder='Panni Color'

                          />
                        </td>



                      </tr>
                    </>)
                  }) : "")

                  }












                </tbody>
              </table>


              </div>
              </div>

              <div className="zui-wrapper" >
            <div className="zui-scroller">



              <table className="zui-table">
                <thead>
                  <tr>

                    <th className="zui-sticky-col">Sr.<br />No</th>
                    <th className="zui-sticky-col2">Old Carton<br />Basic Rate</th>
                    <th className="zui-sticky-col3">Old Carton<br />Rate</th>
                    <th className="zui-sticky-col4">Old Stick<br />Rate</th>
                    <th className="zui-sticky-col5">Old 1 Kg<br />Rate</th>
                    <th>Product<br />Code</th>
                    <th>Product Grade,<br />Name & Type</th>
                    <th>
                      <div>
                        <input type="checkbox"
                          id="mainCheck"
                          onClick={handleMainShowOffDuplicate}
                          checked={showOnOffDuplicate} />
                      </div>
                      Show<br />On-Off

                    </th>
                    <th>Date</th>
                    <th>

                      Change Carton<br />Basic Rate</th>
                    <th>
                      {inputError && HeaderCartonRate !=0? <span style={{color:'red'}}>Allow only numbers, decimal point.</span>:null}
                      <div>
                        <input type='text'  name='Carton Rate' disabled style={{ textAlign: 'center',background:'none',border:'none' }}   />

                      </div>
                      Change Carton<br />Rate</th>
                    <th>
                    {inputError && HeaderStickRate !=0? <span style={{color:'red'}}>Allow only numbers, decimal point.</span>:null}
                      <div>
                        <input type='text' name='Stick Rate' disabled style={{ textAlign: 'center',background:'none',border:'none'  }}  />
                      </div>
                      Change Stick<br />Rate</th>
                    <th>
                    {inputError && HeaderKGRate !=0? <span style={{color:'red'}}>Allow only numbers, decimal point.</span>:null}
                      <div>
                        <input type='text' name='1 Kg. Rate' disabled style={{ textAlign: 'center',background:'none',border:'none'  }} 
                         
                        />
                      </div>

                      Change 1 KG<br />Rate</th>
                    <th>
                    {inputError  && PercentageRate !=0? <span style={{color:'red'}}>Allow only numbers, decimal point.</span>:null}
                      <div>
                        <input type='text' name='' disabled style={{ textAlign: 'center',background:'none',border:'none'  }} 
                          
                        />
                      </div>
                      % <br />Rate</th>
                    <th>GST Change<br />Amount</th>
                    <th>GST<br />Rate %</th>
                    <th>Carton<br />Basic Rate</th>
                    <th>GST<br />Amount</th>
                    <th>Carton<br />Rate</th>
                    <th>Difference<br />Rate</th>
                    <th>New Carton<br />Basic Rate</th>
                    <th>New Carton<br />Rate</th>
                    <th>New Stick<br />Rate</th>
                    <th>New 1 KG<br />Rate</th>
                    <th>New Percentage<br />Rate</th>
                    <th>Product Weight<br />Price List</th>
                    <th>Stick<br />Nos</th>
                    <th>Carton<br />Stick</th>
                    <th > Product Total <br />Qty</th>
                    <th >UOM</th>
                    <th >Carton<br />Weight</th>
                    <th >Price List <br />Mould Size(MM)</th>
                    <th >Production<br />Mould Size(MM)</th>
                    <th >ML Price<br />List</th>
                    <th >Product <br />Color</th>
                    <th >Product <br />Brand</th>
                    <th >Panni <br />Color</th>
                  </tr>
                </thead>
                <tbody>
                  {(priceListAction ? duplicate?.map((e: any, index: any) => {
                    const rowState = e || {};

                    return (<>
                      <tr key={index}>

                        <td className="zui-sticky-col" style={rowState?.show_on_off_duplicate ? { background: 'rgb(204, 204, 204)' } : {}}>
                          <input
                            style={rowState?.show_on_off_duplicate ? { border: "none", width: "50px", backgroundColor: "rgb(204, 204, 204)" } : { border: "none", width: "50px", backgroundColor: "rgb(204, 204, 204)" }}

                            type="text"
                            placeholder="ID"
                            disabled
                            value={index + 1}
                          />



                        </td>
                        <td className="zui-sticky-col2" style={rowState?.show_on_off_duplicate ? { background: 'rgb(204, 204, 204)' } : {}}>
                          <input
                            type="number"
                            style={{
                              textAlign: 'center',
                              border: 'none',
                              backgroundColor: 'transparent',
                              width: '100%',
                            }}
                            disabled
                            value={rowState?.old_carton_basic_rate}


                            placeholder='Old basic rate'

                          />

                        </td>
                        <td className="zui-sticky-col3" style={rowState?.show_on_off_duplicate ? { background: 'rgb(204, 204, 204)' } : {}}>
                          <input
                            type="number"
                            style={{
                              textAlign: 'center',
                              border: 'none',
                              backgroundColor: 'transparent',
                              width: '100%',
                            }}
                            disabled
                            value={rowState?.old_carton_rate}


                            placeholder='Old rate'

                          />

                        </td>
                        <td className="zui-sticky-col4" style={rowState?.show_on_off_duplicate ? { background: 'rgb(204, 204, 204)' } : {}}>
                          <input
                            type="number"
                            style={{
                              textAlign: 'center',
                              border: 'none',
                              backgroundColor: 'transparent',
                              width: '100%',
                            }}
                            disabled
                            value={rowState?.old_stick_rate}


                            placeholder='Old stick rate'

                          />

                        </td>
                        <td className="zui-sticky-col5" style={rowState?.show_on_off_duplicate ? { background: 'rgb(204, 204, 204)' } : {}}>
                          <input
                            type="number"
                            style={{
                              textAlign: 'center',
                              border: 'none',
                              backgroundColor: 'transparent',
                              width: '100%',
                            }}
                            disabled
                            value={rowState?.old_one_kg_rate}


                            placeholder='Old One Kg Rate'

                          />

                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={editData ? rowState?.product_code?.product_code : rowState?.product_code}
                            // value={rowState?.product_code?.product_code}

                            type="text"

                            disabled
                            placeholder='Product Code'

                          />

                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={editData ? rowState?.product_code?.product_name_and_grade?.material_type_name : rowState?.product_name_and_grade?.material_type_name}
                            type="text"
                            disabled
                            placeholder='Product Grade & Name & Type'

                          />

                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          <input type="checkbox" style={{ border: "none" }}
                            onChange={(e: any) => handleInnerShowOnOffDuplicate(e, index)}
                            value={rowState?.show_on_off_duplicate}
                            checked={rowState?.show_on_off_duplicate}
                            //  disabled
                            placeholder='Show on-off' />
                        </td>

                        <td >
                          <input type="text" style={{ border: "none" }}
                            value={rowState?.product_rate_change_date}
                            disabled
                            placeholder='Date' />
                        </td>

                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>






                          <input
                            style={{ border: "none" }}
                            value={rowState?.carton_basic_rate}
                            type="text"

                            disabled
                            placeholder='Basic Rate'

                          />





                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>



                          {rowState?.isRateEditable ? (
                            <input
                              type="number"
                              disabled={rateType != 'Carton Rate' || rowState?.show_on_off_duplicate}


                              style={rateType != 'Carton Rate' || rowState?.show_on_off_duplicate ? {
                                textAlign: 'center',
                                border: '2px solid black',
                                backgroundColor: 'grey',
                                width: '100%',
                              } :
                                {
                                  textAlign: 'center',
                                  border: '2px solid black',
                                  backgroundColor: 'transparent',
                                  width: '100%',
                                }
                              }
                              value={Math.ceil(rowState.change_carton_rate)}


                              onChange={(e) =>
                                handleInputChange(index, 'carton_rate', e.target.value)
                              }
                              onBlur={() => disableEdit(index, 'Rate')}
                              autoFocus
                              placeholder="Change Rate"
                            />
                          ) : (
                            <div
                              // onClick={() => enableEdit(index, 'Rate')}

                              style={rateType != 'Carton Rate' || rowState?.show_on_off_duplicate ? {
                                textAlign: 'center',
                                border: '2px solid black',
                                backgroundColor: 'grey',
                                cursor: 'pointer',
                                width: '100%',
                              } :
                                {
                                  textAlign: 'center',
                                  border: '2px solid black',
                                  backgroundColor: 'transparent',
                                  cursor: 'pointer',
                                  width: '100%',
                                }
                              }
                            >
                              {rowState?.change_carton_rate}
                            </div>
                          )}



                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>






                          {rowState?.isStickRateEditable ? (
                            <input
                              type="number"
                              disabled={rateType != 'Packet Rate' || rowState?.show_on_off_duplicate}


                              style={rateType != 'Packet Rate' || rowState?.show_on_off_duplicate ? {
                                textAlign: 'center',
                                border: '2px solid black',
                                backgroundColor: 'grey',
                                width: '100%',
                              } :
                                {
                                  textAlign: 'center',
                                  border: '2px solid black',
                                  backgroundColor: 'transparent',
                                  width: '100%',
                                }
                              }
                              value={Math.ceil(rowState?.change_stick_rate)}
                              onChange={(e) =>
                                handleInputChange(index, 'stick_rate', e.target.value)
                              }
                              onBlur={() => disableEdit(index, 'StickRate')}
                              autoFocus
                              placeholder="Change Stick Rate"
                            />
                          ) : (
                            <div
                              style={rateType != 'Packet Rate' || rowState?.show_on_off_duplicate ? {
                                textAlign: 'center',
                                border: '2px solid black',
                                backgroundColor: 'grey',
                                cursor: 'pointer',
                                width: '100%',
                              } :
                                {
                                  textAlign: 'center',
                                  border: '2px solid black',
                                  backgroundColor: 'transparent',
                                  cursor: 'pointer',
                                  width: '100%',
                                }
                              }
                            >
                              {rowState?.change_stick_rate}
                            </div>
                          )}
                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>



                          {rowState?.isOneKgEditable ? (
                            <input
                              type="number"
                              disabled={rateType != '1 Kg. Rate' || rowState?.show_on_off_duplicate}


                              style={rateType != '1 Kg. Rate' || rowState?.show_on_off_duplicate ? {
                                textAlign: 'center',
                                border: '2px solid black',
                                backgroundColor: 'grey',
                                width: '100%',
                              } :
                                {
                                  textAlign: 'center',
                                  border: '2px solid black',
                                  backgroundColor: 'transparent',
                                  width: '100%',
                                }
                              }
                              value={Math.ceil(rowState?.change_one_kg_rate)}
                              onChange={(e) =>
                                handleInputChange(index, 'one_kg_rate', e.target.value)
                              }
                              onBlur={() => disableEdit(index, 'OneKg')}
                              autoFocus
                              placeholder="Change One Kg Rate"
                            />
                          ) : (
                            <div
                              style={rateType != '1 Kg. Rate' || rowState?.show_on_off_duplicate ? {
                                textAlign: 'center',
                                border: '2px solid black',
                                backgroundColor: 'grey',
                                cursor: 'pointer',
                                width: '100%',
                              } :
                                {
                                  textAlign: 'center',
                                  border: '2px solid black',
                                  backgroundColor: 'transparent',
                                  cursor: 'pointer',
                                  width: '100%',
                                }
                              }
                            >
                              {rowState?.change_one_kg_rate}
                            </div>
                          )}
                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>




                          {rowState?.isPercentageEditable ? (

                            <input
                              type="number"


                              disabled={rateType != '% Rate' || rowState?.show_on_off_duplicate}


                              style={rateType != '% Rate' || rowState?.show_on_off_duplicate ? {
                                textAlign: 'center',
                                border: '2px solid black',
                                backgroundColor: 'grey',
                                width: '100%',
                              } :
                                {
                                  textAlign: 'center',
                                  border: '2px solid black',
                                  backgroundColor: 'transparent',
                                  width: '100%',
                                }
                              }

                              value={Math.ceil(rowState?.change_percentage_rate)}
                              onChange={(e) =>
                                handleInputChange(index, 'carton_percentage_rate', e.target.value)
                              }
                              onBlur={() => disableEdit(index, 'PercentageRate')}
                              placeholder="Change Percentage Rate"
                            />
                          ) : (
                            <div
                              // onClick={() => enableEdit(index, 'PercentageRate')}
                              style={rateType != '% Rate' || rowState?.show_on_off_duplicate ? {
                                textAlign: 'center',
                                border: '2px solid black',
                                backgroundColor: 'grey',
                                cursor: 'pointer',
                                width: '100%',
                              } :
                                {
                                  textAlign: 'center',
                                  border: '2px solid black',
                                  backgroundColor: 'transparent',
                                  cursor: 'pointer',
                                  width: '100%',
                                }
                              }
                            >
                              {rowState?.change_percentage_rate}
                            </div>
                          )}




                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={"Lion"}
                            type="text"

                            disabled
                            placeholder='Gst Change Amount'

                          />
                        </td>

                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={editData ? rowState?.product_code?.panni_code_and_grade_name?.name_and_grade?.gst?.india_GST : rowState?.panni_code_and_grade_name?.name_and_grade?.gst?.india_GST}
                            type="text"

                            disabled
                            placeholder='Gst %'

                          />
                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={"2.50"}
                            type="text"

                            disabled
                            placeholder='Carton Basic Rate'

                          />
                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={"15.00"}
                            type="text"

                            disabled
                            placeholder='GST Amount'

                          />
                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={"84.75"}
                            type="text"

                            disabled
                            placeholder='Carton Rate'

                          />
                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={"339.00"}
                            type="text"

                            disabled
                            placeholder='Difference Rate'

                          />
                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          {/* <input
   style={{border:"none"}}
   
       type="text"
       value={new_carton_basic_rate}
       disabled
       placeholder='New basic rate'
     
     /> */}

                          <input
                            type="number"
                            style={{
                              textAlign: 'center',
                              border: 'none',
                              backgroundColor: 'transparent',
                              width: '100%',
                            }}
                            disabled
                            value={rowState?.new_carton_basic_rate}


                            placeholder='New basic rate'

                          />
                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          {/* <input
   style={{border:"none"}}
   
       type="text"
       value={new_carton_rate}
       disabled
       placeholder='New rate'
      
     /> */}
                          <input
                            type="number"
                            style={{
                              textAlign: 'center',
                              border: 'none',
                              backgroundColor: 'transparent',
                              width: '100%',
                            }}
                            disabled
                            value={rowState?.new_carton_rate}


                            placeholder='New basic rate'

                          />
                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          {/* <input
   style={{border:"none"}}
   
       type="text"
       value={new_stick_rate}
       disabled
       placeholder='New stick rate'
      
     /> */}
                          <input
                            type="number"
                            style={{
                              textAlign: 'center',
                              border: 'none',
                              backgroundColor: 'transparent',
                              width: '100%',
                            }}
                            disabled
                            value={rowState?.new_stick_rate}


                            placeholder='New basic rate'

                          />
                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          {/* <input
   style={{border:"none"}}
   value={new_one_kg_rate}
       type="text"
       disabled
       placeholder='New 1 kg rate'
      
     /> */}
                          <input
                            type="number"
                            style={{
                              textAlign: 'center',
                              border: 'none',
                              backgroundColor: 'transparent',
                              width: '100%',
                            }}
                            disabled
                            value={rowState?.new_one_kg_rate}


                            placeholder='New basic rate'

                          />
                        </td>

                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          {/* <input
   style={{border:"none"}}
   value={new_one_kg_rate}
       type="text"
       disabled
       placeholder='New 1 kg rate'
      
     /> */}
                          <input
                            type="number"
                            style={{
                              textAlign: 'center',
                              border: 'none',
                              backgroundColor: 'transparent',
                              width: '100%',
                            }}
                            disabled
                            value={rowState?.new_percentage_rate}


                            placeholder='New Percentage rate'

                          />
                        </td>
                        {/* <td>
   <input
   style={{border:"none"}}
   value={"7600.38"}
       type="text"
       disabled
       placeholder='Date'
     
     />
   </td> */}
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={editData ? rowState?.product_code?.product_price_list_1_piece_weight : rowState?.product_price_list_1_piece_weight}
                            type="text"
                            disabled
                            placeholder='Product Weight Price List'

                          />
                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={editData ? rowState?.product_code?.stick_nos : rowState?.stick_nos}
                            type="text"
                            disabled
                            placeholder='Stick Nos'

                          />
                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={editData ? rowState?.product_code?.carton_in_stick_packing : rowState?.carton_in_stick_packing}
                            type="text"
                            disabled
                            placeholder='Carton Stick'

                          />
                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={"7600.38"}
                            type="text"
                            disabled
                            placeholder='Product Total Qty'

                          />
                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={"Gram"}
                            type="text"
                            disabled
                            placeholder='Unit of Measure'

                          />
                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={editData ? rowState?.product_code?.carton_code_and_grade_and_name?.carton_empty_weight : rowState?.carton_code_and_grade_and_name?.carton_empty_weight}
                            type="text"
                            disabled
                            placeholder='Carton Weight'

                          />
                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={"10.05"}
                            type="text"
                            disabled
                            placeholder='Price List Mould Size MM'

                          />
                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={"76.38"}
                            type="text"
                            disabled
                            placeholder='Production Mould Size MM'

                          />
                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={editData ? rowState?.product_code?.product_single_piece_code?.product_ml_price_list?.ProductPriceListML : rowState?.product_single_piece_code?.product_ml_price_list?.ProductPriceListML}
                            type="text"
                            disabled
                            placeholder='ML Pricelist'

                          />
                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={editData ? rowState?.product_code?.product_color?.map((e: any) => e?.name) : rowState?.product_color?.map((e: any) => e?.name)}
                            type="text"
                            disabled
                            placeholder='Product Color'

                          />
                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={editData ? rowState?.product_code?.product_brand?.name : rowState?.product_brand?.name}
                            type="text"
                            disabled
                            placeholder='Product Brand'

                          />
                        </td>
                        <td style={rowState?.show_on_off_duplicate ? { background: '#0a8e0aa3' } : {}}>
                          <input
                            style={{ border: "none" }}
                            value={editData ? rowState?.product_code?.panni_code_and_grade_name?.color?.map((e: any) => e?.name) : rowState?.panni_code_and_grade_name?.color?.map((e: any) => e?.name)}
                            type="text"
                            disabled
                            placeholder='Panni Color'

                          />
                        </td>



                      </tr>
                    </>)
                  }) : "")

                  }












                </tbody>
              </table>
            </div>

<div className='d-flex justify-content-end'>
<button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                        onClick={handleSave}
                                                    >Save</button>


<button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                        onClick={handleRemove}
                                                    >Remove</button>
</div>

          </div>
        </>}
    </>

  )
}

export default Price_List_Table