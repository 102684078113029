import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Columns_Mould, titleofheaders } from "./Columns_Mould"
import Table from "../../components/Dynamic_Table/Table/Table";
const List = () => {
  const Breadcrumb_Primary_Title = "Home";
  const Breadcrumb_Primary_Link = "Color";

  const Breadcrumb_Parent_Title = "Machinery";

  const Breadcrumb_Title_Name = "Mould Size";

  const apiname = process.env.REACT_APP_MOULD_API?.toString();

  const filter = {

    mould_code: "",
    mould_shape: "",
    purchase_date: "",
    product_list_diameter_mm: "",
    product_mould_diameter_mm: "",
    product_length_diameter: "",
    product_width_diameter: "",
    tfm_machine_usd_mould: "",
    mould_company: "",
    model_number: "",
    mould_brand: "",
    mould_location: "",
    cavity_qty: "",
    mould_cutter_thickness_mm: "",
    forming_cutting_area_sheet_length_mm: "",
    forming_cutting_area_sheet_width_mm: "",
    tfm_chain_infield_maximum_mm: "",
    tfm_chain_infield_minimum_mm: "",
    mould_cavity_hole_height_mm: "",
    mould_lip_mm: "",
    mould_new_old: "",
    sheet_length: "",
    sheet_width: "",
    length: "",
    cutter_gap: "",
    status: "",
    created_by: "",
    updated_by: "",

  };

  const addButtonName = "Mould Size";
  const addButtonPageName = "Mould"
  const permissionClass = {
    front: "mould",
    back: "mouldshape"
  }
  const stickyStart = 1;
  const stickyEnd = 2;

  return (
    <>
      <div className="content-wrapper">
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{Breadcrumb_Title_Name} List</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to={`/list${Breadcrumb_Primary_Link}`}
                      className="text-decoration-none text-black"
                    >
                      {Breadcrumb_Primary_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      {Breadcrumb_Parent_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {Breadcrumb_Title_Name} List
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="container p-0" style={{ maxWidth: "100%" }}>
                {/* <BasicTable/> */}
                <Table
                  apiname={apiname}
                  filterFields={filter}
                  addButtonName={addButtonName}
                  stickyStart={stickyStart}
                  stickyEnd={stickyEnd}
                  TableColumns={Columns_Mould}
                  addButtonPageName={addButtonPageName}
                  titleofheaders={titleofheaders}
                  permissionClass={permissionClass}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default List;
