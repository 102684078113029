import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../components/Header/header.css";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Alert } from "../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate } from "../../hooks/Api";
import InputField from "../../components/Form Component/inputfield";
import Error from "../../components/Form Component/error";
import { green } from "@mui/material/colors";
import useFocusOnEnter from "../../components/focus";

const Add_Document = () => {

    const focus:any = useRef();
    const {onEnterKey} = useFocusOnEnter(focus);
    const navigate = useNavigate()

    const [File, setFile]: any = useState()
    const apiname = process.env.REACT_APP_DOCUMENT_API?.toString()


    const { mutate: document, data, isError, isLoading, isSuccess, error }:any = useCreate(apiname)


    const { register, handleSubmit, setError, formState: { errors }, reset, setValue ,getValues, control}: any = useForm();


    const onFileChange = (e:any) => {
        setFile(e.target.files[0].name)
    }

    
    useEffect(() => {
        if (data) {
            reset(data)
        }
    }, [data]);

    const handleReset = () => {
        reset()
    }

    const onSubmit = (documentDetail: any) => {
        const formData = new FormData();

        for (const [key, value] of Object.entries(documentDetail)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else {
                formData.append(key, formvalue[0])
            }
        }
        document(formData);
       
    }





    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listDocument', { state: { showDocumentToast: true }})
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])



    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);



    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Document</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/listColor" className="text-decoration-none text-black">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Masters</a></li>
                                    <li className="breadcrumb-item"><a href="/brandlist" className="text-decoration-none text-black">Document</a></li>
                                    <li className="breadcrumb-item active">Add Document</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Document</span></h5>
                            </div>
                            <form 
                              ref={focus}
                              onKeyUp={event => onEnterKey(event)}
                            
                            onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>
                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message.name} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-3 mt-2">
                                                    <Controller
                                                        name="title"
                                                        control={control}
                                                        rules={{ required: {value:true, message:"Document Title is required" } }}
                                                        defaultValue=""
                                                        render={({ field }) => (
                                                            <InputField
                                                                label={"Title"}
                                                                placeholder="Enter Document Title"
                                                                onChange={(e: any) => field.onChange(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                                                value={field.value} />
                                                        )}
                                                    />
                                                    {errors.title && <Error>{errors?.title?.message}</Error>}
                                                </div>
                                                
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Document Type</label>
                                                    <select className="form-select" {...register("type",
                                                     {
                                                        required: { value: true, message: "Type of File is required" },

                                                    })}>
                                                        <option value="" disabled selected>Select Any</option>
                                                        <option value="pdf">PDF</option>
                                                        <option value="image">Image</option>
                                                    </select>
                                                    {errors.type && <Error>{errors?.type?.message}</Error>}
                                                </div>

                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">File</label>
                                                    <label style={{cursor:"pointer"}}>
                                                    <img src={pdfLogo} alt='' className="uploaded-image" />
                                                    <input data-required="image" type="file"  id="file_upload" accept="application/pdf" className="image-input" data-traget-resolution="image_resolution" {...register("doc",{onChange:onFileChange, required: { value: true, message: "File is required" },})} hidden />
                                                    </label>
                                                    <p>{File}</p>
                                                    {errors.doc && <Error>{errors?.doc?.message}</Error>}
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                <a type="button" className="btn border rounded text-white px-4 mt-2 mx-3" onClick={handleSubmit(onSubmit)}>Submit</a>

<button type="button" className="btn border-1 rounded  border-1 px-4 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

<button type="button" className="btn border-1 rounded  px-4 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listDocument")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Add_Document