import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/esm/Button";
import { useCreate } from '../../hooks/Api';
import { Alert } from "../../components/Model/Model";
import { useForm } from 'react-hook-form';
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from '../focus';
import Select from "react-select"
import { useNavigate } from 'react-router-dom';
import { useFetchGSTDropDown } from '../../hooks/DropDown_Api';
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'


const customIndicator = {
    IndicatorsContainer:IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer:IndicatorsContainerCreate
}


export const Material_Density_Model = ({refetchForm,open,setOpen,setSubmitted}:any) => {
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()

    const { register:register, handleSubmit:handleSubmit, formState: { errors },reset,setValue}: any = useForm({
        mode: "onChange"
    });


    const apiname = process.env.REACT_APP_MATERIAL_DENSITY_API?.toString()


    const  { mutate: Material, data,isSuccess,error,isError}:any = useCreate(apiname)
  
    const [valueDensity, setValueDensity] = useState('')

    const handleDensity = (e: any) => {

        setValueDensity(e.target.value)
    };


//     useEffect(()=>{
// if(isSuccess){
//     setOpen(true)
//     reset()
// }



// refetchForm()
//     },[isSuccess])


   
    const [forGST,setForGSTValue]:any = useState([])

    

    useEffect(()=>{
if(isSuccess){
    setSubmitted(true)
    setOpen(false)
    reset()
    setForGSTValue(null)
}
refetchForm()
    },[isSuccess,data])

 
const handleReset = () => {
    reset()
    setForGSTValue(null)
    }

    const onSubmit = (materialdetail: any) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(materialdetail)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else
            {
                formData.append(key, formvalue[0])
            }
            if (key === 'material_density') {
                let decimalNumber = Number(valueDensity);
                const formattedString = decimalNumber.toFixed(3);
                formData.append('material_density', formattedString)
            }
            
        }
        Material(formData);
        
    }



    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);


    const [optionsGST, setOptionsGST] = useState([])

    const { data: gstData, refetch: gstRefetch } = useFetchGSTDropDown()

    useEffect(() => {
        setOptionsGST(gstData)
    }, [gstData])

    const handleChange = (e: any) => {
        setValue("gst",`${e.value}`)
        setForGSTValue({label:e?.label,value:e?.value})

    }  

  


    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }



  return (
    <>
    <Modal  
    
    size='xl'
    
    show={open} onHide={()=>{
        setShow(false)
        reset()
        setOpen(false)}} aria-labelledby="contained-modal-title-vcenter"
centered    >
  <Modal.Header closeButton>
    <Modal.Title style={{fontSize:'16px'}}>Material Density</Modal.Title>
  </Modal.Header>
  <Modal.Body> 
     
  <form ref={focus} onKeyUp={event => onEnterKey(event)} onSubmit={handleSubmit(onSubmit)}>
                                {show && showmessage === "success" ? <Alert color={showmessage} message={"Successfully Added"} /> : ""}

                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Type Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder="Material Type Name" {...register("material_type_name", { onChange:handleValue,required: { value: true, message: "Material Type Name is required" }})} />
                                                    <div className="input-group-append">
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                        </div>
                                                    <span className='text-red'>{errors?.material_type_name?.message}</span>
                                                    
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Density <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Material Density"  {...register("material_density", {onChange: handleDensity, required: { value: true, message: "Material Density is required" }})} />
                                                        <div className="input-group-append">
                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                        </div>
                                                    <span className='text-red'>{errors?.material_density?.message}</span>
                                                    
                                                </div>
                                               
                                                <div className="col-sm-4 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">GST <span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                     {...register("gst", { required: { value: true, message: "Gst is required" }})}
                                                    placeholder={'Select Gst'}
                                                    value={forGST}
                                                    components={customIndicator}
                                                    options={optionsGST}
                                                    // isOptionDisabled={(optionsGST:any)=>
                                                    //     {
                                                    //         if(optionsGST.status === "inactive" || optionsGST.deleted == true){
                                                    //         return optionsGST
                                                    //         }
                                                    //        }}
                                                    onChange={handleChange}
                                                    />
                                                     <span className='text-red'>{errors?.gst?.message}</span>
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Grade Sutra <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Grade Sutra"   {...register("grade_sutra", {onChange:handleValue, required: { value: true, message: "Grade Sutra is required" }})} />
                                                        <div className="input-group-append">
                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                   
                                                        </div>
                                                        <span className='text-red'>{errors?.grade_sutra?.message}</span>
                                                   
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Polymer Grade </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Polymer Grade"  {...register("grade_polymer", { onChange:handleValue, required: { value: false, message: "Polymer Grade is required" }})} />
                                                        <div className="input-group-append">
                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                        </div>
                                                        <span className='text-red'>{errors?.grade_polymer?.message}</span>
                                                    
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="status">Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status",
                                                        {
                                                            required: { value: true, message: "Status is required" }, maxLength: 30,

                                                        })}>
                                                        <option value="" selected>Select Any </option>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"inactive"}>Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {/* <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                            <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                            <button 
                                                type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3" 
                                                style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                onClick={handleSubmit(onSubmit)}>Submit</button>
                                                <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                                                <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listMaterialDensity")}>Cancel</button>

                                            </div>
                                            </div>
                                        </div>
                                    </div> */}
                            </form>
      
</Modal.Body>
  <Modal.Footer>
  
    <Button variant="info" className="text-white" 
 onClick={handleSubmit(onSubmit)}
 style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
    >
      Submit
    </Button>
    <Button variant="info" className="text-white" style={{ borderColor: "blue", background: "blue", color: "white" }}
 onClick={handleReset}
 >
   Reset
 </Button>
    <Button variant="secondary" style={{ borderColor: "red", background: "red", color: "white" }} onClick={()=>{setOpen(false)}}>
      Cancel
    </Button>
  </Modal.Footer>
</Modal>
    </>
  )
}



