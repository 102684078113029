import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Columns_PriceList, titleofheaders } from "./Columns_PriceList"
import Table from "../../components/Dynamic_Table/Table/Table";
const List_Address_Details = () => {
  const Breadcrumb_Primary_Title = "Home";
  const Breadcrumb_Primary_Link = "";

  const Breadcrumb_Parent_Title = "Sales And Marketing";

  const Breadcrumb_Title_Name = "Price";

  const apiname = process.env.REACT_APP_PRICELIST_API?.toString();

  const filter = {

    // old_carton_basic_rate: "",
    // old_carton_rate: "",
    // old_stick_rate: "",
    // old_one_kg_rate: "",
    // product_code: "",
    // product_name_and_grade_and_type: "",
    // show_on_off: "",
    // change_stick_rate: "",
    // change_carton_rate: "",
    // change_one_kg_rate: "",
    // change_rate: "",
    // change_carton_basic_rate: "",
    // gst_change_amount: "",
    // gst_rate: "",
    // carton_basic_rate2: "",
    // gst_amount: "",
    // change_carton_rate2: "",
    // difference_rate: "",
    // new_carton_basic_rate: "",
    // new_carton_rate: "",
    // new_stick_rate: "",
    // new_one_kg_rate: "",
    // product_weight_price_list: "",
    // stick_nos: "",
    // carton_stick: "",
    // product_total_qty: "",
    // unit_of_measure: "",
    // carton_weight: "",
    // price_list_mould_size_mm: "",
    // production_mould_size_mm: "",
    // ml_price_list: "",
    // product_color: "",  
    // product_brand: "",
    // panni_color:"",
    // date: "",
   
    code:"",
    name:"",
    grade:"",
    // product_type:"",
    // price_list_type:"",
    price:"",
    rate_type:"",
    rate:"",
    rate_change_date:"",

  
  
  
    created_by:"",
    updated_by:"",
    status:"",

  };

  const addButtonName = "Price List";
  const addButtonPageName = "PriceList"
  const permissionClass = {
    front: "price_list_code",
    back: "pricelist"
  }
  const stickyStart = 1;
  const stickyEnd = 2;




  return (
    <>
      <div className="content-wrapper">
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{Breadcrumb_Title_Name} List</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to={`/list${Breadcrumb_Primary_Link}`}
                      className="text-decoration-none text-black"
                    >
                      {Breadcrumb_Primary_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      {Breadcrumb_Parent_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {Breadcrumb_Title_Name} List
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="container p-0" style={{ maxWidth: "100%" }}>
                <Table
                  apiname={apiname}
                  filterFields={filter}
                  addButtonName={addButtonName}
                  stickyStart={stickyStart}
                  stickyEnd={stickyEnd}
                  TableColumns={Columns_PriceList}
                  addButtonPageName={addButtonPageName}
                  titleofheaders={titleofheaders}
                  permissionClass={permissionClass}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default List_Address_Details;


