// import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../../components/Cell/Status";
import { useFetch, useUpdateSingle } from "../../../hooks/Api";
import noImage from "../../../images/noImage.png"
import Modal from "react-bootstrap/esm/Modal";



interface Data {
  selection: any

  

  code: any
  godown_stock: any
  balance_stock: any
  lee10_price: any
  order_qty: any
  uom: any
  discount_after_tax: any
  discount: any
  one_kg_rate:any
  after_discount_box: any
  brand: any
  nos_strip: any
  box_strip: any
  cup_weight: any
  box_weight: any
  basic_discount:any
  total_basic_discount:any
  basic_rate_per:any
  total_basic:any
  gst_rate:any
  gst_amount:any
  net_amount:any


  dispatch_order:any



  created_at:any

  created_by:any
  updated_by:any
  updated_at:any


  status: any;
  // actions: any;
}



export const titleofheaders=[
  {
    id: `srno`,
    Header: `Sr No`,
  },






  

  {
    id: 'code',
    Header: "Product Code",
   
  },
  {
    id: 'godown_stock',
    Header: "Godown Stock",
   
 
  },
  {
    id: 'balance_stock',
    Header:" Balance Stock",
  
 
  },
  {
    id: 'lee10_price',
    Header: "Lee10 Price",
   
 
  },
  {
    id: 'uom',
    Header: "UOM",
   
 
  },
  {
    id: 'order_qty',
    Header: "Order Qty",
   
 
  },


  {
    id: 'discount_after_tax',
    Header: "Discount After Tax",
   
 
  },
  {
    id: 'discount',
    Header:" Discount %",
   
 
  },
  {
    id: 'one_kg_rate',
    Header:" 1 KG Rate",
    
  },
  {
    id: 'after_discount_box',
    Header: "After Discount Box",
   
 
  },
  {
    id: 'brand',
    Header: "Brand",
   
  },
  {
    id: 'nos_strip',
    Header: "Nos/Strip",
   
  },
  {
    id: 'box_strip',
    Header: "Box/Strip",
  
  },
  {
    id: 'cup_weight',
    Header: "Cup Weight",
   
  },
  {
    id: 'box_weight',
    Header: "Box Weight",
    
  },
  {
    id: 'basic_discount',
    Header: "Basic Discount",
  
  },
  {
    id: 'total_basic_discount',
    Header: "Total Basic Discount",
   
  },
  {
    id: 'basic_rate_per',
    Header:" Basic Rate per",
   
  },
  
 
  {
    id: `total_basic`,
    Header: "Total Basic",
   
  },
  {
    id: `gst_rate`,
    Header: "GST Rate %",
   
  },
  {
    id: `gst_amount`,
    Header: "GST Amount",
    
  },
  {
    id: `net_amount`,
    Header: "Net Amount",
  
  },
  // {
  //     id: 'order_actions',
  //     Header: 'Actions',
  
    
  // },


  {
    id: 'created_at',
    Header: "Created At",
  },
  {
    id: 'created_by',
    Header: "Created By",
  },
  {
    id: 'updated_at',
    Header: "Updated At",
  },
  {
    id: 'updated_by',
    Header: "Updated By",
  },
  {
    id: 'status',
    Header: 'Status',
  },
]
      



export const Columns_Sales_Order: any = [



 
  {
    id: `srno`,
    Header: () => <div style={{display:"inline"}}>Sr.<br/>No</div>,
    sticky:"left",
  },

  {
    id: `name`,
    Header: ()=><div style={{display:'inline'}}>Name</div>,
    sticky:"left",
    accessor:'name',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.extra_response?.customer?.name}
        </div>
      )
    }
  },
  {
    id: `code`,
    Header: ()=><div style={{display:'inline'}}>Sales Order<br/>Code</div>,
    // sticky:"left",
    accessor:'code',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original?.code}
        </div>
      )
    }
  },
  {
    id: `lead_generate`,
    Header: ()=><div style={{display:'inline'}}>Generated <br/>Leads</div>,
    accessor:'lead_generate',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.extra_response?.lead_generate?.lead_generate_code?row.original.extra_response?.lead_generate?.lead_generate_code:<span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
      )
    }
  },
  {
    id: `address`,
    Header: ()=><div style={{display:'inline'}}>Delivery <br/>Address</div>,
    accessor:'address',
    Cell: ({ row }: any) => {
      return(
        <div>
          {`${row.original.extra_response?.delivery_address?.delivery_address_number},${row.original.extra_response?.delivery_address?.building_name?row.original.extra_response?.delivery_address?.building_name:row.original.extra_response?.delivery_address?.bunglow_name}`}
        </div>
      )
    }
  },
  {
    id: `transportation`,
    Header: ()=><div style={{display:'inline'}}>Transport<br/>Detail</div>,
    accessor:'transportation',
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.extra_response?.transport_detail?.transport_detail_name}
        </div>
      )
    }
  },
  
  {
    id: 'status',
    Header: 'Status',
    accessor:'status',

 
  },


  

  
  
 
 

 

  {
    id: 'created_at',
    Header: () => <div style={{display:"inline"}}>Created<br/>At</div>,
    accessor:'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{display:"inline"}}>Created<br/>By</div>,
    accessor:'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username

 
  },
  {
    id: 'updated_at',
    Header: () => <div style={{display:"inline"}}>Updated<br/>At</div>,
    accessor:'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{display:"inline"}}>Updated<br/>By</div>,
    accessor:'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },
  
 
  // {
  //   id: 'Actions',
  //   Header: 'Actions',

  
  // },
  {
    id: 'dispatch_order',
    Header: () => <div style={{display:"inline"}}>Dispatch<br/>Order</div>,
    // Header: 'Dispatch Order',

  
  },
  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_Sales_Order, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows} = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SALES_SALES_ORDER_API}/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
  // {
  //   id: 'dispatch_order',
  //   Header: () => <div style={{display:"inline"}}>Dispatch<br/>Order</div>,
  //   // Header: 'Dispatch Order',
  //   // sticky:'right'
  
  // },
]




