
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../../components/Cell/Status";
import Actions from "../../../components/Cell/Actions";
import Modal from "react-bootstrap/esm/Modal";
import noImage from "../../../images/noImage.png"

interface Data {
  selection: any
 
  employee:any
  type:any
  customer:any
  priority:any
  account_type: any
  account_number: any
  swift_code: any
  iec_code: any
  ifsc_code:any
  cheque_print_name: any
  amount_transfer_account_name: any
  pan_number: any
  contact_number: any
  bank:any
  city:any
  branch:any
  micr:any
  district:any
  state:any
  country:any
  pincode:any


  created_at:any
  created_by:any
  updated_by:any
  updated_at:any
  status: any;
  actions: any;
}


export const titleofheaders=[
  {
    id: `srno`,
    Header: `Sr No`,
  },
  {
    id: `type`,
    Header: "PersonType",
   
},
{
    id: `priority`,
    Header: "Priority",
 
},

{
  id: `customer`,
  Header: "Customer Code & Name",

},
{
  id: `employee`,
  Header: "Employee Code & Name",

},
{
  id: `ifsc_code`,
  Header: "IFSCCode",

},
{
  id: `bank`,
  Header: "Bank Name",
 
},
{
  id: `city`,
  Header: "City Name",

},
{
  id: `branch`,
  Header: "Branch Name",
 
},
{
  id: `account_type`,
  Header: "Account Type",

},
{
  id: `account_number`,
  Header: "Account Number",
 
 
},
{
  id: `swift_code`,
  Header: "Bank SwiftCode",
 
},
{
  id: `iec_code`,
  Header: "Bank IECCode",

},
{
  id: `micr`,
  Header: "Bank Micr Code",

},
{
  id: `cheque_print_name`,
  Header: "Cheque PrintName",

},
{
  id: `amount_transfer_account_name`,
  Header: "Amount TransferAccount Name",
 
},
{
  id: `district`,
  Header: "District",

},
{
  id: `state`,
  Header: "State",
 

},
{
  id: `country`,
  Header: "Country",
 
},
{
  id: `pincode`,
  Header: "Pin Code",

},
{
  id: `pan_number`,
  Header: "Pan Number",
 
},
{
  id: `contact_number`,
  Header: "Contact Number",
  
},
  {
    id: 'status',
    Header: 'Status',
  },
  {
    id: 'created_at',
    Header: `Created At`,
  },


  {
    id: 'created_by',
    Header: `Created By`,

 
  },
  {
    id: 'updated_at',
    Header: `Updated At`,

  },
  {
    id: 'updated_by',
    Header: `Updated By`,

  },
]


export const Columns_Bank_Format: any[] = [



{
    id: `srno`,
    Header: () => <div style={{display:"inline"}}>Sr.<br/>No</div>,
    accessor:'srno',
    sticky:"left",
},
// {
//     id: `type`,
//     Header: () => <div style={{display:"inline"}}>Person<br/>Type</div>,
//     accessor:'type',
//    
// },
{
    id: `priority`,
    Header: () => <div style={{display:"inline"}}>Priority</div>,
    accessor:'priority',
    sticky:"left",
    Cell: ({ row }: any) => {
      return(
      (row.original?.priority?.name?row.original?.priority?.name: <span style={{ color: 'red',fontSize:"20px" }}>****</span>)
      )
      }
},

{
  id: `customer`,
  Header: () => <div style={{display:"inline"}}>Customer Code<br/>& Name</div>,
  accessor:'customer',
  Cell: ({ row }: any) => {
    return(
    (row.original?.customer?.name?row.original?.customer?.name: <span style={{ color: 'red',fontSize:"20px" }}>****</span>)
    )
    }

},
{
  id: `employee`,
  Header: () => <div style={{display:"inline"}}>Employee Code<br/>& Name</div>,
  accessor:'employee',
  Cell: ({ row }: any) => {
    return(
      (row.original?.employee?.name?row.original?.employee?.name: <span style={{ color: 'red',fontSize:"20px" }}>****</span>)
    )
    }

},
{
  id: `ifsc_code`,
  Header: () => <div style={{display:"inline"}}>IFSC<br/>Code</div>,
  accessor:'ifsc_code',
  Cell: ({ row }: any) => {
    return(row.original?.ifsc_code?.IFSC)
    
    }

},
{
  id: `bank`,
  Header: () => <div style={{display:"inline"}}>Bank<br/>Name</div>,
  accessor:'bank',
  Cell: ({ row }: any) => {
    return(row.original?.ifsc_code?.BANK)
    
    }

},
{
  id: `city`,
  Header: () => <div style={{display:"inline"}}>City<br/>Name</div>,
  accessor:'city',
  Cell: ({ row }: any) => {
    return(row.original?.ifsc_code?.CITY)
    
    }

},
{
  id: `branch`,
  Header: () => <div style={{display:"inline"}}>Branch<br/>Name</div>,
  accessor:'branch',
  Cell: ({ row }: any) => {
    return(row.original?.ifsc_code?.BRANCH)
    
    }

},
{
  id: `account_type`,
  Header: () => <div style={{display:"inline"}}>Account <br/> Type</div>,
  accessor:'account_type',
  Cell: ({ row }: any) => {
    return(row.original?.account_type)
    
    }

},
{
  id: `account_number`,
  Header: () => <div style={{display:"inline"}}>Account <br/> Number</div>,
  accessor:'account_number',
  Cell: ({ row }: any) => {
    return(row.original?.account_number)
    
    }

},
{
  id: `swift_code`,
  Header: () => <div style={{display:"inline"}}>Bank Swift<br/>Code</div>,
  accessor:'swift_code',
  Cell: ({ row }: any) => {
    return(row.original?.swift_code?row.original?.swift_code:<span style={{ color: 'red',fontSize:"20px" }}>****</span>)
    
    }

},
{
  id: `iec_code`,
  Header: () => <div style={{display:"inline"}}>Bank IEC<br/>Code</div>,
  accessor:'iec_code',
  Cell: ({ row }: any) => {
    return(row.original?.iec_code?row.original?.iec_code:<span style={{ color: 'red',fontSize:"20px" }}>****</span>)
    
    }

},
{
  id: `micr`,
  Header: () => <div style={{display:"inline"}}>Bank Micr <br/> Code</div>,
  accessor:'micr',
  Cell: ({ row }: any) => {
    return(row.original?.ifsc_code?.MICR?row.original?.ifsc_code?.MICR:<span style={{ color: 'red',fontSize:"20px" }}>****</span>)
    
    }

},
{
  id: `cheque_print_name`,
  Header: () => <div style={{display:"inline"}}>Cheque Print<br/>Name</div>,
  accessor:'cheque_print_name',
  Cell: ({ row }: any) => {
    return(row.original?.cheque_print_name?row.original?.cheque_print_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>)
    
    }

},
{
  id: `amount_transfer_account_name`,
  Header: () => <div style={{display:"inline"}}>Amount Transfer<br/>Account Name</div>,
  accessor:'amount_transfer_account_name',
  Cell: ({ row }: any) => {
    return(row.original?.amount_transfer_account_name)
    
    }

},
{
  id: `district`,
  Header: () => <div style={{display:"inline"}}>District</div>,
  accessor:'district',
  Cell: ({ row }: any) => {
    return(row.original?.ifsc_code?.DISTRICT)
    
    }

},
{
  id: `state`,
  Header: () => <div style={{display:"inline"}}>State</div>,
  accessor:'state',
  Cell: ({ row }: any) => {
    return(row.original?.ifsc_code?.STATE)
    
    }

},
{
  id: `country`,
  Header: () => <div style={{display:"inline"}}>Country</div>,
  accessor:'country',
  Cell: ({ row }: any) => {
    return(row.original?.ifsc_code?.COUNTRY?row.original?.ifsc_code?.COUNTRY:<span style={{color:'red'}}>xxxx</span>)
    
    }

},
{
  id: `pincode`,
  Header: () => <div style={{display:"inline"}}>Pin Code</div>,
  accessor:'pincode',
  Cell: ({ row }: any) => {
    return(row.original?.ifsc_code?.PINCODE?row.original?.ifsc_code?.PINCODE:<span style={{color:'red'}}>xxxx</span>)
    
    }

},
{
  id: `pan_number`,
  Header: () => <div style={{display:"inline"}}>Pan <br/> Number</div>,
  accessor:'pan_number',
  Cell: ({ row }: any) => {
    return(row.original?.pan_number)
    
    }

},
{
  id: `contact_number`,
  Header: () => <div style={{display:"inline"}}>Contact <br/> Number</div>,
  accessor:'contact_number',
  Cell: ({ row }: any) => {
    return(row.original?.contact_number)
    
    }

},
  
 

 


  

{
  id: 'status',
  Header: 'Status',
  accessor:'status',


},
{
  id: `image`,
  Header: () => <div style={{display:"inline"}}>Machine<br/>Image</div>,

  // accessor: `logo`,
  Cell: ({ row }: any) =>{
  
  const [deleteConfirm, setDeleteConfirm] = useState(false)


return(
  <>

      <div >

        {!row.original.image?<>
          <a 
        
        href="#"
        
        // data-large-src={row.original.image?row.original.image:noImage} data-toggle="modal"
        >
          <img src={noImage}  height={60} width={100}/>
          </a>
        </>:<>
        <a 
        onClick={()=>setDeleteConfirm(true)}
        href={`#galleryModal${row.original.name}`}
        
        data-large-src={(row.original.image.length > 0 ? row.original.image.at(-1).doc : noImage)} data-toggle="modal">
          <img src={(row.original.image.length > 0 ? row.original.image.at(-1).doc : noImage)}  height={60} width={100}/>
          </a>
        </>}
       
      </div>








<Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
      centered    >
      
      <Modal.Body>  
      <img src={(row.original.image.length > 0 ? row.original.image.at(-1).doc : noImage)} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid"/>

      </Modal.Body>
      <Modal.Footer>
      <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

      </Modal.Footer>
    </Modal>
      </>)
  }


  },



{
    id: 'created_at',
    Header: () => <div style={{display:"inline"}}>Created<br/>At</div>,
    accessor:'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{display:"inline"}}>Created<br/>By</div>,
    accessor:'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username

 
  },
  {
    id: 'updated_at',
    Header: () => <div style={{display:"inline"}}>Updated<br/>At</div>,
    accessor:'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{display:"inline"}}>Updated<br/>By</div>,
    accessor:'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },

{
    id: 'Actions',
    Header: 'Actions',


},
  
  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_Bank_Format, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows} = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BANK_API}/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



