
import React, { useEffect, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGSTEdit, useUpdateGst } from "../../hooks/GST";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import {
  useFetchSingle,
  useUpdateSingle,
  useUpdateSingleforcewithno,
  useUpdateSingleforcewithyes,
} from "../../hooks/Api";
import { Alert } from "../../components/Model/Model";
// import { Alert } from "../Model/Model";
// import Select from 'react-select/async';
import Modal from "react-bootstrap/esm/Modal";
import { Deletesinglewithdependancy } from "../../components/modal";
import { DatePicker } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import moment from "moment";

const Edit_Gst = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const apiname = process.env.REACT_APP_GST_API?.toString();
  const { data } = useFetchSingle(id, apiname);
  const {
    mutate,
    isSuccess,
    isError,
    error,
    data: gstUpdated_response,
  }: any = useUpdateSingle();
  const [confirmmodal, setConfirmmodal] = useState(false);
  const [valuesofform, setValuesofform] = useState<any>();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, dirtyFields, isDirty },
    reset,
    setValue,
  }: any = useForm();
  const [stateGST, setStateGST]: any = useState("");
  const [centralGST, setCentralGST]: any = useState("");
  const [integratedGST, setIntegratedGST]: any = useState("");

  const [indiastateGST, setIndiaStateGST]: any = useState("");
  const [indiacentralGST, setIndiaCentralGST]: any = useState("");
  const [indiaintegratedGST, setIndiaIntegratedGST]: any = useState("");
  const [openEditModel, setOpenEditModel] = useState(false);
  const [expirationDate, setExpirationDate] = useState();
  // const [fromDate, setFromDate] = useState()
  // const [toDate, setToDate] = useState()
  const {
    mutate: mutateyes,
    isSuccess: yessuccess,
    isError: yeserror,
    error: yeserrordetail,
    data: yesupdated_response,
  }: any = useUpdateSingleforcewithyes();
  const {
    mutate: mutateno,
    isSuccess: nosuccess,
    isError: noerror,
    error: noerrordetail,
    data: noupdated_response,
  }: any = useUpdateSingleforcewithno();

  const onSubmit = (branddetail: any) => {
    if (!fromDate && !toDate) {
      delete branddetail.from_date;
      delete branddetail.to_date;
    } else if (fromDate && !toDate) {
      delete branddetail.to_date;
    } else if (!fromDate && toDate) {
      delete branddetail.from_date;
    }
    const formData = new FormData();
    for (const [key, value] of Object.entries(branddetail)) {
      const formvalue: any = value;
      if (key === "from_date") {
        const renewdate = moment(branddetail.from_date, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        );
        formData.append(key, renewdate);
      } else if (key === "to_date") {
        const renewdate = moment(branddetail.to_date, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        );
        formData.append(key, renewdate);
      } else if (key === "india_GST" || key === "out_of_india_GST") {
        formData.append(key, parseFloat(branddetail[key]).toFixed(2));
      } else if (typeof value !== "object") {
        formData.append(key, formvalue);
      }
    }

    let data = {
      apiname: apiname,
      id: id,
      data: formData,
    };

    mutate(data);
    setValuesofform(data);
  };

  const [show, setShow] = useState(false);
  const [showmessage, setshowmessage] = useState("");

  useEffect(() => {
    if (isSuccess || yessuccess || nosuccess) {
      setShow(true);
      setshowmessage("success");
      navigate("/listGST", { state: { showEditToast: true } });
    } else if (isError) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [
    data,
    isSuccess,
    yessuccess,
    nosuccess,
    gstUpdated_response,
    error,
    isSuccess,
  ]);
  useEffect(() => {
    if (isError) {
      if (error.response?.status === 405) {
        setConfirmmodal(true);
      }
    } else if (isError || yeserror || noerror) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [isError, yeserror, noerror]);
  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [show]);

  var from = data?.from_date;
  var to = data?.to_date;
  var one = moment(from, "DD-MM-YYYY").add(1, "d").toISOString();
  var two = moment(to, "DD-MM-YYYY").add(1, "d").toISOString();

  useEffect(() => {
    if (data) {
      reset(data);
      setValue("india_GST", data?.india_GST);
      setValue("out_of_india_GST", data?.out_of_india_GST);
      setValue("from_date", one?.split("T")[0]);
      setValue("to_date", two?.split("T")[0]);

      if (data?.from_date) {
        setFromDate(dayjs(data?.from_date, "DD/MM/YYYY"));
      }

      if (data?.to_date) {
        setToDate(dayjs(data?.to_date, "DD/MM/YYYY"));
      }
    }
  }, [data]);

  const handleIndiaGST = (e: any) => {
    setStateGST(e.target.value / 2);
    setCentralGST(e.target.value / 2);
    setIntegratedGST(e.target.value);
    setValue("india_guj_GST", e.target.value / 2, { shouldDirty: true });
    setValue("out_state_GST", e.target.value / 2, { shouldDirty: true });
    setValue("ics_GST", e.target.value, { shouldDirty: true });
  };

  const handleOutofIndiaGST = (e: any) => {
    setIndiaStateGST(e.target.value / 2);
    setIndiaCentralGST(e.target.value / 2);
    setIndiaIntegratedGST(e.target.value);
    setValue("out_of_india_guj_GST", e.target.value / 2, { shouldDirty: true });
    setValue("out_of_india_out_state_GST", e.target.value / 2, {
      shouldDirty: true,
    });
    setValue("out_of_india_ics_GST", e.target.value, { shouldDirty: true });
  };

  const handleValue = (e: any) => {
    e.target.value = e.target.value
      .toLowerCase()
      .split(" ")
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  };

  const yesupdatemodal = () => {
    setConfirmmodal(false);
    mutateyes(valuesofform);
  };
  const cancelupdatemodal = () => {
    setConfirmmodal(false);
  };
  const noupdatemodal = () => {
    setOpenEditModel(true);
  };

  const handleExpirationDate = (e: any) => {
    setExpirationDate(e.target.value);
    setValue("expiration_date", e.target.value);
  };

  const handleConfirm = (data: any) => {
    delete data.created_at;
    delete data.created_by;
    let ok = {
      id: id,
      apiname: apiname,
      data: data,
    };

    mutateno(ok);
    setConfirmmodal(false);
    setOpenEditModel(false);
  };
  const [fromDate, setFromDate]: any = useState("");
  const [toDate, setToDate]: any = useState("");

  const handleFromDate = (date: any, dateString: any) => {
    if (date == null) {
      setFromDate("");
      setValue("from_date", ``);
      return;
    }
    setFromDate(dayjs(date, "DD/MM/YYYY"));
    setValue("from_date", `${date?.$y}-${date?.$M + 1}-${date?.$D}`, {
      shouldDirty: true,
    });
  };

  const handleToDate = (date: any, dateString: any) => {
    if (date == null) {
      setToDate("");
      setValue("to_date", ``);
      return;
    }
    setToDate(dayjs(date, "DD/MM/YYYY"));
    setValue("to_date", `${date?.$y}-${date?.$M + 1}-${date?.$D}`, {
      shouldDirty: true,
    });
  };

  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current < dayjs().startOf("day");
  };

  let dateOne = data?.from_date;
  let fromDateValue = dateOne;
  let dateTwo = data?.to_date;
  let toDateValue = dateTwo;

  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
        <section className="content-header px-3 py-1">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>Edit GST</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to="/dashboard"
                      className="text-decoration-none text-black"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      Masters
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to="/listGST"
                      className="text-decoration-none text-black"
                    >
                      Gst
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Edit GST</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body" style={{ overflow: "auto" }}>
                  {show && showmessage === "danger" ? (
                    <Alert
                      color={showmessage}
                      message={error?.response.data?.message}
                    />
                  ) : (
                    ""
                  )}

                  <div className="row d-flex justify-content-md-center justify-content-lg-start">
                    <div className="col-md-12">
                      <div className="row">
                        {/* <div className="row mt-4"> */}
                        <h5 className="mb-0 mt-2 b">India GST%</h5>
                        {/* </div> */}

                        <div className="col-sm-3 mt-4">
                          <label htmlFor="exampleInputEmail1">GST%</label>
                          <div className="input-group">
                            <input
                              type="number"
                              className="form-control"
                              id="exampleInputBrand"
                              placeholder="GST%"
                              {...register("india_GST", {
                                onChange: handleIndiaGST,
                                required: "Field is required",
                                pattern: {
                                  // value:
                                  // /^(0(?![.0-9])|[1-9]\d?(?:\.\d{0,3})?)$/gm,
                                  value: /^((?:0(?![.0-9])|0\.\d{1,3}|\.0[1-9]|\.[1-9]|[1-9]\d?(?:\.\d{0,3})?))$/gm,
                                  message: "Enter valid GST",
                                },
                              })}

                              // {...register("india_GST", { onChange: handleIndiaGST, })}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text border-start-0 bg-primary">
                                <RiPencilLine color="white" />
                              </span>
                            </div>
                          </div>
                          <span className="text-red w-100">
                            {errors?.india_GST?.message}
                          </span>
                        </div>
                        <div className="col-sm-3 mt-4">
                          <label htmlFor="exampleInputEmail1">
                            State GST (SGST)%
                          </label>
                          <div className="input-group">
                            <input
                              type="number"
                              className="form-control"
                              id="exampleInputBrand"
                              disabled
                              placeholder="State GST (SGST)%"
                              {...register("india_guj_GST")}
                            />
                            {/* <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>

                                                        </div> */}
                          </div>
                        </div>
                        <div className="col-sm-3 mt-4">
                          <label htmlFor="exampleInputEmail1">
                            Central GST (CGST)%
                          </label>
                          <div className="input-group">
                            <input
                              type="number"
                              className="form-control"
                              id="exampleInputBrand"
                              disabled
                              placeholder="Central GST (CGST)%"
                              {...register("out_state_GST")}
                            />
                            {/* <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>

                                                        </div> */}
                          </div>
                        </div>
                        <div className="col-sm-3 mt-4">
                          <label htmlFor="exampleInputEmail1">
                            Integrated GST (IGST)%
                          </label>
                          <div className="input-group">
                            <input
                              type="number"
                              className="form-control"
                              id="exampleInputBrand"
                              disabled
                              placeholder="Integrated GST (IGST)%"
                              {...register("ics_GST")}
                            />
                            {/* <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>

                                                        </div> */}
                          </div>
                        </div>

                        <div className="row mt-4">
                          <h5 className="mb-0 mt-2 b">Out of India GST%</h5>
                        </div>

                        <div className="col-sm-3 mt-4">
                          <label htmlFor="exampleInputEmail1">GST%</label>
                          <div className="input-group">
                            <input
                              type="number"
                              className="form-control"
                              id="exampleInputBrand"
                              placeholder="GST%"
                              {...register("out_of_india_GST", {
                                onChange: handleOutofIndiaGST,
                                required: "Field is required",
                                pattern: {
                                  // value:
                                  // /^(0(?![.0-9])|[1-9]\d?(?:\.\d{0,3})?)$/gm,
                                  value: /^((?:0(?![.0-9])|0\.\d{1,3}|\.0[1-9]|\.[1-9]|[1-9]\d?(?:\.\d{0,3})?))$/gm,
                                  message: "Enter valid GST",
                                },
                              })}
                              {...register("out_of_india_GST", {
                                onChange: handleOutofIndiaGST,
                              })}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text border-start-0 bg-primary">
                                <RiPencilLine color="white" />
                              </span>
                            </div>
                          </div>
                          <span className="text-red w-100">
                            {errors?.out_of_india_GST?.message}
                          </span>
                        </div>
                        <div className="col-sm-3 mt-4">
                          <label htmlFor="exampleInputEmail1">
                            State GST (SGST)%
                          </label>
                          <div className="input-group">
                            <input
                              type="number"
                              className="form-control"
                              disabled
                              id="exampleInputBrand"
                              placeholder="State GST (SGST)%"
                              {...register("out_of_india_guj_GST")}
                            />
                            {/* <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>

                                                        </div> */}
                          </div>
                        </div>
                        <div className="col-sm-3 mt-4">
                          <label htmlFor="exampleInputEmail1">
                            Central GST (CGST)%
                          </label>
                          <div className="input-group">
                            <input
                              type="number"
                              className="form-control"
                              disabled
                              id="exampleInputBrand"
                              placeholder="Central GST (CGST)%"
                              {...register("out_of_india_out_state_GST")}
                            />
                            {/* <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>

                                                        </div> */}
                          </div>
                        </div>
                        <div className="col-sm-3 mt-4">
                          <label htmlFor="exampleInputEmail1">
                            Integrated GST (IGST)%
                          </label>
                          <div className="input-group">
                            <input
                              type="number"
                              className="form-control"
                              disabled
                              id="exampleInputBrand"
                              placeholder="Integrated GST (IGST)%"
                              {...register("out_of_india_ics_GST")}
                            />
                            {/* <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>

                                                        </div> */}
                          </div>
                        </div>
                        <div className="row mt-4" />
                        <div className="col-sm-3 mt-4">
                          <label htmlFor="exampleInputEmail1">From Date </label>
                          {/* <input type="date" className="form-control" style={{ fontSize: "13px" }}  {...register("from_date", { onChange: handleFromDate })}
                                                    /> */}

                          <DatePicker
                            className="form-control"
                            format={dateFormatList}
                            disabledDate={disabledDate}
                            value={
                              fromDate == undefined
                                ? dayjs(fromDateValue, "DD/MM/YYYY")
                                : fromDate
                            }
                            {...register("from_date", {
                              required: {
                                value: false,
                                message: "Field is required",
                              },
                            })}
                            onChange={handleFromDate}
                          />
                          <span className='text-red w-100'>{errors?.from_date?.message}</span>
                        </div>
                        <div className="col-sm-3 mt-4">
                          <label htmlFor="exampleInputEmail1">To Date {/*<span style={{ color: 'red' }}>*</span>*/}</label>
                          {/* <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("to_date", { onChange: handleToDate })}
                                                    /> */}

                          <DatePicker
                            className="form-control"
                            format={dateFormatList}
                            disabledDate={disabledDate}
                            value={
                              toDate == undefined
                                ? dayjs(toDateValue, "DD/MM/YYYY")
                                : toDate
                            }
                            {...register("to_date", {
                              required: {
                                value: false,
                                message: "Field is required",
                              },
                            })}
                            onChange={handleToDate}
                          />
                          <span className='text-red w-100'>{errors?.to_date?.message}</span>

                        </div>
                        <div className="col-sm-3 mt-4">
                          <label htmlFor="remarks">Remarks </label>
                          <div className="input-group" data-colorpicker-id={2}>
                            <input
                              type="text"
                              placeholder="Remarks"
                              className="form-control"
                              id="remarks"
                              {...register("remarks", {
                                onChange: handleValue,
                              })}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text border-start-0 bg-primary">
                                <RiPencilLine color="white" />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-3 mt-4">
                          <label htmlFor="status">
                            Status <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-select"
                            {...register("status", {
                              required: {
                                value: true,
                                message: "Status is required",
                              },
                              maxLength: 30,
                            })}
                          >
                            <option value="" selected>
                              Select Any{" "}
                            </option>
                            <option value={"active"}>Active</option>
                            <option value={"inactive"}>Inactive</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row d-flex  ">
                    <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                      <div className="row d-flex ">
                        <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                          <button
                            type="button"
                            className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                            style={{
                              borderColor: "rgb(0, 54, 101)",
                              background: "rgb(0, 54, 101)",
                              color: "white",
                            }}
                            onClick={handleSubmit(onSubmit)}
                            // disabled={!isDirty}
                          >
                            Submit
                          </button>

                          <button
                            type="button"
                            className=" border-1 rounded  px-4 py-1 mx-3 mt-2"
                            style={{
                              borderColor: "red",
                              background: "red",
                              color: "white",
                            }}
                            onClick={() => navigate("/listGST")}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
      <Modal
        show={openEditModel}
        onHide={() => setOpenEditModel(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select a Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12">
            <div className="row">
              <div className="col-sm-12 mt-2">
                <label htmlFor="exampleInputEmail1">Choose Date</label>
                <input
                  type="date"
                  className="form-control"
                  style={{ fontSize: "13px" }}
                  {...register("expiration_date", {
                    onChange: handleExpirationDate,
                  })}
                />
                {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className=" border-1 rounded  px-4 py-1 mx-3 mt-2"
            style={{ borderColor: "red", background: "red", color: "white" }}
            onClick={() => {
              setOpenEditModel(false);
            }}
          >
            Close
          </button>
          <button
            type="button"
            className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
            style={{
              borderColor: "rgb(0, 54, 101)",
              background: "rgb(0, 54, 101)",
              color: "white",
            }}
            onClick={handleSubmit(handleConfirm)}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
      <Deletesinglewithdependancy
        noupdatemodal={noupdatemodal}
        yesupdatemodal={yesupdatemodal}
        cancelupdatemodal={cancelupdatemodal}
        masterName={apiname}
        confirmmodal={confirmmodal}
        foundependacy={error?.response?.data}
      />
    </div>
  );
};

export default Edit_Gst;
