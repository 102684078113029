
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import Actions from "../../components/Cell/Actions";
// import { updateBrand, useFetchBrands } from "../../hooks/brand";
// import noImage from "../../images/noImage.png"
import { useFetchGst, useUpdateGst } from "../../hooks/GST";


interface Data {
  // selection: any
  // table_sr_no:any
  // department:any
  // sub_name:any
  // code_name:any
  // full_code:any
  // default_no:any
  // manualcode_1:any
  // manualcode_Disc_1:any
  // manualcode_2:any
  // manualcode_Disc_2:any
  // serial_no:any
  // code_type:any
  // check_list:any
  // remarks:any

  // created_at:any
  // created_by:any
  // updated_by:any
  // updated_at:any
//   status: any;

srno:any
department:any
table_sr_no:any
code_name:any
sub_name:any
full_code:any
default_no:any
manualcode_1:any
manualcode_Disc_1:any
manualcode_2:any
manualcode_Disc_2:any
serial_no:any
code_type:any
check_list:any
remarks:any
created_at:any
created_by:any
updated_at:any
updated_by:any
Actions:any
Selection:any
}

export const titleofheaders=[
  {
    id: `srno`,
    Header: `Sr No`,
  },
  

{
  id: `department`,
  Header: `Department`,
},
{
  id: `code_name`,
  Header: `Code Name`,
},





{
  id: `full_code`,
  Header: `Full Code`,
},
{
  id: `table_sr_no`,
  Header: `Table Sr No`,
  
},
{
  id: `sub_name`,
  Header: `Sub Name`,
},
{
  id: `default_no`,
  Header: `Default NO`,
},
{
  id: `manualcode_1`,
  Header: `Manual Code 1`,
},
{
  id: `manualcode_Disc_1`,
  Header: `Manual Code Disc 1`,
},
{
  id: `manualcode_2`,
  Header: `Manual Code 2`,
},
{
  id: `manualcode_Disc_2`,
  Header: `Manual Code Disc 2`,
},

{
  id: `serial_no`,
  Header: `Serial No`,
},
{
  id: `code_type`,
  Header: `Code Type`,
},
{
  id: `check_list`,
  Header: `Check List`,
},
{
  id: `remarks`,
  Header: `Remarks`,
},

  {
    id: 'created_at',
    Header: "Created At",
  },
  {
    id: 'created_by',
    Header: "Created By",
  },
  {
    id: 'updated_at',
    Header: "Updated At",
  },
  {
    id: 'updated_by',
    Header: "Updated By",
  },

  // {
  //   id: 'status',
  //   Header: 'Status',
  // },
]



export const Columns_NumberSystem: any = [



  
  {
    id: `srno`,
    Header: () => <div style={{ display: "inline" }}>Sr<br/>No</div>,
    sticky:"left",
  },
  
  {
    id: `department`,
    Header: `Department`,
    accessor: 'department',
    sticky:"left",
    Cell: ({ row }: any) =>

      <div>
        {row.original.department?.department}
      </div>
  },
  {
    id: `table_sr_no`,
    Header: () => <div style={{ display: "inline" }}>Table<br/>Sr.No</div>,
    accessor: 'table_sr_no',
    // sticky:"left",
    Cell: ({ row }: any) =>

      <div>
        {row.original.table_sr_no}
      </div>
  },
  {
    id: `code_name`,
    Header: () => <div style={{ display: "inline" }}>Code<br/>Name</div>,
    accessor: 'code_name',
    // sticky:"left",
    Cell: ({ row }: any) =>

      <div>
        {row.original.code_name}
      </div>
  },
  {
    id: `sub_name`,
    Header: () => <div style={{ display: "inline" }}>Sub<br/>Name</div>,
    accessor: 'sub_name',
    Cell: ({ row }: any) =>

      <div>
        {row.original.sub_name?row.original.sub_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
      </div>
  },
  {
    id: `full_code`,
    Header: () => <div style={{ display: "inline" }}>Full<br/>Code</div>,
    accessor: 'full_code',
    
    Cell: ({ row }: any) =>

      <div>
        {row.original.full_code?row.original.full_code:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
      </div>
  },
 

  {
    id: `default_no`,
    Header: () => <div style={{ display: "inline" }}>Default<br/>No</div>,
    accessor: 'default_no',
    Cell: ({ row }: any) =>

      <div>
        {row.original.default_no?row.original.default_no:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
      </div>
  },
  {
    id: `manualcode_1`,
    Header: () => <div style={{ display: "inline" }}>Manual<br/>Code 1</div>,
    accessor: 'manualcode_1',
    Cell: ({ row }: any) =>

      <div>
        {row.original.manualcode_1?row.original.manualcode_1:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
      </div>
  },
  {
    id: `manualcode_Disc_1`,
    Header: () => <div style={{ display: "inline" }}>Manual Code<br/>Description 1</div>,
    accessor: 'manualcode_Disc_1',
    Cell: ({ row }: any) =>

      <div>
        {row.original.manualcode_Disc_1?row.original.manualcode_Disc_1:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
      </div>
  },
  {
    id: `manualcode_2`,
    Header: () => <div style={{ display: "inline" }}>Manual<br/>Code 2</div>,
    accessor: 'manualcode_2',
    Cell: ({ row }: any) =>

      <div>
        {row.original.manualcode_2?row.original.manualcode_2:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
      </div>
  },
  {
    id: `manualcode_Disc_2`,
    Header: () => <div style={{ display: "inline" }}>Manual Code<br/>Description 2</div>,
    accessor: 'manualcode_Disc_2',
    Cell: ({ row }: any) =>

      <div>
        {row.original.manualcode_Disc_2?row.original.manualcode_Disc_2:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
      </div>
  },

  {
    id: `serial_no`,
    Header: () => <div style={{ display: "inline" }}>Serial<br/>No</div>,
    accessor: 'serial_no',
    Cell: ({ row }: any) =>

      <div>
        {row.original.serial_no?row.original.serial_no:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
      </div>
  },
  {
    id: `code_type`,
    Header: () => <div style={{ display: "inline" }}>Working<br/>Running</div>,
    accessor: 'code_type',

   

    Cell: ({ value }: { value: any }) => {
      
      return <span style={value==="working"?{backgroundColor:"chocolate",color:'white',width:"100%",height:"100%",fontWeight:'bold'}:value==="running"?{backgroundColor:"darkgoldenrod",color:'black',width:"100%",height:"100%",fontWeight:'bold'}:value==="hold"?{backgroundColor:"#EDAFE6",color:"black",width:"100%",height:"100%",fontWeight:'bold'}:{backgroundColor:"blue",color:"white",width:"100%",height:"100%",fontWeight:'bold'}}>{value.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")}</span>
  },
  },
  {
    id: `check_list`,
    Header: () => <div style={{ display: "inline" }}>Check<br/>List</div>,
    accessor: 'check_list',
    Cell: ({ value }: { value: any }) => {
      
      return <span style={value==="ok"?{backgroundColor:"green",color:'white',width:"100%",height:"100%",fontWeight:'bold'}:{backgroundColor:"red",color:"white",width:"100%",height:"100%",fontWeight:'bold'}}>{value.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")}</span>
  },
    // Cell: ({row}:any) => {
    //   const convertToUpper = row.original.check_list
    //   if (!convertToUpper) return "";
    //   return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    // }
  },
  {
    id: `remarks`,
    Header: `Remarks`,
    accessor: 'remarks',
    Cell: ({ row }: any) =>

      <div>
        {row.original.remarks?row.original.remarks:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
      </div>
  },

  {
    id: 'created_at',
    Header: () => <div style={{ display: "inline" }}>Created<br/>At</div>,
    accessor:'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{ display: "inline" }}>Created<br/>By</div>,

    accessor:'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username

 
  },
  {
    id: 'updated_at',
    Header: () => <div style={{ display: "inline" }}>Updated<br/>At</div>,

    accessor:'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{ display: "inline" }}>Updated<br/>By</div>,

    accessor:'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },


  

  


  {
    id: 'Actions',
    Header: 'Actions',

  
  },

  {
    id: 'Selection',
    // sticky:"left",
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_NumberSystem, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows } = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_NUMBER_SYSTEM_API}/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



