import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
// import MyTable from "./MyTable";
import { Columns_Task_List, titleofheaders } from "./table-data";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Editable_Table from "../Dynamic_Table/Table/Editable_Table";
import { Columns_Color } from "../../pages/Color/Columns_Color";
import Axiosinstance from "../../hooks/Interceptor";
import { useForm } from "react-hook-form";
import { useCreate } from "../../hooks/Api";
import { Alert } from "../Model/Model";
import Mui_Toaster from "../Mui_Toaster/Mui_Toaster";
import Select from 'react-select'
import { useFetchCartonPaperBFDropDown, useFetchCartonPaperDesignDropDown, useFetchCartonPaperGSMDropDown, useFetchCartonPaperNameDropDown, useFetchColorDropDown, useFetchPanniMaterialUseDropDown } from "../../hooks/DropDown_Api";
import "./editable.css"
import CreatableSelect from "react-select/creatable";
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'

const customIndicator = {
  IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
  IndicatorsContainer: IndicatorsContainerCreate
}

interface Option {
  readonly label: string;
  readonly name: string;
}
const createOption = (label: string) => ({
  label,
  name: label,
  status: "active",
});
// import "./styles.css";

// const TableStyles = styled.div`
//   padding: 1rem;

//   table {
//     border-spacing: 0;
//     border: 1px solid black;

//     tr {
//       :last-child {
//         td {
//           border-bottom: 0;
//         }
//       }
//     }

//     th,
//     td {
//       margin: 0;
//       padding: 0.5rem;
//       border-bottom: 1px solid black;
//       border-right: 1px solid black;

//       :last-child {
//         border-right: 0;
//       }
//       input {
//         font-size: 1rem;
//         padding: 0;
//         margin: 0;
//         border: 0;
//       }
//     }
//   }
// `;

const Editable = ({
  setMessage,
  setCartonPlyId,
  setOpen,
  checkToast,
  cartonSize,
  cartonLength = 0,
  cartonWidth = 0,
  cartonHeight = 0,
  kanHeight = 0,
  kanWidth = 0,
  panniKan = "no",
  // setSavedData
}: any) => {
  const [paperSizeLengthMM, setPaperSizeLengthMM] = useState(0);
  const [paperDecalSizeMM, setPaperDecalSizeMM] = useState(0);
  const [totalMM, settotalMM] = useState(0);

  const [paperLiner, setPaperLiner] = useState(0);
  const [usePaper, setUsePaper]: any = useState("");
  const [totalOfPaperGSM, setTotalOfPaperGSM] = useState(0);

  const [paperQty, setPaperQty] = useState(0);
  const [paperWeight, setPaperWeight] = useState(0);
  const [total_MM, setTotal_MM] = useState(0);

  const [tableData, setTableData]: any = React.useState([]);

  const memoizedColumns = React.useMemo(() => Columns_Task_List, []);
  const memoizedData = React.useMemo(() => tableData, [tableData]);

  const data: any = {
    paper_name: "",
    use_paper: "",
    paper_color: "",
    paper_design: "",
    paper_bursting_factor: "",
    paper_gsm: 0,
    paper_liner: "",
    paper_length_mm: cartonLength,
    paper_decal: cartonWidth,
    carton_height: cartonHeight,
    kan_width: kanWidth,
    kan_height: kanHeight,
    paper_size_length_mm: Number(cartonLength + cartonWidth + kanWidth),
    paper_decal_size_mm: paperDecalSizeMM,
    total_mm: paperSizeLengthMM * paperDecalSizeMM,
    paper_qty: "",
    paper_weight: "",
    paper_printing: "",
  };

  const handlePaperLiner = (e: any, index: any) => {
    // setPaperLiner(e.target.value);

    let temp_data = structuredClone(tableData);
    let temp_updated = temp_data.map((d: any, ind: any) => {
      let temp_d = { ...d };
      if (index === ind) {
        temp_d["paper_liner"] = e.target.value;
        temp_d["paper_length_mm"] =
          Number((cartonLength * Number(temp_d.paper_liner)) / 100) +
          cartonLength +
          cartonWidth +
          kanWidth;
        temp_d["total_mm"] =
          Number(temp_d["paper_length_mm"]) * paperDecalSizeMM;
      }
      return temp_d;
    });
    setTableData(temp_updated);
    // setUsePaper(e.target.value);
  };

  const handleUsePaper = (e: any, index: any) => {
    let temp_data = structuredClone(tableData);
    let temp_updated = temp_data.map((d: any, ind: any) => {
      let temp_d = { ...d };
      if (index === ind) {
        temp_d["use_paper"] = e.target.value;
      }
      return temp_d;
    });
    setTableData(temp_updated);
    // setUsePaper(e.target.value);
  };

  // const handlePaperGSM = (e: any, index: any) => {
  //   let temp_data = structuredClone(tableData);
  //   let temp_updated = temp_data.map((d: any, ind: any) => {
  //     let temp_d = { ...d };
  //     if (index === ind) {
  //       temp_d["paper_gsm"] = e.target.value;
  //     }
  //     return temp_d;
  //   });
  //   setTableData(temp_updated);
  //   // setUsePaper(e.target.value);
  // };

  const handlePaperQty = (e: any, index: any) => {
    let temp_data = structuredClone(tableData);
    let temp_updated = temp_data.map((d: any, ind: any) => {
      let temp_d = { ...d };
      if (index === ind) {
        temp_d["paper_qty"] = e.target.value;
      }
      return temp_d;
    });
    setTableData(temp_updated);
    // setUsePaper(e.target.value);
  };

  const handlePaperWeight = (e: any, index: any) => {
    let temp_data = structuredClone(tableData);
    let temp_updated = temp_data.map((d: any, ind: any) => {
      let temp_d = { ...d };
      if (index === ind) {
        temp_d["paper_weight"] = e.target.value;
      }
      return temp_d;
    });
    setTableData(temp_updated);
    // setUsePaper(e.target.value);
  };


  // const handlePaperName = (e: any, index: any) => {
  //   let temp_data = structuredClone(tableData);
  //   let temp_updated = temp_data.map((d: any, ind: any) => {
  //     let temp_d = { ...d };
  //     if (index === ind) {
  //       temp_d["paper_name"] = e.target.value;



  //     }
  //     return temp_d;
  //   });
  //   setTableData(temp_updated);
  //   // setUsePaper(e.target.value);
  // };

  const handlePaperColor = (e: any, index: any) => {
    let temp_data = structuredClone(tableData);
    let temp_updated = temp_data.map((d: any, ind: any) => {
      let temp_d = { ...d };
      if (index === ind) {
        temp_d["paper_color"] = e.value;
        setValue("paper_color", e.value)
      }
      return temp_d;
    });
    setTableData(temp_updated);
    // setUsePaper(e.target.value);
  };
  // const handlePaperDesign = (e: any, index: any) => {
  //   let temp_data = structuredClone(tableData);
  //   let temp_updated = temp_data.map((d: any, ind: any) => {
  //     let temp_d = { ...d };
  //     if (index === ind) {
  //       temp_d["paper_design"] = e.target.value;
  //     }
  //     return temp_d;
  //   });
  //   setTableData(temp_updated);
  //   // setUsePaper(e.target.value);
  // };
  // const handlePaperBF = (e: any, index: any) => {
  //   let temp_data = structuredClone(tableData);
  //   let temp_updated = temp_data.map((d: any, ind: any) => {
  //     let temp_d = { ...d };
  //     if (index === ind) {
  //       temp_d["paper_bursting_factor"] = e.target.value;
  //     }
  //     return temp_d;
  //   });
  //   setTableData(temp_updated);
  //   // setUsePaper(e.target.value);
  // };
  const handlePaperPrinting = (e: any, index: any) => {
    let temp_data = structuredClone(tableData);
    let temp_updated = temp_data.map((d: any, ind: any) => {
      let temp_d = { ...d };
      if (index === ind) {
        temp_d["paper_printing"] = e.target.value;
      }
      return temp_d;
    });
    setTableData(temp_updated);
    // setUsePaper(e.target.value);
  };

  useEffect(() => {
    let temp_data = structuredClone(tableData);
    let temp_gsm = temp_data
      .map((d: any, ind: any) => {
        
        return Number(d.paper_gsm);
      })
      .reduce((a: any, b: any) => a + b, 0);
    setTotalOfPaperGSM(temp_gsm);

    let temp_qty = temp_data
      .map((d: any, ind: any) => {
        return Number(d.paper_qty);
      })
      .reduce((a: any, b: any) => a + b, 0);
    setPaperQty(temp_qty);

    let temp_paper_weight = temp_data
      .map((d: any, ind: any) => {
        return Number(d.paper_weight);
      })
      .reduce((a: any, b: any) => a + b, 0);
    setPaperWeight(temp_paper_weight.toFixed(3));

    let temp_total_mm = temp_data
      .map((d: any, ind: any) => {
        return Number(d.total_mm);
      })
      .reduce((a: any, b: any) => a + b, 0);
    setTotal_MM(temp_total_mm.toFixed(3));
  }, [tableData]);

  useEffect(() => {
    setPaperSizeLengthMM(Number(cartonLength + cartonWidth + kanWidth));
  }, [cartonWidth, kanWidth, cartonLength]);

  useEffect(() => {
    setPaperDecalSizeMM(Number(cartonWidth) + cartonHeight);
  }, [cartonWidth, cartonHeight, paperDecalSizeMM]);

  useEffect(() => {
    settotalMM(Number(paperSizeLengthMM) * paperDecalSizeMM);
  }, [paperSizeLengthMM, paperDecalSizeMM, totalMM]);



  React.useEffect(() => {
    let blankData: any = [];
    let temp = { ...data };
    for (let i = 1; i <= cartonSize; i++) {
      blankData.push(temp);
    }
    setTableData([...blankData]);

  }, [
    cartonSize,
    cartonLength,
    cartonWidth,
    cartonHeight,
    kanHeight,
    kanWidth,
    paperSizeLengthMM,
    paperDecalSizeMM,
  ]);

  const handleNewRowClick = () => {
    setTableData([{}, ...tableData]);
  };

  const updateMyData = (rowIndex: any, columnId: any, value: any) => {
    setTableData((prevTableData: any) =>
      prevTableData.map((row: any, index: any) => {
        if (index === rowIndex) {
          return {
            ...prevTableData[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };


  const Breadcrumb_Primary_Title = "Home";
  const Breadcrumb_Primary_Link = "Color";

  const Breadcrumb_Parent_Title = "Carton Ply";

  const Breadcrumb_Title_Name = "Carton Ply";

  const apiname = process.env.REACT_APP_TASK_LIST_API?.toString();

  const filter = {
    name: "",
    age: "",
  };

  const addButtonName = "Carton Ply List";
  const addButtonPageName = "taskList";

  const stickyStart = -1;
  const stickyEnd = -1;

  // const [savedData,setSavedData]:any = useState([])
  const apiCartonPly = process.env.REACT_APP_CARTON_PLY_API?.toString()
  const { mutate: cartonPly, data: CartonPly, isError, isLoading, isSuccess, error }: any = useCreate(apiCartonPly)

  const { register, handleSubmit, setError, formState: { errors, isDirty }, reset, setValue, getValues }: any = useForm({
    mode: "onChange"
  });


  const [errorForInput, setErrorForInput]: any = useState([])


  const onSubmit = () => {

    let tempCheck = structuredClone(tableData)
    let checkList = ["paper_name", "use_paper", "paper_color",
      "paper_design",
      "paper_bursting_factor",
      "paper_gsm",
      "paper_liner",
      "paper_qty",
      "paper_weight",
      "paper_printing"]


    let checkError = tempCheck.map((data: any, index: any) => {
      let ctx: any = {}
      for (const [key, value] of Object.entries(data)) {

        if (checkList.includes(key)) {
          if (value === "" || value === 0) {
            if (key === "paper_liner") {
              if (data?.use_paper === "LINER") {
                ctx[key] = "Required!"
              }
            }
            else {
              ctx[key] = "Required!"
            }
          }
        }
      }
      return ctx
    })
    setErrorForInput([...checkError])

    let saveCheck = checkError.filter((data: any) => Object.keys(data).length > 0).length

    if (saveCheck === 0) {
      cartonPly({ cartonPly: tableData })
    }



    // Axiosinstance.post('/login', tableData).then((data:any)=>{});
  }

  // const [cartonPlyId,setCartonPlyId] = useState([])
  

  useEffect(() => {
    let cartonIds = CartonPly?.data?.map((e: any) => {
      return e?.id
    })
    setCartonPlyId(cartonIds)


  }, [CartonPly])



  const [show, setShow] = useState(false)
  const [showmessage, setshowmessage] = useState("")


  // const [message,set]

  useEffect(() => {
    if (isSuccess) {
      setShow(true)
      setshowmessage("success")
      setOpen(false)

      setMessage(true)
      // reset()
      // navigate('/listBrand', { state: { showToast: true }})
    }
    else if (isError) {
      setShow(true)
      setshowmessage("danger")
    }
  }, [data, isSuccess, error])



  useEffect(() => {
    setTimeout(() => {
      setShow(false)
    }, 3000)

  }, [show]);

  const [options, setOptions] = useState([])

  const { data: colorData, refetch: colorRefetch } = useFetchColorDropDown()

  useEffect(() => {
    setOptions(colorData)
  }, [colorData])




  const [color, setColor] = useState()
  const handleChange = (e: any) => {

    // const color = e.map((value: any) => {
    //     return value.value
    // })
    setColor(e.value)
    setValue("paper_color", e.value)

    // setForColorValue(e)

  }




  const apinamePaperName = process.env.REACT_APP_CARTON_PLY_PAPER_NAME_API?.toString()
  const { mutate: mutatePaperName, data: dataPaperName, isError: PaperNameIsError, isLoading: PaperNameLoading, isSuccess: PaperNameSuccess, error: PaperNameError }: any = useCreate(apinamePaperName)
  // const [isLoadingValue, setIsLoadingValue] = useState(false);

  const [valuePaperName, setValuePaperName]: any = useState();
  const [optionsPaperName, setOptionsPaperName]: any = useState([]);
  const [valuePaperNameCreatable, setValuePaperNameCreatable] = useState<Option | null>();

  const { data: PaperNameData, refetch: PaperNameRefetch } = useFetchCartonPaperNameDropDown()

  useEffect(() => {
    setOptionsPaperName(PaperNameData)
  }, [PaperNameData])


  const handlePaperName = (e: any, index: any) => {
    // setValuePaperName(e)
    let temp_data = structuredClone(tableData);
    let temp_updated = temp_data.map((d: any, ind: any) => {
      let temp_d = { ...d };
      if (index === ind) {
        temp_d["paper_name"] = e.value;
        setValue("paper_name", e.value)
      }
      return temp_d;
    });
    setTableData(temp_updated);
    // setUsePaper(e.target.value);
  };

  const [currentIndexPaperName, setCurrentIndexPaperName]: any = useState('')

  const handleCreatePaperName = (inputValue: string, index: any) => {

    setCurrentIndexPaperName(index)

    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setTimeout(() => {
      const newOption = createOption(inputValue);

      mutatePaperName({ name: newOption.name, status: newOption.status })
      setOptionsPaperName((prev: any) => [...prev, newOption]);
      setValuePaperNameCreatable(newOption);

    }, 0);


  };


  useEffect(() => {
    (async () => {
      if (PaperNameSuccess && optionsPaperName) {
        await PaperNameRefetch();
        setValue("paper_name", PaperNameData[0]?.value)
        setValuePaperNameCreatable(PaperNameData[0])


        let temp_data = structuredClone(tableData);
        let temp_updated = temp_data.map((d: any, ind: any) => {
          let temp_d = { ...d };
          if (currentIndexPaperName === ind) {
            temp_d["paper_name"] = PaperNameData[0]?.value;
            setValue("paper_name", PaperNameData[0]?.value)
          }
          return temp_d;
        });
        setTableData(temp_updated);
        // setValuePaperName(temp_updated)
      }
    })();

    return () => {
    };
  }, [PaperNameSuccess, PaperNameData, currentIndexPaperName]);

  const apinamePaperDesign = process.env.REACT_APP_CARTON_PLY_PAPER_DESIGN_API?.toString()
  const { mutate: mutatePaperDesign, data: dataPaperDesign, isError: PaperDesignIsError, isLoading: PaperDesignLoading, isSuccess: PaperDesignSuccess, error: PaperDesignError }: any = useCreate(apinamePaperDesign)
  // const [isLoadingValue, setIsLoadingValue] = useState(false);

  const [valuePaperDesign, setValuePaperDesign]: any = useState();
  const [optionsPaperDesign, setOptionsPaperDesign]: any = useState([]);
  const [valuePaperDesignCreatable, setValuePaperDesignCreatable] = useState<Option | null>();

  const { data: PaperDesignData, refetch: PaperDesignRefetch } = useFetchCartonPaperDesignDropDown()

  useEffect(() => {
    setOptionsPaperDesign(PaperDesignData)
  }, [PaperDesignData])


  const handlePaperDesign = (e: any, index: any) => {
    
    // setValuePaperDesign(e)
    let temp_data = structuredClone(tableData);
    let temp_updated = temp_data.map((d: any, ind: any) => {
      let temp_d = { ...d };
      if (index === ind) {
        temp_d["paper_design"] = e.value;
        setValue("paper_design", e.value)
      }
      return temp_d;
    });
    setTableData(temp_updated);
    // setUsePaper(e.target.value);
  };

  const [currentIndexPaperDesign, setCurrentIndexPaperDesign]: any = useState('')

  const handleCreatePaperDesign = (inputValue: string, index: any) => {

    setCurrentIndexPaperDesign(index)

    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setTimeout(() => {
      const newOption = createOption(inputValue);

      mutatePaperDesign({ name: newOption.name, status: newOption.status })
      setOptionsPaperName((prev: any) => [...prev, newOption]);
      setValuePaperDesignCreatable(newOption);

    }, 0);


  };


  useEffect(() => {
    (async () => {
      if (PaperDesignSuccess && optionsPaperDesign) {
        await PaperDesignRefetch();
        setValue("paper_design", PaperDesignData[0]?.value)
        setValuePaperDesignCreatable(PaperDesignData[0])


        let temp_data = structuredClone(tableData);
        let temp_updated = temp_data.map((d: any, ind: any) => {
          let temp_d = { ...d };
          if (currentIndexPaperDesign === ind) {
            temp_d["paper_design"] = PaperDesignData[0]?.value;
            setValue("paper_design", PaperDesignData[0]?.value)
          }
          return temp_d;
        });
        setTableData(temp_updated);
        // setValuePaperDesign(temp_updated)
      }
    })();

    return () => {
    };
  }, [PaperDesignSuccess, PaperDesignData, currentIndexPaperDesign]);

  const apinamePaperBF = process.env.REACT_APP_CARTON_PLY_PAPER_BF_API?.toString()
  const { mutate: mutatePaperBF, data: dataPaperBF, isError: PaperBFIsError, isLoading: PaperBFLoading, isSuccess: PaperBFSuccess, error: PaperBFError }: any = useCreate(apinamePaperBF)
  // const [isLoadingValue, setIsLoadingValue] = useState(false);

  const [valuePaperBF, setValuePaperBF]: any = useState();
  const [optionsPaperBF, setOptionsPaperBF]: any = useState([]);
  const [valuePaperBFCreatable, setValuePaperBFCreatable] = useState<Option | null>();

  const { data: PaperBFData, refetch: PaperBFRefetch } = useFetchCartonPaperBFDropDown()

  useEffect(() => {
    setOptionsPaperBF(PaperBFData)
  }, [PaperBFData])


  const handlePaperBF = (e: any, index: any) => {
    // setValuePaperBF(e)
    let temp_data = structuredClone(tableData);
    let temp_updated = temp_data.map((d: any, ind: any) => {
      let temp_d = { ...d };
      if (index === ind) {
        temp_d["paper_bursting_factor"] = e.value;
        setValue("paper_bursting_factor", e.value)
      }
      return temp_d;
    });
    setTableData(temp_updated);
    // setUsePaper(e.target.value);
  };

  const [currentIndexPaperBF, setCurrentIndexPaperBF]: any = useState('')

  const handleCreatePaperBF = (inputValue: string, index: any) => {

    setCurrentIndexPaperBF(index)

    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setTimeout(() => {
      const newOption = createOption(inputValue);

      mutatePaperBF({ name: newOption.name, status: newOption.status })
      setOptionsPaperName((prev: any) => [...prev, newOption]);
      setValuePaperBFCreatable(newOption);

    }, 0);


  };


  useEffect(() => {
    (async () => {
      if (PaperBFSuccess && optionsPaperBF) {
        await PaperBFRefetch();
        setValue("paper_bursting_factor", PaperBFData[0]?.value)
        setValuePaperBFCreatable(PaperBFData[0])


        let temp_data = structuredClone(tableData);
        let temp_updated = temp_data.map((d: any, ind: any) => {
          let temp_d = { ...d };
          if (currentIndexPaperBF === ind) {
            temp_d["paper_bursting_factor"] = PaperBFData[0]?.value;
            setValue("paper_bursting_factor", PaperBFData[0]?.value)
          }
          return temp_d;
        });
        setTableData(temp_updated);
        // setValuePaperBF(temp_updated)
      }
    })();

    return () => {
    };
  }, [PaperBFSuccess, PaperBFData, currentIndexPaperBF]);

  const apinamePaperGSM = process.env.REACT_APP_CARTON_PLY_PAPER_GSM_API?.toString()
  const { mutate: mutatePaperGSM, data: dataPaperGSM, isError: PaperGSMIsError, isLoading: PaperGSMLoading, isSuccess: PaperGSMSuccess, error: PaperGSMError }: any = useCreate(apinamePaperGSM)
  // const [isLoadingValue, setIsLoadingValue] = useState(false);

  const [valuePaperGSM, setValuePaperGSM]: any = useState();
  const [optionsPaperGSM, setOptionsPaperGSM]: any = useState([]);
  const [valuePaperGSMCreatable, setValuePaperGSMCreatable] = useState<Option | null>();

  const { data: PaperGSMData, refetch: PaperGSMRefetch } = useFetchCartonPaperGSMDropDown()

  useEffect(() => {
    setOptionsPaperGSM(PaperGSMData)
  }, [PaperGSMData])


  const handlePaperGSM = (e: any, index: any) => {
    // setValuePaperGSM(e)
    let temp_data = structuredClone(tableData);
    let temp_updated = temp_data.map((d: any, ind: any) => {
      let temp_d = { ...d };
      if (index === ind) {
        temp_d["paper_gsm"] = e.value;
        setValue("paper_gsm", e.value)
      }
      return temp_d;
    });
    setTableData(temp_updated);
    // setUsePaper(e.target.value);
  };

  const [currentIndexPaperGSM, setCurrentIndexPaperGSM]: any = useState('')

  const handleCreatePaperGSM = (inputValue: string, index: any) => {

    setCurrentIndexPaperGSM(index)

    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setTimeout(() => {
      const newOption = createOption(inputValue);

      mutatePaperGSM({ name: newOption.name, status: newOption.status })
      setOptionsPaperName((prev: any) => [...prev, newOption]);
      setValuePaperGSMCreatable(newOption);

    }, 0);


  };


  useEffect(() => {
    (async () => {
      if (PaperGSMSuccess && optionsPaperGSM) {
        await PaperGSMRefetch();
        setValue("paper_gsm", PaperGSMData[0]?.value)
        setValuePaperGSMCreatable(PaperGSMData[0])


        let temp_data = structuredClone(tableData);
        let temp_updated = temp_data.map((d: any, ind: any) => {
          let temp_d = { ...d };
          if (currentIndexPaperGSM === ind) {
            temp_d["paper_gsm"] = PaperGSMData[0]?.value;
            setValue("paper_gsm", PaperGSMData[0]?.value)
          }
          return temp_d;
        });
        setTableData(temp_updated);
        // setValuePaperGSM(temp_updated)
      }
    })();

    return () => {
    };
  }, [PaperGSMSuccess, PaperGSMData, currentIndexPaperGSM]);

  // {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}
  //                               {checkToast&&show && showmessage === "success" && 

  //                                   <Mui_Toaster message={"Brand Added Successfully"} severity={"success"} Isopen={true}/>
  //                               }









  return (
    <>
      <div className="">
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6" style={{ fontSize: '30px' }}>
                <h4>{Breadcrumb_Title_Name} List</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to={`/list${Breadcrumb_Primary_Link}`}
                      className="text-decoration-none text-black"
                    >
                      {Breadcrumb_Primary_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      {Breadcrumb_Parent_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" >
                    {Breadcrumb_Title_Name} List
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content" style={{ padding: '0px' }}>
          <div className="container-fluid">
            <div className="card card-default">
              <div className="container p-0" style={{ maxWidth: "100%" }}>
                {/* <button onClick={handleNewRowClick}>Add New</button> */}
                {/* <BasicTable/> */}
                {/* <Editable_Table
                  apiname={apiname}
                  filterFields={filter}
                  addButtonName={addButtonName}
                  stickyStart={stickyStart}
                  stickyEnd={stickyEnd}
                  TableColumns={memoizedColumns}
                  data={memoizedData}
                  updateMyData={updateMyData}
                  addButtonPageName={addButtonPageName}
                  titleofheaders={titleofheaders}
                  handleNewRowClick={handleNewRowClick}
                /> */}

                <div className="container table-responsive ">
                  {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}

                  {/* {checkToast&&show && showmessage === "success" && 
                                
                                                                   <Mui_Toaster message={"Added Successfully"} severity={"success"} Isopen={true}/>
                                                               } */}
                  <form action="">
                    <table
                      className="table table-bordered table-hover"
                      style={{ width: "max-content" }}
                    >

                      <thead className="text-center">
                        <tr>
                          {/* <th scope="col">#</th> */}
                          <th scope="col">
                            Paper <br /> Name
                          </th>
                          <th scope="col">
                            Use
                            <br /> Paper
                          </th>
                          <th scope="col">
                            Paper
                            <br /> Color
                          </th>
                          <th scope="col">
                            Paper <br />
                            Design
                          </th>
                          <th scope="col">
                            Paper
                            <br /> BF
                          </th>
                          <th scope="col">
                            Paper
                            <br /> GSM
                          </th>
                          <th scope="col">
                            Paper
                            <br /> Liner
                          </th>
                          <th scope="col">
                            Carton
                            <br /> Length MM
                          </th>
                          <th scope="col">
                            Carton
                            <br /> Width MM
                          </th>
                          <th scope="col">
                            Carton
                            <br /> Height MM
                          </th>
                          <th scope="col">
                            Carton
                            <br /> Kan Width MM
                          </th>
                          <th scope="col">
                            Carton
                            <br /> Kan Height MM
                          </th>
                          <th scope="col">
                            Paper <br />
                            Size Length MM
                          </th>
                          <th scope="col">
                            Paper <br />
                            Decal Size MM(Width)
                          </th>
                          <th scope="col">
                            Total
                            <br /> MM
                          </th>
                          <th scope="col">
                            Paper
                            <br /> Qty
                          </th>
                          <th scope="col">
                            Paper
                            <br /> Weight
                          </th>
                          <th scope="col">
                            Paper
                            <br /> Printing
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData?.map((data: any, index: any) => {
                          return (
                            <tr>
                              <td style={{ width: "180px" }}>
                                <div>
                                  {/* <select
                                    style={errorForInput[index]?.paper_name == "Required!" ? { border: "1px solid red" } : {}}
                                    //  {...register("name", { required: { value: true, message: "required" } })}
                                    key={index}
                                    name='paper_name'
                                    required
                                    onChange={(e: any) => handlePaperName(e, index)}
                                    className="text-center form-select"
                                  >
                                    <option value="" selected>
                                      select
                                    </option>
                                    <option value="GOLDEN_PAPER">Golden Paper</option>
                                    <option value="NATURAL_PAPER">Natural Paper</option>
                                  </select> */}

                                  <CreatableSelect
                                    // isClearable={() => {
                                    //   setValuePaperName(null)
                                    //   setValue(null)
                                    // }}
                                    className={errorForInput[index]?.paper_names == "Required!" ? "text-center border border-danger rounded-1" : ""}
                                    // {...register("material_use", { required: { value: true, message: "Material Use is required" } })}
                                    placeholder={'Paper Name'}
                                    // menuIsOpen={true}
                                    components={customCreatableIndicator}
                                    onChange={(e: any) => handlePaperName(e, index)}
                                    options={optionsPaperName}
                                    onCreateOption={(inputValue) => handleCreatePaperName(inputValue, index)}
                                    // onChange={handleChangeMaterialType}
                                    // onMenuOpen={() => MaterialTypeRefetch()}
                                    value={valuePaperNameCreatable == null ? valuePaperName : valuePaperNameCreatable}
                                  />
                                </div>
                                {/* <span className='text-red w-100'>{errors?.name?.message}</span> */}
                              </td>
                              <td style={{ width: "180px" }}>
                                <div>
                                  <select
                                    style={errorForInput[index]?.use_paper == "Required!" ? { border: "1px solid red" } : {}}
                                    // {...register("use_paper", { required: { value: true, message: "required" } })}
                                    key={index}
                                    onChange={(e: any) => handleUsePaper(e, index)}
                                    className="text-center form-select"
                                  >
                                    <option value="" selected>
                                      select
                                    </option>
                                    <option value="PLAIN">Plain</option>
                                    <option value="LINER">Liner</option>
                                  </select>
                                </div>
                                {/* <span className='text-red w-100'>{errors?.use_paper?.message}</span> */}

                              </td>

                              <td style={{ width: "180px" }}>
                                <div>
                                  {/* <select
                                                              {...register("paper_color", { required: { value: true, message: "required" } })}

                                key={index}
                                onChange={(e: any) =>
                                  handlePaperColor(e, index)
                                }
                                className="px-4"
                              >
                                <option value="" selected>
                                  select
                                </option>
                                <option value="brown">Brown</option>
                              </select> */}

                                  <Select
                                    components={customIndicator}
                                    // {...register("paper_color", { required: { value: true, message: "Color is required" } })}
                                    // placeholder={'Select Color'}
                                    className={errorForInput[index]?.paper_color == "Required!" ? "text-center border border-danger rounded-1" : ""}
                                    onChange={(e: any) =>
                                      handlePaperColor(e, index)
                                    }
                                    options={options}
                                  // isMulti
                                  // defaultValue={options}
                                  // value={color}
                                  // isOptionDisabled={(options:any)=>
                                  //     {
                                  //         if(options.status === "inactive" || options.deleted == true){
                                  //         return options
                                  //         }
                                  //        }}
                                  // className="dropdown-select"

                                  />




                                </div>
                                {/* <span className='text-red w-100'>{errors?.paper_color?.message}</span> */}
                              </td>

                              <td style={{ width: "180px" }}>
                                <div>
                                  {/* <select
                                    style={errorForInput[index]?.paper_design == "Required!" ? { border: "1px solid red" } : {}}

                                    // {...register("paper_design", { required: { value: true, message: "required" } })}

                                    key={index}
                                    onChange={(e: any) =>
                                      handlePaperDesign(e, index)
                                    }
                                    className="text-center form-select"
                                  >
                                    <option value="" selected>
                                      select
                                    </option>
                                    <option value="Triangle">Triangle</option>
                                    <option value="Verbal">Verbal</option>
                                  </select> */}

                                  <CreatableSelect
                                    // isClearable={() => {
                                    //   setValueMaterialType(null)
                                    //   setValue(null)
                                    // }}
                                    className={errorForInput[index]?.paper_design == "Required!" ? "text-center border border-danger rounded-1" : ""}
                                    // {...register("material_use", { required: { value: true, message: "Material Use is required" } })}
                                    placeholder={'Paper Design'}
                                    // menuIsOpen={true}
                                    components={customCreatableIndicator}
                                    onChange={(e: any) => handlePaperDesign(e, index)}
                                    onCreateOption={(inputValue) => handleCreatePaperDesign(inputValue, index)}
                                    value={valuePaperDesignCreatable == null ? valuePaperDesign : valuePaperDesignCreatable}
                                  options={optionsPaperDesign}
                                  // onCreateOption={(inputValue) => handleCreateMaterialType(inputValue, index)}



                                  />

                                </div>
                                {/* <span className='text-red w-100'>{errors?.paper_design?.message}</span> */}
                              </td>
                              <td style={{ width: "180px" }}>
                                <div>
                                  {/* <select
                                    style={errorForInput[index]?.paper_bursting_factor == "Required!" ? { border: "1px solid red" } : {}}
                                    // {...register("paper_bf", { required: { value: true, message: "required" } })}

                                    key={index}
                                    onChange={(e: any) => handlePaperBF(e, index)}
                                    className="text-center form-select"
                                  >
                                    <option value="" selected>
                                      select
                                    </option>
                                    <option value={"12"}>12</option>
                                    <option value={"16"}>16</option>
                                  </select> */}

                                  <CreatableSelect
                                    // isClearable={() => {
                                    //   setValueMaterialType(null)
                                    //   setValue(null)
                                    // }}
                                    className={errorForInput[index]?.paper_bursting_factor == "Required!" ? "text-center border border-danger rounded-1" : ""}
                                    // {...register("material_use", { required: { value: true, message: "Material Use is required" } })}
                                    placeholder={'Paper BF'}
                                    // menuIsOpen={true}
                                    components={customCreatableIndicator}
                                    onChange={(e: any) => handlePaperBF(e, index)}
                                    onCreateOption={(inputValue) => handleCreatePaperBF(inputValue, index)}
                                    value={valuePaperBFCreatable == null ? valuePaperBF : valuePaperBFCreatable}
                                    options={optionsPaperBF}
                                  // onCreateOption={(inputValue) => handleCreateMaterialType(inputValue, index)}



                                  />

                                </div>
                                {/* <span className='text-red w-100'>{errors?.paper_bf?.message}</span> */}
                              </td>
                              <td style={{ width: "180px" }}>
                                <div>
                                  {/* <select
                                    style={errorForInput[index]?.paper_gsm == "Required!" ? { border: "1px solid red" } : {}}
                                    // {...register("paper_gsm", { required: { value: true, message: "required" } })}

                                    key={index}
                                    onChange={(e: any) => handlePaperGSM(e, index)}
                                    className="text-center form-select"
                                  >
                                    <option value="" selected>
                                      select
                                    </option>
                                    <option value={"100"}>100</option>
                                    <option value={"150"}>150</option>
                                  </select> */}

                                  <CreatableSelect
                                    // isClearable={() => {
                                    //   setValueMaterialType(null)
                                    //   setValue(null)
                                    // }}
                                    className={errorForInput[index]?.paper_gsm == "Required!" ? "text-center border border-danger rounded-1" : ""}
                                    // {...register("material_use", { required: { value: true, message: "Material Use is required" } })}
                                    placeholder={'Paper GSM'}
                                    // menuIsOpen={true}
                                    components={customCreatableIndicator}
                                    onChange={(e: any) => handlePaperGSM(e, index)}
                                    onCreateOption={(inputValue) => handleCreatePaperGSM(inputValue, index)}
                                    value={valuePaperGSMCreatable == null ? valuePaperGSM : valuePaperGSMCreatable}
                                    options={optionsPaperGSM}


                                  />

                                </div>
                                {/* <span className='text-red w-100'>{errors?.paper_gsm?.message}</span> */}
                              </td>
                              <td style={{ width: "180px" }}>
                                <div>
                                  <input
                                    style={data.use_paper == "LINER" && errorForInput[index]?.paper_liner == "Required!" ? { border: "1px solid red", width: "-webkit-fill-available" } : { width: "-webkit-fill-available" }}
                                    type="number"
                                    className="form-control text-center"
                                    value={data.paper_liner}
                                    disabled={data.use_paper !== "LINER"}
                                    key={index}
                                    onChange={(e: any) =>
                                      handlePaperLiner(e, index)
                                    }
                                  // style={{ width: "-webkit-fill-available" }}
                                  />
                                </div>
                              </td>
                              <td style={{ width: "180px" }}>
                                <div>
                                  <input
                                    key={index}
                                    type="number"
                                    className="form-control text-center"
                                    disabled
                                    value={data.paper_length_mm}
                                    style={{ width: "-webkit-fill-available" }}
                                  />
                                </div>
                              </td>
                              <td style={{ width: "180px" }}>
                                <div>
                                  <input
                                    type="number"
                                    className="form-control text-center"
                                    key={index}
                                    disabled
                                    value={cartonWidth}
                                    style={{ width: "-webkit-fill-available" }}
                                  />
                                </div>
                              </td>
                              <td style={{ width: "180px" }}>
                                <div>
                                  <input
                                    type="number"
                                    className="form-control text-center"
                                    key={index}
                                    disabled
                                    value={cartonHeight}
                                    style={{ width: "-webkit-fill-available" }}
                                  />
                                </div>
                              </td>
                              <td style={{ width: "180px" }}>
                                <div>
                                  <input
                                    type="number"
                                    className="form-control text-center"
                                    key={index}
                                    value={kanWidth}
                                    disabled
                                    style={{ width: "-webkit-fill-available" }}
                                  />
                                </div>
                              </td>
                              <td style={{ width: "180px" }}>
                                <div>
                                  <input
                                    type="number"
                                    className="form-control text-center"
                                    key={index}
                                    value={kanHeight}
                                    disabled
                                    style={{ width: "-webkit-fill-available" }}
                                  />
                                </div>
                              </td>
                              <td style={{ width: "180px" }}>
                                <div>
                                  <input
                                    type="number"
                                    className="form-control text-center"
                                    key={index}
                                    value={data.paper_size_length_mm}
                                    disabled
                                    style={{ width: "-webkit-fill-available" }}
                                  />
                                </div>
                              </td>
                              <td style={{ width: "180px" }}>
                                <div>
                                  <input
                                    type="number"
                                    className="form-control text-center"
                                    key={index}
                                    value={paperDecalSizeMM}
                                    disabled
                                    style={{ width: "-webkit-fill-available" }}
                                  />
                                </div>
                              </td>
                              <td style={{ width: "180px" }}>
                                <div>
                                  <input
                                    type="number"
                                    className="form-control text-center"
                                    key={index}
                                    value={data.total_mm}
                                    disabled
                                    style={{ width: "-webkit-fill-available" }}
                                  />
                                </div>
                              </td>
                              <td style={{ width: "180px" }}>
                                <div>
                                  <input
                                    style={errorForInput[index]?.paper_qty == "Required!" ? { border: "1px solid red", width: "-webkit-fill-available" } : { width: "-webkit-fill-available" }}
                                    // {...register("paper_qty", { required: { value: true, message: "required" } })}
                                    type="number"
                                    className="form-control text-center"
                                    value={data.paper_qty}
                                    key={index}
                                    onChange={(e: any) => handlePaperQty(e, index)}
                                  />
                                </div>
                                {/* <span className='text-red w-100'>{errors?.paper_qty?.message}</span> */}
                              </td>
                              <td style={{ width: "180px" }}>
                                <div>
                                  <input
                                    style={errorForInput[index]?.paper_weight == "Required!" ? { border: "1px solid red", width: "-webkit-fill-available" } : { width: "-webkit-fill-available" }}
                                    // {...register("paper_weight", { required: { value: true, message: "required" } })}
                                    type="number"
                                    className="form-control text-center"
                                    // disabled
                                    value={data.paper_weight}
                                    key={index}
                                    onChange={(e: any) =>
                                      handlePaperWeight(e, index)
                                    }
                                  />
                                </div>
                                {/* <span className='text-red w-100'>{errors?.paper_weight?.message}</span> */}
                              </td>
                              <td style={{ width: "180px" }}>
                                <div>
                                  <select
                                    style={errorForInput[index]?.paper_printing == "Required!" ? { border: "1px solid red" } : {}}
                                    // {...register("printing", { required: { value: true, message: "required" } })}
                                    key={index}
                                    onChange={(e: any) =>
                                      handlePaperPrinting(e, index)
                                    }
                                    className="text-center form-select"
                                  >
                                    <option value="" selected>
                                      select
                                    </option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                </div>
                                {/* <span className='text-red w-100'>{errors?.printing?.message}</span> */}
                              </td>
                            </tr>
                          );
                        })}

                        <tr>
                          <td colSpan={5} className="table-active">
                            TOTAL
                          </td>
                          <td className="table-active">
                            Paper GSM = {totalOfPaperGSM}
                          </td>
                          <td colSpan={1} className="table-active"></td>
                          <td colSpan={1} className="table-active"></td>
                          <td colSpan={1} className="table-active"></td>
                          <td colSpan={1} className="table-active"></td>
                          <td colSpan={1} className="table-active"></td>
                          <td colSpan={1} className="table-active"></td>
                          <td colSpan={1} className="table-active"></td>
                          <td colSpan={1} className="table-active"></td>
                          <td colSpan={1} className="table-active">
                            Total MM = {total_MM}
                          </td>
                          <td colSpan={1} className="table-active">
                            Paper Qty = {paperQty}
                          </td>
                          <td colSpan={1} className="table-active">
                            Paper Weight = {paperWeight}
                          </td>
                          <td colSpan={1} className="table-active"></td>
                        </tr>
                      </tbody>
                    </table>
                  </form>
                </div>







                <div className="m-2" style={{ justifyContent: "end", float: "right" }}>
                  <button
                    type="button"
                    className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                    style={{
                      borderColor: "rgb(0, 54, 101)",
                      background: "rgb(0, 54, 101)",
                      color: "white",
                    }}
                    // disabled={paperQty === 0 || paperWeight==0.000 }

                    onClick={handleSubmit(onSubmit)}
                  // disabled={isLoading}
                  >
                    Submit
                  </button>

                </div>

              </div>
              {/* {show && showmessage === "success" ? <Alert color={showmessage} message={"Added Successfully"} /> : ""} */}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Editable;
