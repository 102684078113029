import { ReactSortable } from 'react-sortablejs'
import "../../pages/Color/colorList.css"
import GridItem from '../Example/GridItem';
// import Style from "./ColumnsVisibility.css"
import Dropdown from 'react-bootstrap/Dropdown';
import { useEffect, useState } from 'react';
import { fetchApiHelper } from '../../hooks/helpers/fetch';


const ColumnVisibility = ({ NewColumnOrder, setNewColumnOrder, instance, columns, pagecolumn, colu }: any) => {
    const onDragDropEnds = (oldIndex: any, newIndex: any) => {
    };
    const [propspass,setPropspass]:any=useState()

    useEffect(() => {
        fetchApiHelper(`configuration/columnVisibility/get_by_model_name/?name=${pagecolumn}`).then((response) => {
            const ishidden=response?.results[0]?.columns?.filter((col:any)=>{
               
                if( col.visibility==="HIDE"){
                    return col.column_name
                }
            }).map((cols:any)=>{
                return cols.column_name
            })
            const previousdata=localStorage.getItem(`colu${pagecolumn}`)
            if(!previousdata||  typeof previousdata==="undefined"|| previousdata===null || previousdata === "[]" || previousdata === undefined){
                localStorage.setItem(`colu${pagecolumn}`,!ishidden? JSON.stringify([]):JSON.stringify(ishidden))
                ishidden?.map((data:any)=> instance?.toggleHideColumn(data,true))
            }
            else{
                let localHiden = JSON.parse(previousdata);
                localHiden?.map((data:any)=> instance?.toggleHideColumn(data,true))
                // localStorage.setItem(`colu${pagecolumn}`,JSON.stringify(localHiden))
                // localStorage.setItem(`colu${pagecolumn}`, JSON.stringify([]))
            }
            setPropspass(ishidden?ishidden:[])
        })

    }, [])

    return (
        <>
            <Dropdown autoClose="outside">
                <Dropdown.Toggle id="dropdown-button-dark-example1" className='text-white rounded-3 p-2' variant="primary" >
                    Column Visibility
                </Dropdown.Toggle>

                <Dropdown.Menu variant="light" style={{ zIndex: "1050" }}>


                    <Dropdown.Item as="button" style={{ width: "600px" }} active={false}>

                        <div className='row'>
                            <div className='col-4'>
                                <button className='btn w-100' onClick={() => {
                                    localStorage.setItem(`colu${pagecolumn}`, JSON.stringify([]))
                                    instance.toggleHideAllColumns(false)
                                }} style={{ background: "#28a745", marginLeft: 0 }}>  Open All</button>
                            </div>
                            <div className='col-4'>
                                <button className='btn w-100' onClick={() => {

                                    instance.toggleHideAllColumns(true)
                                    let temp = NewColumnOrder.map((e: any) => e.id)
                                    localStorage.setItem(`colu${pagecolumn}`, JSON.stringify(temp))

                                }} style={{ backgroundColor: '#dc3545', marginLeft: 0 }}>   Close All</button>
                            </div>
                            <div className='col-4'>
                                <button className='btn w-100' onClick={() => {
                                    localStorage.setItem(`colu${pagecolumn}`, JSON.stringify(propspass))
                                    // localStorage.removeItem(`colu${pagecolumn}`)
                                    instance.setColumnOrder(instance?.columns)
                                    let temp_default_column: any = []
                                    instance?.columns?.map((e: any) => {
                                        NewColumnOrder?.map((x: any) => {
                                            if (e.id === x.id) {
                                                temp_default_column.push(x)
                                            }
                                        })
                                    })
                                    instance.toggleHideAllColumns(false)
                                    setNewColumnOrder(temp_default_column)
                                    propspass?.map((data:string)=>{
                                        // instance.toggleHideColumn(data)
                                        instance.toggleHideColumn(data,true)
                                    })
                                }} style={{ marginLeft: 0 }}>  Reset</button>
                            </div>
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Item as="button" active={false} >
                        <ReactSortable
                            list={NewColumnOrder}
                            setList={(newlist) => {
                                setNewColumnOrder(newlist)
                            }}
                            ghostClass="dropArea"
                            handle=".dragHandle"
                            filter=".ignoreDrag"
                            className='row '
                            preventOnFilter={true}
                            onEnd={({ oldIndex, newIndex }) => onDragDropEnds(oldIndex, newIndex)}

                        >
                            <>
                                {NewColumnOrder?.map((user: any, index: any) => {

                                    const title = columns?.filter((e: any) => {
                                        if (e.id === user.id) return e
                                    })

                                    return (
                                        <GridItem key={user.id} pagecolumn={pagecolumn} colu={user} instance={instance} headerTitle={title[0]?.Header} indexValue={index} />
                                    )
                                })}
                            </>
                        </ReactSortable>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

        </>
    )
}

export default ColumnVisibility