import React, { useState } from 'react'
import './quotation.css'
import { Link, useNavigate } from 'react-router-dom'
import { Document, Page, Text, View, PDFViewer, StyleSheet, Font } from '@react-pdf/renderer';

import Check from './Check';
import { PDFDownloadLink } from '@react-pdf/renderer';


const Quotation = () => {

    const navigate = useNavigate()

    let pageName = "Quotation"


   
  
  return (
    <>



<div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header px-3 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Get a {pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">{pageName}</Link></li>
                                    <li className="breadcrumb-item">Get a {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add GST</span></h5>
                            </div> */}
                            <form >
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>                                
                                {/* {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message} /> : ""} */}

                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-4 mt-2">
                                                    <label >Quotation ID<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder="Designation Name" 
                                                    disabled
                                                    // {...register("designation",{onChange:handleValue, required: { value: true, message: "Designation Name is required" }})} 
                                                    />
                                                        </div>
                                                    {/* <span className='text-red w-100'>{errors?.designation?.message}</span> */}
                                                </div>
                                            
                                                <div className="col-sm-4 mt-2">
                                                    <label >Discount (%)<span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" 
                                                    // {...register("status", { required: { value: true, message: "Status is required" } })}
                                                    >
                                                    <option value={""}  selected>Select Any</option>
                                                        <option value={"5"}>5 %</option>
                                                        <option value={"10"}>10 %</option>
                                                    </select>
                                                    {/* <span className='text-red w-100'>{errors?.status?.message}</span> */}
                                                </div>


                                               
                                            
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                {/* <h1>Generate and Download PDF</h1> */}
      <PDFDownloadLink className="border-1 rounded text-white px-4 py-1 mt-2 mx-3" 
        style={{ borderColor: "rgb(0, 54, 101)", color: "white" ,backgroundColor:"rgb(0, 54, 101)",textDecoration:"none"}} document={<Check />} fileName="sample.pdf">
        {({ blob, url, loading, error }) =>
          loading ? 'Loading document...' : 'Download PDF'
        }
      </PDFDownloadLink>

      <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listSalesinquiry")}>Cancel</button>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
        </div>






















        {/* <div className="container">
        <div className="card">
            <div className="blue-box">
                <img src="logo.png" alt="Logo" className="img"/>
                <p className="header-text">SQ08899</p>
            </div>

            <div className="header-row">
                <div className="col">
                    <h1 className="header">Invoice for</h1>
                    <p>Thomas Lee</p>
                    <p>43 Lane, San Francisco,</p>
                    <p>California, United States 874536</p>
                    <p>Email: customer@gmail.com</p>
                </div>
                <div className="col">
                    <h1 className="header">Delivery Location</h1>
                    <p>2552 Avenue</p>
                    <p>Boggstown, India</p>
                    <p>India 360057</p>
                </div>
                <div className="col">
                    <div className="bg-light centered-text">
                        <h1 className="header">AMOUNT</h1>
                        <p className="amount">RS. 23,600</p>
                        <p>APR 8, 2023</p>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <table>
                        <tr>
                            <td>SQ Date:</td>
                            <td>APR 8, 2023</td>
                        </tr>
                        <tr>
                            <td>Amendment:</td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td>Number Of Item:</td>
                            <td>05</td>
                        </tr>
                    </table>
                </div>
                <div className="col">
                    <table>
                        <tr>
                            <td>SQ Date:</td>
                            <td>APR 8, 2023</td>
                        </tr>
                        <tr>
                            <td>Amendment:</td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td>Number Of Item:</td>
                            <td>05</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div> */}

   
    </>

  )
}

export default Quotation