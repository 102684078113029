import React, { useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../components/Dynamic_Table/Table/Table";
import { Columns_BoxPatti,titleofheaders } from "./Columns_BoxPatti";
const filter = {
box_patti_code:'',
name_and_grade:'',
color:'',
material:'',
printing:'',
printing_brand:'',
printing_type:'',
printing_color:'',
printing_total_color:'',
width:'',
thickness_micron:'',
box_patti_1_meter_weight_gram:'',
box_patti_1_roll_meter:'',
patti_1_roll_net_kg:'',
packing_roll_nos:'',
packing_roll_kg:'',
box_patti_meter_in_1_kg:'',
hsn_code:'',
gst:'',
box_patti_special_running:'',
bursting_strength:'',
status:'',
created_by:'',
updated_by:'',
};
const List_BoxPatti = () => {
  const Breadcrumb_Primary_Title = "Home";
  const Breadcrumb_Primary_Link = "Color";

  const Breadcrumb_Parent_Title = "Product Details";

  const Breadcrumb_Title_Name = "Box Patti";

  const apiname = process.env.REACT_APP_BOX_PATTI_API?.toString();



  const addButtonName = "Box Patti";

  const stickyStart = 1;
  const stickyEnd = 2;

  const addButtonPageName="boxpatti"
  const permissionClass = {
    front: "boxpatti",
    back: "boxpatti"
  }

  return (
    <>
      <div className="content-wrapper">
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{Breadcrumb_Title_Name} List</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to={`/list${Breadcrumb_Primary_Link}`}
                      className="text-decoration-none text-black"
                    >
                      {Breadcrumb_Primary_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      {Breadcrumb_Parent_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {Breadcrumb_Title_Name} List
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="container p-0" style={{ maxWidth: "100%" }}>
                {/* <BasicTable/> */}
                <Table
                  apiname={apiname}
                  filterFields={filter}
                  addButtonName={addButtonName}
                  stickyStart={stickyStart}
                  stickyEnd={stickyEnd}
                  TableColumns={Columns_BoxPatti}
                  addButtonPageName={addButtonPageName}
                  titleofheaders={titleofheaders}
                  permissionClass={permissionClass}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default List_BoxPatti;
