import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { TiTickOutline } from "react-icons/ti";

import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Restricted from "../../Roles/Restricted";
import Axiosinstance from "../../hooks/Interceptor";
const Order_Actions = ({ row, apiname, deletemodalask,permissionClass}: any) => {
  console.log("row iddddddddddddd",row)
  return (
    <>
    <div
        className="vertical-align-center"
        style={{
          display: "inline-block !important",
          justifyContent: "space-evenly !important",
        }}
      >
      

        <Restricted
          to={`${permissionClass?.front}.change_${permissionClass?.back}`}
          fallback={""}
        >
        <button
          onClick={() => {
            Axiosinstance.delete(`${process.env.REACT_APP_BASE_URL}sales/products/${row.original.id}`)
            console.log(row.original.id);
          }}
          className="btn btn-primary text-white"
        >
          <RiDeleteBinLine />
        </button>
        </Restricted>


        <Restricted
          to={`${permissionClass?.front}.delete_${permissionClass?.back}`}
          fallback={""}
        >
        <button
          // onClick={() => {
          //   deletemodalask(row.original.id);
          // }}
          className="btn btn-primary text-white"
        >
          <TiTickOutline />
        </button>
        </Restricted>

      </div>
    </>
  );
};

export default Order_Actions;

