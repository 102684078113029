import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/esm/Button";
import { useCreate } from '../../hooks/Api';
import { Alert } from "../../components/Model/Model";
import { useForm } from 'react-hook-form';
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from '../focus';
import Select from "react-select"
import { useNavigate } from 'react-router-dom';
import CreatableSelect from "react-select/creatable";
// import { useFetchBrandDropDown, useFetchColorDropDown, useFetchGSTDropDown } from '../../hooks/DropDown_Api';
import { useFetchDistrictDropDown, useFetchStateDropDown, useFetchCountryDropDown, useFetchPincodeDropDown, useFetchTalukaDropDown } from "../../hooks/DropDown_Api";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'
const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}

interface Option {
    readonly label: string;
    readonly pincode?: string;
    //   readonly value: string;
    readonly name?: string;
  }
  const createOptionsPincode = (label: string) => ({
    label,
    // value: label,
    pincode: label,
    status: "active"
  });
  const createOptions = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
  });
  const createOptionDistrict = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
  });
  const createOptionState = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
  });
  const createOptionCountry = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
  });






const ntc = require('@yatiac/name-that-color');
const toHex = require('colornames')




export const City_Model = ({refetchForm,open,setOpen,setSubmitted}:any) => {
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()
    const [logo, setlogo] = useState(null)
    const [logoPdf, setlogoPdf] = useState(null)

    const onLogoChange = (e:any) => {
        setlogo(e.target.files[0].name)
    }

    const onLogoPDFChange = (e:any) => {
        setlogoPdf(e.target.files[0].name)
    }

    const { register:register, handleSubmit:handleSubmit, formState: { errors },reset,setValue, getValues}: any = useForm({
        mode: "onChange"
    });


    const apiname = process.env.REACT_APP_CITY_API?.toString()


    const  { mutate: brandPost, data,isSuccess,error,isError}:any = useCreate(apiname)

    const [valueDistrict, setValueDistrict]: any = useState();
  const [valueState, setValueState]: any = useState();
  const [valueCountry, setValueCountry]: any = useState();
  const [searchTerm, setSearchTerm] = useState('')
  const [optionsDistrict, setOptionsDistrict]: any = useState([]);
  const [optionsState, setOptionsState]: any = useState([]);
  const [optionsCountry, setOptionsCountry]: any = useState([]);
  const [isLoadingValue, setIsLoadingValue] = useState(false);
  const [countryName, setCountryName] = useState("")
  const [talukaName, settalukaName] = useState("")
  const [districtName, setDistrictName] = useState("")
  const [stateName, setStateName] = useState("")
  const [editName, setEditName] = useState("")
  const [countryValue, setCountryValue] = useState("")


    const [valueDistrictCreatable, setValueDistrictCreatable] = useState<Option | null>();


    const apinameForDistrict = process.env.REACT_APP_DISTRICT_API?.toString()
  
    const { mutate: mutateDistrict, data: dataDistrict, isError: DistrictIsError, isLoading: DistrictLoading, isSuccess: DistrictSuccess, error: DistrictError }: any = useCreate(apinameForDistrict)
  
    const [byDistrict, setByDistrict] = useState()
    const { data: DistrictData, refetch: machineNameRefetch } = useFetchDistrictDropDown(byDistrict)
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
      setOptionsDistrict(DistrictData)
    }, [DistrictData])
  
  
    useEffect(() => {
      if (searchTerm?.length >= 1) {
        machineNameRefetch()
      }
    }, [])


    const handleChangeMachineName = (e: any) => {


        // machineNameRefetch()
    
        // setForDistrict(true)
        // setForState(false)
        // setForTaluka(false)
    
        // setApiName(apinameForDistrict)
        // setDeleteApiName(apinameForDistrict)
        // setEditId(e?.value)
        // setEditName(districtName)
        // setEditData(DistrictData)
        // setValueDistrict(e)
        // setRenderKey(prevKey => prevKey + 1);
    
        setValue("district", `${e?.value}`)
        setValueDistrictCreatable(null)
        setByTaluka(e?.value)
        setDistrictValue(e?.value)
        setDistrictName(e?.label)
      }
    
    
      const handleCreate = (inputValue: string) => {
    
        inputValue = inputValue.toLowerCase()
          .split(' ')
          .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
          const newOption = createOptionDistrict(inputValue);
          setIsLoadingValue(false);
    
    
          mutateDistrict({ name: newOption.name, status: newOption.status, state: stateValue, country: countryValue })
          setOptionsDistrict((prev: any) => [...prev, newOption]);
          setValueDistrictCreatable(newOption);
        }, 0);
    
    
      };
    
      useEffect(() => {
        (async () => {
          if (DistrictSuccess && optionsDistrict) {
            await machineNameRefetch();
            setValue("district", DistrictData[0]?.value)
            setDistrictValue(DistrictData[0]?.value)
          }
    
        })();
    
        return () => {
        };
      }, [DistrictSuccess, DistrictData]);
    
    
    
    
      const [valueTaluka, setValueTaluka]: any = useState();
      const [optionsTaluka, setOptionsTaluka]: any = useState([]);
      const [valueTalukaCreatable, setValueTalukaCreatable] = useState<Option | null>();
    
    
    
    
    
      const apinameForTaluka = process.env.REACT_APP_TALUKA_API?.toString()
    
      const { mutate: mutateTaluka, data: dataTaluka, isError: TalukaIsError, isLoading: TalukaLoading, isSuccess: TalukaSuccess, error: TalukaError }: any = useCreate(apinameForTaluka)
    
      const [byTaluka, setByTaluka] = useState()
      const { data: TalukaData, refetch: TalukaRefetch } = useFetchTalukaDropDown(byTaluka)
      // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
      useEffect(() => {
        setOptionsTaluka(TalukaData)
      }, [TalukaData])
    
    
      //   useEffect(() => {
      //       if (searchTerm?.length >= 1) {
      //           machineNameRefetch()
      //       }
      //   }, [])
    
      const [districtValue, setDistrictValue]: any = useState()
    
    
      const [talukaValue, setTalukaValue]: any = useState()
    
    
      const handleChangeTaluka = (e: any) => {
    
        // TalukaRefetch()
    
        // setForTaluka(true)
        // setForState(false)
        // setForDistrict(false)
    
        // setApiName(apinameForTaluka)
        // setDeleteApiName(apinameForTaluka)
        // setEditId(e?.value)
        // setEditName(e?.label)
        // setEditData(TalukaData)
        // // setValueDistrict(e)
        // setRenderKey(prevKey => prevKey + 1);
    
        setValue("taluka", `${e?.value}`)
        setValueTalukaCreatable(null)
        setTalukaValue(e?.value)
        setByPincode(e?.value)
        setValueTaluka(e)
      }
    
      const handleCreateTaluka = (inputValue: string) => {
    
        inputValue = inputValue.toLowerCase()
          .split(' ')
          .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
          const newOption = createOptions(inputValue);
          setIsLoadingValue(false);
    
    
          mutateTaluka({ name: newOption.name, status: newOption.status, state: stateValue, country: countryValue, district: districtValue })
          setOptionsTaluka((prev: any) => [...prev, newOption]);
          setValueTalukaCreatable(newOption);
        }, 0);
    
    
      };
    
      useEffect(() => {
        (async () => {
          if (TalukaSuccess && optionsTaluka && dataTaluka) {
            await TalukaRefetch();
            setValue("taluka", TalukaData[0].value)
          }
    
        })();
    
        return () => {
        };
      }, [TalukaSuccess, TalukaData]);
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
      const [valuePincode, setValuePincode]: any = useState();
      const [optionsPincode, setOptionsPincode]: any = useState<Option[] | null>([]);
      const [valuePincodeCreatable, setValuePincodeCreatable]: any = useState<Option[] | null>([]);
    
    
      const apinameForPincode = process.env.REACT_APP_PINCODE_API?.toString()
    
      const { mutate: mutatePincode, data: dataPincode, isError: PincodeIsError, isLoading: PincodeLoading, isSuccess: PincodeSuccess, error: PincodeError }: any = useCreate(apinameForPincode)
    
      const [byPincode, setByPincode] = useState()
      const { data: PincodeData, refetch: PincodeRefetch } = useFetchPincodeDropDown(byPincode)
      // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
      useEffect(() => {
        setOptionsPincode(PincodeData)
      }, [PincodeData])
    
    
      //   useEffect(() => {
      //       if (searchTerm?.length >= 1) {
      //           machineNameRefetch()
      //       }
      //   }, [])
    
      const [pincodeValue, setPincodeValue]: any = useState([])
    
    
      const handleChangePincode = (e: any) => {
    
    
    
    
        let pincodes = e?.map((e: any) => {
          return e.value
        })
    
    
        setValue("pincode", pincodes)
        setValuePincodeCreatable(e)
      }
    
    
      const handleCreatePincode = async (inputValue: any) => {    
        inputValue = inputValue.toLowerCase()
          .split(' ')
          .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');
        setIsLoadingValue(true);
    
        const newOption = createOptionsPincode(inputValue);
        setIsLoadingValue(false);
    
    
    
    
        if (byTaluka) {
          await mutatePincode({ pincode: newOption.pincode, status: newOption.status, state: stateValue, country: countryValue, district: districtValue, taluka: talukaValue })
    
        } else {
          await mutatePincode({ pincode: newOption.pincode, status: newOption.status, state: stateValue, country: countryValue, district: districtValue })
        }
    
      };
    
    
    
    
      useEffect(() => {
        (async () => {
          if (PincodeSuccess && optionsPincode) {
            await dataPincode
            let ok = getValues('pincode'); // Get the existing values
            ok = Array.isArray(ok) ? ok : []; // Initialize as an array if not already
    
            let ctx = {
              deleted: dataPincode.data.is_deleted,
              label: dataPincode.data.pincode,
              status: dataPincode.data.status,
              value: dataPincode.data.id,
            }
    
    
            setOptionsPincode((prev: any) => [...prev, ctx]);
            setValuePincodeCreatable((prevSelected: any) => [...prevSelected, ctx]);
    
            let check = [...ok, dataPincode.data.id];
            setValue("pincode", check); // Update the 'pincode' field value
    
    
    
          }
    
        })();
    
        return () => {
    
    
        };
      }, [PincodeSuccess, dataPincode]);
      const [editId, setEditId]: any = useState()

      const apinameForCountry = process.env.REACT_APP_COUNTRY_API?.toString()
      const [valueCountryCreatable, setValueCountryCreatable] = useState<Option | null>();
      const [stateValue, setStateValue] = useState("")
  const [valueStateCreatable, setValueStateCreatable] = useState<Option | null>();

  const apinameForState = process.env.REACT_APP_STATE_API?.toString()

  const { mutate: mutateState, data: dataState, isError: StateIsError, isLoading: StateLoading, isSuccess: StateSuccess, error: StateError }: any = useCreate(apinameForState)

  const [byState, setByState] = useState()
  const { data: StateData, refetch: StateRefetch } = useFetchStateDropDown(byState)
  // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
  useEffect(() => {
    setOptionsState(StateData)
  }, [StateData])

  const handleChangeState = (e: any) => {

    // StateRefetch()

    // setForState(true)
    // setForDistrict(false)
    // setForTaluka(false)

    // setApiName(apinameForState)
    // setDeleteApiName(apinameForState)
    // setEditId(e?.value)
    // setEditName(stateName)
    // setEditData(StateData)
    setValueState(e)

    setValue("state", `${e?.value}`)
    setValueStateCreatable(null)
    setByDistrict(e?.value)
    setStateValue(e?.value)
    setStateName(e?.label)

    // setRenderKey(prevKey => prevKey + 1);

  }

  useEffect(() => {
    if (valueCountry || valueCountryCreatable || valueState || valueStateCreatable) {
      setEditId(editId)
    }
  }, [valueCountry,
    valueCountryCreatable,
    valueState,
    valueStateCreatable])


  const handleCreateState = (inputValue: string) => {

    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setIsLoadingValue(true);
    setTimeout(() => {
      const newOption = createOptionState(inputValue);
      setIsLoadingValue(false);


      mutateState({ name: newOption.name, status: newOption.status, country: countryValue })
      setOptionsState((prev: any) => [...prev, newOption]);
      setValueStateCreatable(newOption);
    }, 0);


  };

  useEffect(() => {
    (async () => {
      if (StateSuccess && optionsState) {
        await StateRefetch();
        setValue("state", StateData[0]?.value)
        setStateValue(StateData[0]?.value)
      }

    })();

    return () => {
    };
  }, [StateSuccess, StateData]);
  const { mutate: mutateCountry, data: dataCountry, isError: CountryIsError, isLoading: CountryLoading, isSuccess: CountrySuccess, error: CountryError }: any = useCreate(apinameForCountry)


  const { data: CountryData, refetch: CountryRefetch } = useFetchCountryDropDown()
  // const [optionsCavityName, setOptionsCavityName] = useCountry(cavityNameData)
  useEffect(() => {
    setOptionsCountry(CountryData)
  }, [CountryData])
  const [CountryCode, setCountryCode]:any = useState(0);

  const handleChangeCountry = (e: any) => {

    // CountryRefetch()

    // setValueState(null)
    // setValueStateCreatable(null)

    // setForState(false)
    // setForDistrict(false)
    // setForTaluka(false)

    // setApiName(apinameForCountry)
    // setDeleteApiName(apinameForCountry)
    setEditId(e?.value)
    setEditName(countryName)
    // setEditData(CountryData)
    // // setOpenEditModel(false)
    setCountryCode(e?.countryCode)
    setValue("country_code_mobile",e?.countryCode)
    setValue("country", `${e?.value}`)
    setValueCountryCreatable(null)
    setByState(e?.value)
    setValueCountry(e)
    setCountryValue(e?.value)
    setCountryName(e?.label)
    // setRenderKey(prevKey => prevKey + 1);

    // setValueCountry(e)
    
  }


  const handleCreateCountry = (inputValue: string) => {
    // setOpenEditModel(false)
    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setIsLoadingValue(true);
    setTimeout(() => {
      const newOption = createOptionCountry(inputValue);
      setIsLoadingValue(false);


      mutateCountry({ name: newOption.name, status: newOption.status })
      setOptionsCountry((prev: any) => [...prev, newOption]);
      setValueCountryCreatable(newOption);
    }, 0);


  };



  const [villageValue, setVillageValue] = useState('')
  const [cityValue, setCityValue] = useState('')

  const handleVillageChange = (e: any) => {
    e.target.value = e.target.value.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');

    setVillageValue(e.target.value)
    setValue('village_name', e.target.value)
  }

  const handleCityChange = (e: any) => {
    e.target.value = e.target.value.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setCityValue(e.target.value)
    setValue('city_name', e.target.value)
  }
    


    useEffect(()=>{
        if(isSuccess){
            setSubmitted(true)
            setOpen(false)
            reset()
            setForGSTValue(null)
        }
        refetchForm()
            },[isSuccess,data])


   
    const [forGST,setForGSTValue]:any = useState([])

 
const handleReset = () => {
    reset()
    setForGSTValue(null)
    }

    const onSubmit = (branddetail: any) => {
        if (!valueTaluka) {
            delete data?.taluka
          }
      
          if (villageValue) {
            delete data?.city_name
          }
      
          if (cityValue) {
            delete data?.village_name
          }
        const formData = new FormData();

        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else if (Array.isArray(value) && key === "pincode") {
              value.forEach((val, index) => formData.append(`pincode`, val))
          }


            else if (key === "logo" || key === "logo_pdf"){
                if (typeof value === 'object' && value !== null && formvalue.length > 0) {
                    formData.append(key, formvalue[0])
                }
                else {
                    formData.append(key, "")
                }
            }

            


        }
        brandPost(formData);
       
    }
    



    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);




  


    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }


  return (
    <>
    <Modal  
    
    size='xl'
    
    show={open} onHide={()=>{
        setShow(false)
        reset()
        setOpen(false)}} aria-labelledby="contained-modal-title-vcenter"
centered    >
  <Modal.Header closeButton>
    <Modal.Title style={{fontSize:'16px'}}>City</Modal.Title>
  </Modal.Header>
  <Modal.Body> 
     
  <form 
                             ref={focus}
                             onKeyUp={event => onEnterKey(event)}
                            onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{maxHeight:"80vh"}}>
                                {show && showmessage === "success" ? <Alert color={showmessage} message={"Successfully Added"} /> : ""}
                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}
                               
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Country Name<span style={{ color: 'red' }}>*</span></label>
                          <CreatableSelect
                            isClearable={() => {
                              setValueCountry(null)
                              
                              setValue(null)
                            }}
                            {...register("country", { required: { value: true, message: "Country Name is required" } })}
                            placeholder={'Select Country Name'}
                            options={optionsCountry}
                            onCreateOption={handleCreateCountry}
                            onChange={handleChangeCountry}                            
                            // onMenuOpen={() => {
                            //   CountryRefetch()
                            //   // setIsCountryOpen(true)
                            // }}
                            // onMenuClose={() => setIsCountryOpen(false)}
                            value={valueCountryCreatable == null ? valueCountry : valueCountryCreatable}
                            components={customCreatableIndicator}
                          // defaultValue={{value:1,label:"India"}}  

                          // isOptionSelected={(optionsCountry:any)=>
                          //         {
                          //             if(optionsCountry.label === "India"){
                          //             return optionsCountry
                          //             }
                          //            }}

                          />
                          <span className='text-red w-100'>{errors?.country?.message}</span>
                        </div>

                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">State Name <span style={{ color: 'red' }}>*</span></label>
                          <CreatableSelect
                            isClearable={() => {
                              setValueState(null)
                              setValue(null)
                            }}
                            {...register("state", { required: { value: true, message: "State Name is required" } })}
                            placeholder={'Select State Name'}
                            isDisabled={!byState}
                            options={optionsState}
                            // menuIsOpen={true}
                            onMenuOpen={() => {
                              StateRefetch()
                              // setIsStateOpen(true)
                            }}
                            // onMenuClose={() => setIsStateOpen(false)}
                            onCreateOption={handleCreateState}
                            onChange={handleChangeState}
                            value={valueStateCreatable == null ? valueState : valueStateCreatable}
                            components={customCreatableIndicator}
                          // defaultValue={{value:7,label:"Gujarat"}}
                          />
                          <span className='text-red w-100'>{errors?.state?.message}</span>

                        </div>

                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">District Name <span style={{ color: 'red' }}>*</span></label>
                          <CreatableSelect
                            isClearable={() => {
                              setValueDistrict(null)
                              setValue(null)
                            }}
                            {...register("district", { required: { value: true, message: "District Name is required" } })}
                            placeholder={'Select District Name'}
                            isDisabled={!byDistrict}
                            options={optionsDistrict}
                            onMenuOpen={() => {
                              machineNameRefetch()
                              // setIsDistrictOpen(true)
                            }}
                            // onMenuClose={() => setIsDistrictOpen(false)}
                            // onMenuOpen={()=>setIsDistrictOpen(true)}
                            // onMenuClose={()=>setIsDistrictOpen(false)}
                            onCreateOption={handleCreate}
                            onChange={handleChangeMachineName}
                            components={customCreatableIndicator}
                            value={valueDistrictCreatable == null ? valueDistrict : valueDistrictCreatable}

                          />
                          <span className='text-red w-100'>{errors?.district?.message}</span>

                        </div>



                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Taluka Name</label>
                          {/* <div className="input-group">
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder="Taluka Name" {...register("taluka", {onChange:handleValue, required: { value: false, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.taluka?.message}</span> */}




                          <CreatableSelect
                            isClearable={() => {
                              setValueTaluka(null)
                              setValue(null)
                            }}
                            {...register("taluka", { required: { value: false, message: "Taluka Name is required" } })}
                            placeholder={'Select Taluka Name'}
                            isDisabled={!byTaluka}
                            options={optionsTaluka}
                            onMenuOpen={() => {
                              TalukaRefetch()
                            }}
                            onCreateOption={handleCreateTaluka}
                            onChange={handleChangeTaluka}
                            components={customCreatableIndicator}
                            value={valueTalukaCreatable == null ? valueTaluka : valueTalukaCreatable}

                          />
                          <span className='text-red w-100'>{errors?.taluka?.message}</span>
                        </div>




                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Village Name</label>
                          <div className="input-group">
                            <input type="text" className="form-control" id="exampleInputBrand"
                              value={villageValue}
                              disabled={cityValue}
                              placeholder="Village Name" {...register("village_name", { onChange: handleVillageChange, required: { value: false, message: "Field is required" } })} />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              // style={{backgroundColor:"blue",color:"green"}} 
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.village_name?.message}</span>

                        </div>
                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">City Name</label>
                          <div className="input-group">
                            <input type="text" className="form-control" id="exampleInputBrand"
                              value={cityValue}
                              placeholder="City Name"
                              disabled={villageValue}
                              {...register("city_name", { onChange: handleCityChange, required: { value: false, message: "Field is required" } })} />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              // style={{backgroundColor:"blue",color:"green"}} 
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.city_name?.message}</span>

                        </div>

                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Pin Code Number <span style={{ color: 'red' }}>*</span></label>
                          {/* <div className="input-group">
                                                    <input type="number" className="form-control" id="exampleInputBrand" placeholder="City Pin Code" {...register("pincode", { required: { value: true, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.pincode?.message}</span> */}


                          <CreatableSelect
                            isClearable={() => {
                              setValuePincode(null)
                              setValue(null)
                            }}
                            {...register("pincode", { required: { value: true, message: "Pin Code Number is required" } })}
                            placeholder={'Select Pin Code Number'}
                            isDisabled={!countryValue|| !stateValue||!districtValue}
                            options={optionsPincode}
                            onCreateOption={handleCreatePincode}
                            onChange={handleChangePincode}
                            value={valuePincodeCreatable==null?valuePincode:valuePincodeCreatable}
                            // value={valuePincodeCreatable}
                            components={customCreatableIndicator}
                            // getOptionLabel={(e: any) => e.label}
                            // getOptionValue={(e: any) => e.value}
                            isMulti

                          />
                          <span className='text-red w-100'>{errors?.pincode?.message}</span>
                        </div>







                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Country Mobile Code</label>
                          <div className="input-group">
                            <input type="text" className="form-control" id="exampleInputBrand" disabled value={CountryCode} placeholder="Country Mobile Code" {...register("country_code_mobile", { onChange: handleValue, required: { value: false, message: "Field is required" } })} />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              // style={{backgroundColor:"blue",color:"green"}} 
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.country_code_mobile?.message}</span>

                        </div>


                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Re-Mark</label>
                          <div className="input-group">
                            <input type="text" className="form-control" id="exampleInputBrand" placeholder="Re-Mark" {...register("remark", { onChange: handleValue, required: { value: false, message: "Field is required" } })} />
                            <div className="input-group-append" >
                              <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                              // style={{backgroundColor:"blue",color:"green"}} 
                              /></span>

                            </div>
                          </div>
                          <span className='text-red w-100'>{errors?.remark?.message}</span>

                        </div>




                        {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Responsible Person</label>
                                                    <select className="form-select" {...register("four", { required: { value: true, message: "Field is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Mr...</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.four?.message}</span>

                                                </div> */}


                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">Status <span style={{ color: 'red' }}>*</span></label>
                          <select className="form-select" {...register("status", { required: { value: true, message: "Field is required" } })}>

                            <option value={""} selected>Select Any</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                          <span className='text-red w-100'>{errors?.status?.message}</span>

                        </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Upload Logo</label>
                                                    <label style={{cursor:"pointer"}}>
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                        <input data-required="image" type="file"  id="file_upload" accept="image/*" className="image-input" data-traget-resolution="image_resolution"  {...register("logo",{onChange:onLogoChange, required:false})} hidden />
                                                    </label>
                                                    <p>{logo}</p>
                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Logo PDF</label>
                                                    <label style={{cursor:"pointer"}}>
                                                    <img src={pdfLogo} alt='' className="uploaded-image" />
                                                    <input data-required="image" type="file"  id="file_upload" accept="application/pdf" className="image-input" data-traget-resolution="image_resolution" {...register("logo_pdf",{onChange:onLogoPDFChange, required:false})} hidden />
                                                    </label>
                                                    <p>{logoPdf}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                   
                                </div>
                            </form>
      
</Modal.Body>
  <Modal.Footer>
  
    <Button variant="info" className="text-white" 
 onClick={handleSubmit(onSubmit)}
 style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
    >
      Submit
    </Button>
    <Button variant="info" className="text-white" style={{ borderColor: "blue", background: "blue", color: "white" }}
 onClick={handleReset}
 >
   Reset
 </Button>
    <Button variant="secondary" style={{ borderColor: "red", background: "red", color: "white" }} onClick={()=>{setOpen(false)}}>
      Cancel
    </Button>
  </Modal.Footer>
</Modal>
    </>
  )
}



