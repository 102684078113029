import { useMutation } from "react-query";
import Axiosinstance from "./Interceptor";

interface datamultidelete {
    deleteMultipleid: Array<any>
    apiname: string | undefined

}

const deletemultipledata = (apiname: string | undefined, temp_data: any) => {
    
    return Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apiname}/deletelist/`, temp_data);
}

export const useDeletemultiple = () => {
    return useMutation((input: datamultidelete) => {
        return deletemultipledata(input.apiname, { id: input.deleteMultipleid })
    }

    )
}
export const useDeletemultipleforce = () => {
    return useMutation((input: datamultidelete) => {
        return deletemultipledataforce(input.apiname, { id: input.deleteMultipleid })
    }

    )
}

const deletemultipledataforce = (apiname: string | undefined, temp_data: any) => {
    return Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apiname}/deletelist/?force=true`, temp_data);
}