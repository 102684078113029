import React, { useEffect, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../components/Header/header.css";
import { useNavigate, useParams } from "react-router-dom";
import { useGstPost } from "../../hooks/GST";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import { useCreate } from "../../hooks/Api";
import axios from "axios";
import Select from "react-select"
import { Alert } from "../../components/Model/Model";

// import { Alert } from "../Model/Model";
// import Select from 'react-select/async';

const Add_StickPacking = () => {
    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_STICK_PACKING_API?.toString()


    const  { mutate: gst, data, isError, isLoading, isSuccess, error }:any = useCreate(apiname)
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue }:any = useForm();
  



   
    useEffect(() => {
        if (data) {

            reset(data)
        }
    }, [data]);
 
const handleReset = () => {
        reset()
    }

    const onSubmit = (branddetail: any) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else
            {
                formData.append(key, formvalue[0])
            }
            if(typeof value === 'object' && value !== null){
                formData.append(key, "")
            }
        }
        gst(formData);
    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listStickPacking', { state: { showStickPackingToast: true }})

        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);



    const handleValue=(e:any)=>{
        e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
    }
    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Stick Packing</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/listColor" className="text-decoration-none text-black">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Masters</a></li>
                                    <li className="breadcrumb-item"><a href="/brandlist" className="text-decoration-none text-black">Add Stick Packing</a></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add GST</span></h5>
                            </div> */}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message.name} /> : ""}

                                    {/* {show && showmessage === "success" && <Alert color={showmessage} message="Brand successfully Updated" />} */}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Stick Packing Type</label>
                                                    <div className="input-group" >
                                                    <input type="text" className="form-control" id="exampleInputBrand" {...register("name",{ required: {onChange:handleValue, value: true, message: "From Date is required" }})} />
                                                    <div className="input-group-append">
                                                            <span className="input-group-text bg-white border-start-0"><RiPencilLine color='green' /></span>
                                                        </div>
                                                        </div>
                                                    <span className='text-red w-100'>{errors?.stick_packing_type?.message}</span>
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Note</label>
                                                    <div className="input-group" >
                                                    <textarea type="text" className="form-control" id="exampleInputBrand"  {...register("note",{ onChange:handleValue,required: { value: true, message: "To Date is required" }})} />
                                                    <div className="input-group-append">
                                                            <span className="input-group-text bg-white border-start-0"><RiPencilLine color='green' /></span>
                                                        </div>
                                                        </div>
                                                    <span className='text-red w-100'>{errors?.note?.message}</span>
                                                </div>


                                               
                                            
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                <a type="button" className="btn border rounded text-white px-4 mt-2 mx-3" onClick={handleSubmit(onSubmit)}>Submit</a>

<button type="button" className="btn border-1 rounded  border-1 px-4 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

<button type="button" className="btn border-1 rounded  px-4 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listStickPacking")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Add_StickPacking