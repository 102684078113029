import React, { useEffect, useRef, useState } from "react";
import "../../../components/Header/header.css"
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import { useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../../hooks/Api";
import { Alert } from "../../../components/Model/Model";
import { async } from "q";
import useFocusOnEnter from "../../../components/focus";
import { Deletesinglewithdependancy } from "../../../components/modal";
import 'bootstrap/dist/css/bootstrap.min.css';
   import Modal from 'react-bootstrap/Modal';
// import { Alert } from "../Model/Model";
// import Select from 'react-select/async';

const Edit_HrDepartment = () => {
    const focus:any = useRef();
    const {onEnterKey} = useFocusOnEnter(focus);
    const { id } = useParams()
    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_HR_DEPARTMENT_API?.toString()
    const { data } = useFetchSingle(id, apiname)
    const [confirmmodal,setConfirmmodal]=useState(false)
    const [valuesofform,setValuesofform]=useState<any>()
    const { mutate, isSuccess, isError, error, data: panniUpdated_response }: any = useUpdateSingle()
    const { mutate:mutateyes, isSuccess:yessuccess, isError:yeserror, error:yeserrordetail, data: yesupdated_response }:any = useUpdateSingleforcewithyes()
    const { mutate:mutateno, isSuccess:nosuccess, isError:noerror, error:noerrordetail, data: noupdated_response }:any = useUpdateSingleforcewithno()

    const { register, handleSubmit, setError, formState: { errors,isDirty }, reset, setValue }: any = useForm();

    const [openEditModel,setOpenEditModel] = useState(false)
    const [expirationDate,setExpirationDate] = useState()
    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")


    useEffect(() => {
        if (data) {

            reset(data)
        }
    }, [data]);

    
        const yesupdatemodal=()=>{
        setConfirmmodal(false);
        mutateyes(valuesofform)
        }
        const cancelupdatemodal=()=>{
           setConfirmmodal(false) ;
        }
    
        useEffect(()=>{
            if(isError)
            
            {
                
                if(error.response.status===405){
                    setConfirmmodal(true)
                }
            }
            else if (isError||yeserror||noerror) {
                setShow(true)
                setshowmessage("danger")
            }
        },[isError,yeserror,noerror])
    //   useEffect(() => {
    //    if (isSuccess||yessuccess||nosuccess) {
    //        setValuesofform(null)
    //        setShow(true)
    //        navigate('/listDepartment', { state: { showEditToast: true } })
    //    }
     
    // }, [data, isSuccess,yessuccess,panniUpdated_response,
    //    nosuccess])

    useEffect(() => {
        if (isSuccess || yessuccess || nosuccess) {
            setValuesofform(null)
            setShow(true)
            navigate('/listDepartment', { state: { showEditToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, isSuccess, yessuccess, isError,error,
        nosuccess])

       useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    const onSubmit = (branddetail: any) => {
        
        delete branddetail?.department_code
        

        const formData = new FormData();
        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
       
            if (typeof value === 'object' && value !== null) {
                formData.append(key, "")
            }
        }
        let data = {
            apiname: apiname,
            id: id,
            data: formData
        }

        mutate(data);
        setValuesofform(data)
    }

    // const [show, setShow] = useState(false)
    // const [showmessage, setshowmessage] = useState("")

    // useEffect(()=> {
    //     if (isSuccess || yessuccess || nosuccess) {
    //         setShow(true)
    //         // setshowmessage("success")
    //          navigate('/listDepartment', { state: { showEditToast: true } })

    //     }
       
    // }, [data, isSuccess, yessuccess,
    //     nosuccess,panniUpdated_response])

   

    const handleValue=(e:any)=>{
        e.target.value = e.target.value.toLowerCase()
    .split(' ')
    .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
    }

    const noupdatemodal=()=>{
        setOpenEditModel(true)
      }


      const handleExpirationDate=(e:any)=>{
    setExpirationDate(e.target.value)
    setValue("expiration_date", e.target.value)
}

const handleConfirm=(data:any)=>{
         
    delete data?.department_code
    let ok = {
        id:id,
        apiname:apiname,
        data:data,
    }

    mutateno(ok)
    setConfirmmodal(false);
    setOpenEditModel(false)
}

console.log(error,"error");

    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>All Department</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">HR</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listDepartment" className="text-decoration-none text-black">Department</Link></li>
                                    <li className="breadcrumb-item">Edit All Department</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add GST</span></h5>
                            </div> */}
                            <form 
                            ref={focus}
                            onKeyUp={event => onEnterKey(event)}
                            onSubmit={handleSubmit(onSubmit)}
                            >
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>
                                    {/* {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message[0]} /> : ""} */}

                                    {show && showmessage === "danger" ? (<Alert color={showmessage} message={error?.response?.data?.message}/> ) : ("")}
                                    {/* {show && showmessage === "success" && <Alert color={showmessage} message="Brand successfully Updated" />} */}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                               
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Department Name <span style={{color:'red'}}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Stick Packing Type" {...register("department", {onChange:handleValue  })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>
                                                        </div>
                                                        
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.department?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span style={{color:'red'}}>*</span></label>
                                                    <select className="form-select" {...register("status")}>
                                                        <option value={""} selected>Select Any</option>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"inactive"}>Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Department Code</label>
                                                    <div className="input-group" >
                                                        <input disabled type="text" className="form-control" id="exampleInputBrand" placeholder="Stick Packing Type" {...register("department_code" )} />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.department_code?.message}</span>
                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                <button 
                                                type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3" 
                                                style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                onClick={handleSubmit(onSubmit)} 
                                                // disabled={!isDirty}
                                                >Submit</button>                                                   
                                                 <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listDepartment")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
                <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
        centered    >
        <Modal.Header closeButton>
          <Modal.Title>Select a Date</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
            
           
            
          

<div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-12 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Choose Date</label>
                                                    <input type="date" className="form-control" id="exampleInputBrand" 
                                                    
                                                    {...register("expiration_date",{onChange:handleExpirationDate})} 
                                                    />
                                                </div>
                                                </div>
           </div>
            
          
            
        </Modal.Body>
        <Modal.Footer>
        

          
                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>


                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>

        </Modal.Footer>
      </Modal>
                <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
yesupdatemodal={yesupdatemodal}
cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />
            </div>
        </div>
    )
}

export default Edit_HrDepartment