import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineEye } from "react-icons/ai";

import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Restricted from "../../Roles/Restricted";
import { useState } from "react";
import { Checkbox } from "../inputcomponent/Checkbox";
import axios from "axios";


const Dispatch_Selection = ({ row, apiname, deletemodalask,permissionClass,setCustomerId,setDos,dos,setIsChecked }: any) => {

    const [show,setShow]:any = useState(false)
    const [ids,setIds]:any = useState([])

    const apinameForDispachOrder = "sales/dispatchOrder";

    const handleSelectCustomersID=(e:any)=>{
       

      
        
    }


   

    // const handleIds = (dos:any,id: any, isChecked: boolean) => {
    //   if (isChecked) {
    //     // Add ID to the list if checked
    //     setCustomerId(id)
    //     // setIds((prevIds:any) => [...prevIds, id])
    //     setIds((prevIds:any) => (!prevIds?.includes(id) ? [...prevIds, id] : prevIds));
    //     setDos((prevDos:any) => [...prevDos, dos]);
    // //     axios.post(`${process.env.REACT_APP_BASE_URL}${apinameForDispachOrder}/search_filter/`,{
    // //       customer_id:id
    // //     })
    // // .then((response) => {
    // //   console.log(response.data.results);
     
    // // })
    // // .catch((error) => {
    // //   console.error('There was an error!', error);  
    // // });
    //   } else {
    //     // Remove ID from the list if unchecked
    //     setCustomerId('')
    //     setIds((prevIds:any) => prevIds?.filter((prevId:any) => prevId !== id))

    //     setDos((prevDos:any) => prevDos.filter((prevItem:any) => prevItem !== id)); // assuming each dos has an id
    //   }
    // }


    const handleIds = (dosID: any, id: string, isChecked: boolean) => {
      if (isChecked) {
          setCustomerId(id);
          setIds((prevIds:any) => (!prevIds?.includes(id) ? [...prevIds, id] : prevIds));
          setDos((prevDos:any) => [...prevDos, dosID]);
          
          setIsChecked(false)
      } else {
          setCustomerId('');
          setIds((prevIds:any) => prevIds?.filter((prevId:any) => prevId !== id));
          setDos((prevDos:any) => prevDos?.filter((prevItem:any) => prevItem !== dosID));
          setIsChecked(true)
      }
  }



  



    // console.log("ids",ids)



  return (
    <>
    <div
        className="vertical-align-center"
        style={{
          display: "flex !important",
          justifyContent: "space-evenly !important",
          
        }}
      >
      
        
      {/* <Restricted
          to={`${permissionClass?.front}.change_${permissionClass?.back}`}
          fallback={""}
        > */}
          {/* <Link
            className="btn btn-primary text-white mx-2"
            to={`/edit${apiname?.split("/").at(-1)}/${Number(row.original.id)}`}
          >
            <FiEdit />
          </Link> */}

<div>
            <Checkbox
  // key={row.original.id}
            // value={row.original?.name?.id}
            // checked={ids?.includes(row.original?.name?.id)}
            // onChange={(e:any) => handleIds(row.original,row.original?.name?.id, e.target.checked)}
            // checked={ids.includes(row.original.id)}
            key={row.original?.id}
            checked={dos?.includes(row.original?.id)}
            onChange={(e: any) => handleIds(row.original?.id, row.original?.name?.id, e.target?.checked)}

              />
          </div>


        {/* </Restricted> */}
       
      </div>
    </>
  );
};

export default Dispatch_Selection;

