import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate, useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import { useFetchAccountCategoryDropDown, useFetchAccountTypeDropDown, useFetchCavityNameDropDown, useFetchMouldDropDown, useFetchSeqDropDown, useFetchSubGroupDropDown, useFetchSuperAccountDropDown } from "../../hooks/DropDown_Api";
import useFocusOnEnter from "../../components/focus";
import CreatableSelect from 'react-select/creatable';

import { Deletesinglewithdependancy } from "../../components/modal";

import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'

import Actions, { Deleteforce, Deletesingle } from "../../components/Cell/Actions";
import { useDelete, useDeletemforce } from "../../hooks/delete";
import Custom_Actions_Buttons from "../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../hooks/deletemultiple";
import { SimpleInput_Edit } from "../../components/Dynamic_Form_Models/SimpleInput_Edit";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";



const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}


interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    superAccountGroup: null,
    status: "active"
});

const Edit_Account = () => {
    const navigate = useNavigate()
    const [openEditDateModel, setOpenEditDateModel] = useState(false)
    const [submittedEdit, setSubmittedEdit] = useState(false)
    const { id } = useParams()
    const focus: any = useRef();
    const [isLoadingValue, setIsLoadingValue] = useState(false);
    const { onEnterKey } = useFocusOnEnter(focus);
    const [optionsCavity, setOptionsCavity]: any = useState([]);
    const [logo, setlogo] = useState(null)
    const [logoPdf, setlogoPdf] = useState(null)
    const [options, setOptions] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [selected, setSelected] = useState("")
    const [sel, setSel] = useState("")

    const [selCavity, setSelCavity] = useState('')
    const [selectedCavity, setSelectedCavity] = useState('')
    // const [openEditModel, setOpenEditModel] = useState(false)
    const [expirationDate, setExpirationDate] = useState()
    const apiname = process.env.REACT_APP_ACCOUNT_TYPE_API?.toString()
    const { data } = useFetchSingle(id, apiname)
    const { mutate, isSuccess, isError, error, data: cavityUpdated_response, isLoading }: any = useUpdateSingle()
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()

    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()





    const apinameSuperAccount = process.env.REACT_APP_SUPER_ACCOUNT_API?.toString()



    const { mutate: mutateSuperAccount, data: dataSuperAccount, isError: isErrorSuperAccount, isLoading: isLoadingSuperAccount, isSuccess: isSuccessSuperAccount, error: errorSuperAccount }: any = useCreate(apinameSuperAccount)



    const [optionsSuperAccount, setOptionsSuperAccount]: any = useState([]);

    const [valueSuperAccount, setValueSuperAccount]: any = useState();
    const [selectedSuperAccount, setSelectedSuperAccount]: any = useState("")


    const [valueSuperAccountCreatable, setValueSuperAccountCreatable] = useState<Option | null>();


    const { data: superAccountData, refetch: superAccountRefetch } = useFetchSuperAccountDropDown()
    useEffect(() => {
        setOptionsSuperAccount(superAccountData)
    }, [superAccountData])







    const handleCreateSuperAccount = async (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');

        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);

            // setValue("name", `${newOption?.value}`)

            mutateSuperAccount({ name: newOption.name, status: newOption.status })


            setOptionsSuperAccount((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValueSuperAccountCreatable(newOption);


        }, 0);




    };

    const [superAccountName, setSuperAccountName] = useState("")

    const[isSuperAccount,setIsSuperAccount] = useState(false)

    const [superAccountId, setSuperAccountId] = useState()

    const handleChangeSuperAccount = (e: any) => {

        superAccountRefetch()
        setIsSuperAccount(false)
    
        setApiName(apinameSuperAccount)
        setDeleteApiName(apinameSuperAccount)
        setEditId(e?.value)
        setEditName(e?.label)
        setEditData(superAccountData)
        setValueSuperAccount(e)


        setValue("superAccountGroup", `${e?.value}`)
        setSuperAccountId(e?.value)
        setValueSuperAccountCreatable(null)
        setSuperAccountName(e?.label)
    }

    


    useEffect(() => {
        (async () => {
            if (isSuccessSuperAccount && optionsSuperAccount && valueSuperAccountCreatable != null) {
                await superAccountRefetch();

                setValue("superAccountGroup", superAccountData[0]?.value)
                setSuperAccountId(superAccountData[0]?.value)
                setSuperAccountName(superAccountData[0]?.label)
            }
        })();

        return () => {
        };
    }, [isSuccessSuperAccount, superAccountData, superAccountName]);




    const apinameSubGroup = process.env.REACT_APP_SUB_GROUP_API?.toString()



    const { mutate: mutateSubGroup, data: dataSubGroup, isError: isErrorSubGroup, isLoading: isLoadingSubGroup, isSuccess: isSuccessSubGroup, error: errorSubGroup }: any = useCreate(apinameSubGroup)



    const [optionsSubGroup, setOptionsSubGroup]: any = useState([]);

    const [valueSubGroup, setValueSubGroup]: any = useState();

    const [selectedSubGroup, setSelectedSubGroup]: any = useState("")



    const [valueSubGroupCreatable, setValueSubGroupCreatable] = useState<Option | null>();



    const { data: subGroupData, refetch: subGroupRefetch } = useFetchSubGroupDropDown(superAccountName)
    useEffect(() => {
        setOptionsSubGroup(subGroupData)
    }, [subGroupData])







    const handleCreateSubGroup = async (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');

        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);

            // setValue("name", `${newOption?.value}`)


            mutateSubGroup({ name: newOption.name, status: newOption.status, superAccountGroup: superAccountId })



            setOptionsSubGroup((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValueSubGroupCreatable(newOption);


        }, 0);




    };

    const handleChangeSubGroup = (e: any) => {


        subGroupRefetch()
        setIsSuperAccount(true)
    
        setApiName(apinameSubGroup)
        setDeleteApiName(apinameSubGroup)
        setEditId(e?.value)
        setEditName(e?.label)
        setEditData(subGroupData)
        setValueSubGroup(e)
        setRenderKey(prevKey => prevKey + 1);

        setValue("subAccountGroup", `${e?.value}`)
        setValueSuperAccountCreatable(null)
        setValueSubGroup(e)
    }

    useEffect(() => {
        (async () => {
            if (isSuccessSubGroup && optionsSubGroup && valueSubGroupCreatable != null) {
                await subGroupRefetch();

                setValue("subAccountGroup", subGroupData[0]?.value)
                //   setValueSubGroup(subGroupData[0]?.value)
                //   setSelectedSubGroup(subGroupData[0]?.value)
            }
        })();

        return () => {
        };
    }, [isSuccessSubGroup, subGroupData]);






    const apinameAccountStatementType = process.env.REACT_APP_ACCOUNT_STATEMENT_TYPE_API?.toString()



    const { mutate: mutateAccountType, data: dataAccountType, isError: isErrorAccountType, isLoading: isLoadingAccountType, isSuccess: isSuccessAccountType, error: errorAccountType }: any = useCreate(apinameAccountStatementType)



    const [optionsAccountType, setOptionsAccountType]: any = useState([]);

    const [valueAccountType, setValueAccountType]: any = useState();
    const [selectedAccountStatementType, setSelectedAccountStatementType]: any = useState("")


    const [valueAccountTypeCreatable, setValueAccountTypeCreatable] = useState<Option | null>();


    const { data: accountTypeData, refetch: accountTypeRefetch } = useFetchAccountTypeDropDown()
    useEffect(() => {
        setOptionsAccountType(accountTypeData)
    }, [accountTypeData])







    const handleCreateAccountType = async (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');

        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);

            // setValue("name", `${newOption?.value}`)

            mutateAccountType({ name: newOption.name, status: newOption.status })


            setOptionsAccountType((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValueAccountTypeCreatable(newOption);


        }, 0);




    };

    const handleChangeAccountType = (e: any) => {

        accountTypeRefetch()
        setIsSuperAccount(false)
    
        setApiName(apinameAccountStatementType)
        setDeleteApiName(apinameAccountStatementType)
        setEditId(e?.value)
        setEditName(e?.label)
        setEditData(accountTypeData)
        setValueAccountType(e)
        setRenderKey(prevKey => prevKey + 1);

        setValue("accountStatementType", `${e?.value}`)
        setValueAccountTypeCreatable(null)
        setValueAccountType(e)
    }

    useEffect(() => {
        (async () => {
            if (isSuccessAccountType && optionsAccountType) {
                await accountTypeRefetch();

                setValue("accountStatementType", accountTypeData[0]?.value)
            }
        })();

        return () => {
        };
    }, [isSuccessAccountType, accountTypeData]);



    const apinameAccountCategory = process.env.REACT_APP_ACCOUNT_CATEGORY_API?.toString()



    const { mutate: mutateAccountCategory, data: dataAccountCategory, isError: isErrorAccountCategory, isLoading: isLoadingAccountCategory, isSuccess: isSuccessAccountCategory, error: errorAccountCategory }: any = useCreate(apinameAccountCategory)



    const [optionsAccountCategory, setOptionsAccountCategory]: any = useState([]);

    const [valueAccountCategory, setValueAccountCategory]: any = useState();
    const [selectedAccountCategory, setSelectedAccountCategory]: any = useState();


    const [valueAccountCategoryCreatable, setValueAccountCategoryCreatable] = useState<Option | null>();


    const { data: accountCategoryData, refetch: accountCategoryRefetch } = useFetchAccountCategoryDropDown()
    useEffect(() => {
        setOptionsAccountCategory(accountCategoryData)
    }, [accountCategoryData])







    const handleCreateAccountCategory = async (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');

        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);

            // setValue("name", `${newOption?.value}`)

            mutateAccountCategory({ name: newOption.name, status: newOption.status })


            setOptionsAccountCategory((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValueAccountCategoryCreatable(newOption);


        }, 0);




    };

    const handleChangeAccountCategory = (e: any) => {
        accountCategoryRefetch()
    setIsSuperAccount(false)

    setApiName(apinameAccountCategory)
    setDeleteApiName(apinameAccountCategory)
    setEditId(e?.value)
    setEditName(e?.label)
    setEditData(accountCategoryData)
    setValueAccountCategory(e)
    

        setValueAccountCategory(e)
        setValue("category", `${e?.value}`)
        setValueAccountCategoryCreatable(null)

        setRenderKey(prevKey => prevKey + 1);
    }

    useEffect(() => {
        (async () => {
            if (isSuccessAccountCategory && optionsAccountCategory) {
                await accountCategoryRefetch();
                setValue("category", accountCategoryData[0]?.value)
            }
            
        })();

        return () => {
        };
    }, [isSuccessAccountCategory, accountCategoryData]);
    const apinameSeq = process.env.REACT_APP_ACCOUNT_SEQ_API?.toString()



    const { mutate: mutateSeq, data: dataSeq, isError: isErrorSeq, isLoading: isLoadingSeq, isSuccess: isSuccessSeq, error: errorSeq }: any = useCreate(apinameSeq)



    const [optionsSeq, setOptionsSeq]: any = useState([]);

    const [valueSeq, setValueSeq]: any = useState();
    const [selectedSeq, setSelectedSeq]: any = useState();


    const [valueSeqCreatable, setValueSeqCreatable] = useState<Option | null>();


    const { data: SeqData, refetch: SeqRefetch } = useFetchSeqDropDown()
    useEffect(() => {
        setOptionsSeq(SeqData)
    }, [SeqData])







    const handleCreateSeq = async (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');

        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);

            // setValue("name", `${newOption?.value}`)

            mutateSeq({ name: newOption.name, status: newOption.status })


            setOptionsSeq((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValueSeqCreatable(newOption);


        }, 0);




    };

    const handleChangeSeq = (e: any) => {

        
    SeqRefetch()
    setIsSuperAccount(false)

    setApiName(apinameSeq)
    setDeleteApiName(apinameSeq)
    setEditId(e?.value)
    setEditName(e?.label)
    setEditData(SeqData)
    setValueSeq(e)
    setRenderKey(prevKey => prevKey + 1);

        setValue("sequence1", `${e?.value}`)
        setValueSeqCreatable(null)
    }



    useEffect(() => {
        (async () => {
          if (isSuccessSeq && optionsSeq) {
            await SeqRefetch();
            setValue("sequence1", SeqData[0]?.value)
          }
         
        })();
    
        return () => {
        };
      }, [isSuccessSeq, SeqData]);


















    useEffect(() => {
        if (data) {
            reset(data)
            // setSelectedCavity(data?.name?.id)
            // setValue("name",data?.name?.id)
            // setValue("mould_usage", data?.mould_usage?.id)
            // setSelected(data?.mould_usage?.id)
            setValue("superAccountGroup", data?.superAccountGroup?.id)
            setSelectedSuperAccount(data?.superAccountGroup?.id)
            setSuperAccountName(data?.superAccountGroup?.name)

            setSuperAccountId(data?.subAccountGroup?.superAccountGroup?.id)

            setValue("subAccountGroup", data?.subAccountGroup?.id)
            setSelectedSubGroup(data?.subAccountGroup?.id)

            setValue("accountStatementType", data?.accountStatementType?.id)
            setSelectedAccountStatementType(data?.accountStatementType?.id)
            setSelectedAccountCategory(data?.category?.id)
            setValue("category",data?.category?.id)
            setSelectedSeq(data?.sequence1?.id)
            setValue("sequence1",data?.sequence1?.id)
        }

    }, [data]);



    const onSubmit = (cavitydetail: any) => {

        const formData = new FormData();
        for (const [key, value] of Object.entries(cavitydetail)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            // else if (key === "image" || key === "pdf") {
            //     if (typeof value === 'object' && value !== null && formvalue.length > 0) {
            //         formData.append(key, formvalue[0])
            //     }
            //     else {
            //         formData.append(key, "")
            //     }
            // }
        }
        let data = {
            apiname: apiname,
            id: id,
            data: formData
        }
        mutate(data);
        setValuesofform(data)


    }




    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess || yessuccess || nosuccess) {
            setValuesofform(null)
            setShow(true)
            navigate('/listAccountType', { state: { showEditToast: true } })
        }

    }, [data, isSuccess, yessuccess,
        nosuccess, cavityUpdated_response])

    useEffect(() => {
        if (isError) {
            if (error.response.status === 405) {
                setConfirmmodal(true)
            }
        }
        else if (isError || yeserror || noerror) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isError, yeserror, noerror])
    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);







    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }
    const noupdatemodal = () => {
        setOpenEditDateModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {

        delete data.created_at
        delete data.created_by
        let ok = {
            id: id,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditDateModel(false)
    }


    const handleUpper = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }







    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditModel, setOpenEditModel] = useState(false)
    const [editName, setEditName] = useState("")
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
      const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
      const [apiName, setApiName]: any = useState()
  
  
      const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
      const [confirmforcemodal, setConfirmforcemodal] = useState(false);
  
      const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
      } = useDelete();
      const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
      } = useDeletemultiple();
      const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
      } = useDeletemultipleforce();
      const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
      } = useDeletemforce();
    
  
  
  
    const handleRefetch = () => {
        if (apiName === apinameAccountCategory) {
          accountCategoryRefetch()
        }
          if (apiName === apinameSuperAccount) {
          superAccountRefetch()
        } 
         if(apiName === apinameSubGroup){
            subGroupRefetch()
          } 
           if(apiName === apinameAccountStatementType){
            accountTypeRefetch()
          } 
           if(apiName === apinameSeq){
            SeqRefetch()
          } 
      // else if(apiName === apinameForTaluka){
      //   TalukaRefetch()
      // }
      }
  
  
      useEffect(() => {
        if (OpenEditModel && valueAccountCategory && apiName === apinameAccountCategory) {
          setValueAccountCategory(null)
          setValueAccountCategoryCreatable(null)
        }
        if (OpenEditModel && valueSuperAccount && apiName === apinameSuperAccount) {
          setValueSuperAccount(null)
          setValueSuperAccountCreatable(null)
        }
    
        if (OpenEditModel && valueSubGroup && apiName === apinameSubGroup) {
          setValueSubGroup(null)
          setValueSubGroupCreatable(null)
        }
  
        if (OpenEditModel && valueAccountType && apiName === apinameAccountStatementType) {
          setValueAccountType(null)
          setValueAccountTypeCreatable(null)
        }
  
        if (OpenEditModel && valueSeq && apiName === apinameSeq) {
          setValueSeq(null)
          setValueSeqCreatable(null)
        }
    
        // if (OpenEditModel && valueTaluka && apiName === apinameForTaluka) {
        //   setValueTaluka(null)
        //   setValueTalukaCreatable(null)
        // }
    
        if(deletesucess && apiName === apinameAccountCategory){
          setValueAccountCategory(null)
          setValueAccountCategoryCreatable(null)
        }
    
        if(deletesucess && apiName === apinameSuperAccount){
          setValueSuperAccount(null)
          setValueSuperAccountCreatable(null)
        }
    
        if(deletesucess && apiName === apinameSubGroup){
          setValueSubGroup(null)
          setValueSubGroupCreatable(null)
        }
    
        if(deletesucess && apiName === apinameAccountStatementType){
          setValueAccountType(null)
          setValueAccountTypeCreatable(null)
        }
  
        if(deletesucess && apiName === apinameSeq){
          setValueSeq(null)
          setValueSeqCreatable(null)
        }
      }, [OpenEditModel, apiName, deletesucess])
  
  
      const [isExist, setIsExist] = useState(true)
  
  
      const CustomOption = ({ innerProps, label, value }: any) => (
    
    
        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
          <span>{label}</span>
          <div style={{ float: "right", justifyContent: "end" }}>
    
    
    
            {isExist ?
    
              <Custom_Actions_Buttons 
              // key={renderKey} 
              value={value}

                apiname={deleteApiName}
                mutate={singleDelete}
                // masterName={addButtonName}
                deleteid={deleteid}
                handleDeletedforce={handleDeletedforce}
                closereconfirmdelete={closereconfirmdelete}
                deletemodalask={deletemodalask}
              setEditOpen={setOpenEditModel}
              // permissionClass={permissionClass}
              />
              : ""}
    
          </div>
        </div>
    
    
      )
  
  
      const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
      }
  
  
   
      useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
      }, [deletesucess, editData?.results]);
      useEffect(() => {
        return () => {
          setDeleteid(undefined);
        };
      }, []);
      const handleConfirmDelete = () => {
        setDeleteConfirm(true);
      };
      const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
      };
    
      const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
      };
      const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
      };
      const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
      };
      const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
          deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
      };
      useEffect(() => {
        if (forcefullysuccess) {
          setShow(true);
          setshowmessage("success");
        } else if (forcefullyerror) {
          setShow(true);
          setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
          setShow(true);
          setshowmessage("success");
        } else if (forcefullydeleteerror) {
          setShow(true);
          setshowmessage("danger");
        }
      }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
      ]);
    
      useEffect(() => {
        if (deleteMultipleSuccess) {
          const showdepenedancymsg = deleteMultiple?.data?.data;
          const dependacyfound = showdepenedancymsg?.filter((color: any) => {
            if (
              color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
            ) {
              return color;
            }
          });
          if (dependacyfound?.length === deleteMultipleid?.length) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
          } else {
            setConfirmforcemodal(true);
          }
        } else if (deleteMultipleIsError) {
          setShow(true);
          setshowmessage("danger");
        } else if (deletesucess) {
          if (deletedata.data?.data?.length > 0) {
            setConfirmforcemodal(true);
          } else if (deleteid) {
            setShow(true);
            setshowmessage("success");
            deleteforce({ deleteid, apiname: deleteApiName });
          }
        }
      }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);
    
      const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
      };

      useEffect(()=>{
        if(!OpenEditModel){
          setRenderKey(prevKey => prevKey + 1);
        }
      },[OpenEditModel])
    

    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Account</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Masters</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listAccountType" className="text-decoration-none text-black">Account</Link></li>
                                    <li className="breadcrumb-item active">Edit Account</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Cavity</span></h5>
                            </div> */}
                            <form ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" 
                                // style={{ maxHeight: "80vh", overflow: "auto" }}
                                >

                                    {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message?.name} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Account Group<span style={{ color: "red" }}>*</span></label>
                                                    {/* <div className="input-group" data-colorpicker-id={2}>
                                               <input type="text" className="form-control" id="exampleInputBrand" placeholder="Accounts Category" 
                                               {...register("category", { onChange:handleUpper, required: { value: true, message: "Accounts Category is required" } })} 
                                               />
                                               <div className="input-group-append" >
                                                       <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                   </div>
                                                   </div> */}
                                                    <CreatableSelect
                                                        {...register("category", { required: { value: true, message: "Account Group is required" } })}
                                                        isClearable={() => {
                                                            setValueAccountType(null)
                                                            setValue(null)
                                                        }}
                                                        placeholder={'Account Group'}
                                                        options={optionsAccountCategory}
                                                        onCreateOption={handleCreateAccountCategory}
                                                        onMenuOpen={() => { accountCategoryRefetch() }}
                                                        components={customCreatableIndicator}
                                                        // onMenuOpen={() => setIsValueAccountCategory(true)}
                                                        // onMenuClose={() => setIsValueAccountCategory(true)}
                                                        onChange={handleChangeAccountCategory}
                                                        // value={valueAccountCategoryCreatable == null ? valueAccountCategory : valueAccountCategoryCreatable}
                                                        value={valueAccountCategoryCreatable == null ? valueAccountCategory == null ? optionsAccountCategory?.filter((obj: any) => obj.value === selectedAccountCategory) : valueAccountCategory : valueAccountCategoryCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.category?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Super Account Group<span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect

                                                        // isClearable={()=>setValueSuperAccount(null)}
                                                        {...register("superAccountGroup", { required: { value: true, message: "Field is required" } })}
                                                        isClearable={false}
                                                        components={customCreatableIndicator}
                                                        placeholder={'Select'}
                                                        options={optionsSuperAccount}
                                                        onMenuOpen={() => { superAccountRefetch() }}
                                                        onCreateOption={handleCreateSuperAccount}
                                                        onChange={handleChangeSuperAccount}
                                                        value={valueSuperAccountCreatable == null ? valueSuperAccount == null ? optionsSuperAccount?.filter((obj: any) => obj.value === selectedSuperAccount) : valueSuperAccount : valueSuperAccountCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.superAccountGroup?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Sub Group<span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        {...register("subAccountGroup", { required: { value: true, message: "Field is required" } })}
                                                        isClearable={false}
                                                        placeholder={'Select'}
                                                        options={optionsSubGroup}
                                                        isDisabled={!superAccountName}
                                                        onMenuOpen={() => { subGroupRefetch() }}
                                                        onCreateOption={handleCreateSubGroup}
                                                        onChange={handleChangeSubGroup}
                                                        value={valueSubGroupCreatable == null ? valueSubGroup == null ? optionsSubGroup?.filter((obj: any) => obj.value === selectedSubGroup) : valueSubGroup : valueSubGroupCreatable}
                                                        components={customCreatableIndicator}


                                                    />
                                                    <span className='text-red w-100'>{errors?.subAccountGroup?.message}</span>
                                                </div>




                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Account Category<span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        {...register("accountStatementType", { required: { value: true, message: "Field is required" } })}
                                                        isClearable={false}
                                                        placeholder={'Select'}
                                                        options={optionsAccountType}
                                                        onCreateOption={handleCreateAccountType}
                                                        onMenuOpen={() => { accountTypeRefetch() }}
                                                        onChange={handleChangeAccountType}
                                                        value={valueAccountTypeCreatable == null ? valueAccountType == null ? optionsAccountType?.filter((obj: any) => obj.value === selectedAccountStatementType) : valueAccountType : valueAccountTypeCreatable}
                                                        components={customCreatableIndicator}

                                                    />
                                                    <span className='text-red w-100'>{errors?.accountStatementType?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Seq - 1</label>
                                                    {/* <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="seq-1"
                                                            {...register("sequence1", { onChange: handleUpper, required: { value: false, message: "Field is required" } })}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div> */}
                                                    <CreatableSelect
                                                        {...register("sequence1", { required: { value: false, message: "Account Type is required" } })}
                                                        isClearable={() => {
                                                            setValueAccountType(null)
                                                            setValue(null)
                                                        }}
                                                        placeholder={'Seq-1'}
                                                        options={optionsSeq}
                                                        onCreateOption={handleCreateSeq}
                                                        onMenuOpen={() => { accountTypeRefetch() }}
                                                        components={customCreatableIndicator}
                                                        // onMenuOpen={() => setIsValueSeq(true)}
                                                        // onMenuClose={() => setIsValueSeq(true)}
                                                        onChange={handleChangeSeq}
                                                        // value={valueSeqCreatable == null ? valueSeq : valueSeqCreatable}
                                                        value={valueSeqCreatable == null ? valueSeq == null ? optionsSeq?.filter((obj: any) => obj.value === selectedSeq) : valueSeq : valueSeqCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.sequence1?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Seq - 2</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="seq-2"
                                                            {...register("sequence2", { onChange: handleUpper, required: { value: false, message: "Field is required" } })}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.sequence2?.message}</span>
                                                </div>






                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>
                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        onClick={handleSubmit(onSubmit)} disabled={isLoading}>Submit</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listAccountType")}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
            <Modal show={openEditDateModel} onHide={() => setOpenEditDateModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                                {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditDateModel(false) }}>Close</button>
                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>
                </Modal.Footer>
            </Modal>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />






                 {/* Edit Modal HTML */}
        <Modal
        show={deleteConfirm}
        onHide={() => setDeleteConfirm(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete {""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            {" "}
            <span className="text-center">Sure you want to delete it?</span>
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setDeleteConfirm(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="info"
            className="text-white"
            onClick={handleMultipleDeleted}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Deleteforce
        key={renderKey}
        handleDeletedforce={handleDeletedforce}
        reconfirmdelete={confirmforcemodal}
        masterName={""}
        closereconfirmdelete={closereconfirmdelete}
        referance={
          deletesucess
            ? deletedata
            : ""
        }
        apiname={apiName}
      />
      <Deletesingle
        key={renderKey}
        confirmmodal={deleteConfirmsingle}
        confirmmodalclose={confirmmodalclose}
        masterName={""}
        confirmmodalsubmit={confirmmodalsubmit}
        apiname={apiName}
      />

      <SimpleInput_Edit key={renderKey} 
      refetchForm={handleRefetch} 
      open={OpenEditModel} 
      setOpen={setOpenEditModel} 
      value={editName} 
      id={editId} 
      superAccountId={superAccountId} 
      optionsSuperAccount={optionsSuperAccount}
      // stateId={stateValue} 
      // districtId={districtValue} 
      // talukaId={talukaValue} 
      setSubmitted={setSubmittedEdit} 
      isSuperAccount={isSuperAccount}
      // forState={forState} 
      // forDistrict={forDistrict} 
      // forTaluka={forTaluka} 
      apiname={apiName} />
        </div>
    )
}

export default Edit_Account