
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import Actions from "../../components/Cell/Actions";
// import { updateBrand, useFetchBrands } from "../../hooks/brand";
import noImage from "../../images/noImage.png"
import { useFetchGst, useUpdateGst } from "../../hooks/GST";
import Modal from "react-bootstrap/esm/Modal";


interface Data {
  selection: any
  product_name_and_grade:any
  product_color:any
  panni_code_and_grade_name:any
  panni_printing_color_name:any
  panni_printing_total_color:any
  product_brand:any
  our_product_brand:any
  product_ml_price_list:any
  product_ml_actual:any
  stick_packing_type:any
  stick_noes:any
  stick_qty:any
  carton_total_nos:any
  carton_code_and_grade_and_name:any
  carton_in_stick_packing:any
  product_single_piece_code:any
  product_price_list_1_piece_weight:any
  product_production_list_1_piece_weight:any
  mouldshape:any
  product_list_mould_diameter:any
  product_type:any


  created_at:any
  created_by:any
  updated_by:any
  updated_at:any
//   status: any;
  actions: any;
}


export const titleofheaders_LD=[
  {
    id: `srno`,
    Header: `Sr.No`,
  },

  {
    id: `product_type`,
    Header: `Product Type`,

  },

  {
    id: `product_code`,
    Header: `Product Code`,

  },

  {
    id: `product_name_and_grade`,
    Header: `Name & Grade`,
  
  },
  {
    id: `stick_packing_type`,
    Header:"Stick Packing Type",
  },
  {
    id: `panni_code_and_grade_name`,
    Header: `Panni Code`,
    

  },
  {
    id: `panni_printing_color_name`,
    Header: `Printing Color Name`,
   

  },
  {
    id: `panni_printing_total_color`,
    Header: `Printing Total Color`,
   
  },
  {
    id: `product_brand`,
    Header: `Product Brand`,
   

  },
  {
    id: `our_product_brand`,
    Header: `Product Brand Type`,
   

  },
 
  {
    id: `carton_code_and_grade_and_name`,
    Header: "Carton Code",
    },
  
  {
    id: `product_ml_actual`,
    Header: "Product Ml Actual",
   
  },
  
  {
    id: `stick_nos`,
    Header:"Stick Nos",
    
  },
  {
    id: `stick_qty`,
    Header:"Stick Qty",
    
  },
  {
    id: `carton_total_nos`,
    Header: "Carton Total Nos",
  },
  
  {
    id: `carton_in_stick_packing`,
    Header:"Carton Stick Packing",
    
  },
  {
    id: `product_price_list_1_piece_weight`,
    Header: "Price List 1 Piece Weight",
    
  },
  {
    id: `product_production_list_1_piece_weight`,
    Header:"Production List 1 Piece Weight",
    
  },
  
  {
    id: `mouldshape`,
    Header:"Mould Shape",
    
  },
  {
    id: `product_list_mould_diameter`,
    Header:"Product List Mould Diameter",
    
  },
  {
    id: `product_single_piece_code`,
    Header: 'Single Piece Code',
    
  },
  
  {
    id: `day_packet_packing_labour_rate`,
    Header: `Day Packet Packing Labour Rate`,
    

  },
  {
    id: `night_packet_packing_labour_rate`,
    Header: `Night Packet Packing Labour Rate`,
    
  },
  {
    id: `day_carton_packing_labour_rate`,
    Header: `Day Cartoon Packing Labour Rate`,

  },
  {
    id: `night_carton_packing_labour_rate`,
    Header: `Night Cartoon Packing Labour Rate`,
   

  },
  {
    id: `product_stock_minimum_qty`,
    Header: `Product Stock Minimum Qty `,
    
  },
  {
    id: `product_stock_maximum_qty`,
    Header: `Product Stock Maximum Qty `,
  },
  {
    id: `product_opening_stock_qty`,
    Header: `Product Opening Stock Qty  `,

  },
  {
    id: `product_our_other`,
    Header: `Product Our-Other  `,
  },
  {
    id: `product_debit_day`,
    Header: `Product Debit Day   `,

  },
  {
    id: `product_special_running`,
    Header: `Product Special-Running`,

  },
  {
    id: `product_by`,
    Header: `Product By`,

  },
  {
    id: `product_location`,
    Header: "Product Location",
  },


  {
    id: `disposable_product_image`,
    Header: "Product Image",
  },
  {
    id: 'created_at',
    Header: 'Created At',
   

 
  },

  {
    id: 'created_by',
    Header: 'Created By',
   

 
  },
  {
    id: 'updated_at',
    Header: 'Updated At',
    
  },
  {
    id: 'updated_by',
    Header: 'Updated By',
   
  },
]


export const Columns_LD: any = [
 
  {
    id: `srno`,
    Header: () => <div style={{display:"inline"}}>Sr<br/>No</div>,
    sticky:"left",
  },

  {
    id: `product_type`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Type</div>,
    accessor:'product_type',
    sticky:"left",
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.extra_response?.product_type?.type}
        </div>
    )}

  },
  // {
  //   id: `product_code`,
  //   Header: () => <div style={{display:"inline"}}>Product<br/>Code</div>,
  //   accessor:'product_code',
  //   // sticky:"left",
    
  //   Cell: ({ row }: any) =>{
  //       return(
  //       <div>
  //           {row.original.product_code}
  //       </div>
  //   )}

  // },

  {
    id: `product_name_and_grade`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Name & Grade</div>,
    accessor:'product_name_and_grade',
   
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.extra_response?.material_type_name}
        </div>
    )}

  },
  {
    id: `stick_packing_type`,
    Header: () => <div style={{display:"inline"}}>Stick Packing<br/>Type</div>,
    accessor:'stick_packing_type',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.extra_response?.stick_packing_type?row.original.extra_response?.stick_packing_type:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
        </div>
    )}

  },
  {
    id: `panni_code_and_grade_name`,
    Header: () => <div style={{display:"inline"}}>Panni Code<br/>Name & Grade</div>,
    accessor:'panni_code_and_grade_name',
   

    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {`${row.original.extra_response?.panni_code_and_grade_name?.panni_code} ${row.original.extra_response?.panni_code_and_grade_name?.material_type_name}`}
        </div>
    )}

  },
//   {
//     id: `panni_printing_color_name`,
//     Header: () => <div style={{display:"inline"}}>Panni Printing<br/>Color Name</div>,
//     accessor:'panni_printing_color_name',
//     Cell: ({ row }: any) => {
//       let printingColor = row.original?.extra_response?.panni_code_and_grade_name?.printing_color_name?.map((e:any)=>{
//         return e
//       })
//       if (printingColor.length === 0) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
//       return printingColor?.join(",")
       
// }
    

//   },
  {
    id: `panni_printing_total_color`,
    Header: () => <div style={{display:"inline"}}>Panni Printing<br/>Total Color</div>,
    accessor:'panni_printing_total_color',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.panni_printing_total_color}
        </div>
    )}

  },
  {
    id: `product_brand`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Brand</div>,
    accessor:'product_brand',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.extra_response?.product_brand_name}
        </div>
    )}

  },
  {
    id: `our_product_brand`,
    Header: () => <div style={{display:"inline"}}>Product Brand<br/>Type</div>,
    accessor:'our_product_brand',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.our_product_brand}
        </div>
    )}

  },
  {
    id: `carton_code_and_grade_and_name`,
    Header: () => <div style={{display:"inline"}}>Carton Code<br/>Name & Grade</div>,
    accessor:'carton_code_and_grade_and_name',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.extra_response?.carton_code}
        </div>
    )}

  },
  
  {
    id: `product_ml_actual`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Ml Actual</div>,
    
    accessor:'product_ml_actual',
   
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.product_ml_actual}
        </div>
    )}

  },
  
  {
    id: `stick_nos`,
    Header: () => <div style={{display:"inline"}}>Stick<br/>Nos</div>,
    accessor:'stick_nos',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.stick_nos}
        </div>
    )}

  },
  {
    id: `stick_qty`,
    Header: () => <div style={{display:"inline"}}>Stick<br/>Qty</div>,
    accessor:'stick_qty',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.stick_qty}
        </div>
    )}

  },
  {
    id: `carton_total_nos`,
    Header: () => <div style={{display:"inline"}}>Carton Total<br/>Nos</div>,
    accessor:'carton_total_nos',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.carton_total_nos}
        </div>
    )}

  },
  
  {
    id: `carton_in_stick_packing`,
    Header: () => <div style={{display:"inline"}}>Carton<br/>Stick Packing</div>,
    accessor:'carton_in_stick_packing',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.carton_in_stick_packing}
        </div>
    )}

  },
  {
    id: `product_price_list_1_piece_weight`,
    Header: () => <div style={{display:"inline"}}>Price List<br/>1 Piece Weight</div>,
    accessor:'product_price_list_1_piece_weight',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.product_price_list_1_piece_weight}
        </div>
    )}

  },
  {
    id: `product_production_list_1_piece_weight`,
    Header: () => <div style={{display:"inline"}}>Production List<br/>1 Piece Weight</div>,
    accessor:'product_production_list_1_piece_weight',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.product_production_list_1_piece_weight}
        </div>
    )}

  },
  
  {
    id: `mouldshape`,
    Header: () => <div style={{display:"inline"}}>Mould<br/>Shape</div>,
    accessor:'mouldshape',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.extra_response?.mouldshape_name}
        </div>
    )}

  },
  {
    id: `product_list_mould_diameter`,
    Header: () => <div style={{display:"inline"}}>Product List<br/>Mould Diameter</div>,
    accessor:'product_list_mould_diameter',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.product_list_mould_diameter}
        </div>
    )}

  },
 {
    id: `product_single_piece_code`,
    Header: () => <div style={{display:"inline"}}>Product single<br/>piece code</div>,
    accessor:'product_single_piece_code',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.extra_response?.single_piece_code}
        </div>
    )}

  },
  
  {
    id: `day_packet_packing_labour_rate`,
    Header: () => <div style={{display:"inline"}}>Day Packet<br/>Packing Labour Rate</div>,
    // Header: `Day Packet Packing Labour Rate`,
    accessor:'day_packet_packing_labour_rate',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.day_packet_packing_labour_rate}
        </div>
    )}

  },
  {
    id: `night_packet_packing_labour_rate`,
    Header: () => <div style={{display:"inline"}}>Night Packet<br/>Packing Labour Rate</div>,
    // Header: `Day Packet Packing Labour Rate`,
    accessor:'night_packet_packing_labour_rate',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.night_packet_packing_labour_rate}
        </div>
    )}

  },
  {
    id: `day_carton_packing_labour_rate`,
    Header: () => <div style={{display:"inline"}}>Day Cartoon<br/>Packing Labour Rate</div>,
    // Header: `Day Cartoon Packing Labour Rate`,
    accessor:'day_carton_packing_labour_rate',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.day_carton_packing_labour_rate}
        </div>
    )}

  },
  {
    id: `night_carton_packing_labour_rate`,
    Header: () => <div style={{display:"inline"}}>Night Cartoon<br/>Packing Labour Rate</div>,
    // Header: `Day Cartoon Packing Labour Rate`,
    accessor:'night_carton_packing_labour_rate',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.night_carton_packing_labour_rate}
        </div>
    )}

  },
  {
    id: `product_stock_minimum_qty`,
    Header: () => <div style={{display:"inline"}}>Product Stock<br/>Minimum Qty</div>,
    // Header: `Product Stock Minimum Qty `,
    accessor:'product_stock_minimum_qty',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.product_stock_minimum_qty}
        </div>
    )}

  },
  {
    id: `product_stock_maximum_qty`,
    Header: () => <div style={{display:"inline"}}>Product Stock<br/>Maximum Qty</div>,
    // Header: `Product Stock Minimum Qty `,
    accessor:'product_stock_maximum_qty',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.product_stock_maximum_qty}
        </div>
    )}

  },
  {
    id: `product_opening_stock_qty`,
    Header: () => <div style={{display:"inline"}}>Product Opening<br/>Stock Qty</div>,
    // Header: `Product Opening Stock Qty  `,
    accessor:'product_opening_stock_qty',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.product_opening_stock_qty}
        </div>
    )}

  },
  {
    id: `product_our_other`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Our-Other</div>,
    // Header: `Product Our-Other  `,
    accessor:'product_our_other',
    Cell: ({row}:any) => {
      const convertToUpper = row.original.product_our_other
      if (!convertToUpper) return "";
      // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }
    // Cell: ({ row }: any) =>{
    //     return(
    //     <div>
    //         {row.original.product_our_other}
    //     </div>
    // )}

  },
  {
    id: `product_debit_day`,
    Header: () => <div style={{display:"inline"}}>Product<br/>Debit Day</div>,
    // Header: `Product Debit Day   `,
    accessor:'product_debit_day',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.product_debit_day}
        </div>
    )}

  },
  // {
  //   id: `product_special_running`,
  //   Header: () => <div style={{display:"inline"}}>Product<br/>Special-Running</div>,
  //   // Header: `Product Debit Day   `,
  //   accessor:'product_special_running',
  //   Cell: ({row}:any) => {
  //     const convertToUpper = row.original.product_special_running
  //     if (!convertToUpper) return "";
  //     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
  //     return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
  //   }
    // Cell: ({ row }: any) =>{
    //     return(
    //     <div>
    //         {row.original.product_special_running}
    //     </div>
    // )}

  // },


  // {
  //   id: `product_by`,
  //   Header: () => <div style={{display:"inline"}}>Product<br/>By</div>,
  //   // Header: `Product Debit Day   `,
  //   accessor:'product_by',
  //   Cell: ({row}:any) => {
  //     const convertToUpper = row.original.product_by
  //     if (!convertToUpper) return "";
  //     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
  //     return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
  //   }
  //   // Cell: ({ row }: any) =>{
  //   //     return(
  //   //     <div>
  //   //         {row.original.product_by}
  //   //     </div>
  //   // )}

  // },

  
  // {
  //   id: `product_location`,
  //   Header: () => <div style={{display:"inline"}}>Product<br/>Location</div>,
  //   // Header: `Product Debit Day   `,
  //   accessor:'product_location',
    
  //   Cell: ({ row }: any) =>{
  //       return(
  //       <div>
  //           {row.original.product_location}
  //       </div>
  //   )}

  // },


  {
    id: `disposable_product_image`,
    Header: () => <div style={{ display: "inline" }}>Product<br />Image</div>,
  
    // accessor: `logo`,
    Cell: ({ row }: any) =>{
    
    const [deleteConfirm, setDeleteConfirm] = useState(false)
  return(
    <>
  
        <div >
  
          {!row.original.disposable_product_image?<>
            <a 
          
          href="#"
          
          >
            <img src={noImage}  height={60} width={100}/>
            </a>
          </>:<>
          <a 
          onClick={()=>setDeleteConfirm(true)}
          href={`#galleryModal${row.original.name}`}
          
          data-large-src={row.original.disposable_product_image?row.original.disposable_product_image:noImage} data-toggle="modal">
            <img src={row.original.disposable_product_image?row.original.disposable_product_image:noImage}  height={60} width={100}/>
            </a>
          </>}
         
        </div>
  
  
  
  <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
        centered    >
       
        <Modal.Body>  
        <img src={row.original.disposable_product_image?row.original.disposable_product_image:noImage} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid"/>
  
        </Modal.Body>
        <Modal.Footer>
        <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>
  
        </Modal.Footer>
      </Modal>
        </>)
    }
  
  
    },
  






  {
    id: `disposable_product_drawing`,
    // Header: `Panni Drawing`,
    Header: () => <div style={{ display: "inline" }}>Product<br />PDF</div>,

    Cell: ({ row }: any) =>
    


        <div>
          <a target="_blank" href={row.original.disposable_product_drawing?row.original.disposable_product_drawing:null}>{row.original.disposable_product_drawing?.split('/').pop()?row.original.disposable_product_drawing?.split('/').pop():<span className="text-danger">No Document Uploaded</span>}</a>
        </div>

    },

  {
    id: 'created_at',
    Header: () => <div style={{display:"inline"}}>Created<br/>At</div>,
    accessor:'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split("T")[0] : "-")

 
  },

  {
    id: 'created_by',
    Header: () => <div style={{display:"inline"}}>Created<br/>By</div>,
    accessor:'created_by',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_by?.username : "-")

 
  },
  {
    id: 'updated_at',
    Header: () => <div style={{display:"inline"}}>Updated<br/>At</div>,
    accessor:'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split("T")[0] : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{display:"inline"}}>Updated<br/>By</div>,
    accessor:'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },


  

  


  {
    id: 'Actions',
    Header: 'Actions',

  
  },


  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_LD, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows} = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}disposableproduct/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



