import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Columns_CheckList, titleofheaders } from "./Columns_CheckList"
import Table from "../../components/Dynamic_Table/Table/Table";
const List_CheckList = () => {
  const Breadcrumb_Primary_Title = "Home";
  const Breadcrumb_Primary_Link = "Color";

  const Breadcrumb_Parent_Title = "Master";

  const Breadcrumb_Title_Name = "Checklist";

  const apiname = process.env.REACT_APP_CHECKLIST_API?.toString();

  const filter = {
    check_list_code:'',
    name:'',
    choice_yes_no:'',
    department_name:'',
    name_and_surname:'',
    point_open_time:'',
    point_close_time:'',
    total_min:'',
    point_start_date:'',
    point_end_date:'',
    total_running_days:'',
    pending_running_days:'',
    emergency_on_date:'',
    emergency_on_reason:'',
    emergency_close_date:'',
    emergency_off_reason:'',
    emergency_on_off_person_name:'',
    point_on_off:'',
    suggested_by:'',
  
  
  
    created_by:'',
    updated_by:'',
  
    status: ''

  };

  const addButtonName = "Checklist";
  const addButtonPageName = "Checklist"
  const permissionClass = {
    front: "checklist",
    back: "checklist"
  }
  const stickyStart = 1;
  const stickyEnd = 2;

  return (
    <>
      <div className="content-wrapper">
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{Breadcrumb_Title_Name} List</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to={`/list${Breadcrumb_Primary_Link}`}
                      className="text-decoration-none text-black"
                    >
                      {Breadcrumb_Primary_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      {Breadcrumb_Parent_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {Breadcrumb_Title_Name} List
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="container p-0" style={{ maxWidth: "100%" }}>
                {/* <BasicTable/> */}
                <Table
                  apiname={apiname}
                  filterFields={filter}
                  addButtonName={addButtonName}
                  stickyStart={stickyStart}
                  stickyEnd={stickyEnd}
                  TableColumns={Columns_CheckList}
                  addButtonPageName={addButtonPageName}
                  titleofheaders={titleofheaders}
                  permissionClass={permissionClass}
                />
                
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default List_CheckList;
