import React, { useContext, useEffect, useRef, useState } from "react";
import SearchLogo from "../../../images/SearchLogo.png";
import cameraLogo from "../../../images/Export/Camera.png";
import pdfLogo from "../../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate } from "../../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../../components/focus";
import CreatableSelect from "react-select/creatable";
import { useFetchAccountDropDown, useFetchAdministrationTypeDropDown, useFetchCityDropDown, useFetchEmployeeDropDown, useFetchGradeDropDown, useFetchOrganizationTypeDropDown, useFetchPersonOccupationDropDown, useFetchPersonPurchaseTypeDropDown, useFetchPersonSalesStatusDropDown, useFetchTypeOfBusinessDropDown, useFetchWeeklyOffDropDown } from "../../../hooks/DropDown_Api";
import { DatePicker } from "antd";
import dayjs from 'dayjs';
import moment from "moment";
import { Upload, UploadFile } from "antd";
import Button from "react-bootstrap/esm/Button";
import { AiOutlinePlus } from "react-icons/ai";
import { useFormContext } from 'react-hook-form';
import Add_Customer_Details from "../Customer Detail/Add_Customer_Detail";
import Edit_Employee from "../../Employee/Edit_Employee";
// import { GlobalStateContext } from "../GlobalStateContext";
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import { Account_Model } from "../../../components/Dynamic_Form_Models/Account_Model";
import { City_Model } from "../../../components/Dynamic_Form_Models/City_Model";

import Modal from "react-bootstrap/Modal";
import Actions, { Deleteforce, Deletesingle } from "../../../components/Cell/Actions";
import { useDelete, useDeletemforce } from "../../../hooks/delete";
import Custom_Actions_Buttons from "../../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../../hooks/deletemultiple";
import { SimpleInput_Edit } from "../../../components/Dynamic_Form_Models/SimpleInput_Edit";


const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}
interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});

const Add_Basic_Customer_Details = ({ setSuccessPersonalDetails, setCurrentStep, currentStep }: any) => {
    let pageName = "Customer Grade"
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()
    // const { formData, setFormData } = useContext(GlobalStateContext);





    const apiname = process.env.REACT_APP_EMPLOYEE_API?.toString()


    const { mutate: Grade, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)
    const { register, handleSubmit, setError, formState, reset, setValue, getValues }: any = useForm({
        mode: "onChange"
    });

    // const { register, handleSubmit, setError, formState, reset,setValue, getValues  }:any = useFormContext();
    const { errors, isDirty } = formState;



    const onSubmit = (data: any) => {

        delete data.image

        if (!JoiningDate) {
            delete data.company_date_of_birth
        }

        if (Visiting.length == 0) {
            delete data.visiting_card
          }
          
          if (ConditionImg.length == 0) {
            delete data.condition_image
          }
          if (Security.length == 0) {
            delete data.security_cheque
          }
          if (ConditionPDF.length == 0) {
            delete data.condition_pdf
          }
          if (EmployeeValue === undefined) {
            delete data.customer_from_party_name
          }
          if (forGradeValue === undefined || forGradeValue === '') {
            delete data.customer_grade
          }
          if (RefrenceValue === undefined) {
            delete data.reference
            delete data.other_reference
          }

        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            const formvalue: any = value

            if (key === "visiting_card") {
                for (let i = 0; i < Visiting.length; i++) {
        
                  formData.append('visiting_card', Visiting[i])
                }
                continue
              }
              if (key === "condition_image") {
                for (let i = 0; i < ConditionImg.length; i++) {
        
                  formData.append('condition_image', ConditionImg[i])
                }
                continue
              }
        
              if (key === "security_cheque") {
                for (let i = 0; i < Security.length; i++) {
        
                  formData.append('security_cheque', Security[i])
                }
                continue
              }
              if (key === "condition_pdf") {
                for (let i = 0; i < ConditionPDF.length; i++) {
        
                  formData.append('condition_pdf', ConditionPDF[i])
                }
                continue
              }

            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }


           
            

        }

        let personType = localStorage.getItem('PersonType');
        let personTypeValue = personType ? JSON.parse(personType) : null;
        formData.append('person_type', personTypeValue.value)
        Grade(formData)


    }

    // useEffect(()=>{
    //     if(isSuccess){
    //         setSuccessPersonalDetails(true)
    //     }
    // },[isSuccess])

    // useEffect(()=>{
    //     if(isSuccess&& data){
    //         
    //         localStorage.setItem('Personal Details',data?.data?.id)
    //          const currentPath = window.location.pathname;
            // if(currentPath === '/addCustomerDetails'){
            // setCurrentStep(currentStep + 1)
            // };
    //     }
    // },[data,isSuccess])

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess && data) {
            localStorage.setItem('Add Data', JSON.stringify(data?.data))
             const currentPath = window.location.pathname;
            if(currentPath === '/addCustomerDetails'){
            setCurrentStep(currentStep + 1)
            }

        }
    }, [data, isSuccess])

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            // setSuccessPersonalDetails(true)
            // navigate('/listPersonDetailGrade', { state: { showToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    };

    const handleReset = () => {
        reset()
        setJoiningDate(null)

    }

    const [openAccount, setOpenAccount]: any = useState(false)
    const [optionsAccount, setOptionsAccount]: any = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [forAccountValue, setForAccountValue]: any = useState([])

    const { data: accountData, refetch: accountRefetch } = useFetchAccountDropDown()

    useEffect(() => {
        setOptionsAccount(accountData)
    }, [accountData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            accountRefetch()
        }
    }, [])



    const handleChangeAccount = (e: any) => {
        setValue("group", e?.value)
        setForAccountValue(e)
        // setForDepartmentValue({ label: e?.label, value: e?.value })
    }



    const [forCityValue, setForCityValue]: any = useState([])
    const [cityOptions, setCityOptions]: any = useState([])
    const [openCity, setOpenCity]: any = useState(false)

    const { data: cityData, refetch: cityRefetch } = useFetchCityDropDown()

    // useEffect(() => {
    //     setCityOptions(cityData)
    // }, [cityData])

    useEffect(() => {
        let temp = cityData?.map((e: any) => {
            return (
                {
                    value: e?.value,
                    label: `${e?.city ? e?.city : e?.village}::${e?.district}`,

                }
            )
        })
        setCityOptions(temp)
    }, [cityData])
    const handleChangeCity = (e: any) => {
        // 
        setForCityValue(e)
        setValue("city_name_and_distict_name", e?.value)

        // setValue("state",e?.stateID)
        // setValue("country",e?.countryID)
        // setValue("district",e?.districtID)


    }

    const [EmployeeValue, setEmployeeValue]: any = useState()
    const [RefrenceValue, setRefrenceValue]: any = useState()
    const [optionsEmployee, setOptionsEmployee] = useState([])
    const [optionsRefrence, setOptionsRefrence] = useState([])
    const [showOtherRef, setShowOtherRef]: any = useState(false)

    const { data: EmployeeData, refetch: colorRefetch } = useFetchEmployeeDropDown()

    useEffect(() => {
        setOptionsEmployee(EmployeeData)
    }, [EmployeeData])


    useEffect(() => {
        // 
        if (EmployeeData) {
            let temp_employee_data: any = EmployeeData?.map((e: any) => e)
            temp_employee_data?.unshift({ label: 'Other', value: 'other' })
            setOptionsRefrence(temp_employee_data)
        }
    }, [EmployeeData])

    const handleChangeRefrence = (e: any) => {
        
        if (e.value === 'other') {
            setRefrenceValue(e)
            setShowOtherRef(true)
            setValue("reference", '')
        }
        else {
            setRefrenceValue(e)
            setShowOtherRef(false)
            // setShowOtherRef(null)
            setValue('other_reference', '')
            setValue("reference", `${e?.value}`)
        }
    }

    const handleChangeEmployee = (e: any) => {
        setEmployeeValue(e)
        setValue("customer_from_party_name", e?.value)
    }



    // const [forGradeValue, setForGradeValue]: any = useState([])

    // const [optionsGrade, setoptionsGrade]: any = useState([])

    // const { data: gradeData, refetch: gradeRefetch } = useFetchGradeDropDown()

    // useEffect(() => {
    //     setoptionsGrade(gradeData)
    // }, [gradeData])



    // const handleChangeGrade = (e: any) => {
    //     setValue("customer_grade", e?.value)
    //     setForGradeValue(e)
    // }

    const optionsGrade = [
        {
            value: 1,
            label: "A"
        },
        {
            value: 2,
            label: "B"
        },
        {
            value: 3,
            label: "C"
        },
        {
            value: 4,
            label: "D"
        },
        {
            value: 5,
            label: "E"
        },

    ]
    const [forGradeValue, setForGradeValue]: any = useState('')
console.log(forGradeValue,"forGradeValue");

    const handleChangeGrade = (e: any) => {
        setForGradeValue(e)
        setValue("customer_grade", e?.label)
    }



    const apinameOrganizationType = process.env.REACT_APP_CUSTOMER_ORGANIZATION_TYPE_API?.toString()
    const { mutate: mutateOrganizationType, data: dataOrganizationType, isError: isErrorOrganizationType, isLoading: isLoadingOrganizationType, isSuccess: isSuccessOrganizationType, error: errorOrganizationType }: any = useCreate(apinameOrganizationType)
    const [optionsOrganizationType, setOptionsOrganizationType]: any = useState([]);
    const [valueOrganizationType, setValueOrganizationType]: any = useState();
    const [valueOrganizationTypeCreatable, setValueOrganizationTypeCreatable] = useState<Option | null>();
    const { data: OrganizationTypeData, refetch: OrganizationTypeRefetch } = useFetchOrganizationTypeDropDown()
    useEffect(() => {
        setOptionsOrganizationType(OrganizationTypeData)
    }, [OrganizationTypeData])

    const handleCreateOrganizationType = async (inputValue: string) => {
        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setValue("name", `${newOption?.value}`)
            mutateOrganizationType({ name: newOption.name, status: newOption.status })
            setOptionsOrganizationType((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValueOrganizationTypeCreatable(newOption);
        }, 0);

    };

    const handleChangeOrganizationType = (e: any) => {

        OrganizationTypeRefetch()
        setApiName(apinameOrganizationType)
        setDeleteApiName(apinameOrganizationType)
        setEditId(e?.value)
        setEditData(OrganizationTypeData)
        setRenderKey(prevKey => prevKey + 1);
        setValueOrganizationType(e)
        setValue("customer_organization_type", `${e?.value}`)
        setValueOrganizationTypeCreatable(null)
    }

    useEffect(() => {
        (async () => {
            if (isSuccessOrganizationType && optionsOrganizationType && valueOrganizationTypeCreatable != null) {
                await OrganizationTypeRefetch();
                setValue("customer_organization_type", OrganizationTypeData[0]?.value)
            }
        })();
        return () => {
        };
    }, [isSuccessOrganizationType, OrganizationTypeData]);


    const apinameTypeOfBusiness = process.env.REACT_APP_CUSTOMER_BUSINESS_TYPE_API?.toString()
    const { mutate: mutateTypeOfBusiness, data: dataTypeOfBusiness, isError: isErrorTypeOfBusiness, isLoading: isLoadingTypeOfBusiness, isSuccess: isSuccessTypeOfBusiness, error: errorTypeOfBusiness }: any = useCreate(apinameTypeOfBusiness)
    const [optionsTypeOfBusiness, setOptionsTypeOfBusiness]: any = useState([]);
    const [valueTypeOfBusiness, setValueTypeOfBusiness]: any = useState();
    const [valueTypeOfBusinessCreatable, setValueTypeOfBusinessCreatable] = useState<Option | null>();
    const { data: TypeOfBusinessData, refetch: TypeOfBusinessRefetch } = useFetchTypeOfBusinessDropDown()
    useEffect(() => {
        setOptionsTypeOfBusiness(TypeOfBusinessData)
    }, [TypeOfBusinessData])

    const handleCreateTypeOfBusiness = async (inputValue: string) => {
        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setValue("name", `${newOption?.value}`)
            mutateTypeOfBusiness({ name: newOption.name, status: newOption.status })
            setOptionsTypeOfBusiness((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValueTypeOfBusinessCreatable(newOption);
        }, 0);

    };

    const handleChangeTypeOfBusiness = (e: any) => {

        TypeOfBusinessRefetch()
        setApiName(apinameTypeOfBusiness)
        setDeleteApiName(apinameTypeOfBusiness)
        setEditId(e?.value)
        setEditData(TypeOfBusinessData)
        setRenderKey(prevKey => prevKey + 1);
        setValueTypeOfBusiness(e)
        setValue("type_of_business", `${e?.value}`)
        setValueTypeOfBusinessCreatable(null)
    }

    useEffect(() => {
        (async () => {
            if (isSuccessTypeOfBusiness && optionsTypeOfBusiness && valueTypeOfBusinessCreatable != null) {
                await TypeOfBusinessRefetch();
                setValue("type_of_business", TypeOfBusinessData[0]?.value)
            }
        })();
        return () => {
        };
    }, [isSuccessTypeOfBusiness, TypeOfBusinessData]);


    const apinamePersonOccupation = process.env.REACT_APP_CUSTOMER_OCCUPATION_API?.toString()
    const { mutate: mutatePersonOccupation, data: dataPersonOccupation, isError: isErrorPersonOccupation, isLoading: isLoadingPersonOccupation, isSuccess: isSuccessPersonOccupation, error: errorPersonOccupation }: any = useCreate(apinamePersonOccupation)
    const [optionsPersonOccupation, setOptionsPersonOccupation]: any = useState([]);
    const [valuePersonOccupation, setValuePersonOccupation]: any = useState();
    const [valuePersonOccupationCreatable, setValuePersonOccupationCreatable] = useState<Option | null>();
    const { data: PersonOccupationData, refetch: PersonOccupationRefetch } = useFetchPersonOccupationDropDown()
    useEffect(() => {
        setOptionsPersonOccupation(PersonOccupationData)
    }, [PersonOccupationData])

    const handleCreatePersonOccupation = async (inputValue: string) => {
        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setValue("name", `${newOption?.value}`)
            mutatePersonOccupation({ name: newOption.name, status: newOption.status })
            setOptionsPersonOccupation((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValuePersonOccupationCreatable(newOption);
        }, 0);

    };

    const handleChangePersonOccupation = (e: any) => {

        PersonOccupationRefetch()
        setApiName(apinamePersonOccupation)
        setDeleteApiName(apinamePersonOccupation)
        setEditId(e?.value)
        setEditData(PersonOccupationData)
        setRenderKey(prevKey => prevKey + 1);
        setValuePersonOccupation(e)
        setValue("occupation", `${e?.value}`)
        setValuePersonOccupationCreatable(null)
    }

    useEffect(() => {
        (async () => {
            if (isSuccessPersonOccupation && optionsPersonOccupation && valuePersonOccupationCreatable != null) {
                await PersonOccupationRefetch();
                setValue("occupation", PersonOccupationData[0]?.value)
            }
        })();
        return () => {
        };
    }, [isSuccessPersonOccupation, PersonOccupationData]);


    const apinamePersonSalesStatus = process.env.REACT_APP_CUSTOMER_SALES_STATUS_API?.toString()
    const { mutate: mutatePersonSalesStatus, data: dataPersonSalesStatus, isError: isErrorPersonSalesStatus, isLoading: isLoadingPersonSalesStatus, isSuccess: isSuccessPersonSalesStatus, error: errorPersonSalesStatus }: any = useCreate(apinamePersonSalesStatus)
    const [optionsPersonSalesStatus, setOptionsPersonSalesStatus]: any = useState([]);
    const [valuePersonSalesStatus, setValuePersonSalesStatus]: any = useState();
    const [valuePersonSalesStatusCreatable, setValuePersonSalesStatusCreatable] = useState<Option | null>();
    const { data: PersonSalesStatusData, refetch: PersonSalesStatusRefetch } = useFetchPersonSalesStatusDropDown()
    useEffect(() => {
        setOptionsPersonSalesStatus(PersonSalesStatusData)
    }, [PersonSalesStatusData])

    const handleCreatePersonSalesStatus = async (inputValue: string) => {
        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setValue("name", `${newOption?.value}`)
            mutatePersonSalesStatus({ name: newOption.name, status: newOption.status })
            setOptionsPersonSalesStatus((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValuePersonSalesStatusCreatable(newOption);
        }, 0);

    };

    const handleChangePersonSalesStatus = (e: any) => {

        PersonSalesStatusRefetch()
        setApiName(apinamePersonSalesStatus)
        setDeleteApiName(apinamePersonSalesStatus)
        setEditId(e?.value)
        setEditData(PersonSalesStatusData)
        setRenderKey(prevKey => prevKey + 1);
        setValuePersonSalesStatus(e)
        setValue("customer_sales_status", `${e?.value}`)
        setValuePersonSalesStatusCreatable(null)
    }

    useEffect(() => {
        (async () => {
            if (isSuccessPersonSalesStatus && optionsPersonSalesStatus && valuePersonSalesStatusCreatable != null) {
                await PersonSalesStatusRefetch();
                setValue("customer_sales_status", PersonSalesStatusData[0]?.value)
            }
        })();
        return () => {
        };
    }, [isSuccessPersonSalesStatus, PersonSalesStatusData]);



    const apinamePersonPurchaseType = process.env.REACT_APP_CUSTOMER_BUYING_TYPE_API?.toString()
    const { mutate: mutatePersonPurchaseType, data: dataPersonPurchaseType, isError: isErrorPersonPurchaseType, isLoading: isLoadingPersonPurchaseType, isSuccess: isSuccessPersonPurchaseType, error: errorPersonPurchaseType }: any = useCreate(apinamePersonPurchaseType)
    const [optionsPersonPurchaseType, setOptionsPersonPurchaseType]: any = useState([]);
    const [valuePersonPurchaseType, setValuePersonPurchaseType]: any = useState();
    const [valuePersonPurchaseTypeCreatable, setValuePersonPurchaseTypeCreatable] = useState<Option | null>();
    const { data: PersonPurchaseTypeData, refetch: PersonPurchaseTypeRefetch } = useFetchPersonPurchaseTypeDropDown()
    useEffect(() => {
        setOptionsPersonPurchaseType(PersonPurchaseTypeData)
    }, [PersonPurchaseTypeData])

    const handleCreatePersonPurchaseType = async (inputValue: string) => {
        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setValue("name", `${newOption?.value}`)
            mutatePersonPurchaseType({ name: newOption.name, status: newOption.status })
            setOptionsPersonPurchaseType((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValuePersonPurchaseTypeCreatable(newOption);
        }, 0);

    };

    const handleChangePersonPurchaseType = (e: any) => {

        PersonPurchaseTypeRefetch()
        setApiName(apinamePersonPurchaseType)
        setDeleteApiName(apinamePersonPurchaseType)
        setEditId(e?.value)
        setEditData(PersonPurchaseTypeData)
        setRenderKey(prevKey => prevKey + 1);
        setValuePersonPurchaseType(e)
        setValue("customer_buying_type", `${e?.value}`)
        setValuePersonPurchaseTypeCreatable(null)
    }

    useEffect(() => {
        (async () => {
            if (isSuccessPersonPurchaseType && optionsPersonPurchaseType && valuePersonPurchaseTypeCreatable != null) {
                await PersonPurchaseTypeRefetch();
                setValue("customer_buying_type", PersonPurchaseTypeData[0]?.value)
            }
        })();
        return () => {
        };
    }, [isSuccessPersonPurchaseType, PersonPurchaseTypeData]);



    const apinameForWeeklyOff = process.env.REACT_APP_EMPLOYEE_WEEKLY_OFF_API?.toString()
    const { mutate: mutateWeeklyOff, data: dataWeeklyOff, isError: WeeklyOffIsError, isLoading: WeeklyOffLoading, isSuccess: WeeklyOffSuccess, error: WeeklyOffError }: any = useCreate(apinameForWeeklyOff)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueWeeklyOff, setValueWeeklyOff]: any = useState();
    const [optionsWeeklyOff, setOptionsWeeklyOff]: any = useState([]);
    const [valueWeeklyOffCreatable, setValueWeeklyOffCreatable] = useState<Option | null>();

    const { data: WeeklyOffData, refetch: WeeklyOffRefetch } = useFetchWeeklyOffDropDown()

    useEffect(() => {
        setOptionsWeeklyOff(WeeklyOffData)
    }, [WeeklyOffData])





    const handleChangeWeeklyOff = (e: any) => {

        WeeklyOffRefetch()

        setApiName(apinameForWeeklyOff)
        setDeleteApiName(apinameForWeeklyOff)
        setEditId(e?.value)
        setEditData(WeeklyOffData)
        setRenderKey(prevKey => prevKey + 1);

        setValueWeeklyOff(e)

        setValue("weekly_off", `${e?.value}`)
        setValueWeeklyOffCreatable(null)
    }





    const handleCreateWeeklyOff = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutateWeeklyOff({ name: newOption.name, status: newOption.status })
            setOptionsWeeklyOff((prev: any) => [...prev, newOption]);
            setValueWeeklyOffCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (WeeklyOffSuccess && optionsWeeklyOff) {
                await WeeklyOffRefetch();
                setValue("weekly_off", WeeklyOffData[0]?.value)
            }

        })();

        return () => {
        };
    }, [WeeklyOffSuccess, WeeklyOffData]);



    const apinameForAdministrationType = process.env.REACT_APP_EMPLOYEE_ADMINISTRATION_API?.toString()
    const { mutate: mutateAdministrationType, data: dataAdministrationType, isError: AdministrationTypeIsError, isLoading: AdministrationTypeLoading, isSuccess: AdministrationTypeSuccess, error: AdministrationTypeError }: any = useCreate(apinameForAdministrationType)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueAdministrationType, setValueAdministrationType]: any = useState();
    const [optionsAdministrationType, setOptionsAdministrationType]: any = useState([]);
    const [valueAdministrationTypeCreatable, setValueAdministrationTypeCreatable] = useState<Option | null>();

    const { data: AdministrationTypeData, refetch: AdministrationTypeRefetch } = useFetchAdministrationTypeDropDown()

    useEffect(() => {
        setOptionsAdministrationType(AdministrationTypeData)
    }, [AdministrationTypeData])





    const handleChangeAdministrationType = (e: any) => {

        AdministrationTypeRefetch()

        setApiName(apinameForAdministrationType)
        setDeleteApiName(apinameForAdministrationType)
        setEditId(e?.value)
        setEditData(AdministrationTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueAdministrationType(e)

        setValue("administration_type", `${e?.value}`)
        setValueAdministrationTypeCreatable(null)
    }





    const handleCreateAdministrationType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutateAdministrationType({ name: newOption.name, status: newOption.status })
            setOptionsAdministrationType((prev: any) => [...prev, newOption]);
            setValueAdministrationTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (AdministrationTypeSuccess && optionsAdministrationType) {
                await AdministrationTypeRefetch();
                setValue("administration_type", AdministrationTypeData[0]?.value)
            }

        })();

        return () => {
        };
    }, [AdministrationTypeSuccess, AdministrationTypeData]);




    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
    const [JoiningDate, setJoiningDate]: any = useState("")


    const handleJoiningDate = (e: any) => {

        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let company_date_of_birth = `${date}-${month}-${year}`
        setValue("company_date_of_birth", company_date_of_birth)
        setJoiningDate(e)

    }



    // const [onNext, setOnNext] = useState(false)


    // const handleNext = () => {
    //     if (onNext) {
    //          const currentPath = window.location.pathname;
            // if(currentPath === '/addCustomerDetails'){
            // setCurrentStep(currentStep + 1)
            // };

    //     }
    // }


    const [SubmittedAccount, setSubmittedAccount]: any = useState(false)
    useEffect(() => {
        if (SubmittedAccount) {

            setForAccountValue(optionsAccount[0])


            setValue("group", optionsAccount[0]?.value)

        }

    }, [SubmittedAccount, optionsAccount])

    const [SubmittedCity, setSubmittedCity]: any = useState(false)
    useEffect(() => {
        if (SubmittedCity) {

            setForCityValue(cityOptions[0])


            setValue("city_name_and_distict_name", cityOptions[0]?.value)

        }

    }, [SubmittedCity, cityOptions])






    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
    } = useDelete();
    const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
    } = useDeletemforce();




    const handleRefetch = () => {
        if (apiName === apinameOrganizationType) {
            OrganizationTypeRefetch()
        }
        if (apiName === apinameTypeOfBusiness) {
            TypeOfBusinessRefetch()
        }
        if (apiName === apinamePersonOccupation) {
            PersonOccupationRefetch()
        }
        if (apiName === apinamePersonSalesStatus) {
            PersonSalesStatusRefetch()
        }
        if (apiName === apinameForAdministrationType) {
            AdministrationTypeRefetch()
        }
        if (apiName === apinameForWeeklyOff) {
            WeeklyOffRefetch()
        }

        if (apiName === apinamePersonPurchaseType) {
            PersonPurchaseTypeRefetch()
        }




        // else if(apiName === apinameForTaluka){
        //   TalukaRefetch()
        // }
    }


    useEffect(() => {
        if (OpenEditDropDownModel && valueTypeOfBusiness && apiName === apinameTypeOfBusiness) {
            setValueTypeOfBusiness(null)
            setValueTypeOfBusinessCreatable(null)
        }
        if (OpenEditDropDownModel && valuePersonOccupation && apiName === apinamePersonOccupation) {
            setValuePersonOccupation(null)
            setValuePersonOccupationCreatable(null)
        }
        if (OpenEditDropDownModel && valueOrganizationType && apiName === apinameOrganizationType) {
            setValueOrganizationType(null)
            setValueOrganizationTypeCreatable(null)
        }
        if (OpenEditDropDownModel && valuePersonSalesStatus && apiName === apinamePersonSalesStatus) {
            setValuePersonSalesStatus(null)
            setValuePersonSalesStatusCreatable(null)
        }
        if (OpenEditDropDownModel && valueAdministrationType && apiName === apinameForAdministrationType) {
            setValueAdministrationType(null)
            setValueAdministrationTypeCreatable(null)
        }
        if (OpenEditDropDownModel && valueWeeklyOff && apiName === apinameForWeeklyOff) {
            setValueWeeklyOff(null)
            setValueWeeklyOffCreatable(null)
        }

        if (OpenEditDropDownModel && valuePersonPurchaseType && apiName === apinamePersonPurchaseType) {
            setValuePersonPurchaseType(null)
            setValuePersonPurchaseTypeCreatable(null)
        }







        if (deletesucess && apiName === apinameOrganizationType) {
            setValueOrganizationType(null)
            setValueOrganizationTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameTypeOfBusiness) {
            setValueTypeOfBusiness(null)
            setValueTypeOfBusinessCreatable(null)
        }
        if (deletesucess && apiName === apinamePersonOccupation) {
            setValuePersonOccupation(null)
            setValuePersonOccupationCreatable(null)
        }
        if (deletesucess && apiName === apinamePersonSalesStatus) {
            setValuePersonSalesStatus(null)
            setValuePersonSalesStatusCreatable(null)
        }

        if (deletesucess && apiName === apinameForAdministrationType) {
            setValueAdministrationType(null)
            setValueAdministrationTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameForWeeklyOff) {
            setValueWeeklyOff(null)
            setValueWeeklyOffCreatable(null)
        }

        if (deletesucess && apiName === apinamePersonPurchaseType) {
            setValuePersonPurchaseType(null)
            setValuePersonPurchaseTypeCreatable(null)
        }


    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (


        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
            <span>{label}</span>
            <div style={{ float: "right", justifyContent: "end" }}>



                {isExist ?

                    <Custom_Actions_Buttons value={value}
                        apiname={deleteApiName}
                        mutate={singleDelete}
                        // masterName={addButtonName}
                        deleteid={deleteid}
                        handleDeletedforce={handleDeletedforce}
                        closereconfirmdelete={closereconfirmdelete}
                        deletemodalask={deletemodalask}
                        setEditOpen={setOpenEditDropDownModel}
                    // permissionClass={permissionClass}
                    />
                    : ""}

            </div>
        </div>


    )


    const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
    }



    useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
        return () => {
            setDeleteid(undefined);
        };
    }, []);
    const handleConfirmDelete = () => {
        setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
    };

    const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
    };
    useEffect(() => {
        if (forcefullysuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullyerror) {
            setShow(true);
            setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullydeleteerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
    ]);

    useEffect(() => {
        if (deleteMultipleSuccess) {
            const showdepenedancymsg = deleteMultiple?.data?.data;
            const dependacyfound = showdepenedancymsg?.filter((color: any) => {
                if (
                    color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
                ) {
                    return color;
                }
            });
            if (dependacyfound?.length === deleteMultipleid?.length) {
                deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
            } else {
                setConfirmforcemodal(true);
            }
        } else if (deleteMultipleIsError) {
            setShow(true);
            setshowmessage("danger");
        } else if (deletesucess) {
            if (deletedata.data?.data?.length > 0) {
                setConfirmforcemodal(true);
            } else if (deleteid) {
                setShow(true);
                setshowmessage("success");
                deleteforce({ deleteid, apiname: deleteApiName });
            }
        }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

    const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
    };




    const [Visiting, setVisiting] = useState([])
    const [uploadVisiting, setUploadVisiting] = useState<UploadFile[]>([])
  
    const VisitingOnChange = (e: any) => {
      e.file.status = "done"
  
      const tempFiles = e.fileList.map((file: any) => {
        return file.originFileObj
      })
      setVisiting(tempFiles)
      setUploadVisiting(e.fileList)
  
    }
    const [ConditionImg, setConditionImg] = useState([])
    const [uploadConditionImg, setUploadConditionImg] = useState<UploadFile[]>([])
  
    const ConditionImgOnChange = (e: any) => {
      e.file.status = "done"
  
      const tempFiles = e.fileList.map((file: any) => {
        return file.originFileObj
      })
      setConditionImg(tempFiles)
      setUploadConditionImg(e.fileList)
  
    }
    const [Security, setSecurity] = useState([])
    const [uploadSecurity, setUploadSecurity] = useState<UploadFile[]>([])
  
    const SecurityOnChange = (e: any) => {
      e.file.status = "done"
  
      const tempFiles = e.fileList.map((file: any) => {
        return file.originFileObj
      })
      setSecurity(tempFiles)
      setUploadSecurity(e.fileList)
  
    }

    const [ConditionPDF, setConditionPDF] = useState([])
    const [uploadConditionPDF, setUploadConditionPDF] = useState<UploadFile[]>([])
  

    const ConditionPDFChange = (e: any) => {
        e.file.status = "done"
    
        const tempFiles = e.fileList.map((file: any) => {
          return file.originFileObj
        })
        setConditionPDF(tempFiles)
        setUploadConditionPDF(e.fileList)
    
      }



    return (
        <div>
            <div className="" >
                <section className="content-header py-1 px-3">
                    {/* <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <h4>Add {pageName}</h4>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                                <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">{pageName}</Link></li>
                                <li className="breadcrumb-item active">Add {pageName}</li>
                            </ol>
                        </div>
                    </div>
                </div> */}
                </section>
                <section className="">
                    <div className="">
                        <div className="">
                            {/* <div className="card-header">
                            <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Source Name</span></h5>
                        </div> */}
                            <form ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="" >
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                {/* <div className="col-sm-4 mt-2">
                                            <label >Person Type <span style={{ color: 'red' }}>*</span></label>
                                            <select className="form-select" {...register("type", { onChange:onPersonTypeChange, required: { value: true, message: "Priority is required" } })}>

                                                <option value={""} selected>Select Any</option>
                                                <option value="Customer">Customer</option>
                                                <option value="Employee">Employee</option>
                                                </select>
                                                <span className='text-red w-100'>{errors?.type?.message}</span>

                                            </div> */}
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Account Type <span style={{ color: "red" }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("group", { required: { value: true, message: "Account Type is required" } })}
                                                            placeholder={'Account Type'}
                                                            onChange={handleChangeAccount}
                                                            // value={forAccountValue}
                                                            options={optionsAccount}
                                                            components={customIndicator}
                                                            value={forAccountValue}

                                                            className="dropdown-select"

                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenAccount(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.group?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label >Customer Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Customer Name" {...register("customer_name", { onChange:handleValue, required: { value: true, message: "Customer Name is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.customer_name?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">City Name & District Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("city_name_and_distict_name", { required: { value: true, message: "City Name & District Name is required" } })}
                                                            onChange={handleChangeCity}
                                                            options={cityOptions}
                                                            components={customIndicator}
                                                            value={forCityValue}
                                                            maxMenuHeight={150}
                                                            // isOptionDisabled={(options:any)=>
                                                            //     {
                                                            //         if(options.status === "inactive" || options.deleted == true){
                                                            //         return options
                                                            //         }
                                                            //        }}

                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenCity(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.city_name_and_distict_name?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label >Person Organization Type <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                        {...register("customer_organization_type", { required: { value: true, message: "Person Organization Type is required" } })}
                                                        isClearable={() => {
                                                            setValueOrganizationType(null)
                                                            setValue(null)
                                                        }}
                                                        components={customCreatableIndicator}
                                                        options={optionsOrganizationType}
                                                        onCreateOption={handleCreateOrganizationType}
                                                        onChange={handleChangeOrganizationType}
                                                        onMenuOpen={() => OrganizationTypeRefetch()}
                                                        value={valueOrganizationTypeCreatable == null ? valueOrganizationType : valueOrganizationTypeCreatable}
                                                    />
                                                    <span className='text-red w-100'>{errors?.customer_organization_type?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label >Type Of Business <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                        {...register("type_of_business", { required: { value: true, message: "Type Of Business is required" } })}
                                                        isClearable={() => {
                                                            setValueTypeOfBusiness(null)
                                                            setValue(null)
                                                        }}
                                                        components={customCreatableIndicator}
                                                        options={optionsTypeOfBusiness}
                                                        onCreateOption={handleCreateTypeOfBusiness}
                                                        onChange={handleChangeTypeOfBusiness}
                                                        onMenuOpen={() => TypeOfBusinessRefetch()}
                                                        value={valueTypeOfBusinessCreatable == null ? valueTypeOfBusiness : valueTypeOfBusinessCreatable}
                                                    />
                                                    <span className='text-red w-100'>{errors?.type_of_business?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >Person Occupation <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                        {...register("occupation", { required: { value: true, message: "Type Of Business is required" } })}
                                                        isClearable={() => {
                                                            setValuePersonOccupation(null)
                                                            setValue(null)
                                                        }}
                                                        components={customCreatableIndicator}
                                                        options={optionsPersonOccupation}
                                                        onCreateOption={handleCreatePersonOccupation}
                                                        onChange={handleChangePersonOccupation}
                                                        onMenuOpen={() => PersonOccupationRefetch()}
                                                        value={valuePersonOccupationCreatable == null ? valuePersonOccupation : valuePersonOccupationCreatable}
                                                    />
                                                    <span className='text-red w-100'>{errors?.occupation?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label >Person Sales Status <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                        {...register("customer_sales_status", { required: { value: true, message: "Type Of Business is required" } })}
                                                        isClearable={() => {
                                                            setValuePersonSalesStatus(null)
                                                            setValue(null)
                                                        }}
                                                        components={customCreatableIndicator}
                                                        options={optionsPersonSalesStatus}
                                                        onCreateOption={handleCreatePersonSalesStatus}
                                                        onChange={handleChangePersonSalesStatus}
                                                        onMenuOpen={() => PersonSalesStatusRefetch()}
                                                        value={valuePersonSalesStatusCreatable == null ? valuePersonSalesStatus : valuePersonSalesStatusCreatable}
                                                    />
                                                    <span className='text-red w-100'>{errors?.customer_sales_status?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label >Person Purchase Type <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                        {...register("customer_buying_type", { required: { value: true, message: "Type Of Business is required" } })}
                                                        isClearable={() => {
                                                            setValuePersonPurchaseType(null)
                                                            setValue(null)
                                                        }}
                                                        components={customCreatableIndicator}
                                                        options={optionsPersonPurchaseType}
                                                        onCreateOption={handleCreatePersonPurchaseType}
                                                        onChange={handleChangePersonPurchaseType}
                                                        onMenuOpen={() => PersonPurchaseTypeRefetch()}
                                                        value={valuePersonPurchaseTypeCreatable == null ? valuePersonPurchaseType : valuePersonPurchaseTypeCreatable}
                                                    />
                                                    <span className='text-red w-100'>{errors?.customer_buying_type?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="manualcode_Disc_1">Company Start Date <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <DatePicker
                                                            className="form-control"
                                                            format={dateFormatList}
                                                            // value={forPointStartDateValue}
                                                            // disabledDate={disabledDate}
                                                            value={JoiningDate}
                                                            {...register("company_date_of_birth", { required: { value: true, message: "Joining Date is required" } })}

                                                            onChange={handleJoiningDate}
                                                        />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.company_date_of_birth?.message}</span>
                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name_and_grade">Person From Party Name </label>
                                                    <div className="d-flex row">
                                                        <Select
                                                            {...register("customer_from_party_name", { required: { value: false, message: "Person From Party Name is required" } })}
                                                            placeholder={'Person From Party Name'}
                                                            onChange={handleChangeEmployee}
                                                            value={EmployeeValue}
                                                            options={optionsEmployee}
                                                            components={customIndicator}
                                                            maxMenuHeight={150}
                                                        />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.customer_from_party_name?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Weekly Off Day Name <span style={{ color: "red" }}>*</span></label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueWeeklyOff(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("weekly_off", { required: { value: true, message: "Weekly Off Day Name is required" } })}
                                                        placeholder={'Weekly Off Day Name'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsWeeklyOff}
                                                        onMenuOpen={() => WeeklyOffRefetch()}
                                                        onCreateOption={handleCreateWeeklyOff}
                                                        onChange={handleChangeWeeklyOff}
                                                        value={valueWeeklyOffCreatable == null ? valueWeeklyOff : valueWeeklyOffCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.weekly_off?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name_and_grade">Customer Grade </label>
                                                    <div className="d-flex row">
                                                        <Select
                                                            {...register("customer_grade", { required: { value: false, message: "Customer Grade is required" } })}
                                                            placeholder={'Customer Grade'}
                                                            onChange={handleChangeGrade}
                                                            value={forGradeValue}
                                                            options={optionsGrade}
                                                            components={customIndicator}
                                                            maxMenuHeight={150}
                                                        />
                                                    </div>
                                                    {/*<div className="d-flex row">
                                                        <Select
                                                            {...register("customer_grade", { required: { value: false, message: "Customer Grade is required" } })}
                                                            placeholder={'Customer Grade'}
                                                            onChange={handleChangeGrade}
                                                            value={forGradeValue}
                                                            options={optionsGrade}
                                                            components={customIndicator}
                                                            maxMenuHeight={150}
                                                        />
                                                    </div>*/}
                                                    <span className='text-red w-100'>{errors?.customer_grade?.message}</span>

                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Administration Type <span style={{ color: "red" }}>*</span></label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueAdministrationType(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("administration_type", { required: { value: true, message: "Administration Type is required" } })}
                                                        placeholder={'Administration Type'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsAdministrationType}
                                                        onMenuOpen={() => AdministrationTypeRefetch()}
                                                        onCreateOption={handleCreateAdministrationType}
                                                        onChange={handleChangeAdministrationType}
                                                        value={valueAdministrationTypeCreatable == null ? valueAdministrationType : valueAdministrationTypeCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.administration_type?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name_and_grade">Reference By </label>
                                                    <div className="d-flex row">
                                                        <Select
                                                            {...register("reference", { required: { value: false, message: "Reference By is required" } })}
                                                            placeholder={'Reference By'}
                                                            onChange={handleChangeRefrence}
                                                            value={RefrenceValue}
                                                            options={optionsRefrence}
                                                            components={customIndicator}
                                                            maxMenuHeight={150}
                                                        />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.reference?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Other Reference By </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Other Reference By"
                                                            disabled={!showOtherRef}
                                                            {...register("other_reference", { onChange: handleValue, required: { value: false, message: "Other Reference By is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.other_reference?.message}</span>
                                                </div>


                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Person First Name <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Person First Name" {...register("name", { onChange: handleValue, required: { value: true, message: "Person First Name is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.name?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Person Middle Name <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Person Middle Name" {...register("father_name", { onChange: handleValue, required: { value: true, message: "Person Middle Name is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.father_name?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Person Last Name <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Person Last Name" {...register("surname", { onChange: handleValue, required: { value: true, message: "Person Last Name is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.surname?.message}</span>
                                                </div> */}


                                                <div className="col-sm-3 mt-2">
                                                    <label >Re-Mark</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control"

                                                            placeholder={'Re-Mark'}


                                                            {...register('re_mark', { required: { value: false, message: "Field is required" } })}
                                                        //  {...register("document_number", { required: { value: true, message: "Field is required" } })} 
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.re_mark?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label >Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Field is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>

                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Visiting Card Image All</label>
                                                    <Upload
                                                        {...register("visiting_card")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={VisitingOnChange}
                                                        multiple={true}
                                                        defaultFileList={uploadVisiting}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload>

                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Condition Image All</label>
                                                    <Upload
                                                        {...register("condition_image")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={ConditionImgOnChange}
                                                        multiple={true}
                                                        defaultFileList={uploadConditionImg}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload>

                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Security Cheque Image</label>
                                                    <Upload
                                                        {...register("security_cheque")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={SecurityOnChange}
                                                        multiple={true}
                                                        defaultFileList={uploadSecurity}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload>

                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Condition PDF All</label>
                                                    <Upload
                                                        {...register("condition_pdf")}
                                                        accept=".doc,.docx,.xml,.pdf"
                                                        listType="text"
                                                        onChange={ConditionPDFChange}
                                                        multiple={true}
                                                        defaultFileList={uploadConditionPDF}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"pdf"}
                                                    >
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">

                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                        onClick={handleSubmit(onSubmit)}
                                                    >Submit</button>
                                                    <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>
                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetail")}>Cancel</button>
                                                    {/* <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => setCurrentStep(currentStep - 1)}>Back</button> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
            <Account_Model refetchForm={accountRefetch} open={openAccount} setOpen={setOpenAccount} setSubmitted={setSubmittedAccount} />
            <City_Model refetchForm={cityRefetch} open={openCity} setOpen={setOpenCity} setSubmitted={setSubmittedCity} />


            {/* Edit Modal HTML */}
            <Modal
                show={deleteConfirm}
                onHide={() => setDeleteConfirm(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete {""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {" "}
                        <span className="text-center">Sure you want to delete it?</span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setDeleteConfirm(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={handleMultipleDeleted}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Deleteforce
                key={renderKey}
                handleDeletedforce={handleDeletedforce}
                reconfirmdelete={confirmforcemodal}
                masterName={""}
                closereconfirmdelete={closereconfirmdelete}
                referance={
                    deletesucess
                        ? deletedata
                        : ""
                }
                apiname={apiName}
            />
            <Deletesingle
                key={renderKey}
                confirmmodal={deleteConfirmsingle}
                confirmmodalclose={confirmmodalclose}
                masterName={""}
                confirmmodalsubmit={confirmmodalsubmit}
                apiname={apiName}
            />

            <SimpleInput_Edit key={renderKey}
                refetchForm={handleRefetch}
                open={OpenEditDropDownModel}
                setOpen={setOpenEditDropDownModel}
                id={editId}
                // superAccountId={superAccountId} 
                // optionsSuperAccount={optionsSuperAccount}
                // isSuperAccount={isSuperAccount}
                apiname={apiName} />

        </div>
    )
}

export default Add_Basic_Customer_Details

