import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Restricted from "../../Roles/Restricted";
// import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import axios from "axios";
import FileDownload from 'js-file-download';
import { BiDotsVerticalRounded } from "react-icons/bi";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Duplicate_Granual from "../../pages/Production/Granual/Duplicate_Granual";



const Duplicate_Granual_Action = ({ row, apiname, deletemodalask, permissionClass, refetch, dos}: any) => {

    const [onOpen,setOnOpen]:any = useState(false)




  return (
    <>
      <div

        className="vertical-align-center"
        style={{
          display: "inline-block !important",
          justifyContent: "space-evenly !important",
        }}
      >
          <button className="btn btn-primary text-white mx-2" onClick={()=>setOnOpen(true)}> Duplicate </button>
                
                
          {onOpen  && <Duplicate_Granual setOnOpen={setOnOpen} rowData={row.original}
        //   setNewAdded={setNewAdded}
          />}


        


      </div>
    </>
  );
};

export default Duplicate_Granual_Action;

