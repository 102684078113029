import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Restricted from "../../Roles/Restricted";
const Maintenance_Responsible = ({ row, apiname, deletemodalask,permissionClass }: any) => {
  return (
    <>
      <div
        className="vertical-align-center"
        style={{
          display: "inline-block !important",
          justifyContent: "space-evenly !important",
        }}
      >
        {/* <Restricted
          to={`${permissionClass?.front}.change_${permissionClass?.back}`}
          fallback={""}
        > */}
          <Link
            className="btn btn-primary text-white mx-2"
            to={`/addMaintenanceResponsible/${Number(row.original.id)}`}
          >
            {/* <FiEdit /> */}
            <span className="text-sm">Responsible</span>
          </Link>
        {/* </Restricted> */}

        {/* <Restricted
          to={`${permissionClass?.front}.delete_${permissionClass?.back}`}
          fallback={""}
        >
        <button
          onClick={() => {
            deletemodalask(row.original.id);
          }}
          className="btn btn-primary text-white"
        >
          <RiDeleteBinLine />
        </button>
        </Restricted> */}
      </div>
    </>
  );
};

export default Maintenance_Responsible;

