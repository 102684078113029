
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import { Update, useFetch, useUpdateSingle } from "../../hooks/Api";
// import Actions from "../components/Cell/Actions";
// import { updateBrand, useFetchBrands } from "../../hooks/brand";
// import noImage from "../../images/noImage.png"
// import { useFetchGst, useUpdateGst } from "../hooks/GST";
import noImage from "../../images/noImage.png"
import Modal from "react-bootstrap/esm/Modal";


interface Data {
  selection: any,
  machine_code:any
  machine_name:any
  plant_machine_number:any
  machine_brand:any
  purchase_company_name_and_city:any
  machine_model_no:any
  machine_old_new:any
  machine_department:any
  hsn_code:any
  machine_manufacturer_date	:any
  machine_location:any
  machine_length_mm:any
  machine_width_mm:any
  machine_height_mm:any
  machine_weight:any
  machine_description:any
  product_categories:any
  remark:any
  machine_purchase_date:any
  service_mobile_no:any
  guarantee_yes_no:any
  guarantee_months:any
  warranty_yes_no:any
  warranty_time:any

  image:any
  
  pdf:any
 
  created_at:any

  created_by:any
  updated_by:any
  updated_at:any

  status: any;
  actions: any;
}

export const titleofheaders=[
  {
    id: `srno`,
    Header: `Sr.No`,
    
  },




  {
    id: `machine_code`,
    Header: `Machine Code`,
    

  },
  {
    id: `machine_name`,
    Header: `Machine Name`,
    

  },
  {
    id: `plant_machine_number`,
    Header: `Plant Machine Number`,
    

  },
  {
    id: `machine_brand`,
    Header: `Machine Brand`,
    

  },
  {
    id: `purchase_company_name_and_city`,
    Header: `Compony Name`,
    

  },
  {
    id: `machine_model_no`,
    Header: `Machine Model No`,
    

  },

  {
    id: `machine_old_new`,
    Header: `Machine New-Old`,
    

  },
  {
    id: `machine_department`,
    Header: `Machine Department`,
    

  },
  {
    id: `hsn_code`,
    Header: `HSN Code`,
    

  },
  
  {
    id: `machine_manufacturer_date`,
    Header: `Manufacturer Date`,
    

  },
  {
    id: `machine_location`,
    Header: `Machine Location`,
    

  },
  {
    id: `machine_length_mm`,
    Header: `Machine Length MM`,
    

  },
  {
    id: `machine_width_mm`,
    Header: `Machine Width MM`,
    

  },
  {
    id: `machine_height_mm`,
    Header: `Machine Height MM`,
    

  },
  {
    id: `machine_weight`,
    Header: `Machine Weight`,
    

  },
  {
    id: `machine_description`,
    Header: `Machine Description`,
    

  },
  {
    id: `remark`,
    Header: `Remark`,
    

  },
  {
    id: `machine_purchase_date`,
    Header: `Purchase Date`,
    

  },
  {
    id: `service_mobile_no`,
    Header: `Service Mobile No.`,
    

  },
  {
    id: `guarantee_yes_no`,
    Header: `Guarantee Month`,
    

  },
  {
    id: `guarantee_months`,
    Header: `Guarantee Month`,
    

  },
  {
    id: `warranty_yes_no`,
    Header: `Warranty Month`,
    

  },
  {
    id: `warranty_time`,
    Header: `Warranty Month`,
    

  },
 
  {
    id: 'created_at',
    Header: 'Created at',
    
  },


  {
    id: 'created_by',
    Header: 'Created By',
    

 
  },
  {
    id: 'updated_at',
    Header: 'Updated At',
    
  },
  {
    id: 'updated_by',
    Header: 'Updated By',
    
  },


  {
    id: 'status',
    Header: 'Status',
      },

  {
    id: 'Actions',
    Header: 'Actions',

  
  },
]



export const Columns_Machine: any  = [



  {
    id: `srno`,
    Header: () => <div style={{display:"inline"}}>Sr<br/>No</div>,
    sticky:"left",
  },




  {
    id: `machine_code`,
    Header: () => <div style={{display:"inline"}}>Machine<br/>Code</div>,

    accessor:'machine_code',
    sticky:"left",
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.machine_code}
    </div>

  },
  {
    id: `machine_name`,
    Header: () => <div style={{display:"inline"}}>Machine<br/>Name</div>,

    accessor:'machine_name',
    sticky:"left",
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.hsn_code?.particular_sector_name?.name?row.original?.hsn_code?.particular_sector_name?.name:<span style={{ color: 'red'}}>****</span>}
    </div>

  },
  {
    id: `machine_department`,
    Header: () => <div style={{display:"inline"}}>Machine<br/>Department</div>,

    accessor:'machine_department',
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.machine_department?.department}
    </div>

  },
  {
    id: `product_categories`,
    Header: () => <div style={{display:"inline"}}>This Machine<br/>Product</div>,

    accessor:'product_categories',
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.product_categories?.name}
    </div>

  },
  {
    id: `plant_machine_number`,
    Header: () => <div style={{display:"inline"}}>Plant Machine<br/>Number</div>,

    accessor:'plant_machine_number',
    Cell: ({ row }: any) => 
    
    <div>
      {row.original?.plant_machine_number?row.original?.plant_machine_number:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>

  },
  {
    id: `machine_model_no`,
    Header: () => <div style={{display:"inline"}}>Machine<br/>Model No</div>,

    accessor:'machine_model_no',
    Cell: ({ row }: any) => 
    
    <div>
      {row.original?.machine_model_no?row.original?.machine_model_no:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

    </div>

  },
 
  {
    id: `hsn_code`,
    Header: () => <div style={{display:"inline"}}>Machine Name & Type &<br/>Category & HSN & GST%</div>,

    accessor:'hsn_code',
    Cell: ({ row }: any) => {
    return(
    <div>
      {row.original?.hsn_code?.grade_type?.material_type_name}::{row.original?.hsn_code?.particular_sector_name?.name?row.original?.hsn_code?.particular_sector_name?.name:<span style={{ color: 'red'}}>****</span>}::
      {row.original?.hsn_code?.product_categories?.name?row.original?.hsn_code?.product_categories?.name:<span style={{ color: 'red'}}>****</span>}::
      {row.original?.hsn_code?.hsn_code}::{row.original?.hsn_code?.gst?.india_GST}


    </div>
    )}
  },

  {
    id: `gst_type`,
    Header: () => <div style={{display:"inline"}}>GST<br/>Type</div>,

    accessor:'gst_type',
    Cell: ({ row }: any) => 
    
    <div>
      {row.original?.hsn_code?.gst_type?.name}

    </div>

  },
  {
    id: `type_name_gst`,
    Header: () => <div style={{display:"inline"}}>Type Name & Density<br/>& Sutra & GST%</div>,

    accessor:'type_name_gst',
    Cell: ({ row }: any) => {
    return(
    <div>
      {row.original?.hsn_code?.grade_type?.material_type_name}::
      {row.original?.hsn_code?.grade_type?.material_density === "0.000" || row.original?.hsn_code?.grade_type?.material_density=== null 
      ? <span style={{ color: 'red'}}>0.000</span>:row.original?.hsn_code?.grade_type?.material_density}::
      {row.original?.hsn_code?.grade_type?.grade_sutra=== null?<span style={{ color: 'red'}}>****</span>:row.original?.hsn_code?.grade_type?.grade_sutra}
      ::{row.original?.hsn_code?.grade_type?.gst?.india_GST}


    </div>
    )}
  },
  {
    id: `hsn`,
    Header: () => <div style={{display:"inline"}}>HSN<br/>Code</div>,

    accessor:'hsn',
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.hsn_code?.hsn_code}
    </div>

  },
  {
    id: `gst`,
    Header: () => <div style={{display:"inline"}}>GST<br/>%</div>,

    accessor:'gst',
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.hsn_code?.gst?.india_GST}
    </div>

  },
  {
    id: `machine_purchase_date`,
    Header: () => <div style={{display:"inline"}}>Purchase<br/>Date</div>,

    accessor:'machine_purchase_date',
    Cell: ({ row }: any) => 
    
    <div>
      {row.original?.machine_purchase_date?row.original?.machine_purchase_date:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

    </div>

  },
  {
    id: `machine_manufacturer_date`,
    Header: () => <div style={{display:"inline"}}>Machine<br/>Manufacturer Date</div>,

    accessor:'machine_manufacturer_date',
    Cell: ({ row }: any) => 
    
    <div>
      {row.original?.machine_manufacturer_date?row.original?.machine_manufacturer_date:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

    </div>

  },


  {
    id: `machine_location`,
    Header: () => <div style={{display:"inline"}}>Machine<br/>Location</div>,

    accessor:'machine_location',
    Cell: ({ row }: any) => 
    
    <div>
      {row.original?.machine_location?.name}

    </div>

  },
  
  
  
 
  
  
  
  
  {
    id: `machine_length_mm`,
    Header: () => <div style={{display:"inline"}}>Machine<br/>Length MM</div>,

    accessor:'machine_length_mm',
    Cell: ({ row }: any) => 
    
    <div>
      {row.original?.machine_length_mm?row.original?.machine_length_mm:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

    </div>

  },
  {
    id: `machine_width_mm`,
    Header: () => <div style={{display:"inline"}}>Machine<br/>Width MM</div>,

    accessor:'machine_width_mm',
    Cell: ({ row }: any) => 
    
    <div>
      {row.original?.machine_width_mm?row.original?.machine_width_mm:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

    </div>

  },
  {
    id: `machine_height_mm`,
    Header: () => <div style={{display:"inline"}}>Machine<br/>Height MM</div>,

    accessor:'machine_height_mm',
    Cell: ({ row }: any) => 
    
    <div>
      {row.original?.machine_height_mm?row.original?.machine_height_mm:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

    </div>

  },
  {
    id: `machine_weight`,
    Header: () => <div style={{display:"inline"}}>Machine<br/>Weight KG</div>,

    accessor:'machine_weight',
    Cell: ({ row }: any) => 
    
    <div>
      {row.original?.machine_weight?row.original?.machine_weight:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

    </div>

  },
  {
    id: `machine_brand`,
    Header: () => <div style={{display:"inline"}}>Machine<br/>Brand</div>,

    accessor:'machine_brand',
    Cell: ({ row }: any) => 
    
    <div>
      {row.original?.machine_brand?.name}

    </div>

  },
  {
    id: `purchase_company_name_and_city`,
    Header: () => <div style={{display:"inline"}}>Machine Company<br/>Name & City</div>,

    accessor:'purchase_company_name_and_city',
    Cell: ({ row }: any) => 
    
    <div>
      {row.original?.purchase_company_name_and_city?.name}

    </div>

  },
  
  {
    id: `machine_old_new`,
    Header: () => <div style={{display:"inline"}}>Machine<br/>New-Old</div>,

    accessor:'machine_old_new',
    Cell: ({row}:any) => {
      const convertToUpper = row.original.machine_old_new
      if (!convertToUpper) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
     
    }
  },
  {
    id: `country_code`,
    Header: () => <div style={{display:"inline"}}>Country Mobile<br/>Code</div>,
    accessor:'country_code',
    Cell: ({ row }: any) => 
    
    <div>
      {row.original?.country_code?row.original?.country_code:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

    </div>

  },
  {
    id: `service_mobile_no`,
    Header: () => <div style={{display:"inline"}}>Service Mobile<br/>Number</div>,

    accessor:'service_mobile_no',
    Cell: ({ row }: any) => 
    
    <div>
      {row.original?.service_mobile_no?row.original?.service_mobile_no:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

    </div>

  },
  {
    id: `guarantee_yes_no`,
    Header: () => <div style={{display:"inline"}}>Guarantee<br/>Yes-No</div>,
    accessor:'guarantee_yes_no',
    Cell: ({row}:any) => {
      const convertToUpper = row.original.guarantee_yes_no
      if (!convertToUpper) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
     
    }
    // Cell: ({ row }: any) => 
    
    // <div>
    //   {row.original?.guarantee_yes_no?row.original?.guarantee_yes_no:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

    // </div>

  },
  {
    id: `guarantee_months`,
    Header: () => <div style={{display:"inline"}}>Guarantee<br/>Month</div>,

    accessor:'guarantee_months',
    
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.guarantee_months?row.original?.guarantee_months:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>

  },
  {
    id: `warranty_yes_no`,
    Header: () => <div style={{display:"inline"}}>Warranty<br/>Yes-No</div>,
    // Header: `Warranty Month`,
    accessor:'warranty_yes_no',
    Cell: ({row}:any) => {
      const convertToUpper = row.original.warranty_yes_no
      if (!convertToUpper) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
     
    }
    // Cell: ({ row }: any) => 
    
    // <div>
    //   {row.original?.guarantee_yes_no?row.original?.guarantee_yes_no:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

    // </div>

  },
  {
    id: `warranty_time`,
    Header: () => <div style={{display:"inline"}}>Warranty<br/>Month</div>,

    accessor:'warranty_time',
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.warranty_time?row.original?.warranty_time:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>

  },

  // {
  //   id: `machine_description`,
  //   Header: () => <div style={{display:"inline"}}>Machine<br/>Description</div>,

  //   accessor:'machine_description',
  //   Cell: ({ row }: any) => 
    
  //   <div>
  //     {row.original?.machine_description?row.original?.machine_description:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

  //   </div>

  // },
  {
    id: `remark`,
    Header: `Remark`,
    accessor:'remark',
    Cell: ({ row }: any) => 
    
    <div>
      {row.original?.remark?row.original?.remark:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

    </div>

  },
  
  {
    id: `image`,
    Header: () => <div style={{display:"inline"}}>Machine<br/>Image All</div>,

    // accessor: `logo`,
    Cell: ({ row }: any) =>{
    
    const [deleteConfirm, setDeleteConfirm] = useState(false)


return(
    <>

        <div >

          {!row.original.image?<>
            <a 
          
          href="#"
          
          // data-large-src={row.original.image?row.original.image:noImage} data-toggle="modal"
          >
            <img src={noImage}  height={60} width={100}/>
            </a>
          </>:<>
          <a 
          onClick={()=>setDeleteConfirm(true)}
          href={`#galleryModal${row.original.name}`}
          
          data-large-src={(row.original.image.length > 0 ? row.original.image.at(-1).doc : noImage)} data-toggle="modal">
            <img src={(row.original.image.length > 0 ? row.original.image.at(-1).doc : noImage)}  height={60} width={100}/>
            </a>
          </>}
         
        </div>








<Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
        centered    >
        
        <Modal.Body>  
        <img src={(row.original.image.length > 0 ? row.original.image.at(-1).doc : noImage)} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid"/>

        </Modal.Body>
        <Modal.Footer>
        <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

        </Modal.Footer>
      </Modal>
        </>)
    }
  
  
    },



  {
    id: `pdf`,
    Header: () => <div style={{display:"inline"}}>Machine PDF<br/> Drawing All</div>,

    // accessor: `pdf`,
    Cell: ({ row }: any) =>
    


        <div>
          <a target="_blank" href={(row.original.pdf.length > 0 ? row.original.pdf.at(-1).doc : null)}>
            {(row.original.pdf.length > 0 ? row.original.pdf.at(-1).doc?.split('/').pop():<span className="text-danger">No Document Uploaded</span>)}
            </a>
        </div>

    },




  {
    id: 'status',
    Header: 'Status',
    accessor:'status',

 
  },

  
 
   
  {
    id: 'created_at',
    Header: () => <div style={{display:"inline"}}>Created<br/>At</div>,
    accessor:'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{display:"inline"}}>Created<br/>By</div>,
    accessor:'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username

 
  },
  {
    id: 'updated_at',
    Header: () => <div style={{display:"inline"}}>Updated<br/>At</div>,
    accessor:'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{display:"inline"}}>Updated<br/>By</div>,
    accessor:'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },
  

  {
    id: 'Actions',
    Header: 'Actions',

  
  },
  
  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_Machine, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows} = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}machine/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



