import React, { useEffect, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../components/Header/header.css";
import { useNavigate, useParams } from "react-router-dom";
import { useGSTEdit, useUpdateGst } from "../../hooks/GST";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import { useFetchSingle, useUpdateSingle } from "../../hooks/Api";
import Select from "react-select"
import axios from 'axios'
import { Alert } from "../../components/Model/Model";
// import { Alert } from "../Model/Model";
// import Select from 'react-select/async';

const Edit_StickPacking = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_STICK_PACKING_API?.toString()
    const { data } = useFetchSingle(id,apiname)
    const { mutate, isSuccess, isError,error,data: gstUpdated_response }: any = useUpdateSingle()

    const { register, handleSubmit, setError, formState: { errors }, reset, setValue } = useForm();

   



    useEffect(() => {
        if (data) {
            reset(data)
        }
    }, [data]);


    const onSubmit = (materialtypeDetails: any) => {
    //   materialtypeDetails.gst = materialtypeDetails.gst.id
      

        const formData = new FormData();
        for (const [key, value] of Object.entries(materialtypeDetails)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
             
        }
        let data = {
          apiname: apiname,
          id:id,
          data: formData
      }
        mutate(data);
        
    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")


    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listStickPacking', { state: { showStickPackingEditToast: true }})

        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [gstUpdated_response, error,isSuccess])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);


    const handleValue=(e:any)=>{
        e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
    }


    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit Stick Packing</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/listColor" className="text-decoration-none text-black">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Masters</a></li>
                                    <li className="breadcrumb-item"><a href="/brandlist" className="text-decoration-none text-black">Stick Packing</a></li>
                                    <li className="breadcrumb-item active">Edit Stick Packing</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Edit GST</span></h5>
                            </div> */}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message.name} /> : ""}


                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Stick Packing Type</label>
                                                    <input type="text" className="form-control" id="exampleInputBrand"  {...register("name",{onChange:handleValue})} />
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Note</label>
                                                    <input type="text" className="form-control" id="exampleInputBrand"  {...register("note",{onChange:handleValue})} />
                                                </div>


                                               
                                              
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button type="button" className="btn border rounded text-white px-4 mt-2 mx-3"  onClick={handleSubmit(onSubmit)}>Submit</button>
                                                    <button type="button" className="btn border-1 rounded  px-4 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listStickPacking")}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Edit_StickPacking


