import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Columns_Bank_Format, titleofheaders } from "./Columns_Bank_Format"
import Table from "../../../components/Dynamic_Table/Table/Table";
// import { useFetchCheckListMapperDropDown } from "../../hooks/DropDown_Api";
const List_Bank_Format = () => {
  const Breadcrumb_Primary_Title = "Home";
  const Breadcrumb_Primary_Link = "";

  const Breadcrumb_Parent_Title = "Customer";

  const Breadcrumb_Title_Name = "Bank Format";

  const apiname = process.env.REACT_APP_BANK_API?.toString();

  const filter = {

    employee:"",
    type:"",
    customer:"",
    priority:"",
    account_type: "",
    account_number: "",
    swift_code: "",
    iec_code: "",
    ifsc_code:"",
    cheque_print_name: "",
    amount_transfer_account_name: "",
    pan_number: "",
    contact_number: "",
    bank:"",
    city:"",
    branch:"",
    micr:"",
    district:"",
    state:"",
    country:"",
    pincode:"",
  
    created_by:"",
    updated_by:"",
    status:"",

  };

  const addButtonName = "Bank Format";
  const addButtonPageName = "BankFormat"
  const permissionClass = {
    front: "Account",
    back: "bank"
  }
  const stickyStart = 1;
  const stickyEnd = 2;




  return (
    <>
      <div className="content-wrapper">
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{Breadcrumb_Title_Name} List</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to={`/list${Breadcrumb_Primary_Link}`}
                      className="text-decoration-none text-black"
                    >
                      {Breadcrumb_Primary_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      {Breadcrumb_Parent_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {Breadcrumb_Title_Name} List
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="container p-0" style={{ maxWidth: "100%" }}>
                {/* <BasicTable/> */}
                <Table
                  apiname={apiname}
                  filterFields={filter}
                  addButtonName={addButtonName}
                  stickyStart={stickyStart}
                  stickyEnd={stickyEnd}
                  TableColumns={Columns_Bank_Format}
                  addButtonPageName={addButtonPageName}
                  titleofheaders={titleofheaders}
                  permissionClass={permissionClass}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default List_Bank_Format;
