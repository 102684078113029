import React from 'react'
import { RiPencilLine } from 'react-icons/ri'

interface InputField {
  id?: any,
  name?: any,
  onChange?: any,
  onClick?: any,
  type?: any,
  accept?: any,
  className?: any,
  placeholder?: any,
  htmlFor?: any,
  color?: any
  isPencil?:any
  value?:any,
  label?: any
}

const TextBoxComponent = ({ id, name, onChange, type, className, placeholder, label, color ,isPencil=true, value}: InputField) => {
    return (
        <div>
            <div className={className ? className : "input-group"} >
                <input type={type} className={className ? className : "form-control"}
                    id={id} placeholder={placeholder} value={value} onChange={onChange}/>
                  {isPencil && <div className="input-group-append" >
                  <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                   </div>}
            </div>
        </div>
    )
}

export default TextBoxComponent