import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate } from "../../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../../components/focus";


const Add_Quotation = () => {
    const pageName ="Quotation"
    const focus:any = useRef();
    const {onEnterKey} = useFocusOnEnter(focus);
    const navigate = useNavigate()



    const { register, handleSubmit, setError, formState, reset,setValue, getValues  }:any = useForm({
        mode:"onChange"
    });
    const { errors } = formState;

    
    
    const onSubmit = (data: any) => {
       
       
    }
    
    
    
    
    const handleReset = () => {
        reset()
    }
    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Add {pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listState" className="text-decoration-none text-black">{pageName}</Link></li>
                                    <li className="breadcrumb-item active">Add {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Source Name</span></h5>
                            </div> */}
                            <form ref={focus}
                             onKeyUp={event => onEnterKey(event)}
                             onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Company Name</label>
                                                    <Select
                                                            placeholder={'Select Company Name'}
                                                            {...register("sales_inquiry_code", { required: { value: true, message: "Company Name is required" } })}
                                                            // onChange={}
                                                            // options={}
                                                            // value={}
                                                            // isOptionDisabled={(optionsSales: any) => {
                                                            //     if (optionsSales.status === "inactive" || optionsSales.deleted == true) {
                                                            //         return optionsSales
                                                            //     }
                                                            // }}
                                                        />
                                                    <span className='text-red w-100'>{errors?.one?.message}</span>

                                                </div>
                                                
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Villeg city name</label>
                                                    <Select
                                                            placeholder={'Select Villeg city name'}
                                                            {...register("sales_inquiry_code", { required: { value: true, message: "Villeg city name is required" } })}
                                                            // onChange={}
                                                            // options={}
                                                            // value={}
                                                            // isOptionDisabled={(optionsSales: any) => {
                                                            //     if (optionsSales.status === "inactive" || optionsSales.deleted == true) {
                                                            //         return optionsSales
                                                            //     }
                                                            // }}
                                                        />
                                                    <span className='text-red w-100'>{errors?.two?.message}</span>
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Code & Description</label>
                                                    <Select
                                                            placeholder={'Select Product Code & Description'}
                                                            {...register("sales_inquiry_code", { required: { value: true, message: "Product Code & Description is required" } })}
                                                            // onChange={}
                                                            // options={}
                                                            // value={}
                                                            // isOptionDisabled={(optionsSales: any) => {
                                                            //     if (optionsSales.status === "inactive" || optionsSales.deleted == true) {
                                                            //         return optionsSales
                                                            //     }
                                                            // }}
                                                        />
                                                    <span className='text-red w-100'>{errors?.two?.message}</span>
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">HSN Code</label>
                                                    <Select
                                                            placeholder={'Select HSN Code'}
                                                            {...register("sales_inquiry_code", { required: { value: true, message: "HSN Code is required" } })}
                                                            // onChange={}
                                                            // options={}
                                                            // value={}
                                                            // isOptionDisabled={(optionsSales: any) => {
                                                            //     if (optionsSales.status === "inactive" || optionsSales.deleted == true) {
                                                            //         return optionsSales
                                                            //     }
                                                            // }}
                                                        />
                                                    <span className='text-red w-100'>{errors?.two?.message}</span>
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">UOM</label>
                                                    <Select
                                                            placeholder={'Select UOM'}
                                                            {...register("sales_inquiry_code", { required: { value: true, message: "UOM is required" } })}
                                                            // onChange={}
                                                            // options={}
                                                            // value={}
                                                            // isOptionDisabled={(optionsSales: any) => {
                                                            //     if (optionsSales.status === "inactive" || optionsSales.deleted == true) {
                                                            //         return optionsSales
                                                            //     }
                                                            // }}
                                                        />
                                                    <span className='text-red w-100'>{errors?.two?.message}</span>
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Qty</label>
                                                    <Select
                                                            placeholder={'Select Product Qty'}
                                                            {...register("sales_inquiry_code", { required: { value: true, message: "Product Qty is required" } })}
                                                            // onChange={}
                                                            // options={}
                                                            // value={}
                                                            // isOptionDisabled={(optionsSales: any) => {
                                                            //     if (optionsSales.status === "inactive" || optionsSales.deleted == true) {
                                                            //         return optionsSales
                                                            //     }
                                                            // }}
                                                        />
                                                    <span className='text-red w-100'>{errors?.two?.message}</span>
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Brand</label>
                                                    <Select
                                                            placeholder={'Select Product Brand'}
                                                            {...register("sales_inquiry_code", { required: { value: true, message: "Product Brand is required" } })}
                                                            // onChange={}
                                                            // options={}
                                                            // value={}
                                                            // isOptionDisabled={(optionsSales: any) => {
                                                            //     if (optionsSales.status === "inactive" || optionsSales.deleted == true) {
                                                            //         return optionsSales
                                                            //     }
                                                            // }}
                                                        />
                                                    <span className='text-red w-100'>{errors?.two?.message}</span>
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Mould Diameter</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder="Mould Diameter" {...register("two", { required: { value: true, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.two?.message}</span>
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Color</label>
                                                    <Select
                                                            placeholder={'Select Product Color'}
                                                            {...register("sales_inquiry_code", { required: { value: true, message: "Product Color is required" } })}
                                                            // onChange={}
                                                            // options={}
                                                            // value={}
                                                            // isOptionDisabled={(optionsSales: any) => {
                                                            //     if (optionsSales.status === "inactive" || optionsSales.deleted == true) {
                                                            //         return optionsSales
                                                            //     }
                                                            // }}
                                                        />
                                                    <span className='text-red w-100'>{errors?.two?.message}</span>
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product ML</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product ML" {...register("two", { required: { value: true, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.two?.message}</span>
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Weight</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product Weight" {...register("two", { required: { value: true, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.two?.message}</span>
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Stick Nos.</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder="Stick Nos." {...register("two", { required: { value: true, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.two?.message}</span>
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Cartoon Stick</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder="Cartoon Stick" {...register("two", { required: { value: true, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.two?.message}</span>
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Total qty</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product Total qty" {...register("two", { required: { value: true, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.two?.message}</span>
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Basic Amount</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder="Basic Amount" {...register("two", { required: { value: true, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.two?.message}</span>
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1"> Total Basic Amount</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder=" Total Basic Amount" {...register("two", { required: { value: true, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.two?.message}</span>
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Responsible Person</label>
                                                    <select className="form-select" {...register("three", { required: { value: true, message: "Field is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Mr...</option>
                                                        {/* <option value="inactive">Inactive</option> */}
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.three?.message}</span>

                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">CGST Rate</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder="CGST Rate" {...register("two", { required: { value: true, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.three?.message}</span>

                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">CGST Amount</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder="CGST Amount" {...register("two", { required: { value: true, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.three?.message}</span>

                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">SGST Rate</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder=" SGST Rate" {...register("two", { required: { value: true, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.three?.message}</span>

                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">SGST Amount</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder=" SGST Amount" {...register("two", { required: { value: true, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.three?.message}</span>

                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Total Amount</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder=" Total Amount" {...register("two", { required: { value: true, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.three?.message}</span>

                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Stick Rate</label>
                                                    <div className="input-group">
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder=" Stick Rate" {...register("two", { required: { value: true, message: "Field is required" } })} />
                                                    <div className="input-group-append" >
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' 
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                    </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.three?.message}</span>

                                                </div>
                                                
                                               
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status</label>
                                                    <select className="form-select" {...register("four", { required: { value: true, message: "Field is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.four?.message}</span>

                                                </div>

                                             
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                <button 
                                                type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3" 
                                                style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                
                                                onClick={handleSubmit(onSubmit)}
                                                >Submit</button>
<button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

<button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listState")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Add_Quotation