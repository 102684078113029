import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/esm/Button";
import { useCreate } from '../../hooks/Api';
import { Alert } from "../../components/Model/Model";
import { useForm } from 'react-hook-form';
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from '../focus';
import Select from "react-select"
import { useNavigate } from 'react-router-dom';
import { useFetchBrandDropDown, useFetchColorDropDown, useFetchGSTDropDown } from '../../hooks/DropDown_Api';
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'


const customIndicator = {
    IndicatorsContainer:IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer:IndicatorsContainerCreate
}


export const UOM_Model = ({refetchForm,open,setOpen,setSubmitted}:any) => {
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()
    const [logo, setlogo] = useState(null)
    const [logoPdf, setlogoPdf] = useState(null)

    const [unitnameValue,setUnitNameValue]:any=useState()
    const[baseUnitValue,setBaseUnitValue]:any = useState()

    const onLogoChange = (e:any) => {
        setlogo(e.target.files[0].name)
    }

    const onLogoPDFChange = (e:any) => {
        setlogoPdf(e.target.files[0].name)
    }

    const { register:register, handleSubmit:handleSubmit, formState: { errors },reset,setValue}: any = useForm({
        mode: "onChange"
    });


    const apiname = process.env.REACT_APP_CAPACITY_API?.toString()


    const  { mutate: brandPost, data,isSuccess,error,isError}:any = useCreate(apiname)
  


    useEffect(()=>{
        if(isSuccess){
            setSubmitted(true)
            setOpen(false)
            reset()
            setForGSTValue(null)
        }
        refetchForm()
            },[isSuccess,data])


   
    const [forGST,setForGSTValue]:any = useState([])

 
const handleReset = () => {
    reset()
    setForGSTValue(null)
    }

    const onSubmit = (branddetail: any) => {
        if (baseUnit === "yes") {
            delete branddetail.baseUnit
          
        }
        const formData = new FormData();

        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
        

            


        }
        brandPost(formData);
       
    }
    



    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);


    const isbaseunit=document?.getElementById("isbase")
    const baseunit=document?.getElementById("base")
    
    const keyup=()=>{
        
       
 }

    const apinameForUnit = process.env.REACT_APP_UNIT_SEARCH_FILTER?.toString()


    const { mutate: unitMutate, data:unitSearchData,  }: any = useCreate(apinameForUnit)
   
    const[type,setType] = useState()
    
   
   
    const handleType=(e:any)=>{
   
       unitMutate({"type":e.target.value})
       setType(e.target.value)
   
       
   
    }
   
   
    const [unitOptions,setUnitOptions]:any = useState([])
   
   //  const {data:unitData,refetch:unitRefetch,isSuccess:unitSuccess} = useFetchUnit()
   
   
   //  useEffect(()=>{
   //      setUnitOptions(unitData)
   //  },[unitData])
   
    useEffect(()=>{
       const ok = unitSearchData?.results?.map((data:any)=>{
           return(
               {
                   label:data?.name,
                   value:data?.id,
                   abbreviation:data?.abbreviation
               }
           )
       })
   setUnitOptions(ok)
    },[unitSearchData])
   
    useEffect(()=>{
    },[unitOptions])
   
   
    const [abbreviation,setAbbreviation] = useState("none")
   
   
    const handleChangeUnitName=(e:any)=>{
   
       setValue("name",e?.value)
   
   setAbbreviation(e?.abbreviation)
   setValue("abbreviation",e?.abbreviation)
   setUnitNameValue(e)
   
    }
   
    useEffect(()=>{
   
   
    },[abbreviation])
   
    const apinameForUom = process.env.REACT_APP_UOM_SEARCH_FILTER?.toString()
   
    const [baseUnit,setBaseUnit] = useState("yes")
   
    const { mutate: baseMutate, data: baseData,  }: any = useCreate(apinameForUom)
   
   
   
    const handleBaseUnit=(e:any)=>{
   
       setBaseUnit(e.target.value)
   
       if(e.target.value=="no"){
           baseMutate({"type":type,"is_base_unit":"yes"})
       }
       
   
    }
   
   //  useEffect(()=>{
   
   //     if(baseUnit=="yes"){
   //         unitMutate({"is_base_unit":unitOptions?.is_base_unit})
   
   //     }
   
   //  },[baseUnit])
   
   const [baseUnitOptions,setBaseUnitOptions] = useState([])
   
   
   
   useEffect(()=>{
       const ok = baseData?.results?.map((data:any)=>{
           if(data.is_base_unit=="yes")
           // console.log(data.name);
           return(
              {
               
               label:data?.name?.name,
               value:data?.name?.id,
                   // abbreviation:data?.abbreviation
              }
           )
       })
       setBaseUnitOptions(ok)
    },[baseData])
   
    
    const handleChangeBaseUnit=(e:any)=>{
       setValue("baseUnit",e?.value)
       setBaseUnitValue(e)
   
    }


  


    const handleChangeCapital = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }


  return (
    <>
    <Modal  
    
    size='xl'
    
    show={open} onHide={()=>{
        setShow(false)
        reset()
        setOpen(false)}} aria-labelledby="contained-modal-title-vcenter"
centered    >
  <Modal.Header closeButton>
    <Modal.Title style={{fontSize:'16px'}}>UOM</Modal.Title>
  </Modal.Header>
  <Modal.Body> 
     
  <form 
                             ref={focus}
                             onKeyUp={event => onEnterKey(event)}
                            onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>
                                {show && showmessage === "success" ? <Alert color={showmessage} message={"Successfully Added"} /> : ""}
                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}
                               
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-sm-3 mt-2">
                                                    <label htmlFor="status">U.O.M Type<span style={{ color: 'red' }}>*</span></label>
                                                    <select id="isbase" className="form-select"  {...register("type",
                                                        {
                                                            required: { value: true, message: "Type is required" }

                                                        })}
                                                        onChange={handleType}
                                                        >
                                                        <option value="" selected>Select Any </option>
                                                        <option value={"volume"}>Volume</option>
                                                        <option value={"weight"}>Weight</option>
                                                        <option value={"length"}>Length</option>
                                                        <option value={"packaging"}>Packaging</option>
                                                        


                                                    </select>
                                                    <span className='text-red w-100'>{errors?.type?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Unit Name<span style={{ color: 'red' }}>*</span></label>
                                                    <Select  {...register("name", { required: { value: true, message: "Unit Name is required" } })}
                                                        placeholder={'Unit Name'}
                                                        options={unitOptions}  
                                                        components={customIndicator}
                                                        onChange={handleChangeUnitName}
                                                        value={unitnameValue}
                                                        maxMenuHeight={120}
                                                        />

                                
                                                    <span className='text-red w-100'>{errors?.name?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name">Abbreviation<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="text" className="form-control" disabled id="name"
                                                       
                                                        placeholder="Abbreviation"
                                                            {...register("abbreviation", { required: { value: false, message: "Abbreviation is required" } })}
                                                            
                                                            value={abbreviation}/>
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.abbreviation?.message}</span>
                                                </div>

                                               
                                                

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="status">Is Base Unit <span style={{ color: 'red' }}>*</span></label>
                                                    <select id="isbase" className="form-select" {...register("is_base_unit",
                                                        {
                                                            required: { value: true, message: "Base Unit is required" }

                                                        })}
                                                        
                                                        onChange={handleBaseUnit}
                                                        >
                                                        <option value={""} selected>Select Any </option>
                                                        <option value={"yes"}>Yes</option>
                                                        <option value={"no"}>No</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.is_base_unit?.message}</span>
                                                </div>


                                                {baseUnit=="no"&&
                                                

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Base Unit<span style={{ color: 'red' }}>*</span></label>
                                                    <Select id="base"  {...register("baseUnit", { required: { value: true, message: "Base Unit is required" } })}
                                                        placeholder={'Base Unit'}
                                                        options={baseUnitOptions}  
                                                        components={customIndicator}
                                                        onChange={handleChangeBaseUnit}
                                                        value={baseUnitValue}
                                                        maxMenuHeight={120}

                                                        />

                                
                                                    <span className='text-red w-100'>{errors?.baseUnit?.message}</span>

                                                </div>}


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name">Conversation Ratio<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="number" className="form-control" id="name" placeholder="Conversation Ratio"
                                                            {...register("conversationRatio", { onChange: handleChangeCapital, required: { value: true, message: "Conversation Ratio is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.conversationRatio?.message}</span>
                                                </div>


                                              
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="status">Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status",
                                                        {
                                                            required: { value: true, message: "Status is required" }

                                                        })}>
                                                        <option value="" selected>Select Any </option>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"inactive"}>Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Upload Logo</label>
                                                    <label style={{cursor:"pointer"}}>
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                        <input data-required="image" type="file"  id="file_upload" accept="image/*" className="image-input" data-traget-resolution="image_resolution"  {...register("logo",{onChange:onLogoChange, required:false})} hidden />
                                                    </label>
                                                    <p>{logo}</p>
                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Logo PDF</label>
                                                    <label style={{cursor:"pointer"}}>
                                                    <img src={pdfLogo} alt='' className="uploaded-image" />
                                                    <input data-required="image" type="file"  id="file_upload" accept="application/pdf" className="image-input" data-traget-resolution="image_resolution" {...register("logo_pdf",{onChange:onLogoPDFChange, required:false})} hidden />
                                                    </label>
                                                    <p>{logoPdf}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                   
                                </div>
                            </form>
      
</Modal.Body>
  <Modal.Footer>
  
    <Button variant="info" className="text-white" 
 onClick={handleSubmit(onSubmit)}
 style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
    >
      Submit
    </Button>
    <Button variant="info" className="text-white" style={{ borderColor: "blue", background: "blue", color: "white" }}
 onClick={handleReset}
 >
   Reset
 </Button>
    <Button variant="secondary" style={{ borderColor: "red", background: "red", color: "white" }} onClick={()=>{setOpen(false)}}>
      Cancel
    </Button>
  </Modal.Footer>
</Modal>
    </>
  )
}



