
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import Actions from "../../components/Cell/Actions";
import { useFetch, useUpdateSingle } from "../../hooks/Api";


interface Data {
  selection: any



  type_density:any
  material_type_name:any
  material_density:any

  use_grade_name:any
  use_grade_qty:any
  use_raw_material_name:any
  use_raw_material_Qty: any

  gst:any
  grade_sutra:any
  grade_polymer:any
  created_at:any

  created_by:any
  updated_by:any
  updated_at:any
  status: any;
  actions: any;
}


export const titleofheaders=[
  {
    id: `srno`,
    Header: `Sr No`,
  },
 
  
  {
    id: 'type_density',
    Header: 'Type Name & Density',
  },



  {
    id: `use_grade_name`,
    Header: `Grade Name`,

    

  },
  {
    id: `use_grade_qty`,
    Header: `Grade Qty`,
  

  },
  {
    id: `use_raw_material_name`,
    Header: `Raw Material Name`,
   

  },
  {
    id: `use_raw_material_Qty`,
    Header: `Raw Material Qty`
  

  },



  {
    id: 'material_type_name',
    Header: 'Material Type Name',
  },
  {
    id: `material_density`,
    Header: `Material Density`,
  },
  {
    id: `grade_sutra`,
    Header: `Grade Sutra`,
  },





 
  {
    id: 'gst',
    Header: 'GST %',
  },
 
 
  {
    id: 'grade_polymer',
    Header: 'Grade Polymer',
  },
  {
    id: 'status',
    Header: 'Status',

 
  },

{
id: 'created_at',
Header: `Created At`,
},


{
id: 'created_by',
Header: `Created By`,


},
{
id: 'updated_at',
Header: `Updated At`,

},
{
id: 'updated_by',
Header: `Updated By`,

},
]


export const Columns_Material_Density: any = [



  
  {
    id: `srno`,
    Header: `Sr.No`,
    sticky:"left",

  },


  {
    id: `type_density`,
    Header: () => <div style={{ display: "inline" }}>Type Name & Density & Sutra<br />& GST %</div>,
    accessor:'type_density',
    sticky:"left",

    Cell: ({ row }: any) =>{
    return(
      <div>
      {row.original.material_type_name}::
      {
        row?.original?.material_density === "0.000" || row?.original?.material_density === null
          ? <span style={{ color: 'red'}}>****</span>
          : row?.original?.material_density
      }:: 
      {row.original.grade_sutra === null?<span style={{ color: 'red'}}>****</span>:row.original.grade_sutra}::
      {row?.original?.gst?.india_GST}
    </div>
    )
    }

  },


  {
    id: `use_grade_name`,
    Header: () => <div style={{ display: "inline" }}>Grade<br />Name</div>,

    // Header: `Grade Name`,
    accessor:'use_grade_name',
    Cell: ({ row }: any) =>
    <div>
{row.original.use_grade_name?row.original.use_grade_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>

  },
  {
    id: `use_grade_qty`,
    Header: () => <div style={{ display: "inline" }}>Grade<br />Qty</div>,
    accessor:'use_grade_qty',
    Cell: ({ row }: any) =>
    <div>
{row.original.use_grade_qty?row.original.use_grade_qty:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>

  },
  {
    id: `use_raw_material_name`,
    Header: () => <div style={{ display: "inline" }}>Raw Material<br />Name</div>,
    accessor:'use_raw_material_name',
    Cell: ({ row }: any) =>
    <div>
{row.original.use_raw_material_name?row.original.use_raw_material_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>

  },
  {
    id: `use_raw_material_Qty`,
    Header: () => <div style={{ display: "inline" }}>Raw Material<br />Qty</div>,
    accessor:'use_raw_material_Qty',
    Cell: ({ row }: any) =>
    <div>
{row.original.use_raw_material_name?row.original.use_raw_material_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>

  },
  {
    id: `material_type_name`,
    Header: () => <div style={{ display: "inline" }}> Material<br />Type Name</div>,
    accessor:'material_type_name',

    Cell: ({ row }: any) =>{
    return(
      <div>
      {row.original.material_type_name}
    </div>
    )
    }

  },
  
  {
    id: `material_density`,
    Header: () => <div style={{ display: "inline" }}>Material <br /> Density</div>,
    accessor:'material_density',

    Cell: ({ row }: any) =>
    <div>
{row?.original?.material_density===null?<span style={{ color: 'red',fontSize:"20px" }}>****</span>:row?.original?.material_density}
    </div>

  },
  {
    id: `grade_sutra`,
    Header: () => <div style={{ display: "inline" }}>Grade <br /> Sutra</div>,

    accessor:'grade_sutra',
    Cell: ({ row }: any) =>
    <div>
{row.original.grade_sutra?row.original.grade_sutra:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>

  },
  {
    id: `gst`,
    Header: () => <div style={{ display: "inline" }}>GST<br />%</div>,
    // Header: `GST`,
    accessor:'gst',
    Cell: ({ row }: any) =>
    <div>
{row?.original?.gst?.india_GST}
    </div>

  },

  

  {
    id: `grade_polymer`,
    Header: () => <div style={{ display: "inline" }}>Polymer <br />Name </div>,

    accessor:'grade_polymer',
    Cell: ({ row }: any) =>
    <div>
{row.original.grade_polymer?row.original.grade_polymer:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>

  },
 
 

  {
    id: 'status',
    Header: 'Status',
    accessor:'status',

 
  },


  

  
  {
    id: 'created_at',
    Header: () => <div style={{ display: "inline" }}>Created<br />At</div>,
    accessor:'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{ display: "inline" }}>Created<br />By</div>,
    accessor:'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username

 
  },
  {
    id: 'updated_at',
    Header: () => <div style={{ display: "inline" }}>Updated<br />At</div>,
    accessor:'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{ display: "inline" }}>Updated<br />By</div>,
    accessor:'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },

  {
    id: 'Actions',
    Header: 'Actions',

  
  },
  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_Material_Density, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows} = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}materialdensity/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



