
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import Actions from "../../components/Cell/Actions";
// import { updateBrand, useFetchBrands } from "../../hooks/brand";
// import noImage from "../../images/noImage.png"
import { useFetchGst, useUpdateGst } from "../../hooks/GST";


interface Data {
  selection: any
  name	:any,
  note:any,
  created_at:any

  created_by:any
  updated_by:any
  updated_at:any
//   status: any;
  actions: any;
}

export const titleofheaders=[
  {
    id: `srno`,
    Header: `Sr No`,
  },
 
  {
    id: 'status',
    Header: 'Status',
  },
]



export const Columns_StickPacking: Column<Data>[] = [



  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_StickPacking, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows} = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}stickpacking/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
  {
    id: `srno`,
    Header: `Sr.No`,
  },
  {
    id: `name`,
    Header: `Stick Packing Type`,
    accessor:'name',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.name}
        </div>
    )}

  }, {
    id: `note`,
    Header: `Note`,
    accessor:'note',
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.note}
        </div>
    )}
  },

 
 

 

  {
    id: 'created_at',
    Header: 'Created at',
    accessor:'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: 'Created By',
    accessor:'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username

 
  },
  {
    id: 'updated_at',
    Header: 'Updated At',
    accessor:'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: 'Updated By',
    accessor:'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },
  

  
//   {
//     id: 'status',
//     Header: 'Status',
//     accessor: 'status',
//     Cell: ({ row }: any) => {
//       const [pageSize, setPageSize] = useState("5")
//       const [search, setSearch] = useState("")
//       const [FilterBy, setFilterBy] = useState({
//         hex: '', name: ''
//       })
//       const [page, setPage] = useState(1)
//       const[orderBy,setOrderBy] = useState("")


//       const { isLoading, isError, data: fetchAllGst, error, isFetching, refetch, isSuccess, isPreviousData } = useFetchGst(FilterBy,search,page,pageSize,orderBy)
      
//       return <><Status row={row} update={useUpdateGst} refetch_f={refetch} /></>
//     }
//   },

  {
    id: 'Actions',
    Header: 'Actions',

  
  },
]



