
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import Actions from "../../components/Cell/Actions";
// import { updateBrand, useFetchBrands } from "../../hooks/brand";
// import noImage from "../../images/noImage.png"
import { useFetchGst, useUpdateGst } from "../../hooks/GST";
import { Update, useFetch, useUpdateSingle } from "../../hooks/Api";
import { Tooltip } from "@mui/material";


interface Data {
    selection: any
    // material_grade_code: any
    name: any
    source: any
    type: any
    material_color: any
    brand_name: any
    company_name:any
    gst: any
    grade: any
    density: any
    sector: any
    mfa5: any
    material_use: any
    use_category: any
    polymer_grade: any
    created_at: any

    created_by: any
    updated_by: any
    updated_at: any
    status: any;
    actions: any;
}

export const titleofheaders = [
    {
        id: `srno`,
        Header: `Sr No`,
    },



    // {
    //     id: `material_grade_code`,
    //     Header: `Raw Material Grade Code`,
    // },

    {
        id: `name`,
        Header: `Material Name`,
    },
    {
        id: `source`,
        Header: `Material Source`,
    },
    {
        id: `type`,
        Header: `Material Type`,
    },

    {
        id: `material_color`,
        Header: `Material Color`,
    },

    {
        id: `brand_name`,
        Header: `Brand Name`,
    },

    {
        id: `company_name`,
        Header: `Company Name`,
    },

    {
        id: `gst`,
        Header: `GST %`,
    },

    {
        id: `grade`,
        Header: `Material Grade`,
    },

    {
        id: `density`,
        Header: `Material density`,
    },

    {
        id: `sector`,
        Header: `Material sector`,
    },

    {
        id: `mfa5`,
        Header: `Material MFA5`,
    },


    {
        id: `material_use`,
        Header: `Raw Material Type`,
    },


    {
        id: `use_category`,
        Header: `Use Category`,
    },


    {
        id: `polymer_grade`,
        Header: `Polymer Grade`,
    },


    {
        id: 'status',
        Header: 'Status',


    },
    {
        id: 'created_at',
        Header: 'Created at',
    },


    {
        id: 'created_by',
        Header: 'Created By',


    },
    {
        id: 'updated_at',
        Header: 'Updated At',
    },
    {
        id: 'updated_by',
        Header: 'Updated By',
    },
]



export const Columns_RawMaterialGrade_Other: any = [




    {
        id: `srno`,
        Header: () => <div style={{ display: "inline" }}>Sr<br />No</div>,
        sticky: "left",
    },
    // {
    //     id: `material_grade_code`,
    //     Header: () => <div style={{ display: "inline" }}>Raw Material<br />Grade Code</div>,
    //     accessor: 'material_grade_code',
    //     sticky: "left",
    // },
    {
        id: `name_type_hsn`,
        Header: () => <div style={{ display: "inline" }}>Material Name & Type &<br />HSN & GST%</div>,
        accessor: 'name_type_hsn',
        sticky: "left",

        Cell: ({ row }: any) => {
            const Material:any = `${row.original.hsn_code?.grade_type?.material_type_name}::${row.original.hsn_code?.particular_sector_name?.name ? row.original.hsn_code?.particular_sector_name?.name : <span style={{ color: 'red' }}>****</span>}::
            ${row.original.hsn_code?.product_categories?.name ? row.original.hsn_code?.product_categories?.name : <span style={{ color: 'red' }}>****</span>}::
            ${row.original.hsn_code?.hsn_code}::${row.original?.hsn_code?.gst?.india_GST}`
            // return (
            //     <div>
            //         {row.original.hsn_code?.grade_type?.material_type_name}::{row.original.hsn_code?.particular_sector_name?.name ? row.original.hsn_code?.particular_sector_name?.name : <span style={{ color: 'red' }}>****</span>}::
            //         {row.original.hsn_code?.product_categories?.name ? row.original.hsn_code?.product_categories?.name : <span style={{ color: 'red' }}>****</span>}::
            //         {row.original.hsn_code?.hsn_code}::{row.original?.hsn_code?.gst?.india_GST}
            //     </div>
            // )
            return (
                <>
                  <Tooltip title={<p style={{ fontSize: '15px', color: 'white' }}>{Material}</p>} placement="top" arrow >
                    <div>
                      {Material.length >=2 ? Material?.slice(0, 19)?.concat('...'):Material}
                    </div>
                  </Tooltip>
        
                </>
              )
        }
    },
    {
        id: `gst_type`,
        Header: () => <div style={{ display: "inline" }}>GST<br />Type</div>,
        accessor: 'gst_type',
        Cell: ({ row }: any) => 

            <div>
                {row.original.hsn_code?.gst_type?.name}
            </div>
    },
    {
        id: `type`,
        Header: () => <div style={{ display: "inline" }}>Type Name &<br />Density & Sutra & GST%</div>,
        accessor: 'type',
        Cell: ({ row }: any) => {
            return(

            <div>
                {row.original.hsn_code?.grade_type?.material_type_name}::{row.original.hsn_code?.grade_type?.material_density === "0.000" ||row.original.hsn_code?.grade_type?.material_density=== null
          ? <span style={{ color: 'red'}}>0.000</span>
          : row?.original?.hsn_code?.grade_type?.material_density}::{row.original.hsn_code?.grade_type?.grade_sutra?row.original.hsn_code?.grade_type?.grade_sutra:<span style={{ color: 'red'}}>****</span>}::{row.original.hsn_code?.grade_type?.gst?.india_GST}
            </div>
             )}
    },

    {
        id: `name`,
        Header: () => <div style={{ display: "inline" }}>Material<br />Name</div>,
        accessor: 'name',
        // sticky:"left",
        Cell: ({row}:any) => <div>
            {row.original.hsn_code?.particular_sector_name?.name?row.original.hsn_code?.particular_sector_name?.name:<span style={{ color: 'red'}}>****</span>}
            </div>
    },
    {
        id: `source`,
        Header: () => <div style={{ display: "inline" }}>Material<br />Source</div>,
        accessor: 'source',
        Cell: ({row}:any) =>
            <div>
        {row.original.source?.name}
        </div>
        
    },
    {
        id: `material_color`,
        Header: () => <div style={{ display: "inline" }}>Material<br />Color</div>,
        accessor: 'material_color',
        // Cell: ({ row }: any) => {
        //     const temp_color = row.original.material_color?.map((e:any) => e.name)
        //     return (<>
        //         <div>
        //             {temp_color.join(", ")}
        //         </div>   
        //     </>)
        // }

        Cell: ({ row }: any) =>

            <div>
                {row.original?.material_color?.company_color_name}
            </div>
    },
    {
        id: `grade`,
        Header: () => <div style={{ display: "inline" }}>Material<br />Grade</div>,
        accessor: 'grade',
    },
    // {
    //     id: `type`,
    //     Header: () => <div style={{ display: "inline" }}>Material Type &<br />Density & Sutra</div>,
    //     accessor: 'type',
    //     Cell: ({ row }: any) => 

    //         <div>
    //             {row.original.type?.material_type_name}
    //         </div>
    // },
    {
        id: `mfa5`,
        Header: () => <div style={{ display: "inline" }}>Material<br />MFA5</div>,
        accessor: 'mfa5',
        Cell: ({ row }: any) =>
            <div>
                {row?.original?.mfa5 ? row?.original?.mfa5 : <span style={{ color: 'red' }}>****</span>}
            </div>
    },
    
    {
        id: `polymer_grade`,
        Header: () => <div style={{ display: "inline" }}>Polymer<br />Name</div>,
        accessor: 'polymer_grade',
        Cell: ({row}:any) => 
        <div>
        {row.original.polymer_grade?row.original.polymer_grade:<span style={{ color: 'red' }}>****</span>} 
        </div>
    },


    {
        id: `material_use`,
        Header: () => <div style={{ display: "inline" }}>Material<br />Use</div>,
        accessor: 'material_use',
        Cell: ({row}:any) => 
        <div>
        {row.original.material_use?.name} 
        </div>
    },
    {
        id: `sector`,
         Header: () => <div style={{ display: "inline" }}>Use Material<br />Machine Name</div>,
        
        accessor: 'sector',
        Cell: ({row}:any) => 
        <div>
        {row.original.sector?.name} 
        </div>
    },
    {
        id: `use_category`,
        Header: () => <div style={{ display: "inline" }}>Machine<br />Product</div>,
        accessor: 'use_category',
        Cell: ({row}:any) => 
        <div>
        {row.original.use_category?.name} 
        </div>
    },





    {
        id: `brand_name`,
        Header: () => <div style={{ display: "inline" }}>Material<br />Brand Name</div>,
        accessor: 'brand_name',
        Cell: ({ row }: any) =>

            <div>
                {row.original.brand_name?.name}
            </div>
    },
    {
        id: `company_name`,
        Header: () => <div style={{ display: "inline" }}>Material Company<br />Name & City</div>,
        accessor: 'company_name',
        Cell: ({row}:any) => 
            <div>
                {row.original?.company_name?.name}
            </div>

          
    },

    {
        id: `density`,
        Header: () => <div style={{ display: "inline" }}>Material<br />Grade Name</div>,
        accessor: 'density',
        Cell: ({ row }: any) => 

            <div>
                {row.original.hsn_code?.grade_type?.material_type_name}
            </div>
    },
    {
        id: `particular_sector_name`,
        Header: () => <div style={{ display: "inline" }}>Product <br />Type</div>,
        accessor: 'particular_sector_name',
        Cell: ({ row }: any) => 

            <div>
                {row.original.hsn_code?.particular_sector_name?.name}
            </div>
    },
    {
        id: `product_categories`,
        Header: () => <div style={{ display: "inline" }}>Product <br />Category</div>,
        accessor: 'product_categories',
        Cell: ({ row }: any) => 

            <div>
                {row.original.hsn_code?.product_categories?.name}
            </div>
    },
    {
        id: `hsn_code`,
        Header: () => <div style={{ display: "inline" }}>HSN <br />Code</div>,
        accessor: 'hsn_code',
        Cell: ({ row }: any) => 

            <div>
                {row.original.hsn_code?.hsn_code}
            </div>
    },
       

    {
        id: `gst`,
        Header: () => <div style={{ display: "inline" }}>GST<br />%</div>,
        accessor: 'gst',
        Cell: ({ row }: any) =>

            <div>
                {row.original.hsn_code?.gst?.india_GST}
            </div>
    },
    {
        id: `material_density`,
        Header: () => <div style={{ display: "inline" }}>Material<br />Density</div>,
        accessor: 'material_density',
        Cell: ({ row }: any) =>

            <div>
                {row.original.hsn_code?.grade_type?.material_density}
            </div>
    },
    {
        id: `grade_sutra`,
        Header: () => <div style={{ display: "inline" }}>Material<br />Grade Sutra</div>,
        accessor: 'grade_sutra',
        Cell: ({ row }: any) =>

            <div>
                {row.original.hsn_code?.grade_type?.grade_sutra}
            </div>
    },

  

  


    {
        id: 'status',
        Header: 'Status',
        accessor: 'status',


    },
    




    {
        id: 'created_at',
        Header: () => <div style={{ display: "inline" }}>Created<br />At</div>,
        accessor: 'created_at',
        Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
    },


    {
        id: 'created_by',
        Header: () => <div style={{ display: "inline" }}>Created<br />By</div>,
        accessor: 'created_by',
        Cell: ({ row }: any) => row.original.created_by?.username


    },
    {
        id: 'updated_at',
        Header: () => <div style={{ display: "inline" }}>Updated<br />At</div>,
        accessor: 'updated_at',
        Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
    },
    {
        id: 'updated_by',
        Header: () => <div style={{ display: "inline" }}>Updated<br />By</div>,
        accessor: 'updated_by',
        Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
    },



    {
        id: 'Actions',
        Header: 'Actions',


    },


    {
        id: 'Selection',
        Header: ({ getToggleAllRowsSelectedProps }: any) => {
            const [data, setData] = useState([])

            const columns: any = useMemo(() => Columns_RawMaterialGrade_Other, [])
            const defaultColumn: any = useMemo(() => {
                return {
                    Filter: Columnlevel
                }
            }
                , [])

            const tableInstance: any = useTable({
                columns,
                data: data,
                defaultColumn,

            },


            )
            const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
            let { selectedFlatRows } = tableInstance
            const { } = useMutation((deleteid: any) => {
                const temp_data = {
                    id: deleteMultipleid
                }
                return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_RAWMATERIALGRADE_API}/deletelist/`, temp_data,
                    {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    });
            });
            return (
                <>
                    <div>
                        <Checkbox

                            onClick={() => {
                                setDeleteMultipleid(() => {
                                    const temp = selectedFlatRows?.map((e: any) => {
                                        return e.original.id;
                                    });
                                    return temp;
                                });
                            }}

                            {...getToggleAllRowsSelectedProps()} />
                    </div>
                </>
            );
        },

        Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
            // Add your onClick function here
        }} />
    },
]



