
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import Actions from "../../components/Cell/Actions";
// import { updateBrand, useFetchBrands } from "../../hooks/brand";
// import noImage from "../../images/noImage.png"
import { useFetchGst, useUpdateGst } from "../../hooks/GST";

import noImage from "../../images/noImage.png"
import Modal from "react-bootstrap/esm/Modal";

interface Data {
  selection: any
  mould_code: any
  mould_shape: any
  purchase_date: any
  product_list_diameter_mm: any
  product_mould_diameter_mm: any
  product_length_diameter: any
  product_width_diameter: any
  tfm_machine_usd_mould: any
  mould_company: any
  model_number: any
  mould_brand: any
  mould_location: any
  cavity_qty: any
  mould_cutter_thickness_mm: any
  forming_cutting_area_sheet_length_mm: any
  forming_cutting_area_sheet_width_mm: any
  tfm_chain_infield_maximum_mm: any
  tfm_chain_infield_minimum_mm: any
  mould_cavity_hole_height_mm: any
  mould_lip_mm: any
  mould_new_old: any
  sheet_length: any
  sheet_width: any
  length: any
  cutter_gap: any
  guarantee_yes_no:any
  guarantee_months:any
  warranty_yes_no:any
  warranty_time:any

  created_at: any
  created_by: any
  updated_by: any
  updated_at: any

  status: any;
  actions: any;

}

export const titleofheaders = [
  {
    id: `srno`,
    Header: `Sr.No`,
  },
  {
    id: `mould_code`,
    Header: 'Mould Code',

  },
  {
    id: `cavity_qty`,
    Header: 'Mould Cavity Qty',
  },


  {
    id: `product_list_diameter_mm`,
    Header: 'Product Price-List Mould Diameter mm',
  },
  {
    id: `product_mould_diameter_mm`,
    Header: ` Production Mould Diameter mm`,
  },
  {
    id: `product_length_diameter`,
    Header: `Production Mould Length Diameter mm`,
  },
  {
    id: `product_width_diameter`,
    Header: `Production Mould Width Diameter mm`,
  },
  {
    id: `mould_shape`,
    Header: `Mould Shape`,


  },
  {
    id: `tfm_machine_usd_mould`,
    Header: `T.F.M. Machine Usd Mould`,

  },
  {
    id: `sheet_length`,
    Header: `Mould Sheet Length mm`,
  },
  {
    id: `sheet_width`,
    Header: `Mould Sheet Width mm`,
  },
  {
    id: `length`,
    Header: `Mould Length mm`,
  },
  {
    id: `mould_cutter_thickness_mm`,
    Header: `Mould Cutter Thikness mm`,
  },
  {
    id: `forming_cutting_area_sheet_length_mm`,
    Header: `Forming Cutting Area Sheet Length mm`,
  },
  {
    id: `forming_cutting_area_sheet_width_mm`,
    Header: `Forming Cutting Area Sheet Width mm`,
  },
  {
    id: `cutter_gap`,
    Header: `Mould Cutter Gap mm`,
  },
  {
    id: `tfm_chain_infield_maximum_mm`,
    Header: `T.F.M. Chain Infiled Maximum mm`,
  },
  {
    id: `tfm_chain_infield_minimum_mm`,
    Header: `T.F.M. Chain Infiled Minimum mm`,
  },
  {
    id: `mould_cavity_hole_height_mm`,
    Header: `Mould Cavity Hole Height mm`,
  },
  {
    id: `mould_lip_mm`,
    Header: `Mould Lip mm`,
  },
  {
    id: `mould_new_old`,
    Header: `Mould New-Old`,
  },
  {
    id: `purchase_date`,
    Header: `Purchase Mould Date`,
  },
  {
    id: `mould_brand`,
    Header: `Mould Brand Name`,
  },
  {
    id: `mould_company`,
    Header: `Mould Company Name`,
  },
  {
    id: `model_number`,
    Header: 'Mould Model No.',
  },
  {
    id: `mould_location`,
    Header: 'Mould Location',

  },
  {
    id: `guarantee_yes_no`,
    Header: `Guarantee Month`,
    

  },
  {
    id: `guarantee_months`,
    Header: `Guarantee Month`,
    

  },
  {
    id: `warranty_yes_no`,
    Header: `Warranty Month`,
    

  },
  {
    id: `warranty_time`,
    Header: `Warranty Month`,
    

  },
  {
    id: 'status',
    Header: 'Status',


  },

  // {
  //   id: `image`,
  //   Header: 'Mould Image',

  // },

  // {
  //   id: `pdf?`,
  //   Header: 'Mould PDF',

  // },

  {
    id: 'created_at',
    Header: 'Created At'

  },


  {
    id: 'created_by',

    Header: 'Created By'



  },
  {
    id: 'updated_at',
    Header: 'Updated At'

  },
  {
    id: 'updated_by',
    Header: 'Updated By'


  },


  {
    id: 'Actions',
    Header: 'Actions',


  },
  {
    id: 'Selection',
    Header: 'Selection',

  },
]



export const Columns_Mould: any = [




  {
    id: `srno`,
    Header: () => <div style={{display:"inline"}}>Sr<br/>No</div>,
    sticky: "left",
  },
  {
    id: `mould_code`,
    Header: () => <div style={{ display: "inline" }}>Mould<br />Code</div>,
    accessor: `mould_code`,
    sticky: "left",

  },
  {
    id: `mould_category`,
    Header: () => <div style={{ display: "inline" }}>Mould Category<br />Shape & Size mm</div>,
    accessor: `mould_category`,
    Cell: ({ row }: any) =>
    <div>
 {row.original?.mould_name_type_category_hsn_code_and_gst?.product_categories?.name}::{row.original?.mould_shape?.name}::{row?.original?.product_mould_diameter_mm}

    </div>

  },
  {
    id: `use_product_code`,
    Header: () => <div style={{ display: "inline" }}>Use product Code<br />& Name & Type</div>,

    // Header: `Grade Name`,
    accessor:'use_product_code',
    Cell: ({ row }: any) =>
    <div>
{row.original?.use_product_code?row.original?.use_product_code:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

    </div>

  },
  {
    id: `use_mould_product_qty`,
    Header: () => <div style={{ display: "inline" }}>Use Mould <br />Product Qty</div>,
    accessor:'use_mould_product_qty',
    Cell: ({ row }: any) =>
    <div>
{row.original?.use_mould_product_qty?row.original?.use_mould_product_qty:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

    </div>

  },
  {
    id: `use_cavity_code_name`,
    Header: () => <div style={{ display: "inline" }}>Use Cavity<br />Code & Name</div>,
    accessor:'use_cavity_code_name',
    Cell: ({ row }: any) =>
    <div>
{row.original?.use_cavity_code_name?row.original?.use_cavity_code_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

    </div>

  },
  {
    id: `use_mould_cavity_Qty`,
    Header: () => <div style={{ display: "inline" }}>Use Mould Cavity<br />Design Qty</div>,
    accessor:'use_mould_cavity_Qty',
    Cell: ({ row }: any) =>
    <div>
{row.original?.use_mould_cavity_Qty?row.original?.use_mould_cavity_Qty:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

    </div>

  },
  {
    id: `mould_name_type_category_hsn_code_and_gst`,
    Header: () => <div style={{ display: "inline" }}>Mould Name & Type &<br />Category & HSN & GST % </div>,
    accessor:'mould_name_type_category_hsn_code_and_gst',
    Cell: ({ row }: any) =>{
      return(
      <div>
{row.original?.mould_name_type_category_hsn_code_and_gst?.grade_type?.material_type_name}::{row.original?.mould_name_type_category_hsn_code_and_gst?.particular_sector_name?.name?row.original?.mould_name_type_category_hsn_code_and_gst?.particular_sector_name?.name:<span style={{ color: 'red'}}>****</span>}::
      {row.original?.mould_name_type_category_hsn_code_and_gst?.product_categories?.name?row.original?.mould_name_type_category_hsn_code_and_gst?.product_categories?.name:<span style={{ color: 'red'}}>****</span>}::
      {row.original?.mould_name_type_category_hsn_code_and_gst?.hsn_code}::{row.original?.mould_name_type_category_hsn_code_and_gst?.gst?.india_GST}
      </div>
    )}
  },
  {
    id: `gst_type`,
    Header: () => <div style={{ display: "inline" }}> GST<br />Type</div>,
    accessor:'gst_type',
    Cell: ({ row }: any) =>
    <div>
{row.original?.mould_name_type_category_hsn_code_and_gst?.gst_type?.name}

    </div>

  },
  {
    id: `type_name`,
    Header: () => <div style={{ display: "inline" }}>Type Name & Density<br />& Sutra & GST% </div>,
    accessor:'type_name',
    Cell: ({ row }: any) =>{
      return(
      <div>
        {row.original?.mould_name_type_category_hsn_code_and_gst?.grade_type?.material_type_name}::
        {row.original?.mould_name_type_category_hsn_code_and_gst?.grade_type?.material_density === "0.000" || row.original?.mould_name_type_category_hsn_code_and_gst?.grade_type?.material_density=== null 
        ? <span style={{ color: 'red'}}>0.000</span>:row.original?.mould_name_type_category_hsn_code_and_gst?.grade_type?.material_density}::
        {row.original?.mould_name_type_category_hsn_code_and_gst?.grade_type?.grade_sutra=== null?<span style={{ color: 'red'}}>****</span>:row.original?.mould_name_type_category_hsn_code_and_gst?.grade_type?.grade_sutra}
        ::{row.original?.mould_name_type_category_hsn_code_and_gst?.grade_type?.gst?.india_GST}
  
  
      </div>
      )}
    

  },
  {
    id: `plant_mould_number`,
    Header: () => <div style={{ display: "inline" }}>Plant Mould<br />Number </div>,
    accessor:'plant_mould_number',
    Cell: ({ row }: any) =>
    <div>
{row.original?.plant_mould_number?row.original?.plant_mould_number:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

    </div>

  },
  {
    id: `model_number`,
    Header: () => <div style={{ display: "inline" }}>Mould<br />Model No.</div>,
    accessor: `model_number`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.model_number?row.original?.model_number:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>
  },
  {
    id: `product_list_diameter_mm`,
    Header: () => <div style={{ display: "inline" }}>Price-List<br />Mould Size mm</div>,
    accessor: `product_list_diameter_mm`
  },

  {
    id: `mould_shape`,
    Header: () => <div style={{ display: "inline" }}>Mould<br />Shape</div>,
    // Header: `Mould Shape`,
    accessor: `mould_shape`,
    Cell: ({row}:any) => {
      const convertToUpper = row.original.mould_shape.name
      if (!convertToUpper) return "";
      return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }
  },
  {
    id: `mould_shape_image`,
    Header: () => <div style={{ display: "inline" }}>Mould<br />Shape Image</div>,
    accessor: 'mould_shape_image',
    Cell: ({ row }: any) =>{
      const [deleteConfirm, setDeleteConfirm] = useState(false)
      

    return (
      <>


<div >

          {!row.original.mould_shape?.shape_image?<>
            <a 
          
          href="#"
          
          // data-large-src={row.original.cavity_shape?.shape_image?row.original.cavity_shape?.shape_image:noImage} data-toggle="modal"
          >
            {/* <img src={''}  height={60} width={100}/> */}
            </a>
          </>:<>
          <a 
          onClick={()=>setDeleteConfirm(true)}
          href={`#galleryModal${row.original.name}`}
          
          data-large-src={row.original.mould_shape?.shape_image?row.original.mould_shape?.shape_image:''} data-toggle="modal">
            <img src={row.original.mould_shape?.shape_image?row.original.mould_shape?.shape_image:''}  height={30} width={'auto'}/>
            </a>
          </>}
         
        </div>

        <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
        centered    >
        <Modal.Header >
          <Modal.Title>
        
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>  
        <img src={row.original.mould_shape?.shape_image?row.original.mould_shape?.shape_image:noImage} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid"/>

        </Modal.Body>
        <Modal.Footer>
        <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

        </Modal.Footer>
      </Modal>
       
        </>
        )}
  },

  {
    id: `product_mould_diameter_mm`,
    Header: () => <div style={{ display: "inline" }}>Production Mould<br />Size mm</div>,
    // Header: ` Production Mould Diameter mm`,
    accessor: `product_mould_diameter_mm`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.product_mould_diameter_mm === '0.000'||row.original?.product_mould_diameter_mm === null?<span style={{ color: 'red',fontSize:"20px" }}>****</span>:row.original?.product_mould_diameter_mm}
    </div>
  },
  
  {
    id: `product_width_diameter`,
    Header: () => <div style={{ display: "inline" }}>Production Mould<br />Width mm</div>,
    // Header: `Production Mould Width Diameter mm`,
    accessor: `product_width_diameter`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.product_width_diameter==="0.000"||row.original?.product_width_diameter===null?<span style={{ color: 'red',fontSize:"20px" }}>****</span>:row.original?.product_width_diameter}
    </div>
  },

  {
    id: `product_length_diameter`,
    Header: () => <div style={{ display: "inline" }}>Production Mould<br />Length mm</div>,
    // Header: `Production Mould Length Diameter mm`,
    accessor: `product_length_diameter`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.product_length_diameter==="0.000"||row.original?.product_length_diameter===null?<span style={{ color: 'red',fontSize:"20px" }}>****</span>:row.original?.product_length_diameter}
    </div>


  },

  {
    id: `mould_cavity_hole_height_mm`,
    Header: () => <div style={{ display: "inline" }}>Mould Cavity<br />Hole Height mm</div>,
    // Header: `Mould Cavity Hole Height mm`,
    accessor: `mould_cavity_hole_height_mm`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.mould_cavity_hole_height_mm?row.original?.mould_cavity_hole_height_mm:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>
  },
  {
    id: `cavity_qty`,
    Header: () => <div style={{ display: "inline" }}>Mould Cavity<br />Qty</div>,
    accessor: `cavity_qty`,

  },
  {
    id: `mould_lip_mm`,
    Header: () => <div style={{ display: "inline" }}>Mould<br />Lip</div>,
    // Header: `Mould Lip mm`,
    accessor: `mould_lip_mm`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.mould_lip_mm?row.original?.mould_lip_mm:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>
  },

  {
    id: `cutter_gap`,
    Header: () => <div style={{ display: "inline" }}>Mould Cutter<br />Gap mm</div>,
    // Header: `Mould Cutter Gap mm`,
    accessor: `cutter_gap`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.cutter_gap?row.original?.cutter_gap:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>
  },

  {
    id: `mould_cutter_thickness_mm`,
    Header: () => <div style={{ display: "inline" }}>Mould Cutter<br />Thikness mm</div>,
    // Header: `Mould Cutter Thikness mm`,
    accessor: `mould_cutter_thickness_mm`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.mould_cutter_thickness_mm?row.original?.mould_cutter_thickness_mm:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>
  },
  {
    id: `mould_cutter_id_mm`,
    Header: () => <div style={{ display: "inline" }}>Mould Cutter<br />ID mm</div>,
    // Header: `Mould Cutter Thikness mm`,
    accessor: `mould_cutter_id_mm`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.mould_cutter_id_mm?row.original?.mould_cutter_id_mm:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>
  },
  {
    id: `mould_cutter_od_mm`,
    Header: () => <div style={{ display: "inline" }}>Mould Cutter<br />OD mm</div>,
    // Header: `Mould Cutter Thikness mm`,
    accessor: `mould_cutter_od_mm`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.mould_cutter_od_mm?row.original?.mould_cutter_od_mm:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>
  },


  {
    id: `tfm_chain_infield_minimum_mm`,
    Header: () => <div style={{ display: "inline" }}>T.F.M. Chain Infiled<br />Minimum mm</div>,
    // Header: `T.F.M. Chain Infiled Minimum mm`,
    accessor: `tfm_chain_infield_minimum_mm`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.tfm_chain_infield_minimum_mm?row.original?.tfm_chain_infield_minimum_mm:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>
  },
  
  {
    id: `tfm_chain_infield_maximum_mm`,
    Header: () => <div style={{ display: "inline" }}>T.F.M. Chain Infiled<br />Maximum mm</div>,
    // Header: `T.F.M. Chain Infiled Maximum mm`,
    accessor: `tfm_chain_infield_maximum_mm`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.tfm_chain_infield_maximum_mm?row.original?.tfm_chain_infield_maximum_mm:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>
  },

  {
    id: `forming_cutting_area_sheet_length_mm`,
    Header: () => <div style={{ display: "inline" }}>Forming Cutting Area<br />Sheet Length mm</div>,
    // Header: `Forming Cutting Area Sheet Length mm`,
    accessor: `forming_cutting_area_sheet_length_mm`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.forming_cutting_area_sheet_length_mm?row.original?.forming_cutting_area_sheet_length_mm:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>
  },
  {
    id: `forming_cutting_area_sheet_width_mm`,
    Header: () => <div style={{ display: "inline" }}>Forming Cutting Area<br />Sheet Width mm</div>,
    // Header: `Forming Cutting Area Sheet Width mm`,
    accessor: `forming_cutting_area_sheet_width_mm`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.forming_cutting_area_sheet_width_mm?row.original?.forming_cutting_area_sheet_width_mm:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>
  },

  
 
 
 
  
  
  
  {
    id: `sheet_length`,
    Header: () => <div style={{ display: "inline" }}>Mould Sheet Roll<br />Length Meter</div>,
    // Header: `Mould Sheet Length mm`,
    accessor: `sheet_length`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.sheet_length?row.original?.sheet_length:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>
  },
  {
    id: `sheet_width`,
    Header: () => <div style={{ display: "inline" }}>Mould Sheet Size<br />Width mm</div>,
    // Header: `Mould Sheet Width mm`,
    accessor: `sheet_width`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.sheet_width?row.original?.sheet_width:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>
  },
  {
    id: `length`,
    Header: () => <div style={{ display: "inline" }}>Mould Length<br />mm</div>,
    // Header: `Mould Length mm`,
    accessor: `length`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.length?row.original?.length:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>
  },
  {
    id: `mould_width_mm`,
    Header: () => <div style={{ display: "inline" }}>Mould Width<br />mm</div>,
    // Header: `Mould Length mm`,
    accessor: `mould_width_mm`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.mould_width_mm?row.original?.mould_width_mm:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>
  },
  {
    id: `mould_weight_kg`,
    Header: () => <div style={{ display: "inline" }}>Mould Weight<br />Kg</div>,
    // Header: `Mould Length mm`,
    accessor: `mould_weight_kg`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.mould_weight_kg?row.original?.mould_weight_kg:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>
  },
  
 
  {
    id: `tfm_machine_usd_mould`,
    Header: () => <div style={{ display: "inline" }}>Machine Usd Mould<br />& Plant Number</div>,
    // Header: `T.F.M. Machine Usd Mould`,
    accessor: `tfm_machine_usd_mould`,
    


    Cell: ({ row }: any) => {
      let machine = row.original.tfm_machine_usd_mould.map((e:any)=>{
        return `${e.plant_machine_number}::${e.machine_model_no?e.machine_model_no:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}`
      })
      return(
        <div>
          {/* {machine?.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")} */}
          {machine.join(",")}
        </div>
      )
}

    
  },

  {
    id: `mould_special_running`,
    Header: () => <div style={{ display: "inline" }}>Mould<br />Sp. - Running</div>,
    // Header: `Mould Length mm`,
    accessor: `mould_special_running`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.mould_special_running?.name?row.original?.mould_special_running?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>
  },
 
  {
    id: `hsn_code`,
    Header: () => <div style={{ display: "inline" }}>HSN<br />Code </div>,
    accessor:'hsn_code',
    Cell: ({ row }: any) =>
    <div>
{row.original?.mould_name_type_category_hsn_code_and_gst?.hsn_code}

    </div>

  },
  {
    id: `gst`,
    Header: () => <div style={{ display: "inline" }}>GST<br />%</div>,
    accessor:'gst',
    Cell: ({ row }: any) =>
    <div>
{row.original?.mould_name_type_category_hsn_code_and_gst?.gst?.india_GST}

    </div>

  },
  {
    id: `purchase_date`,
    Header: () => <div style={{ display: "inline" }}>Mould <br />Purchase Date</div>,
    // Header: `Purchase Mould Date`,
    accessor: `purchase_date`
  },
 
  {
    id: `manufacture_date`,
    Header: () => <div style={{ display: "inline" }}>Mould<br />Manufacture Date</div>,
    // Header: `Purchase Mould Date`,
    accessor: `manufacture_date`
  },
  {
    id: `mould_location`,
    Header: () => <div style={{ display: "inline" }}>Mould<br />Location</div>,
    accessor: `mould_location`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.mould_location?.name?row.original?.mould_location?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>
  },

  {
    id: `mould_brand`,
    Header: () => <div style={{ display: "inline" }}>Mould<br />Brand</div>,
    // Header: `Mould Brand Name`,\
    accessor: `mould_brand`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.mould_brand?.name?row.original?.mould_brand?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>
  },
  {
    id: `mould_company`,
    Header: () => <div style={{ display: "inline" }}>Mould Company<br />Name & City</div>,
    // Header: `Mould Company Name`,
    accessor: `mould_company`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.mould_company?.name?row.original?.mould_company?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>
  },

  {
    id: `mould_new_old`,
    Header: () => <div style={{ display: "inline" }}>Mould<br />New-Old</div>,
    // Header: `Mould New-Old`,
    accessor: `mould_new_old`,
    
    Cell: ({row}:any) => {
      const convertToUpper = row.original.mould_new_old
      if (!convertToUpper) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }
  },
  {
    id: `country_code`,
    Header: () => <div style={{ display: "inline" }}>Country Mobile<br />Code</div>,
    // Header: `Mould Company Name`,
    accessor: `country_code`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.country_code?row.original?.country_code:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>
  },
  {
    id: `service_mobile_number`,
    Header: () => <div style={{ display: "inline" }}>Service <br />Mobile No.</div>,
    // Header: `Mould Company Name`,
    accessor: `service_mobile_number`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.service_mobile_number?row.original?.service_mobile_number:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>
  },
  
  
 
  {
    id: `guarantee_yes_no`,
    Header: () => <div style={{display:"inline"}}>Guarantee<br/>Yes-No</div>,
    accessor:'guarantee_yes_no',
    Cell: ({row}:any) => {
      const convertToUpper = row.original.guarantee_yes_no
      if (!convertToUpper) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
     
    }
    // Cell: ({ row }: any) => 
    
    // <div>
    //   {row.original?.guarantee_yes_no?row.original?.guarantee_yes_no:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

    // </div>

  },
  {
    id: `guarantee_months`,
    Header: () => <div style={{display:"inline"}}>Guarantee<br/>Month</div>,

    accessor:'guarantee_months',
    
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.guarantee_months?row.original?.guarantee_months:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>

  },
  {
    id: `warranty_yes_no`,
    Header: () => <div style={{display:"inline"}}>Warranty<br/>Yes-No</div>,
    // Header: `Warranty Month`,
    accessor:'warranty_yes_no',
    Cell: ({row}:any) => {
      const convertToUpper = row.original.warranty_yes_no
      if (!convertToUpper) return <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
     
    }
    // Cell: ({ row }: any) => 
    
    // <div>
    //   {row.original?.guarantee_yes_no?row.original?.guarantee_yes_no:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

    // </div>

  },
  {
    id: `warranty_time`,
    Header: () => <div style={{display:"inline"}}>Warranty<br/>Month</div>,

    accessor:'warranty_time',
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.warranty_time?row.original?.warranty_time:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>

  },
  {
    id: `remark`,
    Header: () => <div style={{ display: "inline" }}>Remark</div>,
    // Header: `Mould Company Name`,
    accessor: `remark`,
    Cell: ({ row }: any) => 
    
    <div>
{row.original?.remark?row.original?.remark:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
    </div>
  },





  {
    id: `image`,
    Header: () => <div style={{ display: "inline" }}>Mould<br />Image All</div>,

    // accessor: `logo`,
    Cell: ({ row }: any) => {

      const [deleteConfirm, setDeleteConfirm] = useState(false)


      return (
        <>

          <div >

            {!row.original.image ? <>
              <a

                href="#"

              // data-large-src={row.original.image?row.original.image:noImage} data-toggle="modal"
              >
                <img src={noImage} height={60} width={100} />
              </a>
            </> : <>
              <a
                onClick={() => setDeleteConfirm(true)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.image.length > 0 ? row.original.image.at(-1).doc : noImage)} data-toggle="modal">
                <img src={(row.original.image.length > 0 ? row.original.image.at(-1).doc : noImage)} height={60} width={100} />
              </a>
            </>}

          </div>








          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            <Modal.Body>
              <img src={(row.original.image?.length > 0 ? row.original.image.at(-1).doc : noImage)} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid" />

            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>)
    }


  },



  {
    id: `pdf?`,
    Header: () => <div style={{ display: "inline" }}>Mould PDF<br />Drawing All</div>,

    // accessor: `pdf`,
    Cell: ({ row }: any) =>



      <div>
        <a target="_blank" href={(row.original.pdf?.length > 0 ? row.original.pdf?.at(-1).doc : null)}>
          {(row.original.pdf?.length > 0 ? row.original.pdf?.at(-1).doc?.split('/').pop() : <span className="text-danger">No Document Uploaded</span>)}
        </a>
      </div>

  },
  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',


  },

  {
    id: 'created_at',
    Header: () => <div style={{ display: "inline" }}>Created<br />At</div>,
    accessor: 'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{ display: "inline" }}>Created<br />By</div>,
    accessor: 'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username


  },
  {
    id: 'updated_at',
    Header: () => <div style={{ display: "inline" }}>Updated<br />At</div>,
    accessor: 'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{ display: "inline" }}>Updated<br />By</div>,
    accessor: 'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },




  {
    id: 'Actions',
    Header: 'Actions',


  },
  {
    id: 'Selection',
    // sticky:"left",
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_Mould, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows } = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MOULD_API}/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



