import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
const Columnlevel = ({column}: { column: any }) => {
  
  const {filterValue,setFilter}=column
  return (

<>
{column.id=="hex"}
<div className="input-group mt-4 m-auto w-75" data-colorpicker-id={2}>
                              <input type="text" className="form-control border-end-0 searchinput" placeholder="Color Code" value={filterValue||''} onChange={e=>setFilter(e.target.value)}/>
                              <div className="input-group-append">
                                <span className="input-group-text bg-white border-start-0 searchinput" 
                                onClick={()=>setFilter("")}
                                ><FontAwesomeIcon color='darkblue'  icon={faXmark} /></span>
                              </div>
                            </div>
        </>
  )
}

export default Columnlevel