import React from 'react'
import { Path, UseFormRegister, useForm ,SubmitHandler } from 'react-hook-form'
import { RiPencilLine } from 'react-icons/ri'

interface InputField {
  id?: any,
  name?: any,
  onChange?: any,
  onClick?: any,
  type?: any,
  accept?: any,
  className?: any,
  placeholder?: any,
  htmlFor?: any,
  color?: any
  isPencil?:any
  value?:any,
  label?: any
}

const InputField = ({ id, name, onChange, type, className, placeholder, label, color ,isPencil=true, value}: InputField) => {

  return (
      <div>
          <label htmlFor="exampleInputEmail1">{label}<span style={{ color: 'red' }}>*</span></label>
          <div className={className ? className : "input-group"} >
              <input type={type} className={className ? className : "form-control"}
                  id={id} placeholder={placeholder} value={value} onChange={onChange}/>
                {isPencil && <div className={className ? className : "input-group-append"}>
                    <span className={className ? className : "input-group-text bg-white border-start-0"}><RiPencilLine color={color} /></span>
                </div>}
          </div>
      </div>
  )
}

export default InputField