import React, { useEffect, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../components/Header/header.css";
import { useNavigate, useParams } from "react-router-dom";
import { useGSTEdit, useUpdateGst } from "../../hooks/GST";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import { useFetchSingle, useUpdateSingle } from "../../hooks/Api";
import Select from "react-select"
import axios from 'axios'
import { Alert } from "../../components/Model/Model";
// import { Alert } from "../Model/Model";
// import Select from 'react-select/async';

const Edit_Material_Type = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_MATERIAL_TYPE_API?.toString()
    const { data } = useFetchSingle(id,apiname)
    const { mutate, isSuccess, isError,error,data: gstUpdated_response }: any = useUpdateSingle()

    const { register, handleSubmit, setError, formState: { errors }, reset, setValue }:any = useForm();

   

    const [options, setOptions] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [loading, setLoading] = useState(false)


    const fetchOptions = async () => {
      try {
          setLoading(true)
          const apicall = await axios.get(`${process.env.REACT_APP_BASE_URL}gst/fetch_all/`, {
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
          })

          const data = apicall.data.results.map((option: any) => ({
              label: option.india_GST,
              value: option.id
          }))
          setOptions(data)
      } catch (error) {
      } finally {
          setLoading(false)
      }
  }

  const loadOptions = async () => {
      try {
          setLoading(true)
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}gst/fetch_all?search_filter=${searchTerm}`, {
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
          })
          const data = response.data.results.map((option: any) => ({
              label: option.india_GST,
              value: option.id,
          }))
          setOptions(data)
      } catch (error) {
      } finally {
          setLoading(false)
      }
  }

  useEffect(() => {
      if (searchTerm.length >= 2) {
          loadOptions()
      } else {
          fetchOptions()
      }
  }, [])

  const handleInputChange = (e: any) => {
      setSearchTerm(e)
  }
  const [selected, setSelected]: any = useState();
  const [sel, setSel] = useState("");

  const handleChange = (e: any) => {
      setSel(e);
      setValue("gst.id",sel)
      setValue("gst.id",`${e.value}`)
  }


    useEffect(() => {
        if (data) {
            reset(data)
            setSelected(data.gst.id);
            setValue("density",data.density)
            setValue("polymer_grade",data.polymer_grade)
            setValue("grade_equation",data.grade_equation)
            setValue("status",data.status)


        }
    }, [data]);


    const onSubmit = (materialtypeDetails: any) => {
      materialtypeDetails.gst = materialtypeDetails.gst.id
      

        const formData = new FormData();
        for (const [key, value] of Object.entries(materialtypeDetails)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
             
        }
        let data = {
          apiname: apiname,
          id:id,
          data: formData
      }
        mutate(data);
        
    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")


    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listMaterialType', { state: { showMaterialTypeEditToast: true }})

        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [gstUpdated_response, error,isSuccess])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    const handleValue=(e:any)=>{
        e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
    }

    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit Material Type</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/listColor" className="text-decoration-none text-black">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Masters</a></li>
                                    <li className="breadcrumb-item"><a href="/brandlist" className="text-decoration-none text-black">Material Type</a></li>
                                    <li className="breadcrumb-item active">Edit Material Type</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Edit GST</span></h5>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message.name} /> : ""}

                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Type Code</label>
                                                    <input type="text" className="form-control" 
                                                    disabled
                                                    id="exampleInputBrand"  {...register("materialtype_code",{onChange:handleValue})} />
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Type</label>
                                                    <input type="text" className="form-control" id="exampleInputBrand"  {...register("type")} />
                                                </div>


                                                <div className="col-sm-4 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Gst</label>

                                                    <Select
                                                    // {...register("gst.india_GST")}
                                                    // name="gst.india_GST"
                                                        placeholder={'Select Brand Name'}
                                                        options={options}
                                                        onInputChange={handleInputChange}
                                                        
                                                        onChange={handleChange}
                                                        value={sel === "" ? options.find((obj: any) => {
                                                          return(
                                                          obj.value === selected)}) : sel}
                                                        defaultMenuIsOpen={options.find((obj: any) => obj.value === selected)}
                                                    />

                                                </div>



                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Density</label>
                                                    <div className="input-group" >
                                                    <input type="text" className="form-control" id="exampleInputBrand"  {...register("density",{ onChange:handleValue,required: { value: true, message: "Field is required" }})} />
                                                    <div className="input-group-append">
                                                            <span className="input-group-text bg-white border-start-0"><RiPencilLine color='green' /></span>
                                                        </div>
                                                        </div>
                                                    <span className='text-red w-100'>{errors?.density?.message}</span>
                                                </div>


                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Polymer Grade</label>
                                                    <select className="form-select" {...register("polymer_grade", { required: { value: true, message: "Field is required" } })}>
                                                        <option value={"homo"}>Homo</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.polymer_grade?.message}</span>
                                                </div>


                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Grade Education</label>
                                                    <div className="input-group" >
                                                    <input type="text" className="form-control" id="exampleInputBrand"  {...register("grade_equation",{ onChange:handleValue,required: { value: true, message: "Field is required" }})} />
                                                    <div className="input-group-append">
                                                            <span className="input-group-text bg-white border-start-0"><RiPencilLine color='green' /></span>
                                                        </div>
                                                        </div>
                                                    <span className='text-red w-100'>{errors?.grade_equation?.message}</span>
                                                </div>



                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status</label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"inactive"}>Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>
                                                </div>
                                              
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button type="button" className="btn border rounded text-white px-4 mt-2 mx-3"  onClick={handleSubmit(onSubmit)}>Submit</button>
                                                    <button type="button" className="btn border-1 rounded  px-4 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listMaterialType")}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Edit_Material_Type


