import { useEffect, useState, useRef } from "react";
import "../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../components/Model/Model";
import Select, { components } from "react-select"
import axios from 'axios'
import { useCreate } from "../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../components/focus";
import { AiOutlinePlus } from "react-icons/ai";
import Button from "react-bootstrap/esm/Button";
import { Gst_Model } from "../../components/Dynamic_Form_Models/Gst_Model";
import { useFetchGSTDropDown } from "../../hooks/DropDown_Api";
// import { components, IndicatorsContainerProps } from 'react-select';
import IndicatorsContainer from '../../components/Select Dropdown/Select'

const Add_MaterialDensity = () => {

    const [open, setOpen] = useState(false)

    const navigate = useNavigate()
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const [options, setOptions] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [loading, setLoading] = useState(false)
    const [gstID, setGstID] = useState()
    const apiname = process.env.REACT_APP_MATERIAL_DENSITY_API?.toString()

    // const navigate = useNavigate()
    const { mutate: Material, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();

    const [valueDensity, setValueDensity] = useState('')

    const handleDensity = (e: any) => {

        setValueDensity(e.target.value)
    };

    const [forGST, setForGSTValue]: any = useState([])


    const [optionsGST, setOptionsGST]: any = useState([])

    const { data: gstData, refetch: gstRefetch } = useFetchGSTDropDown()

    useEffect(() => {
        setOptionsGST(gstData)
    }, [gstData])

    const handleChange = (e: any) => {
        setValue("gst", `${e.value}`)
        setForGSTValue({ label: e?.label, value: e?.value })

    }


    useEffect(() => {
        if (data) {
            reset(data)
        }
    }, [data]);

    const handleReset = () => {
        reset()
        setForGSTValue(null)
    }

    const onSubmit = (materialdetail: any) => {
        
        const formData = new FormData();
        for (const [key, value] of Object.entries(materialdetail)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }

            else {
                formData.append(key, formvalue[0])
            }


            if (valueDensity && key === 'material_density') {
                let decimalNumber = Number(valueDensity);
                const formattedString = decimalNumber.toFixed(3);
                formData.append('material_density', formattedString)
            }

        }

        Material(formData);

    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listMaterialDensity', { state: { showToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }


    const [submitted, setSubmitted] = useState(false)



    useEffect(() => {
        if (submitted) {
            setForGSTValue(optionsGST[0])

            setValue("gst", optionsGST[0].value)

        }

    }, [submitted, optionsGST])


   

    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header px-3 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Material Density</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Purchase</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listMaterialDensity" className="text-decoration-none text-black">Material Density</Link></li>
                                    <li className="breadcrumb-item active">Add Material Density</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">

                            <form ref={focus} onKeyUp={event => onEnterKey(event)} onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Type Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Material Type Name" {...register("material_type_name", { onChange: handleValue, required: { value: true, message: "Material Type Name is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.material_type_name?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Density</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control"
                                                            // value={valueDensity}
                                                            id="exampleInputBrand" placeholder="Material Density"  {...register("material_density", { onChange: handleDensity, required: { value: false, message: "Material Density is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.material_density?.message}</span>

                                                </div>

                                                {/* <div className="col-sm-3 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Gst <span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                     {...register("gst", { required: { value: true, message: "Gst is required" }})}
                                                    placeholder={'Select Gst'}
                                                    value={forGST}
                                                    options={options}
                                                    onInputChange={handleInputChange}
                                                    onChange={handleChange}
                                                    />
                                                     <span className='text-red'>{errors?.gst?.message}</span>
                                                </div> */}



                                              



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Grade Sutra</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Grade Sutra"  {...register("grade_sutra", { onChange: handleValue, required: { value: false, message: "Grade Sutra is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                        <span className='text-red w-100'>{errors?.grade_sutra?.message}</span>
                                                    </div>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name_and_grade">GST % <span style={{ color: 'red' }}>*</span></label>
                                                    {/* <div className="input-group" ></div> */}
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("gst", { required: { value: true, message: "Gst is required" } })}
                                                            placeholder={'Select Gst'}
                                                            components={{ IndicatorsContainer }}
                                                            value={forGST}
                                                            options={optionsGST}
                                                            onChange={handleChange}
                                                            // isOptionDisabled={(optionsGST: any) => {
                                                            //     if (optionsGST.status === "inactive" || optionsGST.deleted == true) {
                                                            //         return (
                                                            //             <>
                                                            //                 {optionsGST}<span className="badge badge-danger">Secondary</span>
                                                            //             </>
                                                            //         )
                                                            //     }
                                                            // }}
                                                            className="dropdown-select"

                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpen(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.gst?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Polymer Name </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Polymer Name"  {...register("polymer_name", { onChange: handleValue, required: { value: false, message: "Polymer Grade is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.polymer_name?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="status">Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status",
                                                        {
                                                            required: { value: true, message: "Status is required" }, maxLength: 30,

                                                        })}>
                                                        <option value="" selected>Select Any </option>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"inactive"}>Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        onClick={handleSubmit(onSubmit)} disabled={isLoading}>Submit</button>
                                                    <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listMaterialDensity")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
            <Gst_Model refetchForm={gstRefetch} open={open} setOpen={setOpen} setSubmitted={setSubmitted} />

            {/* <Footer /> */}
        </div>
    )

}

export default Add_MaterialDensity