
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import Actions from "../../components/Cell/Actions";
// import { updateBrand, useFetchBrands } from "../../hooks/brand";
// import noImage from "../../images/noImage.png"
import { useFetchGst, useUpdateGst } from "../../hooks/GST";
import { Update, useFetch, useUpdateSingle } from "../../hooks/Api";
import Modal from "react-bootstrap/esm/Modal";
import noImage from "../../images/noImage.png"


interface Data {
  selection: any
  category:any
  superAccountGroup:any,
  subAccountGroup:any
  accountStatementType:any
  sequence1:any
  sequence2:any
 
  created_at:any

  created_by:any
  updated_by:any
  updated_at:any
  status: any;
  actions: any;
}

export const titleofheaders=[
  {
    id: `srno`,
    Header: `Sr.No`,
    
  },


  {
    id: `category`,
    Header: `Account Group`,
   
  },
  {
    id: `superAccountGroup`,
    Header: `Super Account Group`,
   
  },



  {
    id: `subAccountGroup`,
    Header: `Sub Account Group`,
    
  },
 



  {
    id: `accountStatementType`,
    Header: `Account Category`,
    
  },



  {
    id: `sequence1`,
    Header: `Sequence 1`,
   
  },
  {
    id: `sequence2`,
    Header: `Sequence 2`,
   
  },
  



  
  {
    id: 'created_at',
    Header: 'Created at',
    
  },


  {
    id: 'created_by',
    Header: 'Created By',
    
 
  },
  {
    id: 'updated_at',
    Header: 'Updated At',
    
  },
  {
    id: 'updated_by',
    Header: 'Updated By',
    
  },

  {
    id: 'status',
    Header: 'Status',
    

 
  },

  {
    id: 'Actions',
    Header: 'Actions',

  
  },
  {
    id: 'Selection',
    Header:'Selection'
  }
]



export const Columns_Account: any = [



 
  {
    id: `srno`,
    Header: () => <div style={{display:"inline"}}>Sr<br/>No</div>,
    sticky:"left",
  },

  {
    id: `category`,
    Header: () => <div style={{display:"inline"}}>Account<br/>Group</div>,
    accessor:'category',
    sticky:"left",
    Cell: ({ row }: any) => 
    
    <div>
{row.original.category?.name}
    </div>
  },

  
  {
    id: `superAccountGroup`,
    Header: () => <div style={{display:"inline"}}>Super Account<br/>Group</div>,
    accessor:'superAccountGroup',
    Cell: ({ row }: any) => 
    
    <div>
{row.original.superAccountGroup?.name}
    </div>
  },
 

  

  {
    id: `subAccountGroup`,
    Header: () => <div style={{display:"inline"}}>Sub Account<br/>Group</div>,
    accessor:'subAccountGroup',
    Cell: ({ row }: any) => 
    
    <div>
{row.original.subAccountGroup?.name}
    </div>
  },



 


  {
    id: `accountStatementType`,
    Header: () => <div style={{display:"inline"}}>Account<br/>Category</div>,
    accessor:'accountStatementType',
    Cell: ({ row }: any) => 
    
    <div>
{row.original.accountStatementType?.name}
    </div>
  },


  {
    id: `sequence1`,
    Header: () => <div style={{display:"inline"}}>Sequence<br/>1</div>,
    accessor:'sequence1',
    Cell: ({row}:any)=> row.original.sequence1?.name?row.original.sequence1?.name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>,
//     Cell: ({ row }: any) => 
    
//     <div>
// {row.original.accountStatementType}
//     </div>
  },

  {
    id: `sequence2`,
    Header: () => <div style={{display:"inline"}}>Sequence<br/>2</div>,
    accessor:'sequence2',
    Cell: ({row}:any)=> row.original.sequence2?row.original.sequence2:<span style={{ color: 'red',fontSize:"20px" }}>****</span>,
//     Cell: ({ row }: any) => 
    
//     <div>
// {row.original.accountStatementType}
//     </div>
  },


  {
    id: 'status',
    Header: 'Status',
    accessor:'status',

 
  },

 



 
  
 


  

  
  {
    id: 'created_at',
    Header: () => <div style={{display:"inline"}}>Created<br/>At</div>,
    accessor:'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{display:"inline"}}>Created<br/>By</div>,
    accessor:'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username

 
  },
  {
    id: 'updated_at',
    Header: () => <div style={{display:"inline"}}>Updated<br/>At</div>,
    accessor:'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{display:"inline"}}>Updated<br/>By</div>,
    accessor:'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },

  
  {
    id: 'Actions',
    Header: 'Actions',

  
  },
  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_Account, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows} = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ACCOUNT_TYPE_API}/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



