import React, { useEffect, useRef, KeyboardEventHandler } from "react";
import Header from "../../components/Header/header";
import Sidebar from "../../components/Sidebar/sidebar";
import SearchLogo from "../../images/SearchLogo.png";
import ColorPickerLogo from "../../images/ColorPickerLogo.png";
import "../../components/Table/table.css";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useBrandPost } from "../../hooks/brand";
import { useState } from "react";
import { URL } from "node:url";
import Mui_Toaster from "../../components/Mui_Toaster/Mui_Toaster";
import { useCreate } from "../../hooks/Api";
import { Alert } from "../../components/Model/Model";
import useFocusOnEnter from "../../components/focus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import CreatableSelect from "react-select/creatable";
import { useFetchDepartmentDropDown, useFetchEmployeeDropDown, useFetchUserAdminDropDown } from "../../hooks/DropDown_Api";
import Select from 'react-select'
import { RiPencilLine } from "react-icons/ri";
import Button from "react-bootstrap/esm/Button";
import { AiOutlinePlus } from "react-icons/ai";
import "./userAdmin.css"
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'
import { Employee_Model } from "../../components/Dynamic_Form_Models/Employee_Model";


const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}

interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});


const Add_UserAdmin = ({ checkToast }: any) => {
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()
    const [iconChange, setIconChange] = useState("password")
    const [optionsUserType, setOptionsUserType]: any = useState([])
    const [valueCavity, setValueCavity]: any = useState();
    const [searchTerm, setSearchTerm] = useState('')
    const [open, setOpen]: any = useState(false)

    const [isLoadingValue, setIsLoadingValue] = useState(false);
    const [valueCavityCreatable, setValueCavityCreatable] = useState<Option | null>();
    const apiname = process.env.REACT_APP_LEOUSER_API?.toString()
    const { mutate, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)
    const apinameForUserAdmin = process.env.REACT_APP_LEOUSER_GROUP_API?.toString()

    const { mutate: UserAdminPost, data: dataCavity, isError: cavityIsError, isLoading: UserAdminLoading, isSuccess: UserAdminSuccess, error: cavityError }: any = useCreate(apinameForUserAdmin)
    const { register, handleSubmit, setError, formState, reset, setValue, getValues }: any = useForm({
        mode: "onChange"
    });
    const { errors } = formState;



    const changeicon = () => {
        if (iconChange == "password") {
            setIconChange("text")
        }
        else {
            setIconChange("password")
        }
    }

    const [user,setUser]:any = useState()
    const { data: machineNameData, refetch: machineNameRefetch } = useFetchUserAdminDropDown()
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsUserType(machineNameData)
    }, [machineNameData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            machineNameRefetch()
        }
    }, [])


    const handleChangeUserType = (e: any) => {
        const groups = e.map((value: any) => {
            return value.value
        })
        setValue("groups", groups)
        setUser(e)
    }


    // const handleCreate = (inputValue: string, event: any) => {


    //     inputValue = inputValue.toLowerCase()
    //         .split(' ')
    //         .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
    //         .join(' ');
    //     setIsLoadingValue(true);
    //     setTimeout(() => {
    //         const newOption = createOption(inputValue);
    //         setIsLoadingValue(false);


    //         UserAdminPost({ name: newOption.name, status: newOption.status })

    //         if (!inputValue) return;
    //         switch (event?.key) {
    //             case 'Enter':
    //             case 'Tab':
    //                 setOptionsUserType((prev: any) => [...prev, newOption]);
    //         }
    //         setValueCavityCreatable(newOption);
    //     }, 0);


    // };

    // useEffect(() => {
    //     (async () => {
    //         if (UserAdminSuccess && optionsUserType) {
    //             await machineNameRefetch();
    //         }
    //         setValue("groups", machineNameData[0]?.value)
    //     })();

    //     return () => {
    //     };
    // }, [UserAdminSuccess, machineNameData]);


    const onSubmit = (branddetail: any) => {
        const formData = new FormData();

        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value

            if (Array.isArray(value) && key === "groups") {
                value.forEach((val, index) => formData.append(`groups`, val))
            }
            else if (Array.isArray(value) && key === "department") {
                value.forEach((val, index) => formData.append(`department`, val))
            }
            // else if (Array.isArray(value) && key === "department") {
            //     value.forEach((val, index) => formData.append(`department`, val))
            // }
            else if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
        }
        mutate(formData);

    }





    const handleReset = () => {
        reset()
        setValueCavityCreatable(null)
    }


    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listLeouser', { state: { showToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])



    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);


    const handleUpper = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }
    // const [inputValue, setInputValue] = React.useState('');

    // const handleKeyDown: KeyboardEventHandler = (event) => {
    //     if (!inputValue) return;
    //     switch (event.key) {
    //       case 'Enter':
    //       case 'Tab':
    //         setValue((prev :any) => [...prev, createOption(inputValue)]);
    //         setInputValue('');
    //         event.preventDefault();
    //     }
    //   };
    const [optionsDepartment, setOptionsDepartment]: any = useState([])
    const [forDepartmentValue, setForDepartmentValue]: any = useState([])


    const [forEmployeeValue, setForEmployeeValue]: any = useState([])



    // const { data: departmentData, refetch: departmentRefetch } = useFetchDepartmentDropDown()

    // useEffect(() => {
    //     setOptionsDepartment(departmentData)
    // }, [departmentData])

    // const handleChange = (e: any) => {



    //     // const department = e?.map((value: any) => {
    //     //     return value?.value
    //     // })
    //     // setValue("department", department)    
    //     // setValue("department", e.value)

    //     // setForDepartmentValue(e?.map((e:any)=>{
    //     //     return(
    //     //         {label:e?.label,value:e?.value}
    //     //     )
    //     // }))

    //     // setForDepartmentValue(e)


    // }


    const [employeeDesignation, setEmployeeDesignation] = useState("")
    const [employeeMobile, setEmployeeMobile] = useState("")
    const [employeeEmail, setEmployeeEmail] = useState("")
    const [employeeDepartment, setEmployeeDepartment] = useState("")



    const [optionsEmployee, setOptionsEmployee]: any = useState([])


    const { data: employeeData, refetch: employeeRefetch } = useFetchEmployeeDropDown()
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsEmployee(employeeData)
    }, [employeeData])



    const [extendEmployee, setExtendEmployee] = useState(false)


    const handleEmployeeChange = (e: any) => {



        setEmployeeDesignation(e.designation)
        setEmployeeMobile(e.mobile)
        setEmployeeEmail(e.email)
        setEmployeeDepartment(e.department)
        setValue("department", e.department_id)
        // setValue("department",e.designation)
        // setValue("mobile_number",e.mobile)
        // setValue("email",e.email)

        setValue("code_and_employees_name_and_city", e.value)
        setForEmployeeValue(e)
        setExtendEmployee(true)

    }

    const [SubmittedEmployee, setSubmittedEmployee]: any = useState(false)
    useEffect(() => {
        if (SubmittedEmployee) {

            setForEmployeeValue(optionsEmployee[0])
            setEmployeeDesignation(optionsEmployee[0].designation)
            setEmployeeMobile(optionsEmployee[0].mobile)
            setEmployeeEmail(optionsEmployee[0].email)
            setEmployeeDepartment(optionsEmployee[0].department)

            setValue("code_and_employees_name_and_city", optionsEmployee[0]?.value)

        }

    }, [SubmittedEmployee, optionsEmployee])


    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Add User Admin</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Product Details</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listUserAdmin" className="text-decoration-none text-black">User Admin</Link></li>
                                    <li className="breadcrumb-item active">Add User Admin</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">

                            <form
                                ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh" }}>
                                    {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message[0]} /> : ""}
                                    {checkToast && show && showmessage === "success" &&

                                        <Mui_Toaster message={"Brand Added Successfully"} severity={"success"} Isopen={true} />
                                    }


                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">

                                            <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">User ID <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="User ID"  {...register("username", { required: { value: true, message: "User ID is required" }, maxLength: 30, })}
                                                            onChange={handleUpper}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.username?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">User Password <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group-form" style={{ width: "100%" }}>
                                                        <input
                                                            type={iconChange ? iconChange : "password"}
                                                            className="form-control rounded-3"
                                                            placeholder="Password"
                                                            {...register("password", { required: { value: true, message: "Password is required" } })}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="iconset" id="basic-addon1"><FontAwesomeIcon onClick={changeicon} icon={iconChange == "password" ? faEye : faEyeSlash} /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.password?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name_and_grade">Code & Employees Name & City </label>
                                                    {/* <div className="input-group" ></div> */}
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("code_and_employees_name_and_city", { required: { value: false, message: "Field is required" } })}
                                                            // placeholder={'Department'}
                                                            onChange={handleEmployeeChange}
                                                            value={forEmployeeValue}
                                                            options={optionsEmployee}
                                                            maxMenuHeight={120}
                                                            components={customIndicator}
                                                        className="dropdown-select"
                                                        // isMulti
                                                        />
                                                        <div className="input-group-append" style={{width:0, padding:0}}>
                                                            <Button className="input-group-text border-start-0 bg-primary"  onClick={()=>{
                                                                setOpen(true)
                                                                }}><AiOutlinePlus/></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.code_and_employees_name_and_city?.message}</span>

                                                </div>


                                                {/* {extendEmployee && <> */}


                                                    <div className="col-sm-3 mt-2">
                                                        <label htmlFor="exampleInputEmail1">Employee Department</label>
                                                        <div className="input-group" data-colorpicker-id={2}>
                                                            <input type="text" className="form-control" id="exampleInputBrand" placeholder="Employee Department"
                                                                disabled
                                                                value={employeeDepartment}
                                                            />
                                                            {/* <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 mt-2">
                                                        <label htmlFor="exampleInputEmail1">Employee Designation</label>
                                                        <div className="input-group" data-colorpicker-id={2}>
                                                            <input type="text" className="form-control" id="exampleInputBrand" placeholder="Employee Designation"
                                                                disabled
                                                                value={employeeDesignation}
                                                            />
                                                            {/* <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 mt-2">
                                                        <label htmlFor="exampleInputEmail1">Employee Mobile No.</label>
                                                        <div className="input-group" data-colorpicker-id={2}>
                                                            <input type="text" className="form-control" id="exampleInputBrand" placeholder="Employee Mobile No."
                                                                disabled
                                                                value={employeeMobile}
                                                            />
                                                            {/* <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 mt-2">
                                                        <label htmlFor="exampleInputEmail1">Employee Email ID</label>
                                                        <div className="input-group" data-colorpicker-id={2}>
                                                            <input type="text" className="form-control" id="exampleInputBrand" placeholder="Employee Email ID"
                                                                disabled
                                                                value={employeeEmail}
                                                            />
                                                            {/* <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div> */}
                                                        </div>
                                                    </div>


                                                {/* </>} */}


                                                

                                                {/* <div className="col-sm-3 mt-2">
                                                <label htmlFor="name_and_grade">Department <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select 
                                                            {...register("department", { required: { value: true, message: "Department is required" } })}
                                                            placeholder={'Department'}
                                                            onChange={handleChange}
                                                            value={forDepartmentValue}
                                                            options={optionsDepartment}
                                                            maxMenuHeight={120}
                                                        />
                                                      
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.department?.message}</span>

                                                </div> */}











                                                

                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Code & Employees Name & City <span style={{color:'red'}}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Code & Employees Name & City"  {...register("code_and_employees_name_and_city",{ required: { value: true, message: "Employees Name & City is required" }, maxLength: 30,})} 
                                                        onChange={handleUpper}
                                                        />
                                                         <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                        <span className='text-red w-100'>{errors?.code_and_employees_name_and_city?.message}</span>
                                                </div> */}



















                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">User Type <span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        {...register("groups", { required: { value: true, message: "User Type is required" } })}
                                                        placeholder={'User Type'}
                                                        onChange={handleChangeUserType}
                                                        components={customIndicator}
                                                        options={optionsUserType}
                                                        isMulti
                                                        value={user}
                                                        maxMenuHeight={120}
                                                    />
                                                    <span className='text-red w-100'>{errors?.groups?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Finger Print ID </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Finger Print ID"  {...register("finger_print_id", { required: { value: false, message: "Finger Print ID is required" }, maxLength: 30, })}
                                                            onChange={handleUpper}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.finger_print_id?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Face ID </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Face ID"  {...register("face_id", { required: { value: false, message: "Face ID is required" }, maxLength: 30, })}
                                                            onChange={handleUpper}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.face_id?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status",
                                                        {
                                                            required: { value: true, message: "Status is required" }, maxLength: 30,

                                                        })}>
                                                        <option value="" selected>Select Any </option>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"inactive"}>Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>
                                                </div>


                                            </div>
                                        </div>
                                    </div>

































                                    {/* <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">User ID <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="User ID"  {...register("username", { required: { value: true, message: "User ID is required" }, maxLength: 30, })}
                                                            onChange={handleUpper}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.username?.message}</span>
                                                </div>


                                              











                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">User Password <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group-form" style={{ width: "100%" }}>
                                                        <input
                                                            type={iconChange ? iconChange : "password"}
                                                            className="form-control rounded-3"
                                                            placeholder="Password"
                                                            {...register("password", { required: { value: true, message: "Password is required" } })}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="iconset" id="basic-addon1"><FontAwesomeIcon onClick={changeicon} icon={iconChange == "password" ? faEye : faEyeSlash} /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.password?.message}</span>
                                                </div>

                                            



















                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">User Type <span style={{ color: 'red' }}>*</span></label>

                                                  
                                                    <Select
                                                        {...register("groups", { required: { value: true, message: "User Type is required" } })}
                                                        placeholder={'User Type'}
                                                        onChange={handleChangeUserType}
                                                        components={customIndicator}
                                                        options={optionsUserType}
                                                        isMulti
                                                        value={user}
                                                        maxMenuHeight={120}
                                                    />
                                                    <span className='text-red w-100'>{errors?.groups?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status",
                                                        {
                                                            required: { value: true, message: "Status is required" }, maxLength: 30,

                                                        })}>
                                                        <option value="" selected>Select Any </option>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"inactive"}>Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>
                                                </div>














                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        onClick={handleSubmit(onSubmit)} disabled={isLoading}>Submit</button>
                                                    <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listLeouser")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </section>
            </div>

            <Employee_Model refetchForm={employeeRefetch} open={open} setOpen={setOpen} setSubmitted={setSubmittedEmployee} />



        </div>
    )
}

export default Add_UserAdmin