import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { TiTickOutline } from "react-icons/ti";

import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Restricted from "../../Roles/Restricted";
const Verify_Dispatch_Products = ({ row, apiname, deletemodalask,permissionClass,dos }: any) => {
  return (
    <>
    <div
        className="vertical-align-center"
        style={{
          display: "flex !important",
          justifyContent: "space-evenly !important",
          
        }}
      >
        
      
        {/* <Restricted
          to={`${permissionClass?.front}.change_${permissionClass?.back}`}
          fallback={""}
        > */}
        <Link
         to={`/verifydispatch_order_list?dos=${encodeURIComponent(JSON.stringify(dos))}`}
          className="btn btn-primary text-white mx-2"
        >
          Verify
        </Link>
        {/* </Restricted> */}

       
      </div>
    </>
  );
};

export default Verify_Dispatch_Products;

