import React from 'react';
import {Permission} from "./Permission";
import PermissionContext from "./PermissionContext";

type Props = {
    permissions: Permission[]
    children: any
}

// This provider is intended to be surrounding the whole application.
// It should receive the users permissions as parameter
const PermissionProvider: any = ({permissions, children}:Props) => {

    // Creates a method that returns whether the requested permission is available in the list of permissions
    // passed as parameter
    const isAllowedTo = (permission: Permission) => permissions.includes(permission);

    // This component will render its children wrapped around a PermissionContext's provider whose
    // value is set to the method defined above
    return <PermissionContext.Provider value={{isAllowedTo}}>{children}</PermissionContext.Provider>;
};

export default PermissionProvider;