import React from 'react'
import "./quotation_test.css"
import test from "../../../images/logo.png"

const Quotation_test = () => {
  return (
    <>
      <div className="container">
        <div className="card">
            <div className="blueBox">
                <img src={test} alt="Logo" className="img" />
                <div className="HeaderText">SQ08899</div>
            </div>

            <div className="invoiceLine"></div>
            <div className="addressLine"></div>
            <div className="logoLine"></div>

            <div className="headerRow">
                <div className="col">
                    <div className="label">Invoice for</div>
                    <div className="header">Thomas Lee</div>
                    <div>2552 Avenue</div>
                    <div>2552 Avenue</div>
                    <div>2552 Avenue</div>
                    <div>2552 Avenue</div>
                </div>

                <div className="invoiceLine"></div>

                <div className="col">
                    <div className="header">Delivery Location</div>
                    <div>2552 Avenue</div>
                    <div>Boggstown, India</div>
                    <div>India 360057</div>
                </div>

                <div className="addressLine"></div>

                <div className="col">
                    <div className="bgLight centeredText">
                        <div className="header">AMOUNT</div>
                        <div className="amount">RS. 23,600</div>
                        <div>APR 8, 2023</div>
                    </div>
                </div>
            </div>

            <div className="headerRowDetails">
                <div className="colDetails">
                    <div className="headerDetails">Quotation Number : SQ08899</div>
                    <div className="headerDetails">Delivery Date: APR 8, 2023</div>
                    <div className="headerDetails">Number Of Item: 05</div>
                </div>

                <div className="colDetails">
                    <div className="headerDetails">Quotation Date : APR 8, 2023</div>
                    <div className="headerDetails">Amendment: 0</div>
                    <div className="headerDetails">Total Amount: 23,600</div>
                </div>
            </div>

            <table className="table">
                <tr className="tableRowHeader">
                    <th className="tableColHeader">Product</th>
                    <th className="tableColHeader">Type</th>
                    <th className="tableColHeader">Period</th>
                    <th className="tableColHeader">Price</th>
                    <th className="tableColHeader">Header 5</th>
                    <th className="tableColHeader">Header 6</th>
                    <th className="tableColHeader">Header 7</th>
                    <th className="tableColHeader">Header 8</th>
                    <th className="tableColHeader">Header 9</th>
                    <th className="tableColHeader">Header 10</th>
                    <th className="tableColHeader">Header 11</th>
                    <th className="tableColHeader">Header 12</th>
                    <th className="tableColHeader">Header 13</th>
                    <th className="tableColHeader">Header 14</th>
                    <th className="tableColHeader">Header 15</th>
                    <th className="tableColHeader">Header 16</th>
                </tr>

              
                <tr className="tableRow">
                    <td className="tableCol">Large Product</td>
                    <td className="tableCol">10 User</td>
                    <td className="tableCol">2019-02-20 - 2020-02-19</td>
                    <td className="tableCol">10€</td>
                    <td className="tableCol">Field 1</td>
                    <td className="tableCol">Field 2</td>
                    <td className="tableCol">Field 3</td>
                    <td className="tableCol">Field 4</td>
                    <td className="tableCol">Field 5</td>
                    <td className="tableCol">Field 6</td>
                    <td className="tableCol">Field 7</td>
                    <td className="tableCol">Field 8</td>
                    <td className="tableCol">Field 9</td>
                    <td className="tableCol">Field 10</td>
                    <td className="tableCol">Field 11</td>
                    <td className="tableCol">Field 12</td>
                </tr>
            </table>
        </div>
    </div>
    </>
  )
}

export default Quotation_test