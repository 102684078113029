import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useBrandEdit, useUpdateBrand } from "../../hooks/brand";
import { Controller, useForm } from "react-hook-form";
import { Alert } from "../../components/Model/Model";
import { RiPencilLine } from "react-icons/ri";
import { useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../hooks/Api";
import useFocusOnEnter from "../../components/focus";
import { Deletesinglewithdependancy } from "../../components/modal";
import 'bootstrap/dist/css/bootstrap.min.css';
   import Modal from 'react-bootstrap/Modal';
   import moment from "moment";
   import Select from 'react-select'

import { DatePicker, Space, TimePicker } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { useFetchDepartmentDropDown, useFetchResponsiblePerson } from "../../hooks/DropDown_Api";
import { type } from "os";
import "react-datepicker/dist/react-datepicker.css";
import DatePickerReact from "react-datepicker";
import { FaCalendarAlt } from "react-icons/fa";

const Edit_CheckList = () => {
    const focus:any = useRef();
    const {onEnterKey} = useFocusOnEnter(focus);
    const { id } = useParams()
    const navigate = useNavigate()

    const [logo, setlogo] = useState(null)
    const [logoPdf, setlogoPdf] = useState(null)
    const [openEditModel,setOpenEditModel] = useState(false)
    const [expirationDate,setExpirationDate] = useState()
const [confirmmodal,setConfirmmodal]=useState(false)
const [valuesofform,setValuesofform]=useState<any>()
    const apiname = process.env.REACT_APP_CHECKLIST_API?.toString()

    const {data} = useFetchSingle(id,apiname)

    const onLogoChange = (e: any) => {

        setlogo(e.target.files[0].name)
    }

    const onLogoPDFChange = (e: any) => {
        setlogoPdf(e.target.files[0].name)
    }

    const { mutate, isSuccess, isError,error,data: brandUpdated_response }: any = useUpdateSingle()
    const { mutate:mutateyes, isSuccess:yessuccess, isError:yeserror, error:yeserrordetail, data: yesupdated_response }:any = useUpdateSingleforcewithyes()
    const { mutate:mutateno, isSuccess:nosuccess, isError:noerror, error:noerrordetail, data: noupdated_response }:any = useUpdateSingleforcewithno()
 
    const { register,control, handleSubmit, setError, formState: { errors,isDirty }, reset, setValue,getValues } :any= useForm();


    const [optionsDepartment, setOptionsDepartment] = useState([])
    const [selDepartment, setSelDepartment] = useState('')
    const [selectedDepartment, setSelectedDepartment]:any = useState('')


    const { data: colorData, refetch: colorRefetch } = useFetchDepartmentDropDown()

    useEffect(() => {
        setOptionsDepartment(colorData)
    }, [colorData])


  






  

    const handleChange = (e: any) => {
       
    
        const department = e.map((value: any) => {
            return value.value
        })
        setValue("department_name", department)   
        setSelDepartment(e)
        setSelectedDepartment([])
    }



   



    let pointOpenTime = data?.point_open_time
    let openHour = pointOpenTime?.split(":")[0]
    let openMinute = pointOpenTime?.split(":")[1]

    let open:any = `${openHour}:${openMinute}`



    let pointCloseTime = data?.point_close_time
    let closeHour = pointCloseTime?.split(":")[0]
    let closeMinute = pointCloseTime?.split(":")[1]

    let close:any = `${closeHour}:${closeMinute}`

    useEffect(() => {
        if (data) {
            
          reset(data)
          setValue("department_name",data?.department_name?.map((e:any) => e.id ))
          setSelectedDepartment(data?.department_name?.map((e:any) => e.id ))

          setValue("point_start_date",new Date( moment(data.point_start_date, "DD-MM-YYYY").toISOString()))
        //   setValue("point_open_time", dayjs("13:55", "HH:mm"))

          setValue("point_end_date",new Date( moment(data.point_end_date, "DD-MM-YYYY").toISOString()))

          if(data?.emergency_on_date){
            setValue("emergency_on_date",new Date( moment(data.emergency_on_date, "DD-MM-YYYY").toISOString()))

          }

          if(data?.emergency_close_date){
            setValue("emergency_close_date",new Date( moment(data.emergency_close_date, "DD-MM-YYYY").toISOString()))

          }


          if(data?.emergency_on_date!==""){
            setEmergencyOn(true)
          }

          setValue("name_and_surname",data?.name_and_surname?.map((e:any)=>e?.id))

          setSelectedPerson(data?.name_and_surname?.map((e:any)=>e?.id))

     






        }
    }, [data]);




    const onSubmit = (branddetail: any) => {


        if(!emergencyOn){
            delete branddetail.emergency_on_date
            delete branddetail.emergency_close_date
            delete branddetail.emergency_on_reason
            delete branddetail.emergency_off_reason
            delete branddetail.emergency_on_off_person_name
        }

        // if(!forEmergencyOnDateValue){
        //     delete branddetail.emergency_on_date
        // }
        // if(!forEmergencyOffDateValue){
        //     delete pannidbranddetailetail.emergency_close_date
        // }


        // if(!forPointStartDateValue){
        //     delete pannidetail.point_start_date
        // }
        // if(!forPointEndDateValue){
        //     delete branddetail.point_end_date
        //     delete branddetail.total_running_days
        //     delete branddetail.pending_running_days
        // }

        
        // if(!forOpenTimeValue){
        //     delete branddetail.point_open_time
        // }
        // if(!forCloseTimeValue){
        //     delete branddetail.point_close_time
        // }

        

        const formData = new FormData();
     

        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else if (Array.isArray(value) && key === "department_name") {
              value.forEach((val, index) => formData.append(`department_name`, val))
          }
          else if ( key === "point_start_date") {
            const renewdate=moment(branddetail.point_start_date,'DD-MM-YYYY').format("DD-MM-YYYY")
            formData.append(key,renewdate)
        }

        else if ( key === "point_end_date") {
            const renewdate=moment(branddetail.point_end_date,'DD-MM-YYYY').format("DD-MM-YYYY")
            formData.append(key,renewdate)
        }

        else if ( key === "emergency_on_date") {
            const renewdate=moment(branddetail.emergency_on_date,'DD-MM-YYYY').format("DD-MM-YYYY")
            formData.append(key,renewdate)
        }

        else if ( key === "emergency_close_date") {
            const renewdate=moment(branddetail.emergency_close_date,'DD-MM-YYYY').format("DD-MM-YYYY")
            formData.append(key,renewdate)
        }
        else if (Array.isArray(value) && key === "name_and_surname") {
            value.forEach((val, index) => formData.append(`name_and_surname`, val))
        }

        }
        let data = {
            id:id,
            apiname:apiname,
            data:formData
        }
        mutate(data);
        setValuesofform(data)
    }





    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess||yessuccess||nosuccess) {
            setValuesofform(null)
            setShow(true)
            navigate('/listChecklist', { state: { showEditToast: true }})
        }
      
     }, [data, isSuccess,yessuccess,
        nosuccess,brandUpdated_response])

        useEffect(()=>{
            if(isError)
            {
                if(error.response.status===405){
                    setConfirmmodal(true)
                }
            }
            else if (isError||yeserror||noerror) {
                setShow(true)
                setshowmessage("danger")
            }
        },[isError,yeserror,noerror])
    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);
    
        const yesupdatemodal=()=>{
        setConfirmmodal(false);
        mutateyes(valuesofform)
        }
        const cancelupdatemodal=()=>{
           setConfirmmodal(false) ;
        }

        const handleValue=(e:any)=>{
            e.target.value = e.target.value.toLowerCase()
        .split(' ')
        .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
        }

        const noupdatemodal=()=>{
            setOpenEditModel(true)
          }
    
    
          const handleExpirationDate=(e:any)=>{
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }
    
    const handleConfirm=(data:any)=>{
             
       
        let ok = {
            id:id,
            apiname:apiname,
            data:data,
        }
    
        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditModel(false)
    }























    const [openTime,setOpenTime]:any = useState()
    const [closeTime,setCloseTime]:any = useState()
    const [minutes,setMinutes]:any = useState()











const [totalDays,setTotalDays]:any = useState()
const [remainingDays,setRemainingDays]:any = useState()
















const [emergencyOnDate,setEmergencyOnDate]:any = useState()
const [emergencyOffDate,setEmergencyOffDate]:any = useState()

const handleEmegencyOnDate =(e:any)=>{
    setEmergencyOnDate(e)
   
}

const handleEmergencyOffDate =(e:any)=>{
    setEmergencyOffDate(e)
   

}


useEffect(()=>{

    if(emergencyOnDate && emergencyOffDate){
        setValue("emergency_on_date",`${emergencyOnDate.$y}-${emergencyOnDate.$M+1}-${emergencyOnDate.$D}`)
        setValue("emergency_close_date",`${emergencyOffDate.$y}-${emergencyOffDate.$M+1}-${emergencyOffDate.$D}`)
    }

},[emergencyOnDate,emergencyOffDate])





const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {

        return  current && current  < dayjs().startOf('day');
      };


      const format = 'HH:mm';



      const[openTimeValue,setOpenTimeValue]:any = useState()

const handleOpenTimePicker=(time:any,timeString:any)=>{
    setOpenTimeValue(dayjs(time, 'HH:mm'))


    setValue("point_open_time",`${time?.$H}:${time?.$m}`)
}



const[closeTimeValue,setCloseTimeValue]:any = useState()

const handleCloseTimePicker=(time:any,timeString:any)=>{
    setCloseTimeValue(dayjs(time, 'HH:mm'))


    setValue("point_close_time",`${time.$H}:${time.$m}`)
}

useEffect(()=>{
    if(openTimeValue && closeTimeValue){

        const TimeOne = moment()
        .set('hour',openTimeValue?.$H)
        .set('minute',openTimeValue?.$m)
        const TimeTwo = moment()
        .set('hour',closeTimeValue?.$H)
        .set('minute',closeTimeValue?.$m)

        setMinutes(TimeTwo.diff(TimeOne, 'minutes'))


        setValue("total_min",minutes)

    }
},[openTimeValue,closeTimeValue,minutes])




const [startDate,setStartDate]:any = useState()
const [endDate,setEndDate]:any = useState()

const handleStartDate=(e:any)=>{
    setStartDate(e)
    setValue("point_start_date",e)
    }

    const handleEndDate=(e:any)=>{
        setEndDate(e)
        setValue("point_end_date",e)

        }



        useEffect(()=>{


            if(startDate && endDate){



                let openDate = startDate.toISOString().split("T")[0].split("-")
                let closeDate = endDate.toISOString().split("T")[0].split("-")


                // let check = moment(openDate).add(1,"d")





        
              
              
                let todayDate = new Date().toISOString().split("T")[0]
        
        
                var DateOne = moment([openDate[0], openDate[1], openDate[2]]);
        
                var DateTwo = moment([closeDate[0], closeDate[1], closeDate[2]]);
        
                var currentDate = moment([todayDate.split("-")[0], todayDate.split("-")[1], todayDate.split("-")[2]]);
        
        
        
        
                
                setTotalDays(DateTwo.diff(DateOne, 'days')+1)
        
                setRemainingDays(DateTwo.diff(currentDate,'days')+2)
        
                // setValue("point_start_date",`${startDate.$y}-${startDate.$M+1}-${startDate.$D}`)
                // setValue("point_end_date",`${endDate.$y}-${endDate.$M+1}-${endDate.$D}`)
        
                setValue("total_running_days",totalDays)
                setValue("pending_running_days",remainingDays)
        
        
        
        
        
        
        
        
            }
        },[startDate,endDate,totalDays,remainingDays])

        const [emergencyOn,setEmergencyOn] = useState(false)






        const [optionsResponsiblePerson, setOptionsResponsiblePerson]:any = useState([])
        const[forResponsiblePersonValue,setForResponsiblePersonValue]:any = useState([])
        const[selPerson,setSelPerson] = useState("")
        const[selectedPerson,setSelectedPerson] = useState("")
      
      
        
        const { data: personData , refetch: personRefetch } = useFetchResponsiblePerson()
      
        useEffect(() => {
          setOptionsResponsiblePerson(personData);
        }, [personData])
      
      
        const handleResponsiblePerson = (e: any) => {
              
          const printing_color_name = e.map((value: any) => {
              return value.value
          })
          setValue("name_and_surname",printing_color_name)
          setSelPerson(e)
      }


      const disabledTime = (selectedHour:any) => {
        const hours:any = [];
        const minutes:any = []
    
       
        
        const currentHour = openTimeValue?.$H
        const currentMinute = openTimeValue?.$m;

        
    
    
        for (let i = currentHour - 1; i <= 24; i--) {
       
          hours.push(i);
          if(i == -1){
            break
        }
        }
    
       
            for (let i = currentMinute + 0; i <= 60; i--) {
              minutes.push(i);
              if(i == -1){
                break
            }
            }
        
    
        return{
            disabledHours: () => hours,
            disabledMinutes: () => minutes,
        }
    
    
        
      };



    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit Checklist</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                                    {/* <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Product Details</Link></li> */}
                                    <li className="breadcrumb-item"><Link to="/listCheckList" className="text-decoration-none text-black">Checklist</Link></li>
                                    <li className="breadcrumb-item active">Edit Checklist</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                           
                            <form 
                            ref={focus}
                            onKeyUp={event => onEnterKey(event)}
                            onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>

                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">


                                            <div className="">
        <div className="title"></div>
        <div className="form">
            <label><input type="radio" className="input-radio off" value={"normal"} {...register("normal")} checked={emergencyOn === false} name="emergency"
            // onChange={handleNormal}
            onClick={()=>setEmergencyOn(false)}
            />Normal</label>
            <label><input type="radio" className="input-radio on"  checked={emergencyOn === true} value={"emergency"} {...register("emergency")} name="emergency"
            onClick={()=>setEmergencyOn(true)}
            />Emergency</label>
        </div>
    </div>







                                            <div className="col-sm-4 mt-2">
                                                <label htmlFor="table_sr_no">Point <span style={{ color: "red" }}>*</span></label>
                                                <div className="input-group">
                                                    <input type="text" placeholder="Point" className="form-control" id="table_sr_no"  
                                                    {...register("name", { onChange: handleValue, required: { value: true, message: "Point is required" } })} />
                                                    <div className="input-group-append" >
                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                        /></span>
                                                    </div>
                                                </div>
                                                <span className='text-red w-100'>{errors?.name?.message}</span>
                                            </div>
                                            <div className="col-sm-4 mt-2">
                                                <label htmlFor="brand_type">Yes-No <span style={{ color: "red" }}>*</span></label>
                                                <select className="form-select" {...register("choice_yes_no", { required: { value: true, message: "Field is required" } })}>
                                                    <option value={""} selected>Select Any</option>
                                                    <option value={"yes"}>Yes</option>
                                                    <option value={"no"}>No</option>
                                                </select>
                                                <span className='text-red w-100'>{errors?.choice_yes_no?.message}</span>
                                            </div>
                                            <div className="col-sm-4 mt-2">
                                                <label htmlFor="name_and_grade">Department <span style={{ color: "red" }}>*</span></label>
                                                <div className="d-flex row" >
                                                    
                                                    <Select
                                                        {...register("department_name", { required: { value: true, message: "Department is required" } })}
                                                        placeholder={'Department'}
                                                        onChange={handleChange}
                                                        options={optionsDepartment}
                                                        isMulti
                                                        // value={selDepartment === "" ? optionsDepartment?.filter((obj: any) => obj.value === selectedDepartment) : selDepartment}
                                                        value={selDepartment.length === 0 ? optionsDepartment?.filter((obj: any) => selectedDepartment.includes(obj.value)) : selDepartment}

                                                        />
                                                </div>
                                                <span className='text-red w-100'>{errors?.department_name?.message}</span>

                                            </div>
                                            {/* <div className="col-sm-4 mt-2">
                                                <label htmlFor="sub_name">Responsible Person <span style={{ color: "red" }}>*</span></label>
                                                <div className="input-group" >
                                                    <input type="text" placeholder="Responsible Person" className="form-control"   
                                                    {...register("name_and_surname", { onChange: handleValue, required: { value: true, message: "Responsible Person is required" } } )} />
                                                    <div className="input-group-append" >
                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                        /></span>
                                                    </div>
                                                </div>
                                                <span className='text-red w-100'>{errors?.name_and_surname?.message}</span>
                                            </div> */}



                                            <div className="col-sm-4 mt-2">
                                            <label htmlFor="sub_name">Responsible Person <span style={{ color: 'red' }}>*</span></label>
                                                            <div className="d-flex row" >
                                                            <Select
                                                                {...register("name_and_surname", { required: { value: false, message: "Responsible Person is required" } })}
                                                                placeholder={'Select'}
                                                                // value={forResponsiblePersonValue}
                                                                value={selPerson.length === 0 ? optionsResponsiblePerson?.filter((obj: any) => selectedPerson.includes(obj.value)) : selPerson}
                                                                options={optionsResponsiblePerson}
                                                                // onInputChange={handlePanniInputChange}
                                                                onChange={handleResponsiblePerson}
                                                                isMulti={true}
                                                                // isOptionDisabled={(optionsPanniName:any)=>
                                                                //     {
                                                                //         if(optionsPanniName.status === "inactive" || optionsPanniName.deleted == true){
                                                                //         return optionsPanniName
                                                                //         }
                                                                //        }}
                                      
                                                                />
                                                              
                                                            </div>
                                                        </div>









                                            <div className="col-sm-4 mt-2">
                                                <label htmlFor="full_code"> Point Open Time<span style={{ color: "red" }}>*</span></label>
                                                <div className="input-group" style={{justifyContent:"center"}}>



                                                    

   
                                              


<TimePicker 
allowClear={false}
 format={format}
  className="form-control" 

  value={openTimeValue==undefined?dayjs(open,"HH:mm"):openTimeValue}
 {...register("point_open_time", { required: { value: false, message: "Point Open Time is required" } })}
 onChange={
    handleOpenTimePicker
    }
/>

                                                 
                                                </div>
                                                <span className='text-red w-100'>{errors?.point_open_time?.message}</span>

                                            </div>
                                            <div className="col-sm-4 mt-2">
                                                <label htmlFor="default_no">Point Close Time<span style={{ color: "red" }}>*</span></label>
                                                <div className="input-group" style={{justifyContent:"center"}}>
                                                {/* <TimePicker size="l" state="normal" 
                                                 onChange={(e:any)=>setCloseTime(e)} 
                                                /> */}

<TimePicker  
allowClear={false}
format={format}  className="form-control" 
{...register("point_close_time", { required: { value: false, message: "Point Close Time is required" } })}
  value={closeTimeValue==undefined?dayjs(close,"HH:mm"):closeTimeValue}
  disabledTime={disabledTime}

 

 onChange={
    handleCloseTimePicker
    }
/>


                                                    
                                                  
                                                </div>
                                                <span className='text-red w-100'>{errors?.point_close_time?.message}</span>

                                            </div>
                                            <div className="col-sm-4 mt-2">
                                                <label htmlFor="manualcode_1">Total Minutes</label>
                                                <div className="input-group" >

                                                    <input type="text" disabled placeholder="Total Minutes" className="form-control" id="total_time" 
                                                    value={minutes}
                                                     {...register("total_min", { onChange: handleValue })} />
                                                   
                                                    <div className="input-group-append" >
                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                        /></span>
                                                    </div>
                                                </div>
                                                

                                            </div>
                                            <div className="col-sm-4 mt-2">
                                                <label htmlFor="manualcode_Disc_1">Point Start Date <span style={{ color: "red" }}>*</span></label>
                                                <div className="input-group" >




<Controller
            control={control}
            name="point_start_date"
            rules={{ required: {
              value:true,
              message:"Please Choose a Point Start Date"
            } }}
            render={({ field: { onChange, onBlur, value, } }) => (
                <>
              <DatePickerReact
             className="form-control"

                onChange={handleStartDate} 
                // selected={value}
                selected={startDate==undefined?value:startDate}

                dateFormat="dd/MM/yyyy"
                minDate={new Date()}
                showIcon
              />

        </>
            )}
          />



                                                  
                                                </div>  
                                                <span className='text-red w-100'>{errors?.point_start_date?.message}</span>

                                            </div>
                                            <div className="col-sm-4 mt-2">
                                                <label htmlFor="manualcode_2">Point Close Date </label>
                                                <div className="input-group" data-colorpicker-id={2}>

                                   

<Controller
            control={control}
            name="point_end_date"
            rules={{ required: {
              value:false,
              message:"Please Choose a date"
            } }}
            render={({ field: { onChange, onBlur, value, } }) => (
                <>
              <DatePickerReact
             className="form-control"

                onChange={handleEndDate} 
                // selected={value}
                selected={endDate==undefined?value:endDate}
                dateFormat="dd/MM/yyyy"
                minDate={new Date()}
                showIcon
              />

        </>
            )}
          />
                                                 
                                                </div>
                                                <span className='text-red w-100'>{errors?.point_end_date?.message}</span>

                                            </div>
                                            <div className="col-sm-4 mt-2">
                                                <label htmlFor="manualcode_Disc_2">Total Running Day </label>
                                                <div className="input-group" data-colorpicker-id={2}>
                                                    <input type="text" disabled placeholder="Total Running Day" className="form-control" id="manualcode_Disc_2"  
                                                    value={totalDays}
                                                    {...register("total_running_days", { onChange: handleValue })} />
                                                    <div className="input-group-append" >
                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                        /></span>
                                                    </div>
                                                </div>
                                                {/* <span className='text-red w-100'>{errors?.total_day?.message}</span> */}

                                            </div>
                                            <div className="col-sm-4 mt-2">
                                                <label htmlFor="serial_no">Remaining Running Day </label>
                                                <div className="input-group" >
                                                    <input type="text" disabled placeholder="Running Day" className="form-control" id="serial_no"  
                                                    value={remainingDays}
                                                    {...register("pending_running_days", { onChange: handleValue })} />
                                                    <div className="input-group-append" >
                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                        /></span>
                                                    </div>
                                                </div>
                                                {/* <span className='text-red w-100'>{errors?.pending_running_days?.message}</span> */}

                                            </div>
                                            {emergencyOn&&<>
                                            <div className="col-sm-4 mt-2">
                                                <label htmlFor="manualcode_Disc_1">Emergency On Date <span style={{ color: "red" }}>*</span></label>
                                                <div className="input-group" >

                                                    {/* <input type="date" placeholder="Manualcode Discription-1" className="form-control" id="start_date"  {...register("start_date", { onChange: handleValue })} /> */}
                                                    {/* <DatePicker 
                                                        className="form-control"
                                                        format={dateFormatList}
                                                        value={checkEmergencyStartDate}
                                                        disabledDate={disabledDate}
                                                      
                                                        {...register("emergency_on_date", { required: { value: false, message: "Field is required" } })}
                                                        onChange={handleEmegencyOnDate}


                                                        /> */}



                                                        
<Controller
            control={control}
            name="emergency_on_date"
            rules={{ required: {
              value:false,
              message:"Please Choose a date"
            } }}
            render={({ field: { onChange, onBlur, value, } }) => (
                <>
              <DatePickerReact
            className="form-control"

                onChange={onChange} // send value to hook form
                // onBlur={onBlur} // notify when input is touched/blur
                selected={value}
                dateFormat="dd/MM/yyyy"
                minDate={new Date()}
                showIcon
                // maxDate={new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0)}
              />
{/* <div className="input-group-append" >
        <span className="input-group-text">
          <FaCalendarAlt />
        </span>
        </div> */}
        </>
            )}
          />
                                                
                                                </div>
                                                <span className='text-red w-100'>{errors?.emergency_on_date?.message}</span>

                                            </div>
                                            <div className="col-sm-4 mt-2">
                                                <label htmlFor="manualcode_2">Emergency On Reason <span style={{ color: "red" }}>*</span></label>
                                                <div className="input-group" data-colorpicker-id={2}>
                                                    <input type="text" placeholder="Emergency On Reason" className="form-control" id="manualcode_2"  
                                                    {...register("emergency_on_reason",  {onChange: handleValue, required: { value: false, message: "Emergency On Reason is required" } })} />
                                                    <div className="input-group-append" >
                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                        /></span>
                                                    </div>
                                                </div>
                                                <span className='text-red w-100'>{errors?.emergency_on_reason?.message}</span>

                                            </div>
                                            <div className="col-sm-4 mt-2">
                                                <label htmlFor="manualcode_Disc_1">Emergency Off Date <span style={{ color: "red" }}>*</span></label>
                                                <div className="input-group" >
                                                    {/* <input type="date" placeholder="Manualcode Discription-1" className="form-control" id="start_date"  {...register("start_date", { onChange: handleValue })} /> */}
                                                    {/* <DatePicker 
                                                        className="form-control"
                                                        format={dateFormatList}
                                                        value={checkEmergencyEndDate}
                                                        disabledDate={disabledDate}
                                                       

                                                        {...register("emergency_close_date", { required: { value: false, message: "Field is required" } })}
                                                        onChange={handleEmergencyOffDate}


                                                        /> */}




<Controller
            control={control}
            name="emergency_close_date"
            rules={{ required: {
              value:false,
              message:"Please Choose a date"
            } }}
            render={({ field: { onChange, onBlur, value, } }) => (
                <>
              <DatePickerReact
            className="form-control"

                onChange={onChange} // send value to hook form
                // onBlur={onBlur} // notify when input is touched/blur
                selected={value}
                dateFormat="dd/MM/yyyy"
                minDate={new Date()}
                showIcon
                // maxDate={new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0)}
              />
{/* <div className="input-group-append" >
        <span className="input-group-text">
          <FaCalendarAlt />
        </span>
        </div> */}
        </>
            )}
          />
                                                    
                                                    
                                                  
                                                </div>
                                                <span className='text-red w-100'>{errors?.emergency_close_date?.message}</span>

                                            </div>
                                            <div className="col-sm-4 mt-2">
                                                <label htmlFor="manualcode_2">Emergency Off Reason <span style={{ color: "red" }}>*</span></label>
                                                <div className="input-group" data-colorpicker-id={2}>
                                                    <input type="text" placeholder="Emergency Off Reason" className="form-control"   
                                                    {...register("emergency_off_reason", {onChange: handleValue, required: { value: false, message: "Emergency Off Reason is required" } } )} />
                                                    <div className="input-group-append" >
                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                        /></span>
                                                    </div>
                                                </div>
                                                <span className='text-red w-100'>{errors?.emergency_off_reason?.message}</span>

                                            </div>
                                            <div className="col-sm-4 mt-2">
                                                <label htmlFor="manualcode_2">Emergency On-Off Person Name <span style={{ color: "red" }}>*</span></label>
                                                <div className="input-group" data-colorpicker-id={2}>
                                                    <input type="text" placeholder="Emergency On-Off Person Name" className="form-control"   
                                                    {...register("emergency_on_off_person_name", {onChange: handleValue, required: { value: false, message: "Emergency On-Off Person Name is required" } })} />
                                                    <div className="input-group-append" >
                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                        /></span>
                                                    </div>
                                                </div>
                                                <span className='text-red w-100'>{errors?.emergency_on_off_person_name?.message}</span>

                                            </div>
                                            </>}
                                           
                                            <div className="col-sm-4 mt-2">
                                                <label htmlFor="brand_type">On-Off<span style={{ color: "red" }}>*</span></label>
                                                <select className="form-select" 
                                                {...register("point_on_off", { required: { value: true, message: "Field is required" } })}>
                                                    <option value={""} selected>Select Any</option>
                                                    <option value={"on"}>On</option>
                                                    <option value={"off"}>Off</option>
                                                </select>
                                                <span className='text-red w-100'>{errors?.point_on_off?.message}</span>
                                            </div>


                                            <div className="col-sm-4 mt-2">
                                                <label htmlFor="remarks">Suggetion Person <span style={{ color: "red" }}>*</span></label>
                                                <div className="input-group" data-colorpicker-id={2}>
                                                    <input type="text" placeholder="Suggetion Person" className="form-control" id="remarks"  
                                                    {...register("suggested_by", {onChange: handleValue, required: {value: true, message: "Suggetion Person is required" } })} />
                                                    <div className="input-group-append" >
                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                        /></span>
                                                    </div>
                                                </div>
                                                <span className='text-red w-100'>{errors?.suggested_by?.message}</span>

                                            </div>
                                            <div className="col-sm-4 mt-2">
                                                <label htmlFor="remarks">Status<span style={{ color: "red" }}>*</span></label>
                                                <div className="input-group" data-colorpicker-id={2}>
                                                <select className="form-select" {...register("status",
                                                     {
                                                        required: { value: true, message: "Status is required" }, maxLength: 30,

                                                    })}>
                                                    <option value=""  selected>Select Any </option>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"inactive"}>Inactive</option>
                                                    </select>   
                                                </div>
                                                <span className='text-red w-100'>{errors?.status?.message}</span>

                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                               

                                                
                                            <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                            <button 
                                                type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3" 
                                                style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                onClick={handleSubmit(onSubmit)}
                                                // disabled={!isDirty}

                                                
                                                >Submit</button>

                                                <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listCheckList")}>Cancel</button>

                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
            <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
        centered    >
        <Modal.Header closeButton>
          <Modal.Title>Select a Date</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
            
           
            
          

<div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-12 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Choose Date</label>
                                                    <input type="date" className="form-control" id="exampleInputBrand" 
                                                    
                                                    {...register("expiration_date",{onChange:handleExpirationDate})} 
                                                    />
                                                </div>
                                                </div>
           </div>
            
          
            
        </Modal.Body>
        <Modal.Footer>
        

          
                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>


                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>

        </Modal.Footer>
      </Modal>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
yesupdatemodal={yesupdatemodal}
cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />
        </div>
    )
}

export default Edit_CheckList