import React, { useEffect, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../components/Header/header.css";
import { useNavigate, useParams } from "react-router-dom";
import { useBrandEdit, useUpdateBrand } from "../../hooks/brand";
import { Alert } from "../../components/Model/Model";
import { RiPencilLine } from "react-icons/ri";
import { useFetchSingle, useUpdateSingle } from "../../hooks/Api";
import InputField from "../../components/Form Component/inputfield";
import Error from "../../components/Form Component/error";
import { useForm, Controller } from "react-hook-form";

const Edit_Document = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [File, setFile]: any = useState()

    const [logo, setlogo] = useState(null)
    const [logoPdf, setlogoPdf] = useState(null)
    // const { data } = useBrandEdit(id)
    const apiname = process.env.REACT_APP_DOCUMENT_API?.toString()

    const {data} = useFetchSingle(id,apiname)

    const onLogoChange = (e: any) => {

        setlogo(e.target.files[0].name)
    }

    const onLogoPDFChange = (e: any) => {
        setlogoPdf(e.target.files[0].name)
    }

    
    const onFileChange = (e:any) => {
        setFile(e.target.files[0].name)
    }

    const { mutate, isSuccess, isError,error,data: brandUpdated_response }: any = useUpdateSingle()

    const { register, handleSubmit, setError, formState: { errors }, reset, setValue,control }:any = useForm();

    useEffect(() => {
        if (data) {
            setValue("title", data.title)
            setValue("type", data.type)
            setValue("status", data.status)
            setValue("doc", data.doc)
            setFile(data.doc)

        }
    }, [data]);


    // const onSubmit = (branddetail: any) => {
    //     const formData = new FormData();
    //     if (branddetail.logo.length == 1) {
    //         delete branddetail.logo_pdf
            
    //     } else if (branddetail.logo_pdf.length == 1) {
    //         delete branddetail.logo
    //     }
    //     else {
    //         delete branddetail.logo
    //         delete branddetail.logo_pdf
    //     }

    //     for (const [key, value] of Object.entries(branddetail)) {
    //         const formvalue: any = value
    //         if (typeof value !== "object") {
    //             formData.append(key, formvalue)
    //         }
    //         else {
    //             formData.append(key, formvalue[0])
    //         }
    //     }
    //     mutate(formData);
    // }

    const onSubmit = (documentDetail: any) => {


        if (documentDetail.doc?.length != 1) {
            delete documentDetail.doc
        } 
        else{
            delete documentDetail.doc
        }

        const formData = new FormData();
      

        for (const [key, value] of Object.entries(documentDetail)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else if (key === "doc"){
                if (typeof value === 'object' && value !== null && formvalue.length > 0) {
                    formData.append(key, formvalue[0])
                }
                else {
                    formData.append(key, "")
                }
            }
        }
        let data = {
            id:id,
            apiname:apiname,
            data:formData
        }
        mutate(data);
        
    }





    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listDocument', { state: { showDocumentEditToast: true }})
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [brandUpdated_response, error,isSuccess])



    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);
    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit Document</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/listColor" className="text-decoration-none text-black">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Masters</a></li>
                                    <li className="breadcrumb-item"><a href="/brandlist" className="text-decoration-none text-black">Document</a></li>
                                    <li className="breadcrumb-item active">Edit Document</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Edit Brand</span></h5>
                            </div> */}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>
                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message.name} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                            <div className="col-sm-3 mt-2">
                                                    <Controller
                                                        name="title"
                                                        control={control}
                                                        rules={{ required: {value:true, message:"Document Title is required" } }}
                                                        defaultValue=""
                                                        render={({ field }) => (
                                                            <InputField
                                                                label={"Title"}
                                                                placeholder="Enter Document Title"
                                                                onChange={(e: any) => field.onChange(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
                                                                value={field.value} />
                                                        )}
                                                    />
                                                    {errors.title && <Error>{errors?.title?.message}</Error>}
                                                </div>
                                                
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Document Type</label>
                                                    <select className="form-select" {...register("type",
                                                     {
                                                        required: { value: true, message: "Type of File is required" },

                                                    })}>
                                                        <option value="" disabled selected>Select Any</option>
                                                        <option value="pdf">PDF</option>
                                                        <option value="image">Image</option>
                                                    </select>
                                                    {errors.type && <Error>{errors?.type?.message}</Error>}
                                                </div>

                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">File</label>
                                                    <label style={{cursor:"pointer"}}>
                                                    <img src={pdfLogo} alt='' className="uploaded-image" />
                                                    <input data-required="image" type="file"  id="file_upload" accept="application/pdf"  className="image-input" data-traget-resolution="image_resolution" {...register("doc",{onChange:onFileChange, required: { value: false, message: "File is required" },})} hidden />
                                                    </label>
                                                    <p>{File?.split("/").pop()}</p>
                                                    {errors.doc && <Error>{errors?.doc?.message}</Error>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                {/* <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button type="button" className="btn border rounded bg-white px-4 mt-2 mx-4" >Cancel</button>
                                                    <button type="button" className="btn border rounded text-white px-4 mt-2 " onClick={handleSubmit(onSubmit)}>Submit</button>

                                                </div> */}

                                                
                                            <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                <a type="button" className="btn border rounded text-white px-4 mt-2 mx-3"   onClick={handleSubmit(onSubmit)}>Submit</a>


                                                <button type="button" className="btn border-1 rounded  px-4 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listDocument")}>Cancel</button>

                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
            {/* <Footer /> */}
        </div>
    )
}

export default Edit_Document