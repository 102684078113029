import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Columns_CheckList_Report, titleofheaders } from "./Columns_CheckList_Report"
import Table from "../../../components/Dynamic_Table/Table/Table";
import Select from 'react-select'
import { useFetchDepartmentDropDown, useFetchResponsiblePerson } from "../../../hooks/DropDown_Api";
import { DatePicker } from "antd";
import { useForm } from "react-hook-form";
import { useCreate } from "../../../hooks/Api";
import axios from "axios";
import Axiosinstance from "../../../hooks/Interceptor";

const List_CheckList_Report = () => {
  const Breadcrumb_Primary_Title = "Home";
  const Breadcrumb_Primary_Link = "Color";

  const Breadcrumb_Parent_Title = "Master";

  const Breadcrumb_Title_Name = "Checklist";

  const apiname = process.env.REACT_APP_CHECKLIST_API?.toString();



  const [forDepartmentValue, setForDepartmentValue]: any = useState(null)

  const [departmentLabel, setDepartmentLabel] = useState('')

  let apiNameForSearchPerson = `${process.env.REACT_APP_LEOUSER_API}/search_filter/`

  const { mutate: mutateDepartment, data: dataDepartment, isError: isErrorDepartment, isLoading: isLoadingDepartment, isSuccess: isSuccessDepartment, error: errorDepartment }: any = useCreate(apiNameForSearchPerson)

  const [optionsDepartment, setOptionsDepartment]: any = useState([])
  const { data: departmentData, refetch: colorRefetch } = useFetchDepartmentDropDown()


  const [apiData, setApiData] = useState([])

  useEffect(() => {
    setOptionsDepartment(departmentData)
  }, [departmentData])

  // const handleChange = (e: any) => {

  //                 setDepartmentLabel(e?.label)
  //               setValue('department',e?.value)
  //              setForDepartmentValue(e)
  //              setForResponsiblePersonValue(null)


  //    }



  const handleChange = useCallback(async (e: any) => {
    console.log(e);
    setValue('department', e?.value)
    setForDepartmentValue(e)
    setForResponsiblePersonValue(null)
    setDepartmentLabel(e?.label)

    try {
      const response = await Axiosinstance.post(apiNameForSearchPerson, {
        department: e?.value
      });
      console.log('Response:', response.data?.results);
      setApiData(response.data?.results)
    } catch (error) {
      console.error('Error:', error);
    }


  }, [departmentLabel]);



  useEffect(() => {
    if (apiData) {
      let temp = apiData?.map((e: any) => {
        return {
          value: e?.id,
          label: `${e?.username}`
        }
      })
      setOptionsResponsiblePerson(temp)
    }
  }, [apiData])

  console.log('dataDepartment', dataDepartment)


  const [optionsResponsiblePerson, setOptionsResponsiblePerson]: any = useState([])
  const [forResponsiblePersonValue, setForResponsiblePersonValue]: any = useState(null)


  //  const { data: personData, refetch: personRefetch } = useFetchResponsiblePerson(departmentLabel)

  //  useEffect(() => {
  //      setOptionsResponsiblePerson(personData);
  //  }, [personData])


  const handleResponsiblePerson = (e: any) => {

    setValue('responsible_person', e?.value)
    setForResponsiblePersonValue(e)
  }

  // const [dateValue,setDateValue] = useState(null)

  const onChangeDateAndMonth = (date: any, dateString: any) => {
    setValue('date', dateString)
    console.log('dateString', dateString)
    // setDateValue(dateString)
  }

  const filter = {
    // check_list_code:'',
    // name:'',
    // choice_yes_no:'',
    // department_name:'',
    // name_and_surname:'',
    // point_open_time:'',
    // point_close_time:'',
    // total_min:'',
    // point_start_date:'',
    // point_end_date:'',
    // total_running_days:'',
    // pending_running_days:'',
    // emergency_on_date:'',
    // emergency_on_reason:'',
    // emergency_close_date:'',
    // emergency_off_reason:'',
    // emergency_on_off_person_name:'',
    // point_on_off:'',
    // suggested_by:'',



    // created_by:'',
    // updated_by:'',

    // status: ''

  };

  const [checkListReportData, setCheckListReportData] = useState([])

  const [renderTable, setRenderTable] = useState(0)


  const apinameForGetData = process.env.REACT_APP_GENERATE_CHECKLIST_REPORT_API?.toString()
  const { mutate, data, isError, isLoading, isSuccess, error }: any = useCreate(apinameForGetData)

  const { register, handleSubmit, setError, formState: { errors }, reset, setValue, getValues }: any = useForm();
  const onSubmit = (data: any) => {




    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {

      const formvalue: any = value

      if (typeof value !== "object") {
        formData.append(key, formvalue)
      }



    }
    mutate(formData);
  }


  useEffect(() => {
    if (isSuccess) {
      setCheckListReportData(data?.data)
      setRenderTable(renderTable + 1)
      console.log('data', data)
    }
  }, [data, isSuccess])

  console.log('checkListReportData', checkListReportData)





  const addButtonName = "Checklist";
  const addButtonPageName = "Checklist"
  const permissionClass = {
    front: "checklist",
    back: "checklist"
  }
  const stickyStart = 2;
  const stickyEnd = 3;

  return (
    <>
      <div className="content-wrapper">
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{Breadcrumb_Title_Name} List</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to={`/list${Breadcrumb_Primary_Link}`}
                      className="text-decoration-none text-black"
                    >
                      {Breadcrumb_Primary_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      {Breadcrumb_Parent_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {Breadcrumb_Title_Name} List
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">



          <div className="container-fluid">
            <div className="card card-default">


              <div className="d-flex">

                <div className="col-sm-3 mt-2" >
                  <label htmlFor="name_and_grade">Department <span style={{ color: 'red' }}>*</span></label>
                  <div className="d-flex row" >
                    <Select
                      {...register("department", { required: { value: true, message: "Department is required" } })}

                      placeholder={'Department'}
                      onChange={handleChange}
                      value={forDepartmentValue}
                      options={optionsDepartment}
                    />

                  </div>
                  <span className='text-red w-100'>{errors?.department?.message}</span>

                </div>

                <div className="col-sm-3 mt-2" >
                  <label htmlFor="sub_name">Responsible Person<span style={{ color: 'red' }}>*</span></label>
                  <div className="d-flex row" >
                    <Select
                      {...register("responsible_person", { required: { value: true, message: "Responsible Person is required" } })}
                      placeholder={'Select'}
                      isDisabled={!forDepartmentValue}
                      value={forResponsiblePersonValue}
                      options={optionsResponsiblePerson}
                      onChange={handleResponsiblePerson}
                    />

                  </div>
                  <span className='text-red w-100'>{errors?.responsible_person?.message}</span>
                </div>


                <div className="col-sm-3 mt-2" >
                  <label htmlFor="sub_name">Select Date & Month<span style={{ color: 'red' }}>*</span></label>
                  <div className="d-flex row" >
                    <DatePicker disabled={!forDepartmentValue || !forResponsiblePersonValue}
                      {...register("date", { required: { value: true, message: "Select Date & Month is required" } })}

                      className="form-control" onChange={onChangeDateAndMonth} picker="month" />

                  </div>
                  <span className='text-red w-100'>{errors?.date?.message}</span>

                </div>


                <div className="col-sm-3 mt-2" >

                  <div className="d-flex row" style={{ justifyContent: 'center', paddingTop: '20px' }}>
                    <button
                      type="button" className="border-1 rounded text-white px-1 py-1 mt-2 mx-1 "
                      style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white", width: '50%' }}
                      onClick={handleSubmit(onSubmit)} >Get Data</button>
                  </div>
                </div>

              </div>

              <div className="container p-0" style={{ maxWidth: "100%" }}>
                {/* <BasicTable/> */}
                <Table
                  key={renderTable}
                  isAddButton={false}
                  // apiname={apiname}
                  filterFields={filter}
                  addButtonName={addButtonName}
                  stickyStart={stickyStart}
                  stickyEnd={stickyEnd}
                  TableColumns={Columns_CheckList_Report}
                  addButtonPageName={addButtonPageName}
                  titleofheaders={titleofheaders}
                  permissionClass={permissionClass}
                  checkListReportData={checkListReportData}
                />

              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default List_CheckList_Report;
