
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../../components/Cell/Status";
import Actions from "../../../components/Cell/Actions";


interface Data {
  selection: any
 
  type: any,
  employee_code: any,
  country: any,
  number_type: any,
  country_code: any,
  device_type: any,
  number: any,
  email_type: any,
  email_address: any,

  created_at:any
  created_by:any
  updated_by:any
  updated_at:any

  status: any;
  actions: any;
}


export const titleofheaders=[
  {
    id: `srno`,
    Header: `Sr No`,
  },
  {
    id: `type`,
    Header: `Type`,
   
  },
  {
    id: `employee_code`,
    Header: `Employee Code`,
   
  },
  {
    id: `country`,
    Header: `Country`,
  
  },
  {
    id: `number_type`,
    Header: `Number Type`,
    
  },
  {
    id: `country_code`,
    Header: `Country Code`,
   
  },
  {
    id: `device_type`,
    Header: `Device Type`,
   
  },
  {
    id: `number`,
    Header: `Contact Number`,
   
  },
  {
    id: `email_type`,
    Header: `Email Type`,
  
  },
  {
    id: `email_address`,
    Header: `Email Address`,
   
    
  },
 
  {
    id: 'status',
    Header: 'Status',
  },
  {
    id: 'created_at',
    Header: `Created At`,
  },


  {
    id: 'created_by',
    Header: `Created By`,

 
  },
  {
    id: 'updated_at',
    Header: `Updated At`,

  },
  {
    id: 'updated_by',
    Header: `Updated By`,

  },
]


export const Columns_Contact_Format: any[] = [



  
  {
    id: `srno`,
    Header: `Sr.No`,
    sticky:"left",
  },
 
  
  {
    id: `type`,
    Header: () => <div style={{display:"inline"}}>Type</div>,
    accessor: `type`,
    sticky:"left",
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.type?.name}
        </div>
      )
}
  },
  {
    id: `employee_code`,
    Header: () => <div style={{display:"inline"}}>Employee Code<br/>Surname,Name & City</div>,
    accessor: `employee_code`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.employee_code?.name}
        </div>
      )
}
  },
  {
    id: `country`,
    Header: () => <div style={{display:"inline"}}>Country</div>,
    accessor: `country`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.country?.name}
        </div>
      )
}
  },
  {
    id: `number_type`,
    Header: () => <div style={{display:"inline"}}>Number<br/>Type</div>,
    accessor: `number_type`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.number_type}
        </div>
      )
}
  },
  {
    id: `country_code`,
    Header: () => <div style={{display:"inline"}}>Country<br/>Code</div>,
    accessor: `country_code`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.country_code}
        </div>
      )
}
  },
  {
    id: `device_type`,
    Header: () => <div style={{display:"inline"}}>Device<br/>Type</div>,
    accessor: `device_type`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.device_type}
        </div>
      )
}
  },
  {
    id: `number`,
    Header: () => <div style={{display:"inline"}}>Mobile<br/>Number</div>,
    accessor: `number`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.number}
        </div>
      )
}
  },
  {
    id: `email_type`,
    Header: () => <div style={{display:"inline"}}>Email<br/>Type</div>,
    accessor: `email_type`,
    Cell: ({ row }: any) => {
      return(
        <div>
          {row.original.email_type}
        </div>
      )
}
  },
  {
    id: `email_address`,
    Header: () => <div style={{display:"inline"}}>Email<br/>Address</div>,
    accessor: `email_address`,
    
  },
 


  {
    id: 'status',
    Header: 'Status',
    accessor:'status',

 
  },

  
  {
    id: 'created_at',
    Header: () => <div style={{display:"inline"}}>Created<br/>At</div>,
    accessor:'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{display:"inline"}}>Created<br/>By</div>,
    accessor:'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username

 
  },
  {
    id: 'updated_at',
    Header: () => <div style={{display:"inline"}}>Updated<br/>At</div>,
    accessor:'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{display:"inline"}}>Updated<br/>By</div>,
    accessor:'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },

  {
    id: 'Actions',
    Header: 'Actions',

  
  },
  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_Contact_Format, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows} = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CONTACT_DETAIL_API}/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



