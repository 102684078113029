import React, { useCallback, useEffect, useState } from "react";
import Select from 'react-select'
import { Link } from "react-router-dom";
import Table from "../../../../components/Dynamic_Table/Table/Table";
import { Columns_Maintenance_Status, titleofheaders } from "./Columns_Maintenance_Status";
import { useFetchDepartmentDropDown, useFetchEmployeeDropDown, useFetchMachineLocationDropDown, useFetchMaintenanceDropDown } from "../../../../hooks/DropDown_Api";
import { useForm } from "react-hook-form";
import { useCreate } from "../../../../hooks/Api";
import Accordion from 'react-bootstrap/Accordion';
import { DatePicker } from 'antd';
import moment from "moment";
import IndicatorsContainer from '../../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../../components/Select Dropdown/Creatabel'
import Restricted from "../../../../Roles/Restricted";

const customIndicator = {
  IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
  IndicatorsContainer: IndicatorsContainerCreate
}

const List_Maintenance_Status = () => {
  const Breadcrumb_Primary_Title = "Home";
  const Breadcrumb_Primary_Link = "Color";

  const Breadcrumb_Parent_Title = "Maintenance";

  const Breadcrumb_Title_Name = "Status Report";

  const apiname = process.env.REACT_APP_MAINTENANCE_API?.toString();

  const [forDepartmentValue, setForDepartmentValue]: any = useState('')
  const [Department, setDepartment]: any = useState('')
  const [optionsDepartment, setOptionsDepartment]: any = useState([])
  const { data: departmentData, refetch: colorRefetch } = useFetchDepartmentDropDown()

  useEffect(() => {
    setOptionsDepartment(departmentData)
  }, [departmentData])

  const handleChange = (e: any) => {
    setValue('department', e?.value)
    setForDepartmentValue(e?.value)
    setDepartment(e)
  }

  const [maintenanceValue, setmaintenanceValue]: any = useState("")
  const [maintenance, setmaintenance]: any = useState('')
  const [optionsmaintenance, setOptionsmaintenance]: any = useState([])
  const { data: maintenanceData, refetch: maintenanceRefetch } = useFetchMaintenanceDropDown()

  useEffect(() => {
    setOptionsmaintenance(maintenanceData)
  }, [maintenanceData])

  const handleChangeMaintenance = (e: any) => {
    setValue('maintenance', e?.value)
    setmaintenanceValue(e?.value)
    setmaintenance(e)
  }


  const [EmployeeOptions, setEmployeeOptions]: any = useState([])
  const [ResponsiblePersonOptions, setResponsiblePersonOptions]: any = useState([])
  const [EmployeeIssueOptions, setEmployeeIssueOptions]: any = useState([])
  const [ForIssueValue, setForIssueValue]: any = useState('')
  const [ForIssue, setForIssue]: any = useState('')
  const [ForReferenceValue, setForReferenceValue]: any = useState('')
  const [ForReference, setForReference]: any = useState('')
  const [ForResponsiblePersonValue, setForResponsiblePersonValue]: any = useState('')
  const [ResponsiblePerson, setResponsiblePerson]: any = useState('')

  const { data: employeeData, refetch: EmployeeRefetch } = useFetchEmployeeDropDown()


  useEffect(() => {
    setEmployeeOptions(employeeData)
  }, [employeeData])
  useEffect(() => {
    setEmployeeIssueOptions(employeeData)
  }, [employeeData])
  useEffect(() => {
    setResponsiblePersonOptions(employeeData)
  }, [employeeData])


  const handleChangeIssueReg = (e: any) => {
    setForIssueValue(e?.value)
    setForIssue(e)
    setValue('issue_registered_by_person_code_and_name', e.value)
  }
  const handleChangeReference = (e: any) => {
    setForReferenceValue(e?.value)
    setForReference(e)
    setValue('refrence_person_code_and_name', e.value)
  }

  const handleChangeResponsiblePerson = (e: any) => {
    setForResponsiblePersonValue(e?.value)
    setResponsiblePerson(e)
    setValue('responsible_person', e.value)
  }

  const [valueLocation, setValueLocation]: any = useState();
  const [Location, setLocation]: any = useState();
  const [optionsLocation, setOptionsLocation]: any = useState([]);
  const { data: LocationData, refetch: LocationRefetch } = useFetchMachineLocationDropDown()

  useEffect(() => {
    setOptionsLocation(LocationData)
  }, [LocationData])


  const handleChangeLocation = (e: any) => {


    setValue('maintenance_location', e?.value)
    // console.log(e.target.value)




    // setApiName(apinameForLocation)
    // setDeleteApiName(apinameForLocation)
    // setEditId(e?.value)
    // setEditData(LocationData)
    // setRenderKey(prevKey => prevKey + 1);

    setValueLocation(e?.value)
    setLocation(e)

    setValue("maintenance_location", `${e?.value}`)
  }

  const [MaintenanceStatus, SetMaintenanceStatus]: any = useState('')

  const onChangeMaintenanceStatus = (e: any) => {
    // console.log(e?.target?.value);

    SetMaintenanceStatus(e.target?.value)
    setValue("maintenance_status", e.target?.value)
  }

  const [maintenanceReportData, setmaintenanceReportData]: any = useState([])

  const [renderTable, setRenderTable] = useState(0)


  const apinameGetData = `${process.env.REACT_APP_MAINTENANCE_API}/search_filter`?.toString()
  const { mutate, data, isError, isLoading, isSuccess, error }: any = useCreate(apinameGetData)

  const { register, handleSubmit, setError, formState: { errors }, reset, setValue, getValues }: any = useForm();
  const onSubmit = (data: any) => {

    let tempData = {
      department_id: forDepartmentValue,
      issue_registered_by_person_code_and_name_id: ForIssueValue,
      refrence_person_code_and_name_id: ForReferenceValue,
      maintenance_location_id: valueLocation,
      maintenance_status: MaintenanceStatus,
      responsible_person_id: ForResponsiblePersonValue,
      start_date: startDate,
      end_date: endDate,

    }

    mutate(tempData);
  }

const [resetData,setResetData] = useState(false)
  const handleReset = () => {
    reset()
    setResponsiblePerson(null)
    setLocation(null)
    setForReference(null)
    setForIssue(null)
    setDepartment(null)
    SetMaintenanceStatus(null)

    setForDepartmentValue(null)
    setmaintenanceValue(null)
    setForIssueValue(null)
    setForReferenceValue(null)
    setForResponsiblePersonValue(null)
    setValueLocation(null)
    setStartDate(null)
    setForPointStartDateValue(null)
    setEndDate(null)
    setForPointEndDateValue(null)

    setmaintenanceReportData([])
    setValue("department", "")
    setValue("issue_registered_by_person_code_and_name", "")
    setValue("refrence_person_code_and_name", "")
    setValue("maintenance_location", "")
    setValue("responsible_person", "")
    setValue("maintenance_status", "")



    setResetData(false)    
    setRenderTable(renderTable + 1)
  }

  useEffect(() => {
    if (isSuccess) {
      setmaintenanceReportData(data?.results)
      setResetData(true)    
      setRenderTable(renderTable + 1)
    }
  }, [data, isSuccess])


  // const { RangePicker } = DatePicker;
  const dateFormatList = ['DD-MM-YYYY'];
  const [forDisableDate, setForDisableDate]: any = useState()
  const [startDate, setStartDate]: any = useState()
  const [forPointStartDateValue, setForPointStartDateValue]: any = useState()

  const [endDate, setEndDate]: any = useState()
  const [forPointEndDateValue, setForPointEndDateValue]: any = useState()


  const handleStartDate = (e: any) => {
    setForDisableDate(e)
    setStartDate(`${e?.$D}-${e?.$M + 1}-${e?.$y}`)
    setValue("start_date", `${e?.$D}-${e?.$M + 1}-${e?.$y}`)

    setForPointStartDateValue(e)

  }

  const handleEndDate = (e: any) => {
    setEndDate(`${e?.$D}-${e?.$M + 1}-${e?.$y}`)
    setValue("end_date", `${e?.$D}-${e?.$M + 1}-${e?.$y}`)
    setForPointEndDateValue(e)

  }


  // useEffect(() => {


  //   if (startDate && endDate) {

  //       let todayDate = new Date().toISOString().split("T")[0]


  //       // var DateOne = moment([startDate.$y, startDate.$M + 1, startDate.$D]);

  //       // var DateTwo = moment([endDate.$y, endDate.$M + 1, endDate.$D]);

  //       // var currentDate = moment([todayDate.split("-")[0], todayDate.split("-")[1], todayDate.split("-")[2]]);


  //       setForPointEndDateValue( `${startDate.$y}-${startDate.$M + 1}-${startDate.$D}`)
  //       setValue("point_end_date", `${endDate.$y}-${endDate.$M + 1}-${endDate.$D}`)

  //   }
  // }, [startDate, endDate])


  const filter = {
    // selection: "",
    // department_name: "",
    // issue_registered_by_person_code_and_name: "",
    // maintenance_location: "",
    // refrence_person_code_and_name: "",
    // responsible_person: "",
    // handover_work_date: "",
    // repairing_date: "",
    // code: "",

    // created_by: "",
    // updated_by: "",
    // status: ''
  };

  const addButtonName = "Status Report";

  const stickyStart = 1;
  const stickyEnd = 2;

  const addButtonPageName = "ComplaintMaintenance"
  const permissionClass = {
    front: "maintenance",
    back: "maintenance"
  }

  const [onReset, setOnReset] = useState(false)

  // console.log(Department,
  //   ForIssue,
  //   ForReference,
  //   Location,
  //   ResponsiblePerson,
  //   MaintenanceStatus)
  return (
    <>
      <div className="content-wrapper">
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{Breadcrumb_Title_Name} List</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to={`/list${Breadcrumb_Primary_Link}`}
                      className="text-decoration-none text-black"
                    >
                      {Breadcrumb_Primary_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      {Breadcrumb_Parent_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {Breadcrumb_Title_Name} List
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
            <Restricted
          to={`${permissionClass?.front}.view_${permissionClass?.back}`}
          fallback={""}
        >
              <div className="">
                <Accordion defaultActiveKey={'0'} flush >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Search Filter</Accordion.Header>
                    <Accordion.Body>
                      <form>
                        <div className="row d-flex">
                          <div className="col-sm-2 mt-2" >
                            <label htmlFor="name_and_grade">Department </label>
                            <div className="d-flex row" >
                              <Select
                                {...register("department", { required: { value: false, message: "Department is required" } })}

                                // placeholder={'Department'}
                                onChange={handleChange}
                                value={Department}
                                options={optionsDepartment}
                                components={customIndicator}

                              />

                            </div>
                            <span className='text-red w-100'>{errors?.department?.message}</span>

                          </div>

                          <div className="col-sm-2 mt-2">
                            <label htmlFor="exampleInputEmail1">Issue Registered By Person Name </label>
                            <div className="d-flex row" >
                              <Select
                                {...register("issue_registered_by_person_code_and_name", { required: { value: false, message: "Issue Registered Person is required" } })}
                                // placeholder={'Issue Registered Person'}
                                onChange={handleChangeIssueReg}
                                components={customIndicator}
                                value={ForIssue}
                                options={EmployeeIssueOptions}

                              />

                            </div>
                            <span className='text-red w-100'>{errors?.issue_registered_by_person_code_and_name?.message}</span>
                          </div>

                          <div className="col-sm-2 mt-2">
                            <label htmlFor="exampleInputEmail1">Fault Reference Person Name </label>
                            <div className="d-flex row" >
                              <Select
                                {...register("refrence_person_code_and_name", { required: { value: false, message: "Fault Reference Person is required" } })}
                                // placeholder={'Fault Reference Person'}
                                onChange={handleChangeReference}
                                components={customIndicator}
                                value={ForReference}
                                options={EmployeeOptions}

                              />

                            </div>
                            <span className='text-red w-100'>{errors?.refrence_person_code_and_name?.message}</span>
                          </div>
                          <div className="col-sm-2 mt-2">
                            <label htmlFor="exampleInputEmail1">Maintenance Location</label>
                            <div className="d-flex row" >
                              <Select
                                {...register("maintenance_location", { required: { value: false, message: "Maintenance Location is required" } })}
                                // placeholder={'Fault Maintenance Location'}
                                onChange={handleChangeLocation}
                                components={customIndicator}
                                value={Location}
                                options={optionsLocation}

                              />

                            </div>
                            <span className='text-red w-100'>{errors?.maintenance_location?.message}</span>
                          </div>

                          <div className="col-sm-2 mt-2">
                            <label htmlFor="exampleInputEmail1">Responsible Person Code & Name </label>
                            <div className="d-flex row" >
                              <Select
                                {...register("responsible_person", { required: { value: false, message: "Responsible Person Code & Name is required" } })}
                                // placeholder={'Responsible Person Code & Name'}
                                onChange={handleChangeResponsiblePerson}
                                components={customIndicator}
                                // value={ForResponsiblePersonValue}
                                options={ResponsiblePersonOptions}
                                value={ResponsiblePerson}

                              />

                            </div>

                            <span className='text-red w-100'>{errors?.responsible_person?.message}</span>
                          </div>

                          <div className="col-sm-2 mt-2">
                            <label htmlFor="exampleInputEmail1">Maintenance Status </label>
                            <select className="form-select" {...register("maintenance_status",
                              {
                                onChange: onChangeMaintenanceStatus, required: { value: false, message: "Status is required" },
                              }
                            )} >
                              <option value="" selected >Select Any</option>
                              <option value={"Assigned"}>Assigned</option>
                              <option value={"InProgress"}>InProgress</option>
                              <option value={"Completed"}>Completed</option>
                              <option value={"Pending"}>Pending</option>
                            </select>
                            <span className='text-red w-100'>{errors?.status?.message}</span>
                          </div>

                          <div className="col-sm-2 mt-2">
                            <label htmlFor="exampleInputEmail1">Start Date </label>
                            {/* <div className="input-group" > */}
                            <DatePicker
                              {...register("start_date")}
                              className="form-control"
                              format={dateFormatList}
                              onChange={handleStartDate}
                              value={forPointStartDateValue}
                            />
                            {/* </div> */}
                            <span className='text-red w-100'>{errors?.start_date?.message}</span>
                          </div>

                          <div className="col-sm-2 mt-2">
                            <label htmlFor="manualcode_2">End Date</label>
                            {/* <div className="input-group" data-colorpicker-id={2}> */}

                            <DatePicker
                              {...register("end_date")}
                              className="form-control"
                              format={dateFormatList}
                              value={forPointEndDateValue}
                              onChange={handleEndDate}
                            />

                            {/* </div> */}
                            <span className='text-red w-100'>{errors?.end_date?.message}</span>

                          </div>


                          {/* <div className="col-sm-3 mt-2" >

                            <div className="d-flex row" style={{ justifyContent: 'center', paddingTop: '20px' }}>
                              <button
                                type="button" className="border-1 rounded text-white px-1 py-1 mt-2 mx-1 "
                                style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white", width: '30%' }}
                                onClick={handleSubmit(onSubmit)} >Get Data</button>
                            </div>
                          </div> */}
                        </div>
                        <div className="row d-flex  ">
                          <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                            <div className="row d-flex ">
                              <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                <button
                                  type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                  style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                  onClick={handleSubmit(onSubmit)} >Get Data</button>
                                <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              </Restricted>

              <div className="container p-0" style={{ maxWidth: "100%" }}>
               
                    <Table
                      key={renderTable}
                      apiname={apiname}
                      filterFields={filter}
                      addButtonName={addButtonName}
                      stickyStart={stickyStart}
                      stickyEnd={stickyEnd}
                      TableColumns={Columns_Maintenance_Status}
                      addButtonPageName={addButtonPageName}
                      titleofheaders={titleofheaders}
                      permissionClass={permissionClass}
                      isAddButton={false}
                      maintenanceReportData={maintenanceReportData}
                      resetData={resetData}

                    />
               

              </div>
            </div>
          </div>
        </section >
      </div >
    </>
  );
};

export default List_Maintenance_Status;
