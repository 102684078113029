import React, { useEffect } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import "../../components/Table/table.css"
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
// import { useCartonEdit, useUpdateCarton } from "../../hooks/Carton";
import { useState } from "react";
import Select from 'react-select'
import axios from 'axios'
import { useCreate, useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../hooks/Api";
import { Alert } from "../../components/Model/Model";
import Editable from "../../components/Editable_Table/Editable";
import Button from "react-bootstrap/esm/Button";
import { AiOutlinePlus, AiOutlineEye } from "react-icons/ai";
import Modal from 'react-bootstrap/Modal';
import Viewable from "../../components/Editable_Table/Viewable";
import { Deletesinglewithdependancy } from "../../components/modal";
import { RiPencilLine } from "react-icons/ri";
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'
import {
    useFetchMaterialDensityDropDown, useFetchBrandDropDown,
    useFetchColorDropDown, useFetchMaterialandHSNDropDown,
    useFetchPanniMaterialUseDropDown,
    useFetchPanniSealingTypeDropDown,
    useFetchPanniProductPrintingTypeDropDown,
    useFetchUomDropDown,
    useFetchPanniProductPakingTypeDropDown,
    useFetchPanniProductByDropDown,
    useFetchMaterialUseDropDown,
    useFetchMachineLocationDropDown,
    useFetchCartonMoistureDropDown
} from "../../hooks/DropDown_Api";
import CreatableSelect from "react-select/creatable";
import { UOM_Model } from "../../components/Dynamic_Form_Models/Uom_Model";
import { Upload, UploadFile } from "antd";
import { Color_Model } from "../../components/Dynamic_Form_Models/Color_Model";
import { Brand_Model } from "../../components/Dynamic_Form_Models/Brand_Model";
import { HSN_Model } from "../../components/Dynamic_Form_Models/HSN_Model";
import Actions, { Deleteforce, Deletesingle } from "../../components/Cell/Actions";
import { useDelete, useDeletemforce } from "../../hooks/delete";
import Custom_Actions_Buttons from "../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../hooks/deletemultiple";
import { SimpleInput_Edit } from "../../components/Dynamic_Form_Models/SimpleInput_Edit";




const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}
interface Option {
    readonly label: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    name: label,
    status: "active",
});


const Edit_Carton = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [openViewable, setOpenViewable] = useState(false)
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()
    const [expirationDate, setExpirationDate] = useState()
    const [openEditModel, setOpenEditModel] = useState(false)
    const [openHSN, setOpenHSN] = useState(false)

    const apiname = process.env.REACT_APP_CARTON_API?.toString()
    const { data } = useFetchSingle(id, apiname)
    const { mutate, isSuccess, isLoading, data: fatchCarton, error, isError }: any = useUpdateSingle()
    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()
    const { register, handleSubmit, setError, formState: { errors, isDirty }, reset, setValue, getValues }: any = useForm({
        mode: "onChange"
    });
    const [panniPrinting, setpanniPrinting] = useState("no")
    const [panniKan, setpanniKan] = useState("no")
    const [logo, setlogo] = useState(null)
    const [logoPdf, setlogoPdf] = useState(null)
    const [brandOpen, setBrandOpen] = useState(false)

    const [options, setOptions]: any = useState([])
    const [optionsPrinting, setOptionsPrinting]: any = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [loading, setLoading] = useState(false)
    const [searchPanniTerm, setSearchPanniTerm] = useState('')
    const [ColorTotal, setColorTotal] = useState("")
    const [selected, setSelected] = useState("")
    const [selectedHSN, setSelectedHSN] = useState("")

    const [optionsbrand, setOptionsBrand]: any = useState([])
    const [searchTermBrand, setSearchTermBrand] = useState('')
    const [loadingBrand, setLoadingBrand] = useState(false)
    const [colorNamePanni, setColorNamePanni]: any = useState({})
    const [optionsHSN, setOptionsHSN]: any = useState([])


    const [printingColorOpen, setPrintingColorOpen] = useState(false)


    const [searchHSN, setSearchHSN] = useState('')
    const [loadingHSN, setLoadingHSN] = useState(false)
    const [gstNumber, setGSTNumber]: any = useState("")
    // const [showGst, setShowGst]: any = useState("")
    const [ok, setOk] = useState('')
    const [panniName, setPanniName]: any = useState({})
    const [selPanni, setSelPanni] = useState("");
    const [optionsPanniName, setOptionsPanniName] = useState([])
    const [loadingPanniName, setLoadingPanniName] = useState(false)
    const [showPanni, setShowPanni] = useState('')
    const [selectedPanni, setSelectedPnni]: any = useState('');
    const [selectedColor, setSelectedColor]: any = useState({});
    // const [colorOfPanni, setColorOfPanni]: any = useState([])
    const [selColor, setSelColor] = useState("")
    const [selBrand, setSelBrand] = useState("")
    const [selHSN, setSelHSN] = useState("");
    const [selectedPrintingColor, setSelectedPrintingColor]: any = useState([]);
    const [selPrintingColor, setSelPrintingColor] = useState("");

    const onPrintingChange = (e: any) => {
        setpanniPrinting(e.target.value)
    }
    const onPanniChange = (e: any) => {
        setpanniKan(e.target.value)
    }
    const onLogoChange = (e: any) => {
        setlogo(e.target.files[0].name)
    }

    const onLogoPDFChange = (e: any) => {
        setlogoPdf(e.target.files[0].name)
    }

    // const loadOptionsPanniName = async () => {
    //     try {
    //         setLoadingHSN(true)
    //         const response = await axios.get(`${process.env.REACT_APP_BASE_URL}materialDensity/fetch_all/`, {
    //             headers: {
    //                 'Authorization': `Bearer ${localStorage.getItem('token')}`
    //             }
    //         })

    //         const data = response.data.results.map((option: any) => {
    //             return ({
    //                 label: `${option.material_type_name}::${option.material_density}::${option.grade_polymer}::${option.grade_sutra}`,
    //                 value: option.id,
    //             })
    //         })
    //         setOptionsPanniName(data)

    //     } catch (error) {
    //     } finally {
    //         setLoadingPanniName(false)
    //     }
    // }

    // useEffect(() => {
    //     loadOptionsPanniName()

    // }, [])

    // const handleInputChangePanniName = (e: any) => {
    //     setSearchHSN(e)
    // }
    // const getRelatedHsn = process.env.REACT_APP_GET_RELATED_HSN_API?.toString()


    // const { mutate: getrelated, data: relatedData } = useCreate(getRelatedHsn)
    // const handleChangePanniName = (e: any) => {
    //     setPanniName(e)
    //     setSelPanni(e)
    //     setValue("name_and_grade", e.value)
    //     getrelated({ "grade": e.value })
    // }


    const [openUOM, setOpenUOM] = useState(false)
    const [selUom, setSelUom] = useState('')
    const [selectedUom, setSelectedUom] = useState('')

    const [forUOMValue, setForUOMValue]: any = useState([])

    const { data: UomData, refetch: UomRefetch } = useFetchUomDropDown()
    const [optionsUom, setOptionsUom]: any = useState()
    useEffect(() => {
        setOptionsUom(UomData)
    }, [UomData])

    const handleChangeUom = (e: any) => {
        setValue("uom", `${e.value}`)
        setSelUom(e)
        // setForUOMValue({ label: e?.label, value: e?.value })

    }



    const { data: colorData, refetch: colorRefetch } = useFetchColorDropDown(searchTerm);

    useEffect(() => {
        setOptions(colorData);
        setOptionsPrinting(colorData)
    }, [colorData]);

    useEffect(() => {
        if (searchTerm.length >= 1) {
            colorRefetch();
        }
    }, []);

    const handleInputChange = (e: any) => {
        setSearchTerm(e)
    }
    const handlePanniInputChange = (e: any) => {
        setSearchPanniTerm(e)
    }
    const handleChange = (e: any) => {
        setValue("product_color_name", e.value)
        // setForColorValue({ label: e?.label, value: e?.value })
        setSelColor(e)
    }




    const handleChangePanni = (e: any) => {
        const panni_color_name = e.map((value: any) => {
            return value.value
        })
        setValue("product_printing_color_name", panni_color_name)
        setValue("product_printing_total_color", e.length)
        setSelPrintingColor(e)
        // setForColorsValue(e)


    }

    const { data: brandData, refetch: brandRefetch } =
        useFetchBrandDropDown(searchTerm);

    useEffect(() => {
        setOptionsBrand(brandData);
    }, [brandData]);

    useEffect(() => {
        if (searchTerm.length >= 1) {
            brandRefetch();
        }
    }, []);

    const handleInputChangeBrand = (e: any) => {
        setSearchTermBrand(e)
    }

    const handleChangeBrand = (e: any) => {
        setColorNamePanni(e)
        setValue("product_brand_type", `${e.type}`)
        setValue("product_brand", `${e.value}`)
        // setForBrandValue({ label: e?.label, value: e?.value, type: e?.type })

    }




    const apinameForSealingType = process.env.REACT_APP_PANNI_SEALING_TYPE_API?.toString()
    const { mutate: mutateSealingType, data: dataSealingType, isError: SealingTypeIsError, isLoading: SealingTypeLoading, isSuccess: SealingTypeSuccess, error: SealingTypeError }: any = useCreate(apinameForSealingType)
    const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueSealingType, setValueSealingType]: any = useState();
    const [selectedSealingType, setSelectedSealingType]: any = useState();
    const [optionsSealingType, setOptionsSealingType]: any = useState([]);
    const [valueSealingTypeCreatable, setValueSealingTypeCreatable] = useState<Option | null>();

    const { data: SealingTypeData, refetch: SealingTypeRefetch } = useFetchPanniSealingTypeDropDown()

    useEffect(() => {
        setOptionsSealingType(SealingTypeData)
    }, [SealingTypeData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            SealingTypeRefetch()
        }
    }, [])


    const handleChangeSealingType = (e: any) => {

        SealingTypeRefetch()

        setApiName(apinameForSealingType)
        setDeleteApiName(apinameForSealingType)
        setEditId(e?.value)
        setEditData(SealingTypeData)
        setRenderKey(prevKey => prevKey + 1);


        setValueSealingType(e)
        setValue("product_sealing_type", `${e?.value}`)
        setValueSealingTypeCreatable(null)
    }





    const handleCreateSealingType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateSealingType({ name: newOption.name, status: newOption.status })
            setOptionsSealingType((prev: any) => [...prev, newOption]);
            setValueSealingTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (SealingTypeSuccess && optionsSealingType) {
                await SealingTypeRefetch();
                setValue("product_sealing_type", SealingTypeData[0]?.value)
            }

        })();

        return () => {
        };
    }, [SealingTypeSuccess, SealingTypeData]);


    const apinameForProductPrintingType = process.env.REACT_APP_PANNI_PRINTING_TYPE_API?.toString()
    const { mutate: mutateProductPrintingType, data: dataProductPrintingType, isError: ProductPrintingTypeIsError, isLoading: ProductPrintingTypeLoading, isSuccess: ProductPrintingTypeSuccess, error: ProductPrintingTypeError }: any = useCreate(apinameForProductPrintingType)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueProductPrintingType, setValueProductPrintingType]: any = useState();
    const [selectedProductPrintingType, setSelectedProductPrintingType]: any = useState();

    const [optionsProductPrintingType, setOptionsProductPrintingType]: any = useState([]);
    const [valueProductPrintingTypeCreatable, setValueProductPrintingTypeCreatable] = useState<Option | null>();

    const { data: ProductPrintingTypeData, refetch: ProductPrintingTypeRefetch } = useFetchPanniProductPrintingTypeDropDown()

    useEffect(() => {
        setOptionsProductPrintingType(ProductPrintingTypeData)
    }, [ProductPrintingTypeData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            ProductPrintingTypeRefetch()
        }
    }, [])


    const handleChangeProductPrintingType = (e: any) => {

        ProductPrintingTypeRefetch()

        setApiName(apinameForProductPrintingType)
        setDeleteApiName(apinameForProductPrintingType)
        setEditId(e?.value)
        setEditData(ProductPrintingTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueProductPrintingType(e)
        setValue("product_printing_type", `${e?.value}`)
        setValueProductPrintingTypeCreatable(null)
    }





    const handleCreateProductPrintingType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateProductPrintingType({ name: newOption.name, status: newOption.status })
            setOptionsProductPrintingType((prev: any) => [...prev, newOption]);
            setValueProductPrintingTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ProductPrintingTypeSuccess && optionsProductPrintingType) {
                await ProductPrintingTypeRefetch();
                setValue("product_printing_type", ProductPrintingTypeData[0]?.value)
            }

        })();

        return () => {
        };
    }, [ProductPrintingTypeSuccess, ProductPrintingTypeData]);


    const apinameForProductPakingType = process.env.REACT_APP_PANNI_PACKING_TYPE_API?.toString()
    const { mutate: mutateProductPakingType, data: dataProductPakingType, isError: ProductPakingTypeIsError, isLoading: ProductPakingTypeLoading, isSuccess: ProductPakingTypeSuccess, error: ProductPakingTypeError }: any = useCreate(apinameForProductPakingType)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueProductPakingType, setValueProductPakingType]: any = useState();
    const [selectedProductPakingType, setSelectedProductPakingType]: any = useState();
    const [optionsProductPakingType, setOptionsProductPakingType]: any = useState([]);
    const [valueProductPakingTypeCreatable, setValueProductPakingTypeCreatable] = useState<Option | null>();

    const { data: ProductPakingTypeData, refetch: ProductPakingTypeRefetch } = useFetchPanniProductPakingTypeDropDown()

    useEffect(() => {
        setOptionsProductPakingType(ProductPakingTypeData)
    }, [ProductPakingTypeData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            ProductPakingTypeRefetch()
        }
    }, [])


    const handleChangeProductPakingType = (e: any) => {


        ProductPakingTypeRefetch()

        setApiName(apinameForProductPakingType)
        setDeleteApiName(apinameForProductPakingType)
        setEditId(e?.value)
        setEditData(ProductPakingTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueProductPakingType(e)
        setValue("product_packing_type", `${e?.value}`)
        setValueProductPakingTypeCreatable(null)
    }





    const handleCreateProductPakingType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateProductPakingType({ name: newOption.name, status: newOption.status })
            setOptionsProductPakingType((prev: any) => [...prev, newOption]);
            setValueProductPakingTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ProductPakingTypeSuccess && optionsProductPakingType) {
                await ProductPakingTypeRefetch();
                setValue("product_packing_type", ProductPakingTypeData[0]?.value)
            }
        })();

        return () => {
        };
    }, [ProductPakingTypeSuccess, ProductPakingTypeData]);


    const apinameForProductBy = process.env.REACT_APP_PANNI_PRODUCT_BY_API?.toString()
    const { mutate: mutateProductBy, data: dataProductBy, isError: ProductByIsError, isLoading: ProductByLoading, isSuccess: ProductBySuccess, error: ProductByError }: any = useCreate(apinameForProductBy)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueProductBy, setValueProductBy]: any = useState();
    const [selectedProductBy, setSelectedProductBy]: any = useState();
    const [optionsProductBy, setOptionsProductBy]: any = useState([]);
    const [valueProductByCreatable, setValueProductByCreatable] = useState<Option | null>();

    const { data: ProductByData, refetch: ProductByRefetch } = useFetchPanniProductByDropDown()

    useEffect(() => {
        setOptionsProductBy(ProductByData)
    }, [ProductByData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            ProductByRefetch()
        }
    }, [])


    const handleChangeProductBy = (e: any) => {


        ProductByRefetch()

        setApiName(apinameForProductBy)
        setDeleteApiName(apinameForProductBy)
        setEditId(e?.value)
        setEditData(ProductByData)
        setRenderKey(prevKey => prevKey + 1);


        setValueProductBy(e)
        setValue("product_by", `${e?.value}`)
        setValueProductByCreatable(null)
    }





    const handleCreateProductBy = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateProductBy({ name: newOption.name, status: newOption.status })
            setOptionsProductBy((prev: any) => [...prev, newOption]);
            setValueProductByCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ProductBySuccess && optionsProductBy) {
                await ProductByRefetch();
                setValue("product_by", ProductByData[0]?.value)
            }
        })();

        return () => {
        };
    }, [ProductBySuccess, ProductByData]);

    const apinameForProductType = process.env.REACT_APP_MATERIAL_USE_API?.toString()
    const { mutate: mutateProductType, data: dataProductType, isError: ProductTypeIsError, isLoading: ProductTypeLoading, isSuccess: ProductTypeSuccess, error: ProductTypeError }: any = useCreate(apinameForProductType)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueProductType, setValueProductType]: any = useState();
    const [selectedProductType, setSelectedProductType]: any = useState();
    const [optionsProductType, setOptionsProductType]: any = useState([]);
    const [valueProductTypeCreatable, setValueProductTypeCreatable] = useState<Option | null>();

    const { data: ProductTypeData, refetch: ProductTypeRefetch } = useFetchMaterialUseDropDown()

    useEffect(() => {
        setOptionsProductType(ProductTypeData)
    }, [ProductTypeData])





    const handleChangeProductType = (e: any) => {

        ProductTypeRefetch()

        setApiName(apinameForProductType)
        setDeleteApiName(apinameForProductType)
        setEditId(e?.value)
        setEditData(ProductTypeData)
        setRenderKey(prevKey => prevKey + 1);


        setValueProductType(e)
        setValue("product_type", `${e?.value}`)
        setValueProductTypeCreatable(null)
    }





    const handleCreateProductType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateProductType({ name: newOption.name, status: newOption.status })
            setOptionsProductType((prev: any) => [...prev, newOption]);
            setValueProductTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ProductTypeSuccess && optionsProductType) {
                await ProductTypeRefetch();
                setValue("product_type", ProductTypeData[0]?.value)
            }
        })();

        return () => {
        };
    }, [ProductTypeSuccess, ProductTypeData]);
    const apinameForLocation = process.env.REACT_APP_MACHINE_LOCATION_API?.toString()
    const { mutate: mutateLocation, data: dataLocation, isError: LocationIsError, isLoading: LocationLoading, isSuccess: LocationSuccess, error: LocationError }: any = useCreate(apinameForLocation)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueLocation, setValueLocation]: any = useState();
    const [selectedLocation, setSelectedLocation]: any = useState();
    const [optionsLocation, setOptionsLocation]: any = useState([]);
    const [valueLocationCreatable, setValueLocationCreatable] = useState<Option | null>();

    const { data: LocationData, refetch: LocationRefetch } = useFetchMachineLocationDropDown()

    useEffect(() => {
        setOptionsLocation(LocationData)
    }, [LocationData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            LocationRefetch()
        }
    }, [])


    const handleChangeLocation = (e: any) => {

        LocationRefetch()

        setApiName(apinameForLocation)
        setDeleteApiName(apinameForLocation)
        setEditId(e?.value)
        setEditData(LocationData)
        setRenderKey(prevKey => prevKey + 1);


        setValueLocation(e)
        setValue("product_location", `${e?.value}`)
        setValueLocationCreatable(null)
    }





    const handleCreateLocation = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateLocation({ name: newOption.name, status: newOption.status })
            setOptionsLocation((prev: any) => [...prev, newOption]);
            setValueLocationCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (LocationSuccess && optionsLocation) {
                await LocationRefetch();
                setValue("product_location", LocationData[0]?.value)
            }
        })();

        return () => {
        };
    }, [LocationSuccess, LocationData]);
    const apinameForMoisture = process.env.REACT_APP_CARTON_MOISTURE_API?.toString()
    const { mutate: mutateMoisture, data: dataMoisture, isError: MoistureIsError, isLoading: MoistureLoading, isSuccess: MoistureSuccess, error: MoistureError }: any = useCreate(apinameForMoisture)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueMoisture, setValueMoisture]: any = useState();
    const [selectedMoisture, setSelectedMoisture]: any = useState();
    const [optionsMoisture, setOptionsMoisture]: any = useState([]);
    const [valueMoistureCreatable, setValueMoistureCreatable] = useState<Option | null>();

    const { data: MoistureData, refetch: MoistureRefetch } = useFetchCartonMoistureDropDown()

    useEffect(() => {
        setOptionsMoisture(MoistureData)
    }, [MoistureData])





    const handleChangeMoisture = (e: any) => {

        MoistureRefetch()

        setApiName(apinameForMoisture)
        setDeleteApiName(apinameForMoisture)
        setEditId(e?.value)
        setEditData(MoistureData)
        setRenderKey(prevKey => prevKey + 1);


        setValueMoisture(e)
        setValue("carton_moisture", `${e?.value}`)
        setValueMoistureCreatable(null)
    }





    const handleCreateMoisture = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateMoisture({ name: newOption.name, status: newOption.status })
            setOptionsMoisture((prev: any) => [...prev, newOption]);
            setValueMoistureCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (MoistureSuccess && optionsMoisture) {
                await MoistureRefetch();
                setValue("carton_moisture", MoistureData[0]?.value)
            }
        })();

        return () => {
        };
    }, [MoistureSuccess, MoistureData]);



    const [colorOpen, setColorOpen] = useState(false)
    const [submittedColor, setSubmittedColor] = useState(false)
    const [submittedPrintingColor, setSubmittedPrintingColor] = useState(false)
    const [submittedBrand, setSubmittedBrand] = useState(false)


    const apinameForMaterialType = process.env.REACT_APP_PANNI_MATERIAL_USE_API?.toString()
    const { mutate: mutateMaterialType, data: dataMaterialType, isError: MaterialTypeIsError, isLoading: MaterialTypeLoading, isSuccess: MaterialTypeSuccess, error: MaterialTypeError }: any = useCreate(apinameForMaterialType)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueMaterialType, setValueMaterialType]: any = useState();
    const [selectedMaterialType, setSelectedMaterialType]: any = useState();

    const [optionsMaterialType, setOptionsMaterialType]: any = useState([]);
    const [valueMaterialTypeCreatable, setValueMaterialTypeCreatable] = useState<Option | null>();

    const { data: MaterialTypeData, refetch: MaterialTypeRefetch } = useFetchPanniMaterialUseDropDown()

    useEffect(() => {
        setOptionsMaterialType(MaterialTypeData)
    }, [MaterialTypeData])





    const handleChangeMaterialType = (e: any) => {

        MaterialTypeRefetch()

        setApiName(apinameForMaterialType)
        setDeleteApiName(apinameForMaterialType)
        setEditId(e?.value)
        setEditData(MaterialTypeData)
        setRenderKey(prevKey => prevKey + 1);


        setValueMaterialType(e)
        setValue("material_use", `${e?.value}`)
        setValueMaterialTypeCreatable(null)
    }





    const handleCreateMaterialType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutateMaterialType({ name: newOption.name, status: newOption.status })
            setOptionsMaterialType((prev: any) => [...prev, newOption]);
            setValueMaterialTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (MaterialTypeSuccess && optionsMaterialType) {
                await MaterialTypeRefetch();
                setValue("material_use", MaterialTypeData[0]?.value)
            }
        })();

        return () => {
        };
    }, [MaterialTypeSuccess, MaterialTypeData]);



    const { data: HSNData, refetch: HSNRefetch } = useFetchMaterialandHSNDropDown()

    useEffect(() => {
        setOptionsHSN(HSNData)
    }, [HSNData])

    // const [openHSN, setOpenHSN] = useState(false)

    const handleChangeHSN = (e: any) => {
        setValue("material_name_and_type_category_and_hsn_code_and_gst", `${e.value}`)
        //   setDensityValue(e.material)
        //   setValue("polymer_grade", e.polymer)
        // setForHSNValue({ label: e?.label, value: e?.value })
        setSelHSN(e)
    }


    // const handleInputChangeHSN = (e: any) => {
    //     setSearchHSN(e)
    // }

    // const handleChangeHSN = (e: any) => {
    //     setGSTNumber(e.gst)
    //     setSelHSN(e)
    //     setValue("hsn", `${e.value}`)
    //     setValue("gst", `${e.gst_id}`)
    // }

    useEffect(() => {
        if (data) {
            reset(data)
            setValue("product_color_name", data?.product_color_name?.id)
            setValue("name_and_grade", data?.name_and_grade?.id)
            setValue("material_name_and_type_category_and_hsn_code_and_gst", data?.material_name_and_type_category_and_hsn_code_and_gst?.id)
            setValue("gst", data?.gst?.id)
            setValue("product_brand", data?.product_brand?.id)
            setValue("product_printing_color_name", data.product_printing_color_name?.map((e: any) => e?.id))
            setColorTotal(data?.printing_total_color)
            setSelectedPnni(data.name_and_grade?.id)
            setSelected(data?.product_brand?.id)
            setSelectedHSN(data.material_name_and_type_category_and_hsn_code_and_gst?.id)
            setSelectedColor(data.product_color_name?.id)
            setSelectedPrintingColor(data.product_printing_color_name?.map((e: any) => e?.id))
            // getrelated({ "grade": data.name_and_grade?.id })
            setpanniKan(data?.product_kan)
            // setpanniPrinting(data?.printing)
            setSelectedMaterialType(data?.material_use?.id)
            setValue("material_use", data?.material_use?.id)
            setValue("printing_total_color", data?.printing_total_color)
            setValue("product_ply", data?.product_ply?.length)
            setCartonPlyValue(data?.product_ply?.length)
            setSelectedSealingType(data?.product_sealing_type?.id)
            setValue("product_sealing_type", data?.product_sealing_type?.id)
            setSelectedProductPrintingType(data?.product_printing_type?.id)
            setValue("product_printing_type", data?.product_printing_type?.id)
            setSelectedProductPakingType(data?.product_packing_type?.id)
            setValue("product_packing_type", data?.product_packing_type?.id)
            setSelectedMoisture(data?.carton_moisture?.id)
            setValue("carton_moisture", data?.carton_moisture?.id)
            setpanniPrinting(data?.product_printing)
            setSelectedProductBy(data?.product_by?.id)
            setValue("product_by", data?.product_by?.id)
            setSelectedProductType(data?.product_type?.id)
            setValue("product_type", data?.product_type?.id)
            setSelectedLocation(data?.product_location?.id)
            setValue("product_location", data?.product_location?.id)
            setSelectedUom(data?.uom?.id)
            setValue("uom", data?.uom?.id)



            let tempCartonPackingImage = data?.product_image?.map((img: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${img?.id}`,
                    name: `${img.title}`,
                    status: 'done',
                    url: img?.doc,
                    thumbUrl: img?.doc,
                }

                return ctx
            })

            let tempProductPacketPackingImage = data?.bunch_packing_image?.map((img: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${img?.id}`,
                    name: `${img.title}`,
                    status: 'done',
                    url: img?.doc,
                    thumbUrl: img?.doc,
                }

                return ctx
            })

            let tempProductImage = data?.drawing_image?.map((img: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${img?.id}`,
                    name: `${img.title}`,
                    status: 'done',
                    url: img?.doc,
                    thumbUrl: img?.doc,
                }

                return ctx
            })
            let tempProductPdfDrawing = data?.product_pdf?.map((pdf: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${pdf?.id}`,
                    name: `${pdf.title}`,
                    status: 'done',
                    url: pdf?.doc,
                    thumbUrl: pdf?.doc,
                }

                return ctx
            })
            let tempBunchPdfDrawing = data?.bunch_packing_pdf?.map((pdf: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${pdf?.id}`,
                    name: `${pdf.title}`,
                    status: 'done',
                    url: pdf?.doc,
                    thumbUrl: pdf?.doc,
                }

                return ctx
            })
            let tempDrawingPdfDrawing = data?.drawing_pdf?.map((pdf: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${pdf?.id}`,
                    name: `${pdf.title}`,
                    status: 'done',
                    url: pdf?.doc,
                    thumbUrl: pdf?.doc,
                }

                return ctx
            })

            setUploadCartonPackingImage(tempCartonPackingImage)
            setUploadProductPacketPacking(tempProductPacketPackingImage)
            setUploadProductImage(tempProductImage)
            setUploadProductPDF(tempProductPdfDrawing)
            setUploadBunchPdf(tempBunchPdfDrawing)
            setUploadDrawingPdf(tempDrawingPdfDrawing)


        }
        if (selPanni) {
            setSelectedPnni(data.name_and_grade?.id)
        }
        if (selColor) {
            setSelectedColor(data.paper_color?.id)
        }
        if (selHSN) {
            setSelectedHSN(data.hsn?.id)
        }
    }, [data, selectedColor, selectedPanni])


    // useEffect(() => {
    //     const temp_results = relatedData?.results.map((e: any) => {
    //         return (
    //             {
    //                 value: e.id,
    //                 label: e.hsn_code,
    //                 gst_id: e.gst.id,
    //                 gst: e.gst.india_GST,

    //             })

    //     })

    //     let temp_gst = temp_results?.find((obj: any) => obj.value === selectedHSN)
    //     setGSTNumber(temp_gst?.gst)
    //     setOptionsHSN(temp_results)
    // }, [relatedData])


    const [cartonPlyId, setCartonPlyId] = useState([])


    // const [cartonPlyValue,setCartonPlyValue] = useState("")
    let oldCartonPlyIds = data?.product_ply?.map((e: any) => {

        return e.id
    })



    const onSubmit = (pannidetail: any) => {
        // pannidetail.gst = pannidetail.gst
        // pannidetail.hsn = pannidetail.hsn
        // pannidetail.brand = pannidetail.brand
        // pannidetail.name_and_grade = pannidetail.name_and_grade
        // pannidetail.paper_color = pannidetail.paper_color
        // pannidetail.grade_type = selHSN

        delete pannidetail.product_empty_weight
        delete pannidetail.name_and_grade
        delete pannidetail.gst

        if (boriAndCartonImage.length == 0) {
            delete pannidetail.product_image
        }
        if (DrawingImage.length == 0) {
            delete pannidetail.bori_and_carton_image
        }

        if (productPacketPacking.length == 0) {
            delete pannidetail.bunch_packing_image
        }
        if (productPDF.length == 0) {
            delete pannidetail.product_pdf
        }
        if (DrawingPdf.length == 0) {
            delete pannidetail.drawing_pdf
        }
        if (BunchPdf.length == 0) {
            delete pannidetail.bunch_packing_pdf
        }

        pannidetail.product_ply = cartonPlyId?.concat(oldCartonPlyIds)



        const formData = new FormData();


        if (panniPrinting === "no") {

            delete pannidetail.printing_total_color
            delete pannidetail.product_printing_color_name
            delete pannidetail.brand
            delete pannidetail.brand_type
        }
        if (panniKan === "no") {
            delete pannidetail.kan_length
            delete pannidetail.kan_width
        }

        if (!logo) {
            delete pannidetail?.carton_image
        }
        if (!logoPdf) {
            delete pannidetail?.carton_drawing
        }



        for (const [key, value] of Object.entries(pannidetail)) {
            const formvalue: any = value
            if (key === "product_image") {
                logoId.map((old: any) => {
                    formData.append('product_images', old?.id)
                })
                for (let i = 0; i < boriAndCartonImage.length; i++) {

                    formData.append('product_image', boriAndCartonImage[i])
                }
                continue
            }
            if (key === "bunch_packing_image") {
                ProductImageId.map((old: any) => {
                    formData.append('bunch_packing_images', old?.id)
                })
                for (let i = 0; i < productPacketPacking.length; i++) {

                    formData.append('bunch_packing_image', productPacketPacking[i])
                }
                continue
            }

            if (key === "drawing_image") {
                ProductDrawingId.map((old: any) => {
                    formData.append('drawing_images', old?.id)
                })
                for (let i = 0; i < productImage.length; i++) {

                    formData.append('drawing_image', productImage[i])
                }
                continue
            }
            if (key === "product_pdf") {
                logoImagesId.map((old: any) => {
                    formData.append('product_pdfs', old?.id)
                })
                for (let i = 0; i < productPDF.length; i++) {

                    formData.append('product_pdf', productPDF[i])
                }
                continue
            }
            if (key === "drawing_pdf") {
                DrawingId.map((old: any) => {
                    formData.append('drawing_pdfs', old?.id)
                })
                for (let i = 0; i < DrawingPdf.length; i++) {

                    formData.append('drawing_pdf', DrawingPdf[i])
                }
                continue
            }
            if (key === "bunch_packing_pdf") {
                BunchDrawingId.map((old: any) => {
                    formData.append('bunch_packing_pdfs', old?.id)
                })
                for (let i = 0; i < BunchPdf.length; i++) {

                    formData.append('bunch_packing_pdf', BunchPdf[i])
                }
                continue
            }



            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            // else if (formvalue.hasOwnProperty('size')) {
            //     formData.append(key, formvalue[0])
            // }
            else if (Array.isArray(value) && key === "product_printing_color_name") {
                value.forEach((val, index) => formData.append(`product_printing_color_name`, val))
            }
            else if (Array.isArray(value) && key === "product_ply") {
                value.forEach((val, index) => formData.append(`product_ply`, val))
            }
            else if (key === "carton_image" || key === "carton_drawing") {
                if (typeof value === 'object' && value !== null && formvalue.length > 0) {
                    formData.append(key, formvalue[0])
                }
                else {
                    formData.append(key, "")
                }
            }
        }
        let data = {
            apiname: apiname,
            id: id,
            data: formData
        }
        mutate(data);
        setValuesofform(data)

    }


    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess || yessuccess || nosuccess) {
            setShow(true);
            setshowmessage("success");
            navigate("/listCarton", { state: { showEditToast: true } });
        } else if (isError) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [data, isSuccess, yessuccess, nosuccess, fatchCarton, error,
        isSuccess,]);
    useEffect(() => {
        if (isError) {
            if (error.response?.status === 405) {
                setConfirmmodal(true);
            }
        } else if (isError || yeserror || noerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [isError, yeserror, noerror]);
    useEffect(() => {
        setTimeout(() => {
            setShow(false);
        }, 3000);
    }, [show]);


    const handleChangePly = (e: any) => {
        let Capital = e.target.value
        setValue("product_ply", Capital.charAt(0).toUpperCase() + Capital.slice(1))
    }

    // const handleChangeMoisture = (e: any) => {
    //     let Capital = e.target.value
    //     setValue("carton_moisture", Capital.charAt(0).toUpperCase() + Capital.slice(1))
    // }


    const [cartonPlyValue, setCartonPlyValue] = useState("")


    const [cartonLength, setCartonLength] = useState(0)
    const [cartonWidth, setCartonWidth] = useState(0)
    const [cartonHeight, setCartonHeight] = useState(0)
    // const [kan,setKan] = useState("no")
    const [kanHeight, setKanHeight] = useState(0)
    const [kanWidth, setKanWidth] = useState(0)






    const handleCartonLength = (e: any) => {
        setCartonLength(Number(e.target.value))
    }

    const handleCartonWidth = (e: any) => {
        setCartonWidth(Number(e.target.value))
    }

    const handleCartonHeight = (e: any) => {
        setCartonHeight(Number(e.target.value))
    }

    // const handleKan=(e:any)=>{
    //     setKan(e.target.value)
    // }
    const handleKanHeight = (e: any) => {
        setKanHeight(Number(e.target.value))
    }
    const handleKanWidth = (e: any) => {
        setKanWidth(Number(e.target.value))
    }

    const [message, setMessage] = useState(false)



    const handleCartonPly = (e: any) => {

        setCartonPlyValue(e.target.value)
    }

    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }

    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }
    const noupdatemodal = () => {
        setOpenEditModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {

        delete data.created_at
        delete data.created_by
        let ok = {
            id: id,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditModel(false)
    }

    const [submittedUOM, setSubmittedUOM] = useState(false)


    useEffect(() => {
        if (submittedUOM) {
            setSelUom(optionsUom[0])

            setValue("uom", optionsUom[0]?.value)

        }

    }, [submittedUOM, optionsUom])

    const [logoId, setLogoId] = useState([])
    const [logoImagesId, setLogoImagesId] = useState([])
    const [ProductImageId, setProductImageId] = useState([])
    const [ProductDrawingId, setProductDrawingId] = useState([])
    const [BunchDrawingId, setBunchDrawingId] = useState([])
    const [DrawingId, setDrawingId] = useState([])

    const [DrawingImage, setDrawingImage] = useState([])
    const [uploadDrawingImage, setuploadDrawingImage] = useState<UploadFile[]>([])
    // const [BunchPDF, setBunchPDF] = useState([])
    const [uploadBunchPDF, setuploadBunchPDF] = useState<UploadFile[]>([])
    const [DrawingPDF, setDrawingPDF] = useState([])
    // const [uploadDrawingPDF, setuploadDrawingPDF] = useState<UploadFile[]>([])

    const [boriAndCartonImage, setBoriAndCartonImage] = useState([])
    const [uploadCartonPackingImage, setUploadCartonPackingImage] = useState<UploadFile[]>([])

    const [productPacketPacking, setProductPacketPacking] = useState([])
    const [uploadProductPacketPacking, setUploadProductPacketPacking] = useState<UploadFile[]>([])

    const [productImage, setProductImage]: any = useState([])
    const [uploadProductImage, setUploadProductImage] = useState<UploadFile[]>([])

    const [productPDF, setProductPDF] = useState([])
    const [uploadProductPDF, setUploadProductPDF] = useState<UploadFile[]>([])
    const [BunchPdf, setBunchPdf] = useState([])
    const [uploadBunchPdf, setUploadBunchPdf] = useState<UploadFile[]>([])
    const [DrawingPdf, setDrawingPdf] = useState([])
    const [uploadDrawingPdf, setUploadDrawingPdf] = useState<UploadFile[]>([])

    const cartonPackingChange = (e: any) => {
        e.file.status = "done"

        // const tempFiles = e.fileList.map((file: any) => {
        //     return file.originFileObj
        // })
        // setlogo(tempFiles)
        // setUploadFile(e.fileList)





        e.file.status = "done"

        let tempOldFiles: any = []


        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }
                tempOldFiles.push(ctx)

            }
        })

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        }).filter((e: any) => e != undefined)

        setLogoId(tempOldFiles)
        setBoriAndCartonImage(tempFiles)
        setUploadCartonPackingImage(e.fileList)

    }


    const ProductPacketPackingOnChange = (e: any) => {
        // e.file.status = "done"

        // const tempFiles = e.fileList.map((file: any) => {
        //     return file.originFileObj
        // })
        // setProductImage(tempFiles)
        // setUploadProductImage(e.fileList)




        e.file.status = "done"

        let tempOldFiles: any = []


        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }
                tempOldFiles.push(ctx)

            }
        })

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        }).filter((e: any) => e != undefined)

        setProductImageId(tempOldFiles)
        setProductPacketPacking(tempFiles)
        setUploadProductPacketPacking(e.fileList)

    }

    const ProductImageOnChange = (e: any) => {
        // e.file.status = "done"

        // const tempFiles = e.fileList.map((file: any) => {
        //     return file.originFileObj
        // })
        // setProductDrawing(tempFiles)
        // setUploadProductDrawing(e.fileList)




        e.file.status = "done"

        let tempOldFiles: any = []


        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }
                tempOldFiles.push(ctx)

            }
        })

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        }).filter((e: any) => e != undefined)

        setProductDrawingId(tempOldFiles)
        setProductImage(tempFiles)
        setUploadProductImage(e.fileList)

    }
    const ProductPDFOnChange = (e: any) => {
        // e.file.status = "done"

        // const tempFiles = e.fileList.map((file: any) => {
        //     return file.originFileObj
        // })
        // setlogoImages(tempFiles)
        // setUploadFileDoc(e.fileList)



        e.file.status = "done"

        let tempOldFiles: any = []


        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }
                tempOldFiles.push(ctx)

            }
        })

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        }).filter((e: any) => e != undefined)

        setLogoImagesId(tempOldFiles)
        setProductPDF(tempFiles)
        setUploadProductPDF(e.fileList)

    }
    const BunchPdfOnChange = (e: any) => {
        // e.file.status = "done"

        // const tempFiles = e.fileList.map((file: any) => {
        //     return file.originFileObj
        // })
        // setlogoImages(tempFiles)
        // setUploadFileDoc(e.fileList)



        e.file.status = "done"

        let tempOldFiles: any = []


        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }
                tempOldFiles.push(ctx)

            }
        })

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        }).filter((e: any) => e != undefined)

        setBunchDrawingId(tempOldFiles)
        setBunchPdf(tempFiles)
        setUploadBunchPdf(e.fileList)

    }
    const DrawingPdfOnChange = (e: any) => {
        // e.file.status = "done"

        // const tempFiles = e.fileList.map((file: any) => {
        //     return file.originFileObj
        // })
        // setlogoImages(tempFiles)
        // setUploadFileDoc(e.fileList)



        e.file.status = "done"

        let tempOldFiles: any = []


        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }
                tempOldFiles.push(ctx)

            }
        })

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        }).filter((e: any) => e != undefined)

        setDrawingId(tempOldFiles)
        setDrawingPdf(tempFiles)
        setUploadDrawingPdf(e.fileList)

    }

    const [submittedHSN, setSubmittedHSN] = useState(false)


    useEffect(() => {
        if (submittedHSN) {
            setSelHSN(optionsHSN[0])

            setValue("material_name_and_type_category_and_hsn_code_and_gst", optionsHSN[0]?.value)

        }

    }, [submittedHSN, optionsHSN])




    useEffect(() => {


        if (submittedColor) {

            setSelColor(options[0])
            setValue("product_color_name", options[0]?.value)
            // setForColorsValue(optionsPrinting[0])
            // setValue("printing_color", optionsPrinting[0]?.value)

        }


    }, [submittedColor, options])

    useEffect(() => {


        if (submittedPrintingColor) {

            // setForColorValue(options[0])
            //   setValue("color", options[0]?.value)
            setSelPrintingColor(optionsPrinting[0])
            setValue("product_printing_color_name", optionsPrinting[0]?.value)

        }


    }, [submittedPrintingColor, optionsPrinting])



    useEffect(() => {

        if (submittedBrand) {

            setValue("product_brand", optionsbrand[0].value)
            setValue("product_brand_type", optionsbrand[0].type)
            setSelBrand(optionsbrand[0])
        }
    }, [submittedBrand, optionsbrand])





    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
    } = useDelete();
    const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
    } = useDeletemforce();




    const handleRefetch = () => {
        if (apiName === apinameForMaterialType) {
            MaterialTypeRefetch()
        }
        if (apiName === apinameForSealingType) {
            SealingTypeRefetch()
        }
        if (apiName === apinameForProductPrintingType) {
            ProductPrintingTypeRefetch()
        }
        if (apiName === apinameForProductPakingType) {
            ProductPakingTypeRefetch()
        }
        if (apiName === apinameForProductBy) {
            ProductByRefetch()
        }
        if (apiName === apinameForProductType) {
            ProductTypeRefetch()
        }
        if (apiName === apinameForLocation) {
            LocationRefetch()
        }
        if (apiName === apinameForMoisture) {
            MoistureRefetch()
        }




        // else if(apiName === apinameForTaluka){
        //   TalukaRefetch()
        // }
    }


    useEffect(() => {
        if (OpenEditDropDownModel && valueMaterialType && apiName === apinameForMaterialType) {
            setValueMaterialType(null)
            setValueMaterialTypeCreatable(null)
        }
        if (OpenEditDropDownModel && valueSealingType && apiName === apinameForSealingType) {
            setValueSealingType(null)
            setValueSealingTypeCreatable(null)
        }
        if (OpenEditDropDownModel && valueProductPrintingType && apiName === apinameForProductPrintingType) {
            setValueProductPrintingType(null)
            setValueProductPrintingTypeCreatable(null)
        }
        if (OpenEditDropDownModel && valueProductPakingType && apiName === apinameForProductPakingType) {
            setValueProductPakingType(null)
            setValueProductPakingTypeCreatable(null)
        }
        if (OpenEditDropDownModel && valueProductBy && apiName === apinameForProductBy) {
            setValueProductBy(null)
            setValueProductByCreatable(null)
        }
        if (OpenEditDropDownModel && valueProductType && apiName === apinameForProductType) {
            setValueProductType(null)
            setValueProductTypeCreatable(null)
        }
        if (OpenEditDropDownModel && valueLocation && apiName === apinameForLocation) {
            setValueLocation(null)
            setValueLocationCreatable(null)
        }
        if (OpenEditDropDownModel && valueMoisture && apiName === apinameForMoisture) {
            setValueMoisture(null)
            setValueMoistureCreatable(null)
        }







        if (deletesucess && apiName === apinameForMaterialType) {
            setValueMaterialType(null)
            setValueMaterialTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameForSealingType) {
            setValueSealingType(null)
            setValueSealingTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameForProductPrintingType) {
            setValueProductPrintingType(null)
            setValueProductPrintingTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameForProductPakingType) {
            setValueProductPakingType(null)
            setValueProductPakingTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameForProductBy) {
            setValueProductBy(null)
            setValueProductByCreatable(null)
        }
        if (deletesucess && apiName === apinameForProductType) {
            setValueProductType(null)
            setValueProductTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameForLocation) {
            setValueLocation(null)
            setValueLocationCreatable(null)
        }
        if (deletesucess && apiName === apinameForMoisture) {
            setValueMoisture(null)
            setValueMoistureCreatable(null)
        }


    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (


        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
            <span>{label}</span>
            <div style={{ float: "right", justifyContent: "end" }}>



                {isExist ?

                    <Custom_Actions_Buttons value={value}
                        apiname={deleteApiName}
                        mutate={singleDelete}
                        // masterName={addButtonName}
                        deleteid={deleteid}
                        handleDeletedforce={handleDeletedforce}
                        closereconfirmdelete={closereconfirmdelete}
                        deletemodalask={deletemodalask}
                        setEditOpen={setOpenEditDropDownModel}
                    // permissionClass={permissionClass}
                    />
                    : ""}

            </div>
        </div>


    )


    const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
    }



    useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
        return () => {
            setDeleteid(undefined);
        };
    }, []);
    const handleConfirmDelete = () => {
        setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
    };

    const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
    };
    useEffect(() => {
        if (forcefullysuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullyerror) {
            setShow(true);
            setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullydeleteerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
    ]);

    useEffect(() => {
        if (deleteMultipleSuccess) {
            const showdepenedancymsg = deleteMultiple?.data?.data;
            const dependacyfound = showdepenedancymsg?.filter((color: any) => {
                if (
                    color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
                ) {
                    return color;
                }
            });
            if (dependacyfound?.length === deleteMultipleid?.length) {
                deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
            } else {
                setConfirmforcemodal(true);
            }
        } else if (deleteMultipleIsError) {
            setShow(true);
            setshowmessage("danger");
        } else if (deletesucess) {
            if (deletedata.data?.data?.length > 0) {
                setConfirmforcemodal(true);
            } else if (deleteid) {
                setShow(true);
                setshowmessage("success");
                deleteforce({ deleteid, apiname: deleteApiName });
            }
        }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

    const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
    };





    return (
        <div>

            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header px-3 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Carton</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Product Details</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listCarton" className="text-decoration-none text-black">Carton</Link></li>
                                    <li className="breadcrumb-item active">Edit Carton</li>

                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message?.name} /> : ""}
                                    {/* <div className="row">
                                        <h5 className="mb-0 mt-2">Carton Detail</h5>
                                    </div> */}
                                    <div className="row d-flex justify-content-md-  center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">

                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Carton Name & Grade <span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                    {...register("name_and_grade", { required: { value: true, message: "Carton Name & Grade is required" } })}
                                                        placeholder={'Panni Name & Grade'}
                                                        onInputChange={handleInputChangePanniName}
                                                        onChange={handleChangePanniName}
                                                        options={optionsPanniName}
                                                        value={selPanni === "" ? optionsPanniName.find((obj: any) => obj.value === selectedPanni) : selPanni}

                                                    />
                                                    <span className='text-red w-100'>{errors?.name_and_grade?.message}</span>
                                                </div> */}

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Name & Type & Category & HSN & GST% <span style={{ color: "red" }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            // placeholder={'Material Name & Type & Category & HSN & GST%'}
                                                            {...register("material_name_and_type_category_and_hsn_code_and_gst", { required: { value: false, message: "Material Name & Type & Category & HSN & GST% is required" } })}
                                                            onChange={handleChangeHSN}
                                                            options={optionsHSN}
                                                            components={customIndicator}
                                                            // value={forHSNValue}
                                                            value={selHSN === "" ? optionsHSN?.find((obj: any) => obj.value === selectedHSN) : selHSN}
                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenHSN(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.material_name_and_type_category_and_hsn_code_and_gst?.message}</span>


                                                </div>
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Carton Color Name <span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        {...register("paper_color", { required: { value: true, message: "Carton Color Name is required" } })}
                                                        placeholder={'Select Color'}
                                                        onInputChange={handleInputChange}
                                                        onChange={handleChange}
                                                        options={options}
                                                        value={selColor === "" ? options?.find((e: any) => e.value === selectedColor) : selColor}
                                                    />
                                                    <span className='text-red w-100'>{errors?.paper_color?.message}</span>
                                                </div> */}


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Use <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueMaterialType(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("material_use", { required: { value: true, message: "Material Use is required" } })}
                                                        placeholder={'Select Material Use'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsMaterialType}
                                                        onCreateOption={handleCreateMaterialType}
                                                        onMenuOpen={() => MaterialTypeRefetch()}
                                                        onChange={handleChangeMaterialType}
                                                        // value={valueMaterialTypeCreatable == null ? valueMaterialType : valueMaterialTypeCreatable}
                                                        value={valueMaterialTypeCreatable == null ? valueMaterialType == null ? optionsMaterialType?.filter((obj: any) => obj.value === selectedMaterialType) : valueMaterialType : valueMaterialTypeCreatable}


                                                    />
                                                    <span className='text-red w-100'>{errors?.material_use?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="paper_color">Product Color Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("product_color_name", { required: { value: true, message: "Product Color Name is required" } })}
                                                            placeholder={'Select Product Color Name'}
                                                            // value={forColorValue}
                                                            value={selColor === "" ? options?.find((e: any) => e.value === selectedColor) : selColor}
                                                            options={options}
                                                            onInputChange={handleInputChange}
                                                            components={customIndicator}
                                                            onChange={handleChange}
                                                            // isOptionDisabled={(options:any)=>
                                                            //     {
                                                            //         if(options.status === "inactive" || options.deleted == true){
                                                            //         return options
                                                            //         }
                                                            //        }}
                                                            className="dropdown-select"

                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setColorOpen(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_color_name?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="carton_width">Product Width mm <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="carton_width" placeholder="Product Width mm"
                                                            {...register("product_width_mm", { required: { value: true, message: "Product Width mm is required" } })}
                                                            onChange={handleCartonWidth}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_width_mm?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="carton_length">Product Length mm <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="carton_length" placeholder="Product Length mm"
                                                            {...register("product_length_mm", { required: { value: true, message: "Product Length mm is required" } })}
                                                            onChange={handleCartonLength} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_length_mm?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="carton_height">Product Height mm <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="carton_height" placeholder="Product Height mm"
                                                            {...register("product_height_mm", { required: { value: true, message: "Product Height mm is required" } })}
                                                            onChange={handleCartonHeight} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_height_mm?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="cartonkan">Product Kan Yes-No <span style={{ color: 'red' }}>*</span> </label>
                                                    <select className="form-select" {...register("product_kan", { required: { value: true, message: "Product Kan Yes-No is required" }, onChange: onPanniChange })}>
                                                        <option value={""} selected>Select Any</option>
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.product_kan?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="kan_width">Product Kan Width mm {panniKan === "yes" ? <span style={{ color: 'red' }}>*</span> : ''} </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" disabled={panniKan === "no"} className="form-control" id="kan_width" placeholder="Product Width mm"
                                                            {...register("product_kan_width", { required: { value: panniKan === "yes" ? true : false, message: "Product Kan Width mm is required" } })}
                                                            onChange={handleKanWidth}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_kan_width?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="kan_length">Product Kan Length mm {panniKan === "yes" ? <span style={{ color: 'red' }}>*</span> : ''} </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" disabled={panniKan === "no"} className="form-control" id="kan_length" placeholder="Product Length mm"
                                                            {...register("product_kan_length", { required: { value: panniKan === "yes" ? true : false, message: "Product Kan Length mm is required" } })}
                                                            onChange={handleKanHeight} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_kan_length?.message}</span>
                                                </div>


                                                {/* <div className="row mt-2">
                                        <h5 className="mb-0 mt-2">Carton Printing</h5>
                                    </div> */}
                                                {/* <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row"> */}
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Carton Printing</label>
                                                    <select className="form-select" {...register("printing", { onChange: onPrintingChange })}>
                                                        <option value={""} disabled selected>Select Any</option>
                                                        <option value={"yes"}>Yes</option>
                                                        <option value={"no"}>No</option>
                                                    </select>
                                                </div>
                                                {panniPrinting === "yes" ?
                                                    <>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Carton Brand Name </label>

                                                            <Select
                                                                {...register("brand", { required: { value: false, message: "Carton Brand Name is required" } })}
                                                                placeholder={'Select Brand Name'}
                                                                onInputChange={handleInputChangeBrand}
                                                                onChange={handleChangeBrand}
                                                                options={optionsbrand}
                                                                value={selBrand === "" ? optionsbrand.find((obj: any) => obj.value === selected) : selBrand}
                                                            />


                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Our Brand Carton</label>
                                                            <select disabled className="form-select" {...register("brand_type", { required: { value: false, message: "Carton Brand Name is required" } })}>
                                                                <option value={""} selected>Select Any</option>
                                                                <option value={"our"}>Our</option>
                                                                <option value={"other"}>Other</option>
                                                            </select>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Carton Printing Color Name</label>
                                                            <Select
                                                                placeholder={'Select Color'}
                                                                {...register("printing_color_name", { required: false })}
                                                                options={options}
                                                                onInputChange={handlePanniInputChange}
                                                                onChange={handleChangePanni}
                                                                value={selPrintingColor.length === 0 ? options?.filter((obj: any) => selectedPrintingColor.includes(obj.value)) : selPrintingColor}
                                                                isMulti
                                                            />
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Carton Printing Total Color</label>
                                                            <div className="input-group" data-colorpicker-id={2}>
                                                                <input type="text"
                                                                    // value={ColorTotal} 
                                                                    disabled className="form-control" id="exampleInputBrand" placeholder=" 0" {...register("printing_total_color")} />
                                                            </div>
                                                        </div>
                                                    </> : ""} */}

                                                {/* </div>
                                        </div>
                                    </div> */}


                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Carton Length MM <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Add Carton Length MM" {...register("carton_length", { required: { value: true, message: "Carton Length MM is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.carton_length?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Carton Width MM <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Add Carton Width MM" {...register("carton_width", { required: { value: true, message: "Carton Width MM is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.carton_width?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Carton Height MM <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Add Carton Height MM" {...register("carton_height", { required: { value: true, message: "Carton Height MM is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.carton_height?.message}</span>
                                                </div> */}


                                                {/* <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                                    <div className="col-md-12">
                                                        <div className="row"> */}
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Carton Kan <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("kan", { onChange: onPanniChange })}>
                                                        <option disabled selected>Select Any</option>
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                    </select>
                                                </div>
                                                {panniKan === "yes" ?
                                                    <>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Carton Kan Height MM<span style={{ color: 'red' }}>*</span></label>
                                                            <div className="input-group" data-colorpicker-id={2}>
                                                                <input type="number" className="form-control" id="exampleInputBrand" placeholder="Add Carton Height MM" {...register("kan_length", { required: { value: true, message: "Carton Kan Height MM is required" } })} />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                            <span className='text-red w-100'>{errors?.kan_length?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Carton Kan Width MM <span style={{ color: 'red' }}>*</span></label>
                                                            <div className="input-group" data-colorpicker-id={2}>
                                                                <input type="number" className="form-control" id="exampleInputBrand" placeholder="Add Carton Width MM" {...register("kan_width", { required: { value: true, message: "Carton Kan Width MM is required" } })} />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                </div>
                                                            </div>
                                                            <span className='text-red w-100'>{errors?.kan_width?.message}</span>
                                                        </div></> : ""} */}
                                                {/* </div>
                                                    </div>
                                                </div> */}

                                                <div className="col-sm-3 mt-2">
                                                    <label >Carton Ply<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2} >
                                                        {/* <input type="text" className="form-control" id="product_ply" placeholder="Add Carton Ply " {...register("product_ply", { onChange:handleChangePly,required: { value: true, message: "Carton Ply is required" } })} /> */}
                                                        {/* <div className="input-group-append " style={{ width: 0, padding: 0 }}> */}
                                                        <select className="form-select " style={{ width: "60%" }}
                                                            // disabled={(cartonHeight && cartonLength && cartonWidth) == 0}

                                                            {...register("product_ply", { required: { value: false, message: "Carton Ply  is required" } })}
                                                            onChange={handleCartonPly}
                                                        >
                                                            <option value="" disabled selected>Select Any</option>
                                                            <option value={1}>1</option>
                                                            <option value={2}>2</option>
                                                            <option value={3}>3</option>
                                                            <option value={4}>4</option>
                                                            <option value={5}>5</option>
                                                            <option value={6}>6</option>
                                                            <option value={7}>7</option>
                                                            <option value={8}>8</option>
                                                            <option value={9}>9</option>
                                                            <option value={10}>10</option>
                                                        </select>

                                                        <Button className="input-group-text border-start-0 bg-primary"
                                                            onClick={() => {
                                                                setOpenViewable(true)
                                                            }}
                                                        ><AiOutlineEye /></Button>

                                                        <Button className="input-group-text border-start-0 bg-success"
                                                            // disabled={cartonPlyValue == 0}
                                                            onClick={() => {
                                                                setOpen(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        {/* </div> */}

                                                    </div>
                                                    {message && <span className='text-success w-100 text-md'>Successfully Added!</span>}
                                                    {/* <span className='text-red w-100'>{errors?.product_ply?.message}</span> */}
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Sealing Type <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueSealingType(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("product_sealing_type", { required: { value: true, message: "Product Sealing Type is required" } })}
                                                        placeholder={'Select Product Sealing Type'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsSealingType}
                                                        onCreateOption={handleCreateSealingType}
                                                        onChange={handleChangeSealingType}
                                                        onMenuOpen={() => SealingTypeRefetch()}
                                                        // value={valueSealingTypeCreatable == null ? valueSealingType : valueSealingTypeCreatable}
                                                        value={valueSealingTypeCreatable == null ? valueSealingType == null ? optionsSealingType?.filter((obj: any) => obj.value === selectedSealingType) : valueSealingType : valueSealingTypeCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.product_sealing_type?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Printing <span style={{ color: 'red' }}>*</span></label>

                                                    <select
                                                        className="form-select"
                                                        {...register("product_printing", {
                                                            onChange: onPrintingChange,
                                                            required: {
                                                                value: true,
                                                                message: "Product Printing is required",
                                                            },
                                                        })}
                                                    >
                                                        <option value={""} selected>
                                                            Select Any
                                                        </option>
                                                        <option value={"yes"}>Yes</option>
                                                        <option value={"no"}>No</option>
                                                    </select>
                                                    <span className="text-red w-100">
                                                        {errors?.product_printing?.message}
                                                    </span>
                                                </div>
                                                {/* {panniPrinting === "yes" && (
                                                    <> */}

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Printing Type</label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueProductPrintingType(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("product_printing_type", { required: { value: panniPrinting === 'no' ? false : true, message: "Product Printing Type is required" } })}
                                                        placeholder={'Select Product Printing Type'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        isDisabled={panniPrinting === 'no' || ''}
                                                        options={optionsProductPrintingType}
                                                        onCreateOption={handleCreateProductPrintingType}
                                                        onMenuOpen={() => ProductPrintingTypeRefetch()}
                                                        onChange={handleChangeProductPrintingType}
                                                        // value={valueProductPrintingTypeCreatable == null ? valueProductPrintingType : valueProductPrintingTypeCreatable}
                                                        value={valueProductPrintingTypeCreatable == null ? valueProductPrintingType == null ? optionsProductPrintingType?.filter((obj: any) => obj.value === selectedProductPrintingType) : valueProductPrintingType : valueProductPrintingTypeCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.product_printing_type?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">
                                                    Product Printing Color Name
                                                    </label>
                                                    <div className="d-flex row" >

                                                        <Select
                                                            {...register("product_printing_color_name", {
                                                                required: {
                                                                    value: panniPrinting === 'no' ? false : true,
                                                                    message: "Product Printing Color Name is required",
                                                                },
                                                            })}
                                                            placeholder={"Select Product Printing Color Name"}
                                                            // value={forColorsValue}
                                                            options={optionsPrinting}
                                                            isDisabled={panniPrinting === 'no' || ''}
                                                            onInputChange={handlePanniInputChange}
                                                            components={customIndicator}
                                                            onChange={handleChangePanni}
                                                            isMulti={true}
                                                            value={selPrintingColor.length === 0 ? options?.filter((obj: any) => selectedPrintingColor.includes(obj.value)) : selPrintingColor}

                                                            // isOptionDisabled={(optionsPanniName:any)=>
                                                            //   {
                                                            //       if(optionsPanniName.status === "inactive" || optionsPanniName.deleted == true){
                                                            //       return optionsPanniName
                                                            //       }
                                                            //      }}
                                                            className="dropdown-select"

                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" disabled={panniPrinting === 'no'} onClick={() => {
                                                                setPrintingColorOpen(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className="text-red w-100">
                                                        {errors?.product_printing_color_name?.message}
                                                    </span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">
                                                    Product Printing Total Color
                                                    </label>
                                                    <div
                                                        className="input-group"
                                                        data-colorpicker-id={2}
                                                    >
                                                        <input
                                                            type="text"
                                                            // value={ColorTotal}
                                                            disabled
                                                            className="form-control"
                                                            id="exampleInputBrand"
                                                            placeholder=" 0"
                                                            {...register("product_printing_total_color", {
                                                                required: false,
                                                            })}
                                                        />
                                                    </div>
                                                    <span className="text-red w-100">
                                                        {errors?.product_printing_total_color?.message}
                                                    </span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="brandname">Product Brand Name </label>

                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("product_brand", { required: { value: panniPrinting === 'no' ? false : true, message: "Product Brand Name is required" } })}
                                                            placeholder={'Select Product Brand Name'}
                                                            onInputChange={handleInputChangeBrand}
                                                            onChange={handleChangeBrand}
                                                            options={optionsbrand}
                                                            isDisabled={panniPrinting === 'no' || ''}
                                                            components={customIndicator}
                                                            value={selBrand === "" ? optionsbrand?.find((obj: any) => obj.value === selected) : selBrand}

                                                            // value={forBrandValue}

                                                            // isOptionDisabled={(optionsbrand:any)=>
                                                            //     {
                                                            //         if(optionsbrand.status === "inactive" || optionsbrand.deleted === true){
                                                            //         return optionsbrand
                                                            //         }
                                                            //        }}
                                                            className="dropdown-select"

                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary"
                                                                disabled={panniPrinting === 'no'}
                                                                onClick={() => {
                                                                    setBrandOpen(true)
                                                                }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_brand?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="product_brand_type">Brand Our-Other</label>
                                                    <select disabled className="form-select" {...register("product_brand_type", { required: { value: false, message: "Brand Our-Other is required" } })}>
                                                        <option value={""} selected>Select Any</option>
                                                        {/* <option selected={colorNamePanni?.type === "our" ? true : false} value="our">Our</option>
                                                        <option selected={colorNamePanni?.type === "other" ? true : false} value="other">Other</option> */}
                                                        <option value={"our"}>Our</option>
                                                        <option value={"other"}>Other</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.product_brand_type?.message}</span>
                                                </div>

                                                {/* </>
                                                )} */}







                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="printinig_width">Product Printing Width mm</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="printinig_width"
                                                            disabled={panniPrinting === 'no' || ''}
                                                            placeholder="Carton Width mm" {...register("product_printing_width", { required: { value: panniPrinting === 'no' ? false : true, message: "Carton Printing Width MM is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_printing_width?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="printinig_width">Product Printing Length mm</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="printinig_width"
                                                            disabled={panniPrinting === 'no' || ''}
                                                            placeholder="Product Printing Length MM" {...register("product_printing_length", { required: { value: panniPrinting === 'no' ? false : true, message: "Product Printing Length mm is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_printing_length?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="carton_empty_weight">Product Empty Automatic KG </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="carton_empty_weight" disabled placeholder="Product Empty Automatic KG " {...register("product_empty_weight", { required: { value: false, message: "Product Empty Automatic KG is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_empty_weight?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="carton_empty_weight">Product Empty Manual KG </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="carton_empty_weight" placeholder="Product Empty Manual KG  " {...register("product_empty_weight_manual", { required: { value: false, message: "Product Empty Manual KG is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_empty_weight_manual?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="examplecarton_empty_bunch_packing_nosInputEmail1">Product Empty Bunch Packing</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="carton_empty_bunch_packing_nos" placeholder="Product Empty Bunch Packing " {...register("product_empty_bunch_packing_nos", { required: { value: false, message: "Product Empty Bunch Packing is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_empty_bunch_packing_nos?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">U.O.M </label>
                                                    <div className="d-flex row" >

                                                        <Select
                                                            {...register("uom", { required: { value: false, message: "Product U.O.M is required" } })}
                                                            placeholder={'Select U.O.M'}
                                                            onChange={handleChangeUom}
                                                            options={optionsUom}
                                                            // value={forUOMValue}
                                                            components={customIndicator}
                                                            className="dropdown-select"
                                                            value={selUom === "" ? optionsUom?.find((obj: any) => obj.value === selectedUom) : selUom}

                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenUOM(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.uom?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Prouct Packing Type</label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueProductPakingType(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("product_packing_type", { required: { value: true, message: "Prouct Packing Type is required" } })}
                                                        placeholder={'Select Prouct Packing Type '}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsProductPakingType}
                                                        onCreateOption={handleCreateProductPakingType}
                                                        onChange={handleChangeProductPakingType}
                                                        onMenuOpen={() => ProductPakingTypeRefetch()}
                                                        // value={valueProductPakingTypeCreatable == null ? valueProductPakingType : valueProductPakingTypeCreatable}
                                                        value={valueProductPakingTypeCreatable == null ? valueProductPakingType == null ? optionsProductPakingType?.filter((obj: any) => obj.value === selectedProductPakingType) : valueProductPakingType : valueProductPakingTypeCreatable}

                                                    />
                                                    <span className='text-red'>{errors?.product_packing_type?.message}</span>

                                                </div>

                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="carton_special_running">Carton Special / Running <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("carton_special_running", { required: { value: true, message: "Carton Special / Running is required" } })}>
                                                        <option value={""} selected>Select Any</option>
                                                        <option value={"special"}>Special</option>
                                                        <option value={"running"}>Running</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.carton_special_running?.message}</span>
                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="gst">GST </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" placeholder="GST %" disabled value={showGst} className="form-control" id="gst"  {...register("gst", { required: { value: false, message: "GST is required" } })} />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.gst?.message}</span>
                                                </div> */}


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="bursting_strength">Stock Minimum Bunch Qty <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="bursting_strength" placeholder="Stock Minimum Bunch Qty " {...register("stock_minimum_bunch_qty", { required: { value: true, message: "Stock Minimum Bunch Qty is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.stock_minimum_bunch_qty?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="bursting_strength">Stock Maximum Bunch Qty<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="bursting_strength" placeholder="Stock Maximum Bunch Qty " {...register("stock_maximum_bunch_qty", { required: { value: true, message: "Stock Maximum Bunch Qty is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.stock_maximum_bunch_qty?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="bursting_strength">Bursting Strength<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="bursting_strength" placeholder="Bursting Strength " {...register("bursting_strength", { required: { value: true, message: "Bursting Strength is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.bursting_strength?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Carton Moisture %</label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueMoisture(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("carton_moisture", { required: { value: true, message: "Carton Moisture % is required" } })}
                                                        placeholder={'Select Carton Moisture %'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsMoisture}
                                                        onCreateOption={handleCreateMoisture}
                                                        onChange={handleChangeMoisture}
                                                        onMenuOpen={() => MoistureRefetch()}
                                                        // value={valueMoistureCreatable == null ? valueMoisture : valueMoistureCreatable}
                                                        value={valueMoistureCreatable == null ? valueMoisture == null ? optionsMoisture?.filter((obj: any) => obj.value === selectedMoisture) : valueMoisture : valueMoistureCreatable}

                                                    />
                                                    <span className='text-red'>{errors?.carton_moisture?.message}</span>

                                                </div>

                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="carton_moisture">Carton Moisture<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="carton_moisture" placeholder="Add Carton Moisture " {...register("carton_moisture", { onChange: handleChangeMoisture, required: { value: true, message: "Carton Moisture is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.carton_moisture?.message}</span>
                                                </div> */}

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Prouct By</label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueProductBy(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("product_by", { required: { value: true, message: "Prouct By is required" } })}
                                                        placeholder={'Select Prouct By '}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsProductBy}
                                                        onCreateOption={handleCreateProductBy}
                                                        onMenuOpen={() => ProductByRefetch()}
                                                        onChange={handleChangeProductBy}
                                                        // value={valueProductByCreatable == null ? valueProductBy : valueProductByCreatable}
                                                        value={valueProductByCreatable == null ? valueProductBy == null ? optionsProductBy?.filter((obj: any) => obj.value === selectedProductBy) : valueProductBy : valueProductByCreatable}

                                                    />
                                                    <span className='text-red'>{errors?.product_by?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Prouct Type</label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueProductType(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("product_type", { required: { value: true, message: "Prouct Type is required" } })}
                                                        placeholder={'Select Prouct Type '}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsProductType}
                                                        onCreateOption={handleCreateProductType}
                                                        onChange={handleChangeProductType}
                                                        onMenuOpen={() => ProductTypeRefetch()}
                                                        // value={valueProductTypeCreatable == null ? valueProductType : valueProductTypeCreatable}
                                                        value={valueProductTypeCreatable == null ? valueProductType == null ? optionsProductType?.filter((obj: any) => obj.value === selectedProductType) : valueProductType : valueProductTypeCreatable}

                                                    />
                                                    <span className='text-red'>{errors?.product_type?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Maximum Debit Day <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Product Maximum Debit Day" {...register("product_maximum_debit_day", { required: { value: true, message: "Product Maximum Debit Day is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_maximum_debit_day?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Carton Location</label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueLocation(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("product_location", { required: { value: true, message: "Carton Location is required" } })}
                                                        placeholder={'Select Carton Location'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsLocation}
                                                        onCreateOption={handleCreateLocation}
                                                        onChange={handleChangeLocation}
                                                        onMenuOpen={() => LocationRefetch()}
                                                        // value={valueLocationCreatable == null ? valueLocation : valueLocationCreatable}
                                                        value={valueLocationCreatable == null ? valueLocation == null ? optionsLocation?.filter((obj: any) => obj.value === selectedLocation) : valueLocation : valueLocationCreatable}

                                                    />
                                                    <span className='text-red'>{errors?.product_location?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Remark </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" placeholder="Remark" className="form-control" id="exampleInputBrand"
                                                            {...register("remark", { onChange: handleValue, required: { value: false, message: "Remark is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.remark?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>
                                                        <option disabled selected>Select Any</option>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"inactive"}>Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>
                                                </div>

                                                {/* <div className="row mt-2">
                                                    <h5 className="mb-0 mt-2">Kan Detail</h5>
                                                </div> */}

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Carton Code</label>
                                                    <input type="text" disabled className="form-control" id="exampleInputBrand" placeholder="Add Panni Code" {...register("carton_code")} />
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="row mt-4">
                                        <h5 className="mb-0 mt-2">Carton Packing</h5>
                                    </div>
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="exampleInputEmail1">Carton Packet Packing Type <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Add Carton Packet Packing Type" {...register("packet_packing_type")} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="exampleInputEmail1">Carton Packing KG <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Add Carton Length MM" {...register("packing_kg")} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 mt-4">
                                                    <label htmlFor="exampleInputEmail1">Carton Packing Type <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("packing_type")}>
                                                        <option disabled selected>Select Any</option>
                                                        <option value={"single patto"}>Single Patto</option>
                                                        <option value={"bori-cartoon"}>Bori-Cartoon</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-2 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Product Image All</label>


                                                    {uploadCartonPackingImage?.length > 0 ?

                                                        <>
                                                            <Upload
                                                                {...register("product_image")}
                                                                accept="image/png, image/jpeg"
                                                                listType="picture"
                                                                onChange={cartonPackingChange}
                                                                multiple={true}
                                                                defaultFileList={[...uploadCartonPackingImage]}
                                                                onPreview={(e: any) => { return "" }}
                                                                key={"image"}

                                                            >
                                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                            </Upload>
                                                        </> :
                                                        <>
                                                            <Upload
                                                                {...register("product_image")}
                                                                accept="image/png, image/jpeg"
                                                                listType="picture"
                                                                onChange={cartonPackingChange}
                                                                multiple={true}
                                                                onPreview={(e: any) => { return "" }}

                                                            >
                                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                            </Upload>
                                                        </>
                                                    }


                                                    {/* <Upload
                                                        {...register("bori_and_carton_image")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={cartonPackingChange}
                                                        multiple={true}
                                                        defaultFileList={uploadFile}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload> */}
                                                </div>

                                                <div className="col-sm-2 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Bunch Packing Image All</label>

                                                    {uploadProductPacketPacking?.length > 0 ?

                                                        <>
                                                            <Upload
                                                                {...register("bunch_packing_image")}
                                                                accept="image/png, image/jpeg"
                                                                listType="picture"
                                                                onChange={ProductPacketPackingOnChange}
                                                                multiple={true}
                                                                defaultFileList={[...uploadProductPacketPacking]}
                                                                onPreview={(e: any) => { return "" }}
                                                                key={"image"}

                                                            >
                                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                            </Upload>
                                                        </> :
                                                        <>
                                                            <Upload
                                                                {...register("bunch_packing_image")}
                                                                accept="image/png, image/jpeg"
                                                                listType="picture"
                                                                onChange={ProductPacketPackingOnChange}
                                                                multiple={true}
                                                                onPreview={(e: any) => { return "" }}

                                                            >
                                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                            </Upload>
                                                        </>
                                                    }

                                                    {/* <Upload
                                                        {...register("product_packet_packing_image")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={ProductOnChange}
                                                        multiple={true}
                                                        defaultFileList={uploadProductImage}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload> */}
                                                </div>

                                                <div className="col-sm-2 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Drawing Image All</label>

                                                    {uploadProductImage?.length > 0 ?

                                                        <>
                                                            <Upload
                                                                {...register("drawing_image")}
                                                                accept="image/png, image/jpeg"
                                                                listType="picture"
                                                                onChange={ProductImageOnChange}
                                                                multiple={true}
                                                                defaultFileList={[...uploadProductImage]}
                                                                onPreview={(e: any) => { return "" }}
                                                                key={"image"}

                                                            >
                                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                            </Upload>
                                                        </> :
                                                        <>
                                                            <Upload
                                                                {...register("drawing_image")}
                                                                accept="image/png, image/jpeg"
                                                                listType="picture"
                                                                onChange={ProductImageOnChange}
                                                                multiple={true}
                                                                onPreview={(e: any) => { return "" }}

                                                            >
                                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                            </Upload>
                                                        </>
                                                    }
                                                    {/* <Upload
                                                        {...register("product_image")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={ProductDrawingOnChange}
                                                        multiple={true}
                                                        defaultFileList={uploadProductDrawing}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload> */}
                                                </div>
                                                <div className="col-sm-2 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Product PDF All</label>

                                                    {uploadProductPDF?.length > 0 ?
                                                        <>
                                                            <Upload
                                                                {...register("product_pdf")}
                                                                accept=".doc,.docx,.xml,.pdf"
                                                                listType="text"
                                                                onChange={ProductPDFOnChange}
                                                                multiple={true}
                                                                defaultFileList={[...uploadProductPDF]}
                                                                onPreview={(e: any) => { return "" }}
                                                                key={"pdf"}
                                                            >
                                                                <img src={pdfLogo} alt='' className="uploaded-image" />
                                                                {/* <p>{logoPdf == null ? data?.pdf?.split("/").pop() : logoPdf}</p> */}

                                                            </Upload>
                                                        </> : <>
                                                            <Upload
                                                                {...register("product_pdf")}
                                                                accept=".doc,.docx,.xml,.pdf"
                                                                listType="text"
                                                                onChange={ProductPDFOnChange}
                                                                multiple={true}
                                                                onPreview={(e: any) => { return "" }}
                                                            >
                                                                <img src={pdfLogo} alt='' className="uploaded-image" />
                                                                {/* <p>{logoPdf == null ? data?.pdf?.split("/").pop() : logoPdf}</p> */}

                                                            </Upload>
                                                        </>
                                                    }
                                                    {/* <Upload
                                                        {...register("product_pdf_drawing")}
                                                        accept=".doc,.docx,.xml,.pdf"
                                                        listType="text"
                                                        onChange={DocOnChange}
                                                        multiple={true}
                                                        defaultFileList={uploadFileDoc}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"pdf"}
                                                    >
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                    </Upload> */}
                                                </div>
                                                <div className="col-sm-2 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Bunch Packing PDF All</label>

                                                    {uploadBunchPdf?.length > 0 ?
                                                        <>
                                                            <Upload
                                                                {...register("bunch_packing_pdf")}
                                                                accept=".doc,.docx,.xml,.pdf"
                                                                listType="text"
                                                                onChange={BunchPdfOnChange}
                                                                multiple={true}
                                                                defaultFileList={[...uploadBunchPdf]}
                                                                onPreview={(e: any) => { return "" }}
                                                                key={"pdf"}
                                                            >
                                                                <img src={pdfLogo} alt='' className="uploaded-image" />
                                                                {/* <p>{logoPdf == null ? data?.pdf?.split("/").pop() : logoPdf}</p> */}

                                                            </Upload>
                                                        </> : <>
                                                            <Upload
                                                                {...register("bunch_packing_pdf")}
                                                                accept=".doc,.docx,.xml,.pdf"
                                                                listType="text"
                                                                onChange={BunchPdfOnChange}
                                                                multiple={true}
                                                                onPreview={(e: any) => { return "" }}
                                                            >
                                                                <img src={pdfLogo} alt='' className="uploaded-image" />
                                                                {/* <p>{logoPdf == null ? data?.pdf?.split("/").pop() : logoPdf}</p> */}

                                                            </Upload>
                                                        </>
                                                    }
                                                    {/* <Upload
                                                        {...register("product_pdf_drawing")}
                                                        accept=".doc,.docx,.xml,.pdf"
                                                        listType="text"
                                                        onChange={DocOnChange}
                                                        multiple={true}
                                                        defaultFileList={uploadFileDoc}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"pdf"}
                                                    >
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                    </Upload> */}
                                                </div>
                                                <div className="col-sm-2 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Drawing PDF All</label>

                                                    {uploadDrawingPdf?.length > 0 ?
                                                        <>
                                                            <Upload
                                                                {...register("drawing_pdf")}
                                                                accept=".doc,.docx,.xml,.pdf"
                                                                listType="text"
                                                                onChange={DrawingPdfOnChange}
                                                                multiple={true}
                                                                defaultFileList={[...uploadDrawingPdf]}
                                                                onPreview={(e: any) => { return "" }}
                                                                key={"pdf"}
                                                            >
                                                                <img src={pdfLogo} alt='' className="uploaded-image" />
                                                                {/* <p>{logoPdf == null ? data?.pdf?.split("/").pop() : logoPdf}</p> */}

                                                            </Upload>
                                                        </> : <>
                                                            <Upload
                                                                {...register("drawing_pdf")}
                                                                accept=".doc,.docx,.xml,.pdf"
                                                                listType="text"
                                                                onChange={DrawingPdfOnChange}
                                                                multiple={true}
                                                                onPreview={(e: any) => { return "" }}
                                                            >
                                                                <img src={pdfLogo} alt='' className="uploaded-image" />
                                                                {/* <p>{logoPdf == null ? data?.pdf?.split("/").pop() : logoPdf}</p> */}

                                                            </Upload>
                                                        </>
                                                    }
                                                    {/* <Upload
                                                        {...register("product_pdf_drawing")}
                                                        accept=".doc,.docx,.xml,.pdf"
                                                        listType="text"
                                                        onChange={DocOnChange}
                                                        multiple={true}
                                                        defaultFileList={uploadFileDoc}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"pdf"}
                                                    >
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                    </Upload> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        onClick={handleSubmit(onSubmit)}
                                                    // disabled={!isDirty}
                                                    >Submit</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listCarton")}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
            <Color_Model refetchForm={colorRefetch} open={colorOpen} setOpen={setColorOpen} setSubmitted={setSubmittedColor} />
            <Color_Model refetchForm={colorRefetch} open={printingColorOpen} setOpen={setPrintingColorOpen} setSubmitted={setSubmittedPrintingColor} />
            <Brand_Model refetchForm={brandRefetch} open={brandOpen} setOpen={setBrandOpen} setSubmitted={setSubmittedBrand} />
            <HSN_Model refetchForm={HSNRefetch} open={openHSN} setOpen={setOpenHSN} setSubmitted={setSubmittedHSN} />
            <UOM_Model refetchForm={UomRefetch} open={openUOM} setOpen={setOpenUOM} setSubmitted={setSubmittedUOM} />



            {/* Edit Modal HTML */}
            <Modal
                show={deleteConfirm}
                onHide={() => setDeleteConfirm(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete {""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {" "}
                        <span className="text-center">Sure you want to delete it?</span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setDeleteConfirm(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={handleMultipleDeleted}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Deleteforce
                key={renderKey}
                handleDeletedforce={handleDeletedforce}
                reconfirmdelete={confirmforcemodal}
                masterName={""}
                closereconfirmdelete={closereconfirmdelete}
                referance={
                    deletesucess
                        ? deletedata
                        : ""
                }
                apiname={apiName}
            />
            <Deletesingle
                key={renderKey}
                confirmmodal={deleteConfirmsingle}
                confirmmodalclose={confirmmodalclose}
                masterName={""}
                confirmmodalsubmit={confirmmodalsubmit}
                apiname={apiName}
            />

            <SimpleInput_Edit key={renderKey}
                refetchForm={handleRefetch}
                open={OpenEditDropDownModel}
                setOpen={setOpenEditDropDownModel}
                id={editId}
                // superAccountId={superAccountId} 
                // optionsSuperAccount={optionsSuperAccount}
                // isSuperAccount={isSuperAccount}
                apiname={apiName} />



            {open &&


                <>
                    <Modal

                        size='xl'

                        show={open} onHide={() => {
                            setShow(false)
                            // reset()
                            setOpen(false)
                        }} aria-labelledby="contained-modal-title-vcenter"
                        centered    >
                        <Modal.Header closeButton>
                            {/* <Modal.Title>Brand</Modal.Title> */}
                        </Modal.Header>
                        <Modal.Body>

                            <Editable cartonSize={cartonPlyValue} cartonLength={Number(data?.product_length_mm)} cartonWidth={Number(data?.product_width_mm)}
                                cartonHeight={Number(data?.product_height_mm)}
                                kanHeight={Number(data?.product_kan_length)}
                                kanWidth={Number(data?.product_kan_width)}
                                panniKan={panniKan}
                                setOpen={setOpen}
                                setMessage={setMessage}
                                setCartonPlyId={setCartonPlyId}
                            // setSavedData={setSavedData}
                            />

                        </Modal.Body>
                        <Modal.Footer>


                        </Modal.Footer>
                    </Modal>
                </>


            }




            {openViewable &&


                <>
                    <Modal

                        size='xl'

                        show={openViewable} onHide={() => {
                            setShow(false)
                            // reset()
                            setOpenViewable(false)
                        }} aria-labelledby="contained-modal-title-vcenter"
                        centered    >
                        <Modal.Header closeButton>
                            {/* <Modal.Title>Brand</Modal.Title> */}
                        </Modal.Header>
                        <Modal.Body>

                            <Viewable cartonSize={cartonPlyValue} cartonLength={cartonLength} cartonWidth={cartonWidth}
                                cartonHeight={cartonHeight}
                                kanHeight={kanHeight}
                                kanWidth={kanWidth}
                                panniKan={panniKan}
                                setOpenViewable={setOpenViewable}
                                setMessage={setMessage}
                                setCartonPlyId={setCartonPlyId}
                                allData={data}

                            // setSavedData={setSavedData}
                            />

                        </Modal.Body>
                        <Modal.Footer>


                        </Modal.Footer>
                    </Modal>
                </>


            }

            <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                                {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>
                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>
                </Modal.Footer>
            </Modal>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />
            {/* <Footer /> */}
        </div>
    )
}

export default Edit_Carton

function typeOf(value: any): any {
    throw new Error("Function not implemented.");
}

