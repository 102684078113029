// import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../../components/Cell/Status";
import { useFetch, useUpdateSingle } from "../../../hooks/Api";
import noImage from "../../../images/noImage.png"
import Modal from "react-bootstrap/esm/Modal";



interface Data {
  selection: any



  transportation: any
  godown_stock: any
  balance_stock: any
  lee10_price: any
  order_qty: any
  uom: any
  discount_after_tax: any
  discount: any
  one_kg_rate: any
  after_discount_box: any
  brand: any
  nos_strip: any
  box_strip: any
  cup_weight: any
  box_weight: any
  basic_discount: any
  total_basic_discount: any
  basic_rate_per: any
  total_basic: any
  gst_rate: any
  gst_amount: any
  net_amount: any




  verify:any

  created_at: any

  created_by: any
  updated_by: any
  updated_at: any


  // status: any;
  dispatched: any;
}



export const titleofheaders = [
  {
    id: `srno`,
    Header: `Sr No`,
  },








  {
    id: 'transportation',
    Header: "Transportation",

  },
  {
    id: 'name',
    Header: "Name",

  },
  {
    id: 'dispatch_date',
    Header: "Dispatch Date",

  },




  {
    id: 'created_at',
    Header: "Created At",
  },
  {
    id: 'created_by',
    Header: "Created By",
  },
  {
    id: 'updated_at',
    Header: "Updated At",
  },
  {
    id: 'updated_by',
    Header: "Updated By",
  },

]




export const Columns_DispatchsOrder: any = [




  {
    id: `srno`,
    Header: () => <div style={{ display: "inline" }}>Sr.<br />No</div>,
    sticky: "left",
  },

  {
    id: 'transportation',
    Header: () => <div style={{ display: "inline" }}>Transportation</div>,
    // Header: 'Sales Inquiry Code',
    sticky: "left",
    accessor: 'transportation',
    Cell: ({ row }: any) => {

      return (
        (`${row.original.extra_response?.transportation?.transportation_name}`)
      )

    }
  },
  {
    id:'do_number',
    Header: () => <div style={{ display: "inline" }}>Dispacth<br/>Number</div>,
    accessor: 'do_number',
    Cell: ({row}:any)=>{
      return(
        (row.original?.do_number)
      )
    }
  },
  {
    id: 'name',
    Header: () => <div style={{ display: "inline" }}>Name</div>,
    // Header: 'Lead Generated by Sales Executive Code & Name',
    accessor: 'name',
    Cell: ({ row }: any) => {


      return (
        (`${row.original.extra_response?.customer?.name}`)
      )
    }

  },
  {
    id: 'dispatch_date',
    Header: () => <div style={{ display: "inline" }}>Dispatch<br />Date</div>,
    // Header: 'Lead Source Of Enquiry',
    accessor: 'dispatch_date',
    Cell: ({ row }: any) => {


      return (
        <div>
          {row.original.dispatch_date}
          {/* {"500"} */}
        </div>
      )
    }

  },
  // {
  //   id: 'total_order_qty',
  //   Header: () => <div style={{ display: "inline" }}>Total Order<br />Qty</div>,
  //   // Header: 'Lead Source Of Enquiry',
  //   accessor: 'total_order_qty',
  //   Cell: ({ row }: any) => {


  //     return (
  //       <div>
  //         {row.original.total_order_qty}
  //         {/* {"500"} */}
  //       </div>
  //     )
  //   }

  // },
  // {
  //   id: 'total_dispatch_qty',
  //   Header: () => <div style={{ display: "inline" }}>Total Dispatch<br />Qty</div>,
  //   // Header: 'Lead Source Of Enquiry',
  //   accessor: 'total_dispatch_qty',
  //   Cell: ({ row }: any) => {


  //     return (
  //       <div>
  //         {row.original.total_dispatch_qty}
  //         {/* {"500"} */}
  //       </div>
  //     )
  //   }

  // },



  {
    id: 'created_at',
    Header: () => <div style={{ display: "inline" }}>Created<br />At</div>,
    accessor: 'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{ display: "inline" }}>Created<br />By</div>,
    accessor: 'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username


  },
  {
    id: 'updated_at',
    Header: () => <div style={{ display: "inline" }}>Updated<br />At</div>,
    accessor: 'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{ display: "inline" }}>Updated<br />By</div>,
    accessor: 'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },


  {
    id:'dispatch_selection',
    Header: () => <div style={{ display: "inline" }}>Dispatch<br />Selection</div>,
  },
  // {
  //   id: 'Selection',
  //   Header: ({ getToggleAllRowsSelectedProps }: any) => {
  //     const [data, setData] = useState([])

  //     const columns: any = useMemo(() => Columns_DispatchsOrder, [])
  //     const defaultColumn: any = useMemo(() => {
  //       return {
  //         Filter: Columnlevel
  //       }
  //     }
  //       , [])

  //     const tableInstance: any = useTable({
  //       columns,
  //       data: data,
  //       defaultColumn,

  //     },


  //     )
  //     const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
  //     let { selectedFlatRows } = tableInstance
  //     const { } = useMutation((deleteid: any) => {
  //       const temp_data = {
  //         id: deleteMultipleid
  //       }
  //       return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SALES_DISPATCH_ORDER_API}/deletelist/`, temp_data,
  //         {
  //           headers: {
  //             'Authorization': `Bearer ${localStorage.getItem('token')}`
  //           }
  //         });
  //     });
  //     return (
  //       <>
  //         <div>
  //           <Checkbox

  //             onClick={() => {
  //               setDeleteMultipleid(() => {
  //                 const temp = selectedFlatRows?.map((e: any) => {
  //                   return e.original.id;
  //                 });
  //                 return temp;
  //               });
  //             }}

  //             {...getToggleAllRowsSelectedProps()} />
  //         </div>
  //       </>
  //     );
  //   },

  //   Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
  //     // Add your onClick function here
  //   }} />
  // },
  {
    id:'dispatched',
    Header:'Dispatched'
  }
  // {
  //   id: 'verify',
  //   Header: () => <div style={{ display: "inline" }}>Verify</div>,
  
  // },
]




