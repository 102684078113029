import React, { useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../components/Dynamic_Table/Table/Table";
import { Columns_Panni_Other,titleofheaders_Other } from "./Columns_Panni_Other";

const List_Panni_Other = () => {
  const Breadcrumb_Primary_Title = "Home";
  const Breadcrumb_Primary_Link = "Color";

  const Breadcrumb_Parent_Title = "Product Details";

  const Breadcrumb_Title_Name = "Packing Material";

  const apiname = process.env.REACT_APP_PANNI_API?.toString();

  const filter = {
    panni_code:"",
    name_and_grade:"",
  color:"",
  brand:"",
  brand_type:"",
  hsn_code:"",
  type:"",
  length:"",
  width:"",
  micron:"",
  gauge:"",
  panni_1_nos_weight_gram:"",
  panni_1KG_in_nos:"",
  material_use:"",
  bursting_strength:"",
  sealing_type:"",
  panni_special_running:"",
  gst:"",
  printing:"",
  printing_type:"",
  printing_color_name:"",
  printing_total_color:"",
  printing_length:"",
  printing_width:"",
  gadget:"",
  gadget_size:"",
  kan:"",
  kan_length:"",
  kan_width:"",
  packet_packing_type:"",
  packing_kg:"",
  packing_type:"",
  product_ml:"",
  product_actual_ml:"",
  uom:"",
  packet_qty:"",
  product_packing_total_kg:"",
  product_stock_minimum_qty: "",
  product_stock_maximum_qty: "",
  product_maximum_debit_day: "",
  remark: "",
  product_by: "",
  panni_location: "",
  product_packing_total_kg_uom: "",
  // created_by:"",
// updated_by:"", 
status:''
  };

  const addButtonName = "Packing Material";

  const stickyStart = 2;
  const stickyEnd = 3;

  const addButtonPageName="Panni"
  const permissionClass = {
    front: "panni",
    back: "panni"
  }
  return (
    <>
      <div className="content-wrapper">
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{Breadcrumb_Title_Name} List</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to={`/list${Breadcrumb_Primary_Link}`}
                      className="text-decoration-none text-black"
                    >
                      {Breadcrumb_Primary_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      {Breadcrumb_Parent_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {Breadcrumb_Title_Name} List
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="container p-0" style={{ maxWidth: "100%" }}>
                {/* <BasicTable/> */}
                <Table
                  apiname={apiname}
                  filterFields={filter}
                  addButtonName={addButtonName}
                  stickyStart={stickyStart}
                  stickyEnd={stickyEnd}
                  TableColumns={Columns_Panni_Other}
                  addButtonPageName={addButtonPageName}
                  titleofheaders={titleofheaders_Other}
                  permissionClass={permissionClass}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default List_Panni_Other;
