import { useEffect, useMemo, useState } from "react";
import "../../components/Header/header.css";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { fetchApiHelper } from "../../hooks/helpers/fetch";
import { useModeloptionsvisibleupt } from "../../hooks/Updatecolumnvisiblebackend";
import Mui_Toaster from "../../components/Mui_Toaster/Mui_Toaster";
import { useFetchSidebar } from "../../hooks/DropDown_Api";
import { Link } from "react-router-dom";

const Columnsettings = ({ permissionClass }: any) => {
  // const apiname = process.env.REACT_APP_SIDEBAR?.toString();
  const [sidebarOptions, setSidebarOptions]: any = useState([]);

  const { data: sidebarData} = useFetchSidebar();

  
  useEffect(() => {
    setSidebarOptions(sidebarData);
  }, [sidebarData]);

  const [tempShow, setTempShow] = useState(false);
  const { mutate } = useModeloptionsvisibleupt();

  const [options, setOptions] = useState([]);
  const [childOptions, setChildOptions] = useState([]);
  const [labels, setLabels] = useState([]);

  const { control, handleSubmit, watch, reset } = useForm({
    mode: "onChange",
  });
  const parentmaster = watch("parentmaster");
  const childselect = watch("childselect");
  useEffect(() => {
    setOptions(
      sidebarData?.map((parent: any) => {
        return {
          
          label: parent.title,
          value: parent.id,
        };
      })
    );
  }, [sidebarData]);

  const onSubmit = (data: any) => {
    setTempShow(true);
    reset({ parentmaster: "", childselect: "" });
    setLabels([]);
    setChildOptions([]);
  };
  useEffect(() => {
    if (parentmaster) {
      const mychildoptions = sidebarData?.filter(
        (child: any) => child.id === parentmaster.value
      );
      const extractchilds = mychildoptions
        ? mychildoptions[0]?.modules.map((subchild: any) => {
          console.log("subchild",subchild)
            return { label: subchild.model?.app_label, value: subchild?.model?.id };
          })
        : [];
      setChildOptions(extractchilds);
      reset({ childselect: "" });
      setLabels([]);
    }
  }, [parentmaster,sidebarData]);
   useMemo(() => {
    if ((childselect !== undefined || childselect !== 0) && childselect) {
      fetchApiHelper(
        `configuration/columnVisibility/get_by_model/?id=${childselect?.value}`
      ).then((succed: any) => {
        setLabels(succed?.results[0]?.columns);
      });
    }
  }, [childselect]);
  const handleCheckboxChange = (columnName: any, value: any, index: any) => {
    setTempShow(false);
    const lablelist: any = labels;
    lablelist[index] = {
      ...lablelist[index],
      visibility: value ? "SHOW" : "HIDE",
    };
    setLabels(lablelist);
    mutate({
      value: lablelist[index]["id"],
      visibility: value,
    });
  };

  return (
    // <Restricted to={"configuration.view_columns"} fallback={""}>
      <div>
        <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
          <section className="content-header py-1 px-3">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  <h4>Column Visibility</h4>
                </div>
                <div className="col-sm-6 ">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link
                        to="/dashboard"
                        className="text-decoration-none text-black"
                      >
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="#" className="text-decoration-none text-black">
                        Settings
                      </Link>
                    </li>
                    <li className="breadcrumb-item active">
                      Column Visibility
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            {tempShow && (
              <Mui_Toaster
                message={"Setting Updated Successfully"}
                severity={"success"}
                Isopen={true}
              />
            )}
            <div className="container-fluid">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="card card-default"
              >
                <div className="row ms-1 mt-2 justify-content-evenly py-3">
                  <div className="col-5">
                    <Controller
                      name="parentmaster"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder={"Master/Table"}
                          options={options}
                        />
                      )}
                    />
                  </div>
                  <div className="col-5">
                    <Controller
                      name="childselect"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder={"Master/Table"}
                          options={childOptions}
                        />
                      )}
                    />
                  </div>

                  <div className="row pt-2">
                    {labels?.map((columnprops: any, index: any) => {
                      return (
                        <Controller
                          key={columnprops.column_name}
                          name={`${columnprops?.display_name}`}
                          control={control}
                          render={({ field }) => (
                            <div className="col-3">
                              {/* {columnprops.is_sticky == true?
                                                        
                                                        <button className={`w-100 m-1 btn  bg-secondary`}
                                                        onClick={(e:any)=>e.preventDefault()}
                                                        >
                                                            {columnprops?.display_name}</button>
                                                    :
                                                    <button className={`w-100 m-1 btn ${columnprops?.visibility === "SHOW" ? 'bg-success' : 'bg-danger'}`} onClick={(e)=>{
                                                        e.preventDefault()
                                                        handleCheckboxChange(columnprops.column_name, columnprops?.visibility === "SHOW" ? false:true, index);
                                                    }}>{columnprops?.display_name}</button>
                                                    
                                                    } */}
                              <button
                                className={`w-100 m-1 btn ${
                                  columnprops?.visibility === "SHOW"
                                    ? "bg-success"
                                    : "bg-danger"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleCheckboxChange(
                                    columnprops.column_name,
                                    columnprops?.visibility === "SHOW"
                                      ? false
                                      : true,
                                    index
                                  );
                                }}
                              >
                                {columnprops?.display_name}
                              </button>
                            </div>
                          )}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="text-end mx-4 mb-3">
                  {/* <button type="submit" className="btn bg-primary " onClick={handleSubmit(onSubmit)}>Submit</button> */}

                  <button
                    type="submit"
                    className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                    style={{
                      borderColor: "rgb(0, 54, 101)",
                      background: "rgb(0, 54, 101)",
                      color: "white",
                    }}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
    // </Restricted>
  );
};

export default Columnsettings;
