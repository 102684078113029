import React, { useContext } from "react";
import PermissionContext from "./PermissionContext";
import { Permission } from "./Permission";
import Result from "antd/es/result";
import { Link } from "react-router-dom";

type Props = {
  to: Permission;
  fallback?: JSX.Element | string;
  children: any;
};

// This component is meant to be used everywhere a restriction based on user permission is needed
const Restricted: any = ({ to, fallback, children }: Props) => {
  // We "connect" to the provider thanks to the PermissionContext
  const { isAllowedTo } = useContext(PermissionContext);

  // If the user has that permission, render the children
  if (isAllowedTo(to)) {
    return <>{children}</>;
  }

  // Otherwise, do not render anything
  if (fallback != "") {
    return (
      <>
        {/* <div className="text-center"> */}
          {/* <h3>{fallback}</h3> */}
          <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
            extra={
              <Link to={"/listColor"} className="btn btn-primary">
                Back Home
              </Link>
            }
          />
        {/* </div> */}
      </>
    );
  } else {
    return "";
  }
};

export default Restricted;
