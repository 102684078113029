import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Restricted from "../../Roles/Restricted";
const Custom_Actions_Buttons = ({ value, apiname, deletemodalask,permissionClass,setEditOpen }: any) => {
  
  return (
    <>
      <div
        className="vertical-align-center"
        style={{
          display: "inline-block !important",
          justifyContent: "space-evenly !important",
        }}
      >
        {/* <Restricted
          to={`${permissionClass?.front}.change_${permissionClass?.back}`}
          fallback={""}
        > */}
          <button
            className="btn text-white border-0 mx-2 my-1" 
            style={{padding:"0px 2px 2px 2px",background:'rgb(188 90 21)'}}
            onClick={(e:any)=>{
              // e.stopPropagation();
              setEditOpen(true)
            }}
          >
            <FiEdit  size={16}/>
          </button>
        {/* </Restricted> */}

        {/* <Restricted
          to={`${permissionClass?.front}.delete_${permissionClass?.back}`}
          fallback={""}
        > */}
        <button
          onClick={(e:any) => {
            // e.stopPropagation();
            deletemodalask(value);
          }}
          className="btn text-white border-0 mx-2 my-1" 
          style={{padding:"0px 2px 2px 2px",background:'#c81a1a'}}
          // className="border-0 mx-2 "
        >
          <RiDeleteBinLine size={18}/>
        </button>
        {/* </Restricted> */}
      </div>
    </>
  );
};

export default Custom_Actions_Buttons;

export const Deletesingle = ({
  apiname,
  confirmmodal,
  confirmmodalclose,
  masterName,
  confirmmodalsubmit,
}: any) => {
  return (
    <Modal
      show={confirmmodal}
      onHide={confirmmodalclose}
      aria-labelledby="Delete single"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete {masterName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {" "}
        <span>
          {" "}
          <span className="text-center">
            Are you sure you want to delete this item?
          </span>
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={confirmmodalclose}>
          Cancel
        </Button>
        <Button
          variant="info"
          onClick={confirmmodalsubmit}
          style={{ color: "white" }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export const Deleteforce = ({
  apiname,
  reconfirmdelete,
  handleDeletedforce,
  masterName,
  closereconfirmdelete,
  referance,
}: any) => {



  



  return (
    <Modal
      show={reconfirmdelete}
      onHide={closereconfirmdelete}
      aria-labelledby="delete multiple confirmatino force"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete {masterName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {" "}
        <span>
          {" "}
          <span className="text-center">
            {"Are you sure you want to delete this?"}
          </span>
        </span>
        <br />
        {referance?.data &&
          typeof referance?.data === "object" &&
          referance?.data?.data?.length > 0 &&
          referance?.data?.data[0]?.field && (
            <Displaydataforce data={referance?.data.data} color={apiname} />
          )}
        {referance?.data?.data?.map((usedepenadancy: any) => {
          return (
            <>
              {usedepenadancy.dependency !=
                `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}` ||
                (usedepenadancy?.length > 0 && (
                  <Displaydataforce
                    data={usedepenadancy.dependency}
                    color={usedepenadancy?.object?.name}
                  />
                ))}
            </>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closereconfirmdelete}>
          Cancel
        </Button>
        <Button
          variant="info"
          // onClick={handleDeletedforce}
          disabled
          style={{ color: "white" }}
        >
          Delete Anyway
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Displaydataforce = ({ data, color }: any) => {
  return (
    <>
      This{" "}
      {color === "Color"
        ? "Color"
        : color === "NumberSystem"
        ? "Number System"
        : color !== ""
        ? color?.slice(0, 1)?.toUpperCase() + color.slice(1)
        : "Department"}{" "}
      Is Used as
      {data && (
        <ul>
          {data?.map((useddata: any) => {
            return (
              // <li>{useddata?.field!="NumberSystem"?useddata?.field:"All Code"} - <a href={`edit${useddata?.model}/${useddata?.id}`} target='blank'>View</a> </li>
              <li>
                {useddata?.field != "NumberSystem"
                  ? useddata?.field
                  : "All Code"}{" "}
                -{" "}
                <a
                  href={`${process.env.REACT_APP_FRONT_URL}edit${useddata?.model}/${useddata?.id}`}
                  target="blank"
                >
                  View
                </a>{" "}
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};
