
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useCallback, useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import { updateColor, useFetchColors, useUpdateSingleColor } from "../../hooks/color/color";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import Actions from "../../components/Cell/Actions";
import { updateBrand, useFetchBrands } from "../../hooks/brand";
import noImage from "../../images/No_Brand.png"
import { Update, useFetch } from "../../hooks/Api";
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

interface Data {
  selection: any
  // id: number;
  hex: any;
  name: string;
  type: string;
  image: any;
  logo: any
  logo_pdf: any
  brand_code: any
  created_at: any

  created_by: any
  updated_by: any
  updated_at: any

  status: any;
  actions: any;
}



export const titleofheaders = [
  {
    id: `srno`,
    Header: `Sr No`,
  },
  {
    id: `brand_code`,
    Header: `Brand Code`,



  },
  {
    id: `name`,
    Header: `Name`,




  },
  {
    id: `type`,
    Header: `Type`,


  },






  {
    id: 'status',
    Header: 'Status',


  },

  {
    id: 'created_at',
    Header: `Created At`,
  },


  {
    id: 'created_by',
    Header: `Created By`,


  },
  {
    id: 'updated_at',
    Header: `Updated At`,

  },
  {
    id: 'updated_by',
    Header: `Updated By`,

  },




]





export const Columns_Brand: any = [




  {
    id: `srno`,
    Header: () => <div style={{ display: "inline" }}>Sr<br />No</div>,
    sticky: "left",
  },
  {
    id: `name`,
    Header: () => <div style={{ display: "inline" }}>Brand<br />Name</div>,
    sticky: "left",
    accessor: `name`,




  },



  {
    id: `type`,
    Header: () => <div style={{ display: "inline" }}>Brand<br />Type</div>,

    accessor: `type`,
    Cell: ({ row }: any) => {
      const convertToUpper = row.original.type
      if (!convertToUpper) return "";
      return convertToUpper.split("_").map((w: any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }

  },

  {
    id: `logo`,
    Header: () => <div style={{ display: "inline" }}>Brand<br />Logo</div>,

    // accessor: `logo`,
    Cell: ({ row }: any) => {

      const [deleteConfirm, setDeleteConfirm] = useState(false)

      return (
        <>

          <div >

            {!row.original.logo ? <>
              <a

                href="#"

              // data-large-src={row.original.logo?row.original.logo:noImage} data-toggle="modal"
              >
                <img src={noImage} height={60} width={100} />
              </a>
            </> : <>
              <a
                onClick={() => setDeleteConfirm(true)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={row.original.logo ? row.original.logo : noImage} data-toggle="modal">
                <img src={row.original.logo ? row.original.logo : noImage} height={60} width={100} />
              </a>
            </>}

          </div>



          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >
            <Modal.Header >
              <p style={{color:'red',display:'flex', justifyContent:'center'}}>Scroll to Zoom In-Out </p>
              <Modal.Title>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <TransformWrapper>
                <TransformComponent>
                  <img src={row.original.logo ? row.original.logo : noImage} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid" />
                </TransformComponent>
              </TransformWrapper>
            </Modal.Body>
            <Modal.Footer>
               
                <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>
              </Modal.Footer>
          </Modal>
        </>)
    }


  },


  {
    id: `logo_pdf`,
    Header: `PDF`,

    accessor: `logo_pdf`,
    Cell: ({ row }: any) =>



      <div>
        <a target="_blank" href={row.original.logo_pdf ? row.original.logo_pdf : null}>{row.original.logo_pdf?.split('/').pop() ? row.original.logo_pdf?.split('/').pop() : <span className="text-danger">No PDF Uploaded</span>}</a>
      </div>

  },

  {
    id: `brand_code`,
    Header: () => <div style={{ display: "inline" }}>Brand<br />Code</div>,
    accessor: `brand_code`,



  },



  // {
  //   id: 'status',
  //   Header: 'Status',
  //   accessor: 'status',
  //   Cell: ({ row }: any) => {
  //     const [pageSize, setPageSize] = useState("5")
  //     const [search, setSearch] = useState("")
  //     const [FilterBy, setFilterBy] = useState({
  //       hex: '', name: ''
  //     })
  //     const [page, setPage] = useState(1)
  //     const[orderBy,setOrderBy] = useState("")

  //     const apiname = process.env.REACT_APP_BRAND_API?.toString()

  //     const { isLoading, isError, data: fetchAllBrand, error, isFetching, refetch, isSuccess, isPreviousData } = useFetch(FilterBy,search,page,pageSize,orderBy,apiname)


  //     return <><Status row={row} update={Update} apiname={apiname} refetch_f={refetch} /></>
  //   }
  // },

  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',


  },

  {
    id: 'created_at',
    Header: () => <div style={{ display: "inline" }}>Created<br />At</div>,
    accessor: 'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{ display: "inline" }}>Created<br />By</div>,
    accessor: 'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username


  },
  {
    id: 'updated_at',
    Header: () => <div style={{ display: "inline" }}>Updated<br />At</div>,
    accessor: 'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{ display: "inline" }}>Updated<br />By</div>,
    accessor: 'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },



  {
    id: 'Actions',
    Header: 'Actions',


  },
  {
    id: 'Selection',
    // sticky:"left",
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_Brand, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows } = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}brand/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]
