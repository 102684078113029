import React from "react";
import Select from "react-select";

interface DropDownFields {
  options?: any;
  selectedOption?: any;
  onChange?: any;
  className?: any;
}

const React_Select_DropDownListComponent = ({
  options,
  selectedOption,
  onChange,
  className,
}: DropDownFields) => {
  return (
    <div>

      <Select
        value={selectedOption}
        className={className ? className : "Customdropdown-select"}
        onChange={onChange}
        
        options={options}
        isMulti
      />
    </div>
  );
};

export default React_Select_DropDownListComponent;
