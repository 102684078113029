import React from 'react'

interface CheckBoxFields {
    label? : any
    onChange? : any
    checked?: boolean
    required? : boolean
}

const CheckBoxComponent = ({label, onChange, checked, required} : CheckBoxFields) => {
  return (
    <label>
        <input type="checkbox"  onChange={onChange} checked={checked}/>
        <span className='mx-1'>{label}</span>
        {(required ? <span style={{ color: 'red' }}>*</span> : "")}
    </label>
  )
}

export default CheckBoxComponent