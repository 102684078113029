import React from 'react';
import "./footer.css"

const Footer = () => {
    return (
        <div>
            <footer className="main-footer">
                <strong>Copyright © 2023 QuantumBot</strong>
            </footer>
        </div>
    )
}

export default Footer